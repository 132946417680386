import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import { Delay } from "../Decorators/Delay";
import { Param, ComponentParam, ComponentUtils } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { IDataSource, IDataSourceListener, IDataSourceModel, IDataSourceView } from "../DataSources/IDataSource";
import { ISelect2Result, ISelect2Query } from "../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

const { classes } = jss
    .createStyleSheet({
        select2component: {
            "&.no-dropdown": {
                display: "block",

                "& .select2-choice": {
                    "& .select2-chosen": {
                        marginRight: "20px",
                    },
                    "& .select2-search-choice-close": {
                        right: "4px",
                        top: "6px",
                    },
                    "& .select2-arrow": {
                        right: "20px",
                    },
                },
            },
        },
    })
    .attach();

const attributes = {
    AllowClear: "allowClear",
    Placeholder: "placeholder",
    Multiple: "multiple",
    ReadOnly: "readOnly",
    Simple: "simple",
    NoFormControl: "noFormControl",
    NoDropdown: "noDropdown",
    Label: "label",
    Value: "value",
    DataSource: "dataSource",
    Listener: "listener",
    InjectTo: "injectTo",
    CssClass: "cssClass",
    Styles: "styles",
    LabelCSS: "labelCss",
    InputCSS: "inputCss",
    TabIndex: "tabindex",
    HasFocus: "hasFocus",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            select2: {
                params?:
                    | {
                          AllowClear?: string;
                          Placeholder?: string;
                          Multiple?: string;
                          ReadOnly?: string;
                          Simple?: string;
                          NoFormControl?: string;
                          NoDropdown?: string;
                          Label?: string;
                          Value?: string;
                          DataSource?: string;
                          Listener?: string;
                          InjectTo?: string;
                          CssClass?: string;
                          Styles?: string;
                          LabelCSS?: string;
                          InputCSS?: string;
                          TabIndex?: string;
                          HasFocus?: string;
                      }
                    | string;

                allowClear?: boolean | (() => string);
                placeholder?: string | (() => string);
                multiple?: boolean | (() => string);
                readOnly?: boolean | (() => string);
                simple?: boolean | (() => string);
                noFormControl?: boolean | (() => string);
                noDropdown?: boolean | (() => string);
                label?: string | (() => string);
                value?: () => string;
                dataSource?: () => string;
                listener?: () => string;
                injectTo?: () => string;
                cssClass?: string | (() => string);
                styles?: string | (() => string);
                labelCss?: string | (() => string);
                inputCss?: string | (() => string);
                tabindex?: number | (() => string);
                hasFocus?: boolean | (() => string);
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

interface ISelect2OnSelectingEvent {
    preventDefault(): void;
    val: string;
    choice?: ISelect2ResultEx;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISelect2Component {}

export interface ISelect2ComponentParameters {
    AllowClear?: Param<boolean>;
    Placeholder?: Param<string>;
    Multiple?: Param<boolean>;
    ReadOnly?: Param<boolean>;

    Simple?: Param<boolean>;
    NoFormControl?: Param<boolean>;
    NoDropdown?: Param<boolean>;

    Label: Param<string>;
    Value: Param<any>;

    DataSource: IDataSource;
    Listener?: Param<IDataSourceListener | IDataSourceListener[]>;
    InjectTo?: ko.Observable<ISelect2Component> | ko.Computed<ISelect2Component>;

    CssClass?: Param<string>;
    Styles?: Param<string>;

    LabelCSS?: Param<string>;
    InputCSS?: Param<string>;
    TabIndex?: Param<number>;
    HasFocus?: Param<boolean>;
}

interface ISelect2ResultEx extends ISelect2Result {
    icon?: string;
    background?: string;
    foreground?: string;
    disabled?: boolean;
    subTitle?: string;
    model?: IDataSourceModel;
    secondaryIcon?: string;
    secondaryIconBackground?: string;
    secondaryIconForeground?: string;
}

class Select2Component implements IDataSourceView {
    AllowClear: ComponentParam<boolean>;
    Placeholder: ComponentParam<string>;
    Multiple: ComponentParam<boolean>;
    Label: ComponentParam<string>;
    ReadOnly: ComponentParam<boolean>;

    Value: ComponentParam<any>;

    CssClass: ComponentParam<string>;
    Styles: ComponentParam<string>;

    LabelCSS: ComponentParam<string>;
    InputCSS: ComponentParam<string>;
    TabIndex: ComponentParam<number>;
    HasFocus: ComponentParam<boolean>;

    private dataSource: IDataSource;
    private listeners: IDataSourceListener[];
    private pageSize = 50;

    constructor(params: ISelect2ComponentParameters) {
        this.AllowClear = ComponentUtils.parseParameter(params.AllowClear, false);
        this.Placeholder = ComponentUtils.parseParameter(params.Placeholder, "");
        this.Multiple = ComponentUtils.parseParameter(params.Multiple, false);
        this.Value = ComponentUtils.parseParameter(params.Value, null);
        this.Label = ComponentUtils.parseParameter(params.Label, "");
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.CssClass = ComponentUtils.parseParameter(params.CssClass, "");
        this.Styles = ComponentUtils.parseParameter(params.Styles, "");
        this.LabelCSS = ComponentUtils.parseParameter(params.LabelCSS, "");
        this.InputCSS = ComponentUtils.parseParameter(params.InputCSS, "");
        this.TabIndex = ComponentUtils.parseParameter(params.TabIndex, 0);
        this.HasFocus = ComponentUtils.parseParameter(params.HasFocus, false);

        this.dataSource = ComponentUtils.parseParameter(params.DataSource, null)();
        const listeners = ComponentUtils.parseParameter(params.Listener, null)();
        this.listeners = Array.isArray(listeners) ? listeners : listeners ? [listeners] : [];

        if (params.InjectTo) params.InjectTo(this);

        if (this.dataSource) this.dataSource.setView(this);
    }

    getPageSize(): number {
        return this.pageSize;
    }

    setPageSize(size: number): void {
        this.pageSize = size;
    }

    refresh(keepSelection?: boolean): void {}

    refreshImmediate(keepSelection?: boolean): void {}

    pushState(): void {}

    popState(): void {}

    async select(...models: IDataSourceModel<string | number, any, string | number, any>[]): Promise<void> {
        if (models.length > 1) throw new Error("Select2Component non supporta la selezione multipla");

        if (models.length == 0) await this.OnSelecting(null);
        else {
            const realModel = await this.dataSource.getById(null, [models[0].id]);
            if (realModel.length == 0) return;
            await this.OnSelecting(realModel[0]);
        }
    }

    navigateTo(...history: IDataSourceModel<string | number, any, string | number, any>[]): void {}

    @Delay()
    public find(query: ISelect2Query) {
        const hasGroups = this.dataSource.isGroupedData(null, query.term);
        if (hasGroups) {
            this.dataSource.getData(null, query.term, 0, 50).then((groups: IDataSourceModel[]) => {
                const results = [];
                const promises: Promise<any>[] = [];

                for (const group of groups) {
                    const groupModel = this.createModelFor(group);

                    promises.push(
                        this.dataSource.getData(group, query.term, 0, 50).then((children: IDataSourceModel[]) => {
                            groupModel.children = children.filter((m) => !m.disabled).map(this.createModelFor, this);
                        })
                    );

                    results.push(groupModel);
                }

                Promise.all(promises).then(() => {
                    query.callback({
                        results: results,
                    });
                });
            });
        } else {
            this.dataSource.getData(null, query.term, 0, 50).then((models: IDataSourceModel[]) => {
                query.callback({
                    results: models.filter((m) => !m.disabled).map(this.createModelFor, this),
                });
            });
        }
    }

    private createModelFor(m: IDataSourceModel): ISelect2ResultEx {
        const model: ISelect2ResultEx = {
            text: m.title,
            subTitle: m.subTitle,
            disabled: m.disabled || false,
            icon: m.icon ? m.icon.icon : null,
            background: (m.icon ? m.icon.background : null) || "transparent",
            foreground: (m.icon ? m.icon.foreground : null) || "black",
            secondaryIcon: m.secondaryIcon ? m.secondaryIcon.icon : null,
            secondaryIconBackground: (m.secondaryIcon ? m.secondaryIcon.background : null) || "transparent",
            secondaryIconForeground: (m.secondaryIcon ? m.secondaryIcon.foreground : null) || "black",
            model: m,
        };

        if (!m.isGroup) {
            model.id = m.id;
        }

        return model;
    }

    public async OnSelecting(model: IDataSourceModel): Promise<void> {
        const calls: Promise<any>[] = [];

        for (const listener of this.listeners) {
            if (listener.canSelectItem) calls.push(listener.canSelectItem(this.dataSource, model));
        }

        const values = await Promise.all(calls);

        if (values.indexOf(false) > -1) return;

        this.Value(!model ? null : model.id);

        if (!model) {
            this.notifyListeners(null);
            return;
        }

        this.notifyListeners(model);
    }

    public OnSelectionChange(model: IDataSourceModel) {
        this.notifyListeners(model);
    }

    public async OnClearing(): Promise<boolean> {
        const calls: Promise<any>[] = [];

        for (const listener of this.listeners) {
            if (listener.canClearSelection) calls.push(listener.canClearSelection(this.dataSource));
        }

        const values = await Promise.all(calls);

        if (values.indexOf(false) > -1) return false;

        return true;
    }

    public OnClear(): void {
        for (const listener of this.listeners)
            if (listener.onSelect2Cleared) listener.onSelect2Cleared(this.dataSource);
    }

    public async initSelection(
        element: HTMLElement,
        callback: (result: ISelect2ResultEx | ISelect2ResultEx[]) => void
    ) {
        const value = $(element).val() as string;
        if (value === null || value === undefined || value === "") {
            callback(null);
            return;
        }

        const values = value.split("|").map((id: string) => (isNaN(parseInt(id)) ? id : parseInt(id)));

        const models: IDataSourceModel[] = await this.dataSource.getById(null, values);
        const selectableModels: IDataSourceModel[] = [];

        for (const model of models) {
            let canSelectModel = true;
            for (const listener of this.listeners) {
                if (listener.canSelectItem) {
                    const canSelectResult = await listener.canSelectItem(this.dataSource, model);
                    if (!canSelectResult) canSelectModel = false;
                }
            }
            if (canSelectModel) {
                selectableModels.push(model);
            }
        }

        const items = selectableModels
            .filter((m) => !m.disabled)
            .map<ISelect2ResultEx>((m) => {
                return {
                    id: m.id,
                    text: m.title,
                    subTitle: m.subTitle,
                    disabled: m.disabled || false,
                    icon: m.icon ? m.icon.icon : null,
                    background: (m.icon ? m.icon.background : null) || "transparent",
                    foreground: (m.icon ? m.icon.foreground : null) || "black",
                    secondaryIcon: m.secondaryIcon ? m.secondaryIcon.icon : null,
                    secondaryIconBackground: (m.secondaryIcon ? m.secondaryIcon.background : null) || "transparent",
                    secondaryIconForeground: (m.secondaryIcon ? m.secondaryIcon.foreground : null) || "black",
                    model: m,
                };
            });

        try {
            //Se viene distrutto il select2 mentre questa operazione è in corso, questa chiamata schianta
            callback(items.length > 0 ? (items.length == 1 ? items[0] : items) : null);
        } catch (e) {
            console.error(e);
        }
    }

    public formatResult(
        object: ISelect2ResultEx,
        container: JQuery<HTMLElement>,
        query: ISelect2Query
    ): string | HTMLElement | JQuery<HTMLElement> {
        /*return  `<div style="margin-top: 3px; width: 100%; font-size: 89%;line-height: 1em;">` + 
                    (object.icon ? `<span style="background-color: ${object.background}; color: ${object.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px; float: left;" class="${object.icon}"></span>&nbsp;` : "") + 
                    `<span style="line-height: ${object.subTitle ? 'inherit' : '20px'}">${object.text}</span>` + 
                    (object.subTitle ? `<br/><small style="margin-left: 4px;" class="text-muted">${object.subTitle}</span>` : "") + 
                `</div>`;*/
        let template = `<table style="width: 100%">
                            <tbody>
                                <tr>`;
        if (object.icon)
            template += `<td rowspan="2" style="padding-right: 5px; vertical-align: top; width: 20px">
                                        <span style="background-color: ${object.background}; color: ${object.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px;" class="${object.icon}"></span>
                                    </td>`;

        template += `<td title="${object.text}">${object.text}</td>`;

        if (object.secondaryIcon)
            template += `<td rowspan="2" style="padding-right: 5px; vertical-align: top; width: 20px">
                                        <span style="background-color: ${object.secondaryIconBackground}; color: ${object.secondaryIconForeground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px;" class="${object.secondaryIcon}"></span>
                                    </td>`;

        template += `</tr>`;
        if (object.subTitle)
            template += `<tr>
                                    <td><small class="text-muted">${object.subTitle}</span></td>
                                </tr>`;
        template += `</tbody>
                        </table>`;
        return template;
    }

    public formatSelection(
        object: ISelect2ResultEx | ISelect2ResultEx[],
        container: JQuery<HTMLElement>
    ): string | HTMLElement | JQuery<HTMLElement> {
        let value: ISelect2ResultEx;
        if (Array.isArray(object) && object.length > 0) value = object[0];
        else value = object as ISelect2ResultEx;

        return (
            `<div style="margin-top: 3px; width: 100%;overflow: hidden;-ms-text-overflow: ellipsis;-o-text-overflow: ellipsis;text-overflow: ellipsis;white-space: nowrap;font-size: 89%;line-height: 1em;">` +
            (value.icon
                ? `<span style="background-color: ${value.background}; color: ${value.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px; float: left;" class="${value.icon}"></span>&nbsp;`
                : "") +
            `<span style="line-height: ${value.subTitle ? "inherit" : "20px"}" title="${value.text}">${
                value.text
            }</span>` +
            (value.secondaryIcon
                ? `<span style="background-color: ${value.secondaryIconBackground}; color: ${value.secondaryIconForeground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px; float: right;" class="${value.secondaryIcon}"></span>&nbsp;`
                : "") +
            (value.subTitle
                ? `<br/><small style="margin-left: 4px;" class="text-muted">${value.subTitle}</span>`
                : "") +
            `</div>`
        );
    }

    private notifyListeners(model: IDataSourceModel) {
        for (const listener of this.listeners) {
            listener.onItemSelected(this.dataSource, model);
        }
    }
}

type Select2Props = {
    dataSource: IDataSource;
    label?: ko.MaybeObservable<string>;
    value: ko.Observable<any>;
    allowClear?: boolean;
    inputClassName?: string;
    listener?: IDataSourceListener;
    placeholder?: string;
    simple?: boolean;
    readonly?: ko.MaybeObservable<boolean>;
    noFormControl?: boolean;
    noDropdown?: boolean;
    children?: React.ReactNode;
    className?: string;

    onFocus?: (element: HTMLInputElement) => void;
    onBlur?: (element: HTMLInputElement) => void;
};
export class Select2 {
    private vm: Select2Component;

    private inputRef: HTMLInputElement = null;

    constructor(private props: Select2Props) {
        this.vm = new Select2Component({
            DataSource: props.dataSource,
            Label: props.label,
            Value: props.value,
            AllowClear: props.allowClear,
            CssClass: props.inputClassName,
            Listener: props.listener,
            Placeholder: props.placeholder,
            Simple: props.simple,
            ReadOnly: props.readonly,
        });

        if (props.onFocus) this.vm.HasFocus.subscribe((value) => value && props.onFocus(this.inputRef));
        if (props.onBlur) this.vm.HasFocus.subscribe((value) => !value && props.onBlur(this.inputRef));
    }

    render() {
        const s2 = this.vm;

        const userClasses = ComponentUtils.classNames(classes.select2component, {
            "form-control": !this.props.noFormControl,
            "no-dropdown": this.props.noDropdown,
        });

        const formGroupClasses = ComponentUtils.classNames("form-group", this.props.className);

        if (this.props.simple)
            return ComponentUtils.bindTo(
                <input
                    className={userClasses}
                    type="hidden"
                    data-bind={{
                        attr: { readonly: s2.ReadOnly, style: s2.Styles, tabindex: s2.TabIndex },
                        idValue: s2.Value,
                        select2SetValueFromField: s2.Value,
                        select2: {
                            allowClear: s2.AllowClear,
                            placeholder: s2.Placeholder,
                            query: s2.find.bind(s2),
                            multiple: s2.Multiple,
                            initSelection: s2.initSelection.bind(s2),
                            escapeMarkup: function (m) {
                                return m;
                            },
                            formatResult: s2.formatResult.bind(s2),
                            formatSelection: s2.formatSelection.bind(s2),
                            onSelecting: s2.OnSelecting.bind(s2),
                            onSelectionChange: s2.OnSelectionChange.bind(s2),
                            onClearing: s2.OnClearing.bind(s2),
                            onClear: s2.OnClear.bind(s2),
                        },
                        hasFocus: s2.HasFocus,
                        css: s2.CssClass,
                    }}
                    ref={(ref) => (this.inputRef = ref)}
                />,
                this.vm,
                "s2"
            );

        if (Array.isArray(this.props.children) && this.props.children.length > 0) {
            return ComponentUtils.bindTo(
                <div className={formGroupClasses}>
                    <label
                        className="control-label"
                        data-bind={{ text: s2.Label, visible: !!s2.Label(), css: s2.LabelCSS }}></label>
                    <div data-bind={{ css: s2.InputCSS }}>
                        <div class="input-group" style={{ tableLayout: "fixed", width: "100%" }}>
                            <input
                                className={userClasses}
                                type="hidden"
                                data-bind={{
                                    attr: { readonly: s2.ReadOnly, style: s2.Styles, tabindex: s2.TabIndex },
                                    idValue: s2.Value,
                                    select2SetValueFromField: s2.Value,
                                    select2: {
                                        allowClear: s2.AllowClear,
                                        placeholder: s2.Placeholder,
                                        query: s2.find.bind(s2),
                                        multiple: s2.Multiple,
                                        initSelection: s2.initSelection.bind(s2),
                                        escapeMarkup: function (m) {
                                            return m;
                                        },
                                        formatResult: s2.formatResult.bind(s2),
                                        formatSelection: s2.formatSelection.bind(s2),
                                        onSelecting: s2.OnSelecting.bind(s2),
                                        onSelectionChange: s2.OnSelectionChange.bind(s2),
                                        onClearing: s2.OnClearing.bind(s2),
                                        onClear: s2.OnClear.bind(s2),
                                    },
                                    hasFocus: s2.HasFocus,
                                    css: s2.CssClass,
                                }}
                                ref={(ref) => (this.inputRef = ref)}
                            />
                            <span
                                style="width: 37px"
                                class="input-group-btn"
                                data-bind="template: { nodes: $componentTemplateNodes, data: $parent }"></span>
                        </div>
                    </div>
                </div>,
                this.vm,
                "s2"
            );
        }

        return ComponentUtils.bindTo(
            <div class={formGroupClasses}>
                <label
                    className="control-label"
                    data-bind={{ text: s2.Label, visible: !!s2.Label(), css: s2.LabelCSS }}></label>
                <input
                    className={userClasses}
                    type="hidden"
                    data-bind={{
                        attr: { readonly: s2.ReadOnly, style: s2.Styles, tabindex: s2.TabIndex },
                        idValue: s2.Value,
                        select2SetValueFromField: s2.Value,
                        select2: {
                            allowClear: s2.AllowClear,
                            placeholder: s2.Placeholder,
                            query: s2.find.bind(s2),
                            multiple: s2.Multiple,
                            initSelection: s2.initSelection.bind(s2),
                            escapeMarkup: function (m) {
                                return m;
                            },
                            formatResult: s2.formatResult.bind(s2),
                            formatSelection: s2.formatSelection.bind(s2),
                            onSelecting: s2.OnSelecting.bind(s2),
                            onSelectionChange: s2.OnSelectionChange.bind(s2),
                            onClearing: s2.OnClearing.bind(s2),
                            onClear: s2.OnClear.bind(s2),
                        },
                        hasFocus: s2.HasFocus,
                        css: s2.CssClass,
                    }}
                    ref={(ref) => (this.inputRef = ref)}
                />
            </div>,
            this.vm,
            "s2"
        );
    }
}

ko.components.register("select2", {
    viewModel: {
        createViewModel: (params: ISelect2ComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new Select2Component(params);

            let templateFragment: string;
            const userClasses =
                classes.select2component +
                (ko.utils.unwrapObservable(params.NoFormControl) ? "" : " form-control") +
                (ko.unwrap(params.NoDropdown) ? " no-dropdown" : "");

            if (ko.utils.unwrapObservable(params.Simple)) {
                templateFragment =
                    '<input class="' +
                    userClasses +
                    '" type="hidden" data-bind="attr: { readonly: ReadOnly, style: Styles, tabindex: TabIndex }, idValue: Value, select2SetValueFromField: Value, select2: { allowClear: AllowClear, placeholder: Placeholder, query: find.bind($data), multiple: Multiple, initSelection: initSelection.bind($data), escapeMarkup: function(m) { return m; }, formatResult: formatResult.bind($data), formatSelection: formatSelection.bind($data), onSelecting: OnSelecting.bind($data), onSelectionChange: OnSelectionChange.bind($data), onClearing: OnClearing.bind($data), onClear: OnClear.bind($data) }, hasFocus: HasFocus , css: CssClass">';
            } else {
                if (componentInfo.templateNodes.length > 0) {
                    templateFragment =
                        '<div class="form-group">' +
                        '<label class="control-label" data-bind="text: Label, visible: Label, css: LabelCSS"></label>' +
                        '<div data-bind="css: InputCSS">' +
                        '<div class="input-group" style="table-layout: fixed; width: 100%">' +
                        '<input class="' +
                        userClasses +
                        '" type="hidden" data-bind="attr: { readonly: ReadOnly, style: Styles, tabindex: TabIndex }, idValue: Value, select2SetValueFromField: Value, select2: { allowClear: AllowClear, placeholder: Placeholder, query: find.bind($data), multiple: Multiple, initSelection: initSelection.bind($data), escapeMarkup: function(m) { return m; }, formatResult: formatResult.bind($data), formatSelection: formatSelection.bind($data), onSelecting: OnSelecting.bind($data), onSelectionChange: OnSelectionChange.bind($data), onClearing: OnClearing.bind($data), onClear: OnClear.bind($data) }, hasFocus: HasFocus, css: CssClass">' +
                        '<span style="width: 37px" class="input-group-btn" data-bind="template: { nodes: $componentTemplateNodes, data: $parent }">' +
                        "</span>" +
                        "</div>" +
                        "</div>" +
                        "</div>";
                } else {
                    templateFragment =
                        '<div class="form-group">' +
                        '<label class="control-label" data-bind="text: Label, visible: Label, css: LabelCSS"></label>' +
                        '<input class="' +
                        userClasses +
                        '" type="hidden" data-bind="attr: { readonly: ReadOnly, style: Styles, tabindex: TabIndex }, idValue: Value, select2SetValueFromField: Value, select2: { allowClear: AllowClear, placeholder: Placeholder, query: find.bind($data), multiple: Multiple, initSelection: initSelection.bind($data), escapeMarkup: function(m) { return m; }, formatResult: formatResult.bind($data), formatSelection: formatSelection.bind($data), onSelecting: OnSelecting.bind($data), onSelectionChange: OnSelectionChange.bind($data), onClearing: OnClearing.bind($data), onClear: OnClear.bind($data) }, hasFocus: HasFocus, css: CssClass">' +
                        "</div>";
                }
            }

            ko.virtualElements.setDomNodeChildren(componentInfo.element, ko.utils.parseHtmlFragment(templateFragment));

            return vm;
        },
    },
    template: [],
});
