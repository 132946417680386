import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 01/03/13
 * Time: 16.38
 * To change this template use File | Settings | File Templates.
 */

export class WarehouseSection
{
    public SectionId : number = -1;
    public Selected : boolean;

    constructor(public title : string, private link : string, id : number, selected : boolean, public icon : string)
    {
        this.Selected = selected;
        this.SectionId = id;
    }

    public edit()
    {
        location.href = this.link;
    }
}