import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IMonthlyHour } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { SplashPageDetailsDialog } from "../../../../JobOrder/jobOrder/ui/splash-page/SplashPageDetailsComponent";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ISelectableDate } from "./IDate";
import { HourApprovalViewModel } from "./HourApprovalViewModel";
import { TaskApprovalViewModel } from "./TaskApprovalViewModel";
import { _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";
import { HoursContainer } from "./HoursContainer";
import { ResourceApprovalViewModel } from "./ResourceApprovalViewModel";


export class JobOrderApprovalViewModel extends HoursContainer<TaskApprovalViewModel> {
    Collapsed: ko.Observable<boolean> = ko.observable(true);

    HasSelectedChildren: ko.Computed<boolean>;

    private resourceId: number;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(hour: IMonthlyHour, private dates: ISelectableDate[], resource: ResourceApprovalViewModel, panel: _HoursApprovalPanelViewModel) {
        super(panel, resource);

        this.id = hour.JobOrderId;
        this.type = ProlifeSdk.JobOrderEntityTypeCode;
        this.name = hour.JobOrderName || ProlifeSdk.TextResources.WorkedHours.NoWorkLabel;
        this.resourceId = hour.ResourceId;

        for (const date of this.dates) {
            const approval = new HourApprovalViewModel({
                id: this.id,
                date: date.Date,
                isHoliday: date.IsHoliday,
                resourceId: this.resourceId
            }, date, this, this.panel);

            this.Hours.push(approval);
        }
    }

    public initialize(): void {
        super.initialize();
        
        const key = this.getKeyForCache();
        const collapsedValue = this.panel.getCollapsedStateFromCache(key);
        if (collapsedValue != undefined)
            this.Collapsed(collapsedValue);

        const selectedValue = this.panel.getRowSelectionStateFromCache(key);
        this.Selected(selectedValue);

        this.Collapsed.subscribe((value: boolean) => {
            if (!this.id)
                return;

            this.panel.cacheCollapsedState(this.getKeyForCache(), value);
        });

        this.Selected.subscribe((value: boolean) => {
            if (!this.id || this.panel.IsLoading())
                return;

            this.panel.cacheRowSelection(this.getKeyForCache(), value);
        });
    }

    public toggleCollapsed() {
        if (!this.CanExpand())
            return;

        this.Collapsed(!this.Collapsed());
    }

    public async openJobOrderDetailedInfo(): Promise<void> {
        return this.dialogsService.ShowModal(new SplashPageDetailsDialog({ Title: String.format(TextResources.JobOrder.DetailedInfoTitle, this.name), JobOrderId: this.id }));
    }

    public action() {
        return  this.id ?   <button type="button" className="btn btn-xs btn-primary" onClick={() => this.openJobOrderDetailedInfo() }>
                                <i className="fa fa-info-circle"></i>
                            </button>
                        :   <></>;
    }
}
