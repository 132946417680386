import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/07/2018
 * Time: 15:16
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";

export class TrustAuthorizationEvent extends BlogEvent implements IView {
    constructor (public serviceLocator: IServiceLocator, public contextEventsObserver : IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);

        this.Updatable(false);

        this.iconClass = "fa-legal";
        this.eventTypeName = ProlifeSdk.TextResources.Invoices.TrustAutorization;
        this.templateName = "thrust-authorization-event";
        this.templateUrl = "invoices/templates/events";
        this.isDeleteLocked(true);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody()
            .then((b : string) => {

                var body = b;
                def.resolve(body);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    public GetMailSubject() : string
    {
        return ProlifeSdk.TextResources.Invoices.TrustAutorizationMailSubject;
    }
}