import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IArticlesService, IArticle } from "../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class ArticleText {
    update(element: any, valueAccessor: () => any): void {
        const value = ko.unwrap(valueAccessor());
        ArticleText.refreshContent(element, value);
    }

    private static refreshContent(element: any, id: number) {
        $(element).text("");

        if (id == null || id == undefined) return;

        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        articlesService.getArticleByCatalogRowId(id).then((article: IArticle) => {
            const item = ArticleValue.transformArticle(article);
            $(element).text(item.FullName);
        });
    }
}

export class ArticleValue {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        const instance = {};

        (<any>$(element))
            .typeahead(
                {
                    items: 1000,
                    minLength: 1,
                    menu: '<ul class="typeahead dropdown-menu"></ul>',
                    item: '<li><a href="#"></a></li>',
                },
                {
                    display: "FullName",
                    source: ArticleValue.findArticles.bind(null, instance, articlesService),
                    limit: 1000,
                    templates: {
                        suggestion: function (json) {
                            const item = JSON.parse(json);
                            return "<div>" + item.FullName + "</div>";
                        },
                    },
                }
            )
            .on("typeahead:selected", (event, json) => {
                const item = JSON.parse(json);
                valueAccessor()(item.id);
                return item.FullName;
            });

        ko.computed(() => {
            const value = ko.utils.unwrapObservable(valueAccessor());
            if (value <= 0) {
                (<any>$(element)).typeahead("val", "");
            }
        });
    }

    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const newValue = ko.utils.unwrapObservable(valueAccessor());

        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        if (!newValue) {
            $(element).val("");
            return;
        }

        articlesService.getArticleByCatalogRowId(newValue).then((article: IArticle) => {
            const item = ArticleValue.transformArticle(article);
            $(element).val(item.FullName);
        });
    }

    private static findArticles(
        _this: any,
        articlesService: IArticlesService,
        query: string,
        process: (items: any[]) => any,
        asyncProcess: (items: any[]) => any
    ) {
        if (_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(function () {
            _this.lastTimeout = 0;
            articlesService.getArticles(query, null, 0, 10).then((articles: IArticle[]) => {
                return asyncProcess(ArticleValue.transformArticles(articles));
            });
        }, 500);
    }

    private static transformArticles(articles: IArticle[]): any[] {
        return articles.map(ArticleValue.transformArticle);
    }

    public static transformArticle(article: IArticle): any {
        return {
            id: article.Id,
            FullName: article.Code + " " + article.Description,
            toString: function () {
                return JSON.stringify(this);
            },
            toLowerCase: function () {
                return this.FullName.toLowerCase();
            },
            indexOf: function () {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function () {
                return String.prototype.replace.apply(this.FullName, arguments);
            },
        };
    }
}

export class ArticleIdValue {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        const instance = {};

        (<any>$(element))
            .typeahead(
                {
                    items: 1000,
                    minLength: 1,
                    menu: '<ul class="typeahead dropdown-menu"></ul>',
                    item: '<li><a href="#"></a></li>',
                },
                {
                    display: "Code",
                    source: ArticleIdValue.findArticles.bind(null, instance, articlesService),
                    limit: 1000,
                    templates: {
                        suggestion: function (json) {
                            const item = JSON.parse(json);
                            return "<div>" + item.Code + "<br/><small>" + item.Description + "</small></div>";
                        },
                    },
                }
            )
            .on("typeahead:selected", (event, json) => {
                const item = JSON.parse(json);
                valueAccessor()(item.id);
                return item.Code;
            });

        ko.computed(() => {
            const value = ko.utils.unwrapObservable(valueAccessor());
            if (value <= 0) {
                (<any>$(element)).typeahead("val", "");
            }
        });
    }

    update(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const newValue = ko.utils.unwrapObservable(valueAccessor());

        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        if (!newValue) {
            $(element).val("");
            return;
        }

        articlesService.getArticleByArticleId(newValue).then((article: IArticle) => {
            const item = ArticleIdValue.transformArticle(article);
            $(element).val(item.Code);
        });
    }

    private static findArticles(
        _this: any,
        articlesService: IArticlesService,
        query: string,
        process: (items: any[]) => any,
        asyncProcess: (items: any[]) => any
    ) {
        if (_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        _this.lastTimeout = setTimeout(function () {
            _this.lastTimeout = 0;
            articlesService.getArticles(query, null, 0, 10).then((articles: IArticle[]) => {
                return asyncProcess(ArticleIdValue.transformArticles(articles));
            });
        }, 500);
    }

    private static transformArticles(articles: IArticle[]): any[] {
        return articles.map(ArticleIdValue.transformArticle);
    }

    public static transformArticle(article: IArticle): any {
        return {
            id: article.ArticleId,
            Code: article.Code,
            Description: article.Description,
            FullName: article.Code + " " + article.Description,
            toString: function () {
                return JSON.stringify(this);
            },
            toLowerCase: function () {
                return this.FullName.toLowerCase();
            },
            indexOf: function () {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function () {
                return String.prototype.replace.apply(this.FullName, arguments);
            },
        };
    }
}

export class ArticleIdText {
    init(element: any, valueAccessor: () => any): void {
        ArticleIdText.refreshContent(element, valueAccessor()());
    }

    update(element: any, valueAccessor: () => any): void {
        ArticleIdText.refreshContent(element, valueAccessor()());
    }

    private static refreshContent(element: any, id: number) {
        $(element).text("");

        if (id == null || id == undefined) return;

        const articlesService = <IArticlesService>Core.serviceLocator.findService(ProlifeSdk.ArticlesServiceType);
        articlesService.getArticleByArticleId(id).then((article: IArticle) => {
            $(element).text(article.Code);
        });
    }
}

ko.bindingHandlers["articleValue"] = new ArticleValue();
ko.bindingHandlers["articleIdValue"] = new ArticleIdValue();
ko.bindingHandlers["articleIdText"] = new ArticleIdText();
ko.bindingHandlers["articleText"] = new ArticleText();
