import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/03/2019
 * Time: 18:13
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk";
import { IDocumentJournalExtendedRow } from "../../interfaces/prolife-sdk/IDocumentsJournalRow";

export class DocumentsJournalExtendedRowViewModel {
    public Id: string;
    public DocumentId: number;
    public DocumentType: string;
    public DocumentDate: Date;
    public DocumentNumber: string;
    public ProtocolId: number;
    public ProtocolProgressiveNumber: number;
    public FiscalYearId: number;
    public CustomerId: number;
    public CustomerName: string;
    public DocumentLabel: string;
    public ProtocolName: string;

    public Draggable: ko.Observable<boolean> = ko.observable(false);

    constructor(private row: IDocumentJournalExtendedRow) {
        this.Id = this.row.Id;
        this.DocumentId = this.row.DocumentId;
        this.DocumentType = this.row.DocumentType;
        this.DocumentDate = this.row.DocumentDate;
        this.DocumentNumber = this.row.DocumentNumber;
        this.ProtocolId = this.row.ProtocolId;
        this.ProtocolProgressiveNumber = this.row.ProtocolProgressiveNumber;
        this.FiscalYearId = this.row.FiscalYearId;
        this.CustomerId = this.row.CustomerId;
        this.CustomerName = this.row.CustomerName;
        this.DocumentLabel = this.row.DocumentLabel;
        this.ProtocolName = this.row.ProtocolName;
    }
}