import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/06/2017
 * Time: 18:21
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { SplashPage } from "./SplashPage";
import { Questionnaire } from "../questionnaires/Questionnaire";
import { QuestionnaireEditDialog } from "../questionnaires/questionnaire-edit/dialogs/QuestionnaireEditDialog";
import { QuestionnaireCategoriesEntityProvider } from "../questionnaires/entityProviders/QuestionnaireCategoriesEntityProvider";
import { QuestionnaireStatesEntityProvider } from "../questionnaires/entityProviders/QuestionnaireStatesEntityProvider";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISurveyService } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import {
    IQuestionnaire,
    IQuestionnaireSplashPageFilters,
} from "../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";

export class QuestionnaireSplashPageList {
    public templateName: string = "questionnaire-splash-page";
    public templateUrl: string = "survey/templates/questionnaire";

    public NavBarActions = [];
    public HintSearchProvider: IControlsCustomEntitiesProvider;

    private LockManager: EntitiesListLockManager;

    private Questionnaires: ko.ObservableArray<QuestionnaireForSplash> = ko.observableArray([]);

    private desktopService: IDesktopService;
    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;
    private entitiesProviderService: IEntityProviderService;

    private hintSearchGroupsInfo: IHintSearchGroupInfo[] = [];

    private canLoadData: boolean;

    constructor(private serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.surveyService = <ISurveyService>serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.entitiesProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );

        this.LockManager = new EntitiesListLockManager(this.Questionnaires, ProlifeSdk.SurveyEntityTypeCode);

        this.canLoadData = true;

        this.NavBarActions = [
            {
                Name: ko.observable(ProlifeSdk.TextResources.Survey.NewQuestionnaire),
                CanRun: ko.observable(true),
                Icon: ko.observable("fa-plus"),
                Run: this.NewQuestionnaire.bind(this),
            },
        ];

        this.hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.Survey.QuestionnaireStatus,
            Provider: new QuestionnaireStatesEntityProvider(this.serviceLocator),
            GroupCode: "S",
        });

        this.hintSearchGroupsInfo.push({
            Description: ProlifeSdk.TextResources.Survey.QuestionnaireCategory,
            Provider: new QuestionnaireCategoriesEntityProvider(this.serviceLocator),
            GroupCode: "C",
        });

        this.HintSearchProvider = this.entitiesProviderService.GetCustomEntitiesProviderForControls(
            this.hintSearchGroupsInfo,
            true,
            false
        );

        //this.Refresh();
    }

    public GetHintSearchProvider(): IControlsCustomEntitiesProvider {
        return this.HintSearchProvider;
    }

    public dispose() {
        this.LockManager.Dispose();
    }

    Refresh() {
        this.Questionnaires([]);
        this.LoadNextBlock();
        this.LoadCount();
        this.RefreshNavbarActions();
    }

    LoadNextBlock() {
        //Prima di caricare i dati aspetto che il lock manager sia pronto...
        if (this.canLoadData) {
            this.canLoadData = false;
            this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);
            this.LockManager.WhenIsReady().then(() => {
                this.surveyService
                    .getQuestionnaireListForSplash(this.GetFilters(), this.Questionnaires().length, 30)
                    .then((questionnaires: IQuestionnaire[]) => {
                        const newList = this.Questionnaires();
                        questionnaires
                            .map((j: IQuestionnaire) => {
                                return this.CreateViewModelFor(j);
                            })
                            .forEach((j) => {
                                newList.push(j);
                            });
                        this.Questionnaires(newList);
                    })
                    .finally(() => {
                        this.desktopService.UnblockPageUI();
                        setTimeout(() => {
                            this.canLoadData = true;
                        }, 500);
                    });
            });
        }
    }

    LoadCount() {
        this.surveyService.getQuestionnaireListForSplashCount(this.GetFilters()).then((count: number) => {
            this.parent.ListElementsCount(count);
        });
    }

    NewQuestionnaire() {
        const editQuestionnaireDialog = new QuestionnaireEditDialog(this.serviceLocator, null);
        this.dialogService.ShowModal<Questionnaire>(editQuestionnaireDialog).then((questionnaire: Questionnaire) => {
            this.parent.SurveyPanel.EditQuestionnaire(questionnaire.Id);
        });
    }

    RefreshNavbarActions(): void {
        this.parent.setNavBarActions(this.NavBarActions);
    }

    private GetFilters(): IQuestionnaireSplashPageFilters {
        const filters: IItemIdWithTypeAndGroupCode[] = this.parent
            .HintSearchProvider()
            .extractItemsAndTypesFromStringValue(this.parent.SearchFilters());

        const words: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "$$HINTWORD$$";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });

        const statesIds: number[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "S";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });

        return <IQuestionnaireSplashPageFilters>{
            TextFilters: words,
            StatesIds: statesIds,
        };
    }

    /*public GetFilters() : void //IJobOrdersSplashPageFilters
    {
         var filters : IItemIdWithTypeAndGroupCode[] = this.HintSearchProvider.extractItemsAndTypesFromStringValue(this.SearchFilters());
         var words : string[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "$$HINTWORD$$"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
         var responsiblesIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "R"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
         var customersIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "C"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
         var statesIds : number[] = filters.filter((f : IItemIdWithTypeAndGroupCode) => { return f.GroupCode == "S"; }).map((f : IItemIdWithTypeAndGroupCode) => { return f.Id; } );
         var navigationFilters : INavigationFilters = this.navigationMenu.GetFiltersFromActualPosition();
         var statesFilters : INavigationFilters = this.stateNavigationMenu.GetFiltersFromActualPosition();

         navigationFilters.LogicalState = statesFilters.LogicalState;
         navigationFilters.State = statesFilters.State;

         return {
         TextFilters : words,
         ResponsiblesIds : responsiblesIds,
         CustomersIds : customersIds,
         StatesIds : statesIds,
         TypeId : navigationFilters.Type,
         LogicalStateId : navigationFilters.LogicalState,
         StateId : navigationFilters.State
         };
    }*/

    private CreateViewModelFor(j: IQuestionnaire) {
        return new QuestionnaireForSplash(this.serviceLocator, j, this);
    }
}

export class QuestionnaireForSplash implements ILockableItem {
    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

    public DetailsUrl: ko.Observable<string> = ko.observable();

    public QuestionnaireIdentifier: ko.Computed<string>;

    constructor(
        serviceLocator: IServiceLocator,
        public questionnaire: IQuestionnaire,
        private parent: QuestionnaireSplashPageList
    ) {
        this.ItemKey = questionnaire.Id;
        this.QuestionnaireIdentifier = ko.computed(() => {
            return this.questionnaire.Title + " - Ver. " + this.questionnaire.Version;
        });

        this.DetailsUrl(
            "#/" +
                ProlifeSdk.TextResources.Survey.SurveyUrl +
                "/" +
                ProlifeSdk.TextResources.Survey.QuestionnaireUrl +
                "/" +
                this.questionnaire.Id
        );
    }

    public OpenQuestionnaire() {
        location.href = this.DetailsUrl();
    }
}
