import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IReminderService } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { Deferred } from "../../../../Core/Deferred";

export class JobOrderRefUiForRemind {
    private remindService : IReminderService;
    private jobOrderService : IJobOrderService;

    public entityType: string = ProlifeSdk.JobOrderEntityTypeCode;
    public templateUrl : string = "reminder/templates/references";
    public templateName : string = "job-order";

    public jobOrder : IDropDownList;

    constructor(private serviceLocator) {
        this.remindService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.jobOrder = this.jobOrderService.getJobOrdersDropDown();
        this.remindService.registerObjReferenceUi(this);
    }

    public initialize(key: IEntityRefKey) : Promise<void> {
        this.jobOrder.selectedId(key.EntityKeyId);
        return Promise.resolve<void>(undefined);
    }

    public getSelectedEntityKey(): IEntityRefKey{
        return {
            EntityKeyType : this.entityType,
            EntityKeyId : this.jobOrder.selectedId()
        }
    }

    public getSelectedEntityPathDescription(): Promise<string> {
        var def = new Deferred<string>();

        if(!this.jobOrder.selectedId())
            return def.resolve(null).promise();

        this.jobOrder.getSelected(null, (jobOrder) => {
            def.resolve(jobOrder.text || "N/A");
        });

        return def.promise();
    }
}