import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DocumentRefDetailsViewModelFactory } from "../../../ProlifeSdk/prolifesdk/documents/DocumentRefDetailsViewModelFactory";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IProvisioningService } from "../../ProvisioningService";
import * as Core from "../../../Core/Core";
import { IReferenceDetailsViewModelFactory } from "../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";

export class RequestForQuotationDocumentRefDetailsViewModelFactory extends DocumentRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.Provisioning.RequestsForQuotation;
    }
}