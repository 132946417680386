import * as ko from "knockout";
﻿/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/06/2018
 * Time: 16:09
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ITrustAuthorizationProcessService } from "../../../Invoices/TrustAuthorizationProcessService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationForHistory, IRequestStatesMap } from "../../../ProlifeSdk/interfaces/invoice/ITrustAuthorizationProcessService";

export class TrustAuthorizationsHistory {
    public AuthorizationRequests: ko.ObservableArray<IAuthorizationForHistory> = ko.observableArray([]);

    public EmptyList: ko.Computed<boolean>;

    private isLoading: boolean = false;
    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationsProcessService: ITrustAuthorizationProcessService;

    private requestStatesMap: IRequestStatesMap = {};

    constructor(private customerId: number) {
        this.EmptyList = ko.computed(() => {
            return this.AuthorizationRequests().length == 0;
        });

        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestNotSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestNotSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestSent] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestSentLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestIgnored] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestIgnoredLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestDenied] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestDeniedLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestOnEvaluation] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestOnEvaluationLabel;
        this.requestStatesMap['_' + ProlifeSdk.TrustAuthorizationRequestAccepted] = ProlifeSdk.TextResources.Invoices.TrustAuthorizationRequestAcceptedLabel;

        this.LoadNextBlock();
    }

    public LoadNextBlock(): void {
        if (this.isLoading)
            return;

        this.isLoading = true;

        this.trustAuthorizationsProcessService.GetPagedTrustAuthorizationsHistoryForCustomer(this.customerId, this.AuthorizationRequests().length, 60)
            .then((authorizations: IAuthorizationForHistory[]) => {
                var auths = this.AuthorizationRequests();
                auths = auths.concat(authorizations);
                this.AuthorizationRequests(auths);
                setTimeout(() => { this.isLoading = false; }, 500);
            })
            .catch(() => {
                setTimeout(() => { this.isLoading = false; }, 500);
            });

    }

    public Reload(): Promise<void | IAuthorizationForHistory[]> {
        return this.trustAuthorizationsProcessService.GetPagedTrustAuthorizationsHistoryForCustomer(this.customerId, 0, 60)
            .then((authorizations: IAuthorizationForHistory[]) => {
                this.AuthorizationRequests(authorizations);
            });
    }
}