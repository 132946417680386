import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/06/13
 * Time: 15.01
 * To change this template use File | Settings | File Templates.
 */

export class Toggle {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var $element, observable;
        observable = valueAccessor();
        var activeClass : string = ko.utils.unwrapObservable(allBindingsAccessor()["toggleActiveClass"]) || "active";

        if (!ko.isWriteableObservable(observable)) {
            throw "You must pass an observable or writeable computed";
        }
        $element = $(element);
        $element.on("click", function() {
            observable(!observable());
            return false;
        });
        ko.computed({
            disposeWhenNodeIsRemoved: element,
            read: function() {
                $element.toggleClass(activeClass, observable());
            }
        });
    }
}

export class ToggleEx {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const observable = valueAccessor();
        if (!ko.isWriteableObservable(observable)) {
            throw "You must pass an observable or writeable computed";
        }
        
        const $element = $(element);
        $element.on("click.toggleEx", function() {
            observable(!observable());
            return false;
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $element.off("click.toggleEx");
        });
    }
}

ko.bindingHandlers['toggle'] = new Toggle();
ko.bindingHandlers['toggleEx'] = new ToggleEx();