import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 21/11/2018
 * Time: 17:57
 * To change this template use File | Settings | File Templates.
 */

import { IProxy } from "../../interfaces/IProxy";
import { Deferred } from "../../../Core/Deferred";

export enum ProxyState {
    Idle = 0,
    Working = 1
}

export class Proxy<T> implements IProxy<T> {
    private resourcesMap: any = {};
    private callsMap: any = {};

    private activeCalls: number = 0;

    public State: ko.Observable<ProxyState> = ko.observable(ProxyState.Idle);

    constructor(private resourceType: string, private getter: (...args: any[]) => Promise<T>, private getterThis: any, private cacheValidity: number = -1) {
    }
    
    
    public get(...getterParams: any[]): Promise<T> {
        var def = new Deferred<T>();

        var resourceKey = getterParams.reduce((acc, current) => { 
            acc += "_" + (!current ? "" : current.toString()); 
            return acc; 
        }, "");

        if (!!this.resourcesMap[resourceKey])
            return def.resolve(this.resourcesMap[resourceKey]).promise();

        if (!!this.callsMap[resourceKey])
            return this.callsMap[resourceKey];

        this.callsMap[resourceKey] = def;
        this.activeCalls++;
        this.State(ProxyState.Working);

        this.getter.apply(this.getterThis, getterParams)
            .then((result: T) => {
                this.resourcesMap[resourceKey] = result;
                def.resolve(result);

                if (this.cacheValidity >= 0)
                    setTimeout(() => this.clearCache(resourceKey), this.cacheValidity);
            })
            .catch(() => {
                def.reject();
            })
            .finally(() => {
                delete this.callsMap[resourceKey];
                this.activeCalls--;

                if (this.activeCalls <= 0)
                    this.State(ProxyState.Idle);
            });

        return def.promise();
    }

    public clear() {
        this.callsMap = {};
        this.resourcesMap = {};
    }

    public dispose(): void {
        this.callsMap = undefined;
        this.resourcesMap = undefined;
        this.resourceType = undefined;
        this.getter = undefined;
        this.getterThis = undefined;
    }

    private clearCache(resourceKey: string): void {
        delete this.resourcesMap[resourceKey];
    }
}