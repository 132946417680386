import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IDataSourceDefinition, IDataSourceInput, IDataSourcesService } from "../../../../Desktop/DataSourcesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { Layout } from "../../../Layouts";
import { List, ListItem } from "../../../ListComponent";
import { Table } from "../../../TableComponent/TableComponent";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { GetAllQueryTypes, GetAllQueryTypesQuery, GetAllQueryTypesQueryVariables, GetTypeSchema, GetTypeSchemaQuery, GetTypeSchemaQueryVariables, __TypeKind } from "../../../../generated/graphql";

const styleSheet = jss.createStyleSheet({
    reportDataSourceDefinitionViewer: {
        "&.list-notification-container .list-notification.list-notification-fit .list-notification-item": {
            borderLeft: '1px solid #e5e5e5',
            borderTop: '0px',
            borderRight: '1px solid #e5e5e5',
            borderBottom: '1px solid #e5e5e5',
            fontSize: '12px',
            padding: '0px 5px',

            "&:first-of-type": {
                borderTop: '1px solid #e5e5e5',
            },

            "& .list-notification-item-details": {
                "& .list-notification-item-title": {
                    margin: 0,
                },

                "& .list-notification-item-time": {
                    fontSize: '0.9em'
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDataSourcesViewerProps = {
    inputDefinition: IDataSourceInput[];
    onDataSourceSelected?: (dataSource: IDataSourceDefinition) => void;
}

export function ReportDataSourcesViewer(props: ReportDataSourcesViewerProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./ReportDataSourcesViewer")._ReportDataSourcesViewer as typeof _ReportDataSourcesViewer;
    return <C {...props} />;
}

export class _ReportDataSourcesViewer implements IDataSourceListener {
    static defaultProps: Partial<ReportDataSourcesViewerProps> = {
    }

    @LazyImport(nameof<IDataSourcesService>())
    private dataSourcesService : IDataSourcesService;

    dataSources : ko.ObservableArray<IDataSourceDefinition> = ko.observableArray();

    constructor(private props : ReportDataSourcesViewerProps) {
        
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(model && this.props.onDataSourceSelected)
            this.props.onDataSourceSelected(model.model);
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    componentDidMount() {
        const loadDataSources = async () => {
            const dataSources = this.dataSourcesService.getDataSources(this.props.inputDefinition);
            this.dataSources(dataSources);
        };
        loadDataSources();
    }

    render() {
        const dataSource = Table.defaultDataSource(this.dataSources, (item) => ({ id: item.Name, title: item.Name, subTitle: item.Description, icon: { icon: "fa fa-list-alt" } }));

        return  <Layout.ScrollContainer systemScrollable>
                    <List scrollable={false} className={classNames("flex-1", classes.reportDataSourceDefinitionViewer)} dataSource={dataSource} itemRenderer={(item) => this.renderItem(item)} listener={this} />
                </Layout.ScrollContainer>;
    }

    renderItem(item: ListItem<string, IDataSourceDefinition>): React.ReactElement {
        return  <div className="flex-container flex-child-center" onClick={() => item.Select()}>
                    <div data-bind={{ style: { backgroundColor: item.Background } }}>
                        <i data-bind={{ css: item.Icon, style: { color: item.Foreground } }} />
                    </div>
                    <div className="flex-1">
                        <div class="list-notification-item-title" data-bind={{ text: item.Title }}></div>
                        <div class="list-notification-item-time" data-bind={{ text: item.Subtitle }}></div>
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDataSourcesViewer);
}