import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 01/03/13
 * Time: 16.28
 * To change this template use File | Settings | File Templates.
 */

import { WarehouseSection } from "./WarehouseSection";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";

export interface SectionInfo
{
    Name : string;
    Url : string;
    Id : number;
    Icon : string;
}

export class WarehouseSectionGroup
{
    public links : any[] = [];
    public isEmpty : boolean = false;

    constructor(public title : string, links : SectionInfo[], selectedSectionId : number)
    {
        this.links = links.map((si : SectionInfo) => { return new WarehouseSection(si.Name, si.Url, si.Id, selectedSectionId == si.Id, si.Icon)});
    }

    addLink(si : SectionInfo, selectedSectionId : number): void {
        this.links.push(new WarehouseSection(si.Name, si.Url, si.Id, selectedSectionId == si.Id, si.Icon));
    }
}