import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { BusinessManagementsTransactionsMenu } from "./BusinessManagementTransactionsMenu";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IHumanResource, IHumanResourcesService } from "../../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { ResourcesDataSource } from "../../../../DataSources/ResourcesDataSource";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ResourceRoleAndCategorySelector } from "../../ui2019/ResourceRoleAndCategorySelector";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IWorkSheet, IWorkSheetRow } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import {
    IWorkedHoursService,
    IValidRolesForServiceOrder,
    IValidRole,
    IValidWorkTimeCategory,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IWorkedHoursMonthlyReportRecord } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursMonthlyReportRecord";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IJobOrderForHint, IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class WorkedHoursMonthlyReport extends ProLifeReport implements IReport, IReportViewModel {
    Name: string = ProlifeSdk.TextResources.WorkedHours.WorkedHoursReport;
    templateName = "report-list-item";
    templateUrl = "reports/templates";
    detailsTemplateName = "worked-hours-monthly-report";
    detailsTemplateUrl = "workedhours/templates/reports";
    private navigator: IReportsNavigator;
    viewModel: any;

    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IHumanResourcesService>())
    resourcesService: IHumanResourcesService;
    @LazyImport(nameof<IInfoToastService>())
    toastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    dialogService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    desktopService: IDesktopService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    CurrentYear: ko.Observable<number> = ko.observable(1);
    CurrentMonth: ko.Observable<number> = ko.observable(1); //0=Gennaio
    CurrentMonthName: ko.Computed<string>;
    NumberOfDaysInMonth: ko.Computed<number[]>;
    Resources: ko.ObservableArray<ResourceMonthlyReport> = ko.observableArray([]);

    WorkTimeCategories: IWorkTimeCategory[];
    CallRightCategories: ICallRightType[];
    Years: number[] = [];
    Months: any[] = [];
    IsMonthLocked: ko.Observable<boolean> = ko.observable(false);

    ShowVariations: ko.Observable<boolean> = ko.observable(true);

    InsertConsole: WorkedHoursMonthReportInsertConsole;
    BusinessManagementsMenu: BusinessManagementsTransactionsMenu;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCatsManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightCatsManager: ICallRightTypesSettingsManager;

    constructor(groupId: number) {
        super(groupId, 1);

        this.WorkTimeCategories = this.workTimeCatsManager.getAll(true);
        this.CallRightCategories = this.callRightCatsManager
            .getAll(true)
            .sort((a: ICallRightType, b: ICallRightType) => {
                if ((a.Name ? a.Name : "").toUpperCase() < (b.Name ? b.Name : "").toUpperCase()) return -1;
                if ((a.Name ? a.Name : "").toUpperCase() > (b.Name ? b.Name : "").toUpperCase()) return 1;
                return 0;
            });

        this.InsertConsole = new WorkedHoursMonthReportInsertConsole(this);
        this.BusinessManagementsMenu = new BusinessManagementsTransactionsMenu(
            this.CurrentYear,
            this.CurrentMonth,
            this
        );

        this.NumberOfDaysInMonth = ko.computed(() => {
            const date = moment(new Date()).year(this.CurrentYear()).month(this.CurrentMonth());
            const daysArray = [];
            const numberOfDays = date.daysInMonth();
            for (let d = 1; d < numberOfDays + 1; d++) {
                date.date(d);
                daysArray.push({
                    Value: d,
                    ShortName: date.format("ddd"),
                });
            }
            return daysArray;
        });

        const currentYear = moment(new Date()).year();
        for (let y = currentYear; y > currentYear - 10; y--) {
            this.Years.push(y);
        }

        for (let m = 0; m < 12; m++) {
            this.Months.push({ Id: m, Name: moment().month(m).format("MMMM") });
        }

        this.CurrentMonthName = ko.computed(() => {
            const matches = this.Months.filter((m) => {
                return m.Id == this.CurrentMonth();
            });
            return matches.length == 0 ? "Seleziona..." : matches[0].Name;
        });

        this.CurrentYear.subscribe(this.NotifyFilterChange.bind(this));
        this.CurrentMonth.subscribe(this.NotifyFilterChange.bind(this));
        this.ShowVariations.subscribe(this.NotifyFilterChange.bind(this));
    }

    SwitchMonthLocked() {
        this.workedHoursService
            .SetLockStatusForMonth(this.CurrentYear(), this.CurrentMonth() + 1, !this.IsMonthLocked())
            .then(() => {
                this.IsMonthLocked(!this.IsMonthLocked());
                this.toastService.Success(
                    this.IsMonthLocked()
                        ? ProlifeSdk.TextResources.WorkedHours.LockedMonthlyHoursInsertion
                        : ProlifeSdk.TextResources.WorkedHours.UnlockedMonthlyHoursInsertion
                );
            });
    }

    ImportImbalanceFromPreviousMonth() {
        this.dialogService
            .ShowModal<void>(
                new ImportImbalanceDialog(this.CurrentYear(), this.CurrentMonth() + 1),
                null,
                null,
                "workedhours/templates/reports",
                "import-imbalance-dialog"
            )
            .then(() => {
                this.toastService.Success(ProlifeSdk.TextResources.WorkedHours.FlexibilityImported);
                this.Refresh();
            });
    }

    public ExportAsExcel(versionId: number) {
        const url =
            "WorkedHours/WorkedHoursMonthlyReportPrint/GenerateExcel?versionId=" +
            versionId +
            "&dayOfMonth=" +
            encodeURIComponent(moment().year(this.CurrentYear()).month(this.CurrentMonth()).date(1).format());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    SwitchVariationsVisibility() {
        this.ShowVariations(!this.ShowVariations());
    }

    SetYear(year: number) {
        this.CurrentYear(year);
    }

    GetDayName(day: number) {
        return moment(new Date()).year(this.CurrentYear()).month(this.CurrentMonth()).date(day).format("ddd");
    }

    SetMonth(month: number) {
        this.CurrentMonth(month);
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        if (this.CurrentMonth() == null || this.CurrentYear() == null) {
            this.Resources([]);
            return Promise.resolve();
        }

        const resourcesArray = [];
        this.workedHoursService
            .GetMonthlyHolidays(this.CurrentYear(), this.CurrentMonth() + 1)
            .then((holidays) => {
                this.resourcesService.GetHumanResources(null, true, true).then((resources: IHumanResource[]) => {
                    this.workedHoursService
                        .CalculateMonthlyReport(this.CurrentYear(), this.CurrentMonth() + 1)
                        .then((monthlyRecords: IWorkedHoursMonthlyReportRecord[]) => {
                            this.workedHoursService
                                .GetLockStatusForMonth(this.CurrentYear(), this.CurrentMonth() + 1)
                                .then((locked: boolean) => {
                                    this.IsMonthLocked(locked);

                                    resources.sort((a: IHumanResource, b: IHumanResource) => {
                                        if (
                                            (a.Resource.Surname ? a.Resource.Surname : "").toUpperCase() <
                                            (b.Resource.Surname ? b.Resource.Surname : "").toUpperCase()
                                        )
                                            return -1;
                                        if (
                                            (a.Resource.Surname ? a.Resource.Surname : "").toUpperCase() >
                                            (b.Resource.Surname ? b.Resource.Surname : "").toUpperCase()
                                        )
                                            return 1;
                                        return 0;
                                    });

                                    resources.forEach((resource: IHumanResource) => {
                                        if (resource.Resource.Deleted) return;

                                        const resourceRecords: IWorkedHoursMonthlyReportRecord[] =
                                            monthlyRecords.filter((mr: IWorkedHoursMonthlyReportRecord) => {
                                                return mr.ResourceId == resource.Resource.Id;
                                            });

                                        if (resourceRecords.length == 0) return;

                                        resourcesArray.push(
                                            new ResourceMonthlyReport(
                                                resource,
                                                holidays,
                                                resourceRecords,
                                                this.NumberOfDaysInMonth(),
                                                this.WorkTimeCategories,
                                                this.CallRightCategories,
                                                this.ShowVariations(),
                                                this.InsertConsole,
                                                this.CurrentYear(),
                                                this.CurrentMonth()
                                            )
                                        );
                                    });
                                    this.Resources(resourcesArray);

                                    const businessRecords = monthlyRecords.filter(
                                        (r: IWorkedHoursMonthlyReportRecord) => {
                                            return r.BusinessManagement;
                                        }
                                    );
                                    this.BusinessManagementsMenu.refresh();
                                });
                        });
                });
            })
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    initialize() {
        super.initialize();
        const today = moment(new Date());
        this.CurrentYear(today.year());
        this.CurrentMonth(today.month());
        this.InsertConsole.initialize();
        this.Refresh();
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}

class BusinessManagmentJobOrdersControlsEntityProvider implements IControlsEntityProvider {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor() {}

    public findEntities(query: any) {
        if (this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {
            this.jobOrderService.hintSearch(query.term, 2).then((data) => {
                query.callback({
                    results: data.map((jobOrder: IJobOrderForHint) => {
                        return {
                            id: jobOrder.Id,
                            text: jobOrder.Name,
                        };
                    }),
                });
            });
        }, 500);
    }

    public findEntity(element, callback) {
        const id = parseInt($(element).val() as string);
        if (!isNaN(id) && id > 0) {
            this.jobOrderService.get(id).then((jobOrder: IJobOrder) =>
                callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name,
                })
            );
        }
    }
}

export class ImportImbalanceDialog implements IDialog {
    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;

    public templateName: string;
    public templateUrl: string;
    public title: string = ProlifeSdk.TextResources.WorkedHours.PreviousMonthFlexibilityImport;
    public filter: ko.Observable<any> = ko.observable();
    modal: { close: (result?: any) => void };
    public jobOrdersSearchService: IControlsEntityProvider;
    public JobOrderId: ko.Observable<number> = ko.observable();

    constructor(private year: number, private month: number) {
        this.jobOrdersSearchService = new BusinessManagmentJobOrdersControlsEntityProvider();
    }

    close(): void {
        this.modal && this.modal.close();
    }

    action(): void {
        if (this.JobOrderId() == null || <any>this.JobOrderId() == "") {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.ChooseJobOrderForFlexibilityImport);
            return;
        }

        this.workedHoursService
            .ImportPreviousMonthImbalance(this.year, this.month, parseInt(<any>this.JobOrderId()))
            .then(() => {
                this.modal && this.modal.close(true);
            });
    }
}

export class WorkedHoursMonthReportInsertConsole implements IDataSourceListener {
    @LazyImport(nameof<IDialogsService>())
    dialogService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    infoToastService: IInfoToastService;
    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    SelectedResourceId: ko.Observable<number> = ko.observable();
    SelectedResource: ko.Observable<IHumanResource> = ko.observable();
    ResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();

    SelectedServiceOrderId: ko.Observable<number> = ko.observable();
    ServiceOrders: ko.ObservableArray<IValidRolesForServiceOrder> = ko.observableArray();

    SelectedRoleId: ko.Observable<number> = ko.observable();
    Roles: ko.ObservableArray<IValidRole> = ko.observableArray();

    SelectedCategoryId: ko.Observable<number> = ko.observable();
    WorkTimeCategories: ko.ObservableArray<IValidWorkTimeCategory> = ko.observableArray();

    SelectedJobOrderId: ko.Observable<number> = ko.observable();
    JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();

    Hours: ko.Observable<number> = ko.observable(0);
    Notes: ko.Observable<string> = ko.observable("");
    WorkDay: ko.Observable<Date> = ko.observable(new Date());
    private validator: IValidator<WorkedHoursMonthReportInsertConsole>;

    constructor(private parent: WorkedHoursMonthlyReport) {
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setCategoryFilter(2);

        this.validator = this.validationService
            .createValidator<WorkedHoursMonthReportInsertConsole>()
            .isNotNullUndefinedOrLessOrEqualZero(
                (v) => v.SelectedResourceId(),
                TextResources.WorkedHours.SelectResource
            )
            .isNotNullUndefinedOrLessOrEqualZero(
                (v) => v.SelectedJobOrderId(),
                TextResources.WorkedHours.SelectJobOrder
            )
            .isNotNullUndefinedOrLessOrEqualZero(
                (v) => v.SelectedServiceOrderId(),
                TextResources.WorkedHours.SelectServiceOrder
            )
            .isNotNullUndefinedOrLessOrEqualZero((v) => v.SelectedRoleId(), TextResources.WorkedHours.SelectRole)
            .isNotNullUndefinedOrLessOrEqualZero(
                (v) => v.SelectedCategoryId(),
                TextResources.WorkedHours.SelectWorktimeCategory
            )
            .isNotNullOrUndefined((v) => v.WorkDay(), TextResources.WorkedHours.SelectDate)
            .isNotNullUndefinedOrZero((v) => v.Hours(), TextResources.WorkedHours.InsertHours);

        new ResourceRoleAndCategorySelector({
            Categories: this.WorkTimeCategories,
            Category: this.SelectedCategoryId,
            Date: this.WorkDay,
            JobOrder: this.SelectedJobOrderId,
            Resource: this.SelectedResourceId,
            Role: this.SelectedRoleId,
            Roles: this.Roles,
            ServiceOrder: this.SelectedServiceOrderId,
            ServiceOrders: this.ServiceOrders,
            Task: null,
            Initializing: () => false,
            IgnoreFirstRun: false,
            DelayComputeds: false,
        });
    }

    onItemSelected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {
        if (sender === this.ResourcesDataSource && ResourcesDataSource.isModel(model)) {
            this.SelectedResource(model.model);
        }
    }

    onItemDeselected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {}

    public initialize() {}

    public SetData(day: Date, resourceId: number, workTimeCategoryId: number) {
        this.WorkDay(day);
        this.SelectedResourceId(resourceId);
        this.SelectedCategoryId(workTimeCategoryId);
    }

    Insert() {
        if (!this.validator.validateAndShowInfoToast(this)) return;

        this.dialogService.Confirm(
            TextResources.WorkedHours.SureToInsertHours,
            TextResources.WorkedHours.DoNotInsert,
            TextResources.WorkedHours.Insert,
            (confirm) => {
                if (!confirm) return;

                this.workedHoursService
                    .GetWorkSheet(this.SelectedResourceId(), this.WorkDay())
                    .then((s: IWorkSheet) => {
                        const sheet =
                            s ||
                            <IWorkSheet>{
                                Day: this.WorkDay(),
                                ResourceId: this.SelectedResourceId(),
                                Rows: [],
                            };

                        const newRow: IWorkSheetRow = {
                            EventId: 0,
                            WorkEventId: 0,
                            JobOrderId: this.SelectedJobOrderId(),
                            WorkDate: this.WorkDay(),
                            ResourceId: this.SelectedResourceId(),
                            ResourceName: this.resourcesManager.getFullName(this.SelectedResource()),
                            RoleId: this.SelectedRoleId(),
                            WorkTimeCategoryId: this.SelectedCategoryId(),
                            FkServiceOrder: this.SelectedServiceOrderId(),
                            From: null,
                            To: null,
                            BreakHours: 0,
                            Billable: false,
                            Billed: false,
                            ActivityContainerType: "JOR",
                            WorkPlaceId: 0,
                            Hours: this.Hours(),
                            TravelDistance: 0,
                            CallRight: false,
                            BusinessManagement: true,
                            Description: this.Notes(),
                            IsPreviousMonthImbalance: false,
                            IsApproved: null,
                            ApprovedBy: null,
                            ApprovalNote: null,
                            ApprovalDate: null,
                        };

                        sheet.Rows.push(newRow);
                        this.workedHoursService.UpdateWorkSheetWithBusinessManagementMovement(sheet).then(() => {
                            this.infoToastService.Success(ProlifeSdk.TextResources.WorkedHours.HoursInserted);
                            this.parent.Refresh();
                        });
                    });
            }
        );
    }
}

class ResourceMonthlyReport {
    Name: string;
    WorkTimeCategories: ko.ObservableArray<WorkTimeCategoryForResourceReport> = ko.observableArray([]);
    CallRightCategories: ko.ObservableArray<CallRightCategoryForResourceReport> = ko.observableArray([]);
    Days: any[] = [];
    Total: number;
    Variation: number;
    TotalWithVariation: number;
    HasAway: boolean;
    HasTravelDistance: boolean;
    TravelDistance = 0;
    NumberOfCalls = 0;

    constructor(
        resource: IHumanResource,
        holidays: any[],
        monthlyRecords: IWorkedHoursMonthlyReportRecord[],
        numberOfDays: number[],
        workTimeCategories: IWorkTimeCategory[],
        callRightCategories: ICallRightType[],
        private showVariations: boolean,
        private insertConsole: WorkedHoursMonthReportInsertConsole,
        year: number,
        month: number
    ) {
        this.Name = resource.Resource.Surname + " " + resource.Resource.Name;
        this.Total = 0;
        this.Variation = 0;
        this.TotalWithVariation = 0;

        const categoriesToShow = workTimeCategories.filter((c: IWorkTimeCategory) => {
            return (
                monthlyRecords.filter((r: IWorkedHoursMonthlyReportRecord) => {
                    return r.WorkTimeCategoryId == c.Id;
                }).length > 0
            );
        });

        const callRightCategoriesToShow = callRightCategories.filter((c: ICallRightType) => {
            return (
                monthlyRecords.filter((r: IWorkedHoursMonthlyReportRecord) => {
                    return r.CallRightType == c.Id;
                }).length > 0
            );
        });

        const resourceHolidays = holidays.filter((h) => {
            return h.resourceId == resource.Resource.Id;
        });
        categoriesToShow.forEach((w: IWorkTimeCategory) => {
            const categoryRecords = monthlyRecords.filter((r: IWorkedHoursMonthlyReportRecord) => {
                return r.WorkTimeCategoryId == w.Id;
            });
            this.WorkTimeCategories.push(
                new WorkTimeCategoryForResourceReport(
                    w,
                    numberOfDays,
                    categoryRecords,
                    showVariations,
                    insertConsole,
                    resource.Resource.Id,
                    year,
                    month,
                    resourceHolidays
                )
            );
        });

        callRightCategoriesToShow.forEach((w: ICallRightType) => {
            const categoryRecords = monthlyRecords.filter((r: IWorkedHoursMonthlyReportRecord) => {
                return r.CallRightType == w.Id;
            });
            this.CallRightCategories.push(
                new CallRightCategoryForResourceReport(
                    w,
                    numberOfDays,
                    categoryRecords,
                    showVariations,
                    insertConsole,
                    resource.Resource.Id,
                    year,
                    month,
                    resourceHolidays
                )
            );
        });

        numberOfDays.forEach((day: any) => {
            let dayTotalHours = 0;
            let dayVariationTotalHours = 0;
            let travelDistance = 0;
            let numberOfCalls = 0;
            let hasAway = false;

            const recordsForDay = monthlyRecords.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return moment(record.WorkDate).date() == day.Value;
            });

            recordsForDay.forEach((record: IWorkedHoursMonthlyReportRecord) => {
                numberOfCalls += record.CallRight;
                travelDistance += record.TravelDistance;
                hasAway = hasAway || record.Away == 1;
            });

            this.TravelDistance += travelDistance;
            this.NumberOfCalls += numberOfCalls;
            this.HasTravelDistance = this.TravelDistance > 0;
            this.HasAway = this.HasAway || hasAway;

            const dayHolidays = resourceHolidays.filter((h) => {
                return h.day == day.Value;
            });

            const matchesWithoutVariation = recordsForDay.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return !record.BusinessManagement;
            });

            matchesWithoutVariation.forEach((record: IWorkedHoursMonthlyReportRecord) => {
                dayTotalHours += record.Hours;
            });

            const matchesWithVariation = recordsForDay.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return record.BusinessManagement;
            });

            matchesWithVariation.forEach((record: IWorkedHoursMonthlyReportRecord) => {
                dayVariationTotalHours += record.Hours;
            });

            this.Days.push({
                Value: dayTotalHours + (showVariations ? dayVariationTotalHours : 0),
                Away: hasAway,
                TravelDistance: travelDistance,
                NumberOfCalls: numberOfCalls,
                IsHoliday: dayHolidays.length > 0 && dayHolidays[0].isHoliday,
            });
            this.Total += dayTotalHours;
            this.Variation += dayVariationTotalHours;
            this.TotalWithVariation += dayTotalHours + dayVariationTotalHours;
        });
    }
}

class BaseCategoryForResourceReport {
    Name: string;
    Days: any[] = [];
    Total: number;

    constructor(
        protected name: string,
        numberOfDays: number[],
        monthlyRecords: IWorkedHoursMonthlyReportRecord[],
        protected showVariations: boolean,
        protected insertConsole: WorkedHoursMonthReportInsertConsole,
        protected resourceId: number,
        protected year: number,
        protected month: number
    ) {
        this.Name = name;
        this.Total = 0;
    }
}

class WorkTimeCategoryForResourceReport extends BaseCategoryForResourceReport {
    TravelDistance = 0;
    Variation: number;
    TotalWithVariation: number;

    constructor(
        private workTimeCategory: IWorkTimeCategory,
        numberOfDays: number[],
        monthlyRecords: IWorkedHoursMonthlyReportRecord[],
        showVariations: boolean,
        insertConsole: WorkedHoursMonthReportInsertConsole,
        resourceId: number,
        year: number,
        month: number,
        holidays: any[]
    ) {
        super(
            workTimeCategory.Name,
            numberOfDays,
            monthlyRecords,
            showVariations,
            insertConsole,
            resourceId,
            year,
            month
        );
        this.TravelDistance = 0;
        this.Variation = 0;
        this.TotalWithVariation = 0;

        numberOfDays.forEach((day: any) => {
            let travelDistance = 0;

            const recordsForDay = monthlyRecords.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return moment(record.WorkDate).date() == day.Value;
            });

            const matchesWithoutVariation = recordsForDay.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return !record.BusinessManagement;
            });

            const matchesWithVariation = recordsForDay.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return record.BusinessManagement;
            });

            recordsForDay.forEach((record: IWorkedHoursMonthlyReportRecord) => {
                travelDistance += record.TravelDistance;
            });

            this.TravelDistance += travelDistance;

            const dayHolidays = holidays.filter((h) => {
                return h.day == day.Value;
            });

            const hours = matchesWithoutVariation.length > 0 ? matchesWithoutVariation.sum((h) => h.Hours) : 0;
            const variationHours = matchesWithVariation.length > 0 ? matchesWithoutVariation.sum((h) => h.Hours) : 0;
            this.Days.push({
                Hours: hours + (showVariations ? variationHours : 0),
                HasVariations: showVariations && matchesWithVariation.length > 0,
                TravelDistance: travelDistance,
                Day: day,
                IsHoliday: dayHolidays.length > 0 && dayHolidays[0].isHoliday,
            });
            this.Total += hours;
            this.Variation += variationHours;
            this.TotalWithVariation += hours + variationHours;
        });
    }

    SetDayOnInsertConsole(element) {
        const day: Date = moment(new Date()).year(this.year).month(this.month).date(element.Day.Value).toDate();

        this.insertConsole.SetData(day, this.resourceId, this.workTimeCategory.Id);
    }
}

class CallRightCategoryForResourceReport extends BaseCategoryForResourceReport {
    NumberOfCalls = 0;

    constructor(
        private callRightType: ICallRightType,
        numberOfDays: number[],
        monthlyRecords: IWorkedHoursMonthlyReportRecord[],
        showVariations: boolean,
        insertConsole: WorkedHoursMonthReportInsertConsole,
        resourceId: number,
        year: number,
        month: number,
        holidays: any[]
    ) {
        super(callRightType.Name, numberOfDays, monthlyRecords, showVariations, insertConsole, resourceId, year, month);
        this.NumberOfCalls = 0;

        numberOfDays.forEach((day: any) => {
            let numberOfCalls = 0;

            const recordsForDay = monthlyRecords.filter((record: IWorkedHoursMonthlyReportRecord) => {
                return moment(record.WorkDate).date() == day.Value;
            });

            recordsForDay.forEach((record: IWorkedHoursMonthlyReportRecord) => {
                numberOfCalls += record.CallRight;
            });

            this.NumberOfCalls += numberOfCalls;

            const dayHolidays = holidays.filter((h) => {
                return h.day == day.Value;
            });

            this.Days.push({
                NumberOfCalls: numberOfCalls,
                Day: day,
                IsHoliday: dayHolidays.length > 0 && dayHolidays[0].isHoliday,
            });
        });
    }
}
