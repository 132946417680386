import { Select, SelectProps } from "../../../../Components/Select";
import * as React from "@abstraqt-dev/jsxknockout";
import { IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";


export class RoleSelect {
    constructor(private props: SelectProps<number, IUserCharacter>) {

    }

    render() {
        return <Select {...this.props}   />
    }
}



