import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";

export type RouteNavigator = {
    navigateTo(url: string) : void;
}

export type RouteParams<T = unknown> = {
    navigator: RouteNavigator;
} & T;

export type RouteProps = {
    path: string;
    exact?: boolean;
    element: (params: RouteParams) => React.ReactNode;
}

export function Route(props: RouteProps) {
    const C = require("./Route")._Route as typeof _Route;
    return <C {...props} />;
}

export class _Route {
    static defaultProps: Partial<RouteProps> = {
    }

    constructor(public props : RouteProps) {}
    
    render() {
        return <div></div>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(Route);
}