import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ResourcesManagerViewModel } from "./ui/ResourcesManagerViewModel";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { LazyImport } from "../../Core/DependencyInjection";

export class ResourcesManagerApplication implements IApplication
{
    public templateName = 'resourcesmanager';
    public templateUrl = 'resourcesmanager/templates';
    public templateData : ko.Observable<ResourcesManagerViewModel> = ko.observable();
    public tileColor = 'tile-grey';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;
    @LazyImport(nameof<IOPAService>())
    private oPAService : IOPAService;

    constructor()
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return ProlifeSdk.ResourcesManagerApplicationCode;
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.ResourcesManager.ApplicationName;
    }

    getIcon(): string
    {
        return "fa fa-users";
    }

    registerRoutes() : void
    {
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Resources, (context) => this.start(0));
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Groups, (context) => this.start(1));
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Roles, (context) => this.start(2));
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_OperationalUnit, (context) => this.start(3));
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_CalRights, (context) => this.start(4));
        this.oPAService.Get("#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_WorkTimeCategories, (context) => this.start(5));
    }

    getApplicationRoute() : string
    {
        return "#/Risorse/" + ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Resources;
    }

    onGoingDown() : void
    {
        this.templateData().dispose();
    }

    private start(startSectionIndex : number)
    {
        if (this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
        }

        this.templateData(new ResourcesManagerViewModel(startSectionIndex));
        this.applicationsService.startApplication(this);
    }
}