import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import "./HolidaysEditorComponent";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface IHolidaysSettingsManager extends ISettingsManager {
    getHolidays(): IHoliday[];
    saveHolidays(holidays: IHoliday[]): Promise<void>;
}

export interface IHoliday {
    year: number;
    month: number;
    day: number;
    IsPermanent: boolean;
}

export class HolidaysSettingsManager implements IHolidaysSettingsManager {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    private holidays : IHoliday[] = [];

    load(): Promise<any> {
        return this.ajaxService.Post<IHoliday[]>("Settings-api/Holidays", "GetAllHolidays", { background: true })
            .then(h => this.holidays = h);
    }

    getName(): string {
        return ProlifeSdk.HolidaysSettingsType;
    }

    getLabel(): string {
        return TextResources.Settings.Holidays;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: null,
            templateUrl: null,
            viewModel: {
            },
            component: "holidays-editor"
        }
    }

    getHolidays(): IHoliday[] {
        return this.holidays;
    }

    async saveHolidays(holidays: IHoliday[]): Promise<void> {
        await this.ajaxService.Post<void>("Settings-api/Holidays", "SaveHolidays", { methodData: { Holidays: holidays} });
    }
}