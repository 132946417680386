import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { IProvisioningService } from "../../../../ProvisioningService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { ICustomersService } from "../../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { Prompt, RouteParams } from "../../../../../Components/Routing";
import { Portlet } from "../../../../../Components/Portlet";
import { BackButton } from "../../../../../Components/BackButton";
import { IApplicationNavBarAction, INavBarActionManager } from "../../../../../Desktop/interfaces/IApplication";
import { RichTextInput } from "../../../../../Components/RichTextInput";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { IChangesNotificationsService } from "../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { TextInput } from "../../../../../Components/TextInput";
import { Select } from "../../../../../Components/Select";
import { DropdownList } from "../../../../../Components/DropdownList";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { PercentageInput } from "../../../../../Components/PercentageInputComponent";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

export type PreOrderInfo = {
    Number: string;
    StatusId: number;
    Notes: string;
    Margin: number;
}

type PreOrderGeneralInfoProps = RouteParams<{
    preOrderId: number;
    margin: ko.Observable<number>;
    hasChanges: ko.Observable<boolean>;
    navBarActionManager: INavBarActionManager;

    onSave: (preOrderInfo: PreOrderInfo) => Promise<void>;
}>;

export function PreOrderGeneralInfo(props: PreOrderGeneralInfoProps) {
    const C = require("./PreOrderGeneralInfo")._PreOrderGeneralInfo as typeof _PreOrderGeneralInfo;
    return <C {...props} />;
}

export class _PreOrderGeneralInfo extends ComponentBase {
    static defaultProps: Partial<PreOrderGeneralInfoProps> = {
    }

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;
    
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    Number : ko.Observable<string> = ko.observable();
    StatusId : ko.Observable<number> = ko.observable();
    PreOrderStatuses: { Id: number; Label: string; }[] = [
        //{ Id: 1, Label: TextResources.Provisioning.DraftPreOrder },
        { Id: 2, Label: TextResources.Provisioning.OnWorkingPreOrder },
        { Id: 3, Label: TextResources.Provisioning.ClosedPreOrder }
    ];
    Notes : ko.Observable<string> = ko.observable();

    createSupplierAction: IApplicationNavBarAction;
    private onPreOrderChangedHandler: number;
    
    constructor(private props : PreOrderGeneralInfoProps) {
        super();
    }

    async componentDidMount() {
        await this.load();

        this.disposeOnUnmount(this.Number.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(this.StatusId.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(this.Notes.subscribe(() => this.props.hasChanges(true)));

        this.createSupplierAction = this.props.navBarActionManager.addNavBarAction(
            "Crea Fornitore",
            "fa fa-user",
            true,
            () => this.createNewSupplier()
        );

        this.onPreOrderChangedHandler = this.changesNotificationsService.RegisterEventHandler("OnPreOrderChanged", (data: { preOrderId: number }) => {
            if(this.props.preOrderId !== data.preOrderId) return;
            this.load();
        });
    }
    
    componentWillUnmount() {
        this.props.navBarActionManager.removeNavBarAction(this.createSupplierAction);

        this.changesNotificationsService.UnregisterEventHandler("OnPreOrderChanged", this.onPreOrderChangedHandler);
    }

    private async load() {
        if (!this.props.preOrderId || this.props.preOrderId <= 0) {
            this.StatusId(2); // Stato aperto di default
            return;
        }

        const [preOrder] = await this.provisioningService.GetPreOrdersByIds([this.props.preOrderId]);
        this.Number(preOrder.Number);
        this.StatusId(preOrder.StatusId);
        this.Notes(preOrder.Note);
        this.props.margin(preOrder.Margin);
    }

    public async createNewSupplier(): Promise<void> {
        return this.customersService.ui.showNewCustomerDialog(null, false, true);
    }

    public async saveSelectedPreOrder(): Promise<void> {
        await this.props.onSave({
            Notes: this.Notes(),
            Number: this.Number(),
            StatusId: this.StatusId(),
            Margin: this.props.margin()
        });
    }

    public async deleteSelectedPreOrder(): Promise<void> {
        if(this.props.preOrderId <= 0) return;

        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.DeletePreOrderMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm
        );
        if(!confirm) return;

        try
        {
            await this.provisioningService.DeletePreOrder(this.props.preOrderId);
            this.infoToastService.Success(TextResources.Provisioning.PreOrderDeleted);

            this.props.navigator.navigateTo("/Provisioning/PreOrders");
        }
        catch
        {
            this.infoToastService.Error(TextResources.Provisioning.PreOrderDeleteFailed)
        }
    }

    public async abortPreOrderEditing(): Promise<void> {
        this.props.navigator.navigateTo("/Provisioning/PreOrders");
    }
    
    render() {
        const _gi = this;

        return  ComponentUtils.bindTo(
            <div className="flex-1">
                <Prompt isBlocking={this.props.hasChanges} 
                        message={TextResources.Provisioning.PreOrderPendingRequestsMessage} 
                        confirm={TextResources.Provisioning.Confirm}
                        cancel={TextResources.Provisioning.Abort} />
                <Portlet initialCollapsed={this.props.preOrderId > 0}>
                    <Portlet.Header>
                        <BackButton style={{ marginRight: '20px' }} onClick={_gi.abortPreOrderEditing.bind(_gi)} />
                        <Portlet.Header.Default title={() => {
                            return this.props.preOrderId < 0 ? "Creazione Preordine" : `Modifica Preordine N° ${this.Number()}`
                        }}
                        subTitle={() => this.PreOrderStatuses.firstOrDefault(s => s.Id == this.StatusId())?.Label} className="font-red-sunglo bold uppercase" />
                    </Portlet.Header>
                    <Portlet.Actions>
                        <button type="button" class="btn btn-danger btn-circle" data-bind={{ asyncClick: _gi.deleteSelectedPreOrder.bind(_gi), clickBubble: false }}>
                            <i className="fa fa-trash-o"></i>&nbsp;Elimina
                        </button>
                        <button type="button" class="btn btn-primary btn-circle" data-bind={{ asyncClick: _gi.saveSelectedPreOrder.bind(_gi), clickBubble: false }}>
                            <i className="fa fa-floppy-o"></i>&nbsp;Salva
                        </button>
                    </Portlet.Actions>
                    <Portlet.Body>
                        {() => <>
                            <div className="row margin-top-10">
                                <TextInput label="Numero preordine" className="col-md-2" placeholder="auto" value={this.Number} readonly />
                                <DropdownList label="Stato" containerClassName="col-md-2" values={this.PreOrderStatuses} value={this.StatusId} idGetter={i => i.Id} valueGetter={i => i.Id.toString()} textGetter={i => i.Label} />
                                <RichTextInput label="Note" className="col-md-8" inputStyle={{ maxHeight: '200px', overflow: 'auto' }} inline value={this.Notes} placeholder="Inserisci qui le note per il preordine..." />
                            </div>
                            <div className="row">
                                <PercentageInput className="col-md-2" value={this.props.margin} label="Margine Predefinito" selectOnFocus />
                            </div>
                        </>}
                    </Portlet.Body>
                </Portlet>
                
                
            </div>
        , this, '_gi');
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PreOrderGeneralInfo);
}