import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IHumanResourcesService, IHumanResource } from "../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ISelect2ResponsibleProvider } from "../../../ProlifeSdk/interfaces/customer/providers/ISelect2ResponsibleProvider";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";

export class ResponsibleReference implements ISelect2ResponsibleProvider
{
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService : IEntityProviderService;
    @LazyImport(nameof<IHumanResourcesService>())
    private resourcesService : IHumanResourcesService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesSettingsManager: IHumanResourcesSettingsManager;

    private resourcesEntityProvider : IEntityProvider<number, IHumanResource>;
    private lastTimeout : ReturnType<typeof setTimeout>;

    public Values : ko.Observable<string> = ko.observable();
    public DisplayName : ko.Observable<string> = ko.observable("");

    public ValuesType: ko.Computed<string>;
    public ValuesTypeDescription: ko.Computed<string>;

    constructor()
    {
        this.resourcesEntityProvider = this.entitiesService.getEntityProvider(ProlifeSdk.HumanResources);

        this.ValuesType = ko.computed(() => {
            let values = this.Values();
            return (values || "").split('#').firstOrDefault();
        });

        this.ValuesTypeDescription = ko.computed(() => {
            let valuesType = this.ValuesType();

            if (!valuesType)
                return "";

            return valuesType === "RESOURCE" ? TextResources.JobOrder.ResponsibleProviderSelectedResourceTitle : valuesType === "TEXT" ? TextResources.JobOrder.ResponsibleProviderPlainTextResourceTitle : "";
        });
    }

    public EscapeMarkup(markup) : string
    {
        if(markup.indexOf("escape-markup='true'") > -1)
            return markup;

        var replace_map = {
            '\\': '&#92;',
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&apos;',
            "/": '&#47;'
        };

        return (<any>$.fn.select2.defaults).escapeMarkup(markup);
    }

    private FormatSelection(data, container)
    {
        return data.text;
    }

    public Initialize(resourceId : number, resourceName : string)
    {
        if(!resourceId)
            this.DisplayName(resourceName || "");
        else
            this.resourcesService.GetHumanResource(resourceId)
                .then((r : IHumanResource) => {
                    this.DisplayName(r ? this.resourcesEntityProvider.getDisplayName(r) : "");
                });

        var stringValue : string = resourceId ?
            "RESOURCE#" + resourceId :
            (resourceName ? "TEXT#" + resourceName : null);
        this.Values(stringValue);
    }

    public GetData()
    {
        let refType : string = this.Values() ? this.Values().split("#")[0] : "TEXT";
        let refValue : string = this.Values() ? this.Values().split("#")[1] : null;

        let id = refType == "TEXT" ? null : parseInt(refValue);
        let resource = this.resourcesSettingsManager.getHumanResourceById(id);
        let name = !id ? refValue : ((resource?.Resource.Name ?? "") + " " + (resource?.Resource.Surname || "")).trim();

        return {
            Id : id,
            Name : name
        }
    }

    public Search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() =>
        {
            this.SearchResourcesInternal(query);
        }, 500);
    }

    public Init(element, callback)
    {
        var values = $(element).val();
        this.InitializeResourcesInternal(values, callback);
    }

    public SearchResourcesInternal(query : any)
    {
        this.resourcesEntityProvider.hintSearch(query.term)
            .then((items : IHumanResource[]) => {

                var data = { results: [] };

                //Stringa di ricerca
                var groupResult = {
                    text : ProlifeSdk.TextResources.Customers.Text,
                    children : [{
                        id : "TEXT#" + query.term,
                        text : query.term
                    }]
                }

                if((query.term || "").trim().length > 0)
                    data.results.push(groupResult);

                //Match sulle risorse
                groupResult = {
                    text: ProlifeSdk.TextResources.Customers.Resources,
                    children: []
                };

                if(items.length > 0)
                    data.results.push(groupResult);

                items.forEach((item) => {

                    groupResult.children.push({
                        id: "RESOURCE#" + item.Resource.Id,
                        text: this.resourcesEntityProvider.getDisplayName(item)
                    });
                });

                query.callback(data);
            })
    }

    public InitializeResourcesInternal(stringValue, callback)
    {
        if((stringValue || "") == "")
            return;

        var refType : string = stringValue.split("#")[0];
        var refValue : string = stringValue.split("#").length > 1 ? stringValue.split("#")[1] : null;

        if(!refValue)
            return;

        if(refType == "TEXT")
        {
            callback({ id : stringValue, text : refValue, hidden : false });
            return;
        }

        var id : number = parseInt(refValue);

        this.resourcesService.GetHumanResource(id)
            .then((r : IHumanResource) => {
                if(!r)
                    return;

                callback({ id : stringValue, text : r ? this.resourcesEntityProvider.getDisplayName(r) :
                    ProlifeSdk.TextResources.Customers.ResourceNotAccessible, hidden : !r });
            });
    }
}