import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import numeral = require("numeral");
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { If, IfNot } from "../../../../../Components/IfIfNotWith";
import { NumericText } from "../../../../../Components/NumericText";
import { Column, ColumnBody } from "../../../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { Table, ITableItem } from "../../../../../Components/TableComponent/TableComponent";
import { TableFooterAggregationMode } from "../../../../../Components/TableComponent/TableFooterAggregationMode";
import { ICartContentRoleInfo, ICartContentWithRolesInfo } from "../../../../../ProlifeSdk/interfaces/allocations/ICart";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ICartsService } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsService";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type CartElementDetails = ICartContentWithRolesInfo & {
    totalEstimatedHours: number;
    totalReestimatedHours: number;
    totalWorkedHours: number;

    Collapsed: ko.Observable<boolean>;
    WorkPerRolesInfo: ko.ObservableArray<ICartContentRoleInfo>;
}

type CartContentProps = {
    cartId: number;
    cartContent: ko.ObservableArray<ICartContentWithRolesInfo>;
    onRemoveContent: (item: ICartContentWithRolesInfo) => void;
    onReorderContent: () => void;
}

export function CartContent(props: CartContentProps) {
    const C = require("./CartContent")._CartContent as typeof _CartContent;
    return <C {...props} />;
}

export class _CartContent {
    static defaultProps: Partial<CartContentProps> = {
    }

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;

    constructor(private props: CartContentProps) {

    }

    private createCartContentItem(item: ICartContentWithRolesInfo): IDataSourceModel<number, CartElementDetails> {
        return {
            id: item.ElementInfo.Id,
            isGroup: false,
            isLeaf: true,
            title: item.ElementInfo.Title,
            model: {
                ...item,
                totalEstimatedHours: item.RolesInfo.reduce((acc, r) => acc + r.EstimatedHours, 0),
                totalReestimatedHours: item.RolesInfo.reduce((acc, r) => acc + r.ReestimatedHours, 0),
                totalWorkedHours: item.RolesInfo.reduce((acc, r) => acc + r.WorkedHours, 0),
                Collapsed: ko.observable(true),

                WorkPerRolesInfo: ko.observableArray(item.RolesInfo)
            }
        };
    }

    private async handleRowsSorting(droppedItem: ICartContentWithRolesInfo, neighbour: ICartContentWithRolesInfo, before: boolean, newIndex: number): Promise<void> {
        const beforeId = before ? neighbour.ElementInfo.Id : null;
        const afterId = before ? null : neighbour.ElementInfo.Id;

        try {
            await this.cartsService.reorderCartContent(this.props.cartId, droppedItem.ElementInfo.Id, beforeId, afterId, newIndex);
            this.props.onReorderContent();
        } catch(e) {
            console.log(e);
            this.infoToastService.Error(TextResources.Allocations.ReorderCartContentError);
        }
    }
    
    private createWorkPerRoleItem(item: ICartContentRoleInfo): IDataSourceModel<number, ICartContentRoleInfo> {
        return {
            id: item.RoleId,
            title: item.RoleName,
            isGroup: false,
            isLeaf: true,
            model: item
        };
    }

    render() {
        return <Table
                    dataSource={{ array: this.props.cartContent, factory: this.createCartContentItem.bind(this) }}
                    compact
                    fixedLayout
                    systemScrollable
                    rowAs="elem"

                    onRowSorting={this.handleRowsSorting.bind(this)}
                    rowsSortingValueGetter={(item) => item.ElementInfo.Order}
                    rowsSortingValueSetter={(item, value) => item.ElementInfo.Order = value}
                >
                    <Column title={TextResources.Allocations.CartElementColumnTitle}>
                        <ColumnBody>
                            {(item: ITableItem<CartElementDetails>) => (
                                <div className={ComponentUtils.classNames("flex-container", classes["element-title"])}>
                                    <span className="title-icon">
                                        <i className={ComponentUtils.classNames("fa", { "fa-sitemap": !item.Data.model.ElementInfo.IsTask, "fa-pencil-square-o": item.Data.model.ElementInfo.IsTask })}></i>
                                    </span>
                                    <span className="flex-fill">{item.Data.title}</span>
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Allocations.WorkflowColumnTitle}>
                        <ColumnBody>
                            {(item: ITableItem<CartElementDetails>) => (item.Data.model.ElementInfo.IsTask ? <span>{item.Data.model.ElementInfo.WorkflowTitle}</span> : TextResources.ProlifeSdk.NotAvailable)}
                        </ColumnBody>
                    </Column>
                    <Column title={TextResources.Allocations.JobOrderColumnTitle}>
                        <ColumnBody>
                            {(item: ITableItem<CartElementDetails>) => <span>{item.Data.model.ElementInfo.JobOrderTitle}</span>}
                        </ColumnBody>
                    </Column>
                    <Column className="text-right" style={{ width: "80px" }}>
                        <ColumnBody>
                            {(item: ITableItem<CartElementDetails>) => (
                                <>
                                    <button type="button" className="btn btn-xs btn-link" onClick={() => item.Data.model.Collapsed(!item.Data.model.Collapsed())}>
                                        <If condition={item.Data.model.Collapsed}>
                                            {() => <i className="fa fa-chevron-down"></i>}
                                        </If>
                                        <IfNot condition={item.Data.model.Collapsed}>
                                            {() => <i className="fa fa-chevron-up"></i>}
                                        </IfNot>
                                        
                                    </button>
                                    <button type="button" className="btn btn-xs btn-danger" onClick={() => this.props.onRemoveContent(item.Data.model)}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                    <SecondaryRow if={() => "!elem.model.Collapsed()"}>
                        {(item: ITableItem<CartElementDetails>) => (
                            <td colSpan={5} style={{ paddingLeft: "25px" }}>
                                <Table
                                    dataSource={{ array: item.Data.model.WorkPerRolesInfo, factory: this.createWorkPerRoleItem.bind(this) }}
                                    compact
                                    fixedLayout
                                    enableAggregators
                                >
                                    <Column title={TextResources.Allocations.UserCharacterColumnTitle}>
                                        <ColumnBody>
                                            {(item: ITableItem<ICartContentRoleInfo>) => <span>{item.Data.model.RoleName}</span>}
                                        </ColumnBody>
                                    </Column>
                                    <Column title={TextResources.Allocations.EstimatedWorkColumnTitle} className="text-right" aggregateOn={(item: ITableItem<ICartContentRoleInfo>) => item.Data.model.EstimatedHours} defaultAggregation={TableFooterAggregationMode.Sum} aggregationFormatter={(value: number) => numeral(value).format("0,0.00[00]")}>
                                        <ColumnBody>
                                            {(item: ITableItem<ICartContentRoleInfo>) => <NumericText value={item.Data.model.EstimatedHours} format="0,0.00[00]" />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title={TextResources.Allocations.ReestimatedWorkColumnTitle} className="text-right"  aggregateOn={(item: ITableItem<ICartContentRoleInfo>) => item.Data.model.ReestimatedHours} defaultAggregation={TableFooterAggregationMode.Sum} aggregationFormatter={(value: number) => numeral(value).format("0,0.00[00]")}>
                                        <ColumnBody>
                                            {(item: ITableItem<ICartContentRoleInfo>) => <NumericText value={item.Data.model.ReestimatedHours} format="0,0.00[00]" />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title={TextResources.Allocations.WorkColumnTitle} className="text-right"  aggregateOn={(item: ITableItem<ICartContentRoleInfo>) => item.Data.model.WorkedHours} defaultAggregation={TableFooterAggregationMode.Sum} aggregationFormatter={(value: number) => numeral(value).format("0,0.00[00]")}>
                                        <ColumnBody>
                                            {(item: ITableItem<ICartContentRoleInfo>) => <NumericText value={item.Data.model.WorkedHours} format="0,0.00[00]" />}
                                        </ColumnBody>
                                    </Column>
                                </Table>
                            </td>
                        )}
                    </SecondaryRow>
                </Table>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CartContent);
}