import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 16:36
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { FestivitiesUIProvider } from "./Agenda/ui/FestivitiesUIProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IFestivitiesService, IFestivityUIProvider, IFestivity } from "../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { LazyImport } from "../Core/DependencyInjection";

class FestivitiesService extends ProLifeService implements IFestivitiesService {
    public ui: IFestivityUIProvider;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.FestivitiesServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IFestivitiesService>(), this);

        this.ui = new FestivitiesUIProvider(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.FestivitiesServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetFestivities(start: Date, end: Date, agendaId): Promise<IFestivity[]> {
        return this.ajaxService.Post<IFestivity[]>("Agenda-api", "Festivities/GetFestivities", {
            methodData: {
                StartDate: start,
                EndDate: end,
                AgendaId: agendaId
            }
        });
    }

    CreateOrUpdate(festivity: IFestivity): Promise<number> {
        return this.ajaxService.Post<number>("Agenda-api", "Festivities", {
            methodData: festivity
        });
    }

    Delete(festivityId: number): Promise<void> {
        return this.ajaxService.Delete<void>("Agenda-api", "Festivities?id=" + festivityId, {});
    }

    UpdateFestivityAfterMove(id: number, start: Date, end: Date, allDay: boolean): Promise<void> {
        return this.ajaxService.Post<void>("Agenda-api", "Festivities/UpdateFestivityAfterMove", {
            methodData: {
                Id: id,
                StartDate: start,
                EndDate: end,
                AllDay: allDay
            }
        });
    }

    GetFestivity(festivityId: number): Promise<IFestivity> {
        return this.ajaxService.Post<IFestivity>("Agenda-api", "Festivities/GetFestivity", {
            methodData: {
                festivityId: festivityId
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator): IService {
    return new FestivitiesService(serviceLocator);
}