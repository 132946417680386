import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/02/13
 * Time: 14.35
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IValidityType, IValidityTypes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IValidityTypes";

export interface IValidityTypeProvider {
    getData() : IValidityType;
    update(validityType : IValidityType) : void;
}

export class ValidityTypesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IValidityTypeProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private validityTypesManager : IValidityTypes) {
        this.title = validityTypesManager.getLabel();

        var validityTypes = validityTypesManager.getValidityTypes();
        validityTypes.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(validityType : IValidityType) {
        this.elements.push(new ValidityTypeViewModel(this, validityType));
    }

    public addNewSetting() {
        var validityTypeViewModel = new ValidityTypeViewModel(this, {
            Descrizione: this.newSettingName()
        });
        this.elements.push(validityTypeViewModel);
        this.createOrUpdateEntry(validityTypeViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IValidityTypeProvider) {
        var validityType = element.getData();
        this.validityTypesManager.createOrUpdate(validityType)
            .then((updatedValidityType) => element.update(updatedValidityType));
    }

    public deleteEntry(element : IValidityTypeProvider) {
        this.elements.remove(element);
        var validityType = element.getData();
        this.validityTypesManager.remove(validityType.Id);
    }
}

class ValidityTypeViewModel implements IValidityTypeProvider {
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);

    constructor(private container : ValidityTypesEditingViewModel, private validityType : IValidityType) {
        this.name(validityType.Descrizione);
        this.name.subscribe(this.onNameChanged.bind(this));
    }

    private onNameChanged(newValue : string) {
        if(!newValue) {
            this.container.deleteEntry(this);
        } else {
            this.container.createOrUpdateEntry(this);
        }
    }

    getData() : IValidityType {
        var validityType : IValidityType = <IValidityType> $.extend({}, this.validityType);
        validityType.Descrizione = this.name();
        return validityType;
    }

    update(validityType : IValidityType) : void {
        this.validityType = validityType;
        this.name(this.validityType.Descrizione);
    }
}