import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../../Core/enumerations/ServiceTypes";
import { WorkflowProvider } from "./WorkflowProvider";
import { TaskProvider } from "./TaskProvider";
import { ContextMenuDialog } from "../../../../../../../ProlifeSdk/prolifesdk/ContextMenuDialog";
import { ITodoListTask } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IBlogEventsRelatedToTask } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowForList } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowNavigatorOptions } from "../../../../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../../../Core/interfaces/IAuthorizationService";
import { INavigationMenuAction, INavigationMenuProvider } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { IObjectChangesInfo } from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { Deferred } from "../../../../../../../Core/Deferred";

export class WorkflowWithTasksProvider extends WorkflowProvider {
    protected loadingData: boolean = false;
    protected itemsAreLoaded: boolean = false;
    protected initialTasksSelection: number[] = [];
    protected refreshDeferred: Deferred<void>;

    private IsSelectable: ko.Computed<boolean>;
    private authorizationsService: IAuthorizationService;

    constructor(
        serviceLocator: IServiceLocator,
        workflow: ITodoListWorkflowForList,
        protected workflowSelectionEnabled: ko.Observable<boolean>,
        private jobOrderId: number,
        observer: any,
        private navigatorOptions: IWorkflowNavigatorOptions
    ) {
        super(serviceLocator, workflow, observer);

        this.authorizationsService = <IAuthorizationService>this.serviceLocator.findService(ServiceTypes.Authorization);

        this.IsLeaf = false;
        this.templateName = "workflow-with-tasks-item";
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.SearchAllowed = true;

        this.IsDraggable = ko.computed(() => {
            return this.navigator() ? this.navigator().draggableOptions.EnableWorkflowsDragging() : false;
        });

        this.IsSelectable = ko.computed(() => {
            return this.navigator() ? this.navigator().WorkflowSelectionEnabled : false;
        });

        if (
            !navigatorOptions.DisableTaskEditing &&
            this.modulesService.IsModuleEnabled(ProlifeSdk.TodolistApplicationCode) &&
            this.authorizationsService.isAuthorized("TaskBoard_InsertTask")
        )
            this.MenuActions.push(<INavigationMenuAction>{
                IsSeparator: false,
                Text: ko.observable(ProlifeSdk.TextResources.Todolist.NewTask),
                Icon: ko.observable("fa fa-plus"),
                Action: this.CreateNewTask.bind(this),
            });
    }

    private async CreateNewTask() {
        await this.todoListService.ShowCreateNewTaskDialog(this.jobOrderId, this.WorkflowId);
        this.refreshItems();
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (!super.OnEntityHasBeenChanged(changesInfo, sendByMe)) {
            this.itemsAreLoaded = false;
            return false;
        }
        return true;
    }

    public RefreshData(workflow: ITodoListWorkflowForList): Promise<void> {
        var def = new Deferred<void>();

        super
            .RefreshData(workflow)
            .then(() => {
                //Non occorre ricaricare i figli se non sono già stati caricati. Saranno caricati al momento dell'ingresso nel livello
                if (this.itemsAreLoaded || this.loadingData) {
                    def.resolve();
                    return;
                }

                this.refreshItems()
                    .then(() => {
                        def.resolve();
                    })
                    .catch(() => {
                        def.reject([]);
                    });
            })
            .catch(() => {
                def.reject([]);
            });

        return def.promise();
    }

    selectWorkflow(item) {
        /*if(this.navigatorOptions.DisableWorkflowSelection)
            return;*/

        var def = this.itemsAreLoaded ? Promise.resolve() : this.loadingData ? this.refreshDeferred.promise() : this.refreshItems();

        def.then(() => {
            var isSelected: boolean = this.AllChildrenSelected();
            this.Items().forEach((p: INavigationMenuProvider) => {
                if (!isSelected) this.navigator().selectProvider(p, false);
                else this.navigator().removeProviderFromSelection(p, false);
            });
            this.navigator().notifySelectionChangedToObservers();
        });
    }

    open(item) {
        if (this.loadingData || (this.IsDraggable() && this.InDragging())) return;

        if (this.workflowSelectionEnabled()) {
            this.selectWorkflow(item);
            return;
        }

        var def = this.itemsAreLoaded ? Promise.resolve() : this.refreshItems();

        def.then(() => {
            super.open(item);
        });
    }

    GetSelectedTasksIds(): number[] {
        return this.Items()
            .filter((p) => {
                return p.IsSelected();
            })
            .map((p: TaskProvider) => {
                return p.task.Id;
            });
    }

    SetSelectedTasks(ids: number[]): Promise<void> {
        this.initialTasksSelection = ids;

        if (ids == null || ids.length == 0) return Promise.resolve<void>(undefined);

        var d = this.itemsAreLoaded ? Promise.resolve() : this.loadingData ? this.refreshDeferred.promise() : this.refreshItems();

        return d.then(() => {
            this.Items().forEach((p: TaskProvider) => {
                if (ids.indexOf(p.task.Id) > -1) this.navigator().selectProvider(p, false);
            });
        });
    }

    search(textFilter: string): Promise<void> {
        if (!textFilter) {
            this.SearchedItems([]);
            return Promise.resolve<void>(undefined);
        }

        var def = this.itemsAreLoaded ? Promise.resolve() : this.loadingData ? this.refreshDeferred.promise() : this.refreshItems();

        return def.then(() => {
            var adaptedFilter: string = (textFilter || "").toUpperCase().trim();
            var filterResult = this.Items().filter((p) => {
                return p.Name.toUpperCase().indexOf(adaptedFilter) > -1;
            });
            this.SearchedItems(filterResult);
        });
    }

    refreshItems(): Promise<void> {
        this.loadingData = true;
        this.refreshDeferred = new Deferred();
        var oldItems = this.Items();

        this.todoListService
            .GetTasks([], [], [this.workflow.Id], null, -1, -1, -1)
            .then((tasks: ITodoListTask[]) => {
                var tasksIds: number[] = tasks.map((t: ITodoListTask) => {
                    return t.Id;
                });
                this.todoListService.GetNumberOfBlogEventsRelatedToTasks(tasksIds).then((result: IBlogEventsRelatedToTask[]) => {
                    var tasksItems = tasks
                        .filter((t: ITodoListTask) => {
                            return !t.Deleted;
                        })
                        .map((t: ITodoListTask) => {
                            var numberMatches: { TaskId: number; NumberOfEvents: number }[] = result.filter((r: { TaskId: number; NumberOfEvents: number }) => {
                                return r.TaskId == t.Id;
                            });
                            var tasksMatches = oldItems.filter((t1: TaskProvider) => {
                                return t1.task.Id == t.Id;
                            });

                            var p: INavigationMenuProvider = tasksMatches.length > 0 ? tasksMatches[0] : new TaskProvider(this.serviceLocator);
                            (<TaskProvider>p).RefreshData(t, numberMatches.length > 0 ? numberMatches[0].NumberOfEvents : 0);
                            p.setCurrentNavigator(this.navigator());
                            return p;
                        });

                    this.Items(tasksItems);
                    this.itemsAreLoaded = true;

                    //Verifico l'eventuale rimozione di task selezionati e notifico il cambio di selezione
                    var removedTasks = oldItems.filter((t) => {
                        return tasksItems.indexOf(t) == -1 && t.IsSelected();
                    });
                    if (removedTasks.length > 0) {
                        removedTasks.forEach((t) => {
                            this.navigator().removeProviderFromSelection(t, false);
                        });
                        this.navigator().notifySelectionChangedToObservers();
                    }

                    this.SetSelectedTasks(this.initialTasksSelection);
                    this.refreshDeferred.resolve();
                });
            })
            .finally(() => {
                this.loadingData = false;
            });

        return this.refreshDeferred.promise();
    }

    openContextMenuDialog() {
        var dialog: ContextMenuDialog = new ContextMenuDialog(this.serviceLocator, this.MenuActions());
        dialog.ShowModal();
    }
}
