import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/07/2017
 * Time: 12:33
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { PeopleSearchFilters } from "../../peoples/search/PeopleSearchFilters";
import { PeopleForListViewModel } from "../../peoples/PeopleForListViewModel";
import { EntitiesListLockManager } from "../../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { EditPeopleDialog } from "../../peoples/dialogs/EditPeopleDialog";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IException } from "../../../../../Core/interfaces/IException";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IPeopleSearchFiltersObserver, IEditPeopleResult, IPeopleForList } from "../../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { ISampleService } from "../../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class SampleContentDialog implements IDialog, IPeopleSearchFiltersObserver {
    public templateName: string = "sample-content-dialog";
    public templateUrl: string = "survey/templates/sample/dialogs";
    public title: string = ProlifeSdk.TextResources.Survey.SampleContentDialogTitle;
    public modal: { close: (result?: any) => void; };

    ExporterId : string = "Survey/SampleImportExport";

    public Contacts: ko.ObservableArray<PeopleForListViewModel> = ko.observableArray([]);
    public Filters: PeopleSearchFilters;

    public EmptyList: ko.Computed<boolean>;

    public ExportUrl: ko.Observable<string> = ko.observable();

    private LockManager : EntitiesListLockManager;

    @LazyImport(nameof<ISampleService>())
    private sampleService: ISampleService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private sampleId: number;

    private isLoading: boolean = false;

    constructor(private serviceLocator: IServiceLocator, sampleId: number) {
        this.sampleId = sampleId;
        
        this.Filters = new PeopleSearchFilters(this);

        this.ExportUrl("Service/Survey/SampleImportExport/GenerateExcel/" + this.sampleId);

        this.LockManager = new EntitiesListLockManager(this.Contacts, ProlifeSdk.SurveyEntityTypeCode);

        this.Refresh(0)
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetSampleContentError);
            });

        this.EmptyList = ko.computed(() => {
            return this.Contacts().length == 0;
        });
    }

    action(): void {
        this.close();
    }

    close(): void {
        this.Filters.dispose();
        this.modal.close(null);
    }

    public AddContact(): void {
        var editPeopleDialog = new EditPeopleDialog(this.serviceLocator, null);
        this.dialogsService.ShowModal<IEditPeopleResult>(editPeopleDialog, "fullscreen", null, "survey/templates/people/dialogs", "edit-people-dialog")
            .then((result: IEditPeopleResult) => {
                if (!result)
                    return;
                this.sampleService.addInSample(this.sampleId, [result.PeopleData.Id])
                    .then(() => {
                        this.Contacts.push(new PeopleForListViewModel(<IPeopleForList>(result.PeopleData)));
                    });
            });
    }

    public async DeleteContactFromSample(contact: PeopleForListViewModel): Promise<void> {
        let confirm = await this.dialogsService.ConfirmAsync(TextResources.Survey.DeletePeopleFromSampleConfirmMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);
        if (!confirm)
            return;

        try {
            await this.sampleService.DeleteContactFromSample(contact.Id, this.sampleId);
            contact.Deleted(true);

            if (!this.Filters.ShowDeleted())
                this.Contacts.splice(this.Contacts.indexOf(contact), 1);
        } catch(e) {
            let ex = e as IException;

            if (ex.ExceptionType === ProlifeSdk.ServerException_ProLife)
                this.infoToastService.Error(ex.ExceptionMessage);
            else
                this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }

    public getExcelExportData() : any | null {
        return {
            sampleId: this.sampleId
        };
    }

    public LoadNextBlock(): void {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);

        this.LockManager.WhenIsReady().then(() => {

            this.Refresh(this.Contacts().length)
            .finally(() => {
                this.desktopService.UnblockPageUI();
            });
        });
    }

    public OnFiltersChanges(): void {
        this.Refresh(0);
    }

    public Refresh(skip: number): Promise<IPeopleForList[]> {
        if (this.isLoading)
            return Promise.resolve<IPeopleForList[]>([]);

        this.isLoading = true;

        if (skip == 0)
            this.Contacts([]);

        return this.sampleService.getSampleContent(this.sampleId, this.Filters.toJSON(), skip, 150)
            .then((peoples: IPeopleForList[]) => {
                var newList = this.Contacts();
                peoples.forEach((p: IPeopleForList) => {
                    newList.push(new PeopleForListViewModel(p));
                });
                this.Contacts(newList);

                setTimeout(() => this.isLoading = false, 500);
                return peoples;
            });
    }
}