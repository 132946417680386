import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IHumanResourcesSettingsManager } from "../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../Users/HumanResourcesService";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IResourcesGroupsSettingsManager, IResourcesGroup } from "../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

export class ResourceOrGroupText
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        let interceptor = ko.computed(() => {
            let options = valueAccessor();

            let settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            let humanResourcesSettingsManager : IHumanResourcesSettingsManager = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
            let groupsSettingsManager : IResourcesGroupsSettingsManager = <IResourcesGroupsSettingsManager> settingsService.findSettingsManager(ProlifeSdk.ResourcesGroups);

            if(options.type == ProlifeSdk.HumanResources)
            {
                let user : IHumanResource = humanResourcesSettingsManager.getHumanResourceById(ko.utils.unwrapObservable(options.id));
                return user != null ? user.Resource.Name  + " " + user.Resource.Surname : (options.defaultDescription || "");
            }
            else
            {
                let g : IResourcesGroup = groupsSettingsManager.GetGroupById(ko.utils.unwrapObservable(options.id));
                return g != null ? g.Name : (options.defaultDescription || "");
            }
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["resourceOrGroupText"] = new ResourceOrGroupText();
ko.virtualElements.allowedBindings["resourceOrGroupText"] = true;
