import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type TelephoneTextProps = {
    phoneNumber: ko.Subscribable<string> | string;
};

export function TelephoneText(props: TelephoneTextProps) {
    const C = require("./TelephoneText")._TelephoneText as typeof _TelephoneText;
    return <C {...props} />;
}

export class _TelephoneText {
    static defaultProps: Partial<TelephoneTextProps> = {};

    constructor(private props: TelephoneTextProps) {}

    render() {
        const tt = this;
        return ComponentUtils.bindTo(<span data-bind={{ telephoneText: tt.props.phoneNumber }}></span>, this, "tt");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(TelephoneText);
}
