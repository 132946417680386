import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IDocumentBuilderDocumentOriginatingRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IPurchasesForDocuments } from "../../../../ProlifeSdk/interfaces/financial-data/IPurchasesForDocuments";
import { IPurchaseRow } from "../../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IFinancialDataService } from "../../../../ProlifeSdk/interfaces/financial-data/IFinancialDataService";
import { ISearchFilter } from "../../../../ProlifeSdk/interfaces/ISearchFilter";
import { IEntitySearchResult } from "../../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { ILogTagFilter, ILogFilter } from "../../../../ProlifeSdk/interfaces/ILogFilter";
import { Deferred } from "../../../../Core/Deferred";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { NavigationMenu } from "../../../../Components/NavigationMenu";
import {
    IWorkflowsAndTasksDataSourceModel,
    WorkflowsAndTasksDataSource,
} from "../../../../DataSources/WorkflowsAndTasksDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
    INavigationMenuComponentActionsGroup,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceListener } from "../../../../DataSources/IDataSource";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { AlertsLegendUI } from "../../../../Components/AlertsLegend";

const { classes } = jss
    .createStyleSheet({
        "purchases-data-source": {
            "& .actions": {
                "& button:not(last-child)": {
                    marginRight: "5px",
                },
            },

            "& .menu-wrapper": {
                "& .page-sidebar": {
                    position: "absolute !important",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    margin: 0,

                    "& .page-quick-sidebar-wrapper": {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
        },
    })
    .attach();

export class PurchasesDataSource extends DocumentDataWizardStep<IPurchasesForDocuments> implements IDataSourceListener {
    ShowAlreadyImported: ko.Observable<boolean> = ko.observable(false);

    Purchases: ko.ObservableArray<PurchaseRow> = ko.observableArray();
    RowsToImport: ko.ObservableArray<IPurchaseRow> = ko.observableArray();
    SearchFilters: ko.Observable<string> = ko.observable();
    SelectAll: ko.Computed<boolean>;

    @LazyImport(nameof<IFinancialDataService>())
    private financialDataService: IFinancialDataService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService: IEntityProviderService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();

    public Total: ko.Computed<number>;
    public TotalInDefaultCurrency: ko.Computed<number>;

    public ShowTotalInDefaultCurrency: ko.Computed<boolean>;

    private referenceDate: Date;
    public WarningMessages: ko.Observable<string> = ko.observable();

    previousStepRows: IDocumentDataWizardRow[];

    WorkflowsAndTasksDataSource: WorkflowsAndTasksDataSource = new WorkflowsAndTasksDataSource();
    private navigationMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    public SelectedTasks: number[] = [];

    constructor() {
        super();
        this.Title(TextResources.Desktop.PurchasesWizardTitle);

        this.installWatches();

        this.SelectAll = ko.computed<boolean>({
            read: () => {
                const purchases = this.Purchases();
                return purchases.filter((r: PurchaseRow) => !r.Selected()).length == 0;
            },
            write: (selected: boolean) => {
                const purchases = this.Purchases();
                purchases.forEach((r: PurchaseRow) => r.Selected(selected));
            },
        });

        this.Total = ko.computed(() => {
            let total = 0;
            this.Rows().forEach((r) => (total += r.NetPrice));
            return total;
        });

        this.TotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();
            if (!documentCurrency) return this.Total();

            return CurrencyUtils.applyCurrencyExchange(this.Total(), documentCurrency);
        });

        this.ShowTotalInDefaultCurrency = ko.computed(() => {
            const documentCurrency = this.DocumentCurrency();
            if (!documentCurrency) return false;

            const defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();
            return defaultCurrency.CodeISO4217alpha3 != documentCurrency.Currency().CodeISO4217alpha3;
        });

        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.InvoiceEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.CreditNoteEntityTypeCode,
            ProlifeSdk.SalEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode,
            ProlifeSdk.WarehouseLoadEntityTypeCode
        );

        this.navigationMenu.subscribe((menu) => {
            this.configureMenu(menu);
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const sign =
            this.mustUseCosts() && this.initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol
                ? -1
                : 1;

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: r.AmountFormula,
                    Amount: r.Amount * sign,
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: r.NetUnitPrice,
                    NetUnitPrice: r.NetUnitPrice.ToEuro(documentCurrency),
                    Order: 0,
                    TotalPriceInDocumentCurrency: r.NetPrice * sign,
                    TotalPrice: r.NetPrice.ToEuro(documentCurrency) * sign,
                    UnitPriceInDocumentCurrency: r.NetUnitPrice,
                    UnitPrice: r.NetUnitPrice.ToEuro(documentCurrency),
                    Description: r.Description,
                    Discounts: null,
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    onItemSelected(sender: WorkflowsAndTasksDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        this.SelectedTasks.push(model.id);
        this.loadPurchases();
    }

    onItemDeselected(sender: WorkflowsAndTasksDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        this.SelectedTasks.remove(model.id);
        this.loadPurchases();
    }

    configureMenu(navigator: INavigationMenuComponent) {
        const showingSecondaryActions = ko.observable(false);
        navigator.enableSelectAllChildren();

        const showLegendAction: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-info-circle",
            text: "",
            title: TextResources.ProlifeSdk.AlertsLegendTitle,
            activeClass: "",
            defaultClass: "btn-transparent",
            active: () => false,
            canExecute: () => true,
            action: () => {
                const component = new AlertsLegendUI();
                component.show();
            },
        };

        navigator.registerAction(showLegendAction);

        const action = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            actions: [
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.initializationInfo.JobOrderId);
                    },
                },
            ],
        };

        if (
            this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
        )
            navigator.registerAction(action);

        if (
            this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_EditTask")
        ) {
            const startEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                visible: () => !showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(true);
                    navigator.showSecondaryAction(true);
                },
            };

            const endEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                visible: () => showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(false);
                    navigator.showSecondaryAction(false);
                },
            };

            navigator.registerAction(startEditAction);
            navigator.registerAction(endEditAction);
        }

        navigator.registerSearchAction({
            text: "Cerca tutto",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(true),
            icon: "",
            isGroup: false,
            isSeparator: false,
            isDefault: true,
        });

        navigator.registerSearchAction({
            text: "Cerca solo in questa lista",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(false),
            icon: "",
            isGroup: false,
            isSeparator: false,
        });

        navigator.setMultipleSelection({
            keepSelection: true,
            multipleSelection: true,
            selectLeafsOnly: true,
        });

        this.WorkflowsAndTasksDataSource.setRecursiveSearch(true);
    }

    private getReferences(r: IPurchasesForDocuments): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        for (const wh of r.Purchases.filter((r) => r.Amount != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                DestEntityKeyId: 0,
                DestEntityType: this.initializationInfo.DocTypeCode,
                Amount: wh.Amount,
                UnitPrice: r.NetUnitPrice,
                NetUnitPrice: r.NetUnitPrice,
                NetPrice: r.NetPrice,
                SourceEntityKeyId: wh.PurchaseId,
                SourceEntityType: ProlifeSdk.PurchasesEntityTypeCode,
                TaskId: wh.TaskId,
            });
        }
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return (
            initializationInfo.JobOrderId > 0 &&
            (initializationInfo.DocTypeCode !== ProlifeSdk.WarehouseLoadEntityTypeCode ||
                initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol)
        );
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo): Promise<void> {
        await super.Initialize(initializationInfo);

        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf("month").toDate());
        this.DateTo(this.referenceDate);
        this.WorkflowsAndTasksDataSource.setJobOrders([initializationInfo.JobOrderId]);

        await this.loadPurchases();
    }

    private installWatches() {
        this.DateFrom.subscribe(this.loadPurchases.bind(this));
        this.DateTo.subscribe(() => {
            if (this.DateTo() > this.referenceDate) {
                this.WarningMessages(
                    String.format(ProlifeSdk.TextResources.Desktop.WatchWarning, moment(this.referenceDate).format("L"))
                );
            } else {
                this.WarningMessages(undefined);
            }
            this.loadPurchases();
        });
        this.ShowAlreadyImported.subscribe(this.loadPurchases.bind(this));
        this.SearchFilters.subscribe(this.loadPurchases.bind(this));
    }

    private async loadPurchases() {
        const filters: ISearchFilter[] = [
            {
                From: this.DateFrom(),
                To: this.DateTo(),
                Tags: [
                    {
                        TagName: ProlifeSdk.TagWorkHours_Billable,
                        ValueTypeId: ProlifeSdk.TagType_Bool,
                        Values: ["True"],
                    },
                ],
                Tasks: this.SelectedTasks.length > 0 ? this.SelectedTasks : null, // Il server NON applica il filtro se tasks è NULL; se tasks è [] il filtro viene applicato e l'API' non restituisce niente
            },
        ];

        const searchFilters = JSON.parse("[" + (this.SearchFilters() || "").replace(/\|/g, ",") + "]");
        // eslint-disable-next-line prefer-spread
        filters.push.apply(filters, searchFilters);

        const purchases = await this.financialDataService.getPurchases(
            -1,
            -1,
            this.initializationInfo.JobOrderId,
            filters,
            null,
            this.mustUseCosts(),
            this.ShowAlreadyImported()
        );

        this.onPurchasesLoaded(purchases);
    }

    private mustUseCosts(): boolean {
        return (
            [ProlifeSdk.SupplierOrderEntityTypeCode, ProlifeSdk.WarehouseLoadEntityTypeCode].indexOf(
                this.initializationInfo.DocTypeCode
            ) >= 0
        );
    }

    private isAlreadyImported(purchase: IPurchaseRow): boolean {
        return purchase.ReferencingRows != null && purchase.ReferencingRows.length > 0;
    }

    private wasUsedDuringThisWizard(purchase: IPurchaseRow): boolean {
        return this.Rows().filter((r) => r.Purchases.indexOf(purchase) > -1).length > 0;
    }

    private onPurchasesLoaded(purchases: IPurchaseRow[]) {
        this.Purchases(
            purchases
                .filter((p: IPurchaseRow) => this.ShowAlreadyImported() || !this.isAlreadyImported(p))
                .map(this.createViewModelFor.bind(this))
        );

        this.SelectAll(true);
    }

    private createViewModelFor(purchase: IPurchaseRow): PurchaseRow {
        return new PurchaseRow(purchase, this.isAlreadyImported(purchase));
    }

    public importDetailed() {
        this.filterRows().forEach(this.addDetailedRow.bind(this));
    }

    public importAggregated() {
        this.addAggregatedRow(this.filterRows());
    }

    private filterRows(): IPurchaseRow[] {
        const selectedPurchases = this.Purchases().filter((r: PurchaseRow) => r.Selected());
        if (selectedPurchases.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.NoPurchasesSelected);
            return [];
        }

        const notImportedPurchases: IPurchaseRow[] = selectedPurchases
            .map((r: PurchaseRow) => r.getPurchase())
            .filter((p: IPurchaseRow) => !this.wasUsedDuringThisWizard(p));

        if (notImportedPurchases.length != selectedPurchases.length) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.ImportPurchasesWarning);
        }

        return notImportedPurchases;
    }

    private addDetailedRow(purchase: IPurchaseRow) {
        const documentCurrency = this.DocumentCurrency();

        const purchases: IPurchasesForDocuments = {
            Purchases: [purchase], // Il riferimanto rimane in Euro
            Description: moment(purchase.PurchaseDate).format("L") + " - " + purchase.Description,
            Amount: purchase.Amount,
            NetUnitPrice: CurrencyUtils.applyCurrencyReverseExchange(purchase.NetMoney, documentCurrency), // purchase.NetMoney,
            NetPrice: CurrencyUtils.applyCurrencyReverseExchange(purchase.TotalMoney, documentCurrency), // purchase.TotalMoney
        };

        this.Rows.push(purchases);
    }

    private addAggregatedRow(purchases: IPurchaseRow[]) {
        const documentCurrency = this.DocumentCurrency();

        const totalSellPrice = purchases.sum((p) => p.TotalMoney);
        let description: string = ProlifeSdk.TextResources.Desktop.Purchases + " ";
        description += this.DateFrom()
            ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L"))
            : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo()
            ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L"))
            : ProlifeSdk.TextResources.Desktop.EndDateToday;

        const p: IPurchasesForDocuments = {
            Purchases: purchases, // I riferimenti rimangono in Euro
            Description: description,
            AmountFormula: null, //numeral(totalHours).format("0,0.00[0]") + " * " + numeral(salaryInDocumentCurrency).format("0,0.00[0]") + documentCurrency.Currency().Symbol,
            Amount: 1,
            NetUnitPrice: CurrencyUtils.applyCurrencyReverseExchange(totalSellPrice, documentCurrency), // totalSellPrice,
            NetPrice: CurrencyUtils.applyCurrencyReverseExchange(totalSellPrice, documentCurrency), // totalSellPrice
        };

        this.Rows.push(p);
    }

    public removeRow(row: IPurchasesForDocuments) {
        this.Rows.remove(row);
    }

    private lastTimeout: ReturnType<typeof setTimeout>;
    findSearchMatches(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.search(query.term, query.callback);
        }, 500);
    }

    private search(searchFilter: string, callback: (data: any) => void) {
        const data = { results: [] };

        const providersDef = new Deferred();
        this.entitiesProviderService
            .search(searchFilter)
            .then((results: IEntitySearchResult[]) => {
                results.forEach((result: IEntitySearchResult) => {
                    if (result.entities.length == 0) return;

                    const menuGroup = {
                        text: result.provider.getEntityTypeName(),
                        children: [],
                    };

                    result.entities.forEach((e) => {
                        const tags: ILogTagFilter[] = [
                            {
                                Values: [result.provider.getPkValue(e)],
                                ValueTypeId: result.provider.getType(),
                            },
                        ];

                        const entityFilter: ILogFilter = {
                            Tags: tags,
                        };

                        menuGroup.children.push({
                            id: JSON.stringify(entityFilter),
                            text: result.provider.getEntityTypeName() + ":   " + result.provider.getDisplayName(e),
                        });
                    });

                    data.results.push(menuGroup);
                });
                providersDef.resolve();
            })
            .catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
    }

    render() {
        let step: PurchasesDataSource;
        let row: PurchaseRow;
        let newRow: IPurchasesForDocuments;

        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["100%"]}
                columns={["auto", "270px"]}
                style={{ height: "100%" }}
                className={classes["purchases-data-source"]}
            >
                <Layout.Grid.Cell column={1} row={1}>
                    <div
                        class="form-horizontal flex-container flex-vertical flex-full-height"
                        style={{ paddingRight: "15px", width: "100%" }}
                    >
                        <div class="row" style="margin-bottom : 20px; margin-top: 20px">
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                            <div class="col-md-2">
                                <div
                                    class="input-group date form_datetime"
                                    data-bind={{ nullableDatePicker: step.DateFrom }}
                                >
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                            <div class="col-md-2">
                                <div
                                    class="input-group date form_datetime"
                                    data-bind={{ nullableDatePicker: step.DateTo }}
                                >
                                    <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                    <span class="input-group-addon">
                                        <span class="fa fa-calendar"></span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div
                                    class="alert alert-danger"
                                    style="margin-top: -8px"
                                    data-bind={{ visible: !!step.WarningMessages(), text: step.WarningMessages }}
                                ></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="col-md-6 form-control-static">
                                    <div class="checker">
                                        <span data-bind={{ css: { checked: step.ShowAlreadyImported } }}>
                                            <input type="checkbox" data-bind={{ checked: step.ShowAlreadyImported }} />
                                        </span>
                                    </div>
                                    {TextResources.Invoices.DocumentWizardViewImporterdPurchases}
                                </div>
                            </div>
                            <div class="col-md-6 actions">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-bind={{ click: step.importDetailed }}
                                >
                                    {TextResources.Invoices.DocumentWizardDetailed}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-bind={{ click: step.importAggregated }}
                                >
                                    {TextResources.Invoices.DocumentWizardGrouped}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-bind={{ click: step.ClearImportedRows }}
                                >
                                    {TextResources.Invoices.DocumentWizardClear}
                                </button>
                            </div>
                        </div>

                        <div class="flex-fill">
                            <Layout.Grid rows={["100%"]} columns={["50%", "50%"]} noOverflow style={{ height: "100%" }}>
                                <Layout.Grid.Cell column={1} row={1}>
                                    <div data-bind={{ slimScroll: "flex" }}>
                                        <table class="table table-striped table-bordered table-advance table-hover">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;width: 120px;">
                                                        <div class="checker">
                                                            <span data-bind={{ css: { checked: step.SelectAll } }}>
                                                                <input
                                                                    type="checkbox"
                                                                    data-bind={{ checked: step.SelectAll }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th style="width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardDate}
                                                    </th>
                                                    <th>{TextResources.Invoices.DocumentWizardDetailed}</th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardAmount}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardUnitPrice}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardDiscount}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardNetPrice}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardSellPrice}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if: step.Purchases().length == 0 }}>
                                                    <tr>
                                                        <td colSpan={8} style="text-align : center">
                                                            {TextResources.Invoices.DocumentWizardNoPurchases}
                                                        </td>
                                                    </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach: { data: step.Purchases, as: "row" } }}>
                                                    <tr data-bind={{ css: { active: row.Selected } }}>
                                                        <td style="text-align: center">
                                                            <div class="checker">
                                                                <span data-bind={{ css: { checked: row.Selected } }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        data-bind={{ checked: row.Selected }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <i
                                                                class="fa fa-lock"
                                                                data-bind={{ visible: row.AlreadyImported }}
                                                            ></i>
                                                            <span data-bind={{ dateText: row.Date }}></span>
                                                        </td>
                                                        <td data-bind={{ text: row.Description }}></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind={{ numberText: row.Amount }}
                                                        ></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind={{ moneyText: row.UnitPrice }}
                                                        ></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind={{ discountText: row.Discount }}
                                                        ></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind={{ moneyText: row.NetPrice }}
                                                        ></td>
                                                        <td
                                                            style="text-align: right"
                                                            data-bind={{ moneyText: row.SellPrice }}
                                                        ></td>
                                                    </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </Layout.Grid.Cell>
                                <Layout.Grid.Cell column={2} row={1} style={{ paddingLeft: "5px" }}>
                                    <div data-bind={{ slimScroll: "flex" }}>
                                        <table class="table table-striped table-bordered table-advance table-hover">
                                            <thead>
                                                <tr>
                                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardAmount}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardTotalPrice}
                                                    </th>
                                                    <th style="text-align: center;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardRemove}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if: step.Rows().length == 0 }}>
                                                    <tr>
                                                        <td colSpan={4} style="text-align : center">
                                                            {TextResources.Invoices.DocumentWizardNoPurchases}
                                                        </td>
                                                    </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach: { data: step.Rows, as: "newRow" } }}>
                                                    <tr>
                                                        <td data-bind={{ text: newRow.Description }}></td>
                                                        <td class="text-right" data-bind={{ text: newRow.Amount }}></td>
                                                        <td
                                                            style="text-align: right"
                                                            data-bind={{
                                                                moneyText: newRow.NetPrice,
                                                                currencySymbol: step.DocumentCurrencySymbol,
                                                                documentCurrency: step.DocumentCurrency,
                                                            }}
                                                        ></td>
                                                        <td style="text-align: center;">
                                                            <a
                                                                href="#"
                                                                data-bind={{
                                                                    click: step.removeRow.bind(step, newRow),
                                                                }}
                                                            >
                                                                <i class="fa fa-times"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ko-bind>
                                                <tr>
                                                    <td>{TextResources.Invoices.DocumentWizardTotalPrice}</td>
                                                    <td colSpan={2} style="text-align: right">
                                                        <span data-bind={{ visible: step.ShowTotalInDefaultCurrency }}>
                                                            (
                                                            <span
                                                                data-bind={{
                                                                    moneyText: step.TotalInDefaultCurrency,
                                                                }}
                                                            ></span>
                                                            )
                                                        </span>{" "}
                                                        <span
                                                            data-bind={{
                                                                moneyText: step.Total,
                                                                currencySymbol: step.DocumentCurrencySymbol,
                                                                documentCurrency: step.DocumentCurrency,
                                                            }}
                                                        ></span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Layout.Grid.Cell>
                            </Layout.Grid>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={2} row={1} style={{ position: "relative" }} className={"menu-wrapper"}>
                    <NavigationMenu
                        dataSource={this.WorkflowsAndTasksDataSource}
                        listener={this}
                        forwardRef={(menu) => this.navigationMenu(menu)}
                    ></NavigationMenu>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "step"
        );
    }
}

class PurchaseRow {
    Selected: ko.Observable<boolean> = ko.observable(false);
    Date: ko.Observable<Date> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable();
    UnitPrice: ko.Observable<number> = ko.observable();
    Discount: ko.Observable<string> = ko.observable();
    NetPrice: ko.Observable<number> = ko.observable();
    PurchasePrice: ko.Observable<number> = ko.observable();
    SellPrice: ko.Observable<number> = ko.observable();
    AlreadyImported: ko.Observable<boolean> = ko.observable();

    constructor(private purchase: IPurchaseRow, alreadyImported: boolean) {
        this.Date(moment(purchase.PurchaseDate).toDate());
        this.Description(purchase.Description);

        this.Amount(purchase.Amount);
        this.UnitPrice(purchase.UnitMoney);
        this.Discount(purchase.Discount);
        this.NetPrice(purchase.NetMoney);

        this.PurchasePrice(purchase.IsCost ? purchase.TotalMoney : 0);
        this.SellPrice(purchase.IsCost ? 0 : purchase.TotalMoney);
        this.AlreadyImported(alreadyImported);
    }

    public getPurchase(): IPurchaseRow {
        return this.purchase;
    }
}
