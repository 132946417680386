import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IResourcesGroupsSettingsManager, IResourcesGroup } from "../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

export class ResourcesGroupDisplayFullName
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var interceptor = ko.computed(() => {
            var options = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var groupsSettingsManager : IResourcesGroupsSettingsManager = <IResourcesGroupsSettingsManager> settingsService.findSettingsManager(ProlifeSdk.ResourcesGroups);

            var g : IResourcesGroup = groupsSettingsManager.GetGroupById(ko.utils.unwrapObservable(options.id));

            if (g != null)
                return g.Name;

            return options.defaultDescription || "";
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["resourcesGroupDisplayFullName"] = new ResourcesGroupDisplayFullName();
ko.virtualElements.allowedBindings["resourcesGroupDisplayFullName"] = true;
