import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/02/2018
 * Time: 10:38
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { ICategoriesProvider, IEventCategory, ISelect2Category } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IFestivityCategory } from "../../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { ICategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/agenda/ICategoriesSettingsManager";
import { ISelect2Query, ISelect2Result } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";

interface ILogicalStatesMap {
    [index: string]: string;
}

export class CategoriesProvider implements ICategoriesProvider, IControlsEntityProvider {
    private EventsCategories: IEventCategory[] = [];
    private FestivitiesCategories: IFestivityCategory[] = [];

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImportSettingManager(ProlifeSdk.EventsCategoriesSettingsManager)
    private categoriesSettingsManager: ICategoriesSettingsManager;

    private logicalStatesMap: ILogicalStatesMap = {};

    constructor(private serviceLocator: IServiceLocator) {
        this.logicalStatesMap["_0"] = ProlifeSdk.TextResources.Agenda.LogicalStateNotSet;
        this.logicalStatesMap["_1"] = ProlifeSdk.TextResources.Agenda.PlannedEventState;
        this.logicalStatesMap["_2"] = ProlifeSdk.TextResources.Agenda.ConfirmedEventState;
        this.logicalStatesMap["_3"] = ProlifeSdk.TextResources.Agenda.WaitingListEventState;
        this.logicalStatesMap["_4"] = ProlifeSdk.TextResources.Agenda.AbortedEventState;

        this.EventsCategories = this.categoriesSettingsManager.getCategories()
        this.FestivitiesCategories = this.categoriesSettingsManager.getFestivitiesCategories();
    }

    GetEventsCategories(query: ISelect2Query) {
        var matchedCategories: IEventCategory[] = this.EventsCategories.filter((c: IEventCategory) => { return !query.term || c.Label.toLowerCase().indexOf(query.term.toLowerCase()) != -1; });
        var logicalStates: number[] = [];

        matchedCategories.forEach((c: IEventCategory) => {
            if (logicalStates.indexOf(c.LogicalState) < 0)
                logicalStates.push(c.LogicalState);
        });

        logicalStates.sort((a, b) => a - b);

        var results: ISelect2Result[] = [];

        logicalStates.forEach((s: number) => {
            results.push({
                text: this.logicalStatesMap['_' + s],
                children: matchedCategories.filter((c: IEventCategory) => c.LogicalState == s).map((c: IEventCategory) => { return { id: c.Id, text: c.Label, color: c.Color }; })
            });
        });

        query.callback({
            results: results
        });
    }

    GetEventsCategory(element: HTMLElement, callback: (result: { id: number; text: string; color: string; }) => void) {
        var categoryId: number = parseInt(<string>$(element).val());
        if (!categoryId || isNaN(categoryId))
            return;
        var categories: IEventCategory[] = this.EventsCategories.filter((c: IEventCategory) => { return c.Id == categoryId; });
        if (categories.length > 0)
            callback({ id: categories[0].Id, text: categories[0].Label, color: categories[0].Color });
    }

    GetFestivitiesCategories(query: ISelect2Query) {
        query.callback({
            results: this.FestivitiesCategories.filter((c: IFestivityCategory) => { return !query.term || c.Label.toLowerCase().indexOf(query.term.toLowerCase()) != -1; })
                .map((c: IFestivityCategory) => { return { id: c.Id, text: c.Label, color: c.Color }; })
        });
    }

    GetFestivitiesCategory(element: HTMLElement, callback: (result: { id: number; text: string; color: string; }) => void) {
        var categoryId: number = parseInt(<string>$(element).val());
        if (!categoryId || isNaN(categoryId))
            return;
        var categories: IFestivityCategory[] = this.FestivitiesCategories.filter((c: IFestivityCategory) => { return c.Id == categoryId; });
        if (categories.length > 0)
            callback({ id: categories[0].Id, text: categories[0].Label, color: categories[0].Color });
    }

    FormatCategory(category: ISelect2Category): string {
        if (category.id == undefined)
            return '<span style="vertical-align: middle">' + category.text + '</span>';
        return '<span style="display: inline-block; width: 12px; height: 12px; background-color: ' + category.color + '; vertical-align: middle"></span>&nbsp;&nbsp;&nbsp;<span style="vertical-align: middle">' + category.text + '</span>';
    }

    GetCategoryColor(categoryId: number, festivity: boolean = false): string {
        if (!festivity)
            return this.EventsCategories.filter((c: IEventCategory) => { return c.Id == categoryId; })[0].Color;
        return this.FestivitiesCategories.filter((c: IFestivityCategory) => { return c.Id == categoryId; })[0].Color;
    }

    GetCategoryLogicalState(categoryId: number): number {
        var matches: IEventCategory[] = this.EventsCategories.filter((c: IEventCategory) => { return c.Id == categoryId; });
        return matches.length == 0 ? null : matches[0].LogicalState;
    }

    GetCategoryByLogicalState(logicalState: number, filterWithUserRights: boolean = true): IEventCategory {
        var categories: IEventCategory[] = (filterWithUserRights ? this.filterCategories(this.EventsCategories) : this.EventsCategories).filter((c: IEventCategory) => { return c.LogicalState == logicalState; });
        if (categories.length == 0)
            return null;
        return categories[0];
    }

    GetCategoryById(categoryId: number, festivity: boolean = false): any {
        if (!festivity)
            return this.EventsCategories.filter((c: IEventCategory) => { return c.Id == categoryId; })[0];
        return this.FestivitiesCategories.filter((c: IFestivityCategory) => { return c.Id == categoryId; })[0];
    }

    findEntities(query: ISelect2Query) {
        this.GetEventsCategories(query);
    }

    findEntity(element, callback) {
        this.GetEventsCategory(element, callback);
    }

    private filterCategories(categories: IEventCategory[]): IEventCategory[] {
        return categories.filter((c: IEventCategory) => {
            return (c.LogicalState == ProlifeSdk.PlannedEventState && this.authorizationService.isAuthorized("Agenda_PlanEvents"))
                || (c.LogicalState == ProlifeSdk.ConfirmedEventState && this.authorizationService.isAuthorized("Agenda_ConfirmEvents"))
                || (c.LogicalState == ProlifeSdk.AbortedEventState && this.authorizationService.isAuthorized("Agenda_AbortEvents"))
                || (c.LogicalState == ProlifeSdk.WaitingListEventState && this.authorizationService.isAuthorized("Agenda_PutEventsOnWaitingList"))
                || c.LogicalState == ProlifeSdk.LogicalStateNotSet; });
    }
}