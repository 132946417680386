import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 21/06/2018
 * Time: 10:45
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { WarehouseCostsPage } from "./AdministrativeReportPages/WarehouseCostsPage";
import { WorkHoursCostsPage } from "./AdministrativeReportPages/WorkHoursCostsPage";
import { PurchasesCostsPage } from "./AdministrativeReportPages/PurchasesCostsPage";
import { EstimatesPage } from "./AdministrativeReportPages/EstimatesPage";
import { CustomerOrdersPage } from "./AdministrativeReportPages/CustomerOrdersPage";
import { DDTsPage } from "./AdministrativeReportPages/DDTsPage";
import { InvoicesPage } from "./AdministrativeReportPages/InvoicesPage";
import { SchedulesPage } from "./AdministrativeReportPages/SchedulesPage";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IAdministrativeReportPage } from "../../../../interfaces/IAdministrativeReportPage";

export class JobOrderAdministrativeReportFactory implements IJobOrderEditorPanelFactory {
    private authorizationService:IAuthorizationService;

    constructor(private serviceLocator:IServiceLocator, public Color:string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderAdministrativeReport(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewAdministrativeReportDashboard");
    }
}

class JobOrderAdministrativeReport implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.AdministrativeReport);
    TemplateUrl = "joborder/templates/joborderdetail/administrative-report";
    TemplateName = "report";

    Pages : ko.ObservableArray<IAdministrativeReportPage> = ko.observableArray();
    ActivePage : ko.Observable<IAdministrativeReportPage> = ko.observable();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {
        this.Pages.push(new WarehouseCostsPage(serviceLocator, editor));
        this.Pages.push(new WorkHoursCostsPage(serviceLocator, editor));
        this.Pages.push(new PurchasesCostsPage(serviceLocator, editor));
        this.Pages.push(new EstimatesPage(serviceLocator, editor));
        this.Pages.push(new CustomerOrdersPage(serviceLocator, editor));
        this.Pages.push(new DDTsPage(serviceLocator, editor));
        this.Pages.push(new InvoicesPage(serviceLocator, editor));
        this.Pages.push(new SchedulesPage(serviceLocator, editor));

        const defaultPage = this.Pages().filter((p : IAdministrativeReportPage) => p.isDefault());
        if (defaultPage.length > 0) {
            this.selectPage(defaultPage[0], false);
        }
    }

    public selectPage(page : IAdministrativeReportPage, autoLoad  = true) {
        this.ActivePage(page);
        if(autoLoad)
            page.load();
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {
    }

    @Delay()
    load(): Promise<boolean> {
        return this.ActivePage().load();
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {

    }

    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) { this.load(); }

    OnWorkflowChanged(workflowId:number) { this.load(); }

    OnWorkflowCreated(workflowId:number) { this.load(); }

    OnWorkflowImportedFromTemplate(workflowId:number) { this.load(); }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}
}