import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { JobOrderClassificationUtils } from "../JobOrder/jobOrder/settings/ui/JobOrderClassificationUtils";

const styleSheet = jss.createStyleSheet({
    jobOrderMetadata: {
        alignItems: "center",
        height: "28px",
        fontWeight: 300,

        "& .icon": {
            textAlign: "center",
            height: "18px",
            width: "18px",
            lineHeight: "18px",
        },

        "& .description": {
            height: "28px",
            lineHeight: "1em",

            "&.inline": {
                alignItems: "center",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type JobOrderMetadataProps = {
    id: number;
    name: string;
    code: string;
    parentId: number;
    entityKeyId: number;
    entityType: string;
    hasChildren: boolean;
    inline?: boolean;
};

export function JobOrderMetadata(props: JobOrderMetadataProps) {
    const C = require("./JobOrderMetadata")._JobOrderMetadata as typeof _JobOrderMetadata;
    return <C {...props} />;
}

export class _JobOrderMetadata {
    static defaultProps: Partial<JobOrderMetadataProps> = {
        inline: false,
    };

    constructor(private props: JobOrderMetadataProps) {}

    render() {
        const classNames = ComponentUtils.classNames("flex-container", classes.jobOrderMetadata);

        const descriptionClassNames = ComponentUtils.classNames("flex-fill description", {
            "flex-container": this.props.inline,
            inline: this.props.inline,
        });

        const icon = JobOrderClassificationUtils.getIconFor({
            Id: this.props.id,
            Name: this.props.name,
            Code: this.props.code,
            ParentId: this.props.parentId,
            EntityKeyId: this.props.entityKeyId,
            EntityType: this.props.entityType,
            HasChildren: this.props.hasChildren,
        });

        return (
            <>
                {this.props.id && (
                    <div className={classNames}>
                        <div className="icon" style={{ backgroundColor: icon.background, color: icon.foreground }}>
                            <i className={icon.icon}></i>
                        </div>
                        <div className={descriptionClassNames}>
                            {this.props.inline && this.renderInline()}
                            {!this.props.inline && this.renderStandard()}
                        </div>
                    </div>
                )}
                {!this.props.id && <></>}
            </>
        );
    }

    private renderStandard() {
        return (
            <>
                <span style={{ display: "inline-block", lineHeight: "1em" }}>{this.props.name}</span>
                <br />
                <small style={{ display: "inline-block", lineHeight: "1em" }} className="text-muted">
                    {this.props.code}
                </small>
            </>
        );
    }

    private renderInline() {
        return (
            this.props.code && (
                <>
                    <span style={{ display: "inline-block", lineHeight: "1em" }}>{this.props.code}</span>&nbsp;-&nbsp;
                    <span style={{ display: "inline-block", lineHeight: "1em" }}>{this.props.name}</span>
                </>
            )
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderMetadata);
}
