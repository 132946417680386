import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../Core/DependencyInjection";
import { ICustomersService } from "../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../../ProlifeSdk/interfaces/customer/ICustomer";

export interface ICustomersProvider {
    findCustomers(query: any);
    findCustomer(element, callback);
}

export class CustomersProvider {
    private customersLastTimeout: ReturnType<typeof setTimeout>;

    @LazyImport(ProlifeSdk.CustomersServiceType)
    private customersService: ICustomersService;

    constructor(
        target: ICustomersProvider,
        private resultGroupTitle: string = ProlifeSdk.TextResources.Desktop.Customers,
        private headerGroupToInject: ko.Observable<any> = ko.observable(null)
    ) {
        target.findCustomers = this.findCustomers.bind(this);
        target.findCustomer = this.findCustomer.bind(this);
    }

    private findCustomers(query: any) {
        if (this.customersLastTimeout) {
            clearTimeout(this.customersLastTimeout);
        }

        this.customersLastTimeout = setTimeout(() => {
            this.customersService
                .getAllCustomersEnvelope(query.term, true)
                .then((customers: ICustomer[]) => {
                    let resultList: any[] = [];

                    const queryResult: any[] = customers.map((customer: ICustomer) => {
                        return {
                            id: customer.IdCliente,
                            text: customer.FormattedContactName,
                            RagioneSociale: customer.RagioneSociale,
                            PIVA: customer.PIVA,
                        };
                    });

                    if (!this.headerGroupToInject()) resultList = queryResult;
                    else {
                        resultList.push(this.headerGroupToInject());
                        resultList.push({
                            text: this.resultGroupTitle,
                            children: queryResult,
                        });
                    }

                    query.callback({
                        results: resultList,
                    });
                })
                .catch(() => {
                    query.callback({ results: [] });
                });
        }, 500);
    }

    private findCustomer(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id)) {
            this.customersService.getCustomerById(id).then((customer: ICustomer) =>
                callback(
                    customer
                        ? {
                              id: customer.IdCliente,
                              text: customer.FormattedContactName,
                              RagioneSociale: customer.RagioneSociale,
                              PIVA: customer.PIVA,
                          }
                        : null
                )
            );
        }
    }
}
