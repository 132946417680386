import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: n.angeli
 * Date: 19/02/13
 * Time: 12.51
 * To change this template use File | Settings | File Templates.
 */

 import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderStateEditingViewModel } from "./ui/JobOrderStateEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderStateObserver } from "../../interfaces/settings/IJobOrderStateObserver";
import { IJobOrderLogicalState } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderLogicalState";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class JobOrderStateSettingsManager implements IJobOrderStateSettingsManager {
	private ajaxService : IAjaxService;
	private jobOrderStates : IJobOrderState[] = [];
	private observers : IJobOrderStateObserver[] = [];
	private statiLogiciCommessa : IJobOrderLogicalState[] = [];
    private modulesService : IModulesService;

	constructor(private serviceLocator : IServiceLocator) {
		const settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
		settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.JobOrder.OrdersSettingsGroup);

		this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);

		this.statiLogiciCommessa.push({IdJobOrderLogicalState: 0, Descrizione: ProlifeSdk.TextResources.JobOrder.Open});
		this.statiLogiciCommessa.push({IdJobOrderLogicalState: 1, Descrizione: ProlifeSdk.TextResources.JobOrder.Closed});
	}

	getJobOrderLogicalState() :IJobOrderLogicalState[]{
		return this.statiLogiciCommessa || [];
	}

	addObserver(observer : IJobOrderStateObserver) : void {
		this.observers.push(observer);
	}

	removeObserver(observer : IJobOrderStateObserver) : void {
		const index = this.observers.indexOf(observer);
		if(index < 0) return;
		this.observers.splice(index, 1);
	}

	load(): Promise<IJobOrderState[]> {
		return this.ajaxService.Get<IJobOrderState[]>("JobOrder-api", "JobOrderState", { background: true })
			.then((jobOrderState: IJobOrderState[]) => this.jobOrderStates = jobOrderState);
	}

	getName(): string {
		return ProlifeSdk.JobOrderState;
	}

	getLabel(): string {
		return ProlifeSdk.TextResources.JobOrder.OrdersStatus;
	}

	hasEditingUI(): boolean {
		return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) || false;
	}

	getEditingUI(): IView {
		return {
			templateName: "jobOrderState",
			templateUrl: "jobOrder/templates/settings",
			viewModel: new JobOrderStateEditingViewModel(this.serviceLocator, this)
		};
	}

	getJobOrderStates() : IJobOrderState[] {
        return this.jobOrderStates;
	}

	getJobOrderStateById(stateId : number) : IJobOrderState {
		const states : IJobOrderState[] = this.jobOrderStates.filter((state : IJobOrderState) => state.IdJobOrderStateId == stateId);
		return states[0];
	}

	createOrUpdate(jobOrderState : IJobOrderState) : Promise<IJobOrderState> {
		if(jobOrderState.IdJobOrderStateId > 0) {
			return this.ajaxService.Put<IJobOrderState>("JobOrder-api", "JobOrderState", { methodData: jobOrderState})
				.then(this.onJobOrderStateChanged.bind(this));
		}

		return this.ajaxService.Post<IJobOrderState>("JobOrder-api", "JobOrderState", { methodData: jobOrderState})
			.then(this.onJobOrderStateAdded.bind(this));
	}

	remove(IdJobOrderStateId : number) : Promise<void> {
		return this.ajaxService.Delete<void>("JobOrder-api", "JobOrderState/" + IdJobOrderStateId, {})
			.then(this.onJobOrderStateDeleted.bind(this, IdJobOrderStateId));
	}

    moveState(stateId : number, beforeId : number, afterId : number) : Promise<IJobOrderState> {
        return this.ajaxService.Post<IJobOrderState>("JobOrder-api/JobOrderState", "UpdatePosition", {
            methodData: {
                id : stateId,
                beforeId: beforeId,
                afterId: afterId
            }
        }).then(this.onJobOrderStateChanged.bind(this));
    }

	private onJobOrderStateChanged(jobOrderState : IJobOrderState) {
		const index : number = this.getIndexOfJobOrder(jobOrderState.IdJobOrderStateId);
		this.jobOrderStates[index] = jobOrderState;

		this.observers.forEach((obs) => obs.onJobOrderStateChanged(jobOrderState));

		return jobOrderState;
	}

	private onJobOrderStateAdded(jobOrderState : IJobOrderState) {
		this.jobOrderStates.push(jobOrderState);
		this.observers.forEach((obs) => obs.onJobOrderStateAdded(jobOrderState));

		return jobOrderState;
	}

	private onJobOrderStateDeleted(IdJobOrderStateId : number) {
		const index : number = this.getIndexOfJobOrder(IdJobOrderStateId);
		this.jobOrderStates.splice(index, 1);

		this.observers.forEach((obs) => obs.onJobOrderStateDeleted(IdJobOrderStateId));

		return IdJobOrderStateId;
	}

	private getIndexOfJobOrder(IdJobOrderStateId : number) : number {
		const item = this.jobOrderStates.filter((jobOrderState : IJobOrderState) => jobOrderState.IdJobOrderStateId == IdJobOrderStateId)[0];
		return this.jobOrderStates.indexOf(item);
	}
}