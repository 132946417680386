import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { SourcesSelectionDocumentDataWizardStep } from "./SourcesSelectionDocumentDataWizardStep";
import { DocumentDataWizardStep } from "./DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import {
    IDocumentBuilderDocumentRows,
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRelatedWorkflows,
    IDocumentCurrencyViewModel,
} from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";

export class ProcessedRow {
    Description: ko.Observable<string> = ko.observable();
    Amount: ko.Observable<number> = ko.observable();
    UnitPrice: ko.Observable<number> = ko.observable();
    UnitPriceInDocumentCurrency: ko.Observable<number> = ko.observable();
    Discounts: ko.Observable<string> = ko.observable();
    NetUnitPrice: ko.Observable<number> = ko.observable();
    NetUnitPriceInDocumentCurrency: ko.Observable<number> = ko.observable();
    TotalPrice: ko.Observable<number> = ko.observable();
    TotalPriceInDocumentCurrency: ko.Observable<number> = ko.observable();
    FKOffset: ko.Observable<number> = ko.observable();
    OffsetCode: ko.Observable<string> = ko.observable();
    FKVatCode: ko.Observable<number> = ko.observable();
    VatCode: ko.Observable<string> = ko.observable();

    FKDocument: ko.Observable<number> = ko.observable();
    Order: ko.Observable<number> = ko.observable();

    constructor(private data: IDocumentBuilderDocumentRows, destDocumentCurrency: IDocumentCurrencyViewModel) {
        this.Description(data.Description);
        this.Amount(data.Amount);
        this.UnitPrice(data.UnitPrice);
        this.UnitPriceInDocumentCurrency(data.UnitPriceInDocumentCurrency);
        this.Discounts(data.Discounts);
        this.NetUnitPrice(data.NetUnitPrice);
        this.NetUnitPriceInDocumentCurrency(data.NetUnitPriceInDocumentCurrency);
        this.TotalPrice(data.TotalPrice);
        this.TotalPriceInDocumentCurrency(data.TotalPriceInDocumentCurrency);
        this.FKOffset(data.FKOffset);
        this.OffsetCode(data.OffsetCode);
        this.FKVatCode(data.FKVatCode);
        this.VatCode(data.VatCode);
        this.FKDocument(data.FKDocument);
        this.Order(data.Order);

        this.Amount.subscribe((newAmount: number) => {
            this.TotalPriceInDocumentCurrency(
                CurrencyUtils.applyCurrencyRoundingRules(
                    this.NetUnitPriceInDocumentCurrency() * newAmount,
                    destDocumentCurrency
                )
            );
            this.TotalPrice(this.TotalPriceInDocumentCurrency().ToEuro(destDocumentCurrency));
        });
    }

    public getData(): IDocumentBuilderDocumentRows {
        return Object.assign(this.data, {
            Amount: this.Amount(),
            Description: this.Description(),
            UnitPrice: this.UnitPrice(),
            UnitPriceInDocumentCurrency: this.UnitPriceInDocumentCurrency(),
            Discounts: this.Discounts(),
            NetUnitPrice: this.NetUnitPrice(),
            NetUnitPriceInDocumentCurrency: this.NetUnitPriceInDocumentCurrency(),
            TotalPrice: this.TotalPrice(),
            TotalPriceInDocumentCurrency: this.TotalPriceInDocumentCurrency(),
            FKOffset: this.FKOffset(),
            OffsetCode: this.OffsetCode(),
            FKVatCode: this.FKVatCode(),
            VatCode: this.VatCode(),

            FKDocument: this.FKDocument(),
            Order: this.Order(),
        });
    }
}

export interface IDocumentDataWizardRow {
    IsSelected: ko.Observable<boolean>;
    Row: ProcessedRow;
    OriginatingRows: IDocumentBuilderDocumentOriginatingRows[];
    SourceRows: IDocumentDataWizardRow[];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[];
}

export abstract class DocumentDataWizardDataSource extends DocumentDataWizardStep {
    abstract HasAuthorization(): boolean;
}

export class ImportDocumentDataWizard implements IDialog {
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    title: string;
    templateName: string;
    templateUrl: string;
    modal: { close: (result?: any) => void };

    public Steps: ko.ObservableArray<DocumentDataWizardStep> = ko.observableArray();
    public CurrentStep: ko.Observable<DocumentDataWizardStep> = ko.observable();

    public IsLastStep: ko.Computed<boolean>;
    public CanGoBack: ko.Computed<boolean>;
    public CanFastForward: ko.Computed<boolean>;
    public FastForwardButtonName: ko.Computed<string>;

    private sourcesSelectionStep: SourcesSelectionDocumentDataWizardStep;

    constructor(protected initializationInfo: IWizardInitializationInfo) {
        this.title = String.format(TextResources.Invoices.DocumentWizardTitle, initializationInfo.DocumentName);
        this.sourcesSelectionStep = new SourcesSelectionDocumentDataWizardStep(this);

        this.IsLastStep = ko.computed(() => {
            const steps = this.Steps();
            if (this.CurrentStep() == this.sourcesSelectionStep) return false;

            return this.CurrentStep() == steps[steps.length - 1];
        });

        this.CanGoBack = ko.computed(() => {
            const steps = this.Steps();
            return this.CurrentStep() != steps[0];
        });

        this.CanFastForward = ko.computed(() => {
            const steps = this.Steps();
            const currentStep = this.CurrentStep();
            if (!currentStep) return false;

            const currentStepIndex = steps.indexOf(currentStep);
            const nextStep = steps[currentStepIndex + 1];
            return nextStep && nextStep.CanBeFastForwarded;
        });

        this.FastForwardButtonName = ko.computed(() => {
            const allSteps = this.Steps();
            if (allSteps.length === 0) return "";

            let fastForwardDestination = allSteps[allSteps.length - 1];

            for (let i = allSteps.length - 2; i >= 0; i--) {
                if (allSteps[i].CanBeFastForwarded) break;
                fastForwardDestination = allSteps[i];
            }

            return fastForwardDestination.Title.peek();
        });

        this.Initialize();
    }

    private async Initialize(): Promise<void> {
        await this.sourcesSelectionStep.Initialize(this.initializationInfo);
        this.Steps.push(this.sourcesSelectionStep);
        this.CurrentStep(this.sourcesSelectionStep);
    }

    public GetInitializationInfo() {
        return this.initializationInfo;
    }

    public SetDataSources(selected: DocumentDataWizardStep[]) {
        this.Steps([this.sourcesSelectionStep]);
        this.Steps.push(...selected);
    }

    public showModal(): Promise<IDocumentDataWizardRow[]> {
        return this.dialogService.ShowModal<IDocumentDataWizardRow[]>(
            this,
            "fullscreen",
            undefined,
            "prolifesdk/templates/wizard",
            "import-data-wizard-dialog"
        );
    }

    close(): void {
        this.modal.close();
    }

    async action(): Promise<void> {
        const currentStep = this.CurrentStep();
        const rows = await Promise.resolve(currentStep.OnNext());
        this.modal.close(rows);
    }

    async back(): Promise<void> {
        if (!this.CanGoBack()) return;

        const currentStep = this.CurrentStep();
        if (!currentStep.BeforeBack()) return;

        const currentStepIndex = this.Steps.indexOf(this.CurrentStep());
        const step = this.Steps()[currentStepIndex - 1];
        this.CurrentStep(step);
    }

    async next(): Promise<void> {
        const currentStep = this.CurrentStep();

        const warnings = currentStep.GetWarnings();
        if (warnings.length > 0) {
            const confirm = await this.dialogService.ConfirmAsync(
                warnings.join("</br></br>"),
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );
            if (!confirm) return;
        }

        if (!currentStep.CanGoNext()) return;

        this.dialogService.LockUI();

        try {
            const rows = await Promise.resolve(currentStep.OnNext()); //In questo modo se OnNext non ritorna una promise posso comunque farci await e non da problemi

            const currentStepIndex = this.Steps.indexOf(currentStep);
            const nextStep = this.Steps()[currentStepIndex + 1];
            await Promise.resolve(nextStep.OnShow(rows));

            this.CurrentStep(nextStep);
        } finally {
            this.dialogService.UnlockUI();
        }
    }

    async fastForward(): Promise<void> {
        this.dialogService.LockUI();

        let stepCanBeForwarded = true;
        try {
            do {
                const currentStep = this.CurrentStep();
                const currentStepIndex = this.Steps.indexOf(currentStep);
                const nextStep = this.Steps()[currentStepIndex + 1];
                if (!nextStep) break;

                await this.next();

                stepCanBeForwarded = nextStep.CanBeFastForwarded;
            } while (stepCanBeForwarded);
        } finally {
            this.dialogService.UnlockUI();
        }
    }

    renderTemplateNodes() {
        return [
            <div class="form-wizard" style="height : 100%">
                <div class="form-body" style="height : 100%; position : relative;">
                    <ul class="nav nav-pills nav-justified steps" data-bind="foreach : Steps">
                        <li data-bind="css : { active : $parent.CurrentStep() == $data }">
                            <a href="#" data-bind="click : function() {}" data-toggle="tab" class="step">
                                <span class="number" data-bind="text : $index() + 1" style="margin-right: 0px">
                                    1
                                </span>
                                <div class="desc">
                                    <i class="fa fa-check"></i>
                                    <ko-text data-bind="Title"></ko-text>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <div
                        class="tab-content"
                        style="padding: 0px;position: absolute;left: 0px;right: 0px;bottom: 0px;top: 90px;overflow-y: auto;overflow-x : hidden"
                        data-bind="tsxtemplate: CurrentStep"
                    ></div>
                </div>
            </div>,
        ];
    }
}
