import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IDocumentBuilderDocumentVatRows } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class DocumentVatRow {
    public VatDescription: ko.Observable<string> = ko.observable();
    public Nature : ko.Observable<string> = ko.observable();
    public ChargeableVatLabel : ko.Observable<string> = ko.observable();
    public Taxable : ko.Observable<number> = ko.observable();
    public VatPercentage : ko.Observable<number> = ko.observable();
    public Tax : ko.Observable<number> = ko.observable();
    public Total : ko.Observable<number> = ko.observable();
    public IsSplitPayment : ko.Computed<boolean>;

    constructor(private vatRow : IDocumentBuilderDocumentVatRows) {
        this.VatDescription(this.vatRow.VatDescription);
        this.Nature(this.vatRow.Nature);
        this.Taxable(this.vatRow.Taxable);
        this.VatPercentage(this.vatRow.VatPercentage);
        this.Tax(this.vatRow.Tax);
        this.Total(this.vatRow.Total);

        this.IsSplitPayment = ko.computed(() => {
            return this.vatRow.VatPaymentMode == ProlifeSdk.SplitPaymentVatChargeability;
        });

        this.ChargeableVatLabel(!this.vatRow.VatPaymentMode ? "" :
            (this.vatRow.VatPaymentMode == ProlifeSdk.ImmediateVatChargeability ? ProlifeSdk.TextResources.Invoices.ImmediateVatChargeability :
                (this.vatRow.VatPaymentMode == ProlifeSdk.PostponedVatChargeability ? ProlifeSdk.TextResources.Invoices.PostponedVatChargeability : ProlifeSdk.TextResources.Invoices.SplitPaymentVatChargeability)));
    }

    public getData(): IDocumentBuilderDocumentVatRows {
        return this.vatRow;
    }
}