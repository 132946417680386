import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IEstimateState, IEstimateStates } from "../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";

export type IEstimateStatesDataSourceModel = IDataSourceModel<number, IEstimateState>

export class EstimateStatesDataSource extends BaseDataSource<IEstimateStatesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.EstimateStates)
    private estimateStates : IEstimateStates;

    private viewDeleted: boolean;

    setViewDeleted(enabled : boolean) {
        this.viewDeleted = enabled;
    }

    getTitle(currentModel: IEstimateStatesDataSourceModel): string {
        return this.estimateStates.getLabel();
    }

    async getData(currentModel: IEstimateStatesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IEstimateStatesDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);
        
        let estimateStates = this.estimateStates.getEstimateStates(this.viewDeleted);
        
        return estimateStates
            .filter(d => splitFilters.filter(w => d.Descrizione.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: IEstimateStatesDataSourceModel, ids: (string | number)[]): Promise<IEstimateStatesDataSourceModel[]> {
        return this.estimateStates.getEstimateStates(true)
            .filter(p => ids.indexOf(p.IdStatoPreventivo) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IEstimateState): IEstimateStatesDataSourceModel {
        return {
            id: model.IdStatoPreventivo,
            title: model.Descrizione,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }

}