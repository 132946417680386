import * as ko from "knockout";
export class ListWithFixedHeader
{
    scrolling : boolean = false;
    listElement : JQuery = null;
    fixedHeader : JQuery = null;

    constructor(list, cssForFixedHeader)
    {
        var self = this;

        this.fixedHeader = $("<div class='fixed-header'></div>");

        if(cssForFixedHeader && cssForFixedHeader != null)
            this.fixedHeader.addClass(cssForFixedHeader);
        this.fixedHeader.hide();

        this.listElement = $(list);

        //Appendo l'header fake sul padre (wrapper)
        this.listElement
            .filter(function() { return this instanceof HTMLElement })
            .parent()
            .append(this.fixedHeader);

        //Aggancio l'handler che gestisce lo scrolling sula lista
        this.listElement
            .filter(function() { return this instanceof HTMLElement })
            .scroll(this.OnFixedHeaderListScroll.bind(this))
            .addClass("fixed-header-list");

        setTimeout(this.OnFixedHeaderListScroll.bind(this), 1000);
    }

    //Handler che gestisce lo scrolling della lista
    OnFixedHeaderListScroll()
    {
        if(this.scrolling)
            return;

        this.scrolling = true;

        setTimeout(function()
        {
            var $sectionOnTop : JQuery = null;

            //Cerco la sezione per la quale mostrare l'header in alto
            this.listElement.find("section").each(function(){
                var $section : JQuery = $(this);
                $sectionOnTop = $sectionOnTop == null || $section.position().top <= 0 ? $section : $sectionOnTop;
            });

            if($sectionOnTop != null)
                this.fixedHeader.show();

            //Imposto l'header fisso
            if($sectionOnTop != null)
            {
                var $header : JQuery = $($sectionOnTop.find("header")[0]);
                this.fixedHeader
                    .empty()
                    .html($header.html());
            }

            this.scrolling = false;
        }.bind(this), 50);
    }
}

export class FixedHeaderList
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        new ListWithFixedHeader(element, valueAccessor()["cssClasses"]);
    }
}

ko.bindingHandlers["fixedHeaderList"] = new FixedHeaderList();
