import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 03/04/13
 * Time: 14.42
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { StockTransactionReport } from "./Stock/StockTransactionReport";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IStocksUI } from "../../../ProlifeSdk/interfaces/warehouse/IStockService";

export class StocksServiceUI implements IStocksUI {
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
    }

    showStockTransactionReport(transactionId : number) : Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(new StockTransactionReport(transactionId, true), "warehouse-large-dialog", { noPrompt: true });
    }

    showStockTransactionReportForCommit(transactionId : number) : Promise<boolean> {
        return this.dialogsService.ShowModal<boolean>(new StockTransactionReport(transactionId), "warehouse-large-dialog");
    }
}