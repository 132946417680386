import * as ko from "knockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidRolesForServiceOrder, IValidRole, IValidWorkTimeCategory, IValidRolesForTask, IWorkedHoursService } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IException } from "../../../Core/interfaces/IException";

export interface IWorkedHoursDefaultsProvider {
    Id: number;
    DefaultWorkPlace?: number;
    DefaultTravelDistance?: number;
    DefaultCallRight?: boolean;
    WorkedHoursDefaultsStrictMode?: boolean;
    HideAdministrativeFieldsOnWorkedHours?: boolean;
    HasRoles?: boolean;
    HasWorkTimeCategories?: boolean;
}

export interface IResourceRoleAndCategorySelectorOptions {
    Resource : ko.Observable<number>, 
    JobOrder: ko.Observable<number>, 
    Task: ko.Observable<IWorkedHoursDefaultsProvider> | ko.Computed<IWorkedHoursDefaultsProvider> | null, 
    ServiceOrder: ko.Observable<number>,
    ServiceOrders: ko.ObservableArray<IValidRolesForServiceOrder>, 
    Role: ko.Observable<number>, 
    Roles: ko.ObservableArray<IValidRole>,
    Category: ko.Observable<number>,
    Categories: ko.ObservableArray<IValidWorkTimeCategory>,
    Date : ko.Observable<Date>,
    IgnoreFirstRun: boolean,
    DelayComputeds: boolean,
    Initializing: () => boolean,
    OnServiceOrdersLoaded?: () => void;
}

export class ResourceRoleAndCategorySelector {
    private validRoles : IValidRolesForTask;

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private options : IResourceRoleAndCategorySelectorOptions) {
        if (!options.Task)
            options.Task = ko.observable();

        if (!this.options.DelayComputeds) {
            this.createComputed();
        }
    }

    public async load(): Promise<void> {
        if (this.options.DelayComputeds) {
            await this.createComputed();
        }
    }

    createComputed(): Promise<void> {
        let frA = this.options.IgnoreFirstRun;
        let frB = this.options.IgnoreFirstRun;
        let frC = this.options.IgnoreFirstRun;

        let resolver: () => void;
        const serviceOrdersLoadingPromise: Promise<void> = new Promise((resolve) => { resolver = resolve; });

        ko.computed(() => {
            this.options.Resource();
            this.options.JobOrder();
            this.options.Task();
            this.options.Date();

            if (frA) {
                frA = false;
                resolver();
                return;
            }

            this.loadValidServiceOrders()
                .then(() => resolver())
                .catch(() => resolver());

        }).extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } });

        ko.computed(() => {
            this.options.ServiceOrders();
            this.options.ServiceOrder();
            
            if (frB) {
                frB = false;
                return;
            }

            this.loadValidRoles();
        });

        ko.computed(() => {
            this.options.ServiceOrders();
            this.options.ServiceOrder();
            this.options.Roles();
            this.options.Role();
            
            if (frC) {
                frC = false;
                return;
            }

            this.loadValidWorkTimeCategories();
        });

        return serviceOrdersLoadingPromise;
    }

    private async loadValidServiceOrders() : Promise<void> {
        const resourceId = this.options.Resource.peek();
        const jobOrderId = this.options.JobOrder.peek();
        const taskId = this.options.Task.peek()?.Id;
        const date = this.options.Date.peek();

        if (!date || !resourceId || !jobOrderId) {
            this.reset();
            return;
        }

        try {
            this.validRoles = await this.workedHoursService.GetValidRolesForTask(resourceId, taskId, jobOrderId, date)    
        } catch (e) {
            const ex = e as IException;
            this.infoToastService.Error(ex.ExceptionMessage);
            this.reset();
        }

        this.options.ServiceOrders(this.validRoles?.ServiceOrders ?? []);
        const serviceOrder = this.validRoles?.ServiceOrders?.firstOrDefault();

        // Commentato perché in alcuni casi impediva il corretto caricamento dei dati. Adesso ordini di servizio, mansioni e tipologie orarie vengono sempre impostati al valore di default nella fase di
        // caricamento di questa classe e, successivamente, questi dati vengono sovrascritti con i dati effettivi in fase di caricamento delle ore lavorate
        /* if (!this.options.Initializing()) */ 
        this.options.ServiceOrder(serviceOrder?.ServiceOrderId);

        this.options.OnServiceOrdersLoaded && this.options.OnServiceOrdersLoaded();
    }

    private reset(): void {
        this.validRoles = null;
        this.options.ServiceOrders([]);
        this.options.ServiceOrder(undefined);
        this.options.Roles([]);
        this.options.Role(undefined);
        this.options.Categories([]);
        this.options.Category(undefined);
    }

    private loadValidRoles() {
        if (!this.validRoles || !this.options.ServiceOrder.peek()) {
            this.options.Roles([]);
            this.options.Role(undefined);
            return;
        }

        const serviceOrder = this.validRoles.ServiceOrders.firstOrDefault(s => s.ServiceOrderId === this.options.ServiceOrder.peek());
        if (!serviceOrder)
            return;

        this.options.Roles(serviceOrder?.Roles ?? []);

        // Commentato perché in alcuni casi impediva il corretto caricamento dei dati. Adesso ordini di servizio, mansioni e tipologie orarie vengono sempre impostati al valore di default nella fase di
        // caricamento di questa classe e, successivamente, questi dati vengono sovrascritti con i dati effettivi in fase di caricamento delle ore lavorate
        /* if (!this.options.Initializing()) */
        this.options.Role(this.options.Roles.peek().firstOrDefault(r => r.IsDefault)?.RoleId);
    }

    private loadValidWorkTimeCategories() {
        if (!this.validRoles || !this.options.ServiceOrder.peek()) {
            this.options.Categories([]);
            this.options.Category(undefined);
            return;
        }

        const serviceOrder = this.validRoles.ServiceOrders.firstOrDefault(s => s.ServiceOrderId === this.options.ServiceOrder.peek());
        if (!serviceOrder)
            return;
        
        const role = serviceOrder.Roles.firstOrDefault(r => r.RoleId === this.options.Role.peek());
        if (!role)
            return;

        this.options.Categories(serviceOrder.WorkTimeCategories);

        // Commentato perché in alcuni casi impediva il corretto caricamento dei dati. Adesso ordini di servizio, mansioni e tipologie orarie vengono sempre impostati al valore di default nella fase di
        // caricamento di questa classe e, successivamente, questi dati vengono sovrascritti con i dati effettivi in fase di caricamento delle ore lavorate
        /* if (this.options.Initializing())
            return; */

        const selectedTask = this.options.Task();

        if (!selectedTask) {
            this.findAndSetWorkTimeCategory(serviceOrder, role);
        } else {
            if (selectedTask.WorkedHoursDefaultsStrictMode)
                this.setWorkTimeCategoryInTaskStrictMode(serviceOrder);
            else
                this.setWorkTimeCategoryInTaskNonStrictMode(serviceOrder, role);
        }
    }

    private setWorkTimeCategoryInTaskNonStrictMode(serviceOrder: IValidRolesForServiceOrder, role: IValidRole) {
        const found = this.setWorkTimeCategoryInTaskStrictMode(serviceOrder);
        if (!found)
            this.findAndSetWorkTimeCategory(serviceOrder, role);
    }

    private setWorkTimeCategoryInTaskStrictMode(serviceOrder: IValidRolesForServiceOrder): boolean {
        const defaultWtc = serviceOrder.WorkTimeCategories.firstOrDefault(w => w.IsDefault && w.SpecifiedOnTask);
        this.options.Category(defaultWtc?.WorkTimeCategoryId);
        return !!defaultWtc;
    }

    private findAndSetWorkTimeCategory(serviceOrder: IValidRolesForServiceOrder, role: IValidRole) {
        const wtc = serviceOrder.WorkTimeCategories.firstOrDefault(w => w.WorkTimeCategoryId === role.DefaultWorkTimeCategory) ?? serviceOrder.WorkTimeCategories.firstOrDefault(w => w.IsDefault);
        this.options.Category(wtc.WorkTimeCategoryId);
    }
}