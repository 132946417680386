import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/02/13
 * Time: 9.48
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { VatNatureCodesDataSource } from "../../../../DataSources/VatNatureCodesDataSource";
import { VatRegulationsCodeDataSource } from "../../../../DataSources/VatRegulationCodesDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IVatRegulationCode, IVatNatureCode } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IIvaMode, IIvaModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";

export interface IIvaModeProvider {
    getData() : IIvaMode;
    update(ivaMode : IIvaMode) : void;
    setDefault(isDefault : boolean) : void;
}

export interface IVatRegulations {
    Nature: string;
    Code: string;
    Description: string;
}

export interface ICategory {
    Id: number;
    Descrizione: string;
    Nature: string;
}

export interface IChargeableVatCode {
    Id: string;
    Label: string;    
}

export class IvaModesEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IIvaModeProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();

    ShowDeleted : ko.Observable<boolean> = ko.observable(false);

    private updating: boolean = false;

    constructor(private ivaModesManager : IIvaModes)
    {
        this.title = ivaModesManager.getLabel();
        this.RefreshList();
        this.ShowDeleted.subscribe(this.RefreshList.bind(this));
    }

    private RefreshList()
    {
        this.elements([]);
        var ivaModes = this.ivaModesManager.getIvaModes(this.ShowDeleted());
        ivaModes.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(ivaMode : IIvaMode) {
        this.elements.push(new IvaModeViewModel(this, ivaMode));
    }

    public addNewSetting() {
        var ivaModeViewModel = new IvaModeViewModel(this, {
            Descrizione: "",
            CodiceIVA: this.newSettingName(),
            Iva: 0,
            IvaDefault: 0,
            Imponibile: 0,
            Eliminato: 0,
            Nature: '',
            VatRegulationsCode: '',
            ChargeableVat: '',
            SubjectToWithholdingTax: false,
            SubjectToStampDuty: false
        });

        this.createOrUpdateEntry(ivaModeViewModel)
            .then(() => {
                this.elements.push(ivaModeViewModel);
                this.newSettingName("");
            });
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IIvaModeProvider): Promise<IIvaMode>
    {
        if (this.updating)
            return Promise.resolve<IIvaMode>(undefined);

        this.updating = true;

        var appearance = element.getData();
        return this.ivaModesManager.createOrUpdate(appearance)
            .then((updatedAppearance: IIvaMode) => {
                element.update(updatedAppearance);
                return updatedAppearance;
            })
            .finally(() => {
                this.updating = false;
            });
    }

    public deleteEntry(element : IIvaModeProvider): Promise<void>
    {
        var appearance = element.getData();
        return this.ivaModesManager.remove(appearance.IdTipoIVA).then(() => {
            if(this.ShowDeleted())
                return;

            this.elements.remove(element);
        });
    }

    public setDefault(element : IIvaModeProvider) {
        this.elements().forEach((ivaMode) => {
            ivaMode.setDefault(ivaMode === element);
        })
    }
}

class IvaModeViewModel implements IIvaModeProvider, IDataSourceListener {
    isDefault : ko.Observable<boolean> = ko.observable(false);
    name : ko.Observable<string> = ko.observable();
    code : ko.Observable<string> = ko.observable();
    value : ko.Observable<number> = ko.observable();
    IsDeleted : ko.Observable<boolean> = ko.observable(false);
    AccountingSoftwareCode : ko.Observable<string> = ko.observable();
    Categoria : ko.Observable<string> = ko.observable();

    Categorie : ko.ObservableArray<ICategory> = ko.observableArray([]);

    Nature: ko.Observable<string> = ko.observable();
    VatNatureCodesDataSource: VatNatureCodesDataSource;

    VatRegulationsCode: ko.Observable<string> = ko.observable();
    VatRegulationCodesDataSource: VatRegulationsCodeDataSource;

    ChargeableVat: ko.Observable<string> = ko.observable();
    FilteredChargeableVatCodes: ko.Computed<IChargeableVatCode[]>;

    SubjectToWithholdingTax: ko.Observable<boolean> = ko.observable();
    SubjectToStampDuty: ko.Observable<boolean> = ko.observable();

    private ChargeableVatCodes: ko.ObservableArray<IChargeableVatCode> = ko.observableArray([]);

    hasFocus : ko.Computed<boolean>;
    codeHasFocus : ko.Observable<boolean> = ko.observable(false);
    valueHasFocus : ko.Observable<boolean> = ko.observable(false);
    nameHasFocus : ko.Observable<boolean> = ko.observable(false);

    valueIsDisabled: ko.Observable<boolean> = ko.observable(false);

	withError : ko.Observable<boolean> = ko.observable(false);

    private updating : boolean = false;
    private loading : boolean = false;

    constructor(private container : IvaModesEditingViewModel, private ivaMode: IIvaMode) {
        this.VatNatureCodesDataSource = new VatNatureCodesDataSource();
        this.VatRegulationCodesDataSource = new VatRegulationsCodeDataSource();
        this.populateLists();

        this.loading = true;

        this.FilteredChargeableVatCodes = ko.computed(() => {
            return this.ChargeableVatCodes().filter((c) => !(this.Nature() || "").startsWith(ProlifeSdk.N6_ReverseCharge) || c.Id != ProlifeSdk.SplitPaymentVatChargeability);
        });

        this.Categoria.subscribe((value: string) => {
            var selectedCategory: any[] = this.Categorie().filter((c) => c.Descrizione == value);

            if (selectedCategory.length == 0 || selectedCategory[0].Id == 0) {
                this.valueIsDisabled(false);
                this.Nature(null);
                this.VatRegulationsCode(null);
                return;
            }

            this.valueIsDisabled(true);
            this.value(0);
        });

        this.name.subscribe(this.onDataChanged.bind(this));
        this.AccountingSoftwareCode.subscribe(this.onDataChanged.bind(this));
        this.code.subscribe(this.onDataChanged.bind(this));
        this.value.subscribe(this.onDataChanged.bind(this));
        this.isDefault.subscribe(this.onDataChanged.bind(this));
        this.Categoria.subscribe(this.onDataChanged.bind(this));

        /* this.Nature.subscribe(this.onDataChanged.bind(this));
        this.VatRegulationsCode.subscribe(this.onDataChanged.bind(this)); */
        this.ChargeableVat.subscribe(this.onDataChanged.bind(this));
        this.SubjectToWithholdingTax.subscribe(this.onDataChanged.bind(this));
        this.SubjectToStampDuty.subscribe(this.onDataChanged.bind(this));

        this.hasFocus = ko.computed(() => this.codeHasFocus() || this.valueHasFocus() || this.nameHasFocus());

        this.update(ivaMode);
                
        setTimeout(() => this.loading = false, 500); // TODO A che serve?? Problemi con gli switch in interfaccia??
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<string, IVatNatureCode|IVatRegulationCode>): void {
        if (sender === this.VatNatureCodesDataSource) {
            this.VatRegulationsCode(null);
            this.VatRegulationCodesDataSource.setNatureCode(model?.id)
        } else {
            this.name(model?.model?.Description);
        }

        this.onDataChanged(model?.model?.Description);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<string, IVatNatureCode|IVatRegulationCode>): void {
        if (sender === this.VatNatureCodesDataSource) {
            this.VatRegulationsCode(null);
            this.VatRegulationCodesDataSource.setNatureCode(null)
        }

        this.onDataChanged(model?.model?.Description);
    }
    
    public Delete()
    {
        this.container.deleteEntry(this).then(() => {
            this.IsDeleted(true);
        });
    }

    public Restore()
    {
        this.IsDeleted(false);
        this.container.createOrUpdateEntry(this);
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating || this.loading) return;
        this.container.createOrUpdateEntry(this);
    }

    getData() : IIvaMode
    {
        var ivaMode : IIvaMode = <IIvaMode> $.extend({}, this.ivaMode);
        ivaMode.IvaDefault = this.isDefault() ? 1 : 0;
        ivaMode.Descrizione = this.name();
        ivaMode.AccountingSoftwareCode = this.AccountingSoftwareCode();
        ivaMode.CodiceIVA = this.code();
        ivaMode.Imponibile = this.Categorie().filter((cat : any) => cat.Descrizione == this.Categoria())[0].Id || 0;
        ivaMode.Iva = ivaMode.Imponibile > 0 ? 0 : this.value();
        ivaMode.Eliminato = this.IsDeleted() ? 1 : 0;
        ivaMode.Nature = this.Nature();
        ivaMode.VatRegulationsCode = this.VatRegulationsCode();
        ivaMode.ChargeableVat = this.ChargeableVat();
        ivaMode.SubjectToWithholdingTax = this.SubjectToWithholdingTax();
        ivaMode.SubjectToStampDuty = this.SubjectToStampDuty();

        return ivaMode;
    }

    update(ivaMode : IIvaMode) : void {
        this.updating = true;

        this.ivaMode = ivaMode;
        this.IsDeleted(ivaMode.Eliminato == 1);
        this.isDefault(ivaMode.IvaDefault != 0);
        this.name(ivaMode.Descrizione);
        this.AccountingSoftwareCode(ivaMode.AccountingSoftwareCode);
        this.code(ivaMode.CodiceIVA);
        this.value(ivaMode.Iva);
        this.Categoria(this.Categorie()[ivaMode.Imponibile].Descrizione);
        this.Nature(this.ivaMode.Nature);
        this.VatRegulationsCode(this.ivaMode.VatRegulationsCode);
        this.ChargeableVat(this.ivaMode.ChargeableVat);
        this.SubjectToWithholdingTax(this.ivaMode.SubjectToWithholdingTax);
        this.SubjectToStampDuty(this.ivaMode.SubjectToStampDuty);

        this.VatRegulationCodesDataSource.setNatureCode(this.ivaMode.Nature);

        this.updating = false;
    }

    setDefault(isDefault : boolean) : void {
        if(isDefault != this.isDefault())
            this.isDefault(isDefault);
    }

    setCategory(category: ICategory) {
        this.Categoria(category.Descrizione);
    }

    private populateLists(): void {
        var categories: ICategory[] = [];

        categories.push({ Id: 0, Descrizione: ProlifeSdk.TextResources.Invoices.Taxable, Nature: "" });
        categories.push({ Id: 1, Descrizione: ProlifeSdk.TextResources.Invoices.NotTaxable, Nature: "" });

        this.Categorie(categories);

        var chargeableVatCodes: IChargeableVatCode[] = [];

        chargeableVatCodes.push({ Id: ProlifeSdk.ImmediateVatChargeability, Label: ProlifeSdk.TextResources.Invoices.ImmediateVatChargeability });
        chargeableVatCodes.push({ Id: ProlifeSdk.PostponedVatChargeability, Label: ProlifeSdk.TextResources.Invoices.PostponedVatChargeability });
        chargeableVatCodes.push({ Id: ProlifeSdk.SplitPaymentVatChargeability, Label: ProlifeSdk.TextResources.Invoices.SplitPaymentVatChargeability });

        this.ChargeableVatCodes(chargeableVatCodes);
    }
}