import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DropdownList } from "../../../../Components/DropdownList";
import { NumberInput } from "../../../../Components/NumberInput";
import { CheckBox } from "../../../../Components/Checkbox";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IWorkedHoursService, IWorkPlace } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { RolesDataSource } from "../../../../DataSources/RolesDataSource";
import { Select } from "../../../../Components/Select";
import { WorkTimeCategoriesDataSource } from "../../../../DataSources/WorkTimeCategoriesDataSource";
import { ITodoListService, ReportingType } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

export type DefaultRole = {
    RoleId: ko.Observable<number>;
    Order: ko.Observable<number>;
}

export type DefaultWorkTimeCategory = {
    WorkTimeCategoryId: ko.Observable<number>;
    Order: ko.Observable<number>;
}

type WorkedHoursDefaultsEditorProps = {
    hideTitle?: boolean;
    reportingType?: ko.Observable<number>;
    editableReportingType?: boolean;

    workPlace: ko.Observable<number>;
    travelDistance: ko.Observable<number>;
    forceDataOnWorkedHours: ko.Observable<boolean>;
    ddc: ko.Observable<boolean>;
    hideAdministrativeDataOnWorkedHours: ko.Observable<boolean>;
    defaultRoles: ko.ObservableArray<DefaultRole>;
    defaultWorkTimeCategories: ko.ObservableArray<DefaultWorkTimeCategory>;
}

export function WorkedHoursDefaultsEditor(props: WorkedHoursDefaultsEditorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./WorkedHoursDefaultsEditor")._WorkedHoursDefaultsEditor as typeof _WorkedHoursDefaultsEditor;
    return <C {...props} />;
}

export class _WorkedHoursDefaultsEditor {
    static defaultProps: Partial<WorkedHoursDefaultsEditorProps> = {
        editableReportingType: false,
        hideTitle: false
    }

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    private workedHoursDefaultPlaces: IWorkPlace[];
    private rolesDataSource: RolesDataSource = new RolesDataSource();
    private workTimeCategoriesDataSource: WorkTimeCategoriesDataSource = new WorkTimeCategoriesDataSource();
    private reportingTypes: ReportingType[] = [];

    constructor(private props : WorkedHoursDefaultsEditorProps) {
        this.workedHoursDefaultPlaces = this.workedHoursService.getWorkPlaces();
        this.rolesDataSource.setShowResourceDefaultOption(true);
        this.workTimeCategoriesDataSource.setShowResourceDefaultOption(true);
        this.reportingTypes = this.todoListService.GetReportingTypes();
    }

    componentDidMount() {
        if (this.props.workPlace() === undefined || this.props.workPlace() === null)
            this.props.workPlace(this.workedHoursDefaultPlaces.firstOrDefault()?.Id);
    }

    private addRole() {
        this.props.defaultRoles.push({
            RoleId: ko.observable(),
            Order: ko.observable(this.props.defaultRoles().length + 1)
        });
    }

    private removeRole(role: DefaultRole) {
        this.props.defaultRoles.remove(role);
    }
    
    private addWorkTimeCategory() {
        this.props.defaultWorkTimeCategories.push({
            WorkTimeCategoryId: ko.observable(),
            Order: ko.observable(this.props.defaultRoles().length + 1)
        });
    }
    
    private removeWorkTimeCategory(workTimeCategory: DefaultWorkTimeCategory) {
        this.props.defaultWorkTimeCategories.remove(workTimeCategory);
    }
    
    private renderEmptyTableMessage() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workedHoursDefaults = this;
        return  <>
                    <ko-bind data-bind={{ if: workedHoursDefaults.props.forceDataOnWorkedHours}}>
                        <span style={{ whiteSpace: "break-spaces" }}>{TextResources.ProlifeSdk.EmptyTable}</span>
                    </ko-bind>
                    <ko-bind data-bind={{ ifnot: workedHoursDefaults.props.forceDataOnWorkedHours}}>
                        <span style={{ whiteSpace: "break-spaces" }}>{TextResources.Todolist.WorkedHoursHelpersEmptyRolesList}</span>
                    </ko-bind>
                </>;
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const workedHoursDefaults = this;
        const role: IDataSourceModel<DefaultRole> = null;
        const workTimeCategory: IDataSourceModel<DefaultWorkTimeCategory> = null;

        return ComponentUtils.bindTo((
            <Layout.WithHeader>
                <Layout.WithHeader.Header className="flex-vertical">
                    {!this.props.hideTitle && (
                        <div className="row">
                            <div className="col-md-12" style={{ borderBottom: "1px solid #e1e1e1", marginBottom: "10px" }}>
                                <h4>{TextResources.Todolist.WorkedHoursHelpersSection}</h4>
                            </div>
                        </div>
                    )}
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content>
                    <div className="row">
                        <div className="col-md-6">
                            {this.props.reportingType && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <DropdownList
                                            values={this.reportingTypes}
                                            value={this.props.reportingType}
                                            idGetter={(rt) => rt.Id}
                                            valueGetter={(rt) => rt.Id.toString()}
                                            textGetter={(rt) => rt.Description}
                                            readonly={!this.props.editableReportingType}
                                            label={TextResources.Todolist.ReportingType}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-6">
                                    <DropdownList value={this.props.workPlace} values={this.workedHoursDefaultPlaces} label={TextResources.Todolist.WorkedHoursDefaultPlace} idGetter={(place) => place.Id } valueGetter={(place) => place.Id.toString() } textGetter={(place) => place.Description} />
                                </div>
                                <div className="col-md-6">
                                    <NumberInput value={this.props.travelDistance} label={TextResources.Todolist.WorkedHoursDefaultTravelDistance} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ paddingTop: "22px" }}>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label className="control-label" style="width: 75%">{TextResources.Todolist.HideAdminstrativeDataFromWorkedHours}</label>
                                        <CheckBox checked={this.props.hideAdministrativeDataOnWorkedHours} switchLabels={{ onLabel: TextResources.ProlifeSdk.Yes, offLabel: TextResources.ProlifeSdk.No }} switch switchSize='small' />
                                        <i className="fa fa-question-circle" style={{ color: "#357ebd", marginLeft: "0.5rem" }} title={TextResources.Todolist.HideAdminstrativeDataFromWorkedHoursTooltip}></i>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label className="control-label" style="width: 75%">{TextResources.Todolist.WorkedHoursForcedData}</label>
                                        <CheckBox checked={this.props.forceDataOnWorkedHours} switchLabels={{ onLabel: TextResources.ProlifeSdk.Yes, offLabel: TextResources.ProlifeSdk.No }} switch switchSize='small' />
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label className="control-label" style="width: 75%">{TextResources.Todolist.DDC}</label>
                                        <CheckBox checked={this.props.ddc} switchLabels={{ onLabel: TextResources.ProlifeSdk.Yes, offLabel: TextResources.ProlifeSdk.No }} switch switchSize='small' />
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label>{TextResources.Todolist.WorkedHoursDefaultRoles}</label>
                            <Table
                                dataSource={Table.defaultDataSource(this.props.defaultRoles, (role) => ({ id: role.RoleId(), title: "" }))}
                                compact
                                fixedLayout
                                rowAs="role"
                                components={{ emptyTableTemplate: this.renderEmptyTableMessage.bind(this) }}
                                sortableColumns
                                rowsSortingValueGetter={(role) => role.Order()}
                                rowsSortingValueSetter={(role, order) => role.Order(order)}
                                >
                                <Column title={TextResources.Todolist.Role}>
                                    <ColumnBody>
                                        {(item: ITableItem<DefaultRole>) => <Select value={item.Data.model.RoleId} dataSource={this.rolesDataSource} placeholder={TextResources.ProlifeSdk.Select2Placeholder} allowClear />}
                                    </ColumnBody>
                                </Column>
                                <Column className="text-center" style={{ width: "50px" }}>
                                    <ColumnHeader>
                                        {() => (
                                            <button type="button" className="btn btn-primary btn-xs" data-bind={{ click: workedHoursDefaults.addRole.bind(workedHoursDefaults) }}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(item: ITableItem<DefaultRole>) => (
                                            <button type="button" className="btn btn-danger btn-xs" data-bind={{ click: workedHoursDefaults.removeRole.bind(workedHoursDefaults, role.model) }}>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        )}
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </div>
                        <div className="col-md-6">
                            <label>{TextResources.Todolist.WorkedHoursDefaultWorkTimeCategories}</label>
                            <Table
                                dataSource={Table.defaultDataSource(this.props.defaultWorkTimeCategories, (role) => ({ id: role.WorkTimeCategoryId(), title: "" }))}
                                compact
                                fixedLayout
                                rowAs="workTimeCategory"
                                components={{ emptyTableTemplate: this.renderEmptyTableMessage.bind(this) }}
                                sortableColumns
                                rowsSortingValueGetter={(wtc) => wtc.Order()}
                                rowsSortingValueSetter={(wtc, order) => wtc.Order(order)}
                                >
                                <Column title={TextResources.Todolist.WorkTimeCategory}>
                                    <ColumnBody>
                                        {(item: ITableItem<DefaultWorkTimeCategory>) => <Select value={item.Data.model.WorkTimeCategoryId} dataSource={this.workTimeCategoriesDataSource} placeholder={TextResources.ProlifeSdk.Select2Placeholder} allowClear />}
                                    </ColumnBody>
                                </Column>
                                <Column className="text-center" style={{ width: "50px" }}>
                                    <ColumnHeader>
                                        {() => (
                                            <button type="button" className="btn btn-primary btn-xs" data-bind={{ click: workedHoursDefaults.addWorkTimeCategory.bind(workedHoursDefaults) }}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(item: ITableItem<DefaultRole>) => (
                                            <button type="button" className="btn btn-danger btn-xs" data-bind={{ click: workedHoursDefaults.removeWorkTimeCategory.bind(workedHoursDefaults, workTimeCategory.model) }}>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        )}
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </div>
                    </div>
                </Layout.WithHeader.Content>
            </Layout.WithHeader>
        ), this, "workedHoursDefaults");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkedHoursDefaultsEditor);
}