import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { If, IfNot } from "../../Components/IfIfNotWith";
import { TextInput } from "../../Components/TextInput";
import { CheckBox } from "../../Components/Checkbox";
import { DateTimeText } from "../../Components/DateTimeText";
import { LazyImport } from "../../Core/DependencyInjection";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { Text } from "../../Components/Text";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

export function LoginScreen() {
    const C = require("./LoginScreen")._LoginScreen as typeof _LoginScreen;
    return <C />;
}

export class _LoginScreen {
    private errorMessage = ko.observable("");
    private hasErrors = ko.observable(false);
    private mustChangePassword = ko.observable(false);
    private hasChangedPassword = ko.observable(false);
    private rememberMe = ko.observable(false);
    private userName = ko.observable("");
    private password = ko.observable("");

    private currentPassword = ko.observable("");
    private newPassword = ko.observable("");
    private confirmPassword = ko.observable("");

    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;

    constructor() {}

    private handleChangePassword = (async () => {
        const changePasswordResult = await this.authenticationService.changePassword(
            this.userName(),
            this.currentPassword(),
            this.newPassword(),
            this.confirmPassword()
        );
        switch (changePasswordResult.status) {
            case "error": {
                this.hasErrors(true);
                this.errorMessage("Le credenziali inserite non sono corrette, riprova.");
                return;
            }

            default: {
                this.hasErrors(false);
                this.mustChangePassword(false);
                this.hasChangedPassword(true);

                this.password("");
                return;
            }
        }
    }).bind(this);

    private handleLogin = (async () => {
        const loginResult = await this.authenticationService.loginUser(this.userName(), this.password());
        switch (loginResult.status) {
            case "error": {
                this.hasErrors(true);
                this.mustChangePassword(false);
                this.errorMessage(loginResult.message ?? "Le credenziali inserite non sono corrette, riprova.");
                return;
            }

            case "password-expired": {
                this.mustChangePassword(true);
                return;
            }

            case "inactivity-period-exceeded": {
                this.hasErrors(true);
                this.mustChangePassword(false);
                this.errorMessage(loginResult.message);
                return;
            }

            default: {
                this.hasErrors(false);
                this.mustChangePassword(false);
                this.hasChangedPassword(false);
                window.location.reload();
            }
        }
    }).bind(this);

    private handleEnterKeyDownForLogin = async () => {
        if (this.userName() && this.password()) await this.handleLogin();
    };

    private handleEnterKeyDownForChangePassword = async () => {
        if (this.currentPassword() && this.newPassword() && this.confirmPassword()) await this.handleChangePassword();
    };

    render() {
        return ComponentUtils.bindTo(
            <div className="login" style={{ position: "absolute", inset: 0 }}>
                <link href="/Content/metronic/assets/admin/pages/css/login.css" rel="stylesheet" type="text/css" />
                <div className="logo">
                    <a href="#">
                        <img src="/Content/metronic/assets/admin/layout/img/logo-big.png" alt="" />
                    </a>
                </div>
                <div className="menu-toggler sidebar-toggler"></div>
                <div className="content">
                    <h3 className="form-title">Accedi con il tuo account</h3>
                    <If condition={this.hasErrors}>
                        {() => (
                            <div className="alert alert-danger">
                                <Text text={this.errorMessage} />
                            </div>
                        )}
                    </If>
                    <If condition={this.hasChangedPassword}>
                        {() => (
                            <div className="alert alert-success">
                                <span>
                                    La password è stata aggiornata correttamente, è ora possibile accedere con la nuova
                                    password.
                                </span>
                            </div>
                        )}
                    </If>
                    <If condition={this.mustChangePassword}>
                        {() => (
                            <>
                                <div className="form-group">
                                    <div className="input-icon">
                                        <i className="fa fa-user"></i>
                                        <TextInput
                                            id="OldPassword"
                                            name="OldPassword"
                                            placeholder="Password Attuale"
                                            password
                                            simple
                                            value={this.currentPassword}
                                            onEnterKeyDown={this.handleEnterKeyDownForChangePassword.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-icon">
                                        <i className="fa fa-lock"></i>
                                        <TextInput
                                            id="NewPassword"
                                            name="NewPassword"
                                            placeholder="Nuova Password"
                                            password
                                            simple
                                            value={this.newPassword}
                                            onEnterKeyDown={this.handleEnterKeyDownForChangePassword.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-icon">
                                        <i className="fa fa-lock"></i>
                                        <TextInput
                                            id="ConfirmPassword"
                                            name="ConfirmPassword"
                                            placeholder="Conferma Password"
                                            password
                                            simple
                                            value={this.confirmPassword}
                                            onEnterKeyDown={this.handleEnterKeyDownForChangePassword.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-actions" style={{ marginBottom: "15px" }}>
                                    <button
                                        type="button"
                                        className="btn green pull-right"
                                        onClick={this.handleChangePassword.bind(this)}
                                    >
                                        Cambia Password&nbsp;<i className="m-icon-swapright m-icon-white"></i>
                                    </button>
                                </div>
                            </>
                        )}
                    </If>
                    <IfNot condition={this.mustChangePassword}>
                        {() => (
                            <>
                                <div className="form-group">
                                    <div className="input-icon">
                                        <i className="fa fa-user"></i>
                                        <TextInput
                                            id="Username"
                                            name="Username"
                                            placeholder="Nome utente"
                                            simple
                                            value={this.userName}
                                            onEnterKeyDown={this.handleEnterKeyDownForLogin.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label visible-ie8 visible-ie9">Password</label>
                                    <div className="input-icon">
                                        <i className="fa fa-lock"></i>
                                        <TextInput
                                            id="Password"
                                            name="Password"
                                            placeholder="Password"
                                            password
                                            simple
                                            value={this.password}
                                            onEnterKeyDown={this.handleEnterKeyDownForLogin.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <CheckBox checked={this.rememberMe} label="Resta collegato" />
                                    <button
                                        type="button"
                                        className="btn green pull-right"
                                        onClick={this.handleLogin.bind(this)}
                                    >
                                        Accedi&nbsp;<i className="m-icon-swapright m-icon-white"></i>
                                    </button>
                                </div>
                            </>
                        )}
                    </IfNot>
                </div>
                <div className="copyright">
                    <DateTimeText format="YYYY" dateTime={new Date()} /> &copy; Abstraqt S.r.l.
                </div>
            </div>,
            this
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(LoginScreen);
}
