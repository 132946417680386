import * as ko from "knockout";
import moment = require("moment");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IBaseDocumentRowForWizard, IBaseDocumentForWizard, IDocumentForWizard } from "../../../interfaces/invoice/IInvoice";
import { IDocumentCurrencyViewModel } from "../../../interfaces/invoice/IDocumentsService";

export abstract class BaseDocumentForWizard<T extends IBaseDocumentRowForWizard = IBaseDocumentRowForWizard> implements IBaseDocumentForWizard<T> {
    public Number : ko.Observable<string> = ko.observable();
    public Date : ko.Observable<Date> = ko.observable();
    public Total : ko.Observable<number> = ko.observable();
    public IsSelected : ko.Observable<boolean> = ko.observable(false);
    public ClosureStatus : ko.Observable<number> = ko.observable();
    public CurrencySymbol: ko.Observable<string> = ko.observable();
    public CurrencyCode: ko.Observable<string> = ko.observable();
    
    public Rows : ko.ObservableArray<T> = ko.observableArray();

    public DocumentCurrency: ko.Observable<IDocumentCurrencyViewModel> = ko.observable();

    @LazyImport(nameof<IDocumentsService>())
    protected documentsService: IDocumentsService;

    constructor(protected document: IDocumentForWizard, entityType: string) {
        this.Number(document.Number);
        this.Date(moment(document.Date).toDate());
        this.Total(document.Total);
        this.ClosureStatus(document.ClosureStatus);
        this.CurrencySymbol(document.Currency);
        this.CurrencyCode(document.CurrencyCode);

        this.DocumentCurrency(this.createDocumentCurrencyViewModel(document, entityType));
    }

    public async openDocument(): Promise<void> {
        await this.documentsService.OpenDocumentOverlayById(this.document.Id);   
    }

    private createDocumentCurrencyViewModel(document: IDocumentForWizard, entityType: string): IDocumentCurrencyViewModel {
        const model = this.documentsService.DocumentCurrenciesFactory.createModel(document.Id, entityType, document.CurrencyId);
        
        model.Id = document.DocumentCurrencyId,
        model.DocumentCurrency = true,
        model.ExchangeValue = document.ExchangeValue,
        model.ExchangeValueForVat = document.ExchangeValueForVat
        

        return this.documentsService.DocumentCurrenciesFactory.createFromModel(model);
    }

    public abstract doImportSelectedRows(result?: boolean): boolean;

    public canBeImported(): boolean {
        return true;
    }
}