import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { RefConverterBase, IRefDocumentRowWithUOM, IDocumentInfoForInlineRefProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IEntityRefKey } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";

export class RoleRefConverter extends RefConverterBase
{
    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;

    private jobOrderService : IJobOrderService;

    private userCharactersSettingsManager : IUserCharactersSettingsManager;

    constructor(private serviceLocator : IServiceLocator,
                activationCode : string,
                docInfo : IDocumentInfoForInlineRefProvider)
    {
        super(activationCode, ProlifeSdk.EstimatedWorkEntityTypeCode, docInfo);
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.userCharactersSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public async getReference(rowId : number, entity : IEntityRefKey, amount : number) : Promise<IRefDocumentRowWithUOM>
    {
        let role : IUserCharacter = this.userCharactersSettingsManager.getUserCharacterById(entity.EntityKeyId);

        let jobOrderId : number = this.DocumentInfo.JobOrderId && this.DocumentInfo.JobOrderId() > 0 ? this.DocumentInfo.JobOrderId() : -1;
        let customerId : number = this.DocumentInfo.CustomerId?.peek() || -1; //se non c'è il cliente ritorno -1

        if (jobOrderId > 0) {
            let j = await this.jobOrderService.get(jobOrderId);
            
            if ((role.ResourceType === 0 && j?.CustomRoles) || (role.ResourceType === 1 && j?.CustomMaterialsRoles)) {
                let price = j.RolesPrices.firstOrDefault(rp => rp.FkUserCharacterId === role.IdUserCharacter);
                if (price)
                    return this.PrepareReference(rowId, entity, amount, price.Salary);
            }
        }

        if (customerId > 0) {
            let customer = await this.customersService.getCustomerById(customerId);

            if ((role.ResourceType == 0 && customer?.CustomRoles) || (role.ResourceType == 1 && customer?.CustomMaterialsRoles)) {
                let price = customer.RolesPrices.firstOrDefault(rp => rp.FkUserCharacterId == role.IdUserCharacter);
                if(price) {
                    return this.PrepareReference(rowId, entity, amount, price.Salary);
                }
            }
        }

        return this.PrepareReference(rowId, entity, amount, role.Salary);
    }

    private PrepareReference(rowId : number, entity : IEntityRefKey, amount : number, salary : number) : IRefDocumentRowWithUOM
    {
        let ref = this.GetEmptyReference(rowId, entity, amount);
        ref.UnitPrice = salary;
        ref.NetUnitPrice = salary;
        ref.NetPrice = salary * ref.Amount;
        return ref;
    }
}
