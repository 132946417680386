import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFunctionPointComputationService } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { IFunctionPointProjectViewModel } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProjectViewModel";
import { IFunctionPointProjectObserver } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProjectObserver";
import { IFunctionPointProject } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProject";

export class FunctionPointProjectViewModel implements IFunctionPointProjectViewModel {
    public Id: number;
    public Name: ko.Observable<string> = ko.observable();
    public Manager: ko.Observable<number> = ko.observable();
    public Referent: ko.Observable<number> = ko.observable();

    public Lastrecord: ko.Observable<string> = ko.observable();
    public LastRecordDate: ko.Observable<Date> = ko.observable();
    public RecordId: ko.Observable<number> = ko.observable();
    public RecordsNumber: ko.Observable<number> = ko.observable();
    public FunctionPointCount: ko.Observable<number> = ko.observable();

    public SelectedManager: ko.Observable<number> = ko.observable();
    public SelectedReferent: ko.Observable<number> = ko.observable();
    public ManagersList: ko.ObservableArray<any> = ko.observableArray([]);
    public ReferentsList: ko.ObservableArray<any> = ko.observableArray([]);

    private observers: IFunctionPointProjectObserver[] = [];

    private functionPointComputationService: IFunctionPointComputationService;
    private infoToastService: IInfoToastService;
    private dialogService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, project?: IFunctionPointProject) {
        this.functionPointComputationService = <IFunctionPointComputationService> 
            this.serviceLocator.findService(ProlifeSdk.FunctionPointComputationServiceType);

        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        if (project != null)
            this.loadFromModel(project);

        this.loadList();
    }

    public viewProject() {
            var editor = this.functionPointComputationService.openProjectEditor(this);
            editor.show();
    }

    public loadList() {
        this.ManagersList.push({ Id: 0, Label: "Alberto" });
        this.ManagersList.push({ Id: 1, Label: "Giuseppe" });
        this.ReferentsList.push({ Id: 0, Label: "Franco" });
        this.ReferentsList.push({ Id: 1, Label: "Riccardo" });
    }
    
    public CreateProject() {
        var editor = this.functionPointComputationService.openProjectEditor();
        editor.show();
    }

    public loadFromModel(project: IFunctionPointProject) {
        this.Id = project.Id;
        this.Name(project.Name);
        this.Manager(project.Manager);
        this.Referent(project.Referent);
    }

    public getData() : IFunctionPointProject {
        var project: IFunctionPointProject = {
            Id: this.Id,
            Name: this.Name(),
            Manager: this.SelectedManager(),
            Referent: this.SelectedReferent()
        };

        return project;
    }

    public Save() {
        var project = this.getData();

        return this.functionPointComputationService.createOrUpdateProject(project)
            .then((result) => {
                if (result) {
                    this.infoToastService.Success(ProlifeSdk.TextResources.FunctionPointComputation.SaveSuccess);
                    this.NotifyProjectUpdated();
                }
            });
    }

    public Delete() {
        var project = this.getData();

        this.dialogService.Confirm(
            ProlifeSdk.TextResources.FunctionPointComputation.DeleteProject,
            ProlifeSdk.TextResources.FunctionPointComputation.Cancel,
            ProlifeSdk.TextResources.FunctionPointComputation.Confirm,
            (confirm: boolean) => {
                if (confirm) {
                    this.functionPointComputationService.deleteProject(project.Id)
                    .then(() => {
                        this.infoToastService.Success(ProlifeSdk.TextResources.FunctionPointComputation.ProjectDeleteSuccess);
                        this.NotifyProjectDeleted();
                    }); 
                }
        });
    }

    public registerObserver(observer: IFunctionPointProjectObserver): void {
        if (this.observers.indexOf(observer) >= 0)
            return;
        this.observers.push(observer);
    }

    public unregisterObserver(observer: IFunctionPointProjectObserver): void {
        var index = this.observers.indexOf(observer);
        if (index <= 0)
            return;
        this.observers.splice(index, 1);
    }

    private NotifyProjectAdded(): void {
        this.observers.forEach((observer: IFunctionPointProjectObserver) => {
            observer.onProjectAdded();
        });
    }

    private NotifyProjectDeleted(): void {
        this.observers.forEach((observer: IFunctionPointProjectObserver) => {
            observer.onProjectDeleted();
        });
    }

    private NotifyProjectUpdated(): void {
        this.observers.forEach((observer: IFunctionPointProjectObserver) => {
            observer.onProjectUpdated();
        });
    }
}