import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/03/2019
 * Time: 16:13
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { WorkedAmountRowForListViewModel } from "./WorkedAmountRowForListViewModel";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IWorkedHoursService } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IProjectsService } from "../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IWorkedAmountRowViewModel } from "../../interfaces/IWorkedAmountRowViewModel";
import { IWorkedAmountExtendedRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkedAmountRow";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

export class WorkedAmountRow extends WorkedAmountRowForListViewModel implements IWorkedAmountRowViewModel {
    JobOrderTypeCode: string = "JOR";
    ProjectTypeCode: string = "PRJ";
    WorkItemTypeCode: string = "WIT";
    TaskTypeCode: string = "TSK";
    JobOrderTaskTypeCode: string = "JTK";

    External: boolean = false;

    public ProjectId: ko.Computed<number>;
    public SelectedJobOrderId: ko.Computed<number>;
    public WorkItemId: ko.Computed<number>;

    public WorkActivitiesContainerType: ko.Observable<any> = ko.observable();
    public WorkActivitiesContainerId: ko.Observable<any> = ko.observable();
    public WorkActivitiesContainerDescr: ko.Observable<string> = ko.observable();
    public WorkActivityType: ko.Observable<any> = ko.observable();
    public WorkActivityId: ko.Observable<any> = ko.observable();
    public WorkActivityDescr: ko.Observable<any> = ko.observable();
    public WorkActivityWorkflow: ko.Observable<any> = ko.observable();

    public OriginalProgress: ko.Observable<number> = ko.observable();
    public OriginalWorkableAmount: ko.Observable<number> = ko.observable();

    public HasActivityContainerFieldFocus: ko.Observable<boolean> = ko.observable(false);
    public HasActivityFieldFocus: ko.Observable<boolean> = ko.observable(true).extend({ notify: 'always' });
    public HasNewAmountFieldFocus: ko.Observable<boolean> = ko.observable(false).extend({ notify: 'always' });
    public IsApproved: ko.Observable<boolean> = ko.observable();
    public IsInitializedFromPreferred: ko.Observable<boolean> = ko.observable(false);

    public Billable: ko.Observable<boolean> = ko.observable(true);
    public EstimatedWork: ko.Observable<number> = ko.observable(0);

    public CanEditWorkableAmount: ko.Observable<boolean> = ko.observable(false);

    public EstimatedWorkInit: ko.Computed<number>;
    public BillableInit: ko.Computed<boolean>;
    public IsJobOrderSelected: ko.Computed<boolean>;
    public IsProjectSelected: ko.Computed<boolean>;
    public IsActivityContainerReadOnly: ko.Computed<boolean>;
    public IsActivityReadOnly: ko.Computed<boolean>;
    public IsReadOnly: ko.Computed<boolean>;
    public CanDelete: ko.Computed<boolean>;

    private ProgressInterceptor: ko.Computed<void>;

    private projectsService: IProjectsService;
    private jobOrderService: IJobOrderService;
    private blogService: IBlogService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private workedHoursService: IWorkedHoursService;
    private todoListService: ITodoListService;

    private calculating: boolean = false;
    private loading: boolean = false;
    private lastValidWorkableAmount: number;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    private validator: IValidator<WorkedAmountRow>;

    constructor(serviceLocator: IServiceLocator) {
        super(serviceLocator);

        this.projectsService = <IProjectsService> serviceLocator.findService(ProlifeSdk.ProjectsServiceType);
        this.jobOrderService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.blogService = <IBlogService> serviceLocator.findService(ProlifeSdk.BlogServiceType);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.workedHoursService = <IWorkedHoursService> this.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);
        this.todoListService = <ITodoListService> this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.ProjectId = ko.computed({
            read : () => {
                return this.WorkActivitiesContainerType() == this.ProjectTypeCode &&
                    this.WorkActivitiesContainerId() ? this.WorkActivitiesContainerId() : null;
            },
            write : (value) => {
                this.WorkActivitiesContainerType(this.ProjectTypeCode);
                this.WorkActivitiesContainerId(value);
            }
        });

        this.SelectedJobOrderId = ko.computed(() => {
            return this.WorkActivitiesContainerType() == this.JobOrderTypeCode &&
                this.WorkActivitiesContainerId() ? this.WorkActivitiesContainerId() : null;
        });

        this.WorkItemId = ko.computed(() => {
            return this.WorkActivityType() == this.WorkItemTypeCode &&
                this.WorkActivityId() ? this.WorkActivityId() : null;
        });

        this.IsJobOrderSelected = ko.computed(() => {
            return this.SelectedJobOrderId() != null;
        });

        this.IsProjectSelected = ko.computed(() => {
            return this.ProjectId() != null;
        });

        this.IsActivityContainerReadOnly = ko.computed(() => {
            return this.WorkActivitiesContainerId() != null;
        });

        this.IsActivityReadOnly = ko.computed(() => {
            return this.WorkActivityId() != null;
        });

        this.IsReadOnly = ko.computed(() => {
            return this.IsApproved() !== null && this.IsApproved() !== undefined;
        });

        this.BillableInit = ko.computed({
            read: this.Billable,
            write: (value : boolean) => {
                if(!this.IsInitializedFromPreferred() && this.serverData() && this.serverData().Id > 0 &&
                    this.serverData().JobOrderId == this.WorkActivitiesContainerId() && this.serverData().TaskId == this.WorkActivityId())
                    return;
                this.Billable(value);
            }
        });

        this.EstimatedWorkInit = ko.computed({
            read: this.EstimatedWork,
            write: (value : number) => {
                this.EstimatedWork(value);
            },
            owner: this
        });

        this.ProgressInterceptor = ko.computed(() => {
            this.Progress();
            var newProgress = !this.WorkableAmount() ? 0 : ((this.WorkedAmount() || 0) + this.NewWorkedAmount()) / this.WorkableAmount();
            this.Progress(newProgress * 100);
        });

        this.CanDelete = ko.computed(() => {
            return this.Id() > 0;
        });

        this.WorkActivityId.subscribe((taskId: number) => {
            if (this.loading)
                return;

            if (!taskId) {
                this.Clear();
                return;
            }

            this.todoListService.GetTaskById(taskId)
                .then((t: ITodoListTask) => {
                    if (t.ActivitiesProgressAmountMode != ProlifeSdk.WorkedAmountMode) {
                        this.dialogsService.Alert(ProlifeSdk.TextResources.WorkedHours.InvalidTaskSelected, ProlifeSdk.TextResources.WorkedHours.InvalidTaskSelectedLabel, () => {
                            this.Clear();
                        });

                        return;
                    }

                    this.BillableInit(t.ReportingType == 1);
                    this.EstimatedWorkInit(t.Duration || 0);
                    this.TaskBoardStatus(t.TaskBoardStatus);
                    this.IsTaskClosed(t.TaskBoardStatus >= 2);

                    var workableAmount = t.Multiplier;
                    var workedAmount = ((t.ProgressAvg || 0) / 100) * workableAmount;

                    this.WorkableAmount(workableAmount);
                    this.WorkedAmount(workedAmount);
                    this.UnitOfMeasure(t.MultiplierUnitOfMeasure);
                    this.Progress(t.ProgressAvg);

                    this.OriginalProgress(t.ProgressAvg);
                    this.OriginalWorkableAmount(workableAmount);

                    this.HasActivityFieldFocus(false);
                    this.HasNewAmountFieldFocus(true);

                    if (this.Id() > 0) {
                        this.serverData().WorkableAmount = workableAmount;
                        this.serverData().WorkedAmount = workedAmount;
                        this.serverData().Progress = t.ProgressAvg || 0;
                        this.serverData().TaskId = t.Id;
                        this.serverData().JobOrderId = this.WorkActivitiesContainerId();
                    }
                });
        });

        this.Progress.subscribe((newProgress: number) => {
            if (this.calculating)
                return;

            this.calculating = true;

            var newWorkedAmount = ((newProgress / 100) * this.WorkableAmount()) - (this.WorkedAmount() || 0);
            this.NewWorkedAmount(newWorkedAmount);

            this.calculating = false;
        });

        this.NewWorkedAmount.subscribe((newWorkedAmount: number) => {
            if (newWorkedAmount + (this.WorkedAmount() || 0) > this.WorkableAmount())
                this.WorkableAmount(newWorkedAmount + (this.WorkedAmount() || 0));
        });

        this.WorkableAmount.subscribe((newWorkableAmount: number) => {
            if (newWorkableAmount < (this.WorkedAmount() || 0) + this.NewWorkedAmount()) {
                this.WorkableAmount(this.lastValidWorkableAmount);
                return;
            }

            this.lastValidWorkableAmount = newWorkableAmount;
        });

        this.configureValidation();

        setTimeout(() => {
            this.SetFieldsFocus();
        }, 300);
    }

    public SetFieldsFocus(): void {
        this.HasActivityFieldFocus(this.Id() <= 0);
        this.HasNewAmountFieldFocus(this.Id() > 0);
    }

    public LoadFrom(workedAmountRow: IWorkedAmountExtendedRow): void {
        this.loading = true;

        super.LoadFrom(workedAmountRow);

        this.WorkActivitiesContainerType(null);
        this.WorkActivitiesContainerId(null);
        this.WorkActivityType(null);
        this.WorkActivityId(null);

        this.WorkActivitiesContainerType(workedAmountRow.JobOrderId ? this.JobOrderTypeCode : null);
        this.WorkActivitiesContainerId(workedAmountRow.JobOrderId);
        this.WorkActivityType(workedAmountRow.TaskId ? this.JobOrderTaskTypeCode : null);
        this.WorkActivityId(workedAmountRow.TaskId);

        this.OriginalProgress(workedAmountRow.OriginalProgress);
        this.OriginalWorkableAmount(workedAmountRow.OriginalWorkableAmount);

        this.lastValidWorkableAmount = workedAmountRow.WorkableAmount;

        this.HasActivityFieldFocus(this.Id() <= 0);
        this.HasNewAmountFieldFocus(this.Id() > 0);

        this.loading = false;
    }

    public GetData(): IWorkedAmountExtendedRow {
        var obj: IWorkedAmountExtendedRow = <IWorkedAmountExtendedRow> super.GetData();

        obj.WorkedAmount = this.NewWorkedAmount();
        obj.JobOrderId = this.WorkActivitiesContainerId();
        obj.TaskId = this.WorkActivityId();

        obj.OriginalProgress = this.OriginalProgress();
        obj.OriginalWorkableAmount = this.OriginalWorkableAmount();

        return obj;
    }

    public HasChanges(): boolean {
        return this.WorkableAmount() != this.serverData().WorkableAmount
            || this.NewWorkedAmount() != this.serverData().WorkedAmount
            || this.Progress() != this.serverData().Progress
            || this.WorkActivityId() != this.serverData().TaskId;
    }

    public Save(): void {
        this.HasNewAmountFieldFocus(false);

        if (!this.validator.validateAndShowInfoToast(this)) {
            return;
        }

        this.workedHoursService.SaveWorkedAmount(this.GetData())
            .then((savedData: IWorkedAmountExtendedRow) => {
                this.infoToastService.Success(ProlifeSdk.TextResources.WorkedHours.WorkedAmountSaveSuccess);
                this.LoadFrom(savedData);
                if (this.editor)
                    this.editor.OnRowSaved(this);
            });
    }

    public Reset(): void {
        this.LoadFrom(this.serverData());
    }

    public Clear(): void {
        this.loading = true;

        this.Id(null);

        this.JobOrderId(null);
        this.TaskId(null);

        this.TaskTitle(null);
        this.WorkableAmount(0);
        this.WorkedAmount(0);
        this.Progress(0);
        this.UnitOfMeasure(null);
        this.IsDeleted(false);
        this.TaskBoardStatus(null);

        this.Date(null);

        this.NewWorkedAmount(0);

        this.WorkActivitiesContainerType(null);
        this.WorkActivitiesContainerId(null);
        this.WorkActivityType(null);
        this.WorkActivityId(null);


        this.OriginalProgress(0);
        this.OriginalWorkableAmount(0);

        this.lastValidWorkableAmount = 0;

        this.HasActivityFieldFocus(true);
        this.HasNewAmountFieldFocus(false);

        this.loading = false;
    }

    public Delete(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.WorkedHours.DeleteWorkedAmountRowConfirmMessage,
            ProlifeSdk.TextResources.WorkedHours.DeleteWorkedAmountRowCancelButton,
            ProlifeSdk.TextResources.WorkedHours.DeleteWorkedAmountRowConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.internalDeleteRow();
            }
        )
    }

    public EditContainer()
    {
        this.WorkActivitiesContainerType(null);
        this.WorkActivitiesContainerId(null);
        this.WorkActivitiesContainerDescr(null);
        this.EditActivity();
        this.HasActivityContainerFieldFocus(true);
    }

    public EditActivity()
    {
        this.WorkActivityType(null);
        this.WorkActivityId(null);
        this.WorkActivityDescr(null);
        this.WorkActivityWorkflow(null);
        this.HasActivityFieldFocus(true);
    }

    public GoToContainerDetail()
    {
        //Vado a blog dalle righe degli statini di intervento
        if(this.External)
            this.blogService.openBlogInNewWindow(this.WorkActivitiesContainerId());
        else
        {
            var url = this.IsProjectSelected() ? this.projectsService.getProjectUrl(this.WorkActivitiesContainerId())
                : this.jobOrderService.getJobOrderUrl(this.WorkActivitiesContainerId());
            window.open(url, "_blank");
        }
    }

    public GoToActivityDetail()
    {
        if(this.WorkActivityType() != this.WorkItemTypeCode)
            return;

        var url = this.projectsService.getWorkItemUrl(this.WorkActivityId());
        window.open(url, "_blank");
    }

    public GetIconForCurrentActivity()
    {
        return this.GetActivityTypeIcon(this.WorkActivityType());
    }

    public GetActivityTypeIcon(type : string)
    {
        var icon: string = null;
        icon = (type == this.TaskTypeCode) ? "fa-clipboard" : icon;
        icon = type == this.JobOrderTaskTypeCode ? "fa-clipboard" : icon;
        icon = type == this.WorkItemTypeCode ? "fa-wrench" : icon;
        return icon;
    }

    private configureValidation(): void {
        this.validator = this.validationService.createValidator<WorkedAmountRow>()
            .isNotNullOrUndefined(w => w.WorkActivityId(), TextResources.WorkedHours.TaskIdRequired);
    }

    private internalDeleteRow(): void {
        this.workedHoursService.DeleteWorkedAmount(this.GetData())
            .then(() => {
                if (this.editor)
                    this.editor.OnRowDeleted(this);
            });
    }
}