import * as ko from "knockout";
/**
 * Created by d.bucchi on 13/06/2017.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";

export class MultipleSelectionDialog implements IDialog {
    private dialogsService: IDialogsService;

    public templateName: string =  "multiple-selection-dialog";
    public templateUrl: string = "blog/templates/Dialog";
    public title: string = "Seleziona";
    public parent: any;
    modal: { close: (result?: any) => void; };
    private showed: boolean = false;
    private modalDeferred: Promise<boolean> = null;

    constructor(serviceLocator: IServiceLocator, parent?: any) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.parent = parent;
    }

    public ShowModal(): Promise<boolean> {
        if (this.showed)
            return this.modalDeferred;

        this.showed = true;
        return this.modalDeferred = this.dialogsService.ShowModal<boolean>(this, "fullscreen", {}, this.templateUrl, this.templateName);
    }

    switchSelectionModeMobile() {
        this.parent.jobOrdersList().switchSelectionModeMobile();
        this.modal && this.modal.close();
    }

    selectAllEvents() {
        this.parent.jobOrdersList().selectAllEvents();
        this.modal && this.modal.close();
    }

    openFilters(){
        this.parent.openFilters();
        this.modal && this.modal.close();
    }

    close(): void {
        this.modal && this.modal.close();
    }

    action(): void {
        this.modal && this.modal.close(true);
    }

}
