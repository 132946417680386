import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ActivitiesWarehouseRow } from "./ActivitiesWarehouseRow";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { IDocumentDataWizardRow, ProcessedRow } from "../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentBuilderDocumentRelatedWorkflows, ISelectableEstimateBudgetRow, ISelectableTodoListTask, IDocumentBuilderDocumentOriginatingRows } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IActivitiesForDocuments, IActivitiesSourceForDocuments } from "../../../ProlifeSdk/interfaces/todolist/IActivitiesForDocuments";
import { ITodoListService, IWorkAndPurchaseCosts } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTask, IEstimateBudgetRow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IWorkflowNavigator } from "../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IArticlesService, IArticle, IArticleTransform } from "../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDiscountsService, IDiscountsManager } from "../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { Deferred } from "../../../Core/Deferred";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";

export class ActivitiesWarehouseDataSource extends DocumentDataWizardStep<ActivitiesWarehouseRow> implements INavigationMenuObserver {
    private ActivitiesBar : IWorkflowNavigator;
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;
    @LazyImport(nameof<IArticlesService>())
    private articlesService : IArticlesService;
    @LazyImport(nameof<IDiscountsService>())
    private discountsService : IDiscountsService;

    private discountManager : IDiscountsManager;

    public EstimatedWorkRows : ko.Computed<ISelectableEstimateBudgetRow[]>;
    public EstimatedPurchasesRows : ko.Computed<ISelectableEstimateBudgetRow[]>;
    public EstimatedArticlesRows : ko.Computed<ISelectableEstimateBudgetRow[]>;

    public AllWorkSelected : ko.Computed<boolean>;
    public AllPurchasesSelected : ko.Computed<boolean>;
    public AllArticlesSelected : ko.Computed<boolean>;

    public SelectedRows : ko.Computed<ISelectableEstimateBudgetRow[]>;

    private Tasks : ko.ObservableArray<ISelectableTodoListTask> = ko.observableArray();
    private selectedTaskId : number;
    previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Todolist.TasksToWarehouse);
        
        this.ActivitiesBar = this.todoListService.GetWorkflowNavigatorUI({
            DisableWorkflowSelection : true,
            DisableWorkflowEditing : true,
            DisableTaskEditing : true
        });
        
        this.ActivitiesBar.setMultipleSelectionStatus(false);
        this.ActivitiesBar.setSingleSelectionStatus(true, true);
        this.ActivitiesBar.addMenuObserver(this);

        this.EstimatedWorkRows = ko.computed(() => {
            var allEstimatedRows = [];

            this.Tasks().forEach((t : ITodoListTask) => {
                var estimatedRows = (t.EstimatedBudgetRows || []).filter((r : IEstimateBudgetRow) => r.Type == ProlifeSdk.EstimatedWorkEntityTypeCode);
                allEstimatedRows = allEstimatedRows.concat(estimatedRows);
            });

            return allEstimatedRows;
        });
        this.EstimatedPurchasesRows = ko.computed(() => {
            var allEstimatedRows = [];

            this.Tasks().forEach((t : ITodoListTask) => {
                var estimatedRows = (t.EstimatedBudgetRows || []).filter((r : IEstimateBudgetRow) => r.Type == ProlifeSdk.EstimatedPurchaseEntityTypeCode);
                allEstimatedRows = allEstimatedRows.concat(estimatedRows);
            });

            return allEstimatedRows;
        });
        this.EstimatedArticlesRows = ko.computed(() => {
            var allEstimatedRows = [];

            this.Tasks().forEach((t : ITodoListTask) => {
                var estimatedRows = (t.EstimatedBudgetRows || []).filter((r : IEstimateBudgetRow) => r.Type == ProlifeSdk.WarehouseArticleEntityTypeCode);
                allEstimatedRows = allEstimatedRows.concat(estimatedRows);
            });

            return allEstimatedRows;
        });

        this.AllWorkSelected = ko.computed({
            read: () => {
                var selectedRows = this.EstimatedWorkRows().filter(r => r.Selected());
                return this.EstimatedWorkRows().length > 0 && selectedRows.length == this.EstimatedWorkRows().length;
            },
            write: (val : boolean) => {
                this.EstimatedWorkRows().forEach(r => r.Selected(val));
            }
        });

        this.AllPurchasesSelected = ko.computed({
            read: () => {
                var selectedRows = this.EstimatedPurchasesRows().filter(r => r.Selected());
                return this.EstimatedPurchasesRows().length > 0 && selectedRows.length == this.EstimatedPurchasesRows().length;
            },
            write: (val : boolean) => {
                this.EstimatedPurchasesRows().forEach(r => r.Selected(val));
            }
        });

        this.AllArticlesSelected = ko.computed({
            read: () => {
                var selectedRows = this.EstimatedArticlesRows().filter(r => r.Selected());
                return this.EstimatedArticlesRows().length > 0 && selectedRows.length == this.EstimatedArticlesRows().length;
            },
            write: (val : boolean) => {
                this.EstimatedArticlesRows().forEach(r => r.Selected(val));
            }
        });

        this.SelectedRows = ko.computed(() =>{
            var selectedRows = this.EstimatedWorkRows().filter(r => r.Selected())
                .concat(this.EstimatedPurchasesRows().filter(r => r.Selected()))
                .concat(this.EstimatedArticlesRows().filter(r => r.Selected()));

            return selectedRows;
        });

        this.RegisterDataSource();
    }

    public RegisterDataSource()
    {
        /* this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.WarehouseLoadEntityTypeCode
        ); */
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Amount(),
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: (r.NetUnitPrice() ?? 0).ToCurrency(documentCurrency),
                NetUnitPrice: r.NetUnitPrice(),
                Order: 0,
                TotalPriceInDocumentCurrency: (r.Total() ?? 0).ToCurrency(documentCurrency),
                TotalPrice: r.Total(),
                UnitPriceInDocumentCurrency: (r.UnitPrice() ?? 0).ToCurrency(documentCurrency),
                UnitPrice: r.UnitPrice(),
                Description: r.Description(),
                Discounts: r.Discounts(),
                UoM: r.UOM()
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : ActivitiesWarehouseRow) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        for(const source of r.Sources.filter(r => r.Amount != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType : source.EntityType,
                SourceEntityKeyId : source.EntityKeyId,
                DestEntityType : this.initializationInfo.DocTypeCode,
                DestEntityKeyId : 0,
                CatalogId: source.CatalogId,
                Amount: source.Amount,
                UnitPrice : source.UnitPrice,
                Discounts : source.Discounts,
                NetUnitPrice: source.NetUnitPrice,
                NetPrice : source.Total,
                WarehouseId: this.initializationInfo.SourceWarehouseId,
                EstimatedBudgetForTaskId: source.EstimateBudgetRowId,
                TaskId: source.TaskId
            });
        }
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);

        this.ActivitiesBar.Initialize([initializationInfo.JobOrderId]);
        this.discountManager = this.discountsService.getDiscountsManagerByCustomerId(initializationInfo.CustomerId);

        this.Tasks([]);
    }

    notifyFilterResultIsChanged(filteredLeafs:INavigationMenuProvider[]) {
    }

    onSelectionChanged(selection:INavigationMenuProvider[], navigator:INavigationMenu) {
        var taskIds = selection.map(p => p.Id);
        if(taskIds.length == 0) {
            this.Tasks([]);
            return;
        }

        this.todoListService.GetTasksByIds(taskIds)
            .then((t : ITodoListTask[]) => {
                var selectableTasks = t.map(task => {
                    task.EstimatedBudgetRows.forEach(r => {
                        //TODO: Cambiare questo schifo!!!
                        (r as any).Selected = ko.observable(false);
                        (r as any).Imported = ko.computed(() => {
                            var imported = this.Rows().filter((a : IActivitiesForDocuments) => {
                                return a.references(r);
                            });
                            return imported.length > 0;
                        });
                    });
                    return task;
                });
                this.Tasks(selectableTasks);
                this.selectedTaskId = selectableTasks.length > 0 ? selectableTasks[0].Id : -1;
            });
    }

    public removeRow(row : any) {
        this.Rows.remove(row);
    }

    public RemoveAll() {
        this.Rows([]);
    }

    private GetSelectedRows() : any[]
    {
        var selectedRows = this.SelectedRows();

        if(selectedRows.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SelectARowToImport);
            return [];
        }

        var notImported = selectedRows.filter((r : any) => !r.Imported());
        if(notImported.length != selectedRows.length) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.SomeRowsWereAlreadyImported);
        }

        return selectedRows;
    }

    public ImportDestruction() {
        if(this.EstimatedArticlesRows().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.NoArticlesAvailable);
            return;
        }

        var totalExtraBudget = 0;

        this.EstimatedWorkRows().forEach((r : IEstimateBudgetRow) => {
            totalExtraBudget += r.Multiplier * r.Price;
        });

        this.EstimatedPurchasesRows().forEach((r : IEstimateBudgetRow) => {
            totalExtraBudget += r.Multiplier * r.Price;
        });

        var articlePrices = 0;
        var articleAmount = 0;
        var newRows = [];
        var deferreds : Promise<any>[] = [];

        this.EstimatedArticlesRows().forEach((r : IEstimateBudgetRow) => {
            var def = new Deferred();
            deferreds.push(def.promise());

            this.articlesService.getArticleByCatalogRowId(r.EntityKeyId)
                .then((article : IArticle) => {
                    this.articlesService.GetArticleTransforms(article.ArticleId)
                        .then((transform : IArticleTransform) => {
                            if(!transform) {
                                var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, r.Amount * r.Multiplier);
                                articlePrices += newRow.Total();
                                articleAmount += newRow.Amount();
                                newRows.push(newRow);
                                def.resolve();
                            } else {
                                newRows.push(new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, -r.Amount * r.Multiplier));

                                var transformDeferreds : Promise<any>[] = [];
                                transform.Destruction.forEach(t => {

                                    transformDeferreds.push(this.articlesService.getArticleByArticleId(t.ComponentArticleId)
                                        .then((component : IArticle) => {
                                            var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, component, r, r.Amount * r.Multiplier * t.Amount);
                                            articlePrices += newRow.Total();
                                            articleAmount += newRow.Amount();
                                            newRows.push(newRow);
                                        }))

                                });

                                Promise.all(transformDeferreds)
                                    .then(() => {
                                        def.resolve();
                                    });
                            }
                        })
                })
        });

        Promise.all(deferreds)
            .then(() => {
                newRows.forEach((r : ActivitiesWarehouseRow) => {
                    if(r.Amount() > 0) {
                        r.updateNetUnitPrice((r.Total() / articlePrices) * totalExtraBudget);
                    }
                    this.Rows.push(r);
                });
            });
    }

    public ImportConstruction() {
        if(this.EstimatedArticlesRows().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.NoArticlesAvailable);
            return;
        }

        var totalExtraBudget = 0;

        this.EstimatedWorkRows().forEach((r : IEstimateBudgetRow) => {
            totalExtraBudget += r.Multiplier * r.Price;
        });

        this.EstimatedPurchasesRows().forEach((r : IEstimateBudgetRow) => {
            totalExtraBudget += r.Multiplier * r.Price;
        });

        var articlePrices = 0;
        var articleAmount = 0;
        var newRows = [];
        var deferreds : Promise<any>[] = [];

        this.EstimatedArticlesRows().forEach((r : IEstimateBudgetRow) => {
            var def = new Deferred();
            deferreds.push(def.promise());

            this.articlesService.getArticleByCatalogRowId(r.EntityKeyId)
                .then((article : IArticle) => {
                    this.articlesService.GetArticleTransforms(article.ArticleId)
                        .then((transform : IArticleTransform) => {
                            if(!transform) {
                                var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, r.Amount * r.Multiplier);
                                articlePrices += newRow.Total();
                                articleAmount += newRow.Amount();
                                newRows.push(newRow);
                                def.resolve();
                            } else {
                                newRows.push(new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, -r.Amount * r.Multiplier));

                                var transformDeferreds : Promise<any>[] = [];
                                transform.Construction.forEach(t => {

                                    transformDeferreds.push(this.articlesService.getArticleByArticleId(t.ComponentArticleId)
                                        .then((component : IArticle) => {
                                            var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, component, r, r.Amount * r.Multiplier * t.Amount);
                                            articlePrices += newRow.Total();
                                            articleAmount += newRow.Amount();
                                            newRows.push(newRow);
                                        }))


                                });

                                Promise.all(transformDeferreds)
                                    .then(() => {
                                        def.resolve();
                                    });
                            }
                        })
                })
        });

        Promise.all(deferreds)
            .then(() => {
                newRows.forEach((r : ActivitiesWarehouseRow) => {
                    if(r.Amount() > 0) {
                        r.updateNetUnitPrice((r.Total() / articlePrices) * totalExtraBudget);
                    }
                    this.Rows.push(r);
                });
            });
    }

    public ImportRealConstruction() {
        if(this.EstimatedArticlesRows().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.NoArticlesAvailable);
            return;
        }

        var articlePrices = 0;
        var articleAmount = 0;
        var newRows = [];
        var totalExtraBudget = 0;
        var deferreds : Promise<any>[] = [];

        deferreds.push(this.todoListService.GetWorkAndPurchaseCostsForTask(this.selectedTaskId)
            .then((costs : IWorkAndPurchaseCosts) => {
                totalExtraBudget += costs.WorkedCost + costs.PurchasesCost;
            }));

        this.EstimatedArticlesRows().forEach((r : IEstimateBudgetRow) => {
            var def = new Deferred();
            deferreds.push(def.promise());

            this.articlesService.getArticleByCatalogRowId(r.EntityKeyId)
                .then((article : IArticle) => {
                    this.articlesService.GetArticleTransforms(article.ArticleId)
                        .then((transform : IArticleTransform) => {
                            if(!transform) {
                                var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, r.Amount * r.Multiplier);
                                articlePrices += newRow.Total();
                                articleAmount += newRow.Amount();
                                newRows.push(newRow);
                                def.resolve();
                            } else {
                                newRows.push(new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, -r.Amount * r.Multiplier));

                                var transformDeferreds : Promise<any>[] = [];
                                transform.Construction.forEach(t => {

                                    transformDeferreds.push(this.articlesService.getArticleByArticleId(t.ComponentArticleId)
                                        .then((component : IArticle) => {
                                            var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, component, r, r.Amount * r.Multiplier * t.Amount);
                                            articlePrices += newRow.Total();
                                            articleAmount += newRow.Amount();
                                            newRows.push(newRow);
                                        }))


                                });

                                Promise.all(transformDeferreds)
                                    .then(() => {
                                        def.resolve();
                                    });
                            }
                        })
                })
        });

        Promise.all(deferreds)
            .then(() => {
                newRows.forEach((r : ActivitiesWarehouseRow) => {
                    if(r.Amount() > 0) {
                        r.updateNetUnitPrice((r.Total() / articlePrices) * totalExtraBudget);
                    }
                    this.Rows.push(r);
                });
            });
    }

    public ImportRealDestruction() {
        if(this.EstimatedArticlesRows().length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.NoArticlesAvailable);
            return;
        }

        var articlePrices = 0;
        var articleAmount = 0;
        var newRows = [];
        var totalExtraBudget = 0;
        var deferreds : Promise<any>[] = [];

        deferreds.push(this.todoListService.GetWorkAndPurchaseCostsForTask(this.selectedTaskId)
            .then((costs : IWorkAndPurchaseCosts) => {
                totalExtraBudget += costs.WorkedCost + costs.PurchasesCost;
            }));

        this.EstimatedArticlesRows().forEach((r : IEstimateBudgetRow) => {
            var def = new Deferred();
            deferreds.push(def.promise());

            this.articlesService.getArticleByCatalogRowId(r.EntityKeyId)
                .then((article : IArticle) => {
                    this.articlesService.GetArticleTransforms(article.ArticleId)
                        .then((transform : IArticleTransform) => {
                            if(!transform) {
                                var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, r.Amount * r.Multiplier);
                                articlePrices += newRow.Total();
                                articleAmount += newRow.Amount();
                                newRows.push(newRow);
                                def.resolve();
                            } else {
                                newRows.push(new ActivitiesWarehouseRow(this.discountsService, this.discountManager, article, r, -r.Amount * r.Multiplier));

                                var transformDeferreds : Promise<any>[] = [];
                                transform.Destruction.forEach(t => {

                                    transformDeferreds.push(this.articlesService.getArticleByArticleId(t.ComponentArticleId)
                                        .then((component : IArticle) => {
                                            var newRow = new ActivitiesWarehouseRow(this.discountsService, this.discountManager, component, r, r.Amount * r.Multiplier * t.Amount);
                                            articlePrices += newRow.Total();
                                            articleAmount += newRow.Amount();
                                            newRows.push(newRow);
                                        }))


                                });

                                Promise.all(transformDeferreds)
                                    .then(() => {
                                        def.resolve();
                                    });
                            }
                        })
                })
        });

        Promise.all(deferreds)
            .then(() => {
                newRows.forEach((r : ActivitiesWarehouseRow) => {
                    if(r.Amount() > 0) {
                        r.updateNetUnitPrice((r.Total() / articlePrices) * totalExtraBudget);
                    }
                    this.Rows.push(r);
                });
            });
    }

    public ImportGroupByTask() {

    }

    public ImportGroupByType() {

    }

    render() {
        let step : ActivitiesWarehouseDataSource;
        let row : ISelectableEstimateBudgetRow;
        let outRow : ActivitiesWarehouseRow;

        return ComponentUtils.bindTo(
            <>
                <div style="position: absolute; bottom: 0; top: 0; left: 10px; right: 280px; overflow-x: hidden; overflow-y: auto;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="portlet" style="margin-bottom: 0">
                                <div class="portlet-title">
                                    <a href="#" class="btn btn-danger btn-sm pull-right" style="margin-left: 5px;" data-bind={{ click: step.ImportDestruction }}>{TextResources.Invoices.DocumentWizardDecompositionWithEstimate}</a>
                                    <a href="#" class="btn btn-danger btn-sm pull-right" style="margin-left: 5px;" data-bind={{ click: step.ImportRealDestruction }}>{TextResources.Invoices.DocumentWizardDecompositionWithEffective}</a>
                                    <a href="#" class="btn btn-primary btn-sm pull-right" style="margin-left: 5px;" data-bind={{ click: step.ImportConstruction }}>{TextResources.Invoices.DocumentWizardCompositionWithEstimate}</a>
                                    <a href="#" class="btn btn-primary btn-sm pull-right" data-bind={{ click: step.ImportRealConstruction }}>{TextResources.Invoices.DocumentWizardCompositionWithEffective}</a>
                                    <div class="caption">
                                        {TextResources.Invoices.DocumentWizardEstimatedExpenses}
                                    </div>
                                </div>
                                <div class="portlet-body">
                                    <div data-bind={{ slimScroll: '680px' }}>
                                        <div style="position: relative">
                                            <h4>{TextResources.Invoices.DocumentWizardWork}</h4>
                                        </div>

                                        <table class="table table-striped table-bordered table-advance">
                                            <thead>
                                                <tr>
                                                    <th>{TextResources.Invoices.DocumentWizardRole}</th>
                                                    <th class="text-right" style="width: 60px;">{TextResources.Invoices.DocumentWizardMultiplierAmount}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardManHours}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.EstimatedWorkRows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={6}><h4 class="text-center">{TextResources.Invoices.DocumentWizardNoWork}</h4></td>
                                                </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.EstimatedWorkRows, as: 'row' }}}>
                                                <tr data-bind={{ css: { success: row.Imported } }}>
                                                    <td>
                                                        <span data-bind={{ UserCharacterDescription : {  userCharacter : row.EntityKeyId } }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Multiplier }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ moneyText : row.UnitPrice }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Amount }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ moneyText : row.Price }}></span>
                                                    </td>
                                                </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>

                                        <div style="position: relative; margin-top : 30px">
                                            <h4>{TextResources.Invoices.DocumentWizardPurchases}</h4>
                                        </div>

                                        <table class="table table-striped table-bordered table-advance">
                                            <thead>
                                                <tr>
                                                <th>{TextResources.Invoices.DocumentWizardRole}</th>
                                                    <th class="text-right" style="width: 60px;">{TextResources.Invoices.DocumentWizardMultiplierAmount}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardAmount}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.EstimatedPurchasesRows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={6}><h4 class="text-center">{TextResources.Invoices.DocumentWizardNoPurchases}</h4></td>
                                                </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.EstimatedPurchasesRows, as: 'row' }}}>
                                                <tr data-bind={{ css: { success: row.Imported } }}>
                                                    <td>
                                                        <span data-bind={{ text : row.Description }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Multiplier }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ moneyText : row.UnitPrice }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Amount }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ moneyText : row.Price }}></span>
                                                    </td>
                                                </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>

                                        <div style="position: relative; margin-top : 30px">
                                            <h4>{TextResources.Invoices.DocumentWizardWarehouse}</h4>
                                        </div>

                                        <table class="table table-striped table-bordered table-advance">
                                            <thead>
                                                <tr>
                                                    <th>{TextResources.Invoices.DocumentWizardArticle}</th>
                                                    <th class="text-right" style="width: 60px;">{TextResources.Invoices.DocumentWizardMultiplierAmount}</th>
                                                    <th class="text-right" style="width: 120px;">{TextResources.Invoices.DocumentWizardAmount}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.EstimatedArticlesRows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={4}><h4 class="text-center">{TextResources.Invoices.DocumentWizardNoArticle}</h4></td>
                                                </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.EstimatedArticlesRows, as: 'row' }}}>
                                                <tr data-bind={{ css: { success: row.Imported } }}>
                                                    <td>
                                                        <span data-bind={{ articleText : row.EntityKeyId }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Multiplier }}></span>
                                                    </td>
                                                    <td class="text-right">
                                                        <span data-bind={{ numberText : row.Amount }}></span>
                                                    </td>
                                                </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="portlet" style="margin-bottom: 0">
                                <div class="portlet-title">
                                    <a href="#" class="btn btn-danger btn-sm pull-right" style="margin-left: 5px;" data-bind={{ click: step.RemoveAll }}>
                                        {TextResources.Invoices.DocumentWizardRemoveAll}
                                    </a>
                                    <div class="caption">
                                        {TextResources.Invoices.DocumentWizardImportedRows}
                                    </div>
                                </div>
                                <div class="portlet-body" style="min-height: 100%">
                                    <div data-bind={{ slimScroll: '680px' }}>
                                        <div style="position: relative">
                                            <h4>&nbsp;</h4>
                                        </div>
                                        <table class="table table-striped table-bordered table-advance table-hover">
                                            <thead>
                                                <tr>
                                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                                    <th style="text-align: right">{TextResources.Invoices.DocumentWizardAmount}</th>
                                                    <th style="text-align: right; width: 90px;">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                                    <th style="text-align: right; width: 90px;">{TextResources.Invoices.DocumentWizardDiscount}</th>
                                                    <th style="text-align: right; width: 90px;">{TextResources.Invoices.DocumentWizardNetPriceShort}</th>
                                                    <th style="text-align: right; width: 90px;">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                                    <th style="width: 40px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.Rows().length == 0 }}>
                                                <tr>
                                                    <td colSpan={7}><h4 class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</h4></td>
                                                </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.Rows, as: 'outRow' }}}>
                                                <tr>
                                                    <td data-bind={{ text: outRow.Description }}></td>
                                                    <td style="text-align: right">
                                                        <span data-bind={{ numberText: outRow.Amount, visible: outRow.Amount() != 0 }}></span>
                                                    </td>
                                                    <td style="text-align: right">
                                                        <span data-bind={{ moneyText: outRow.UnitPrice, visible: outRow.Amount() != 0 }}></span>
                                                    </td>
                                                    <td style="text-align: right">
                                                        <span data-bind={{ discountText: outRow.Discounts, visible: outRow.Amount() != 0 }}></span>
                                                    </td>
                                                    <td style="text-align: right">
                                                        <span data-bind={{ moneyText: outRow.NetUnitPrice, visible: outRow.Amount() != 0 }}></span>
                                                    </td>
                                                    <td style="text-align: right">
                                                        <span data-bind={{ moneyText: outRow.Total, visible: outRow.Amount() != 0 }}></span>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <a href="#" class="btn btn-xs red" title={TextResources.Invoices.DocumentWizardRemove} data-bind={{ click: step.removeRow.bind(step, outRow) }}>
                                                            <i class="fa fa-trash-o"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-quick-sidebar-wrapper" style="position: absolute; bottom: 0; top : 0; right : 0; width : 270px; border-left: 1px solid rgb(219, 219, 219);">
                    <div class="page-quick-sidebar">
                        <div class="page-quick-sidebar-chat">
                            <div class="page-quick-sidebar-chat-users navigator">
                                <ko-bind data-bind={{ with : step.ActivitiesBar }}>
                                    <ko-template data-bind="{ name : templateName, templateUrl : templateUrl }">
                                    </ko-template>
                                </ko-bind>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        , this, "step");
    }
}

class ActivitiesTextRow implements IActivitiesForDocuments
{
    Description:ko.Observable<string> = ko.observable();
    AmountFormula : ko.Observable<string> = ko.observable();
    Amount:ko.Observable<number> = ko.observable(0);
    UnitPrice:ko.Observable<number> = ko.observable(0);
    Discounts:ko.Observable<string> = ko.observable();
    NetUnitPrice:ko.Observable<number> = ko.observable(0);
    UOM: ko.Observable<string> = ko.observable();

    Total:ko.Observable<number> = ko.observable(0);

    Sources:IActivitiesSourceForDocuments[] = [];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[] = [];

    constructor(tasks : ko.ObservableArray<ISelectableTodoListTask>, taskId : number)
    {
        var taskWithId = tasks().filter((t : ITodoListTask) => t.Id == taskId);
        if(taskWithId.length > 0) {
            var task = taskWithId[0];
            this.Description(String.format(ProlifeSdk.TextResources.Todolist.TaskReference, task.Title));
        } else {
            this.Description(ProlifeSdk.TextResources.Todolist.UnknownTask);
        }
    }

    public references(row : IEstimateBudgetRow) : boolean {
        return false;
    }
}