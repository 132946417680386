import * as ko from "knockout";
import * as numeral from "numeral";

import { CurrencyUtils } from "../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { IDocumentCurrencyViewModel } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class MoneyText {
    static FormatMoney(valueAccessor: () => any, extendedMoneyFormat : boolean, currencySymbol?: string, documentCurrency?: IDocumentCurrencyViewModel) : string {
        const underlyingObservable = valueAccessor();
        const val : number = ko.utils.unwrapObservable(underlyingObservable);

        const format = MoneyText.createFormatString(extendedMoneyFormat, documentCurrency);
        let formattedValue = numeral(val || 0).format(format);

        if (currencySymbol)
            formattedValue = MoneyText.replaceCurrencySymbol(formattedValue, currencySymbol);

        return formattedValue;
    }

    public  static replaceCurrencySymbol(value: string, newCurrencySymbol: string): string {
        const partial = value.substring(0, value.length - 1);
        return partial + newCurrencySymbol;
    }

    public static createFormatString(extendedMoneyFormat: boolean, documentCurrency: IDocumentCurrencyViewModel): string {
        let format = extendedMoneyFormat ? '0,0.0000[0000] $' : '0,0.00 $';

        if (!extendedMoneyFormat && documentCurrency) {
            let numberOfDecimals = CurrencyUtils.getNumberOfDecimalsForCurrency(documentCurrency);
            format = "0,0";

            let decimals = "";

            while (numberOfDecimals > 0) {
                decimals += "0";
                numberOfDecimals--;
            }

            format = (!decimals ? format : format + "." + decimals) + " $";
        }

        return format;
    }

    init(element: Node, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const extendedMoneyFormat = allBindingsAccessor()["extendedMoneyFormat"] == true;
        const currencySymbol = allBindingsAccessor()["currencySymbol"];
        const documentCurrency = allBindingsAccessor()["documentCurrency"];

        let timeout = $(element).data("moneyTextTimeout");
        
        if(element.nodeType == 8) //E' un commento
        {
            const newSpan = document.createElement("span");
            element.parentElement.insertBefore(newSpan, element.nextSibling);
            element = newSpan;
        }

        $(element).on("hover", function() {
            if(extendedMoneyFormat) {
                if(timeout) {
                    clearTimeout(timeout);
                    $(element).data("moneyTextTimeout", null);
                }

                $(element).text(MoneyText.FormatMoney(valueAccessor, true, ko.utils.unwrapObservable(currencySymbol), ko.utils.unwrapObservable(documentCurrency)));
            }
        }, function() {
            if(extendedMoneyFormat) {
                timeout = setTimeout(() => {
                    $(element).text(MoneyText.FormatMoney(valueAccessor, false, ko.utils.unwrapObservable(currencySymbol), ko.utils.unwrapObservable(documentCurrency)));
                }, 1000);
                $(element).data("moneyTextTimeout", timeout);
            }
        });
    }

    update(element: Node, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        const extendedMoneyFormat = allBindingsAccessor()["extendedMoneyFormat"] == true;
        const valuesToReplace : {value : number; text : string; }[] = allBindingsAccessor()["moneyTextPlaceholders"] || [];
        const currencySymbol = allBindingsAccessor()["currencySymbol"];
        const documentCurrency = allBindingsAccessor()["documentCurrency"];

        if(element.nodeType == 8) //E' un commento
        {
            element = element.nextSibling;
        }
        $(element).text(MoneyText.FormatMoney(valueAccessor, false, ko.utils.unwrapObservable(currencySymbol), ko.utils.unwrapObservable(documentCurrency)));

        /*var interceptor = ko.computed<string>({
            read: function() {
                var val : number = ko.utils.unwrapObservable(underlyingObservable);
                var valMatches = valuesToReplace.filter((v) => { return v.value == val; });
                return valMatches.length > 0 ? valMatches[0].text : numeral(val || 0).format(extendedMoneyFormat ? '0,0.0000[0000] $' : '0,0.00 $');
            },
            write: function(newValue) {
                if(!newValue)
                    newValue = "0";

                var components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if(components.length > 1)
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);

                var valueToWrite = numeral().unformat(newValue);
                //if(valueToWrite != newValue)
                underlyingObservable(valueToWrite);
                underlyingObservable.valueHasMutated();
            
        });}*/

        //ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class MoneyValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        const maxNumberOfDecimals = allBindingsAccessor()["maxNumberOfDecimals"] || 4;
        const onChange = allBindingsAccessor()["onChange"]

        if (onChange) {
            $(element).on("keyup", function() {
                const val = $(element).val() as string;
                const numericVal = numeral(val || "").value();
                onChange(numericVal);
            });    
        }

        $(element).on("change", function() {
            let newValue = $(element).val() as string;

            if (!newValue)
                newValue = "0";

            const parts = newValue.split(" "); // Separa il numero dal simbolo della valuta perché in alcuni simboli è presente il carattere '.' e questo crea problemi nel codice seguente
            newValue = parts[0];

            const components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
            if(components.length > 1) {
                newValue = components.slice(0,-1).join('') + "," + components.slice(-1);
                newValue = numeral(newValue).value().toFixed(maxNumberOfDecimals).replace(/\./g, ",");
            }

            const valueToWrite = numeral(newValue).value();
            //if(valueToWrite != newValue)
            underlyingObservable(valueToWrite);
            //underlyingObservable.valueHasMutated();
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        const extendedMoneyFormat = allBindingsAccessor()["extendedMoneyFormat"] == true;
        const currencySymbol = allBindingsAccessor()["currencySymbol"];
        const documentCurrency = allBindingsAccessor()["documentCurrency"];

        const format = MoneyText.createFormatString(extendedMoneyFormat, ko.utils.unwrapObservable(documentCurrency));
        let formattedValue = numeral(ko.utils.unwrapObservable(underlyingObservable) || 0).format(format);

        if (currencySymbol)
            formattedValue = MoneyText.replaceCurrencySymbol(formattedValue, ko.utils.unwrapObservable(currencySymbol));
        
        if($(element).val() == formattedValue)
            return;

        $(element).prop("value", formattedValue);

        /*var interceptor = ko.computed<string>({
            read: function() {
                let format = MoneyText.createFormatString(extendedMoneyFormat, ko.utils.unwrapObservable(documentCurrency));
                let formattedValue = numeral(ko.utils.unwrapObservable(underlyingObservable) || 0).format(format);

                if (currencySymbol)
                    formattedValue = MoneyText.replaceCurrencySymbol(formattedValue, ko.utils.unwrapObservable(currencySymbol));

                return formattedValue;
            },
            write: function(newValue) {
                if(!newValue)
                    newValue = "0";

                let parts = newValue.split(" "); // Separa il numero dal simbolo della valuta perché in alcuni simboli è presente il carattere '.' e questo crea problemi nel codice seguente
                newValue = parts[0];

                var components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if(components.length > 1) {
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);
                    newValue = numeral().unformat(newValue).toFixed(maxNumberOfDecimals).replace(/\./g, ",");
                }

                var valueToWrite = numeral().unformat(newValue);
                //if(valueToWrite != newValue)
                underlyingObservable(valueToWrite);
                //underlyingObservable.valueHasMutated();
            }
        });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);*/
    }
}

ko.bindingHandlers["moneyTextEx"] = ko.bindingHandlers["moneyText"] = new MoneyText();
ko.bindingHandlers["moneyValueEx"] = ko.bindingHandlers["moneyValue"] = new MoneyValue();

ko.virtualElements.allowedBindings.moneyTextEx = true;
ko.virtualElements.allowedBindings.moneyValueEx = true;
ko.virtualElements.allowedBindings.moneyText = true;
ko.virtualElements.allowedBindings.moneyValue = true;

