import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";

export class ContextMenuForFileRespositoryDialog implements IDialog {
    private dialogsService:IDialogsService;

    public parent: any;
    public templateName: string = "context-menu-dialog";
    public templateUrl: string = "FileRepository/templates/Dialog";
    public title: string = "Seleziona";
    modal: { close: (result?:any) => void; };
    private showed: boolean = false;
    private modalDeferred: Promise<boolean> = null;

    constructor(serviceLocator: IServiceLocator, parent?: any) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.parent = parent;
    }

    public ShowModal(): Promise<boolean> {
        if (this.showed)
            return this.modalDeferred;

        this.showed = true;
        return this.modalDeferred = this.dialogsService.ShowModal<boolean>(this, "fullscreen", {}, this.templateUrl, this.templateName);;
    }

    launchSelectedFile(){
        this.parent.launchSelectedFile();
        this.close();
    }

    sendByMail(){
        this.parent.sendByMail();
        this.close();
    }

    copyInClipboard(){
        this.parent.copyInClipboard();
        this.close();
    }

    copyAllFromClipboardHere(){
        this.parent.copyAllFromClipboardHere();
        this.close();
    }

    moveAllFromClipboardHere(){
        this.parent.moveAllFromClipboardHere();
        this.close();
    }

    renameSelected(){
        this.parent.renameSelected();
        this.close();
    }

    removeSelectedFiles(){
        this.parent.removeSelectedFiles();
        this.close();
    }

    close(): void {
        this.modal && this.modal.close(true);
    }

    action(): void {
        this.modal && this.modal.close(true);
    }
}