import * as ko from "knockout";
import { IProLifeListsUtilities } from "../../interfaces/prolife-sdk/IProLifeUtilitiesLibrary";

export class ListsUtilities implements IProLifeListsUtilities
{
    public GetNewProgressiveName(prefix : string, otherNames : string[]) : string
    {
        var matches = otherNames.filter((o : string) => { return o.indexOf(prefix) == 0; });

        var maxIndex : number = 0;
        matches.forEach((name : string) => {
            var nameIndex : number = parseInt(name.replace(prefix, "").replace("(", "").replace(")", "").trim());
            nameIndex = isNaN(nameIndex) ? 0 : nameIndex;
            maxIndex = maxIndex < nameIndex ? nameIndex : maxIndex;
        });
        return prefix + (matches.length == 0 ? "" : " (" + (maxIndex+1).toString() + ")");
    }
}
