import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IGenericFileConfigurationsUI } from "../../../ProlifeSdk/interfaces/files/IGenericFileConfigurationsUI";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ITagHint } from "../../../ProlifeSdk/interfaces/files/ITagHint";
import { ITagHintEx } from "../../interfaces/ITagHintEx";

export class GenericFileConfigurationsUI implements IGenericFileConfigurationsUI
{
    private fileRepository : IFileRepositoryService;

    public templateName : string = "GenericFileConfigurationsUI";
    public templateUrl : string = "FileRepository/templates/settings";
    viewModel : any = null;

    private AssignedTags : ko.ObservableArray<ITagHint> = ko.observableArray([]);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.fileRepository = <IFileRepositoryService>  this.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);
    }

    public Initialize(tags : ITagHint[])
    {
        var array = tags ? tags.slice(0) : [];
        this.AssignedTags(array);
    }

    private AssignTag(tag : ITagHintEx)
    {
        var matches = this.AssignedTags().filter((t : ITagHint) => {
            return (t.Name ? t.Name : "").toUpperCase() == (tag.Name ? tag.Name : "").toUpperCase();
        });

        if(matches.length > 0)
            return;

        if(tag.IsCreateNew)
        {
            this.fileRepository.createNewTag(tag.Value, 0)
                .then((newTag : ITagHint) => this.AssignedTags.push(newTag));
        }
        else
            this.AssignedTags.push(tag);
    }

    private RemoveTag(tag : ITagHint)
    {
        this.AssignedTags.remove(tag);
    }

    public GetAssignedTags() : ITagHint[]
    {
        return this.AssignedTags();
    }
}