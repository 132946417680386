import * as ko from "knockout";
import * as ProlifeSdk from '../ProlifeSdk/ProlifeSdk';
import { LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService } from '../Invoices/DocumentsService';
import { IDataSource, IDataSourceView, IDataSourceModel } from './IDataSource';
import { ICommonRowData, IGetDocumentsRowsRequest } from '../ProlifeSdk/interfaces/invoice/IDocumentsService';

export class DocumentsRowsCommonDataDataSource implements IDataSource {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    
    private currentView: IDataSourceView;
    private documentId: number;
    private documentType: string;
    private getTotallyAssignedRows: boolean = false;

    private dataFilter: (model: IDataSourceModel<number, ICommonRowData>) => boolean;

    constructor() {

    }

    getTitle(currentModel: IDataSourceModel<number, ICommonRowData>): string {
        return ProlifeSdk.TextResources.Warehouse.OrderRows;
    }
    
    isGroupedData(currentModel: IDataSourceModel<number, ICommonRowData>, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IDataSourceModel<number, ICommonRowData>, b: IDataSourceModel<number, ICommonRowData>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }
    
    async getData(currentModel: IDataSourceModel<number, ICommonRowData>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<number, ICommonRowData>[]> {
        if (!this.documentId)
            return [];

        let request: IGetDocumentsRowsRequest = {
            DocumentId: this.documentId,
            DocumentType: this.documentType,
            TextFilter: textFilter,
            GetFullyAssigned: this.getTotallyAssignedRows,
            Skip: skip,
            Count: count
        };

        let documentsRows = await this.documentsService.GetDocumentsRows(request);

        let models = documentsRows.map(this.createOrderRowModel, this);

        if(this.dataFilter)
            models = models.filter((m) => this.dataFilter(m));
        
        return models;
    }
    
    async getById(currentModel: IDataSourceModel<number, ICommonRowData>, ids: number[]): Promise<IDataSourceModel<number, ICommonRowData>[]> {
        let orderRows = await this.documentsService.GetDocumentsRowsByIds(ids.map(i => { return { EntityKeyId: i, EntityKeyType: this.documentType } }));
        return orderRows.map(this.createOrderRowModel, this);
    }
    
    setView(view: IDataSourceView): void {
        this.currentView = view;
    }

    setDocumentAndTypeId(documentId: number, documentType: string): void {
        this.documentId = documentId;
        this.documentType = documentType
    }
    
    setGetTotallyAssignedOrdersRows(value: boolean) {
        this.getTotallyAssignedRows = value;
    }

    setDataFilter(callback: (model: IDataSourceModel) => boolean): void {
        this.dataFilter = callback;
    }

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
    
    private createOrderRowModel(documentRow: ICommonRowData): IDataSourceModel<number, ICommonRowData> {
        return {
            id: documentRow.Id,
            title: documentRow.Description,
            isGroup: false,
            isLeaf: true,
            model: documentRow
        }
    }
}