import * as ko from "knockout";
/**
 * Created by d.collantoni on 24/02/14.
 */
import * as ProlifeSdk from "../../ProlifeSdk";
import { BlogEventBaseInput } from "./BlogEventBaseInput";
import { _EventClassificationInput } from "./EventClassificationInput";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { BlogEvent } from "./BlogEvent";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ContactList } from "../../../Blog/Blog/EventsDetails/SearchService/ContactList";

export class EventSignatureViewModel implements IDialog
{
    templateName:string = "event-signature-dialog";
    templateUrl:string = "blog/templates/events";
    title:string = ProlifeSdk.TextResources.ProlifeSdk.EventSignature;
    modal: {
        close: (result?: any) => void;
    };

    public signers : ContactList;
    public signatureCanvas : ko.Observable<any>;
    private dialogService : IDialogsService;
    private editableEvent : BlogEventBaseInput;
    private classification : _EventClassificationInput;
    private initialStatus : string;

    close():void {
        if(this.modal)
            this.modal.close();
    }

    action():void {
        var canvas : fabric.Canvas = this.signatureCanvas();
        /*if(canvas.isDrawingMode)
        {
            this.dialogService.Alert("Attenzione! Non è stata disabilitata la modalità di inserimento firma. Disabilitarla per confermare la firma prima di procedere al salvataggio!", "Firma non confermata", () => {});
            return;
        }*/

        var atLeastOneObjectExists = false;
        canvas.forEachObject(o => atLeastOneObjectExists = true);

        if(!atLeastOneObjectExists)
        {
            this.dialogService.Alert(ProlifeSdk.TextResources.ProlifeSdk.WarningNoSignature, ProlifeSdk.TextResources.ProlifeSdk.WarningNoSignatureTitle, () => {});
            return;
        }

        this.classification.isSigned(true);
        this.classification.setStatus(ProlifeSdk.Tag_State_ToDo);

        this.editableEvent.save()
            .then(() => this.modal.close(true))
            .catch(() => this.modal.close(false));
    }

    constructor(public serviceLocator : IServiceLocator, public blogEvent : BlogEvent) {
        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        this.editableEvent = <BlogEventBaseInput>this.blogEvent.getEditViewModel();
        this.classification = this.editableEvent.classificazione;
        this.signatureCanvas = this.editableEvent.classificazione.signatureCanvas;
        this.signers = this.editableEvent.signers;

        this.initialStatus = this.classification.status();
    }

    public clearSignature(item, event : Event) {
        var canvas : fabric.Canvas = this.signatureCanvas();
        canvas.backgroundImage = undefined;
        canvas.forEachObject(canvas.remove.bind(canvas));
    }

    public toggleSignature(item, event : Event) {
        this.classification.toggleSignature(item, event);

        var canvas : fabric.Canvas = this.signatureCanvas();
        if(!canvas.isDrawingMode)
        {
            var atLeastOneObjectExists = false;
            canvas.forEachObject(o => atLeastOneObjectExists = true);

            if(atLeastOneObjectExists)
            {
                this.classification.isSigned(true);
                this.classification.setStatus(ProlifeSdk.Tag_State_ToDo);
            }
        }
        else
        {
            this.classification.isSigned(false);
            this.classification.setStatus(this.initialStatus);
        }
    }
}