import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ReactElement } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../Core/utils/ComponentUtils";

type IfIfNotWithParams = {
    value: ko.Subscribable<any>;
    template: () => ReactElement;
}

export function If(props : { condition: ko.Subscribable<any> | (() => any) | string, children: () => ReactElement}) {
    let finalValue = props.condition;

    if(!ko.isSubscribable(finalValue) && typeof finalValue === "function")
        finalValue = ko.computed(finalValue);

    return ComponentUtils.setBinding(<ko-bind data-bind={{ noop: 0 }}></ko-bind>, { tsxIf: { value: finalValue, template: props.children[0] } });
}

export function IfNot(props : { condition: ko.Subscribable<any> | (() => any) | string, children: () => ReactElement}) {
    let finalValue = props.condition;

    if(!ko.isSubscribable(finalValue) && typeof finalValue === "function")
        finalValue = ko.computed(finalValue);

    return ComponentUtils.setBinding(<ko-bind data-bind={{ noop: 0 }}></ko-bind>, { tsxIfNot: { value: finalValue, template: props.children[0] } });
}

export function With(props : { data: any, as?:string, children?: () => ReactElement}) {
    return ComponentUtils.setBinding(<ko-bind data-bind={{ noop: 0 }}></ko-bind>, { tsxWith: { value: props.data, template: props.children[0] }, as: props.as });
}

declare global {
    interface Window {
        dependencyTracking: ko.Observable[][];
        dependencyHandles: { [name: string] : ko.Observable };
    }

    interface NodeModule {
        hot: any;
    }
}

window.dependencyTracking = []; //Stack
window.dependencyHandles = {};

function makeWithIfBinding(bindingKey, isWith, isNot) {
    ko.bindingHandlers[bindingKey] = {
        'init': function(element, valueAccessor: () => IfIfNotWithParams, allBindings, viewModel, bindingContext) {
            var didDisplayOnLastUpdate, contextOptions = {}, completeOnRender, needAsyncContext, renderOnEveryChange : boolean;

            if (isWith) {
                var as = allBindings.get('as'), noChildContext = allBindings.get('noChildContext');
                renderOnEveryChange = !(as && noChildContext);
                contextOptions = { 'as': as, 'noChildContext': noChildContext, 'exportDependencies': renderOnEveryChange };
            }

            completeOnRender = allBindings.get("completeOn") == "render";
            needAsyncContext = completeOnRender || allBindings['has']((ko.bindingEvent as any).descendantsComplete);

            ko.computed(function() {
                let { value, template } = ko.utils.unwrapObservable(valueAccessor());

                const accessor = valueAccessor().value;
                if(typeof accessor === "string") {
                    value = ComponentUtils.parseParameterWithContext(accessor, bindingContext);
                }

                const unwrappedValue = ko.utils.unwrapObservable(value),
                      shouldDisplay = !isNot !== !unwrappedValue; // equivalent to isNot ? !value : !!value,
                let childContext;

                if (!renderOnEveryChange && shouldDisplay === didDisplayOnLastUpdate) {
                    return;
                }

                if (needAsyncContext) {
                    bindingContext = ko.bindingEvent.startPossiblyAsyncContentBinding(element, bindingContext);
                }

                if (shouldDisplay) {
                    if (!isWith || renderOnEveryChange) {
                        contextOptions['dataDependency'] = (ko.computedContext as any).computed();
                    }

                    if (isWith) {
                        childContext = bindingContext['createChildContext'](typeof unwrappedValue == "function" ? unwrappedValue : () => unwrappedValue, contextOptions);
                    } else if (ko.computedContext.getDependenciesCount()) {
                        childContext = bindingContext['extend'].call(bindingContext, null, contextOptions);
                    } else {
                        childContext = bindingContext;
                    }
                }


                ko.ignoreDependencies(() => ComponentUtils.notifyWillUnmount(element));

                if (shouldDisplay) {
                    window.dependencyTracking.push([]);
                    let nodes = ko.ignoreDependencies(() => template());
                    let deps = window.dependencyTracking.pop();

                    renderOnEveryChange = renderOnEveryChange || deps.length > 0;
                    for(let d of deps)
                        d();
                    
                    ko.virtualElements.setDomNodeChildren(element, [nodes]);
                    ComponentUtils.applyBindingsToChildren(element, childContext);

                    ko.ignoreDependencies(() => ComponentUtils.notifyDidMount(element));
                } else {
                    ko.virtualElements.emptyNode(element);

                    if (!completeOnRender) {
                        (ko.bindingEvent as any).notify(element, (ko.bindingEvent as any).childrenComplete);
                    }
                }

                didDisplayOnLastUpdate = shouldDisplay;

            }, null, { disposeWhenNodeIsRemoved: element });

            return { 'controlsDescendantBindings': true };
        }
    };
    ko.expressionRewriting.bindingRewriteValidators[bindingKey] = false; // Can't rewrite control flow bindings
    ko.virtualElements.allowedBindings[bindingKey] = true;
}

// Construct the actual binding handlers
makeWithIfBinding('tsxIf', false, false);
makeWithIfBinding('tsxIfNot', false /* isWith */, true /* isNot */);
makeWithIfBinding('tsxWith', true /* isWith */, false);