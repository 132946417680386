import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 30/07/2018
 * Time: 12:08
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SurveyInboundWizardViewModel } from "./SurveyInboundWizardViewModel";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInboundConfiguredSurvey, ISurveyService, IInboundConfiguredSurveyWithQuestionnaireName } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";

export class InboundWizard {
    public templateName : string = "inbound-wizard";
    public templateUrl : string = "surveywizard/templates/inbound";

    public Wizard : ko.Observable<any> = ko.observable();
    public ExternalURL : ko.Observable<string> = ko.observable();
    public Agenda : ko.Observable<number> = ko.observable();

    private config : IInboundConfiguredSurvey;

    constructor(private serviceLocator : IServiceLocator, callId : number, configId : number, phoneNumber : string, calledPhoneNumber : string, ivrCode : string) {
        var surveyService = <ISurveyService> serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        surveyService.getConfiguredInboundSurvey(configId)
            .then((config : IInboundConfiguredSurvey) => {
                this.config = config;
                this.Wizard(new SurveyInboundWizardViewModel(this.serviceLocator, callId, config, phoneNumber));
                this.ExternalURL(config.ExternalURL);
                this.Agenda(config.FkAgenda);
            })
    }
}

export class WaitingForInboundWizard {
    public templateName : string = "waiting-for-inbound-wizard";
    public templateUrl : string = "surveywizard/templates/inbound";

    public Surveys : ko.ObservableArray<IInboundConfiguredSurveyWithQuestionnaireName> = ko.observableArray();

    private surveyWizardService : ISurveyWizardService;

    constructor(private serviceLocator : IServiceLocator) {
        var surveyService = <ISurveyService> serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.surveyWizardService = <ISurveyWizardService> serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType);
        surveyService.getConfiguredInboundSurveysWithQuestionnaireName()
            .then((configs : IInboundConfiguredSurveyWithQuestionnaireName[]) => {
                this.Surveys(configs.filter(c => !c.Disabled));
            });
    }

    public BackToSplash(): void {
        location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl;
    }

    public StartWizard(config : IInboundConfiguredSurvey) {
        this.surveyWizardService.notifyAcceptedCall(null, config.PhoneNumber, null)
            .then((callId : number) => {
                this.surveyWizardService.openSurveyWizard(callId, null, config.PhoneNumber, null, config.Id);
            });
    }
}