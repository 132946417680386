import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CustomerMetadatasViewModel } from "./CustomerMetadatasViewModel";
import { CustomerBanksViewModel } from "./CustomerBanksViewModel";
import { UoAddresses } from "./UoAddresses";
import { CustomerContactsReferences } from "./CustomerContactsReferences";
import { ResponsibleReference } from "./ResponsibleReference";
import { CustomerViewModel } from "./CustomerViewModel";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsProvider } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IOrganizationalUnit,
    IProtocolSettingsForCustomer,
    IContactReference,
    IProtocolDefaultMetadataForCustomer,
} from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import {
    IProtocolDefaultValuesSettingsUi,
    IPaymentAccount,
    IProtocolSetting,
} from "../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { ICustomerBank } from "../../../ProlifeSdk/interfaces/customer/ICustomerBank";
import { IValidation } from "../../../ProlifeSdk/ValidationService";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import { IRecipientCodeViewModel } from "../../interfaces/IRecipientCodeViewModel";
import { MetadataType } from "../../../Invoices/invoices/enums/MetadataType";
import { IProtocolDefaultMetadatas } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ProtocolMailRecipientMapper } from "./ProtocolMailRecipientMapper";

@DetectClassChanges
export class OrganizationalUnit {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    private guid: string;
    private data: IOrganizationalUnit;

    @DetectChanges
    public Description: ko.Observable<string> = ko.observable();
    @DetectChanges
    public ForTechnical: ko.Observable<boolean> = ko.observable();
    @DetectChanges
    public ForAdministration: ko.Observable<boolean> = ko.observable();
    @DetectChanges
    public ForCommercial: ko.Observable<boolean> = ko.observable();
    @DetectChanges
    public Department: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Qualification: ko.Observable<string> = ko.observable();

    @DetectChanges
    public AssignedRecipientCodeId: ko.Observable<number> = ko.observable();
    public AssignedRecipientCode: ko.Observable<IRecipientCodeViewModel> = ko.observable();

    @DetectChanges
    public CustomerCode: ko.Observable<string> = ko.observable();

    public Metadatas: CustomerMetadatasViewModel;
    public Banks: CustomerBanksViewModel;
    public Addresses: UoAddresses;
    public References: CustomerContactsReferences; // TODO le modifiche a questi dati non vengono osservate
    public ReferencedCompanies: CustomerContactsReferences; // TODO le modifiche a questi dati non vengono osservate
    public ReferencedBy: CustomerContactsReferences; // TODO le modifiche a questi dati non vengono osservate

    private Id: ko.Observable<number> = ko.observable(0);
    public Title: ko.Computed<string>;

    private ShowAdvanced: ko.Observable<boolean> = ko.observable(false);
    public ProtocolsSettings: ko.ObservableArray<IProtocolDefaultValuesSettingsUi> = ko.observableArray([]); // TODO le modifiche a questi dati non vengono osservate
    private SelectedProtocol: ko.Observable<any> = ko.observable(null);

    public CommercialResponsible: ResponsibleReference; // TODO le modifiche a questi dati non vengono osservate
    public AdministrativeResponsible: ResponsibleReference; // TODO le modifiche a questi dati non vengono osservate

    public HasPeople: ko.Computed<boolean>;
    public HasAddressesChanges: ko.Computed<boolean>;
    public HasMetadatasChanges: ko.Computed<boolean>;
    public HasChanges: ko.Computed<boolean>;

    public isChanged: ko.Observable<number> = ko.observable(0);

    constructor(public IsDefault: boolean, private parent: CustomerViewModel) {
        this.guid = this.desktopService.GetNewGuid();

        this.Banks = new CustomerBanksViewModel();
        this.Metadatas = new CustomerMetadatasViewModel();
        this.Addresses = new UoAddresses();

        this.ReferencedCompanies = new CustomerContactsReferences(true);
        this.References = new CustomerContactsReferences(false);
        this.ReferencedBy = new CustomerContactsReferences(false);

        this.Title = ko.computed(() => {
            const title: string =
                !this.Description() || this.Description().length == 0
                    ? this.Id()
                        ? ProlifeSdk.TextResources.Customers.UONoName
                        : ProlifeSdk.TextResources.Customers.NewUO
                    : this.Description();
            return title.trim();
        });

        parent.IsCompany.subscribe((isCompany: boolean) => {
            this.Department("");
            this.Qualification("");
        });

        //Carico le gui per le configurazioni dei protocolli
        this.documentsService.getRegisteredDocumentProviders().forEach((p: IDocumentsProvider) => {
            if (!p.HasDefaultValuesSettingsUi) return;
            this.ProtocolsSettings.push(p.GetDefaultValuesSettingsUi());
        });

        this.CommercialResponsible = new ResponsibleReference();
        this.AdministrativeResponsible = new ResponsibleReference();

        this.HasPeople = ko.computed(() => {
            let hasPeople: boolean = this.CommercialResponsible && this.CommercialResponsible.DisplayName().length > 0;
            hasPeople =
                hasPeople ||
                (this.AdministrativeResponsible && this.AdministrativeResponsible.DisplayName().length > 0);
            hasPeople = hasPeople || (this.References && this.References.ContactsReferences().length > 0);
            hasPeople = hasPeople || (this.ReferencedBy && this.ReferencedBy.ContactsReferences().length > 0);
            return hasPeople;
        });

        this.HasAddressesChanges = ko.computed(() => {
            if (!this.Addresses) return false;

            return this.Addresses.HasChanges();
        });

        this.HasMetadatasChanges = ko.computed(() => {
            if (!this.Metadatas) return false;

            return this.Metadatas.HasChanges();
        });

        this.HasChanges = ko.computed(() => {
            return (
                this.isChanged() > 0 ||
                this.Banks.isChanged() > 0 ||
                this.HasAddressesChanges() ||
                this.HasMetadatasChanges()
            );
        });

        this.AssignedRecipientCodeId.subscribe((id: number) => {
            this.AssignedRecipientCode(this.parent.GetRecipientCodeById(id));
        });
    }

    public Validate(): IValidation[] {
        const addressesValidation = this.Addresses.GetValidation();
        const banksValidation = this.Banks.GetValidation();
        const metadatasValidation = this.ProtocolsSettings()
            .map((p) => {
                let validation = p.validateMetadata();
                return {
                    valid: !validation,
                    message: String.format(TextResources.ProlifeSdk.MetadataError, p.ProtocolName, validation),
                };
            })
            .filter((v) => !v.valid);

        return addressesValidation.concat(banksValidation).concat(metadatasValidation);
    }

    public AddPhoneNumber(label: string, phoneNumber: string, autoScroll: boolean) {
        const telephoneGroups = this.Metadatas.metadataGroups().filter((mg) => mg.isTelephone);
        if (telephoneGroups.length > 0) {
            telephoneGroups[0].AddMetadata(label, phoneNumber, null, autoScroll);
        }
    }

    public AddMail(label: string, mail: string, autoScroll: boolean) {
        const mailGroup = this.Metadatas.metadataGroups().firstOrDefault((mg) => mg.isMail);
        if (!mailGroup) return;

        mailGroup.AddMetadata(label, mail, null, autoScroll);
    }

    public SetEditMode(editable: boolean) {
        this.ProtocolsSettings().forEach((ps: IProtocolDefaultValuesSettingsUi) => {
            ps.SetEditMode(editable);
        });
    }

    private RefreshContactAccountsForPayments() {
        const accounts: IPaymentAccount[] = this.Banks.getData().map((b: ICustomerBank) => {
            return <IPaymentAccount>{
                Id: b.Id,
                Description:
                    (b.ABI ? b.ABI : ProlifeSdk.TextResources.Customers.NoABI) +
                    " - " +
                    (b.CAB ? b.CAB : ProlifeSdk.TextResources.Customers.NoCAB),
            };
        });

        this.ProtocolsSettings().forEach((s: IProtocolDefaultValuesSettingsUi) => {
            s.Initialize(accounts);
        });
    }

    public RefreshOrganizationalUnitsForProtocolsDefaultsPaymentOptions(ous: OrganizationalUnit[]): void {
        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            if (p.PaymentTypeManagerUi) {
                p.PaymentTypeManagerUi.refreshCustomerPaymentsOptions(
                    ous.map((ou: OrganizationalUnit) => ou.GetData())
                );
            }
        });
    }

    private SelectProtocol(p: IProtocolDefaultValuesSettingsUi) {
        this.SelectedProtocol(p);
    }

    private SwitchAdvancedVisibility() {
        this.ShowAdvanced(!this.ShowAdvanced());
    }

    public Select() {
        this.parent.SelectedUo(this);
    }

    public Load(data: IOrganizationalUnit): OrganizationalUnit {
        this.data = data;
        this.Description(data.Description);
        this.ForTechnical(data.ForTechnical);
        this.ForAdministration(data.ForAdministration);
        this.ForCommercial(data.ForCommercial);
        this.Department(data.Department);
        this.Qualification(data.Qualification);
        this.CustomerCode(data.Code);
        this.Banks.load(data.Banche);
        this.Metadatas.load(data.Metadati);
        this.Addresses.load(data.Addresses);
        this.Id(data.Id);

        this.AssignedRecipientCodeId(data.RecipientCodeId);

        this.CommercialResponsible.Initialize(data.FkCommercialResponsible, data.CommercialResponsibleName);
        this.AdministrativeResponsible.Initialize(data.FkAdministrativeResponsible, data.AdministrativeResponsibleName);

        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            const matches = data.ProtocolsSettings.filter((ps: IProtocolSettingsForCustomer) => {
                return ps.ProtocolId == p.ProtocolId;
            });

            const metadatas = data.ProtocolsDefaultMetadatas.filter((m) => m.ProtocolId === p.ProtocolId).map((m) => ({
                Id: m.Id,
                FKProtocol: null,
                FKMetadata: m.FKMetadata,
                MetadataValueType: m.MetadataValueType as MetadataType,
                ShowMarkerOnAllocationsGantt: m.ShowMarkerOnAllocationsGantt,
                PreloadOnDocument: m.PreloadOnDocument,
                Order: null,
            }));

            p.SetStaticMetadatasTable(true);
            p.SetEnableMetadatasSorting(false);
            p.LoadSettings(data.ProtocolsDefaultValues, matches.length > 0 && matches[0].DefaultValuesEnabled);
            p.LoadMetadata(metadatas, matches.length > 0 && matches[0].DefaultMetadatasEnabled);
            p.loadMailRecipientTypes(
                data.tosMailRecipients.filter((r) => r.protocolId === p.vatRegisterId),
                data.ccsMailRecipients.filter((r) => r.protocolId === p.vatRegisterId),
                data.bccsMailRecipients.filter((r) => r.protocolId === p.vatRegisterId)
            );
        });

        this.References.Initialize(
            data.References.filter((r: IContactReference) => {
                return r.ReferenceType == 0;
            })
        );

        this.ReferencedCompanies.Initialize(
            data.References.filter((r: IContactReference) => {
                return r.ReferenceType == 1;
            })
        );

        const referencedBy = data.ReferencedBy.map((c: IContactReference) => {
            return <IContactReference>{
                ContactOrganizationalUnitId: c.ReferencedOrganizationalUnitId,
                ReferencedOrganizationalUnitId: c.ContactOrganizationalUnitId,
                ReferenceType: c.ReferenceType,
            };
        });
        this.ReferencedBy.Initialize(referencedBy);
        this.SelectProtocol(this.ProtocolsSettings().length > 0 ? this.ProtocolsSettings()[0] : null);
        this.RefreshContactAccountsForPayments();

        this.isChanged(0);
        return this;
    }

    public GetData(): IOrganizationalUnit {
        const uo: IOrganizationalUnit = Object.assign({}, this.data, {
            ForTechnical: false,
            ForAdministration: false,
            ForCommercial: false,
            Metadati: [],
            Banche: [],
            Addresses: [],
            ProtocolsDefaultValues: [],
            ProtocolsDefaultMetadatas: [],
            ProtocolsSettings: [],
            IsDefault: this.IsDefault,
            References: [],
            ReferencedBy: [],
            RecipientCodeId: null,
        });

        uo.Department = this.Department();
        uo.Description = this.Description();
        uo.ForAdministration = this.ForAdministration();
        uo.ForCommercial = this.ForCommercial();
        uo.ForTechnical = this.ForTechnical();
        uo.Qualification = this.Qualification();
        uo.RecipientCodeId = this.AssignedRecipientCodeId();
        uo.Code = this.CustomerCode();
        uo.Banche = this.Banks.getData();
        uo.Metadati = this.Metadatas.getData();
        uo.Addresses = this.Addresses.getData();

        uo.FkCommercialResponsible = this.CommercialResponsible.GetData().Id;
        uo.CommercialResponsibleName = this.CommercialResponsible.GetData().Name;
        uo.FkAdministrativeResponsible = this.AdministrativeResponsible.GetData().Id;
        uo.AdministrativeResponsibleName = this.AdministrativeResponsible.GetData().Name;

        uo.ProtocolsSettings = [];
        uo.ProtocolsDefaultValues = [];
        uo.References = [];
        uo.tosMailRecipients = [];
        uo.ccsMailRecipients = [];
        uo.bccsMailRecipients = [];

        this.References.GetReferences(uo.Id).forEach((r: IContactReference) => {
            r.ReferenceType = 0;
            uo.References.push(r);
        });

        this.ReferencedCompanies.GetReferences(uo.Id).forEach((r: IContactReference) => {
            r.ReferenceType = 1;
            uo.References.push(r);
        });

        this.ProtocolsSettings().forEach((p: IProtocolDefaultValuesSettingsUi) => {
            uo.ProtocolsSettings.push({
                ProtocolId: p.ProtocolId,
                DefaultValuesEnabled: p.Enabled(),
                DefaultMetadatasEnabled: p.MetadatasEnabled(),
            });

            if (p.Enabled()) {
                p.GetSettings().forEach((s: IProtocolSetting) => {
                    uo.ProtocolsDefaultValues.push(s);
                });
            }

            if (p.MetadatasEnabled()) {
                p.GetMetadata().forEach((m: IProtocolDefaultMetadatas) => {
                    const metadata: IProtocolDefaultMetadataForCustomer = {
                        Id: m.Id,
                        FKOrganizationalUnit: uo.Id,
                        ProtocolId: p.ProtocolId,
                        FKMetadata: m.FKMetadata,
                        MetadataValueType: m.MetadataValueType,
                        ShowMarkerOnAllocationsGantt: m.ShowMarkerOnAllocationsGantt,
                        PreloadOnDocument: m.PreloadOnDocument,
                    };

                    uo.ProtocolsDefaultMetadatas.push(metadata);
                });
            }

            uo.tosMailRecipients = uo.tosMailRecipients.concat(
                ProtocolMailRecipientMapper.mapToOrganizationalUnitMailRecipient(
                    p.getMailRecipientsForDocumentsSendTo(),
                    uo.Id
                )
            );

            uo.ccsMailRecipients = uo.ccsMailRecipients.concat(
                ProtocolMailRecipientMapper.mapToOrganizationalUnitMailRecipient(
                    p.getMailRecipientsForDocumentsSendCc(),
                    uo.Id
                )
            );

            uo.bccsMailRecipients = uo.bccsMailRecipients.concat(
                ProtocolMailRecipientMapper.mapToOrganizationalUnitMailRecipient(
                    p.getMailRecipientsForDocumentsSendBcc(),
                    uo.Id
                )
            );
        });

        return uo;
    }

    public Delete() {
        this.parent.OrganizationalUnits.remove(this);
        this.parent.SelectDefaultUo();
    }

    OnRecipientCodesLoaded(): void {
        this.AssignedRecipientCode(this.parent.GetRecipientCodeById(this.AssignedRecipientCodeId()));
    }

    public dispose(): void {}
}
