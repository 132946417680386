import * as ko from "knockout";
declare var infuser: any;

class SharedBootstrapKnockoutContext {
    public show: () => void = function() { };
    public hide: () => void = function() { };

    constructor(private templateName: () => string, private templateData: any, private templateUrl: () => string) {
        templateData().isVisible();
    }

    public isVisible(): boolean {
        return this.templateData().isVisible();
    }

    private alreadyRecalculatingPopoverPosition: boolean = false;

    private pollForTemplateLoaded(containerElement: any) {
        if ($(containerElement).html().trim() == infuser.defaults.loadingTemplate.content.trim()) {
//            console.log('Template still loading...');
            setTimeout(() => this.pollForTemplateLoaded(containerElement), 50);
            return;
        }

        console.log('Template loaded');
        if (this.isVisible() && !this.alreadyRecalculatingPopoverPosition) {
			console.log('Template show');
            this.alreadyRecalculatingPopoverPosition = true;
            this.show(); //base class recalculates popover position using new template width/height
            this.alreadyRecalculatingPopoverPosition = false;
        }
    }

    private bodyInitialized: boolean = false;
    private lastShown: number;
    private lastClicked: number;

    private initHideEvents(containerElement: any) {
        this.lastShown = Date.now();

        if (!this.bodyInitialized)
        {
            this.bodyInitialized = true;
            $(document).click((e) => {
                var now = Date.now();
                if ((now - this.lastShown < 10) || (now - this.lastClicked < 10))
                    return;
                this.hide();
            })
        }

        if (!containerElement.hideEvents) {
            containerElement.hideEvents = true;

            $(containerElement).click((e) => {
                this.lastClicked = Date.now();
            });
        }
    }

    public setContent(containerElement: any, hideCallback: () => void, showCallback: () => void): void {
		console.log('setContent');
        this.hide = hideCallback;
        this.show = showCallback;

        var data = this.templateData();
        (<any>ko).renderTemplate(this.templateName(), data, { templateUrl: this.templateUrl()}, containerElement);
        this.pollForTemplateLoaded(containerElement);

        var data = this.templateData();
        data.isVisible(true);

        this.initHideEvents(containerElement);
    }
}

(function extendBootstrapPopover($, ko) {
    console.log('extending Boostrap Popover');

    var KnockoutPopover = function(element, options, koContext) {
        this.internalKoContext = koContext;
        this.koContext = () => {
            return this.internalKoContext;
        };
        this.init('kopopover', element, options);

    }

    KnockoutPopover.prototype = $.extend({}, $.fn.popover["Constructor"].prototype, {
        constructor: KnockoutPopover,
        setContent: function() {
            var $tip = this.tip(), title = this.getTitle()

            $tip.find('.popover-title')[this.options.html ? 'html' : 'text'](title)
            var templateContainer = $tip.find('.popover-content')[0];
            this.koContext().setContent(templateContainer, this.hide.bind(this), this.show.bind(this));

            $tip.removeClass('fade top bottom left right in')
        }, hasContent: function() {
            return true;
        }
    });

    /* KO-POPOVER PLUGIN DEFINITION
     * ======================= */

    var old = $.fn["kopopover"];

    $.fn["kopopover"] = function (option, koContext) {
        return this.each(function () {
            var $this = $(this)
                , data = $this.data('bs.kopopover')
                , options = typeof option == 'object' && option
            if (!data) $this.data('bs.kopopover', (data = new KnockoutPopover(this, options, koContext)))
            if (typeof option == 'string') data[option]()
        })
    }

    $.fn["kopopover"]["Constructor"] = KnockoutPopover

    $.fn["kopopover"]["defaults"] = $.extend({} , $.fn.popover["defaults"], {
        placement: 'bottom'
        , trigger: 'click'
        , content: ''
        , template: '<div class="popover autosize"><div class="arrow"></div><div class="popover-inner"><h3 class="popover-title"></h3><div class="popover-content"></div></div></div>'
    })


    /* KOPOPOVER NO CONFLICT
     * =================== */

    $.fn["kopopover"]["noConflict"] = function () {
        $.fn["kopopover"] = old
        return this
    }
})($, ko);

export class PopoverBindingHandler {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any
    {
        console.log('init popover binding handler');
        var vm : any = ko.utils.unwrapObservable(valueAccessor());

        const koContext = new SharedBootstrapKnockoutContext(ko.utils.unwrapObservable.bind(this, vm.name), ko.utils.unwrapObservable.bind(this, vm.data), ko.utils.unwrapObservable.bind(this, vm.templateUrl));
        $(element).data("__shared_bootstrapKoContext__", koContext);
        (<any>$(element)).kopopover({html: true, customClass : vm.customClass }, koContext);

        return {
            controlsDescendantBindings: true
        };
    }

    update(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any {
        var sharedContext = <SharedBootstrapKnockoutContext> $(element).data("__shared_bootstrapKoContext__");
        if (sharedContext) {
            if (sharedContext.isVisible()) {
                //show or update
                sharedContext.show();
            } else {
                sharedContext.hide();
            }
        }
    }
}

(function($, ko) {
    console.log("Installing PopoverBindingHandler");
    ko.bindingHandlers['popover'] = new PopoverBindingHandler();
})($, ko);