import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { EstimateStatesEditingViewModel } from "./ui/EstimateStatesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IEstimateState, IEstimateStates, IEstimateStatesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class EstimateStatesSettingsManager implements IEstimateStates {
    private ajaxService : IAjaxService;

    private estimateStates : IEstimateState[] = [];
    private observers : IEstimateStatesObserver[] = [];

    constructor(private serviceLocator : IServiceLocator) {
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<IEstimateState[]> {
        var def = new Deferred<IEstimateState[]>();

        this.ajaxService.Get("Invoices-api", "EstimateState?getDeleted=true", {})
            .then((estimateStates: IEstimateState[]) => {
                this.estimateStates = estimateStates;
                def.resolve(estimateStates);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.EstimateStates;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.QuoteAndOrderStatus;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: 'estimateStates',
            templateUrl: 'invoices/templates/settings',
            viewModel: new EstimateStatesEditingViewModel(this.serviceLocator, this)
        };
    }

    getEstimateStates(getDeleted : boolean = false) : IEstimateState[] {
        return this.estimateStates.filter((s : IEstimateState) => {
            return getDeleted || s.Eliminato == 0;
        });
    }

    getEstimateStateById(estimateStateId : number) : IEstimateState {
        var estimateStates = this.estimateStates.filter((estimateState : IEstimateState) => estimateState.IdStatoPreventivo == estimateStateId);
        if(estimateStates.length == 0) return null;
        return estimateStates[0];
    }

    createOrUpdate(estimateState : IEstimateState) : Promise<IEstimateState> {
        var def = new Deferred<IEstimateState>();
        
        /*if(estimateState.IdStatoPreventivo > 0) {
            return this.ajaxService.Put("Invoices-api", "EstimateState", { methodData: estimateState})
                .then(this.onEstimateStateChanged.bind(this));
        }*/

        this.ajaxService.Post("Invoices-api", "EstimateState", { methodData: estimateState})
            .then((estimateState: IEstimateState) => {
                this.onEstimateStateAdded(estimateState);
                def.resolve(estimateState);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    remove(id : number) : Promise<void> {
        if(!id) {
            return Promise.reject<void>([])
        }

        var def = new Deferred<void>();

        this.ajaxService.Delete("Invoices-api", "EstimateState/" + id, {})
            .then(() => {
                this.onEstimateStateDeleted(id);
                def.resolve();
            })
            .catch(() => def.reject());

        return def.promise();
    }

    restore(id : number) : Promise<IEstimateState>
    {
        var def = new Deferred<IEstimateState>();

        this.ajaxService.Post("Invoices-api", "EstimateState/Restore?id=" + id, {})
            .then((estimateState: IEstimateState) => {
                this.onEstimateStateAdded(estimateState);
                def.resolve(estimateState);

            })
            .catch(() => def.reject());

        return def.promise();
    }

    addObserver(observer : IEstimateStatesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IEstimateStatesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    private onEstimateStateChanged(estimateState : IEstimateState) {
        this.load();
        this.observers.forEach((obs : IEstimateStatesObserver) => obs.onEstimateStateChanged(estimateState));
    }

    private onEstimateStateAdded(estimateState : IEstimateState) {
        this.load();
        this.observers.forEach((obs : IEstimateStatesObserver) => obs.onEstimateStateAdded(estimateState));
    }

    private onEstimateStateDeleted(estimateStateId : number) {
        this.load();
        this.observers.forEach((obs : IEstimateStatesObserver) => obs.onEstimateStateDeleted(estimateStateId));
    }
}