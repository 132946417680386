import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { BaseDataSource } from "./BaseDataSource";
import { INavigationMenuComponentModel } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceModel } from "./IDataSource";
import { ITodoListTemplateTask } from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDraggedTemplateTask } from "../ProlifeSdk/interfaces/todolist/ITodoList";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IObjectChangesInfo,
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
} from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { Deferred } from "../Core/Deferred";

export type ITemplateTasksDataSourceModel = INavigationMenuComponentModel<number, ITodoListTemplateTask>;

type NotificationAction = (changesInfo: IObjectChangesInfo, sentByMe: boolean) => void;
interface ITemplateTasksDataSourceNotificationsActions {
    [actionId: number]: NotificationAction;
}

export class TemplateTasksDataSource extends BaseDataSource<ITemplateTasksDataSourceModel> implements IChangesNotificationsServiceObserver {
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService!: ITodoListService;

    @LazyImport(ProlifeSdk.ChangesNotificationsServiceType)
    private changesNotificationsService!: IChangesNotificationsService;

    @LazyImport(ProlifeSdk.UserInfoServiceType)
    private userInfo!: IUserInfo;

    private templateIds: number[] = [];
    private notificationsEnabled = false;

    private notificationsActions: ITemplateTasksDataSourceNotificationsActions = {
        0: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*if(changesInfo.Object && changesInfo.Object.Task) {
                let model = this.createModelForTask(changesInfo.Object.Task);
                this.view.onItemCreated(model);
            }*/
            this.refresh();
        },
        1: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createModelForTask(changesInfo.Object.Task);
            this.view.onItemUpdated(model);*/
            this.refresh();
        },
        2: (changesInfo: IObjectChangesInfo, sentByMe: boolean) => {
            if (!this.view) return;

            /*let model = this.createMinimalModelForTask(changesInfo.Object.Task);
            this.view.onItemDeleted(model);*/
            this.refresh();
        },
    };

    enableNotifications(enabled: boolean) {
        if (this.notificationsEnabled == enabled) return;

        if (enabled) {
            this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TemplateTaskEntityTypeCode, this);
        } else this.changesNotificationsService.RemoveObserver(this);

        this.notificationsEnabled = enabled;
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sentByMe: boolean) {
        let templateId: number = null;
        let taskId: number = null;

        if (changesInfo.EntityCode == ProlifeSdk.TemplateTaskEntityTypeCode) {
            taskId = changesInfo.EntityKeyId;
            templateId = (changesInfo.Changes.NewStatus || changesInfo.Changes.OldStatus).TemplateId;
        }

        if (this.templateIds.indexOf(templateId) == -1) return false;

        this.notificationsActions[changesInfo.Action](changesInfo, sentByMe);
        return false;
    }

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Todolist.Templates;
    }

    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    setTemplates(templateIds: number[]) {
        this.templateIds = templateIds.slice();

        if (this.view) this.view.refresh(true);
    }

    getTemplates(): number[] {
        return this.templateIds.slice();
    }

    async getData(currentModel: ITemplateTasksDataSourceModel, textFilter: string, skip: number, count: number): Promise<ITemplateTasksDataSourceModel[]> {
        console.log("currentModel: ", currentModel, "textFilter: ", textFilter, "skip: ", skip, "count: ", count, "templates:", this.templateIds);

        const splittedTextFilter = (textFilter || "").split(" ").filter((t) => !!t);
        console.log("Splitted filter: ", splittedTextFilter);

        console.log("Querying server for tasks in template ", this.templateIds);
        //let tasks = await this.todoListService.GetTasksForUser(null, this.workflowIds, null, true, true, skip, count, true, false, null, true, false);
        const tasks = await this.todoListService.GetTemplatesTasks(splittedTextFilter, [], this.templateIds[0], null, -1, skip, count);
        console.log("Tasks: ", tasks);

        return this.createModelsForTasks(tasks);
    }

    getById(currentModel: IDataSourceModel, ids: number[]): Promise<ITemplateTasksDataSourceModel[]> {
        const deferred = new Deferred<ITemplateTasksDataSourceModel[]>();
        const promises: Promise<any>[] = [];

        for (const id of ids) {
            promises.push(this.todoListService.GetTemplateTaskById(id));
        }

        Promise.all(promises).then((tasks: ITodoListTemplateTask[]) => {
            deferred.resolve(this.createModelsForTasks(tasks));
        });

        return deferred.promise();
    }

    createModelsForTasks(tasks: ITodoListTemplateTask[]): ITemplateTasksDataSourceModel[] {
        return tasks.map((t) => {
            Object.defineProperty(t, "IsTemplateTask", { value: true });

            return {
                id: t.Id,
                isGroup: false,
                isLeaf: true,
                title: t.Title,
                dragEnabled: true,
                model: t,
            };
        });
    }

    public getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-template-task"];
    }

    public onItemBeginMove(model: ITemplateTasksDataSourceModel, dataTransfer: DataTransfer) {
        const task = model.model;

        const draggedTask: IDraggedTemplateTask = {
            TemplateTaskId: task.Id,
            TemplateId: task.TemplateId,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };

        dataTransfer.setData("text/plain", task.Title);
        dataTransfer.setData("application/prolife-template-task", JSON.stringify(draggedTask));
    }

    async onItemMoved(dataTransfer: DataTransfer, model: IDataSourceModel, before: boolean): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-template-task") >= 0 && model) {
            const droppedTask: IDraggedTemplateTask = JSON.parse(dataTransfer.getData("application/prolife-template-task"));
            const task: ITemplateTasksDataSourceModel = <ITemplateTasksDataSourceModel>model;

            if (this.userInfo.getCurrentCompanyGuid() != droppedTask.CompanyGuid) return;

            if (task.model.TemplateId != droppedTask.TemplateId) return;

            this.todoListService.MoveTemplateTask(droppedTask.TemplateTaskId, task.model.Id, before);
        }
    }
}
