import * as React from "@abstraqt-dev/jsxknockout";
import { HoursApprovalPanelViewModel, _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";

declare let Layout;

export class HoursApprovalRenderer {
    private panel: ko.Observable<_HoursApprovalPanelViewModel> = ko.observable();
    private observer: ko.Subscription;

    private requestedInterval: { startDate: Date, endDate: Date };

    constructor() {
        this.observer = this.panel.subscribe((panel) => {
            if (this.requestedInterval) {
                panel.setInterval(this.requestedInterval.startDate, this.requestedInterval.endDate);
                this.requestedInterval = null;
            }
        });
    }

    setInterval(startDate: Date, endDate: Date): void {
        const panel = this.panel();

        if (!panel) {
            this.requestedInterval = { startDate: startDate, endDate: endDate };
            return;
        }

        panel.setInterval(startDate, endDate);
    }

    render() {
        setTimeout(() => Layout.fixContentHeight(), 300);
        return <HoursApprovalPanelViewModel forwardRef={(panel) => this.panel(panel)} />;
    }
}