import * as ko from "knockout";
import scrollIntoViewIfNeeded from  "scroll-into-view-if-needed";

export class ScrollIntoView
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {

        var trigger = valueAccessor();

        var subscription : ko.Subscription = trigger.subscribe(() => {
            element.scrollIntoView();
        });

        $(element).on("remove", () => {
            subscription.dispose();
        });
    }

}

export class ScrollIntoViewOnlyIfTrue {
    update(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var trigger = valueAccessor();

        if(ko.isObservable(trigger)) {
            var subscription : ko.Subscription = trigger.subscribe(() => {
                if (trigger())
                    element.scrollIntoView();
            });

            $(element).on("remove", () => {
                subscription.dispose();
            });
        } else if(typeof trigger === "function") {
            ko.computed(() => {
                if(trigger())
                    element.scrollIntoView();
            }, null, { disposeWhenNodeIsRemoved: element });
        }
    }
}

export class ScrollIntoViewIfNeeded {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings, bindingContext: ko.BindingContext): void {
        let trigger = valueAccessor();
        let options = allBindingsAccessor.get("scrollOptions") || {
            scrollMode: 'if-needed',
            behavior: 'instant',
            block: 'nearest',
            inline: 'nearest'
        };

        if(ko.isObservable(trigger)) {
            let subscription : ko.Subscription = trigger.subscribe(() => {
                if (trigger()) {
                    scrollIntoViewIfNeeded(element, options);
                }
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscription.dispose();
            });
        } else if(typeof trigger === "function") {
            ko.computed(() => {
                if(trigger())
                    scrollIntoViewIfNeeded(element, options);
            }, null, { disposeWhenNodeIsRemoved: element });
        }
    }
}

ko.bindingHandlers["scrollIntoViewOnlyIfTrue"] = new ScrollIntoViewOnlyIfTrue();
ko.bindingHandlers["scrollIntoView"] = new ScrollIntoView();
ko.bindingHandlers["scrollIntoViewIfNeeded"] = new ScrollIntoViewIfNeeded();