import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { Requirement } from "../entities/Requirements/Requirement";
import { RequirementSource } from "../entities/Requirements/RequirementSource";
import { PreOrderRowStates } from "../enums/PreOrderRowStates";
import { IProvisioningService, IPreOrderRow, IRequirement } from "../../ProvisioningService";
import { SuppliersDataSource } from "../../../DataSources/SuppliersDataSource";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../Components/TableComponent/SecondaryRow";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { With } from "../../../Components/IfIfNotWith";
import { ComponentUtils, PropsWithChildren } from "../../../Core/utils/ComponentUtils";
import jss from "jss";
import { EntityInfo } from "../ui/panels/ArticleInfo";
import { RequirementSourceInfo } from "../ui/panels/RequirementSourceInfo";
import { CheckBox } from "../../../Components/Checkbox";

const { classes } = jss.createStyleSheet({
    requirementsImporter: {
        "& .filters-match > td": {
            backgroundColor: "#c9e6ff"
        },

        "& .checkbox-column": {
            width: "22px"
        },

        "& .entity-description-column": {
            maxWidth: "300px",
            minWidth: "300px",
            width: "300px"
        },

        "& .required-amount-column": {
            width: "140px",
            maxWidth: "140px",
            minWidth: "140px"
        },

        "& .sources-table": {
            "& .description-column": {
                width: "300px",
                maxWidth: "300px",
                minWidth: "300px"
            },

            "& .requirement-column": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px"
            },

            "& .amount-column": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px"
            },

            "& .stock-column": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px"
            },

            "& .other-stock-column": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px"
            },

            "& .delivery-date-column": {
                width: "140px",
                maxWidth: "140px",
                minWidth: "140px"
            },

            "& .joborder-column": {
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px"
            },

            "& .warehouse-column": {
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px"
            },

            "& .customer-column": {
                width: "200px",
                maxWidth: "200px",
                minWidth: "200px"
            }
        }
    }
}).attach();

export class RequirementsImporter extends DialogComponentBase {
    public ArticlesAndPurchasesSearchFilter: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public ShowArticlesAndPurchases: ko.Computed<boolean>;
    public ShowArticles: ko.Observable<boolean> = ko.observable(true);
    public ShowPurchases: ko.Observable<boolean> = ko.observable(true);
    
    public WarehouseSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public CustomerSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public JobOrderSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public DocumentNumberSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public DocumentFromSearch: ko.Observable<Date> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public DocumentToSearch: ko.Observable<Date> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public ProtocolSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public SupplierSearch : ko.Observable<number> = ko.observable();

    public OpenedRequirements: ko.ObservableArray<Requirement> = ko.observableArray([]);
    public FilteredOpenedRequirements: ko.ObservableArray<Requirement> = ko.observableArray([]);

    public EmptyRequirementsList: ko.Computed<boolean>;
    public SelectAll: ko.Computed<boolean>;
    public IsSearching: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    private filtersInterceptor: ko.Computed<void>;

    constructor() {
        super({ className: "fullscreen", actionLabel: "Importa" });
        this.title(ProlifeSdk.TextResources.Provisioning.RequirementsImporterTitle);

        this.ShowArticlesAndPurchases = ko.computed({
            read: () => {
                return (!this.ShowArticles() && this.ShowPurchases()) || ( this.ShowArticles() && !this.ShowPurchases()) ? null : this.ShowArticles() && this.ShowPurchases();
            },
            write: (value: boolean) => {
                this.ShowArticles(value);
                this.ShowPurchases(value);
            }
        });


        this.filtersInterceptor = ko.computed(() => {
            let requirements = this.OpenedRequirements();

            let articlePurchaseFilter = this.ArticlesAndPurchasesSearchFilter();
            let warehouseFilter = this.WarehouseSearch();
            let customerFilter = this.CustomerSearch();
            let typeFilter = this.ShowArticlesAndPurchases() ? null : this.ShowArticles() ? ProlifeSdk.WarehouseArticleEntityTypeCode : ProlifeSdk.EstimatedPurchaseEntityTypeCode;
            let jobOrderFilter = this.JobOrderSearch();
            let documentNumberFilter = this.DocumentNumberSearch();
            let protocolFilter = this.ProtocolSearch();
            let fromFilter = this.DocumentFromSearch();
            let toFilter = this.DocumentToSearch();

            let filteredRequirements = requirements.filter((r) => {
                return (this.contains(r.Description(), articlePurchaseFilter))
                    && (r.hasRowsForWarehouse(warehouseFilter))
                    && (r.hasRowsForCustomer(customerFilter))
                    && (!typeFilter || typeFilter === r.Type())
                    && (r.hasRowsForJobOrder(jobOrderFilter))
                    && (r.hasRowsForDocumentNumber(documentNumberFilter))
                    && (r.hasRowsForProtocol(protocolFilter))
                    && (r.hasRowsInRange(fromFilter, toFilter));
            });

            this.FilteredOpenedRequirements(filteredRequirements);
        });

        this.EmptyRequirementsList = ko.computed(() => {
            return this.FilteredOpenedRequirements().length === 0;
        });

        this.SelectAll = ko.computed({
            read: () => {
                return this.OpenedRequirements().filter(r => r.Selected()).length === this.OpenedRequirements().length;
            },
            write: (value: boolean) => {
                this.OpenedRequirements().forEach((r) => r.Selected(value));
            }
        });

        this.IsSearching = ko.computed(() => {
            return !!this.WarehouseSearch()
                || !!this.CustomerSearch()
                || !!this.JobOrderSearch()
                || !!this.DocumentNumberSearch()
                || !!this.ProtocolSearch()
                || !!this.DocumentFromSearch()
                || !!this.DocumentToSearch()
                || !!this.ArticlesAndPurchasesSearchFilter();
        });

        this.loadOpenedRequirements();
    }

    public async show(): Promise<IPreOrderRow[]> {
        return this.dialogsService.ShowModal<IPreOrderRow[]>(this);
    }

    public close(): void {
        this.modal.close([]);
    }
    
    public action(): void {
        let preOrderRows: IPreOrderRow[] = [];

        this.OpenedRequirements().forEach((r: Requirement) => {
            let selectedRequirements: IRequirement[] = r.Sources()
                .filter((s: RequirementSource) => s.Selected())
                .map((s) => s.getData() as IRequirement);

            selectedRequirements.forEach((sr) => {
                let preOrderRow = {
                    RowId: null,
                    EntityId: r.EntityId,
                    EntityType: r.Type(),
                    Amount: r.SelectedAmount(),
                    UoM: sr.UoM,
                    PreOrderId: null,
                    EntityDescription: r.Description(),
                    Stateid: PreOrderRowStates.OnWorkingStep,
                    DeliveryDate: r.EarlierDeliveryDate(),
                    RowSourceId: null,
                    CustomerOrderId: sr.CustomerOrderId,
                    CustomerOrderRowId: sr.CustomerOrderRowId,
                    CustomerOrderRowNumber: sr.CustomerOrderRowNumber,
                    EntityRefId: sr.RefId,
                    RefAmount: sr.Amount,
                    RefUoM: sr.UoM,
                    CustomerOrderRowDescription: sr.CustomerOrderRowDescription,
                    EntityRefDescription: sr.EntityDescription
                };

                preOrderRows.push(preOrderRow);
            });
            
        });

        this.modal.close(preOrderRows);
    }

    public contains(value: string, searchQuery: string): boolean {
        if (!searchQuery)
            return true;

        value = (value || "").toLowerCase();

        let words = searchQuery.trim().split(' ').filter(w => !!w);
        for (let word of words) {
            if (value.indexOf(word.toLowerCase()) < 0)
                return false;
        }

        return true;
    }

    private async loadOpenedRequirements(): Promise<void> {
        let requirements = [];//await this.provisioningService.GetOpenedRequirements();

        let requirementsViewModels = [];

        let lastEntityType = "";
        let lastEntityId = -1;
        let lastEntity: Requirement = null;

        requirements.forEach((r) => {
            if (lastEntityType != r.EntityType || lastEntityId != r.EntityId) {
                if (lastEntity && lastEntity.RequiredAmount() > 0.0001)
                    requirementsViewModels.push(lastEntity);

                lastEntity = new Requirement(r);
                lastEntityId = r.EntityId;
                lastEntityType = r.EntityType;
            }

            lastEntity.addSource(r);
        });

        if (lastEntity && lastEntity.RequiredAmount() > 0.0001)
            requirementsViewModels.push(lastEntity);

        this.OpenedRequirements(requirementsViewModels);
    }

    private createDataSourceModel(source: Requirement): IDataSourceModel<number, Requirement> {
        return {
            id: source.EntityId,
            title: source.Description(),
            isGroup: false,
            isLeaf: true,
            model: source
        };
    }

    public renderBody() {
        return  ComponentUtils.bindTo(
                    <LayoutWithHeader className={classes.requirementsImporter}>
                        <LayoutHeader className="flex-vertical no-gutters">
                            {this.renderImporterHeader()}
                        </LayoutHeader>
                        <LayoutContent className="requirements-list" noOverflow={true}>
                            {this.renderImporterBody()}
                        </LayoutContent>
                    </LayoutWithHeader>
                , this, "importer");
    }

    private renderImporterHeader() {
        let importer: RequirementsImporter;
        return  <>
                    <div class="row">
                        <div class="col-md-2 form-group">
                            <label>Magazzino</label>
                            <input type="text" class="form-control" placeholder="Cerca per magazzino..." data-bind={{ value: importer.WarehouseSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                        <div class="col-md-2 form-group">
                            <label>Cliente</label>
                            <input type="text" class="form-control" placeholder="Cerca per cliente..." data-bind={{ value: importer.CustomerSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label>Commessa</label>
                            <input type="text" class="form-control" placeholder="Cerca per commessa..." data-bind={{ value: importer.JobOrderSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                        <div class="col-md-2 form-group">
                            <label>Data documento dal</label>
                            <input type="text" class="form-control" placeholder="Cerca per data documento...." data-bind={{ nullableDatePicker: importer.DocumentFromSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                        <div class="col-md-2 form-group">
                            <label>Data documento al</label>
                            <input type="text" class="form-control" placeholder="Cerca per data documento...." data-bind={{ nullableDatePicker: importer.DocumentToSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                        <div class="col-md-3 form-group">
                            <label>Protocollo</label>
                            <input type="text" class="form-control" placeholder="Cerca per data protocollo...." data-bind={{ value: importer.ProtocolSearch, valueUpdate: 'afterkeydown' }} />
                        </div>
                    </div>
                </>;
    }

    private renderImporterBody() {
        let requirement: IDataSourceModel<number, Requirement>;
        let importer: RequirementsImporter;

        return  <Table compact={true} scrollable={true} striped={true} fixedLayout={true} dataSource={{ array: this.FilteredOpenedRequirements, factory: this.createDataSourceModel.bind(this) }} rowAs="requirement">
                    <Column headerCssClasses="checkbox-column" cssClasses="checkbox-column">
                        <ColumnHeader>
                            <input type="checkbox" data-bind={{ checkbox: importer.SelectAll }}></input>
                        </ColumnHeader>
                        <ColumnBody>
                            <div className="with-identifier">
                                <span className="identifier" data-bind={{ style: { 'background-color': (requirement.model.Type() == 'WAR' ? '#43b545' : '#56b7f7') } }}></span>
                                <input type="checkbox" data-bind={{ checkbox: requirement.model.Selected }} />
                            </div>
                        </ColumnBody>
                    </Column>
                    <Column title="Articolo/Acquisto" className="entity-description-column">
                        <ColumnHeader>
                            {() =>  <div className="btn-group btn-group-sm ignore-sort header-btn" style={{ marginRight: '5px' }}>
                                        <button className="btn btn-default dropdown" data-toggle="dropdown">
                                            Tipologie&nbsp;<i class="fa fa-angle-down"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-checkboxes">
                                            <input type="text" class="form-control input-sm" placeholder="Cerca..." data-bind={{ value: importer.ArticlesAndPurchasesSearchFilter, valueUpdate: 'afterkeydown' }} />
                                            <CheckBox label="Tutte" checked={this.ShowArticlesAndPurchases}></CheckBox>
                                            <CheckBox label="Articoli" checked={this.ShowArticles}></CheckBox>
                                            <CheckBox label="Acquisti" checked={this.ShowPurchases}></CheckBox>
                                        </div>
                                    </div>
                            }
                        </ColumnHeader>
                        <ColumnBody>
                            {(item : ITableItem<Requirement>) => (
                                <div className="flex-container flex-child-center">
                                    <span className="arrow" data-bind={{ click: requirement.model.switchExpandedState.bind(requirement.model) }}>
                                        <i className="fa" data-bind={{ css: { 'fa-caret-right': !requirement.model.IsExpanded(), 'fa-caret-down': requirement.model.IsExpanded } }}></i>
                                    </span>
                                    <EntityInfo 
                                        code={item.Data.model.EntityCode} 
                                        description={item.Data.model.EntityDescription} 
                                        rowDescription={item.Data.model.Description} 
                                        type={item.Data.model.Type} />
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title="Da ordinare" className="required-amount-column">
                        <span data-bind={{ numberText: requirement.model.RequiredAmount }}></span>
                    </Column>
                    <Column title="Selezionato">
                        <span data-bind={{ numberText: requirement.model.SelectedAmount }}></span>
                    </Column>
                    <SecondaryRow visible={() => "requirement.model.IsExpanded"}>
                        {(item) => this.renderSourceRowsTable(item)}
                    </SecondaryRow>
                </Table>;
    }

    private renderSourceRowsTable(tableItem: ITableItem<Requirement>) {
        let factory = (source: RequirementSource) => ({
            id: source.Id.toString() + "_" + source.Type,
            title: source.Description(),
            isGroup: false,
            isLeaf: true,
            model: source
        });

        let requirement: IDataSourceModel<string, Requirement>;
        let source: IDataSourceModel<string, RequirementSource>;

        let SearchableRow = (props : PropsWithChildren<unknown>) => {
            return <tr data-bind="css: { 'filters-match': source.model.MatchFilters() && importer.IsSearching() }">{props.children}</tr>
        }

        let { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<RequirementSource>();

        return  <td colSpan={4} data-bind={{ style: { 'background-color': (requirement.model.Type() == 'WAR' ? '#518c52' : '#56b7f7'), padding: '1px', paddingLeft: '2px' }}}>
                    <div data-bind={{ slimScroll: "100%" }} style={{ paddingBottom: "10px", backgroundColor: 'white' }}>
                        <Table compact={true} dataSource={{ array: tableItem.Data.model.Sources, factory: factory }} rowAs="source" className="sources-table" style={{ marginLeft: '-2px'}} components={{ row: SearchableRow }}>
                            <Column  headerCssClasses="checkbox-column">
                                <input type="checkbox" data-bind={{ checkbox: source.model.Selected }} />
                            </Column>
                            <Column title="Riga documento" sorter={sortString(s => s.Description())} className="description-column text-ellipsis">
                                <ColumnBody>
                                    {(item : ITableItem<RequirementSource>) => (
                                        <RequirementSourceInfo 
                                            docId={item.Data.model.CustomerOrderId}
                                            docNumber={item.Data.model.CustomerOrderNumber} 
                                            docDate={item.Data.model.CustomerOrderDate}
                                            rowNumber={item.Data.model.CustomerOrderRow}
                                            protocolName={item.Data.model.Protocol} />
                                    )}
                                </ColumnBody>
                            </Column>
                            <Column title="Fabbisogno" sorter={sortNumber(s => s.Requirement())} className="requirement-column text-ellipsis">
                                <span data-bind={{ numberText: source.model.Requirement }}></span>
                            </Column>
                            <Column title="Q.tà riga" sorter={sortNumber(s => s.Amount())} className="amount-column text-ellipsis">
                                <span data-bind={{ numberText: source.model.Amount }}></span>
                            </Column>
                            <Column title="In stock" sorter={sortNumber(s => s.WarehouseStock())} className="stock-column text-ellipsis">
                                <span data-bind={{ numberText: source.model.WarehouseStock }}></span>
                            </Column>
                            <Column title="Stock altri magazz." sorter={sortNumber(s => s.OthersWarehousesStock())} className="other-stock-column text-ellipsis">
                                <span data-bind={{ numberText: source.model.OthersWarehousesStock }}></span>
                            </Column>
                            <Column title="Data cons." sorter={sortDate(s => s.CustomerOrderRowDeliveryDate())} className="delivery-date-column text-ellipsis">
                                <span data-bind={{ dateText: source.model.CustomerOrderRowDeliveryDate, dateTextNoValue: 'N/A' }}></span>
                            </Column>
                            <Column title="Commessa" sorter={sortString(s => s.JobOrderName())} className="joborder-column text-ellipsis">
                                <span data-bind={{ text: source.model.JobOrderName }}></span>
                            </Column>
                            <Column title="Magazzino" sorter={sortString(s => s.SourceWarehouse())} className="warehouse-column text-ellipsis">
                                <span data-bind={{ text: source.model.SourceWarehouse }}></span>
                            </Column>
                            <Column title="Cliente" sorter={sortString(s => s.CustomerName())} className="text-ellipsis">
                                <span data-bind={{ text: source.model.CustomerName }}></span>
                            </Column>
                        </Table>
                    </div>
                </td>;
    }
}