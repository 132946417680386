import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { LazyImport } from "../../Core/DependencyInjection";

export class ContractsApplication implements IApplication
{
    public templateName : string;
    public templateUrl : string;
    public templateData : any;
    public tileColor : string = 'tile-green';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Contracts";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Contracts.Contracts;
    }

    getIcon(): string
    {
        return "icon-note";
    }

    registerRoutes() : void
    {
    }

    getApplicationRoute() : string
    {
        return "http://vecchiaintranet.ab/odl/elencocontratti.aspx";
    }

    onGoingDown() : void
    {
        if(this.templateData)
            this.templateData.dispose();
    }

    private start()
    {
    }
}