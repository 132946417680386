import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 16:41
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../../Core/interfaces/IInfoToastService";
import { ICategory } from "../../../../../interfaces/ICategory";
import { IQuestionnaireCategorySettingManager } from "../../../../../interfaces/IQuestionnaireCategorySettingManager";

export interface IQuestionnaireCategoriesProvider
{
    getData() : ICategory;
    update(category : ICategory) : void;
    withError: ko.Observable<boolean>;
}

export class QuestionnaireCategoryEditingViewModel {
    title : string;
    elements : ko.ObservableArray<IQuestionnaireCategoriesProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    NewCategory : ko.Observable<string> = ko.observable();
    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private categoriesManager : IQuestionnaireCategorySettingManager)
    {
        this.title = categoriesManager.getLabel();
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        categoriesManager.getQuestionnaireCategories()
            .forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(campaignType : ICategory)
    {
        this.elements.push(new CategoryViewModel(this.serviceLocator, this, campaignType));
    }

    public addNewSetting()
    {
        var vm = new CategoryViewModel(this.serviceLocator, this, {
            Id: null,
            Name: this.NewCategory(),
            Description: null,
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF"
        });
        this.elements.push(vm);
        this.createOrUpdateEntry(vm);
        this.NewCategory("");
    }

    public setIsSelectedNewSetting()
    {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IQuestionnaireCategoriesProvider)
    {
        var category = element.getData();
        this.categoriesManager.addOrUpdate(category)
            .then((updated) => element.update(updated));
    }

    public deleteEntry(element : IQuestionnaireCategoriesProvider)
    {
        var category = element.getData();
        this.categoriesManager.remove(category.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.toastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            })
            .catch(() => { });
    }

    private reloadElements() {
        this.elements([]);
        this.categoriesManager.load()
            .then(() => {
                this.categoriesManager.getQuestionnaireCategories()
                    .forEach(this.createViewModelFor.bind(this));
            });
    }
}


class CategoryViewModel implements IQuestionnaireCategoriesProvider
{
    private toastService : IInfoToastService;

    Id: ko.Observable<number> = ko.observable();
    Name : ko.Observable<string> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    Icon: ko.Observable<string> = ko.observable();
    Background: ko.Observable<string> = ko.observable();
    Foreground: ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);

    public withError: ko.Observable<boolean> = ko.observable(false);

    private updating : boolean = false;

    private currentDescription : string;

    constructor(private serviceLocator, private container : QuestionnaireCategoryEditingViewModel, private category : ICategory)
    {
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        this.update(category);

        this.Name.subscribe(this.onNameChanged.bind(this));
        this.Description.subscribe(this.onDataChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
    }

    private onNameChanged(newValue : string)
    {
        if(this.currentDescription == newValue || !newValue)
            return;

        this.withError(false);

        this.container.createOrUpdateEntry(this);
    }

    getData() : ICategory
    {
        var campaignType : ICategory = <ICategory> $.extend({}, this.category);
        campaignType.Name = this.Name();
        campaignType.Icon = this.Icon();
        campaignType.Description = this.Description();
        campaignType.Background = this.Background();
        campaignType.Foreground = this.Foreground();
        return campaignType;
    }

    private onDataChanged(newValue : string)
    {
        if(this.updating) return;
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    update(category : ICategory) : void
    {
        this.updating = true;

        this.category = category;
        this.Id(category.Id);
        this.Name(category.Name);
        this.Icon(category.Icon);
        this.Description(category.Description);
        this.Background(category.Background);
        this.Foreground(category.Foreground);

        this.currentDescription = category.Name;
        this.updating = false;
    }
}