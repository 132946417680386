import * as ko from "knockout";
export class TabOnEnter {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var nextElementIndexFromMe : number = valueAccessor()["nextIndexFromMe"] || 1;

        element.addEventListener('keydown', (event) => {
            var keycode = event.keyCode || event.which;
            if(!event.shiftKey && keycode == 13)
            {
                var allInputs = $(":input");
                if(allInputs.length > 1)
                    for(var i=0;i< allInputs.length; i++)
                    {
                        if(allInputs[i] == element)
                            $(i+nextElementIndexFromMe < allInputs.length && i+nextElementIndexFromMe >= 0 ?
                                allInputs[i+nextElementIndexFromMe] :
                                allInputs[0]).focus();
                    }
            }
            return true;
        });
    }
}

ko.bindingHandlers['tabOnEnter'] = new TabOnEnter();