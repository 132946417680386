import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 11/02/13
 * Time: 9.47
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { TelephoneNumber } from "../../../Desktop/utilities/TelephoneNumber";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ICustomersMetadataTypes } from "../../interfaces/ICustomersMetadataTypes";
import { IMetadataType } from "../../../ProlifeSdk/interfaces/customer/IMetadataType";
import { IMetadata } from "../../../ProlifeSdk/interfaces/customer/IMetadata";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
import { MailRecipientTypeSelectorUI } from "../../../Components/MailRecipientTypeSelector";
import { MetadataLogicalTypeSource } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";

export class CustomerMetadatasViewModel {
    @LazyImportSettingManager(ProlifeSdk.CustomersMetadataTypesSettingsManager)
    private metadataTypesManager: ICustomersMetadataTypes;

    public metadataGroups: ko.ObservableArray<CustomerMetadataGroupViewModel> = ko.observableArray([]);

    public HasChanges: ko.Computed<boolean>;

    constructor() {
        this.createMetadataGroups();

        this.HasChanges = ko.computed(() => {
            return !!this.metadataGroups().firstOrDefault((g) => g.HasChanges());
        });
    }

    private createMetadataGroups() {
        const metadataTypes = this.metadataTypesManager.getMetadataTypes();
        metadataTypes.forEach(this.createMetadataGroup.bind(this));
    }

    private createMetadataGroup(metadataType: IMetadataType) {
        this.metadataGroups.push(new CustomerMetadataGroupViewModel(metadataType));
    }

    public load(metadatas: IMetadata[]) {
        this.metadataGroups().forEach((group) => group.load(metadatas));
    }

    public getData(): IMetadata[] {
        const allMetadatas: IMetadata[] = [];
        this.metadataGroups().forEach((group) => group.getData().forEach((m) => allMetadatas.push(m)));
        return allMetadatas;
    }
}

@DetectClassChanges
class CustomerMetadataGroupViewModel {
    iconCode: string;
    name: string;
    isTelephone: boolean;
    isMail: boolean;

    @DetectChanges
    metadatas: ko.ObservableArray<CustomerMetadataViewModel> = ko.observableArray([]);
    isChanged: ko.Observable<number> = ko.observable(0);

    CanAddMetadata: ko.Computed<boolean>;

    public HasChanges: ko.Computed<boolean>;

    constructor(private metadataType: IMetadataType) {
        this.iconCode = "fa-" + metadataType.IconCode;
        this.name = metadataType.Descrizione;
        this.isTelephone = metadataType.SpecialData == 1;
        this.isMail = metadataType.SpecialData == 2;

        this.CanAddMetadata = ko.computed(() => {
            return (
                (this.metadataType.SpecialData !== 4 && this.metadataType.SpecialData !== 5) ||
                this.metadatas().length < 1
            );
        });

        this.HasChanges = ko.computed(() => {
            return !!this.metadatas().firstOrDefault((m) => m.isChanged() !== 0) || this.isChanged() !== 0;
        });
    }

    public load(metadatas: IMetadata[]) {
        this.metadatas([]);
        const metadatasOfThisType = metadatas.filter(
            (metadata: IMetadata) => metadata.FkCategoria == this.metadataType.Id
        );
        metadatasOfThisType.forEach(this.createViewModelFor.bind(this));

        this.isChanged(0);
    }

    private createViewModelFor(metadata: IMetadata) {
        this.metadatas.push(new CustomerMetadataViewModel(metadata, this.metadataType, false));
    }

    public Delete(metadata: CustomerMetadataViewModel) {
        this.metadatas.remove(metadata);
    }

    public AddMetadata(
        label: string,
        value: string,
        logicalType: number | null,
        autoScroll: boolean,
        event: Event = null
    ): void {
        if (!this.CanAddMetadata()) return;

        const metadata = new CustomerMetadataViewModel(
            {
                Label: label,
                Valore: value,
                LogicalType: logicalType,
            },
            this.metadataType,
            true
        );
        this.metadatas.push(metadata);
        if (autoScroll) metadata.HasFocus(true);

        if (!event || !autoScroll) return;

        const element = event.currentTarget as HTMLElement;
        scrollIntoViewIfNeeded(element, {
            scrollMode: "if-needed",
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
        });
    }

    public getData(): IMetadata[] {
        return this.metadatas().map((m) => m.getMetadata());
    }

    public dispose(): void {}
}

@DetectClassChanges
class CustomerMetadataViewModel {
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;

    @DetectChanges
    Label: ko.Observable<string> = ko.observable();
    @DetectChanges
    Value: ko.Observable<string> = ko.observable();
    @DetectChanges
    LogicalType: ko.Observable<number | null> = ko.observable(null);

    HasFocus: ko.Observable<boolean> = ko.observable(false);
    EnableLogicalType: ko.Observable<boolean> = ko.observable(false);

    LogicalTypeSelectorUI: MailRecipientTypeSelectorUI;

    isChanged: ko.Observable<number> = ko.observable(0);

    ValidatedValue: ko.Computed<string>;
    Link: ko.Computed<string>;
    Target: ko.Computed<string>;
    HasLink: ko.Computed<boolean>;

    constructor(private metadata: IMetadata, private metadataType: IMetadataType, private showValidationErrors) {
        const isMailMetadata = this.metadataType.Id === 3; // TODO rimuovere numero magico. Sarebbe buono avere un flag nel metadataType.

        this.Label(metadata.Label || this.getDefaultMetadataLabel());
        this.EnableLogicalType(isMailMetadata);

        if (isMailMetadata)
            this.LogicalTypeSelectorUI = new MailRecipientTypeSelectorUI({
                recipientTypes: this.LogicalType,
                dataSources: [MetadataLogicalTypeSource.CustomerMetadata],
                readonly: ko.observable(false),
            });

        this.ValidatedValue = ko.computed({
            read: this.Value,
            write: this.validateValue,
            owner: this,
        });
        this.Link = ko.computed(this.computeLink.bind(this));
        this.Target = ko.computed(this.computeTarget.bind(this));

        this.HasLink = ko.computed(() => {
            return this.Link() && this.Link().trim().length > 0;
        });

        this.Value(metadata.Valore || "");
        this.LogicalType(metadata.LogicalType);

        this.isChanged(0);
    }

    private getDefaultMetadataLabel() {
        switch (this.metadataType.SpecialData) {
            case 1:
                return ProlifeSdk.TextResources.Customers.Phone;
            case 2:
                return ProlifeSdk.TextResources.Customers.Email;
            case 3:
                return ProlifeSdk.TextResources.Customers.Web;
            case 4:
                return ProlifeSdk.TextResources.Customers.ExternalAccountingCustomerCode;
            case 5:
                return ProlifeSdk.TextResources.Customers.ExternalAccountingSupplierCode;
            default:
                return ProlifeSdk.TextResources.Customers.New;
        }
    }

    private computeLink() {
        const valore = this.ValidatedValue() ? this.ValidatedValue() : "";
        let link = "";
        link = this.metadataType.SpecialData == 1 ? "tel://" + valore : link;
        link = this.metadataType.SpecialData == 2 ? "mailto://" + valore : link;
        link = this.metadataType.SpecialData == 3 ? "http://" + valore.toUpperCase().replace("HTTP://", "") : link;
        return link;
    }

    private computeTarget() {
        return this.metadataType.SpecialData == 3 ? "_blank" : "";
    }

    private validateValue(newValue) {
        const newVal = this.metadataType.SpecialData == 1 ? TelephoneNumber.formatGSM(newValue) : newValue;
        this.Value(newVal);

        if (!this.showValidationErrors) this.ShowValidationErrors();

        return newVal;
    }

    public reset() {
        this.Label(this.getDefaultMetadataLabel());
        this.Value("");
        this.LogicalType(null);
    }

    public getMetadata() {
        const newMetadata: IMetadata = <IMetadata>$.extend({}, this.metadata);
        newMetadata.Label = this.Label();
        newMetadata.Valore = this.Value();
        newMetadata.FkCategoria = this.metadataType.Id;
        newMetadata.LogicalType = this.LogicalType();
        return newMetadata;
    }

    public isValid() {
        const isValid = !!this.Label() && this.Value() && this.Value().trim().length > 0;
        return isValid;
    }

    private ShowValidationErrors() {
        const regExp =
            this.metadataType && this.metadataType.ValidationRegExp
                ? new RegExp(this.metadataType.ValidationRegExp)
                : null;
        if (this.isValid() && regExp && !this.Value().match(regExp)) {
            const msg: string = String.format(
                ProlifeSdk.TextResources.Customers.InvalidData,
                this.Label(),
                this.metadataType.Descrizione
            );
            this.infoToast.Warning(msg);
        }
    }

    public dispose(): void {}
}
