import * as ko from "knockout";

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEventBaseInput } from "../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { MilestoneBlogEvent } from "../EventViewModels/MilestoneBlogEvent";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";

export class MilestoneInput extends BlogEventBaseInput {

	public templateName : string  = "milestone-details";
	public title : string = ProlifeSdk.TextResources.Blog.MilestoneTitle;

    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver,
                event : MilestoneBlogEvent)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconName = "f-icon-flag";
        this.setValues(event);
    }

    getBlogEvent(): ILogEvent {
        var localBlogEvent : ILogEvent = super.getBlogEvent();
        localBlogEvent.EventType = "Blog.Milestone";
        return localBlogEvent;
        
    }

	public getValidationMessage() : string[] {
		var message : string[] = super.getValidationMessage();

		if (this.subject() == null || this.subject().trim() == "")
			message = message.concat([ProlifeSdk.TextResources.Blog.RequiredObject]);

		return message;
	}
}