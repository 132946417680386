import * as ko from "knockout";
import { IAccount } from "../ProlifeSdk/interfaces/settings/IAccount";

export class CompanyIBANValue
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any) : void
    {
        var dataSource = valueAccessor();
        var IBANSource : ko.ObservableArray<IAccount> = allBindingsAccessor()["IBANSource"] || ko.observableArray([]);

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : (query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) => {
                var matchingIBANs = IBANSource().filter((a : IAccount) => {
                        if (!query)
                            return true;

                        var words = query.split(" ");
                        var matchedWords = words.filter(w => {
                            return a.IBAN.toLowerCase().indexOf(w.toLowerCase()) > -1 || (a.Description || "").toLowerCase().indexOf(w.toLowerCase()) > -1;
                        });

                        return matchedWords.length == words.length;
                    }
                );

                process(matchingIBANs.map((a : IAccount) => {
                    return {
                        Id: a.IBAN,
                        IBAN: a.IBAN,
                        Description: a.Description || "",
                        DisplayValue: a.IBAN + (a.Description ? " - " + a.Description : ""),
                        toString: function() {
                            return JSON.stringify(this);
                        },
                        toLowerCase: function() {
                            return this.DisplayValue.toLowerCase()
                        },
                        indexOf: function() {
                            return String.prototype.indexOf.apply(this.DisplayValue, arguments);
                        },
                        replace: function() {
                            return String.prototype.replace.apply(this.DisplayValue, arguments);
                        }
                    }
                }));
            },
            display: (json) => {
                var item = JSON.parse(json);
                return item.IBAN;
            },
            limit: 1000,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\">" + item.IBAN + "<br/><small style=\"color: grey\">" + item.Description + "</span></div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            dataSource(item.IBAN);
            return item.IBAN;
        });
    }

    update(element, valueAccessor : () => any) {
        var dataSource = valueAccessor();

        $(element).val(ko.utils.unwrapObservable(dataSource));
    }
}

ko.bindingHandlers["companyIBANValue"] = new CompanyIBANValue();