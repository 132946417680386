import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { StringArrayFunc } from "../Bindings/SortableList";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

const { classes } = jss.createStyleSheet({
    "d-zone": {
        width: "100%",
        height: "100%",
        position: "relative",

        "& .backdrop": {
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            display: "none",
            width: "100%",
            height: "100%",
            opacity: "0.45",
            backgroundColor: "black",

            "& > .drop-area-message": {
                display: "none",
                minWidth: "200px",
                margin: "auto"
            }
        },

        "&.droppable-hover": {
            "& .backdrop": {
                zIndex: 1000000,
                display: "flex",
                
                "& > .drop-area-message": {
                    color: "white",
                    display: "block"
                }
            }
        }
    }
}).attach();

type DropZoneProps = {
    allowedMimeTypes: string[] | ko.ObservableArray<string> | ko.Computed<string[]> | StringArrayFunc;
    onDrop: (dataTransfer: DataTransfer) => void;
    className?: string;
    hoverClass?: string;

    dropMessage?: React.ReactElement;

    children?: React.ReactElement;
}

export function DropZone(props: DropZoneProps) {
    const C = require("./DropZone")._DropZone as typeof _DropZone;
    return <C {...props} />;
}

export class _DropZone {
    static defaultProps: Partial<DropZoneProps> = {
        className: ''
    }

    constructor(private props : DropZoneProps) {
        
    }

    private onDrop(dataTransfer: DataTransfer) {
        this.props.onDrop(dataTransfer);
    }
    
    render() {
        const dz = this;

        return  ComponentUtils.bindTo((
            <div data-bind={{ droppableEx: { allowedMimeTypes: dz.props.allowedMimeTypes, hoverClass: "droppable-hover", onDrop: dz.onDrop.bind(dz) } }} className={ComponentUtils.classNames(classes["d-zone"], this.props.className)}>
                {this.props.children}
                <div className="backdrop">
                    <div className="drop-area-message">
                        {this.props.dropMessage ?? <div>{TextResources.ProlifeSdk.DropDefaultMessage}</div>}
                    </div>
                </div>
            </div>
        ), this, "dz");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(DropZone);
}