import * as ko from "knockout";
import * as numeral from "numeral";

export class PercentageValue {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const format = ko.utils.unwrapObservable(allBindingsAccessor.get("format")) || "0[.]0[0]%";

        const interceptor = ko
            .computed<string>({
                read: function () {
                    let value = underlyingObservable() || 0;
                    return numeral(value / 100).format(format);
                },
                write: function (newValue) {
                    if (newValue.indexOf("%") == -1) newValue = newValue + "%";
                    underlyingObservable(numeral(newValue).value() * 100);
                },
            })
            .extend({ notify: "always" });

        ko.applyBindingsToNode(element, { value: interceptor }, undefined);
    }
}

export class PercentageText {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const underlyingObservable = valueAccessor();
        const isNullable = allBindingsAccessor()["percentageTextNullable"] || false;
        const nullSymbol = allBindingsAccessor()["percentageTextNull"] || "";
        const format = ko.utils.unwrapObservable(allBindingsAccessor.get("format")) || "0[.]0[0]%";

        const interceptor = ko.computed<string>({
            read: function () {
                let value = ko.utils.unwrapObservable(underlyingObservable);
                if (isNullable && (value == null || value == undefined || value === "")) return nullSymbol;
                value = value || 0;
                return numeral(value / 100).format(format);
            },
            write: function (newValue) {
                if (newValue.indexOf("%") == -1) newValue = newValue + "%";
                underlyingObservable(numeral(newValue).value() * 100);
            },
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["percentageValue"] = new PercentageValue();
ko.bindingHandlers["percentageText"] = new PercentageText();
ko.virtualElements.allowedBindings["percentageText"] = true;
