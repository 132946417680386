import { LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService, ITaxRelief } from "../Invoices/DocumentsService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export type TaxReliefDataSourceModel = IDataSourceModel<number, ITaxRelief>;

export class TaxReliefDataSource extends BaseDataSource<TaxReliefDataSourceModel> {

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    getTitle(currentModel: TaxReliefDataSourceModel): string {
        return currentModel?.title || "";
    }

    async getData(currentModel: TaxReliefDataSourceModel, textFilter: string, skip: number, count: number): Promise<TaxReliefDataSourceModel[]> {
        const taxRelief = await this.documentsService.GetTaxRelief(textFilter, skip, count);
        return taxRelief.map(this.createTaxReliefDataSourceModel, this);
    }
    
    async getById(currentModel: TaxReliefDataSourceModel, ids: number[]): Promise<TaxReliefDataSourceModel[]> {
        const taxRelief = await this.documentsService.GetTaxReliefByIds(ids);
        return taxRelief.map(this.createTaxReliefDataSourceModel, this);
    }

    private createTaxReliefDataSourceModel(model: ITaxRelief): TaxReliefDataSourceModel {
        return {
            id: model.Id,
            title: model.Label,
            isGroup: false,
            isLeaf: true,
            model: model
        };
    }
}