import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { ICallRightType } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightType";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";

export class CallRightTypesDropDownList implements IDropDownList
{
    private elements : ICallRightType[] = [];
    public selectedId : ko.Observable<number> = ko.observable(-1);

    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightsSettingsService : ICallRightTypesSettingsManager;

    constructor(resourceType? : number){
        this.elements = this.callRightsSettingsService.getAll(false, resourceType);
    }

    public search(query : any)
    {
        var matches = this.elements.filter((u : ICallRightType) => {
            var name = this.renderText(u).toUpperCase();
            return name.indexOf((query.term || "").trim().toUpperCase()) > -1;
        });

        query.callback({
            results: matches
                .map((u : ICallRightType) => {
                    return {
                        id: u.Id,
                        text: this.renderText(u)
                    };
                }).sort((a, b) => {
                    if(a.text < b.text)
                        return -1;
                    if(a.text > b.text)
                        return 1;
                    return 0;
                })
        });
    }

    public getSelected(element, callback)
    {
        var selected = this.elements.filter((u : ICallRightType) => { return u.Id == this.selectedId(); });

        if(selected && selected.length > 0) {
            callback({
                id: selected[0].Id,
                text: this.renderText(selected[0])
            });
        }
    }

    public renderText(u : ICallRightType)
    {
        return (u.Name || "").trim();
    }
}
