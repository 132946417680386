import * as ko from "knockout";

declare module "knockout" {
    interface Subscription {
        subscribeChanged(callback : (previousValue : any, newValue : any) => void) : ko.Subscription;
    }

    interface Observable<T = any> extends ko.ObservableFunctions<T> {
        subscribeChanged(callback : (previousValue : T, newValue : T) => void) : ko.Subscription;
    
        greaterThan(otherObservable : Observable<T>) : Observable<T>;
        greaterOrEqualThan(otherObservable : Observable<T>) : Observable<T>;
        lowerThan(otherObservable : Observable<T>) : Observable<T>;
        lowerOrEqualThan(otherObservable : Observable<T>) : Observable<T>;
    }

    interface NotifiableComputed<T> extends ko.Computed<T> {
        valueWillMutate(): void;
        valueHasMutated(): void;
    }

    module utils {
        function observableBetween(minObservable, maxObservable) : void; 
        function conditionedObservable<T>(value : T, condition : (value : T) => boolean) : ko.Computed<T>;
        function notifyableComputed<T>(predicate: () => T, context?: any, options?: ko.ComputedOptions<T>) : NotifiableComputed<T>;
    }
}

ko.subscribable.fn["subscribeChanged"] = function(callback : (previousValue : any, newValue : any) => void) {
    var savedValue = this.peek();
    return this.subscribe(function (latestValue) {
        var oldValue = savedValue;
        savedValue = latestValue;
        callback(oldValue, latestValue);
    });
}

ko.observableArray.fn["subscribeChanged"] = function(callback : (previousValue : any, newValue : any) => void) {
    var savedValue = this.peek().slice(0);
    return this.subscribe(function (latestValue) {
        var oldValue = savedValue;
        savedValue = latestValue.slice(0);
        callback(oldValue, latestValue);
    });
};

ko.subscribable.fn["greaterThan"] = function(otherObservable) {
    this.subscribeChanged((previousValue, newValue) => {
        if(newValue != null && otherObservable() >= newValue) {
            this(previousValue);
        }
    });
    return this;
};

ko.subscribable.fn["greaterOrEqualThan"] = function(otherObservable) {
    this.subscribeChanged((previousValue, newValue) => {
        if(newValue != null && otherObservable() > newValue) {
            this(previousValue);
        }
    });
    return this;
};

ko.subscribable.fn["lowerThan"] = function(otherObservable) {
    this.subscribeChanged((previousValue, newValue) => {
        if(newValue != null && otherObservable() <= newValue) {
            this(previousValue);
        }
    });
    return this;
};

ko.subscribable.fn["lowerOrEqualThan"] = function(otherObservable) {
    this.subscribeChanged((previousValue, newValue) => {
        if(newValue != null && otherObservable() < newValue) {
            this(previousValue);
        }
    });
    return this;
};

ko.utils["observableBetween"] = function(minObservable, maxObservable) {
    minObservable.lowerOrEqualThan(maxObservable);
    maxObservable.greaterOrEqualThan(minObservable);
};

ko.utils["conditionedObservable"] = function<T>(value : T, condition : (value : T) => boolean) : ko.Computed<T> {
    let obs = ko.observable(value);
    let computed = ko.computed({
        read: () => obs(),
        write: (newValue) => {
            let v = ko.unwrap(newValue);
            if(condition(v))
                obs(v);
            else
                computed.notifySubscribers(obs());
        }
    });
    return computed;
}

ko.utils["notifyableComputed"] = function<T>(predicate: () => T, context?: any, options?: ko.ComputedOptions<T>) {
    let trigger = ko.observable().extend({ notify: 'always '});
    let target = ko.computed(function() {
        trigger();
        return predicate.call(context);
    }, context, options);
    target["valueHasMutated"] = function() {
        trigger.valueHasMutated();
    }
    target["valueWillMutate"] = function() {
        trigger.valueWillMutate();
    }
    return target as any;
}