/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 17:46
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import "fullcalendar";
import "fullcalendar/dist/locale/it.js";
import { Moment } from "moment";
import { IFullCalendar, IFullCalendarViewMap, IFullCalendarEventsReceiver } from "../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { EventSourceInput, OptionsInput, ToolbarInput } from "fullcalendar/src/types/input-types";
import { EventObjectInput } from "fullcalendar/src/exports";

const csss = require("../../node_modules/fullcalendar/dist/fullcalendar.css");

export class FullCalendar {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const options: OptionsInput = valueAccessor()['options'];
        $(element).fullCalendar(options);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const calendar: IFullCalendar = ko.utils.unwrapObservable(valueAccessor()['calendar']);
        const options: OptionsInput = valueAccessor()['options'];

        $(element).fullCalendar('removeEventSources', null);
        options.eventSources.forEach((s: EventSourceInput) => {
            $(element).fullCalendar('addEventSource', s);
        });

        $(element).fullCalendar('option', 'header', options.header);
        if (options.views)
            $(element).fullCalendar('option', 'views', options.views);

        $(element).fullCalendar('option', 'defaultView', options.defaultView);
        $(element).fullCalendar('option', 'viewRender', options.viewRender);
        $(element).fullCalendar('option', 'eventRender', options.eventRender);
        $(element).fullCalendar('option', 'eventDrop', options.eventDrop);
        $(element).fullCalendar('option', 'eventResize', options.eventResize);
        $(element).fullCalendar('option', 'eventClick', options.eventClick);
        $(element).fullCalendar('option', 'dayRender', options.dayRender);
        $(element).fullCalendar('option', 'slotDuration', options.slotDuration);
        $(element).fullCalendar('option', 'snapDuration', options.snapDuration);
        $(element).fullCalendar('option', 'editable', options.editable);
        $(element).fullCalendar('option', 'droppable', options.droppable);
        $(element).fullCalendar('option', 'selectable', options.selectable);
        $(element).fullCalendar('option', 'locale', options.locale);
        $(element).fullCalendar('option', 'drop', options.drop);
        $(element).fullCalendar('option', 'eventReceive', options.eventReceive);
        $(element).fullCalendar('option', 'select', options.select);
        $(element).fullCalendar('option', 'unselect', options.unselect);

        if (options.height)
            $(element).fullCalendar('option', 'height', options.height);
        if (options.eventOverlap)
            $(element).fullCalendar('option', 'eventOverlap', options.eventOverlap);
        if (options.dropAccept)
            $(element).fullCalendar('option', 'dropAccept', options.dropAccept);
        if (options.customButtons)
            $(element).fullCalendar('option', 'customButtons', options.customButtons);
        if (options.buttonText)
            $(element).fullCalendar('option', 'buttonText', options.buttonText);
        if (options.unselectAuto)
            $(element).fullCalendar('option', 'unselectAuto', options.unselectAuto);
        if (options.unselectCancel)
            $(element).fullCalendar('option', 'unselectCancel', options.unselectCancel);
        if (options.allDaySlot)
            $(element).fullCalendar('option', 'allDaySlot', options.allDaySlot);

        calendar.GetEvents = (idOrFilter = null) => {
            return $(element).fullCalendar('clientEvents', idOrFilter);
        };

        calendar.OnEventAdded.Add((event: EventObjectInput, stick = false) => {
            const currentEvents = $(element).fullCalendar('clientEvents', null);
            if (currentEvents.firstOrDefault(e => e.id === event.id))
                $(element).fullCalendar('updateEvent', event);
            else
                $(element).fullCalendar('renderEvent', event, stick);
        });

        calendar.OnEventUpdated.Add((modifiedEvent: EventObjectInput) => {
            $(element).fullCalendar('updateEvent', modifiedEvent);
        });

        calendar.OnEventDeleted.Add((eventId: number) => {
            $(element).fullCalendar('removeEvents', eventId);
        });

        calendar.OnEventsRefresh.Add(() => {
            $(element).fullCalendar('refetchEvents');
        });

        calendar.OnSetHeaderRequest.Add((header: boolean | ToolbarInput) => {
            $(element).fullCalendar('option', 'header', header);
        });

        calendar.OnChengeViewRequest.Add((view: string) => {
            $(element).fullCalendar('changeView', view, null);
        });

        calendar.OnSetViewsOptionRequest.Add((views: IFullCalendarViewMap) => {
            $(element).fullCalendar('option', 'views', views);
        });

        calendar.OnGoToDateRequest.Add((date: Moment) => {
            $(element).fullCalendar('gotoDate', date);
        });

        calendar.OnSelectRequest.Add((start: Moment, end: Moment = null) => {
            if (!end) {
                $(element).fullCalendar('select', start);
                return;
            }
            $(element).fullCalendar('select', start, end);
        });

        calendar.OnUnselectRequest.Add(() => {
            $(element).fullCalendar('unselect');
        });

        calendar.OnSetBusinessHoursRequest.Add((businessHours: any) => {
            $(element).fullCalendar('option', 'businessHours', businessHours);
        });

        calendar.OnSetSlotDurationRequest.Add((duration: string) => {
            $(element).fullCalendar('option', 'slotDuration', duration);
            $(element).fullCalendar('option', 'snapDuration', duration);
        });
    }
}

export class FullCalendarEventsDroppable {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const agenda: IFullCalendarEventsReceiver = ko.utils.unwrapObservable(valueAccessor());

        agenda.IsEventOverMe = (x: number, y: number) => {
            const el: JQuery = $(element);
            const offset = el.offset();
            (<any>offset).right = el.outerWidth() + offset.left;
            (<any>offset).bottom = el.outerHeight() + offset.top;

            if (x >= offset.left && y >= offset.top && x <= (<any>offset).right && y <= (<any>offset).bottom)
                return true;

            return false;
        };
    }
}

ko.bindingHandlers["fullCalendarEventsDroppable"] = new FullCalendarEventsDroppable();
ko.bindingHandlers["fullCalendar"] = new FullCalendar();