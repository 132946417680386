import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IWorkflowCategoriesSettingsManager } from "../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IWorkflowCategory } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";

export interface IWorkflowCategoryDisplayNameOptions {
    id: number;
    defaultDescription: string;
    serviceLocator: IServiceLocator;
}

export class WorkflowCategoryDisplayName
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options = valueAccessor();
        var settings = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var categoriesSettings : IWorkflowCategoriesSettingsManager = <IWorkflowCategoriesSettingsManager>settings.findSettingsManager(ProlifeSdk.WorkflowCategoriesType);

        var name : ko.Observable<string> = ko.observable(options.defaultDescription || "");

        categoriesSettings.GetCategories().then((categories : IWorkflowCategory[]) => {
            var matches = categories.filter((c : IWorkflowCategory) => {
                return c.Id == ko.utils.unwrapObservable(options.id);
            });

            if(matches.length > 0)
                name(matches[0].Name);
        });

        var interceptor = ko.computed(() => {
            return name();
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["workflowCategoryName"] = new WorkflowCategoryDisplayName();
ko.virtualElements.allowedBindings["workflowCategoryName"] = true;

