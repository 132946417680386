import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/07/2017
 * Time: 15:51
 * To change this template use File | Settings | File Templates.
 */

import { IPeopleSearchFilter, IPeopleSearchFiltersObserver } from "../../../../../ProlifeSdk/interfaces/survey/IPeopleService";

export class PeopleSearchFilters {
    public Name: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public Cf_Piva: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public PhoneNumber: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public Mail_PEC: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public SourceName: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public ImportDate: ko.Observable<Date> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    public ShowDeleted: ko.Observable<Boolean> = ko.observable();

    private subscriptions: ko.Subscription[] = [];

    constructor(private parent: IPeopleSearchFiltersObserver) {
        this.ShowDeleted(true);
        
        this.subscriptions.push(this.Name.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.Cf_Piva.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.PhoneNumber.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.Mail_PEC.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.SourceName.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.ImportDate.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
        this.subscriptions.push(this.ShowDeleted.subscribe(() => {
            this.parent.OnFiltersChanges();
        }));
    }

    public toJSON(): IPeopleSearchFilter {
        return <IPeopleSearchFilter> {
            Name: this.Name(),
            Cf_Piva: this.Cf_Piva(),
            PhoneNumber: this.PhoneNumber(),
            Mail_PEC: this.Mail_PEC(),
            SourceName: this.SourceName(),
            ImportDate: this.ImportDate(),
            ShowDeleted: this.ShowDeleted()
        };
    }

    public dispose(): void {
        this.subscriptions.forEach((s: ko.Subscription) => {
            s.dispose();
        });
    }
}