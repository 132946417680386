import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IReferenceDetailsViewModelFactory, IEntityRefInfo, IReferenceDetailsViewModel, ISimpleEntitiesViewModel } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentReferencesMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IReferenceForMap } from "../../../../bindings";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";

export class ResourceRoleRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    public UrlProvider : (docId : number, regId : number) => string;

    constructor()
    {
    }

    GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.Users.ProfessionalFigures;
    }

    CreateViewModelsFor(references : IRefDocumentRow[], details : IEntityRefInfo[], customerId: number, isReferencer : boolean) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        references.forEach((r : IRefDocumentRow) => {
            var matches = details.filter((d : IEntityRefInfo) => { return d.EntityKeyId == r.SourceEntityKeyId; });
            viewModels.push(new RoleRefDetailsViewModel(customerId, r, matches[0], isReferencer));
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(referencedLeafsInfo : ILeafReferenceInfo[], warehouseId : number, customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r : ILeafReferenceInfo) => {
            viewModels.push(new RoleLeafRefDetailsViewModel(r));
        });
        return viewModels;
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(referenceMap : IDocumentReferencesMap) : ISimpleEntitiesViewModel
    {
        //Implementare se necessario
        return new RolesEntitiesViewModel(referenceMap);
    }
}

export class RoleRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    public templateName : string;
    public templateUrl : string;
    public Title : string;

    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    @LazyImport(nameof<IDocumentsService>())
    private docService : IDocumentsService;

    constructor(private entityKeyId : number, private description : string)
    {
        this.templateUrl = "users/templates/documents/referencemap/referencedetails";

        var activationCode : string = this.docService.getRowReferencesGeneratorFactoryForEntity(ProlifeSdk.EstimatedWorkEntityTypeCode).ActivationCode;
        this.Title = activationCode + (this.sdkService.Utilities.Numbers.ZeroPad(entityKeyId.toString(), 5)) + " - " + (description || "");
    }

    ShowDetails()
    {
        window.open(ProlifeSdk.TextResources.Settings.SettingsDefaultUrl);
    }
}

export class RoleRefDetailsViewModel extends RoleRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(private customerId: number, private reference : IRefDocumentRow, private details : IEntityRefInfo, isReferencer : boolean)
    {
        super(details.EntityKeyId, details.Description);
        this.templateName = "role";
    }
}

export class RoleLeafRefDetailsViewModel extends RoleRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(private reference : ILeafReferenceInfo)
    {
        super(reference.EntityKeyId, reference.Description);
        this.templateName = "role-leaf";
    }
}

export class RolesEntitiesViewModel implements ISimpleEntitiesViewModel
{
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager : IUserCharactersSettingsManager;

    templateName : string = "documents-map-viewer-roles";
    templateUrl : string = "users/templates/documents/referencemap";
    EntityType : string = ProlifeSdk.EstimatedWorkEntityTypeCode;

    public Roles : ko.ObservableArray<RoleForReferenceMap> = ko.observableArray([]);

    constructor(private referenceMap : IDocumentReferencesMap)
    {
    }

    public LoadEntities(references : IReferenceForMap[]) : Promise<void>
    {
        var entitiesIds : number[] = references
            .filter((r : IReferenceForMap) => { return r.SourceEntityType == this.EntityType; })
            .map((r : IReferenceForMap) => { return r.SourceId; });

        var roles : RoleForReferenceMap[] = this.userCharactersSettingsManager.getUserCharacters()
        .filter((r : IUserCharacter) => { return entitiesIds.indexOf(r.IdUserCharacter) > -1; })
        .map((r : IUserCharacter) => {
            return new RoleForReferenceMap(r, this);
        });

        this.Roles(roles);
        return Promise.resolve<void>(undefined);
    }

    public SelectRelatedRows(entityId : number, entityType : string)
    {
        this.Roles().forEach((r: RoleForReferenceMap) => {
            r.IsSelected(r.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }

    public OnSelectionChanged(selected : RoleForReferenceMap)
    {
        this.Roles().forEach((a : RoleForReferenceMap) => {
            a.IsSelected(a == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.role.IdUserCharacter, this.EntityType);
    }
}

export class RoleForReferenceMap extends NodeSubElement
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public role : IUserCharacter, private container : RolesEntitiesViewModel)
    {
        super(container, role.IdUserCharacter, container.EntityType);
    }
}