import * as ko from "knockout";
import { ITodoListFolder } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";

export class TodoListFolder implements ITodoListFolder
{
    public Id : number;
    public IsSelected : ko.Observable<boolean> = ko.observable(false);
    public IsAggregateFolder : boolean;

    constructor(serviceLocator : IServiceLocator, private todolist : any, public title : string, public icon : string, public background : string, public foreground: string, id : number, isAggregate : boolean)
    {
        this.Id = id;
        this.IsAggregateFolder = isAggregate;
    }
}
