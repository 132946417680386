import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { DefaultValuesSettingsUi } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValuesSettingsUi";
import { LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IIvaModes, IIvaMode } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { IDDTAppearances, IDDTAppearance } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IDDTAppearances";
import { IDDTCauses, IDDTCause } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";
import { IConveyances, IConveyance } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IConveyances";
import { IDdtStates, IDdtState } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IDdtStates";
import {
    IDDTPortiSettingsManager,
    IDDTPorto,
} from "../../../../../ProlifeSdk/interfaces/invoice/settings/IDDTPortiSettingsManager";
import { IVatRegister } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolSetting } from "../../../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";
import { DefaultValueType } from "../../../../../ProlifeSdk/prolifesdk/documents/default-values/DefaultValueType";

export class DdtOrInvoiceDefaultValuesSettingsUi extends DefaultValuesSettingsUi {
    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModesManager: IIvaModes;
    @LazyImportSettingManager(ProlifeSdk.DDTAppearance)
    private appearanceManager: IDDTAppearances;
    @LazyImportSettingManager(ProlifeSdk.DDTCause)
    private causesManager: IDDTCauses;
    @LazyImportSettingManager(ProlifeSdk.Conveyance)
    private transportsManager: IConveyances;
    @LazyImportSettingManager(ProlifeSdk.DdtStates)
    private ddtStatesManager: IDdtStates;
    @LazyImportSettingManager(ProlifeSdk.DDTPorti)
    private ddtPortiManager: IDDTPortiSettingsManager;

    private IvaModes: IIvaMode[];
    private TransportsTypes: IConveyance[];
    private Appearances: IDDTAppearance[];
    private Causes: IDDTCause[];
    private DdtStates: IDdtState[];
    private DdtPorti: IDDTPorto[];

    private DefaultIva: ko.Observable<IIvaMode> = ko.observable(null);
    private DefaultAppearance: ko.Observable<IDDTAppearance> = ko.observable(null);
    private DefaultCause: ko.Observable<IDDTCause> = ko.observable(null);
    private DefaultTransport: ko.Observable<IConveyance> = ko.observable(null);
    private DefaultDdtState: ko.Observable<IDdtState> = ko.observable(null);
    private DefaultDdtPorto: ko.Observable<IDDTPorto> = ko.observable(null);
    private DefaultShowCustomerOrderReferimentOnFinalDocument: ko.Observable<boolean> = ko.observable(false);
    private DefaultShowExternalReferimentOnFinalDocument: ko.Observable<boolean> = ko.observable(false);

    private IsInvoice: ko.Observable<boolean> = ko.observable(false);
    private IsDDT: ko.Observable<boolean> = ko.observable(false);

    constructor(protocol: IVatRegister, protocolId: string) {
        super(protocol, protocolId);

        this.templateUrl = "invoices/templates/defaultvalues";
        this.templateName = "ddt-or-invoice-default-values";

        this.IvaModes = this.ivaModesManager.getIvaModes();

        this.TransportsTypes = this.transportsManager.getConveyances();

        this.Appearances = this.appearanceManager.getDDTAppearances();

        this.Causes = this.causesManager
            .getDDTCauses()
            .filter((c) => this.protocol.TipoDocumento === 1 || c.Tipologia !== 3);

        this.DdtStates = this.ddtStatesManager.getStates();

        this.DdtPorti = this.ddtPortiManager.getDDTPorti();

        this.IsInvoice(protocol.TipoDocumento == 0);
        this.IsDDT(protocol.TipoDocumento == 1);

        this.ShowAbiAndCabFields(false);
    }

    LoadSettings(settings: IProtocolSetting[], settingsEnabled: boolean) {
        super.LoadSettings(settings, settingsEnabled);

        settings.forEach((s: IProtocolSetting) => {
            if (s.ProtocolId != this.ProtocolId) return;

            if (s.SettingKey == ProlifeSdk.IvaSettingKey) {
                const ivaMatches = this.IvaModes.filter((im: IIvaMode) => {
                    return im.IdTipoIVA == s.IntValue;
                });

                this.DefaultIva(ivaMatches.length > 0 ? ivaMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.AppearanceSettingKey) {
                const ddtAppearenceMatches = this.Appearances.filter((im: IDDTAppearance) => {
                    return im.Id == s.IntValue;
                });

                this.DefaultAppearance(ddtAppearenceMatches.length > 0 ? ddtAppearenceMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.CauseSettingKey) {
                const ddtCauseMatches = this.Causes.filter((im: IDDTCause) => {
                    return im.Id == s.IntValue;
                });

                this.DefaultCause(ddtCauseMatches.length > 0 ? ddtCauseMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.TransportTypeSettingKey) {
                const coveyanceMatches = this.TransportsTypes.filter((im: IConveyance) => {
                    return im.Id == s.IntValue;
                });

                this.DefaultTransport(coveyanceMatches.length > 0 ? coveyanceMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.DdtStateSettingKey) {
                const ddtStateMatches = this.DdtStates.filter((state: IDdtState) => {
                    return state.Id == s.IntValue;
                });

                this.DefaultDdtState(ddtStateMatches.length > 0 ? ddtStateMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.DdtPortoSettingKey) {
                const ddtPortoMatches = this.DdtPorti.filter((p: IDDTPorto) => {
                    return p.Id == s.IntValue;
                });

                this.DefaultDdtPorto(ddtPortoMatches.length > 0 ? ddtPortoMatches[0] : null);
            }

            if (s.SettingKey == ProlifeSdk.ShowCustomerOrderReferenceOnFinalDocumentSettingKey) {
                this.DefaultShowCustomerOrderReferimentOnFinalDocument(!!s.IntValue);
            }

            if (s.SettingKey == ProlifeSdk.ShowExternalReferenceOnFinalDocumentSettingKey) {
                this.DefaultShowExternalReferimentOnFinalDocument(!!s.IntValue);
            }
        });
    }

    GetSettings(): IProtocolSetting[] {
        const settings = super.GetSettings();

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.IvaSettingKey,
                this.DefaultIva() ? this.DefaultIva().IdTipoIVA : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.AppearanceSettingKey,
                this.DefaultAppearance() ? this.DefaultAppearance().Id : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.CauseSettingKey,
                this.DefaultCause() ? this.DefaultCause().Id : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.TransportTypeSettingKey,
                this.DefaultTransport() ? this.DefaultTransport().Id : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.DdtStateSettingKey,
                this.DefaultDdtState() ? this.DefaultDdtState().Id : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.DdtPortoSettingKey,
                this.DefaultDdtPorto() ? this.DefaultDdtPorto().Id : null,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.ShowCustomerOrderReferenceOnFinalDocumentSettingKey,
                this.DefaultShowCustomerOrderReferimentOnFinalDocument() ? 1 : 0,
                DefaultValueType.IntValue
            )
        );

        settings.push(
            this.GetNewSetting(
                ProlifeSdk.ShowExternalReferenceOnFinalDocumentSettingKey,
                this.DefaultShowExternalReferimentOnFinalDocument() ? 1 : 0,
                DefaultValueType.IntValue
            )
        );

        return settings;
    }
}
