import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Document } from "./Document";

export function DocumentCause() {
    let doc: Document;

    return  <ko-if data-bind="CanHaveCause">
                <div class="invoice-shipping-informations bootstrap" data-as={{doc}}>
                    <table class="table table-condensed table-advance">
                        <thead>
                            <tr>
                                <th>{TextResources.Invoices.DocumentShippingInfoCause}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ko-bind data-bind={{ if: doc.ReadOnly }}>
                                        <input type="checkbox" data-bind={{ visible: doc.CanCopy, checked: doc.ShippingInfoSelected }} />
                                    </ko-bind>
                                    <select2 
                                        readOnly={() => "ReadOnly"}
                                        class="cause-selector select2-container-for-documents" 
                                        value={() => "FKCause"}
                                        dataSource={() => "WarehouseLoadCausesDataSource"}
                                        allowClear={true}
                                        simple={true}
                                        noFormControl={true}
                                        placeholder={TextResources.Invoices.DocumentShippingInfoCausePlaceholder}
                                        listener={() => "$data"}></select2>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ko-if>;
}