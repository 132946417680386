import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { RelateToObjManager } from "./RelateToObjManager";
import { IReminderService, IRemind, IRemindReferenceObjFieldType, IEntityRefKeyWithPath, IRemindUser, IRelatedObjTagField } from "../../../../ProlifeSdk/interfaces/reminder/IReminderService";
import { IEntityProviderService, IEntityDescriptor } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class RemindEditMaskDialog implements IDialog {

    private entitiesService : IEntityProviderService;
    public reminderService : IReminderService;
    private toastService : IInfoToastService;
    private dialogsService : IDialogsService;

    public templateName: string = "remind-edit-mask-dialog";
    public templateUrl: string = "reminder/templates";
    public title: string = ProlifeSdk.TextResources.Reminder.RemindEditMaskDialogTitle;
    modal: { close: (result?: any) => void; };

    private model : IRemind;

    public date : ko.Observable<Date> = ko.observable();
    public alertFrom : ko.Observable<Date> = ko.observable();
    public remindTitle : ko.Observable<string> = ko.observable();
    public notes : ko.Observable<string> = ko.observable();
    public priority : ko.Observable<number> = ko.observable();
    public relatedObjType : ko.Observable<string> = ko.observable();
    public relatedObjKey : ko.Observable<number> = ko.observable();
    public relatedObjPathDescription : ko.Observable<string> = ko.observable();
    public relatedObjTitleField : ko.Observable<number> = ko.observable();
    public relatedObjTitleFieldDescription : ko.Computed<string>;
    public relatedObjDateField : ko.Observable<number> = ko.observable();
    public relatedObjDateFieldDescription : ko.Computed<string>;

    public usersEntityProvider : IControlsEntityProvider;
    public usersStringArray : ko.Observable<string> = ko.observable("");

    public priorities : any[] = [];
    public isNew : ko.Observable<boolean> = ko.observable(false);

    private relationManager : RelateToObjManager;
    public entityFields : ko.ObservableArray<IRemindReferenceObjFieldType> = ko.observableArray([]); //Campi disponibili per la referenziazione relativi all'oggetto referenziato
    public entityFieldsForTitle : ko.Computed<IRemindReferenceObjFieldType[]>;
    public entityFieldsForDate : ko.Computed<IRemindReferenceObjFieldType[]>;

    public relatedObjTagsFieldsProvider :  RelatedObjFieldsForTagsProvider;

    constructor(private serviceLocator : IServiceLocator) {
        this.reminderService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
        this.toastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.entitiesService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);

        this.priorities.push({ id : 1, text : ProlifeSdk.TextResources.Reminder.RemindPriorityLow });
        this.priorities.push({ id : 2, text : ProlifeSdk.TextResources.Reminder.RemindPriorityMedium });
        this.priorities.push({ id : 3, text : ProlifeSdk.TextResources.Reminder.RemindPriorityHigh });

        var entityProviderService : IEntityProviderService = <IEntityProviderService>serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.usersEntityProvider = entityProviderService.getEntityProvider(ProlifeSdk.UsersEntityType).getControlsProvider();

        this.relationManager = new RelateToObjManager(this.serviceLocator);
        this.relatedObjTagsFieldsProvider = new RelatedObjFieldsForTagsProvider(serviceLocator);

        this.entityFieldsForTitle = ko.computed(() => {
            return this.entityFields().filter((f : IRemindReferenceObjFieldType) => {
                return f.IsForTitle;
            });
        });

        this.entityFieldsForDate = ko.computed(() => {
            return this.entityFields().filter((f : IRemindReferenceObjFieldType) => {
                return f.IsForExpireDate;
            });
        });

        this.relatedObjTitleFieldDescription = ko.computed(() => {
            var entityDescriptor : IEntityDescriptor = this.entitiesService.GetEntityDescriptor(this.relatedObjType());
            var matches = this.entityFieldsForTitle().filter((f : IRemindReferenceObjFieldType) => { return f.Id == this.relatedObjTitleField(); });
            return matches.length == 0 ? null : (entityDescriptor ? entityDescriptor.EntityName : "N/A") + " > " +  matches[0].Description;
        });

        this.relatedObjDateFieldDescription = ko.computed(() => {
            var entityDescriptor : IEntityDescriptor = this.entitiesService.GetEntityDescriptor(this.relatedObjType());
            var matches = this.entityFieldsForDate().filter((f : IRemindReferenceObjFieldType) => { return f.Id == this.relatedObjDateField(); });
            return matches.length == 0 ? null : (entityDescriptor ? entityDescriptor.EntityName : "N/A") + " > " +  matches[0].Description;
        });
    }

    public setTitleRefField(f : IRemindReferenceObjFieldType){
        this.relatedObjTitleField(f.Id);
        this.remindTitle("");
    }

    public resetTitleRefField(){
        this.relatedObjTitleField(null);
        this.remindTitle("");
    }

    public setDateRefField(f : IRemindReferenceObjFieldType){
        this.relatedObjDateField(f.Id);
        this.date(null);
    }

    public resetDateRefField(){
        this.relatedObjDateField(null);
        this.date(null);
    }

    public relateTo(){
        this.relationManager.initialize(this.relatedObjType(), this.relatedObjKey());
        this.dialogsService.ShowModal<boolean>(this.relationManager, null, null, this.relationManager.templateUrl, this.relationManager.templateName)
            .then((result : boolean) => {
                if(!result)
                    return;

                this.setReferencedObjVariables();
            });
    }

    private setReferencedObjVariables() : Promise<IEntityRefKeyWithPath> {
        return this.relationManager.getSelectedRef().then((ref : IEntityRefKeyWithPath) => {
            var entityDescriptor : IEntityDescriptor = this.entitiesService.GetEntityDescriptor(ref.EntityKeyType);
            this.relatedObjPathDescription(ref ? (entityDescriptor ? entityDescriptor.EntityName : "N/A") + " : " + ref.PathDescription  : null);
            this.relatedObjKey(ref ? ref.EntityKeyId : null);
            this.relatedObjType(ref ? ref.EntityKeyType : null);
            this.entityFields(ref ? this.reminderService.getReferenceFieldsForEntityType(ref.EntityKeyType) : []);
            this.relatedObjDateField(null);
            this.relatedObjTitleField(null);
            this.relatedObjTagsFieldsProvider.loadEntityType(ref ? ref.EntityKeyType : null);
            return ref;
        });
    }

    public initialize(remind : IRemind){
        this.model = remind;
        this.isNew(!remind.Id);

        this.date(remind.RelatedObjDateField ? null : remind.Date);
        this.remindTitle(remind.RelatedObjTitleField ? null : remind.Title);
        this.notes(remind.Notes);
        this.priority(remind.Priority);
        this.alertFrom(remind.AlertFrom);
        this.relationManager.initialize(remind.RelatedObjType, remind.RelatedObjId).then(() => {
            this.setReferencedObjVariables().then(() => {
                this.relatedObjDateField(remind.RelatedObjDateField);
                this.relatedObjTitleField(remind.RelatedObjTitleField);
                this.relatedObjTagsFieldsProvider.loadSelection(remind.RelatedObjTagFields);
            });
        });

        var usersString : string = "";
        remind.RelatedUsers.forEach((r : IRemindUser) => { usersString += r.FkUser + "|"; });
        if(usersString.length > 0)
            usersString = usersString.substr(0, usersString.length-1);
        this.usersStringArray(usersString);
        return this;
    }

    public setPriority(p : any) {
        this.priority(p.id);
    }

    public close(){
        this.modal.close();
    }

    public action(){

        if(!this.relatedObjTitleField() && (!this.remindTitle() || this.remindTitle().length == 0))
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Reminder.RemindSaveValidationInvalidTitle);
            return;
        }

        if(!this.relatedObjDateField() && !this.date())
        {
            this.toastService.Warning(ProlifeSdk.TextResources.Reminder.RemindSaveValidationInvalidExpireDate);
            return;
        }

        this.model.Date = this.date();
        this.model.Title = this.remindTitle();
        this.model.Notes = this.notes();
        this.model.Priority = this.priority();
        this.model.AlertFrom = this.alertFrom();
        this.model.RelatedObjType = this.relatedObjType();
        this.model.RelatedObjId = this.relatedObjKey();
        this.model.RelatedObjTitleField = this.relatedObjTitleField();
        this.model.RelatedObjDateField = this.relatedObjDateField();
        this.model.RelatedObjTagFields = this.relatedObjTagsFieldsProvider.getSelection();
        this.model.RelatedUsers = this.usersStringArray().split("|")
            .filter((s : string) => { return s && s.trim().length > 0; })
            .map((s : string) => { return { FkUser : parseInt(s) } });

        this.reminderService.createOrUpdate(this.model).then((m : IRemind) => {
            this.initialize(m);
            this.toastService.Success(ProlifeSdk.TextResources.Reminder.CreateOrUpdateRemindSuccessMessage);
        }).catch(() => { this.toastService.Error(ProlifeSdk.TextResources.Reminder.CreateOrUpdateRemindErrorMessage); });
    }

    public deleteItem(){
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Reminder.DeleteRemindWarning,
            ProlifeSdk.TextResources.Reminder.DeleteRemindWarningCancel,
            ProlifeSdk.TextResources.Reminder.DeleteRemindWarningConfirm, (confirm : boolean) => {
                if(!confirm)
                    return;

                this.reminderService.deleteRemind(this.model.Id)
                    .then(() => {
                        this.toastService.Success(ProlifeSdk.TextResources.Reminder.RemindDeleted);
                        this.close();
                    }).catch(() => {
                        this.toastService.Error(ProlifeSdk.TextResources.Reminder.RemindDeleteError);
                    });
            });
    }
}

export class RelatedObjFieldsForTagsProvider
{
    public reminderService : IReminderService;
    private fields : IRemindReferenceObjFieldType[] = [];

    public selectionStringArray : ko.Observable<string> = ko.observable("");

    constructor(serviceLocator : IServiceLocator)
    {
        this.reminderService = <IReminderService>serviceLocator.findService(ProlifeSdk.ReminderServiceType);
    }

    public loadEntityType(entityType : string){
        this.fields = this.reminderService.getReferenceFieldsForEntityType(entityType) || [];
        this.loadSelection([]);
    }

    public loadSelection(fields : IRelatedObjTagField[]){
        this.selectionStringArray((fields || []).map((f : IRelatedObjTagField) => { return f.FieldId; }).join("|"));
    }

    public getSelection() : IRelatedObjTagField[]{
        return this.selectionStringArray().split("|").map((f : string) => { return { FieldId : numeral(f).value() } });
    }

    public findEntities(query : any)
    {
        var matches = (this.fields || []).filter((f : IRemindReferenceObjFieldType) => { return (f.Description || "").toUpperCase().indexOf((query.term || "").toUpperCase()) > -1; })

        query.callback({
            results: matches
                .map((f : IRemindReferenceObjFieldType) =>
                {
                    return {
                        id: f.Id,
                        text: f.Description
                    };
                })
        });
    }

    /***
     * Estrae gli id già impostati sull'elemento e li ritorna in formato lista
     * @param element
     * @param callback
     */
    public findMultipleEntities(element, callback)
    {
        var ids : string[] = (<string>$(element).val()).split("|");
        if(ids.length == 0)
            return;

        var result : any[] = [];
        ids.map((s : string) => { return parseInt(s); })
            .forEach((id : number) => {
                var matches = (this.fields || []).filter((f : IRemindReferenceObjFieldType) => { return f.Id == id; });

                if(matches.length > 0)
                    result.push({
                        id: id,
                        text: matches[0].Description
                    });
            });

        callback(result);
    }
}