import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { InvoicesReport } from "./InvoicesReport";
import { CustomerDocuments } from "./CustomerDocuments";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomersProvider } from "../../../Desktop/utilities/CustomersProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { Deferred } from "../../../Core/Deferred";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";

export class InvoicesByCustomerReport extends InvoicesReport implements ICustomersProvider {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private customersService: ICustomersService;
    public selectedCustomer: ko.Observable<number> = ko.observable();

    private AllCustomers: CustomerDocuments;
    private firstLoad = true;

    private customersProvider: IControlsEntityProvider;

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(
            ProlifeSdk.TextResources.Invoices.CustomerInvoices,
            "invoices/templates/reports",
            "invoices-by-customer-report",
            groupId,
            2,
            "invoices-by-customer-report-filter",
            serviceLocator
        );
        this.customersService = <ICustomersService>serviceLocator.findService(ProlifeSdk.CustomersServiceType);

        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.customersProvider = entityProviderService
            .getEntityProvider(ProlifeSdk.CustomerEntityType)
            .getControlsProvider();

        const allCustomers = {
            IdCliente: -1,
            FormattedContactName: ProlifeSdk.TextResources.Invoices.AllCustomers,
        };
        this.AllCustomers = new CustomerDocuments(<ICustomer>allCustomers);

        this.selectedCustomer.subscribe(this.onCustomerSelected.bind(this));
    }

    initialize() {
        this.commonInitialize();
        this.quickFilterByMonth(new Date().getMonth() + 1);
        this.selectedCategory(this.AllCustomers);
    }

    public ExportAsExcel(versionId: number) {
        if (!this.startDate() || !this.endDate() || !this.selectedCategory()) return;

        const url =
            "Invoices/CustomerReport/GenerateExcel?" +
            this.getQueryString(versionId, this.selectedCategory().customer.IdCliente);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private getQueryString(versionId: number, customerId: number): string {
        return (
            "versionId=" +
            versionId +
            "&start=" +
            encodeURIComponent(moment(this.startDate()).format()) +
            "&end=" +
            encodeURIComponent(moment(this.endDate()).format()) +
            "&customerId=" +
            customerId
        );
    }

    public ExportAsPdf(versionId: number) {
        if (!this.startDate() || !this.endDate() || !this.selectedCategory()) return;

        const url =
            "Invoices/CustomerReport/Generate?" +
            this.getQueryString(versionId, this.selectedCategory().customer.IdCliente);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    onCustomerSelected() {
        if (this.selectedCustomer())
            this.customersService.getCustomerById(this.selectedCustomer()).then(this.onCustomerLoaded.bind(this));
        else this.selectedCategory(this.AllCustomers);
    }

    onCustomerLoaded(data) {
        const customerDocuments: CustomerDocuments = new CustomerDocuments(data);
        this.selectedCategory(customerDocuments);
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        this.noResultsFound(true);

        if (!this.startDate() || !this.endDate() || !this.selectedCategory()) return def.resolve().promise();

        this.invoicesService
            .getInvoicesByCustomerAndDates(this.selectedCategory().customer.IdCliente, this.startDate(), this.endDate())
            .then(this.onLoadDocumentsSucceeded.bind(this))
            .catch(this.onLoadDocumentsFailed.bind(this))
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    findCustomers(query: any) {}
    findCustomer(element, callback) {}
}
