import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 04/05/2018
 * Time: 10:25
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ITrustMovementsService, ITrustMovementDocumentInfo } from "../ProlifeSdk/interfaces/customer/ITrustMovementsService";
import { ITrustMovementRowInfo } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class TrustMovementsService implements ITrustMovementsService {
    private ajaxService : IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ITrustMovementsService>(), this);

        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType(): string {
        return ProlifeSdk.TrustMovementsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetMovementRows(documentId: number, entityType: string): Promise<ITrustMovementRowInfo[]> {
        return this.ajaxService.Post("Customers-api/TrustMovements", "GetTrustMovementRows", {
            methodData: {
                DocumentId: documentId,
                EntityType: entityType
            }
        });
    }

    GetPagedTrustsMovements(CustomerId: number | null, Skip: number | null, Count: number | null): Promise<ITrustMovementDocumentInfo[]> {
        return this.ajaxService.Post<ITrustMovementDocumentInfo[]>("Customers-api/TrustMovements", "GetPagedTrustsMovements", {
            background: true,
            methodData: {
        		CustomerId: CustomerId,
        		Skip: Skip,
        		Count: Count,
        	}
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new TrustMovementsService(serviceLocator);
}