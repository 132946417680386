import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DDTCausesEditingViewModel } from "./ui/DDTCausesEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IDDTCauses, IDDTCause, IDDTCauseCategory, IDDTCausesObserver } from "../../../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class DDTCauseSettingsManager implements IDDTCauses {
    private causes : IDDTCause[];
    private categories : IDDTCauseCategory[];
    private observers : IDDTCausesObserver[] = [];

    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }
    
    addObserver(observer : IDDTCausesObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : IDDTCausesObserver) : void {
        var index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<any> {
        let def = new Deferred<any>();
        
        Promise.all([
            this.ajaxService.Get<IDDTCause[]>("Invoices-api", "DDTCause", {})
                .then((appearances) => { this.causes = appearances; return appearances; }),
            this.ajaxService.Get<IDDTCauseCategory[]>("Invoices-api", "DDTCauseCategory", {})
                .then((categories) => { this.categories = categories; return categories; })
        ]).then(() => {
            def.resolve();
        }).catch(() => {
            def.reject();
        });

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.DDTCause;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.DDTDeliveryReason;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "causes",
            templateUrl: "invoices/templates/settings",
            viewModel: new DDTCausesEditingViewModel(this)
        };
    }

    getDDTCauses() : IDDTCause[] {
        return this.causes;
    }

    getDDTCauseCategories() : IDDTCauseCategory[] {
        return this.categories;
    }

    getDDTCauseIdByName(ddtCauseName : string) : number {
        var causesWithName = this.causes.filter((cause : IDDTCause) => cause.Descrizione == ddtCauseName);
        if(causesWithName.length == 0) return 0;
        return causesWithName[0].Id;
    }

    getDDTCauseById(ddtCauseId: number): IDDTCause {
        var causes = this.causes.filter((cause : IDDTCause) => cause.Id == ddtCauseId);
        if(causes.length == 0) return null;
        return causes[0];
    }

    getDDTCauseByName(ddtCauseName : string) : IDDTCause
    {
        var causesWithName = this.causes.filter((cause : IDDTCause) => cause.Descrizione == ddtCauseName);
        return causesWithName.length == 0 ? null : causesWithName[0];
    }

    createOrUpdate(cause : IDDTCause) : Promise<IDDTCause> {
        if(cause.Id > 0) {
            return this.ajaxService.Put<IDDTCause>("Invoices-api", "DDTCause/Update", { methodData: cause})
                .then(this.onDDTCauseChanged.bind(this));
        }

        return this.ajaxService.Post<IDDTCause>("Invoices-api", "DDTCause/Create", { methodData: cause})
            .then(this.onDDTCauseAdded.bind(this));
    }

    remove(causeId : number) : Promise<void> {
        return this.ajaxService.Delete<void>("Invoices-api", "DDTCause/" + causeId, {})
            .then(this.onDDTCauseDeleted.bind(this, causeId));
    }

    private onDDTCauseChanged(cause : IDDTCause) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTCauseChanged(cause));
    }

    private onDDTCauseAdded(cause : IDDTCause) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTCauseAdded(cause));
    }

    private onDDTCauseDeleted(causeId : number) {
        this.load();
        this.observers.forEach((obs) => obs.onDDTCauseDeleted(causeId));
    }
}