import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 31/03/2017
 * Time: 16:30
 * To change this template use File | Settings | File Templates.
 */
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SplashPage } from "./SplashPage";
import { EntitiesListNotificationsManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListNotificationsManager";
import { SplashPageColumnsNotificationManagerAdapter } from "./SplashPageColumnsNotificationManagerAdapter";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderForSplash } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IJobOrderState } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";

export class SplashPageColumnsView {
    public templateName = "splashPageColumnsView";
    public templateUrl = "joborder/templates";

    public Columns: ko.ObservableArray<SplashPageColumn> = ko.observableArray();
    public VisibleColumns: ko.Computed<SplashPageColumn[]>;

    private jobOrderService: IJobOrderService;
    private toastService: IInfoToastService;
    private jobOrderStatesManager: IJobOrderStateSettingsManager;

    private NotificationsManager: EntitiesListNotificationsManager;

    constructor(private serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.jobOrderService = <IJobOrderService>this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        const settingsService = <ISettingsService>this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.jobOrderStatesManager = <IJobOrderStateSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.JobOrderState)
        );

        this.NotificationsManager = new EntitiesListNotificationsManager(
            new SplashPageColumnsNotificationManagerAdapter(
                serviceLocator,
                this.parent.GetFilters.bind(this.parent),
                this.Columns,
                this.CreateViewModelFor.bind(this)
            )
        );

        this.VisibleColumns = ko.computed(() => {
            return this.Columns().filter((c: SplashPageColumn) => !c.hidden());
        });

        this.Refresh();
    }

    public dispose() {
        this.NotificationsManager.Dispose();
    }

    private CreateViewModelFor(jobOrder: IJobOrderForSplash) {
        return new JobOrderForSplash(this.serviceLocator, jobOrder, this);
    }

    public Refresh() {
        this.jobOrderService.GetJobOrdersForSplashCount(this.parent.GetFilters()).then((count) => {
            this.parent.JobOrdersCount(count);
        });

        const actualStateFilter = this.parent.stateNavigationMenu.GetFiltersFromActualPosition();

        let counter = 0;
        const columnsSize = 25;

        this.Columns(
            this.jobOrderStatesManager
                .getJobOrderStates()
                .filter((s: IJobOrderState) =>
                    actualStateFilter.LogicalState >= 0 ? s.LogicalState == actualStateFilter.LogicalState : true
                )
                .map((s: IJobOrderState) => {
                    return new SplashPageColumn(this.serviceLocator, this, s, columnsSize, columnsSize * counter++);
                })
        );

        this.RefreshColumnsSizes();

        this.Columns().forEach((c: SplashPageColumn) => {
            c.LoadJobOrders();
        });
    }

    public RefreshColumnsSizes() {
        let counter = 0;
        const columnsSize = 25;

        const columnsCount = this.VisibleColumns().length;
        let singleColumnSize = 100 / columnsCount;
        if (singleColumnSize < columnsSize) singleColumnSize = columnsSize;

        this.VisibleColumns().forEach((c: SplashPageColumn) => {
            c.columnWidth(singleColumnSize);
            c.columnOffset(singleColumnSize * counter++);
        });
    }

    public GetFiltersForState(state: IJobOrderState) {
        const filters = this.parent.GetFilters();
        if (filters.StateId > 0 && filters.StateId != state.IdJobOrderStateId)
            filters.StateId = 2147483647; //Disabilito la ricerca
        else filters.StateId = state.IdJobOrderStateId;

        filters.LogicalStateId = state.LogicalState;
        return filters;
    }

    SaveJobOrder(jobOrder: JobOrderForSplash) {
        this.jobOrderService
            .UpdateFromSplash(jobOrder.jobOrder, this.parent.GetFilters())
            .then((matchWithFilters: boolean) => {
                this.toastService.Success(ProlifeSdk.TextResources.JobOrder.UpdateCompleted);

                //All'aggiornamento sulla vista ci pensa il notification manager...
            });
    }
}

export class SplashPageColumn {
    public columnWidth: ko.Observable<number> = ko.observable(10);
    public columnOffset: ko.Observable<number> = ko.observable(0);

    public Title: ko.Observable<string> = ko.observable();
    public JobOrders: ko.ObservableArray<JobOrderForSplash> = ko.observableArray();
    public hidden: ko.Observable<boolean> = ko.observable(false);
    public status: number;

    public visible: ko.Computed<boolean>;

    public Loading: ko.Observable<boolean> = ko.observable(false);

    private jobOrderService: IJobOrderService;

    constructor(
        private serviceLocator: IServiceLocator,
        private parent: SplashPageColumnsView,
        private state: IJobOrderState,
        columnSize: number,
        columnOffset: number
    ) {
        this.jobOrderService = <IJobOrderService>this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);

        this.columnWidth(columnSize);
        this.columnOffset(columnOffset);

        this.Title(state.Description);
        this.status = state.IdJobOrderStateId;

        this.visible = ko.computed({
            read: () => {
                return !this.hidden();
            },
            write: (value: boolean) => {
                this.hidden(!value);
            },
        });

        this.hidden.subscribe(() => {
            setTimeout(() => {
                this.parent.RefreshColumnsSizes();
            }, 0);
        });

        this.LoadJobOrders();
    }

    public LoadJobOrders() {
        this.JobOrders([]);
        this.Loading(true);

        const filters = this.parent.GetFiltersForState(this.state);

        this.jobOrderService
            .GetJobOrdersForSplash(filters, 0, 10000000, true)
            .then((jobOrders: IJobOrderForSplash[]) => {
                this.Loading(false);
                this.JobOrders(
                    jobOrders.map((jobOrder: IJobOrderForSplash) => {
                        return new JobOrderForSplash(this.serviceLocator, jobOrder, this.parent);
                    })
                );
            });
    }

    public hide() {
        this.hidden(!this.hidden());
    }

    public OnJobOrderDropped(event: Event, ui) {
        if (!$(event.target).hasClass("column-container") || ui.draggable.parent().length == 0) return;

        const jobOrderId: number = parseInt(ui.draggable.attr("jobOrderId"));
        ui.draggable.remove(); //Rimuovo il task dal DOM (sarà riaggiunto in seguito a notifica server)
        this.jobOrderService.changeState(jobOrderId, this.status);
    }

    public ValidateJobOrderDrop(el: HTMLElement) {
        return parseInt($($($(el).parent()[0]).parent()[0]).attr("status")) != this.status;
    }

    public GetJobOrderById(jobOrderId: number): JobOrderForSplash {
        const matches = this.JobOrders().filter((j: JobOrderForSplash) => j.jobOrder.JobOrderId == jobOrderId);
        return matches.length > 0 ? matches[0] : null;
    }

    public RemoveJobOrderById(jobOrderId: number) {
        const matches = this.JobOrders().filter((j: JobOrderForSplash) => j.jobOrder.JobOrderId == jobOrderId);
        if (matches.length > 0) this.JobOrders.remove(matches[0]);
    }
}

export class JobOrderForSplash implements ILockableItem {
    private statesManager: IJobOrderStateSettingsManager;
    private typesManager: IJobOrderTypesSettingsManager;

    public State: ko.Observable<any> = ko.observable();
    public StateDescription: ko.Computed<string>;
    public States: IJobOrderState[];

    public Type: ko.Observable<any> = ko.observable();
    public TypeDescription: ko.Computed<string>;
    public Types: IJobOrderType[];

    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

    public DetailsUrl: ko.Observable<string> = ko.observable();

    constructor(
        serviceLocator: IServiceLocator,
        public jobOrder: IJobOrderForSplash,
        private parent: SplashPageColumnsView
    ) {
        this.ItemKey = jobOrder.JobOrderId;

        const settingsService: ISettingsService = <ISettingsService>(
            serviceLocator.findService(ProlifeSdk.SettingsServiceType)
        );
        this.statesManager = <IJobOrderStateSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.JobOrderState)
        );
        this.typesManager = <IJobOrderTypesSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderType);

        this.States = this.statesManager.getJobOrderStates();
        this.State(this.statesManager.getJobOrderStateById(jobOrder.StateId));
        this.StateDescription = ko.computed(() => {
            return this.State() ? this.State().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });

        this.Types = this.typesManager.getVisibleJobOrderTypes();

        this.Type(this.typesManager.getJobOrderTypeById(jobOrder.TypeId));
        this.TypeDescription = ko.computed(() => {
            return this.Type() ? this.Type().Description : ProlifeSdk.TextResources.JobOrder.PleaseSelect;
        });

        this.DetailsUrl("#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + this.jobOrder.JobOrderId);
    }

    public OpenJobOrder() {
        location.href = this.DetailsUrl();
    }

    public SetState(state: IJobOrderState) {
        this.State(state);
        this.SaveChanges();
    }

    public SetType(type: IJobOrderType) {
        this.Type(type);
        this.SaveChanges();
    }

    private SaveChanges() {
        this.jobOrder.StateId = this.State().IdJobOrderStateId;
        this.jobOrder.TypeId = this.Type().Id;
        this.parent.SaveJobOrder(this);
    }
}
