import * as ko from "knockout";
export class Slider {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var options : JQueryUI.SliderOptions = valueAccessor() || {};
        var valueField : ko.Observable<number> = allBindingsAccessor()["value"];

        $(element).slider(options)
            .on("slide", (event, ui) => {
                valueField(ui.value);
            });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var valueField : ko.Observable<number> = allBindingsAccessor()["value"];
        var options = valueAccessor();

        var disableOption = options.disabled;

        $(element).slider('value', valueField());

        if (disableOption != undefined) {
            if (ko.utils.unwrapObservable(disableOption))
                $(element).slider('disable');
            else
                $(element).slider('enable');
        }
    }
}

ko.bindingHandlers["slider"] = new Slider();
