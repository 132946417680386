import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { HomePage } from "./reports/ui/HomePage";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ReportsViewModel } from "./reports/ui/ReportsViewModel";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IOPAService } from "../Core/interfaces/IOPAService";
import { IApplication } from "../Desktop/interfaces/IApplication";
import { IApplicationsService } from "../Desktop/interfaces/IApplicationsService";

export class ReportsApplication implements IApplication
{
    public templateName : string = 'reports';
    public templateUrl : string = 'reports/templates';
    public templateData : ko.Observable<any> = ko.observable();
    public tileColor : string = 'tile-red';

    private applicationsService : IApplicationsService;

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Reports";
    }

    getName(): string
    {
        return ProlifeSdk.TextResources.Reports.Reports;
    }

    getIcon(): string
    {
        return "icon-bar-chart";
    }

    registerRoutes() : void
    {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/Reports", (context) => this.start());
        opaService.Get("#/Reports/:sectionId/:reportId", (context) => this.openReport(parseInt(context.params.sectionId), parseInt(context.params.reportId)));
    }

    getApplicationRoute() : string
    {
        return "#/Reports";
    }

    onGoingDown(): void
    {
        this.templateData().dispose();
        //delete this.templateData();
    }

    private openReport(sectionId : number, reportId : number)
    {
        if(this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            //delete this.templateData();
        }

        this.templateData(new ReportsViewModel(this.serviceLocator));
        this.templateData().openReport(sectionId, reportId);
        this.applicationsService.startApplication(this);
    }

    private start()
    {
        if(this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            //delete this.templateData();
        }

        this.templateData(new ReportsViewModel(this.serviceLocator));
        this.applicationsService.startApplication(this);
    }
}