import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { _TemplateEditMask } from "./TemplateEditMask";
import { WorkflowCategoriesManagerDialog } from "../../settings/ui/WorkflowCategoriesSettingsViewModel";
import { TemplateImportDialog } from "./TemplateImportDialog";
import { TemplateTasksDataSource } from "../../../../DataSources/TemplateTasksDataSource";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTemplate, ITodoListTemplateTask } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITemplateEditMaskObserver } from "../../../interfaces/ITemplateEditMaskObserver";
import { ITemplatesNavigator } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { INavigationMenuObserver } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { IFileRepositoryService } from "../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";

export class TemplatesDesigner implements INavigationMenuObserver, ITemplateEditMaskObserver
{
    templateUrl  = "todolist/templates/templates-designer";

    ExporterId  = "TodoList/TemplateActivityExport";

    private todoListService : ITodoListService;
    private dialogsService : IDialogsService;
    private fileRepositoryService : IFileRepositoryService;

    public DetailedMode : ko.Observable<boolean> = ko.observable(false);
    public TasksDataSource : TemplateTasksDataSource;
    public loadedTemplate : ko.Observable<_TemplateEditMask> = ko.observable(null);
    public templatesList : ITemplatesNavigator;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.fileRepositoryService = <IFileRepositoryService> serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);

        this.TasksDataSource = new TemplateTasksDataSource();
        this.TasksDataSource.enableNotifications(true);

        this.templatesList = this.todoListService.GetTemplatesNavigatorUI(false);
        this.templatesList.setSingleSelectionStatus(true, true);
        this.templatesList.Initialize();
        this.templatesList.addMenuObserver(this);
    }

    public OnTemplateSaved(t : ITodoListTemplate)
    {
        this.OnTemplateLoaded(t);

        this.templatesList.refreshItems().then(() => {
            this.templatesList.SetSelectedTemplates([t.Id]);
        });
    }

    private LoadTemplate(templateId : number)
    {
        this.todoListService.GetTemplate(templateId).then((t : ITodoListTemplate) => {
              this.OnTemplateLoaded(t);
        });
    }

    private OnTemplateLoaded(t : ITodoListTemplate)
    {
        this.loadedTemplate(new _TemplateEditMask({ template: t, parent: this}));
        this.TasksDataSource.setTemplates([t.Id]);
    }

    public OnTemplateDeleted()
    {
        this.PrepareNewTemplate();
    }

    notifyFilterResultIsChanged(filteredLeafs : INavigationMenuProvider[]){}

    onSelectionChanged(selection : INavigationMenuProvider[])
    {
        if(selection.length == 0)
            return;

        const templateId : number = (<any>selection[0]).template.Id;

        if(this.loadedTemplate() && this.loadedTemplate().IsChanged())
        {
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Todolist.SureToLooseTemplateChanges, ProlifeSdk.TextResources.Todolist.DoNotLooseTemplateChanges, ProlifeSdk.TextResources.Todolist.LooseTemplateChanges,
                (confirm : boolean) => {
                    if(!confirm)
                        return;

                    this.LoadTemplate(templateId);
                })
        }
        else
            this.LoadTemplate(templateId);

    }

    public PrepareNewTemplate()
    {
        const template : ITodoListTemplate = {
            Title: "",
            Description: "",
            Color: "rgb(0, 0, 0)",
            Resources : [],
            DefaultRoleId: -1,
            ReportingType: 0,
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false
        };

        this.loadedTemplate(new _TemplateEditMask({ template: template, parent: this }));
        this.loadedTemplate().Edit();
        this.templatesList.SetSelectedTemplates([]);
    }

    goHome()
    {
        location.href = "#/";
    }

    dispose()
    {
        //this.tasksList.Dispose();
        this.templatesList.Dispose();
    }

    public ShowCategoriesManager()
    {
        const dialog : IDialog = new WorkflowCategoriesManagerDialog();
        this.dialogsService.ShowModal<void>(dialog, "large", {}, dialog.templateUrl, dialog.templateName);
    }

    public ShowJobOrderTypesEditor()
    {
        const settingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        const jobOrderTypeManager = <IJobOrderTypesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.JobOrderType);
        jobOrderTypeManager.showEditingDialog();
    }

    public ImportTasks()
    {
        const dialog : IDialog = new TemplateImportDialog(this.serviceLocator, this.loadedTemplate().template.Id, this.loadedTemplate().template.Title);
        this.dialogsService.ShowModal<boolean>(dialog, "", null, dialog.templateUrl, dialog.templateName).then((result) => {
            if(result)
                this.LoadTemplate(this.loadedTemplate().template.Id);
        });
    }

    public getExcelExportData() : any | null {
        if(!this.loadedTemplate())
            return null;

        return {
            modelId: this.loadedTemplate().template.Id
        };
    }

    public ShowDetails(task : ITodoListTemplateTask) {
        return this.todoListService.ShowEditTasktTemplateDialog(task.Id);
    }

    public SwitchCompactView() {
        this.DetailedMode(false);
    }

    public SwitchDetailedView() {
        this.DetailedMode(true);
    }

    public AddNewTask() {
        const templateId = this.loadedTemplate()?.template.Id;
        return this.todoListService.ShowCreateTasktTemplateDialog(templateId);
    }
}
