import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2017
 * Time: 15:13
 * To change this template use File | Settings | File Templates.
 */

import { IEventHandler } from "../../interfaces/IEventHandler";

export class EventHandler implements IEventHandler {
    private handler : any[] = [];

    public Add(callback : any) : any {
        this.handler.push(callback);
        return callback;
    }

    public Remove(callback : any) {
        var found = this.handler.indexOf(callback);
        if(found >= 0)
            this.handler.splice(found, 1);
    }

    public Clear() {
        this.handler = [];
    }

    public Call(...args : any[]) {
        this.handler.forEach(h => h.apply(null, args));
    }
}