import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { ISignalrConnection, IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IEntitiesLockService } from "../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { LazyImport } from "../../Core/DependencyInjection";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";

declare var JsonNetDecycle: any;

export class SignalrConnectionManager implements ISignalrConnection {
    Id: string;

    private ajaxService: IAjaxService;
    private entitiesLockService: IEntitiesLockService;
    private desktopService: IDesktopService;
    private hubConnection: HubConnection;

    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;

    constructor(private serviceLocator: IServiceLocator) {
        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/ServiceNew/s/signalr", {
                accessTokenFactory: () => {
                    const token = this.authenticationService.getToken();
                    return token ?? "";
                },
            })
            .configureLogging(signalR.LogLevel.Debug)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (context) => 5000,
            })
            .build();
    }

    public Initialize() {
        this.entitiesLockService = <IEntitiesLockService>(
            this.serviceLocator.findService(ProlifeSdk.EntitiesLockServiceType)
        );
        this.desktopService = <IDesktopService>this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);

        this.hubConnection.onreconnecting(() => {
            this.desktopService.SystemHeader.NotifyReconnecting();
        });
        this.hubConnection.onreconnected(() => {
            this.OnConnectionStart();
        });
        this.hubConnection.onclose(() => {
            console.log("SignalR Disconnesso, riconnessione automatica in corso");
            this.desktopService.SystemHeader.NotifyConnectionLost();
        });
    }

    public Disconnect() {
        this.hubConnection.stop();
    }

    public getHubConnection(): HubConnection {
        return this.hubConnection;
    }

    public StartConnection() {
        this.desktopService.SystemHeader.NotifyConnecting();

        this.hubConnection
            .start() //Avvio l'ascolto sugli hub del server
            .then(this.OnConnectionStart.bind(this));
    }

    public async OnConnectionStart(): Promise<void> {
        try {
            await this.SyncConnectionOnServer();

            //Al primo avvio sblocco le entità precedentemente bloccate (caso di F5)
            this.desktopService.SystemHeader.NotifyConnected();
            this.entitiesLockService.UnlockEntitiesLockedFromMe();
        } catch {
            this.desktopService.SystemHeader.NotifyConnectionLost();
            this.hubConnection.stop();

            setTimeout(() => this.StartConnection(), 1000);
        }
    }

    /***
     * Notifica al server il connection id in modo da registrarlo nella sessione http
     * @returns {JQueryPromise}
     * @constructor
     */
    public async SyncConnectionOnServer(): Promise<void> {
        this.Id = this.hubConnection.connectionId;

        this.ajaxService.addBeforeSendInjector(
            (config) => {
                if (this.Id) {
                    config.headers!["SignalRConnectionId"] = this.Id;
                }

                if (typeof config.data === "object") {
                    config.data = JsonNetDecycle.retrocycle(config.data);
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }
}
