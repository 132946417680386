import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { WizardDialog, IDocumentBuilderDocumentRowsWithReferences } from "../../../../ProlifeSdk/prolifesdk/WizardDialog";
import { WizardStep } from "../../../../ProlifeSdk/prolifesdk/WizardStep";
import { ElectronicInvoicingDataSource } from "../data-sources/ElectronicInvoicingDataSource";
import { ElectronicInvoicingDataSourceStep } from "./steps/ElectronicInvoicingDataSourceStep";
import { ElectronicInvoicingSummaryStep } from "./steps/ElectronicInvoicingSummaryStep";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { ISalRow } from "../../../../ProlifeSdk/interfaces/sal/ISal";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentImportDataWizardStep } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDocumentForElectronicInvoicingViewModel } from "../../../interfaces/IDocumentForElectronicInvoicing";
import { Deferred } from "../../../../Core/Deferred";

export class ElectronicInvoicingWizard extends WizardDialog {
    public SelectedDocuments: ko.ObservableArray<IDocumentForElectronicInvoicingViewModel> = ko.observableArray([]);
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    
    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    constructor() {
        super("electronic-invoicing-wizard", "invoices/templates/wizard", ProlifeSdk.TextResources.Invoices.ElectronicInvoicing, 'DOC', [], <IWizardInitializationInfo>{});
        this.initializeSteps();
    }

    public back() : void
    {
        if(!this.CurrentStep().BeforeBack())
            return;

        if(!this.CanGoBack())
            return;

        var currentStepIndex = this.Steps.indexOf(this.CurrentStep());

        var step: IDocumentImportDataWizardStep = this.Steps()[currentStepIndex - 1];
        this.CurrentStep(step);
    }

    public next() : void
    {
        if(!this.CurrentStep().BeforeNext())
            return;

        if(this.IsLastStep())
            return;

        var currentStepIndex = this.Steps.indexOf(this.CurrentStep());
        if (currentStepIndex == 0) {
            let dataSource = this.dataSources()[currentStepIndex] as ElectronicInvoicingDataSource;

            this.SelectedDocuments(dataSource.GetData());
        }

        var step: IDocumentImportDataWizardStep = this.Steps()[currentStepIndex + 1];
        step.BeforeShow();
        this.CurrentStep(step);
    }

    public async action(): Promise<void>
    {
        this.dialogsService.LockUI(ProlifeSdk.TextResources.Invoices.ElectronicInvoicingOnExport);

        try
        {
            await this.invoicesService.ExportDocumentsForElectronicInvoicing(this.SelectedDocuments().map((d) => ({ EntityKeyId: d.DocumentId, EntityKeyType: d.EntityType })));
        }
        finally
        {
            this.dialogsService.UnlockUI();
            this.modal.close();
        }
    }

    public Show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", null, this.dialogTemplateUrl, this.dialogTemplateName);
    }

    public GetCustomSteps(): WizardStep[]
    {
        var steps: WizardStep[] = [];
        steps.push(new ElectronicInvoicingSummaryStep(this.SelectedDocuments, this.initializationInfo));
        return steps;
    }

    public OnDataSourcesSelected()
    {
        var steps = [];

        this.dataSources().forEach((d) => {
            steps.push(new ElectronicInvoicingDataSourceStep(d));
        });

        this.GetCustomSteps().forEach((s: WizardStep) => {
            steps.push(s);
        });

        steps[0].BeforeShow();

        this.Steps(steps);
        this.CurrentStep(steps[0]);
        //this.documentsToImportInfo = [];
    }

    public getRowsToImport(): Promise<IDocumentBuilderDocumentRowsWithReferences[]> {
        return Promise.resolve([]);
    }

    private initializeSteps(): void {
        this.Steps([]);
        var dataSource = new ElectronicInvoicingDataSource();
        this.dataSources([dataSource]);
    }
}