import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { JobOrderMetadataEditor } from "./ui/JobOrderMetadataEditor";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface IJobOrderMetadata {
    Id: number;
    ParentId: number | null;
    Name: string;
    Code: string;
    EntityType: string | null;
    EntityKeyId: number | null;
    HasChildren?: boolean;
}

export interface IJobOrderMetadataSettingsManager extends ISettingsManager {
    hasMetadataForJobOrderType(jobOrderTypeId: number): boolean;
    createOrUpdate(model: IJobOrderMetadata): Promise<IJobOrderMetadata>;
    deleteMetadata(id: number): Promise<void>;
    getRoots(): IJobOrderMetadata[];
    getChildren(parentId: number): IJobOrderMetadata[];
    getByIds(ids: number[]): IJobOrderMetadata[];
}

export class JobOrderMetadataSettingsManager implements IJobOrderMetadataSettingsManager {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(ProlifeSdk.SettingsServiceType)
    private settingsService: ISettingsService;

    private metadatas: IJobOrderMetadata[] = [];

    constructor() {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.JobOrder.OrdersSettingsGroup);
    }

    load(): Promise<any> {
        return this.ajaxService
            .Post<IJobOrderMetadata[]>("JobOrder-api/JobOrderMetadata", "GetAll", { background: true })
            .then((metadata) => {
                this.metadatas = metadata;
            });
    }

    getRoots(): IJobOrderMetadata[] {
        const roots = this.metadatas.filter((m) => !m.ParentId);

        for (let root of roots) {
            root.HasChildren = this.getChildren(root.Id).length > 0;
        }

        return roots;
    }

    getChildren(parentId: number): IJobOrderMetadata[] {
        const children = this.metadatas.filter((m) => m.ParentId === parentId);

        for (let child of children) {
            child.EntityKeyId = this.getEntityKeyIdFromRoot(child);
            child.HasChildren = this.hasChildren(child);
        }

        return children;
    }

    getByIds(ids: number[]): IJobOrderMetadata[] {
        const items = this.metadatas.filter((m) => ids.indexOf(m.Id) != -1);

        for (let item of items) {
            item.EntityKeyId = this.getEntityKeyIdFromRoot(item);
            item.HasChildren = this.hasChildren(item);
        }

        return items;
    }

    removeById(id: number): boolean {
        const items = this.metadatas.filter((m) => m.Id == id);
        if (items.length == 0) return false;

        let item = items[0];
        let index = this.metadatas.indexOf(item);
        this.metadatas.splice(index, 1);

        return true;
    }

    hasMetadataForJobOrderType(jobOrderTypeId: number): boolean {
        return this.metadatas.filter((m) => m.EntityKeyId === jobOrderTypeId).length > 0;
    }

    async createOrUpdate(model: IJobOrderMetadata): Promise<IJobOrderMetadata> {
        let isNew = !model.Id;

        return this.ajaxService
            .Post<IJobOrderMetadata>("JobOrder-api/JobOrderMetadata", "CreateOrUpdate", {
                methodData: model,
                background: true,
            })
            .then((metadata) => {
                if (!isNew) {
                    this.removeById(metadata.Id);
                }
                this.metadatas.push(metadata);

                this.infoToastService.Success("Salvataggio completato con successo!");

                return metadata;
            });
    }

    async deleteMetadata(id: number): Promise<void> {
        return this.ajaxService
            .Post<void>("JobOrder-api/JobOrderMetadata", "DeleteMetadata", {
                methodData: {
                    Id: id,
                },
                background: true,
            })
            .then(() => {
                this.removeById(id);

                this.infoToastService.Success("Metadato eliminato con successo!");
            });
    }

    getName(): string {
        return ProlifeSdk.JobOrderMetadataSettings;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.JobOrder.MetadataSettings;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "jobOrderMetadata",
            templateUrl: "JobOrder/Templates/Settings",
            viewModel: new JobOrderMetadataEditor(),
        };
    }

    private hasChildren(item: IJobOrderMetadata): boolean {
        return this.metadatas.filter((m) => m.ParentId === item.Id).length > 0;
    }

    private getEntityKeyIdFromRoot(child: IJobOrderMetadata): number {
        let parentId = child.ParentId;
        let parent = child;

        while (parentId) {
            parent = this.metadatas.firstOrDefault((m) => m.Id == parentId);
            parentId = parent?.ParentId;
        }

        return parent?.EntityKeyId;
    }
}
