import * as ko from "knockout";
import { INavigationMenuProvider, INavigationMenuActionBase } from "../../../interfaces/navigation-menu/INavigationMenuProvider";
import { INavigationMenu } from "../../../interfaces/navigation-menu/INavigationMenu";
import { Deferred } from "../../../../Core/Deferred";

export class NavigationProvider implements INavigationMenuProvider
{
    navigator : ko.Observable<any> = ko.observable(null);

    Id : number = -1;
    IsLeaf : boolean = false;
    Items : ko.ObservableArray<INavigationMenuProvider> = ko.observableArray([]);
    Loading : ko.Observable<boolean> = ko.observable(false);
    Name : string;
    templateName : string;
    templateUrl : string;
    SearchAllowed : boolean = true;
    AllowMultiLevelSearch : boolean  = false;
    SearchedItems : ko.ObservableArray<INavigationMenuProvider> = ko.observableArray([]);
    IsSelected : ko.Observable<boolean> = ko.observable(false);
    IsSingleSelectionEnabled : ko.Computed<boolean>;
    IsMultipleSelectionEnabled : ko.Computed<boolean>;
    HasSelectedItems : ko.Computed<boolean>;

    public IsDraggable: ko.Computed<boolean>;
    public InDragging: ko.Observable<boolean> = ko.observable(false);

    public MenuActions : ko.ObservableArray<INavigationMenuActionBase> = ko.observableArray([]);
    public SecondMenuActions: ko.ObservableArray<INavigationMenuActionBase> = ko.observableArray([]);

    constructor()
    {
        this.IsSingleSelectionEnabled = ko.computed(() => { return this.navigator() != null && this.navigator().singleSelectionEnabled()});
        this.IsMultipleSelectionEnabled = ko.computed(() => { return this.navigator() != null && this.navigator().multipleSelectionEnabled()});
        this.HasSelectedItems = ko.computed(() => {
            var hasSelectedItems : boolean = false;
            this.Items().forEach((i) => {
                hasSelectedItems = hasSelectedItems || i.HasSelectedItems() || i.IsSelected();
            });
            return hasSelectedItems;
        });

        this.IsDraggable = ko.computed(() => {
            return false;
        });
    }

    removeFromSelection(notifyToObservers : boolean = true)
    {
        if ((<any>this.navigator()).mobileBrowserEnabled()) notifyToObservers = false;
        (<any>this.navigator()).removeProviderFromSelection(this, notifyToObservers);
    }

    select(notifyToObservers : boolean = true) : void
    {
        (<any>this.navigator()).selectProvider(this, notifyToObservers);
    }

    addToMultipleSelectedJob(item,self) {
        (<any>self.navigator()).addToMultipleSelectedJob(item);
    }

    removeToMultipleSelectedJob(item,self) {
        (<any>self.navigator()).removeToMultipleSelectedJob(item);
    }

    open(item)
    {
        //Di default, se l'elemento è in movimento non esegue la navigazione
        if (this.InDragging())
            return;

        //Di default se non è una foglia si sposta verso il provider figlio richiesto
        if(!this.IsLeaf)
        {
            this.navigator().navigateTo(item);
            return;
        }

        //Gestione della selezione sulle foglie
        var funcToInvoke = null;
        var selectionEnabled: boolean = (<any>this.navigator()).singleSelectionEnabled() || (<any>this.navigator()).multipleSelectionEnabled();
        if (selectionEnabled && (<any>this.navigator()).multipleSelectionEnabled() && (<any>this.navigator()).mobileBrowserEnabled()) {
            this.IsSelected(!this.IsSelected());
            funcToInvoke = this.IsSelected() ? this.addToMultipleSelectedJob : this.removeToMultipleSelectedJob;
            funcToInvoke(item,this);
        } else {
            if (selectionEnabled && ((<any>this.navigator()).multipleSelectionEnabled() || !this.IsSelected() || (<any>this.navigator()).unselectOnSecondClick())) {
                this.IsSelected(!this.IsSelected());
                funcToInvoke = this.IsSelected() ? this.select : this.removeFromSelection;
                funcToInvoke.apply(this);
            }
        }
    }

    setCurrentNavigator(navigator : INavigationMenu)
    {
        this.navigator(navigator);
        this.Items().forEach((provider) => provider.setCurrentNavigator(navigator));
    }

    search(filter : string) : Promise<void>
    {
        //Fare override dove necessario
        return Promise.resolve<void>(undefined);
    }

    clearSearch()
    {
        this.search(null);
    }

    refreshItems() : Promise<any>
    {
        var deferred = new Deferred<void>();
        var childrenDefs : Promise<any>[] = [];
        //Di default propago il refresh sugli items figli
        this.Items().forEach(i => {
            childrenDefs.push(i.refreshItems());
        });

        if(childrenDefs.length > 0)
            Promise.all(childrenDefs)
                .then(() => { deferred.resolve(); })
                .catch(() => { deferred.reject(); });
        else
            deferred.resolve();

        return deferred.promise();
    }

    getAllFilteredLeafs() : INavigationMenuProvider[]
    {
        var leafs = [];

        if(this.IsLeaf)
            return [this];

        this.Items().forEach((i : INavigationMenuProvider) => {
            i.getAllFilteredLeafs().forEach((l : INavigationMenuProvider) => {
                leafs.splice(0, 0, l);
            })
        });
        return leafs;
    }
}
