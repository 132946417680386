import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";
import { IJobOrderForList } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ISelect2Query } from "../../../../ProlifeSdk/interfaces/prolife-sdk/providers/ISelect2Provider";
import { ITodoListTask } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflowLink, ITodoListWorkflow, ITodoListWorkflowForList } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";

@DetectClassChanges
export class WorkflowLink implements IDetectChanges {
    @DetectChanges
    JobOrderId : ko.Observable<number> = ko.observable();
    JobOrderName : ko.Observable<string> = ko.observable();
    @DetectChanges
    WorkflowId : ko.Observable<number> = ko.observable();
    WorkflowName : ko.Observable<string> = ko.observable();
    @DetectChanges
    TaskId : ko.Observable<number> = ko.observable();
    TaskName : ko.Observable<string> = ko.observable();

    isChanged: ko.Observable<number> = ko.observable(0);

    private loading  = false;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService : IJobOrderService;
    
    private jobOrderSearchTimeout : ReturnType<typeof setTimeout>;

    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;

    private workflowSearchTimeout : ReturnType<typeof setTimeout>;
    private taskSearchTimeout : ReturnType<typeof setTimeout>;

    constructor(private ownerId: number, private link : ITodoListWorkflowLink) {
        this.Load();
    }
    
    public dispose(): void {}

    public Load() {
        if(this.link.Type == ProlifeSdk.JobOrderTaskEntityTypeCode) {
            this.TaskId(this.link.ReferenceId);

            this.todoListService.GetTaskById(this.TaskId())
                .then((t : ITodoListTask) => {
                    this.TaskName(t.Title);

                    this.WorkflowId(t.RelatedWorkFlow);

                    this.todoListService.GetWorkflow(this.WorkflowId())
                        .then((w : ITodoListWorkflow) => {
                            this.WorkflowName(w.Title);

                            this.JobOrderId(w.JobOrderId);
                            this.isChanged(0);
                            this.jobOrdersService.GetJobOrderForList(this.JobOrderId())
                                .then((j : IJobOrderForList) => {
                                    this.JobOrderName(j.Name);
                                });
                        })
                });
        } else if(this.link.Type == ProlifeSdk.WorkflowEntityTypeCode) {
            this.WorkflowId(this.link.ReferenceId);

            this.todoListService.GetWorkflow(this.WorkflowId())
                .then((w : ITodoListWorkflow) => {
                    this.WorkflowName(w.Title);

                    this.JobOrderId(w.JobOrderId);
                    this.isChanged(0);
                    this.jobOrdersService.GetJobOrderForList(this.JobOrderId())
                        .then((j:IJobOrderForList) => {
                            this.JobOrderName(j.Name);
                        });
                });
        }
    }

    public IsValid() : boolean
    {
        return this.JobOrderId() > 0 && this.WorkflowId() > 0;
    }

    public Clone() : WorkflowLink
    {
        return new WorkflowLink(this.ownerId, this.getJson(false));
    }

    public Reset() {
        this.TaskId(null);
        this.WorkflowId(null);
        this.JobOrderId(null);
    }

    public getJson(keepId = true) : ITodoListWorkflowLink
    {
        if(this.link.Id) {
            return <ITodoListWorkflowLink> $.extend(this.link, {
                Type: this.TaskId() ? ProlifeSdk.JobOrderTaskEntityTypeCode : ProlifeSdk.WorkflowEntityTypeCode,
                ReferenceId: this.TaskId() ? this.TaskId() : this.WorkflowId()
            });
        } else {
            return {
                Id: -1,
                WorkflowId: this.ownerId,
                Type: this.TaskId() ? ProlifeSdk.JobOrderTaskEntityTypeCode : ProlifeSdk.WorkflowEntityTypeCode,
                ReferenceId: this.TaskId() ? this.TaskId() : this.WorkflowId()
            };
        }
    }

    public findJobOrders(query : ISelect2Query) {
        if(this.jobOrderSearchTimeout)
            clearTimeout(this.jobOrderSearchTimeout);

        this.jobOrderSearchTimeout = setTimeout(() => {
            this.jobOrdersService.GetJobOrdersList(-1, -1, -1, false, query.term, -1)
                .then((jobOrders : IJobOrderForList[]) => {
                    query.callback({
                        results: jobOrders.map((j : IJobOrderForList) => {
                            return {
                                id: j.JobOrderId,
                                text: j.Name
                            };
                        })
                    });
                })
        }, 500);
    }

    public findJobOrder(element, callback) {
        const id = $(element).val();
        if(id !== "") {
            this.jobOrdersService.GetJobOrderForList(<number>id)
                .then((jobOrder : IJobOrderForList) => callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name
                }));
        }
    }

    public findWorkflows(query : ISelect2Query) {
        if(this.workflowSearchTimeout)
            clearTimeout(this.workflowSearchTimeout);

        this.workflowSearchTimeout = setTimeout(() => {
            this.todoListService.GetWorkflowsForList(this.JobOrderId(), query.term)
                .then((workflows : ITodoListWorkflowForList[]) => {
                    query.callback({
                        results: workflows.map((w : ITodoListWorkflowForList) => {
                            return {
                                id: w.Id,
                                text: w.Title
                            };
                        })
                    });
                })
        }, 500);
    }

    public findWorkflow(element, callback) {
        const id = $(element).val();
        if(id !== "") {
            this.todoListService.GetWorkflowForList(<number>id)
                .then((workflow : ITodoListWorkflowForList) => callback({
                    id: workflow.Id,
                    text: workflow.Title
                }));
        }
    }

    public findTasks(query : ISelect2Query) {
        if(this.taskSearchTimeout)
            clearTimeout(this.taskSearchTimeout);

        this.taskSearchTimeout = setTimeout(() => {
            const textFilters = [];

            if((query.term || "").length > 0)
                textFilters.push(query.term);

            this.todoListService.GetTasks(textFilters, [], [parseInt(<any>this.WorkflowId())], null, -1, -1, -1)
                .then((tasks : ITodoListTask[]) => {
                    query.callback({
                        results: tasks.map((t : ITodoListTask) => {
                            return {
                                id: t.Id,
                                text: t.Title
                            };
                        })
                    });
                })
        }, 500);
    }

    public findTask(element, callback) {
        const id = $(element).val();
        if(id !== "") {
            this.todoListService.GetTaskById(<number>id)
                .then((task : ITodoListTask) => callback({
                    id: task.Id,
                    text: task.Title
                }));
        }
    }
}