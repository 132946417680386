/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/08/2016
 * Time: 12:56
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { JobOrderViewModel } from "./JobOrderViewModel";
import { WorkflowsDataSource } from "../../../../DataSources/WorkflowsDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { WorkflowEditDialog } from "../../../../Todolist/Todolist/ui/workflows/WorkflowEditDialog";
import { IWorkflowOutcomeSettingsManager } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowOutcomeSettingsManager";
import { IWorkflowOutcome } from "../../../../Todolist/WorkflowOutcomesService";
import { IHumanResource } from "../../../../Users/HumanResourcesService";
import {
    IJobOrderEditor,
    IJobOrderEditorPanel,
    IJobOrderEditorPanelFactory,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentTemplatesProvider,
    INavigationMenuComponentAction,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkflowEditorResult } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderDetailsObserver } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderDetailsObserver";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { WorkedHoursJobOrderDateDataSource } from "../../../../DataSources/WorkedHoursJobOrderDateDataSource";
import { DatesMenuTemplateProvider } from "../../../../WorkedHours/workedhours/ui2019/navigation/DatesMenuTemplateProvider";
import { AlertsLegendUI } from "../../../../Components/AlertsLegend";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class JobOrderEditor implements IJobOrderEditor, IDataSourceListener {
    templateName = "editor";
    templateUrl = "jobOrder/Templates/JobOrderDetail";

    public Panels: ko.ObservableArray<IJobOrderEditorPanel> = ko.observableArray();
    public ActivePanel: ko.Observable<IJobOrderEditorPanel> = ko.observable();

    public UseWorkDaysMenu: ko.Observable<boolean> = ko.observable(false);

    private DefaultPanel: IJobOrderEditorPanel;
    private DefaultNewPanel: IJobOrderEditorPanel;

    public WorkflowsDataSource: WorkflowsDataSource;
    public WorkflowsNavigatorMenu: ko.Observable<INavigationMenuComponent> = ko.observable();

    private datesMenuTemplateProvider: INavigationMenuComponentTemplatesProvider;
    public WorkDaysDataSource: WorkedHoursJobOrderDateDataSource;
    public WorkDaysNavigationMenu: ko.Observable<INavigationMenuComponent> = ko.observable();

    public get JobOrderId(): number {
        return this.m_jobOrderId;
    }

    public set JobOrderId(id: number) {
        this.m_jobOrderId = id;

        if (this.WorkDaysDataSource) this.WorkDaysDataSource.setJobOrderId(id);
    }

    private m_jobOrderId: number;

    public JobOrder: ko.Observable<JobOrderViewModel> = ko.observable();

    public IsJobOrderLocked: ko.Computed<boolean>;
    public IsJobOrderClosed: ko.Computed<boolean>;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IAuthorizationService>())
    private authService: IAuthorizationService;
    @LazyImport(nameof<IUserInfo>())
    private userService: IUserInfo;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IBlogService>())
    private blogService: IBlogService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStateSettingsManager: IJobOrderStateSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkflowOutcomes)
    private outcomeService: IWorkflowOutcomeSettingsManager;

    constructor(private serviceLocator: IServiceLocator, private detailsObserver: IJobOrderDetailsObserver) {
        this.datesMenuTemplateProvider = new DatesMenuTemplateProvider();

        this.WorkflowsDataSource = new WorkflowsDataSource();
        this.WorkflowsDataSource.setViewFilters(
            true,
            true,
            this.authService.isAuthorized("JobOrders_ViewAllJobOrders")
        );
        this.WorkflowsDataSource.enableNotifications(true);
        this.WorkflowsDataSource.setWorkFilters(true);

        this.WorkDaysDataSource = new WorkedHoursJobOrderDateDataSource();
        this.WorkDaysDataSource.setJobOrderId(this.JobOrderId);

        this.WorkflowsNavigatorMenu.subscribe((navigator) => {
            const showingSecondaryActions = ko.observable(false);

            const showLegendAction: INavigationMenuComponentAction = {
                isGroup: false,
                isSeparator: false,
                icon: "fa fa-info-circle",
                text: "",
                title: TextResources.ProlifeSdk.AlertsLegendTitle,
                activeClass: "",
                defaultClass: "btn-transparent",
                active: () => false,
                canExecute: () => true,
                action: () => {
                    const component = new AlertsLegendUI();
                    component.show();
                },
            };

            navigator.registerAction(showLegendAction);

            const action = {
                icon: "fa fa-plus",
                isGroup: true,
                isSeparator: false,
                actions: [
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-plus",
                        text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                        visible: () => true,
                        canExecute: () => this.authService.isAuthorized("TaskBoard_InsertWorkflow"),
                        action: () => {
                            this.CreateNewWorkflow();
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-copy",
                        text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                        visible: () => true,
                        canExecute: () => this.authService.isAuthorized("TaskBoard_CloneWorkflow"),
                        action: () => {
                            this.CreateNewWorkflowFromWorkflow();
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "fa fa-magic",
                        text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                        visible: () => true,
                        canExecute: () => this.authService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                        action: () => {
                            this.CreateNewWorkflowFromTemplate();
                        },
                    },
                ],
            };

            if (
                this.authService.isAuthorized("TaskBoard_InsertWorkflow") ||
                this.authService.isAuthorized("TaskBoard_CloneWorkflow") ||
                this.authService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
            )
                navigator.registerAction(action);

            if (this.authService.isAuthorized("TaskBoard_EditWorkflow")) {
                const startEditAction = {
                    icon: "fa fa-pencil",
                    isGroup: false,
                    isSeparator: false,
                    defaultClass: "btn-primary",
                    visible: () => !showingSecondaryActions(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(true);
                        this.WorkflowsNavigatorMenu().showSecondaryAction(true);
                    },
                };

                const endEditAction = {
                    icon: "fa fa-pencil",
                    isGroup: false,
                    isSeparator: false,
                    defaultClass: "btn-warning",
                    visible: () => showingSecondaryActions(),
                    canExecute: () => true,
                    action: () => {
                        showingSecondaryActions(false);
                        this.WorkflowsNavigatorMenu().showSecondaryAction(false);
                    },
                };

                navigator.registerAction(startEditAction);
                navigator.registerAction(endEditAction);
            }
        });

        this.WorkDaysNavigationMenu.subscribe(() => {
            this.navigateWorkDaysMenuToToday();
        });

        this.IsJobOrderLocked = ko.computed(() => {
            return this.JobOrder() ? !!this.JobOrder().jobOrder.FkBlockCause : false;
        });

        this.IsJobOrderClosed = ko.computed(() => {
            const jobOrder = this.JobOrder();
            return !jobOrder
                ? false
                : this.jobOrderStateSettingsManager.getJobOrderStateById(jobOrder.jobOrder.StateId)?.LogicalState == 1;
        });

        this.IsJobOrderClosed.subscribe((value: boolean) => {
            this.WorkflowsDataSource.setShowClosesJobOrdersOnWorkflowEditor(value);
        });

        const panels = this.jobOrderService
            .getRegisteredEditorPanelFactories()
            .filter((f: IJobOrderEditorPanelFactory) => f.hasRequiredModules() && f.hasAuthorization())
            .map((f: IJobOrderEditorPanelFactory) => f.createPanel(this.serviceLocator, this));
        this.Panels(panels);

        const defaultPanels = this.Panels().filter((f: IJobOrderEditorPanel) => f.isDefault());
        this.DefaultPanel = defaultPanels.length > 0 ? defaultPanels[0] : this.Panels()[0];

        const newDefaultPanels = this.Panels().filter((f: IJobOrderEditorPanel) => f.isDefaultOnNew());
        this.DefaultNewPanel = newDefaultPanels.length > 0 ? newDefaultPanels[0] : this.Panels()[0];
    }

    public navigateWorkDaysMenuToToday() {
        this.WorkDaysDataSource.selectDay(moment(new Date()).startOf("day").toDate());
    }
    public CreateNewWorkflow() {
        const notInitializedStates = this.outcomeService.getOutcomes().filter((o: IWorkflowOutcome) => {
            o.LogicalStatus = 0;
        });
        const notInitializedState = notInitializedStates.length > 0 ? notInitializedStates[0].Id : 1;

        const workflow: ITodoListWorkflow = {
            Title: "",
            Description: "",
            Color: "",
            JobOrderId: this.JobOrderId,
            Resources: [],
            Status: 0,
            OutcomeId: notInitializedState,
            ShownInWorkflowId: null,
            DefaultRoleId: -1,
            Links: [],
            ActivitiesProgressAmountMode: null,
            Multiplier: 1,
            MultiplierUoM: null,
            Code: "",
            ShowAlertOnUnestimatedTasks: false,
            WorkflowMustBePlanned: false,
            ActivitiesMustBeReviewed: false,
            WorkflowMustBeRelatedToCustomerOrders: false,
            WorkedHoursDefaultsStrictMode: false,
            HideAdministrativeFieldsOnWorkedHours: false,
        };

        const vm: WorkflowEditDialog = new WorkflowEditDialog({
            workflow: workflow,
            showClosedJobOrdersOnSelector: this.IsJobOrderClosed(),
        });
        vm.ShowDialog().then((result: IWorkflowEditorResult) => {
            if (!result) return;

            this.WorkflowsDataSource.refresh();
        });
    }

    public CreateNewWorkflowFromWorkflow() {
        if ((this.JobOrderId || 0) <= 0) return;

        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.JobOrderId, this.IsJobOrderClosed());
    }

    public CreateNewWorkflowFromTemplate() {
        this.todoListService
            .ShowCreateWorkflowFormTemplateDialog(this.JobOrderId, this.IsJobOrderClosed())
            .then((result) => {
                if (!result) return;

                this.WorkflowsDataSource.refresh();
            });
    }

    public async load(jobOrderId: number): Promise<void> {
        const isReload = this.JobOrderId == jobOrderId;
        this.JobOrderId = jobOrderId;

        try {
            const jobOrder = await this.jobOrderService.get(jobOrderId);
            await this.loaded(jobOrder);
        } catch {
            this.loadFailed();
        }

        if (!isReload) {
            this.selectPanel(this.DefaultPanel);
        }
    }

    public reload() {
        this.load(this.JobOrderId);
    }

    private async loaded(jobOrder: IJobOrder): Promise<void> {
        if (jobOrder) {
            this.JobOrder(new JobOrderViewModel(this.serviceLocator, this.detailsObserver, jobOrder));
        } else {
            this.JobOrder(null);
            await this.dialogsService.AlertAsync(
                TextResources.JobOrder.NotAuthorized,
                TextResources.JobOrder.NotAuthorizedLabel
            );
            location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL;
            this.desktopService.SystemHeader.setSidebarVisibility(true);
        }

        const activePanel = this.ActivePanel();
        if (activePanel) {
            await activePanel.load();
        }

        this.WorkflowsDataSource.setJobOrders(jobOrder.JobOrderId);
    }

    private loadFailed() {}

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        const currentPanel = this.ActivePanel();
        if (currentPanel) {
            currentPanel.onItemSelected(sender, model);
        }
    }

    async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        const currentPanel = this.ActivePanel();
        if (currentPanel && currentPanel.canSelectItem) return currentPanel.canSelectItem(sender, model);

        return true;
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        const currentPanel = this.ActivePanel();
        if (currentPanel) {
            currentPanel.onItemDeselected(sender, model);
        }
    }

    public dispose() {
        const oldPanel = this.ActivePanel();
        if (oldPanel) {
            oldPanel.dispose();
        }

        this.WorkflowsDataSource.enableNotifications(false);
    }

    public async selectPanel(panel: IJobOrderEditorPanel, skipPendingChangesCheck = false): Promise<void> {
        if (!panel.canShow()) return;

        const oldPanel = this.ActivePanel() as IJobOrderEditorPanel;
        if (oldPanel && !skipPendingChangesCheck) {
            const result = await oldPanel.beforeChangePanel();

            if (!result) return;

            oldPanel.dispose();
            this.WorkflowsDataSource.popState();
        }

        this.SwitchToNewPanel(panel);
    }

    SwitchToNewPanel(panel: IJobOrderEditorPanel) {
        if (panel) panel.beforeShowPanel();

        this.ActivePanel(panel);

        if (this.JobOrder()) {
            this.WorkflowsDataSource.pushState();
        }

        setTimeout(() => {
            if ((panel as any).Gantt) (panel as any).Gantt.ScrollToTodayMarker(10);
        }, 3000);
    }

    getDetailsObserver(): IJobOrderDetailsObserver {
        return this.detailsObserver;
    }

    createNew(skipPendingChangesCheck = false) {
        const responsible: IHumanResource = this.humanResourcesSettingsManager.getHumanResourceByUserId(
            this.userService.getIdUser()
        );
        const responsibleId = responsible != null ? responsible.Resource.Id : 0;

        const jobOrder: IJobOrder = {
            Name: null,
            Code: null,
            Description: null,
            CreationDate: new Date(),
            CreationUserId: this.userService.getIdUser(),
            ResponsableUserId: responsibleId,
            StartDate: null,
            EndDate: null,
            RequestEndDate: null,
            TypeId: 0, //jobOrderTypesSettingsManager.getJobOrderTypes()[0].Id,
            StateId: 0, //jobOrderStateSettingsManager.getJobOrderStates()[0].IdJobOrderStateId,
            CustomerId: 0,
            CustomRoles: false,
            CustomMaterialsRoles: false,
            RolesPrices: [],
            ProtocolsDefaultValues: [],
            ProtocolsDefaultMetadatas: [],
            ProtocolsSettings: [],
            CallRightTypesPrices: [],
            FkCommercialResponsible: null,
            FkAdministrativeResponsible: null,
            CommercialResponsibleName: null,
            AdministrativeResponsibleName: null,
            ResponsiblesAndAuthorizedResources: [],
            MailRecipientsForDocumentsSendTo: [],
            MailRecipientsForDocumentsSendCc: [],
            MailRecipientsForDocumentsSendBcc: [],
        };

        this.selectPanel(this.DefaultNewPanel, skipPendingChangesCheck);
        this.loaded(jobOrder);
        //HACK: chiamiamo la funzione di edit della commessa direttamente... trovare un modo più
        //elegante per chiamare il metodo
        (this.DefaultNewPanel as any).editJobOrder();
    }

    public async BackToSplash(skipPendingChangesCheck = false): Promise<void> {
        if (!skipPendingChangesCheck) {
            const oldPanel = this.ActivePanel() as IJobOrderEditorPanel;
            if (oldPanel) {
                const result = await oldPanel.beforeChangePanel(true);
                if (!result) return;
            }
        }

        this.DoBackToSplash();
    }

    private DoBackToSplash() {
        location.href = "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL;
    }

    public goToBlog() {
        this.blogService.openBlogInNewWindow(this.JobOrderId);
    }
}
