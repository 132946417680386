import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 02/08/2017
 * Time: 17:50
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { Campaign } from "./Campaign";
import { SamplesProvider } from "../samples/providers/SamplesProvider";
import { QuestionnairesProvider } from "../questionnaires/providers/QuestionnairesProvider";
import { ResourcesAndGroupsManager } from "./ResourcesAndGroupsManager";
import { ITodoListResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ICampaignService } from "../../../../ProlifeSdk/interfaces/survey/ICampaignService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { ICampaignType, ICampaignState, ICampaign } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ICampaignStateSettingManager } from "../../../interfaces/ICampaignStateSettingManager";
import { ICampaignTypeSettingManager } from "../../../interfaces/ICampaignTypeSettingManager";

export class CampaignViewModel extends Campaign {
    public CampaignTypes: ko.ObservableArray<ICampaignType> = ko.observableArray([]);
    public CampaignStates: ko.ObservableArray<ICampaignState> = ko.observableArray([]);
    public SelectedState: ko.Observable<number> = ko.observable();
    public SelectedType: ko.Observable<number> = ko.observable();

    public samplesProvider: SamplesProvider;
    public questionnairesProvider: QuestionnairesProvider;

    public SelectedQuestionnaire: ko.Observable<number> = ko.observable();
    public SelectedSample: ko.Observable<number> = ko.observable();

    private campaignService: ICampaignService;
    private infoToastService : IInfoToastService;

    private statesManager: ICampaignStateSettingManager;
    private typesManager: ICampaignTypeSettingManager;

    private subscriptions: ko.Subscription[] = [];

    public Resources: ResourcesAndGroupsManager;

    constructor(private serviceLocator: IServiceLocator, campaign: ICampaign, campaignResources: ITodoListResource[]) {
        super(campaign);
        this.campaignService = <ICampaignService> this.serviceLocator.findService(ProlifeSdk.CampaignServiceType);
        this.infoToastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);

        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <ICampaignStateSettingManager>settingsService.findSettingsManager(ProlifeSdk.CampaignState);
        this.typesManager = <ICampaignTypeSettingManager>settingsService.findSettingsManager(ProlifeSdk.CampaignType);

        this.samplesProvider = new SamplesProvider(this.serviceLocator);
        this.questionnairesProvider = new QuestionnairesProvider(this.serviceLocator);

        this.Resources = new ResourcesAndGroupsManager(this.serviceLocator);

        if (!campaignResources)
            campaignResources = [];

        this.Resources.LoadResources(campaignResources);

        var types: ICampaignType[] = this.typesManager.getCampaignTypes();
        this.CampaignTypes(types);
        if (!campaign.Id && types.length > 0) {
            this.TypeId(types[0].Id);
            this.TypeName(types[0].Name);
            this.Icon(types[0].Icon);
            this.Background(types[0].Background);
            this.Foreground(types[0].Foreground);
        }
        if (campaign.Id) {
            this.SelectedType(campaign.TypeId);
        }

        var states: ICampaignState[] = this.statesManager.getCampaignStates();
        this.CampaignStates(states);
        if (!campaign.Id && states.length > 0) {
            this.StateId(states[0].Id);
            this.StateDescription(states[0].Label);
        }
        if (campaign.Id) {
            this.SelectedState(campaign.StateId);
            this.SelectedQuestionnaire(campaign.QuestionnaireId);
            this.SelectedSample(campaign.SampleId);
        }

        this.subscriptions.push(this.SelectedType.subscribe((value: number) => {
            var type: ICampaignType[] = this.CampaignTypes().filter((t: ICampaignType) => {
                return t.Id == value;
            });
            this.TypeId(value);
            this.TypeName(type[0].Name);
            this.Icon(type[0].Icon);
            this.Background(type[0].Background);
            this.Foreground(type[0].Foreground);
        }));

        this.subscriptions.push(this.SelectedState.subscribe((value: number) => {
            var state: ICampaignState[] = this.CampaignStates().filter((s: ICampaignState) => {
                return s.Id == value;
            });
            this.StateId(value);
            this.StateDescription(state[0].Label);
        }));

        this.subscriptions.push(this.SelectedQuestionnaire.subscribe((value: number) => {
            this.QuestionnaireId(value);
        }));

        this.subscriptions.push(this.SelectedSample.subscribe((value: number) => {
            this.SampleId(value);
        }));
    }

    public dispose(): void {
        this.subscriptions.forEach((s: ko.Subscription) => {
            s.dispose();
        });
    }
}