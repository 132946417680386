import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/02/13
 * Time: 17.38
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
 
export class IsCalendar {
    constructor() {
    }
}

var monthNames = [ ProlifeSdk.TextResources.Desktop.January, ProlifeSdk.TextResources.Desktop.February, ProlifeSdk.TextResources.Desktop.March, ProlifeSdk.TextResources.Desktop.April, ProlifeSdk.TextResources.Desktop.May, ProlifeSdk.TextResources.Desktop.June, ProlifeSdk.TextResources.Desktop.July, ProlifeSdk.TextResources.Desktop.August, ProlifeSdk.TextResources.Desktop.September, ProlifeSdk.TextResources.Desktop.October, ProlifeSdk.TextResources.Desktop.November, ProlifeSdk.TextResources.Desktop.December ];
var dayNamesMin = [ ProlifeSdk.TextResources.Desktop.Sun, ProlifeSdk.TextResources.Desktop.Mon, ProlifeSdk.TextResources.Desktop.Tue, ProlifeSdk.TextResources.Desktop.Wed, ProlifeSdk.TextResources.Desktop.Thu, ProlifeSdk.TextResources.Desktop.Fri, ProlifeSdk.TextResources.Desktop.Sat ];
var dayNames = [ ProlifeSdk.TextResources.Desktop.Sunday, ProlifeSdk.TextResources.Desktop.Monday, ProlifeSdk.TextResources.Desktop.Tuesday, ProlifeSdk.TextResources.Desktop.Wednesday, ProlifeSdk.TextResources.Desktop.Thursday, ProlifeSdk.TextResources.Desktop.Friday, ProlifeSdk.TextResources.Desktop.Saturday ];
var nextMonthText = ProlifeSdk.TextResources.Desktop.NextMonth;
var prevMonthText = ProlifeSdk.TextResources.Desktop.PreviousMonth;

ko.bindingHandlers["isCalendar"] = {

    init: function(element, valueAccessor)
    {
        var options = valueAccessor()["options"] || { };
        var startDate = valueAccessor()["startDate"];

        /*options.monthNames = monthNames;
        options.dayNamesMin = dayNamesMin;
        options.dayNames = dayNames;
        options.nextText = nextMonthText;
        options.prevText = prevMonthText;*/

        if(startDate)
            options.defaultDate = startDate;

        (<any>$(element)).datepicker(options).datepicker( "option", "showButtonPanel", true).datepicker( "option", "currentText", "Now" );
    }
}