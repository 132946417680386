import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IJobOrderType } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IJobOrderTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IListItem, List } from "../../../../Components/ListComponent";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { TemplatesDataSource } from "../../../../DataSources/TemplatesDataSource";
import { JobOrderType, _JobOrderType } from "./JobOrderType";

const styleSheet = jss.createStyleSheet({
    jobOrderTypesEditor: {
        height: "100%",

        "& .header": {
            "& h3": {
                marginBottom: "25px",

                "& .alert": {
                    position: "absolute",
                    left: "60%",
                    marginLeft: "-25%",
                    textAlign: "center",
                    top: "10px",
                    width: "500px",
                    color: "#a94442"
                }
            }
        },

        "& .actions": {
            "& .btn": {
                "&:not(:last-child)": {
                    marginRight: "5px"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type JobOrderTypesEditingViewModelProps = {
    forwardRef?: (editor: JobOrderTypesEditingViewModel) => void;
}

export class JobOrderTypesEditingViewModelUI implements IView {
    templateName: string;
    templateUrl: string;
    viewModel: JobOrderTypesEditingViewModel;

    constructor() {

    }
    
    render() {
        let Editor = require("./JobOrderTypesEditingViewModel").JobOrderTypesEditingViewModel as typeof JobOrderTypesEditingViewModel;
        return <Editor forwardRef={(e) => this.viewModel = e} />;
    }
}

export class JobOrderTypesEditingViewModel
{
	title : string;
	elements : ko.ObservableArray<IJobOrderType> = ko.observableArray();
	newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
	newSettingName : ko.Observable<string> = ko.observable();

    private jobOrderTypes: _JobOrderType[] = [];
    
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesManager : IJobOrderTypesSettingsManager;

    public TemplatesDataSource : TemplatesDataSource = new TemplatesDataSource();
    private allowedMimeTypes: string[] = ["application/job-order-type"];
    
	constructor(private props: JobOrderTypesEditingViewModelProps)
    {
		this.title = this.jobOrderTypesManager.getLabel();
		this.elements(this.jobOrderTypesManager.getJobOrderTypes() ?? []);
	}

    public expandAll(): void {
        for (const jobOrderType of this.jobOrderTypes)
            jobOrderType.Collapsed(false);
    }

    public collapseAll(): void {
        for (const jobOrderType of this.jobOrderTypes)
            jobOrderType.Collapsed(true);
    }

	public addNewSetting()
    {
		let newType = {
            Id: null,
			Description: this.newSettingName(),
            DefaultTemplates: [],
            DefaultResponsiblesAndAuthorizedResources: [],
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF",
            Index: (this.elements().length + 1) || 1,
            HideFromLists: false,
            TypeCode: ""
		};

		this.elements.push(newType);
		this.newSettingName("");
	}

	public setIsSelectedNewSetting()
    {
		this.newSettingHasFocus(true);
	}

    public OnBeginDrag(model: IDataSourceModel, dataTransfer: DataTransfer): void {
        let dragged = {
            id: model.id
        };

        dataTransfer.setData("text/plain", model.title);
        dataTransfer.setData(this.allowedMimeTypes.firstOrDefault(), JSON.stringify(dragged));
        dataTransfer.dropEffect = "move";
    }

    public OnDrop(dataTransfer: DataTransfer, model: IDataSourceModel<number, IJobOrderType>, before: boolean) : void {
        this.handleDrop(dataTransfer, model, before);
    }

    private async handleDrop(dataTransfer: DataTransfer, neighbour: IDataSourceModel<number, IJobOrderType>, before: boolean): Promise<void> {
        const movedItem = JSON.parse(dataTransfer.getData(this.allowedMimeTypes.firstOrDefault()));

        try {
            const movedItemId = movedItem.id;
            const neighbourId = neighbour.id;

            await this.jobOrderTypesManager.changeOrder(movedItemId, neighbourId, before);
            
            this.reloadElements();
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.JobOrder.UnableToMoveType);
        }
    }

    private async reloadElements(): Promise<void> {
        try {
            this.jobOrderTypes = [];
            await this.jobOrderTypesManager.load();
            this.elements(this.jobOrderTypesManager.getJobOrderTypes());
        } catch(e) {

        }
    }

    private renderListItem(item: IListItem<IJobOrderType>) {
        return <JobOrderType
                    jobOrderType={item.Model}
                    templatesDataSource={this.TemplatesDataSource}
                    onTypeDeleted={this.onJobOrderTypeDeleted.bind(this)}

                    forwardRef={(jobOrderType) => this.jobOrderTypes.push(jobOrderType)}
                />;
    }

    private onJobOrderTypeDeleted(jobOrderType: IJobOrderType) {
        this.reloadElements();
    }

    render() {
        const editor = this;

        return ComponentUtils.bindTo((
            <Layout.Grid rows={["min-content", "1fr", "min-content"]} columns={["1fr"]} className={classes.jobOrderTypesEditor}>
                <Layout.Grid.Cell row={1} column={1} className="header">
                    <h3 className="page-title">
                        <span>{this.title}</span>
                        <small className="alert alert-danger">{TextResources.ProlifeSdk.SettingManagerReloadAlert}</small>
                    </h3>
                </Layout.Grid.Cell>
                
                <Layout.Grid.Cell row={2} column={1} noOverflow className="flex-vertical">
                    <div className="row">
                        <div className="col-md-4">
                            <span className="bold" style="font-size: 12pt">{TextResources.JobOrder.Label}</span>
                        </div>
                        <div className="col-md-8 text-right actions">
                            <button type="button" className="btn btn-primary" data-bind={{ click: editor.expandAll.bind(editor) }}>
                                {TextResources.JobOrder.ExpandAll}
                            </button>
                            <button type="button" className="btn btn-primary" data-bind={{ click: editor.collapseAll.bind(editor) }}>
                            {TextResources.JobOrder.CollapseAll}
                            </button>
                        </div>
                    </div>
                    <List
                        dataSource={Table.defaultDataSource(this.elements, (element) => ({ id: element.Id, title: element.Description, dragEnabled: true }))}
                        containerHeight="flex"
                        itemRenderer={this.renderListItem.bind(this)}
                        allowedMimeTypes={this.allowedMimeTypes}
                        onBeginDrag={this.OnBeginDrag.bind(this)}
                        onDrop={this.OnDrop.bind(this)}
                        sortable
                        scrollable={false}
                        systemScrollable
                        />
                </Layout.Grid.Cell>
                
                <Layout.Grid.Cell row={3} column={1} className="flex-vertical">
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-12">
                            <form action="#" data-bind={{ submit: editor.addNewSetting.bind(editor) }}>
                                <input type="text" className="form-control" placeholder={TextResources.ProlifeSdk.New} data-bind={{ value: editor.newSettingName, valueUpdate: 'afterkeydown', submitOnEnterOrTab: true }} />
                            </form>
                        </div>
                    </div>
                </Layout.Grid.Cell>
            </Layout.Grid>
        ), this, "editor");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(JobOrderTypesEditingViewModel);
}