import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import "./TypedResourceManager";

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "resource-manager": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IResourceManagerParams {

}

export class ResourceManager {
    constructor(params : IResourceManagerParams) {
        
    }
}



ko.components.register("resource-manager", {
    viewModel: {
        createViewModel: (params: IResourceManagerParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new ResourceManager(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <ul class="nav nav-tabs">
                        <li class="active">
                            <a href="#humanTab" data-toggle="tab">
                                <i class="fa fa-user"></i>
                                {TextResources.ResourcesManager.HumanResources}
                            </a>
                        </li>
                        <li>
                            <a href="#materialsTab" data-toggle="tab">
                                <i class="fa fa-cubes"></i>
                                {TextResources.ResourcesManager.MaterialResources}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="humanTab">
                            <typed-resource-manager resourceType={0}></typed-resource-manager>
                        </div>
                        <div class="tab-pane" id="materialsTab">
                            <typed-resource-manager resourceType={1}></typed-resource-manager>
                        </div>
                    </div>
                </>
            ]);
            
            return vm;
        },
    },
    template: []
});