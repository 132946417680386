import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 06/07/2017
 * Time: 16:56
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";

export class LabelsListContainer {
    init(element: any, valueAccessor: () => any): void {
        var labelField = valueAccessor()["labelField"];
        var sourceData = valueAccessor()["sourceData"];
        $(element).data("labelField", labelField());

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 0,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source: sourceData,
            display: (json) => {
                var item = JSON.parse(json);
                return item.Label;
            },
            limit: 1000,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div style=\"border-bottom: 1px solid #DDDDDD\"><span ";
                    if (!item.Enabled)
                        element += "style=\"color: #ddd\"><i class=\"fa fa-eye-slash\" title=\"" + ProlifeSdk.TextResources.Survey.DisabledQuestionOrSection + "\"></i>&nbsp;";
                    if (item.Enabled)
                        element += "><i class=\"fa fa-eye\" title=\"" + ProlifeSdk.TextResources.Survey.EnabledQuestionOrSection + "\"></i>&nbsp;";
                    element += item.FullName + "</span></div>";
                    return element;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            var item = JSON.parse(json);
            labelField(item.Label);
            $(element).data("labelField", item.Label);
            return item.Label;
        });
    }
}

ko.bindingHandlers["labelsListContainer"] = new LabelsListContainer();