import * as ko from "knockout";
import { JobOrderMetadataDataSource } from "../../../../DataSources/JobOrderMetadataDataSource";
import { JobOrderTypesDataSource } from "../../../../DataSources/JobOrderTypesDataSource";
import { DetectChanges, DetectClassChanges } from "../../../../Core/ChangeDetection";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IJobOrderMetadata, IJobOrderMetadataSettingsManager } from "../JobOrderMetadataSettingsManager";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ITreeComponent } from "../../../../Components/TreeComponent";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";

@DetectClassChanges
export class JobOrderMetadata {
    isChanged : ko.Observable<number> = ko.observable();

    Id: number;

    @DetectChanges
    Name : ko.Observable<string> = ko.observable();
    
    @DetectChanges
    JobOrderType : ko.Observable<number> = ko.observable();

    @DetectChanges
    Code : ko.Observable<string> = ko.observable();

    IsRoot : boolean = false;
    IsNew : ko.Observable<boolean> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager! : IJobOrderMetadataSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService! : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    JobOrderTypesDataSource : JobOrderTypesDataSource = new JobOrderTypesDataSource();

    constructor(private model : IJobOrderMetadata, private editor : JobOrderMetadataEditor) {
        this.load();
    }

    private load() {
        this.Id = this.model.Id;
        this.IsNew(!this.Id);

        this.Name(this.model.Name);
        this.Code(this.model.Code);
        this.JobOrderType(this.model.EntityKeyId);
        this.IsRoot = !this.model.ParentId;
        if(!this.IsNew())
            this.isChanged(0);
    }

    dispose() {}

    public async Save() : Promise<void> {
        if (String.isNullOrUndefinedOrWhiteSpace(this.Name())) {
            this.infoToastService.Warning("Inserire una descrizione prima di salvare");
            return;
        }

        if (String.isNullOrUndefinedOrWhiteSpace(this.Code())) {
            this.infoToastService.Warning("Inserire un codice prima di salvare");
            return;
        }

        const model = Object.assign({}, this.model, {
            Name: this.Name(),
            Code: this.Code(),
            EntityKeyId: this.JobOrderType(),
            EntityKeyType: "JOT"
        });

        this.model = await this.metadataSettingsManager.createOrUpdate(model);
        this.editor.onMetadataSaved(this.model);
        this.load();
    }

    public async Cancel() {
        if(!await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse. Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche"))
            return;

        if(!this.Id) {
            this.editor.SelectedMetadata(undefined);
            return;
        }

        this.load();
    }
}

export class JobOrderMetadataEditor implements IDataSourceListener {
    MetadataDataSource : JobOrderMetadataDataSource;
    SelectedMetadata : ko.Observable<JobOrderMetadata> = ko.observable();
    MetadataTree : ko.Observable<ITreeComponent> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager! : IJobOrderMetadataSettingsManager;

    constructor() {
        this.MetadataDataSource = new JobOrderMetadataDataSource();

        this.MetadataTree.subscribe((value : ITreeComponent) => {

        });
    }

    async canSelectItem(sender : IDataSource, model : IDataSourceModel): Promise<boolean> {
        if(!this.SelectedMetadata() || this.SelectedMetadata().isChanged() == 0)
            return true;
        
        return await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse. Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<number, IJobOrderMetadata>): void {
        if(!model)
            this.SelectedMetadata(undefined);
        else
            this.SelectedMetadata(new JobOrderMetadata(model.model, this));
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    onMetadataSaved(model: IJobOrderMetadata) {
        this.MetadataDataSource.refresh();
        this.MetadataDataSource.select(model);
    }

    public async DeleteSelected() {
        if(!this.SelectedMetadata()) return;

        if(!await this.dialogsService.ConfirmAsync("Sei sicuro di voler eliminare il metadato e tutti i suoi figli?", "Annulla", "Elimina"))
            return;

        await this.metadataSettingsManager.deleteMetadata(this.SelectedMetadata().Id);

        this.MetadataDataSource.refresh();
    }

    public async NewMetadata() : Promise<void> {
        const parentId = this.SelectedMetadata() ? this.SelectedMetadata().Id : null;
        this.SelectedMetadata(new JobOrderMetadata({
            Id: 0,
            ParentId: parentId,
            Name: "Nuovo Metadato",
            Code: "",
            EntityType: this.SelectedMetadata() ? "JOT" : null,
            EntityKeyId: this.SelectedMetadata() ? this.SelectedMetadata().JobOrderType() : null
        }, this));
    }
}