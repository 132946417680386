import * as ko from "knockout";
import { WordFound } from "../objects/WordFound";
import { IWordFound } from "../../interfaces/IWordFound";
import { IBaseFilter } from "../../interfaces/IBaseFilter";

export class BaseFilter implements IBaseFilter {
    propertyName: string;
    filterLabel: string;
    funcToRetrievePropertyValue: Function;

    constructor(propertyName, filterLabel) {
        this.propertyName = propertyName;
        this.filterLabel = filterLabel;
        this.funcToRetrievePropertyValue = null;
    }

    GetValue(obj) : string[] {
        return [];
    }

    Filter(obj, filterText) : boolean {
        var propertyValues = this.GetValue(obj);

        if(propertyValues == null || propertyValues.length == 0)
            return false;

        for(var i = 0; i < propertyValues.length; i++) {
            var propertyValue = propertyValues[i] ? propertyValues[i].toString().toLowerCase() : "";
            propertyValue = " " + propertyValue;
            var matched = propertyValue.indexOf(' ' + filterText.toLowerCase()) != -1;
            if(matched)
                return true;
        }

        return false;
    }

    FindWordThatMatch(obj : any, textFilter : string) : IWordFound[] {
        var value = this.GetValue(obj);

        if(value == null || value.length == 0)
            return [];

        var matchedWords = <IWordFound[]> value.map(this.findWord.bind(this, textFilter));
        return matchedWords.filter((item) => item != null);
    }

    private findWord(textFilter: string, item : any) : IWordFound {
        var formattedValue = " " + item.toLowerCase() + " ";
        var textToFind = " " + textFilter.toLowerCase();

        var startIndex = formattedValue.indexOf(textToFind);

        if(startIndex < 0)
            return null;

        var indexOfSpace = startIndex + textToFind.length + formattedValue.substring(startIndex + textToFind.length, formattedValue.length).indexOf(" ");
        var lastIndex = (indexOfSpace == -1) ? formattedValue.length : indexOfSpace;
        var w = new WordFound();
        w.word = $.trim(formattedValue.substring(startIndex, lastIndex));
        return w;
    }
}
