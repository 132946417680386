import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow, useEffect } from "../../Core/utils/ComponentUtils";
import type * as monaco from "monaco-editor";
import { Delay } from "../../Decorators/Delay";
import { If } from "../IfIfNotWith";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type CodeEditorProps = {
    typeDefinitions: string[];
    code: ko.Observable<string>;
    onCodeChanged: (newCode: string) => void;
    onCompiledCodeChanged: (newCompiledCode: string) => void;
    onError?: (errors) => void;
}

export function CodeEditor(props: CodeEditorProps) {
    const C = require("./CodeEditor")._CodeEditor as typeof _CodeEditor;
    return <C {...props} />;
}

export class _CodeEditor extends React.Component<CodeEditorProps> {
    static defaultProps: Partial<CodeEditorProps> = {
    }

    private element: HTMLDivElement;
    editor: monaco.editor.IStandaloneCodeEditor;
    currentModel : monaco.editor.ITextModel;
    
    componentDidMount() {
        const monaco = require("monaco-editor");
        
        monaco.languages.typescript.typescriptDefaults.setExtraLibs(this.props.typeDefinitions.map(d => ({ content: d })))

        let options = monaco.languages.typescript.typescriptDefaults.getCompilerOptions();
        options.lib = ["es5"];
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions(options);

        this.editor = monaco.editor.create(this.element, {
            model: null,
            automaticLayout: true,
            theme: 'vs-dark',
            minimap: {
                enabled: false
            },
            quickSuggestions: {
                comments: true,
                strings: true,
                other: true
            },
        });

        useEffect(async () => {
            if(!this.editor)
                return;
                
            const newCode = ko.unwrap(this.props.code);
            const model = this.editor.getModel();
            if(newCode == model?.getValue())
                return;

            this.currentModel = monaco.editor.createModel(ko.unwrap(this.props.code), "typescript");
            this.editor.setModel(this.currentModel);
            if(model)
                model.dispose();

            if(!this.currentModel)
                return;

            const getWorker = await monaco.languages.typescript.getTypeScriptWorker()
            const worker = await getWorker(this.currentModel.uri);

            if(!this.currentModel) //Il componente è stato smontato nel frattempo
                return;
            this.currentModel.onDidChangeContent(() => {
                this.notifyCodeChanged(this.currentModel.uri.toString(), worker);
                this.notifyCompiledCodeChanged(this.currentModel.uri.toString(), worker);
            })

            this.notifyCodeChanged(this.currentModel.uri.toString(), worker);
            this.notifyCompiledCodeChanged(this.currentModel.uri.toString(), worker);
        }, [this.props.code])
    }

    @Delay(1000)
    async notifyCodeChanged(fileName: string, worker: monaco.languages.typescript.TypeScriptWorker) {
        if(!this.currentModel) return;
        const newCode = this.currentModel.getValue();
        this.props.onCodeChanged(newCode)
    }

    @Delay(1000)
    async notifyCompiledCodeChanged(fileName: string, worker: monaco.languages.typescript.TypeScriptWorker) {
        if(!this.currentModel) return;
        
        const errors = (await worker.getSyntacticDiagnostics(fileName)).filter(e => e.category === 1);
        const semanticErrors = (await worker.getSemanticDiagnostics(fileName)).filter(e => e.category === 1);

        if(this.props.onError) {
            const allErrors = errors.map(e => e.messageText).concat(semanticErrors.map(e => e.messageText));
            this.props.onError(allErrors);
        }

        if(errors.length === 0 && semanticErrors.length === 0) {
            const emitOutput = await worker.getEmitOutput(fileName);
            let compiledCode = emitOutput.outputFiles[0].text;
            this.props.onCompiledCodeChanged(compiledCode)
        }
    }

    componentWillUnmount() {
        if(this.currentModel) {
            this.editor.setModel(null);
            this.currentModel.dispose()
            this.currentModel = null;
        }
        if(this.editor) {
            this.editor.dispose();
            this.editor = null;
        }
    }
    
    render() {
        return  <div className="flex-1" style={{ height: '100%' }} ref={(d) => this.element = d}></div>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CodeEditor);
}