import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, useEffect } from "../../../Core/utils/ComponentUtils";
import { Portlet } from "../../../Components/Portlet";
import { List } from "../../../Components/ListComponent";
import { IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IQuotationBillOfMaterials } from "../../QuotationService";

type QuotationBillOfMaterialsListProps = {
    selectedBillOfMaterials?: ko.Observable<IQuotationBillOfMaterials>;
    billsOfMaterials: ko.ObservableArray<IQuotationBillOfMaterials>;
    onNew: () => void;
    onBillOfMaterialsSelected: (b: IQuotationBillOfMaterials) => void;
};

export function QuotationBillOfMaterialsList(props: QuotationBillOfMaterialsListProps) {
    const C = require("./QuotationBillOfMaterialsList")
        ._QuotationBillOfMaterialsList as typeof _QuotationBillOfMaterialsList;
    return <C {...props} />;
}

export class _QuotationBillOfMaterialsList extends React.Component<QuotationBillOfMaterialsListProps> {
    static defaultProps: Partial<QuotationBillOfMaterialsListProps> = {};

    private list: ko.Observable<List<number, IQuotationBillOfMaterials>> = ko.observable();
    private ignoreNextItemSelected = false;

    constructor(props: QuotationBillOfMaterialsListProps) {
        super(props);

        if (!props.selectedBillOfMaterials) props.selectedBillOfMaterials = ko.observable();

        useEffect(() => {
            if (!this.list()) return;

            this.ignoreNextItemSelected = true;
            const list = this.list();
            list.select(props.selectedBillOfMaterials());
        }, [props.selectedBillOfMaterials, this.list]);
    }

    onItemSelected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {
        if (this.ignoreNextItemSelected) {
            this.ignoreNextItemSelected = false;
            return;
        }
        this.props.onBillOfMaterialsSelected(model?.model);
    }

    onItemDeselected(
        sender: IDataSource<string | number, any>,
        model: IDataSourceModel<string | number, any, string | number, any>
    ): void {}

    private factory(b: IQuotationBillOfMaterials) {
        return {
            id: b.Id,
            title: b.Name,
            isLeaf: true,
            isGroup: false,
            model: b,
        };
    }

    render() {
        return (
            <Portlet collapsible={false}>
                <Portlet.Header>
                    <Portlet.Header.Default title="Distinte" className="font-red-sunglo bold uppercase" />
                </Portlet.Header>
                <Portlet.Actions>
                    <button className="btn btn-circle btn-icon-only btn-default" onClick={this.props.onNew}>
                        <i className="fa fa-plus"></i>
                    </button>
                </Portlet.Actions>
                <Portlet.Body>
                    {() => (
                        <List
                            ref={(l) => this.list(l)}
                            listener={this}
                            dataSource={{ array: this.props.billsOfMaterials, factory: this.factory }}
                            containerHeight="flex"
                            emptyResultMessage="Nessuna Distinta"
                        />
                    )}
                </Portlet.Body>
            </Portlet>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(QuotationBillOfMaterialsList);
}
