import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { reloadNow, ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { Document } from "./Document";
import { IDocumentBuilderDocumentResources } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { ResourcesDataSource, IResourceDataSourceModel } from "../../../DataSources/ResourcesDataSource";
import { IDocumentsService } from "../../DocumentsService";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { IDataSourceModel, IDataSourceListener } from "../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Select2 } from "../../../Components/Select2Component";
import { NumberInput } from "../../../Components/NumberInput";
import { DateTimeInput } from "../../../Components/DateTimeInput";
import { IfNot, If } from "../../../Components/IfIfNotWith";
import { IValidator, IValidationService } from "../../../ProlifeSdk/ValidationService";
import { TextInput } from "../../../Components/TextInput";
import { SecondaryRow } from "../../../Components/TableComponent/SecondaryRow";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { DropdownList } from "../../../Components/DropdownList";
import { DocumentResourceHoursCalculation } from "../enums/DocumentResourceHoursCalculation";
import moment = require("moment");
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import {
    IHumanResource,
    IHumanResourceOrders,
    IHumanResourceOrdersWorkingHours,
} from "../../../Users/HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { PercentageInput } from "../../../Components/PercentageInputComponent";
import { TableFooterAggregationMode } from "../../../Components/TableComponent/TableFooterAggregationMode";
import { NumericText } from "../../../Components/NumericText";
import {
    DocumentResourcesSettings,
    IDocumentResourcesSettingsManager,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IDocumentResourcesSettingsManager";
import { DataSourceType, DocumentFieldsReader } from "./DocumentFieldsReader";
import { DocumentResourceAssignmentsDetailsViewer } from "./DocumentResourceAssignemntsDetails";

const styleSheet = jss.createStyleSheet({
    resources: {
        "& .table-wrapper": {
            "& .flex-container": {
                overflow: "unset !important",
            },
        },
        "& table": {
            "& thead": {
                "& th": {
                    "&.hours-amount-header": {
                        paddingRight: "20px",
                    },
                },
            },
        },
        "& .hours-amount": {
            width: "100px",
            maxWidth: "100px",

            "&.input-group": {
                tableLayout: "fixed",

                "& .form-control": {
                    display: "table-cell !important",
                },

                "& .input-group-btn": {
                    width: "21px",

                    "& .btn": {
                        marginRight: "-6px",
                    },
                },
            },
        },
    },
    calculator: {
        width: "320px",
        maxWidth: "320px",
    },
});

const { classes } = styleSheet.attach();

type DocumentResourcesProps = {
    resources: ko.ObservableArray<DocumentResource>;
    documentFieldsReader: DocumentFieldsReader;
    readonly: ko.Observable<boolean>;
    className?: string;
};

export class DocumentResource implements IDataSourceListener {
    get Id(): number {
        return this.resource.Id;
    }

    get Name(): string {
        return this.selectedResource?.Resource.Name;
    }

    ResourceId: ko.Observable<number> = ko.observable();
    HoursAmount: ko.Observable<number> = ko.observable();
    StartDate: ko.Observable<Date> = ko.observable();
    EndDate: ko.Observable<Date> = ko.observable();
    Notes: ko.Observable<string> = ko.observable();
    Ratio: ko.Computed<number>;

    ShowNotes: ko.Observable<boolean> = ko.observable(false);
    ShowAlert: ko.Observable<boolean> = ko.observable(false);

    ResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();

    private selectedResource: IHumanResource;
    private validator: IValidator<DocumentResource>;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    private detailsPopOver: DocumentResourceAssignmentsDetailsViewer = null;

    constructor(
        private resource: IDocumentBuilderDocumentResources,
        private allResources: ko.ObservableArray<DocumentResource>,
        private documentFieldsReader: DocumentFieldsReader
    ) {
        this.ResourcesDataSource.setGetHumanResources(true);
        this.ResourcesDataSource.setGetMaterialResources(false);
        this.ResourcesDataSource.setIncludeDeletedResources(false);
        this.ResourcesDataSource.setIncludeDisabledResources(false);

        this.ResourceId(this.resource.FKResource);
        this.HoursAmount(this.resource.HoursAmount);
        this.StartDate(this.resource.StartDate);
        this.EndDate(this.resource.EndDate);
        this.Notes(this.resource.Notes);

        this.validator = this.validationService.createValidator<DocumentResource>();
        this.validator
            .isNotNullOrUndefined((r) => r.ResourceId(), TextResources.Invoices.MissingResource)
            .isNotNullOrUndefined((r) => r.StartDate(), TextResources.Invoices.MissingResourceStartDate)
            .isNotNullOrUndefined((r) => r.EndDate(), TextResources.Invoices.MissingResourceEndDate);

        this.selectedResource = this.humanResourcesSettingsManager.getHumanResourceById(this.resource.FKResource);

        this.HoursAmount.subscribe((amount: number) => {
            if (!amount || !this.StartDate() || !this.EndDate()) return;

            const totalWorkingHours = this.getTotalWorkingHoursIntoPeriod();
            this.ShowAlert(amount > totalWorkingHours);

            if (amount > totalWorkingHours)
                this.dialogsService.AlertAsync(
                    TextResources.Invoices.DocumentResourceHoursAlert,
                    TextResources.Invoices.DocumentResourceHoursAlertTitle
                );
        });

        this.ShowNotes.subscribe(() => {
            this.allResources().forEach((r) => r.closeAssignmentsDetails());
        });

        this.Ratio = ko.computed(() => {
            return this.HoursAmount() / this.getResourcesTotalHours();
        });
    }

    onItemSelected(sender: ResourcesDataSource, model: IResourceDataSourceModel): void {
        this.selectedResource = model?.model;
    }

    onItemDeselected(sender: ResourcesDataSource, model: IResourceDataSourceModel): void {
        this.selectedResource = null;
    }

    async showAssignmentsDetails(target: HTMLElement): Promise<void> {
        if (this.detailsPopOver || !this.ResourceId() || !this.StartDate() || !this.EndDate()) return;

        this.detailsPopOver = new DocumentResourceAssignmentsDetailsViewer({
            resource: this,
            alreadyAssignedAmount: this.getResourcesTotalHours() - this.HoursAmount(),
            documentFeldsReader: this.documentFieldsReader,
        });

        this.detailsPopOver.show(target as HTMLElement);
    }

    closeAssignmentsDetails(): void {
        this.detailsPopOver?.modal.close();
        this.detailsPopOver = null;
    }

    async openHoursAmountCalculator(model: IDataSourceModel<number, DocumentResource>, event: Event): Promise<void> {
        const target = event.currentTarget as HTMLElement;
        return this.dialogsService.ShowPopoverComponent(
            target,
            new Calculator({ resource: this }),
            "bottom",
            null,
            classes.calculator
        );
    }

    validateAndShowInfoToast() {
        let result = this.validator.validateAndShowInfoToast(this);
        if (!result) return result;

        if (moment(this.StartDate()).isAfter(moment(this.EndDate()))) {
            result = false;
            this.infoToastService.Error(TextResources.Invoices.InvalidResourceDates);
        }

        return result;
    }

    getResourcesTotalHours(): number {
        return this.allResources().sum((r) => r.HoursAmount());
    }

    getServiceOrderWorkingHours(serviceOrderId: number) {
        if (!this.selectedResource) return [];

        return this.selectedResource.OrdersWorkingHours.filter((w) => w.ServiceOrderId === serviceOrderId);
    }

    getResourceServiceOrdersIntoPeriod(): IHumanResourceOrders[] {
        if (!this.selectedResource || !this.StartDate() || !this.EndDate()) return [];

        const startDate = moment(this.StartDate());
        const endDate = moment(this.EndDate());

        const ordersIntoPeriod = this.selectedResource.Orders.filter((o) => {
            const oStart = moment(o.FromDate);
            const oEnd = moment(o.ToDate ?? "2100-01-01T00:00:00+01:00");

            return (
                oStart.isBetween(startDate, endDate, undefined, "[)") ||
                oEnd.isBetween(startDate, endDate, undefined, "[)") ||
                startDate.isBetween(oStart, oEnd, undefined, "[)") ||
                startDate.isBetween(oStart, oEnd, undefined, "[)")
            );
        });

        ordersIntoPeriod.sort((o1, o2) => o1.FromDate.valueOf() - o2.FromDate.valueOf());
        return ordersIntoPeriod;
    }

    getTotalWorkingHoursIntoPeriod() {
        const orders = this.getResourceServiceOrdersIntoPeriod();
        let hours = 0;

        for (const order of orders) {
            const startDate = order.FromDate.valueOf() > this.StartDate().valueOf() ? order.FromDate : this.StartDate();
            const endDate =
                order.ToDate && order.ToDate.valueOf() <= this.EndDate().valueOf() ? order.ToDate : this.EndDate();

            hours += this.getHoursIntoPeriod(order, startDate, endDate);
        }

        return hours;
    }

    getHoursIntoPeriod(
        order: IHumanResourceOrders,
        startDate: Date,
        endDate: Date,
        requestedWeekHours: number = null
    ): number {
        const days = moment(endDate).startOf("day").diff(moment(startDate).startOf("day"), "days");
        const workingHours: IHumanResourceOrdersWorkingHours[] = this.getServiceOrderWorkingHours(order.Id);

        let fullWeeks = Math.floor(days / 7) - 1;
        fullWeeks = fullWeeks >= 0 ? fullWeeks : 0;

        let remainingDays = days - fullWeeks * 7;
        const weekHours =
            order.HoursMonday +
            order.HoursTuesday +
            order.HoursWednesday +
            order.HoursThursday +
            order.HoursFriday +
            order.HoursSaturday +
            order.HoursSunday;
        const ratio = requestedWeekHours ? requestedWeekHours / weekHours : 1;

        let partial = fullWeeks * (requestedWeekHours ?? weekHours);

        const startDay = moment(startDate).day();
        const firstPartialWeekDays = 7 - startDay + 1;
        for (let i = 0; i < firstPartialWeekDays; i++) {
            const weekDay = i + startDay === 7 ? 0 : i + startDay;
            const dayHours = workingHours.filter((h) => h.DayOfWeek === weekDay).sum((w) => w.Duration);
            partial += dayHours * ratio ?? 0;
            remainingDays--;
        }

        const endDay = moment(endDate).day();
        for (let i = 0; i < remainingDays; i++) {
            const weekDay = endDay - i === 7 ? 0 : endDay - i;
            const dayHours = workingHours.filter((h) => h.DayOfWeek === weekDay).sum((w) => w.Duration);
            partial += dayHours * ratio ?? 0;
        }

        return partial;
    }

    getData(): IDocumentBuilderDocumentResources {
        const data = Object.assign({}, this.resource) as IDocumentBuilderDocumentResources;

        data.Id = data.Id ?? this.documentsService.getFakeId();
        data.FKResource = this.ResourceId();
        data.HoursAmount = this.HoursAmount();
        data.StartDate = this.StartDate();
        data.EndDate = this.EndDate();
        data.Notes = this.Notes();

        return data;
    }
}

type CalculationMode = { id: DocumentResourceHoursCalculation; label: string };
type CalculationMethodsDictionary = { [mode: string]: () => number };

class Calculator extends DialogComponentBase {
    Value: ko.Observable<number> = ko.observable(0);
    CalculationMode: ko.Observable<DocumentResourceHoursCalculation> = ko.observable(
        DocumentResourceHoursCalculation.WeeklyHours
    );

    CalculationModes: CalculationMode[] = [];

    private calculationMethods: CalculationMethodsDictionary = {};

    constructor(private props: { resource: DocumentResource }) {
        super({ popover: true });

        this.title(TextResources.Invoices.DocumentResourcesHoursCalculator);
        this.populateCalculationModes();
    }

    renderBody() {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <IfNot
                            condition={() =>
                                this.CalculationMode() ===
                                DocumentResourceHoursCalculation.PercentageOnResourceWorkingHours
                            }>
                            {() => (
                                <NumberInput
                                    value={this.Value}
                                    label={TextResources.Invoices.ResourceHoursCalculationValue}
                                    selectOnFocus
                                />
                            )}
                        </IfNot>
                        <If
                            condition={() =>
                                this.CalculationMode() ===
                                DocumentResourceHoursCalculation.PercentageOnResourceWorkingHours
                            }>
                            {() => (
                                <PercentageInput
                                    value={this.Value}
                                    label={TextResources.Invoices.ResourceHoursCalculationValue}
                                    selectOnFocus
                                />
                            )}
                        </If>
                    </div>
                    <div className="col-md-6">
                        <DropdownList
                            value={this.CalculationMode}
                            values={this.CalculationModes}
                            label={TextResources.Invoices.ResourceHoursAmountModeLabel}
                            textGetter={(v) => v.label}
                            valueGetter={(v) => v.id.toString()}
                            idGetter={(v) => v.id}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-primary btn-circle btn-xs"
                            onClick={this.doCalculationAndApply.bind(this)}>
                            {TextResources.Invoices.Apply}
                        </button>
                    </div>
                </div>
            </>
        );
    }

    private doCalculationAndApply(): void {
        const isValid = this.props.resource.validateAndShowInfoToast();
        if (!isValid) return;

        const modeId = this.CalculationMode();
        const hours = this.calculationMethods[modeId]();

        const mode = this.CalculationModes.firstOrDefault((m) => m.id === modeId);
        const modeDescription = "" + this.Value() + " " + mode.label;
        this.props.resource.Notes(modeDescription + " " + (this.props.resource.Notes() ?? ""));

        this.props.resource.HoursAmount(hours);
    }

    private calculateInWeeklyHoursMode(): number {
        const requestedValue = this.Value();
        return this.calculateWeeklyHours(requestedValue);
    }

    private calculateInMonthlyHoursMode(): number {
        const requestedValue = this.Value() / 4.3;
        return this.calculateWeeklyHours(requestedValue);
    }

    private calculateWeeklyHours(requestedValue: number): number {
        const resource = this.props.resource;
        const orders = resource.getResourceServiceOrdersIntoPeriod();

        let startDate = resource.StartDate();
        let endDate = null;

        let total = 0;

        for (const order of orders) {
            endDate = order.ToDate ?? resource.EndDate();
            total += resource.getHoursIntoPeriod(order, startDate, endDate, requestedValue);
            startDate = endDate;
        }

        return total;
    }

    private calculateInPercentageHoursMode(): number {
        const requestedValue = this.Value();
        const totalWorkingHours = this.props.resource.getTotalWorkingHoursIntoPeriod();
        return (totalWorkingHours * requestedValue) / 100;
    }

    private populateCalculationModes() {
        this.CalculationModes.push({
            id: DocumentResourceHoursCalculation.WeeklyHours,
            label: TextResources.Invoices.ResourceWeeklyHoursCalculation,
        });
        this.CalculationModes.push({
            id: DocumentResourceHoursCalculation.MonthlyHours,
            label: TextResources.Invoices.ResourceMonthlyHoursCalculation,
        });
        this.CalculationModes.push({
            id: DocumentResourceHoursCalculation.PercentageOnResourceWorkingHours,
            label: TextResources.Invoices.ResourcePercentageHoursCalculation,
        });

        this.calculationMethods[DocumentResourceHoursCalculation.WeeklyHours] =
            this.calculateInWeeklyHoursMode.bind(this);
        this.calculationMethods[DocumentResourceHoursCalculation.MonthlyHours] =
            this.calculateInMonthlyHoursMode.bind(this);
        this.calculationMethods[DocumentResourceHoursCalculation.PercentageOnResourceWorkingHours] =
            this.calculateInPercentageHoursMode.bind(this);
    }
}

export function DocumentResourcesEditor() {
    let vm: Document;

    return (
        <ko-bind data-bind={{ if: vm.CanHaveResources() && vm.ShowResources() }}>
            <ko-bind data-bind={{ tsxtemplate: vm.ResourcesEditorUI }}></ko-bind>
        </ko-bind>
    );
}

export function DocumentResources(props: DocumentResourcesProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./DocumentResources")._DocumentResources as typeof _DocumentResources;
    return <C {...props} />;
}

export class _DocumentResources {
    static defaultProps: Partial<DocumentResourcesProps> = {
        className: "",
    };

    @LazyImportSettingManager(nameof<IDocumentResourcesSettingsManager>())
    private documentResourcesSettingsManager: IDocumentResourcesSettingsManager;

    private settings: DocumentResourcesSettings;
    private resourceHasFocus = false;
    private startHasFocus = false;
    private endHasFocus = false;
    private notesHasFocus = false;

    constructor(private props: DocumentResourcesProps) {
        if (!this.props.readonly) this.props.readonly = ko.observable(false);
        this.loadSettings();
    }

    private loadSettings(): void {
        this.settings = this.documentResourcesSettingsManager.getSettings();
    }

    addResource() {
        this.props.resources().forEach((r) => r.closeAssignmentsDetails());

        const startDate = this.readStartDateFromDocument();
        const endDate = this.readEndDateFromDocument();

        this.props.resources.push(
            new DocumentResource(
                {
                    Id: null,
                    FKDocument: null,
                    FKResource: null,
                    StartDate: startDate,
                    EndDate: endDate,
                    HoursAmount: 0,
                },
                this.props.resources,
                this.props.documentFieldsReader
            )
        );
    }

    readEndDateFromDocument() {
        if (this.settings?.assignmentEndDataSource && this.settings?.assignmentEndSourceField) {
            return this.props.documentFieldsReader.getValue(
                this.settings.assignmentEndDataSource as DataSourceType,
                this.settings.assignmentEndSourceField
            );
        }

        return null;
    }

    readStartDateFromDocument() {
        if (this.settings?.assignmentStartDataSource && this.settings?.assignmentStartSourceField) {
            return this.props.documentFieldsReader.getValue(
                this.settings.assignmentStartDataSource as DataSourceType,
                this.settings.assignmentStartSourceField
            );
        }

        return null;
    }

    removeResource(resource: DocumentResource) {
        this.props.resources().forEach((r) => r.closeAssignmentsDetails());
        this.props.resources.remove(resource);
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const resourcesTable = this;
        let resource: IDataSourceModel<number, DocumentResource>;

        return ComponentUtils.bindTo(
            <div className={"row striped resources " + classes.resources + " " + this.props.className}>
                <div className="col-md-12 table-wrapper">
                    <Table
                        dataSource={{ array: this.props.resources, factory: this.modelsFactory.bind(this) }}
                        compact
                        className="table-advance"
                        rowAs="resource"
                        title="Risorse"
                        enableAggregators
                        id={ProlifeSdk.DocumentResourcesTable}>
                        <Column
                            title={TextResources.Invoices.DocumentResourceColumnTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.Name}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <Select2
                                        dataSource={item.Data.model.ResourcesDataSource}
                                        value={item.Data.model.ResourceId}
                                        listener={item.Data.model}
                                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                                        readonly={this.props.readonly}
                                        simple
                                        onFocus={(elem) => {
                                            this.resourceHasFocus = true;
                                        }}
                                        onBlur={(elem) => (this.resourceHasFocus = false)}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentResourceStartDateColumnTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.StartDate}
                            style={{ width: "110px" }}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <DateTimeInput
                                        value={item.Data.model.StartDate}
                                        placeholder={TextResources.ProlifeSdk.DateTimeInputPlaceholder}
                                        readonly={this.props.readonly}
                                        allowClear
                                        simple
                                        dateonly
                                        onFocus={(elem) => {
                                            this.startHasFocus = true;
                                        }}
                                        onBlur={(elem) => (this.startHasFocus = false)}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentResourceEndDateColumnTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.EndDate}
                            style={{ width: "110px" }}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <DateTimeInput
                                        value={item.Data.model.EndDate}
                                        placeholder={TextResources.ProlifeSdk.DateTimeInputPlaceholder}
                                        readonly={this.props.readonly}
                                        allowClear
                                        simple
                                        dateonly
                                        onFocus={(elem) => {
                                            this.endHasFocus = true;
                                        }}
                                        onBlur={(elem) => (this.endHasFocus = false)}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentResourceHoursColumnTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.HoursAmount()}
                            defaultAggregation={TableFooterAggregationMode.Sum}
                            className="text-right"
                            headerCssClasses="hours-amount-header"
                            style={{ width: "100px" }}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <div className="input-group hours-amount">
                                        <If condition={item.Data.model.ShowAlert}>
                                            {() => (
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    style={{
                                                        position: "absolute",
                                                        top: "6px",
                                                        left: "0px",
                                                        zIndex: 999,
                                                        color: "red",
                                                    }}
                                                    title={TextResources.Invoices.DocumentResourceHoursAlert}></i>
                                            )}
                                        </If>
                                        <NumberInput
                                            value={item.Data.model.HoursAmount}
                                            placeholder={TextResources.ProlifeSdk.IntNumericInputPlaceholder}
                                            readOnly={this.props.readonly}
                                            nullable
                                            simple
                                            selectOnFocus
                                            onFocus={(elem) => item.Data.model.showAssignmentsDetails(elem)}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    if (
                                                        this.resourceHasFocus ||
                                                        this.startHasFocus ||
                                                        this.endHasFocus ||
                                                        this.notesHasFocus
                                                    )
                                                        item.Data.model.closeAssignmentsDetails();
                                                }, 200);
                                            }}
                                        />
                                        <span className="input-group-btn">
                                            <button
                                                className="btn btn-primary btn-xs"
                                                type="button"
                                                data-bind={{
                                                    disable: resourcesTable.props.readonly,
                                                    asyncClick: resource.model.openHoursAmountCalculator.bind(
                                                        resource.model
                                                    ),
                                                }}>
                                                <i
                                                    className="fa fa-building-o"
                                                    style={{
                                                        transform: "rotate(180deg)",
                                                        position: "relative",
                                                        top: "-1px",
                                                    }}></i>
                                            </button>
                                        </span>
                                    </div>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentResourcesNotesColumnTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.Notes}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <TextInput
                                        value={item.Data.model.Notes}
                                        placeholder={TextResources.Invoices.DocumentResourcesNotesColumnTitle}
                                        readonly={this.props.readonly}
                                        selectOnFocus
                                        simple
                                        onFocus={(elem) => {
                                            this.notesHasFocus = true;
                                        }}
                                        onBlur={(elem) => (this.notesHasFocus = false)}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.DocumentResourcesRatioTitle}
                            aggregateOn={(item: ITableItem<DocumentResource>) => item.Data.model.Ratio}>
                            <ColumnBody>
                                {(item: ITableItem<DocumentResource>) => (
                                    <NumericText value={item.Data.model.Ratio} format="0,0 %" />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column style={{ width: "65px" }} className="text-right">
                            <ColumnHeader>
                                <IfNot condition={this.props.readonly}>
                                    {() => (
                                        <button
                                            className="btn btn-primary btn-xs"
                                            data-bind={{ click: resourcesTable.addResource.bind(resourcesTable) }}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    )}
                                </IfNot>
                            </ColumnHeader>
                            <ColumnBody>
                                <button
                                    className="btn btn-default btn-circle btn-xs"
                                    title={TextResources.Invoices.ShowResourcesNotesTooltip}
                                    data-bind={{ toggle: resource.model.ShowNotes }}>
                                    <i
                                        className="fa"
                                        data-bind={{
                                            css: {
                                                "fa-chevron-down": !resource.model.ShowNotes(),
                                                "fa-chevron-up": resource.model.ShowNotes,
                                            },
                                        }}></i>
                                </button>
                                <IfNot condition={this.props.readonly}>
                                    {() => (
                                        <button
                                            className="btn btn-danger btn-xs"
                                            data-bind={{
                                                click: resourcesTable.removeResource.bind(
                                                    resourcesTable,
                                                    resource.model
                                                ),
                                            }}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                    )}
                                </IfNot>
                            </ColumnBody>
                        </Column>
                        <SecondaryRow if={() => "resource.model.ShowNotes"}>
                            {(item: ITableItem<DocumentResource>) => (
                                <td colSpan={6}>
                                    <textarea
                                        data-bind={{
                                            value: resource.model.Notes,
                                            disable: resourcesTable.props.readonly,
                                        }}
                                        style={{ width: "100%" }}
                                        placeholder={
                                            TextResources.Invoices.DocumentResourcesNotesColumnTitle
                                        }></textarea>
                                </td>
                            )}
                        </SecondaryRow>
                    </Table>
                </div>
            </div>,
            this,
            "resourcesTable"
        );
    }

    private modelsFactory(resource: DocumentResource): IDataSourceModel<number, DocumentResource> {
        return {
            id: resource.Id,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: resource,
        };
    }
}

export class DocumentResourcesUI {
    constructor(private props: DocumentResourcesProps) {}

    render() {
        return <DocumentResources {...this.props} />;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentResources);
}
