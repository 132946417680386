import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { EditorFor } from "./ReportEditorProvider";
import { ReportComponent } from "../Components/ReportComponent";
import { ReportEditorProps } from "./ReportEditor";
import { CheckBox } from "../../Checkbox";
import { ReportComponentWithVisibility } from "../Components/ReportComponentWithVisibility";
import { DataSourceBindingDialog } from "./DataSourceEditor/DataSourceBindingDialog";

const styleSheet = jss.createStyleSheet({
    visibilityEditor: {
        "& .toolbar": {
            backgroundColor: "#eee",
            minHeight: '28px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },

        "& .list-group-item": {
            padding: '5px'
        }
    }
});
const { classes } = styleSheet.attach();

type ReportVisibilityEditorProps = {
    component: ReportComponentWithVisibility & ReportComponent;
} & ReportEditorProps;

export function ReportVisibilityEditor(props: ReportVisibilityEditorProps) {
    const C = require("./ReportVisibilityEditor")._ReportVisibilityEditor as typeof _ReportVisibilityEditor;
    return <C {...props} />;
}

@EditorFor("Visibility")
export class _ReportVisibilityEditor {
    static defaultProps: Partial<ReportVisibilityEditorProps> = {
    }

    constructor(private props : ReportVisibilityEditorProps) {
        
    }

    private async openBindingEditor() {
        const result = await new DataSourceBindingDialog({
            type: "boolean",
            component: this.props.component,
            binding: this.props.component.boundVisible,
            dataSources: this.props.component.getSection().dataSources()
        }).show();

        this.props.component.boundVisible.loadFrom(result);
    }

    render() {
        let ve = this;

        return  ComponentUtils.bindTo(
                <div class={ComponentUtils.classNames("editor-group", classes.visibilityEditor)}>
                    <span class="editor-group-title">Visibilità</span>
                    <div className="editor-group-body" style={{ paddingTop: '5px'}}>
                        <div className="row">
                            <div className="col-md-12 flex-container">
                                <CheckBox label="Mostra Componente" checked={this.props.component.visible} readOnly={this.props.component.boundVisible.bound} />
                                <button className="btn btn-sm" style={{ marginLeft: 'auto' }} onClick={() => this.openBindingEditor()} data-bind={{ css: { 'btn-primary': ve.props.component.boundVisible.bound, 'btn-default': !ve.props.component.boundVisible.bound() } }}>
                                    <i className="fa fa-chain" />
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <CheckBox label="Mantieni su una pagina" checked={this.props.component.doNotSplit} />
                            </div>
                        </div>
                    </div>
                </div>
        , ve, 've');
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportVisibilityEditor);
}