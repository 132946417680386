import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentRows, DocumentRow } from "./DocumentRows";
import { Document } from "./Document";
import { DocumentLayouts } from "./DocumentLayouts";

export function OffsetsTableReadOnly() {
    let rows : DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return <ko-bind data-bind={{ with: rows.Document, as: 'doc' }}>
                <div class="offsets-panel striped" data-bind={{ visible: doc.IsOnTop }}>
                    <table class="table table-condensed no-border table-advance readonly" data-bind={{ css: { 'compact-detailed': doc.LayoutId() == 3 } }}> {/* DocumentLayouts.DetailedCompact */}
                        <thead>
                            <tr>
                                <th>{TextResources.Invoices.DocumentOffset}</th>
                                <th>{TextResources.Invoices.DocumentWorkflows}</th>
                            </tr>
                        </thead>
                        <tbody class="offsets-table-body">
                            <ko-bind data-bind={{ foreach: { data: doc.Rows, as: 'row' } }}>
                                <tr>
                                    <td>
                                    <select2 params="CssClass: 'select2-container-for-documents', DataSource: row.OffsetsDataSource, Listener: $data, AllowClear: true, Placeholder: 'Seleziona...', Value: row.FKOffset, Simple: true, NoFormControl: true, NoDropdown: true, ReadOnly: true"></select2>
                                    </td>
                                    <td>
                                        {RenderRelatedWorkflowsBadge()}
                                        <span class="text-ellipsis" style="display: inline-block; width: 72px; vertical-align: bottom" data-bind={{ text: row.FormattedRelatedWorkflows, attr: { title: row.FormattedRelatedWorkflows } }}></span>
                                        <button class="btn btn-primary btn-xs btn-related-workflows" style="display: inline-block; margin-top: -5px;" data-bind={{ asyncClick: row.showReadonlyRelatedWorkflowsPopUp.bind(row) }}>
                                            <i class="fa fa-chevron-down"></i>
                                        </button>
                                    </td>
                                </tr>
                                <ko-bind data-bind={{ if: doc.LayoutId() == 2 && row.Amount() != 0 }}> {/* DocumentLayouts.Detailed */}
                                    <tr>
                                        <td style="vertical-align: middle !important" colSpan={2} class="small">
                                            <div data-bind={{ text: row.AmountFormula, attr: { 'data-amount': row.Amount } }}></div>
                                        </td>
                                    </tr>
                                </ko-bind>
                            </ko-bind>
                        </tbody>
                    </table>
                </div>
            </ko-bind>;
}

export function RenderRelatedWorkflowsBadge() {
    let row: DocumentRow;

    return  <span class="related-workflows-badge badge" data-bind={{ visible: row.HasRelatedWorkflows, numberText: row.AmountAssignedToWorkflows, format: "0,0[.][000]", css: { 'badge-success': row.AmountAssignedToWorkflows() === row.Amount(), 'badge-warning': row.AmountAssignedToWorkflows() !== row.Amount() }, attr: { title: row.RelatedWorkflowsTooltipText } }}>
                {/* <i class="fa" data-bind={{ css: {'fa-check': row.AmountAssignedToWorkflows() === row.Amount(), 'fa-arrow-down': row.AmountAssignedToWorkflows() < row.Amount(), 'fa-arrow-up': row.AmountAssignedToWorkflows() > row.Amount() } }}></i> */}
            </span>;
}

export function OffsetsTableNonReadOnly() {
    let rows : DocumentRows;
    let doc: Document;
    let row: DocumentRow;

    return  <ko-bind data-bind={{ with: rows.Document, as: 'doc' }}>
                <div class="offsets-panel striped" data-bind={{ visible: doc.IsOnTop }}>
                    <table class="table table-condensed no-border table-advance" data-bind={{ css: { 'compact-detailed': doc.LayoutId() == 3 } }}> {/* DocumentLayouts.DetailedCompact */}
                        <thead>
                            <tr>
                                <th>
                                    <div class="flex-container flex-child-center">
                                        <span class="flex-1">{TextResources.Invoices.DocumentOffset}</span>
                                        <button class="btn btn-primary btn-xs btn-invoice" data-bind={{ asyncClick: rows.ShowOffsetAutomation.bind(rows) }}>
                                            <i class="fa fa-chevron-down"></i>
                                        </button>
                                    </div>
                                </th>
                                <th>
                                    <div class="flex-container flex-child-center">
                                        <span class="flex-1">{TextResources.Invoices.DocumentWorkflows}</span>
                                        <button class="btn btn-primary btn-xs btn-invoice" data-bind={{ asyncClick: rows.ShowRelatedWorkflowsAutomation.bind(rows) }}>
                                            <i class="fa fa-chevron-down"></i>
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="offsets-table-body">
                            <ko-bind data-bind={{ foreach: { data: doc.Rows, as: 'row' }}}>
                                <tr>
                                    <td>
                                        <select2 params="CssClass: 'select2-container-for-documents', DataSource: row.OffsetsDataSource, Listener: $data, AllowClear: true, Placeholder: 'Seleziona...', Value: row.FKOffset, Simple: true, NoFormControl: true, NoDropdown: true"></select2>
                                    </td>
                                    <td>
                                        {RenderRelatedWorkflowsBadge()}
                                        <span class="text-ellipsis" style="display: inline-block; width: 72px; vertical-align: bottom" data-bind={{ text: row.FormattedRelatedWorkflows, attr: { title: row.FormattedRelatedWorkflows } }}></span>
                                        <button class="btn btn-primary btn-xs btn-related-workflows" style="display: inline-block; margin-top: -5px;" data-bind={{ asyncClick: row.showRelatedWorkflowsPopUp.bind(row) }}>
                                            <i class="fa fa-chevron-down"></i>
                                        </button>
                                    </td>
                                </tr>
                                <ko-bind data-bind={{ if: doc.LayoutId() == 2 }}> {/* DocumentLayouts.Detailed */}
                                    <tr>
                                        <td colSpan={2} class="small">
                                            <input autoComplete="off" type="text" class="input-block-level amount-formula font-grey-gallery" style="font-size: 1em;" title={TextResources.Invoices.AmountFormulaPlaceholder} data-bind={{ disable : row.IsLocked, value: row.AmountFormula, attr: { 'data-amount': row.Amount }, selectOnFocus: {} }} />
                                        </td>
                                    </tr>
                                </ko-bind>
                            </ko-bind>
                        </tbody>
                    </table>
                </div>
            </ko-bind>;
}