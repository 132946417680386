import * as ko from "knockout";
import { ReportComponent, ReportComponentProps } from "./ReportComponent";
import { HasChildren, ReportComponentWithChildrenProps } from "./ReportComponentWithChildren";
import { HasDataSource, ReportComponentWithDataSourceProps } from "./ReportComponentWithDataSource";
import { ReportPanel, ReportPanelProps } from "./ReportPanel";

export type ReportSectionProps = {
    header?: ReportPanelProps;
    footer?: ReportPanelProps;
} & ReportComponentWithDataSourceProps & ReportComponentWithChildrenProps & ReportComponentProps;

export class ReportSection extends HasDataSource(HasChildren(ReportComponent)) {
    //public features: string[] = ["Section"];
    get type() : string { return nameof<ReportSection>(); }

    header: ReportPanel;
    footer: ReportPanel;
    
    constructor(props: ReportSectionProps) {
        super(props);
        this.features = ["Component", "DataSource", "Section"];

        this.header = new ReportPanel(props.header ?? { type: nameof<ReportPanel>(), id: -1, name: "Intestazione", x: 0, y: 0, width: 21, height: 3 });
        this.footer = new ReportPanel(props.footer ?? { type: nameof<ReportPanel>(), id: -1, name: "Pié di Pagina", x: 0, y: 26.7, width: 21, height: 3 });

        this.header.internalParent(this);
        this.footer.internalParent(this);
    }

    getData() {
        const data = super.getData();
        return {
            ...data,
            header: this.header.getData(),
            footer: this.footer.getData()
        }
    }
}