import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IRoleRemainingWork } from "../ProlifeSdk/interfaces/job-order/IJobOrder";

interface AllocationsMenuDetailsParameters {
    RolesRemainingWork: IRoleRemainingWork[];
}

interface DetailstableHeaders {
    OperationalUnit: string;
    ReestimatedRemainingWork: string;
    ReestimatedWork: string;
}

export class AllocationsMenuDetailsComponent {
    public DefaultTextOnEmptyList: string;

    public RolesRemainingWork: IRoleRemainingWork[];

    constructor(params: AllocationsMenuDetailsParameters) {
        this.RolesRemainingWork = params.RolesRemainingWork || [];
    }
}

ko.components.register("allocations-menu-details", {
    viewModel: {
        createViewModel: (params: AllocationsMenuDetailsParameters, componentInfo: ko.components.ComponentInfo) => {
            let vm = new AllocationsMenuDetailsComponent(params);
            let $data = vm;
            var role: IRoleRemainingWork;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <span
                        data-bind={{ visible: $data.RolesRemainingWork.length == 0 }}
                        style="font-size: 8pt; display: none;">
                        {TextResources.Allocations.EmptyAllocationsMenuDetailsList}
                    </span>
                    <table
                        class="table table-condensed"
                        data-bind={{ visible: $data.RolesRemainingWork.length > 0 }}
                        style="font-size: 8pt; margin-bottom: 0px;">
                        <thead>
                            <tr>
                                <th style="font-size: 8pt; border-bottom: 1px solid #ddd; padding: 0px; width: 33.3%">
                                    {TextResources.Allocations.Role}
                                </th>
                                <th style="font-size: 8pt; border-bottom: 1px solid #ddd; padding: 0px; width: 33.3%">
                                    {TextResources.Allocations.ReestimatedRemainingWork}
                                </th>
                                <th style="font-size: 8pt; border-bottom: 1px solid #ddd; padding: 0px; width: 33.3%">
                                    {TextResources.Allocations.ReestimatedWork}
                                </th>
                            </tr>
                        </thead>
                        <tbody data-bind={{ foreach: { data: $data.RolesRemainingWork, as: "role" } }}>
                            <tr>
                                <td data-bind={{ text: role.RoleName }} style="padding: 0px"></td>
                                <td
                                    data-bind={{ numberText: role.RemainingReestimatedHours }}
                                    style="padding: 0px"></td>
                                <td data-bind={{ numberText: role.TotalReestimatedHours }} style="padding: 0px"></td>
                            </tr>
                        </tbody>
                    </table>
                </>,
            ]);

            return vm;
        },
    },
    template: [],
});
