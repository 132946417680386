import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import * as moment from "moment";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { BaseDocumentForWizard } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/BaseDocumentForWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "../wizard/ImportDocumentDataWizard";
import { EstimateStatesDataSource } from "../../../../DataSources/EstimateStatesDataSource";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEstimateRowForDocuments, IEstimateRow, IEstimateForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IEstimate";
import { IEstimatesService } from "../../../../ProlifeSdk/interfaces/invoice/IEstimatesService";
import { ICustomerOrderRowForDocuments } from "../../../../ProlifeSdk/interfaces/warehouse/ICustomerOrder";
import { IBaseDocumentRowForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IDocumentBuilderDocumentOriginatingRows, IDocumentBuilderDocumentRelatedWorkflows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDocumentToImportInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentImportDataWizardStep";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IEstimateStates, IEstimateState } from "../../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { DocumentLogicalStatus } from "../../enums/DocumentLogicalStatus";
import { DocumentDataSourceDataWizardStep } from "../wizard/data-sources/DocumentDataSourceDataWizardStep";

export class EstimatesDataSource extends DocumentDataSourceDataWizardStep<IEstimateRowForDocuments, EstimateRow>
{
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;
    @LazyImport(nameof<IEstimatesService>())
    private estimatesService : IEstimatesService;
    @LazyImportSettingManager(ProlifeSdk.EstimateStates)
    private estimatesStateManager: IEstimateStates;

    public JobOrderName : ko.Observable<string> = ko.observable();
    public CustomerName : ko.Observable<string> = ko.observable();
    public WithNoJobOrder : ko.Observable<boolean> = ko.observable(false);
    public AllStates: ko.Observable<boolean> = ko.observable(false);
    public EstimateStateId: ko.Observable<number> = ko.observable();
    public Estimates : ko.ObservableArray<Estimate> = ko.observableArray();

    public Total : ko.Computed<number>;

    public DateFrom : ko.Observable<Date> = ko.observable();
    public DateTo : ko.Observable<Date> = ko.observable();
    public SelectAllRows : ko.Computed<boolean>;
    private DisableDocumentsReload  = false;
    private referenceDate : Date;

    public WarningMessages : ko.Observable<string> = ko.observable();
    public ExpandedColumn: ko.Observable<number> = ko.observable(1);
    previousStepRows: IDocumentDataWizardRow[];

    public ShowEstimateReferenceRow: ko.Observable<boolean> = ko.observable(false);
    public ShowExternalReferencesRow: ko.Observable<boolean> = ko.observable(false);

    EstimateStatesDataSource: EstimateStatesDataSource = new EstimateStatesDataSource();

    constructor()
    {
        super();
        this.Title(TextResources.Invoices.Quotes);
        
        this.installWatches();

        this.Total = ko.computed(() => {
            let result = 0;
            this.Rows().forEach(r => {
                result += (r.TotaleRiga || 0);
            });
            return result;
        });

        this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.CustomerOrderEntityTypeCode,
            ProlifeSdk.SalEntityTypeCode,
            ProlifeSdk.InvoiceEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.CreditNoteEntityTypeCode,
            ProlifeSdk.EstimateEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode
        );

        this.SelectAllRows = ko.computed({
            read: () => {
                const documents = this.SelectedDocuments();
                let rowsCount = 0;
                let selectedRowsCount = 0;
                
                documents.forEach((e: Estimate) => {
                    const allRows = e.Rows();
                    const selectedRows = allRows.filter((r : EstimateRow) => { return r.Selected(); })

                    rowsCount += allRows.length;
                    selectedRowsCount += selectedRows.length;
                });

                return rowsCount === selectedRowsCount ? true : selectedRowsCount === 0 ? false : undefined;
            },
            write: (selected : boolean) => {
                this.SelectedDocuments().forEach((e : Estimate) => {
                    e.Rows().forEach((r : EstimateRow) => {
                        r.Selected(selected);
                    });
                });
            }
        });
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }
    
    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Quantita,
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: r.NetUnitPriceInDocumentCurrency,
                NetUnitPrice: r.NetUnitPriceInDocumentCurrency.ToEuro(documentCurrency),
                Order: 0,
                TotalPriceInDocumentCurrency: r.TotalPriceInDocumentCurrency,
                TotalPrice: r.TotalPriceInDocumentCurrency.ToEuro(documentCurrency),
                UnitPriceInDocumentCurrency: r.PriceInDocumentCurrency,
                UnitPrice: r.PriceInDocumentCurrency.ToEuro(documentCurrency),
                Description: r.Descrizione,
                Discounts: r.Discounts,
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: this.getRelatedWorkflows(r),
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : IEstimateRowForDocuments) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        for(const sourceRow of r.Rows.filter(r => r.Quantita != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                SourceEntityType : ProlifeSdk.EstimateEntityTypeCode,
                SourceEntityKeyId : sourceRow.IdRigaPreventivo,
                DestEntityType : this.initializationInfo.DocTypeCode,
                DestEntityKeyId : 0,
                Amount: sourceRow.Quantita,
                UnitPrice : sourceRow.Importo,
                Discounts : sourceRow.Discounts,
                NetUnitPrice: sourceRow.NetUnitPrice,
                NetPrice : sourceRow.TotaleRiga
            });
        }
        return refs;
    }

    protected getRelatedWorkflows(source : IEstimateRowForDocuments) : IDocumentBuilderDocumentRelatedWorkflows[] {
        const result : IDocumentBuilderDocumentRelatedWorkflows[] = [];

        for(const row of source.Rows) {
            for(const rw of row.RelatedWorkflows || []) {

                result.push({
                    Id : null,
                    WorkflowId : rw.WorkflowId,
                    DocumentId : null,
                    DocumentType: this.initializationInfo.DocTypeCode,
                    DocumentNumber: null,
                    DocumentDate: null,
                    DocumentProtocolId: null,
                    RowId: 0,
                    RowDescription: null,
                    RowAmount: source.Quantita,
                    RowUoM: source.Udm,
                    RowPrice: source.NetUnitPrice,
                    WorkflowAmount: rw.WorkflowAmount,
                    ValidityStartDate: null,
                    WorkflowName: rw.WorkflowName
                });
            }
        }

        return result;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return true;
    }

    public getDocumentType() : string {
        return this.initializationInfo.DocTypeCode;
    }

    public async applyStateToSelectedDocuments(): Promise<void> {
        const selectedDocumentsIds = this.SelectedDocuments().map((estimate: Estimate) => estimate.getId());

        if (selectedDocumentsIds.length === 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.EstimatesSelectionRequired);
            return;
        }

        const selectedState = this.EstimateStateId();
        await this.documentsService.SetDocumentsState(selectedState, selectedDocumentsIds);
        this.SelectedDocuments().forEach((e: Estimate) => e.setState(selectedState));
    }

    public HasAuthorization(): boolean {
        return this.authorizationService.isAuthorized("Documents_ViewEstimates") || this.authorizationService.isAuthorized("Documents_Estimates")
    }

    public async GetDocumentsToImportInfo() : Promise<IDocumentToImportInfo[]>
    {
        const estimatesToImport : Estimate[] = [];
        this.Rows().forEach((r : IEstimateRowForDocuments) => {
            r.Rows.forEach((er : IEstimateRow) => {
                const matches : Estimate[] = this.Estimates().filter((e : Estimate) => { return e.getId() == er.FKPreventivo; });

                if(matches.length == 0 || estimatesToImport.indexOf(matches[0]) > -1)
                    return;

                estimatesToImport.push(matches[0]);
            });
        });


        return this.SelectedDocuments().map((e : Estimate) => {
            return {
                Id : e.getId(),
                Name : String.format(ProlifeSdk.TextResources.Invoices.QuoteName, e.Number(), moment(e.Date()).format("L")),
                EntityType : ProlifeSdk.EstimateEntityTypeCode
            }
        });
    }

    protected override onImportSelectedRows(someDocumentsCannotBeImported: boolean)
    {
        if (someDocumentsCannotBeImported)
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.InvalidEstimateStateForImportIntoCustomerOrder);
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        this.DisableDocumentsReload = true;

        await super.Initialize(initializationInfo);
        this.SelectedDocuments([]);
        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf('year').toDate());
        this.DateTo(this.referenceDate);

        const initialState = this.estimatesStateManager.getEstimateStates(false).firstOrDefault(s => s.Stato === DocumentLogicalStatus.Accepted);
        this.EstimateStateId(initialState?.IdStatoPreventivo);

        await this.loadJobOrderName();
        await this.loadCustomerName();

        this.DisableDocumentsReload = false;
        await this.loadEstimates();
    }

    private installWatches()
    {
        this.DisableDocumentsReload = true;
        this.WithNoJobOrder.subscribe(this.loadEstimates.bind(this));
        this.DateFrom.subscribe(this.loadEstimates.bind(this));
        this.DateTo.subscribe(() => {
            if(this.DateTo() > this.referenceDate) {
                this.WarningMessages(String.format(ProlifeSdk.TextResources.Invoices.WatchWarning, moment(this.referenceDate).format("L")));
            } else {
                this.WarningMessages(undefined);
            }
            this.loadEstimates();
        });
        this.AllStates.subscribe(this.loadEstimates.bind(this));
        this.DisableDocumentsReload = false;
    }

    private async loadJobOrderName()
    {
        let jobOrder : IJobOrder;
        try
        {
            jobOrder = await this.jobOrderService.get(this.initializationInfo.JobOrderId);
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.JobOrderName(jobOrder?.Name);
        }
    }

    private async loadCustomerName()
    {
        let customer : ICustomer;
        try
        {
            customer = await this.customersService.getCustomerById(this.initializationInfo.CustomerId)
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.CustomerName(customer?.FormattedContactName);
        }
    }

    private async loadEstimates()
    {
        if(this.DisableDocumentsReload)
            return;

        const estimates = await this.estimatesService.GetEstimatesForWizard(
            "",
            this.WithNoJobOrder(), 
            this.initializationInfo.JobOrderId, 
            true, 
            this.AllStates(), 
            -1, 
            this.DateFrom(), 
            this.DateTo(),  
            0, 
            1000000
        );
        this.estimatesLoaded(estimates);
    }

    private estimatesLoaded(estimates : IEstimateForWizard[])
    {
        this.SelectedDocuments([]);
        this.Estimates(estimates
            .map(this.createViewModelFor.bind(this)));
    }

    private createViewModelFor(estimate : IEstimateForWizard) : Estimate
    {
        return new Estimate(estimate, this, this.initializationInfo.DocumentOriginatingRows);
    }

    public removeRow(row : any)
    {
        this.Rows.remove(row);
    }

    expandColumn(colId: number): void {
        this.ExpandedColumn(colId);
    }

    render() {
        let step : EstimatesDataSource;
        let estimate: Estimate;
        let estimateRow: EstimateRow;
        let outputRow: IEstimateRowForDocuments;

        return ComponentUtils.bindTo(
                <div class="form-horizontal" style="padding: 0px 15px;">
                    <div class="row wizard-header" style="height: 110px">
                        <div class="col-md-8 wizard-header-block">
                            <div class="row">
                                <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardJobOrder}</label>
                                <div class="col-md-3 form-control-static text-ellipsis" data-bind={{text: step.JobOrderName, attr: { title: step.JobOrderName }, tooltip: { placement: 'right' }}}></div>
                                
                                <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardCustomer}</label>
                                <div class="col-md-3 form-control-static text-ellipsis" data-bind={{text: step.CustomerName, attr: { title: step.CustomerName }, tooltip: { placement: 'right' }}}></div>
                            </div>
                            <div class="row">
                                <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardFrom}</label>
                                <div class="col-md-3">
                                    <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateFrom}}>
                                        <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                        <span class="input-group-addon">
                                            <span class="fa fa-calendar"></span>
                                        </span>
                                    </div>
                                </div>
                                <label class="col-md-1 control-label">{TextResources.Invoices.DocumentWizardTo}</label>
                                <div class="col-md-3">
                                    <div class="input-group date form_datetime" data-bind={{nullableDatePicker : step.DateTo}}>
                                        <input class="form-control form-control-inline date-picker" type="text" id="date" />
                                        <span class="input-group-addon">
                                            <span class="fa fa-calendar"></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4" style="background-color: #f1f1f1; padding-top: 5px; padding-bottom: 5px; position: relative; top: -5px;">
                                    <div class="row">
                                        <label class="col-md-3 control-label">{TextResources.Invoices.DocumentWizardStatus}</label>
                                        <div class="col-md-9">
                                            <div class="input-group">
                                                <select2
                                                    simple={true}
                                                    allowClear={true}
                                                    value={() => "EstimateStateId"}
                                                    placeholder={TextResources.Invoices.DocumentWizardSelectPlaceholder}
                                                    dataSource={() => "EstimateStatesDataSource"}></select2>
                                                <span class="input-group-btn">
                                                    <button class="btn btn-primary" type="button" title={TextResources.Invoices.DocumentWizardApplyStateToEstimates} data-bind={{click: step.applyStateToSelectedDocuments}}>
                                                        <i class="fa fa-check"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="checkbox" data-bind={{checkbox : step.WithNoJobOrder}} />
                                    {TextResources.Invoices.DocumentWizardSearchEstimatedWithNoJobOrder}
                                </div>
                                
                                <div class="col-md-2">
                                    <input type="checkbox" data-bind={{checkbox : step.AllStates}} />
                                    {TextResources.Invoices.DocumentWizardShowNotAcceptedEstimates}
                                </div>
                                <div class="col-md-3">
                                    <label style="font-size: 13px;">
                                        <input type="checkbox" data-bind={{checkbox: step.ShowEstimateReferenceRow}}></input> {TextResources.Invoices.DocumentWizardShowReferenceToEstimate}
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <label style="font-size: 13px">
                                        <input type="checkbox" data-bind={{checkbox: step.ShowExternalReferencesRow}}></input> {TextResources.Invoices.DocumentWizardShowExternalReference}
                                    </label>
                                </div>
                                <ko-bind data-bind={{ if: step.DocumentCurrenciesEnabled }}>
                                    <div class="col-md-1" data-bind={{with: step.initializationInfo.DocumentCurrenciesInfo}}>
                                        <document-currencies-popover 
                                            readonly-document-currency={() => "LockDocumentCurrencyChanges"}
                                            readonly-exchange-value={() => "LockExchangeValueChanges"}
                                            readonly-exchange-valueForVat={() => "LockExchangeValueForVatChanges"}
                                            document-currencies={() => "DocumentCurrencies"}
                                            button-css-classes='btn btn-sm blue'
                                            popover-css-classes='currencies-manager-for-modal'
                                            button-text={TextResources.Invoices.CurrenciesLabel}></document-currencies-popover>
                                    </div>
                                </ko-bind>
                            </div>
                        </div>
                        <div class="col-md-4 wizard-header-block">
                            <div class="alert alert-danger" data-bind={{visible: !!step.WarningMessages(), text: step.WarningMessages}}>

                            </div>
                        </div>
                    </div>

                    <div class="row wizard-body" style="top: 125px">
                        <div class="wizard-body-column first-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 1, 'collapsed-column': step.ExpandedColumn() != 1 }, click: step.expandColumn.bind(step, 1)}}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center closure-state-col">
                                                <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardClosureStatus}></i>
                                            </th>
                                            <th class="doc-number-col">{TextResources.Invoices.DocumentWizardNumber}</th>
                                            <th class="doc-date-col">{TextResources.Invoices.DocumentWizardDate}</th>
                                            <th class="doc-state-col">{TextResources.Invoices.DocumentWizardStatus}</th>
                                            <th class="text-right">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                            <th class="text-right">{TextResources.Invoices.CurrencyLabel}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if : step.Estimates().length == 0 }}>
                                                <tr>
                                                    <td colSpan={4} style="text-align : center">{TextResources.Invoices.DocumentWizardNoEstimate}</td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: { data: step.Estimates, as: 'estimate' }}}>
                                                <tr data-bind={{click: estimate.SwitchSelection, css : { active : estimate.IsSelected }}}>
                                                    <td class="text-center closure-state-col">
                                                        <div class="label label-sm" data-bind={{css : { 'label-warning' : estimate.ClosureStatus() == 1, 'label-danger': estimate.ClosureStatus() == 0, 'label-success': estimate.ClosureStatus() == 2 }, click: estimate.openDocument.bind(estimate), style: { cursor: 'pointer' }}}>
                                                            <i class="fa fa-file-text" data-bind={`attr : { title :  estimate.ClosureStatus() == 1 ? '${TextResources.Invoices.DocumentWizardPartiallyClosed}' : (ClosureStatus() == 0 ? '${TextResources.Invoices.DocumentWizardClosed}' : '${TextResources.Invoices.DocumentWizardOpened}') }`}></i>
                                                        </div>
                                                    </td>
                                                    <td class="doc-number-col" data-bind={{text: estimate.Number}}></td>
                                                    <td class="doc-date-col" data-bind={{dateText: estimate.Date}}></td>
                                                    <td class="doc-state-col" style="padding: 0px">
                                                        <select2
                                                            simple={true}
                                                            allowClear={true}
                                                            value={() => "EstimateStateId"}
                                                            placeholder={TextResources.Invoices.DocumentWizardSelectPlaceholder}
                                                            dataSource={() => "step.EstimateStatesDataSource"}></select2>
                                                    </td>
                                                    <td class="text-right" data-bind={{moneyText: estimate.Total, currencySymbol: estimate.CurrencySymbol, documentCurrency: estimate.DocumentCurrency}}></td>
                                                    <td class="text-right" data-bind={{text: estimate.CurrencyCode}}></td>
                                                </tr>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="wizard-body-column second-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 2, 'collapsed-column': step.ExpandedColumn() != 2 }, style: { left: (step.ExpandedColumn() == 3 || step.ExpandedColumn() == 2 ? '25%' : '50%') }, click: step.expandColumn.bind(step, 2)}}>
                            <div class="wizard-data-source-table-header">
                                <table class="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                <input type="checkbox" data-bind={{checkbox : step.SelectAllRows}} />
                                            </th>
                                            <th class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                            </th>

                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-center closed-amount-col">{TextResources.Invoices.DocumentWizardImportedAmount}</th>
                                            <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmountToImport}</th>
                                            <th class="unit-of-measure-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardUoM}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                            <th class="discount-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardDiscount}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                            <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                            <div class="wizard-data-source-table-body">
                                <div data-bind={{slimScroll: 'auto'}}>
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead></thead>
                                        <tbody>
                                            <ko-bind data-bind={{ if : step.SelectedDocuments().length == 0 }}>
                                                <tr>
                                                    <td colSpan={10} class="text-center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach : { data: step.SelectedDocuments, as: 'estimate' }}}>
                                                <ko-bind data-bind={{ foreach : { data: estimate.Rows, as: 'estimateRow' }}}>
                                                    <tr data-bind={{css : { active : estimateRow.Selected }}}>
                                                        <td class="text-center selection-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                            <input type="checkbox" data-bind={{checkbox : estimateRow.Selected}} />
                                                        </td>
                                                        <td class="text-center closure-state-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 2 }}}>
                                                            <ko-bind data-bind={{ if : estimateRow.AlreadyImportedInThisDocument }}>
                                                                <i class="fa fa-thumb-tack" title={TextResources.Invoices.DocumentWizardInDestinationDocument}></i>
                                                            </ko-bind>
                                                        </td>

                                                        <td><span data-bind={{text: estimateRow.Description}}></span></td>
                                                        <ko-bind data-bind={{ ifnot : estimateRow.ManuallyClosed }}>
                                                            <td class="text-right closed-amount-col" data-bind={{numberText : estimateRow.ClosedAmount}}></td>
                                                        </ko-bind>
                                                        <ko-bind data-bind={{ if : estimateRow.ManuallyClosed }}>
                                                            <td class="text-center closed-amount-col">
                                                                <i class="fa fa-lock" title={TextResources.Invoices.DocumentWizardManuallyClosed}></i>
                                                            </td>
                                                        </ko-bind>
                                                        <td class="text-right amount-col" data-bind={{numberText: estimateRow.Amount}}></td>
                                                        <td class="unit-of-measure-col" data-bind={{text: estimateRow.Udm, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: estimateRow.UnitPrice, currencySymbol: estimate.CurrencySymbol, documentCurrency: estimate.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="discount-col" data-bind={{discountText: estimateRow.Discounts, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: estimateRow.NetUnitPrice, currencySymbol: estimate.CurrencySymbol, documentCurrency: estimate.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 2 }}}></td>
                                                        <td class="text-right total-price-col" data-bind={{moneyText: estimateRow.Total, currencySymbol: estimate.CurrencySymbol, documentCurrency: estimate.DocumentCurrency}}></td>
                                                    </tr>
                                                </ko-bind>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="wizard-body-column third-col" data-bind={{css: { 'expanded-column': step.ExpandedColumn() == 3, 'collapsed-column': step.ExpandedColumn() != 3 }, click: step.expandColumn.bind(step, 3)}}>
                            <div style="text-align: center;" data-bind={{visible: step.ExpandedColumn() == 3}}>
                                <button type="button" class="btn btn-sm btn-primary" data-bind={{click: step.importSelectedRows}}>{TextResources.Invoices.DocumentWizardImportRows}</button>
                                <button type="button" class="btn btn-sm btn-danger" data-bind={{click: step.ClearImportedRows}}>{TextResources.Invoices.DocumentWizardClear}</button>
                            </div>
                            
                            <div class="wizard-body-column-with-actions" data-bind={{style: { top: step.ExpandedColumn() == 3 ? '37px' : '0px', bottom: step.ExpandedColumn() == 3 ? '0px' : '37px' } }}>
                                <div class="wizard-data-source-table-header">
                                    <table class="table table-striped table-bordered table-advance table-hover">
                                        <thead>
                                        <tr>
                                            <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                            <th class="text-right amount-col">{TextResources.Invoices.DocumentWizardAmount}</th>
                                            <th class="unit-of-measure-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardUoM}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                            <th class="discount-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardDiscount}</th>
                                            <th class="text-right unit-price-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardNetUnitPrice}</th>
                                            <th class="text-right total-price-col">{TextResources.Invoices.DocumentWizardTotalPrice}</th>
                                            <th class="action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>{TextResources.Invoices.DocumentWizardRemove}</th>
                                        </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                                <div class="wizard-data-source-table-body">
                                    <div data-bind={{slimScroll: 'auto'}}>
                                        <table class="table table-striped table-bordered table-advance table-hover">
                                            <thead></thead>
                                            <tbody>
                                                <ko-bind data-bind={{ if : step.Rows().length == 0 }}>
                                                    <tr>
                                                        <td colSpan={8} style="text-align : center">{TextResources.Invoices.DocumentWizardNoRowAvailable}</td>
                                                    </tr>
                                                </ko-bind>
                                                <ko-bind data-bind={{ foreach : { data: step.Rows, as: 'outputRow' }}}>
                                                    <tr>
                                                        <td data-bind={{text: outputRow.Descrizione}}></td>
                                                        <td class="text-right amount-col" data-bind={{numberText: outputRow.Quantita}}></td>
                                                        <td class="unit-of-measure-col" data-bind={{text: outputRow.Udm, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: outputRow.PriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="discount-col" data-bind={{discountText: outputRow.Discounts, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right unit-price-col" data-bind={{moneyText: outputRow.NetUnitPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency, css: { 'hidden-col': step.ExpandedColumn() != 3 }}}></td>
                                                        <td class="text-right total-price-col" data-bind={{moneyText: outputRow.TotalPriceInDocumentCurrency, currencySymbol: step.DocumentCurrencySymbol, documentCurrency: step.DocumentCurrency}}></td>
                                                        <td class="text-center action-col" data-bind={{css: { 'hidden-col': step.ExpandedColumn() != 3 }}}>
                                                            <a href="#" class="btn btn-xs red" data-bind={{click: step.removeRow.bind(step, outputRow)}}>
                                                                <i class="fa fa-trash-o"></i>
                                                                {TextResources.Invoices.DocumentWizardRemove}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        , this, "step");
    }
}

class Estimate extends BaseDocumentForWizard<EstimateRow>
{
    EstimateStateId: ko.Observable<number> = ko.observable();
    States: ko.ObservableArray<IEstimateState> = ko.observableArray([]);

    @LazyImportSettingManager(ProlifeSdk.EstimateStates)
    private estimatesStateManager: IEstimateStates;

    @LazyImport(nameof<IEstimatesService>())
    private estimatesService: IEstimatesService;

    private rowsLoaded  = false;
    private initializing = true;

    constructor(private estimateModel : IEstimateForWizard, private rowsStorage : EstimatesDataSource, private previouslyImportedRows : IDocumentBuilderDocumentOriginatingRows[])
    {
        super(estimateModel, ProlifeSdk.EstimateEntityTypeCode);

        this.States(this.estimatesStateManager.getEstimateStates(false));
        this.EstimateStateId(estimateModel.EstimateStateId);

        this.EstimateStateId.subscribe((stateId: number) => {
            this.applyCurrentState();
        });

        this.initializing = false;
    }

    public getId() {
        return this.estimateModel.Id;
    }

    public SwitchSelection()
    {
        this.IsSelected(!this.IsSelected());
        if(this.IsSelected())
        {
            this.loadRows().then(() => { this.rowsStorage.SelectedDocuments.push(this); });
        }
        else
            this.rowsStorage.SelectedDocuments.remove(this);
    }

    public setState(stateId: number, applyState = false): void {
        this.initializing = !applyState;

        this.EstimateStateId(stateId);

        this.initializing = false;
    }

    public async applyCurrentState(): Promise<void> {
        if (this.initializing)
            return;

        const stateId = this.EstimateStateId();
        const estimateId = this.estimateModel.Id;

        await this.documentsService.SetDocumentsState(stateId, [estimateId]);
    }

    public override canBeImported(): boolean {
        let state = this.estimatesStateManager.getEstimateStateById(this.EstimateStateId());
        state = state || { Stato: DocumentLogicalStatus.Draft } as IEstimateState;

        return this.rowsStorage.getDocumentType() == ProlifeSdk.EstimateEntityTypeCode
            || state.Stato === DocumentLogicalStatus.Accepted; 
    }

    public loadRows()  : Promise<any>
    {
        if(this.rowsLoaded)
            return Promise.resolve();

        return this.estimatesService.getDocumentRowsById(this.estimateModel.Id)
            .then(this.onRowsLoaded.bind(this))
            .catch(() => this.rowsLoaded = false);
    }

    private onRowsLoaded(rows : IEstimateRow[])
    {
        this.Rows(rows.map(this.createViewModelFor.bind(this)));
        this.rowsLoaded = true;
    }

    private createViewModelFor(row : IEstimateRow) : EstimateRow
    {
        return new EstimateRow(row, this.rowsStorage, this.previouslyImportedRows, this.estimateModel);
    }

    public doImportSelectedRows(result  = true)
    {
        let someRowsAlreadyImported  = false;

        if (!result)
            return someRowsAlreadyImported;

        if (!this.canBeImported())
            return true;

        const selectedRows = this.Rows().filter((r : EstimateRow) => { return r.Selected(); });
        const rowsToImport = selectedRows.filter((r : EstimateRow) => !r.wasPreviouslyImportedInThisDocument() && !r.wasImportedDuringThisWizard());
        someRowsAlreadyImported = rowsToImport.length != selectedRows.length;

        if (this.rowsStorage.ShowEstimateReferenceRow() && rowsToImport.length > 0) {
            const docRefRow: IEstimateRowForDocuments = this.getDocumentReferenceCommentRow();
            if (this.rowsStorage.Rows().filter((r) => r.Descrizione == docRefRow.Descrizione).length == 0)
                this.rowsStorage.Rows.push(docRefRow);
        }

        if (this.rowsStorage.ShowExternalReferencesRow()) {
            const extRefRow: IEstimateRowForDocuments = this.getExternalReferencesCommentRow();
            if (this.rowsStorage.Rows().filter((r) => r.Descrizione == extRefRow.Descrizione).length == 0)
            this.rowsStorage.Rows.push(extRefRow);
        }

        rowsToImport.forEach((r : EstimateRow) => {
            const row = r.createImportRow();

            const sourceDocumentCurrency = this.DocumentCurrency();

            if (this.rowsStorage.ApplyCurrenciesExchangeValues(row, sourceDocumentCurrency))
                this.rowsStorage.Rows.push(row);
        });

        return someRowsAlreadyImported;
    }

    private getExternalReferencesCommentRow(): ICustomerOrderRowForDocuments {
        let refs = "";

        if (this.estimateModel.ReferenceNumber)
            refs += String.format(TextResources.Warehouse.ExternalReferencesForCommentRowRefNumber, this.estimateModel.ReferenceNumber.toString());

        if (this.estimateModel.ReferenceDate) {
            if (refs)
                refs += " - ";

            refs += String.format(TextResources.Warehouse.ExternalReferencesForCommentRowRefDate, moment(this.estimateModel.ReferenceDate).format("L"));
        }

        if (this.estimateModel.ExternalReference) {
            if (refs)
                refs += " - ";

            refs += String.format(TextResources.Warehouse.ExternalReferencesForCommentRowExtRef, this.estimateModel.ExternalReference);
        }

        const row = this.getDocumentReferenceCommentRow();
        row.Descrizione = TextResources.Warehouse.ExternalReferencesForCommentRowIncipit + " " + refs;
        return row;
    }

    private getDocumentReferenceCommentRow() : IEstimateRowForDocuments
    {
        return {
            Descrizione: String.format(TextResources.Warehouse.ReferenceToEstimateForCommentRow, this.estimateModel.Number, moment(this.estimateModel.Date).format("L")),
            Quantita: 0,
            Importo: 0,
            PriceInDocumentCurrency: 0,
            TotaleRiga: 0,
            TotalPriceInDocumentCurrency: 0,
            NetUnitPrice: 0,
            NetUnitPriceInDocumentCurrency: 0,
            Udm : null,
            Discounts : null,
            Rows: []
        };
    }
}

class EstimateRow implements IBaseDocumentRowForWizard
{
    //@LazyImport(nameof<IDiscountsService>())
    //private discountsService : IDiscountsService;

    Selected : ko.Observable<boolean> = ko.observable(true);
    RowNumber : ko.Observable<number> = ko.observable();
    Description : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Discounts : ko.Observable<string> = ko.observable();
    Udm : ko.Observable<string> = ko.observable();
    ManuallyClosed: ko.Observable<boolean> = ko.observable();
    ClosedAmount: ko.Observable<number> = ko.observable();

    AlreadyImportedInThisDocument : ko.Observable<boolean> = ko.observable(false);

    constructor(public row : IEstimateRow, private rowsStorage : EstimatesDataSource, private destinationDocumentRowsReferences : IDocumentBuilderDocumentOriginatingRows[], estimate : IEstimateForWizard)
    {
        const absAmount = Math.abs(row.Quantita);
        const absClosed = Math.abs(row.ClosedAmount);
        let amount = 0;

        if(absClosed > absAmount)
            amount = row.Quantita;
        else
            amount = row.Quantita - row.ClosedAmount;

        this.RowNumber(row.IndiceRiga);
        this.Description(row.Descrizione);
        this.Amount(amount);
        this.UnitPrice(row.PriceInDocumentCurrency); // this.UnitPrice(row.Importo);

        /*var estimateDiscount = estimate.Discount || "";
        var percentage = this.discountsService.calculateDiscount(estimateDiscount);
        var finalDiscount = ((row.Discounts || "") + " " + (percentage < 1 ? estimateDiscount : "")).trim();*/

        this.Total((amount) * (row.NetUnitPriceInDocumentCurrency || 0)); // this.Total((amount) * (row.NetUnitPrice || 0));
        this.Discounts(row.Discounts/*finalDiscount*/);
        this.Udm(row.Udm);
        this.NetUnitPrice(this.row.NetUnitPriceInDocumentCurrency || 0); // this.NetUnitPrice(this.row.NetUnitPrice || 0);
        this.ManuallyClosed(this.row.ManuallyClosed);
        this.ClosedAmount(this.row.ClosedAmount);

        this.AlreadyImportedInThisDocument(this.wasPreviouslyImportedInThisDocument());
    }

    public setAmountAndUpdateTotal(amount: number): void {
        this.Amount(amount);
        this.Total((amount) * (this.row.NetUnitPriceInDocumentCurrency || 0));
    }

    public wasPreviouslyImportedInThisDocument() : boolean {
        return this.destinationDocumentRowsReferences
            .filter((ref : IRefDocumentRow) => ref.SourceEntityKeyId == this.row.IdRigaPreventivo && ref.SourceEntityType == ProlifeSdk.EstimateEntityTypeCode)
            .length > 0;
    }

    public wasAlreadyImportedInADocument() : boolean {
        const sign: number = this.row.Quantita < 0 ? -1 : 1;
        return this.row.ReferencingRows.length > 0 && ((this.row.Quantita - this.row.ClosedAmount) * sign <= 0);
    }

    public wasImportedDuringThisWizard() : boolean {
        return this.rowsStorage.Rows()
            .filter((r : IEstimateRowForDocuments) => r.Rows.filter((er : IEstimateRow) => er.IdRigaPreventivo == this.row.IdRigaPreventivo && er.EntityType == this.row.EntityType).length > 0)
            .length > 0
    }

    public createImportRow() : IEstimateRowForDocuments
    {
        const referencedRow: IEstimateRow = $.extend(true, {}, this.row);

        referencedRow.Quantita = this.Amount();
        referencedRow.TotaleRiga = this.Total(); // Viene convertito in Euro dal preventivo
        referencedRow.NetUnitPrice = this.NetUnitPrice(); // Viene convertito in Euro dal preventivo
        referencedRow.TotalPriceInDocumentCurrency = this.Total();
        referencedRow.NetUnitPriceInDocumentCurrency = this.NetUnitPrice();

        return {
            Descrizione: this.Description(),
            Quantita: this.Amount(),
            Importo: this.UnitPrice(), // Viene convertito in Euro dal preventivo
            PriceInDocumentCurrency: this.UnitPrice(), // Viene convertito in valuta documento dal preventivo
            TotaleRiga: this.Total(), // Viene convertito in Euro dal preventivo
            TotalPriceInDocumentCurrency: this.Total(), // Viene convertito in valuta documento dal preventivo
            NetUnitPrice: this.NetUnitPrice(), // Viene convertito in Euro dal preventivo
            NetUnitPriceInDocumentCurrency: this.NetUnitPrice(), // Viene convertito in valuta documento dal preventivo
            Discounts: this.Discounts(),
            Udm: this.Udm(),
            OffsetId: this.row.OffsetId,
            OffsetCode: this.row.OffsetCode,

            Rows: [referencedRow]
        };
    }
}
