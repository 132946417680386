import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { FunctionPointComputationApplication } from "./FunctionPointComputation/FunctionPointComputationApplication";
import { FunctionPointProjectDialogManagerViewModel } from "./FunctionPointComputation/Dialogs/FunctionPointProjectDialogManagerViewModel";
import { FunctionPointRecordDialogManagerViewModel } from "./FunctionPointComputation/Dialogs/FunctionPointRecordDialogManagerViewModel";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IEditingDialog } from "../ProlifeSdk/interfaces/functionpointcomputation/IEditingDialog";
import { IFunctionPointComputationService, IInfrastructure, IInfrastructureEnvelope, IInfrastructureFeatures, IInfrastructureRequirement, IInfrastructureRequirementEnvelope, IInfrastructureStatement, IInfrastructureStatementEnvelope } from "../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointComputationService";
import { IService } from "../Core/interfaces/IService";
import { IFunctionPointProject } from "../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProject";
import { IFunctionPointProjectViewModel } from "../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointProjectViewModel";
import { IFunctionPointRecord } from "../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecord";
import { IFunctionPointRecordViewModel } from "../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecordViewModel";
import { IJobOrderForTaskBoard } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { Infrastructure } from "./FunctionPointComputation/Infrastructure";
import { LazyImport, Service } from "../Core/DependencyInjection";
@Service(nameof<IFunctionPointComputationService>())
export class FunctionPointComputationService implements IFunctionPointComputationService
{
    private application: FunctionPointComputationApplication;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) 
    {

    }
    
    InitializeService() {
        this.application = new FunctionPointComputationApplication();
    }

    getServiceType(): string {
        return ProlifeSdk.FunctionPointComputationServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public createOrUpdateProject(project: IFunctionPointProject): Promise<IFunctionPointProjectViewModel> {
        return this.ajaxService.Post<IFunctionPointProjectViewModel>("FunctionPointComputation-Api/FunctionPointProjects", "SaveOrUpdateProject", {
            methodData: {
                Project: project
            }
        });
    }

    public deleteProject(id: number): Promise<void> {
        return this.ajaxService.Post<void>("FunctionPointComputation-Api/FunctionPointProjects", "DeleteProject", {
            methodData: {
                Id: id
            }
        });
    }

    public getProjects(): Promise<IFunctionPointProject[]> {
        return this.ajaxService.Post<IFunctionPointProject[]>("FunctionPointComputation-Api/FunctionPointProjects", "GetProjects", {
            methodData: {}
        });
    }
    
    public createOrUpdateRecord(record: IFunctionPointRecord): Promise<IFunctionPointRecordViewModel> {
        return this.ajaxService.Post<IFunctionPointRecordViewModel>("FunctionPointComputation-Api/FunctionPointRecords", "SaveOrUpdateRecord", {
            methodData: {
                Record: record
            }
        });
    }

    public deleteRecord(id: number): Promise<void> {
        return this.ajaxService.Post<void>("FunctionPointComputation-Api/FunctionPointRecords", "DeleteRecord", {
            methodData: {
                Id: id
            }
        });
    }

    public getRecords(): Promise<IFunctionPointRecord[]> {
        return this.ajaxService.Post<IFunctionPointRecord[]>("FunctionPointComputation-Api/FunctionPointRecords", "GetRecords", {
            methodData: {}
        });
    }

    public openProjectEditor(project?: IFunctionPointProjectViewModel) : IEditingDialog {
        var editor = new FunctionPointProjectDialogManagerViewModel(this.serviceLocator, project);
        return editor;
    }

    public openRecordEditor(record?: IFunctionPointRecordViewModel) : IEditingDialog {
        var editor = new FunctionPointRecordDialogManagerViewModel(this.serviceLocator, record);
        return editor;
    }

    private infrastructures: IInfrastructure[] = [];
    public async searchInfrastructures() : Promise<IInfrastructureEnvelope[]> {
        return await this.infrastructures.slice();
    }

    private statements: IInfrastructureStatement[] = [];
    public async getStatements(infrastructureId: number) : Promise<IInfrastructureStatementEnvelope[]> {
        return await this.statements.filter(s => s.FKInfrastructure === infrastructureId);
    }

    private requirements: IInfrastructureRequirementEnvelope[] = [];
    public async getRequirements(infrastructureId: number) : Promise<IInfrastructureRequirementEnvelope[]> {
        return await this.requirements.filter(s => s.FKInfrastructure === infrastructureId);
    }

    async getInfrastructureEnvelopeById(infrastructureId: number): Promise<IInfrastructureEnvelope> {
        return await this.infrastructures.firstOrDefault(i => i.Id === infrastructureId);
    }

    async getStatementJobOrders(Id: number): Promise<IJobOrderForTaskBoard[]> {
        return await this.statements.firstOrDefault(s => s.Id === Id).JobOrders;
    }

    async getInfrastructureById(infrastructureId: number) : Promise<IInfrastructure> {
        const original = this.infrastructures.firstOrDefault(i => i.Id === infrastructureId);
        if(original == null) {
            return null;
        }
        const newInfrastructrure = Object.assign({}, original);
        newInfrastructrure.Requirements = (original.Requirements == null)? [] : original.Requirements.map(r => this.cloneRequirement(r));
        newInfrastructrure.Statements = original.Statements.map(s => this.cloneStatement(s));
        newInfrastructrure.Features = original.Features.map(f => this.cloneFunction(f));
        
        return newInfrastructrure;
    }

    async saveInfrastructure(infrastructure: Pick<IInfrastructureEnvelope, "Id" | "Title" | "Description">): Promise<IInfrastructureEnvelope> {
        const id = infrastructure.Id <= 0 ? (this.infrastructures.max(i => i.Id) || 0) + 1 : infrastructure.Id;
        const existing = this.infrastructures.firstOrDefault(i => i.Id === infrastructure.Id);
        if(!existing) {
            //Creo nuova
            this.infrastructures.push({
                ...infrastructure,
                Id: id,
                CreationDate: new Date(),
                CreationUserId: 15,
                Features: [],
                Requirements: [],
                Statements: []
            })
        } else {
            //Aggiorno
            existing.Title = infrastructure.Title;
            existing.Description = infrastructure.Description;
        }

        return await this.getInfrastructureById(id);
    }

    private cloneRequirement(requirement: IInfrastructureRequirement) : IInfrastructureRequirement {
        return {
            ...requirement
        }
    }

    private cloneStatement(statement: IInfrastructureStatement) : IInfrastructureStatement {
        return {
            ...statement
        }
    }

    private cloneFunction(func: IInfrastructureFeatures) : IInfrastructureFeatures {
        return {
            ...func
        }
    }
}

export default function Create(serviceLocator: IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IFunctionPointComputationService>());
}