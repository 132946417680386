import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IHoursApproval_Type, IMonthlyHour, IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IGetResourceHoursApprovalGroupsRequest, IHoursApprovalGroup, IResourceGroupMembership, IUsersService } from "../../../../Users/UsersService";
import { Layout } from "../../../../Components/Layouts";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { CheckBox } from "../../../../Components/Checkbox";
import { RichTextInput } from "../../../../Components/RichTextInput";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { IconSwitcher } from "../../../../Components/IconSwitcher";
import TsxForEach from "../../../../Components/ForEach";
import { HoursApprovalState } from "../../enums/HoursApprovalState";

const styleSheet = jss.createStyleSheet({
    hoursApprovalSummary: {
        "& .total-hours": {
            paddingRight: "5px",
            paddingTop: "10px",
            fontSize: "16pt"
        },

        "& .approval-group": {
            padding: "0px 5px"
        },

        "& .approval-group-checkbox": {
            width: "30px"
        },

        "& .actions": {
            width: "30px"
        },

        "& .selected": {
            fontWeight: "bold"
        },
        "& .not-selected": {
            color: "#aaa"
        }
    }
});
const { classes } = styleSheet.attach();

type ResourceGroup = {
    IsSelectedForApprove: ko.Computed<boolean>;
} & IResourceGroupMembership;

class ResourceHours {
    get Id(): number {
        return this.id;
    }

    Name: ko.Observable<string> = ko.observable();
    Groups: ko.ObservableArray<ResourceGroup> = ko.observableArray([]);
    Hours: ko.ObservableArray<IMonthlyHour> = ko.observableArray([]);

    HoursTotal: ko.Computed<number>;

    constructor(private id: number, name: string) {
        this.Name(name);

        this.HoursTotal = ko.computed(() => {
            return this.Hours().sum(h => h.Hours);
        });
    }

    public isInAnyGroup(selectedGroups: number[]): boolean {
        return this.Groups().filter(g => selectedGroups.indexOf(g.GroupId) >= 0).length > 0;
    }
}

class ApprovalGroup {
    get Id(): number {
        return this.group.GroupId;
    }

    Name: ko.Observable<string> = ko.observable();
    Role: ko.Observable<string> = ko.observable();
    Notes: ko.Observable<string> = ko.observable();

    Selected: ko.Observable<boolean> = ko.observable(true);
    ShowNotes: ko.Observable<boolean> = ko.observable(false);

    constructor(private group: IHoursApprovalGroup) {
        this.Name(this.group.GroupName);
        this.Role(this.group.WorkedHoursApprovalRole);
    }

    public toggleNotes(): void {
        this.ShowNotes(!this.ShowNotes());
    }
}

type HoursApprovalSummaryProps = {
    hours: IMonthlyHour[];
}

export class HoursApprovalSummary extends DialogComponentBase {
    static defaultProps: Partial<HoursApprovalSummaryProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IUsersService>())
    private usersService: IUsersService;
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;
    
    public ResourcesHours: ko.ObservableArray<ResourceHours> = ko.observableArray([]);
    public ApprovalGroups: ko.ObservableArray<ApprovalGroup> = ko.observableArray([]);

    public FilteredResourcesHours: ko.Computed<ResourceHours[]>;

    public HoursTotal: ko.Computed<number>;

    constructor(private props : HoursApprovalSummaryProps) {
        super({ className: "medium", actionLabel: TextResources.WorkedHours.HoursApprovalSummaryApproveButton });

        this.title(TextResources.WorkedHours.HoursApprovalSummaryTitle);

        this.FilteredResourcesHours = ko.computed(() => {
            return this.ResourcesHours().filter(r => !!r.Groups().firstOrDefault(g => g.IsSelectedForApprove()));
        }).extend({ trackArrayChanges: true });

        this.HoursTotal = ko.computed(() => {
            let sum = 0;

            const resources = this.FilteredResourcesHours();
            
            for (const resource of resources) {
                sum += resource.HoursTotal();
            }

            return sum;
        });
    }

    componentDidMount() {
        this.load();
    }

    private async load(): Promise<void> {
        try {
            const approvalGroupsRequest: IGetResourceHoursApprovalGroupsRequest = {
                textFilter: ""
            };

            const [approvalGroups, membreships] = await Promise.all<IHoursApprovalGroup[], IResourceGroupMembership[]>([
                this.usersService.GetResourceHoursApprovalGroups(approvalGroupsRequest),
                this.usersService.GetUsersGroupsMembership(this.props.hours.map(h => h.ResourceId).distinct())
            ]);

            const resourcesMap: { [id: number]: ResourceHours } = {};
            const resources = [];

            for (const hour of this.props.hours) {
                let resource = resourcesMap[hour.ResourceId];
                if (!resource) {
                    resourcesMap[hour.ResourceId] = new ResourceHours(hour.ResourceId, hour.ResourceName);
                    resource = resourcesMap[hour.ResourceId];

                    resources.push(resource);
                    const resourceGroups = membreships.filter(m => m.ResourceId === hour.ResourceId && !!approvalGroups.firstOrDefault(g => g.GroupId === m.GroupId));
                    resource.Groups(resourceGroups.map(g => (
                        {
                            ...g,
                            IsSelectedForApprove: ko.computed(() => !!this.ApprovalGroups().firstOrDefault(ag => ag.Selected() && ag.Id === g.GroupId))
                        }
                    )));
                }

                resource.Hours.push(hour);
            }

            const groups = [];
            for (const group of approvalGroups) {
                if (membreships.filter(m => m.GroupId === group.GroupId).length !== 0) {
                    groups.push(new ApprovalGroup(group));
                }
            }

            this.ResourcesHours(resources);
            this.ApprovalGroups(groups);
        } catch(e) {
            console.log(e);
        }
    }

    async approve() : Promise<void> {
        const confirmed = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.WorkedHours.ApprovalConfirmation, 
            ProlifeSdk.TextResources.WorkedHours.ApprovalConfirmationCancel,
            ProlifeSdk.TextResources.WorkedHours.ApprovalConfirmationOk
        );

        if (!confirmed)
            return;

        let hours: IHoursApproval_Type[] = [];

        for (const group of this.ApprovalGroups().filter(g => g.Selected())) {
            const groupResources = this.ResourcesHours().filter(r => !!r.Groups().firstOrDefault(g => g.GroupId === group.Id));
            for (const resource of groupResources) {
                const resourceHours = resource.Hours().map(h => ({
                    HoursId: h.HoursId,
                    ApprovalGroupId: group.Id,
                    ApprovalNotes: group.Notes(),
                    ApprovalState: HoursApprovalState.Approved
                }));

                hours = hours.concat(resourceHours);
            }
        }

        try {
            await this.workedHoursService.SetApprovalState(hours, null, null);
        } catch(e) {
            console.log(e);
        }

        this.infoToastService.Success(ProlifeSdk.TextResources.WorkedHours.ApprovalSuccessful);
    }
    
    action() {
        this.approve()
            .then(() => {
                this.modal.close();
            })
    }
    
    renderBody() {
        const approvalSummary = this;
        const resource: IDataSourceModel<number, ResourceHours> = null;
        const approvalGroup: IDataSourceModel<number, ApprovalGroup> = null;

        return ComponentUtils.bindTo((
            <Layout.Grid rows={["min-content", "min-content", "1fr"]} columns={["1fr"]} className={classes.hoursApprovalSummary}>
                <Layout.Grid.Cell row={1} column={1}>
                    <Table
                        dataSource={{ array: this.ApprovalGroups, factory: Table.defaultFactory(g => ({ id: g.Id, title: g.Name() })) }}
                        fixedLayout
                        editable
                        compact
                        rowAs="approvalGroup"
                    >
                        <Column>
                            <ColumnHeader>
                                {() => <span>{TextResources.WorkedHours.HoursApprovalSummaryApprovalGroupName}</span>}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ApprovalGroup>) => (
                                    <div className="flex-container approval-group">
                                        <div className="approval-group-checkbox" data-bind={{ visible: approvalSummary.ApprovalGroups().length > 1 }}>
                                            <CheckBox checked={item.Data.model.Selected} />
                                        </div>
                                        <div className="flex-fill">
                                            {item.Data.model.Name()}
                                        </div>
                                    </div>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column>
                            <ColumnHeader>
                                {() => <span>{TextResources.WorkedHours.HoursApprovalSummaryApprovalGroupRole}</span>}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ApprovalGroup>) => <span>{item.Data.model.Role()}</span>}
                            </ColumnBody>
                        </Column>
                        <Column className="actions">
                            <ColumnBody>
                                {(item: ITableItem<ApprovalGroup>) => (
                                    <span data-bind={{ click: approvalGroup.model.toggleNotes.bind(approvalGroup.model), visible: approvalSummary.ApprovalGroups().length > 1 }}>
                                        <IconSwitcher isTrue="fa-chevron-up" isFalse="fa-chevron-down" trigger={item.Data.model.ShowNotes} />
                                    </span>
                                )}
                            </ColumnBody>
                        </Column>
                        <SecondaryRow visible={() => "approvalGroup.model.ShowNotes() || approvalSummary.ApprovalGroups().length === 1"}>
                            {(item: ITableItem<ApprovalGroup>) => (
                                <td colSpan={3}>
                                    <RichTextInput
                                        value={item.Data.model.Notes}
                                        inline
                                        autoGrow
                                        simple
                                        placeholder={TextResources.WorkedHours.HoursApprovalSummaryApprovalGroupNotesPlaceholder}
                                    />
                                </td>
                            )}
                        </SecondaryRow>
                    </Table>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1}>
                    <div className="flex-container flex-fill">
                        <div className="total-hours flex-fill bold text-right">{TextResources.WorkedHours.HoursApprovalSummaryTotalHours}&nbsp;<span data-bind={{ numberText: approvalSummary.HoursTotal }}></span></div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={3} column={1}>
                    <Table
                        dataSource={{ array: this.FilteredResourcesHours, factory: Table.defaultFactory((r) => ({ id: r.Id, title: r.Name() })) }}
                        fixedLayout
                        systemScrollable
                        compact
                        rowAs="resource"
                    >
                        <Column>
                            <ColumnHeader>
                                {() => <span>{TextResources.WorkedHours.HoursApprovalSummaryResource}</span>}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ResourceHours>) => <span>{item.Data.title}</span>}
                            </ColumnBody>
                        </Column>
                        <Column>
                            <ColumnHeader>
                                {() => <span>{TextResources.WorkedHours.HoursApprovalSummaryResourceGroups}</span>}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<ResourceHours>) => (
                                    <TsxForEach data={item.Data.model.Groups} as="group">
                                        {(group) => (
                                            <div className="flex-container">
                                                <span data-bind={{ css: { "not-selected": !group.IsSelectedForApprove(), "selected": group.IsSelectedForApprove } }}>{group.GroupName}</span>
                                            </div>
                                        )}
                                    </TsxForEach>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column className="text-right">
                            <ColumnHeader>
                                {() => <span>{TextResources.WorkedHours.HoursApprovalSummaryResourceTotalHours}</span>}
                            </ColumnHeader>
                            <ColumnBody>
                                {() => <span className="bold" data-bind={{ numberText: resource.model.HoursTotal }}></span>}
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.Grid.Cell>
            </Layout.Grid>
        ), this, "approvalSummary");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(HoursApprovalSummary);
}