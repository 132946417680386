import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { Feature } from "./Feature";
import jss from "jss";
import { BackButton } from "../../../Components/BackButton";
import { Layout } from "../../../Components/Layouts";
import { Portlet } from "../../../Components/Portlet";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { FunctionPointComputationFeatureEditor } from "./FunctionPointComputationFeatureEditor";
import { FunctionPointComputationFeaturesList } from "./FunctionPointComputationFeaturesList";
import { reloadNow } from "../../../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
    featureEditor: {     
        "& > div":{
            padding: "15px",
        }
    }
}).attach();

const {classes} = styleSheet;

type FeatureEditorProps = {
    onBack:()=> void;

    infrastructureId: number;
}
export function FeatureEditor(props: FeatureEditorProps) {
    const C = require("./FeatureEditor")._FeatureEditor as typeof _FeatureEditor;
    return <C {...props} />;
}

export class _FeatureEditor {
    static defaultProps: Partial<FeatureEditorProps> = {
    }

    features : ko.ObservableArray<Feature> = ko.observableArray();
    feature: ko.Observable<Feature> = ko.observable();
        
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private props : FeatureEditorProps) {
        this.features.push(new Feature(1,"fun1","fun"));
        this.features.push(new Feature(2,"fun2","fun"));
        this.features.push(new Feature(3,"fun3","fun"));
        this.feature(new Feature(-2));
    }

    onBackButtonClick(){
        this.props.onBack();       
    }

    async addFeature(f: Feature){
        let existingFeature = this.features().findIndex((ft)=> ft.Id === f.Id);
        if(existingFeature >= 0){
            var oldFeature = this.features()[existingFeature];
            this.features.replace(oldFeature,f);
        }
        else
        {
            this.features.push(f);
        }       
    }

    onNewFeature(){        
        this.feature(new Feature(-1));
    }

    async onRemoveFeature(): Promise<void>{
        let existingFeature = this.features().find(ft=> ft.featureId === this.feature().featureId);
        let confirm: boolean = await this.dialogsService.ConfirmAsync("Desideri eliminare la funzione: " + existingFeature.featureId + " ?", "Annulla", "Conferma");
        if(existingFeature && confirm)
        {
            this.features.remove(existingFeature);
            this.infoToastService.Success("Funzione eliminata con successo");
        }
            
    }

    render() {
       
        let feature: IDataSourceModel<number,Feature>;
        return  <Layout.Grid className={classes.featureEditor} columns={["1fr","2fr"]} rows={["auto","1fr"]}>
                    <Layout.Grid.Cell column={"1/3"} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <BackButton style={{marginRight: "20px"}} onClick={()=> this.onBackButtonClick()}/>
                                <Portlet.Header.Default title={"Gestione Funzionalità"} />                                           
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>             
                    <Layout.Grid.Cell column={1} row={2}>
                        <FunctionPointComputationFeaturesList features={this.features} onNew={this.onNewFeature.bind(this)} selectedFeature={this.feature} onFeatureSelected={f => this.feature(f)} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={2} >
                       <FunctionPointComputationFeatureEditor features={this.features} feature={this.feature} onSave={this.addFeature.bind(this)} onDelete={this.onRemoveFeature.bind(this)}/>
                    </Layout.Grid.Cell>                  
                </Layout.Grid>                    
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(FeatureEditor);
}