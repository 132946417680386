import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 15/06/2017
 * Time: 16:55
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { Answer } from "./Answer";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISurveyWizardAnswer, ISurveyWizardService, ISurveyWizardQuestion, ISurveyWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IAnswerForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerForWizard";
import { IAnswerFromWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IAnswerFromWizard";

export class AnswerForWizard extends Answer implements ISurveyWizardAnswer {
    public EnablePublicLabel: ko.Observable<string> = ko.observable();
    public GoToPublicLabel: ko.Observable<string> = ko.observable();
    public SectionEnablePublicLabel: ko.Observable<string> = ko.observable();
    public SectionGoToPublicLabel: ko.Observable<string> = ko.observable();

    public Checked: ko.Observable<boolean> = ko.observable();

    public TextValue: ko.Observable<string> = ko.observable();
    public DateValue: ko.Observable<Date> = ko.observable();
    public NumericValue: ko.Observable<number> = ko.observable();

    public IsExpanded: ko.Observable<boolean> = ko.observable(false);

    public IsUniqueAnswer: ko.Observable<boolean> = ko.observable();

    public HaveDateField: ko.Computed<boolean>;
    public HaveNumberField: ko.Computed<boolean>;
    public HaveGenericTextField: ko.Computed<boolean>;

    public DisableOpenField: ko.Computed<boolean>;

    public IsComplete: ko.Computed<boolean>;

    public Enable: ko.Computed<string>;
    public GoTo: ko.Computed<string>;

    private questionType: number;

    private surveyWizardService: ISurveyWizardService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, answer: IAnswerForWizard, isUniqueAnswer: boolean, private question: ISurveyWizardQuestion, private wizard: ISurveyWizard) {
        super(answer);

        this.EnablePublicLabel(answer.EnablePublicLabel);
        this.GoToPublicLabel(answer.GoToPublicLabel);
        this.SectionEnablePublicLabel(answer.SectionEnablePublicLabel);
        this.SectionGoToPublicLabel(answer.SectionGoToPublicLabel);

        this.surveyWizardService = <ISurveyWizardService> this.serviceLocator.findService(ProlifeSdk.SurveyWizardServiceType);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Checked(false);
        if (!!answer.HaveResponse) {
            this.Checked(true);
            this.TextValue(answer.OpenTextValue);
            this.DateValue(answer.OpenDateTimeOffsetValue);
            this.NumericValue(answer.OpenNumericValue);
        }

        this.IsUniqueAnswer(isUniqueAnswer && this.IsOpen());

        this.DisableOpenField = ko.computed(() => {
            return !this.Checked() && !this.IsUniqueAnswer();
        });

        this.questionType = question.TypeId();

        this.HaveDateField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerDateField;
        });

        this.HaveNumberField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerNumericField || this.AnswerTypeId() == ProlifeSdk.AnswerMoneyField;
        });

        this.HaveGenericTextField = ko.computed(() => {
            return this.AnswerTypeId() == ProlifeSdk.AnswerTextField;
        });

        if (this.IsUniqueAnswer()) {
            if (this.HaveDateField())
                this.DateValue.subscribe((value: Date) => {
                    this.Checked(!!value);
                });
            if (this.HaveNumberField())
                this.NumericValue.subscribe((value: number) => {
                    this.Checked(!!value);
                });
            if (this.HaveGenericTextField())
                this.TextValue.subscribe((value: string) => {
                    this.Checked(!!value);
                });
        }

        this.Enable = ko.computed(() => {
            return this.EnablePublicLabel() || this.SectionEnablePublicLabel() || null;
        });

        this.GoTo = ko.computed(() => {
            return this.GoToPublicLabel() || this.SectionGoToPublicLabel() || null;
        });

        this.Checked.subscribe((value: boolean) => {
            if (!value) {
                this.wizard.checkForDisablingQuestionWithAnswer(this);
            }
        });

        this.IsComplete = ko.computed(() => {
            return !this.IsOpen() ||
                (this.IsOpen() && !!this.DateValue() || !!this.TextValue() || !!this.NumericValue());
        });
    }

    public Select(): void {
        this.Checked(true);
        this.question.UncheckSingleAnswers(this.Id);
    }

    public reset(campaignId: number, peopleId: number): void {
        if (this.wizard.Mode() == ProlifeSdk.WizardExecutionMode) {
            this.surveyWizardService.deleteQuestionnaireResult(campaignId, peopleId, this.Id)
                .then(() => {
                    this.TextValue(null);
                    this.DateValue(null);
                    this.NumericValue(null);
                    this.Checked(false);
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.ResetAnswerError);
                });
            return;
        }
        this.TextValue(null);
        this.DateValue(null);
        this.NumericValue(null);
        this.Checked(false);
    }

    public ToJSON() : IAnswerFromWizard {
        var answer: IAnswerFromWizard = <IAnswerFromWizard>super.ToJSON();
        answer.TextValue = this.TextValue();
        answer.NumericValue = this.NumericValue();
        answer.DateValue = this.DateValue();
        return answer;
    }
}