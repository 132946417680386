import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../../ProlifeSdk/ProlifeSdk";
import { TemplateProvider } from "./TemplateProvider";
import { TaskProvider } from "./TaskProvider";
import { ITodoListTemplateForList, ITodoListTemplateTask } from "../../../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IServiceLocator } from "../../../../../../../Core/interfaces/IServiceLocator";
import { IObjectChangesInfo } from "../../../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { INavigationMenuProvider } from "../../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../../../../../Core/Deferred";

export class TemplateWithChildrenProvider extends TemplateProvider {
    private itemsAreLoaded: boolean = false;

    constructor(serviceLocator: IServiceLocator, template: ITodoListTemplateForList) {
        super(serviceLocator, template);
        this.templateName = "template-with-children-item";
        this.SearchAllowed = true;
        this.IsLeaf = false;
    }

    GetSelectedTasksIds(): number[] {
        return this.Items()
            .filter((p) => {
                return p.IsSelected();
            })
            .map((p: any) => {
                return p.task.Id;
            });
    }

    SetSelectedTasks(ids: number[]): Promise<void> {
        this.Items().forEach((p: any) => {
            if (ids.indexOf(p.task.Id) > -1) this.navigator().selectProvider(p, false);
        });

        return Promise.resolve<void>(undefined);
    }

    search(textFilter: string): Promise<void> {
        if (!textFilter) {
            this.SearchedItems([]);
            return Promise.resolve<void>(undefined);
        }

        var def = this.itemsAreLoaded ? Promise.resolve() : this.refreshItems();

        return def.then(() => {
            var adaptedFilter: string = (textFilter || "").toUpperCase().trim();
            var filterResult = this.Items().filter((p) => {
                return p.Name.toUpperCase().indexOf(adaptedFilter) > -1;
            });
            this.SearchedItems(filterResult);
        });
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (!super.OnEntityHasBeenChanged(changesInfo, sendByMe)) {
            this.itemsAreLoaded = false;
            return false;
        }
        return true;
    }

    public RefreshData(t: ITodoListTemplateForList): Promise<void> {
        var def = new Deferred<void>();
        super
            .RefreshData(t)
            .then(() => {
                if (this.itemsAreLoaded) {
                    def.resolve();
                    return;
                }

                this.refreshItems()
                    .then(() => {
                        def.resolve();
                    })
                    .catch(() => {
                        def.reject([]);
                    });
            })
            .catch(() => {
                def.reject([]);
            });

        return def.promise();
    }

    refreshItems(): Promise<void> {
        var d = new Deferred<void>();

        this.todoListService.GetTemplatesTasks([], [], this.template.Id, null, -1, -1, -1).then((templates: ITodoListTemplateTask[]) => {
            var tasksItems = templates
                .filter((t: ITodoListTemplateTask) => {
                    return !t.Deleted;
                })
                .map((t: ITodoListTemplateTask) => {
                    var p: INavigationMenuProvider = new TaskProvider(this.serviceLocator, t);
                    p.setCurrentNavigator(this.navigator());
                    return p;
                });
            this.Items(tasksItems);
            this.itemsAreLoaded = true;
            d.resolve();
        });

        return d.promise();
    }

    open(item) {
        var def = this.itemsAreLoaded ? Promise.resolve() : this.refreshItems();

        def.then(() => {
            super.open(item);
        });
    }
}
