import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { WithholdingTaxTypesDataSource } from "../../../../DataSources/WithholdingTaxTypesDataSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IWitholdingTaxSettingsManager, IWithholdingTaxMapping } from "../../../../ProlifeSdk/interfaces/invoice/settings/IWitholdingTaxSettingsManager";

export class WithodingTaxAccountingSoftwareMappings
{
    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;

    Mappings : ko.ObservableArray<WitholdingTaxMapping> = ko.observableArray();

    constructor(private manager : IWitholdingTaxSettingsManager)
    {
        this.manager.GetAccountingSwMappings().then((mappings : IWithholdingTaxMapping[]) => {
            this.Mappings(mappings.map((m : IWithholdingTaxMapping) => { return new WitholdingTaxMapping(m);}));
        });
    }

    public Remove(m : WitholdingTaxMapping)
    {
        this.Mappings.remove(m);
    }

    public AddNew()
    {
        this.Mappings.push(new WitholdingTaxMapping());
    }

    private Validate()
    {
        var existsNotValid : boolean = this.Mappings().filter((m : WitholdingTaxMapping) => {
            var isNotValid : boolean = (m.AccountingSwCode() || "").trim().length == 0 ||
                this.Mappings().filter((m1 : WitholdingTaxMapping) => { return m1.HasSameValue(m); }).length > 0;
            return isNotValid;
        }).length > 0;

        if(existsNotValid)
            this.toastService.Warning(ProlifeSdk.TextResources.Invoices.InvalidWitholdingFields);

        return !existsNotValid;
    }

    public Save()
    {
        if(!this.Validate())
            return;

        this.manager.UpdateAccountingSwMappings(this.Mappings().map((m : WitholdingTaxMapping) => { return m.GetData(); }))
            .then(() => {
                this.toastService.Success(ProlifeSdk.TextResources.Invoices.SettingsSaved);
            });
    }
}

export class WitholdingTaxMapping
{
    public RateValue : ko.Observable<number> = ko.observable();
    public Type: ko.Observable<string> = ko.observable();
    public AccountingSwCode : ko.Observable<string> = ko.observable();
    
    public WithholdingTaxTypesDataSource: WithholdingTaxTypesDataSource;

    constructor(private mapping : IWithholdingTaxMapping = null)
    {
        this.WithholdingTaxTypesDataSource = new WithholdingTaxTypesDataSource();

        this.RateValue(mapping?.RateValue);
        this.Type(mapping?.Type);
        this.AccountingSwCode(mapping?.AccountingSwCode);
    }

    public HasSameValue(other : WitholdingTaxMapping)
    {
        if(other == this)
            return false;

        return (other.RateValue() || 0) == (this.RateValue() || 0);
    }

    public GetData()
    {
        var data : IWithholdingTaxMapping = this.mapping || <IWithholdingTaxMapping> {};
        data.RateValue = this.RateValue() || 0;
        data.Type = this.Type();
        data.AccountingSwCode = this.AccountingSwCode();
        return data;
    }
}

