import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { TreeGrid,TreeGridStringColumn, TreeGridColumn } from "../../../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { AdministrativeReportUtils } from "./AdministrativeReportUtils";
import { IJobOrderService, IJobOrderWorkHourCosts } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderEditor } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IEntityProviderService } from "../../../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { ISettingsService } from "../../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IAdministrativeReportPage } from "../../../../../interfaces/IAdministrativeReportPage";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWorkTimeCategory } from "../../../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { IControlsEntityProvider } from "../../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IFileRepositoryService } from "../../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IWorkTimeCategoriesSettingsManager } from "../../../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { Deferred } from "../../../../../../Core/Deferred";

export class WorkHoursCostsPage implements IAdministrativeReportPage {
    Title : ko.Observable<string> = ko.observable(TextResources.JobOrder.WorkedHoursCosts);
    TemplateUrl:string = "joborder/templates/joborderdetail/administrative-report";
    TemplateName:string = "workhour-costs-page";
    Table : TreeGrid = new TreeGrid();

    ExporterId : string = "JobOrder/JobOrderAdministativeReportWorkHourCostsExport";

    Resource : ko.Observable<number> = ko.observable();
    Role : ko.Observable<number> = ko.observable();
    Type : ko.Observable<number> = ko.observable();
    DateFrom : ko.Observable<Date> = ko.observable();
    DateTo : ko.Observable<Date> = ko.observable();
    HoursFrom : ko.Observable<number> = ko.observable();
    HoursTo : ko.Observable<number> = ko.observable();
    CostFrom : ko.Observable<number> = ko.observable();
    CostTo : ko.Observable<number> = ko.observable();
    OnlyToBeBilled : ko.Observable<boolean> = ko.observable();
    OnlyImported : ko.Observable<boolean> = ko.observable();
    OrderBy : ko.Observable<string> = ko.observable();
    OrderAscDesc : ko.Observable<string> = ko.observable();

    Roles : ko.ObservableArray<IUserCharacter> = ko.observableArray();
    Types : ko.ObservableArray<IWorkTimeCategory> = ko.observableArray();

    public humanResourcesSearchService : IControlsEntityProvider;

    private columnMapping : string[] = [
        'WorkflowName',
        'SourceDate',
        'ResourceName',
        'Role',
        'Type',
        'Hours',
        'Cost',
        'ToBeBilled',
        'ImportedInDoc'
    ];

    private expandCollapseCache : { [id: string] : boolean; } = {};

    private jobOrdersService : IJobOrderService;
    private fileRepositoryService : IFileRepositoryService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor) {
        this.jobOrdersService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.fileRepositoryService = <IFileRepositoryService> serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);

        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.humanResourcesSearchService = entityProviderService.getEntityProvider(ProlifeSdk.HumanResources).getControlsProvider();

        var userCharactedSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
        this.Roles(userCharactedSettingsManager.getUserCharacters());

        var workedHourTypeSettingsManager = <IWorkTimeCategoriesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType);
        this.Types(workedHourTypeSettingsManager.getAll(false));

        let today = moment();
        this.DateFrom(moment(today).add(-1, "month").startOf("month").toDate());
        this.DateTo(today.endOf("day").toDate());

        this.Table.ShowActions(false);
        this.Table.AddColumn(new TreeGridStringColumn("Piano di lavoro", value => value.WorkflowName === undefined ? '' : !value.WorkflowName ? ProlifeSdk.TextResources.ProlifeSdk.NotAvailable : value.WorkflowName, 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Data", value => !value.SourceDate ? '' : moment(value.SourceDate).format("L"), 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Risorsa", value => value.ResourceName, 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Ruolo", value => value.Role, 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Tipologia", value => value.Type, 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Ore", value => value.Hours, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00')).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Costo", value => value.Cost, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00 $')).Align('right'));
        this.Table.AddColumn(new TreeGridColumn("Fatturabile", value => AdministrativeReportUtils.billableAndBilledFormatter(value.ToBeBilled), 100).Align('center'));
        this.Table.AddColumn(new TreeGridColumn("Importato in Doc.", value => AdministrativeReportUtils.billableAndBilledFormatter(value.ImportedInDoc), 150).Align('center'));

        this.Table.SortingModeChanged.Add((column : TreeGridColumn, sortMode : number) => {
            var index = this.Table.Columns().indexOf(column);
            if(index < 0 || sortMode == 0) {
                this.OrderBy(null);
                this.OrderAscDesc(null);
            } else {
                this.OrderBy(this.columnMapping[index]);
                this.OrderAscDesc(sortMode == 1 ? 'ASC' : 'DESC');
            }

            this.load();
        });

        ko.utils.observableBetween(this.HoursFrom, this.HoursTo);
        ko.utils.observableBetween(this.CostFrom, this.CostTo);
        ko.utils.observableBetween(this.DateFrom, this.DateTo);
    }

    dispose() {
    }

    public Search() {
        this.load();
    }

    public getExcelExportData() : any | null {
        return {
            JobOrderId: this.editor.JobOrderId,
            Resource: this.Resource(),
            Role: this.Role(),
            Type: this.Type(),
            DateFrom : this.DateFrom(),
            DateTo : this.DateTo(),
            HoursFrom : this.HoursFrom(),
            HoursTo : this.HoursTo(),
            CostFrom : this.CostFrom(),
            CostTo : this.CostTo(),
            OnlyToBeBilled : this.OnlyToBeBilled(),
            OnlyImported : this.OnlyImported(),
            OrderBy: this.OrderBy(),
            OrderAscDesc: this.OrderAscDesc()
        };
    }

    /*private OpenColumnsInfo() {
        var report = new ReportColumnsInfoDialog("JobOrder/JobOrderAdministativeReportWorkHourCostsExport", "AvailableColumns", "DefaultTemplate");
        this.dialogsService.ShowModal<void>(report, "large", { noPrompt: true });
    }

    public Export() {
        this.fileRepositoryService.openAsDialog("/" + ProlifeSdk.TextResources.JobOrder.ReportModelsURL)
            .then((selectedFiles : IFileOrFolder[]) => {
                if(selectedFiles.length == 0)
                    return;

                var template = selectedFiles[0];

                this.jobOrdersService.ExportWorkHourCosts(template.Id, this.editor.JobOrderId, this.Resource(), this.Role(), this.Type(), this.DateFrom(), this.DateTo(), this.HoursFrom(), this.HoursTo(), this.CostFrom(), this.CostTo(), this.OnlyToBeBilled(), this.OnlyImported(), this.OrderBy(), this.OrderAscDesc());
            });
    }*/

    load():Promise<boolean> {
        var def = new Deferred<boolean>();

        this.jobOrdersService.GetWorkHourCosts(this.editor.JobOrderId, this.Resource(), this.Role(), this.Type(), this.DateFrom(), this.DateTo(), this.HoursFrom(), this.HoursTo(), this.CostFrom(), this.CostTo(), this.OnlyToBeBilled(), this.OnlyImported(), this.OrderBy(), this.OrderAscDesc())
            .then((costs : IJobOrderWorkHourCosts[]) => {
                this.Table.ClearItems();

                /*var lastSourceType = "", lastSourceId = 0;
                var lastItem = null;
                var total = 0;
                var totalToImport = 0;

                costs.forEach(c => {
                    if(lastSourceType != c.SourceType || lastSourceId != c.SourceId) {
                        lastItem = this.Table.AddItem({}, this.getDescriptionString(c));
                        lastSourceType = c.SourceType;
                        lastSourceId = c.SourceId;

                        var existingValue = this.expandCollapseCache[c.SourceType + c.SourceId];
                        if(existingValue == undefined) {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = false;
                            existingValue = false;
                        }

                        lastItem.IsExpanded(existingValue);
                        lastItem.IsExpandedChanged.Add(isExpanded => {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = isExpanded;
                        });
                    }

                    lastItem.AddChild(c, moment(c.SourceDate).format("L")).CanHaveChildren(false);

                    total += c.Cost;
                    if(!c.ImportedInDoc)
                        totalToImport += c.Cost
                });*/

                let totalHours = 0;
                let totalHoursToImport = 0;
                let total = 0;
                let totalToImport = 0;
                let billableCount = 0;
                let billedCount = 0;

                costs.forEach(c => {
                    let item = this.Table.AddItem(c, this.getDescriptionString(c));
                    item.CanHaveChildren(false);

                    totalHours += (c.Hours || 0);
                    total += c.Cost;

                    billableCount += c.ToBeBilled ? 1 : 0;
                    billedCount += c.ImportedInDoc ? 1 : 0;

                    if (!c.ImportedInDoc)
                    {
                        totalHoursToImport += (c.Hours || 0);
                        totalToImport += c.Cost
                    }
                });

                this.Table.AddItem({ Hours: totalHours, Cost: total, ToBeBilled: billableCount, ImportedInDoc: billedCount }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotal).CanHaveChildren(false);
                this.Table.AddItem({ Hours: totalHoursToImport, Cost: totalToImport }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotalToImport).CanHaveChildren(false);

                def.resolve(true);
            });

        return def.promise();
    }

    private getDescriptionString(c : IJobOrderWorkHourCosts) : string {
        switch(c.SourceType) {
            case "JTK":
                return c.SourceName;
            case "JOR":
                return ProlifeSdk.TextResources.JobOrder.ProjectWorkHours;
        }

        return "";
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    beforeShowPanel() {
    }
}