import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IFileRepositoryService } from "../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IWorkTimeCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrdersControlsEntityProvider } from "../../../ProlifeSdk/interfaces/job-order/entityProviders/IJobOrdersControlsEntityProvider";
import { IHumanResourcesControlsEntityProvider } from "../../../ProlifeSdk/interfaces/users/IHumanResourcesControlsEntityProvider";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderType";

export class ExtractHoursReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;
    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService: IProLifeSdkService;
    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager: IUserCharactersSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private statesManager: IJobOrderStateSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private typesManager: IJobOrderTypesSettingsManager;

    public jobOrdersSearchService: IJobOrdersControlsEntityProvider;
    public humanResourcesSearchService: IHumanResourcesControlsEntityProvider;

    Name: string = ProlifeSdk.TextResources.WorkedHours.WorkedHoursExport;
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";

    ExporterId: string = "WorkedHours/HoursReport";

    detailsTemplateName: string = "extract-hours";
    detailsTemplateUrl: string = "WorkedHours/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    //Filtri
    private startDate: ko.Observable<Date> = ko.observable();
    private endDate: ko.Observable<any> = ko.observable();

    private resourceId: ko.Observable<number> = ko.observable();
    private roleId: ko.Observable<number> = ko.observable();
    private workTimeCategoryId: ko.Observable<number> = ko.observable();
    private jobOrder: ko.Observable<any> = ko.observable();

    public workTimeCategories: any[] = [];
    public roles: any[] = [];

    public State: ko.Observable<any> = ko.observable();
    public States: IJobOrderState[];

    public Type: ko.Observable<any> = ko.observable();
    public Types: IJobOrderType[];

    constructor(groupId: number) {
        super(groupId, 5);

        this.jobOrdersSearchService = this.entitiesService
            .getEntityProvider(ProlifeSdk.JobOrderEntityTypeCode)
            .getControlsProvider() as IJobOrdersControlsEntityProvider;
        this.humanResourcesSearchService = this.entitiesService
            .getEntityProvider(ProlifeSdk.HumanResources)
            .getControlsProvider() as IHumanResourcesControlsEntityProvider;
        this.humanResourcesSearchService.SimpleSearch = true;

        this.workTimeCategories = this.workTimeCategoriesManager.getAll(false);
        this.roles = this.rolesManager.getUserCharacters();

        this.States = this.statesManager.getJobOrderStates();
        this.State(null);

        this.Types = this.typesManager.getJobOrderTypes();
        this.Type(null);

        this.State.subscribe(this.updateJobOrderSearchField.bind(this));
        this.Type.subscribe(this.updateJobOrderSearchField.bind(this));
    }

    private updateJobOrderSearchField() {
        this.jobOrder(null);
        this.jobOrdersSearchService.setStateFilter(this.State() ? this.State().IdJobOrderStateId : null);
        this.jobOrdersSearchService.setTypeFilter(this.Type() ? this.Type().Id : null);
    }

    public getExcelExportData(): any | null {
        if (!this.startDate() || !this.endDate() || (<any>moment(this.startDate())).isAfter(this.endDate())) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SpecifyValidDateInterval);
            return;
        }

        return {
            start: this.startDate(),
            end: this.endDate(),
            jobOrderId: this.jobOrder() ? this.jobOrder() : null,
            resourceId: this.resourceId() ? this.resourceId() : null,
            workTymeCategoryId: this.workTimeCategoryId() ? this.workTimeCategoryId() : null,
            roleId: this.roleId() ? this.roleId() : null,
            jobOrderType: this.Type() ? this.Type().Id : null,
            jobOrderState: this.State() ? this.State().IdJobOrderStateId : null,
        };
    }

    SetWorkTimeCategory(id: number) {
        this.workTimeCategoryId(id);
    }

    SetRole(id: number) {
        this.roleId(id);
    }

    initialize() {
        //super.initialize();
        this.startDate(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.endDate(moment(new Date()).subtract("month", 1).endOf("month").toDate());
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}
