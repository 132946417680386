import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { DdtOrInvoiceDefaultValuesSettingsUi } from "../ui/documents/default-values/DdtOrInvoiceDefaultValuesSettingsUi";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolDefaultValuesSettingsUi } from "../../../ProlifeSdk/interfaces/invoice/IProtocolsSettingsManager";

export class VatRegisterDocumentsProvider extends DocumentsProvider {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    constructor(private vatRegister: IVatRegister) {
        super(vatRegister.TipoDocumento == 1 ? ProlifeSdk.DdtEntityTypeCode : ProlifeSdk.InvoiceEntityTypeCode);

        this.ProviderId = "Vat";
        this.CanEdit = vatRegister.Stato == ProlifeSdk.EnabledProtocolState;
        this.Id = vatRegister.IdRegistroIVA;
        this.Name(vatRegister.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(vatRegister.NuovoLabel);

        this.CanCreateNew(
            vatRegister.Stato == ProlifeSdk.EnabledProtocolState &&
                (vatRegister.TipoDocumento == 1
                    ? this.authorizationService.isAuthorized("Documents_DDTs")
                    : this.authorizationService.isAuthorized("Documents_Invoices"))
        );
        this.CanView(
            this.CanCreateNew() ||
                (vatRegister.Stato != ProlifeSdk.HiddenProtocolState &&
                    (vatRegister.TipoDocumento == 1
                        ? this.authorizationService.isAuthorized("Documents_ViewDDTs")
                        : this.authorizationService.isAuthorized("Documents_ViewInvoices")))
        );

        this.ProviderId = "Vat" + this.Id;
        this.HasDefaultValuesSettingsUi = true;
        this.DefaultSearchProvider = vatRegister.DefaultSearchProvider;
    }

    public GetDocumentGroupLabel(): string {
        return this.vatRegister.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.vatRegister.PositionInList;
    }

    GetDefaultValuesSettingsUi(): IProtocolDefaultValuesSettingsUi {
        return new DdtOrInvoiceDefaultValuesSettingsUi(this.vatRegister, this.ProviderId);
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        const documentTypes: IProtocolDocumentType[] = [];

        if (this.vatRegister.TipoDocumento == 1) {
            documentTypes.push({
                DocumentType: ProlifeSdk.DdtEntityTypeCode,
                NewLabel: this.vatRegister.NuovoLabel,
            });
        } else if (this.vatRegister.TipoDocumento == 0) {
            if (this.vatRegister.TipiFatture.charAt(0) === "1") {
                documentTypes.push({
                    DocumentType: ProlifeSdk.InvoiceEntityTypeCode,
                    NewLabel: this.vatRegister.InvoiceLabel,
                });
            }

            if (this.vatRegister.TipiFatture.charAt(1) === "1") {
                documentTypes.push({
                    DocumentType: ProlifeSdk.CreditNoteEntityTypeCode,
                    NewLabel: this.vatRegister.CreditNoteLabel,
                });
            }

            if (this.vatRegister.TipiFatture.charAt(2) === "1") {
                documentTypes.push({
                    DocumentType: ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
                    NewLabel: this.vatRegister.AccompanyingInvoiceLabel,
                });
            }
        }

        return documentTypes;
    }
}
