import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ResourcesGroupsEditingViewModel } from "./ui/ResourcesGroupsEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IResourcesGroupsSettingsManager, IResourcesGroup, IResourcesGroupsSettingsObserver } from "../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export class ResourcesGroupsSettingsManager implements IResourcesGroupsSettingsManager
{
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    
    private groups : IResourcesGroup[];
    private observers : IResourcesGroupsSettingsObserver[] = [];
    
    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Users.ResourcesRoles);
        this.groups = [];
    }

    load(): Promise<IResourcesGroup[]>
    {
        return this.ajaxService.Get<IResourcesGroup[]>("Users-api", "ResourcesGroups/All", { })
            .then((groups: IResourcesGroup[]) => this.groups = groups);
    }

    getName(): string
    {
        return ProlifeSdk.ResourcesGroups;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Users.ResourcesGroups;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) &&
            !this.modulesService.IsModuleEnabled(ProlifeSdk.ResourcesManagerApplicationCode);
    }

    getEditingUI(): IView
    {
        return {
            templateName: "",
            templateUrl: "",
            viewModel: null,
            render: () => ResourcesGroupsEditingViewModel({ groupsManager: this})
        };
    }

    public GetGroups() : IResourcesGroup[]
    {
        return this.groups;
    }

    public GetGroupById(id : number) : IResourcesGroup
    {
        const retValues : IResourcesGroup[] = this.groups.filter((g) => g.Id == id);
        if (retValues != null && retValues.length == 1)
            return retValues[0];
        return null;
    }

    async CreateOrUpdate(group : IResourcesGroup) : Promise<IResourcesGroup>
    {
        if (!group.Id)
            group.Id = -1;

        const callbackFunc = group.Id > 0 ? this.OnGroupChanged.bind(this) : this.OnGroupAdded.bind(this);
        group.HoursApprovalResources.forEach(r => r.FKGroup = group.Id);

        return this.ajaxService.Post<IResourcesGroup>("Users-api", "ResourcesGroups/CreateOrUpdate", { methodData: group })
            .then(callbackFunc);
    }

    Remove(id : number) : Promise<IResourcesGroup>
    {
        return this.ajaxService.Delete<IResourcesGroup>("Users", "ResourcesGroups/" + id, {})
            .then(this.OnGroupDeleted.bind(this, id));
    }

    private OnGroupChanged(group : IResourcesGroup)
    {
        const index : number = this.GetGroupIndex(group.Id);
        this.groups[index] = group;
        this.observers.forEach((obs) => obs.OnGroupChanged(group));

        return group;
    }

    private OnGroupAdded(group : IResourcesGroup)
    {
        this.groups.push(group);
        this.observers.forEach((obs) => obs.OnGroupAdded(group));

        return group;
    }

    private OnGroupDeleted(id : number)
    {
        const index : number = this.GetGroupIndex(id);
        this.groups.splice(index, 1);
        this.observers.forEach((obs) => obs.OnGroupDeleted(id));
    }

    private GetGroupIndex(id : number) : number
    {
        const item = this.groups.filter((g : IResourcesGroup) => g.Id == id)[0];
        return this.groups.indexOf(item);
    }
}
