import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/02/2018
 * Time: 17:49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { CategoriesProvider } from "../providers/CategoriesProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IApplicationsConfigurationsService, IApplicationConfiguration } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class UserConfigurationDialog implements IDialog {
    public templateName: string = "user-configs-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Agenda.UserConfigDialogTitle;

    public PreferredCategory: ko.Observable<PreferredCategoryConfigurationViewModel> = ko.observable();

    modal: { close: (result?: any) => void; };

    private moduleId: number;

    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    private modulesService: IModulesService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsConfigurationsService = <IApplicationsConfigurationsService> this.serviceLocator.findService(ProlifeSdk.ApplicationsConfigurationsServiceCode);
        this.modulesService = <IModulesService> this.serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.modulesService.getModuleId(ProlifeSdk.AgendaApplication)
            .then((moduleId: number) => {
                this.moduleId = moduleId;

                this.LoadConfigurations();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.UserConfigurationDialogError);
            });
    }

    public close(): void {
        this.modal.close(null);
    }

    public async action(): Promise<void> {
        let config = this.PreferredCategory().GetData();
        config.UserID = this.userInfo.getIdUser();

        try {
            let savedConfig: IApplicationConfiguration = await this.applicationsConfigurationsService.AddOrUpdateConfiguration(config);
            this.PreferredCategory().UpdateFromModel(savedConfig);
            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.SaveUserConfigurationSuccess);
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.SaveUserConfigError);
        }
    }

    public Show(): Promise<void> {
        return this.dialogsService.ShowModal<void>(this);
    }

    private LoadConfigurations(): void {
        this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.UserPredefinedCategoryConfigurationType, this.userInfo.getIdUser(), this.moduleId, null)
            .then((configuration: IApplicationConfiguration[]) => {
                if (!configuration || !configuration[0]) {
                    this.PreferredCategory(this.CreateEmptyPreferredCategoryViewModel());
                    return;
                }

                this.PreferredCategory(new PreferredCategoryConfigurationViewModel(this.serviceLocator, configuration[0]));
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.LoadConfigurationsError);
            });
    }

    private CreateEmptyPreferredCategoryViewModel(): PreferredCategoryConfigurationViewModel {
        var config: IApplicationConfiguration = {
            ConfigurationId: -1,
            ConfigurationType: ProlifeSdk.UserPredefinedCategoryConfigurationType,
            UserID: null,
            ModuleId: this.moduleId,
            Configuration: ''
        };

        return new PreferredCategoryConfigurationViewModel(this.serviceLocator, config);
    }
}

class PreferredCategoryConfigurationViewModel {
    public PreferredCategoryId: ko.Observable<number> = ko.observable();
    public CategoriesProvider: CategoriesProvider;

    constructor(private serviceLocator: IServiceLocator, private config: IApplicationConfiguration) {
        this.CategoriesProvider = new CategoriesProvider(this.serviceLocator);

        this.PreferredCategoryId(!this.config.Configuration ? null : JSON.parse(this.config.Configuration));
    }

    public UpdateFromModel(config: IApplicationConfiguration): void {
        this.config.ConfigurationId = config.ConfigurationId;
        this.config.ModuleId = config.ModuleId;
        this.config.UserID = config.UserID;
        this.config.ConfigurationType = config.ConfigurationType;
        this.config.Configuration = config.Configuration;
    }

    public GetData(): IApplicationConfiguration {
        return {
            ConfigurationId: this.config.ConfigurationId,
            ConfigurationType: this.config.ConfigurationType,
            UserID: this.config.UserID,
            ModuleId: this.config.ModuleId,
            Configuration: JSON.stringify(this.PreferredCategoryId())
        };
    }
}