import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IResourcesGroupsSettingsManager, IResourcesGroup } from "../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

export class ResourcesGroupsControlsEntityProvider implements IControlsEntityProvider
{
    @LazyImportSettingManager(ProlifeSdk.ResourcesGroups)
    private groupsSettingsManager : IResourcesGroupsSettingsManager;

    private lastTimeout : ReturnType<typeof setTimeout>;

    constructor() {}

    public findEntities(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            var data : IResourcesGroup[] = this.groupsSettingsManager.GetGroups();
            query.callback({
                results: data
                    .filter((r : IResourcesGroup) => r.Deleted == false)
                    .map((group : IResourcesGroup) =>
                    {
                        return {
                            id: group.Id,
                            text: group.Name
                        };
                    })
                });
            }, 500);
    }

    /***
     * Estrae l'id già impostato sull'elemento e lo ritorna in formato lista
     * @param element
     * @param callback
     */
    public findEntity(element, callback)
    {
        var id = $(element).val();
        if(id !== "" && id > 0) {
            var group : IResourcesGroup = this.groupsSettingsManager.GetGroupById(<number>id);
            callback({
                id: group.Id,
                text: group.Name
            })
        }
    }

    /***
     * Estrae gli id già impostati sull'elemento e li ritorna in formato lista
     * @param element
     * @param callback
     */
    public findMultipleEntities(element, callback)
    {
        var ids : string[] = (<string>$(element).val()).split("|");
        if(ids.length == 0)
            return;

        var result : any[] = [];
        ids.map((s : string) => { return parseInt(s); })
            .forEach((id : number) => {
                var group : IResourcesGroup = this.groupsSettingsManager.GetGroupById(id);

                result.push({
                    id: id,
                    text: group.Name
                });
            });

        callback(result);
    }
}
