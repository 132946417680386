import * as ko from "knockout";
import { IApplicationStarter } from "../interfaces/IApplicationsService";

export abstract class ApplicationStarter implements IApplicationStarter {
    public applicationName: string;
    public applicationIcon: string;
    public tileColor: string;

    public order?: number;
    public group?: number;

    public areAuthorizationsLoaded = false;

    public visible: ko.Observable<boolean> = ko.observable(false);

    public abstract start();

    public abstract getApplicationRoute();
}
