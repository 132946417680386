import * as ko from "knockout";
import { HumanWorkPeopleCosts } from "./ui/HumanWorkPeopleCosts";
import {
    IJobOrderService,
    IJobOrderEconomicsArticlesCosts,
    IJobOrderEconomics,
} from "../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import {
    IEconomicsProvider,
    IEconomicsProviderObserver,
} from "../../ProlifeSdk/interfaces/job-order/IJobOrdersProvider";
import { IJobOrder } from "../../ProlifeSdk/interfaces/job-order/IJobOrder";
import {
    IEstimatedPurchasesDetails,
    IEstimatedWorkDetails,
    IArticleDetails,
    IFinancialDataDetails,
} from "../../ProlifeSdk/interfaces/job-order/IFinancialDataDetails";
import { IResourceWork } from "../../ProlifeSdk/interfaces/job-order/IResourceWork";
import { LazyImport } from "../../Core/DependencyInjection";

//
//  Brief : Si occupa di caricare i dati sui costi e ricavi
//
export class CostsAndRevenuesProvider implements IEconomicsProvider {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    private jobOrder: IJobOrder;
    private selectedWorkflow: number = null;
    //private jobOrderSals : ISal [] = [];

    //Materiali
    public PurchasesBilledCosts: ko.Observable<number> = ko.observable(0);
    public PurchasesToBeBilledCosts: ko.Observable<number> = ko.observable(0);
    public PurchasesOtherCosts: ko.Observable<number> = ko.observable(0);
    public PurchasesToBeBilledRevenues: ko.Observable<number> = ko.observable(0);
    public PurchasesBilledRevenues: ko.Observable<number> = ko.observable(0);

    public EstimatedPurchasesByType: ko.ObservableArray<IEstimatedPurchasesDetails> = ko.observableArray();
    public EstimatedWorkByRole: ko.ObservableArray<IEstimatedWorkDetails> = ko.observableArray();
    public PurchasesByType: ko.ObservableArray<any> = ko.observableArray([
        {
            Type: "Type 1",
            Purchases: [
                {
                    Description: "Test 1",
                    Amount: 10,
                    Cost: 12030,
                },
                {
                    Description: "Test 2",
                    Amount: 20,
                    Cost: 22030,
                },
            ],
        },
        {
            Type: "Type 2",
            Purchases: [
                {
                    Description: "Test 3",
                    Amount: 30,
                    Cost: 32030,
                },
            ],
        },
    ]);

    //Ore lavorate
    public HumanWorkBilledCosts: ko.Observable<number> = ko.observable(0);
    public HumanWorkToBeBilledCosts: ko.Observable<number> = ko.observable(0);
    public HumanWorkOtherCosts: ko.Observable<number> = ko.observable(0);

    public HumanWorkCostsByPeople: ko.ObservableArray<HumanWorkPeopleCosts> = ko.observableArray([]); //Aggregato di tutti i costi ore per risorsa
    public HumanWorkToBeBilledRevenues: ko.Observable<number> = ko.observable();
    public HumanWorkBilledRevenues: ko.Observable<number> = ko.observable();

    //Magazzino
    public ArticlesInfo: ko.ObservableArray<IArticleDetails> = ko.observableArray([]);
    public WarehouseBilledCosts: ko.Computed<number>;
    public WarehouseToBeBilledCosts: ko.Computed<number>;
    public WarehouseBilledRevenues: ko.Computed<number>;
    public WarehouseToBeBilledRevenues: ko.Computed<number>;

    //Ricavi da SAL
    public TotalToBeBilled: ko.Observable<number> = ko.observable(0);
    public TotalRevenues: ko.Observable<number> = ko.observable(0);
    public TotalRevenuesWithVat: ko.Observable<number> = ko.observable(0);
    public TotalEstimate: ko.Observable<number> = ko.observable(0);

    public EconomicsData: ko.Observable<any> = ko.observable();
    public HistoricalEconomics: ko.Observable<any> = ko.observable();
    public EconomicsArticlesCosts: ko.ObservableArray<IJobOrderEconomicsArticlesCosts> = ko.observableArray([]);

    public CachedTotalRevenues: ko.Observable<number> = ko.observable(0);

    private observer: IEconomicsProviderObserver[] = [];

    constructor() {
        this.WarehouseBilledRevenues = ko.computed(() => {
            let total = 0;
            this.ArticlesInfo().forEach((a: IArticleDetails) => {
                total += a.RevenuesForArticle;
            });
            return total;
        });

        this.WarehouseToBeBilledRevenues = ko.computed(() => {
            let total = 0;
            this.ArticlesInfo().forEach((a: IArticleDetails) => {
                total += a.RevenuesToBeBilledForArticle;
            });
            return total;
        });

        this.WarehouseBilledCosts = ko.computed(() => {
            let total = 0;
            //this.ArticlesInfo().forEach((a : IArticleDetails) => { total += a.BilledCostForArticle; });
            this.EconomicsArticlesCosts().forEach((a: IJobOrderEconomicsArticlesCosts) => {
                total += a.TotalCost;
            });
            return total;
        });

        this.WarehouseToBeBilledCosts = ko.computed(() => {
            let total = 0;
            this.ArticlesInfo().forEach((a: IArticleDetails) => {
                total += a.CostForArticle - a.BilledCostForArticle;
            });
            return total;
        });
    }

    public async refresh(): Promise<void> {
        if (this.jobOrder) await this.loadJobOrderData(this.jobOrder, this.selectedWorkflow);
    }

    async loadJobOrderData(jobOrder: IJobOrder, workflowId: number /*, sals : ISal[]*/): Promise<void> {
        this.jobOrder = jobOrder;
        this.selectedWorkflow = workflowId;
        //this.jobOrderSals = sals;

        const financialDataDetails = await this.jobOrderService.getFinancialDataDetails(
            jobOrder.JobOrderId,
            workflowId
        );
        const jobOrderEconomics = await this.jobOrderService.GetJobOrderEconomicsData(jobOrder.JobOrderId, workflowId);
        const historicalEconomics = await this.jobOrderService.GetJobOrderHistoricalEconomics(
            jobOrder.JobOrderId,
            workflowId
        );

        this.updateFinancialDataDetails(financialDataDetails);
        this.updateJobOrderEconomicsData(jobOrderEconomics, jobOrder, workflowId);
        this.HistoricalEconomics(historicalEconomics);

        this.observer.forEach((o) => o.onDataLoaded());
    }

    public addObserver(observer: IEconomicsProviderObserver) {
        this.observer.push(observer);
    }

    private updateFinancialDataDetails(data: IFinancialDataDetails) {
        this.HumanWorkCostsByPeople([]);
        this.HumanWorkCostsByPeople(data.ResourcesWork.map((rw: IResourceWork) => new HumanWorkPeopleCosts(rw)));

        this.ArticlesInfo(data.Articles);

        const allTypes = [];
        let lastType = null;
        for (const purchase of data.Purchases) {
            if (!lastType || lastType.Type != purchase.PurchaseType) {
                lastType = {
                    Type: purchase.PurchaseType,
                    Purchases: ko.observableArray([]),
                    Collapsed: ko.observable(true),
                    switchCollapsedState: function () {
                        this.Collapsed(!this.Collapsed());
                    },
                    TotalCosts: null,
                };

                lastType.TotalCosts = ko.computed(function () {
                    let sum = 0;

                    this.Purchases().forEach((c) => {
                        sum += c.Cost;
                    });

                    return sum;
                }, lastType);

                allTypes.push(lastType);
            }

            lastType.Purchases.push(purchase);
        }

        this.PurchasesByType(allTypes);

        this.EstimatedPurchasesByType(data.EstimatedPurchases);
        this.EstimatedWorkByRole(data.EstimatedWork);
    }

    private updateJobOrderEconomicsData(economicsData: IJobOrderEconomics, jobOrder: IJobOrder, workflowId: number) {
        this.EconomicsData(economicsData.EconomicsData);
        this.EconomicsArticlesCosts(economicsData.EconomicsArticlesCosts);

        this.TotalRevenues(
            economicsData.EconomicsData.ArticlesRevenues +
                economicsData.EconomicsData.HoursRevenues +
                economicsData.EconomicsData.PurchasesRevenues +
                economicsData.EconomicsData.OthersRevenues
        );
        this.TotalRevenuesWithVat(economicsData.EconomicsData.TotalRevenuesWithVat);

        if (jobOrder.JobOrderId && !workflowId) {
            this.CachedTotalRevenues(this.TotalRevenues());
        }
    }
}
