import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";

type DiscountInputProps = {
    simple?: boolean;
    value: ko.Observable<string> | ko.Computed<string>;
    label?: string;
    selectOnFocus?: boolean;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean>;
}

export function DiscountInput(props: DiscountInputProps) {
    const C = require("./DiscountInput")._DiscountInput as typeof _DiscountInput;
    return <C {...props} />;
}

export class _DiscountInput {
    static defaultProps: Partial<DiscountInputProps> = {
        simple: false,
        label: " ",
        selectOnFocus: false,
        readonly: false
    }

    constructor(private props : DiscountInputProps) {
        
    }
    
    render() {
        let bindings = "discountValue: di.props.value, disable: di.props.readonly";

        if(this.props.selectOnFocus)
            bindings += ", selectOnFocus: {}";

        if(this.props.simple)
            return ComponentUtils.bindTo(<input className="form-control text-right" type="text" data-bind={bindings}></input>, this, 'di');

        return ComponentUtils.bindTo(
                    <div className="form-group">
                        <label className="control-label">{this.props.label}</label>
                        <input className="form-control text-right" type="text" data-bind={bindings}></input>
                    </div>
        , this, 'di');
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(DiscountInput);
}