import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ComponentParam, Param } from "../../../Core/utils/ComponentUtils";
import { Document } from "./Document";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import jss from "jss";

let attributes = {
    Document: "document"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "document-layout-selection": {
               params?: {
                   Document: string;
               };
               
               "document": Document | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

const { classes } = jss.createStyleSheet({
    layoutSelectionMenu: {
        backgroundColor: "white",

        "& > .layouts-list": {
            margin: "0px",
            padding: "0px",
            listStyleType: "none",

            "& > li": {
                paddingBottom: "5px",
                paddingTop: "1px",
                cursor: "pointer",

                "&:hover": {
                    background: "#F2F2F2",
                },

                "& > .body": {
                    width: "400px",
                    marginTop: "5px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    border: "1px solid transparent",

                    "&.selected": {
                        border: "1px solid silver",
                    },

                    "& > .image": {
                        display: "inline-block",
                        backgroundColor: "silver",
                        width: "100px",
                        height: "100px",
                        verticalAlign: "top",
                        marginLeft: "5px",
                        marginRight: "10px",
                    },

                    "& > .text": {
                        display: "inline-block",
                        width: "270px",
                        verticalAlign: "top",

                        "& > .title": {
                            fontSize: "1.3em",
                            fontWeight: "bold",
                        },

                        "& > .description": {
                            fontSize: "0.9em",
                        }
                    }
                }
            }
        }
    }
}).attach();

export interface IDocumentLayoutSelectionParams {
    Document: Param<Document>;
    OnClose: () => void;
}

export interface IDocumentLayout {
    Id: number;
    Title: string;
    Description: string;
    Image: string;
}

export class DocumentLayoutSelection {
    Document: ComponentParam<Document>;
    Layouts: ko.ObservableArray<IDocumentLayout> = ko.observableArray();
    
    constructor(private params : IDocumentLayoutSelectionParams) {
        this.Document = ComponentUtils.parseParameter(params.Document, null);

        this.Layouts([
            {
                Id: 1,
                Title: TextResources.ProlifeSdk.StandardDetailTitle,
                Description: TextResources.ProlifeSdk.StandardDetailDescription,
                Image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGMAAABjCAYAAACPO76VAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAN+SURBVHja7J1PTxNBGIeHQkBJlH8eISEx0eiJgxFv0A9A0huJJ3rxaEv0qCmJHk2Aq5dy0YSDgW/QPRnUQ8tFo4lpGzhgIpVqQrQKyLvJmiIzm6Zdd1fmeZKGLFCamac7Lzu/mW3X0TEK4oCToA/iAzKQATp6mg82Xr9RtdoXeiUkJm/eUCPDw3oZImJn5xO9FBKNRoNhipoByEAGIAMZgIyzSFfzROFurXbqf1/4dwwfX/D19fZ6h86Ji77mq0FgmEIGIAOQgQxAxv9Fj+kHpVJJra+vq+npafeh42DrrTra/2pFR3WPXVdd/RcDaX9iZFQlLo22LmNpacmVIZhk/Fh9rA4+vLJCxvl7z1TP1VuBtL935q7qm8m0PkytrKyoiYkJxg5qBjIgrgVckGFqfHzcbHLsmjUd9Xfx7qT9UsC1r8HyztjA8k5qBiADGYAMZAAykAHIQAYgwza0E4WVSsUNlKrVqpqamlKO42if/Ov9hhWdJJOEkvQF1X5daui+jmmi0Itch4aGVLFY1AZN3+5ctkJG95VJ1X//eWDt16WGym+iMJVKuWnfwMAAiV+Uw5QgIubn59Xa2hq9FGUBl5Uh6XTaPStkYYIcQ0RnhqR7hULhxLFpLLXiHWtI9Nptv654+xZwCB2SPi76ABnIAGQgA5CBDEAGMiAUjLO2kmfIBKHfNjIISYbM1kqOkUwmVblc1k4W/nz5wn3YQN/sg1NpX7vtl4nHc7MPW5exsLDwJ1waHBzU/s7h7rY1e/p0GymDbr9vzdjb22PsiLqAy4IEeczNzal6vY6UOBRwkZDP5323kkFwdBQuHX7edsdNG9Atr2m3/YalPw5JX3wg6eMKHJCBDEAGMgAZyABkIANCwfd+U9lsVi0vLyvTjMn31UfqcOudFR2l27m0/+R2W39LF1T5ypBZWxHhh4iwJVzS0W7bTXf81A5TMnUuWUYmk2HsiLpmSP4toZJ3ZkgECxHJkM6XOuHtXkJGOPjmGTJcect1tGOmRXd81mwVDnofOOFSjCBc4qIPkIEMQAYyABnIAGQgA0LB99PIvK0AbCOLWIbkGd4ny5hkSNJlS7h04enHU98L+B6F5mFqc3PT3T7GWRGDmpHL5dyvssGSnUsRypDOlyFK7uDpHUNENUP280n0KhIWFxfZRhYSHYVLJH3BJ30F3pOxoPRbgAEAInZhZhcV0CYAAAAASUVORK5CYII="
            },
            {
                Id: 2,
                Title: TextResources.ProlifeSdk.DetailedDetailTitle,
                Description: TextResources.ProlifeSdk.DetailedDetailDescription,
                Image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGMAAABjCAYAAACPO76VAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAMtSURBVHja7JxNaxNRFEBv2jTVgiakLlualUU3SUGsukp/QGmhi4Ju+gs0oEuFgC4VqktXyUKhC6GhP6DZWXXRrBQFoaFdVLAxcVFttKm9A5FJOlPazEcm5RwIZBp403kn7968mzsJHRwiEASKfcxBcEAGMsCKsPlg7f0HqVR+MCs+MXn9mgzH49YyVMT29jdmySfq9TphipwByEAGIAMZgIyzSMhcKNypVI589gXviB9u+AYjkeZhsWXTZ94NAmEKGYAMQAYyABm9RdjuhVKpJMvLy5JOp42HFb+XHktj8xOzeNoVMHpFzs0/OrmMxcVFQ4ZiJ0NF7H95x+x6HaZyuZykUilmiJyBDAhqAlc0TCUSiWMTEXSWwK0I0d4ZGGjvJGcAMpAB7nyacsru09uOxxi4NWc8muxvfpS9pSeeTsrQg9euX4eZwfmH0j961V8ZbpRK+scnW44Pdn/6XoJx+3x6DYQpcgYggwTeFu8vTzp/twyPtJYMhi66Mq7f19F+DZZ/pxwSGCiHkDMAGcgAZCADkIEM8E/GxsaGFItFqVarzJJPhO1ENDtD9LmVkL+f1+TXszvMYAecv/9KwuM3TrYyVIL22urKqNVqxnPo0spoCllYWJBkMkmbZ7dlqAhtfNbVAV1M4Cogn88bIWpiYgIhPtFxCb3xfUv+vH3DDHbAwM056bs0cmQN8H1GcOD7DHbggAxkgPN9hlP2Vp5LfeWF43EuvPzqybh2RKbvyuD0PU/P135NrAzCFCADGRCYBK4tkZoMe2Vc8/h+ns8M5ZDgQDmEnAHIQAYgAxmADGSAfzvw2dlZKRQKMjMz8/+3Cs14Xco+y5y6hJ7JZGR1ddUQQkdhl8OU9kpNTU3J2NgYHYXdlpHNZmV9fV3K5bLR/Axdyhm6KjRMKdFo9NjfKQT3sK3aNls6NUTFYrEjr+stAfzAcGeYv2M3Tzkl9OBACZ1NHyCjZ3fgTtFbBho7W87/wbZ739wa1/bdOTzS0q7vxfms7ufzVIbeu+FFR6Fb49rR3lHoxfnoKCRnADKQAU5hB84OHAhTyABkIAOQgQxwGy0UFpmGQFD6J8AA9BodXJJ6DFAAAAAASUVORK5CYII="
            },
            {
                Id: 3,
                Title: TextResources.ProlifeSdk.CompactDetailTitle,
                Description: TextResources.ProlifeSdk.CompactDetailDescription,
                Image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAPXSURBVHja7J1NS1RRGMePb0khIRjkYsKRIKFFTateNo4fQFBcBLVINy1TaNvCRcsC6ws4Llq0EBQ/QOOml1UzLYLaOJDLAikwzNTmf+pM18F753bR26n7+8NlxsHDZZ7ffc6Z87/PPNO2t7d3yxiTN8gHldrqQJ7VnxSJhRcqthMDvwQQgKAodQb/eF2p1o83RCUlXSpcqB8XyRCmLAQQgCCAAAQBJNP7kJ6eHtPff5qopCTFu1mYi34Jc5E1BAEEIAggmQBSq9XMxsYGUfIByNzcnBkcHDSVSoUo/a2NYVBjY2NmaWlp32vf3700Xx/ezERgjo3eMd2j0+bL7bOJxh+/+8Q+RsXLnSNWhuTzeS5XnzLkILWdOGk6zl3Oxlzel7OPSd+vYtVqvDvHvnFR1onWD2VKb28vl246KkZmSKFQIETsQwCCAIIAAhAEEIAggGQViNzeYrFoZmdniVKKCrVO5PTKMhkZGTH1/7Gv7X5cN9svFjMRGHlQnUNXzNbKo0Tju65O2MeoeLlzBFSMtN9LpZIZGBhovLb7ad18W3mcCSCyxhWspO/XmYpR4905Yk1ZgjE1NWVmZmaYR1JUaIbI6R0eHrZTl4Miu1hUszJluas40eL8y1qPGn+QNU/lol+icpF9CAIIQBBAAIIAAhDkDxDt1GWfqOAapafQnfrk5GSj8r25xhcd3U491MsSEN0LCVYt7nx4a7ae3s9EZLquTdhj88GNROO7r9+zj1HxcucIKhSISkgFJej27m1+NjvvX2UCSMfQT+Mv6ftVrFqNd+eIBUT3QxwY5MEa4hb2YH2vzZD6tJWJTzt9OdN+Kme/E5Mow86cb0zzrc4RXEOw3z1b1NmHsDFEAAEIAghAEEAAgrwCIh9LBdcoPYV6WbLcdT8kaJ3g9sbXobq9uiklp1fGop7r0HPc3vhK6va2R01XNA5IX5HmojKjXC7bbHHUcXtjZhhu738h3F72IQggAEEAAQgCCEBQ6go1F7VD1yHRXsODDBEMWSfY755kiGAsLCzYCngHhc7W8XXona01Ta2trZnl5WUuWx8yRA7v6uqqmZ7+TZDO1vF1JJ2tUerC7WUfggACEAQQgCCAAAT5BUQ+Fj+b5wkQ1faqfFT1vSg9hXpZMhdVStpsvyft9PzPXal9OVsIvf180TaQ/lO5rtVR49X9uqlyMRxItVo18/PzZnx8vNFqXMpKZ2sF1AFJUnDtulZHjdc5moGETllqoqyG/MFW4+gvTllaQ9zvGDaTz8qUZaeVepYc9LWBOBnWajz2u/+y/bL0MapMLLxQ7YcAAwCXLHjGCioDKAAAAABJRU5ErkJggg=="
            }
        ]);
    }

    SelectLayout(layout : IDocumentLayout): void {
        this.Document().LayoutId(layout.Id);
        this.params.OnClose();
    }
}

ko.components.register("document-layout-selection", {
    viewModel: {
        createViewModel: (params: IDocumentLayoutSelectionParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new DocumentLayoutSelection(params);
            let layout: IDocumentLayout;
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={classes.layoutSelectionMenu} data-as={{vm}}>
                    <ul class="layouts-list" data-bind={{ foreach : { data: vm.Layouts, as: 'layout' }}}>
                        <li data-bind={{ click : vm.SelectLayout.bind(vm, layout) }}>
                            <div class="body" data-bind={{ css : { selected : vm.Document().LayoutId() == layout.Id } }}>
                                <div class="image standard" data-bind={{ backgroundImage: layout.Image }}></div>
                                <div class="text">
                                    <div class="title" data-bind={{ text : layout.Title }}></div>
                                    <span class="description" data-bind={{ text : layout.Description }}></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});