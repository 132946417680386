import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IMetadata, IMetadataService, IFullMetadata, IGetMetadatasRequest } from "../Invoices/MetadataService";
import { LazyImport } from "../Core/DependencyInjection";

export type IDocumentMetadatasDataSourceModel = IDataSourceModel<number, IFullMetadata>;

export class DocumentMetadatasDataSource extends BaseDataSource<IDocumentMetadatasDataSourceModel> {
    @LazyImport(nameof<IMetadataService>())
    private metadatasService: IMetadataService;

    private getDeleted = false;
    private protocolId: number;
    private metadataToOmit: number[] = [];

    getTitle(currentModel: IDocumentMetadatasDataSourceModel): string {
        return "";
    }

    async getData(
        currentModel: IDocumentMetadatasDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDocumentMetadatasDataSourceModel[]> {
        const request: IGetMetadatasRequest = {
            textFilter: textFilter,
            getDeleted: this.getDeleted,
            protocolId: this.protocolId,
            includeDeletedListValues: false,
            skip: skip,
            count: count,
        };
        const metadatas = await this.metadatasService.GetMetadatas(request);
        metadatas.Metadatas = metadatas.Metadatas.filter((m) => this.metadataToOmit.indexOf(m.Id) < 0);
        return this.createModels(metadatas);
    }

    async getById(
        currentModel: IDocumentMetadatasDataSourceModel,
        ids: number[]
    ): Promise<IDocumentMetadatasDataSourceModel[]> {
        const metadatas = await this.metadatasService.GetMetadatasByIds(ids, true);
        return this.createModels(metadatas);
    }

    setProtocolId(protocolId: number) {
        this.protocolId = protocolId;
    }

    setGetDeleted(value: boolean): void {
        this.getDeleted = value;
    }

    setMetadataToOmit(metadataToOmit: number[]) {
        this.metadataToOmit = metadataToOmit;
    }

    private createModels(metadatas: IMetadata): IDocumentMetadatasDataSourceModel[] {
        const fullMetadatas = [];

        for (const metadata of metadatas.Metadatas) {
            const fullMetadata = Object.assign({}, metadata) as IFullMetadata;
            fullMetadata.ListValues = metadatas.MetadatasListValues.filter((m) => m.FKMetadata === metadata.Id);
            fullMetadatas.push(fullMetadata);
        }

        return fullMetadatas.map(this.createModel, this);
    }

    private createModel(metadata: IFullMetadata): IDocumentMetadatasDataSourceModel {
        return {
            id: metadata.Id,
            title: metadata.Label,
            isGroup: false,
            isLeaf: true,
            model: metadata,
        };
    }
}
