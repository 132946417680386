import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { RemindEditMaskDialog } from "./reminder/ui/remind-detail/RemindEditMaskDialog";
import { LazyImport } from "../Core/DependencyInjection";
import {
    IReminderService,
    IRemindObjReferenceUi,
    IRemindReferenceObjFieldType,
    IRemind,
    IRemindForList,
} from "../ProlifeSdk/interfaces/reminder/IReminderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IDialogsService, IDialog } from "../Core/interfaces/IDialogsService";
import { IService } from "../Core/interfaces/IService";

class ReminderService implements IReminderService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private remindObjReferenceUis: IRemindObjReferenceUi[] = [];
    private remindObjReferenceFields: { type: string; fields: IRemindReferenceObjFieldType[] }[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IReminderService>(), this);
    }

    InitializeService() {}

    showCreateRemindDialog() {
        const remind: IRemind = {
            Id: 0,
            Date: null,
            AlertFrom: null,
            Title: null,
            Notes: null,
            CreatedBy: 0,
            Priority: 1,
            RelatedUsers: [],
        };

        const dialog: IDialog = new RemindEditMaskDialog(this.serviceLocator).initialize(remind);
        this.dialogsService.ShowModal<void>(dialog, "large", null, dialog.templateUrl, dialog.templateName);
    }

    registerObjReferenceFields(entityType: string, fields: IRemindReferenceObjFieldType[]) {
        this.remindObjReferenceFields.push({ type: entityType, fields: fields });
    }

    getReferenceFieldsForEntityType(entityType: string) {
        const matches = this.remindObjReferenceFields.filter((a) => {
            return a.type == entityType;
        });
        return matches.length == 0 ? [] : matches[0].fields;
    }

    registerObjReferenceUi(ui: IRemindObjReferenceUi) {
        this.remindObjReferenceUis.push(ui);
    }

    getObjReferenceUiFor(entityType: string): IRemindObjReferenceUi {
        const matches = this.remindObjReferenceUis.filter((ui: IRemindObjReferenceUi) => {
            return ui.entityType == entityType;
        });
        return matches.length == 0 ? null : matches[0];
    }

    getServiceType(): string {
        return ProlifeSdk.ReminderServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public getRemind(id: number): Promise<IRemind> {
        return this.ajaxService.Get("Reminder-api", "Reminds/" + id, {});
    }

    public setDoneStatusForRemind(id: number, done: boolean): Promise<void> {
        return this.ajaxService.Post("Reminder-api", "Reminds/SetDoneStatusForRemind", {
            methodData: {
                Id: id,
                Done: done,
            },
        });
    }

    public searchRemindsForList(
        textFilter: string,
        from: Date,
        to: Date,
        onlyAlert: boolean,
        getDone: boolean,
        getUndone: boolean,
        orderByField: number,
        orderByAscOrDesc: boolean
    ): Promise<IRemindForList[]> {
        return this.ajaxService.Post("Reminder-api", "Reminds/SearchRemindsForList", {
            methodData: {
                TextFilter: textFilter,
                From: from,
                To: to,
                OnlyAlert: onlyAlert,
                GetDone: getDone,
                GetUndone: getUndone,
                OrderByField: orderByField,
                OrderByAscOrDesc: orderByAscOrDesc,
            },
        });
    }

    public createOrUpdate(remind: IRemind): Promise<IRemind> {
        return this.ajaxService.Post("Reminder-api", "Reminds", { methodData: remind });
    }

    public deleteRemind(id: number): Promise<void> {
        return this.ajaxService.Delete("Reminder-api", "Reminds/" + id, {});
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new ReminderService(serviceLocator);
}
