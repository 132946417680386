import * as ko from "knockout";
export class ScrollBottom
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var functionToInvoke = ko.utils.unwrapObservable(valueAccessor()["func"]);
		var enable = ko.utils.unwrapObservable(valueAccessor()["enable"]);

        $(element).on("scroll", function()
        {
            var lastScroll : number = parseInt($(element).attr("data-scroll")) || 0;
            $(element).attr("data-scroll", $(element).scrollTop());

            //if(functionToInvoke && enable && element.scrollHeight < element.scrollTop + 2* element.clientHeight && lastScroll < $(element).scrollTop())
            if(functionToInvoke && enable && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 100 && lastScroll < $(element).scrollTop())
                functionToInvoke.call(viewModel);
        });

    }

}

ko.bindingHandlers["scrollBottom"] = new ScrollBottom();