import * as ko from "knockout";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { ISettingsManager } from "../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ISettingsGroup } from "../../ProlifeSdk/interfaces/settings/ISettingsGroup";

interface SettingsManagerEntry{
	[settingsManagerName : string] : ISettingsManager;
}

export class SettingsManagerGroup implements ISettingsGroup {

	private settingsManagers : SettingsManagerEntry = {};
	private settingsManagersArray : ISettingsManager[] = [];

	constructor(serviceLocator : IServiceLocator, public name: string, public label: string){

	}

	public add(settingsManager : ISettingsManager) : void
	{
		if (this.get(settingsManager.getName()))
			return;

		this.settingsManagers[settingsManager.getName()] = settingsManager;
		this.settingsManagersArray.push(settingsManager);
	}

	public get(settingsManagerName : string) : ISettingsManager
	{
		return this.settingsManagers[settingsManagerName] || null;
	}


	public getAllSettingsManagers() : ISettingsManager[]{
		return this.settingsManagersArray;
	}

    public load() : Promise<any> {
        return Promise.all(
			this.settingsManagersArray.map((manager) => {
				console.log("Loading " + (<any>manager.constructor).name);
				let promise = manager.load()
					.then(() => {
						console.log("Finished loading " + (<any>manager.constructor).name);
					});
				return promise;
			}));
    }
}