/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 04/03/13
 * Time: 11.28
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ManufacturerEditDialog } from "../Catalogs/ManufacturerEditDialog";
import { CatalogEditDialog } from "../Catalogs/CatalogEditDialog";
import { DiscountFamiliesDataSource } from "../../../../DataSources/DiscountFamiliesDataSource";
import { StatisticsFamiliesDataSource } from "../../../../DataSources/StatisticsFamiliesDataSource";
import { ManufacturersDataSource } from "../../../../DataSources/ManufacturersDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { CatalogsDataSource } from "../../../../DataSources/CatalogsDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IArticleWithPosition } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../../ProlifeSdk/ValidationService";
import { PurchaseTypesDataSource } from "../../../../DataSources/PurchaseTypesDataSource";

export class Article implements IDataSourceListener {
    RealArticleId: ko.Observable<number> = ko.observable(); //ATTENZIONE : Questo è l'id articolo
    ArticleId: ko.Observable<number> = ko.observable(); //ATTENZIONE : Questa è la CatalogRowId !!!
    CatalogId: ko.Observable<number> = ko.observable();

    Code: ko.Observable<string> = ko.observable();
    EanCode: ko.Observable<string> = ko.observable();
    InsertManufacturerCode: ko.Observable<string> = ko.observable();
    ManufacturerCode: ko.Observable<string> = ko.observable();
    ManufacturerId: ko.Observable<number> = ko.observable();
    Description: ko.Observable<string> = ko.observable();
    ExtendedDescription: ko.Observable<string> = ko.observable();
    BoxQuantity: ko.Observable<number> = ko.observable();
    OrderQtStep: ko.Observable<number> = ko.observable();
    MinOrderQt: ko.Observable<number> = ko.observable();
    MaxOrderQt: ko.Observable<number> = ko.observable();
    LeadTime: ko.Observable<string> = ko.observable();
    DealerPrice: ko.Observable<number> = ko.observable();
    CustomerPrice: ko.Observable<number> = ko.observable();
    Currency: ko.Observable<string> = ko.observable();
    UOM: ko.Observable<string> = ko.observable();
    Kit: ko.Observable<number> = ko.observable(0);
    State: ko.Observable<number> = ko.observable(1);
    LastModification: ko.Observable<Date> = ko.observable();
    DiscountFamily: ko.Observable<number> = ko.observable();
    StatisticFamily: ko.Observable<number> = ko.observable();
    Hallway: ko.Observable<string> = ko.observable();
    Shelf: ko.Observable<string> = ko.observable();
    Ledge: ko.Observable<string> = ko.observable();
    WarehousePosition: ko.Computed<string>;

    FKPurchaseType: ko.Observable<number> = ko.observable();
    PurchaseType: ko.Observable<string> = ko.observable();

    DiscountFamiliesDataSource: DiscountFamiliesDataSource = new DiscountFamiliesDataSource();
    StatisticFamiliesDataSource: StatisticsFamiliesDataSource = new StatisticsFamiliesDataSource();

    public AvailableLeadTimes: ko.Computed<{ id: string; text: string }[]>;
    public LeadTimeDescription: ko.Computed<string>;
    public ManufacturersDataSource: ManufacturersDataSource = new ManufacturersDataSource();
    public CatalogsDataSource: CatalogsDataSource = new CatalogsDataSource();
    public PurchaseTypesDataSource: PurchaseTypesDataSource = new PurchaseTypesDataSource();

    private isLoading = false;
    private manufacturerCode: string;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;
    private validation: IValidator<Article>;

    constructor(private article?: IArticleWithPosition) {
        this.AvailableLeadTimes = ko.computed({
            read: this.getAvailableLeadTimes.bind(this),
            deferEvaluation: true,
        });

        this.LeadTimeDescription = ko.computed(() => {
            const matches = this.AvailableLeadTimes().filter((lt) => {
                return lt.id == this.LeadTime();
            });
            return matches.length == 0 ? "" : matches[0].text;
        });

        this.load(article);

        this.InsertManufacturerCode.subscribe(this.updateCodes.bind(this));

        this.Hallway() ?? this.Hallway("");
        this.Ledge() ?? this.Ledge("");
        this.Shelf() ?? this.Shelf("");
        this.WarehousePosition = ko.computed(() => {
            return this.Hallway() + this.Shelf() + this.Ledge();
        });

        /*CatalogId: this.CatalogId,
            ManufacturerId: this.ManufacturerId,
            Code: this.Code,
            MinOrderQt: this.MinOrderQt,
            OrderQtStep: this.OrderQtStep,
            BoxQuantity: this.BoxQuantity,
            ManufacturerCode: this.ManufacturerCode*/
        this.validation = this.validationService
            .createValidator<Article>()
            .isNotNullOrUndefined((a) => a.CatalogId(), TextResources.Warehouse.CatalogRequired)
            .isNotNullOrUndefined((a) => a.ManufacturerId(), TextResources.Warehouse.ManufacturerRequired)
            .isNotNullOrUndefined((a) => a.ManufacturerCode(), TextResources.Warehouse.ManufacturerCodeRequired)
            .isNotNullOrUndefinedOrWhiteSpace((a) => a.Code(), TextResources.Warehouse.CodeRequired)
            .stringMaxLength((a) => a.Code(), 47, TextResources.Warehouse.CodeLengthError);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (model != null) {
            this.manufacturerCode = model.model.Code;
            this.updateCodes();
            this.DiscountFamiliesDataSource.setManufacturerId(model.model.Id);
            this.StatisticFamiliesDataSource.setManufacturerId(model.model.Id);
            this.CatalogsDataSource.setManufacturerId(model.model.Id);
        } else {
            this.manufacturerCode = "";
            this.Code("");
        }

        this.resetCatalogId();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    dispose() {}

    private resetCatalogId() {
        if (this.isLoading) return;
        this.CatalogId(undefined);
    }

    private updateCodes() {
        const newCode = this.InsertManufacturerCode() || "";
        const fullCode = this.manufacturerCode || "";
        this.ManufacturerCode(newCode);
        this.Code(fullCode + newCode);
    }

    private getAvailableLeadTimes(): any[] {
        const result = [{ id: "1", text: ProlifeSdk.TextResources.Warehouse.OneDay }];
        for (let i = 2; i <= 9; i++)
            result.push({ id: i.toString(), text: String.format(ProlifeSdk.TextResources.Warehouse.N_Days, i) });
        for (let i = 65; i <= 90; i++)
            result.push({
                id: String.fromCharCode(i),
                text: String.format(ProlifeSdk.TextResources.Warehouse.N_Weeks, i - 63),
            });
        return result;
    }

    public getValidation(): IValidator<Article> {
        return this.validation;
    }

    public load(article?: IArticleWithPosition) {
        if (!article) return;
        this.isLoading = true;

        this.ArticleId(article.Id);
        this.RealArticleId(article.ArticleId);
        this.ManufacturerId(article.ManufacturerId);
        this.CatalogId(article.CatalogId);

        this.Code(article.Code);
        this.EanCode(article.EanCode);
        this.InsertManufacturerCode(article.ManufacturerCode);
        this.ManufacturerCode(article.ManufacturerCode);
        this.Description(article.Description);
        this.ExtendedDescription(article.ExtendedDescription);
        this.BoxQuantity(article.BoxQuantity);
        this.OrderQtStep(article.OrderQtStep);
        this.MinOrderQt(article.MinOrderQt);
        this.MaxOrderQt(article.MaxOrderQt);
        this.LeadTime(article.LeadTime);
        this.DealerPrice(article.DealerPrice);
        this.CustomerPrice(article.CustomerPrice);
        this.Currency(article.Currency);
        this.UOM(article.UOM);
        this.Kit(article.Kit);
        this.State(article.State);
        this.LastModification(moment(article.LastModification || new Date()).toDate());
        this.DiscountFamily(article.DiscountFamily);
        this.StatisticFamily(article.StatisticFamily);
        this.Hallway(article.Hallway);
        this.Shelf(article.Shelf);
        this.Ledge(article.Ledge);
        //this.WarehousePosition(article.WarehousePosition);

        this.FKPurchaseType(article.FKPurchaseType);
        this.PurchaseType(article.PurchaseType);

        this.DiscountFamiliesDataSource.setManufacturerId(article.ManufacturerId);
        this.StatisticFamiliesDataSource.setManufacturerId(article.ManufacturerId);

        this.isLoading = false;
    }

    public getData(): IArticleWithPosition {
        const article = <IArticleWithPosition>$.extend({}, this.article);

        article.ManufacturerId = this.ManufacturerId();
        article.CatalogId = this.CatalogId();

        article.Code = this.Code();
        article.EanCode = this.EanCode();
        article.ManufacturerCode = this.ManufacturerCode();
        article.Description = this.Description();
        article.ExtendedDescription = this.ExtendedDescription();
        article.BoxQuantity = this.BoxQuantity();
        article.OrderQtStep = this.OrderQtStep();
        article.MinOrderQt = this.MinOrderQt();
        article.MaxOrderQt = this.MaxOrderQt();
        article.LeadTime = this.LeadTime();
        article.DealerPrice = this.DealerPrice();
        article.CustomerPrice = this.CustomerPrice();
        article.Currency = this.Currency();
        article.UOM = this.UOM();
        article.Kit = this.Kit();
        article.State = this.State();
        article.LastModification = this.LastModification();
        article.DiscountFamily = this.DiscountFamily();
        article.StatisticFamily = this.StatisticFamily();
        article.Hallway = this.Hallway();
        article.Shelf = this.Shelf();
        article.Ledge = this.Ledge();
        article.WarehousePosition = this.WarehousePosition();
        article.FKPurchaseType = this.FKPurchaseType();
        article.PurchaseType = this.PurchaseType();

        return article;
    }

    public isChanged(): boolean {
        return !(<any>Object).identical(this.article, this.getData());
    }

    public newManufacturer(): Promise<void> {
        return new ManufacturerEditDialog().ShowModal();
    }

    public newCatalog(): Promise<void> {
        return new CatalogEditDialog().ShowModal();
    }
}
