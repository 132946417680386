import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/03/2016
 * Time: 14:29
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";

export class TaskStateBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed(() => {
                var taskStatus = ko.utils.unwrapObservable(underlyingObservable);
                switch(taskStatus) {
                    case -1:
                        return ProlifeSdk.TextResources.Todolist.Backlog;
                    case 0:
                        return ProlifeSdk.TextResources.Todolist.ToDo;
                    case 1:
                        return ProlifeSdk.TextResources.Todolist.InProgress;
                    case 2:
                        return ProlifeSdk.TextResources.Todolist.CompletedSingular;
                    case 3:
                        return ProlifeSdk.TextResources.Todolist.VerifiedSingular;
                    case 4:
                        return ProlifeSdk.TextResources.Todolist.Suspended;
                    case 5:
                        return ProlifeSdk.TextResources.Todolist.DeletedColumn;
                    default:
                        return ProlifeSdk.TextResources.Todolist.TaskCreation;
                }
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["taskStateText"] = new TaskStateBinding()
ko.virtualElements.allowedBindings["taskStateText"] = true;