import * as ko from "knockout";
export class SortableListElement
{
    floatingElement = null;
    container = null;
    element = null;
    options = null;
    elementViewModel = null;

    constructor(element : any, container : any, options : any, elementViewModel : any)
    {
        this.element = element;
        this.container = container;
        this.options = options;
        this.elementViewModel = elementViewModel;

        var anchor : any = $(element.find(".drag-anchor")[0]);
        anchor.hammer().on("dragstart", this.startDrag.bind(this));
        anchor.hammer().on("drag", this.onDrag.bind(this));
        anchor.hammer().on("dragend", this.endDrag.bind(this));
    }

    startDrag(event)
    {
        setTimeout(() => {
            $("body").disableSelection();
            this.container.addClass("scroll-disabled");

            var elementWidth = this.element.outerWidth();
            this.floatingElement = this.element.clone();
            this.floatingElement.css('width', elementWidth + 'px');

            if(this.options.onDragClass)
                this.floatingElement.addClass(this.options.onDragClass);

            this.floatingElement.css("position", "absolute");
            this.container.append(this.floatingElement);
            this.element.addClass(this.options.currentPositionClass);
        }, 0);
    }

    onDrag(event)
    {
        setTimeout(() => {
            event.preventDefault();
            event.stopPropagation();

            if(!event.gesture)
                return;

            var lastPosition = this.options.elements.indexOf(this.elementViewModel);
            var elementHeight = this.element.outerHeight();
            var positionsOffset = Math.round(event.gesture.deltaY / elementHeight);
            var newPosition = lastPosition + positionsOffset;
            newPosition = newPosition < 0 ? 0 : newPosition;
            newPosition = newPosition > this.options.elements().length - 1 ? this.options.elements().length - 1 : newPosition;

            $(this.container.find("." + this.options.childrenClass)
                .each(function(index, e){
                    if(index == newPosition)
                        $(e).addClass(this.options.highlightClass);
                    else
                        $(e).removeClass(this.options.highlightClass);
                }.bind(this)));

            this.floatingElement.offset({ top : event.gesture.center.pageY, left :this.element.offset().left });

        }, 0);
    }

    endDrag(event)
    {
        setTimeout(() => {
            $("body").enableSelection();
            this.container.removeClass("scroll-disabled");
            this.element.removeClass(this.options.currentPositionClass);
            this.floatingElement.remove();
            this.container.find("." + this.options.childrenClass)
                .removeClass(this.options.highlightClass);

            if(!event.gesture)
                return;

            var lastPosition = this.options.elements.indexOf(this.elementViewModel);
            var elementHeight = this.element.outerHeight();
            var positionsOffset = Math.round(event.gesture.deltaY / elementHeight);
            var newPosition = lastPosition + positionsOffset;
            newPosition = newPosition < 0 ? 0 : newPosition;
            newPosition = newPosition > this.options.elements().length - 1 ? this.options.elements().length - 1 : newPosition;

            this.options.elements.splice(newPosition, 0, this.options.elements.splice(lastPosition, 1)[0]);
            this.options.afterSortHandler();
        }, 0);
    }

}

export class DragForTouch
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        var options = valueAccessor();
        new SortableListElement($(element), $(element).parent(), options, viewModel);
    }
}

ko.bindingHandlers["draggableItem"] = new DragForTouch();