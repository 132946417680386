import * as ko from "knockout";

import * as ProlifeSdk from "../../ProlifeSdk";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ISearchResultsProvider } from "../../interfaces/ISearchResultsProvider";
import { IEntityProvider } from "../../interfaces/IEntityProvider";
import { IUserForHint } from "../../interfaces/users/IUser";
import { ITag, IEventTag } from "../../interfaces/ITag";
import { LazyImport } from "../../../Core/DependencyInjection";

export class EventUsersList implements ISearchResultsProvider
{
	private userEntityProvider : IEntityProvider<number, IUserForHint>;
	@LazyImport(nameof<IInfoToastService>())
	public infoToastService : IInfoToastService;
	@LazyImport(nameof<IEntityProviderService>())
	public entityProviderService : IEntityProviderService;

	private lastTimeout : ReturnType<typeof setTimeout>;
	private Tags : ko.Observable<string> = ko.observable(""); //Solo stringhe
    private tagsName : string;

	constructor(tagsName : string)
    {
        this.tagsName = tagsName;
		this.userEntityProvider = this.entityProviderService.getEntityProvider(ProlifeSdk.UsersEntityType);
	}

	private search(searchFilter : string, callback : (data : any) => void)
    {
		var data = { results: [] };

		var promise = this.userEntityProvider.hintSearch(searchFilter)
			.then((items : IUserForHint[]) => {

				if (items.length == 0)
					return;

				var groupResult = {
					text: ProlifeSdk.TextResources.ProlifeSdk.Users,
					children: []
				};
				data.results.push(groupResult);

				items.forEach((item: IUserForHint) => {
					var tag : ITag = <ITag> {
						TagName : this.tagsName,
						TagTypeId : this.userEntityProvider.getType(),
						Value : this.userEntityProvider.getPkValue(item)};

					groupResult.children.push({
						id: JSON.stringify(tag) + '#' + item.Name,
						text: item.Name
					});

				});
			})
			.catch(this.OnSearchFailed.bind(this));

		Promise.all([promise]).then(() => callback(data));
	}

	private searchById(serializedItem : string) {

		var result : any[] = [];
		var tags : string[] = serializedItem.split("|");
		tags.forEach((serializedTag : string) => {
			var tag : string[] = serializedTag.split("#");
			result.push({id: serializedTag,text: tag[1]});
		});

		return result;
	}

	private OnSearchFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any){
		this.infoToastService.Error(jqXHR.statusText);
	}

	public findSearchMatches(query : any)
    {
		if(this.lastTimeout) {
			clearTimeout(this.lastTimeout);
		}

		this.lastTimeout = setTimeout(() => {
			this.search(query.term, query.callback);
		}, 500);
	}

	public findSearchMatch(element, callback)
    {
		var id = <string>$(element).val();
		if(id !== "") {
			var result = this.searchById(id);
			callback(result);
		}
	}

	public addElements(elements: IEventTag[])
    {
		var result = elements.map((tag : IEventTag) => {return JSON.stringify(tag.getTag()) + "#" + tag.displayName()}).join("|");
		if(this.Tags().length > 0)
			this.Tags(this.Tags() + "|" + result);
		else
			this.Tags(result);
	}

	public getElement() : ITag[]{

		if (this.Tags().length ==0)
			return [];

		var tags : string[] = this.Tags().split("|");

		return tags.map((serializedTag : string) => {
			return JSON.parse(serializedTag.split("#")[0]);
		});
	}

	public clear()
    {
		return this.Tags("");
	}
}