import * as moment from "moment";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { JobOrderEntityProvider } from "./jobOrder/entityProviders/JobOrderEntityProvider";
import { JobOrderApplication } from "./jobOrder/JobOrderApplication";
import { JobOrderStateSettingsManager } from "./jobOrder/settings/JobOrderStateSettingsManager";
import { JobOrderGeneralSettingsManager } from "./jobOrder/settings/JobOrderGeneralSettingsManager";
import { JobOrderServiceUIProvider } from "./jobOrder/JobOrderServiceUIProvider";
import { JobOrderTypesSettingsManager } from "./jobOrder/settings/JobOrderTypesSettingsManager";
import { JobOrderBlockMotivationsSettingsManager } from "./jobOrder/settings/JobOrderBlockMotivationsSettingsManager";
import { JobOrderReportsProvider } from "./jobOrder/reports/JobOrderReportsProvider";
import { JobOrdersAndProjectsSearchProvider } from "./jobOrder/providers/JobOrdersAndProjectsSearchProvider";
import { ProtocolDefaultValuesProviderByJobOrder } from "./jobOrder/documents/providers/ProtocolDefaultValuesProviderByJobOrder";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { BlogEvent } from "../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { BlogEventBase } from "../ProlifeSdk/prolifesdk/blog/BlogEventBase";
import { GitCommitEvent } from "./jobOrder/ui/events/GitCommitEvent";
import { JobOrderStatusChangeEvent } from "./jobOrder/ui/events/JobOrderStatusChangeEvent";
import { SalEvent } from "./jobOrder/ui/events/SalEvent";
import { JobOrderDropDownList } from "./jobOrder/ui/dropdownlist/JobOrderDropDownList";
import { JobOrderRefUiForRemind } from "./jobOrder/ui/reminds-refs/JobOrderRefUiForRemind";
import { JobOrderEntityCodeGeneratorSettingsManager } from "./jobOrder/settings/JobOrderEntityCodeGeneratorSettingsManager";
import { JobOrderGeneralInfoFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderGeneralInfo";
import { JobOrderDocumentsFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderDocuments";
import {
    JobOrderDocumentExpiriesFactory,
    EditExpiryDialog,
} from "./jobOrder/ui/job-order-detail/panels/JobOrderDocumentExpiries";
import { JobOrderTasksFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderTasks";
import { JobOrderActivitiesTreeFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderActivitiesTree";
import { JobOrderProjectsFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderProjects";
import { JobOrderBudgetFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderBudget";
import { JobOrderCostsRevenuesFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderCostsRevenues";
import { JobOrderPerformanceFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderPerformance";
import { JobOrderWorkHistoryFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderWorkHistory";
import { JobOrderAdministrativeReportFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderAdministrativeReport";
import { PurchaseTypeSettingManager } from "../SettingManagers/PurchaseTypeSettingManager";
import { JobOrderMetadataSettingsManager } from "./jobOrder/settings/JobOrderMetadataSettingsManager";
import { JobOrderCostsPlanningFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderCostsPlanning";
import { LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService } from "../Invoices/DocumentsService";
import {
    IJobOrderService,
    IJobOrderSectionsDefaultSettings,
    IJobOrderLockInfo,
    IEventfulJobOrder,
    IJobOrderAnalysis,
    IJobOrderEconomics,
    IJobOrderHistoricalEconomics,
    IJobOrderEstimatedRevenues,
    IJobOrdersSplashPageFilters,
    IJobOrderForSplashWithBefore,
    IJobOrdersAndProjectsSearchResultsProvider,
    IDocumentExpire,
    IDocumentExpiryHistory,
    IJobOrderDocument,
    IWorkHistoryWorkflows,
    IWorkHistoryTasks,
    IWorkHistoryWork,
    IWorkHistoryRange,
    IWorkHistoryTaskWorkStates,
    IEstimatedRevenuesForReportingHoursAndPurchases,
    IAllocationDates,
    IResourceAllocation,
    IJobOrderWarehouseCosts,
    IJobOrderWorkHourCosts,
    IJobOrderPurchaseCosts,
    IJobOrderEstimate,
    IJobOrderSchedule,
    IGetJobOrderMetadataIdRequest,
    IJobOrderRolePrice,
    IGetJobOrdersListForAllocationsRequest,
    IJobOrderAllocationsDetails,
    IJobOrderOrdersDetails,
    IJobOrderWorkflowsDetails,
    IJobOrderForAdvancedExport,
    WorkflowAnalysis,
    WorkflowsAnalysisFilters,
} from "../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderEditorPanelFactory } from "../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IReminderService, IRemindReferenceObjFieldType } from "../ProlifeSdk/interfaces/reminder/IReminderService";
import { IContextEventsObserver } from "../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IJobOrderServiceUIProvider } from "../ProlifeSdk/interfaces/job-order/IJobOrderServiceUIProvider";
import {
    IApplicationsConfigurationsService,
    IApplicationConfiguration,
} from "../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IFiltersEngine } from "../Core/interfaces/IFiltersEngine";
import { IReportsService } from "../ProlifeSdk/interfaces/report/IReportsService";
import { IService } from "../Core/interfaces/IService";
import { ISearchContext } from "../Core/interfaces/ISearchContext";
import { IDropDownList } from "../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { IFinancialDataDetails } from "../ProlifeSdk/interfaces/job-order/IFinancialDataDetails";
import {
    IJobOrder,
    IJobOrderWithMetadataId,
    IJobOrderForHint,
    IEstimatedRevenue,
    IJobOrderForList,
    IJobOrderForAllocations,
    IJobOrderForSplash,
    IJobOrderForLimitedList,
    IJobOrderNew,
} from "../ProlifeSdk/interfaces/job-order/IJobOrder";
import {
    IBudgetRequestForList,
    IBudgetRequest,
    ITaskInfoForBudgetRequest,
} from "../ProlifeSdk/interfaces/job-order/IEstimatedBudgetNavigator";
import { ISearchResult } from "../Core/interfaces/ISearchResult";
import { IWordFound } from "../Core/interfaces/IWordFound";
import { IJobOrderFilter } from "../ProlifeSdk/interfaces/job-order/IJobOrderFilter";
import { IJobOrderRolesPrices } from "../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";
import { ILogEvent } from "../ProlifeSdk/interfaces/ILogEvent";
import { IView } from "../ProlifeSdk/interfaces/IView";
import { IJobOrderTypeWithCounter } from "../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderStateWithCounter } from "../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderRelatedElementsNumbers } from "../ProlifeSdk/interfaces/job-order/IJobOrderRelatedElementsNumbers";
import { Deferred } from "../Core/Deferred";
import { JobOrderDetailsFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderDetails";
import { JobOrderTreatmentsFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderGDPRTreatments";
import { JobOrderWorkedHoursPanelFactory } from "./jobOrder/ui/job-order-detail/panels/JobOrderWorkedHours";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { ResponseData, ResponseError } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IValidationException } from "../Core/interfaces/IException";

interface IBlogViewModels {
    [type: string]: new (
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver
    ) => BlogEventBase;
}

export class JobOrderService extends ProLifeService implements IJobOrderService {
    public ui: IJobOrderServiceUIProvider;

    @LazyImport(ProlifeSdk.ApplicationsConfigurationsServiceCode)
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    private ajaxService: IAjaxService;
    private searchContext: ISearchContext;
    private filtersEngine: IFiltersEngine;
    private application: JobOrderApplication;
    private reportsService: IReportsService;

    private eventsViewModels: IBlogViewModels = {};

    private editorPanelFactories: IJobOrderEditorPanelFactory[] = [];

    private actualJobOrderSectionsConfiguration: IJobOrderSectionsDefaultSettings;
    private jobOrderSectionsConfigurationLoaded = false;
    private savedJobOrderSectionsConfiguration: IApplicationConfiguration;

    private errorMessagesPerErrorCode = {
        WorkflowOutcomePreventTasksEditing: (workflowTitle: string) =>
            String.format(TextResources.JobOrder.WorkflowOutcomePreventTasksEditing, workflowTitle),
    };

    private errorHandlers = new Map<string, (error: unknown) => void>();

    private sectionsDefaultSettings: IJobOrderSectionsDefaultSettings = {
        WarehousesOpened: false,
        UserCharactersOpened: false,
        CallRightsPricesOpened: false,
        VatRegistersOpened: false,
        ActivitiesProgressOpened: false,
    };

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.JobOrderApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IJobOrderService>(), this);

        this.initializeErrorHandlers();
    }

    private initializeErrorHandlers() {
        this.errorHandlers.set("MissingDefaultMetadata", (_) =>
            this.infoToastService.Error(TextResources.JobOrder.MissingDefaultMetadata)
        );
        this.errorHandlers.set("ChangeNotAuthorized", (_) =>
            this.infoToastService.Error(TextResources.JobOrder.ChangeNotAuthorized)
        );
        this.errorHandlers.set("MissingMailRecipientEmail", (_) =>
            this.infoToastService.Error(TextResources.JobOrder.MissingMailRecipientEmail)
        );
        this.errorHandlers.set("InvalidMailRecipientEmail", (mailAddress: string) =>
            String.format(TextResources.JobOrder.InvalidMailRecipientEmail, mailAddress)
        );
        this.errorHandlers.set("JobOrdersDuplicatedCode", (code: string) =>
            String.format(ProlifeSdk.TextResources.JobOrder.DuplicatedCode, code)
        );
    }

    InitializeService() {
        super.InitializeService();

        new JobOrderBlockMotivationsSettingsManager(this.serviceLocator);
        new JobOrderTypesSettingsManager(this.serviceLocator);
        new JobOrderEntityProvider(this.serviceLocator);
        new JobOrderStateSettingsManager(this.serviceLocator);
        new JobOrderGeneralSettingsManager();
        new JobOrderEntityCodeGeneratorSettingsManager(this.serviceLocator);
        new PurchaseTypeSettingManager();
        new JobOrderMetadataSettingsManager();

        this.ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);
        this.reportsService = <IReportsService>this.serviceLocator.findService(ProlifeSdk.ReportsServiceType);

        this.filtersEngine = <IFiltersEngine>this.serviceLocator.findService(ServiceTypes.Search);
        this.searchContext = this.filtersEngine.CreateContext();
        this.searchContext.addStringFilter("Name", ProlifeSdk.TextResources.JobOrder.Name);
        this.searchContext.addStringFilter("Description", ProlifeSdk.TextResources.JobOrder.Description);

        const entitiesService: IEntityProviderService = <IEntityProviderService>(
            this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );

        entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode: ProlifeSdk.JobOrderEntityTypeCode,
            EntityName: ProlifeSdk.TextResources.JobOrder.Order,
            PluralEntityName: ProlifeSdk.TextResources.JobOrder.Orders,
            EntityIsMale: false,
            CanBeRelatedToRemind: true,
        });

        this.ui = new JobOrderServiceUIProvider(this.serviceLocator);
        this.application = new JobOrderApplication(this.serviceLocator);

        this.eventsViewModels[ProlifeSdk.GitCommitEventType] = GitCommitEvent;
        this.eventsViewModels[ProlifeSdk.JobOrderStatusChangeEventType] = JobOrderStatusChangeEvent;
        this.eventsViewModels[ProlifeSdk.SalEventType] = SalEvent;

        this.registerEditorPanel(new JobOrderGeneralInfoFactory("#0376cc"));
        this.registerEditorPanel(new JobOrderDocumentsFactory(this.serviceLocator, "#762dff"));
        this.registerEditorPanel(new JobOrderDetailsFactory(this.serviceLocator, "#762dff"));
        this.registerEditorPanel(new JobOrderDocumentExpiriesFactory(this.serviceLocator, "#762dff"));
        this.registerEditorPanel(new JobOrderTreatmentsFactory(this.serviceLocator, "#762dff"));

        this.registerEditorPanel(new JobOrderTasksFactory(this.serviceLocator, "#FFBC00"));
        this.registerEditorPanel(new JobOrderCostsPlanningFactory("#FFBC00"));
        this.registerEditorPanel(new JobOrderActivitiesTreeFactory(this.serviceLocator, "#FFBC00"));
        this.registerEditorPanel(new JobOrderProjectsFactory(this.serviceLocator, "#FFBC00"));
        this.registerEditorPanel(new JobOrderWorkedHoursPanelFactory(this.serviceLocator, "#FFBC00"));

        this.registerEditorPanel(new JobOrderBudgetFactory(this.serviceLocator, "#2db200"));

        this.registerEditorPanel(new JobOrderCostsRevenuesFactory("red"));
        this.registerEditorPanel(new JobOrderPerformanceFactory(this.serviceLocator, "red"));
        this.registerEditorPanel(new JobOrderWorkHistoryFactory(this.serviceLocator, "red"));

        this.registerEditorPanel(new JobOrderAdministrativeReportFactory(this.serviceLocator, "red"));

        new JobOrderRefUiForRemind(this.serviceLocator);

        const remindService: IReminderService = <IReminderService>(
            this.serviceLocator.findService(ProlifeSdk.ReminderServiceType)
        );
        const fields: IRemindReferenceObjFieldType[] = [];
        fields.push({
            Id: 1,
            Description: ProlifeSdk.TextResources.JobOrder.EntityFieldNameTitle,
            IsForTitle: true,
            IsForExpireDate: false,
        });
        fields.push({
            Id: 2,
            Description: ProlifeSdk.TextResources.JobOrder.EntityFieldNameStartDate,
            IsForTitle: false,
            IsForExpireDate: true,
        });
        fields.push({
            Id: 3,
            Description: ProlifeSdk.TextResources.JobOrder.EntityFieldNameExpireDate,
            IsForTitle: false,
            IsForExpireDate: true,
        });
        remindService.registerObjReferenceFields(ProlifeSdk.JobOrderEntityTypeCode, fields);

        this.actualJobOrderSectionsConfiguration = {
            WarehousesOpened: this.sectionsDefaultSettings.WarehousesOpened,
            UserCharactersOpened: this.sectionsDefaultSettings.UserCharactersOpened,
            CallRightsPricesOpened: this.sectionsDefaultSettings.CallRightsPricesOpened,
            VatRegistersOpened: this.sectionsDefaultSettings.VatRegistersOpened,
            ActivitiesProgressOpened: this.sectionsDefaultSettings.ActivitiesProgressOpened,
        };
    }

    getJobOrdersDropDown(): IDropDownList {
        return new JobOrderDropDownList(this.serviceLocator);
    }

    public OnServerInitializationDataLoaded() {
        //Ignoro il caricamento dei report e altre info accessorie se il modulo non è abilitato
        if (!this.IsServiceEnabled()) return;

        this.reportsService.registerReportsProvider(new JobOrderReportsProvider(this.serviceLocator));

        const documentsService: IDocumentsService = <IDocumentsService>(
            this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType)
        );
        documentsService.getRegisteredDocumentProviders().forEach((p: IDocumentsProvider) => {
            p.RegisterDefaultValuesProvider(new ProtocolDefaultValuesProviderByJobOrder());
        });
    }

    getJobOrderUrl(jobOrderId: number): string {
        return "#/" + ProlifeSdk.TextResources.JobOrder.OrdersURL + "/" + jobOrderId;
    }

    getFinancialDataDetails(jobOrderId: number, workflowId: number): Promise<IFinancialDataDetails> {
        if (!jobOrderId) return Promise.reject<IFinancialDataDetails>([]);

        return this.ajaxService.Post("JobOrder-api/FinancialData", "GetFinancialDataDetails", {
            methodData: {
                jobOrderId: jobOrderId,
                workflowId: workflowId,
            },
            background: true,
        });
    }

    async get(jobOrderId: number): Promise<IJobOrder> {
        // TODO non si dovrebbe mai ritornare null o undefined ma lanciare un'eccezione in caso di errore
        if (!jobOrderId) {
            return undefined;
        }

        const getResponse = await this.ajaxServiceNew.Get<ResponseData<IJobOrderNew>>(
            "j/jobOrder",
            jobOrderId.toString(),
            {}
        );
        if (!getResponse.succeeded) return null;

        const jobOrder = toPascalCase(getResponse.data);
        console.log("JobOrder get: ", jobOrder);
        return jobOrder;
    }

    GetBudgetRequestsForList(jobOrderId: number): Promise<IBudgetRequestForList[]> {
        return this.ajaxService.Post("JobOrder-api/BudgetRequest", "GetBudgetRequestsForList", {
            methodData: { JobOrderId: jobOrderId },
        });
    }

    GetBudgetRequest(requestId: number): Promise<IBudgetRequest> {
        return this.ajaxService.Get("JobOrder-api/BudgetRequest", "" + requestId, {});
    }

    SetLockStatusForJobOrders(jobOrdersLockInfo: IJobOrderLockInfo[]): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "SetLockStatusForJobOrders", {
            methodData: jobOrdersLockInfo,
        });
    }

    GetBudgetRequestForList(requestId: number): Promise<IBudgetRequestForList> {
        return this.ajaxService.Post("JobOrder-api/BudgetRequest", "GetBudgetRequestForList", {
            methodData: { RequestId: requestId },
        });
    }

    GetTasksInfoForBudgetRequest(requestId: number): Promise<ITaskInfoForBudgetRequest[]> {
        return this.ajaxService.Post("JobOrder-api/BudgetRequest", "GetTasksInfoForBudgetRequest", {
            methodData: { RequestId: requestId },
        });
    }

    GetTaskInfoForBudgetRequest(requestId: number, taskId: number): Promise<ITaskInfoForBudgetRequest> {
        return this.ajaxService.Post("JobOrder-api/BudgetRequest", "GetTaskInfoForBudgetRequest", {
            methodData: { RequestId: requestId, TaskId: taskId },
        });
    }

    InsertOrUpdateBudgetRequest(request: IBudgetRequest): Promise<IBudgetRequest> {
        return this.ajaxService.Post("JobOrder-api/BudgetRequest", "", {
            methodData: request,
        });
    }

    DeleteBudgetRequest(requestId: number): Promise<void> {
        return this.ajaxService.Delete("JobOrder-api/BudgetRequest", "" + requestId, {});
    }

    async insert(jobOrder: IJobOrderWithMetadataId): Promise<IJobOrder> {
        if (!jobOrder) {
            return Promise.reject<IJobOrder>(undefined);
        }

        try {
            let response: ResponseData<IJobOrderNew> = null;

            response = await this.ajaxServiceNew.Post("j/jobOrder", "", {
                methodData: {
                    Commessa: jobOrder,
                    MetadataId: jobOrder.MetadataId,
                },
            });

            if (!response.succeeded) {
                throw response.errors;
            }

            const insertedJobOrder = toPascalCase(response.data);
            console.log("insertedJobOrder", insertedJobOrder);
            return insertedJobOrder;
        } catch (e) {
            const errors = e as ResponseError[];
            if (errors) {
                this.handleResponseErrors(errors);
            }

            throw e;
        }
    }

    GetNameById(id: number): Promise<string> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "ResolveNameById", {
            methodData: {
                Id: id,
            },
        });
    }

    update(jobOrder: IJobOrderWithMetadataId): Promise<IJobOrder> {
        return this.insert(jobOrder);
    }

    delete(jobOrder: IJobOrder): Promise<void> {
        return this.ajaxService.Delete("JobOrder-api/JobOrder", "" + jobOrder.JobOrderId, {});
    }

    undelete(jobOrder: IJobOrder): Promise<IJobOrder> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "Undelete", {
            methodData: {
                Id: jobOrder.JobOrderId,
            },
        });
    }

    getJobOrdersWithIncompleteQuality(
        from: Date,
        to: Date,
        templatesTasks: number[],
        withoutTemplatesTasks: number[],
        stateId: number,
        typeId: number,
        showWithoutActivities: boolean
    ): Promise<IEventfulJobOrder[]> {
        return this.ajaxService.Post("JobOrder-api/Reports", "CalculateJobOrdersWithIncompleteQuality", {
            methodData: {
                From: from,
                To: to,
                EventTypes: [],
                TemplatesTasks: templatesTasks,
                WithoutTemplatesTasks: withoutTemplatesTasks,
                StateId: stateId,
                TypeId: typeId,
                ShowWithoutActivities: showWithoutActivities,
            },
        });
    }

    getJobOrdersAnalysis(ids: number[]): Promise<IJobOrderAnalysis[]> {
        return this.ajaxService.Post("JobOrder-api/Reports", "CalculateJobOrdersAnalysis", {
            methodData: { JobOrdersIds: ids },
        });
    }

    getEventfulJobOrders(
        from: Date,
        to: Date,
        eventTypes: string[],
        templatesTasks: number[],
        stateId: number,
        typeId: number,
        includeNotBillableEvents: boolean,
        hasNotBilledDdt: boolean
    ): Promise<IEventfulJobOrder[]> {
        return this.ajaxService.Post("JobOrder-api/Reports", "CalculateEventfulJobOrders", {
            methodData: {
                From: moment(from).startOf("day").toDate(),
                To: moment(to).endOf("day").toDate(),
                EventTypes: eventTypes,
                TemplatesTasks: templatesTasks,
                StateId: stateId,
                TypeId: typeId,
                IncludeNotBillable: includeNotBillableEvents,
                HasNotBilledDdt: hasNotBilledDdt,
            },
        });
    }

    hintSearch(
        value: string,
        typeFilter?: number,
        customerId?: number,
        excludeClosed?: boolean
    ): Promise<IJobOrderForHint[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "HintSearch", {
            methodData: {
                Value: value,
                TypeFilter: typeFilter,
                CustomerFilter: customerId,
                ExcludeClosed: excludeClosed,
            },
            background: true,
        });
    }

    GetJobOrderEconomicsData(jobOrderId: number, workflowId: number): Promise<IJobOrderEconomics> {
        return this.ajaxService.Post("JobOrder-api/EconomicsData", "GetEconomicsData", {
            methodData: {
                jobOrderId: jobOrderId,
                workflowId: workflowId,
            },
            background: true,
        });
    }

    GetJobOrderHistoricalEconomics(jobOrderId: number, workflowId: number): Promise<IJobOrderHistoricalEconomics> {
        return this.ajaxService.Post("JobOrder-api/EconomicsData", "GetHistoricalEconomics", {
            methodData: {
                jobOrderId: jobOrderId,
                workflowId: workflowId,
            },
            background: true,
        });
    }

    GetJobOrderEstimatedRevenues(jobOrderId: number, workflowId: number): Promise<IJobOrderEstimatedRevenues> {
        return this.ajaxService.Get(
            "JobOrder-api/EstimatedRevenues",
            "GetJobOrderEstimatedRevenues?jobOrderId=" + jobOrderId + "&workflowId=" + workflowId,
            {
                background: true,
            }
        );
    }

    UpdateJobOrderEstimatedRevenues(jobOrderId: number, estimatedRevenues: IEstimatedRevenue[]): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/EstimatedRevenues", "UpdateJobOrderEstimatedRevenues", {
            methodData: {
                JobOrderId: jobOrderId,
                EstimatedRevenues: estimatedRevenues,
            },
            background: true,
        });
    }

    hintWordsSearch(value: string): Promise<string[]> {
        const def = new Deferred<string[]>();

        this.ajaxService
            .Get("JobOrder-api/JobOrder", "HintSearch?value=" + encodeURIComponent(value), {
                background: true,
            })
            .then((jobOrders: IJobOrder[]) => {
                this.searchContext.items = jobOrders;
                const result: ISearchResult = this.filtersEngine.Search(this.searchContext, value);
                def.resolve(result.wordsFound.map((word: IWordFound) => word.word));
            })
            .catch(() => {
                //console.log('i should be never called');
                def.reject();
            });

        return def.promise();
    }

    detailedSearch(jobOrderFilters: IJobOrderFilter[], skip: number, count: number): Promise<IJobOrder[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "DetailedSearch", {
            methodData: { skip: skip, count: count, jobOrderFilters: jobOrderFilters },
            background: true,
        });
    }

    GetJobOrderForList(id: number): Promise<IJobOrderForList> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetJobOrderForList", {
            methodData: { Id: id },
            background: true,
        });
    }

    GetJobOrdersForList(ids: number[]): Promise<IJobOrderForList[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetJobOrdersForList", {
            methodData: { Ids: ids },
            background: true,
        });
    }

    GetJobOrderForAllocationsList(id: number): Promise<IJobOrderForAllocations> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetJobOrderForAllocationsList", {
            methodData: { Id: id },
        });
    }

    GetJobOrdersList(
        stateId: number,
        typeId: number,
        logicalStateId: number,
        getDeleted: boolean,
        textFilter: string,
        customerId?: number,
        workflowType = -1,
        workflowState = -1,
        skip = 0,
        count = 1000000,
        viewAll: boolean = null
    ): Promise<IJobOrderForList[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetJobOrdersList", {
            methodData: {
                StateId: stateId,
                TypeId: typeId,
                LogicalStateId: logicalStateId,
                GetDeleted: getDeleted,
                TextFilter: textFilter,
                CustomerId: customerId,
                WorkflowType: workflowType,
                WorkflowState: workflowState,
                Skip: skip,
                Count: count,
                ViewAll: viewAll,
            },
            background: true,
        });
    }

    async UpdateFromSplash(jobOrder: IJobOrderForSplash, filters: IJobOrdersSplashPageFilters): Promise<boolean> {
        try {
            const result = await this.ajaxService.Post<boolean>("JobOrder-api/SplashPage", "UpdateFromSplash", {
                methodData: {
                    JobOrder: jobOrder,
                    Filters: {
                        TextFilters: filters.TextFilters,
                        WorkflowsFilters: filters.WorkflowsFilters,
                        ResponsiblesIds: filters.ResponsiblesIds,
                        CustomersIds: filters.CustomersIds,
                        StatesIds: filters.StatesIds,
                        TypeId: filters.TypeId,
                        LogicalStateId: filters.LogicalStateId,
                        StateId: filters.StateId,
                        Types: filters.Types,
                        States: filters.States,
                    },
                },
            });

            return result;
        } catch (e) {
            const ve = e as IValidationException;
            if (ve?.ExceptionCode === 50700) {
                let message = "<br />";

                message += this.handleTasksUpdateErrors(ve);
                this.infoToastService.Error(
                    String.format(TextResources.JobOrder.CloseJobOrderActivitiesError, message)
                );
            }

            return true;
        }
    }

    private handleTasksUpdateErrors(ve: IValidationException) {
        let message = "";

        for (const workflowErrors of ve.ValidationData) {
            for (const error of workflowErrors.Errors) {
                if (this.errorMessagesPerErrorCode[error.Code])
                    message += this.errorMessagesPerErrorCode[error.Code](workflowErrors.WorkflowTitle) + "<br />";
            }
        }

        return message;
    }

    GetNextItem(jobOrderId: number, filters: IJobOrdersSplashPageFilters): Promise<IJobOrderForSplash> {
        return this.ajaxService.Post("JobOrder-api/SplashPage", "GetNextItem", {
            methodData: {
                Id: jobOrderId,
                Filters: {
                    TextFilters: filters.TextFilters,
                    WorkflowsFilters: filters.WorkflowsFilters,
                    ResponsiblesIds: filters.ResponsiblesIds,
                    CustomersIds: filters.CustomersIds,
                    StatesIds: filters.StatesIds,
                    TypeId: filters.TypeId,
                    LogicalStateId: filters.LogicalStateId,
                    StateId: filters.StateId,
                    Types: filters.Types,
                    States: filters.States,
                },
            },
            background: true,
        });
    }

    GetItemWithBefore(jobOrderId: number, filters: IJobOrdersSplashPageFilters): Promise<IJobOrderForSplashWithBefore> {
        return this.ajaxService.Post("JobOrder-api/SplashPage", "GetItemWithBefore", {
            methodData: {
                Id: jobOrderId,
                Filters: {
                    TextFilters: filters.TextFilters,
                    WorkflowsFilters: filters.WorkflowsFilters,
                    ResponsiblesIds: filters.ResponsiblesIds,
                    CustomersIds: filters.CustomersIds,
                    StatesIds: filters.StatesIds,
                    TypeId: filters.TypeId,
                    LogicalStateId: filters.LogicalStateId,
                    StateId: filters.StateId,
                    Types: filters.Types,
                    States: filters.States,
                },
            },
            background: true,
        });
    }

    GetJobOrdersForSplash(
        filters: IJobOrdersSplashPageFilters,
        skip: number,
        count: number,
        background = true
    ): Promise<IJobOrderForSplash[]> {
        return this.ajaxService.Post("JobOrder-api/SplashPage", "GetJobOrdersForSplash", {
            methodData: {
                TextFilters: filters.TextFilters,
                WorkflowsFilters: filters.WorkflowsFilters,
                ResponsiblesIds: filters.ResponsiblesIds,
                CustomersIds: filters.CustomersIds,
                StatesIds: filters.StatesIds,
                TypeId: filters.TypeId,
                LogicalStateId: filters.LogicalStateId,
                StateId: filters.StateId,
                Types: filters.Types,
                States: filters.States,
                Skip: skip,
                Count: count,
            },
            background: background,
        });
    }

    GetJobOrdersForSplashCount(filters: IJobOrdersSplashPageFilters, background?: boolean): Promise<number> {
        return this.ajaxService.Post("JobOrder-api/SplashPage", "GetJobOrdersForSplashCount", {
            methodData: {
                TextFilters: filters.TextFilters,
                WorkflowsFilters: filters.WorkflowsFilters,
                ResponsiblesIds: filters.ResponsiblesIds,
                CustomersIds: filters.CustomersIds,
                StatesIds: filters.StatesIds,
                TypeId: filters.TypeId,
                LogicalStateId: filters.LogicalStateId,
                StateId: filters.StateId,
                Types: filters.Types,
                States: filters.States,
                Skip: 0,
                Count: 0,
            },
            background: background,
        });
    }

    // N.B. le mansioni del cliente sono già gestite da questa API e vengono restituite se non sono configurate mansioni sulla commessa
    getRolesConfiguredOnJobOrder(jobOrderId: number): Promise<IJobOrderRolesPrices[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetRolesConfiguredOnJobOrder", {
            methodData: { Id: jobOrderId },
            background: true,
        });
    }

    getServiceType(): string {
        return ProlifeSdk.JobOrderServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetJobOrdersAndProjectsSearchProvider(): IJobOrdersAndProjectsSearchResultsProvider {
        return new JobOrdersAndProjectsSearchProvider(this.serviceLocator);
    }

    getUIForEvent(item: ILogEvent, contextEventsObserver: IContextEventsObserver): IView {
        const type = this.eventsViewModels[item.EventType] || BlogEvent;

        const event: BlogEventBase = new type(this.serviceLocator, contextEventsObserver);
        event.LoadItemFromServerObject(item);
        return <IView>event;
    }

    registerEditorPanel(factory: IJobOrderEditorPanelFactory) {
        this.editorPanelFactories.push(factory);
    }

    getRegisteredEditorPanelFactories(): IJobOrderEditorPanelFactory[] {
        return this.editorPanelFactories;
    }

    getJobOrderTypesWithCounters(userId: number, viewAll: boolean): Promise<IJobOrderTypeWithCounter[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderType", "GetJobOrderTypesWithCounters", {
            methodData: { userId: userId, viewAll: viewAll },
            background: true,
        });
    }

    getJobOrderStatesWithCounters(userId: number, viewAll: boolean): Promise<IJobOrderStateWithCounter[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderState", "GetJobOrderStatesWithCounters", {
            methodData: { userId: userId, viewAll: viewAll },
            background: true,
        });
    }

    changeState(jobOrderId: number, stateId: number): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "ChangeState", {
            methodData: { JobOrderId: jobOrderId, StateId: stateId },
            background: true,
        });
    }

    getDocumentExpires(jobOrderId: number, showAll: boolean): Promise<IDocumentExpire[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetDocumentExpires", {
            methodData: { JobOrderId: jobOrderId, ShowAll: showAll },
            background: true,
        });
    }

    getDocumentExpiryHistory(rowId: number, entityType: string): Promise<IDocumentExpiryHistory[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetDocumentExpiryHistory", {
            methodData: { RowId: rowId, EntityType: entityType },
            background: true,
        });
    }

    insertDocumentExpiryVariation(rowId: number, entityType: string, expiryDate: Date, note: string): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "InsertDocumentExpiryVariation", {
            methodData: { RowId: rowId, EntityType: entityType, ExpiryDate: expiryDate, Note: note },
            background: true,
        });
    }

    ShowEditExpireDialog(rowId: number, docType: string, canEdit = true): Promise<Date> {
        const fakeExpire: IDocumentExpire = {
            RowId: rowId,
            DueDate: null,
            Description: null,
            Amount: null,
            Uom: null,
            UnitPrice: null,
            Discounts: null,
            NetUnitPrice: null,
            TotalPrice: null,
            RowType: docType,
            DocumentId: null,
            ProtocolId: null,
            ProtocolName: null,
            DocumentDate: null,
            DocumentNumber: null,
            CustomerId: null,
            FormattedContactName: null,
            SourceWarehouseId: null,
            Ordine: null,
            IsClosed: false,
            IsExpired: false,
            IsChanged: false,
        };

        const dialog = new EditExpiryDialog(this.serviceLocator, fakeExpire);
        return dialog.ShowModal(canEdit);
    }

    RectifyTaskReportingState(jobOrderId: number): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "RectifyTaskReportingState", {
            methodData: { JobOrderId: jobOrderId },
            background: true,
        });
    }

    GetJobOrderDocuments(jobOrderId: number): Promise<IJobOrderDocument[]> {
        return this.ajaxService.Post("JobOrder-api/EconomicsData", "GetJobOrderDocuments", {
            methodData: { JobOrderId: jobOrderId },
            background: true,
        });
    }

    GetWorkHistoryWorkflows(jobOrderId: number): Promise<IWorkHistoryWorkflows[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkHistoryWorkflows", {
            methodData: { JobOrderId: jobOrderId },
            background: true,
        });
    }

    GetWorkHistoryTasks(workflowId: number): Promise<IWorkHistoryTasks[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkHistoryTasks", {
            methodData: { WorkflowId: workflowId },
            background: true,
        });
    }

    GetWorkHistoryWork(taskId: number): Promise<IWorkHistoryWork[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkHistoryWork", {
            methodData: { TaskId: taskId },
            background: true,
        });
    }

    GetWorkHistoryRange(jobOrderId: number): Promise<IWorkHistoryRange> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkHistoryRange", {
            methodData: { JobOrderId: jobOrderId },
            background: true,
        });
    }

    GetWorkHistoryTaskWorkStates(workflowId: number): Promise<IWorkHistoryTaskWorkStates[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkHistoryTaskWorkStates", {
            methodData: { WorkflowId: workflowId },
            background: true,
        });
    }

    async CheckOpenedTasksWorkedHoursAndDocuments(jobOrderId: number): Promise<boolean> {
        const elements = await this.ajaxService.Post<IJobOrderRelatedElementsNumbers[]>(
            "JobOrder-api/JobOrder",
            "checkOpenedTasksWorkedHoursAndDocuments",
            {
                methodData: {
                    JobOrderId: jobOrderId,
                },
            }
        );

        if (elements.length === 0) return true;

        let message = "";

        const labels = {
            TSK: ProlifeSdk.TextResources.JobOrder.ActivitiesText,
            PRC: ProlifeSdk.TextResources.JobOrder.PurchasesText,
            WRK: ProlifeSdk.TextResources.JobOrder.WorkedHoursText,
            SCD_0: ProlifeSdk.TextResources.JobOrder.UnpaidSchedulesText,
            SCD_2: ProlifeSdk.TextResources.JobOrder.CancelledSchedulesText,
            WAR: ProlifeSdk.TextResources.JobOrder.WarehousesText,
        };

        elements.forEach((m: IJobOrderRelatedElementsNumbers) => {
            if (message) message += ", ";

            if (labels[m.Type]) message += String.format(labels[m.Type], m.Number);
            else message += String.format(TextResources.JobOrder.DocumentsText, m.Number, m.EntityName);
        });

        return this.dialogsService.ConfirmAsync(
            String.format(TextResources.JobOrder.CloseJobOrderWithOpenedActivities, message),
            TextResources.JobOrder.CloseJobOrderWithOpenedActivitiesCancel,
            TextResources.JobOrder.OrderDeleteMsgConfirm
        );
    }

    GetEstimatedRevenuesForReportingHoursAndPurchases(
        jobOrderId: number,
        workflowId: number
    ): Promise<IEstimatedRevenuesForReportingHoursAndPurchases> {
        return this.ajaxService.Post(
            "JobOrder-api/EstimatedRevenues",
            "GetEstimatedRevenuesForReportingHoursAndPurchases",
            {
                methodData: {
                    jobOrderId: jobOrderId,
                    workflowId: workflowId,
                },
            }
        );
    }

    GetWorkflowAllocationDates(workflowId: number): Promise<IAllocationDates> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetWorkflowAllocationDates", {
            methodData: {
                WorkflowId: workflowId,
            },
        });
    }

    GetResourceAllocationRanges(resourceId: number, startDate: Date, endDate: Date): Promise<IResourceAllocation[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetResourceAllocationRanges", {
            methodData: {
                ResourceId: resourceId,
                StartDate: startDate,
                EndDate: endDate,
            },
        });
    }

    GetWarehouseCosts(
        jobOrderId: number,
        searchField: string,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        dateFrom: Date,
        dateTo: Date,
        source: string,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderWarehouseCosts[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetWarehouseCosts", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                DateFrom: dateFrom,
                DateTo: dateTo,
                Source: source,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportWarehouseCosts(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        dateFrom: Date,
        dateTo: Date,
        source: string,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportWarehouseCostsExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                DateFrom: dateFrom,
                DateTo: dateTo,
                Source: source,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetWorkHourCosts(
        jobOrderId: number,
        resource: number,
        role: number,
        type: number,
        dateFrom: Date,
        dateTo: Date,
        hoursFrom: number,
        hoursTo: number,
        costFrom: number,
        costTo: number,
        onlytoBeBilled: boolean,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderWorkHourCosts[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetWorkHourCosts", {
            methodData: {
                JobOrderId: jobOrderId,
                Resource: resource,
                Role: role,
                Type: type,
                DateFrom: dateFrom,
                DateTo: dateTo,
                HoursFrom: hoursFrom,
                HoursTo: hoursTo,
                CostFrom: costFrom,
                CostTo: costTo,
                OnlyToBeBilled: onlytoBeBilled,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportWorkHourCosts(
        templateId: string,
        jobOrderId: number,
        resource: number,
        role: number,
        type: number,
        dateFrom: Date,
        dateTo: Date,
        hoursFrom: number,
        hoursTo: number,
        costFrom: number,
        costTo: number,
        onlytoBeBilled: boolean,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportWorkHourCostsExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                Resource: resource,
                Role: role,
                Type: type,
                DateFrom: dateFrom,
                DateTo: dateTo,
                HoursFrom: hoursFrom,
                HoursTo: hoursTo,
                CostFrom: costFrom,
                CostTo: costTo,
                OnlyToBeBilled: onlytoBeBilled,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetPurchaseCosts(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlytoBeBilled: boolean,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderPurchaseCosts[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetPurchaseCosts", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyToBeBilled: onlytoBeBilled,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportPurchaseCosts(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlytoBeBilled: boolean,
        onlyImported: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportPurchaseCostsExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyToBeBilled: onlytoBeBilled,
                OnlyImported: onlyImported,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetEstimates(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderEstimate[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetEstimates", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportEstimates(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportEstimatesExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetCustomerOrders(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderEstimate[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetCustomerOrders", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportCustomerOrders(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportCustomerOrderExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetDDTs(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderEstimate[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetDDTs", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportDDTs(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportDDTsExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetInvoices(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderEstimate[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetInvoices", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportInvoices(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportInvoicesExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetSchedules(
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<IJobOrderSchedule[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrderAdministrativeReport", "GetSchedules", {
            methodData: {
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    ExportSchedules(
        templateId: string,
        jobOrderId: number,
        searchField: string,
        dateFrom: Date,
        dateTo: Date,
        amountFrom: number,
        amountTo: number,
        valueFrom: number,
        valueTo: number,
        onlyClosed: boolean,
        orderBy: string,
        orderAscDesc: string
    ): Promise<void> {
        return this.ajaxService.Download("JobOrder/JobOrderAdministativeReportSchedulesExport", "GenerateExcel", {
            methodData: {
                TemplateId: templateId,
                JobOrderId: jobOrderId,
                SearchField: searchField,
                DateFrom: dateFrom,
                DateTo: dateTo,
                AmountFrom: amountFrom,
                AmountTo: amountTo,
                ValueFrom: valueFrom,
                ValueTo: valueTo,
                OnlyClosed: onlyClosed,
                OrderBy: orderBy,
                OrderAscDesc: orderAscDesc,
            },
        });
    }

    GetJobOrdersLimitedList(
        textFilter: string,
        getDeleted: boolean,
        skip: number,
        count: number,
        getClosed = true
    ): Promise<IJobOrderForLimitedList[]> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "GetJobOrdersLimitedList", {
            methodData: {
                TextFilter: textFilter,
                GetDeleted: getDeleted,
                Skip: skip,
                Count: count,
                GetClosed: getClosed,
            },
        });
    }

    RefreshPerformance(jobOrderId: number): Promise<void> {
        return this.ajaxService.Post("JobOrder-api/JobOrder", "RefreshPerformance", {
            methodData: {
                JobOrderId: jobOrderId,
            },
        });
    }

    async saveJobOrderSectionsConfiguration(config: IJobOrderSectionsDefaultSettings): Promise<void> {
        const configToBeSaved: IApplicationConfiguration = $.extend(
            true,
            {},
            this.savedJobOrderSectionsConfiguration
        ) as IApplicationConfiguration;
        configToBeSaved.Configuration = JSON.stringify(config);
        configToBeSaved.ConfigurationType = ProlifeSdk.JobOrderSectionsConfigurationType;
        configToBeSaved.UserID = this.userInfo.getIdUser();

        if (!configToBeSaved.ModuleId) {
            const moduleId = await this.modulesService.getModuleId(ProlifeSdk.JobOrderApplicationCode);
            if (!moduleId) return;

            configToBeSaved.ModuleId = moduleId;
        }

        await this.applicationsConfigurationsService.AddOrUpdateConfiguration(configToBeSaved);

        this.actualJobOrderSectionsConfiguration = config;
    }

    async getJobOrderSectionsConfiguration(): Promise<IJobOrderSectionsDefaultSettings> {
        let configuration: IApplicationConfiguration;

        if (!this.jobOrderSectionsConfigurationLoaded) {
            const moduleId: number = await this.modulesService.getModuleId(ProlifeSdk.JobOrderApplicationCode);

            if (!moduleId) return this.sectionsDefaultSettings;

            const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(
                ProlifeSdk.JobOrderSectionsConfigurationType,
                this.userInfo.getIdUser(),
                moduleId,
                null
            );
            configuration = configurations && configurations.length > 0 ? configurations[0] : null;

            this.jobOrderSectionsConfigurationLoaded = true;

            if (!configuration || !configuration.Configuration) return this.actualJobOrderSectionsConfiguration;

            this.savedJobOrderSectionsConfiguration = configuration;
            const savedConfig: IJobOrderSectionsDefaultSettings = JSON.parse(configuration.Configuration);

            this.actualJobOrderSectionsConfiguration.WarehousesOpened = savedConfig.WarehousesOpened;
            this.actualJobOrderSectionsConfiguration.UserCharactersOpened = savedConfig.UserCharactersOpened;
            this.actualJobOrderSectionsConfiguration.CallRightsPricesOpened = savedConfig.CallRightsPricesOpened;
            this.actualJobOrderSectionsConfiguration.VatRegistersOpened = savedConfig.VatRegistersOpened;
            this.actualJobOrderSectionsConfiguration.ActivitiesProgressOpened = savedConfig.ActivitiesProgressOpened;
        }

        return this.actualJobOrderSectionsConfiguration;
    }

    GetJobOrderMetadataId(request: IGetJobOrderMetadataIdRequest): Promise<number> {
        return this.ajaxService.Post<number>("JobOrder-api/JobOrder", "GetJobOrderMetadataId", {
            methodData: request,
        });
    }

    GetRolesConfiguredOnJobOrders(JobOrdersIds: number[] | null): Promise<IJobOrderRolePrice[]> {
        return this.ajaxService.Post<IJobOrderRolePrice[]>("JobOrder-api/JobOrder", "GetRolesConfiguredOnJobOrders", {
            background: true,
            methodData: {
                JobOrdersIds: JobOrdersIds,
            },
        });
    }

    GetJobOrdersListForAllocations(
        request: IGetJobOrdersListForAllocationsRequest
    ): Promise<IJobOrderForAllocations[]> {
        return this.ajaxService.Post<IJobOrderForAllocations[]>(
            "JobOrder-api/JobOrder",
            "GetJobOrdersListForAllocations",
            {
                background: true,
                methodData: request,
            }
        );
    }

    GetJobOrderAllocationDetails(jobOrderId: number | null): Promise<IJobOrderAllocationsDetails[]> {
        return this.ajaxService.Post<IJobOrderAllocationsDetails[]>(
            "JobOrder-api/JobOrder",
            "GetJobOrderAllocationDetails",
            {
                background: true,
                methodData: {
                    jobOrderId: jobOrderId,
                },
            }
        );
    }

    PreviewCode(jobOrder: IJobOrderWithMetadataId): Promise<string> {
        if (!jobOrder) {
            return Promise.reject<string>(undefined);
        }

        return this.ajaxService.Post("JobOrder-api/JobOrder", "PreviewCode", {
            methodData: {
                Commessa: jobOrder,
                MetadataId: jobOrder.MetadataId,
                background: true,
            },
        });
    }

    GetAdvancedExportData(date: Date | null): Promise<IJobOrderForAdvancedExport[]> {
        const result = this.ajaxService.Post<IJobOrderForAdvancedExport[]>(
            "JobOrder-api/JobOrder",
            "GetAdvancedExportData",
            {
                background: true,
                methodData: {
                    date: date,
                },
            }
        );

        return result;
    }

    GetOrdersWorkflowsDetails(
        jobOrderId: number | null,
        workflowStatus: number | null,
        customerOrderId: number | null
    ): Promise<IJobOrderWorkflowsDetails[]> {
        const result = this.ajaxService.Post<IJobOrderWorkflowsDetails[]>(
            "JobOrder-api/JobOrder",
            "GetOrdersWorkflowsDetails",
            {
                background: true,
                methodData: {
                    jobOrderId: jobOrderId,
                    workflowStatus: workflowStatus,
                    customerOrderId: customerOrderId,
                },
            }
        );

        return result;
    }

    GetOrdersDetails(jobOrderId: number | null): Promise<IJobOrderOrdersDetails[]> {
        const result = this.ajaxService.Post<IJobOrderOrdersDetails[]>("JobOrder-api/JobOrder", "GetOrdersDetails", {
            background: true,
            methodData: {
                jobOrderId: jobOrderId,
            },
        });

        return result;
    }

    async getWorkflowsAnalysis(filters: WorkflowsAnalysisFilters): Promise<WorkflowAnalysis[]> {
        try {
            const response = await this.ajaxServiceNew.Post<ResponseData<WorkflowAnalysis[]>>(
                "j/workflows",
                "analysis",
                {
                    methodData: filters,
                }
            );

            if (!response.succeeded) {
                throw response.errors;
            }

            return response.data;
        } catch (e) {
            const errors = e as ResponseError[];

            if (errors) {
                this.handleResponseErrors(errors);
            } else {
                console.log(e);
            }

            return [];
        }
    }

    private handleResponseErrors(errors: ResponseError[]) {
        for (const error of errors) {
            const handler = this.errorHandlers.get(error.code);
            handler ? handler(error.metadata) : this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new JobOrderService(serviceLocator);
}
