import * as ko from "knockout";
export class StopBubble {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
        ko.utils.registerEventHandler(element, "click", function(event) {
            event.cancelBubble = true;
            if (event.stopPropagation)
                event.stopPropagation();
        });
    }
}

ko.bindingHandlers["stopBubble"] = new StopBubble();
