import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
import jss from "jss";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IPlanningService, IJobOrderPerformance } from "../../../ProlifeSdk/interfaces/planning/IPlanningService";
import { INavigationMenuObserver } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenu } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenu";
import { INavigationMenuProvider } from "../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ComponentUtils, PropsWithChildren } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { Column } from "../../../Components/TableComponent/CustomColumn";
import { Portlet } from "../../../Components/Portlet";
import { DashboardStat as DashboardStat, DashboardStatItem } from "../../../Components/DashboardStat";

const { classes } = jss.createStyleSheet({
    performanceDashboard: {
        "& .bordered": {
            borderBottom: "1px solid lightgray",
            marginBottom: "30px",
            paddingBottom: "10px"
        },

        "& .vertical-align-middle": {
            "& td": {
                verticalAlign: "middle !important",
                wordBreak: "break-all",
                whiteSpace: "initial !important"
            }
        },

        "& .planning-portlet": {
            padding: 0,
            marginBottom: "20px"
        }
    }
}).attach()

interface IExcelExportData {
    jobOrderCategories: number[];
    jobOrderStates: number[];
}

interface IPlanningJobOrderPerformanceEx extends IJobOrderPerformance {
    URL: string;
}

export class PerformanceViewModel implements INavigationMenuObserver
{
    public templateName = 'planningPerformance';
    public templateUrl = 'planning/templates';

    public ExporterId: string = "Planning/PlanningPerformanceExport";

    public categoriesMenu: INavigationMenu;
    public statesMenu: INavigationMenu;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IPlanningService>())
    private planningService: IPlanningService;

    public JobOrderTypes: ko.ObservableArray<number> = ko.observableArray([]);
    public JobOrderStates: ko.ObservableArray<number> = ko.observableArray([]);
    public AllPerformances: ko.ObservableArray<IPlanningJobOrderPerformanceEx> = ko.observableArray();

    public SearchFilter: ko.Observable<string> = ko.observable().extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });
    public Loading: ko.Observable<boolean> = ko.observable(false);

    public Performances: ko.Computed<IPlanningJobOrderPerformanceEx[]>;

    public ExpectedCosts: ko.Computed<number>;
    public RealCosts: ko.Computed<number>;
    public ExpectedRevenues: ko.Computed<number>;
    public RealRevenues: ko.Computed<number>;
    public ExpectedMarginProfit: ko.Computed<number>;
    public RealMarginProfit: ko.Computed<number>;

    constructor()
    {
        this.categoriesMenu = this.jobOrderService.ui.getTypesNavigationMenu();
        this.categoriesMenu.setMultipleSelectionStatus(true);
        this.categoriesMenu.addMenuObserver(this);

        this.statesMenu = this.jobOrderService.ui.getStatesNavigationMenu();
        this.statesMenu.setMultipleSelectionStatus(true);
        this.statesMenu.addMenuObserver(this);

        this.Performances = ko.computed(() => {
            if ((this.SearchFilter() || "").length == 0) {
                return this.AllPerformances();
            }

            let splits = this.SearchFilter().toUpperCase().split(" ");
            let filteredPerformances = this.AllPerformances().filter(p => {
                return splits.filter(s => p.Name.toUpperCase().indexOf(s) >= 0).length == splits.length;
            });

            return filteredPerformances;
        }).extend({ trackArrayChanges: true });

        let interceptor = ko.computed(() => {
            this.refresh();
        }).extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 500 } });

        this.ExpectedCosts = ko.computed(() => { return this.Performances().sum(p => p.EstimatedWorkCosts) });
        this.RealCosts = ko.computed(() => { return this.Performances().sum(p => p.TotalWorkCosts) });
        this.ExpectedRevenues = ko.computed(() => { return this.Performances().sum(p => p.ExpectedWorkRevenues) });
        this.RealRevenues = ko.computed(() => { return this.Performances().sum(p => p.WorkRevenues) });
        this.ExpectedMarginProfit = ko.computed(() => { return this.Performances().sum(p => p.ExpectedMarginOfProfit) });
        this.RealMarginProfit = ko.computed(() => { return this.Performances().sum(p => p.RealMarginOfProfit) });
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {
    }

    onSelectionChanged(selection: INavigationMenuProvider[], navigator: INavigationMenu)
    {
        if (navigator == this.categoriesMenu)
        {
            this.JobOrderTypes(selection.map(p => p.Id));
        }
        else if (navigator == this.statesMenu)
        {
            this.JobOrderStates(selection.map(p => p.Id));
        }
    }

    public async refresh(): Promise<void> {
        this.Loading(true);
        this.AllPerformances([]);

        try {
            let performance: IJobOrderPerformance[] = await this.planningService.GetJobOrdersPerformance(this.JobOrderStates(), this.JobOrderTypes());
            let exPreformance = performance.map(p => {
                let e = p as IPlanningJobOrderPerformanceEx;
                e.URL = this.jobOrderService.getJobOrderUrl(p.JobOrderId);

                return e;
            });

            this.AllPerformances(exPreformance);
            this.Loading(false);
        } catch(e) {
            this.Loading(false);
        }
    }

    public getExcelExportData(): IExcelExportData {
        return {
            jobOrderCategories: this.JobOrderTypes(),
            jobOrderStates: this.JobOrderStates()
        };
    }

    private createDataSourceModel(item: IJobOrderPerformance): IDataSourceModel<number, IJobOrderPerformance> {
        return {
            id: item.JobOrderId,
            title: item.Name,
            isGroup: false,
            isLeaf: true,
            model: item
        };
    }

    public render() {
        let performanceVM: PerformanceViewModel;
        let performanceRow: IDataSourceModel<number, IPlanningJobOrderPerformanceEx>;

        let CustomTableRow = (props: PropsWithChildren<unknown>) => {
            return  <tr data-bind={{ css: { 'info': performanceRow.model.ToApproveBudget > 0 } }}>
                        {props.children}
                    </tr>
        }

        let { sortString, sortNumber } = ComponentUtils.useSorter<IPlanningJobOrderPerformanceEx>();

        return ComponentUtils.bindTo((
            <LayoutWithHeader className={classes.performanceDashboard}>
                <LayoutHeader className="bordered flex-child-center">
                    <h3 className="flex-fill">{TextResources.Planning.JobOrdersPerformance}</h3>
                    <input type="text" className="form-control input-medium margin-right-10" placeholder="Cerca..." data-bind={{ value: performanceVM.SearchFilter, valueUpdate: 'afterkeydown' }} />
                    <excel-exporter exporterId={() => "performanceVM.ExporterId"} exporterMethod={'GenerateExcel'} dataProvider={() => "performanceVM.getExcelExportData.bind(performanceVM)"} position='left'>
                        <i className="fa fa-download"></i>&nbsp;
                        {TextResources.Planning.CostsAndRevenuesExport}
                    </excel-exporter>
                </LayoutHeader>
                <LayoutContent noOverflow={true}>
                    <Portlet initialCollapsed={false} portletTitle={TextResources.Planning.Details} flex={false} noMargin={true} noBorder={true} className="planning-portlet">
                        <Portlet.Body>
                            {() =>  <div className="row">
                                        <div className="col-md-4">
                                            <DashboardStat title={TextResources.Planning.Costs} color="red" style={{ height: "260px" }}>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.ExpectedCosts}>
                                                    <span data-bind={{ moneyText: performanceVM.ExpectedCosts }}></span>
                                                </DashboardStatItem>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.RealCosts}>
                                                    <span data-bind={{ moneyText: performanceVM.RealCosts }}></span>
                                                </DashboardStatItem>
                                            </DashboardStat>
                                        </div>
                                        <div className="col-md-4">
                                            <DashboardStat title={TextResources.Planning.Revenues} color="blue" style={{ height: "260px" }}>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.ExpectedRevenues}>
                                                    <span data-bind={{ moneyText: performanceVM.ExpectedRevenues }}></span>
                                                </DashboardStatItem>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.RealRevenues}>
                                                    <span data-bind={{ moneyText: performanceVM.RealRevenues }}></span>
                                                </DashboardStatItem>
                                            </DashboardStat>
                                        </div>
                                        <div className="col-md-4">
                                            <DashboardStat title={TextResources.Planning.MarginProfit} color="green" style={{ height: "260px" }}>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.ExpectedMarginProfit}>
                                                    <span data-bind={{ moneyText: performanceVM.ExpectedMarginProfit }}></span>
                                                </DashboardStatItem>
                                                <DashboardStatItem className="number" desc={TextResources.Planning.RealMarginProfit}>
                                                    <span data-bind={{ moneyText: performanceVM.RealMarginProfit }}></span>
                                                </DashboardStatItem>
                                            </DashboardStat>
                                        </div>
                                    </div>
                        }
                        </Portlet.Body>    
                    </Portlet>
                    <Table id={45} scrollable={true} compact={true} striped={true} bordered={true} fixedLayout={true} showLoadingIndicator={this.Loading} dataSource={{ array: this.Performances, factory: this.createDataSourceModel.bind(this) }} className="vertical-align-middle" rowAs="performanceRow" components={{ row: CustomTableRow }} showColumnSelector={true}>
                        <Column id={46} style={{ width: "300px" }} title="Commessa" sorter={sortString((p => p.Name))}>
                            <a target="blank" href="#" data-bind={{ text: performanceRow.model.Name, attr: { href: performanceRow.model.URL } }}></a>
                        </Column>
                        <Column id={47} title="Stato" sorter={sortString((p => p.State))}>
                            <span data-bind={{ text: performanceRow.model.State }}></span>
                        </Column>
                        <Column id={48} title="Tipologia" sorter={sortString((p => p.Type))}>
                            <span data-bind={{ text: performanceRow.model.Type }}></span>
                        </Column>
                        <Column id={49} className="text-right" style={{ width: "100px" }} title="Stimato" sorter={sortNumber((p => p.EstimatedWork))}>
                            <span data-bind={{ numberText: performanceRow.model.EstimatedWork, numberTextSuffix: 'h/u' }}></span>
                        </Column>
                        <Column id={50} className="text-right" style={{ width: "100px" }} title="Ristimato" sorter={sortNumber((p => p.ReestimatedWork))}>
                            <span data-bind={{ numberText: performanceRow.model.ReestimatedWork, numberTextSuffix: 'h/u' }}></span>
                        </Column>
                        <Column id={51} className="text-right" style={{ width: "100px" }} title="In Lavorazione" sorter={sortNumber((p => p.InProgressEstimate))}>
                            <span data-bind={{ numberText: performanceRow.model.InProgressEstimate, numberTextSuffix: 'h/u' }}></span>
                        </Column>
                        <Column id={52} className="text-right" style={{ width: "100px" }} title="Lavorato" sorter={sortNumber((p => p.WorkedHours))}>
                            <span data-bind={{ numberText: performanceRow.model.WorkedHours, numberTextSuffix: 'h/u' }}></span>
                        </Column>
                        <Column id={53} className="text-center" title="Velocità" data-bind="css: { 'danger': performanceRow.model.Speed < 0.8 || performanceRow.model.Speed > 1.5 }" sorter={sortNumber((p => p.Speed))}>
                            <span data-bind={{ numberText: performanceRow.model.Speed }}></span>
                        </Column>
                        <Column id={54} className="text-center" title="Avanz." sorter={sortNumber((p => p.Progress))}>
                            <span data-bind={{ percentageText: performanceRow.model.Progress }}></span>
                        </Column>
                        <Column id={55} className="text-right" title="Budget Approvato" sorter={sortNumber((p => p.ApprovedBudget))}>
                            <span data-bind={{ moneyText: performanceRow.model.ApprovedBudget }}></span>
                        </Column>
                        <Column id={56} className="text-center" title="Costo Atteso" sorter={sortNumber((p => p.EstimatedWorkCosts))}>
                            <span data-bind={{ moneyText: performanceRow.model.EstimatedWorkCosts }}></span>
                        </Column>
                        <Column id={57} className="text-center" title="Costo" sorter={sortNumber((p => p.TotalWorkCosts))}>
                            <span data-bind={{ moneyText: performanceRow.model.TotalWorkCosts }}></span>
                        </Column>
                        <Column id={58} className="text-center" title="Ricavo Atteso" sorter={sortNumber((p => p.ExpectedWorkRevenues))}>
                            <span data-bind={{ moneyText: performanceRow.model.ExpectedWorkRevenues }}></span>
                        </Column>
                        <Column id={59} className="text-center" title="Ricavo" sorter={sortNumber((p => p.WorkRevenues))}>
                            <span data-bind={{ moneyText: performanceRow.model.WorkRevenues }}></span>
                        </Column>
                        <Column id={60} className="text-center" title="Margine atteso" sorter={sortNumber((p => p.ExpectedMarginOfProfit))}>
                            <span data-bind={{ moneyText: performanceRow.model.ExpectedMarginOfProfit }}></span>
                        </Column>
                        <Column id={61} className="text-center" title="Margine atteso (%)" sorter={sortNumber((p => p.ExpectedMarginOfProfitPercentage))}>
                            <span data-bind={{ percentageText: performanceRow.model.ExpectedMarginOfProfitPercentage }}></span>
                        </Column>
                        <Column id={62} className="text-center" title="Margine effettivo" sorter={sortNumber((p => p.RealMarginOfProfit))}>
                            <span data-bind={{ moneyText: performanceRow.model.RealMarginOfProfit }}></span>
                        </Column>
                        <Column id={63} className="text-center" title="Margine effettivo (%)" sorter={sortNumber((p => p.RealMarginOfProfitPercentage))}>
                            <span data-bind={{ percentageText: performanceRow.model.RealMarginOfProfitPercentage }}></span>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>
        ), this, "performanceVM");
    }
}