import * as ko from "knockout";
var asyncTemplateLoader = {
    loadTemplate: (name, templateConfig, callback) => {
        if (!templateConfig.templateUrl) {
            callback(null);
            return;
        }

        infuser.get(templateConfig.templateUrl, (template: string) => {
            ko.components.defaultLoader.loadTemplate(name, template, callback);
        });
    }
}

ko.components.loaders.unshift(asyncTemplateLoader);