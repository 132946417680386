import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IOffsetsService, IDocumentOffset } from "../../../OffsetsService";
import { DocumentOffsetsDataSource } from "../../../../DataSources/DocumentOffsetsDataSource";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";

export enum DocumentOffsetsTypes {
    BalanceSheet = 0,
    IncomeStatement = 1,
    Accounts = 2
}

@DetectClassChanges
class DocumentOffset implements IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable(0);

    Id: number;

    IsNew : ko.Observable<boolean> = ko.observable();

    @DetectChanges
    Code : ko.Observable<string> = ko.observable();

    @DetectChanges
    Description : ko.Observable<string> = ko.observable();

    @DetectChanges
    Type : ko.Observable<number> = ko.observable();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService! : IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    
    @LazyImport(nameof<IOffsetsService>())
    private offsetsService : IOffsetsService;
    
    constructor(private model : IDocumentOffset, private editor : OffsetEditor) {
        this.load();
    }

    private load() {
        this.Id = this.model.Id;
        this.IsNew(!this.Id);

        this.Code(this.model.Code);
        this.Description(this.model.Description);
        this.Type(this.model.Type);

        if(!this.IsNew())
            this.isChanged(0);
    }

    dispose(): void {
        
    }

    public async Save() : Promise<void> {
        if (String.isNullOrUndefinedOrWhiteSpace(this.Description())) {
            this.infoToastService.Warning("Inserire una descrizione prima di salvare");
            return;
        }

        if (String.isNullOrUndefinedOrWhiteSpace(this.Code())) {
            this.infoToastService.Warning("Inserire un codice prima di salvare");
            return;
        }

        let model = $.extend({}, this.model, {
            Description: this.Description(),
            Code: this.Code(),
            Type: this.Type()
        });

        this.model = (await this.offsetsService.InsertOrUpdateOffset([model]))[0];
        this.editor.onOffsetSaved(this.model);
        this.load();
    }

    public async Cancel() {
        if(!await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse. Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche"))
            return;

        if(!this.Id) {
            this.editor.SelectedOffset(undefined);
            return;
        }

        this.load();
    }
}

export class OffsetEditor implements IDataSourceListener {
    public DocumentOffsetsDataSource : DocumentOffsetsDataSource = new DocumentOffsetsDataSource();
    SelectedOffset : ko.Observable<DocumentOffset> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IOffsetsService>())
    private offsetsService : IOffsetsService;

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(!model)
            this.SelectedOffset(undefined);
        else
            this.SelectedOffset(new DocumentOffset(model.model, this));
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    async canSelectItem(sender : IDataSource, model : IDataSourceModel): Promise<boolean> {
        if(!this.SelectedOffset() || this.SelectedOffset().isChanged() == 0)
            return true;
        
        return await this.dialogsService.ConfirmAsync("Sono presenti delle modifiche non salvate, continuando verranno perse. Sei sicuro di voler continuare?", "Annulla", "Perdi Modifiche");
    }

    public NewOffset() {
        let parentId = this.SelectedOffset() ? this.SelectedOffset().Id : null;
        this.SelectedOffset(new DocumentOffset({
            Id: 0,
            ParentId: parentId,
            Description: "",
            Code: "",
            Type: 0
        }, this));
    }

    public async DeleteSelected() {
        if(!this.SelectedOffset()) return;

        if(!await this.dialogsService.ConfirmAsync("Sei sicuro di voler eliminare la contropartita e tutti i suoi figli?", "Annulla", "Elimina"))
            return;

        await this.offsetsService.DeleteOffset(this.SelectedOffset().Id);

        this.DocumentOffsetsDataSource.refresh();
    }

    onOffsetSaved(model: IDocumentOffset) {
        this.DocumentOffsetsDataSource.refresh();
        this.DocumentOffsetsDataSource.select(model);
    }
}