import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import TsxForEach from "../ForEach";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IDataSourceModel, IDataSourceView, IDataSource, IDataSourceListener } from "../../DataSources/IDataSource";
import { ComponentUtils, PropsWithChildren, reloadNow } from "../../Core/utils/ComponentUtils";
import { SecondaryRow } from "./SecondaryRow";
import { ColumnSelectionPopover } from "./ColumnSelectionPopover";
import { Bind } from "../Bind";
import { If, With } from "../IfIfNotWith";
import { Delay } from "../../Decorators/Delay";
import { Column, ColumnBody, ColumnHeader } from "./CustomColumn";
import { TableFooterAggregationMode } from "./TableFooterAggregationMode";
import { LazyImport } from "../../Core/DependencyInjection";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { Layout } from "../Layouts";
import {
    isObservableArrayDataSource,
    ObservableArrayDataSource,
    synchronizeItems,
} from "../../DataSources/ObservableArrayDataSource";
import { CheckBox } from "../Checkbox";
import {
    IApplicationConfiguration,
    IApplicationsConfigurationsService,
} from "../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { TableFilter } from "./TableFilter";

const styleSheet = jss.createStyleSheet({
    "prolife-table": {
        width: "100%",
        margin: "0px !important",

        "&.no-header": {
            "& > thead": {
                height: "0px !important",

                "& > tr": {
                    height: "0px !important",
                },
            },
        },

        "&.hover": {
            "& > tbody": {
                "& tr": {
                    "&:hover": {
                        backgroundColor: "#d0dce8 !important",
                    },
                },
            },
        },

        "& .input-icon > i": {
            zIndex: "initial",
        },

        "& .rows-sorting-anchor": {
            minWidth: "50px !important",
            maxWidth: "50px !important",
            width: "50px !important",

            "& i": {
                cursor: "move",
            },
        },

        "& .row-selector": {
            minWidth: "60px !important",
            maxWidth: "60px !important",
            width: "60px !important",

            padding: "0px 5px !important",

            "& label": {
                margin: 0,
            },
        },

        "& .prolife-table-row": {
            "&.droppable-hover-before": {
                borderTop: "3px solid #4b8df8 !important",
            },

            "&.droppable-hover-after": {
                borderBottom: "3px solid #4b8df8 !important",
            },

            "& > td": {
                padding: 0,

                "& > span:not(.btn-link), & > a:not(.btn-link), & > p": {
                    padding: "0 5px !important",
                },
            },
        },

        "& .prolife-odd-row": {
            "& > td": {
                backgroundColor: "#f9f9f9",
            },

            "&:hover": {
                "& > td": {
                    backgroundColor: "#d0dce8 !important",
                },
            },
        },

        "& .prolife-even-row": {
            "&:hover": {
                "& > td": {
                    backgroundColor: "#d0dce8 !important",
                },
            },
        },

        "&.fixedLayout": {
            tableLayout: "fixed",
        },

        "&.scrollable > thead > tr > th": {
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 2,

            "&.sticky-column": {
                left: 0,
                zIndex: 3,
            },
        },

        "&.scrollable > tfoot > tr > td": {
            position: "sticky",
            bottom: 0,
            background: "white",
        },

        "&.scrollable > tbody > tr > td.sticky-column": {
            position: "sticky",
            left: "0px",
            background: "white",
            zIndex: 1,
        },

        "& > thead > tr > th, & .inner-table > thead > tr > th": {
            border: "0px",
            height: "29px",
            cursor: "default",

            "&.sortable": {
                background:
                    "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTRDMDM5NjkyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTRDMDM5NkEyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxNEMwMzk2NzIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxNEMwMzk2ODIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm8NGvcAAADkSURBVHjaYvz//z8DtQATAxUBCzbBu3fvInO5gLgNiMuA+BdMUFlZmSyXZQNxFhCnUupNLSDOA2JWIC4AOYhcwxiBuBiIZaB8FajBjOQY5gDEgWhiiUBsTaphvEBcC8SCWMRrgJidFMNCoC74gQU7AnEQ1nChZqLFlc4igdQCIP6HwzcZwHQ2n1hvrgPi/UDMgQUfBeI1pITZTyBuAeLPaOLvgbgZizjBpAFyAbpX1gPxAXLSGShmJgHxHSj/CRD3QsXJyk6gHD8BiH9DDb5GcmyigdlArArEUwkpZBy0hSNAgAEA5Ho0sMdEmU8AAAAASUVORK5CYII=) no-repeat right",
                backgroundPositionY: "calc(100% - 5px)",
                paddingRight: "20px",

                "&.sorting_asc": {
                    background:
                        "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxNjIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxNTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+z5ABTAAAAI5JREFUeNpi/P//PwO1ABMDFQELIQXVjfe4gFQbEJe11iv9otRl2UCcBcSphBQy4gszoKu0gNROIJYB4jtA7AF03V2SXQY0iBFIFUMNAgEVIM6DipPsTQcgDkQTSwRia5IMA9rOC6RqgVgQTQokXgOUZyfFZSFQF/zAgh2BOIjkCBjQRDtq2Khh9DAMIMAAT9AmNBDSXegAAAAASUVORK5CYII=) no-repeat right",
                    backgroundPositionY: "calc(100% - 5px)",
                },

                "&.sorting_desc": {
                    background:
                        "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxQTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxOTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+1fsfwAAAAJdJREFUeNpi/P//PwO1ABMDFcGoYaOG0cMwFmyC1Y33IoHUAiD+h8MBGa31SvOJddk6IN4PxBxY8FEgXkO0N4G2/gRSLUD8GU3qPRA3A+U/kxpmIBege2U9EB/ApYERX6kBDDtlILUDiFWA+AkQuwNddY2s2ARqvAukJgDxbyCehM8gnLGJBmYDsSoQTyWkkHHQFo4AAQYAAA0piq4hbqwAAAAASUVORK5CYII=) no-repeat right",
                    backgroundPositionY: "calc(100% - 5px)",
                },
            },

            "& .btn-group": {
                "&.header-btn": {
                    "& > .btn": {
                        border: "1px solid #ddd",
                        padding: 0,
                        fontWeight: 600,
                        marginLeft: "5px",
                        marginBottom: "2px",

                        "& > i": {
                            padding: "0 3px",
                        },
                    },
                },
            },

            "& .dropdown-menu": {
                "&.dropdown-checkboxes": {
                    "& > input[type=checkbox]": {
                        margin: "5px 5px 0px 5px",
                    },
                },
            },
        },

        "& .load-when-visible": {
            height: "28px",
        },

        "& > tfoot": {
            borderTop: "1px solid #ddd",
        },

        "& > tfoot, & .inner-table > tfoot": {
            "& > tr": {
                maxHeight: "29px",

                "& td": {
                    verticalAlign: "middle",
                    height: "29px",
                },
            },
        },

        "&.compact, & .inner-table.compact": {
            "& .rows-sorting-anchor": {
                minWidth: "30px !important",
                maxWidth: "30px !important",
                width: "30px !important",

                "& i": {
                    cursor: "move",
                },
            },

            "&.fixed-height": {
                "& > tbody > tr, & > thead > tr, & > tfoot > tr": {
                    height: "29px",
                },
            },

            "&:not(.hide-selection)": {
                "& > tbody": {
                    "& > tr": {
                        "&.selected": {
                            backgroundColor: "lightblue",

                            "& input, & select": {
                                backgroundColor: "lightblue",
                            },

                            "& .select2-choice": {
                                backgroundColor: "lightblue",
                            },

                            "& .select2-arrow": {
                                backgroundColor: "lightblue",
                            },

                            "& > td": {
                                backgroundColor: "lightblue",
                            },
                        },
                    },
                },
            },

            "& > tbody > tr, & > thead > tr, & > tfoot > tr": {
                minHeight: "29px",
            },

            "&:not(.no-last-border) > tbody:last-child > tr:last-child > td": {
                borderBottom: "1px solid #ddd",
            },

            "& > thead": {
                "& th": {
                    padding: "0px 5px",
                    fontSize: "12px",

                    "&.row-selector": {
                        "& > input[type=checkbox]": {
                            marginLeft: "5px",
                        },
                    },

                    "&.sortable": {
                        background:
                            "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTRDMDM5NjkyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTRDMDM5NkEyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxNEMwMzk2NzIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxNEMwMzk2ODIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm8NGvcAAADkSURBVHjaYvz//z8DtQATAxUBCzbBu3fvInO5gLgNiMuA+BdMUFlZmSyXZQNxFhCnUupNLSDOA2JWIC4AOYhcwxiBuBiIZaB8FajBjOQY5gDEgWhiiUBsTaphvEBcC8SCWMRrgJidFMNCoC74gQU7AnEQ1nChZqLFlc4igdQCIP6HwzcZwHQ2n1hvrgPi/UDMgQUfBeI1pITZTyBuAeLPaOLvgbgZizjBpAFyAbpX1gPxAXLSGShmJgHxHSj/CRD3QsXJyk6gHD8BiH9DDb5GcmyigdlArArEUwkpZBy0hSNAgAEA5Ho0sMdEmU8AAAAASUVORK5CYII=) no-repeat bottom right",
                        paddingRight: "20px",

                        "&.sorting_asc": {
                            background:
                                "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxNjIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxNTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+z5ABTAAAAI5JREFUeNpi/P//PwO1ABMDFQELIQXVjfe4gFQbEJe11iv9otRl2UCcBcSphBQy4gszoKu0gNROIJYB4jtA7AF03V2SXQY0iBFIFUMNAgEVIM6DipPsTQcgDkQTSwRia5IMA9rOC6RqgVgQTQokXgOUZyfFZSFQF/zAgh2BOIjkCBjQRDtq2Khh9DAMIMAAT9AmNBDSXegAAAAASUVORK5CYII=) no-repeat bottom right",
                        },

                        "&.sorting_desc": {
                            background:
                                "white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxQTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxOTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+1fsfwAAAAJdJREFUeNpi/P//PwO1ABMDFcGoYaOG0cMwFmyC1Y33IoHUAiD+h8MBGa31SvOJddk6IN4PxBxY8FEgXkO0N4G2/gRSLUD8GU3qPRA3A+U/kxpmIBege2U9EB/ApYERX6kBDDtlILUDiFWA+AkQuwNddY2s2ARqvAukJgDxbyCehM8gnLGJBmYDsSoQTyWkkHHQFo4AAQYAAA0piq4hbqwAAAAASUVORK5CYII=) no-repeat bottom right",
                        },
                    },
                },
            },

            "& .input-icon": {
                "& i": {
                    margin: "8px 2px 4px 7px",
                },
            },

            "&.bordered": {
                border: "1px solid #ddd",

                "& tr": {
                    "& > th:not(:last-child)": {
                        borderRight: "1px solid #ddd",
                    },

                    "& > th": {
                        "& .select2-container": {
                            "& .select2-choice": {
                                borderRight: "0px !important",
                            },
                        },
                    },

                    "& > td:not(:last-child)": {
                        borderRight: "1px solid #ddd",
                    },

                    "& > td": {
                        "& .select2-container": {
                            "& .select2-choice": {
                                borderRight: "0px !important",
                            },
                        },
                    },

                    "&:last-child": {
                        borderBottom: "1px solid #ddd",
                    },
                },
            },

            "& > tbody": {
                "& > tr > td:first-child:not(.form)": {
                    "& input[type=text], & input[type=hidden], & select": {
                        borderLeft: "none",
                    },

                    "&.no-border": {
                        "& input[type=text], & input[type=hidden], & select": {
                            borderRight: "none",
                        },
                    },
                },

                "& > tr": {
                    height: "1px",

                    "& > td": {
                        borderBottom: "1px solid #ddd",
                    },
                },

                "& > tr > td:not(.form)": {
                    padding: 0,
                    fontSize: "13px",
                    whiteSpace: "nowrap",
                    minHeight: "29px",
                    height: "inherit",

                    "& > .pr_table_column_body": {
                        minHeight: "28px",
                        height: "100%",
                        lineHeight: "28px",
                        display: "block",
                        margin: 0,

                        "&.break-text": {
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",

                            "& *": {
                                whiteSpace: "unset",
                                textOverflow: "unset",
                            },
                        },

                        "&.text-ellipsis": {
                            "& > *": {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            },
                        },
                    },

                    "& > .pr_table_column_body > span:not(.badge)": {
                        minHeight: "28px",
                        lineHeight: "28px",
                        display: "block",
                        margin: 0,

                        /* "&:before": {
                            content: "''",
                            display: "inline-block",
                        }, */
                    },

                    "& > .with-identifier": {
                        //margin: "0 -12px 0 -4px",
                        //padding: "0 5px",
                        paddingLeft: "3px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        minHeight: "28px",
                        height: "100%",

                        "& .identifier": {
                            position: "absolute",
                            //left: '-1px',
                            left: "0px",
                            top: "-1px",
                            bottom: "-1px",
                            width: "3px",
                        },

                        "& > input[type=checkbox]": {
                            margin: 0,
                        },

                        "& > .select2-container, & > .twitter-typeahead": {
                            margin: "0 -12px 0 -3px",
                        },
                    },

                    "& .cke_editable": {
                        minHeight: "28px",

                        "&:focus-visible": {
                            outline: "none",
                        },
                    },

                    "& .form-group": {
                        //margin: "0px -5px",
                        margin: 0,
                    },

                    "& .select2-container": {
                        height: "28px !important",
                        fontSize: "13px",

                        "& .select2-choice": {
                            border: "0",
                            padding: "0 0 0 8px",
                            height: "28px !important",
                            borderRight: "1px solid #ddd",
                        },
                    },

                    "& .tt-hint": {
                        height: "28px",
                    },

                    "& input[type=text], & input[type=hidden], & select": {
                        height: "29px",
                        padding: "5px",
                        fontSize: "13px",
                        border: "0px",
                        margin: 0,
                        boxSizing: "border-box",
                        borderLeft: "1px solid #ddd",
                        borderRight: "1px solid #ddd",

                        "&.tt-hint, &.tt-input": {
                            boxSizing: "border-box",
                            height: "28px",
                        },
                    },

                    "& select": {
                        marginRight: "3px",
                    },

                    "& .btn.btn-xxs": {
                        width: "13px",
                        height: "13px",
                        lineHeight: "9px",
                        marginLeft: "4px",
                        marginRight: 0,
                        padding: 0,

                        "& > i": {
                            fontSize: "10px",
                            lineHeight: "12px",
                            marginLeft: "0px",
                        },
                    },

                    "& .btn.btn-icon-only.btn-circle.btn-xs": {
                        padding: "1px 6px",
                        minWidth: "22px",
                    },
                },
            },

            "& .actions-col": {
                padding: "3px 5px !important",
            },
        },

        "&.editable, & .inner-table.editable": {
            "& > tbody > tr > td": {
                backgroundColor: "#f5f5f5",
                "& input[type=text]:disabled, & input[type=hidden]:disabled, & select:disabled": {
                    backgroundColor: "#f5f5f5",
                },
            },
        },

        "&.top, & .inner-table.top": {
            "& > tbody > tr > td": {
                verticalAlign: "top",
            },
        },

        "&.middle, & .inner-table.middle": {
            "& > tbody > tr > td": {
                verticalAlign: "middle",
            },
        },

        "&.bottom, & .inner-table.bottom": {
            "& > tbody > tr > td": {
                verticalAlign: "bottom",
            },
        },
    },
});
const { classes } = styleSheet.attach();

export type TableColumnConfiguration = {
    columnId: number;
    visible: boolean;
    order: number;
    aggregationMode: TableFooterAggregationMode;
    width: number | null;
    height: number | null;
    useCustomTextColor: boolean | null;
    textColor: string | null;
    useCustomBackgroundColor: boolean | null;
    backgroundColor: string | null;
    breakText: boolean;
};
export type TableColumnsConfiguration = {
    tableId: number;
    columnsConfigurations: { [columnId: number]: TableColumnConfiguration };
};

export interface ITableComponentParams<T = any> {
    id?: number;
    compact?: boolean;
    bordered?: boolean;
    striped?: boolean;
    scrollable?: boolean;
    systemScrollable?: boolean;
    noHeader?: boolean;
    noLastBorder?: boolean;
    children?: ReactNode;
    verticalAlign?: "middle" | "top" | "bottom";
    className?: string;
    selectableRows?: ko.MaybeSubscribable;
    selectRowsByCheckbox?: boolean;
    multipleRowsSelection?: boolean;
    sortableColumns?: boolean;
    rowsSortingValueGetter?: (model: T) => number;
    rowsSortingValueSetter?: (model: T, newOrder: number) => void;
    onRowSorting?: (droppedItem: T, neighbourItem: T, before: boolean, droppedItemNewIndex: number) => void;
    disableDropBetweenNotDraggableRows?: boolean;
    disableDropBeforeNotDraggableRowIfFirst?: boolean;
    disableDropAfterNotDraggableRowIfLast?: boolean;
    mimeType?: string;
    dataSource: IDataSource<string | number, T> | string | ObservableArrayDataSource<T>;
    listener?: IDataSourceListener | string;
    rowAs?: string;
    title?: ko.MaybeObservable<string>;
    editable?: boolean;
    showColumnSelector?: boolean;
    columnSelectorPosition?: "right" | "left";
    fixedLayout?: boolean;
    hideSelection?: boolean;
    textFilter?: ko.Observable<string>;
    emptyResultMessage?: string;
    containerClassName?: string;
    enableAggregators?: boolean;
    useHoverEffect?: boolean;
    showLoadingIndicator?: ko.Subscribable<boolean>;
    stickyColumns?: number;

    defaultSorter?: (left: T, right: T) => number;

    style?: React.CSSProperties;
    headerActions?: () => React.ReactElement;

    components?: {
        row?: typeof TableRowComponent;
        emptyTableTemplate?: () => React.ReactNode;
        rowDecorator?: (item: TableItem<T>, rowRenderer: () => React.ReactElement) => React.ReactNode;
    };

    bindTo?: {
        [as: string]: unknown;
    };

    forwardRef?: (table: ITableComponent<T>) => void;
}

export interface ITableItem<T, K = IDataSourceModel<string | number, T>> {
    onRowBeginDrag(item: ITableItem<T>, dataTransfer: DataTransfer): void;
    Selected: ko.Observable<boolean>;
    Data: K;
    Order: number;
    VisibleColumnsCount: ko.Computed<number>;
}

export interface ITableComponent<T> {
    Items: ko.ObservableArray<ITableItem<T>>;

    selectItem(item: ITableItem<T>): void;
    resetSelection(): void;
}

type DraggedRow<T> = {
    tableId: number;
    rowId: number;
    companyGuid: string;
    data: T;
};

class TableItem<T> implements ITableItem<T> {
    public Id: number;
    public Order: number;
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public VisibleColumnsCount: ko.Computed<number>;

    public readonly Data: IDataSourceModel<string | number, T>;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    onSelectedChange: (item: TableItem<T>) => Promise<void> | void;

    constructor(
        data: IDataSourceModel<string | number, T>,
        visibleColumnsCount: ko.Computed<number>,
        private mimeType: string,
        private owner: Table<T>
    ) {
        this.Data = data;

        this.VisibleColumnsCount = visibleColumnsCount;

        this.Selected.subscribe(() => {
            if (this.onSelectedChange) this.onSelectedChange(this);
        });
    }

    onRowBeginDrag(item: TableItem<T>, dataTransfer: DataTransfer): void {
        const draggedRow: DraggedRow<T> = {
            tableId: this.owner.Id,
            rowId: item.Id,
            companyGuid: this.userInfo.getCurrentCompanyGuid(),
            data: item.Data.model,
        };

        dataTransfer.setData("text/plain", item.Data.title);
        dataTransfer.setData(this.mimeType, JSON.stringify(draggedRow));
    }

    equal(item: TableItem<T>): boolean {
        return item.Data.id === this.Data.id;
    }
}

export type TableRowComponentProps<T = unknown> = {
    item: ITableItem<T>;
    // eslint-disable-next-line @typescript-eslint/ban-types
} & PropsWithChildren<{}>;

export function TableRowComponent(props: TableRowComponentProps) {
    return <tr>{props.children}</tr>;
}

export class Table<T> implements ITableComponent<T>, IDataSourceView {
    static defaultProps: Partial<ITableComponentParams> = {
        emptyResultMessage: TextResources.ProlifeSdk.EmptyTable,
        columnSelectorPosition: "left",
        enableAggregators: false,
        useHoverEffect: false,
        selectableRows: false,
        multipleRowsSelection: false,
        selectRowsByCheckbox: false,
        noHeader: false,
        stickyColumns: 0,
        mimeType: "application/prolife-table-row",
    };

    static defaultDataSource<T, K extends string | number>(
        array: T[] | ko.ObservableArray<T> | ko.Computed<T[]>,
        selector: (item: T) => { id: K; title: string } & Partial<IDataSourceModel<K, T>>
    ): ObservableArrayDataSource<T, K> {
        let finalArray = array;
        if (!ko.isObservableArray(array) && !ko.isComputed(array)) {
            finalArray = ko.observableArray(array);
        }

        return {
            array: finalArray as ko.ObservableArray<T> | ko.Computed<T[]>,
            factory: Table.defaultFactory<T, K>(selector),
        };
    }

    static defaultFactory<T, K extends string | number>(
        selector: (item: T) => { id: K; title: string } & Partial<IDataSourceModel<K, T>>
    ): (item: T) => IDataSourceModel<K, T> {
        return (item: T) => {
            return {
                model: item,
                ...selector(item),
                isGroup: false,
                isLeaf: true,
            };
        };
    }

    public get Id(): number {
        return this.props.id || Math.floor(Math.random() * 100000000);
    }

    public Items: ko.ObservableArray<TableItem<T>> = ko.observableArray();
    public SortedItems: ko.NotifiableComputed<TableItem<T>[]>;
    public TextFilter: ko.Observable<string> = ko.observable(null);
    public Selectable: ko.Observable<boolean> = ko.observable(false);
    public CssClasses: ko.Observable<string> = ko.observable();
    public Loaded: ko.Observable<boolean> = ko.observable(false);
    public Loading: ko.Observable<boolean> = ko.observable(false);

    public VisibleColumnsCount: ko.Computed<number>;
    public AllSelected: ko.Computed<boolean>;

    /* filtro su righe selezionate */
    private ShowAllRows: ko.Computed<boolean>;
    private ShowSelectedRows: ko.Observable<boolean> = ko.observable(true);
    private ShowUnselectedRows: ko.Observable<boolean> = ko.observable(true);
    /* ---- */

    private ShouldRender: ko.Observable<boolean> = ko.observable(true);

    private DataSource: IDataSource;
    private pageSize = 50;
    private listeners: IDataSourceListener[] = [];

    private sorter: (a: T, b: T) => number;
    private columns: Column<T>[] = [];
    private secondaryRows: SecondaryRow[] = [];
    private enableAggregators = false;

    private rowsSortingEnabled = false;
    private nextRowId = 1;
    private rowsSortingCol: Column<T>;
    private selectorColumn: Column<T>;
    private SortingOnRowsSortingCol: ko.Observable<boolean> = ko.observable(true);
    private subscriptions: ko.Subscription[] = [];
    private selecting = false;
    private currentSelectedRows: TableItem<T>[] = [];
    private currentColumnsConfiguration: IApplicationConfiguration;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService: IApplicationsConfigurationsService;

    constructor(private props: ITableComponentParams<T>) {
        if (!this.props.showLoadingIndicator) this.props.showLoadingIndicator = ko.observable(false);

        if (props.defaultSorter) {
            this.sorter = props.defaultSorter;
        }

        this.Selectable(ko.isSubscribable(props.selectableRows) ? props.selectableRows() : props.selectableRows);

        props.components = Object.assign({ row: TableRowComponent }, props.components);

        if (props.textFilter) {
            this.TextFilter = props.textFilter;

            this.TextFilter.subscribe(this.doTextSearch.bind(this));
        }

        let columnsOrder = 1;
        const analyzeChildren = (c) => {
            if (ComponentUtils.isOfType(c, Column)) {
                const col = ComponentUtils.getComponent(c) as Column<any>;
                col.order(columnsOrder++);
                this.columns.push(col);
            } else if (ComponentUtils.isOfType(c, SecondaryRow))
                this.secondaryRows.push(ComponentUtils.getComponent(c));
            else if (Array.isArray(c)) {
                ComponentUtils.Children.forEach(c, analyzeChildren);
            }
        };

        ComponentUtils.Children.forEach(props.children, analyzeChildren);
        if (this.props.rowsSortingValueGetter) {
            this.rowsSortingEnabled = true;
            this.addRowsSortingColumn();
        }

        if (this.mustShowRowSelectorColumn()) this.addRowSelectorColumn();

        const hasColunmWithAgggregator = !!this.columns.firstOrDefault((c) => c.hasAggregator);
        this.enableAggregators = this.props.enableAggregators && hasColunmWithAgggregator;

        this.VisibleColumnsCount = ko.computed(() => {
            return this.columns.filter((c) => c.visible()).length;
        });

        this.AllSelected = ko.computed({
            read: () => {
                const selectedCount = this.Items().filter((i) => i.Selected()).length;
                return selectedCount === this.Items().length ? true : selectedCount === 0 ? false : undefined;
            },
            write: (value: boolean) => {
                this.selecting = true;

                for (const item of this.Items()) {
                    if (value) this.setItemAsSelected(item);
                    else this.setItemAsDeselected(item);
                }

                this.selecting = false;
            },
        });

        this.SortedItems = ko.utils
            .notifyableComputed(() => {
                let items = [];

                if (!this.sorter) items = this.Items();
                else
                    items = this.Items.sorted((a, b) => {
                        return ko.ignoreDependencies(() => this.sorter(a.Data.model, b.Data.model));
                    });

                if (!this.props.selectRowsByCheckbox) return items;
                else {
                    return items.filter((i) => {
                        return (
                            (i.Selected() && this.ShowSelectedRows()) || (!i.Selected() && this.ShowUnselectedRows())
                        );
                    });
                }
            })
            .extend({ trackArrayChanges: true });

        this.ShowAllRows = ko.computed({
            read: () => {
                if (!this.ShowSelectedRows() && !this.ShowUnselectedRows()) return false;

                if (this.ShowSelectedRows() && this.ShowUnselectedRows()) return true;

                return undefined;
            },
            write: (value: boolean) => {
                this.ShowSelectedRows(value);
                this.ShowUnselectedRows(value);
            },
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    public resetSelection(): void {
        this.selecting = true;

        const currentSelectedRows = [...this.currentSelectedRows];
        for (const row of currentSelectedRows) this.setItemAsDeselected(row);

        this.selecting = false;
    }

    private addRowSelectorColumn() {
        const col = (
            <Column
                className="row-selector"
                visible={this.Selectable()}
                forwardRef={(c) => {
                    c.visible(this.Selectable());
                    this.selectorColumn = c;
                }}
            >
                <ColumnHeader>
                    {() => (
                        <>
                            {this.props.selectRowsByCheckbox && <CheckBox checked={this.AllSelected} />}
                            <TableFilter customFilterStateHandler={() => !this.ShowAllRows()}>
                                {() => (
                                    <div className="row-selector-filter">
                                        <ul>
                                            <li>
                                                <CheckBox
                                                    label={TextResources.ProlifeSdk.SelectAll}
                                                    checked={this.ShowAllRows}
                                                ></CheckBox>
                                            </li>
                                            <li>
                                                <CheckBox
                                                    label={TextResources.ProlifeSdk.Selected}
                                                    checked={this.ShowSelectedRows}
                                                ></CheckBox>
                                            </li>
                                            <li>
                                                <CheckBox
                                                    label={TextResources.ProlifeSdk.Unselected}
                                                    checked={this.ShowUnselectedRows}
                                                ></CheckBox>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </TableFilter>
                        </>
                    )}
                </ColumnHeader>
                <ColumnBody>{(item: ITableItem<T>) => <CheckBox checked={item.Selected} label="" />}</ColumnBody>
            </Column>
        );
        this.columns.unshift(ComponentUtils.getComponent(col));
    }

    private mustShowRowSelectorColumn() {
        if (ko.isSubscribable(this.props.selectableRows)) return true;

        return this.Selectable() && this.props.selectRowsByCheckbox;
    }

    @Delay(300)
    private doTextSearch(): void {
        this.refresh();
    }

    private addRowsSortingColumn() {
        const hasColumnsWithSorter = !!this.columns.firstOrDefault((c) => !!c.sorter);
        const columnAttributes = {};

        if (hasColumnsWithSorter)
            columnAttributes["sorter"] = (a: T, b: T) =>
                this.props.rowsSortingValueGetter(a) - this.props.rowsSortingValueGetter(b);

        const col = (
            <Column className="text-center rows-sorting-anchor" {...columnAttributes}>
                <ColumnBody>
                    {(item: ITableItem<T>) => (item.Data.dragEnabled ?? true) && <i className="fa fa-bars"></i>}
                </ColumnBody>
            </Column>
        );
        this.rowsSortingCol = ComponentUtils.getComponent(col);
        this.columns.unshift(this.rowsSortingCol);
    }

    private onRowDropped(dataTransfer: DataTransfer, row: IDataSourceModel<string | number, T>, before: boolean): void {
        if (dataTransfer.types.indexOf(this.props.mimeType) < 0) return;

        const droppedItem: DraggedRow<T> = JSON.parse(dataTransfer.getData(this.props.mimeType));
        if (!droppedItem) return;

        const companyGuid = this.userInfo.getCurrentCompanyGuid();
        if (companyGuid.toLowerCase() !== droppedItem.companyGuid.toLowerCase()) {
            this.infoToastService.Error(TextResources.ProlifeSdk.InvalidTableRowDrop);
            return;
        }

        const items = this.Items();
        const neighborItem = items.firstOrDefault((i) => i.Data.model === row.model || i.Data.id === row.id);
        if (droppedItem.rowId === neighborItem?.Id && droppedItem.tableId === this.Id) {
            // Ho droppato su me stesso, non faccio niente
            return;
        }

        if (
            this.props.disableDropBetweenNotDraggableRows ||
            this.props.disableDropBeforeNotDraggableRowIfFirst ||
            this.props.disableDropAfterNotDraggableRowIfLast
        ) {
            const index = items.indexOf(neighborItem);
            const prevItem = items[index - 1];
            if (
                this.props.disableDropBetweenNotDraggableRows &&
                prevItem &&
                !prevItem.Data.dragEnabled &&
                !neighborItem.Data.dragEnabled &&
                before
            ) {
                return;
            }

            if (
                this.props.disableDropBeforeNotDraggableRowIfFirst &&
                index === 0 &&
                !neighborItem.Data.dragEnabled &&
                before
            )
                return;

            const nextItem = items[index + 1];
            if (
                this.props.disableDropBetweenNotDraggableRows &&
                nextItem &&
                !nextItem.Data.dragEnabled &&
                !neighborItem.Data.dragEnabled &&
                !before
            ) {
                return;
            }

            if (
                this.props.disableDropAfterNotDraggableRowIfLast &&
                index === items.length - 1 &&
                !neighborItem.Data.dragEnabled &&
                !before
            )
                return;
        }

        this.Items.valueWillMutate();

        let newIndex = null;
        if (droppedItem.tableId === this.Id) {
            newIndex = this.handleInternalDrop(droppedItem, row, before, items);
        } else {
            newIndex = this.handleExternalDrop(droppedItem, row, before, items);
        }

        items.sort((a, b) => a.Order - b.Order);
        this.Items.valueHasMutated();

        if (this.props.onRowSorting) this.props.onRowSorting(droppedItem.data, row.model, before, newIndex);
    }

    private handleInternalDrop(
        droppedItem: DraggedRow<T>,
        neighborModel: IDataSourceModel,
        before: boolean,
        items: TableItem<T>[]
    ): number {
        const neighborIndex = this.props.rowsSortingValueGetter(neighborModel.model);
        const droppedItemOldIndex = items.firstOrDefault((i) => droppedItem.rowId === i.Id)?.Order;

        const aheadMovement = droppedItemOldIndex < neighborIndex;
        const newIndex = !aheadMovement
            ? before
                ? neighborIndex
                : neighborIndex + 1
            : before
            ? neighborIndex - 1
            : neighborIndex;

        let droppedItemModel;

        for (const row of items) {
            if (row.Id === droppedItem.rowId) {
                row.Order = newIndex;

                droppedItemModel = row.Data.model;
                if (this.props.rowsSortingValueSetter) this.props.rowsSortingValueSetter(droppedItemModel, newIndex);

                continue;
            }

            if (!aheadMovement && row.Order >= newIndex && row.Order < droppedItemOldIndex) {
                row.Order++;

                if (this.props.rowsSortingValueSetter) this.props.rowsSortingValueSetter(row.Data.model, row.Order);
            }

            if (aheadMovement && row.Order > droppedItemOldIndex && row.Order <= newIndex) {
                row.Order--;

                if (this.props.rowsSortingValueSetter) this.props.rowsSortingValueSetter(row.Data.model, row.Order);
            }
        }

        return newIndex;
    }

    private handleExternalDrop(
        droppedItem: DraggedRow<T>,
        neighborModel: IDataSourceModel,
        before: boolean,
        items: TableItem<T>[]
    ): number {
        const neighborIndex = this.props.rowsSortingValueGetter(neighborModel.model);
        const newIndex = before ? neighborIndex : neighborIndex + 1;

        // N.B. per come funziona al momento la tabella non è sempre possibile, internamente, aggiungere una riga perché potrei non avere la factory per creare il model
        // e anche se l'avessi potrebbe non funzionare perché l'oggetto viene serializzato e deserializzato durante il drag&drop, quindi non riesco a ricrearlo correttamente
        // (es. se sull'oggetto originale c'erano degli observable non riesco a ricrearli e l'interfaccia schianta).
        // In questo caso si rimanda la creazione all'esterno, fornendo solo i dati necessari per l'inserimento nella posizione corretta

        return newIndex;
    }

    sortBy(column: Column<T>) {
        let desc = false;

        for (const otherCols of this.columns) {
            if (otherCols !== column) {
                otherCols.sorting(false);
                otherCols.sortingAsc(false);
                otherCols.sortingDesc(false);
            } else {
                column.sorting(true);
                const isRowsSortingCol = column === this.rowsSortingCol;
                this.SortingOnRowsSortingCol(isRowsSortingCol);
                if (isRowsSortingCol || !column.sortingAsc()) {
                    column.sortingAsc(true);
                    column.sortingDesc(false);
                } else {
                    column.sortingAsc(false);
                    column.sortingDesc(true);
                    desc = true;
                }
            }
        }

        if (desc && column.sorter) this.sorter = (a, b) => -column.sorter(a, b);
        else this.sorter = column.sorter;

        this.SortedItems.valueHasMutated();
    }

    componentDidMount(context: ko.BindingContext) {
        const maybeDataSource = ComponentUtils.parseParametersWithContext(this.props.dataSource, context);
        if (isObservableArrayDataSource<T>(maybeDataSource)) {
            this.Loaded(true);

            const observableArrayDataSource: ObservableArrayDataSource<T> = maybeDataSource;
            synchronizeItems(this.Items, observableArrayDataSource, (item) =>
                this.createTableItemViewModel(observableArrayDataSource.factory(item))
            );
        } else {
            this.DataSource = maybeDataSource;
            this.DataSource.setView(this);
        }

        if (ko.isSubscribable(this.props.selectableRows)) {
            this.subscriptions.push(
                this.props.selectableRows.subscribe((v) => {
                    this.selectorColumn && this.selectorColumn.visible(v);
                })
            );
        }

        const listener = ComponentUtils.parseParametersWithContext(this.props.listener, context);
        this.listeners = listener ? [listener] : [];

        this.loadAndApplyPreferredConfiguration();

        if (!this.Loaded()) this.loadNextPage();
    }

    async loadAndApplyPreferredConfiguration(): Promise<void> {
        if (this.props.id === null || this.props.id === undefined) return;

        const userId = this.userInfo.getIdUser();
        const objectId = this.props.id;

        try {
            const preferredConfig =
                await this.applicationsConfigurationsService.GetUserPreferredApplicationConfiguration(userId, objectId);
            if (!preferredConfig) return;

            const config = JSON.parse(preferredConfig.Configuration) as TableColumnsConfiguration;
            this.currentColumnsConfiguration = preferredConfig;
            this.applyColumnsConfiguration(config);
            this.reorderColumns();
        } catch (e) {
            console.log(e);
        }
    }

    private applyColumnsConfiguration(config: TableColumnsConfiguration) {
        const columnsWithoutConfig = [];
        let maxOrderValue = -1;
        for (const col of this.columns) {
            if (col.id === null || col.id === undefined) continue;

            const columnConfig = config.columnsConfigurations[col.id];
            if (!columnConfig) {
                console.warn(String.format("Configurazione colonna [{0}: {1}] non trovata!", col.id, col.title));
                columnsWithoutConfig.push(col);
                continue;
            }

            if (maxOrderValue < columnConfig.order) maxOrderValue = columnConfig.order;

            col.order(columnConfig.order);
            col.visible(columnConfig.visible);
            col.aggregationMode(columnConfig.aggregationMode);
            col.width(columnConfig.width);
            col.breakText(columnConfig.breakText);
        }

        for (const col of columnsWithoutConfig) col.order(++maxOrderValue);
    }

    private reorderColumns() {
        this.columns.sort((a, b) => a.order() - b.order());
        this.ShouldRender(false);
        this.ShouldRender(true);
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];
    }

    public addListener(listener: IDataSourceListener): void {
        if (this.listeners.indexOf(listener) >= 0) return;

        this.listeners.push(listener);
    }

    public async selectItem(item: TableItem<T>): Promise<void> {
        if (this.selecting) return;

        this.selecting = true;

        const isDeselection = this.itemWasAlreadySelected(item);

        if (!isDeselection) {
            const results: boolean[] = await this.requestCanSelectItem(item);
            if (results.filter((v) => !v).length > 0) return;

            this.setItemAsSelected(item);
        } else {
            this.setItemAsDeselected(item);
        }

        this.selecting = false;
    }

    private setItemAsSelected(item: TableItem<T>): void {
        item.Selected(true);
        this.addItemToCurrentSelectedRows(item);
        this.handleMultipleSelection(item);
        this.notifyItemSelection(item);
    }

    private setItemAsDeselected(item: TableItem<T>): void {
        item.Selected(false);
        this.removeItemFromCurrentSelectedRows(item);
        this.notifyItemDeselection(item);
    }

    private removeItemFromCurrentSelectedRows(item: TableItem<T>) {
        const itemPositionInCurrentSelction = this.getItemPositionInCurrentSelection(item);
        if (itemPositionInCurrentSelction >= 0) this.currentSelectedRows.splice(itemPositionInCurrentSelction, 1);
    }

    private getItemPositionInCurrentSelection(item: TableItem<T>) {
        let position = null;
        for (let i = 0; i < this.currentSelectedRows.length; i++) {
            const selectedRow = this.currentSelectedRows[i];
            if (selectedRow.equal(item)) {
                position = i;
                break;
            }
        }
        return position;
    }

    private itemWasAlreadySelected(item: TableItem<T>): boolean {
        return !!this.currentSelectedRows.firstOrDefault((r) => r.equal(item));
    }

    private async requestCanSelectItem(item: ITableItem<T>) {
        const asyncProcesses: Promise<boolean>[] = [];
        for (const obs of this.listeners) {
            if (obs.canSelectItem) asyncProcesses.push(obs.canSelectItem(this.DataSource, item.Data));
        }

        const results: boolean[] = await Promise.all(asyncProcesses);
        return results;
    }

    private handleMultipleSelection(item: TableItem<T>) {
        if (!this.props.multipleRowsSelection) {
            for (let i = 0; i < this.currentSelectedRows.length; i++) {
                const row = this.currentSelectedRows[i];

                if (!row.equal(item)) {
                    row.Selected(false);
                    this.currentSelectedRows.splice(i, 1);
                    i--;
                }
            }
        }
    }

    private addItemToCurrentSelectedRows(item: TableItem<T>) {
        this.currentSelectedRows.push(item);
    }

    public async loadNextPage(): Promise<void> {
        if (!this.DataSource || this.Loading()) return;

        this.Loading(true);
        const currentItems = this.Items();
        const data = await this.DataSource.getData(null, this.TextFilter(), currentItems.length, this.pageSize);
        const newItems = data.map((m) => this.createTableItemViewModel(m));

        this.Items(currentItems.concat(newItems));

        if (data.length === 0 || data.length < this.pageSize) this.Loaded(true);

        this.Loading(false);
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(size: number): void {
        this.pageSize = size;
    }

    public refresh(keepSelection = true): void {
        this.refreshImmediate(keepSelection);
    }

    public refreshImmediate(keepSelection = true): void {
        if (!this.DataSource) return;

        this.Items([]);
        this.Loaded(false);
        this.loadNextPage();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public pushState(): void {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public popState(): void {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public async select(...models: IDataSourceModel<string | number, any, string | number, any>[]): Promise<void> {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public navigateTo(...history: IDataSourceModel<string | number, any, string | number, any>[]): void {}

    private createTableItemViewModel(data: IDataSourceModel<string | number, T>) {
        const row = new TableItem(data, this.VisibleColumnsCount, this.props.mimeType, this);
        row.Id = this.nextRowId++;

        if (this.props.rowsSortingValueGetter) {
            const rowOrder = this.props.rowsSortingValueGetter(data.model);
            if (rowOrder !== null && rowOrder !== undefined) row.Order = rowOrder;
            else {
                row.Order = this.getNewRowOrder();
                if (this.props.rowsSortingValueSetter) this.props.rowsSortingValueSetter(data.model, row.Order);
            }
        } else {
            row.Order = this.getNewRowOrder();
        }

        if (this.currentSelectedRows.firstOrDefault((r) => r.equal(row))) row.Selected(true);

        return row;
    }

    private getNewRowOrder(): number {
        const maxRowOrder = this.Items().max((r) => r.Order);
        return (maxRowOrder ?? 0) + 1;
    }

    private notifyItemSelection(item: ITableItem<T>) {
        this.listeners.forEach((l) => l.onItemSelected(this.DataSource, item.Data));
    }

    private notifyItemDeselection(item: ITableItem<T>) {
        this.listeners.forEach((l) => l.onItemDeselected(this.DataSource, item.Data));
    }

    private async showColumnSelectionPopover(viewModel: Table<T>, event: MouseEvent) {
        const popover = new ColumnSelectionPopover({
            columns: this.columns,
            sortableColumns: this.props.sortableColumns,
            enableAggregators: this.enableAggregators,
            tableId: this.props.id,
            currentColumnsConfiguration: this.currentColumnsConfiguration,
            onConfigurationApplied: (configuration) => (this.currentColumnsConfiguration = configuration),
        });

        try {
            const visibleColumns = await popover.showOn(event.currentTarget as HTMLElement, "left");
            for (const col of this.columns) col.visible(!col.hasTitle || visibleColumns.indexOf(col) !== -1);

            this.reorderColumns();
        } catch (e) {
            console.log(e);
        }
    }

    private renderColumnHeaders() {
        const headers = [];
        let index = 0;
        for (const c of this.columns) {
            headers.push(c.renderHeader(this, { stickyColumn: index < this.props.stickyColumns }));
            index++;
        }

        return <>{headers}</>;
    }

    private renderTableFooter() {
        const footers = [];
        let footerFound = false;

        for (const c of this.columns) {
            const footer = c?.renderFooter(this.Items);

            if (footer) {
                footerFound = true;
                footers.push(footer);
            } else {
                footers.push(<td></td>);
            }
        }

        if (!footerFound) return <></>;

        return (
            <tfoot>
                <tr>{footers}</tr>
            </tfoot>
        );
    }

    private renderSecondaryRows(item: ITableItem<T>) {
        const rows = [];

        if (this.secondaryRows.length === 0) return <></>;

        for (const row of this.secondaryRows) rows.push(row.renderRow(item));

        return React.createElement(React.Fragment, {}, ...rows);
    }

    private renderRowBody(item: TableItem<T>) {
        const even = item.Order % 2 === 0;

        const bindings: any = {
            css: {
                selected: item.Selected,
                "prolife-table-row": true,
                "prolife-even-row": even,
                "prolife-odd-row": !even,
            },
        };

        if (this.Selectable()) {
            if (!this.props.selectRowsByCheckbox) bindings.click = this.selectItem.bind(this, item);
            else {
                item.onSelectedChange = this.selectItem.bind(this, item);
                bindings.dblClickEx = () => {
                    item.Selected(!item.Selected());
                };
            }
        }

        if (this.rowsSortingEnabled && (item.Data.dragEnabled ?? true)) {
            bindings.draggableEx = {
                CanDrag: this.SortingOnRowsSortingCol,
                OnDrag: item.onRowBeginDrag.bind(item, item),
            };
        }

        const renderRow = () => {
            return (
                <>
                    <Bind bind={bindings}>
                        {React.createElement(
                            this.props.components.row,
                            { item: item },
                            this.columns.map((c, index) =>
                                c.renderBody(item, { stickyColumn: index < this.props.stickyColumns })
                            )
                        )}
                    </Bind>
                    {this.renderSecondaryRows(item)}
                </>
            );
        };

        return (
            <With data={item.Data} as={this.props.rowAs}>
                {() => (
                    <>
                        {this.props.components.rowDecorator
                            ? this.props.components.rowDecorator(item, renderRow)
                            : renderRow()}
                    </>
                )}
            </With>
        );
    }

    render() {
        const tableClasses = ComponentUtils.classNames(
            "table",
            "table-condensed",
            "table-component",
            classes["prolife-table"],
            {
                compact: this.props.compact,
                bordered: this.props.bordered && this.props.compact,
                "table-bordered": this.props.bordered && !this.props.compact,
                editable: this.props.editable,
                fixedLayout: this.props.fixedLayout,
                "hide-selection": this.props.hideSelection,
                scrollable: this.props.scrollable || this.props.systemScrollable,
                hover: this.props.useHoverEffect,
                "no-header": this.props.noHeader,
                "no-last-border": this.props.noLastBorder,
            },
            this.props.verticalAlign ?? "middle",
            this.props.className
        );

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const table = this;

        //let dataBind = "";

        const styles: React.CSSProperties = this.props.style ?? {};

        if (this.props.scrollable || this.props.systemScrollable) {
            styles.overflow = "hidden";
            //dataBind += "slimScroll: 'flex'";
        }

        const renderShowColumnsButton = () => {
            return (
                <>
                    {this.props.showColumnSelector && (
                        <button
                            className="btn btn-primary btn-circle btn-icon-only"
                            data-bind={{ asyncClick: table.showColumnSelectionPopover.bind(table) }}
                            title="Selezione Colonne"
                        >
                            <i className="fa fa-navicon"></i>
                        </button>
                    )}
                </>
            );
        };

        const containerClasses = ComponentUtils.classNames(
            "flex-container flex-vertical flex-1",
            this.props.containerClassName
        );

        return ComponentUtils.bindTo(
            <div className={containerClasses} style={styles}>
                {(this.props.title || this.props.showColumnSelector || this.props.headerActions) && (
                    <div
                        class="flex-container"
                        style={{
                            alignItems: "center",
                            justifyContent: this.props.columnSelectorPosition === "left" ? "flex-start" : "flex-end",
                        }}
                    >
                        {this.props.columnSelectorPosition === "left" && renderShowColumnsButton()}
                        {this.props.title && <h3 className="flex-1" data-bind={{ text: table.props.title }}></h3>}
                        {this.props.headerActions && this.props.headerActions()}
                        {this.props.columnSelectorPosition === "right" && renderShowColumnsButton()}
                    </div>
                )}
                <Layout.ScrollContainer
                    className="flex-container flex-full-height flex-vertical"
                    scrollable={this.props.scrollable}
                    systemScrollable={this.props.systemScrollable}
                >
                    <table
                        className={tableClasses}
                        data-bind={{
                            css: table.CssClasses,
                            droppableListEx: {
                                hoverClass: "droppable-hover",
                                hoverBeforeClass: "droppable-hover-before",
                                hoverAfterClass: "droppable-hover-after",
                                itemSelector: ".prolife-table-row",
                                allowedMimeTypes: [table.props.mimeType],
                                onDrop: table.onRowDropped.bind(table),
                            },
                        }}
                    >
                        <If condition={this.ShouldRender}>
                            {() => (
                                <>
                                    <thead>
                                        <tr>{!this.props.noHeader && this.renderColumnHeaders()}</tr>
                                    </thead>
                                    <tbody>
                                        <TsxForEach data={this.SortedItems} as="tableItem">
                                            {(item) => <>{this.renderRowBody(item)}</>}
                                        </TsxForEach>
                                        <ko-bind
                                            data-bind={{ if: !table.Loaded() || table.props.showLoadingIndicator() }}
                                        >
                                            <tr>
                                                <td
                                                    class="load-when-visible text-center"
                                                    colSpan={this.columns.length}
                                                    data-bind={{
                                                        notifyWhenVisible: {
                                                            callback: table.loadNextPage.bind(table),
                                                            rootSelector: "table-scrollable-body",
                                                        },
                                                        attr: { colspan: table.VisibleColumnsCount },
                                                    }}
                                                >
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind
                                            data-bind={{
                                                if:
                                                    table.Loaded() &&
                                                    !table.props.showLoadingIndicator() &&
                                                    table.SortedItems().length == 0,
                                            }}
                                        >
                                            <tr>
                                                <td
                                                    class="text-center"
                                                    colSpan={this.columns.length}
                                                    data-bind={{ attr: { colspan: table.VisibleColumnsCount } }}
                                                >
                                                    {this.props.components?.emptyTableTemplate ? (
                                                        this.props.components.emptyTableTemplate()
                                                    ) : (
                                                        <span>{this.props.emptyResultMessage}</span>
                                                    )}
                                                </td>
                                            </tr>
                                        </ko-bind>
                                    </tbody>
                                    {this.renderTableFooter()}
                                </>
                            )}
                        </If>
                    </table>
                </Layout.ScrollContainer>
            </div>,
            this,
            "table",
            this.props.bindTo
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(Table);
}
