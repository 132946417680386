import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderForList } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IJobOrderType } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";

export class CustomerJobOrders
{
    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService : IJobOrderService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private joTypesSettings : IJobOrderTypesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private joStateSettingsManager : IJobOrderStateSettingsManager;

    private Loading : boolean = false;

    public SearchFilter : ko.Observable<string> = ko.observable("");
    public OpenedSearchedItems : ko.ObservableArray<JobOrdersGroup> = ko.observableArray([]);
    public ClosedSearchedItems : ko.ObservableArray<JobOrdersGroup> = ko.observableArray([]);

    private searchTimeout : ReturnType<typeof setTimeout>;

    constructor(private customerId : number)
    {
        this.SearchFilter.subscribe(this.Refresh.bind(this));
    }

    public Refresh()
    {
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(() => {
            this.jobOrdersService.GetJobOrdersList(-1, -1, -1, false, this.SearchFilter(), this.customerId).then((jobOrders : IJobOrderForList[]) => {

                const openedJobOrdersGroups : JobOrdersGroup[] = [];
                const closedJobOrdersGroups : JobOrdersGroup[] = [];

                const logicalStatusMap: { [key: number]: number } = {};

                jobOrders.forEach((j) => {
                    const jo: CustomerJobOrder = new CustomerJobOrder(j, this.jobOrdersService);
                    if (logicalStatusMap[j.StateId] === null || logicalStatusMap[j.StateId] === undefined) {
                        const jobOrderState = this.joStateSettingsManager.getJobOrderStateById(j.StateId);
                        logicalStatusMap[j.StateId] = jobOrderState.LogicalState;
                    }

                    let groupMatches;
                    let collection: JobOrdersGroup[];

                    if (logicalStatusMap[j.StateId] === 0) {
                        groupMatches = openedJobOrdersGroups.filter((g : JobOrdersGroup) => { return g.jobOrderType.Id == j.Type});
                        collection = openedJobOrdersGroups;
                    }
                    else if (logicalStatusMap[j.StateId] === 1) {
                        groupMatches = closedJobOrdersGroups.filter((g : JobOrdersGroup) => { return g.jobOrderType.Id == j.Type});
                        collection = closedJobOrdersGroups;
                    }
                    else
                        throw Error("Invalid logical state " + logicalStatusMap[j.StateId] + " for job order state " + j.StateId);

                    let group : JobOrdersGroup;

                    if (groupMatches.length > 0)
                        group = groupMatches[0];
                    else
                    {
                        group = new JobOrdersGroup(this.joTypesSettings.getJobOrderTypeById(j.Type));
                        collection.push(group);
                    }

                    group.Items.push(jo);
                });

                this.OpenedSearchedItems(openedJobOrdersGroups);
                this.ClosedSearchedItems(closedJobOrdersGroups);
            });
        }, 500);

    }
}

export class JobOrdersGroup
{
    public title : string;
    public Items : ko.ObservableArray<CustomerJobOrder> = ko.observableArray([]);

    constructor(public jobOrderType : IJobOrderType)
    {
        this.title = jobOrderType.Description;
    }
}

export class CustomerJobOrder
{
    constructor(private jobOrder : IJobOrderForList, private jobOrdersService : IJobOrderService)
    {
    }

    public open()
    {
        const url = this.jobOrdersService.getJobOrderUrl(this.jobOrder.JobOrderId);
        window.open(url, "_blank");
    }
}
