import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import * as NumbersFormatters from "../../../Desktop/utilities/NumbersFormatters";
import { ScheduleViewModel } from "./ScheduleViewModel";
import { InvoiceDetailsDialog } from "./InvoiceDetailsDialog";
import { ScheduleDetailViewModel } from "./ScheduleDetailViewModel";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { IInvoiceForSchedule } from "../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IScheduleService, ICurrencyInfoForSchedule } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { IScheduleStateSettingsManager } from "../../../ProlifeSdk/interfaces/schedule/settings/IScheduleStateSettingsManager";
import { IDialogsService, IDialog } from "../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IProLifeSdkService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IDesktopService } from "../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ISchedule } from "../../../ProlifeSdk/interfaces/schedule/ISchedule";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";

export class LightInvoicesWithSchedulesViewModel
{
	@LazyImport(nameof<IProLifeSdkService>())
	private sdkService: IProLifeSdkService;
	@LazyImport(nameof<IDesktopService>())
	private desktopService : IDesktopService;
	@LazyImport(nameof<IDialogsService>())
	private dialogService : IDialogsService;
	@LazyImport(nameof<IInfoToastService>())
	private infoToast : IInfoToastService;
	@LazyImport(nameof<IScheduleService>())
	private scheduleService : IScheduleService;
	@LazyImport(nameof<IDocumentsService>())
	private documentsService : IDocumentsService;
	@LazyImportSettingManager(ProlifeSdk.ScheduleState)
	private scheduleStateSettingsManager : IScheduleStateSettingsManager;

	private currencyInfo: ICurrencyInfoForSchedule;

	public templateName : string = 'schedule_invoice_edit';

	public selected : ko.Observable<boolean> = ko.observable(false);
	public total : ko.Computed<number>;
	public schedulesToRemove : ko.ObservableArray<ScheduleViewModel> = ko.observableArray([]);
	public SchedulesList : ko.ObservableArray<ScheduleViewModel> = ko.observableArray([]);

	public SchedulesAreEditable : ko.Observable<boolean> = ko.observable(false);

	constructor(public invoice : IInvoiceForSchedule, public schedules : ScheduleViewModel[], private customer: ICustomer)
	{
		this.currencyInfo = {
			CurrencyId: this.invoice.CurrencyId,
			CurrencySymbol: this.invoice.CurrencySymbol,
			CurrencyExchangeValue: this.invoice.CurrencyExchangeValue
		};

		this.total = ko.computed(() => {
			var res : number = 0;
			this.SchedulesList().forEach( (shedule : ScheduleViewModel) =>{
				res = res + shedule.PriceInDocumentCurrency();
			});
			return res;
		});

        this.SchedulesList(schedules.slice(0));
	}

    EditSchedule()
    {
        this.SchedulesAreEditable(true);
    }

    SomeIsChanged() : boolean
    {
        var isChanged = this.schedules.length != this.SchedulesList().length;

        this.SchedulesList().forEach((s : ScheduleViewModel) => {
            isChanged = isChanged || this.schedules.indexOf(s) == -1 || s.someIsChanged();
        });

        return isChanged;
    }

    CancelEditSchedule()
    {
        this.SchedulesAreEditable(false);
        this.SchedulesList.removeAll();
        this.schedulesToRemove.removeAll();
        this.RevertChanges();
    }

    public ShowDetails()
    {
        this.Refresh().then(() => {
            //Se non ci sono scadenze ne aggiungo una nuova e mi metto in edit
            if(this.SchedulesList().length == 0)
            {
                this.addSchedule(null, null);
                this.EditSchedule();
            }

            var details = new ScheduleDetailViewModel(this);
            var dialog : IDialog = new InvoiceDetailsDialog(details);
            this.dialogService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
        });
    }

    public OpenInvoice()
    {
		this.documentsService.OpenDocumentOverlayById(this.invoice.Id);
    }

	public calculateDate(starDate : Date, month : number): Date {
		var scadenza : any = new Date(starDate.getTime());
		scadenza.add({ months: month});
		scadenza.moveToLastDayOfMonth();

		if (scadenza.is().sunday() || scadenza.is().saturday()) {
			scadenza.monday();
		}

		return scadenza;
	}


	public invoiceAfterRender() : void
	{
		//$('textarea').autosize();
	}

	public addSchedule(item, event) : void
    {
		var scadenza : Date = new Date();
		var importo : number = this.invoice.Total;
		let sign = this.invoice.NotaCredito == 1 ? -1 : 1;

		if (this.SchedulesList().length > 0)
		{
			var lastSchedule = this.SchedulesList()[this.SchedulesList().length - 1];

			importo = Math.max(0, this.invoice.Total - (this.total() * sign)) * sign;
			scadenza = this.calculateDate(lastSchedule.DataScadenza(), 1);
		}

		this.addScheduleToArray(scadenza, importo, "");

		// TODO rivedere perché questa altera tutte le scadenze, anche quelle già pagate
        //Se le scadenze precedenti coprivano già l'importo totale spalmo il totale su tutte le scadenze
        /*if(importo == 0)
        {
            var scheduleTotal : number =  this.invoice.Total / this.SchedulesList().length;
            var rest : number = this.invoice.Total - (scheduleTotal * this.SchedulesList().length);
            this.SchedulesList().forEach((s : ScheduleViewModel) => { s.PriceInDocumentCurrency(scheduleTotal * sign); });
            this.SchedulesList()[this.SchedulesList().length -1 ].PriceInDocumentCurrency((scheduleTotal + rest) * sign);  //All'ultima aggiungo l'eventuale resto della divisione
        }*/

		if(event)
			event.stopPropagation();
	}

	public addScheduleToArray(date, importo, descrizione) : void
    {
		var schedule = new ScheduleViewModel({
			DataScadenza : date,
			DataPagamento: null,
			Importo : this.sdkService.Utilities.Numbers.Round((importo * this.currencyInfo.CurrencyExchangeValue), 2),
			Nota: null,
			FKFattura: this.invoice.Id,
			Descrizione : descrizione,
			PaymentBankName: this.invoice.PaymentBankName,
			PaymentIBAN: this.invoice.PaymentIBAN,
			PaymentABI: this.invoice.PaymentABI,
			PaymentCAB: this.invoice.PaymentCAB,
			TipoPagamento: this.invoice.PaymentModeDescription,
			FKTipoPagamento: this.invoice.PaymentModeId,
			Stato: this.scheduleStateSettingsManager.getScheduleStates()[0].IdScadenzaStato,
			PriceInDocumentCurrency: importo,
			CurrencyExchangeValue: this.currencyInfo.CurrencyExchangeValue,
			CurrencyId: this.currencyInfo.CurrencyId
		}, this.currencyInfo, this.customer);
		this.SchedulesList.push(schedule);
	}

	public save(item,event) : void
    {
		if (!this.validate())
			return;

		this.innersave(true);
	}

	public innersave(result : boolean) : void
    {
		if(!result) return;

		var schedules : ISchedule[] = [];
		this.SchedulesList().forEach( (item : ScheduleViewModel) =>{
			schedules.push(item.getData());
		});

		var schedulesRemove : ISchedule[] = [];
		this.schedulesToRemove().forEach( (item : ScheduleViewModel) =>{
			schedulesRemove.push(item.getData());
		});

        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Schedule.Saving);

		this.scheduleService.UpdateSchedules(schedules, schedulesRemove)
			.then((schedules : ISchedule[]) => {
                this.Refresh();
            })
			.catch((jqXHR) => { this.infoToast.Error(jqXHR ? jqXHR.statusText : ProlifeSdk.TextResources.Schedule.ErrorWhileSaving);})
            .finally(() => { this.SchedulesAreEditable(false); this.desktopService.UnblockPageUI(); });
	}

	public Refresh() : Promise<ISchedule[]>
    {
		this.schedulesToRemove([]);

        return this.scheduleService.GetInvoiceSchedules(this.invoice.Id).then((schedules : ISchedule[]) => {
            this.schedules = schedules.map((s : ISchedule) => {
                return new ScheduleViewModel(s, this.currencyInfo, this.customer);
            });
            this.SchedulesList(this.schedules.slice(0));
			return schedules;
        });
	}

    public RevertChanges()
    {
        this.SchedulesList(this.schedules.slice(0));
        this.SchedulesList().forEach((s : ScheduleViewModel) => { s.ResetData(); });
    }

	public remSchedule(item){
		this.SchedulesList.remove(item);
		if (item.IdScadenza() > -1)
			this.schedulesToRemove.push(item);
	}

	public validate() : boolean
    {

		var res = true;
		var alerts = [];
		let errors = [];

		var dataFattura = moment(this.invoice.Date).toDate();
		var itemPrecDate : Date;
		var index : number = 1;
		this.SchedulesList().forEach((item : ScheduleViewModel) => {

			// TODO uniformare la validazione
			let validation = item.getValidation();
			validation.forEach((v) => { if (!v.valid) errors.push(v.message) });

			if (errors.length > 0)
				res = false;

			if ((item.Descrizione() || "").length < 1)
				alerts.push(String.format(ProlifeSdk.TextResources.Schedule.InsertDeadlineTitle, index));

			if (item.PriceInDocumentCurrency() == 0)
				alerts.push(String.format(ProlifeSdk.TextResources.Schedule.DeadlineAmountIsZero, index));

			if (item.PriceInDocumentCurrency() > 0 && this.invoice.NotaCredito == 1) {
				res = false;
				errors.push(String.format(ProlifeSdk.TextResources.Schedule.InvalidDeadlineAmountForCreditNote, index));
			}

			if (item.PriceInDocumentCurrency() < 0 && this.invoice.NotaCredito == 0) {
				res = false;
				errors.push(String.format(ProlifeSdk.TextResources.Schedule.InvalidDeadlineAmountForInvoice, index));
			}

			if (!item.CurrencyExchangeValue() || item.CurrencyExchangeValue() < 0) {
				res = false;
				errors.push(String.format(ProlifeSdk.TextResources.Schedule.InvalidEsxchangeValueForSchedule, index));
			}

			if (!item.DataScadenza())
			{
				res = false;
				errors.push(String.format(ProlifeSdk.TextResources.Schedule.InsertExpiryDate, index));
			}

			if (item.Stato().StatoLogico > 0 && !item.DataPagamento())
			{
				res = false;
				errors.push(String.format(ProlifeSdk.TextResources.Schedule.InsertPaymentDate, index));
			}

			if (item.Stato().StatoLogico > 0 && moment(item.DataScadenza()).startOf('day').isAfter(moment(item.DataPagamento()).startOf('day')))
				alerts.push(String.format(ProlifeSdk.TextResources.Schedule.PaymentDateIsBeforeExpiryDate, index));

			if (moment(dataFattura).startOf('day').isAfter(moment(item.DataScadenza()).startOf('day')))
				alerts.push(String.format(ProlifeSdk.TextResources.Schedule.ExpiryDateIsBeforeInvoiceDate, index));

			if (itemPrecDate != null && moment(itemPrecDate).startOf('day').isAfter(moment(item.DataScadenza()).startOf('day')))
				alerts.push(String.format(ProlifeSdk.TextResources.Schedule.ExpiryDateIsAfterNextDeadline, index));


			itemPrecDate = item.DataScadenza();
			index = index + 1;
		});

		let sign = this.invoice.NotaCredito == 1 ? -1 : 1;

		if (NumbersFormatters.roundTo(this.total() * sign, 2) != NumbersFormatters.roundTo(this.invoice.Total, 2))
			alerts.push(ProlifeSdk.TextResources.Schedule.InvoiceAmountIsNotEqualToSum);

		if (errors.length != 0) {
			var msg = String.format(ProlifeSdk.TextResources.Schedule.ErrorsAlert, errors.join("<br><br> - "));
			this.infoToast.Error(msg);
			return res;
		}

		if (alerts.length != 0)
		{
            var msg = String.format(ProlifeSdk.TextResources.Schedule.WarningsAlert, alerts.join("<br><br> - "));
			this.dialogService.Confirm(msg, ProlifeSdk.TextResources.Schedule.WarningsAlertAbort, ProlifeSdk.TextResources.Schedule.WarningsAlertContinue, this.innersave.bind(this));
			res = false;
		}

		return res;
	}
}