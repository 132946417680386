import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IReport } from "../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrdersControlsEntityProvider } from "../../../ProlifeSdk/interfaces/job-order/entityProviders/IJobOrdersControlsEntityProvider";
import { IReportsNavigator } from "../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderType";

export class ExtractPurchasesReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesService: IEntityProviderService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private statesManager: IJobOrderStateSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private typesManager: IJobOrderTypesSettingsManager;

    public jobOrdersSearchService: IJobOrdersControlsEntityProvider;
    Name: string = ProlifeSdk.TextResources.Blog.PurchasesExport;
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";

    detailsTemplateName: string = "extract-purchases";
    detailsTemplateUrl: string = "Blog/templates/reports";
    viewModel: any;

    private navigator: IReportsNavigator;

    //Filtri
    private startDate: ko.Observable<Date> = ko.observable();
    private endDate: ko.Observable<any> = ko.observable();
    private jobOrder: ko.Observable<any> = ko.observable();

    public State: ko.Observable<any> = ko.observable();
    public States: IJobOrderState[];

    public Type: ko.Observable<any> = ko.observable();
    public Types: IJobOrderType[];

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(groupId, 1);

        this.jobOrdersSearchService = <IJobOrdersControlsEntityProvider>(
            this.entitiesService.getEntityProvider(ProlifeSdk.JobOrderEntityTypeCode).getControlsProvider()
        );

        this.States = this.statesManager.getJobOrderStates();
        this.State(null);

        this.Types = this.typesManager.getJobOrderTypes();
        this.Type(null);

        this.State.subscribe(this.updateJobOrderSearchField.bind(this));
        this.Type.subscribe(this.updateJobOrderSearchField.bind(this));
    }

    private updateJobOrderSearchField() {
        this.jobOrder(null);
        this.jobOrdersSearchService.setStateFilter(this.State() ? this.State().IdJobOrderStateId : null);
        this.jobOrdersSearchService.setTypeFilter(this.Type() ? this.Type().Id : null);
    }

    public getExcelExportData(): any | null {
        if (!this.startDate() || !this.endDate() || (<any>moment(this.startDate())).isAfter(this.endDate())) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SpecifyValidDateInterval);
            return null;
        }

        return {
            fromDate: this.startDate(),
            toDate: this.endDate(),
            jobOrderId: this.jobOrder() ? this.jobOrder() : null,
            type: this.Type() ? this.Type().Id : null,
            state: this.State() ? this.State().IdJobOrderStateId : null,
        };
    }

    initialize() {
        //super.initialize();
        this.startDate(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.endDate(moment(new Date()).subtract("month", 1).endOf("month").toDate());
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}
