import * as ko from "knockout";
export class JquerySheet
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void
    {
		var options = valueAccessor();

        //TO BE IMPLEMENTED
	}
}

ko.bindingHandlers["jquerySheet"] = new JquerySheet();