import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { JobOrderForSplash } from "./SplashPageList";
import { IJobOrderService, IJobOrderForSplashWithBefore } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IEntitiesListNotificationsManagerAdapter } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IEntitiesListNotificationsManagerAdapter";
import { IJobOrderForSplash } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../Core/Deferred";

export class SplashPageNotificationsManagerAdapter implements IEntitiesListNotificationsManagerAdapter
{
    public ShowNotificationsMessages : boolean = true;
    public EntityCode : string;
    private jobOrdersService : IJobOrderService;

    constructor(private serviceLocator : IServiceLocator,
                private getFiltersMethod,
                private jobOrdersList : ko.ObservableArray<JobOrderForSplash>,
                private createViewModelMethod : (j : IJobOrderForSplash) => JobOrderForSplash)
    {
        this.EntityCode = ProlifeSdk.JobOrderEntityTypeCode
        this.jobOrdersService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public GetJsonDataFromItem(item : any) : any
    {
        return item.jobOrder;
    }

    public GetNextItem(itemId : number) : Promise<IJobOrderForSplash>
    {
        return this.jobOrdersService.GetNextItem(itemId, this.getFiltersMethod());
    }

    public GetItemWithBefore(itemId : number) : Promise<IJobOrderForSplashWithBefore>
    {
        return this.jobOrdersService.GetItemWithBefore(itemId, this.getFiltersMethod());
    }

    public ExtractElementFromListById(itemId : number) : JobOrderForSplash
    {
        var matches = this.jobOrdersList().filter((j : JobOrderForSplash) => { return j.jobOrder.JobOrderId == itemId; });
        return matches.length > 0 ? matches[0] : null;
    }

    public GetItemId(item : any) : number
    {
        return item ? item.jobOrder.JobOrderId : -1;
    }

    public RemoveFromList(itemId : number)
    {
        var item = this.ExtractElementFromListById(itemId);

        if(item)
            this.jobOrdersList.remove(item);
    }

    public CreateViewModelFor(jsonData : IJobOrderForSplash) : Promise<JobOrderForSplash>
    {
        var def = new Deferred<JobOrderForSplash>();
        return def.resolve(this.createViewModelMethod(jsonData)).promise();
    }

    public GetItemIdByJsonData(jsonData : IJobOrderForSplash) : number
    {
        return jsonData ? jsonData.JobOrderId : null;
    }

    public InsertAt(item : any, insertFromMe : boolean, index : number = -1, insert : boolean = false)
    {
        if(index == -1 || index > this.jobOrdersList().length-1)
        {
            this.jobOrdersList.push(item);
            return;
        }

        var list = this.jobOrdersList();
        list.splice(index, 0, item);
        this.jobOrdersList(list);
    }

    public GetListItemIndex(item : any) : number
    {
        return this.jobOrdersList().indexOf(item);
    }

    public ExtractLastElementFromList() : any
    {
        return this.jobOrdersList().length > 0 ? this.jobOrdersList()[this.jobOrdersList().length-1] : null;
    }
}


