import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../Core/utils/ComponentUtils";

class ForceBinding {
    init(element: HTMLElement, valueAccessor: () => any, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) {
        const name = valueAccessor();

        const firstChild = ko.virtualElements.firstChild(element) as Node;
        const firstChildVM = ko.dataFor(firstChild);
        const myContext : ko.BindingContext = ko.contextFor(element);
        const childContext = myContext.createChildContext(firstChildVM, name);

        ko.applyBindingAccessorsToNode(firstChild, () => ({ with: () => firstChildVM, as: () => name }), childContext);

        return { controlsDescendantBindings: true };
    }
}

ko.bindingHandlers["forceBinding"] = new ForceBinding();
ko.virtualElements.allowedBindings["forceBinding"] = true;

const addDependency = (app) => {
    let name = app.constructor.name;
    if(name === "Function")
        name = app.name;

    if(!window.dependencyHandles[name]) {
        window.dependencyHandles[name] = ko.observable();
        (window.dependencyHandles[name] as any).tag = name;
    }
    const dep = window.dependencyHandles[name];
    window.dependencyTracking[window.dependencyTracking.length - 1].push(dep);
}

class TsxTemplate {
    static cleanupElement(element : HTMLElement) {
        ComponentUtils.notifyWillUnmount(element);
        const firstLevelChildren = ko.virtualElements.childNodes(element);
        for(const firstLevelChild of firstLevelChildren)
            ko.cleanNode(firstLevelChild);
    }

    init(element: HTMLElement, valueAccessor: () => { render?(): Node; getComponent?(): Node; componentDidMount?(): void; componentWillUnmount?(): void; }, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) {
        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            TsxTemplate.cleanupElement(element);
        })

        return { controlsDescendantBindings: true };
    }

    update(element: HTMLElement, valueAccessor: () => { (): Node; render?(): Node; getComponent?(): Node; componentDidMount?(context : ko.BindingContext): void; componentWillUnmount?(): void; }, allBindingsAccessor: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext) {
        const maybeApplication = ko.unwrap(valueAccessor());
        const onError = allBindingsAccessor.has("tsxtemplateonerror") ? allBindingsAccessor.get("tsxtemplateonerror") : () => React.createElement("div", { className: "note note-danger" }, "Si è verificato un errore");

        let deps = [];
        
        ko.ignoreDependencies(() => {
            try
            {
                TsxTemplate.cleanupElement(element);
                ko.virtualElements.emptyNode(element);

                let nodes : Node;
                if(maybeApplication) {
                    window.dependencyTracking.push([]);

                    if(maybeApplication.getComponent) {
                        addDependency(maybeApplication);
                        nodes = maybeApplication.getComponent();
                    }
                    else if(maybeApplication.render) {
                        addDependency(maybeApplication);
                        nodes = maybeApplication.render();
                    }
                    else if(typeof maybeApplication === "function") {
                        nodes = maybeApplication();
                    } else
                        throw "Invalid object passed to tsxtemplate";

                    deps = [...deps, ...window.dependencyTracking.pop()];
                    
                    //let clonedNodes = ComponentUtils.cloneNode(nodes);
                    //ko.virtualElements.setDomNodeChildren(element, [clonedNodes]);
                    ko.virtualElements.setDomNodeChildren(element, [nodes]);
                    ComponentUtils.applyBindingsToChildren(element, bindingContext);

                    ComponentUtils.notifyDidMount(element);

                    /*if(maybeApplication.componentDidMount)
                        setTimeout(() => {
                            maybeApplication.componentDidMount(bindingContext);
                        }, 0);*/
                }
            }
            catch(e)
            {
                console.error(e);
                
                /*ko.virtualElements.emptyNode(element);

                const nodes = onError();
                ko.virtualElements.setDomNodeChildren(element, [nodes]);
                ComponentUtils.applyBindingsToChildren(element, bindingContext);*/
            }
        });

        for(const d of deps)
            d();
        
        return { controlsDescendantBindings: true };
    }    
}

ko.bindingHandlers["tsxtemplate"] = new TsxTemplate();
ko.virtualElements.allowedBindings["tsxtemplate"] = true;

export function Template(props: { component: ko.MaybeObservable<any> | (() => React.ReactElement); dependencies?: any[]; onError?: () => React.ReactElement }) {
    return ComponentUtils.setBinding(
        React.createElement("ko-bind", {"data-bind": "noop: 0" } as any)
    , { tsxtemplate: props.component, tsxtemplatedependencies: props.dependencies ?? [], tsxtemplateonerror: props.onError });
}