/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 16.05
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { DiscountsUtilities } from "../Utilities/DiscountsUtilities";
import { IResolvedCustomerDiscountCatalogArticleWithCatalog, DiscountCatalogRowMode } from "../../../DiscountsCatalogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IStockService, IStockMovement } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class MovementArticle<T extends IArticle = IArticle>
{
    public ArticleId : ko.Observable<number> = ko.observable();
    public CatalogId : ko.Observable<number> = ko.observable();
    public Description : ko.Observable<string> = ko.observable();
    public Amount : ko.Observable<number> = ko.observable();
    public UOM: ko.Observable<string> = ko.observable();
    public UnitPrice: ko.Observable<number> = ko.observable();
    public Discounts : ko.Observable<string> = ko.observable();
    public NetUnitPrice : ko.Observable<number> = ko.observable();
    public Price : ko.Observable<number> = ko.observable();
    public Availability : ko.Observable<number> = ko.observable();

    private updatingPrices  = false;
    private netUnitPriceWasManuallyChanged  = false;

    @LazyImport(nameof<IStockService>())
    private stockService : IStockService;
    private movement : IStockMovement;

    constructor(protected article : T, protected manageTransaction : boolean, protected transactionId : number = 0, protected forSupplier : boolean = false, protected forInventoryAdjustment: boolean = false)
    {
        this.ArticleId(article.Id);
        this.CatalogId(article.CatalogId);
        this.Description(article.Code + " " + article.Description);
        this.Amount(0);
        this.UOM(article.UOM);
        this.UnitPrice(this.forInventoryAdjustment ? article.Cost : (this.forSupplier ? article.DealerPrice : article.CustomerPrice));
        this.NetUnitPrice(this.forInventoryAdjustment ? article.Cost : (this.forSupplier ? article.DealerPrice : article.CustomerPrice));
        this.Price(0);

        this.Amount.subscribe(this.calculatePrices.bind(this));
        this.UnitPrice.subscribe(this.resetNetUnitPriceAndCalculatePrices.bind(this));
        this.Discounts.subscribe(this.resetNetUnitPriceAndCalculatePrices.bind(this));
        this.NetUnitPrice.subscribe(this.calculateFixedPrice.bind(this));

        this.movement = {
            StockTransactionId: this.transactionId,
            ArticleId: this.article.Id,
            Amount: this.Amount(),
            CatalogPrice: this.UnitPrice(),
            Discounts: this.Discounts(),
            NetUnitPrice: this.NetUnitPrice(),
            NetPrice: this.Price()
        };
    }

    public getMovementId() : number {
        return this.movement.Id;
    }

    public getArticleId() : number {
        return this.article.ArticleId;
    }

    public setAvailability(availability : number) {
        this.Availability(availability);
    }

    public getArticle() : IArticle {
        return this.article;
    }

    private updateMovement()
    {
        if(!this.manageTransaction)
            return;

        this.movement.Amount = this.Amount();
        this.movement.CatalogPrice = this.UnitPrice();
        this.movement.Discounts = this.Discounts();
        this.movement.NetUnitPrice = this.NetUnitPrice();
        this.movement.NetPrice = this.Price();

        this.stockService.createOrUpdateMovement(this.movement)
            .then((movement : IStockMovement) => this.movement = movement);
    }

    private resetNetUnitPriceAndCalculatePrices() {
        if(this.updatingPrices) return;
        this.netUnitPriceWasManuallyChanged = false;
        this.calculatePrices();
    }

    private calculateFixedPrice() {
        if(this.updatingPrices) return;

        this.updatingPrices = true;

        this.netUnitPriceWasManuallyChanged = true;
        this.Price(this.NetUnitPrice() * this.Amount());
        this.Discounts(undefined);
        this.updateMovement();

        this.updatingPrices = false;
    }

    private calculatePrices() {
        if (this.updatingPrices) return;

        this.updatingPrices = true;

        const discount = this.calculateDiscount();
        if (!this.netUnitPriceWasManuallyChanged)
            this.NetUnitPrice(this.UnitPrice() * discount);

        this.Price(this.NetUnitPrice() * this.Amount());
        this.updateMovement();

        this.updatingPrices = false;
    }

    private calculateDiscount() : number {
        if (this.forInventoryAdjustment)
            return 1;

        return DiscountsUtilities.calculateDiscount(this.Discounts());
    }

    public setDefaultValues(discount : IResolvedCustomerDiscountCatalogArticleWithCatalog)
    {
        if (this.forInventoryAdjustment) {
            this.Discounts(undefined);
            return;
        }

        if (discount) {
            if (DiscountCatalogRowMode.getDiscountSign(discount.Mode) > 0) //E' un ricarico quindi non mostro il ricarico nel documento
            {
                const finalPrice = discount.UnitPrice + ((discount.TotalDiscount / 100) * discount.UnitPrice);
                this.Discounts(undefined);
                this.UnitPrice(finalPrice);
            }
            else
            {
                const discountString = 
                (
                    (discount.Discount0 > 0 ? discount.Discount0 + "% " : "") +
                    (discount.Discount1 > 0 ? discount.Discount1 + "% " : "") +
                    (discount.Discount2 > 0 ? discount.Discount2 + "% " : "") +
                    (discount.Discount3 > 0 ? discount.Discount3 + "% " : "") +
                    (discount.Discount4 > 0 ? discount.Discount4 + "% " : "")
                ).trim();

                this.UnitPrice(discount.UnitPrice);
                this.Discounts(discountString);
            }
        }
    }
}