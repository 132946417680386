import * as ko from "knockout";
import {
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentCurrencyViewModel,
} from "../../../interfaces/invoice/IDocumentsService";
import { IRefDocumentRow, IEntityForRef } from "../../../interfaces/invoice/IDocumentRow";
import { IEntityRefKey } from "../../../interfaces/invoice/IEntityRefInfo";

export type IRefDocumentRowWithUOM = (IRefDocumentRow | IDocumentBuilderDocumentOriginatingRows) & {
    UnitOfMeasure: string;
};

export interface IDocumentInfoForInlineRefProvider {
    DocTypeCode: string;
    DocumentCurrencies: ko.ObservableArray<IDocumentCurrencyViewModel>;
    IsWarehouseTransfer: ko.Subscribable<boolean>;
    CustomerId?: ko.Observable<number>;
    JobOrderId?: ko.Observable<number>;
    SourceWarehouseId?: ko.Observable<number>;
    DestinationWarehouseId?: ko.Observable<number>;
    DocumentDate?: ko.Observable<Date>;
    OverrideDiscountCatalog?: ko.Observable<number>;
}

export interface IDocumentRowInlineReferenceProvider {
    ActivationCode: string;
    EntityTypeCode: string;
    DocumentInfo: IDocumentInfoForInlineRefProvider;

    getReference(rowId: number, entity: IEntityRefKey, amount: number): Promise<IRefDocumentRowWithUOM>;
}

export interface IDocumentRowInlineReferenceProviderFactory {
    SupportedDocTypes: string[]; //Tipi di documenti supportati
    ActivationCode: string; //Codice di abilitazione provider
    EntityType: string; //Tipo di entità trattata (1 solo provider per un tipo di entità!!!)
    Icon: string;

    Description: string;
    DefaultActivation: boolean;

    findEntities(
        query: string,
        skip: number,
        count: number,
        docInfo: IDocumentInfoForInlineRefProvider
    ): Promise<IEntityForRef[]>;
    create(document: IDocumentInfoForInlineRefProvider): IDocumentRowInlineReferenceProvider;
}

export interface IDocumentRowInlineReferenceProviderFactoryWithState {
    Provider: IDocumentRowInlineReferenceProviderFactory;
    Enabled: boolean;
}
export abstract class RefConverterBase implements IDocumentRowInlineReferenceProvider {
    public ActivationCode: string;
    public EntityTypeCode: string;
    public DocumentInfo: IDocumentInfoForInlineRefProvider;

    constructor(activationCode: string, entityTypeCode: string, docInfo: IDocumentInfoForInlineRefProvider) {
        this.ActivationCode = activationCode;
        this.EntityTypeCode = entityTypeCode;
        this.DocumentInfo = docInfo;
    }

    public abstract getReference(rowId: number, entity: IEntityRefKey, amount: number): Promise<IRefDocumentRowWithUOM>;

    public GetEmptyReference(rowId: number, entity: IEntityRefKey, amount: number): IRefDocumentRowWithUOM {
        return {
            RefId: 0,
            SourceEntityKeyId: entity.EntityKeyId,
            SourceEntityType: this.EntityTypeCode,
            DestEntityKeyId: rowId,
            DestEntityType: this.DocumentInfo.DocTypeCode,
            CatalogId: 0,
            Amount: amount,
            UnitPrice: 0,
            Discounts: null,
            NetUnitPrice: 0,
            NetPrice: 0,
            WarehouseId: null,
            UnitOfMeasure: "",
            DocumentId: 0,
        };
    }
}
