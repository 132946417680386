import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IArrayDataSource, IArrayDataSourceModel } from "./ArrayDataSource";
import { LazyImportSettingManager, LazyImport } from "../Core/DependencyInjection";
import { IDataSourceView } from "./IDataSource";


export interface ITaskState {
    Id: number;
    Title: string;
}

export class TaskStatesDataSource implements IArrayDataSource<ITaskState> {
    private view: IDataSourceView;

    refreshRequested: boolean = false;

    states : ITaskState[] = [
        { Id: -1, Title: ProlifeSdk.TextResources.Todolist.Backlog },
        { Id:  0, Title: ProlifeSdk.TextResources.Todolist.ToDo },
        { Id:  1, Title: ProlifeSdk.TextResources.Todolist.InProgress },
        { Id:  2, Title: ProlifeSdk.TextResources.Todolist.CompletedSingular },
        { Id:  3, Title: ProlifeSdk.TextResources.Todolist.VerifiedSingular },
        { Id:  4, Title: ProlifeSdk.TextResources.Todolist.SuspendedSingular },
        { Id:  5, Title: ProlifeSdk.TextResources.Todolist.DeletedSingular },
    ];

    public setView(view: IDataSourceView): void {
        this.view = view;

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }

    refresh(): any {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh();
    }

    public getTitle(currentModel: IArrayDataSourceModel<ITaskState>): string {
        return ProlifeSdk.TextResources.Todolist.TaskStates;
    }
    
    public async getData(currentModel: IArrayDataSourceModel<ITaskState>, textFilter: string, skip: number, count: number): Promise<IArrayDataSourceModel<ITaskState>[]> {
        return this.states
            .filter((c) => this.textFilterMatch(c, textFilter))
            .slice(skip, count)
            .map(this.createModelFor, this);
    }
    
    public async getById(currentModel: IArrayDataSourceModel<ITaskState>, ids: number[]): Promise<IArrayDataSourceModel<ITaskState>[]> {
        return this.states
            .filter(s => ids.indexOf(s.Id) != -1)
            .map(this.createModelFor, this);
    }
    
    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public isGroupedData(currentModel: IArrayDataSourceModel<ITaskState>, textFilter: string): boolean {
        return false;
    }
    
    public areEqual(a: IArrayDataSourceModel<ITaskState>, b: IArrayDataSourceModel<ITaskState>): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(taskState: ITaskState): IArrayDataSourceModel<ITaskState> {
        return {
            id: taskState.Id,
            isGroup: false,
            isLeaf: true,
            title: taskState.Title,
            model: taskState
        };
    }

    private textFilterMatch(carriage: ITaskState, textFilter: string): boolean {
        let words = (textFilter || "").toLowerCase().trim().split("");
        return words.filter(w => (carriage.Title || "").toLowerCase().indexOf(w) >= 0).length === words.length;
    }
}