import * as ko from "knockout"
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../Core/DependencyInjection";
import { ComponentUtils } from "../Core/utils/ComponentUtils";
import { IApplicationNavBarAction, IComponentApplication, INavBarActionManager } from "./interfaces/IApplication";
import { IApplicationsService } from "./interfaces/IApplicationsService";

type BaseComponentApplicationOptions = {
    tileColor?: string;
}

export abstract class BaseComponentApplication implements IComponentApplication, INavBarActionManager {
    tileColor: string;
    canShowSidebar: ko.Observable<boolean> = ko.observable(false);  
    navBarActions : ko.ObservableArray<IApplicationNavBarAction> = ko.observableArray();

    @LazyImport(nameof<IApplicationsService>())
    protected applicationsService: IApplicationsService;

    constructor(options: BaseComponentApplicationOptions) {
        this.tileColor = options.tileColor;
        
        this.applicationsService.registerApplication(this);
    }

    abstract getName(): string;    
    abstract getIcon(): string;
    abstract getApplicationCode(): string;
    abstract registerRoutes(): void;
    abstract getApplicationRoute(): string;
    abstract getComponent(): React.ReactNode;

    onGoingDown(): void {
        this.navBarActions([]);
    }

    addNavBarAction(name: ko.MaybeObservable<string> | (()=> string), icon: ko.MaybeObservable<string> | (()=> string), canRun: ko.MaybeObservable<boolean> | (()=> boolean), run: ()=> void){
        let newAction = {
            CanRun: ComponentUtils.getComputed(canRun),
            Icon: ComponentUtils.getComputed(icon),
            Name: ComponentUtils.getComputed(name),
            Run: run,
        };
        this.navBarActions.push(newAction);     
        return newAction;   
    }

    removeNavBarAction(action: IApplicationNavBarAction){
        this.navBarActions.remove(action);
    }

    clearNavBarAction(){
        this.navBarActions([]);
    }
}