import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IJobOrdersControlsEntityProvider } from "../../../ProlifeSdk/interfaces/job-order/entityProviders/IJobOrdersControlsEntityProvider";
import { IJobOrderForList } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class JobOrdersControlsEntityProvider implements IJobOrdersControlsEntityProvider
{
    private jobOrderService : IJobOrderService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    private typeFilter : number = -1;
    private stateFilter : number = -1;

    public excludeClosed : boolean = false;

    constructor(private serviceLocator : IServiceLocator){
        this.jobOrderService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public findEntities(query : any) {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.lastTimeout = setTimeout(() => {

            this.jobOrderService.GetJobOrdersList(this.stateFilter, this.typeFilter, -1, !this.excludeClosed, query.term)
                .then((data : IJobOrderForList[]) => {
                    query.callback({
                        results: data.map((jobOrder : IJobOrderForList) => {
                            return {
                                id: jobOrder.JobOrderId,
                                text: jobOrder.Name,
                                locked : jobOrder.Locked,
                                lockedByDescription : jobOrder.LockedByDescription,
                                lockedById : jobOrder.LockedById,
                                lockMotivationDescription : jobOrder.LockMotivationDescription,
                                lockMotivationId : jobOrder.LockMotivationId
                            };
                        })
                    });
                })
                .catch(() =>  {
                    query.callback({results: []});
                });
        }, 500);
    }

    public findEntity(element, callback) {
        var id = parseInt(<string> $(element).val());
        if(!isNaN(id) && id > 0) {
            this.jobOrderService.GetJobOrderForList(id)
                .then((jobOrder : IJobOrderForList) => callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name,
                    locked : jobOrder.Locked,
                    lockedByDescription : jobOrder.LockedByDescription,
                    lockedById : jobOrder.LockedById,
                    lockMotivationDescription : jobOrder.LockMotivationDescription,
                    lockMotivationId : jobOrder.LockMotivationId
                }));
        }
    }

    public setTypeFilter(type: number) {
        this.typeFilter = type || -1;
    }

    public setStateFilter(state: number) {
        this.stateFilter = state || -1;
    }

    public setDeletedFilter(getDeleted: boolean) {
        this.excludeClosed = !getDeleted;
    }
}