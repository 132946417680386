import * as ko from "knockout";
import { BaseFilter } from "./BaseFilter";

export class HtmlStringPropertyFilter extends BaseFilter {

    constructor(propertyName, filterLabel) {
        super(propertyName, filterLabel);
    }

    GetValue(obj) : string[]
    {
        //Cerco la property di filtraggio...
        if(obj[this.propertyName] != null && obj[this.propertyName] != undefined)
        {
            var propertyValue = ko.utils.unwrapObservable(obj[this.propertyName]);
            propertyValue = (propertyValue == null || propertyValue == undefined) ? "" : propertyValue;
            var innerText = $('<div>' + propertyValue.toString() + '</div>').text();
            return [innerText];
        }

        return null;
    }
}