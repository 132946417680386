import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";
import { IJobOrderForList } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class JobOrderDropDownList implements IDropDownList
{
    private jobOrderService : IJobOrderService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    public selectedId : ko.Observable<number> = ko.observable();

    constructor(private serviceLocator : IServiceLocator)
    {
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
    }

    public search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.jobOrderService.GetJobOrdersList(-1, -1, -1, false, query.term)
                .then((data) => {
                    query.callback({
                        results: data.map((g : IJobOrderForList) =>
                        {
                            return {
                                id: g.JobOrderId,
                                text: g.Name
                            };
                        })
                    });
                });
        }, 500);
    }

    public getSelected(element, callback)
    {
        if(this.selectedId()) {
            this.jobOrderService.GetJobOrderForList(this.selectedId())
                .then((g : IJobOrderForList) => callback({
                    id: g.JobOrderId,
                    text: g.Name
                }));
        }
    }
}