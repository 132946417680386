import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { PlanningTaskBoardColumn, TaskForTaskBoard } from "./PlanningTaskBoardColumn";
import { TodoListTasksProvider } from "../tasks-list/providers/TodoListTasksProvider";
import { TodoListTask } from "../tasks-list/providers/TodoListTask";
import { WorkflowCategoryControlsEntityProvider } from "../../entity-providers/WorkflowCategoryControlsEntityProvider";
import { WorkflowEditDialog } from "../workflows/WorkflowEditDialog";
import { PageSwitcher } from "../../../../ProlifeSdk/prolifesdk/PageSwitcher";
import { ShowTaskboardSearchField } from "../../../../Bindings/ShowTaskboardSearchField";
import { ChangeStateDialog } from "../tasks-list/dialogs/ChangeStateDialog";
import { ChangePlanDialog } from "../tasks-list/dialogs/ChangePlanDialog";
import {
    CartsPerDayDataSource,
    ICartPerDayDataSourceModel,
    ICartInDayPlanDataSourceModel,
} from "../../../../DataSources/CartsPerDayDataSource";
import { CartsWorkflowsDataSource } from "../../../../DataSources/CartsWorkflowsDataSource";
import { Delay } from "../../../../Decorators/Delay";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IHumanResourcesService, IHumanResource } from "../../../../Users/HumanResourcesService";
import {
    IDataSourceListener,
    IDataSourceView,
    IDataSource,
    IDataSourceModel,
} from "../../../../DataSources/IDataSource";
import {
    INavigationMenuComponentTemplatesProvider,
    INavigationMenuComponentModel,
    INavigationMenuComponent,
    INavigationMenuComponentActionsGroup,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import {
    ITodoListService,
    ITaskBoardPlanningCartInDayPlan,
    ITaskForTaskBoard,
    ITaskDialogOptions,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    ITodoListTask,
    ITaskForCartTaskBoard,
    IDraggedTask,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IApplicationHost } from "../../../../Desktop/interfaces/IApplicationHost";
import {
    IChangesNotificationsServiceObserver,
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IPageSwitcherObserver } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IPageSwitcherObserver";
import { IPageConfigurationMap } from "../../../../ProlifeSdk/interfaces/IPageConfigurationMap";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { PlanningTaskboardMenuMode } from "../../../../JobOrder/jobOrder/settings/enums/PlanningTaskboardMenuMode";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { ProLifeClipboard } from "../../../../Core/utils/ProLifeClipboard";

export class PlanningTaskBoard
    implements
        IChangesNotificationsServiceObserver,
        IPageSwitcherObserver,
        IDataSourceListener,
        INavigationMenuComponentTemplatesProvider
{
    public templateName = "planning-task-board";
    public templateUrl = "todolist/templates/task-board";

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImport(nameof<ITodoListService>())
    public todoListService: ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService: IHumanResourcesService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IApplicationHost>())
    private applicationHostService: IApplicationHost;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettings: IJobOrderGeneralSettingsManager;

    public cartSearchFilter: ko.Observable<string> = ko.observable("");
    //public carts : ko.ObservableArray<CartForResource> = ko.observableArray([]);
    //public filteredCarts : ko.Computed<CartForResource[]>;
    public CartsPerDayDataSource: CartsPerDayDataSource = new CartsPerDayDataSource();
    public CartsPerDayMenu: ko.Observable<IDataSourceView> = ko.observable();

    public CartsWorkflowsDataSource: CartsWorkflowsDataSource = new CartsWorkflowsDataSource();
    public CartsWorkflowsMenu: ko.Observable<IDataSourceView> = ko.observable();

    public ViewWorker: ko.Observable<boolean> = ko.observable(true);
    public ViewResponsible: ko.Observable<boolean> = ko.observable(true);
    public ViewAll: ko.Observable<boolean> = ko.observable(false);
    public ShowClosed: ko.Observable<boolean> = ko.observable(false);
    public HideNoWork: ko.Observable<boolean> = ko.observable(false);

    public workflowsSearchFilter: ko.Observable<string> = ko.observable("");
    //public workflows : ko.ObservableArray<WorkflowForTaskBoard> = ko.observableArray([]);
    //public workflowGroups : ko.ObservableArray<WorkflowStateGroup> = ko.observableArray();
    //public cartGroups : ko.Computed<CartGroup[]>;
    //public filteredWorkflows : ko.Computed<WorkflowForTaskBoard[]>;

    public selectedCart: ko.Observable<ITaskBoardPlanningCartInDayPlan> = ko.observable();
    public selectedWorkflow: ko.Observable<IWorkflowForTaskBoard> = ko.observable();
    public selectedTask: ko.Computed<TaskForTaskBoard>;
    public selectedTasks: ko.ObservableArray<TaskForTaskBoard> = ko.observableArray([]);

    private selectedDate: ko.Observable<Date> = ko.observable();

    public Columns: PlanningTaskBoardColumn[] = [];

    private tasksProvider: TodoListTasksProvider;
    private currentResource: IHumanResource;

    public Category: ko.Observable<number> = ko.observable();
    public CategoriesProvider: WorkflowCategoryControlsEntityProvider;

    public ResourceId: ko.Observable<number> = ko.observable();
    public SearchFilter: ko.Observable<string> = ko
        .observable()
        .extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

    /* Per versione mobile */
    public taskboardSearchFieldEnabler: ShowTaskboardSearchField;
    public taskboardPageSwitcherHandler: PageSwitcher;
    public pageSwitcherMap: IPageConfigurationMap = {};
    private afterDeleteCallback: (taskId: number) => void;
    /* ------------------- */

    public SetActivity: ko.Observable<any> = ko.observable();
    public ShowWorkflowSelectorButton: ko.Observable<boolean> = ko.observable(false);
    public CartsPerDayMenuStandardView: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator: IServiceLocator, public UserId: ko.Observable<number>) {
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.TaskBoardTaskEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);

        this.tasksProvider = new TodoListTasksProvider();
        this.CategoriesProvider = new WorkflowCategoryControlsEntityProvider();

        this.CartsWorkflowsDataSource.setShowJobOrderName(true);

        const columnSize = 100.0 / 7.0;

        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                -1,
                ProlifeSdk.TextResources.Todolist.Backlog,
                columnSize,
                columnSize * 0,
                "fa fa-inbox",
                "#c6c6c6"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                0,
                ProlifeSdk.TextResources.Todolist.ToDo,
                columnSize,
                columnSize * 1,
                "fa fa-tachometer",
                "#428bca"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                1,
                ProlifeSdk.TextResources.Todolist.InProgress,
                columnSize,
                columnSize * 2,
                "fa fa-bolt",
                "#ecbc29"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                2,
                ProlifeSdk.TextResources.Todolist.Completed,
                columnSize,
                columnSize * 3,
                "fa fa-flag-checkered",
                "#45b6af"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                3,
                ProlifeSdk.TextResources.Todolist.Verified,
                columnSize,
                columnSize * 4,
                "fa fa-check",
                "#45b6af"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                4,
                ProlifeSdk.TextResources.Todolist.Suspended,
                columnSize,
                columnSize * 5,
                "fa fa-clock-o",
                "#89c4f4"
            )
        );
        this.Columns.push(
            new PlanningTaskBoardColumn(
                serviceLocator,
                this,
                5,
                ProlifeSdk.TextResources.Todolist.DeletedColumn,
                columnSize,
                columnSize * 5,
                "fa fa-trash-o",
                "#f3565d"
            )
        );

        this.recalculateColumnSizes();

        let updating = false;

        this.ViewWorker.subscribe(() => {
            if (updating) return;

            updating = true;

            if (!this.ViewWorker() && !this.ViewResponsible()) {
                this.ViewWorker(true);
                updating = false;
                return;
            }

            updating = false;

            this.LoadCarts();
        });
        this.ViewResponsible.subscribe(() => {
            if (updating) return;

            updating = true;

            if (!this.ViewWorker() && !this.ViewResponsible()) {
                this.ViewResponsible(true);
                updating = false;
                return;
            }

            updating = false;

            this.LoadCarts();
        });

        this.ViewAll.subscribe(() => {
            this.LoadCarts();
        });

        this.ShowClosed.subscribe(() => {
            this.LoadCarts();
        });

        this.HideNoWork.subscribe(() => {
            this.LoadCarts();
        });

        this.Category.subscribe(this.LoadCarts.bind(this));

        this.ResourceId.subscribe(() => {
            if (updating) return;

            updating = true;

            this.humanResourcesService
                .GetHumanResource(this.ResourceId())
                .then((r: IHumanResource) => {
                    this.UserId(r.Resource.FkUser);
                    this.currentResource = r;
                    this.LoadCarts();
                })
                .finally(() => {
                    updating = false;
                });
        });

        this.UserId.subscribe(() => {
            if (!this.UserId()) return;

            if (!updating) {
                updating = true;

                this.humanResourcesService
                    .GetHumanResources()
                    .then((resources: IHumanResource[]) => {
                        const matches = resources.filter((r: IHumanResource) => r.Resource.FkUser == this.UserId());
                        this.ResourceId(matches.length == 0 ? null : matches[0].Resource.Id);
                        this.currentResource = matches.length == 0 ? null : matches[0];
                        this.LoadCarts();
                    })
                    .finally(() => {
                        updating = false;
                    });
            } else {
                this.LoadCarts();
            }
        });

        this.UserId.valueHasMutated();

        const planningTaskboardStartMode = this.generalSettings.getPlanningTaskboardMenuStartMode();
        this.CartsPerDayMenuStandardView(planningTaskboardStartMode === PlanningTaskboardMenuMode.Calendar);
        this.CartsPerDayDataSource.standardMode(this.CartsPerDayMenuStandardView());
        this.CartsPerDayMenu.subscribe((menu: INavigationMenuComponent) => {
            const action: INavigationMenuComponentActionsGroup = {
                isGroup: true,
                isSeparator: false,
                icon: () => "fa fa-angle-down",
                actions: [
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "glyphicon glyphicon-calendar",
                        text: "Mod. calendario",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => this.CartsPerDayMenuStandardView(),
                        action: () => {
                            this.CartsPerDayMenuStandardView(true);

                            this.CartsPerDayDataSource.standardMode(true);
                            menu.reset();

                            this.CartsPerDayDataSource.navigateToMonth(new Date());
                        },
                    },
                    {
                        isGroup: false,
                        isSeparator: false,
                        icon: "glyphicon glyphicon-list",
                        text: "Mod. lista",
                        visible: () => true,
                        canExecute: () => true,
                        active: () => !this.CartsPerDayMenuStandardView(),
                        action: () => {
                            this.CartsPerDayMenuStandardView(false);

                            this.CartsPerDayDataSource.standardMode(false);
                            menu.reset();
                        },
                    },
                ],
            };

            menu.registerAction(action);

            if (this.CartsPerDayMenuStandardView()) this.CartsPerDayDataSource.navigateToMonth(new Date());
        });

        this.selectedTask = ko.computed(() => {
            const tasks = this.selectedTasks();
            return tasks.length == 0 ? null : tasks[0];
        });

        /* Per versione mobile */
        this.taskboardSearchFieldEnabler = new ShowTaskboardSearchField();
        this.taskboardPageSwitcherHandler = new PageSwitcher();
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Projects,
            true,
            ProlifeSdk.TextResources.Todolist.ProjectsPageTitle,
            true,
            "next",
            false,
            false,
            true
        );
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Plans,
            false,
            ProlifeSdk.TextResources.Todolist.PlansPageTitle,
            true,
            "next",
            false
        );
        this.taskboardPageSwitcherHandler.addPage(
            ProlifeSdk.Page_Taskboard,
            false,
            ProlifeSdk.TextResources.Todolist.TaskboardPageTitle
        );
        this.Columns.forEach((c: PlanningTaskBoardColumn, i: number) => {
            const INDEX = "TaskboardColumn" + i;
            this.taskboardPageSwitcherHandler.addPage(INDEX, false, c.title, true, "next", false);
        });
        this.taskboardPageSwitcherHandler.registerObserver(this);
        this.desktopService.SystemHeader.setContextMenu({
            templateName: "task-board-filters",
            templateUrl: "Todolist/Templates/Task-Board",
            viewModel: this,
        });
        this.pageSwitcherMap[ProlifeSdk.Page_Projects] = {
            onGoingBack: this.deselectJobOrder.bind(this),
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };
        this.pageSwitcherMap[ProlifeSdk.Page_Plans] = {
            onGoingBack: this.deselectWorkflow.bind(this),
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };
        this.pageSwitcherMap[ProlifeSdk.Page_Taskboard] = {
            onGoingBack: () => {},
            LeftMenuEnabled: true,
            RightMenuEnabled: true,
        };

        this.applicationHostService.SetSideMenuEnabled(true, true);
        /* ------------------- */
    }

    public hasTemplateFor(dataSource: IDataSource, model: INavigationMenuComponentModel): boolean {
        const dayModel = model as ICartPerDayDataSourceModel;
        if (dayModel.isDay) return true;

        const cartModel = model as ICartInDayPlanDataSourceModel;
        if (cartModel.isCart) return true;

        return false;
    }

    public templatesProvider(dataSource: IDataSource, model: INavigationMenuComponentModel): Node {
        const dayModel = model as ICartPerDayDataSourceModel;
        if (dayModel.isDay)
            return (
                <div
                    data-bind="style: { 'border-left' : $data.isToday ? '4px solid rgb(217, 168, 19)' : '' }"
                    style="margin: -10px; padding: 10px;"
                    class="day-menu-component">
                    <div>
                        <span data-bind="style : { color : $data.isToday ? '#d9a813' : ($data.isHoliday ? '#db6f57' : ''), 'font-size' : $data.isToday ? '18px' : 'inherit' }">
                            <span>
                                <strong data-bind="text : $data.dayNumber"></strong>
                            </span>
                            &nbsp;
                            <span data-bind="text : $data.title"></span>
                        </span>

                        <span class="pull-right approval-icons">
                            <span
                                style="color: rgb(0, 196, 255)"
                                title={TextResources.Todolist.HasPriorityWorkTitle}
                                data-bind="attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: (model.HasPriorityWorkToDo) ? 'rgb(0, 196, 255)' : (!$parent.Selected() ? '#4e4e4e' : '#859caf') }">
                                <i class="glyphicon glyphicon-flag"></i>
                            </span>

                            <span
                                style="color: rgb(210, 233, 253)"
                                title={TextResources.Todolist.NewCartStartTitle}
                                data-bind="attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: (model.HasNewCart ? 'rgb(210, 233, 253)' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) }">
                                <i class="glyphicon glyphicon-shopping-cart"></i>
                            </span>

                            <span
                                style="color: orange"
                                title={TextResources.Todolist.HasWorkToDoTitle}
                                data-bind="attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: (model.HasWorkToDo ? 'orange' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) }">
                                <i class="glyphicon glyphicon-exclamation-sign"></i>
                            </span>

                            <span
                                style="color: red"
                                title={TextResources.Todolist.HasManualWorkToDoTitle}
                                data-bind="attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: (model.HasManualWorkToDo ? 'red' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) }">
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>

                            <span
                                style="color: lime"
                                title={TextResources.Todolist.HasMilestoneTitle}
                                data-bind="attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top'}, tooltip: {}, style: { color: (model.HasMilestone ? 'lime' : (!$parent.Selected() ? '#4e4e4e' : '#859caf')) }">
                                <i class="glyphicon glyphicon-star"></i>
                            </span>
                        </span>
                    </div>
                    <div data-bind="html: $data.detail"></div>
                    <div data-bind="visible: !$data.detail">&nbsp;</div>
                </div>
            );

        const cartModel = model as ICartInDayPlanDataSourceModel;
        if (cartModel.isCart)
            return (
                <>
                    <ko-if data-bind="$data.isOnGoing">
                        <div class="date">
                            <span
                                class="pull-right"
                                style="margin-left: 9px"
                                title={TextResources.Allocations.OnGoingActivity}>
                                <i class="fa fa-cog fa-spin"></i>
                            </span>
                            <ko-if data-bind="$data.badge">
                                <ko-if data-bind="$data.badge.action">
                                    <button
                                        class="badge pull-right"
                                        style="border: none;"
                                        data-bind="text: $data.badge.text, css : $data.badge.cssClass, asyncClick: $data.badge.action.bind($data.badge)"></button>
                                </ko-if>
                                <ko-ifnot data-bind="$data.badge.action">
                                    <ko-ifnot data-bind="$data.badge.icon">
                                        <span
                                            class="badge pull-right"
                                            data-bind="text: $data.badge.text, css : $data.badge.cssClass, attr: { title: $data.badge.title }"></span>
                                    </ko-ifnot>
                                    <ko-if data-bind="$data.badge.icon">
                                        <span
                                            class="badge pull-right"
                                            data-bind="css : $data.badge.cssClass, attr: { title: $data.badge.title }">
                                            <i
                                                class="fa"
                                                data-bind="css: $data.badge.icon"
                                                style="position: relative; top: -2px"></i>
                                        </span>
                                    </ko-if>
                                </ko-ifnot>
                            </ko-if>
                        </div>
                    </ko-if>
                    <div class="col1">
                        <div class="cont">
                            <div class="cont-col1">
                                <ko-if data-bind="$data.icon">
                                    <div
                                        class="btn btn-primary btn-lg"
                                        data-bind="style: { background: icon.background, color: icon.foreground }"
                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0;">
                                        <i data-bind="css: icon.icon"></i>
                                    </div>
                                </ko-if>
                                <br />

                                <ko-if data-bind="$data.secondaryAction">
                                    <div
                                        class="btn btn-warning btn-lg"
                                        data-bind="click: $data.secondaryAction.action, clickBubble: false, with: $data.secondaryAction.icon, style: { background: $data.secondaryAction.icon.background, color: $data.secondaryAction.icon.foreground }, attr: { title: $data.secondaryAction.title }"
                                        style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; margin-top: 5px;">
                                        <i class="fa" data-bind="css: icon"></i>
                                    </div>
                                </ko-if>
                            </div>
                            <div class="cont-col2">
                                <div class="desc" data-bind="css: { 'without-icon': !$data.icon }">
                                    <div data-bind="text: title"></div>
                                    <ko-if data-bind="$data.subTitle">
                                        <ko-ifnot data-bind="$data.multilineSubtitle">
                                            <div class="label label-sm menu-item-subtitle" style="max-width: 160px">
                                                <span style="font-size: 16px">
                                                    {TextResources.Allocations.EffectiveDate}&nbsp;
                                                    <span data-bind="dateText: $data.effectiveDate"></span>
                                                </span>
                                            </div>
                                            <div class="label label-sm menu-item-subtitle" style="max-width: 160px">
                                                <span style="font-size: 16px">
                                                    {TextResources.Allocations.LastAllocatedDate}&nbsp;
                                                    <span data-bind="dateText: $data.lastAllocatedDate"></span>
                                                </span>
                                            </div>
                                            <div class="label label-sm menu-item-subtitle" style="max-width: 160px">
                                                <span style="font-size: 10px">
                                                    {TextResources.Allocations.FirstAllocatedDate}&nbsp;
                                                    <span data-bind="dateText: $data.firstAllocatedDate"></span>
                                                </span>
                                            </div>
                                            <div
                                                class="label label-sm menu-item-subtitle"
                                                data-bind="visible: subTitle"
                                                style="max-width: 160px; display: block">
                                                <span data-bind="text: subTitle" style="color: #a2a2a2"></span>
                                            </div>
                                        </ko-ifnot>
                                        <span class="approval-icons pull-right">
                                            <span data-bind="visible: showDatesDifferenceInDays, attr: { 'data-placement' : $index() == 0 ? 'bottom' : 'top', 'data-original-title': $data.datesDifferenceInDays <= 0 ? 'WorkWithoutLateTitle' : 'WorkWithLateTitle' }, tooltip: {}, style: { color: ($data.datesDifferenceInDays <= 0) ? 'green' : 'red' }">
                                                <i class="fa fa-clock-o"></i>
                                                {TextResources.Todolist.GG}&nbsp;
                                                <span data-bind="text: $data.datesDifferenceInDays"></span>
                                            </span>
                                        </span>
                                        <ko-if data-bind="$data.multilineSubtitle">
                                            <div
                                                class="label label-sm multiline-label"
                                                data-bind="visible: subTitle, foreach: subTitle">
                                                <span data-bind="text: $data"></span>
                                            </div>
                                        </ko-if>
                                    </ko-if>
                                    <ko-if data-bind="$data.progressBar">
                                        <div
                                            class="progress"
                                            style="width: 140px; height: 10px; margin-top: 10px; margin-bottom: 0; display: inline-block"
                                            data-bind="with: progressBar">
                                            <div
                                                class="progress-bar progress-bar-success"
                                                style="min-width: 0"
                                                role="progressbar"
                                                aria-valueMin={0}
                                                aria-valueMax={100}
                                                data-bind="attr : { 'aria-valuenow' : progress }, style : { width : progress + '%' }"></div>
                                        </div>
                                    </ko-if>
                                    <ko-if data-bind="$data.secondaryIcon">
                                        <div
                                            class="btn btn-primary btn-lg"
                                            data-bind="style: { background: secondaryIcon.background, color: secondaryIcon.foreground }"
                                            style="min-width: 30px; min-height: 30px; text-align: center; line-height: 30px; padding: 0; display: inline-block; float: right; margin-top: -10px">
                                            <i data-bind="css: secondaryIcon.icon"></i>
                                        </div>
                                    </ko-if>
                                    <ko-if data-bind="$data.alerts">
                                        <div data-bind="with: alerts">
                                            <span
                                                class="pull-left"
                                                style="margin-right: 5px; line-height: 22px"
                                                data-bind="text: label"></span>
                                            <ko-foreach data-bind="icons">
                                                <div
                                                    class="btn btn-default workflow-navigator-alert-icon pull-left"
                                                    style="margin-right: 5px"
                                                    data-bind="attr: { title: tooltip }, tooltip: {}, with: icon">
                                                    <i class="" data-bind="css: icon, style: { color: foreground }"></i>
                                                </div>
                                            </ko-foreach>
                                        </div>
                                    </ko-if>
                                </div>
                                <ko-if data-bind="$data.details">
                                    <div
                                        class="desc"
                                        style="margin: 0px; padding: 0px; max-width: 100%"
                                        data-bind="component: { name: details.componentName, params: details.model }"></div>
                                </ko-if>
                            </div>
                        </div>
                    </div>
                </>
            );

        return undefined;
    }

    getSelectedDate(): Date {
        return this.selectedDate();
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.CartsPerDayDataSource) {
            const cartModel = model as ICartInDayPlanDataSourceModel;
            this.selectedCart(model.model);
            this.selectedDate(cartModel.selectedDate);
            this.Columns.forEach((c) => c.Clear());
            this.selectedWorkflow(null);
            this.selectedTasks([]);
            this.LoadWorkflows();
            this.LoadTasks();

            this.taskboardPageSwitcherHandler.goToPage(ProlifeSdk.Page_Taskboard);
        } else if (sender === this.CartsWorkflowsDataSource) {
            this.selectedWorkflow(model.model);
            this.selectedTasks([]);
            this.LoadTasks();

            this.taskboardPageSwitcherHandler.goBack();
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.CartsWorkflowsDataSource) {
            this.selectedWorkflow(null);
            this.selectedTasks([]);
            this.LoadTasks();
        }
    }

    goToSelectWorkflow() {
        this.taskboardPageSwitcherHandler.goToPage(ProlifeSdk.Page_Plans);
    }

    onGoingBack(newPageName: string) {
        if (typeof this.pageSwitcherMap[newPageName] != "undefined") this.pageSwitcherMap[newPageName].onGoingBack();
    }

    onPageChanged(newPageName: string, oldPageName: string) {
        if (this.pageSwitcherMap[newPageName] != undefined)
            this.applicationHostService.SetSideMenuEnabled(
                this.pageSwitcherMap[newPageName].LeftMenuEnabled,
                this.pageSwitcherMap[newPageName].RightMenuEnabled
            );

        this.ShowWorkflowSelectorButton(newPageName.indexOf("TaskboardColumn") >= 0);
    }

    deselectJobOrder() {
        /*if (this.selectedCart() != null)
            this.selectedCart().Selected(false);*/
    }

    deselectWorkflow() {
        /*if (this.selectedWorkflow() != null)
            this.selectedWorkflow().Selected(false);*/
    }

    private findTaskColumn(taskId: number, isTask: boolean) {
        for (const c of this.Columns) {
            if (!c.ContainsTask(taskId, isTask)) continue;
            return c;
        }

        return null;
    }

    private findColumnByStatus(statusId: number) {
        return this.Columns.find((c) => c.status === statusId);
    }

    public async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sendByMe: boolean) {
        if (!this.selectedCart())
            //Se non è stato selezionato un carrello ignoro l'evento
            return true;

        if (changesInfo.EntityCode == ProlifeSdk.WorkflowEntityTypeCode) {
            //Se l'evento riguarda un piano
            this.LoadWorkflows();
        }

        const isTask = changesInfo.EntityCode !== ProlifeSdk.WorkflowEntityTypeCode;
        const elementId = changesInfo.EntityKeyId;

        if (changesInfo.Action === 0 && isTask) {
            //Task created
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (selectedWorkflow && changesInfo.Object.workflowId !== selectedWorkflow) return true;

            const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [elementId]);
            if (!task) return false; //Task not found

            const column = this.findColumnByStatus(task.TaskBoardStatus);
            column.AddTask(task as ITaskForCartTaskBoard);
        }

        if (changesInfo.Action === 1 && isTask) {
            //Task modified
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (selectedWorkflow && changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const existingColumn = this.findTaskColumn(elementId, isTask);
            if (!existingColumn) return false; //Task not found
            existingColumn.RemoveTask(elementId, isTask);

            const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [elementId]);
            if (!task) return false; //Task not found

            const column = this.findColumnByStatus(task.TaskBoardStatus);
            column.AddTask(task as ITaskForCartTaskBoard);
        }

        if (changesInfo.Action === 2 && isTask) {
            //Task deleted
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (selectedWorkflow && changesInfo.Object?.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            column.RemoveTask(elementId, isTask);
        }

        if (changesInfo.Action === 8 && isTask) {
            //Status Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (selectedWorkflow && changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            const task = column.RemoveTask(elementId, isTask);
            if (!task) return false; //Task not found

            task.task.TaskBoardStatus = changesInfo.Changes.NewStatus;
            const newColumn = this.findColumnByStatus(changesInfo.Changes.NewStatus);
            newColumn.AddTask(task.task);
        }

        if (changesInfo.Action === 9 && isTask) {
            //Workflow Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            const taskId = changesInfo.Object?.taskId;

            if (changesInfo.Object.workflowId === selectedWorkflow) {
                const [task] = await this.todoListService.GetTasksForUserById(this.ResourceId(), [taskId]);
                if (!task) return false; //Task not found

                const column = this.findColumnByStatus(task.TaskBoardStatus);
                column.AddTask(task as ITaskForCartTaskBoard);
            }

            if (changesInfo.Changes.OldStatus.previousWorkflowId === selectedWorkflow) {
                const column = this.findTaskColumn(taskId, isTask);
                if (!column) return false; //Task not found

                column.RemoveTask(taskId, isTask);
            }
        }

        if (changesInfo.Action === 10 && isTask) {
            //Task Position Changed
            const selectedWorkflow = this.selectedWorkflow()?.Id;
            if (selectedWorkflow && changesInfo.Object.workflowId !== selectedWorkflow) return false;

            const column = this.findTaskColumn(elementId, isTask);
            if (!column) return false; //Task not found

            const task = column.RemoveTask(elementId, isTask);
            if (!task) return false; //Task not found

            task.task.PositionIndex = changesInfo.Object.newPosition;
            column.AddTask(task.task);
        }

        let action: string = ProlifeSdk.TextResources.Todolist.Modified;
        action = changesInfo.Action == 0 ? ProlifeSdk.TextResources.Todolist.Inserted : action;
        action = changesInfo.Action == 2 ? ProlifeSdk.TextResources.Todolist.Deleted : action;

        if (!sendByMe)
            this.infoToastService.Info(
                String.format(
                    ProlifeSdk.TextResources.Todolist.TaskChangeMessage,
                    changesInfo.Object.title,
                    action,
                    changesInfo.UserFullName
                )
            );

        return false;
    }

    public dispose() {
        this.changesNotificationsService.RemoveObserver(this);
    }

    public CreateNewTask() {
        this.todoListService.ShowCreateNewTaskDialog(null, this.selectedWorkflow().Id);
    }

    public StatePlusOne(status: number) {
        if (!this.selectedTask()) return;

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            this.Columns.forEach((t: PlanningTaskBoardColumn) => {
                if (((status + 2) % 6) - 1 === t.status) {
                    t.SetSelectedState(t);
                    t.MoveToState(task.Id, task.IsTask());
                }
            });
        });
    }

    public EditSelectedWorkflow() {
        const workflow = this.selectedWorkflow();
        this.todoListService.GetWorkflow(workflow.Id).then((w: ITodoListWorkflow) => {
            const dialog: WorkflowEditDialog = new WorkflowEditDialog({ workflow: w });
            dialog.ShowDialog();
        });
    }

    public async EditSelectedTask() {
        if (!this.selectedTask()) return false;

        const taskId = this.selectedTask().task.Id;
        const options: ITaskDialogOptions = {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            showClosedJobOrders: false,
            tasksProvider: this.tasksProvider,
            workedHoursData: { resourceId: this.ResourceId(), date: this.selectedDate() },
            containerId: !this.selectedWorkflow() ? this.selectedTask().task.WorkflowId : this.selectedWorkflow().Id,
        };

        return this.todoListService.ShowEditTaskDialog(taskId, options);
    }

    public async NewTaskFromTask(): Promise<boolean> {
        const workflowId = !this.selectedWorkflow() ? this.selectedTask().task.WorkflowId : this.selectedWorkflow().Id;
        const jobOrderId = !this.selectedWorkflow()
            ? this.selectedTask().task.JobOrderId
            : this.selectedWorkflow().JobOrderId;

        return this.todoListService.ShowCopyTaskDialog(workflowId, jobOrderId, {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            tasksProvider: this.tasksProvider,
        });
    }

    public async NewTaskFromTemplate(): Promise<boolean> {
        const workflowId = !this.selectedWorkflow() ? this.selectedTask().task.WorkflowId : this.selectedWorkflow().Id;
        const jobOrderId = !this.selectedWorkflow()
            ? this.selectedTask().task.JobOrderId
            : this.selectedWorkflow().JobOrderId;

        return this.todoListService.ShowCreateTaskFromTemplateDialog(workflowId, jobOrderId, {
            initialViewAll: this.authorizationService.isAuthorized("TaskBoard_CanViewAll"),
            tasksProvider: this.tasksProvider,
        });
    }

    public OpenDialogForChangeState() {
        if (!this.selectedTask()) return;

        const dialog: ChangeStateDialog = new ChangeStateDialog(this.serviceLocator, this.tasksProvider, this);

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
            dialog.SetActivity(task);
            dialog.ShowModal();
        });
    }

    public OpenDialogForChangePlan() {
        if (!this.selectedTask()) return;

        const dialog: ChangePlanDialog = new ChangePlanDialog(this.serviceLocator, this.tasksProvider, this);

        this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
            const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
            //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
            dialog.SetActivity(task);
            dialog.ShowModal();
        });
    }

    public DeleteTask() {
        if (!this.selectedTask() || !this.selectedTask().task.IsTask) {
            return;
        }
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Todolist.SureToDeleteTask,
            ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
            ProlifeSdk.TextResources.Todolist.DeleteTask,
            (confirm: boolean) => {
                if (!confirm) return;
                this.todoListService.GetTaskById(this.selectedTask().task.Id).then((t: ITodoListTask) => {
                    const task: TodoListTask = new TodoListTask(null, t, this.tasksProvider);
                    //task.ShowDetails(); 26/06/2020 inutile perché chiama la stessa API utilizzata sopra e ricarica i dati. Inoltre crea problemi i interfaccia di editing sui select2 (i select2 usati nelle righe di stima vengono creati, distrutti e ricreati perché la LoadFromModel viene fatta sia nel costruttore che nella ShowDetails, ma il codice tenta di accedere ai select2 distrutti e schianta)
                    this.SetActivity(task);
                    this.SetActivity().Delete();
                });
            }
        );
    }

    public SelectTask(t: TaskForTaskBoard, evt: MouseEvent) {
        if (this.selectedTask() && (!evt || (!evt.ctrlKey && !evt.metaKey))) {
            for (const task of this.selectedTasks()) {
                task.IsSelected(false);
            }
            this.selectedTasks([]);
        }

        this.selectedTasks.push(t);
        t.IsSelected(true);
    }

    public onCopy() {
        if (!this.selectedTasks().length) return;

        let taskTitles = "";
        for (const task of this.selectedTasks()) {
            taskTitles += task.task.Title + "\n";
        }
        taskTitles = taskTitles.trim();

        ProLifeClipboard.copy(taskTitles);

        if (this.selectedTasks().length > 1)
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskTitlesCopiedToClipboard);
        else this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskTitleCopiedToClipboard);
    }

    onBeginDrag(task: ITaskForCartTaskBoard, dataTransfer: DataTransfer) {
        let allTasks = this.selectedTasks().map((t) => t.task);
        if (allTasks.length == 0)
            //Se non ho una selezione, uso il task draggato
            allTasks.push(task);
        else if (allTasks.indexOf(task) == -1)
            //Se il task draggato non è nella selezione, uso il task draggato
            allTasks = [task];

        let taskTitles = "";
        for (const task of allTasks) {
            taskTitles += task.Title + "\n";
        }
        taskTitles = taskTitles.trim();

        const draggedTasks = allTasks.map(this.createDraggedTask, this);

        dataTransfer.setData("text/plain", taskTitles);
        dataTransfer.setData("application/prolife-task", JSON.stringify(draggedTasks[0]));
        dataTransfer.setData("application/prolife-tasks", JSON.stringify(draggedTasks));
    }

    private createDraggedTask(task: ITaskForTaskBoard): IDraggedTask {
        return {
            IsTask: task.IsTask,
            TaskId: task.Id,
            TaskBoardStatus: task.TaskBoardStatus,
            WorkflowId: task.WorkflowId,
            JobOrderId: task.JobOrderId,
            CompanyGuid: this.userInfo.getCurrentCompanyGuid(),
        };
    }

    @Delay()
    private LoadTasks() {
        this.Columns.forEach((c: PlanningTaskBoardColumn) => {
            c.LoadTasks();
        });
    }

    private LoadCarts() {
        this.selectedCart(null);
        this.selectedWorkflow(null);
        this.selectedTasks([]);

        this.Columns.forEach((c) => c.Clear());

        this.CartsPerDayDataSource.setResourceId(this.ResourceId());
        this.CartsPerDayDataSource.refresh();

        this.LoadWorkflows();
    }

    private LoadWorkflows() {
        this.CartsWorkflowsDataSource.setCartId(this.selectedCart() ? this.selectedCart().CartId : null);
        this.CartsWorkflowsDataSource.setUserId(this.UserId());
        this.CartsWorkflowsDataSource.setCategoryFilter(this.Category());
        this.CartsWorkflowsDataSource.refresh();
    }

    public recalculateColumnSizes() {
        const collapsedColumns = this.Columns.filter((c) => c.isCollapsed());
        const expandedColumns = this.Columns.filter((c) => !c.isCollapsed());

        const collapsedColumnWidth = 10;
        const expandedColumnMeanWidth =
            (100 - 1 - collapsedColumns.length * collapsedColumnWidth) / expandedColumns.length;
        let left = 0;

        this.Columns.forEach((c) => {
            if (!c.isCollapsed()) {
                c.columnWidth(expandedColumnMeanWidth);
                c.columnOffset(left);

                left += expandedColumnMeanWidth;
            } else {
                c.columnWidth(collapsedColumnWidth);
                c.columnOffset(left);

                left += collapsedColumnWidth;
            }
        });
    }
}

interface IJobOrderForTaskBoard {
    Id: number;
    Name: string;
    Locked: boolean;
    LockMotivationDescription?: string;
    LockMotivationId?: number;
    LockedByDescription?: string;
    LockedById?: number;
    CustomerName?: string;
    TasksCount?: number;
    NotCompleteTasksCount?: number;
    Icon?: string;
    Background?: string;
    Foreground?: string;
    StateId?: number;
    StateDescription?: string;
    IsReady: boolean;
    HasWarehouse: boolean;
    HasPurchases: boolean;
    HasResources: boolean;
    IsLateStart: boolean;
    IsLateEnd: boolean;
    HasReportingTasks: boolean;
    HoursToBeBilled: number;
}

interface IWorkflowForTaskBoard {
    Id: number;
    Title: string;
    InQuality: boolean;
    HideFromSuggestions: boolean;
    TasksCount?: number;
    NotCompleteTasksCount?: number;
    Icon?: string;
    Background?: string;
    Foreground?: string;
    StateId?: number;
    OutcomeIcon: string;
    OutcomeBackground: string;
    OutcomeForeground: string;
    IsReady: boolean;
    JobOrderId: number;
    HasWarehouse: boolean;
    HasPurchases: boolean;
    HasResources: boolean;
    IsLateStart: boolean;
    IsLateEnd: boolean;
    HasWorkflowWarehouse: boolean;
    WorkflowIsReady: boolean;
    HasReportingTasks: boolean;
    HoursToBeBilled: number;
    TotalReestimatedHours: number;
    AllocatedHours: number;
    UnallocatedHours: number;
    HasNotEstimatedElementsAllocated: boolean;
}
