import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import TsxForEach from "../../../Components/ForEach";
import { ITaskForTaskBoard, ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { PercentageInput } from "../../../Components/PercentageInputComponent";
import { IDataSourceModel } from "../../../DataSources/IDataSource";

const styleSheet = jss.createStyleSheet({
    workflowStatusDialog: {
        "& h4": {
            borderBottom: "1px solid #ddd",
            margin: "0",
            padding: "10px 0"
        }
    }
});
const { classes } = styleSheet.attach();

type WorkflowStatusAfterSaveDialogProps = {
    tasksIds: number[]
}

type WorkflowStatusAfterSaveProps = {
    tasksIds: number[],
    forwardRef?: (dialog: _WorkflowStatusAfterSave) => void;
}

export type TaskStatusInfo = {
    Id: number;
    Status: number;
    ProgressAvg: number;
    WillChangeStatus: boolean;
    CanChangeStatus: boolean;
}

export type ConfirmedTasksStatus = {
    Confirm: boolean;
    TasksStatus: TaskStatusInfo[];
}

export class WorkflowStatusAfterSaveDialog extends DialogComponentBase {
    static defaultProps: Partial<WorkflowStatusAfterSaveProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private ref: _WorkflowStatusAfterSave;

    constructor(private props : WorkflowStatusAfterSaveDialogProps) {
        super({ className: "medium" });

        this.title(ProlifeSdk.TextResources.Blog.ToDoListTitle);
    }

    show(): Promise<ConfirmedTasksStatus> {
        return this.dialogsService.ShowModal(this);
    }
    
    close()
    {
        this.modal.close({
            Confirm : false,
            TaskStatus: []
        });
    }

    action() {
        this.ref.action().then((actionResult) => {
            if (!actionResult)
                return;

            this.modal.close(actionResult);
        });
    }
    
    renderBody() {
        return <WorkflowStatusAfterSave {...this.props} forwardRef={(dialog) => this.ref = dialog} />;
    }
}

export function WorkflowStatusAfterSave(props: WorkflowStatusAfterSaveProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./WorkflowStatusAfterSaveDialog")._WorkflowStatusAfterSave as typeof _WorkflowStatusAfterSave;
    return <C {...props} />;
}

export class _WorkflowStatusAfterSave
{
    @LazyImport(nameof<ITodoListService>())
    private todoListService : ITodoListService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    public JobOrders : ko.ObservableArray<JobOrderWithActivities> = ko.observableArray([]);

    constructor(private props: WorkflowStatusAfterSaveProps) {
        if (this.props.forwardRef)
            this.props.forwardRef(this);
    }

    componentDidMount() {
        this.load();
    }
    
    private async load(): Promise<void> {
        let tasks : ITaskForTaskBoard[] = await this.todoListService.GetTasksForUserById(this.userInfo.getIdUser(), this.props.tasksIds);
        
        let jobOrdersMap = {};
        let jobOrders = [];
        
        for (const task of tasks) {
            if (!jobOrdersMap[task.JobOrderId]) {
                jobOrdersMap[task.JobOrderId] = new JobOrderWithActivities(task.JobOrderId, task.JobOrderName, []);
                jobOrders.push(jobOrdersMap[task.JobOrderId]);
            }

            jobOrdersMap[task.JobOrderId].addTask(task);
        }

        this.JobOrders(jobOrders);
    }

    public async action(): Promise<ConfirmedTasksStatus>
    {
        let tasksStatusInfo : TaskStatusInfo[] = [];
        let deferreds : Promise<boolean>[] = [];

        for (const jobOrder of this.JobOrders()) {
            const jobOrderTasksStatusInfo = jobOrder.GetStatusInfo();
            tasksStatusInfo = tasksStatusInfo.concat(jobOrderTasksStatusInfo);
            
            for (const taskStatus of jobOrderTasksStatusInfo) {
                if (!taskStatus.WillChangeStatus)
                    continue;

                let promise = this.todoListService.CanChangeState(taskStatus.Id).then(result => { taskStatus.CanChangeStatus = result; return result; });
                deferreds.push(promise);
            }
        }

        if (deferreds.length > 0)
            await Promise.all(deferreds);

        let tasksThatCannotChangeState = tasksStatusInfo.filter(t => !t.CanChangeStatus);
        let confirm: boolean = true;
        if (tasksThatCannotChangeState.length > 0)
            confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.Blog.OnOrMoreTasksAreNotReady, ProlifeSdk.TextResources.Blog.DoNotContinue, ProlifeSdk.TextResources.Blog.Continue);
            
        if (!confirm)
            return null;

        return {
            Confirm: true,
            TasksStatus: tasksStatusInfo
        };
    }

    private createTaskStatusModel(task: TaskStatus): IDataSourceModel<number, TaskStatus> {
        return {
            id: task.Id,
            title: task.Title(),
            isGroup: false,
            isLeaf: true,
            model: task
        };
    }

    render() {
        const taskModel: IDataSourceModel<number, TaskStatus> = null;

        return ComponentUtils.bindTo((
            <div className={classes.workflowStatusDialog}>
                <div class="note note-info">
                    <p>
                        {TextResources.Blog.WorkflowStatusAfterSaveDialogHelpText}
                    </p>
                </div>
                
                <TsxForEach data={this.JobOrders}>
                    {(jobOrder: JobOrderWithActivities) => (
                        <>
                            {this.JobOrders().length > 1 && <h4>{jobOrder.jobOrderName}</h4>}
                            <Table dataSource={{ array: jobOrder.Tasks, factory: this.createTaskStatusModel.bind(this) }} noHeader rowAs="taskModel">
                                <Column>
                                    <ColumnBody>
                                        {(task: ITableItem<TaskStatus>) => (
                                            <>
                                                <span>{task.Data.model.Title()}</span><br/>
                                                {task.Data.model.ActivitiesProgressAmountMode() != 0 && <span style={{ fontSize: "8pt", display: "inline-block", marginTop: "3px" }}>{TextResources.Blog.ActivityWithAmountProgressMode}</span>}
                                            </>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column style={{ width: "140px", maxWidth: "140px", minWidth: "140px" }}>
                                    <ColumnBody>
                                        {(task: ITableItem<TaskStatus>) => <span data-bind={{ text: taskModel.model.StatusDescription }}></span>}
                                    </ColumnBody>
                                </Column>
                                <Column style={{ width: "150px", maxWidth: "150px", minWidth: "150px" }}>
                                    <ColumnBody>
                                        {(task: ITableItem<TaskStatus>) => (
                                            <div className="input-group">
                                                <PercentageInput value={task.Data.model.ProgressAvg} simple selectOnFocus />
                                                <span className="input-group-btn">
                                                    <button className="btn btn-primary" data-bind={{ click: taskModel.model.setOneHundredPerventProgress.bind(taskModel.model) }}>
                                                        100%
                                                    </button>
                                                </span>
                                            </div>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column style={{ width: "120px", maxWidth: "120px", minWidth: "120px", paddingLeft: "10px" }}>
                                    <ColumnBody>
                                        {(task: ITableItem<TaskStatus>) => <div style={{ width: "100px" }} data-bind={{ slider : { min : 0, max : 100, step : 5, value : taskModel.model.ProgressAvg(), disabled: taskModel.model.ActivitiesProgressAmountMode() != 0 }, value : taskModel.model.ProgressAvg, css : { 'bg-grey' : taskModel.model.ProgressAvg() == 0, 'bg-yellow' : taskModel.model.ProgressAvg() > 0 && taskModel.model.ProgressAvg() < 100, 'bg-green' : taskModel.model.ProgressAvg() == 100 }}}></div>}
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </>
                    )}
                </TsxForEach>
            </div>
        ), this, "dialog");
    }
}

export class JobOrderWithActivities
{

    public Tasks : ko.ObservableArray<TaskStatus> = ko.observableArray([]);

    constructor(private jobOrderId: number, public jobOrderName: string, tasks: ITaskForTaskBoard[])
    {
        this.Tasks(tasks.map((t) => { return new TaskStatus(t); }));
    }

    addTask(task: ITaskForTaskBoard): void {
        this.Tasks.push(new TaskStatus(task));
    }

    public GetStatusInfo()
    {
        var tasksStatusInfo : { Id : number; Status : number; ProgressAvg : number; WillChangeStatus: boolean; CanChangeStatus: boolean; }[] = [];
        this.Tasks().forEach((t : TaskStatus) => {
            tasksStatusInfo.push(t.GetStatusInfo());
        });
        return tasksStatusInfo;
    }
}

export class TaskStatus
{
    public get Id(): number {
        return this.taskData.Id;
    }
    public Title: ko.Observable<string> = ko.observable();
    public ProgressAvg : ko.Observable<number> = ko.observable(0);
    public ActivitiesProgressAmountMode: ko.Observable<number> = ko.observable();
    public StatusDescription : ko.Computed<string>;

    constructor(private taskData : ITaskForTaskBoard)
    {
        this.Title(this.taskData.Title);
        this.ProgressAvg(this.taskData.Progress || 0);
        this.ActivitiesProgressAmountMode(this.taskData.ActivityProgressMode || 0);

        this.StatusDescription = ko.computed(() => {
            var description : string = ProlifeSdk.TextResources.Blog.ToDo;
            description = this.ProgressAvg() > 0 ? numeral(this.ProgressAvg()).format("0,0") + "%" : description;
            description = this.ProgressAvg() == 100 ? ProlifeSdk.TextResources.Blog.Completed : description;
            return description;
        });
    }

    public setOneHundredPerventProgress(): void {
        this.ProgressAvg(100);
    }

    public GetStatusInfo(): TaskStatusInfo
    {
        var status : number = this.ProgressAvg() == 100 ? 1 : 0;

        return {
            Id : this.taskData.Id,
            Status : status,
            ProgressAvg : this.ProgressAvg(),
            WillChangeStatus : this.WillChangeState(),
            CanChangeStatus : true
        }
    }

    public WillChangeState() : boolean {
        if(this.taskData.Progress == 0 && this.ProgressAvg() > 0)
            return true;
        if(this.taskData.Progress < 100 && this.ProgressAvg() == 100)
            return true;
        return false;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkflowStatusAfterSave);
}