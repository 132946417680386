import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 22/04/13
 * Time: 10.01
 * To change this template use File | Settings | File Templates.
 */

export class AutoHeight {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        setTimeout(() => {
            (<any>$(element)).autosize();
        }, 0);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        /*setTimeout(() => {
            $(element).trigger('autosize');
        }, 0);*/
    }
}

export class AutoWidth {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var containerSelector = valueAccessor();

        var $container = $(containerSelector);
        $container.resize(() => {
            var width = $container.outerWidth();
            $(element).css("width", width + "px");
        });
        $container.scroll(() => {
            var left = $container[0].scrollLeft;
            $(element).css("margin-left", left + "px");
        });

        $container.trigger('resize');
    }
}

ko.bindingHandlers["autoHeight"] = new AutoHeight();
ko.bindingHandlers["autoWidth"] = new AutoWidth();