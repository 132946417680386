import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { _ReportDesigner } from "./ReportDesigner";
import { ReportComponent, ReportText } from "./Components";
import { CanHaveChildren, ReportComponentWithChildren } from "./Components/ReportComponentWithChildren";
import { ReportPanel } from "./Components/ReportPanel";
import { ReportFlowPanel } from "./Components/ReportFlowPanel";
import { FileUploadButton } from "../FileUploadButton";

const styleSheet = jss.createStyleSheet({
    reportDesignerToolbar: {
        width: '100%',
        padding: '0px 10px'
    }
});
const { classes } = styleSheet.attach();

type ReportDesignerToolbarProps = {
    designer: _ReportDesigner;
    canImport?: boolean;
    canSaveToFile?: boolean;
}

export function ReportDesignerToolbar(props: ReportDesignerToolbarProps) {
    const C = require("./ReportDesignerToolbar")._ReportDesignerToolbar as typeof _ReportDesignerToolbar;
    return <C {...props} />;
}

export class _ReportDesignerToolbar {
    static defaultProps: Partial<ReportDesignerToolbarProps> = {
        canImport: true,
        canSaveToFile: true
    }
    containerSelected: ko.Computed<boolean>;
    container: ko.Computed<ReportComponent & ReportComponentWithChildren>;

    constructor(private props : ReportDesignerToolbarProps) {
        this.containerSelected = ko.computed(() => {
            return CanHaveChildren(props.designer.selectedComponent());
        });
        this.container = ko.computed(() => {
            const component = props.designer.selectedComponent();
            if(!CanHaveChildren(component)) return undefined;
            return component;
        })
    }

    private addText() {
        const container = this.container();
        if(!container) return;

        const allNames =  this.props.designer.getChildrenNames();
        const newName = ReportComponent.findNextName(allNames, "Testo");

        const newText = new ReportText({
            text: newName,
            x: 0,
            y: 0,
            width: 5,
            height: 1,
            id: -10,
            name: newName,
            type: nameof<ReportText>()
        });
        
        container.children.push(newText);
        this.props.designer.selectedComponent(newText);
    }

    private addPanel() {
        const container = this.container();
        if(!container) return;

        const allNames =  this.props.designer.getChildrenNames();
        const newName = ReportComponent.findNextName(allNames, "Pannello");

        const newPanel = new ReportPanel({
            x: 0,
            y: 0,
            width: 5,
            height: 1,
            id: -10,
            name: newName,
            type: nameof<ReportPanel>()
        });
        
        container.children.push(newPanel);
        this.props.designer.selectedComponent(newPanel);
    }

    private addFlowPanel() {
        const container = this.container();
        if(!container.children) return;

        const allNames =  this.props.designer.getChildrenNames();
        const newName = ReportComponent.findNextName(allNames, "Pannello Flow");

        const newPanel = new ReportFlowPanel({
            x: 0,
            y: 0,
            width: 5,
            height: 5,
            id: -10,
            name: newName,
            type: nameof<ReportFlowPanel>()
        });
        
        container.children.push(newPanel);
        this.props.designer.selectedComponent(newPanel);
    }

    private async loadReport(file: File) {
        const json = await file.text();
        const data = JSON.parseWithDate(json);

        this.props.designer.load(data);
    }

    private saveReport() {
        const data = this.props.designer.getData();
        const blob = new Blob([ JSON.stringify(data) ], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "report.json";

        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        }

        a.addEventListener('click', clickHandler);
        a.click();
    }

    private exportToPdf() {
        return this.props.designer.exportToPdf();
    }
    
    render() {
        const rdt = this;
        const className = ComponentUtils.classNames(classes.reportDesignerToolbar, "flex-container", "flex-child-center");

        return ComponentUtils.bindTo(
            <div className={className}>
                <button type="button" className="btn btn-icon-only btn-primary" data-bind={{ enable: rdt.containerSelected, click: rdt.addText.bind(rdt) }}>
                    <i className="fa fa-font"></i>
                </button>
                <button type="button" className="btn btn-icon-only btn-primary" data-bind={{ enable: rdt.containerSelected, click: rdt.addPanel.bind(rdt) }}>
                    <i className="fa fa-square-o"></i>
                </button>
                <button type="button" className="btn btn-icon-only btn-primary" data-bind={{ enable: rdt.containerSelected, click: rdt.addFlowPanel.bind(rdt) }}>
                    <i className="fa fa-square-o"></i>
                </button>
                {this.props.canImport && <FileUploadButton style={{ marginLeft: 'auto' }} accept=".json" className="btn btn-icon-only btn-primary" icon="fa fa-folder-open-o" onChange={(files) => this.loadReport(files[0])} />}
                {this.props.canSaveToFile && <button type="button" className="btn btn-icon-only btn-primary" data-bind={{ asyncClick: rdt.saveReport.bind(rdt) }}>
                    <i className="fa fa-floppy-o"></i>
                </button>}
                {/* <button type="button" className="btn btn-icon-only btn-primary" data-bind={{ asyncClick: rdt.exportToPdf.bind(rdt) }}>
                    <i className="fa fa-file-pdf-o"></i>
                </button> */}
            </div>
        , this, "rdt");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDesignerToolbar);
}