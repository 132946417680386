import jsPDF, { GState } from "jspdf";
import { ReportText } from "../Components";
import { ParseColor } from "../Utilities/ColorUtils";
import { PointsToCentimeters } from "../Utilities/UnitOfMeasureUtils";
import { ExportBackground } from "./ReportBackgroundExporter";
import { ExporterFor, ReportExporter } from "./ReportExporterProvider";

@ExporterFor(nameof<ReportText>())
export class ReportTextExporter implements ReportExporter {
    async export(doc: jsPDF, component: ReportText): Promise<void> {
        const linerHeightFactor = 1.5;
        
        await ExportBackground(doc, component, component.x(), component.y(), component.width(), component.height());

        doc.saveGraphicsState();
        const color = ParseColor(component.fontColor());
        doc.setGState(new GState({ opacity: color.a }))

        const lines : string[] = doc.setTextColor(color.r, color.g, color.b)
            .setFont(component.fontFamily(), component.fontStyle(), component.fontWeight())
            .setFontSize(component.fontSize())
            .setLineHeightFactor(linerHeightFactor)
            .splitTextToSize(component.text(), component.width());

        const lineHeight = PointsToCentimeters(component.fontSize()) * linerHeightFactor;
        
        let x = component.x();
        if(component.textAlign() === "right")
            x += component.width();
        else if(component.textAlign() === "center")
            x += component.width() / 2;

        let y = component.y();
        if(component.verticalAlign() === "bottom") {
            const textHeight = lineHeight * lines.length;
            y = component.y() + Math.max(0, component.height() - textHeight);
        } else if(component.verticalAlign() === "center") {
            const textHeight = lineHeight * lines.length;
            y = component.y() + (Math.max(0, component.height() - textHeight) / 2);
        }

        doc.text(lines, x, y + (lineHeight * 0.7), { align: component.textAlign(), maxWidth: component.width(), lineHeightFactor: linerHeightFactor });
        doc.restoreGraphicsState();
    }

}