import * as ko from "knockout";
import * as moment from "moment";

export class TimePicker
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = allBindingsAccessor();
        var input = $(element).children(".inputTime")[0];
        var btnClear =  $(element).children(".clear")[0];
        var customDatePickerClass = (options) ? options.customDatePickerClass || "" : "";

        //Traduco il valore iniziale GMT in UTC gestito dalla libreria
        var initialTime = moment(valueAccessor()()).toDate();
        initialTime.setUTCHours(initialTime.getHours());

        //Handle sul cambio di valore del picker
        (<any>$(input)).datetimepicker({ startDate : initialTime,pickTime: true, pickDate : false, pickSeconds : false, language: 'it', customClass : customDatePickerClass })
            .on('changeDate', function(ev)
            {
                //Riadatto il valore fornito dalla libreria 
                ev.date.setHours(ev.date.getUTCHours());
                valueAccessor()(ev.date);
            });

        //Handle sulla rimozione del nodo dal DOM per distruggere il picker
        var picker = (<any>$(input)).data('datetimepicker');
        picker.setDate(initialTime);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            picker.destroy();
        });

        //Handle click bottone clear
        $(btnClear).click(() =>{
            valueAccessor()(null);
            $(input).val("");
        })
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = allBindingsAccessor();
        if(options.datePickerWriteOnly)
            return;

        var input = $(element).children(".inputTime")[0];
        var value = ko.utils.unwrapObservable(valueAccessor());
        if (!value)
        {
            $(input).val("");
            return;
        }

        //$(input).val(moment(value).format("HH:mm"));
    }
}

export class TimePickerEx
{
    private static setValueOnChange: boolean = true; 

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = allBindingsAccessor();
        var initialValue = ko.utils.unwrapObservable(valueAccessor());

        //Handle sul cambio di valore del picker
        var picker = (<any>$(element)).timepicker({ autoclose: true, showSeconds : false, showMeridian: false, language: 'it' })
            .on('changeTime.timepicker', function(ev)
            {
                if (TimePickerEx.setValueOnChange) {
                    //Riadatto il valore fornito dalla libreria
                    var newDate = !initialValue ? moment(new Date()) : moment(initialValue);
                    newDate.hours(ev.time.hours);
                    newDate.minutes(ev.time.minutes);
                    newDate.seconds(ev.time.seconds);
                    newDate.milliseconds(0);

                    valueAccessor()(newDate.toDate());
                }
            });

        if(!options.doNotInterceptInputGroup) {
            $(element).parent('.input-group').on('click', '.input-group-btn', function(e){
                e.preventDefault();
            picker.timepicker('showWidget');
            });
        }

        if ( Object.prototype.toString.call(initialValue) === "[object Date]" ) {
            // it is a date
            if (isNaN(initialValue.getTime())) {  // d.valueOf() could also work
                // date is not valid
                $(element).val("");
            }
            else
            {
                TimePickerEx.setValueOnChange = false;
                picker.timepicker('setTime', moment(initialValue).format("LT"));
                TimePickerEx.setValueOnChange = true;

                $(element).val(moment(initialValue).format("LT"));
            }
        }

        if (ko.isObservable(valueAccessor())) {
            let obs = valueAccessor();
            let valueBinding: ko.Computed<string> = ko.computed({
                read: () => {
                    return !obs() ? null : moment(obs()).format("LT");
                },
                write: (value: string) => {
                    if (!value)
                        obs(null);
                    else
                        obs(moment(value, "LT").toDate());
                }
            });

            let bindings = {
                value: valueBinding
            }

            ko.applyBindingsToNode(element, bindings, undefined);
        }
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var options = allBindingsAccessor();
        if(options.datePickerWriteOnly)
            return;

        var value = ko.utils.unwrapObservable(valueAccessor());
        if (!value)
        {
            $(element).val("");
            return;
        } else {
            TimePickerEx.setValueOnChange = false;
            (<any>$(element)).timepicker('setTime', moment(value).format("LT"));
            TimePickerEx.setValueOnChange = true;
            $(element).val(moment(value).format("LT"));
        }

        //$(input).val(moment(value).format("HH:mm"));
    }
}

export class ClockFace
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var options = allBindingsAccessor();
        var initialValue = ko.utils.unwrapObservable(valueAccessor());

        //Handle sul cambio di valore del picker
        var picker = (<any>$(element)).clockface({ format: 'HH:mm' })
            .on('pick.clockface', function(ev, data)
            {
                picker.data("_clockFace_changingDate", true);

                var value = ko.utils.unwrapObservable(valueAccessor());

                //Riadatto il valore fornito dalla libreria
                var newDate = moment(value)
                    .hours(data.hour)
                    .minutes(data.minute)
                    .seconds(0)
                    .toDate();

                valueAccessor()(newDate);

                picker.data("_clockFace_changingDate", false);
            });

        $(element).on('change', function() {
            var isChangingDate = $(element).data("_clockFace_changingDate");
            if(isChangingDate)
                return;

            var elementValue = $(element).val();
            var newTime = moment(elementValue, "HH:mm");
            if(newTime.isValid()) {
                (<any>$(element)).clockface('setTime', newTime.format("LT"));
                $(element).val(newTime.format("LT"));

                var value = ko.utils.unwrapObservable(valueAccessor());

                //Riadatto il valore fornito dalla libreria
                var newDate = moment(value)
                    .hours(newTime.hours())
                    .minutes(newTime.minutes())
                    .seconds(0)
                    .toDate();

                valueAccessor()(newDate);
            }
        });

        picker.clockface('setTime', moment(initialValue).format("LT"));
        $(element).val(moment(initialValue).format("LT"));

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            picker.clockface('destroy');
        });
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {

        var isChangingDate = $(element).data("_clockFace_changingDate");
        if(isChangingDate)
            return;

        var options = allBindingsAccessor();
        if(options.clockFaceWriteOnly)
            return;

        var value = ko.utils.unwrapObservable(valueAccessor());
        if (!value)
        {
            $(element).val("");
            return;
        }

        (<any>$(element)).clockface('setTime', moment(value).format("LT"));
        $(element).val(moment(value).format("LT"));
    }
}

export class TimeText
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var initialValue = ko.utils.unwrapObservable(valueAccessor());

        if ( Object.prototype.toString.call(initialValue) === "[object Date]" ) {
            // it is a date
            if (isNaN(initialValue.getTime())) {  // d.valueOf() could also work
                // date is not valid
                $(element).text("");
            }
            else
            {
                $(element).text(moment(initialValue).format("LT"));
            }
        }
        else
        {
            $(element).text("");
        }
    }
}

export class TimeTextFromNumber {
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        let value = typeof ko.utils.unwrapObservable(valueAccessor()) == 'number' ? ko.utils.unwrapObservable(valueAccessor()) : parseFloat(ko.utils.unwrapObservable(valueAccessor()));
        let showSeconds = allBindingsAccessor()["timeTextShowSeconds"] || false;
        let alwaysPositive = allBindingsAccessor()["timeTextAlwaysPositive"] || false;
        if(alwaysPositive){
            value = Math.abs(value);
        }
        let format = showSeconds ? "hh:mm:ss" : "hh:mm";

        let time = !value || isNaN(value) ? moment.duration(0, "hours").format(format, { trim: false }) : moment.duration(value, "hours").format(format, { trim: false });
        $(element).text(time);
    }
}

ko.bindingHandlers["timePicker"] = new TimePicker();
ko.bindingHandlers["timePickerEx"] = new TimePickerEx();
ko.bindingHandlers["clockFace"] = new ClockFace();
ko.bindingHandlers["timeText"] = new TimeText();
ko.bindingHandlers["timeTextFromNumber"] = new TimeTextFromNumber();
