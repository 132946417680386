import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { DocumentsProvider, IProtocolDocumentType } from "../../../ProlifeSdk/prolifesdk/documents/DocumentsProvider";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { PurchaseRequestDefaultValuesSettingUi } from "./PurchaseRequestDefaultValuesSettingUi";

export class PurchaseRequestDocumentProvider extends DocumentsProvider {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(private vatRegister : IVatRegister)
    {
        super(ProlifeSdk.PurchaseRequestEntityTypeCode);

        this.ProviderId = "Rda";
        this.CanEdit = vatRegister.Stato == ProlifeSdk.EnabledProtocolState;

        this.Id = vatRegister.IdRegistroIVA;
        this.Name(vatRegister.NomeRegistroIVA);
        this.HasVatRegister = true;
        this.VatRegisterLabel(vatRegister.NuovoLabel);

        this.CanCreateNew(vatRegister.Stato == ProlifeSdk.EnabledProtocolState && this.authorizationService.isAuthorized("Provisioning_PurchaseRequest"));

        this.ProviderId = this.ProviderId + this.Id;
        this.HasDefaultValuesSettingsUi = true;
        this.DefaultSearchProvider = vatRegister.DefaultSearchProvider;
    }

    public GetDocumentGroupLabel(): string {
        return this.vatRegister.GroupLabel;
    }

    public GetProviderPositionForMenu(): number {
        return this.vatRegister.PositionInList;
    }

    GetDocumentTypes(): IProtocolDocumentType[] {
        return [{
            DocumentType: ProlifeSdk.PurchaseRequestEntityTypeCode,
            NewLabel: this.vatRegister.NuovoLabel
        }];
    }

    GetDefaultValuesSettingsUi() {
        return new PurchaseRequestDefaultValuesSettingUi(this.vatRegister, this.ProviderId);
    }
}