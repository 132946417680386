import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import {
    DocumentResourcesSettings,
    IDocumentResourcesSettingsManager,
} from "../../../../ProlifeSdk/interfaces/invoice/settings/IDocumentResourcesSettingsManager";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Select } from "../../../../Components/Select";
import { CheckBox } from "../../../../Components/Checkbox";
import { NumberInput } from "../../../../Components/NumberInput";
import { BaseDataSource } from "../../../../DataSources/BaseDataSource";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";
import { IMetadataSettingsManager } from "../MetadataSettingsManager";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";

const styleSheet = jss.createStyleSheet({
    editor: {
        height: "100%",

        "& .header": {
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #ddd",
        },

        "& .body": {
            paddingTop: "10px",

            "& .label-col": {
                width: "300px",
            },

            "& .field-col": {
                width: "250px",
            },

            "& .align-center": {
                alignItems: "center",
            },

            "& .padding-top-5": {
                paddingTop: "5px",
            },

            "& .margin-top-20": {
                marginTop: "20px",
            },

            "& .table": {
                width: "60%",

                "& thead": {
                    "& tr": {
                        "& th": {
                            "&.fixed-width": {
                                width: "100px",
                            },
                        },
                    },
                },

                "& tbody": {
                    "& tr": {
                        "& td": {
                            padding: "0px",
                        },
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type DataSource = {
    id: string;
    name: string;
    description: string;
};

type DataSourceField = {
    id: string;
    name: string;
    description: string;
    type: string;
    nullable: boolean;
};

class DataSourcesDataSource extends BaseDataSource<IDataSourceModel<string, DataSource>> {
    private dataSources: IDataSourceModel<string, DataSource>[] = [
        {
            id: "documents",
            title: "Documento",
            isGroup: false,
            isLeaf: true,
            model: {
                id: "documents",
                name: "Documento",
                description: "Dati di testata del documento",
            },
        },
        {
            id: "documents-metadata",
            title: "Dati aggiuntivi",
            isGroup: false,
            isLeaf: true,
            model: {
                id: "documents-metadata",
                name: "Dati aggiuntivi",
                description: "Dati aggiuntivi del documento",
            },
        },
    ];

    getTitle(currentModel: IDataSourceModel<string, DataSource>): string {
        return "";
    }

    async getData(
        currentModel: IDataSourceModel<string, DataSource>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<string, DataSource>[]> {
        const filteredDataSources = this.dataSources.filter(
            (ds) =>
                TextFiltersUtilities.contains(ds.title, textFilter) ||
                TextFiltersUtilities.contains(ds.model.description, textFilter)
        );
        return filteredDataSources.slice(skip, skip + count);
    }

    async getById(
        currentModel: IDataSourceModel<string, DataSource>,
        ids: (string | number)[]
    ): Promise<IDataSourceModel<string, DataSource>[]> {
        return this.dataSources.filter((ds) => ids.indexOf(ds.id) >= 0);
    }
}

class DataSourceFieldsDataSource extends BaseDataSource<IDataSourceModel<string, DataSourceField>> {
    private dataSourceFields: Map<string, DataSourceField[]> = new Map();

    private selectedDataSource: string = null;
    private fieldTypeFilters: string[] = [];

    @LazyImportSettingManager(nameof<IMetadataSettingsManager>())
    private metadatasSettingsManager: IMetadataSettingsManager;

    constructor() {
        super();

        this.dataSourceFields.set("documents", this.generateDocumentsFields());
        this.dataSourceFields.set("documents-metadata", this.generateDocumentsMetadataFields());
    }

    public setDataSource(dataSource: string) {
        this.selectedDataSource = dataSource;
    }

    public setFieldTypeFilters(fieldTypeFilters: string[]) {
        this.fieldTypeFilters = fieldTypeFilters ?? [];
    }

    getTitle(currentModel: IDataSourceModel<string, DataSourceField>): string {
        return "";
    }

    async getData(
        currentModel: IDataSourceModel<string, DataSourceField>,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel<string, DataSourceField>[]> {
        if (!this.selectedDataSource) {
            return [];
        }

        const fields = this.dataSourceFields.get(this.selectedDataSource);
        if (!fields) return [];

        const filteredFields = fields.filter(
            (f) =>
                (TextFiltersUtilities.contains(f.name, textFilter) ||
                    TextFiltersUtilities.contains(f.description, textFilter)) &&
                (this.fieldTypeFilters.length === 0 || this.fieldTypeFilters.indexOf(f.type) >= 0)
        );

        return filteredFields.slice(skip, skip + count).map((f) => this.createModel(f));
    }

    async getById(
        currentModel: IDataSourceModel<string, DataSourceField>,
        ids: (string | number)[]
    ): Promise<IDataSourceModel<string, DataSourceField>[]> {
        if (!this.selectedDataSource) {
            return [];
        }

        const fields = this.dataSourceFields.get(this.selectedDataSource);
        if (!fields) return [];

        const filteredFields = fields.filter(
            (f) =>
                ids.indexOf(f.id) >= 0 &&
                (this.fieldTypeFilters.length === 0 || this.fieldTypeFilters.indexOf(f.type) >= 0)
        );

        return filteredFields.map((f) => this.createModel(f));
    }

    private createModel(field: DataSourceField): IDataSourceModel<string, DataSourceField> {
        return {
            id: field.id,
            title: field.name,
            isGroup: false,
            isLeaf: true,
            model: field,
        };
    }

    private generateDocumentsMetadataFields(): DataSourceField[] {
        const metadata = this.metadatasSettingsManager.getMetadatas();
        return metadata.map((m) => ({
            id: m.Id.toString(),
            name: m.Label,
            description: null,
            type: m.ValueType,
            nullable: false,
        }));
    }

    private generateDocumentsFields(): DataSourceField[] {
        return [
            { id: "ReferenceNumber", name: "Num. Rif.", description: null, type: "number", nullable: true },
            { id: "ReferenceDate", name: "Data Rif.", description: null, type: "date", nullable: true },
            { id: "ExternalReference", name: "Rif. Est.", description: null, type: "date", nullable: true },
            { id: "CIG", name: "CIG", description: null, type: "string", nullable: true },
            { id: "CUP", name: "CUP", description: null, type: "string", nullable: true },
        ];
    }
}

type DocumentResourcesSettingsEditorProps = {
    settingsManager: IDocumentResourcesSettingsManager;
};

export function DocumentResourcesSettingsEditor(props: DocumentResourcesSettingsEditorProps) {
    const C = require("./DocumentResourcesSettingsEditor")
        ._DocumentResourcesSettingsEditor as typeof _DocumentResourcesSettingsEditor;
    return <C {...props} />;
}

export class _DocumentResourcesSettingsEditor {
    static defaultProps: Partial<DocumentResourcesSettingsEditorProps> = {};

    private AssignmentStartDataSource: ko.Observable<string> = ko.observable("");
    private AssignmentStartSourceField: ko.Observable<string> = ko.observable("");
    private AssignmentEndDataSource: ko.Observable<string> = ko.observable("");
    private AssignmentEndSourceField: ko.Observable<string> = ko.observable("");
    private TotalHoursToAssignDataSource: ko.Observable<string> = ko.observable("");
    private TotalHoursToAssignSourceField: ko.Observable<string> = ko.observable("");
    private TotalHoursConversionFactor: ko.Observable<number> = ko.observable(1);
    private UseResourceServiceOrderForCalculations: ko.Observable<boolean> = ko.observable(true);
    private StandardServiceOrderMondayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderTuesdayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderWednesdayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderThursdayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderFridayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderSaturdayHours: ko.Observable<number> = ko.observable(0);
    private StandardServiceOrderSundayHours: ko.Observable<number> = ko.observable(0);

    private dataSourcesDataSource: DataSourcesDataSource = new DataSourcesDataSource();

    private resourceAssignmentStartDataSourceFieldsDataSource: DataSourceFieldsDataSource =
        new DataSourceFieldsDataSource();
    private resourceAssignmentEndDataSourceFieldsDataSource: DataSourceFieldsDataSource =
        new DataSourceFieldsDataSource();
    private totalHoursToAssignDataSourceFieldsDataSource: DataSourceFieldsDataSource = new DataSourceFieldsDataSource();

    private _settingsId: number;

    constructor(private props: DocumentResourcesSettingsEditorProps) {
        this.load();
    }

    private load(): void {
        const settings = this.props.settingsManager.getSettings();

        this._settingsId = settings.id;

        this.resourceAssignmentStartDataSourceFieldsDataSource.setDataSource(settings.assignmentStartDataSource);
        this.resourceAssignmentEndDataSourceFieldsDataSource.setDataSource(settings.assignmentEndDataSource);
        this.totalHoursToAssignDataSourceFieldsDataSource.setDataSource(settings.totalHoursToAssignDataSource);

        this.AssignmentStartDataSource(settings.assignmentStartDataSource);
        this.AssignmentStartSourceField(settings.assignmentStartSourceField);
        this.AssignmentEndDataSource(settings.assignmentEndDataSource);
        this.AssignmentEndSourceField(settings.assignmentEndSourceField);
        this.TotalHoursToAssignDataSource(settings.totalHoursToAssignDataSource);
        this.TotalHoursToAssignSourceField(settings.totalHoursToAssignSourceField);
        this.TotalHoursConversionFactor(settings.totalHoursConversionFactor);
        this.UseResourceServiceOrderForCalculations(settings.useResourceServiceOrderForCalculations);
        this.StandardServiceOrderMondayHours(settings.standardServiceOrderMondayHours);
        this.StandardServiceOrderTuesdayHours(settings.standardServiceOrderTuesdayHours);
        this.StandardServiceOrderWednesdayHours(settings.standardServiceOrderWednesdayHours);
        this.StandardServiceOrderThursdayHours(settings.standardServiceOrderThursdayHours);
        this.StandardServiceOrderFridayHours(settings.standardServiceOrderFridayHours);
        this.StandardServiceOrderSaturdayHours(settings.standardServiceOrderSaturdayHours);
        this.StandardServiceOrderSundayHours(settings.standardServiceOrderSundayHours);
    }

    private getData(): DocumentResourcesSettings {
        return {
            id: this._settingsId,
            assignmentStartDataSource: this.AssignmentStartDataSource(),
            assignmentStartSourceField: this.AssignmentStartSourceField(),
            assignmentEndDataSource: this.AssignmentEndDataSource(),
            assignmentEndSourceField: this.AssignmentEndSourceField(),
            totalHoursToAssignDataSource: this.TotalHoursToAssignDataSource(),
            totalHoursToAssignSourceField: this.TotalHoursToAssignSourceField(),
            totalHoursConversionFactor: this.TotalHoursConversionFactor(),
            useResourceServiceOrderForCalculations: this.UseResourceServiceOrderForCalculations(),
            standardServiceOrderMondayHours: this.StandardServiceOrderMondayHours(),
            standardServiceOrderTuesdayHours: this.StandardServiceOrderTuesdayHours(),
            standardServiceOrderWednesdayHours: this.StandardServiceOrderWednesdayHours(),
            standardServiceOrderThursdayHours: this.StandardServiceOrderThursdayHours(),
            standardServiceOrderFridayHours: this.StandardServiceOrderFridayHours(),
            standardServiceOrderSaturdayHours: this.StandardServiceOrderSaturdayHours(),
            standardServiceOrderSundayHours: this.StandardServiceOrderSundayHours(),
        };
    }

    private async save(): Promise<void> {
        const savedSettings = await this.props.settingsManager.saveSettings(this.getData());
        this._settingsId = savedSettings.id;
    }

    render() {
        const e = this;

        const renderSelectedDataSource = (item: IDataSourceModel<string, DataSource>) => {
            return (
                <>
                    <span class="list-item-icon fa fa-list-alt"></span>
                    <div class="list-item-content">
                        <div class="list-item-title">{item.title}</div>
                        <small class="list-item-subtitle text-muted">{item.model.description}</small>
                    </div>
                </>
            );
        };

        const renderDataSourceItem = (item: IDataSourceModel<string, DataSource>) => {
            return (
                <>
                    <span class="list-item-icon fa fa-list-alt"></span>
                    <div class="list-item-content">
                        <div class="list-item-title">{item.title}</div>
                        <small class="list-item-subtitle text-muted">{item.model.description}</small>
                    </div>
                </>
            );
        };

        return ComponentUtils.bindTo(
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} className={classes.editor}>
                <Layout.Grid.Cell row={1} column={1} className="header">
                    <h3>{TextResources.Invoices.ResourcesAssignmentSettingsTitle}</h3>
                    <button type="button" className="btn btn-primary" data-bind={{ asyncClick: e.save.bind(e) }}>
                        {TextResources.ProlifeSdk.Save}
                    </button>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={1} className="flex-vertical body">
                    <Layout.ScrollContainer systemScrollable>
                        <div className="flex-container align-center">
                            <div className="label-col">
                                <label className="control-label">
                                    {TextResources.Invoices.ResourceAssignemntStartFrom}
                                </label>
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.AssignmentStartDataSource}
                                    dataSource={this.dataSourcesDataSource}
                                    placeholder={TextResources.Invoices.DataSourcePlaceholder}
                                    allowClear
                                    onSelect={(item) =>
                                        this.resourceAssignmentStartDataSourceFieldsDataSource.setDataSource(item.id)
                                    }
                                    onDeselect={() => {
                                        this.resourceAssignmentStartDataSourceFieldsDataSource.setDataSource(null);
                                        this.AssignmentStartSourceField(null);
                                    }}
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.AssignmentStartSourceField}
                                    dataSource={this.resourceAssignmentStartDataSourceFieldsDataSource}
                                    placeholder={TextResources.Invoices.DataSourceFieldPlaceholder}
                                    allowClear
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                        </div>
                        <div className="flex-container align-center">
                            <div className="label-col">
                                <label className="control-label">
                                    {TextResources.Invoices.ResourceAssignemntEndFrom}
                                </label>
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.AssignmentEndDataSource}
                                    dataSource={this.dataSourcesDataSource}
                                    placeholder={TextResources.Invoices.DataSourcePlaceholder}
                                    allowClear
                                    onSelect={(item) =>
                                        this.resourceAssignmentEndDataSourceFieldsDataSource.setDataSource(item.id)
                                    }
                                    onDeselect={() =>
                                        this.resourceAssignmentEndDataSourceFieldsDataSource.setDataSource(null)
                                    }
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.AssignmentEndSourceField}
                                    dataSource={this.resourceAssignmentEndDataSourceFieldsDataSource}
                                    placeholder={TextResources.Invoices.DataSourceFieldPlaceholder}
                                    allowClear
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                        </div>
                        <div className="flex-container align-center">
                            <div className="label-col">
                                <label className="control-label">{TextResources.Invoices.TotalHoursToAssignFrom}</label>
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.TotalHoursToAssignDataSource}
                                    dataSource={this.dataSourcesDataSource}
                                    placeholder={TextResources.Invoices.DataSourcePlaceholder}
                                    allowClear
                                    onSelect={(item) =>
                                        this.totalHoursToAssignDataSourceFieldsDataSource.setDataSource(item.id)
                                    }
                                    onDeselect={() =>
                                        this.totalHoursToAssignDataSourceFieldsDataSource.setDataSource(null)
                                    }
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                            <div className="field-col">
                                <Select
                                    value={this.TotalHoursToAssignSourceField}
                                    dataSource={this.totalHoursToAssignDataSourceFieldsDataSource}
                                    placeholder={TextResources.Invoices.DataSourceFieldPlaceholder}
                                    allowClear
                                    renderItem={renderDataSourceItem}
                                    renderSelectedItem={renderSelectedDataSource}
                                />
                            </div>
                        </div>
                        <div className="flex-container align-center">
                            <div className="label-col">
                                <label className="control-label">
                                    {TextResources.Invoices.TotalHoursConversionFactor}
                                </label>
                            </div>
                            <div className="field-col">
                                <NumberInput
                                    value={this.TotalHoursConversionFactor}
                                    simple
                                    placeholder={TextResources.Invoices.TotalHoursConversionFactorPlaceholder}
                                />
                            </div>
                        </div>
                        <div className="flex-container padding-top-5 margin-top-20">
                            <div className="label-col">
                                <label className="control-label">
                                    {TextResources.Invoices.UseResourceServiceOrderForCalculations}
                                </label>
                            </div>
                            <div className="field-col">
                                <CheckBox checked={this.UseResourceServiceOrderForCalculations} simple switch />
                            </div>
                        </div>
                        <div className="flex-container flex-vertical">
                            <div>
                                <label className="control-label">
                                    {TextResources.Invoices.DefaultServiceOrderConfiguration}
                                </label>
                            </div>
                            <div>
                                <table className="table fixed-table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="text-right fixed-width">{TextResources.Invoices.Monday}</th>
                                            <th className="text-right fixed-width">{TextResources.Invoices.Tuesday}</th>
                                            <th className="text-right fixed-width">
                                                {TextResources.Invoices.Wednesday}
                                            </th>
                                            <th className="text-right fixed-width">
                                                {TextResources.Invoices.Thursday}
                                            </th>
                                            <th className="text-right fixed-width">{TextResources.Invoices.Friday}</th>
                                            <th className="text-right fixed-width">
                                                {TextResources.Invoices.Saturday}
                                            </th>
                                            <th className="text-right fixed-width">{TextResources.Invoices.Sunday}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderMondayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderTuesdayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderWednesdayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderThursdayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderFridayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderSaturdayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                            <td>
                                                <NumberInput
                                                    value={this.StandardServiceOrderSundayHours}
                                                    simple
                                                    placeholder={TextResources.Invoices.HoursPlaceholder}
                                                    readOnly={this.UseResourceServiceOrderForCalculations}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Layout.ScrollContainer>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "e"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentResourcesSettingsEditor);
}
