import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { ReportEditorProps } from "./ReportEditor";
import { ReportComponentWithBackground } from "../Components/ReportComponentWithBackground";
import { ReportComponent } from "../Components";
import { ColorInput } from "../../ColorInput";
import { FilePickerInput } from "../../FilePickerInput";
import { SegmentedInput } from "../../SegmentedInput";
import { EditorFor } from "./ReportEditorProvider";

type ReportBackgroundEditorProps = {
    component: ReportComponentWithBackground & ReportComponent;
} & ReportEditorProps;

export function ReportBackgroundEditor(props: ReportBackgroundEditorProps) {
    const C = require("./ReportBackgroundEditor")._ReportBackgroundEditor as typeof _ReportBackgroundEditor;
    return <C {...props} />;
}

@EditorFor("Background")
class _ReportBackgroundEditor {
    static defaultProps: Partial<ReportBackgroundEditorProps> = {
    }

    constructor(private props : ReportBackgroundEditorProps) {
        
    }
    
    render() {
        const { component } = this.props;

        return  <div class="editor-group">
                    <span class="editor-group-title">Stile</span>
                    <div className="editor-group-body">
                        <ColorInput size="xs" label="Colore di Sfondo" allowAlpha value={component.backgroundColor} />
                        <FilePickerInput label="Immagine di Sfondo" noFileName withPreview previewWidth={100} previewHeight={50} value={component.backgroundImage} />
                        <SegmentedInput label="Dimensione Sfondo" value={component.backgroundSize} options={[{text: "Auto", value: "auto"}, { text: "Riempi", value: "cover" }, { text: "Contieni", value: "contain" }]} />
                    </div>
                </div>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportBackgroundEditor);
}