import { IService } from "../Core/interfaces/IService";
import { Service, LazyImport } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { QuotationApplication } from "./ui/QuotationApplication";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IIdGeneratorService } from "../ProlifeSdk/IdGeneratorService";

export type QuotationBillOfMaterialsRowType = "WAR" | "EPC" | "EWK" | "BOM" | "";

export type IQuotationBillOfMaterialsRow = {
    Id: number;
    FKParent: number;
    Type: QuotationBillOfMaterialsRowType;
    EntityKeyId: number;
    Description: string;

    Amount: number;
    UnitCost: number;
    TotalCost: number;
    Margin: number;
    UnitPrice: number;
    Discount: string;
    NetUnitPrice: number;
    TotalPrice: number;
}

export type IQuotationBillOfMaterials = {
    Id: number;
    FKQuotation: number;
    Name: string;
    Description: string;

    Rows: IQuotationBillOfMaterialsRow[];
}

export type IQuotationForList = {
    Id: number;
    FKCustomer: number;
    CustomerName: string;
    FKJobOrder: number;
    JobOrderName: string;
    Name: string;
    Description: string;
    FKState: number;
}

export type IQuotationRowEntity = {
    Id: number;
    FKParent: number;
    Type: QuotationBillOfMaterialsRowType;
    EntityKeyId: number;
    Description: string;

    Amount: number;
    UnitCost: number;
    TotalCost: number;
    Margin: number;
    UnitPrice: number;
    Discount: string;
    NetUnitPrice: number;
    TotalPrice: number;
}

export type IQuotationRowCategoryValue = {
    FKQuotationRow: number;
    FKCategory: number;
    Type: number;
    Values: IQuotationCategoryValue[];

    ValueString?: string;
    ValueNumber?: number;
    ValueDate?: Date;
    ValueBoolean?: boolean;
}

export type IQuotationRow = {
    Id: number;
    FKParent: number;
    Description: string;
    Amount: number;
    UnitCost: number;
    TotalCost: number;
    Margin: number;
    UnitPrice: number;
    Discount: string;
    NetUnitPrice: number;
    TotalPrice: number;

    Entities: IQuotationRowEntity[];
    Categories: IQuotationRowCategoryValue[];
}

export type IQuotation = {
    Id: number;
    FKCustomer: number;
    CustomerName: string;
    FKJobOrder: number;
    JobOrderName: string;
    Name: string;
    Description: string;
    FKState: number;

    Rows: IQuotationRow[];
}

export type IQuotationCategoryValue = {
    Id: number;
    FKCategory: number;
    Value: string;
}

export type IQuotationCategory = {
    Id: number;
    FKQuotation: number;
    Name: string;
    Type: number;
    Values: IQuotationCategoryValue[];
}
export interface IQuotationService extends IService {
    getQuotations(customerId: number) : Promise<IQuotationForList[]>;

    getQuotation(quotationId: number) : Promise<IQuotation>;
    createOrUpdateQuotation(quotation: IQuotation) : Promise<IQuotation>;

    getCategories(quotationId: number) : Promise<IQuotationCategory[]>;
    createOrUpdateCategory(category: IQuotationCategory) : Promise<IQuotationCategory>;
    removeCategory(categoryId: number) : Promise<void>;

    getBillsOfMaterials(quotationId: number) : Promise<IQuotationBillOfMaterials[]>;
    getBillOfMaterials(billOfMaterialsId: number) : Promise<IQuotationBillOfMaterials>;
    createOrUpdateBillOfMaterials(billOfMaterials: IQuotationBillOfMaterials) : Promise<IQuotationBillOfMaterials>;
    removeBillOfMaterials(billOfMaterialsId: number) : Promise<void>;
}

@Service(nameof<IQuotationService>())
class QuotationService extends ProLifeService implements IQuotationService {
    private quotations: IQuotation[] = [];
    private categories: IQuotationCategory[] = [];
    private billsOfMaterials : IQuotationBillOfMaterials[] = [];

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    InitializeService() {
        super.InitializeService();
        new QuotationApplication();
    }

    private cloneQuotationRowEntity(entity: IQuotationRowEntity) : IQuotationRowEntity {
        const clonedEntity = Object.assign({}, entity);
        return clonedEntity;
    }

    private cloneQuotationRow(row: IQuotationRow) : IQuotationRow {
        const clonedRow = Object.assign({}, row);
        clonedRow.Entities = clonedRow.Entities.map(this.cloneQuotationRowEntity, this);
        return clonedRow;
    }

    private cloneQuotation(quotation: IQuotation) : IQuotation {
        const clone = Object.assign({}, quotation);
        clone.Id = -this.idGeneratorService.getNextId();
        clone.Rows = clone.Rows.map(this.cloneQuotationRow, this);
        return clone;
    }

    async getQuotations(customerId: number): Promise<IQuotationForList[]> {
        return this.quotations.filter(q => q.FKCustomer === customerId).map(q => ({
            Id: q.Id,
            FKCustomer: q.FKCustomer,
            CustomerName: q.CustomerName,
            FKJobOrder: q.FKJobOrder,
            JobOrderName: q.JobOrderName,
            Name: q.Name,
            Description: q.Description,
            FKState: q.FKState,
        }));
    }

    async getQuotation(quotationId: number): Promise<IQuotation> {
        return this.quotations.find(q => q.Id === quotationId);
    }

    async createOrUpdateQuotation(quotation: IQuotation): Promise<IQuotation> {
        const clone = this.cloneQuotation(quotation);
        const index = this.quotations.findIndex(q => q.Id === quotation.Id);
        if(index < 0) {
            clone.Id = -clone.Id;
            clone.Rows.forEach(r => r.FKParent = clone.Id);
            this.quotations.push(clone);
        } else {
            this.quotations[index] = clone;
        }

        return clone;
    }

    private cloneCategory(category: IQuotationCategory) : IQuotationCategory {
        const clone = Object.assign({}, category);
        clone.Values = clone.Values.map(v => Object.assign({}, v));
        return clone;
    }

    async getCategories(quotationId: number): Promise<IQuotationCategory[]> {
        return this.categories.filter(c => c.FKQuotation === quotationId).map(this.cloneCategory, this);
    }

    async createOrUpdateCategory(category: IQuotationCategory): Promise<IQuotationCategory> {
        const clone = this.cloneCategory(category);
        const index = this.categories.findIndex(c => c.Id === category.Id);
        if(index < 0) {
            clone.Id = -clone.Id;
            clone.Values.forEach(v => v.FKCategory = clone.Id);
            this.categories.push(clone);
        } else {
            this.categories[index] = clone;
        }

        return clone;
    }

    async removeCategory(categoryId: number) : Promise<void> {
        const index = this.categories.findIndex(c => c.Id === categoryId);
        this.categories.splice(index, 1);
    }

    private cloneBillOfMaterialsRow(row : IQuotationBillOfMaterialsRow) : IQuotationBillOfMaterialsRow {
        const clone = Object.assign({}, row);
        return clone;
    }

    private cloneBillOfMaterials(bom : IQuotationBillOfMaterials) : IQuotationBillOfMaterials {
        const clone = Object.assign({}, bom);
        clone.Rows = clone.Rows.map(this.cloneBillOfMaterialsRow, this);
        return clone;
    }

    async getBillsOfMaterials(quotationId: number) : Promise<IQuotationBillOfMaterials[]> {
        return this.billsOfMaterials.filter(c => c.FKQuotation === quotationId).map(this.cloneBillOfMaterials, this);
    }

    async getBillOfMaterials(billOfMaterialsId: number) : Promise<IQuotationBillOfMaterials> {
        const found = this.billsOfMaterials.find(b => b.Id === billOfMaterialsId);
        if(!found) return null;
        return this.cloneBillOfMaterials(found);
    }

    async createOrUpdateBillOfMaterials(billOfMaterials: IQuotationBillOfMaterials) : Promise<IQuotationBillOfMaterials> {
        const clone = this.cloneBillOfMaterials(billOfMaterials);
        const index = this.billsOfMaterials.findIndex(c => c.Id === billOfMaterials.Id);
        if(index < 0) {
            clone.Id = -clone.Id;
            clone.Rows.forEach(v => v.FKParent = clone.Id);
            this.billsOfMaterials.push(clone);
        } else {
            this.billsOfMaterials[index] = clone;
        }

        return clone;
    }

    async removeBillOfMaterials(billOfMaterialsId: number) : Promise<void> {
        const index = this.billsOfMaterials.findIndex(c => c.Id === billOfMaterialsId);
        this.billsOfMaterials.splice(index, 1);
    }
}

export default function Create(serviceLocator : IServiceLocator) {
    return serviceLocator.findService(nameof<IQuotationService>());
}