import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { NavigationProvider } from "../../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { ITodoListService } from "../../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { IModulesService } from "../../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { INavigationMenuProvider } from "../../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class WorkflowsNavigatorProvider extends NavigationProvider
{
    public todoListService : ITodoListService;
    public dialogsService : IDialogsService;
    public modulesService : IModulesService;

    public AllChildrenSelected : ko.Computed<boolean>;
    public SomeChildrenAreSelected : ko.Computed<boolean>;

    public HasMultipleSearchModes : boolean = false;
    public SearchModes : any[];
    public SearchPlaceholder : ko.Computed<string>;
    public CurrentSearchMode : ko.Observable<any> = ko.observable();

    constructor(protected serviceLocator : IServiceLocator)
    {
        super();
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);

        this.AllChildrenSelected = ko.computed(() => {
            var matches = this.Items().filter((p : INavigationMenuProvider) => { return !p.IsSelected() });
            return matches.length == 0 && this.Items().length > 0;
        });

        this.SomeChildrenAreSelected = ko.computed(() => {
            var matches = this.Items().filter((p : INavigationMenuProvider) => { return p.IsSelected() });
            return matches.length  > 0;
        });

        this.AllChildrenSelected.subscribe((v) => {
            this.IsSelected(v);
        });

        this.SearchPlaceholder = ko.computed(() => {
            return this.CurrentSearchMode() ? this.CurrentSearchMode().Placeholder : "";
        });
    }

    public SwitchSearchMode(searchMode : any)
    {
        this.SearchedItems([]);
        this.navigator().SearchFilter("");
        this.CurrentSearchMode(searchMode);
    }

    open(item) {
        (<any>this.navigator()).mobileBrowserEnabled(false);
        super.open(item);
    }
}
