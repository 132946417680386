import * as ko from "knockout";
import { BaseFilter } from "./BaseFilter";

export class DatePropertyFilter extends BaseFilter {

    constructor(propertyName, filterLabel) {
        super(propertyName, filterLabel);
    }

    GetValue(obj) : string[] {
        //Cerco la property di filtraggio...
        if(obj[this.propertyName] != null && obj[this.propertyName] != undefined)
        {
            var propertyValue : Date = ko.utils.unwrapObservable(obj[this.propertyName]);
            return (propertyValue == null || propertyValue == undefined) ? null : [propertyValue.toString(/*'d'*/)];
        }

        return null;
    }
}
