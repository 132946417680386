import * as ko from "knockout";
import { IProLifeCanvasUtilities } from "../../interfaces/prolife-sdk/IProLifeUtilitiesLibrary";

export class CanvasUtilities implements IProLifeCanvasUtilities
{
    public DrawRoundRect(ctx, x : number, y : number, width : number, height : number, radius : number, fill : boolean, stroke : boolean)
    {
        if (typeof stroke == "undefined" )
            stroke = true;

        if (typeof radius === "undefined")
            radius = 5;

        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();

        if (stroke)
            ctx.stroke();

        if (fill)
            ctx.fill();
    }

}
