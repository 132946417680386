import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Document, IDocumentConfiguration } from "../Document";

export class PurchaseRequestConfiguration implements IDocumentConfiguration {
    DocumentType : string = ProlifeSdk.PurchaseRequestEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(true);
        
        document.DDTCausesDataSource.setShowOnlySalesCauses(true);
        
        document.CanHaveDestinationRecipient(false);
        document.CanBeAnElectronicInvoice(false);
        document.CanHaveStampDuty(false);
        document.CanBeDownPayment(false);
        document.CanHaveSecondaryRecipients(false);
        document.CanBeForPublicAdministration(false);
        document.CanHaveWithholdingTax(false);
        document.CanHideAmounts(false);
        document.CanSelectCustomers(true);
        document.CanSelectSuppliers(false);
        document.CanHaveRelatedDocuments(false);
        document.CanHaveSourceWarehouse(document.IsWarehouseEnabled);
        document.CanHaveDestinationWarehouse(false);
        document.CanHaveShippingInformation(false);
        document.CanHaveCause(false);
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(false);
        document.CanHaveTaxReport(false);
        document.CanReferenceArticles(true);
        document.CanHaveVAT(false); //TODO: Perchè?
        document.CanCloseRows(true);
        document.CanHaveLetterOfAttempts(false);
        document.CanViewOldLetterOfAttempts(false);
        document.CanChangeDiscountCatalog(true);
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(false);
        document.CanEditRevisionNumber(false);
        document.CanHaveSchedules(false);
        document.CanExportElectronicInvoices(false);
        document.CanHaveState(false);
        document.CanSelectClosedLettersOfAttempt(false);
        document.CanHaveCIGCUP(true);
        document.MustCheckTrust(false);
        document.IsCustomerDocument(true);
        document.ShowVersionNumbersSuggestions(false);
        document.CanHaveMetadatas(document.MetadatasEnabled);
        document.RecipientPosition("right");

        document.ExcelExporterId("Provisioning/PurchaseRequestExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.NuovoLabel);

        document.CanCreateWarehouseInspections(document.CanCreateWarehouseInspectionFromDocument && document.IsWarehouseEnabled);
    }

    print(documentId: number) {
        //var popup = window.open("about:blank", "_blank");
        //popup.location.href = "/Service/Warehouse/Print/CustomerOrder/" + documentId;
    }
}