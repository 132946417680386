import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

const styleSheet = jss.createStyleSheet({
    buttonToolbar: {
        "&.btn-group-xs": {
            marginTop: "0px",
        },
        "& .btn": {
            //backgroundColor: "#4b8df8",
            boxShadow: "0px 0px 0px 1px inset #b3b3b3",
            //color: "#4b8df8",

            "&.blue": {
                backgroundColor: "#4b8df8",
                boxShadow: "0px 0px 0px 1px inset #4b8df8",
                color: "white",
            },
        },
    },
});
const { classes } = styleSheet.attach();

type ButtonToolbarOption = {
    icon?: string;
    text?: string;
    active: ko.Observable<boolean> | ko.Computed<boolean> | (() => boolean);
    onClick: () => void | Promise<void>;
};

type ButtonToolbarProps = {
    label?: string;
    simple?: boolean;
    size?: "lg" | "md" | "sm" | "xs";
    options: ButtonToolbarOption[];
    itemStyle?: CSSProperties;
};

export function ButtonToolbar(props: ButtonToolbarProps) {
    const C = require("./ButtonToolbar")._ButtonToolbar as typeof _ButtonToolbar;
    return <C {...props} />;
}

export class _ButtonToolbar {
    static defaultProps: Partial<ButtonToolbarProps> = {
        size: "xs",
    };

    constructor(private props: ButtonToolbarProps) {}

    private wrapWithLabel(toolbar: React.ReactElement) {
        if (this.props.simple) return toolbar;

        const classNames = ComponentUtils.classNames("form-group");

        return (
            <div className={classNames}>
                <label className="control-label">{this.props.label}</label>
                {toolbar}
            </div>
        );
    }

    render() {
        const bt = this;
        const opt: ButtonToolbarOption = null;
        const classNames = ComponentUtils.classNames(
            classes.buttonToolbar,
            "btn-group",
            "btn-group-circle",
            "btn-group-" + this.props.size,
            "btn-group-solid"
        );

        return ComponentUtils.bindTo(
            this.wrapWithLabel(
                <div className="btn-toolbar">
                    <div className={classNames} data-bind={{ foreach: { data: bt.props.options, as: "opt" } }}>
                        <button
                            type="button"
                            className="btn blue"
                            data-bind={{ css: { blue: opt.active }, click: opt.onClick }}
                            style={this.props.itemStyle}>
                            <i data-bind={{ css: opt.icon }} />
                            <span data-bind={{ text: opt.text }}></span>
                        </button>
                    </div>
                </div>
            ),
            this,
            "bt"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ButtonToolbar);
}
