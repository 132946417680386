import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 17.55
 * To change this template use File | Settings | File Templates.
 */

import { SettingsManagerViewModel } from "./SettingsManagerViewModel";
import { ISettingsGroup } from "../../../ProlifeSdk/interfaces/settings/ISettingsGroup";
import { ISettingsViewModel } from "../../interfaces/ISettingsViewModel";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";

export class SettingsGroupViewModel {
    public title : string;
    public links : any[] = [];
    public isEmpty : boolean;

    constructor(settingsGroup : ISettingsGroup, private settings : ISettingsViewModel) {
        this.title = settingsGroup.label;
        this.links = settingsGroup.getAllSettingsManagers()
            .filter(this.onlyManagersWithEditingUI)
            .map(l => this.createViewModelFor(l))
            .orderBy(l => l.title);
        this.isEmpty = this.links.length == 0;
    }

    private onlyManagersWithEditingUI(manager : ISettingsManager) {
        return manager.hasEditingUI();
    }

    private createViewModelFor(manager : ISettingsManager) : SettingsManagerViewModel {
        return new SettingsManagerViewModel(manager, this.settings);
    }


}