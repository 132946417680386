import * as Core from "../Core";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../enumerations/ServiceTypes";
import "json.date-extensions";
import { IException } from "../interfaces/IException";
import { IServiceLocator } from "../interfaces/IServiceLocator";
import {
    IAjaxService,
    IAjaxServiceConfiguration,
    AjaxOptions,
    UploadData,
    DownloadAjaxOptions,
    IAjaxServiceNew,
} from "../interfaces/IAjaxService";
import { IDialogsService } from "../interfaces/IDialogsService";
import { IAjaxFilterHostService, IAjaxFilter } from "../interfaces/IAjaxFilterHostService";
import { IService } from "../interfaces/IService";
import { Deferred, PromiseWithProgress } from "../Deferred";
import {
    ApolloClient,
    ApolloQueryResult,
    InMemoryCache,
    NormalizedCacheObject,
    QueryOptions,
    createHttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { LazyImport } from "../DependencyInjection";
import { IAuthenticationService } from "../interfaces/IAuthenticationService";
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosInterceptorOptions,
    AxiosProgressEvent,
    AxiosResponseHeaders,
    InternalAxiosRequestConfig,
} from "axios";
import { IInfoToastService } from "../interfaces/IInfoToastService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ResponseError } from "../response/ResponseBase";

// eslint-disable-next-line no-var
declare var JsonNetDecycle: any;

const SERVICE_MAINTENANCE_MODE_ERROR_CODE = "ServiceInMaintenanceMode";

class AjaxService implements IAjaxService, IAjaxServiceNew, IAjaxFilterHostService {
    private axiosInstance: AxiosInstance;
    private _filters: IAjaxFilter[] = [];
    private _graphqlCache: InMemoryCache = new InMemoryCache();
    private _graphqlClient: ApolloClient<NormalizedCacheObject>;

    @LazyImport(nameof<IAuthenticationService>())
    private _authenticationService: IAuthenticationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private _unauthenticatedUrls: string[] = ["a/login/login", "a/login/refresh-token"];

    constructor(private configuration: IAjaxServiceConfiguration, private authenticateOnInitialization: boolean) {
        this.axiosInstance = axios.create({
            baseURL: this.configuration.serviceRoot,
            headers: {
                "Content-Type": "application/json",
            },
            transformResponse: (data: any, headers?: AxiosResponseHeaders) => {
                try {
                    return JSON.parseWithDate(data);
                } catch {
                    return data;
                }
            },
        });

        this.axiosInstance.interceptors.request.use(
            (config) => {
                const token = this._authenticationService.getJwtTokenInfo();
                if (token && !this.isUnauthenticatedUrl(config.url)) {
                    config.headers["Authorization"] = this.getBearerHeader();
                }
                return config;
            },
            (error) => {
                return Promise.reject(error.response.data);
            }
        );

        this.axiosInstance.interceptors.response.use(
            (response) => {
                if (typeof response.data === "object" && response.request.responseType !== "blob")
                    response.data = JsonNetDecycle.retrocycle(response.data);
                return response;
            },
            async (
                error: AxiosError<{ errors: ResponseError[] }> & {
                    config: InternalAxiosRequestConfig<any> & { _retry?: boolean };
                }
            ) => {
                const originalConfig = error.config;
                if (error.response) {
                    const response = error.response;

                    if (
                        error.response?.status === 403 &&
                        this.isServiceMaintenanceModeError(error.response?.data?.errors)
                    ) {
                        this.infoToastService.Error(
                            TextResources.ProlifeSdk.ServiceInMaintenanceMode,
                            TextResources.ProlifeSdk.SystemMessage,
                            {
                                tapToDismiss: true,
                            }
                        );
                    }

                    if (
                        response.status === 401 &&
                        !this.isUnauthenticatedUrl(response.config.url) &&
                        !originalConfig._retry
                    ) {
                        if (!(await this._authenticationService.renewToken())) {
                            this.logout();
                            return Promise.reject("Token scaduto");
                        }

                        originalConfig._retry = true;
                        return this.axiosInstance(response.config);
                    }

                    return await this.handleException(error);
                } else {
                    return Promise.reject(error);
                }
            }
        );

        const httpLink = createHttpLink({
            uri: this.configuration.newServiceRoot + "g/graphql",
        });

        const authLink = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: this.getBearerHeader(),
                },
            };
        });

        this._graphqlClient = new ApolloClient({
            cache: this._graphqlCache,
            name: "prolife-web-client",
            version: "1.3",
            queryDeduplication: false,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: "cache-and-network",
                },
            },
            link: authLink.concat(httpLink),
        });
    }

    private async handleException(error: AxiosError<unknown, any>) {
        const data = error.response.data;
        let exception: IException = null;
        if (typeof data == "string") {
            if (!this.isJSON(data)) return Promise.reject(error);
            exception = JSON.parseWithDate(data);
        } else if (data instanceof Blob && data.type === "application/json") {
            const text = await data.text();
            if (!this.isJSON(text)) return Promise.reject(error);
            exception = JSON.parseWithDate(text);
        } else {
            exception = data as IException;
        }

        switch (exception.ExceptionType) {
            case ProlifeSdk.ServerException_SessionExpired:
            case ProlifeSdk.ServerException_Authentication:
            case ProlifeSdk.ServerException_MaintenanceMode:
                //logout
                this.logout();
                break;
            case ProlifeSdk.ServerException_OldVersion:
                this.dialogsService.LockUI(exception.ExceptionMessage);
                break;
            case ProlifeSdk.ServerException_ProLife:
            case ProlifeSdk.ServerException_Authorization:
            case "System.Exception":
                {
                    this.infoToastService.Error(exception.ExceptionMessage || exception.Message);
                }
                break;
        }

        return Promise.reject(exception);
    }

    private isUnauthenticatedUrl(url: string) {
        return this._unauthenticatedUrls.some((u) => url.toLowerCase().indexOf(u) !== -1);
    }

    private isServiceMaintenanceModeError(errors: ResponseError[]): boolean {
        return errors?.some((e) => e.code === SERVICE_MAINTENANCE_MODE_ERROR_CODE);
    }

    private isJSON(data: any): data is string {
        let isJson = false;
        try {
            // this works with JSON string and JSON object, not sure about others
            const json = JSON.parse(data);
            isJson = typeof json === "object";
        } catch (ex) {
            //console.error('data is not JSON');
        }
        return isJson;
    }

    async InitializeService(): Promise<void> {
        //if (this.authenticateOnInitialization) return this.Authenticate();
    }

    getServiceType(): string {
        return ServiceTypes.Ajax;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public isAuthenticated(): boolean {
        return this._authenticationService.isLoggedIn();
    }

    private getBearerHeader(): string {
        const tokenInfo = this._authenticationService.getJwtTokenInfo();
        if (tokenInfo === null) return null;
        return "Bearer " + tokenInfo.token;
    }

    private async renewTokenIfNeeded() {
        if (this._authenticationService.isJwtTokenAlmostExpired()) {
            return await this._authenticationService.renewToken();
        }

        return true;
    }

    private logout() {
        this._authenticationService.logoutUser();
    }

    async Query<T, TVars>(options?: QueryOptions<TVars, T>): Promise<ApolloQueryResult<T>> {
        if (!(await this.renewTokenIfNeeded())) {
            this.logout();
            throw new Error("Token scaduto");
        }
        return this._graphqlClient.query<T, TVars>(options);
    }

    Post<T>(service: string, method: string, options: AjaxOptions): Promise<T> {
        return this.Call<T>("POST", service, method, options);
    }

    Get<T>(service: string, method: string, options: AjaxOptions): Promise<T> {
        return this.Call<T>("GET", service, method, options);
    }

    Put<T>(service: string, method: string, options: AjaxOptions): Promise<T> {
        return this.Call<T>("PUT", service, method, options);
    }

    Delete<T>(service: string, method: string, options: AjaxOptions): Promise<T> {
        return this.Call<T>("DELETE", service, method, options);
    }

    Download(service: string, method: string, options: DownloadAjaxOptions): Promise<void> {
        const url = service + this.configuration.serviceSuffix + (method.length > 0 ? "/" + method : "");
        return this.DownloadFileFromUrl(url, options);
    }

    async DownloadFileFromUrl(url: string, options: DownloadAjaxOptions): Promise<void> {
        const { blob, fileName } = await this.DownloadFromUrl(url, options);

        const link = document.createElement("a");
        const blobLink = URL.createObjectURL(blob);
        link.href = blobLink;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobLink);
    }

    async DownloadOnly(
        service: string,
        method: string,
        options: DownloadAjaxOptions
    ): Promise<{ blob: Blob; fileName: string }> {
        const url = service + this.configuration.serviceSuffix + (method.length > 0 ? "/" + method : "");
        return await this.DownloadFromUrl(url, options);
    }

    async DownloadFromUrl(url: string, options: DownloadAjaxOptions): Promise<{ blob: Blob; fileName: string }> {
        const response = await this.axiosInstance.request({
            url: url,
            method: options.overrideMethod ?? "POST",
            responseType: "blob",
            data: options.methodData,
        });

        if (response.status === 200) {
            const disposition: string = response.headers["content-disposition"] ?? "";
            const contentType: string = (response.headers["content-type"] as string) ?? "application/octet-stream";
            const matches = /filename="?([^";]+)"?;?/.exec(disposition);
            const fileName = matches != null && matches[1] ? matches[1] : options.overrideFileName ?? "file";

            const blob = new Blob([response.data], { type: contentType });
            return { blob, fileName };
        }

        throw response.data;
    }

    Upload<T>(service: string, method: string, data: UploadData, background = false): PromiseWithProgress<T> {
        const deferred = new Deferred<T>();

        const formData = new FormData();
        for (const name in data) {
            if (data[name] instanceof File) formData.append(name, data[name]);
            else if (data[name] instanceof Blob) formData.append(name, data[name], name);
            else formData.append(name, data[name]);
        }

        this.axiosInstance
            .post(`${service}${this.configuration.serviceSuffix}${method.length > 0 ? "/" + method : ""}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    const percentComplete = progressEvent.loaded / progressEvent.total;
                    //Do something with upload progress
                    deferred.notify({
                        loaded: progressEvent.loaded,
                        total: progressEvent.total,
                        percentComplete: percentComplete,
                    });
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject(response.data);
                }
            });

        return deferred.promise();
    }

    private Call<T>(httpVerb: string, serviceName: string, methodName: string, options: AjaxOptions): Promise<T> {
        if (this.dialogsService && !options.background) this.dialogsService.LockUI(this.configuration.defaultLoading);

        return this.axiosInstance
            .request({
                url: `${serviceName}${this.configuration.serviceSuffix}${
                    methodName.length > 0 ? "/" + methodName : ""
                }`,
                method: httpVerb,
                data: options.methodData,
            })
            .then((response) => {
                if (response.status < 400) {
                    //TODO: Trovare soluzione per gestire gli errori
                    return Promise.resolve(response.data);
                } else {
                    return Promise.reject(response.data);
                }
            })
            .finally(() => {
                if (this.dialogsService && !options.background) this.dialogsService.UnlockUI();
            });
    }

    /*private async CallWithParameters<T>(parameters: JQueryAjaxSettings): Promise<T> {
        for (let i = 0; i < this._filters.length; i++) {
            const filter = this._filters[i];

            const overridePromise: Promise<T> = filter.onStarting(parameters);
            if (overridePromise) return overridePromise;
        }

        if (this.dialogsService && !(<any>parameters).background)
            this.dialogsService.LockUI(this.configuration.defaultLoading);

        for (;;) {
            try {
                const v: T = await $.ajax(parameters);
                return v;
            } catch (exception) {
                const response: JQueryXHR = exception;
                const authResponse = response.getResponseHeader("WWW-Authenticate");
                if (authResponse && authResponse.indexOf('Bearer error="invalid_token"') !== -1) {
                    console.log("Token scaduto, rinnovo in corso...");
                    await this.Authenticate(true);

                    parameters.headers = {
                        Authorization: "Bearer " + this.jwt,
                    };

                    continue;
                }

                if (this.dialogsService && !(<any>parameters).background) this.dialogsService.UnlockUI();

                let result: IException = null;
                try {
                    if (response.responseJSON) result = response.responseJSON;
                    else result = JSON.parse(response.responseText);
                } catch (e) {
                    result = {
                        Message: response.responseText,
                        ExceptionMessage: response.responseText,
                        ExceptionType: "",
                        StackTrace: "",
                    };
                }

                throw result;
            } finally {
                if (this.dialogsService && !(<any>parameters).background) this.dialogsService.UnlockUI();
            }
        }
    }*/

    /*private BuildAjaxParameters(
        httpVerb: string,
        serviceName: string,
        methodName: string,
        options: AjaxOptions
    ): JQueryAjaxSettings {
        const filters = this._filters;
        const beforeSendInjectors: any[] = this._beforeSendInjectors;
        const parameters: JQueryAjaxSettings & { background: boolean } = {
            url:
                this.configuration.serviceRoot +
                serviceName +
                this.configuration.serviceSuffix +
                (methodName.length > 0 ? "/" + methodName : ""),
            type: httpVerb,
            dataType: this.configuration.jsonp ? "jsonp" : "json",
            contentType: "application/json",
            converters: {
                "* text": (<any>window).String,
                "text html": true,
                "text json": (json) => {
                    const obj = JSON.parseWithDate(!json ? null : json);
                    JsonNetDecycle.retrocycle(obj);
                    return obj;
                },
                "text xml": jQuery.parseXML,
            },

            beforeSend: function (xhr) {
                beforeSendInjectors.forEach((i) => {
                    i(xhr);
                });
            },

            complete: function (jqXHR: JQueryXHR, textStatus: string) {
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i];

                    if (filter.onComplete(options, jqXHR, textStatus)) return;
                }
            },

            success: function (data: unknown, textStatus: string, jqXHR: JQueryXHR) {
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i];

                    if (filter.onSuccess(options, data, textStatus, jqXHR)) return;
                }

                if (options.successCallback !== undefined) options.successCallback(data);
            },

            error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: any) => {
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i];

                    if (filter.onError(options, jqXHR, textStatus, errorThrown)) return;
                }

                if (options.errorCallback !== undefined) options.errorCallback(jqXHR, textStatus, errorThrown, options);
            },

            async: options.async != undefined ? options.async : true,
            data:
                options.methodData != undefined
                    ? this.configuration.jsonp
                        ? options.methodData
                        : JSON.stringify(JsonNetDecycle.decycle(options.methodData))
                    : undefined,
            background: options.background,
        };

        if (this.authenticateOnInitialization) {
            parameters.headers = {
                Authorization: "Bearer " + this.jwt,
            };
        }

        return <JQueryAjaxSettings>parameters;
    }*/

    addFilter(filter: IAjaxFilter) {
        this._filters.push(filter);
    }

    addBeforeSendInjector(
        onFulfilled?:
            | ((value: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>)
            | null,
        onRejected?: ((error: any) => any) | null,
        options?: AxiosInterceptorOptions
    ) {
        this.axiosInstance.interceptors.request.use(onFulfilled, onRejected, options);
    }
}

export default function Create(serviceLocator: IServiceLocator): IService | IService[] {
    const ajaxService = new AjaxService(Core.configuration.ajaxConfig, false);
    serviceLocator.registerServiceInstance(ajaxService);
    serviceLocator.registerServiceInstanceWithName(nameof<IAjaxService>(), ajaxService);
    serviceLocator.registerServiceInstanceWithName(nameof<IAjaxFilterHostService>(), ajaxService);

    const newConfig = Object.assign({}, Core.configuration.ajaxConfig);
    newConfig.serviceRoot = newConfig.newServiceRoot;

    const newAjaxService = new AjaxService(newConfig, true);
    serviceLocator.registerServiceInstanceWithName(nameof<IAjaxServiceNew>(), newAjaxService);

    return [ajaxService, newAjaxService];
}
