import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/10/2017
 * Time: 15:10
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ICartStatus, ICartLogicalStatus } from "../../../../../ProlifeSdk/interfaces/allocations/ICart";
import { ICartsStatesSettingManager } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsStatesSettingManager";

export interface IStatesProvider
{
    getData(): ICartStatus;
    update(status: ICartStatus): void;
}

export class CartsStatesEditingViewModel {
    public title: string = ProlifeSdk.TextResources.Allocations.CartStatusSettingsTitle;
    public states: ko.ObservableArray<CartStatusViewModel> = ko.observableArray([]);

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private statesManager: ICartsStatesSettingManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        var cartStates = this.statesManager.getCartsStatuses();
        this.states(cartStates.map((s: ICartStatus) => { return new CartStatusViewModel(s, this, this.statesManager.getCartsLogicalStatuses()); }));
    }

    public createNew(): void {
        this.states.push(new CartStatusViewModel(this.newEmptyStatus(), this, this.statesManager.getCartsLogicalStatuses()));
    }

    private newEmptyStatus(): ICartStatus {
        return <ICartStatus> {
            Id: null,
            Label: null,
            LogicalStatus: 1
        };
    }

    public createOrUpdateEntry(status: CartStatusViewModel): void {
        if (!status.Label()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.StateLabelRequired);
            return;
        }

        this.statesManager.addOrUpdate(status.getData())
            .then((data: ICartStatus) => {
                status.update(data);
            });
    }

    public deleteState(status: CartStatusViewModel): void {
        this.statesManager.remove(status.Id)
            .then((result: boolean) => {
                if (!result) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CanNotDeleteCartStatus);
                    return;
                }
                this.states.remove(status);
            });
    }
}

export class CartStatusViewModel implements IStatesProvider {

    public Id: number;
    public Label: ko.Observable<string> = ko.observable();
    public LogicalStatus: ko.Observable<number> = ko.observable();

    public LogicalStatuses: ICartLogicalStatus[];
    public LogicalStatusName: ko.Observable<string> = ko.observable();

    private updating: boolean = false;

    constructor(private status: ICartStatus, private editor: CartsStatesEditingViewModel, logicalStatuses: ICartLogicalStatus[]) {
        this.LogicalStatuses = logicalStatuses;

        this.Id = status.Id;
        this.Label(status.Label);
        this.SetState(status.LogicalStatus);

        this.Label.subscribe(this.onDataChanged.bind(this));
        this.LogicalStatus.subscribe(this.onDataChanged.bind(this));
    }

    public SetState(statusId: number): void {
        this.LogicalStatus(statusId);
        this.LogicalStatusName(this.LogicalStatuses.filter((s: ICartLogicalStatus) => { return s.Id == statusId; })[0].Description);
    }

    public DeleteEntry(): void {
        this.editor.deleteState(this);
    }

    getData(): ICartStatus
    {
        var status : ICartStatus = <ICartStatus> $.extend({}, this.status);
        status.Id = this.Id;
        status.Label = this.Label();
        status.LogicalStatus = this.LogicalStatus();
        return status;
    }

    private onDataChanged(newValue: string)
    {
        if (this.updating) return;
        this.editor.createOrUpdateEntry(this);
    }

    update(status: ICartStatus) : void
    {
        this.updating = true;

        this.status = status;
        this.Id = status.Id;
        this.Label(status.Label);
        this.SetState(status.LogicalStatus);

        this.updating = false;
    }
}