import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IWorkingResource } from "../../../../ProlifeSdk/interfaces/todolist/IWorkingResource";
import { IWorkingResourceAllocation } from "../../../../ProlifeSdk/interfaces/todolist/IWorkingResourceAllocation";

export class WorkingResourceAllocationDialog implements IDialog
{
    templateName:string = "workingResourcesAllocation";
    templateUrl:string = "JobOrder/templates";
    title:string = ProlifeSdk.TextResources.JobOrder.WorkingResourceAllocation;

    modal: {
        close: (result?: any) => void;
    };

    public JobOrderAllocations : ko.ObservableArray<JobOrderResourceAllocation> = ko.observableArray([]);

    private todoListService : ITodoListService;

    constructor(serviceLocator : IServiceLocator, resource : IWorkingResource)
    {
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        this.title += " (" + resource.Name + " " + resource.Surname + ")";

        this.todoListService.GetWorkingResourceAllocation(resource.ResourceId)
            .then((results : IWorkingResourceAllocation[]) => {
                this.LoadResourceAllocation(results);
            });
    }

    LoadResourceAllocation(results : IWorkingResourceAllocation[]) {
        var lastJobOrderId = -1;
        var jobOrderAllocation = null;

        results.forEach((r : IWorkingResourceAllocation) => {
            if(r.JobOrderId != lastJobOrderId) {
                if(jobOrderAllocation)
                    this.JobOrderAllocations.push(jobOrderAllocation);

                jobOrderAllocation = new JobOrderResourceAllocation(r);
                lastJobOrderId = r.JobOrderId;
            } else {
                jobOrderAllocation.AppendAllocation(r);
            }
        });
    }

    close():void {
        this.modal.close();
    }

    action():void {
        this.modal.close();
    }
}

class JobOrderResourceAllocation
{
    public JobOrderName : ko.Observable<string> = ko.observable();
    public JobOrderState : ko.Observable<string> = ko.observable();
    public JobOrderType : ko.Observable<string> = ko.observable();

    public WorkflowsAllocations : ko.ObservableArray<WorkflowResourceAllocation> = ko.observableArray([]);

    constructor(allocation : IWorkingResourceAllocation) {
        this.JobOrderName(allocation.JobOrderName);
        this.JobOrderState(allocation.JobOrderState);
        this.JobOrderType(allocation.JobOrderType);

        this.AppendAllocation(allocation);
    }

    public AppendAllocation(allocation : IWorkingResourceAllocation) {
        this.WorkflowsAllocations.push(new WorkflowResourceAllocation(allocation));
    }
}

class WorkflowResourceAllocation
{
    public WorkflowName : ko.Observable<string> = ko.observable();
    public WorkflowState : ko.Observable<string> = ko.observable();
    public WorkflowType : ko.Observable<string> = ko.observable();

    public FirstWorkDay : ko.Observable<Date> = ko.observable();
    public LastWorkDay : ko.Observable<Date> = ko.observable();

    public WorkedHours : ko.Observable<number> = ko.observable();
    public MeanWorkPerDay : ko.Observable<number> = ko.observable();

    constructor(allocation : IWorkingResourceAllocation) {
        this.WorkflowName(allocation.WorkflowTitle);
        this.WorkflowState(allocation.WorkflowState);
        this.WorkflowType(allocation.WorkflowCategory);

        this.FirstWorkDay(allocation.FirstWorkDay);
        this.LastWorkDay(allocation.LastWorkDay);

        this.WorkedHours(allocation.WorkedHours);
        this.MeanWorkPerDay(allocation.MeanWorkPerDay);
    }

}
