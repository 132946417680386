import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { WarehouseServiceUI as WarehouseServiceUI } from "./warehouse/ui/WarehouseServiceUI";
import { WarehouseSettingsManager } from "./warehouse/settings/WarehouseSettingsManager";
import { WarehouseEntityCodeGeneratorSettingsManager } from "./warehouse/settings/WarehouseEntityCodeGeneratorSettingsManager";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    IWarehousesService,
    IWarehousesServiceObserver,
    IWarehouseUI,
    IWarehouse,
    IWarehouseBusinessInfo,
    IWarehouseAddress,
    IWarehouseWithStockInfo,
    IWarehousesWhereArticleIsStocked,
    IWarehouseWithStockInfoNew,
} from "../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { LazyImport } from "../Core/DependencyInjection";
import { ResponseData } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";

class WarehousesService implements IWarehousesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    private observers: IWarehousesServiceObserver[] = [];

    public ui: IWarehouseUI;

    constructor(private serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IWarehousesService>(), this);
        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.ui = new WarehouseServiceUI(this.serviceLocator);
        new WarehouseSettingsManager();
        new WarehouseEntityCodeGeneratorSettingsManager(this.serviceLocator);
    }

    getServiceType(): string {
        return ProlifeSdk.WarehousesServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    async getWarehouses(
        filter: string,
        skip: number,
        count: number,
        customerIds: number[],
        getAllWarehouses = false,
        getDeleted = false,
        showOnlyJobOrderWarehouses = false,
        jobOrderIds: number[]
    ): Promise<IWarehouseWithStockInfo[]> {
        try {
            const response: ResponseData<IWarehouseWithStockInfoNew[]> = await this.ajaxServiceNew.Post(
                "w/warehouses",
                "search",
                {
                    methodData: {
                        filter: filter,
                        skip: skip,
                        count: count,
                        customerIds: (customerIds ?? []).filter((c) => !!c),
                        getAllWarehouses: getAllWarehouses,
                        getDeleted: getDeleted,
                        showOnlyJobOrderWarehouses: showOnlyJobOrderWarehouses,
                        jobOrderIds: (jobOrderIds ?? []).filter((j) => !!j),
                    },
                }
            );

            if (response.succeeded) {
                const mappedWarehouses: IWarehouseWithStockInfo[] = toPascalCase(response.data);
                mappedWarehouses.forEach((warehouse) => {
                    const source = response.data.firstOrDefault((x) => x.id == warehouse.Id);
                    warehouse.CAP = source.cap;
                    warehouse.FAX = source.fax;
                });

                return mappedWarehouses;
            } else {
                console.error(response.errors);
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    getWarehousesCount(
        filter: string,
        customerId?: number,
        getAllWarehouses: boolean = false,
        getDeleted: boolean = false
    ): Promise<number> {
        customerId = customerId || null;

        return this.ajaxService.Get(
            "Warehouse-api",
            "Warehouses/Count?filter=" +
                encodeURI($.trim(filter)) +
                "&customerId=" +
                customerId +
                "&getAllWarehouses=" +
                getAllWarehouses +
                "&getDeleted=" +
                getDeleted,
            {}
        );
    }

    GetWarehouseBusinessInfo(warehouseId: number): Promise<IWarehouseBusinessInfo> {
        if (!warehouseId) {
            return Promise.reject<IWarehouseBusinessInfo>(undefined);
        }

        return this.ajaxService.Get<IWarehouseBusinessInfo>(
            "Warehouse-api",
            "WarehouseBusinessInfo/" + warehouseId,
            {}
        );
    }

    GetJobOrderAddressToAssignToNewWarehouse(jobOrderId: number): Promise<IWarehouseAddress> {
        return this.ajaxService.Get(
            "Warehouse-api",
            "Warehouses/GetJobOrderAddressToAssignToNewWarehouse?jobOrderId=" + jobOrderId,
            {}
        );
    }

    GetJobOrderWarehouses(jobOrderId: number): Promise<IWarehouseWithStockInfo[]> {
        return this.ajaxService.Get("Warehouse-api", "Warehouses/GetJobOrderWarehouses?jobOrderId=" + jobOrderId, {});
    }

    getWarehouseById(warehouseId: number): Promise<IWarehouse> {
        if (!warehouseId) {
            return Promise.resolve<IWarehouse>(null);
        }

        return this.ajaxService.Get("Warehouse-api", "Warehouses/GetWarehouseById?id=" + warehouseId, {
            background: true,
        });
    }

    getDefaultWarehouse(): Promise<IWarehouse> {
        return this.ajaxService.Get("Warehouse-api", "DefaultWarehouse/FindDefault", {});
    }

    GetWarehousesWhereArticleIsStocked(articleId: number): Promise<IWarehousesWhereArticleIsStocked[]> {
        return this.ajaxService.Post("Warehouse-api", "Warehouses/GetWarehousesWhereArticleIsStocked", {
            methodData: { Id: articleId },
        });
    }

    createOrUpdate(warehouse: IWarehouse): Promise<IWarehouse> {
        const callback = warehouse.Id ? this.onWarehouseChanged.bind(this) : this.onWarehouseAdded.bind(this);

        return this.ajaxService
            .Post<IWarehouse>("Warehouse-api", "Warehouses", { methodData: warehouse })
            .then(callback);
    }

    PreviewCode(warehouse: IWarehouse): Promise<string> {
        return this.ajaxService.Post<string>("Warehouse-api/Warehouses", "PreviewCode", {
            methodData: warehouse,
            background: true,
        });
    }

    remove(warehouseId: number): Promise<void> {
        if (!warehouseId) {
            return Promise.reject<void>(undefined);
        }

        return this.ajaxService
            .Delete<void>("Warehouse-api", "Warehouses/" + warehouseId, {})
            .then(this.onWarehouseDeleted.bind(this, warehouseId));
    }

    undelete(warehouseId: number): Promise<IWarehouse> {
        if (!warehouseId) {
            return Promise.reject<IWarehouse>(undefined);
        }

        return this.ajaxService
            .Post<IWarehouse>("Warehouse-api", "Warehouses/Undelete", {
                methodData: {
                    Id: warehouseId,
                },
            })
            .then(this.onWarehouseChanged.bind(this));
    }

    addObserver(observer: IWarehousesServiceObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IWarehousesServiceObserver): void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    onWarehouseChanged(warehouse: IWarehouse): IWarehouse {
        this.observers.forEach((observer) => observer.onWarehouseChanged(warehouse));
        return warehouse;
    }

    onWarehouseAdded(warehouse: IWarehouse): IWarehouse {
        this.observers.forEach((observer) => observer.onWarehouseAdded(warehouse));
        return warehouse;
    }

    onWarehouseDeleted(warehouseId: number): void {
        this.observers.forEach((observer) => observer.onWarehouseDeleted(warehouseId));
    }

    GetWarehouseStockUrl(warehouseId: number) {
        return ProlifeSdk.TextResources.Warehouse.InventoryURL + "/" + warehouseId;
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new WarehousesService(serviceLocator);
}
