import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { Right } from "../../../../../Core/Authorizations";
import { IDataSource } from "../../../../../DataSources/IDataSource";
import { _WorkedHoursEditor } from "../../../../../WorkedHours/workedhours/ui2019/editor/WorkedHoursEditor";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import moment = require("moment");
import { IHumanResourcesSettingsManager } from "../../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResourceResource } from "../../../../../Users/HumanResourcesService";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IWorkedHoursEditor } from "../../../../../WorkedHours/interfaces/IWorkedHoursEditor";
import { IDayNavigationMenuComponentModel } from "../../../../../DataSources/WorkedHoursJobOrderDateDataSource";

export class JobOrderWorkedHoursPanelFactory implements IJobOrderEditorPanelFactory {
    @Right("WorkedHours_Start")
    private canInsertWorkedHours: boolean;

    @Right("WorkedHours_CanEditOtherUsersWorkSheets")
    private canEditOtherUsersWorkedHours: boolean;

    constructor(serviceLocator : IServiceLocator, public Color : string) {

    }

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderWorkedHoursPanel(editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }
    
    hasAuthorization(): boolean {
        return this.canInsertWorkedHours && this.canEditOtherUsersWorkedHours;
    }
}

class JobOrderWorkedHoursPanel implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(TextResources.JobOrder.HoursReporting);
    TemplateUrl = "";
    TemplateName = "";

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    private loggedResource: IHumanResourceResource;
    private hasChanges = false;

    public WorkedHoursEditor: IWorkedHoursEditor;

    constructor(private editor : IJobOrderEditor, public Color : string) {
        this.loggedResource = this.resourcesManager.getLoggedResource()?.Resource;
    }
    
    dispose() {
        
    }
    
    async load(): Promise<void> {
        
    }
    
    isDefaultOnNew(): boolean {
        return false;
    }
    
    isDefault(): boolean {
        return false;
    }
    
    async beforeChangePanel(skipPendingChangesCheck = false): Promise<boolean> {
        if (skipPendingChangesCheck || !this.hasChanges) {
            this.editor.UseWorkDaysMenu(false);
            return true;
        }

        let confirm = await  this.dialogsService.ConfirmAsync(TextResources.JobOrder.UnsavedChangesLostMsg, TextResources.JobOrder.UnsavedChangesLostMsgCancel, TextResources.JobOrder.UnsavedChangesLostMsgConfirm);
        if (!confirm)
            return false;

        this.editor.UseWorkDaysMenu(false);
        return true;
    }
    
    async beforeShowPanel(): Promise<void> {
        this.editor.UseWorkDaysMenu(true);
        this.desktopService.SystemHeader.setSidebarVisibility(true);

        setTimeout(() => { this.initializeWorkDaysMenu(); }, 0);
    }
    
    canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    onItemSelected(sender: IDataSource, model: IDayNavigationMenuComponentModel): void {
        if (!this.WorkedHoursEditor)
            return;

        this.WorkedHoursEditor.setWorkDay(model.date);
    }
    
    onItemDeselected(sender: IDataSource, model: IDayNavigationMenuComponentModel): void {
        
    }

    private initializeWorkDaysMenu(): void {
        this.editor.navigateWorkDaysMenuToToday();
        this.hasChanges = false;
    }

    render() {
        let panel = this;

        return (
            <div style={{ position: "absolute", top: "15px", right: "15px", bottom: "15px", left: "15px" }}>
                <_WorkedHoursEditor
                    date={moment().toDate()}
                    resourceId={this.loggedResource?.Id}
                    listeners={[]}
                    jobOrderId={this.editor?.JobOrderId}
                    canEditDate={false}
                    enableMultipleInsert={false}
                    editFromJobOrderPanel={true}

                    onEditorChanges={(value: boolean) => panel.hasChanges = value}

                    ref={(e) => panel.WorkedHoursEditor = e}
                />
            </div>
        );
    }
}