import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ContactsReferencesBase } from "./ContactsReferencesBase";
import { IContactReference, IContactUoForList } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { Deferred } from "../../../Core/Deferred";

export class CustomerContactsReferences extends ContactsReferencesBase
{
    public ContactsReferences : ko.ObservableArray<IContactReference> = ko.observableArray([]);

    constructor(private onlyCompanies : boolean)
    {
        super();
    }

    public Initialize(references : IContactReference[])
    {
        this.Values("");
        references.forEach((reference : IContactReference) => { this.AddContact(reference); });
        this.InitializeContactsInternal(this.Values(), (result) => {
            this.ContactsReferences(result);
        });
    }

    public GetReferences(uoId) : IContactReference[]
    {
        var contacts : IContactReference[] = [];
        if(this.Values() && this.Values().trim().length > 0)
            this.Values().split("|")
                .map((id : string) => { return parseInt(id);})
                .forEach((id : number) => {
                    contacts.push(<IContactReference>{
                        ContactOrganizationalUnitId : uoId,
                        ReferencedOrganizationalUnitId : id,
                        ReferenceType : 0
                    });
                });
       return contacts;
    }

    private AddContact(reference : IContactReference)
    {
        if(this.Values().length > 0)
            this.Values(this.Values() + "|" + reference.ReferencedOrganizationalUnitId);
        else
            this.Values(reference.ReferencedOrganizationalUnitId.toString());
    }

    public SearchContactsInternal(query : any)
    {
        this.customerService.GetContactsUoForList(query.term, this.onlyCompanies)
            .then((items : IContactUoForList[]) => {

                var data = { results: [] };

                if (items.length == 0)
                    return;

                var groupResult = {
                    text: ProlifeSdk.TextResources.Customers.Contacts,
                    children: []
                };
                data.results.push(groupResult);

                items.forEach((item: IContactUoForList) => {

                    groupResult.children.push({
                        id: item.OrganzationalUnitId,
                        contactId : item.ContactId,
                        text: item.FormattedContactName + (item.Description ? " (" + item.Description + ")" : "")
                    });

                });

                query.callback(data);
            })
    }

    protected InitializeContactsInternal(ids : string, callback: (data: any) => void) : void
    {
        if(ids !== "")
        {
            var result : any[] = [];
            var uoIds : string[] = ids.split("|");
            var defs : Promise<void>[] = [];
            uoIds.forEach((uoId : string) => {
                var def = new Deferred<void>();
                defs.push(def.promise());
                this.customerService.GetContactUoForList(parseInt(uoId))
                    .then((c : IContactUoForList) => {
                        if(!c)
                            return;

                        result.push({ 
                            id : uoId, 
                            contactId : c ? c.ContactId : -1, 
                            text : c ? c.FormattedContactName + (c.Description ? " (" + c.Description + ")" : "") : ProlifeSdk.TextResources.Customers.UONotAccessible, 
                            hidden : !c 
                        });
                    })
                    .finally(() => { def.resolve()});
            });

            Promise.all(defs).then(() => {
                callback(result);
            });
        }
    }
}
