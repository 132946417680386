import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { JobOrdersListReport, JobOrderForReport } from "../JobOrdersListReport";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    INotBilledDdtViewModel,
    ICustomerJobOrdersGroup,
    ICustomerDdtsGroup,
    IEventfulJobOrder,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { INotBilledDdt } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IInvoicesService } from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { ILogEventType } from "../../../../ProlifeSdk/interfaces/desktop/ILogService";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../../Core/Deferred";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

interface EventFilters {
    [type: string]: ko.Observable<boolean>;
}

export class NotBilledDdt implements INotBilledDdtViewModel {
    Number: string;
    Data: Date;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor(private ddt: INotBilledDdt) {
        this.Number = ddt.Number;
        this.Data = ddt.Data;
    }

    public OpenDocument(): Promise<void> {
        return this.documentsService.OpenDocumentOverlay(ProlifeSdk.DdtEntityTypeCode, this.ddt.Id);
    }
}

export class JobOrderMovementsReport extends JobOrdersListReport {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    private invoicesService: IInvoicesService;

    detailsTemplateName = "job-order-movements";
    detailsTemplateUrl = "jobOrder/templates/reports";

    public IncludeNotBillable: ko.Observable<boolean> = ko.observable(false);
    public EventTypes: ko.ObservableArray<ILogEventType> = ko.observableArray([]);
    private SelectedEventFilters: EventFilters = {};
    public Customers: ko.ObservableArray<ICustomerJobOrdersGroup> = ko.observableArray([]);
    public CustomersWithNotBilledDdt: ko.ObservableArray<ICustomerDdtsGroup> = ko.observableArray([]);

    public IsIncludeNotBillableFilterVisible = ko.observable(true);
    public HasNotBilledDdt: ko.Observable<boolean> = ko.observable(false);

    constructor(serviceLocator: IServiceLocator, groupId: number) {
        super(serviceLocator, groupId, 2);
        this.invoicesService = <IInvoicesService>serviceLocator.findService(ProlifeSdk.InvoicesServiceType);
        this.Name = ProlifeSdk.TextResources.JobOrder.MovedOrdersNotBilledDelivery;

        this.logService.GetEventTypes().then((types: ILogEventType[]) => {
            types.forEach((t: ILogEventType) => {
                this.SelectedEventFilters[t.EventType] = ko.observable(false);
            });
            this.EventTypes(
                types.filter((t: ILogEventType) => {
                    return t.Searchable;
                }) || []
            );
        });

        this.IncludeNotBillable.subscribe(this.NotifyFilterChange.bind(this));
        this.HasNotBilledDdt.subscribe(this.NotifyFilterChange.bind(this));
    }

    public onSelectionChanged(selection: INavigationMenuProvider[]) {
        this.NotifyFilterChange();
    }

    private GetSelectedEventFiltersCodes() {
        const eventTypes = this.EventTypes()
            .map((t: ILogEventType) => {
                return t.EventType;
            })
            .filter((t1: string) => {
                return this.SelectedEventFilters[t1]();
            });
        return eventTypes;
    }

    public RefreshReportData(): Promise<void> {
        const def = new Deferred<void>();
        const eventTypes = this.GetSelectedEventFiltersCodes();
        const includeNotBillable = this.IsIncludeNotBillableFilterVisible() ? this.IncludeNotBillable() : true;

        this.CustomersWithNotBilledDdt([]);
        this.Customers([]);

        this.jobOrderService
            .getEventfulJobOrders(
                moment(this.From()).startOf("day").toDate(),
                moment(this.To()).endOf("day").toDate(),
                eventTypes,
                this.ActivitiesFilter.GetSelectedTasksIds(),
                this.StateId(),
                this.TypeId(),
                includeNotBillable,
                this.HasNotBilledDdt()
            )
            .then((results: IEventfulJobOrder[]) => {
                if (this.HasNotBilledDdt()) {
                    this.invoicesService
                        .GetNotBilledDdts(
                            false,
                            moment(this.From()).startOf("day").toDate(),
                            moment(this.To()).endOf("day").toDate()
                        )
                        .then((notBilledDdts: any) => {
                            this.CreateJobOrdersGroups(results);
                            this.CreateNotBilledDdtGroups(notBilledDdts);
                        });
                } else this.CreateJobOrdersGroups(results);
            })
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    private CreateNotBilledDdtGroups(ddts: INotBilledDdt[]) {
        const customers: ICustomerDdtsGroup[] = [];
        const processedCustomers = [];

        ddts.forEach((r: INotBilledDdt) => {
            if (processedCustomers.indexOf(r.CustomerId) != -1) return;

            processedCustomers.push(r.CustomerId);

            const customer: ICustomerDdtsGroup = {
                Name: r.CustomerName,
                Ddts: [],
            };

            customer.Ddts = ddts
                .filter((r1: INotBilledDdt) => {
                    return r1.CustomerId == r.CustomerId;
                })
                .map((ddt: INotBilledDdt) => {
                    return new NotBilledDdt(ddt);
                });

            customers.push(customer);
        });

        this.CustomersWithNotBilledDdt(customers);
    }

    private CreateJobOrdersGroups(jobOrders: IEventfulJobOrder[]) {
        const customers: ICustomerJobOrdersGroup[] = [];
        const processedCustomers = [];

        jobOrders.forEach((r: IEventfulJobOrder) => {
            if (processedCustomers.indexOf(r.CustomerId) != -1) return;

            processedCustomers.push(r.CustomerId);

            const customer: ICustomerJobOrdersGroup = {
                Name: r.CustomerName,
                JobOrders: [],
            };

            customer.JobOrders = jobOrders
                .filter((r1: IEventfulJobOrder) => {
                    return r1.CustomerId == r.CustomerId;
                })
                .map((r2: IEventfulJobOrder) => {
                    return new JobOrderForReport(r2);
                });

            customers.push(customer);
        });

        this.Customers(customers);
    }

    public SetEventFilter(type: ILogEventType): void {
        this.SelectedEventFilters[type.EventType](!this.SelectedEventFilters[type.EventType]());

        const eventTypes = this.GetSelectedEventFiltersCodes();
        const selectedEventsForBusinness = this.EventTypes().filter((et: ILogEventType) => {
            return this.SelectedEventFilters[et.EventType]() && et.ForBusinness;
        });

        //this.IsIncludeNotBillableFilterVisible(selectedEventsForBusinness.length > 0);
        this.NotifyFilterChange();
    }

    initialize() {
        super.initialize();
        this.Refresh();
    }

    public ExportAsPdf(versionId: number) {
        let eventFilters = "";
        let templatesTasksFilters = "";

        this.GetSelectedEventFiltersCodes().forEach((c: string) => {
            if (eventFilters.length > 0) eventFilters += "-";
            eventFilters += c;
        });

        this.ActivitiesFilter.GetSelectedTasksIds().forEach((t: number) => {
            if (templatesTasksFilters.length > 0) templatesTasksFilters += "-";
            templatesTasksFilters += t.toString();
        });

        const url: string =
            "JobOrder/EventfulJobOrdersReportsPrint/GeneratePdf?" +
            this.getQueryString(versionId, eventFilters, templatesTasksFilters);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private getQueryString(versionId: number, eventFilters: string, templatesTasksFilters: string): string {
        return (
            "versionId=" +
            versionId +
            "&from=" +
            encodeURIComponent(moment(this.From()).format()) +
            "&to=" +
            encodeURIComponent(moment(this.To()).format()) +
            "&eventFilters=" +
            eventFilters +
            "&templatesTasksFilters=" +
            templatesTasksFilters +
            "&stateId=" +
            this.StateId() +
            "&typeId=" +
            this.TypeId() +
            "&includeNotBillableEvents=" +
            this.IncludeNotBillable() +
            "&hasNotBilledDdt=" +
            this.HasNotBilledDdt()
        );
    }

    public ExportAsExcel(versionId: number) {
        let eventFilters = "";
        let templatesTasksFilters = "";

        this.GetSelectedEventFiltersCodes().forEach((c: string) => {
            if (eventFilters.length > 0) eventFilters += "-";
            eventFilters += c;
        });

        this.ActivitiesFilter.GetSelectedTasksIds().forEach((t: number) => {
            if (templatesTasksFilters.length > 0) templatesTasksFilters += "-";
            templatesTasksFilters += t.toString();
        });

        const url: string =
            "JobOrder/EventfulJobOrdersReportsPrint/GenerateExcel?" +
            this.getQueryString(versionId, eventFilters, templatesTasksFilters);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }
}
