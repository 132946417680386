import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 07/06/13
 * Time: 17.41
 * To change this template use File | Settings | File Templates.
 */

export class FixedHeaderTable {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        (<any>$(element)).fixedHeaderTable({ themeClass: 'table' });
    }
}

ko.bindingHandlers["fixedHeaderTable"] = new FixedHeaderTable();