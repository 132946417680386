import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

type HtmlProps = {
    html: ko.Subscribable<string> | string;
};

export function Html(props: HtmlProps) {
    const C = require("./Html")._Html as typeof _Html;
    return <C {...props} />;
}

export class _Html {
    static defaultProps: Partial<HtmlProps> = {};

    constructor(private props: HtmlProps) {}

    render() {
        const html = this;

        return ComponentUtils.bindTo(<span data-bind={{ multilineText: html.props.html }}></span>, this, "html");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(Html);
}
