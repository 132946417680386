import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSourceModel } from "./IDataSource";
import { IPaymentTypeCode } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class PaymentTypeCodesDataSource extends BaseDataSource<IDataSourceModel<string, IPaymentTypeCode>> {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    
    public getTitle(currentModel: IDataSourceModel<string, IPaymentTypeCode>): string {
        return "";
    }
    
    public async getData(currentModel: IDataSourceModel<string, IPaymentTypeCode>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<string, IPaymentTypeCode>[]> {
        let codes = await this.documentsService.GetPaymentTypeCodes(textFilter, skip, count);
        return codes.map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<string, IPaymentTypeCode>, ids: string[]): Promise<IDataSourceModel<string, IPaymentTypeCode>[]> {
        let codes = await this.documentsService.GetPaymentTypeCodesByIds(ids);
        return codes.map(this.createModel, this);
    }

    private createModel(code: IPaymentTypeCode): IDataSourceModel<string, IPaymentTypeCode> {
        return {
            id: code.Code,
            title: String.format(TextResources.Invoices.PaymentTypeCodeLabel, code.Code, code.Description),
            isGroup: false,
            isLeaf: true,
            model: code
        };
    }
}