import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import moment = require("moment");
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";

export class JobOrdersAdvancedExportReport implements IReport, IReportViewModel {
    Id: number = 9;
    GroupId : number;
    
    Name: string = "Esportazione Avanzata Commesse";
    templateName : string = "report-list-item";
    templateUrl : string = "reports/templates";
    component: string = "joborder-advanced-export";
    viewModel: any;

    private navigator: IReportsNavigator;

    constructor(groupId : number) {
        this.GroupId = groupId;
        this.viewModel = this;
    }

    initialize() {
        
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator?(navigator: IReportsNavigator): void {
        this.navigator = navigator;
    }
}

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "joborder-advanced-export": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IJobOrderAdvancedExportComponentParams {
    
}

export class JobOrderAdvancedExportComponent {
    Date : ko.Observable<Date> = ko.observable();

    constructor(params : IJobOrderAdvancedExportComponentParams) {
        this.Date(moment().toDate());
    }

    public getExcelExportData() {
        return {
            Date: this.Date()
        }
    }
}

ko.components.register("joborder-advanced-export", {
    viewModel: {
        createViewModel: (params: IJobOrderAdvancedExportComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new JobOrderAdvancedExportComponent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="portlet light bordered">
                    <div class="portlet-title">
                        <div class="caption">
                            <i class="fa fa-file-text-o font-red-sunglo"></i>
                            <span class="caption-subject font-red-sunglo bold uppercase">{TextResources.JobOrder.JobOrderAdvancedExport}</span>
                        </div>
                    </div>
                    <div class="portlet-body form">
                        <form action="#" class="form-horizontal">
                            <div class="form-body">
                                <div class="form-group">
                                    <div class="col-md-offset-3 col-md-9">
                                        {TextResources.JobOrder.JobOrderAdvancedExportFirstHelpTextLine}<br />
                                        {TextResources.JobOrder.JobOrderAdvancedExportSecondHelpTextLine}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-md-3">{TextResources.JobOrder.JobOrderAdvancedExportReferenceDate}</label>
                                    <div class="col-md-4">
                                        <input class="form-control" type="text" data-bind="datePicker: Date" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="row">
                                    <div class="col-md-offset-3 col-md-9">
                                        <excel-exporter exporterId="JobOrder/AdvancedExport" exporterMethod="GenerateExcel" dataProvider={() => "$data.getExcelExportData.bind($data)"} position="right">
                                            <i class="fa fa-download"></i>&nbsp;
                                            Genera Report
                                        </excel-exporter>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});