import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { EventBaseInput } from "./EventBaseInput";
import { _EventClassificationInput } from "./EventClassificationInput";
import { Attachment } from "./Attachment";
import { BlogEvent } from "./BlogEvent";
import { IWorkflowsAndTasksDataSourceModel } from "../../../DataSources/WorkflowsAndTasksDataSource";
import { IDataSource } from "../../../DataSources/IDataSource";
import { IJobOrderService } from "../../interfaces/job-order/IJobOrderService";
import { ITodoListService, ITaskForTaskBoard } from "../../interfaces/todolist/ITodoListService";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../interfaces/desktop/IDesktopService";
import { IFileRepositoryService, IFileUploadResult } from "../../interfaces/files/IFileRepositoryService";
import { ILogEvent } from "../../interfaces/ILogEvent";
import { ITag } from "../../interfaces/ITag";
import { IFileOrFolder } from "../../interfaces/files/IFileOrFolder";
import { IJobOrder } from "../../interfaces/job-order/IJobOrder";
import { INavigationMenuProvider } from "../../interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../Core/Deferred";
import { ContactList } from "../../../Blog/Blog/EventsDetails/SearchService/ContactList";

export class BlogEventBaseInput extends EventBaseInput {
    protected desktopService: IDesktopService;
    protected jobOrderService: IJobOrderService;
    protected toastService: IInfoToastService;
    protected todoListService: ITodoListService;
    protected fileRepositoryService: IFileRepositoryService;

    public classificazione: _EventClassificationInput;
    private attachments: ko.ObservableArray<Attachment> = ko.observableArray([]);
    private selectedAttachments: ko.Computed<Attachment[]>;
    private showAttachmentsAsList: ko.Observable<boolean> = ko.observable(false);
    private attachmentsTemplateName: ko.Computed<string>;
    public subject: ko.Observable<string> = ko.observable("");
    public signers: ContactList;

    private initialTasksSelectionFor: { TasksIds: number[]; JobOrderId: number };

    public NotesAreVisible: ko.Observable<boolean> = ko.observable(false);
    public ExcelSheetsAreVisible: ko.Observable<boolean> = ko.observable(true);

    protected selectedTasks: ITaskForTaskBoard[] = [];
    protected selectedTasksObservable: ko.ObservableArray<any> = ko.observableArray([]);

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.jobOrderService = <IJobOrderService>serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);
        this.signers = new ContactList(ProlifeSdk.Tag_SignatureSigner);
        this.classificazione = new _EventClassificationInput({});
        this.fileRepositoryService = <IFileRepositoryService>(
            serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.toastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.commessaId.subscribe((value) => {
            const jobOrdersIds: number[] = [];
            const jobOrderId = parseInt(value);
            if (!isNaN(jobOrderId)) {
                jobOrdersIds.push(jobOrderId);
                this.contextEventsObserver.NotifyEventJobOrderSelected(jobOrderId);
            } else {
                this.contextEventsObserver.NotifyEventJobOrderSelected(null);
            }

            const tasks =
                this.initialTasksSelectionFor.JobOrderId === jobOrderId ? this.initialTasksSelectionFor.TasksIds : [];
            this.contextEventsObserver.SelectTasks(tasks);
        });

        this.selectedAttachments = ko.computed(() => {
            return this.attachments().filter((a: Attachment) => a.selected());
        });

        this.attachmentsTemplateName = ko.computed(() => {
            return this.showAttachmentsAsList() ? "list" : "icons";
        });

        this.classificazione.isSigned.subscribe(() => {
            if (!this.classificazione.isSigned()) {
                this.classificazione.disableInQualityFlag(this.selectedTasks.length > 0);
            }
        });
    }

    public dispose() {}

    public ShowNotes() {
        this.ExcelSheetsAreVisible(false);
        this.NotesAreVisible(true);
    }

    public ShowExcelSheet() {
        this.ExcelSheetsAreVisible(true);
        this.NotesAreVisible(false);
    }

    getBlogEvent(): ILogEvent {
        const Tags: ITag[] = [];

        const classificazioneTags: ITag[] = this.classificazione.getTags();
        classificazioneTags.forEach((tag) => {
            Tags.push(tag);
        });

        const localBlogEvent: ILogEvent = this.getBaseBlogEvent(null, Tags);
        localBlogEvent.Tags.push({
            TagName: ProlifeSdk.Tag_Subject,
            Value: this.subject(),
            TagTypeId: ProlifeSdk.TagType_String,
        });

        this.signers.getElement().forEach((tag: ITag) => {
            localBlogEvent.Tags.push(tag);
        });

        return localBlogEvent;
    }

    switchAttachmentsView() {
        this.showAttachmentsAsList(!this.showAttachmentsAsList());
    }

    addAttachments() {
        this.prepareJobOrderAttachmentsFolder().then((destinationPath: string) => {
            this.fileRepositoryService
                .openAsDialog(destinationPath || "/")
                .then((selectedFiles: IFileOrFolder[]) => {
                    const alreadyPresentAttachmentsIds = this.attachments().map((a: Attachment) => a.id);
                    const newAttachments = selectedFiles
                        .filter((f: IFileOrFolder) => alreadyPresentAttachmentsIds.indexOf(f.Id) < 0)
                        .map((file: IFileOrFolder) => new Attachment().loadFromFile(file));
                    newAttachments.forEach((a: Attachment) => {
                        this.attachments.push(a);
                    });
                })
                .catch(() => {
                    const error = "";
                });
        });
    }

    public UploadAndAttach(file: File) {
        this.prepareJobOrderAttachmentsFolder().then((destinationPath: string) => {
            const newVersionDef = new Deferred<void>();

            this.fileRepositoryService.browse(destinationPath).then((files: IFileOrFolder[]) => {
                const filesMatches: IFileOrFolder[] = files.filter((f: IFileOrFolder) => {
                    return f.Name.toUpperCase() == file.name.toUpperCase();
                });

                if (filesMatches.length == 0) newVersionDef.resolve();
                else {
                    this.dialogService.Confirm(
                        ProlifeSdk.TextResources.ProlifeSdk.ConfirmAttachmentNewVersion,
                        ProlifeSdk.TextResources.ProlifeSdk.DoNotAttachNewVersion,
                        ProlifeSdk.TextResources.ProlifeSdk.AttachNewVersion,
                        (confirm: boolean) => {
                            if (!confirm) newVersionDef.reject();
                            else newVersionDef.resolve();
                        }
                    );
                }
            });

            newVersionDef.promise().then(() => {
                const uploadDef = this.fileRepositoryService.upload(destinationPath, file, file.name);
                this.desktopService.AddUploadNotification(file.name, uploadDef);
                uploadDef.then((result: IFileUploadResult) => {
                    if (!result.UploadSuccess) {
                        this.toastService.Error(
                            String.format(ProlifeSdk.TextResources.ProlifeSdk.ErrorUploadingAttachments, file.name)
                        );
                        return;
                    }

                    this.fileRepositoryService.getFileDetails(result.FileId).then((fileOrFolder: IFileOrFolder) => {
                        this.attachments.push(new Attachment().loadFromFile(fileOrFolder));
                    });
                });
            });
        });
    }

    private prepareJobOrderAttachmentsFolder(): Promise<string> {
        const pathPreparationDef = new Deferred<string>();

        if (parseInt(this.commessaId() || "-1") <= 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.ProlifeSdk.SelectReferenceJobOrderToUploadAttachments);
            pathPreparationDef.reject();
            return pathPreparationDef.promise();
        }

        const jobOrderId = parseInt(this.commessaId());
        this.jobOrderService.get(jobOrderId).then((jobOrder: IJobOrder) => {
            const blogEventYear = moment(this.eventDate() || new Date()).year();
            const destinationPath: string = String.format(
                ProlifeSdk.TextResources.ProlifeSdk.DefaultAttachmentUploadPathForJobOrder,
                jobOrder.Name || ProlifeSdk.TextResources.ProlifeSdk.NoJobOrder,
                blogEventYear
            );

            //Verifico se la path di destinazione esiste. Se non c'è la creo.
            this.fileRepositoryService.ExistsDirectory(destinationPath).then((exists: boolean) => {
                if (!exists)
                    this.fileRepositoryService
                        .CreateDirectory(destinationPath)
                        .then(() => {
                            pathPreparationDef.resolve(destinationPath);
                        })
                        .catch(() => {
                            pathPreparationDef.reject([]);
                        });
                else pathPreparationDef.resolve(destinationPath);
            });
        });

        return pathPreparationDef.promise();
    }

    public SetAttachments(attachments: IFileOrFolder[]) {
        const newAttachments: Attachment[] = attachments
            ? attachments.map((file: IFileOrFolder) => new Attachment().loadFromFile(file))
            : [];
        this.attachments(newAttachments);
    }

    downloadAttachments() {
        this.selectedAttachments()[0].download();
    }

    removeSelectedAttachments() {
        this.selectedAttachments().forEach((a: Attachment) => {
            this.attachments.remove(a);
        });
    }

    notifyFilterResultIsChanged(filteredLeafs: INavigationMenuProvider[]) {
        //Nulla da fare
    }

    onItemSelected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        this.selectedTasks.push(model.model as ITaskForTaskBoard);

        if (this.selectedTasks.length > 0) this.classificazione.inQuality(true);

        this.classificazione.disableInQualityFlag(this.selectedTasks.length > 0 || this.classificazione.isSigned());

        this.selectedTasksObservable.push({
            Id: model.model.Id,
            Description: model.model.Title,
        });
    }

    onItemDeselected(sender: IDataSource, model: IWorkflowsAndTasksDataSourceModel): void {
        let index = -1;
        for (const task of this.selectedTasks) {
            index++;
            if (task.Id == model.id) {
                this.selectedTasks.splice(index, 1);
                break;
            }
        }

        if (this.selectedTasks.length > 0) this.classificazione.inQuality(true);

        this.classificazione.disableInQualityFlag(this.selectedTasks.length > 0 || this.classificazione.isSigned());

        index = -1;
        for (const task of this.selectedTasksObservable()) {
            index++;
            if (task.Id == model.model.Id) {
                this.selectedTasksObservable.splice(index, 1);
                break;
            }
        }
    }

    public setValues(blogEvent: BlogEvent): void {
        this.initialTasksSelectionFor = {
            TasksIds: <number[]>blogEvent.Tasks() || [],
            JobOrderId: blogEvent.JobOrder(),
        };

        super.setValues(blogEvent);
        this.signers.clear();
        this.signers.addElements(blogEvent.Signers());
        this.classificazione.tags.clear();
        this.classificazione.setValues(
            blogEvent.Priority(),
            blogEvent.State(),
            blogEvent.CustomMetaTags(),
            blogEvent.AllowedUsersTags(),
            blogEvent.IsInQuality(),
            blogEvent.IsAdministration(),
            blogEvent.IsCommercial(),
            blogEvent.IsTechnical(),
            blogEvent.IsSigned(),
            blogEvent.Signature()
        );
        this.attachments(blogEvent.Attachments());
        this.classificazione.disableInQualityFlag(blogEvent.Tasks().length > 0 || blogEvent.IsSigned());
        this.subject(blogEvent.Subject());

        this.selectedTasksObservable(
            this.selectedTasks.map((t) => {
                return {
                    Id: t.Id,
                    Description: t.Title,
                };
            })
        );
    }

    getBaseBlogEvent(eventType: string, Tags: ITag[]): ILogEvent {
        const blogEvent: ILogEvent = super.getBaseBlogEvent(eventType, Tags);

        this.attachments().forEach((a: Attachment) => {
            Tags.push({
                TagName: ProlifeSdk.Tag_File,
                TagTypeId: ProlifeSdk.TagType_DocumentReference,
                Value: a.id,
                DisplayName: a.fileName,
            });
        });

        blogEvent.Tasks = this.selectedTasks.map((t) => t.Id);
        blogEvent.Tasks.forEach((id: number) => {
            Tags.push({
                TagName: ProlifeSdk.Tag_TodoListTask,
                TagTypeId: ProlifeSdk.EventsTodoListTask,
                Value: id,
            });
        });

        blogEvent.Locked = blogEvent.Locked || this.classificazione.isSigned();

        return blogEvent;
    }
}
