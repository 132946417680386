import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { Tag } from "../../../../../ProlifeSdk/prolifesdk/blog/Tag";
import { BlogEventBaseInput } from "../../../../../ProlifeSdk/prolifesdk/blog/BlogEventBaseInput";
import { DocumentEventDetailsEdit } from "../../../../../ProlifeSdk/prolifesdk/documents/events/details/DocumentEventDetailsEdit";
import { DdtEvent } from "../DdtEvent";
import { IContextEventsObserver } from "../../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../../ProlifeSdk/interfaces/ILogEvent";

export class DdtEventDetailsEdit extends DocumentEventDetailsEdit
{
    constructor(serviceLocator : IServiceLocator, contextEventsObserver : IContextEventsObserver, event : DdtEvent)
    {
        super(serviceLocator, contextEventsObserver, event);
        this.iconName = "f-icon-doc-text";
        this.title = ProlifeSdk.TextResources.Invoices.Delivery;
        this.setValues(event);
    }

    public setValues(item : DdtEvent) : void
    {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent
    {
        var event : ILogEvent = super.getBlogEvent();
        event.EventType = ProlifeSdk.InvoiceEventType_Ddt;
        return event;
    }
}
