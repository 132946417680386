import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import {
    ICatalogsService,
    ICatalog,
    ICatalogType,
    IICatalog,
    ISearchedCatalog,
    INewCatalog,
} from "../ProlifeSdk/interfaces/warehouse/ICatalogsService";
import { LazyImport } from "../Core/DependencyInjection";
import { ResponseData } from "../Core/response/ResponseBase";
import { toPascalCase } from "../Core/utils/NamingConventions";

class CatalogsService implements ICatalogsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    constructor(serviceLocator: IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<ICatalogsService>(), this);
    }

    getServiceType(): string {
        return ProlifeSdk.CatalogsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    InsertOrUpdateCatalog(catalog: ICatalogType[] | null): Promise<IICatalog> {
        return this.ajaxService.Post<IICatalog>("Warehouse-api/Catalogs", "InsertOrUpdateCatalog", {
            methodData: {
                catalog: catalog,
            },
        });
    }

    SearchCatalogs(
        textFilter: string | null,
        showOnlyActive: boolean | null,
        skip: number | null,
        count: number | null
    ): Promise<ISearchedCatalog[]> {
        return this.ajaxService.Post<ISearchedCatalog[]>("Warehouse-api/Catalogs", "SearchCatalogs", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showOnlyActive: showOnlyActive,
                skip: skip,
                count: count,
            },
        });
    }

    SearchCatalogsCount(textFilter: string | null, showOnlyActive: boolean | null): Promise<number> {
        return this.ajaxService.Post<number>("Warehouse-api/Catalogs", "SearchCatalogsCount", {
            background: true,
            methodData: {
                textFilter: textFilter,
                showOnlyActive: showOnlyActive,
            },
        });
    }

    SearchCatalogsByManufacturer(manufacturerId: number | null, filter: string | null): Promise<ICatalog[]> {
        return this.ajaxService.Post<ICatalog[]>("Warehouse-api/Catalogs", "SearchCatalogsByManufacturer", {
            background: true,
            methodData: {
                manufacturerId: manufacturerId,
                filter: filter,
            },
        });
    }

    async getCatalogById(catalogId: number): Promise<ICatalog> {
        const response = await this.ajaxServiceNew.Get<ResponseData<INewCatalog>>("w/catalogs", "" + catalogId, {});

        if (!response.succeeded) {
            throw response.errors;
        }

        return toPascalCase(response.data);
    }

    async getCatalogs(
        textFilter: string | null,
        manufacturerId: number | null,
        skip: number | null,
        count: number | null
    ): Promise<ICatalog[]> {
        const response = await this.ajaxServiceNew.Post<ResponseData<ICatalog[]>>("w/catalogs", "", {
            background: true,
            methodData: {
                textFilter: textFilter,
                manufacturerId: manufacturerId,
                skip: skip,
                count: count,
            },
        });

        if (!response.succeeded) {
            throw response.errors;
        }

        return response.data.map((d) => toPascalCase(d));
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new CatalogsService(serviceLocator);
}
