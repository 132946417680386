import * as ko from "knockout";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentDataWizardRow } from "./ImportDocumentDataWizard";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../../../DocumentsService";
import { ICurrenciesSettingsManager } from "../../settings/CurrenciesSettingsManager";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { IBaseRowForDocument, IBaseDocumentForWizard } from "../../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { ISalRowForDocuments, ISalRow } from "../../../../ProlifeSdk/interfaces/sal/ISal";
import { IDocumentCurrencyViewModel, IDocumentCurrency } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { WizardDialogStepBase } from "../../../../ProlifeSdk/prolifesdk/WizardDialogStepBase";

export abstract class DocumentDataWizardStep<T = IDocumentDataWizardRow> extends WizardDialogStepBase<IDocumentDataWizardRow[], IDocumentDataWizardRow[]> {
    @LazyImport(nameof<IDialogsService>())
    protected dialogsService : IDialogsService;

    @LazyImport(nameof<IDocumentsService>())
    protected documentsService : IDocumentsService;

    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService : IInfoToastService;

    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationService : IAuthorizationService;

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    protected currenciesSettingsManager: ICurrenciesSettingsManager;

    CanBeFastForwarded = false;

    Rows : ko.ObservableArray<T> = ko.observableArray();
    ProcessedRows : ko.ObservableArray<IDocumentDataWizardRow> = ko.observableArray();

    DocumentCurrenciesEnabled: ko.Observable<boolean> = ko.observable();
    DocumentCurrency : ko.NotifiableComputed<IDocumentCurrencyViewModel>;
    DocumentCurrencySymbol : ko.NotifiableComputed<string>;

    protected initializationInfo: IWizardInitializationInfo;

    constructor() {
        super();

        this.DocumentCurrency = ko.utils.notifyableComputed(() => {
            return this.initializationInfo?.DocumentCurrenciesInfo.DocumentCurrency();
        });
        
        this.DocumentCurrencySymbol = ko.utils.notifyableComputed(() => {
            return this.initializationInfo?.DocumentCurrenciesInfo.DocumentCurrencySymbol();
        });
    }

    abstract CanShow(initializationInfo : IWizardInitializationInfo) : boolean;
    
    async Initialize(initializationInfo : IWizardInitializationInfo) : Promise<void> {
        this.Rows([]);
        this.DocumentCurrenciesEnabled(this.authorizationService.isAuthorized("Documents_EnableCurrenciesOnDocuments"));
        this.initializationInfo = initializationInfo;
        this.DocumentCurrency.valueHasMutated();
        this.DocumentCurrencySymbol.valueHasMutated();
    }

    ClearImportedRows() { this.Rows([]); }
    
    public GetCurrencyByCode(currencyCode: string): IDocumentCurrencyViewModel {
        return this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrencies().firstOrDefault((c) => c.Currency().CodeISO4217alpha3 === currencyCode);
    }

    public ApplyCurrenciesExchangeValues(row: IBaseRowForDocument | ISalRowForDocuments, sourceDocumentCurrency: IDocumentCurrencyViewModel): boolean {
        const destDocumentCurrency = this.DocumentCurrency();

        const destDocumentCurrencyCode = destDocumentCurrency.Currency().CodeISO4217alpha3;
        const sourceDocumentCurrencyCode = sourceDocumentCurrency.Currency().CodeISO4217alpha3;

        if (destDocumentCurrencyCode === sourceDocumentCurrencyCode)
            return true;

            const sourceToDestDocumentCurrencyExchange = this.GetCurrencyByCode(sourceDocumentCurrencyCode);

        if (!sourceToDestDocumentCurrencyExchange) {
            this.infoToastService.Warning(String.format(TextResources.Invoices.CurrencyExchangeNotConfigured, sourceDocumentCurrencyCode));
            return false;
        }

        row.Rows.forEach((r: IDocumentRow | ISalRow) => {
            const dRow = r as IDocumentRow // Nel caso di documenti diversi dal Sal
            if (dRow.Importo !== undefined) {
                const priceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.PriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                const netUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                const totalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(dRow.TotalPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
                
                dRow.Importo = CurrencyUtils.applyCurrencyExchange(priceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                dRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(netUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                dRow.TotaleRiga = CurrencyUtils.applyCurrencyExchange(totalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
                return;
            }

            const sRow = r as ISalRow; // Nel caso di sal
            const priceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.UnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
            const netUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento
            const totalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(CurrencyUtils.applyCurrencyExchange(sRow.NetPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange), destDocumentCurrency); // Converto in valuta documento

            sRow.UnitPrice = CurrencyUtils.applyCurrencyExchange(priceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(netUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetPrice = CurrencyUtils.applyCurrencyExchange(totalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        });

        row.PriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento
        row.NetUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento
        row.TotalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, sourceToDestDocumentCurrencyExchange); // Converto in valuta documento

        const dRow = row as IBaseRowForDocument;
        if (dRow.Importo !== undefined) {
            dRow.Importo = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            dRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            dRow.TotaleRiga = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        } else {
            const sRow = row as ISalRowForDocuments;
            sRow.UnitPrice = CurrencyUtils.applyCurrencyExchange(row.PriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.NetUnitPrice = CurrencyUtils.applyCurrencyExchange(row.NetUnitPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
            sRow.Total = CurrencyUtils.applyCurrencyExchange(row.TotalPriceInDocumentCurrency, destDocumentCurrency); // Converto in Euro
        }

        return true;
    }

    protected async ManageCurrenciesForImportedDocuments(documents: IBaseDocumentForWizard[]): Promise<void> {
        const currenciesMap = {};
        documents
            .filter((d) => d.Rows().filter(r => r.Selected()).length > 0)
            .forEach((fd) => currenciesMap[fd.CurrencyCode()] = fd.CurrencyCode());

        const documentCurrenciesInfo = this.initializationInfo.DocumentCurrenciesInfo;
        const configuredCurrencies = documentCurrenciesInfo.DocumentCurrencies();
        let missingCurrenciesCounter = 0;
        for (const currencyCode in currenciesMap) {
            if (configuredCurrencies.filter((c) => c.Currency().CodeISO4217alpha3 === currencyCode).length === 0) {
                const documentCurrency = this.createDocumentCurrencyViewModel(currencyCode);
                documentCurrenciesInfo.DocumentCurrencies.push(documentCurrency);
                missingCurrenciesCounter++;
            }
        }

        if (missingCurrenciesCounter === 0)
            return;

        await this.dialogsService.ShowModalComponent({
            componentName: "document-currencies-manager",
            model: {
                DocumentCurrencies: documentCurrenciesInfo.DocumentCurrencies,
                ReadOnly: documentCurrenciesInfo.ReadOnly,
                CurrenciesEditor: ko.observable(),

                close: function() {
                    const editor = this.CurrenciesEditor();

                    if (!editor)
                        return;

                    if (editor.validate())
                        this.modal.close(null);
                }
            },
            title: TextResources.Invoices.CurrenciesLabel,
            params: "DocumentCurrencies: DocumentCurrencies, ReadOnly: ReadOnly, InjectTo: CurrenciesEditor"
        }, undefined, { saveText: TextResources.ProlifeSdk.Apply, noPrompt: true });
    }

    private createDocumentCurrencyViewModel(currencyCode: string): IDocumentCurrencyViewModel {
        const model = this.createDocumentCurrencyModel(currencyCode);
        return this.documentsService.DocumentCurrenciesFactory.createFromModel(model);
    }

    private createDocumentCurrencyModel(currencyCode: string): IDocumentCurrency {
        const currencyModel = this.documentsService.DocumentCurrenciesFactory.createModel(null, this.initializationInfo.DocTypeCode);
        currencyModel.ExchangeValue = null;
        currencyModel.DocumentCurrency = false;

        const currency = this.currenciesSettingsManager.getCurrencyByCode(currencyCode);
        currencyModel.CurrencyId = currency.Id;

        return currencyModel;
    }
}