import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 08/03/13
 * Time: 15.12
 * To change this template use File | Settings | File Templates.
 */
const css1 = require("./Select2/select2-overrides.scss");

export class Select2 {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: () => any,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const obj = valueAccessor(),
            allBindings = allBindingsAccessor(),
            lookupKey = allBindings.lookupKey,
            selectId = allBindings.selectId,
            selectText = allBindings.selectText,
            formatText = allBindings.formatText,
            onChange = allBindings.onChange,
            onSelecting = obj.onSelecting,
            onClearing = obj.onClearing,
            onClear = obj.onClear,
            onSelectionChange = obj.onSelectionChange,
            setValueFromField: ko.Observable<any> = allBindings.select2SetValueFromField,
            hasFocus = allBindings.hasFocus;

        if (obj.query && ko.isObservable(obj.query)) {
            const observable = obj.query;
            obj.query = function (query) {
                const data = { results: [] };
                const values: any[] = ko.utils.unwrapObservable(observable);
                data.results = values
                    .filter((item: any) => {
                        for (let i = 0; i < selectText.length; i++) {
                            const value = item[selectText[i]];
                            if (value.toLowerCase().indexOf(query.term.toLowerCase()) != -1) return true;
                        }
                        return false;
                    })
                    .map((item: any) => {
                        return {
                            id: item[selectId],
                            text: formatText(item),
                        };
                    });
                query.callback(data);
            };
            obj.initSelection = function (element, callback) {
                const id = $(element).val();
                if (id !== "") {
                    const values: any[] = ko.utils.unwrapObservable(observable);
                    const data = values
                        .filter((item: any) => item[selectId] === id)
                        .map((item: any) => {
                            return {
                                id: item[selectId],
                                text: formatText(item),
                            };
                        });
                    callback(data[0]);
                }
            };
        }

        obj.separator = obj.separator || "|";
        obj.allowClear = ko.utils.unwrapObservable(obj.allowClear);
        const placeholder = ko.utils.unwrapObservable(obj.placeholder);
        obj.placeholder = obj.allowClear && !placeholder ? "Seleziona..." : placeholder;
        obj.multiple = ko.utils.unwrapObservable(obj.multiple);

        (<any>$(element)).select2(obj);

        if (lookupKey) {
            const value = ko.utils.unwrapObservable(allBindings.value);
            (<any>$(element)).select2(
                "data",
                ko.utils.arrayFirst(obj.data.results, function (item) {
                    return item[lookupKey] === value;
                })
            );
        }

        if (onSelecting) {
            //TODO: Verificare il d.ts per il tipo dell'evento
            $(element).on("select2-selecting", (e: any) => {
                e.preventDefault();
                if ((e.val === null || e.val === undefined) && !e.choice) {
                    const selection = $(element).select2("data");
                    const item = Array.isArray(selection) ? selection[0] : selection;
                    onSelecting(item);
                    $(element).select2("close");
                    return;
                }
                onSelecting(e.choice.model);
                $(element).select2("close");
            });

            $(element).on("change", (e: any) => {
                const selection = $(element).select2("data");
                const item = Array.isArray(selection) ? selection[0] : selection;

                if (!e.val && !e.added && !e.removed) {
                    onSelectionChange(item);
                    return;
                }

                const newItem = !e.added ? null : e.added.model;
                if (!newItem || newItem.id != item.id) onSelectionChange(newItem);
            });
        }

        if (onClearing) {
            let clearing = false;
            $(element).on("select2-clearing", (e) => {
                if (clearing) return;

                clearing = true;
                e.preventDefault();
                const selection = $(element).select2("data");
                const item = Array.isArray(selection) ? selection[0] : selection;

                const onClearingResult = onClearing(item);
                if (onClearingResult instanceof Promise) {
                    onClearingResult
                        .then((canClear: boolean) => {
                            if (canClear) {
                                ($(element) as any).select2("val", "", true);

                                if (onClear) onClear();
                            }

                            clearing = false;
                        })
                        .catch((e) => {
                            console.log(e);
                            clearing = false;
                        });
                } else {
                    if (onClearingResult) {
                        ($(element) as any).select2("val", "");
                        if (onClear) onClear();
                    }

                    clearing = false;
                }
            });
        }

        if (onChange) {
            /*(<any>$(element)).on('selected', (e : Event) => {
                onChange(e, (<any>$(element)).select2('data'));
            });*/
            (<any>$(element)).on("change", (e: any) => {
                if (!e.val && !e.added && !e.removed) {
                    const selection = $(element).select2("data");
                    const item = Array.isArray(selection) ? selection[0] : selection;
                    onChange(e, item);
                    return;
                }
                onChange(e, !e.added ? null : e.added);
            });
        }

        if (allBindings.value && obj.value) {
            alert("Binding handlers incompatibili: utilizzare value oppure Select2.value");
        }

        let objValueSubscription: ko.Subscription;

        if (obj.value) {
            const observableValue = ko.utils.unwrapObservable(obj.value);

            (<any>$(element)).select2(
                "val",
                observableValue == null || observableValue == undefined ? "" : observableValue
            );
            //(<any>$(element)).trigger("change");

            objValueSubscription = obj.value.subscribe((value) => {
                (<any>$(element)).select2("val", value == null || value == undefined ? "" : value);
                //(<any>$(element)).trigger("change");
            });

            $(element).on("change", function (e: any) {
                if (this.value == "") obj.value(undefined);
                else obj.value(this.value);
            });
        }

        //Serve per impostare un valore sul campo a partire da una variabile trigger
        let setValueFromFieldSubscription: ko.Subscription = null;

        if (setValueFromField) {
            if (obj.multiple) {
                setValueFromFieldSubscription = setValueFromField.subscribe((value) => {
                    (<any>$(element)).trigger("change");
                });
            } else {
                setValueFromFieldSubscription = setValueFromField.subscribe((value) => {
                    if (obj.allowClear || (value !== null && value !== undefined && value !== "")) {
                        ($(element) as any).select2("val", value == null || value == undefined ? "" : value, true);
                        /*(<any>$(element)).val((value == null || value == undefined) ? '' : value);
                        (<any>$(element)).trigger("change");*/
                    }
                });
            }
        }

        let hasFocusSubscription: ko.Subscription = null;

        if (hasFocus) {
            const hasFocusValue = ko.utils.unwrapObservable(hasFocus);
            if (hasFocusValue) Select2.setFocus(element);

            if (ko.isSubscribable(hasFocus)) {
                hasFocusSubscription = hasFocus.subscribe((focus: boolean) => {
                    if (focus) Select2.setFocus(element);
                });
            }
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            if (setValueFromFieldSubscription) setValueFromFieldSubscription.dispose();

            if (objValueSubscription) objValueSubscription.dispose();

            if (hasFocusSubscription) hasFocusSubscription.dispose();

            (<any>$(element)).select2("destroy");
        });
    }

    /*update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        $(element).trigger('change');
    }*/

    private static setFocus(element: HTMLElement): void {
        const jq = $(element);

        if ((<any>jq).select2("isFocused")) return;

        const elem = jq.parent().find(".select2-focusser");
        elem.trigger("focus");
    }
}

ko.bindingHandlers["select2"] = new Select2();
