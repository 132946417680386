import * as ko from "knockout";
/**
 * Created by d.collantoni on 28/03/2018.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { IDialog } from "../../../Core/interfaces/IDialogsService";

export class DateFilterDialog implements IDialog
{
    templateName: string = "date-filter-dialog";
    templateUrl: string = "invoices/templates";
    title: string = ProlifeSdk.TextResources.Invoices.AdvancedFilter;

    StartDate : ko.Observable<Date> = ko.observable();
    EndDate : ko.Observable<Date> = ko.observable();
    SearchOnDocumentsContent: ko.Observable<boolean> = ko.observable();

    modal: {
        close: (result?: any) => void;
    };

    constructor() {
        this.StartDate.subscribe(() => {
            if(this.StartDate() && this.EndDate() && moment(this.StartDate()) > moment(this.EndDate())) {
                this.StartDate(this.EndDate());
            }
        });

        this.EndDate.subscribe(() => {
            if(this.StartDate() && this.EndDate() && moment(this.EndDate()) < moment(this.StartDate())) {
                this.EndDate(this.StartDate());
            }
        })
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        this.modal.close({
            StartDate: this.StartDate(),
            EndDate: this.EndDate(),
            SearchOnDocumentsContent: this.SearchOnDocumentsContent()
        });
    }

}