import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { classNames, ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ReportComponentDataSourceProps } from "../../Components/ReportComponentWithDataSource";
import { Layout } from "../../../Layouts";
import { TextInput } from "../../../TextInput";
import { ReportDataSourceInputViewer } from "./ReportDataSourceInputViewer";
import { IDataSourceColumn, IDataSourceDefinition, IDataSourceInput, IDataSourcesService } from "../../../../Desktop/DataSourcesService";
import { ReportDataSourcesViewer } from "./ReportDataSourcesViewer";
import { With } from "../../../IfIfNotWith";
import { ReportDataSourceColumnsViewer } from "./ReportDataSourceColumnsViewer";
import { ReportDataSourceBinding } from "./ReportDataSourceBinding";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

const styleSheet = jss.createStyleSheet({
    dataSourceEditingDialog: {
        "&.modal.fullscreen > .modal-dialog > .modal-content > .modal-body.default-overflow": {
            overflow: 'unset'
        }
    }
});
const { classes } = styleSheet.attach();

type DataSourceEditingDialogProps = {
    inputDefinition: IDataSourceInput[];
}

export type DataSourceConfiguration = {
    id: number;
    name: string;
    dataSourceId: string;
    mappings: {
        column: IDataSourceColumn; 
        inputParameter: IDataSourceInput;
    }[];
}

export class DataSourceEditingDialog extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    private ui: _DataSourceEditingDialogUI;

    constructor(private options: DataSourceEditingDialogProps) {
        super({ className: classNames("fullscreen", classes.dataSourceEditingDialog), bodyClassName: "default-overflow" });
        this.title("Fonte Dati");
    }

    show(): Promise<DataSourceConfiguration> {
        return this.dialogsService.ShowModal(this);
    }

    action() {
        this.ui.onAction()
            .then((value: DataSourceConfiguration) => value && this.modal.close(value))
    }

    renderBody() {
        return <DataSourceEditingDialogUI {...this.options} forwardRef={ui => this.ui = ui} />;
    }
}

export function DataSourceEditingDialogUI(props: DataSourceEditingDialogProps & { forwardRef: (ui: _DataSourceEditingDialogUI) => void; }) {
    const C = require("./DataSourceEditingDialog")._DataSourceEditingDialogUI as typeof _DataSourceEditingDialogUI;
    return <C {...props} />;
}

export class _DataSourceEditingDialogUI {
    static defaultProps: Partial<DataSourceEditingDialogProps> = {
    }

    Name : ko.Observable<string> = ko.observable();
    selectedDataSource : ko.Observable<IDataSourceDefinition> = ko.observable();
    Preview : ko.Observable<any> = ko.observable();
    testData : ko.Observable<any> = ko.observable();
    expertMode : ko.Observable<boolean> = ko.observable();

    private associations : { column: IDataSourceColumn; inputParameter: IDataSourceInput; }[] = [];

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    constructor(private props : DataSourceEditingDialogProps & { forwardRef: (ui: _DataSourceEditingDialogUI) => void; }) {
        if(this.props.forwardRef)
            this.props.forwardRef(this);
    }

    async loadFromDataSource() {
        const inputWithValue = {
            ...this.props.inputDefinition[0],
            Value: 3317
        };

        //let result = await this.dataSourcesService.getRow(this.selectedDataSource(), inputWithValue);
        //this.testData(result);
    }

    onColumnAssociated(column: IDataSourceColumn, inputParameter: IDataSourceInput) {
        this.associations.push({ column, inputParameter });
    }

    onDataSourceSelected(ds: IDataSourceDefinition): void {
        this.selectedDataSource(ds);
        this.associations = [];
    }

    async onAction() : Promise<DataSourceConfiguration> {
        if(this.associations.length === 0) {
            this.infoToastService.Warning("Associare almeno un parametro di ingresso ad una colonna di una fonte dati");
            return null;
        }

        if(!this.Name() || this.Name().trim().length === 0) {
            this.infoToastService.Warning("E' necessario specificare il nome della fonte dati");
            return null;
        }

        return {
            id: -1,
            name: this.Name().trim(),
            dataSourceId: this.selectedDataSource().Name,
            mappings: this.associations.slice()
        };
    }
    
    render() {
        return  ComponentUtils.bindTo(
                <Layout.Grid noOverflow={false} columns={["1fr", "2fr"]} rows={["1fr", "1fr"]} style={{ position: 'absolute', inset: '15px' }}>
                    <Layout.Grid.Cell noOverflow={false} column={1} row={1} style={{ padding: '15px', flexDirection: 'column' }}>
                        <TextInput label="Nome" value={this.Name} />
                        <ReportDataSourceInputViewer inputDefinition={this.props.inputDefinition} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell noOverflow={false} column={1} row={2} style={{ padding: '15px' }}>
                        <ReportDataSourcesViewer inputDefinition={this.props.inputDefinition} onDataSourceSelected={(ds) => this.onDataSourceSelected(ds)} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell noOverflow={false} column={2} row={"1/3"} style={{ padding: '15px', flexDirection: 'column' }}>
                        <ReportDataSourceColumnsViewer dataSource={this.selectedDataSource} onColumnAssociated={(c, ip) => this.onColumnAssociated(c, ip)} />
                    </Layout.Grid.Cell>
                </Layout.Grid>, this, "vm")
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DataSourceEditingDialogUI);
}