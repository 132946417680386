import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IWorkflowsDataSourceModel, WorkflowsDataSource } from "../../../../DataSources/WorkflowsDataSource";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    ProcessedRow,
    IDocumentDataWizardRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentBuilderDocumentOriginatingRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IWorkedHoursService,
    ISearchRequest,
    IExtendedWorkSheetRow,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IWorkedHoursForDocuments } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursForDocuments";
import { IFiltersEngine } from "../../../../Core/interfaces/IFiltersEngine";
import { ICustomersService } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IJobOrderGeneralSettingsManager } from "../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IJobOrder, IJobOrderCallRightTypesPrice } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ICustomer, ICustomerRolePrice } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { ISearchTagFilter } from "../../../../ProlifeSdk/interfaces/ISearchTagFilter";
import { IJobOrderRolesPrices } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";
import { IEntitySearchResult } from "../../../../ProlifeSdk/interfaces/IEntitySearchResult";
import { ILogTagFilter, ILogFilter } from "../../../../ProlifeSdk/interfaces/ILogFilter";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { Deferred } from "../../../../Core/Deferred";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import {
    IWorkflowsAndTasksDataSourceModel,
    WorkflowsAndTasksDataSource,
} from "../../../../DataSources/WorkflowsAndTasksDataSource";
import { NavigationMenu } from "../../../../Components/NavigationMenu";
import { Layout } from "../../../../Components/Layouts";
import { IDataSource, IDataSourceListener } from "../../../../DataSources/IDataSource";
import {
    INavigationMenuComponent,
    INavigationMenuComponentAction,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { SegmentedInput } from "../../../../Components/SegmentedInput";
import { AlertsLegendUI } from "../../../../Components/AlertsLegend";
import { HoursApprovalState } from "../../../../WorkedHours/workedhours/enums/HoursApprovalState";

const { classes } = jss
    .createStyleSheet({
        "worked-hours-data-source": {
            "& .grouping-mode": {
                margin: 0,
            },

            "& .actions": {
                alignSelf: "end",
                "& button:not(last-child)": {
                    marginRight: "5px",
                },
            },

            "& .right-actions": {
                height: "100%",
                alignItems: "end",

                "& .already-imported": {
                    display: "inline-block",
                    marginBottom: "8px",
                },
            },

            "& .menu-wrapper": {
                "& .page-sidebar": {
                    position: "absolute !important",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    margin: 0,

                    "& .page-quick-sidebar-wrapper": {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },

            "&.standard-layout": {
                "& .menu-wrapper": {
                    display: "none !important",
                },

                "& .data-source-content": {
                    gridColumn: "1 / 3 !important",
                },
            },
        },
    })
    .attach();

type WizardLayout = "standard-layout" | "menu-layout";
type GroupingMode = "NOTHING" | "WORKFLOW" | "ACTIVITY";
type ActivitiesGroupingMode = { id: GroupingMode; label: string };

export class WorkedHoursDataSource
    extends DocumentDataWizardStep<IWorkedHoursForDocuments>
    implements IDataSourceListener
{
    ShowAlreadyImported: ko.Observable<boolean> = ko.observable(false);
    ActivitiesGroupingMode: ko.Observable<GroupingMode> = ko.observable("NOTHING");

    WorkedHours: ko.ObservableArray<WorkedHoursRow> = ko.observableArray();
    RowsToImport: ko.ObservableArray<WorkedHoursRow> = ko.observableArray();
    SearchFilters: ko.Observable<string> = ko.observable();
    SelectedLayout: ko.Observable<WizardLayout> = ko.observable("standard-layout");

    UseCosts: ko.Observable<boolean> = ko.observable(false);

    StandardLayoutEnabled: ko.Computed<boolean>;
    SelectAll: ko.Computed<boolean>;
    Total: ko.Computed<number>;

    @LazyImport(nameof<IFiltersEngine>())
    private filtersEngine: IFiltersEngine;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IEntityProviderService>())
    private entitiesProviderService: IEntityProviderService;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private settingsManager: IJobOrderGeneralSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesManager: ICallRightTypesSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersManager: IUserCharactersSettingsManager;

    private jobOrder: IJobOrder;
    private customer: ICustomer;

    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();
    public WorkflowId: ko.Observable<number> = ko.observable();
    public SelectedTasks: number[] = [];
    public IsApproved: ko.Observable<boolean> = ko.observable();
    public WarningMessages: ko.Observable<string> = ko.observable();

    WorkflowsDataSource: WorkflowsDataSource = new WorkflowsDataSource();
    WorkflowsAndTasksDataSource: WorkflowsAndTasksDataSource = new WorkflowsAndTasksDataSource();

    private referenceDate: Date;
    private previousStepRows: IDocumentDataWizardRow[];

    private navigationMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    private activitiesGroupingModes: ActivitiesGroupingMode[] = [
        { id: "NOTHING", label: TextResources.Invoices.GroupingModeNothing },
        { id: "WORKFLOW", label: TextResources.Invoices.GroupingModeWorkflow },
        { id: "ACTIVITY", label: TextResources.Invoices.GroupingModeActivity },
    ];

    constructor() {
        super();
        this.Title(TextResources.Desktop.ReportedHours);

        this.SelectAll = ko.computed({
            read: () => {
                const workedHours = this.WorkedHours();
                return workedHours.filter((r: WorkedHoursRow) => !r.Selected()).length == 0;
            },
            write: (selected: boolean) => {
                const workedHours = this.WorkedHours();
                workedHours.forEach((r: WorkedHoursRow) => r.Selected(selected));
            },
        });

        this.StandardLayoutEnabled = ko.computed(() => {
            return this.SelectedLayout() === "standard-layout";
        });

        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.InvoiceEntityTypeCode,
            ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
            ProlifeSdk.CreditNoteEntityTypeCode,
            ProlifeSdk.SalEntityTypeCode,
            ProlifeSdk.DdtEntityTypeCode,
            ProlifeSdk.CustomerOrderEntityTypeCode,
            ProlifeSdk.WarehouseLoadEntityTypeCode
        );

        this.navigationMenu.subscribe((menu) => {
            this.configureMenu(menu);
        });

        this.StandardLayoutEnabled.subscribe((value) => {
            if (value) this.SelectedTasks = [];
            else this.WorkflowId(null);

            this.loadWorkedHours();
        });
    }

    public CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return (
            initializationInfo.JobOrderId > 0 &&
            (initializationInfo.DocTypeCode !== ProlifeSdk.WarehouseLoadEntityTypeCode ||
                initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol)
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        const sign =
            this.mustUseCosts() && this.initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol
                ? -1
                : 1;

        const newRows = this.Rows().map((r) => ({
            Row: new ProcessedRow(
                {
                    Id: 0,
                    AmountFormula: r.AmountFormula,
                    Amount: r.Amount * sign,
                    ClosedAmount: 0,
                    EntityType: this.initializationInfo.DocTypeCode,
                    FKCurrency: documentCurrency.CurrencyId(),
                    Currency: documentCurrency.Currency().Symbol,
                    FKDocument: 0,
                    ManuallyClosed: false,
                    NetUnitPriceInDocumentCurrency: r.NetUnitPrice,
                    NetUnitPrice: r.NetUnitPrice.ToEuro(documentCurrency),
                    Order: 0,
                    TotalPriceInDocumentCurrency: r.NetPrice * sign,
                    TotalPrice: r.NetPrice.ToEuro(documentCurrency) * sign,
                    UnitPriceInDocumentCurrency: r.NetUnitPrice,
                    UnitPrice: r.NetUnitPrice.ToEuro(documentCurrency),
                    Description: r.Description,
                    Discounts: null,
                },
                documentCurrency
            ),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: [],
        }));

        return this.previousStepRows.concat(newRows);
    }

    configureMenu(navigator: INavigationMenuComponent) {
        const showingSecondaryActions = ko.observable(false);
        navigator.enableSelectAllChildren();

        const showLegendAction: INavigationMenuComponentAction = {
            isGroup: false,
            isSeparator: false,
            icon: "fa fa-info-circle",
            text: "",
            title: TextResources.ProlifeSdk.AlertsLegendTitle,
            activeClass: "",
            defaultClass: "btn-transparent",
            active: () => false,
            canExecute: () => true,
            action: () => {
                const component = new AlertsLegendUI();
                component.show();
            },
        };

        navigator.registerAction(showLegendAction);

        const action = {
            icon: "fa fa-plus",
            isGroup: true,
            isSeparator: false,
            actions: [
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-plus",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-copy",
                    text: ProlifeSdk.TextResources.Todolist.NewWorkflowFromWorkflow,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFromWorkflowDialog(this.initializationInfo.JobOrderId);
                    },
                },
                {
                    isGroup: false,
                    isSeparator: false,
                    icon: "fa fa-magic",
                    text: ProlifeSdk.TextResources.Todolist.CreateFromModel,
                    visible: () => true,
                    canExecute: () => this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate"),
                    action: () => {
                        this.todoListService.ShowCreateWorkflowFormTemplateDialog(this.initializationInfo.JobOrderId);
                    },
                },
            ],
        };

        if (
            this.authorizationsService.isAuthorized("TaskBoard_InsertWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CloneWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_CreateWorkflowFromTemplate")
        )
            navigator.registerAction(action);

        if (
            this.authorizationsService.isAuthorized("TaskBoard_EditWorkflow") ||
            this.authorizationsService.isAuthorized("TaskBoard_EditTask")
        ) {
            const startEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-primary",
                visible: () => !showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(true);
                    navigator.showSecondaryAction(true);
                },
            };

            const endEditAction = {
                icon: "fa fa-pencil",
                isGroup: false,
                isSeparator: false,
                defaultClass: "btn-warning",
                visible: () => showingSecondaryActions(),
                canExecute: () => true,
                action: () => {
                    showingSecondaryActions(false);
                    navigator.showSecondaryAction(false);
                },
            };

            navigator.registerAction(startEditAction);
            navigator.registerAction(endEditAction);
        }

        navigator.registerSearchAction({
            text: "Cerca tutto",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(true),
            icon: "",
            isGroup: false,
            isSeparator: false,
            isDefault: true,
        });

        navigator.registerSearchAction({
            text: "Cerca solo in questa lista",
            action: () => this.WorkflowsAndTasksDataSource.setRecursiveSearch(false),
            icon: "",
            isGroup: false,
            isSeparator: false,
        });

        navigator.setMultipleSelection({
            keepSelection: true,
            multipleSelection: true,
            selectLeafsOnly: true,
        });

        this.WorkflowsAndTasksDataSource.setRecursiveSearch(true);
    }

    private getReferences(r: IWorkedHoursForDocuments): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        for (const wh of r.WorkedHours.filter((r) => r.Hours != 0)) {
            refs.push({
                RefId: 0,
                DocumentId: 0,
                DestEntityKeyId: 0,
                DestEntityType: this.initializationInfo.DocTypeCode,
                Amount: wh.Hours,
                UnitPrice: r.NetUnitPrice,
                NetUnitPrice: r.NetUnitPrice,
                NetPrice: r.NetPrice,
                SourceEntityKeyId: wh.HoursId,
                SourceEntityType: ProlifeSdk.WorkedHoursEntityTypeCode,
                TaskId: wh.TaskId,
            });
        }
        return refs;
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo): Promise<void> {
        await super.Initialize(initializationInfo);
        this.referenceDate = initializationInfo.DocumentDate || new Date();
        this.DateFrom(moment(this.referenceDate).startOf("month").toDate());
        this.DateTo(this.referenceDate);
        this.UseCosts(this.mustUseCosts());

        this.WorkflowsDataSource.setJobOrders(initializationInfo.JobOrderId);
        this.WorkflowsAndTasksDataSource.setJobOrders([initializationInfo.JobOrderId]);

        try {
            this.jobOrder = await this.jobOrderService.get(initializationInfo.JobOrderId);
        } catch {
            this.jobOrder = {
                JobOrderId: -1,
                Name: TextResources.Invoices.NoJobOrder,
                RolesPrices: [],
                CustomRoles: false,
                CustomMaterialsRoles: false,
                CallRightTypesPrices: [],
            } as unknown as IJobOrder;
        }

        this.installWatches();
        this.loadWorkedHours();

        if (this.jobOrder?.CustomerId) {
            try {
                this.customer = await this.customersService.getCustomer(this.jobOrder?.CustomerId);
            } catch {
                this.customer = {
                    IdCliente: -1,
                    FormattedContactName: TextResources.Invoices.NoCustomer,
                    RolesPrices: [],
                    CustomRoles: false,
                    CustomMaterialsRoles: false,
                } as unknown as ICustomer;
            }
        }

        this.IsApproved(this.settingsManager.getSetFlagIsApprovedWorkedHours());
    }

    public onItemSelected(
        sender: WorkflowsAndTasksDataSource | WorkflowsDataSource,
        model: IWorkflowsAndTasksDataSourceModel | IWorkflowsDataSourceModel
    ): void {
        if (sender === this.WorkflowsDataSource) {
            this.WorkflowId(model?.id);
        } else {
            this.SelectedTasks.push(model.id);
        }

        this.loadWorkedHours();
    }

    public onItemDeselected(
        sender: WorkflowsAndTasksDataSource | WorkflowsDataSource,
        model: IWorkflowsAndTasksDataSourceModel | IWorkflowsDataSourceModel
    ): void {
        if (sender === this.WorkflowsDataSource) {
            this.WorkflowId(null);
        } else {
            this.SelectedTasks.remove(model.id);
        }

        this.loadWorkedHours();
    }

    public onSelect2Cleared(sender: IDataSource): void {
        this.WorkflowId(null);
        this.loadWorkedHours();
    }

    private installWatches() {
        this.DateFrom.subscribe(this.loadWorkedHours.bind(this));
        this.DateTo.subscribe(() => {
            if (this.DateTo() > this.referenceDate) {
                this.WarningMessages(
                    String.format(ProlifeSdk.TextResources.Desktop.WatchWarning, moment(this.referenceDate).format("L"))
                );
            } else {
                this.WarningMessages(undefined);
            }
            this.loadWorkedHours();
        });
        this.ShowAlreadyImported.subscribe(this.loadWorkedHours.bind(this));
        this.SearchFilters.subscribe(this.loadWorkedHours.bind(this));
        this.IsApproved.subscribe(this.loadWorkedHours.bind(this));
    }

    private async loadWorkedHours(): Promise<void> {
        const tagsFilters: ISearchTagFilter[] = this.prepareTagsList();

        const request: ISearchRequest = {
            JobOrderId: this.initializationInfo.JobOrderId,
            JobOrderType: null,
            CustomerId: null,
            From: this.DateFrom(),
            To: this.DateTo(),
            Billable: this.mustUseCosts() ? null : true,
            ShowBilled: this.mustUseCosts() ? true : this.ShowAlreadyImported(),
            IgnoreRowsWithoutCustomer: false,
            WorkflowId: this.WorkflowId(),
            TaskId: null,
            SelectedTasks: this.SelectedTasks,
            Roles: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "UserRole"),
            HumanResources: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "HumanResources"),
            ResourcesGroups: this.filtersEngine.ExtractIdListFromTags(tagsFilters, "ResourcesGroups"),
            Skip: null,
            Count: null,
            ApprovalState: !this.IsApproved() ? null : HoursApprovalState.Approved,
        };

        try {
            const wh = await this.workedHoursService.Search(request);
            this.onWorkedHoursLoaded(wh);
        } catch (e) {
            console.error(e);
        }
    }

    private prepareTagsList(): ISearchTagFilter[] {
        if (!this.SearchFilters()) return [];

        const filters: ISearchTagFilter[] = [];
        const tags: string[] = this.SearchFilters().split("|");

        tags.forEach((t: string) => {
            JSON.parse(t).Tags.forEach((tag: ISearchTagFilter) => filters.push(tag));
        });

        return filters;
    }

    private mustUseCosts(): boolean {
        return (
            [ProlifeSdk.SupplierOrderEntityTypeCode, ProlifeSdk.WarehouseLoadEntityTypeCode].indexOf(
                this.initializationInfo.DocTypeCode
            ) >= 0
        );
    }

    private wasUsedDuringThisWizard(wh: IExtendedWorkSheetRow): boolean {
        return (
            this.Rows().filter((r: IWorkedHoursForDocuments) =>
                r.WorkedHours.firstOrDefault((x) => x.HoursId == wh.HoursId)
            ).length > 0 ||
            this.initializationInfo.DocumentOriginatingRows.filter(
                (ref) =>
                    ref.SourceEntityKeyId == wh.HoursId && ref.SourceEntityType == ProlifeSdk.WorkedHoursEntityTypeCode
            ).length > 0
        );
    }

    private onWorkedHoursLoaded(workedHours: IExtendedWorkSheetRow[]) {
        this.WorkedHours(workedHours.map(this.createViewModelFor.bind(this)));
        this.SelectAll(true);
    }

    private createViewModelFor(workedHours: IExtendedWorkSheetRow): WorkedHoursRow {
        return new WorkedHoursRow(workedHours, this.userCharactersManager, workedHours.Billed, this.jobOrder);
    }

    public importDetailed() {
        this.doImport((rows: IExtendedWorkSheetRow[]) => {
            rows.forEach((r: IExtendedWorkSheetRow) => this.addDetailedRow(r));
        });
    }

    public importAggregateResource() {
        this.doImport((rows: IExtendedWorkSheetRow[]) => {
            this.aggregateByResourceId(rows).forEach((rows: IExtendedWorkSheetRow[]) =>
                this.aggregateByRoleId(rows).forEach(this.addResourceAggregatedRow.bind(this))
            );
        });
    }

    public importAggregateRole() {
        this.doImport((rows: IExtendedWorkSheetRow[]) => {
            this.aggregateByRoleId(rows).forEach(this.addRoleAggregatedRow.bind(this));
        });
    }

    private doImport(action: (rows: IExtendedWorkSheetRow[]) => void) {
        const activitiesGroupingMode = this.ActivitiesGroupingMode();
        const currentRows = this.Rows();

        const selectedRows = this.filterRows(this.WorkedHours());
        switch (activitiesGroupingMode) {
            case "NOTHING":
                action(selectedRows);
                break;
            case "ACTIVITY": {
                const perWorkflowRows = this.aggregateByWorkflow(selectedRows);
                Object.keys(perWorkflowRows).forEach((workflowId: string) => {
                    const workflowRows = perWorkflowRows[workflowId];
                    let numericId = parseInt(workflowId);

                    if (isNaN(numericId)) numericId = -1;

                    if (!currentRows.firstOrDefault((r) => r.WorkflowId === numericId && !r.Amount))
                        this.addWorkflowDescriptionRow(workflowRows[0]);

                    const perActivityRows = this.aggregateByActivity(workflowRows);

                    Object.keys(perActivityRows).forEach((taskId: string) => {
                        const activitiesRows = perActivityRows[taskId];
                        let numericTaskId = parseInt(taskId);

                        if (isNaN(numericTaskId)) numericTaskId = -1;

                        if (
                            numericTaskId != -1 &&
                            !currentRows.firstOrDefault((r) => r.TaskId === numericTaskId && !r.Amount)
                        )
                            this.addTaskDescriptionRow(activitiesRows[0]);

                        action(activitiesRows);
                    });
                });
                break;
            }
            case "WORKFLOW": {
                const perWorkflowRows = this.aggregateByWorkflow(selectedRows);
                Object.keys(perWorkflowRows).forEach((workflowId: string) => {
                    const workflowRows = perWorkflowRows[workflowId];
                    let numericId = parseInt(workflowId);

                    if (isNaN(numericId)) numericId = -1;

                    if (!currentRows.firstOrDefault((r) => r.WorkflowId === numericId && !r.Amount))
                        this.addWorkflowDescriptionRow(workflowRows[0]);

                    action(workflowRows);
                });
                break;
            }
            default:
                throw new Error("Unknown activitiesGroupingMode: " + activitiesGroupingMode);
        }
    }

    private addTaskDescriptionRow(hoursRow: IExtendedWorkSheetRow): void {
        this.Rows.push({
            WorkedHours: [],
            Description: !hoursRow.TaskId ? TextResources.Invoices.JobOrderWorkedHours : hoursRow.TaskTitle,
            Amount: 0,
            NetUnitPrice: 0,
            NetPrice: 0,
            TotalDdc: 0,
            Dependency: null,
            TaskId: hoursRow.TaskId ?? -1,
            WorkflowId: hoursRow.WorkflowId ?? -1,
        });
    }

    private addWorkflowDescriptionRow(hoursRow: IExtendedWorkSheetRow): void {
        this.Rows.push({
            WorkedHours: [],
            Description: !hoursRow.WorkflowId ? TextResources.Invoices.JobOrderWorkedHours : hoursRow.WorkflowTitle,
            Amount: 0,
            NetUnitPrice: 0,
            NetPrice: 0,
            TotalDdc: 0,
            Dependency: null,
            TaskId: null,
            WorkflowId: hoursRow.WorkflowId ?? -1,
        });
    }

    private aggregateByActivity(rows: IExtendedWorkSheetRow[]): { [taskId: number]: IExtendedWorkSheetRow[] } {
        return rows.groupBy((r) => r.TaskId);
    }

    private aggregateByWorkflow(rows: IExtendedWorkSheetRow[]): { [workflowId: number]: IExtendedWorkSheetRow[] } {
        return rows.groupBy((r) => r.WorkflowId);
    }

    private filterRows(rows: WorkedHoursRow[]): IExtendedWorkSheetRow[] {
        const selectedWorkedHours = rows.filter((r: WorkedHoursRow) => r.Selected());
        if (selectedWorkedHours.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.NoWorkSheetSelected);
            return [];
        }

        const notImportedWorkedHours: IExtendedWorkSheetRow[] = selectedWorkedHours
            .map((r: WorkedHoursRow) => r.getWorkedHours())
            .filter((wh: IExtendedWorkSheetRow) => !this.wasUsedDuringThisWizard(wh));

        if (notImportedWorkedHours.length != selectedWorkedHours.length) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.ImportWorkSheetsWarning);
        }

        return notImportedWorkedHours;
    }

    private aggregateByResourceId(rows: IExtendedWorkSheetRow[]): IExtendedWorkSheetRow[][] {
        return rows.reduce((prev: any, item: IExtendedWorkSheetRow) => {
            if (prev[item.ResourceId]) prev[item.ResourceId].push(item);
            else prev[item.ResourceId] = [item];
            return prev;
        }, []);
    }

    private aggregateByRoleId(rows: IExtendedWorkSheetRow[]): IExtendedWorkSheetRow[][] {
        return rows.reduce((prev: any, item: IExtendedWorkSheetRow) => {
            if (prev[item.RoleId]) prev[item.RoleId].push(item);
            else prev[item.RoleId] = [item];
            return prev;
        }, []);
    }

    private getRole(roleId: number): IJobOrderRolesPrices {
        const defaultRole = this.userCharactersManager.getUserCharacterById(roleId);

        if (!defaultRole)
            return { Salary: 0, Description: ProlifeSdk.TextResources.Desktop.RoleNotFound, FkUserCharacterId: -1 };

        const roles: IJobOrderRolesPrices[] = this.jobOrder.RolesPrices.filter(
            (rp: IJobOrderRolesPrices) => rp.FkUserCharacterId == roleId
        );
        if (roles.length > 0) {
            return {
                Salary: roles[0].Salary,
                Description: defaultRole.Description,
                FkUserCharacterId: defaultRole.IdUserCharacter,
            };
        }

        const customerRoles = this.customer
            ? this.customer.RolesPrices.filter((r) => r.FkUserCharacterId == roleId)
            : [];
        if (customerRoles.length > 0) {
            return {
                Salary: customerRoles[0].Salary,
                Description: defaultRole.Description,
                FkUserCharacterId: defaultRole.IdUserCharacter,
            };
        }

        return {
            Salary: defaultRole.Salary,
            Description: defaultRole.Description,
            FkUserCharacterId: defaultRole.IdUserCharacter,
        };
    }

    private calculateWorkTime(workedHours: IExtendedWorkSheetRow): number {
        return workedHours.Hours;
    }

    private GetHoursUnitPrice(workedHours: IExtendedWorkSheetRow): number {
        if (this.mustUseCosts()) return workedHours.Cost;

        const role: IUserCharacter = this.userCharactersManager.getUserCharacterById(workedHours.RoleId);

        let matches: IJobOrderRolesPrices[] = [];

        if (this.jobOrder.CustomRoles || this.jobOrder.CustomMaterialsRoles)
            matches = this.jobOrder.RolesPrices.filter((r: IJobOrderRolesPrices) => {
                return r.FkUserCharacterId == workedHours.RoleId;
            });

        if (matches.length > 0) return matches[0].Salary;

        if (this.customer && (this.customer.CustomRoles || this.customer.CustomMaterialsRoles))
            matches = this.customer.RolesPrices.filter(
                (r: ICustomerRolePrice) => r.FkUserCharacterId == workedHours.RoleId
            );

        if (matches.length > 0) return matches[0].Salary;

        return matches.length > 0 ? matches[0].Salary : role.Salary;
    }

    private GetCallRightCost(workedHours: IExtendedWorkSheetRow) {
        if (!workedHours.CallRight) return 0;

        const callRightTypeId = this.userCharactersManager.getUserCharacterById(
            workedHours.RoleId
        ).DefaultCallRightType;
        const callRightTypesMatches = this.jobOrder.CallRightTypesPrices.filter((t: IJobOrderCallRightTypesPrice) => {
            return t.CallRightId == callRightTypeId;
        });
        if (callRightTypesMatches.length > 0) return callRightTypesMatches[0].Price;

        const callRight = this.callRightTypesManager.getById(callRightTypeId);
        if (callRight) return callRight.Price;

        return 0;
    }

    private addDetailedRow(workedHours: IExtendedWorkSheetRow) {
        const salary = this.GetHoursUnitPrice(workedHours);
        const totalHours: number = this.calculateWorkTime(workedHours);
        const role = this.getRole(workedHours.RoleId);
        const callRightCost = this.GetCallRightCost(workedHours);
        const description =
            moment(workedHours.WorkDate).format("L") +
            " - " +
            workedHours.ResourceName +
            " - " +
            role.Description +
            (workedHours.Description ? " - " + workedHours.Description : "");

        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        const salaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(salary, documentCurrency);
        const totalSalaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(
            salary * totalHours,
            documentCurrency
        );

        const hours: IWorkedHoursForDocuments = {
            WorkedHours: [workedHours], // I riferimenti rimangono in Euro
            Description: description,
            AmountFormula: null,
            Amount: totalHours,
            NetUnitPrice: salaryInDocumentCurrency, // NetUnitPrice : salary,
            NetPrice: totalSalaryInDocumentCurrency, // NetPrice : salary * totalHours,
            TotalDdc: 0,
            Dependency: null,
            TaskId: workedHours.TaskId,
            WorkflowId: workedHours.WorkflowId,
        };

        const groupingMode = this.ActivitiesGroupingMode();
        const lastRowIndex = this.getLastRowIndex(groupingMode, workedHours.TaskId, workedHours.WorkflowId);
        if (lastRowIndex === -1) {
            this.Rows.push(hours);
        } else {
            this.Rows.splice(lastRowIndex + 1, 0, hours);
        }

        this.addCallsRightRow(workedHours.CallRight ? 1 : 0, callRightCost, hours);
    }

    private addResourceAggregatedRow(hours: IExtendedWorkSheetRow[], roleId: number) {
        type IWorkSheetRowWithSalary = IExtendedWorkSheetRow & { Salary: number };

        const salaries: IWorkSheetRowWithSalary[] = [];
        hours.forEach((h: IExtendedWorkSheetRow) => {
            const salary: number = this.GetHoursUnitPrice(h);
            salaries.push({
                ...h,
                Salary: salary,
            });
        });

        let totalPrice = 0;
        let totalHours = 0;
        let callRightCost = 0;
        let callsNumber = 0;

        salaries.forEach((h: IWorkSheetRowWithSalary) => {
            totalHours += this.calculateWorkTime(h);
            totalPrice += h.Salary * this.calculateWorkTime(h);

            const crCost = this.GetCallRightCost(h);
            callRightCost += crCost;
            callsNumber += crCost > 0 ? 1 : 0;
        });

        for (const salary of salaries) {
            salary.Hours /= totalHours;
        }

        const role = this.getRole(roleId);
        let description = "";
        description += this.DateFrom()
            ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L"))
            : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo()
            ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L"))
            : ProlifeSdk.TextResources.Desktop.EndDateToday;
        description += " - " + hours[0].ResourceName + " - " + role.Description;

        const unitPrice = totalHours > 0 ? totalPrice / totalHours : 0;

        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        const salaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(unitPrice, documentCurrency);
        const totalSalaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(totalPrice, documentCurrency);

        const taskId = hours[0].TaskId;
        const workflowId = hours[0].WorkflowId;
        const groupingMode = this.ActivitiesGroupingMode();

        const workedHours: IWorkedHoursForDocuments = {
            WorkedHours: salaries, // I riferimenti rimangono in Euro
            Description: description,
            AmountFormula:
                numeral(totalHours).format("0,0.00[0]") +
                " * " +
                numeral(salaryInDocumentCurrency).format("0,0.00[0]") +
                documentCurrency.Currency().Symbol,
            Amount: totalHours,
            NetUnitPrice: salaryInDocumentCurrency, // NetUnitPrice : hourPrice,
            NetPrice: totalSalaryInDocumentCurrency, // NetPrice : totalPrice,
            TotalDdc: 0,
            Dependency: null,
            TaskId: groupingMode === "ACTIVITY" ? taskId : null,
            WorkflowId: groupingMode === "WORKFLOW" ? workflowId : null,
        };

        const lastRowIndex = this.getLastRowIndex(groupingMode, taskId, workflowId);
        if (lastRowIndex === -1) {
            this.Rows.push(workedHours);
        } else {
            this.Rows.splice(lastRowIndex + 1, 0, workedHours);
        }

        const callPrice = callsNumber > 0 ? callRightCost / callsNumber : 0;
        this.addCallsRightRow(callsNumber, callPrice, workedHours);
    }

    private addRoleAggregatedRow(hours: IExtendedWorkSheetRow[], roleId: number) {
        type IWorkSheetRowWithSalary = IExtendedWorkSheetRow & { Salary: number };

        const salaries: IWorkSheetRowWithSalary[] = [];
        hours.forEach((h: IExtendedWorkSheetRow) => {
            const salary: number = this.GetHoursUnitPrice(h);
            salaries.push({
                ...h,
                Salary: salary,
            });
        });

        let totalPrice = 0;
        let totalHours = 0;
        let callRightCost = 0;
        let callsNumber = 0;

        salaries.forEach((h) => {
            totalHours += this.calculateWorkTime(h);
            totalPrice += h.Salary * this.calculateWorkTime(h);

            const crCost = this.GetCallRightCost(h);
            callRightCost += crCost;
            callsNumber += crCost > 0 ? 1 : 0;
        });

        for (const salary of salaries) {
            salary.Hours /= totalHours;
        }

        const role = this.getRole(roleId);
        let description = "";
        description += this.DateFrom()
            ? String.format(ProlifeSdk.TextResources.Desktop.StartDate, moment(this.DateFrom()).format("L"))
            : ProlifeSdk.TextResources.Desktop.StartWorkDate;
        description += this.DateTo()
            ? String.format(ProlifeSdk.TextResources.Desktop.EndDate, moment(this.DateTo()).format("L"))
            : ProlifeSdk.TextResources.Desktop.EndDateToday;
        description += " - " + role.Description;

        const unitPrice = totalHours > 0 ? totalPrice / totalHours : 0;
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        const salaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(unitPrice, documentCurrency);
        const totalSalaryInDocumentCurrency = CurrencyUtils.applyCurrencyReverseExchange(totalPrice, documentCurrency);

        const taskId = hours[0].TaskId;
        const workflowId = hours[0].WorkflowId;
        const groupingMode = this.ActivitiesGroupingMode();

        const workedHours: IWorkedHoursForDocuments = {
            WorkedHours: salaries, // I riferimenti rimangono in Euro
            Description: description,
            AmountFormula:
                numeral(totalHours).format("0,0.00[0]") +
                " * " +
                numeral(salaryInDocumentCurrency).format("0,0.00[0]") +
                documentCurrency.Currency().Symbol,
            Amount: totalHours,
            NetUnitPrice: salaryInDocumentCurrency, // NetUnitPrice : hourPrice,
            NetPrice: totalSalaryInDocumentCurrency, // NetPrice : totalPrice,
            TotalDdc: 0,
            Dependency: null,
            TaskId: groupingMode === "ACTIVITY" ? taskId : null,
            WorkflowId: groupingMode === "WORKFLOW" ? workflowId : null,
        };

        const lastRowIndex = this.getLastRowIndex(groupingMode, taskId, workflowId);
        if (lastRowIndex === -1) {
            this.Rows.push(workedHours);
        } else {
            this.Rows.splice(lastRowIndex + 1, 0, workedHours);
        }

        const callPrice = callsNumber > 0 ? callRightCost / callsNumber : 0;
        this.addCallsRightRow(callsNumber, callPrice, workedHours);
    }

    private addCallsRightRow(calls: number, callsPrice: number, dependency: IWorkedHoursForDocuments) {
        if (calls == 0) return;

        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const callRightRow: IWorkedHoursForDocuments = {
            WorkedHours: [],
            Description: dependency.Description + ProlifeSdk.TextResources.Desktop.CallsRights,
            Amount: calls,
            NetUnitPrice: CurrencyUtils.applyCurrencyReverseExchange(callsPrice, documentCurrency), // NetUnitPrice : callsPrice,
            NetPrice: CurrencyUtils.applyCurrencyReverseExchange(callsPrice * calls, documentCurrency), // NetPrice : callsPrice * calls,
            TotalDdc: 0,
            Dependency: dependency,
            TaskId: dependency.TaskId,
            WorkflowId: dependency.WorkflowId,
        };

        const groupingMode = this.ActivitiesGroupingMode();
        const lastRowIndex = this.getLastRowIndex(groupingMode, dependency.TaskId, dependency.WorkflowId);
        if (lastRowIndex === -1) {
            this.Rows.push(callRightRow);
        } else {
            this.Rows.splice(lastRowIndex + 1, 0, callRightRow);
        }
    }

    private getLastRowIndex(groupingMode: GroupingMode, taskId: number, workflowId: number): number {
        let lastRowIndex = -1;
        if (groupingMode === "NOTHING") return lastRowIndex;

        let groupFound = false;
        this.Rows().forEach((row: IWorkedHoursForDocuments, index: number) => {
            if (groupingMode === "ACTIVITY" && row.TaskId === taskId) {
                if (!row.Amount) groupFound = true;
                else if (groupFound) {
                    lastRowIndex = index;
                    return;
                }
            }

            if (groupingMode === "WORKFLOW" && row.WorkflowId === workflowId) {
                if (!row.Amount) groupFound = true;
                else if (groupFound) {
                    lastRowIndex = index;
                    return;
                }
            }
        });

        return lastRowIndex;
    }

    public removeRow(row: IWorkedHoursForDocuments) {
        this.Rows.remove(row);
    }

    private lastTimeout: ReturnType<typeof setTimeout>;

    findSearchMatches(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            this.search(query.term, query.callback);
        }, 500);
    }

    private search(searchFilter: string, callback: (data: any) => void) {
        const data = { results: [] };
        const providersDef = new Deferred();
        this.entitiesProviderService
            .search(searchFilter, [ProlifeSdk.HumanResources, ProlifeSdk.ResourcesGroups, ProlifeSdk.RolesEntityType])
            .then((results: IEntitySearchResult[]) => {
                results.forEach((result: IEntitySearchResult) => {
                    if (result.entities.length == 0) return;

                    const menuGroup = {
                        text: result.provider.getEntityTypeName(),
                        children: [],
                    };

                    result.entities.forEach((e) => {
                        const tags: ILogTagFilter[] = [
                            {
                                Values: [result.provider.getPkValue(e)],
                                ValueTypeId: result.provider.getType(),
                            },
                        ];

                        const entityFilter: ILogFilter = {
                            Tags: tags,
                        };

                        menuGroup.children.push({
                            id: JSON.stringify(entityFilter),
                            text: result.provider.getEntityTypeName() + ":   " + result.provider.getDisplayName(e),
                        });
                    });

                    data.results.push(menuGroup);
                });
                providersDef.resolve();
            })
            .catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
    }

    public async EditWorkflow(): Promise<void> {
        if (!this.WorkflowId()) return;

        await this.todoListService.ShowEditWorkflowDialog(this.WorkflowId());
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const step = this;

        return ComponentUtils.bindTo(
            <Layout.Grid
                rows={["100%"]}
                columns={["auto", "270px"]}
                style={{ height: "100%" }}
                className={classes["worked-hours-data-source"]}
                bindings={{ css: { "standard-layout": this.StandardLayoutEnabled } }}>
                <Layout.Grid.Cell column={1} row={1} className="data-source-content">
                    <div
                        className="form-horizontal flex-container flex-vertical flex-full-height"
                        style={{ paddingRight: "15px", width: "100%" }}>
                        <div className="row" style="margin-bottom : 20px; margin-top: 20px">
                            <div className="col-md-6">
                                <div className="row">
                                    <label className="col-md-2 control-label">
                                        {TextResources.Invoices.DocumentWizardFrom}
                                    </label>
                                    <div className="col-md-4">
                                        <div
                                            className="input-group date form_datetime"
                                            data-bind="nullableDatePicker : DateFrom">
                                            <input
                                                className="form-control form-control-inline date-picker"
                                                type="text"
                                                id="date"
                                            />
                                            <span className="input-group-addon">
                                                <span className="fa fa-calendar"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <label className="col-md-2 control-label">
                                        {TextResources.Invoices.DocumentWizardTo}
                                    </label>
                                    <div className="col-md-4">
                                        <div
                                            className="input-group date form_datetime"
                                            data-bind="nullableDatePicker : DateTo">
                                            <input
                                                className="form-control form-control-inline date-picker"
                                                type="text"
                                                id="date"
                                            />
                                            <span className="input-group-addon">
                                                <span className="fa fa-calendar"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className="alert alert-danger"
                                            style="margin-top: 3px; margin-bottom: -8px;"
                                            data-bind="visible: WarningMessages() != undefined, text: WarningMessages"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 form-control-static flex-container">
                                <div className="flex-fill">
                                    <div className="checker">
                                        <span data-bind="css : { checked : IsApproved }">
                                            <input type="checkbox" data-bind="checked : IsApproved" />
                                        </span>
                                    </div>
                                    {TextResources.Invoices.DocumentWizardShowOnlyApprovedHours}
                                </div>
                                <SegmentedInput
                                    value={this.SelectedLayout}
                                    simple
                                    options={[
                                        { value: "standard-layout", text: "Mod. standard" },
                                        { value: "menu-layout", text: "Mod. selettore attività" },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="row" style="margin-bottom : 20px">
                            <label className="col-md-1 control-label">
                                {TextResources.Invoices.DocumentWizardSearch}
                            </label>
                            <div className="col-md-5">
                                <input
                                    className="select2-focusser select2-offscreen"
                                    type="hidden"
                                    data-bind={`value: SearchFilters, select2: { allowClear: true, query: findSearchMatches.bind($data), placeholder: '${TextResources.Invoices.DocumentWizardSearch}', multiple: true, minimumInputLength: 1 }`}
                                    style="width: 100%"
                                />
                            </div>

                            <label className="col-md-1 control-label">
                                {TextResources.Invoices.DocumentWizardWorkflow}
                            </label>
                            <div className="col-md-4">
                                <select2
                                    params={`Value: WorkflowId, DataSource: WorkflowsDataSource, Listener: $data, ReadOnly: !StandardLayoutEnabled(), Simple: true, AllowClear: true, Placeholder: '${TextResources.Invoices.DocumentWizardWorkflowPlaceholder}'`}></select2>
                            </div>
                            <div className="col-md-1">
                                <button
                                    className="btn btn-primary"
                                    title={TextResources.Invoices.DocumentWizardEditWorkflow}
                                    data-bind="asyncClick: EditWorkflow, attr: {disabled: !WorkflowId()}">
                                    <i className="fa fa-pencil"></i>
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 flex-container">
                                <div className="form-group grouping-mode">
                                    <label className="control-label">
                                        {TextResources.Invoices.ActivitiesGroupingMode}
                                    </label>
                                    <select
                                        className="form-control"
                                        data-bind={{
                                            options: step.activitiesGroupingModes,
                                            optionsText: "label",
                                            optionsValue: "id",
                                            value: step.ActivitiesGroupingMode,
                                        }}></select>
                                </div>
                                <div className="actions flex-fill">
                                    <button type="button" className="btn btn-primary" data-bind="click: importDetailed">
                                        {TextResources.Invoices.DocumentWizardDetailed}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bind="click: importAggregateResource">
                                        {TextResources.Invoices.DocumentWizardGroupedByResource}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bind="click: importAggregateRole">
                                        {TextResources.Invoices.DocumentWizardGroupedByRole}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 flex-container right-actions">
                                <ko-bind data-bind={{ ifnot: step.UseCosts }}>
                                    <div className="already-imported">
                                        <div className="checker">
                                            <span data-bind="css : { checked : ShowAlreadyImported }">
                                                <input type="checkbox" data-bind="checked : ShowAlreadyImported" />
                                            </span>
                                        </div>
                                        {TextResources.Invoices.DocumentWizardShowAlreadyImportedHours}
                                    </div>
                                </ko-bind>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    style="margin-left: 30px"
                                    data-bind="click: ClearImportedRows">
                                    {TextResources.Invoices.DocumentWizardClear}
                                </button>
                            </div>
                        </div>

                        <div className="flex-fill">
                            <Layout.Grid rows={["100%"]} columns={["50%", "50%"]} noOverflow style={{ height: "100%" }}>
                                <Layout.Grid.Cell column={1} row={1}>
                                    <div data-bind={{ slimScroll: "flex" }}>
                                        <table
                                            className="table table-striped table-bordered table-advance table-hover"
                                            style={{ paddingRight: "5px" }}>
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;width: 120px;">
                                                        <div className="checker">
                                                            <span data-bind="css : { checked : SelectAll }">
                                                                <input
                                                                    type="checkbox"
                                                                    data-bind="checked : SelectAll"
                                                                />
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th style="text-align: center;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardDate}
                                                    </th>
                                                    <th style="text-align: center;">
                                                        {TextResources.Invoices.DocumentWizardResource}
                                                    </th>
                                                    <th style="text-align: center;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardRole}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardCallRight}
                                                    </th>
                                                    <th style="text-align: center;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardWorkType}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardHours}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-if data-bind="WorkedHours().length == 0">
                                                    <tr>
                                                        <td colSpan={7} style="text-align : center">
                                                            {TextResources.Invoices.DocumentWizardNoWorkedHours}
                                                        </td>
                                                    </tr>
                                                </ko-if>
                                                <ko-foreach data-bind="WorkedHours">
                                                    <tr data-bind="css : { active : Selected }">
                                                        <td style="text-align: center">
                                                            <div className="checker">
                                                                <span data-bind="css : { checked : Selected }">
                                                                    <input
                                                                        type="checkbox"
                                                                        data-bind="checked : Selected"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <i
                                                                className="fa fa-lock"
                                                                data-bind="visible: AlreadyImported"></i>
                                                            <span data-bind="dateText: Date"></span>
                                                        </td>
                                                        <td
                                                            style="text-align: center;"
                                                            data-bind="text: ResourceName"></td>
                                                        <td
                                                            style="text-align: center;"
                                                            data-bind="text: ResourceRole"></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind="numberText: CallRightPrice"></td>
                                                        <td
                                                            style="text-align: center;"
                                                            data-bind="text: WorkTimeCategoryDescr"></td>
                                                        <td
                                                            style="text-align: right;"
                                                            data-bind="numberText: Hours"></td>
                                                    </tr>
                                                </ko-foreach>
                                            </tbody>
                                        </table>
                                    </div>
                                </Layout.Grid.Cell>
                                <Layout.Grid.Cell column={2} row={1} style={{ paddingLeft: "5px" }}>
                                    <div data-bind={{ slimScroll: "flex" }}>
                                        <table className="table table-striped table-bordered table-advance table-hover">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardAmount}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardUnitPrice}
                                                    </th>
                                                    <th style="text-align: right;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardTotalPrice}
                                                    </th>
                                                    <th style="text-align: center;width: 120px;">
                                                        {TextResources.Invoices.DocumentWizardRemove}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ko-if data-bind="Rows().length == 0">
                                                    <tr>
                                                        <td colSpan={6} style="text-align : center">
                                                            {TextResources.Invoices.DocumentWizardNoWorkedHours}
                                                        </td>
                                                    </tr>
                                                </ko-if>
                                                <ko-foreach data-bind="Rows">
                                                    <tr>
                                                        <td style="text-align: center">
                                                            <span
                                                                className="fa fa-link"
                                                                data-bind="visible : Dependency"></span>
                                                        </td>
                                                        <td data-bind="text: Description"></td>
                                                        <td style="text-align: right" data-bind="text: Amount"></td>
                                                        <td
                                                            style="text-align: right"
                                                            data-bind="moneyText: NetUnitPrice"></td>
                                                        <td
                                                            style="text-align: right"
                                                            data-bind="moneyText: NetPrice"></td>
                                                        <td style="text-align: center;">
                                                            <a
                                                                href="#"
                                                                data-bind="click: $parent.removeRow.bind($parent, $data)">
                                                                <i className="fa fa-times"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ko-foreach>
                                            </tbody>
                                        </table>
                                    </div>
                                </Layout.Grid.Cell>
                            </Layout.Grid>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning">
                                    <span className="fa fa-link"></span>
                                    &nbsp;{TextResources.Invoices.DocumentWizardWorkedHoursInfo}
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={2} row={1} style={{ position: "relative" }} className={"menu-wrapper"}>
                    <NavigationMenu
                        dataSource={this.WorkflowsAndTasksDataSource}
                        listener={this}
                        forwardRef={(menu) => this.navigationMenu(menu)}></NavigationMenu>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "step"
        );
    }
}

class WorkedHoursRow {
    Selected: ko.Observable<boolean> = ko.observable(false);
    Date: ko.Observable<Date> = ko.observable();
    ResourceName: ko.Observable<string> = ko.observable();
    ResourceRole: ko.Observable<string> = ko.observable();
    Hours: ko.Observable<number> = ko.observable();
    Overtime: ko.Observable<number> = ko.observable();
    AlreadyImported: ko.Observable<boolean> = ko.observable();
    CallRightPrice: ko.Observable<number> = ko.observable();
    WorkTimeCategoryDescr: ko.Observable<string> = ko.observable();

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCategoriesManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesManager: ICallRightTypesSettingsManager;

    constructor(
        private workedHours: IExtendedWorkSheetRow,
        private userCharactersManager: IUserCharactersSettingsManager,
        alreadyImported: boolean,
        private jobOrder: IJobOrder
    ) {
        this.Date(workedHours.WorkDate);
        this.ResourceName(workedHours.ResourceName);
        this.ResourceRole(this.userCharactersManager.getUserCharacterById(workedHours.RoleId).Description);
        this.WorkTimeCategoryDescr(this.workTimeCategoriesManager.getById(workedHours.WorkTimeCategoryId).Name);
        this.Hours(workedHours.Hours);
        this.Overtime(0);
        this.AlreadyImported(alreadyImported);

        const callRightTypeId = this.userCharactersManager.getUserCharacterById(
            workedHours.RoleId
        ).DefaultCallRightType;
        const callRightTypesMatches = this.jobOrder.CallRightTypesPrices.filter((t: IJobOrderCallRightTypesPrice) => {
            return t.CallRightId == callRightTypeId;
        });
        let callRightPrice = 0;
        if (callRightTypesMatches.length > 0) callRightPrice = callRightTypesMatches[0].Price;
        else {
            const callRight = this.callRightTypesManager.getById(callRightTypeId);
            if (callRight) callRightPrice = callRight.Price;
        }
        this.CallRightPrice(workedHours.CallRight ? callRightPrice : 0);
    }

    public getWorkedHours(): IExtendedWorkSheetRow {
        return this.workedHours;
    }
}
