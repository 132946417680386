import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 31/07/2017
 * Time: 10:53
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { PeopleMetadata } from "./People";
import { EditPeopleDialog } from "./dialogs/EditPeopleDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IPeopleMetadata, IMetadataTypeType, IPeopleService } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";

export class PeopleMetadataViewModel extends PeopleMetadata {
    public IsInteger: ko.Computed<boolean>;
    public IsFloat: ko.Computed<boolean>;
    public IsString: ko.Computed<boolean>;
    public IsBoolean: ko.Computed<boolean>;
    public IsDateTimeOffset: ko.Computed<boolean>;

    public BooleanTextValue: ko.Computed<string>;

    constructor(metadata: IPeopleMetadata) {
        super(metadata);

        this.IsInteger = ko.computed(() => {
            return !!this.IntegerValue();
        });

        this.IsFloat = ko.computed(() => {
            return !!this.FloatValue();
        });

        this.IsString = ko.computed(() => {
            return !!this.StringValue();
        });

        this.IsBoolean = ko.computed(() => {
            return !!this.BooleanValue();
        });

        this.IsDateTimeOffset = ko.computed(() => {
            return !!this.DateTimeOffsetValue();
        });

        this.BooleanTextValue = ko.computed(() => {
            return this.BooleanValue() ? ProlifeSdk.TextResources.Survey.PeopleMetadataBooleanTrueValue : ProlifeSdk.TextResources.Survey.PeopleMetadataBooleanFalseValue;
        });
    }
}

export class PeopleMetadataForEditViewModel extends PeopleMetadataViewModel {
    public DataTypes: ko.ObservableArray<IMetadataTypeType> = ko.observableArray([]);
    public SelectedDataType: ko.Observable<IMetadataTypeType> = ko.observable();

    public StringTypeSelected: ko.Computed<boolean>;
    public IntegerTypeSelected: ko.Computed<boolean>;
    public FloatTypeSelected: ko.Computed<boolean>;
    public DateTimeOffsetTypeSelected: ko.Computed<boolean>;
    public BooleanTypeSelected: ko.Computed<boolean>;

    public MissingRequiredData: ko.Computed<boolean>;

    public Scroll: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IPeopleService>())
    private peopleService: IPeopleService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(metadata: IPeopleMetadata, private editor: EditPeopleDialog) {
        super(metadata);

        this.Scroll(false);
        
        this.peopleService.getMetadataTypes()
            .then((types: IMetadataTypeType[]) => {
                this.DataTypes(types);
                this.setInitialDataType();
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.GetMetadataTypesError);
            })
            .finally(() => {
                this.SelectedDataType.subscribe((value: IMetadataTypeType) => {
                    this.MetaType(value?.TypeName);
                });
            });

        this.MissingRequiredData = ko.computed(() => {
            return !this.MetaName();
        });

        this.StringTypeSelected = ko.computed(() => {
            return this.SelectedDataType()?.TypeName == ProlifeSdk.StringDataType;
        });

        this.IntegerTypeSelected = ko.computed(() => {
            return this.SelectedDataType()?.TypeName == ProlifeSdk.IntegerDataType;
        });

        this.FloatTypeSelected = ko.computed(() => {
            return this.SelectedDataType()?.TypeName == ProlifeSdk.FloatDataType;
        });

        this.BooleanTypeSelected = ko.computed(() => {
            return this.SelectedDataType()?.TypeName == ProlifeSdk.BooleanDataType;
        });

        this.DateTimeOffsetTypeSelected = ko.computed(() => {
            return this.SelectedDataType()?.TypeName == ProlifeSdk.DateDataType;
        });
    }

    public Delete(): void {
        this.editor.PeopleMetadata.remove(this);
    }

    private setInitialDataType(): void {
        this.SelectedDataType(this.DataTypes().firstOrDefault(t => t.TypeName === this.MetaType()));

        if (!this.Id)
            this.SelectedDataType(this.DataTypes().firstOrDefault(t => t.TypeName === ProlifeSdk.StringDataType));
            
        let selectedType = this.SelectedDataType();
        this.MetaType(selectedType?.TypeName);
    }
}