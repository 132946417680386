import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ArticleTransformEditorDialog } from "./ArticleTransformEditorDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IArticleTransform, IArticlesService } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { ArticleTransformImportDialog } from "./ArticleTransformImportDialog";

export class Transforms implements IView {
    templateName: string = "transforms";
    templateUrl: string = "warehouse/templates";
    viewModel: any;

    SectionId: number = 9;

    searchFilter: ko.Observable<string> = ko.observable();
    SomeFilterIsChanged: ko.Observable<boolean> = ko.observable();

    Articles: ko.ObservableArray<IArticleTransform> = ko.observableArray();

    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService: IArticlesService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        this.viewModel = this;

        this.searchFilter.subscribe(() => {
            this.SomeFilterIsChanged(true);
        });

        this.loadTransforms();
    }

    private async loadTransforms() {
        this.SomeFilterIsChanged(false);

        const transforms: IArticleTransform[] = await this.articlesService.GetArticlesTransforms(this.searchFilter());

        this.Articles(transforms);
    }

    public async editTransform(transform: IArticleTransform) {
        const dialog = new ArticleTransformEditorDialog(transform);
        const result = await dialog.show();

        if (!result) return;

        this.loadTransforms();
    }

    public async deleteTransform(transform: IArticleTransform) {
        const result = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Warehouse.SureToDeleteTransform,
            ProlifeSdk.TextResources.Warehouse.DoNotDeleteTransform,
            ProlifeSdk.TextResources.Warehouse.DeleteTransform
        );

        if (!result) return;

        await this.articlesService.DeleteTransform(transform.ArticleId);

        this.loadTransforms();
    }

    public async createNew() {
        const dialog = new ArticleTransformEditorDialog(null);
        const result = await dialog.show();

        if (!result) return;

        this.loadTransforms();
    }

    public async importTransforms() {
        const modal = new ArticleTransformImportDialog();
        await modal.show();
        this.loadTransforms();
    }
}
