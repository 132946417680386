import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IOperationalUnitsSettingsManager, IOperationalUnit } from "../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";

export class OperationalUnitDescription {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

		var interceptor = ko.computed(() => {
			var options = valueAccessor();

			var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
			var ouSettings : IOperationalUnitsSettingsManager= <IOperationalUnitsSettingsManager> settingsService.findSettingsManager(ProlifeSdk.OperationalUnitsSettingsServiceType);

			var element : IOperationalUnit = ouSettings.getById(ko.utils.unwrapObservable(options.id));

			if (element)
				return element.Name;
			else
				return ko.utils.unwrapObservable(options.defaultDescription) || ProlifeSdk.TextResources.WorkedHours.Select;
		});

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
	}
}

ko.bindingHandlers["operationalUnitDescription"] = new OperationalUnitDescription();
ko.virtualElements.allowedBindings["operationalUnitDescription"] = true;

