import * as ko from "knockout";
/**
 * Created by g.adami on 19/06/2017.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SplashPage } from "./SplashPage";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { SampleEditDialog } from "../samples/dialogs/SampleEditDialog";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISampleService } from "../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import {
    IControlsCustomEntitiesProvider,
    IHintSearchGroupInfo,
    IItemIdWithTypeAndGroupCode,
} from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { ISampleForSplash, ISampleSplashPageFilters } from "../../../../ProlifeSdk/interfaces/survey/ISample";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";

export class SampleSplashPageList {
    public templateName = "sample-splash-page-list";
    public templateUrl = "survey/templates/sample";

    public Samples: ko.ObservableArray<SampleForSplash> = ko.observableArray([]);

    public NavBarActions = [];

    public HintSearchProvider: IControlsCustomEntitiesProvider;

    private LockManager: EntitiesListLockManager;

    private desktopService: IDesktopService;
    private sampleService: ISampleService;
    private toastService: IInfoToastService;
    private dialogService: IDialogsService;
    private entitiesProviderService: IEntityProviderService;

    private hintSearchGroupsInfo: IHintSearchGroupInfo[] = [];

    private canLoadData: boolean;

    constructor(private serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.desktopService = <IDesktopService>serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.sampleService = <ISampleService>serviceLocator.findService(ProlifeSdk.SampleServiceType);
        this.toastService = <IInfoToastService>serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.entitiesProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );

        this.LockManager = new EntitiesListLockManager(this.Samples, ProlifeSdk.SurveyEntityTypeCode);

        this.HintSearchProvider = this.entitiesProviderService.GetCustomEntitiesProviderForControls(
            this.hintSearchGroupsInfo,
            true,
            false
        );

        this.NavBarActions = [
            {
                Name: ko.observable(ProlifeSdk.TextResources.Survey.NewSample),
                CanRun: ko.observable(true),
                Icon: ko.observable("fa-plus"),
                Run: this.NewSample.bind(this),
            },
        ];

        this.canLoadData = true;
    }

    public GetHintSearchProvider(): IControlsCustomEntitiesProvider {
        return this.HintSearchProvider;
    }

    public dispose() {
        this.LockManager.Dispose();
    }

    Refresh() {
        this.Samples([]);
        this.LoadNextBlock();
        this.LoadCount();
    }

    LoadNextBlock() {
        if (this.canLoadData) {
            this.canLoadData = false;
            this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);
            this.LockManager.WhenIsReady().then(() => {
                this.sampleService
                    .getSamplesForSplash(this.GetFilters(), this.Samples().length, 30)
                    .then((samples: ISampleForSplash[]) => {
                        const newList = this.Samples();
                        samples
                            .map((s: ISampleForSplash) => {
                                return this.CreateViewModelFor(s);
                            })
                            .forEach((s) => {
                                newList.push(s);
                            });
                        this.Samples(newList);
                    })
                    .finally(() => {
                        this.desktopService.UnblockPageUI();
                        setTimeout(() => {
                            this.canLoadData = true;
                        }, 500);
                    });
            });
        }
    }

    LoadCount() {
        this.sampleService.getSamplesForSplashCount(this.GetFilters()).then((count: number) => {
            this.parent.ListElementsCount(count);
        });
    }

    public NewSample() {
        const addSampleDialog = new SampleEditDialog(this.serviceLocator, null);
        this.dialogService.ShowModal<number>(addSampleDialog).then((result: number) => {
            if (!result) return;
            this.parent.SurveyPanel.EditSample(result);
        });
    }

    private GetFilters(): ISampleSplashPageFilters {
        const filters: IItemIdWithTypeAndGroupCode[] = this.parent
            .HintSearchProvider()
            .extractItemsAndTypesFromStringValue(this.parent.SearchFilters());
        const words: string[] = filters
            .filter((f: IItemIdWithTypeAndGroupCode) => {
                return f.GroupCode == "$$HINTWORD$$";
            })
            .map((f: IItemIdWithTypeAndGroupCode) => {
                return f.Id;
            });

        return <ISampleSplashPageFilters>{
            TextFilters: words,
        };
    }

    private CreateViewModelFor(s: ISampleForSplash) {
        return new SampleForSplash(this.serviceLocator, s, this);
    }
}

export class SampleForSplash implements ILockableItem {
    public DetailsUrl: ko.Observable<string> = ko.observable();

    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

    public FormattedTitle: ko.Observable<string> = ko.observable();

    constructor(
        serviceLocator: IServiceLocator,
        public sample: ISampleForSplash,
        private parent: SampleSplashPageList
    ) {
        this.DetailsUrl(
            "#/" +
                ProlifeSdk.TextResources.Survey.SurveyUrl +
                "/" +
                ProlifeSdk.TextResources.Survey.SampleUrl +
                "/" +
                this.sample.Id
        );

        this.ItemKey = this.sample.Id;

        this.FormattedTitle(this.sample.PublicId + " - " + this.sample.Title);
    }

    public OpenSample() {
        location.href = this.DetailsUrl();
    }
}
