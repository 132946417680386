import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as numeral from "numeral";
import jss from "jss";
import { ReferencesMapViewer } from "../../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/ReferencesMapViewer";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../../Invoices/DocumentsService";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IJobOrderDocument, IJobOrderService } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IScheduleService } from "../../../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IWarehousesService } from "../../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { CreateNewDocumentButton } from "../../../../../Invoices/invoices/CreateNewDocumentButton";
import { IEstimateStates } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IEstimateStates";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../../../Components/Layouts";
import { BindTo } from "../../../../../Components/Bind";
import { DocumentClosingStatusIndicator } from "../../../../../Components/DocumentClosingStatusIndicator";
import { TableFilter } from "../../../../../Components/TableComponent/TableFilter";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    jobOrderDocuments: {
        "& table.table.joborder-documents-table": {
            "& td a.btn.icon": {
                "&[data-state='0']": {
                    backgroundColor: 'transparent',
                    color: 'black'
                },

                "&[data-state='1']": {
                    backgroundColor: '#46c72f',
                    color: 'white'
                },

                "&[data-state='2']": {
                    backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #ecbc29 100%) !important',
                    color: 'white'
                },

                "&[data-state='3']": {
                    backgroundColor: '#f3565d',
                    color: 'white'
                },
                
                "&[data-state='4']": {
                    backgroundImage: 'linear-gradient(to bottom, #ecbc29 0, #f3565d 100%) !important',
                    color: 'white'
                },

                "&[data-state='5']": {
                    backgroundColor: '#ecbc29',
                    color: 'white'
                },

                "&[data-state='6']": {
                    backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #f3565d 100%) !important',
                    color: 'white'
                },

                "&[data-state='7']": {
                    backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #ecbc29 50%, #f3565d 100%) !important',
                    color: 'white'
                },
            }
        }
    }
});
const { classes } = styleSheet.attach();


export class JobOrderDocumentsFactory implements IJobOrderEditorPanelFactory {
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;

    constructor(serviceLocator : IServiceLocator, public Color : string) {}

    createPanel(serviceLocator: IServiceLocator, editor: IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderDocuments(editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewDocuments");
    }
}

class JobOrderDocuments implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Documents);
    TemplateUrl:string;
    TemplateName:string;

    public Documents : ko.ObservableArray<IJobOrderDocument> = ko.observableArray([]);
    public FilteredDocuments : ko.ObservableArray<IJobOrderDocument> = ko.observableArray([]);

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    @LazyImport(nameof<IWarehousesService>())
    private warehousesService : IWarehousesService;
    @LazyImport(nameof<IScheduleService>())
    private scheduleService : IScheduleService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    private selectedDocTypes: string[] = [];
    private selectedProtocols: number[] = [];
    private selectedCustomers: string[] = [];
    private selectedExternalReferenceNumbers: string[] = [];
    private selectedExternalReferences: string[] = [];

    constructor(private editor : IJobOrderEditor, public Color : string) {}

    private newDocument(registerId : number, documentType: string) {
        this.documentsService.CreateNewDocumentOverlay(documentType, registerId, this.editor.JobOrderId)
            .then(() => {
                this.load();
            });
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {}

    @Delay()
    load(): Promise<IJobOrderDocument[]> {
        return this.jobOrderService.GetJobOrderDocuments(this.editor.JobOrderId)
            .then((documents : IJobOrderDocument[]) => {
                documents.forEach((d: IJobOrderDocument) => {
                    d.StateLabel = d.State == 0 ? ProlifeSdk.TextResources.JobOrder.JobOrderDocumentOpenedState : (d.State == 1 ? ProlifeSdk.TextResources.JobOrder.JobOrderDocumentPartiallyClosedState : (!d.State ? "" : ProlifeSdk.TextResources.JobOrder.JobOrderDocumentClosedState));
                });
                this.Documents(documents);
                return documents;
            })
    }

    private createModel(d: IJobOrderDocument) {
        return {
            id: d.Id,
            title: d.Customer,
            isGroup: false,
            isLeaf: true,
            model: d
        }
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) {
    }

    OnWorkflowChanged(workflowId:number) {
    }

    OnWorkflowCreated(workflowId:number) {
    }

    OnWorkflowImportedFromTemplate(workflowId:number) {
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    public OpenDocument(document : IJobOrderDocument) {
        this.documentsService.OpenDocumentOverlay(document.DocType, document.Id);
    }

    public ShowDetails(document : IJobOrderDocument) {
        const dialog = new DocumentDetailsDialog(this, document);
        this.dialogsService.ShowModal<void>(dialog, undefined, { noPrompt: true });
    }

    public ShowExpirires(document : IJobOrderDocument) {
        switch(document.DocType)
        {
            case "DOC":
            case "DOA":
                this.scheduleService.GetInvoiceSchedulesDialog(document.Id).then((dialog : IDialog) => {
                    this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
                });
                break;
        }
    }

    public ShowReferencesMap(document : IJobOrderDocument)
    {
        const vm : ReferencesMapViewer = new ReferencesMapViewer(document.Id, document.DocType, -1, null);
        this.dialogsService.ShowModal<void>(vm, "fullscreen doc-references-map", null, vm.templateUrl, vm.templateName);
    }

    public OpenSourceWarehouseStock(document : IJobOrderDocument) {
        const url = this.warehousesService.GetWarehouseStockUrl(document.SourceWarehouseId);
        window.open(url, "_blank");
    }

    public OpenDestinationWarehouseStock(document : IJobOrderDocument) {
        const url = this.warehousesService.GetWarehouseStockUrl(document.DestinationWarehouseId);
        window.open(url, "_blank");
    }

    public getExcelExportData() : any | null {
        return {
            jobOrderId: this.editor.JobOrderId
        };
    }

    private onDocTypeSelectionChange(selection: string[]) {
        this.selectedDocTypes = selection;
        this.applyFilters();
    }
    
    private onProtocolsSelectionChange(selection: number[]) {
        this.selectedProtocols = selection;
        this.applyFilters();
    }
    
    private onCustomersSelectionChange(selection: string[]) {
        this.selectedCustomers = selection;
        this.applyFilters();
    }
    
    private onExternalReferenceNumbersSelectionChange(selection: string[]) {
        this.selectedExternalReferenceNumbers = selection;
        this.applyFilters();
    }
    
    private onExternalReferencesSelectionChange(selection: string[]) {
        this.selectedExternalReferences = selection;
        this.applyFilters();
    }

    @Delay(200)
    private applyFilters() {
        const allDocs = this.Documents();
        const filterdDocs = allDocs.filter(d => 
               this.selectedDocTypes.indexOf(d.DocType) >= 0
            && this.selectedProtocols.indexOf(d.FkRegistroIva) >= 0
            && (this.selectedCustomers.indexOf(d.Customer) >= 0 || (!d.Customer && this.selectedCustomers.filter(c => !c).length !== 0))
            && (this.selectedExternalReferenceNumbers.indexOf(d.ReferenceNumber) >= 0 || (!d.ReferenceNumber && this.selectedExternalReferenceNumbers.filter(n => !n).length !== 0))
            && (this.selectedExternalReferences.indexOf(d.ExternalReference) >= 0 || (!d.ExternalReference && this.selectedExternalReferences.filter(r => !r).length !== 0))
        );

        this.FilteredDocuments([]);
        this.FilteredDocuments(filterdDocs);
    }

    render() {
        let vm : JobOrderDocuments;
        let $data : IDataSourceModel<number, IJobOrderDocument>;

        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<IJobOrderDocument>();

        return (
            <BindTo viewModel={this} as="vm">
                <div className={classes.jobOrderDocuments + " container-fluid"} style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, padding: "15px" }}>
                    <LayoutWithHeader>
                        <LayoutHeader>
                            <div className="flex-fill">
                                <CreateNewDocumentButton onNewDocument={(registerId, documentType) => this.newDocument(registerId, documentType)} />
                            </div>
                            <excel-exporter className="pull-right" exporterId="JobOrder/JobOrderDocumentsExport" exporterMethod="GenerateExcel" dataProvider={() => "vm.getExcelExportData.bind(vm)"} position="left" viewport=".tabbable.tabbable-custom">
                                <i className="fa fa-download"></i>&nbsp;Esporta Excel
                            </excel-exporter>
                        </LayoutHeader>
                        <LayoutContent noOverflow={true}>
                            <Table
                                id={ProlifeSdk.JobOrderDocumentsTable}
                                compact
                                systemScrollable
                                verticalAlign="middle"
                                className="joborder-documents-table"
                                dataSource={{ array: this.FilteredDocuments, factory: this.createModel }}
                                enableAggregators
                                showColumnSelector
                                columnSelectorPosition="right"
                                >
                                <Column id={1} title={TextResources.JobOrder.DocTypeColumnTitle} style={{ width: '50px' }} sorter={sortString(r => r.DocType)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.DocType}>
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.JobOrder.DocTypeColumnTitle}
                                                <TableFilter
                                                    filterSource={this.Documents}
                                                    itemKeyGetter={(doc: IJobOrderDocument) => doc.DocType}
                                                    itemLabelGetter={(doc: IJobOrderDocument) => doc.DocType}

                                                    onSelectionChange={this.onDocTypeSelectionChange.bind(this)}
                                                />
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(doc: ITableItem<IJobOrderDocument>) => <span>{doc.Data.model.DocType}</span>}
                                    </ColumnBody>
                                </Column>
                                <Column id={2} title={TextResources.JobOrder.DocNumberColumnTitle} style={{ width: '150px', minWidth: '150px' }} sorter={sortString(r => r.Number)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.Number}>
                                    <ColumnBody>
                                        {(item: ITableItem<IJobOrderDocument>) => (
                                            <div className="flex-container">
                                                <DocumentClosingStatusIndicator
                                                    documentId={item.Data.model.Id} 
                                                    documentType={item.Data.model.DocType}
                                                    protocolId={item.Data.model.FkRegistroIva}
                                                    documentCauseLogicType={item.Data.model.CauseLogicType}
                                                    documentClosingState={item.Data.model.State}
                                                    documentState={item.Data.model.FKEstimateState}
                                                    className="btn-xs"
                                                    content={<span>{item.Data.model.Number}</span>}
                                                />
                                                <div className="flex-container" style={{ marginLeft: 'auto' }}>
                                                    <span className="btn btn-primary btn-xs pull-right" title="Visualizza Mappa Riferimenti" data-bind={{ click: vm.ShowReferencesMap.bind(vm, $data.model) }}><i className="fa fa-chain"></i></span>
                                                    {((item.Data.model.DocType == 'DOC' || item.Data.model.DocType == 'DOA') && !item.Data.model.ForPartialInvoicesRegister) 
                                                        && <span className="btn btn-primary btn-xs pull-right" title="Visualizza Scadenze" data-bind={{ click: vm.ShowExpirires.bind(vm, $data.model) }}><i className="fa fa-calendar"></i></span>}
                                                </div>
                                            </div>
                                        )}
                                    </ColumnBody>
                                    
                                    <a className="btn btn-xs icon" href="#" style={{ display: 'inline-block', minWidth: '21px' }} data-bind={{ click: vm.OpenDocument.bind(vm, $data.model), text: $data.model.Number, attr: { 'data-state': $data.model.State } }}></a>
                                    
                                </Column>
                                <Column id={3} title={TextResources.JobOrder.ProtocolColumnTitle} sorter={sortString(r => r.ProtocolName)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.ProtocolName} cssClasses="text-ellipsis">
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.JobOrder.ProtocolColumnTitle}
                                                <TableFilter
                                                    filterSource={this.Documents}
                                                    itemKeyGetter={(doc: IJobOrderDocument) => doc.FkRegistroIva}
                                                    itemLabelGetter={(doc: IJobOrderDocument) => doc.ProtocolName}

                                                    onSelectionChange={this.onProtocolsSelectionChange.bind(this)}
                                                />
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(doc: ITableItem<IJobOrderDocument>) => <span>{doc.Data.model.ProtocolName}</span>}
                                    </ColumnBody>
                                </Column>
                                <Column id={4} title={TextResources.JobOrder.DocDateColumnTitle} data-bind={{ dateText: $data.model.DocDate }} sorter={sortDate(r => r.DocDate)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.DocDate}></Column>
                                <Column id={5} title={TextResources.JobOrder.CustomerColumnTitle} sorter={sortString(r => r.Customer)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.Customer} style={{ width: '30%' }} cssClasses="text-ellipsis">
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.JobOrder.CustomerColumnTitle}
                                                <TableFilter
                                                    filterSource={this.Documents}
                                                    itemKeyGetter={(doc: IJobOrderDocument) => doc.Customer}
                                                    itemLabelGetter={(doc: IJobOrderDocument) => doc.Customer}

                                                    onSelectionChange={this.onCustomersSelectionChange.bind(this)}
                                                />
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(doc: ITableItem<IJobOrderDocument>) => <span>{doc.Data.model.Customer}</span>}
                                    </ColumnBody>
                                </Column>
                                <Column id={6} title={TextResources.JobOrder.TaxableTotalColumnTitle} data-bind={{ moneyText: $data.model.TaxableTotal }} sorter={sortNumber(r => r.TaxableTotal)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.TaxableTotal} aggregationFormatter={(value: number) => numeral(value).format("0,0.00 $")}></Column>
                                <Column id={7} title={TextResources.JobOrder.NotTaxableTotalColumnTitle} data-bind={{ moneyText: $data.model.NotTaxableTotal }} sorter={sortNumber(r => r.NotTaxableTotal)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.NotTaxableTotal} aggregationFormatter={(value: number) => numeral(value).format("0,0.00 $")}></Column>
                                <Column id={8} title={TextResources.JobOrder.TotalColumnTitle} data-bind={{ moneyText: $data.model.Total }} sorter={sortNumber(r => r.Total)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.Total} aggregationFormatter={(value: number) => numeral(value).format("0,0.00 $")}></Column>
                                <Column id={9} title={TextResources.JobOrder.ExternalReferenceNumberColumnTitle} sorter={sortString(r => r.ReferenceNumber)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.ReferenceNumber} cssClasses="text-ellipsis">
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.JobOrder.ExternalReferenceNumberColumnTitle}
                                                <TableFilter
                                                    filterSource={this.Documents}
                                                    itemKeyGetter={(doc: IJobOrderDocument) => doc.ReferenceNumber}
                                                    itemLabelGetter={(doc: IJobOrderDocument) => doc.ReferenceNumber}

                                                    onSelectionChange={this.onExternalReferenceNumbersSelectionChange.bind(this)}
                                                />
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(doc: ITableItem<IJobOrderDocument>) => <span>{doc.Data.model.ReferenceNumber}</span>}
                                    </ColumnBody>
                                </Column>
                                <Column id={10} title={TextResources.JobOrder.ExternalReferenceDateColumnTitle} data-bind={{ dateText: $data.model.ReferenceDate }} sorter={sortDate(r => r.ReferenceDate)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.ReferenceDate}></Column>
                                <Column id={11} title={TextResources.JobOrder.ExternalReferenceColumnTitle} sorter={sortString(r => r.ExternalReference)} aggregateOn={(doc: ITableItem<IJobOrderDocument>) => doc.Data.model.ExternalReference} cssClasses="text-ellipsis">
                                    <ColumnHeader>
                                        {() => (
                                            <>
                                                {TextResources.JobOrder.ExternalReferenceColumnTitle}
                                                <TableFilter
                                                    filterSource={this.Documents}
                                                    itemKeyGetter={(doc: IJobOrderDocument) => doc.ExternalReference}
                                                    itemLabelGetter={(doc: IJobOrderDocument) => doc.ExternalReference}

                                                    onSelectionChange={this.onExternalReferencesSelectionChange.bind(this)}
                                                />
                                            </>
                                        )}
                                    </ColumnHeader>
                                    <ColumnBody>
                                        {(doc: ITableItem<IJobOrderDocument>) => <span>{doc.Data.model.ExternalReference}</span>}
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </LayoutContent>
                    </LayoutWithHeader>
                </div>
            </BindTo>
        );
    }
}

class DocumentDetailsDialog implements IDialog {
    templateUrl:string = "joborder/templates/joborderdetail";
    templateName:string = "document-details";
    title:string = ProlifeSdk.TextResources.JobOrder.DocumentDetails;

    modal: {
        close: (result?: any) => void;
    };

    SourceWarehouseId : ko.Observable<number> = ko.observable();
    DestinationWarehouseId : ko.Observable<number> = ko.observable();
    AdministrativeNotes : ko.Observable<string> = ko.observable();

    constructor(private parent : JobOrderDocuments, private document : IJobOrderDocument) {
        this.SourceWarehouseId(document.SourceWarehouseId);
        this.DestinationWarehouseId(document.DestinationWarehouseId);
        this.AdministrativeNotes(document.Note);
    }

    close():void {
        this.modal.close();
    }

    action():void {
    }

    OpenSourceWarehouseStock() {
        this.parent.OpenSourceWarehouseStock(this.document);
    }

    OpenDestinationWarehouseStock() {
        this.parent.OpenDestinationWarehouseStock(this.document);
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    //reloadNow(JobOrderDocuments);
}

