import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { CurrenciesDataSource } from "../../../../DataSources/CurrenciesDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ICurrenciesSettingsManager } from "../CurrenciesSettingsManager";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { NationsDataSource } from "../../../../DataSources/NationsDataSource";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { IDataSourceListener, IDataSourceModel, IDataSource } from "../../../../DataSources/IDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ICurrencyWithCountriesIds } from "../../../interfaces/ICurrenciesService";

const { classes } = jss.createStyleSheet({
    currenciesPortlet: {
        "width": "60%",

        "& .portlet": {
            width: "100%"
        },

        "& .portlet-body": {
            "display": "flex",
            "flex-wrap": "nowrap",
            "flex-grow": "1",
            "flex-direction": "column",

            "& list": {
                height: "100%",

                "& .list-notification-container": {
                    height: "100% !important"
                }
            }
        }
    },

    currencyFormPortlet: {
        "width": "40%",

        "& .portlet": {
            width: "100%"
        },

        "& .portlet-body": {
            display: "flex",
            "flex-grow": "1"
        }
    },

    currencyForm: {
        width: "100%",

        "& .nations-button-wrapper": {
            margin: "10px 0",

            "& button": {
                "margin-left": "15px"
            }
        },

        "& .form-actions": {
            "& button": {
                "margin-left": "5px"
            }
        },

        "& .nations-list-wrapper": {
            position: "relative",

            "& .nations-list": {
                "overflow-y": "auto",
                position: "absolute",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
            }
        }
    }
}).attach();

let attributes = {
    
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "currencies-editor": {
               params?: {
                   
               };
               
           } & HTMLAttributes<HTMLElement>
       }
   }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICurrenciesEditorParams {

}

export class CurrenciesEditor implements IDataSourceListener, ICurrencyFormObserver {
    public title: string = "";

    public TextFilter: ko.Observable<string> = ko.observable();

    public CurrencyForm: CurrencyForm;
    public CurrenciesDataSource: CurrenciesDataSource;
    
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(params : ICurrenciesEditorParams) {
        this.title = this.currenciesSettingsManager.getLabel();

        this.CurrencyForm = new CurrencyForm();
        this.CurrenciesDataSource = new CurrenciesDataSource();
        
        this.CurrencyForm.addObserver(this);
        this.CurrencyForm.setCurrency();
    }
    
    public isChanged(): boolean {
        return this.CurrencyForm.selectedCurrencyHasChanges();
    }

    public onCurrencyCreationStarted(): void {
        this.CurrenciesDataSource.select();
    }
    
    public onCurrencySaved(updatedCurrency: ICurrencyWithCountriesIds): void {
        this.CurrenciesDataSource.refresh();

        this.CurrenciesDataSource.getById(null, [updatedCurrency.Id])
            .then((models: IDataSourceModel<number, ICurrencyWithCountriesIds>[]) => {
                this.CurrenciesDataSource.select(...models);
            });
    }
    
    public onCurrencyDeleted(updatedCurrency: ICurrencyWithCountriesIds): void {
        this.CurrencyForm.setCurrency();
        this.CurrenciesDataSource.refresh();
        this.CurrenciesDataSource.select();
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.CurrencyForm.setCurrency(!model ? undefined : model.model);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.CurrencyForm.setCurrency(undefined);
    }
    
    public async canSelectItem(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): Promise<boolean> {
        return this.CurrencyForm.canChangeSelectedCurrency();
    }
}

export class CurrencyForm {
    public SelectedCurrency: ko.Observable<Currency> = ko.observable();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    private observers: ICurrencyFormObserver[] = [];

    public setCurrency(currency: ICurrencyWithCountriesIds = null) {
        currency = currency || this.createCurrencyModel();
        this.SelectedCurrency(new Currency(currency));
    }
    
    public addObserver(observer: ICurrencyFormObserver): void {
        if (this.observers.indexOf(observer) < 0)
            this.observers.push(observer);
    }

    public removeObserver(observer: ICurrencyFormObserver): void {
        let index = this.observers.indexOf(observer);

        if (index >= 0)
            this.observers.splice(index, 1);
    }

    public async createNewCurrency(): Promise<void> {
        let confirm = await this.canChangeSelectedCurrency();

        if (!confirm)
            return;

        this.setCurrency();
        this.observers.forEach(o => o.onCurrencyCreationStarted());
    }

    public async saveCurrency(): Promise<void> {
        let selectedCurrency = this.SelectedCurrency();
        
        if (!selectedCurrency || !selectedCurrency.Validate())
            return;

        let model = selectedCurrency.GetData();
        let updatedCurrency = (await this.currenciesSettingsManager.saveCurrencies([model])).firstOrDefault();

        this.infoToastService.Success(TextResources.Invoices.SaveCurrencySuccess);
        selectedCurrency.isChanged(0);

        this.observers.forEach(o => o.onCurrencySaved(updatedCurrency));
    }

    public async deleteCurrency(): Promise<void> {
        let selectedCurrency = this.SelectedCurrency();

        if (!selectedCurrency || !selectedCurrency.Id || selectedCurrency.Id <= 0)
            return;

        let confirm: boolean = true;

        confirm = await this.dialogsService.ConfirmAsync(TextResources.Invoices.CurrencyDeleteConfirmMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);

        if (!confirm)
            return;

        let data = selectedCurrency.GetData();
        data.Deleted = true;

        let updatedCurrency = (await this.currenciesSettingsManager.saveCurrencies([data])).firstOrDefault();
        this.infoToastService.Success(TextResources.Invoices.CurrencyDeleteSuccess);

        this.observers.forEach(o => o.onCurrencyDeleted(updatedCurrency));
    }

    public selectedCurrencyHasChanges(): boolean {
        let currency = this.SelectedCurrency();

        if (!currency || (currency && currency.isChanged() === 0 && currency.Countries().filter(c => c.isChanged() != 0).length === 0))
            return false;

        return true;
    }

    public async canChangeSelectedCurrency(): Promise<boolean> {
        if (!this.selectedCurrencyHasChanges())
            return true;

        return this.showChenageSelectedCurrencyConfirmPrompt();
    }

    public async showChenageSelectedCurrencyConfirmPrompt(): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(TextResources.Invoices.CurrencyPendingChangesMessage, TextResources.ProlifeSdk.No, TextResources.ProlifeSdk.Yes);
    }

    private createCurrencyModel(): ICurrencyWithCountriesIds {
        return {
            Id: null,
            Name: null,
            Symbol: null, 
            CodeISO4217alpha3: null,
            FractionalUnit: null,
            Deleted: false,
            CountriesIds: []
        };
    }
}

export interface ICurrencyFormObserver {
    onCurrencyCreationStarted(): void;
    onCurrencySaved(updatedCurrency: ICurrencyWithCountriesIds): void;
    onCurrencyDeleted(updatedCurrency: ICurrencyWithCountriesIds): void;
}

@DetectClassChanges
export class Currency {
    public get Id(): number {
        return this.currency?.Id;
    }

    @DetectChanges
    public Name: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Code: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Symbol: ko.Observable<string> = ko.observable();
    @DetectChanges
    public FractionalUnit: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Countries: ko.ObservableArray<CurrencyCountryAssociation> = ko.observableArray([]);
    
    public isChanged: ko.Observable<number> = ko.observable(0);

    public canBeDeleted: ko.Observable<boolean> = ko.observable(false);

    public NationsDataSource: NationsDataSource;
    
    private currency: ICurrencyWithCountriesIds;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(currency: ICurrencyWithCountriesIds) {
        this.NationsDataSource = new NationsDataSource();
        this.LoadFromModel(currency);
    }

    public Validate(): boolean {
        if (!this.Code() || this.Code().length != 3) {
            this.infoToastService.Warning(TextResources.Invoices.InvalidCurrencyCodeError);
            return false;
        }

        if (!this.Symbol()) {
            this.infoToastService.Warning(TextResources.Invoices.InvalidCurrencySymbolError);
            return false;
        }

        if (this.Countries().filter(c => !c.CountryId()).length > 0) {
            this.infoToastService.Warning(TextResources.Invoices.CurrencyCountryAssociationError);
            return false;
        }

        return true;
    }

    public LoadFromModel(currency: ICurrencyWithCountriesIds): void {
        this.currency = currency;
        
        this.Name(currency.Name);
        this.Code(currency.CodeISO4217alpha3);
        this.Symbol(currency.Symbol);
        this.FractionalUnit(currency.FractionalUnit);
        this.Countries(currency.CountriesIds.map(i => new CurrencyCountryAssociation(i, this.NationsDataSource)));

        this.canBeDeleted(!!currency.Id);
        this.isChanged(0);
    }

    public GetData(): ICurrencyWithCountriesIds {
        let data: ICurrencyWithCountriesIds = $.extend(true, {}, this.currency);

        data.Name = this.Name();
        data.CodeISO4217alpha3 = this.Code();
        data.Symbol = this.Symbol();
        data.FractionalUnit = this.FractionalUnit();
        data.CountriesIds = this.Countries().map((c) => c.CountryId());

        return data;
    }

    public AddCountryAssociation(): void {
        this.Countries.push(new CurrencyCountryAssociation(undefined, this.NationsDataSource));
    }

    public RemoveCountryAssociation(association: CurrencyCountryAssociation): void {
        let index = this.Countries().indexOf(association);

        if (index >= 0)
            this.Countries.splice(index, 1);
    }

    public dispose(): void {

    }
}

@DetectClassChanges
export class CurrencyCountryAssociation {
    public CountryId: ko.Observable<number> = ko.observable();
    public isChanged: ko.Observable<number> = ko.observable(0);

    constructor(countryId: number, public NationsDataSource: NationsDataSource) {
        this.CountryId(countryId);
        this.isChanged(0);
    }

    public dispose(): void {}
}

ko.components.register("currencies-editor", {
    viewModel: {
        createViewModel: (params: ICurrenciesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new CurrenciesEditor(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="flex-container flex-vertical" style="height: 100%">
                    <h3 class="page-title">
                        <span data-bind="text: title"></span>
                    </h3>
                    <div class="flex-container flex-fill">
                        <portlet class={classes.currenciesPortlet + " flex-container flex-fill flex-vertical"} portlet-title={TextResources.Invoices.CurrenciesLabel} view-model={() => "$data"}>
                            <text-input placeholder={TextResources.ProlifeSdk.SearchPlaceholder} value={() => "TextFilter"} valueUpdate="afterkeydown"></text-input>
                            <list class="flex-fill" dataSource={() => "CurrenciesDataSource"} containerHeight="100%" listener={() => "$data"} textFilter={() => "TextFilter"}></list>
                        </portlet>
                        <portlet class={"flex-container flex-fill flex-vertical " + classes.currencyFormPortlet} portlet-title={TextResources.Invoices.CurrencyFormLabel} view-model={() => "CurrencyForm"}>
                            <div class={"form flex-container flex-vertical " + classes.currencyForm}>
                                <div class="form-body flex-container flex-vertical flex-fill" data-bind="with: SelectedCurrency">
                                    <text-input value={() => "Code"} maxLength={3} label={TextResources.Invoices.CurrencyCodeFormLabel} placeholder={TextResources.Invoices.CurrencyCodeFormLabel} helpText={TextResources.Invoices.CurrencyCodeHelpText}></text-input>
                                    <text-input value={() => "Symbol"} label={TextResources.Invoices.CurrencySymbolFormLabel} placeholder={TextResources.Invoices.CurrencySymbolFormLabel}></text-input>
                                    <text-input value={() => "Name"} label={TextResources.Invoices.CurrencyNameFormLabel} placeholder={TextResources.Invoices.CurrencyNameFormLabel}></text-input>
                                    <div class="form-group">
                                        <label class="control-label">{TextResources.Invoices.CurrencyFractionalUnitFormLabel}</label>
                                        <input class="form-control" type="text" data-bind="value: FractionalUnit" placeholder={TextResources.Invoices.CurrencyFractionalUnitFormLabel}></input>
                                    </div>
                                    <div class="form-group nations-button-wrapper">
                                        <label>{TextResources.Invoices.CurrencyCountriesLabel}</label>
                                        <button type="button" class="btn btn-primary btn-xs" data-bind="click: AddCountryAssociation">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="flex-fill nations-list-wrapper">
                                        <div class="nations-list" data-bind="slimScroll: '100%'">
                                            <ko-if data-bind="Countries().length === 0">
                                                <div class="text-center">
                                                    {TextResources.Invoices.EmptyCurrencyCountriesAssociation}
                                                </div>
                                            </ko-if>

                                            <ko-foreach data-bind="Countries">
                                                <select2 dataSource={() => "NationsDataSource"} value={() => "CountryId"} placeholder={TextResources.ProlifeSdk.Select2Placeholder}>
                                                    <button type="button" class="btn btn-danger" data-bind="click: $parents[2].RemoveCountryAssociation.bind($parents[2], $data)">
                                                        <i class="fa fa-trash-o"></i>
                                                    </button>
                                                </select2>
                                            </ko-foreach>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions text-right">
                                    <button type="button" class="btn btn-success" data-bind="click: createNewCurrency">{TextResources.Invoices.NewCurrencyButton}</button>
                                    <button type="button" class="btn btn-danger" data-bind="click: deleteCurrency, enable: SelectedCurrency() && SelectedCurrency().canBeDeleted()">{TextResources.Invoices.DeleteCurrencyButton}</button>
                                    <button type="button" class="btn btn-primary" data-bind="click: saveCurrency">{TextResources.Invoices.SaveCurrencyButton}</button>
                                </div>
                            </div>
                        </portlet>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});