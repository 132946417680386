import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { BasePagedList } from "../Utilities/BasePagedList";
import {
    IArticle,
    IArticlePurchase,
    IArticlesService,
    IArticlesServiceObserver,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { Article } from "./Article";
import { Delay } from "../../../../Decorators/Delay";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { DeleteArticlesResults } from "../../../enums/DeleteArticleResults";
import { DocumentsReferencingArticleDialog } from "./DocumentsReferencingArticleDialog";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { CreateNewArticleDialog } from "./CreateNewArticleDialog";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Purchase } from "./Purchase";
import { EditPurchaseModal } from "./EditPurchaseModal";
import { ArticleDetailsDialog } from "./ArticleDetailsDialog";

const styleSheet = jss.createStyleSheet({
    "purchases-management": {
        position: "absolute",
        top: "120px",
        bottom: "60px",
        left: "20px",
        right: "20px",
        overflowY: "auto",

        "& table.table > thead": {
            "& > tr": {
                position: "sticky",
                top: 0,

                "& > th": {
                    fontSize: "0.9em",
                },
            },
        },
    },

    pagination: {
        position: "absolute",
        bottom: 0,
        left: "20px",

        "& .pagination": {
            "& > li": {
                "& > button": {
                    position: "relative",
                    float: "left",
                    padding: "6px 12px",
                    marginLeft: "-1px",
                    lineHeight: "1.42857143",
                    color: "#428bca",
                    textDecoration: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                },
            },

            "& .active": {
                "& > button": {
                    background: "#eee",
                    borderColor: "#dddddd",
                    color: "#333",
                },
            },
        },
    },
});

const { classes } = styleSheet.attach();

type PurchasesManagementProps = {
    searchFilter: ko.Observable<string>;
    selectedCatalog: ko.Observable<number>;
    forwardRef?: (list: BasePagedList) => void;
};

export function PurchasesManagement(props: PurchasesManagementProps) {
    const C = require("./PurchasesManagement")._PurchasesManagement as typeof _PurchasesManagement;
    return <C {...props} />;
}

export class _PurchasesManagement
    extends BasePagedList<IArticlePurchase, Purchase>
    implements IArticlesServiceObserver
{
    static defaultProps: Partial<PurchasesManagementProps> = {};

    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public CanDeletePurchases: ko.Observable<boolean> = ko.observable(false);

    constructor(private props: PurchasesManagementProps) {
        super(props.searchFilter);

        this.CanDeletePurchases(this.authorizationsService.isAuthorized("Warehouse_DeletePurchase"));
    }

    componentDidMount() {
        if (this.props.forwardRef) this.props.forwardRef(this);
        this.articlesService.addObserver(this);
        this.subscriptions.push(this.props.selectedCatalog.subscribe(this.internalLoadRows.bind(this)));

        this.loadRows();
    }

    componentWillUnmount() {
        super.dispose();
        this.articlesService.removeObserver(this);
    }

    onArticleChanged(_: IArticle): void {
        this.loadRows();
    }

    onArticleAdded(_: IArticle): void {
        this.loadRows();
    }

    onArticleDeleted(_: number): void {
        this.loadRows();
    }

    @Delay(100)
    private internalLoadRows() {
        this.loadRows();
    }

    public fetchRows(): Promise<IArticlePurchase[]> {
        return this.articlesService.searchPurchases(
            this.searchFilter(),
            null,
            false,
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage
        );
    }

    public fetchRowsCount(): Promise<number> {
        return this.articlesService.searchPurchasesCount(this.searchFilter(), null, false);
    }

    public createViewModelFor(purchase: IArticlePurchase): Purchase {
        return new Purchase(purchase);
    }

    public editPurchase(purchase: Purchase) {
        const modal = new EditPurchaseModal(purchase);
        modal.show();
    }

    public async deletePurchase(purchase: Purchase): Promise<void> {
        const confirm = await this.dialogService.ConfirmAsync(
            ProlifeSdk.TextResources.Warehouse.DeleteArticleConfirmMessage,
            ProlifeSdk.TextResources.Warehouse.DeleteArticleCancel,
            ProlifeSdk.TextResources.Warehouse.DeleteArticleConfirm
        );

        if (!confirm) return;

        const deleteResult = await this.articlesService.deleteArticle(purchase.id);

        if (deleteResult.Result === DeleteArticlesResults.ERROR_FOR_ARTICLE_USED_IN_DOCUMENTS) {
            const choice = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorMessage,
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorCancel,
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorConfirm
            );

            if (!choice) {
                const documentsDialog = new DocumentsReferencingArticleDialog(purchase.id);
                await documentsDialog.show();
            }

            return;
        }

        if (deleteResult.Result === DeleteArticlesResults.SUCCESS) {
            this.infoToastService.Success(ProlifeSdk.TextResources.Warehouse.DeleteArticleSuccess);
            this.loadRows();
        }
    }

    public showPurchaseDetails(purchase: Purchase) {
        const dialog: ArticleDetailsDialog = new ArticleDetailsDialog(
            purchase.id,
            purchase.Code(),
            purchase.Description()
        );

        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    render() {
        const am = this;
        const row: Purchase = null;
        const page = 0;

        return ComponentUtils.bindTo(
            <>
                <Layout.ScrollContainer systemScrollable verticalOnly className={classes["purchases-management"]}>
                    <table className="table table-advance">
                        <thead>
                            <tr>
                                <th>{TextResources.Warehouse.ArticleCode}</th>
                                <th>{TextResources.Warehouse.ArticleDescription}</th>
                                <th>{TextResources.Warehouse.ArticleType}</th>
                                <th>{TextResources.Warehouse.ArticleUoM}</th>
                                <th style={{ textAlign: "right" }} className="text-right">
                                    {TextResources.Warehouse.ArticleDealerPrice}
                                </th>
                                <th style={{ textAlign: "right" }} className="text-right">
                                    {TextResources.Warehouse.ArticleCustomerPrice}
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody data-bind={{ foreach: { data: am.rows, as: "row" } }}>
                            <tr>
                                <td data-bind={{ text: row.Code }}></td>
                                <td data-bind={{ text: row.Description }}></td>
                                <td data-bind={{ text: row.PurchaseType }}></td>
                                <td data-bind={{ text: row.Uom }}></td>
                                <td
                                    style={{ textAlign: "right" }}
                                    data-bind={{ moneyText: row.DealerPrice, extendedMoneyFormat: true }}
                                ></td>
                                <td
                                    style={{ textAlign: "right" }}
                                    data-bind={{ moneyText: row.CustomerPrice, extendedMoneyFormat: true }}
                                ></td>
                                <td
                                    style={{ textAlign: "right", width: "220px" }}
                                    data-bind={{ style: { width: am.CanDeletePurchases() ? "320px" : "220px" } }}
                                >
                                    <button
                                        style={{ width: "85px", textAlign: "left" }}
                                        className="btn btn-xs default"
                                        data-bind={{ click: am.editPurchase.bind(am, row) }}
                                    >
                                        <i className="fa fa-pencil"></i>&nbsp;{TextResources.Warehouse.EditArticle}
                                    </button>
                                    <button
                                        style={{ width: "95px", textAlign: "left" }}
                                        className="btn btn-xs default"
                                        data-bind={{ click: am.showPurchaseDetails.bind(am, row) }}
                                    >
                                        <i className="fa fa-eye"></i>&nbsp;{TextResources.Warehouse.ArticleMovements}
                                    </button>
                                    {this.CanDeletePurchases() && (
                                        <button
                                            style="width : 95px;text-align: left"
                                            className="btn btn-xs btn-danger"
                                            data-bind={{ click: am.deletePurchase.bind(am, row) }}
                                        >
                                            <i className="fa fa-trash-o"></i>&nbsp;
                                            {TextResources.Warehouse.DeleteArticle}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Layout.ScrollContainer>

                <div className={classes.pagination}>
                    <ul className="pagination">
                        <li data-bind={{ css: { disabled: am.isOnFirstPage() || am.SomeFilterIsChanged() } }}>
                            <button data-bind={{ click: am.goToPrevPage }}>
                                &larr; {TextResources.Warehouse.ArticlesPaginationPrev}
                            </button>
                        </li>

                        <ko-bind data-bind={{ foreach: { data: am.pages, as: "page" } }}>
                            <li
                                data-bind={{
                                    css: { disabled: am.SomeFilterIsChanged(), active: am.selectedPage() == page },
                                }}
                            >
                                <button data-bind={{ text: page + 1, click: am.goToPage.bind(am, page) }}></button>
                            </li>
                        </ko-bind>

                        <li data-bind={{ css: { disabled: am.isOnLastPage() || am.SomeFilterIsChanged() } }}>
                            <button data-bind={{ click: am.goToNextPage }}>
                                {TextResources.Warehouse.ArticlesPaginationNext} &rarr;
                            </button>
                        </li>
                    </ul>
                </div>
            </>,
            this,
            "am"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PurchasesManagement);
}
