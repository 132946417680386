import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/06/2018
 * Time: 14:55
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { MonthlyInvoicingDataRow } from "../../../../ProlifeSdk/prolifesdk/documents/wizard/MonthlyInvoicingDataRow";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { BaseDataSourceForMonthlyInvoicing } from "../wizard/data-sources/BaseDataSourceForMonthlyInvoicing";
import { UngroupedCustomersDataSource } from "../../../../DataSources/UngroupedCustomersDataSource";
import { WarehousesDataSource } from "../../../../DataSources/WarehousesDataSource";
import { CurrencyUtils } from "../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import {
    IDataSourceForMonthlyInvoicing,
    IMonthlyInvoicingDataSource,
    IMonthlyInvoicingDataSourceBaseFilters,
    IMonthlyInvoicingOptions,
} from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import {
    IInvoicesService,
    IGetDdtsForMonthlyInvoicingWizardRequest,
    IDdtForMonthlyInvoicingWizard,
} from "../../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IEntitiesDataSourceForMonthlyInvoicingWrapper } from "../../../interfaces/IEntitiesToImportForMonthlyInvoicingWrapper";
import {
    IEntityToImportInfoForMonthlyInvoicing,
    ILabelInfo,
} from "../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IDDTToImportForMonthlyInvoicingInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDDTToImportForMonthlyInvoicingInfo";
import { IDocumentCurrencyViewModel } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IEntityForMonthlyInvoicingTree } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IDDTCause, IDDTCauses } from "../../../../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";
import { IVatRegister, IVatRegisters } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { ICustomer } from "../../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";

export class DdtDataSourceForMonthlyInvoicing
    extends BaseDataSourceForMonthlyInvoicing
    implements IEntitiesDataSourceForMonthlyInvoicingWrapper, IDataSourceForMonthlyInvoicing
{
    public SourceWarehouse: ko.Observable<number> = ko.observable(null);
    public SourceWarehouseCustomer: ko.Observable<number> = ko.observable(null);
    public Protocol: ko.Observable<number> = ko.observable(null);
    public Cause: ko.Observable<number> = ko.observable(null);
    public WithNoJobOrder: ko.Observable<boolean> = ko.observable(false);

    public EntitiesList: ko.ObservableArray<IEntityToImportInfoForMonthlyInvoicing> = ko.observableArray();
    public SelectedEntities: ko.ObservableArray<IEntityToImportInfoForMonthlyInvoicing> = ko.observableArray([]);
    public DocumentsToImport: ko.ObservableArray<IEntityToImportInfoForMonthlyInvoicing> = ko.observableArray([]);

    public AllSelected: ko.Computed<boolean>;

    public SourceWarehouseCustomersDataSource: UngroupedCustomersDataSource;
    public WarehousesDataSource: WarehousesDataSource;

    private wizard: IMonthlyInvoicingDataSource;

    private causes: ko.ObservableArray<IDDTCause> = ko.observableArray([]);
    private protocols: ko.ObservableArray<IVatRegister> = ko.observableArray([]);

    private WatchesInstalled: boolean = false;
    private IsLoading: boolean = false;

    @LazyImport(ProlifeSdk.InvoicesServiceType)
    private invoicesService: IInvoicesService;
    @LazyImportSettingManager(ProlifeSdk.DDTCause)
    private causesManager: IDDTCauses;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    constructor() {
        super(
            "ddts-for-monthly-invoicing",
            "invoices/templates/wizard/data-sources",
            ProlifeSdk.TextResources.Invoices.Delivery
        );

        this.documentsService.registerDocumentDataSourceForMonthlyInvoicing(this);
        this.registerSupportedDestinationDocumentTypeCodes();

        this.SourceWarehouseCustomersDataSource = new UngroupedCustomersDataSource();
        this.WarehousesDataSource = new WarehousesDataSource();

        this.AllSelected = ko.computed({
            read: () => {
                return this.SelectedEntities().length == this.EntitiesList().length;
            },
            write: (selected: boolean) => {
                this.EntitiesList().forEach((ddt: IDDTToImportForMonthlyInvoicingInfo) => ddt.Selected(selected));
            },
        });
    }

    public Initialize() {
        this.DisableDocumentsReload = true;

        super.Initialize();

        this.Cause(null);
        this.Protocol(null);
        this.SourceWarehouse(null);
        this.SourceWarehouseCustomer(null);
        this.WithNoJobOrder(false);

        this.SelectedEntities([]);
        this.DocumentsToImport([]);

        this.causes(
            this.causesManager.getDDTCauses().filter((c: IDDTCause) => c.Tipologia == ProlifeSdk.DDTCausalSaleType)
        );
        this.protocols(this.vatRegisters.getVatRegisters().filter((r) => r.TipoDocumento === ProlifeSdk.DdtTypeId));

        this.DisableDocumentsReload = false;

        if (this.loadRowsAfterInitialize) {
            this.loadDdts().finally(this.installWatches.bind(this));
        } else {
            this.installWatches();
        }
    }

    public InitializeFilters(filters: IMonthlyInvoicingDataSourceBaseFilters): void {
        super.InitializeFilters(filters);
        this.loadDdts();
    }

    public HasAuthorization(): boolean {
        return (
            this.authorizationsService.isAuthorized("Documents_ViewDDTs") ||
            this.authorizationsService.isAuthorized("Documents_DDTs")
        );
    }

    public LoadNextDdtsBlock(): void {
        if (this.DisableDocumentsReload) return;

        let request: IGetDdtsForMonthlyInvoicingWizardRequest = {
            From: this.DateFrom(),
            To: this.DateTo(),
            CustomerId: this.Customer(),
            ProtocolId: this.Protocol(),
            JobOrderId: this.JobOrder(),
            JobOrderTypeId: this.JobOrderType(),
            SourceWarehouseId: this.SourceWarehouse(),
            SourceWarehouseCustomerId: this.SourceWarehouseCustomer(),
            CauseId: this.Cause(),
            Skip: 0,
            Count: 1000000,
        };

        this.invoicesService.GetDdtsForMonthlyInvoicingWizard(request).then(this.ddtsLoadedForAppend.bind(this));
    }

    public async GetDocumentsToImportInfo(): Promise<IEntityToImportInfoForMonthlyInvoicing[]> {
        let ddts: IEntityToImportInfoForMonthlyInvoicing[] = [];

        for (let d of this.DocumentsToImport()) {
            const ddt = await d.GetCopy();
            ddts.push(ddt);
        }

        return ddts;
    }

    public Validate(): boolean {
        return true;
    }

    public SetDataSourceOptions(monthlyInvoicingOptions: IMonthlyInvoicingOptions): void {}

    public SetWizard(wizard: IMonthlyInvoicingDataSource): void {
        this.wizard = wizard;
    }

    public onCustomerSelected(e, newValue) {
        this.SourceWarehouse(null);
    }

    public AddSelectedDocumentsToImportList(): void {
        var skipped: number = 0;
        this.SelectedEntities().forEach((ddt: IEntityToImportInfoForMonthlyInvoicing) => {
            if (
                this.DocumentsToImport().filter((d: IEntityToImportInfoForMonthlyInvoicing) => d.Id == ddt.Id).length >
                0
            ) {
                skipped++;
                return;
            }

            this.DocumentsToImport.push(ddt);
        });

        if (skipped > 0) this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ImportDdtsWarning);
    }

    public RemoveDocumentFromImportList(document: IEntityToImportInfoForMonthlyInvoicing): void {
        this.DocumentsToImport.remove(document);
    }

    public EmptiesImportList(): void {
        this.DocumentsToImport([]);
    }

    private registerSupportedDestinationDocumentTypeCodes(): void {
        this.SupportedDestinationDocumentsTypesCodes.push(ProlifeSdk.InvoiceEntityTypeCode);
    }

    private loadDdts(): Promise<any> {
        if (this.DisableDocumentsReload || this.IsLoading) return Promise.resolve();

        this.IsLoading = true;

        this.EntitiesList([]);

        let request: IGetDdtsForMonthlyInvoicingWizardRequest = {
            From: this.DateFrom(),
            To: this.DateTo(),
            CustomerId: this.Customer(),
            ProtocolId: this.Protocol(),
            JobOrderId: this.JobOrder(),
            JobOrderTypeId: this.JobOrderType(),
            SourceWarehouseId: this.SourceWarehouse(),
            SourceWarehouseCustomerId: this.SourceWarehouseCustomer(),
            CauseId: this.Cause(),
            Skip: this.EntitiesList().length,
            Count: 1000000,
        };

        return this.invoicesService
            .GetDdtsForMonthlyInvoicingWizard(request)
            .then(this.ddtsLoaded.bind(this))
            .finally(() => {
                setTimeout(() => {
                    this.IsLoading = false;
                }, 200);
            });
    }

    private installWatches() {
        if (this.WatchesInstalled) return;

        this.DisableDocumentsReload = true;

        this.Customer.subscribe(this.loadDdts.bind(this));
        this.JobOrder.subscribe(this.loadDdts.bind(this));
        this.JobOrderType.subscribe(this.loadDdts.bind(this));
        this.SourceWarehouse.subscribe(this.loadDdts.bind(this));
        this.SourceWarehouseCustomer.subscribe((v) => {
            if (!this.SourceWarehouse()) this.loadDdts();

            this.WarehousesDataSource.setCustomerIds(v);
            this.SourceWarehouse(null);
        });
        this.Protocol.subscribe(this.loadDdts.bind(this));
        this.Cause.subscribe(this.loadDdts.bind(this));
        this.WithNoJobOrder.subscribe(this.loadDdts.bind(this));
        this.DateFrom.subscribe(() => {
            if (!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateTo(moment(this.DateFrom()).endOf("day").toDate());

            this.loadDdts();
        });
        this.DateTo.subscribe(() => {
            if (!!this.DateTo() && !!this.DateFrom() && moment(this.DateTo()) < moment(this.DateFrom()))
                this.DateFrom(moment(this.DateTo()).startOf("day").toDate());

            this.loadDdts();
        });

        this.DisableDocumentsReload = false;
        this.WatchesInstalled = true;
    }

    private async ddtsLoaded(ddts: IDdtForMonthlyInvoicingWizard[]): Promise<void> {
        await this.loadJobOrdersAndCustomersData(ddts);

        this.SelectedEntities([]);
        this.EntitiesList(ddts.map(this.createViewModelFor.bind(this)));
        this.AllSelected(true);
    }

    private loadJobOrdersAndCustomersData(ddts: IDdtForMonthlyInvoicingWizard[]): Promise<[void, void]> {
        let jobOrdersToLoad: number[] = [];
        let customersToLoad: number[] = [];

        for (let ddt of ddts) {
            if (ddt.JobOrderId) jobOrdersToLoad.push(ddt.JobOrderId);

            if (ddt.CustomerId) customersToLoad.push(ddt.CustomerId);
        }

        return Promise.all([
            this.wizard.LoadJobOrdersIntoCache(jobOrdersToLoad),
            this.wizard.LoadCustomersIntoCache(customersToLoad),
        ]);
    }

    private ddtsLoadedForAppend(ddts: IDdtForMonthlyInvoicingWizard[]) {
        this.SelectedEntities([]);
        var oldDdts: IEntityToImportInfoForMonthlyInvoicing[] = this.EntitiesList();
        this.EntitiesList(oldDdts.concat(ddts.map(this.createViewModelFor.bind(this))));
    }

    private createViewModelFor(ddt: IDdtForMonthlyInvoicingWizard): IDDTToImportForMonthlyInvoicingInfo {
        var ddtToImport = new Ddt(ddt, this.wizard);
        ddtToImport.storage(this);
        return ddtToImport;
    }
}

class Ddt extends MonthlyInvoicingDataRow implements IDDTToImportForMonthlyInvoicingInfo {
    public templateName: string = "ddt-for-grouping";
    public templateUrl: string = "invoices/templates/wizard/steps";

    public Number: ko.Observable<string> = ko.observable();
    public Date: ko.Observable<Date> = ko.observable();
    public Total: ko.Observable<number> = ko.observable();
    public DocumentLabel: ko.Observable<string> = ko.observable();
    public ProtocolId: ko.Observable<number> = ko.observable();
    public ProtocolName: ko.Observable<string> = ko.observable();
    public CustomerId: ko.Observable<number> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderId: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public SourceWarehouseName: ko.Observable<string> = ko.observable();
    public Cause: ko.Observable<string> = ko.observable();
    public PaymentType: ko.Observable<string> = ko.observable();
    public ExpiryType: ko.Observable<string> = ko.observable();
    public PaymentTypeId: ko.Observable<number> = ko.observable();
    public ExpiryTypeId: ko.Observable<number> = ko.observable();
    public ClosureStatus: ko.Observable<number> = ko.observable();

    public DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;

    private compatibilityRules: any = {};

    private customer: ICustomer;
    private jobOrder: IJobOrder;

    constructor(public ddt: IDdtForMonthlyInvoicingWizard, private wizard: IMonthlyInvoicingDataSource) {
        super();

        this.compatibilityRules[ProlifeSdk.DdtEntityTypeCode] = this.ddtCompatibilityRule.bind(this);
        this.compatibilityRules[ProlifeSdk.WorkedHoursEntityTypeCode] = this.workedHoursCompatibilityRule.bind(this);
        this.compatibilityRules[ProlifeSdk.PurchasesEntityTypeCode] = this.purchasesCompatibilityRule.bind(this);

        this.Id = this.ddt.Id;
        this.Name = String.format(
            ProlifeSdk.TextResources.Invoices.DocumentName,
            this.ddt.DocumentLabel,
            this.ddt.Number,
            moment(this.ddt.Date).format("L")
        );
        this.EntityType = ProlifeSdk.DdtEntityTypeCode;

        this.Number(this.ddt.Number);
        this.Date(this.ddt.Date);
        this.Total(this.ddt.Total);
        this.DocumentLabel(this.ddt.DocumentLabel);
        this.ProtocolId(this.ddt.ProtocolId);
        this.ProtocolName(this.ddt.ProtocolName);
        this.CustomerId(this.ddt.CustomerId);
        this.CustomerName(this.ddt.CustomerName);
        this.JobOrderId(this.ddt.JobOrderId);
        this.JobOrderName(this.ddt.JobOrderName);
        this.SourceWarehouseName(this.ddt.SourceWarehouseName);
        this.Cause(this.ddt.Cause);
        this.PaymentTypeId(this.ddt.PaymentTypeId);
        this.PaymentType(this.ddt.PaymentType);
        this.ExpiryTypeId(this.ddt.ExpiryTypeId);
        this.ExpiryType(this.ddt.ExpiryType);
        this.ClosureStatus(this.ddt.ClosureStatus);
        this.CurrencySymbol(this.ddt.CurrencySymbol);
        this.CurrencyCode(this.ddt.CurrencyCode);

        this.DocumentCurrencies.push(this.createDocumentCurrency());

        this.DocumentCurrency = ko.computed(() => {
            return this.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
        });

        this.TotalInDefaultCurrency = ko.computed(() => {
            let documentCurrency = this.DocumentCurrency();

            if (!documentCurrency) return 0;

            return CurrencyUtils.applyCurrencyExchange(this.Total(), documentCurrency);
        });
    }

    public GetLabelInfo(): ILabelInfo {
        return {
            EntityType: this.EntityType,
            Label: this.LabelForInvoiceGrouping(),
            CustomerId: this.ddt.CustomerId,
            JobOrder: this.ddt.JobOrderId,
            PaymentMode: this.ddt.PaymentType,
            ExpiryMode: this.ddt.ExpiryType,
            NumberOfItemsWithLabel: 0,
        };
    }

    public VerifyDocumentCompatibility(labelInfo: ILabelInfo): boolean {
        return this.compatibilityRules[labelInfo.EntityType](labelInfo);
    }

    public ApplyLabelToSelectedEntities(): void {
        if (!this.storage()) return;

        var invalidSelections: string[] = [];

        this.storage()
            .SelectedEntities()
            .forEach((ddt: IDDTToImportForMonthlyInvoicingInfo) => {
                if (!ddt.VerifyDocumentCompatibility(this.GetLabelInfo())) {
                    invalidSelections.push(
                        ddt.DocumentLabel() +
                            " " +
                            ddt.Number() +
                            " " +
                            moment(ddt.Date()).format("L") +
                            " - " +
                            ddt.ProtocolName()
                    );
                    return;
                }

                ddt.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
            });

        if (invalidSelections.length > 0) {
            var message: string = "<br/>";
            invalidSelections.forEach((m: string) => (message += m + "<br/>"));
            message += "<br/>";
            this.infoToastService.Warning(
                String.format(ProlifeSdk.TextResources.Invoices.InvalidDocumentsForLabelApplication, message)
            );
        }
    }

    public async GetCopy(): Promise<IEntityToImportInfoForMonthlyInvoicing> {
        var ddt = new Ddt(this.ddt, this.wizard);
        ddt.storage(null);
        ddt.LabelForInvoiceGrouping(this.LabelForInvoiceGrouping());
        return ddt;
    }

    public GetDocumentForMonthlyInvoicingTree(
        importedDocuments: IEntityForMonthlyInvoicingTree[],
        documentLabel: string = null
    ): IEntityForMonthlyInvoicingTree {
        return {
            EntityId: this.Id,
            EntityType: this.EntityType,
            DocumentLabel: documentLabel === null ? this.Name : documentLabel,
            VatRegisterId: this.ProtocolId(),
            CustomerId: this.CustomerId(),
            CustomerName: this.CustomerName(),
            JobOrderId: this.JobOrderId(),
            ExpiryTypeId: this.ExpiryTypeId(),
            ExpiryType: this.ExpiryType(),
            PaymentTypeId: this.PaymentTypeId(),
            PaymentType: this.PaymentType(),
            PaymentIBAN: null,
            PaymentABI: null,
            PaymentCAB: null,
            DocumentCurrencies: this.DocumentCurrencies().map((c) => c.getData()),
            IsPartialInvoice: false,
            ImportedDocuments: importedDocuments,
            WorkedHoursRow: null,
            PurchasesRow: null,
        };
    }

    public async OpenDocumentOverlay(): Promise<void> {
        await this.documentsService.OpenDocumentOverlayById(this.ddt.Id);
    }

    private createDocumentCurrency(): IDocumentCurrencyViewModel {
        let currency = this.documentsService.DocumentCurrenciesFactory.create(
            this.ddt.Id,
            ProlifeSdk.DdtEntityTypeCode,
            this.ddt.CurrencyId
        );
        currency.IsDocumentCurrency(true);
        currency.ExchangeValue(this.ddt.ExchangeValue);
        currency.ExchangeValueForVat(this.ddt.ExchangeValueForVat);
        return currency;
    }

    private ddtCompatibilityRule(labelInfo: ILabelInfo): boolean {
        return (
            labelInfo.CustomerId == this.ddt.CustomerId &&
            labelInfo.JobOrder == this.ddt.JobOrderId &&
            (labelInfo.PaymentMode || "").replace(/\s/g, "") == (this.ddt.PaymentType || "").replace(/\s/g, "") &&
            (labelInfo.ExpiryMode || "").replace(/\s/g, "") == (this.ddt.ExpiryType || "").replace(/\s/g, "")
        );
    }

    private workedHoursCompatibilityRule(labelInfo: ILabelInfo): boolean {
        return labelInfo.CustomerId == this.ddt.CustomerId && labelInfo.JobOrder == this.ddt.JobOrderId;
    }

    private purchasesCompatibilityRule(labelInfo: ILabelInfo): boolean {
        return labelInfo.CustomerId == this.ddt.CustomerId && labelInfo.JobOrder == this.ddt.JobOrderId;
    }
}
