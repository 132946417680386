import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IException } from "../../../../Core/interfaces/IException";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IManufacturersService } from "../../../../ProlifeSdk/interfaces/warehouse/IManufacturersService";

export class ManufacturerEditDialog implements IDialog {
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(ProlifeSdk.ManufacturersServiceType)
    private manufacturersService : IManufacturersService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    templateName: string = "manufacturerEditDialog";
    templateUrl: string = "Warehouse/Templates/Manufacturers";
    title: string = TextResources.Warehouse.NewManufacturer;
    
    modal: { close: (result?: any) => void; };

    Code : ko.Observable<string> = ko.observable();
    Piva : ko.Observable<string> = ko.observable();
    Description: ko.Observable<string> = ko.observable();

    constructor() {

    }

    close(): void {
        this.modal.close();
    }    

    public ShowModal() : Promise<void> {
        return this.dialogsService.ShowModal<void>(this);
    }
    
    async action(): Promise<void> {
        if (!this.Code()) {
            this.infoToastService.Warning(TextResources.Warehouse.EnterCodeBeforeSaving);
            return;
        }

        if (this.Code().length !== 3) {
            this.infoToastService.Warning(TextResources.Warehouse.WrongManufacturerCodeLength);
            return;
        }

        if (!this.Piva()) {
            this.infoToastService.Warning(TextResources.Warehouse.EnterVatBeforeSaving);
            return;
        }
        
        if (!this.Description()) {
            this.infoToastService.Warning(TextResources.Warehouse.EnterDescriptionBeforeSaving);
            return;
        }

        try
        {
            let manufacturer = {
                Id: 0,
                Code: this.Code(),
                Piva: this.Piva(),
                Description: this.Description()
            }; 

            await this.manufacturersService.CreateOrUpdateManufacturer([manufacturer]);
        }
        catch(e)
        {
            let ex = e as IException;

            if (ex.ExceptionMessage.indexOf("UQ_WAREHOUSE_Manufactures_Code") >= 0)
                this.infoToastService.Error(TextResources.Warehouse.ManufacturerCodeAlreadyExists);
            else
                this.infoToastService.Error(ex.ExceptionMessage);
            
            return;
        }

        this.modal.close();
    }
}