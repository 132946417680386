import * as ko from "knockout";
import { IListView, IListViewItem, IListViewProvider, IListViewOptions } from "../../../interfaces/prolife-sdk/controls/IListView";

export class ListView implements IListView
{
    templateName : string = "list-view";
    templateUrl : string = "prolifesdk/templates/controls";
    items : ko.ObservableArray<IListViewItem> = ko.observableArray([]);

    public Height: string = '320px';
    public IsCrudEnabled : boolean;

    constructor(private provider : IListViewProvider, private options? : IListViewOptions)
    {
        this.IsCrudEnabled = options ? options.enableCrud : false;
        this.Height = options ? options.height || '320px' : '320px';

        provider.GetItems().then((items : IListViewItem[]) => {
            this.items(items);
        });
    }

    public DeleteItem(item : IListViewItem)
    {
        item.Delete().then(() => {
            this.items.remove(item);
        });
    }

    public CreateItem()
    {
        this.provider.CreateNewItem().then((i : IListViewItem) => {
            this.items.push(i);
        });
    }

    public GetItems() : IListViewItem[]
    {
        return this.items();
    }
}
