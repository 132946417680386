import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CustomersProvider, ICustomersProvider } from "../../../../Desktop/utilities/CustomersProvider";
import { InventoryWarehouse } from "./InventoryWarehouse";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import {
    IWarehousesService,
    IWarehouseWithStockInfo,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export class CreateNewWarehouseDialog implements IDialog, ICustomersProvider {
    title: string = ProlifeSdk.TextResources.Warehouse.Warehouse;
    templateName = "warehouse-new-warehouse";
    templateUrl = "warehouse/templates";

    public newWarehouse: InventoryWarehouse;

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToast: IInfoToastService;

    modal: { close: (result?: any) => void };

    constructor(warehouse?: IWarehouseWithStockInfo) {
        new CustomersProvider(this);

        if (warehouse) this.newWarehouse = new InventoryWarehouse(warehouse);
    }

    close(): void {
        this.modal && this.modal.close(false);
    }

    action(): void {
        const validation = this.newWarehouse.getValidation();
        if (!validation.validateAndShowInfoToast(this.newWarehouse)) {
            return;
        }

        if (!this.newWarehouse.isChanged()) {
            this.infoToast.Info(ProlifeSdk.TextResources.Warehouse.NoChangesMadeToWarehouse);
            this.modal && this.modal.close(true);
            return;
        }

        this.warehousesService.createOrUpdate(this.newWarehouse.getData()).then(this.onSaveSuccessful.bind(this));
    }

    private onSaveSuccessful() {
        this.infoToast.Success(ProlifeSdk.TextResources.Warehouse.WarehouseSavedSuccessfully);
        this.modal && this.modal.close(true);
    }

    findCustomers(query: any) {}
    findCustomer(element, callback) {}
}
