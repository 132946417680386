import * as ko from "knockout";
import * as numeral from "numeral";

export class NumberValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        const suffix = allBindingsAccessor()["numberValueSuffix"] || "";
        const format = ko.utils.unwrapObservable(allBindingsAccessor()["format"]) || "0,0.00[0]";

        const interceptor = ko.computed<string>({
            read: function() {
                return numeral(ko.utils.unwrapObservable(underlyingObservable) || 0).format(format) + (suffix ? " " + suffix : "");
            },
            write: function(newValue) {
                if(!newValue)
                    newValue = "0";

                const components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if(components.length > 1) {
                    const secondComponentLength = components[1].length;
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);
                    newValue = numeral(newValue).value().toFixed(secondComponentLength).replace(/\./g, ",");
                }

                const selectionStart = $(element).prop("selectionStart");
                const selectionEnd = $(element).prop("selectionEnd");

                const valueToWrite = numeral(newValue).value();
                underlyingObservable(valueToWrite);

                $(element).prop("selectionStart", selectionStart);
                $(element).prop("selectionEnd", selectionEnd);
            }
        }).extend({ notify: 'always' });

        let binding : any = { value: interceptor };
        if(allBindingsAccessor().valueUpdate)
            binding = $.extend({ valueUpdate: allBindingsAccessor().valueUpdate }, binding);

        ko.applyBindingsToNode(element, binding, undefined);
    }
}

export class NullableNumberValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const underlyingObservable = valueAccessor();
        const suffix = allBindingsAccessor()["numberValueSuffix"] || "";
        const format = allBindingsAccessor()["format"] || "0,0.00[0]";

        const interceptor = ko.computed<string>({
            read: function() {
                const newValue = ko.utils.unwrapObservable(underlyingObservable);
                if(newValue == undefined || newValue == null)
                    return null;

                return numeral(newValue).format(format) + (suffix ? " " + suffix : "");
            },
            write: function(newValue) {
                if(newValue == undefined || newValue == null || newValue.trim() == "") {
                    underlyingObservable(null);
                    return;
                }

                if (isNaN(parseInt(newValue)) && isNaN(parseFloat(newValue))) {
                    underlyingObservable(null);
                    return;
                }

                const components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                if (components.length > 1) {
                    const secondComponentLength = components[1].length;
                    newValue = components.slice(0,-1).join('') + "," + components.slice(-1);
                    newValue = numeral(newValue).value().toFixed(secondComponentLength).replace(/\./g, ",");
                }

                const selectionStart = $(element).prop("selectionStart");
                const selectionEnd = $(element).prop("selectionEnd");

                const valueToWrite = numeral(newValue).value();
                underlyingObservable(valueToWrite);

                $(element).prop("selectionStart", selectionStart);
                $(element).prop("selectionEnd", selectionEnd);
            }
        }).extend({ notify: 'always' });

        let binding : any = { value: interceptor };
        if(allBindingsAccessor().valueUpdate)
            binding = $.extend({ valueUpdate: allBindingsAccessor().valueUpdate }, binding);

        ko.applyBindingsToNode(element, binding, undefined);
    }
}

export class NumberText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        //setTimeout(() => {

            const underlyingObservable = valueAccessor();
            const valuesToReplace : {value : number; text : string; }[] = allBindingsAccessor()["numberTextPlaceholders"] || [];
            let suffix = ko.unwrap(allBindingsAccessor()["numberTextSuffix"] || "");
            const textIfNoValue = ko.unwrap(allBindingsAccessor()["textIfNoValue"] || "");

            if (suffix)
                suffix = " " + suffix;

            const format = allBindingsAccessor()["format"] || "0,0.00[0]";

            const interceptor = ko.computed<string>({
                read: function() {
                    const val: number = ko.utils.unwrapObservable(underlyingObservable);

                    if ((val === undefined || val === null) && !!textIfNoValue)
                        return textIfNoValue;

                    const valMatches = valuesToReplace.filter((v) => { return v.value == val; });
                    return valMatches.length > 0 ? valMatches[0].text : (numeral(val || 0).format(format) + suffix);
                }/*,
                write: function(newValue) {
                    if(!newValue)
                        newValue = "0";

                    let components = newValue.replace(/\./g, ",").split(",").filter((component : string) => component.length > 0);
                    if (components.length > 1) {
                        let secondComponentLength = components[1].length;
                        newValue = components.slice(0,-1).join('') + "," + components.slice(-1);
                        newValue = numeral().unformat(newValue).toFixed(secondComponentLength).replace(/\./g, ",");
                    }

                    let valueToWrite = numeral().unformat(newValue);
                    //if(valueToWrite != newValue)
                    underlyingObservable(valueToWrite);
                    //underlyingObservable.valueHasMutated();
                }*/
            });

            ko.applyBindingsToNode(element, { text: interceptor }, undefined);

        //}, 0);
    }
}

ko.bindingHandlers["numberValue"] = new NumberValue();
ko.bindingHandlers["nullableNumberValue"] = new NullableNumberValue();
ko.bindingHandlers["numberText"] = new NumberText();
ko.virtualElements.allowedBindings["numberText"] = true;