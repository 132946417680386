import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 12/04/13
 * Time: 17.57
 * To change this template use File | Settings | File Templates.
 */

import { IExpireMode } from "../ProlifeSdk/interfaces/invoice/settings/IExpireModes";

/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 25/03/13
 * Time: 14.49
 * To change this template use File | Settings | File Templates.
 */

export class ExpireModeValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();

        var instance = {
            expireModesAccessor : valueAccessor()
        };

        (<any>$(element)).typeahead({
            source: ExpireModeValue.findExpireModes.bind(null, instance),
            items: 8,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>',
            highlighter: function(json) {
                var item = JSON.parse(json);
                return item.Description;
            },
            updater: function(json) {
                var item = JSON.parse(json);
                //valueAccessor()(item.id);
                otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(item.Description);
                return item.Description;
            }
        });
    }

    private static findExpireModes(_this : any, query : string, process : (items : any[]) => any) {
        var expireModes = ko.utils.unwrapObservable(_this.expireModesAccessor);
        var items = ExpireModeValue.transformExpireModes(expireModes);
        process(items);
    }

    private static transformExpireModes(expireModes : IExpireMode[]) : any[] {
        return expireModes.map(ExpireModeValue.transformExpireMode);
    }

    private static transformExpireMode(expireMode : IExpireMode) : any {
        return {
            id: expireMode.IdTipoScadenza,
            Description: expireMode.Descrizione,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.Description.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.Description, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.Description, arguments);
            }
        }
    }
}

ko.bindingHandlers["expireModeValue"] = new ExpireModeValue();