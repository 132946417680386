import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { WorkItemsControlsEntityProvider } from "./WorkItemsControlsEntityProvider";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IProjectsService } from "../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IWorkItemHint, IWorkItemBase } from "../../../ProlifeSdk/interfaces/projects/IWorkItem";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class WorkItemsEntityProvider implements IEntityProvider<number, IWorkItemHint|IWorkItemBase>
{
    private projectsService : IProjectsService;

    constructor(private serviceLocator : IServiceLocator )
    {
        var entityProviderService : IEntityProviderService= <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        entityProviderService.registerEntityProvider(this, this.getType());
        this.projectsService = <IProjectsService> serviceLocator.findService(ProlifeSdk.ProjectsServiceType);
    }

    getType() : string
    {
        return ProlifeSdk.WorkItemEntityTypeCode;
    }

    getEntityTypeName() : string
    {
        return "Work Item";
    }

    getPkValue(item : IWorkItemHint) : number
    {
        return item.Id;
    }

    getDisplayName(item : IWorkItemHint) : string
    {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.projectsService.getWorkItem(pkValue).then(
            (p : IWorkItemBase) => {
                def.resolve(p ? p.name : "");
            }
        )
            .catch(() => { def.reject(); });

        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IWorkItemHint[]>
    {
        return this.projectsService.workItemsHintSearch(stringFilter);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new WorkItemsControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : number) : Promise<IWorkItemBase>
    {
        return this.projectsService.getWorkItem(pkValue);
    }
}