import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ActivitiesNavigatorBase } from "./ActivitiesNavigatorBase";
import { RootNavigationProvider } from "./providers/templates/RootNavigationProvider";
import { ITemplatesNavigator } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowNavigator";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";

export class TemplatesNavigator extends ActivitiesNavigatorBase implements ITemplatesNavigator
{
    constructor(serviceLocator : IServiceLocator, navigateTasks : boolean)
    {
        super(serviceLocator, new RootNavigationProvider(serviceLocator, navigateTasks));
    }

    public Initialize() : Promise<void>
    {
        return super.InitializeBase();
    }

    public SetSelectedTemplates(ids : number[])
    {
        this.clearSelection(false);
        return (<any>this.RootMenuProvider).SetSelectedTemplates(ids);
    }
}
