/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 07/02/13
 * Time: 12.01
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { AccountEditingViewModel } from "./AccountEditingViewModel";
import { IBank } from "../../../../ProlifeSdk/interfaces/settings/IBank";
import { IAccount } from "../../../../ProlifeSdk/interfaces/settings/IAccount";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IValidationService, IValidator, IValidation } from "../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class BankEditingViewModel {
    Name : ko.Observable<string> = ko.observable();
    ABI : ko.Observable<string> = ko.observable();
    CAB : ko.Observable<string> = ko.observable();
    SEPACode : ko.Observable<string> = ko.observable();

    Selected : ko.Observable<boolean> = ko.observable(false);

    Accounts : ko.ObservableArray<AccountEditingViewModel> = ko.observableArray();

    NewAccountIBAN : ko.Observable<string> = ko.observable();

    public originalValue : IBank;
    public isChanged : ko.Computed<boolean>;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    private validation : IValidator<BankEditingViewModel>;

    constructor(private bank? : IBank)
    {
        this.originalValue = bank;

        this.isChanged = ko.computed(() => {
            var isChanged = false;
            isChanged = isChanged || ((this.originalValue ? this.originalValue.Name : null) != this.Name());
            isChanged = isChanged || ((this.originalValue ? this.originalValue.ABI : null) != this.ABI());
            isChanged = isChanged || ((this.originalValue ? this.originalValue.CAB : null) != this.CAB());
            isChanged = isChanged || ((this.originalValue ? this.originalValue.SEPACode : null) != this.SEPACode());
            isChanged = isChanged || (this.Accounts().length != ((this.originalValue?.Accounts ?? []).length));

            if(this.originalValue)
                isChanged = isChanged || this.Accounts().map((b : AccountEditingViewModel) => b.originalValue)
                    .filter((b : IAccount) => { this.originalValue.Accounts.indexOf(b) == -1})
                    .length > 0;
            return isChanged;
        });

        this.Name(bank?.Name);
        this.ABI(bank?.ABI);
        this.CAB(bank?.CAB);
        this.SEPACode(bank?.SEPACode);
        this.Accounts(bank?.Accounts?.map(this.createViewModelFor.bind(this)) ?? []);

        this.validation = this.validationService.createValidator<BankEditingViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace(b => b.Name(), TextResources.Settings.InsertBankName)
            .isNotNullOrUndefinedOrWhiteSpace(b => b.ABI(), TextResources.Settings.InsertABI)
            .isNotNullOrUndefinedOrWhiteSpace(b => b.CAB(), TextResources.Settings.InsertCAB)
            .mustBeAbi(b => b.ABI())
            .mustBeCab(b => b.CAB());
    }

    public validate() : IValidation[]
    {
        return this.validation.validate(this);
    }


    public getBank() : IBank {
        var bank : IBank = <IBank> $.extend({}, this.bank);

        bank.Name = this.Name();
        bank.ABI = this.ABI();
        bank.CAB = this.CAB();
        bank.SEPACode = this.SEPACode();
        bank.Accounts = this.Accounts().map((account) => account.getAccount());

        return bank;
    }

    private createViewModelFor(account : IAccount) {
        return new AccountEditingViewModel(account);
    }

    public CreateAccount(element)
    {
        var iban : string = this.NewAccountIBAN();
        iban = iban ? iban.replace(/\s/g, '').substring(0, (iban.length>27 ? 27 : iban.length)) : "";

        if(iban.length == 0)
            return;

        this.Accounts.push(new AccountEditingViewModel({
            IBAN: iban,
            Description: ""
        }));
        this.NewAccountIBAN("");
        element.scrollIntoView(true);
    }

    public DeleteAccount(account : AccountEditingViewModel) {
        this.Accounts.remove(account);
    }
}