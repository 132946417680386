import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { BasePagedList } from "../Utilities/BasePagedList";
import { DiscountCatalogEditDialog } from "./DiscountCatalogs/DiscountCatalogEditDialog";
import {
    IDiscountsCatalogsService,
    IGetDiscountCatalogsResponse,
    IDiscountCatalog,
} from "../../../DiscountsCatalogsService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";

export class DiscountsCatalogsList extends BasePagedList implements IView {
    templateName = "discount-catalogs-list";
    templateUrl = "warehouse/templates/discounts";
    viewModel: any;

    public title: string = ProlifeSdk.TextResources.Warehouse.DiscountsCatalogs;
    public SectionId = 10;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsService!: IDiscountsCatalogsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public ShowExpiredDiscountCatalogs: ko.Observable<boolean> = ko.observable(false);
    public ShowForCustomers: ko.Observable<boolean> = ko.observable(true);
    public ShowForSuppliers: ko.Observable<boolean> = ko.observable(true);

    constructor() {
        super();
        this.viewModel = this;

        this.ShowExpiredDiscountCatalogs.subscribe(() => this.SomeFilterIsChanged(true));
        this.ShowForCustomers.subscribe(() => this.SomeFilterIsChanged(true));
        this.ShowForSuppliers.subscribe(() => this.SomeFilterIsChanged(true));

        this.loadRows();
    }

    public fetchRows(): Promise<IGetDiscountCatalogsResponse[]> {
        return this.discountsService.GetDiscountCatalogs({
            textFilter: this.searchFilter(),
            showExpired: this.ShowExpiredDiscountCatalogs(),
            showForCustomers: this.ShowForCustomers(),
            showForSuppliers: this.ShowForSuppliers(),
            skip: this.selectedPage() * this.rowsPerPage,
            count: this.rowsPerPage,
        });
    }

    public fetchRowsCount(): Promise<number> {
        return this.discountsService.GetDiscountCatalogsCount(this.searchFilter(), this.ShowExpiredDiscountCatalogs());
    }

    public createViewModelFor(row: IDiscountCatalog): IDiscountCatalog {
        return row;
    }

    public async Edit(catalog: IGetDiscountCatalogsResponse) {
        await new DiscountCatalogEditDialog(catalog).ShowModal();

        this.loadRows();
    }

    public async NewCatalog() {
        await new DiscountCatalogEditDialog().ShowModal();

        this.loadRows();
    }

    public async DeleteCatalog(catalog: IDiscountCatalog): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Warehouse.DeleteDiscountCatalogMessage,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );
        if (!confirm) return;

        try {
            await this.discountsService.DeleteDiscountCatalog(catalog.Id);
            this.loadRows();
        } catch (e) {
            console.error(e);
            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }
}
