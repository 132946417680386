import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { Select } from "../Select";
import { SuppliersDataSource } from "../../DataSources/SuppliersDataSource";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type SupplierColumnFilterProps = {
    initialValue: number;
    onSupplierSelected: (supplierId: number) => void;
}

export function SupplierColumnFilter(props: SupplierColumnFilterProps) {
    const C = require("./SupplierColumnFilter")._SupplierColumnFilter as typeof _SupplierColumnFilter;
    return <C {...props} />;
}

export class _SupplierColumnFilter extends DialogComponentBase {
    static defaultProps: Partial<SupplierColumnFilterProps> = {
    }

    public SuppliersDataSource: SuppliersDataSource = new SuppliersDataSource();
    SupplierId : ko.Observable<number> = ko.observable();

    constructor(private props : SupplierColumnFilterProps) {
        super({ popover: true });

        this.SupplierId(props.initialValue);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return <Select 
                    style={{ width: '200px' }}
                    dataSource={this.SuppliersDataSource}
                    allowClear
                    placeholder="Seleziona un fornitore..."
                    value={this.SupplierId}
                    onSelect={(i) => this.props.onSupplierSelected(i.id)}
                    onDeselect={(i) => this.props.onSupplierSelected(undefined)} />;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(SupplierColumnFilter);
}