import * as ko from "knockout";
//
//  Brief : Classe base del menu di navigazione. Da specializzare per i casi specifici.

import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { INavigationMenu, IDraggableOptions } from "../../../interfaces/navigation-menu/INavigationMenu";
import { INavigationMenuObserver } from "../../../interfaces/navigation-menu/INavigationMenuObserver";
import { INavigationMenuProvider } from "../../../interfaces/navigation-menu/INavigationMenuProvider";
import { Deferred } from "../../../../Core/Deferred";

//
export class NavigationMenu implements INavigationMenu {
    public collapsible: ko.Observable<boolean> = ko.observable(false);
    public isExpanded: ko.Observable<boolean> = ko.observable(false);
    public draggableOptions: IDraggableOptions;

    private menuObservers: INavigationMenuObserver[] = [];
    public templateUrl: string = null;
    public templateName: string = null;
    RootMenuProvider: INavigationMenuProvider;
    MenuProvidersStack: ko.ObservableArray<INavigationMenuProvider> = ko.observableArray([]);
    CurrentView: ko.Computed<INavigationMenuProvider>;
    CanGoBack: ko.Computed<boolean>;
    Title: ko.Computed<string>;

    public singleSelectionEnabled: ko.Observable<boolean> = ko.observable(false);
    public multipleSelectionEnabled: ko.Observable<boolean> = ko.observable(false);
    public mobileBrowserEnabled: ko.Observable<boolean> = ko.observable(false);
    public unselectOnSecondClick: ko.Observable<boolean> = ko.observable(false);
    public selectedProviders: INavigationMenuProvider[] = [];
    private searchModeForced: boolean = false;

    //Ricerca full text
    IsSearching: ko.Observable<boolean> = ko.observable(false);
    SearchFilter: ko.Observable<string> = ko.observable();
    SearchEnabled: ko.Computed<boolean>;

    //Toggles
    public ShowToggles: boolean = false;
    public TogglesTrueLabel: string = "";
    public TogglesFalseLabel: string = "";
    public OnTogglesChangesCallback: (p: INavigationMenuProvider) => object = null;

    private lastTimeout: ReturnType<typeof setTimeout>;

    constructor(serviceLocator: IServiceLocator, rootProvider: INavigationMenuProvider) {
        this.RootMenuProvider = rootProvider;
        this.MenuProvidersStack.push(this.RootMenuProvider);

        this.draggableOptions = {
            EnableWorkflowsDragging: ko.observable(false),
            EnableTasksDragging: ko.observable(false),
            DragContainmentOption: ko.observable("body"),
            DragAppendToOption: ko.observable("body"),
            DragScope: ko.observable("default"),
        };

        this.CurrentView = ko.computed(() => {
            if (this.MenuProvidersStack().length == 0) return undefined;
            return this.MenuProvidersStack()[this.MenuProvidersStack().length - 1];
        });

        this.SearchEnabled = ko.computed(() => {
            return this.CurrentView() && this.CurrentView().SearchAllowed;
        });

        this.SearchFilter.subscribe((filter) => {
            this.doSearch(filter);
        });

        this.CanGoBack = ko.computed(() => {
            return this.MenuProvidersStack().length > 1;
        });

        this.Title = ko.computed(() => {
            if (!this.CurrentView()) return "";
            return ko.utils.unwrapObservable(this.CurrentView().Name);
        });

        this.RootMenuProvider.setCurrentNavigator(this);
    }

    private doSearch(filter: string) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            var isFilterValid: boolean = filter && filter.length > 0;
            filter = isFilterValid ? filter : "";

            var searching = this.searchModeForced || isFilterValid;

            if (this.CurrentView()) {
                if (!searching) this.CurrentView().clearSearch();
                else this.CurrentView().search(filter);
            }

            this.IsSearching(searching);
        }, 500);
    }

    public ConfigureToggles(
        enable: boolean,
        trueLabel: string,
        falseLabel: string,
        onChangesCallback: (p: INavigationMenuProvider) => object
    ) {
        this.ShowToggles = enable;
        this.TogglesTrueLabel = trueLabel;
        this.TogglesFalseLabel = falseLabel;
        this.OnTogglesChangesCallback = onChangesCallback;
    }

    public ForceSearchMode(enabled: boolean) {
        this.searchModeForced = enabled;

        if (!enabled) return;

        this.MenuProvidersStack([this.RootMenuProvider]);
        if (this.CurrentView()) this.CurrentView().search(this.SearchFilter());
        this.IsSearching(true);
    }

    public switchExpandStatus(): void {
        this.isExpanded(!this.isExpanded());
    }

    addMenuObserver(o: INavigationMenuObserver) {
        this.menuObservers.push(o);
    }

    getSelectedProviders(): INavigationMenuProvider[] {
        return this.selectedProviders;
    }

    clearSelection(notifyToObservers: boolean = true) {
        this.selectedProviders.forEach((p: INavigationMenuProvider) => {
            p.IsSelected(false);
        });
        this.selectedProviders = [];
        if (notifyToObservers) this.notifySelectionChangedToObservers();
    }

    removeProviderFromSelection(provider: INavigationMenuProvider, notifyToObservers: boolean = true) {
        if (!this.singleSelectionEnabled() && !this.multipleSelectionEnabled()) return;

        this.selectedProviders.splice(this.selectedProviders.indexOf(provider), 1);
        provider.IsSelected(false);
        if (notifyToObservers) this.notifySelectionChangedToObservers();
    }

    selectProvider(provider: INavigationMenuProvider, notifyToObservers: boolean = true): void {
        if (!this.singleSelectionEnabled() && !this.multipleSelectionEnabled()) return;

        //In selezione singola deseleziono gli altri item
        if (this.singleSelectionEnabled()) {
            this.selectedProviders.forEach((p: INavigationMenuProvider) => {
                p.IsSelected(false);
            });
            this.selectedProviders = [];
        }

        if (this.selectedProviders.indexOf(provider) == -1) this.selectedProviders.push(provider);
        provider.IsSelected(true);
        if (notifyToObservers) this.notifySelectionChangedToObservers();
    }

    selectFirstProviderMatch(
        matchRule: (provider: INavigationMenuProvider) => boolean,
        notifyToObservers: boolean = true
    ): void {
        if (!this.singleSelectionEnabled() && !this.multipleSelectionEnabled()) return;

        //In selezione singola deseleziono gli altri item
        if (this.singleSelectionEnabled()) {
            this.selectedProviders.forEach((p: INavigationMenuProvider) => {
                p.IsSelected(false);
            });
            this.selectedProviders = [];
        }

        this.CurrentView()
            .Items()
            .forEach((i: INavigationMenuProvider) => {
                if (matchRule(i) && this.selectedProviders.indexOf(i) == -1) {
                    this.selectedProviders.push(i);
                    i.IsSelected(true);

                    if (notifyToObservers) this.notifySelectionChangedToObservers();
                }
            });
    }

    public notifySelectionChangedToObservers() {
        this.menuObservers.forEach((o: INavigationMenuObserver) => {
            o.onSelectionChanged(this.selectedProviders, this);
        });
    }

    setSingleSelectionStatus(enabled: boolean, unselectOnSecondClick: boolean) {
        this.singleSelectionEnabled(enabled);
        this.unselectOnSecondClick(enabled && unselectOnSecondClick);
        this.multipleSelectionEnabled(enabled ? false : this.multipleSelectionEnabled());
    }

    setMultipleSelectionStatus(enabled: boolean) {
        this.multipleSelectionEnabled(enabled);
        this.singleSelectionEnabled(enabled ? false : this.singleSelectionEnabled());
    }

    setMobileStatus(enabled: boolean) {
        this.mobileBrowserEnabled(enabled);
    }

    navigateTo(provider: INavigationMenuProvider) {
        this.MenuProvidersStack.push(provider);
    }

    SetCollapsibleMode(isCollapsible: boolean) {
        this.collapsible(isCollapsible);
    }

    navigateToJobOrder(id: number) {
        var path: INavigationMenuProvider[] = (<any>this.RootMenuProvider).findPathToJobOrder(id);

        //Se il provider padre della commessa non è attualmente selezionato devo navigare l'albero per visualizzarlo
        if (path.length > 1 && path[path.length - 2] != this.CurrentView()) {
            this.MenuProvidersStack([this.RootMenuProvider]);

            path.forEach((p: INavigationMenuProvider) => {
                if (p != this.RootMenuProvider && p != path[path.length - 1]) this.navigateTo(p);
            });
        }

        if (path.length > 0) path[path.length - 1].select(false);

        this.notifySelectionChangedToObservers();
    }

    refreshItems(): Promise<void> {
        var def = new Deferred<void>();
        if (this.MenuProvidersStack().length > 0)
            this.MenuProvidersStack()[0]
                .refreshItems()
                .then(() => {
                    def.resolve();
                })
                .catch(() => def.reject());
        else def.resolve();
        return def.promise();
    }

    GoBack() {
        this.SearchFilter("");
        this.MenuProvidersStack.pop();
    }

    close() {
        location.href = "#/";
    }
}
