import * as ko from "knockout";
﻿import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IPurchaseTypeSettingManager } from "../../../SettingManagers/PurchaseTypeSettingManager";
import { IExtendedEstimateBudgetRow } from "./ActivitiesDataSource";
import { IDocumentBuilderDocumentRelatedWorkflows } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IActivitiesForDocuments, IActivitiesSourceForDocuments } from "../../../ProlifeSdk/interfaces/todolist/IActivitiesForDocuments";
import { IEstimateBudgetRow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IArticlesService, IArticle } from "../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDiscountsService, IDiscountsManager } from "../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import { IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";

export class ActivitiesRow implements IActivitiesForDocuments
{
    ArticleId : ko.Observable<number> = ko.observable();

    Description : ko.Observable<string> = ko.observable();
    AmountFormula : ko.Observable<string> = ko.observable();
    Amount : ko.Observable<number> = ko.observable();
    UnitPrice : ko.Observable<number> = ko.observable();
    Discounts : ko.Observable<string> = ko.observable();
    NetUnitPrice : ko.Observable<number> = ko.observable();
    Total : ko.Observable<number> = ko.observable();
    UOM: ko.Observable<string> = ko.observable();

    CatalogId : ko.Observable<number> = ko.observable();

    Sources : IActivitiesSourceForDocuments[] = [];
    RelatedWorkflows: IDocumentBuilderDocumentRelatedWorkflows[] = [];

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacterService : IUserCharactersSettingsManager;

    @LazyImport(nameof<IArticlesService>())
    private articlesService : IArticlesService;

    @LazyImport(nameof<IDiscountsService>())
    private discountsService : IDiscountsService;

    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesSettingManager : IPurchaseTypeSettingManager;

    constructor(discountsManager : IDiscountsManager, public row : IExtendedEstimateBudgetRow, useCosts: boolean = false) {
        var deferSourceGeneration = false;

        if (row.Type == ProlifeSdk.EstimatedWorkEntityTypeCode) {
            let userCharacter = this.userCharacterService.getUserCharacterById(row.EntityKeyId);
            this.Description(userCharacter.Description + (row.Description ? " - " + row.Description : ""));
            this.UOM(ProlifeSdk.TextResources.Todolist.UOMHours);
        } else if (row.Type == ProlifeSdk.WarehouseArticleEntityTypeCode) {
            deferSourceGeneration = true;

            this.Description(ProlifeSdk.TextResources.Core.Loading);

            this.articlesService.getArticleByCatalogRowId(row.EntityKeyId)
                .then((a : IArticle) => {
                    this.ArticleId(a.ArticleId);
                    this.Description(a.Code + " " + a.Description);
                    this.CatalogId(a.CatalogId);
                    this.UOM(a.UOM);
                    
                    this.updateNetUnitPrice();

                    this.Total(row.Amount * row.Multiplier * this.NetUnitPrice());

                    this.generateSources();
                });
        }
        else if(row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
            let purchaseType = this.purchaseTypesSettingManager.getById(row.EntityKeyId);
            let title = (purchaseType?.Title ?? TextResources.JobOrder.PurchaseTypeUnknown) + (row.Description ? " - " + row.Description : "");
            this.Description(title);
        }
        else {
            this.Description(row.Description);
            this.UOM("");
        }

        this.Amount(row.Amount * row.Multiplier);
        this.UnitPrice(useCosts ? row.UnitCost : row.UnitPrice);
        this.NetUnitPrice(useCosts ? row.UnitCost : row.NetUnitPrice);
        this.Discounts(useCosts ? null : row.DiscountValues);
        
        this.RelatedWorkflows.push({
            Id: -1,
            RowAmount: this.Amount(),
            RowPrice: this.Total(),
            RowDescription: this.Description(),
            RowUoM: this.UOM(),
            RowId: null,
            WorkflowAmount: this.Amount(),
            WorkflowId: row.WorkflowId,
            WorkflowName: null,
            DocumentDate: null,
            DocumentId: null,
            DocumentNumber: null,
            DocumentProtocolId: null,
            DocumentType: null,
        });

        if(!deferSourceGeneration) {
            this.updateNetUnitPrice();
            this.Total(row.Amount * row.Multiplier * this.NetUnitPrice());

            this.generateSources();
        }
    }

    private updateNetUnitPrice()
    {
        let discount = 1.0;

        if (this.Discounts())
            discount = this.discountsService.calculateDiscount(this.Discounts());
        
        this.NetUnitPrice(this.UnitPrice() * discount);
    }

    private generateSources()
    {
        this.Sources = [{
            Description: this.Description(),
            Amount: this.Amount(),
            UnitPrice: this.UnitPrice(),
            Discounts: this.Discounts(),
            NetUnitPrice: this.NetUnitPrice(),
            UOM: this.UOM(),
            Total: this.Total(),

            EntityKeyId: this.getEntityId(),
            EntityType: this.row.Type,
            CatalogId: this.CatalogId(),

            EstimateBudgetRowId: this.row.Id,
            TaskId: this.row.TaskId
        }];
    }

    private getEntityId() {
        switch(this.row.Type) {
            case ProlifeSdk.WarehouseArticleEntityTypeCode:
                return this.ArticleId();
            case ProlifeSdk.EstimatedPurchaseEntityTypeCode:
                return this.row.Id;
            case ProlifeSdk.EstimatedWorkEntityTypeCode:
                return this.row.EntityKeyId;
            default:
                return undefined;
        }
    }

    public getEstimatedBudgetRowId() {
        return this.row.Id;
    }

    public getTaskId() {
        return this.row.TaskId;
    }

    public references(row : IEstimateBudgetRow) : boolean {
        return this.row.Type == row.Type && this.row.Id == row.Id;
    }
}