import ko = require("knockout");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IAllocationsService } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class TeamsPopover implements IDialog {
    public templateName = "cart-teams-popover";
    public templateUrl = "allocations/templates/dialogs";
    public title: string = TextResources.Allocations.TeamsPopoverTitle;
    
    public modal: { close: (result?: any) => void; };

    public TeamsNames: ko.ObservableArray<string> = ko.observableArray([]);
    
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService : IAllocationsService;

    constructor(private cartId: number) {
        this.loadTeamsNames();
    }
    
    public close(): void {
        this.modal.close();
    }

    public action(): void {
        this.modal.close();
    }
    
    private async loadTeamsNames(): Promise<void> {
        if (this.TeamsNames().length > 0)
            return;

        const teams = await this.allocationsService.getCartTeamsNames(this.cartId);
        this.TeamsNames(teams.map(t => t.Name));
    }
}