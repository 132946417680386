import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/05/2017
 * Time: 18:38
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { TeamAllocationViewModel } from "./TeamAllocationViewModel";
import { Moment } from "moment";
import { ResourceOperationalUnitViewModel } from "./ResourceOperationalUnitViewModel";
import { ITeamViewModel, ITeamAllocationViewModel, ITeamsManagerProvider, IResourceOperationalUnitViewModel, ITeamManagerViewModel } from "./TeamsManager";
import { WorkPoolsEditor } from "./WorkPoolsEditor";
import { LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IAllocationsService, ITeam, ITeamAllocation, ITeamTheoreticalAllocation, IFullTeam, ITeamManager, IFullTeamManagers } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IOpUnitsResourcesAllocatedHours } from "../../interfaces/IOpUnitsResourcesAllocatedHours";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IManualAllocationWorkPoolRole, IWorkPool } from "../../interfaces/IManualAllocation";
import { Deferred } from "../../../Core/Deferred";
import { IHumanResource } from "../../../Users/HumanResourcesService";
import { TeamManagerViewModel } from "./TeamManagerViewModel";
import { ResourcesAndGroupsDataSource } from "../../../DataSources/ResourcesAndGroupsDataSource";
import { IResourcesGroup } from "../../../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";

interface IVisibleTeamManagers {
    name: string;
    type: string; 
    typeId: number; //0 Resource, 1 Group
    model: (string | IHumanResource | IResourcesGroup)
}

export class TeamViewModel implements ITeamViewModel {
    Id : ko.Observable<number> = ko.observable();
    Name : ko.Observable<string> = ko.observable();
    Allocations : ko.ObservableArray<ITeamAllocationViewModel> = ko.observableArray();
    TeamManagers : ko.ObservableArray<(string|IHumanResource|IResourcesGroup)> = ko.observableArray();
    //TheoreticalAllocations: ko.ObservableArray<TeamTheoreticalAllocationViewModel> = ko.observableArray([]);
    OpUnitsResourcesAllocatedHours: ko.Computed<IOpUnitsResourcesAllocatedHours[]>;
    CreatedBy : ko.Observable<number> = ko.observable();
    CreationDate : ko.Observable<Date> = ko.observable();
    IsSelected : ko.Observable<boolean> = ko.observable(false);
    StatusIcon : ko.Observable<string> = ko.observable("fa fa-group");
    HasChanged : ko.Computed<boolean>;

    Hide: ko.Observable<boolean> = ko.observable();
    Position: ko.Observable<number> = ko.observable();
    NewResourceId : ko.Observable<number> = ko.observable();
    NewTeamManagerResourceId : ko.Observable<string> = ko.observable();
    SelectedAllocation : ko.Observable<ITeamAllocationViewModel> = ko.observable();
    SelectedTeamManager : ko.Observable<(string|IHumanResource|IResourcesGroup)> = ko.observable();
    ShowFinishedAllocations: ko.Observable<boolean> = ko.observable(false);
    ShowResourcesWithoutFutureAllocations: ko.Observable<boolean> = ko.observable(false);
    TeamCanBeFiltered: ko.Observable<boolean> = ko.observable(false);

    StartDate : ko.Computed<Moment>;
    EndDate : ko.Computed<Moment>;

    ValuesHaveChanged : ko.Observable<boolean> = ko.observable(false);
    ChildrenHaveChanged : ko.Computed<boolean>;

    CanHide: ko.Computed<boolean>;

    AllocationsViewActive : ko.Observable<boolean> = ko.observable(true);
    TheoreticalAllocationsViewActive : ko.Observable<boolean> = ko.observable(false);
    AllocatedHoursForOpUnitsViewActive : ko.Observable<boolean> = ko.observable(false);
    TeamManagerViewActive : ko.Observable<boolean> = ko.observable(false);

    VisibleAllocations : ko.Computed<ITeamAllocationViewModel[]>;
    VisibleTeamManagers : ko.Computed<IVisibleTeamManagers[]>;

    ResourceAndGroupsDataSource: ResourcesAndGroupsDataSource;

    private allocationsService : IAllocationsService;
    private entityProviderService : IEntityProviderService;
    private humanResourcesProvider : IEntityProvider<number, IHumanResource>;
    private infoToastService : IInfoToastService;
    private dialogsService : IDialogsService;

    public HumanResources : IControlsEntityProvider;
    public WorkPoolsEditor: WorkPoolsEditor;

    private lastPosition: number;
    private lastGanttUpdateTimeout: ReturnType<typeof setTimeout>;
    public lockPositionElaboration = false;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharacterSettingsManager: IUserCharactersSettingsManager;
    private m_workPoolBaseRoles: IManualAllocationWorkPoolRole[] = [];

    constructor(private serviceLocator : IServiceLocator, private team : ITeam, private teamsManagerProvider : ITeamsManagerProvider) {
        this.allocationsService = <IAllocationsService> serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);
        this.entityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);

        this.humanResourcesProvider = this.entityProviderService.getEntityProvider(ProlifeSdk.HumanResources);
        this.HumanResources = this.humanResourcesProvider.getControlsProvider();
        this.ResourceAndGroupsDataSource = new ResourcesAndGroupsDataSource();
        this.ResourceAndGroupsDataSource.setIncludeGroups(true);

        this.m_workPoolBaseRoles = this.userCharacterSettingsManager.getUserCharacters().map(r => ({ RoleId: r.IdUserCharacter, RoleName: r.Description }));
        this.WorkPoolsEditor = new WorkPoolsEditor(this.m_workPoolBaseRoles);
        this.TeamCanBeFiltered(team.CanBeFiltered)

        this.Id(team.Id);
        this.Name(team.Name);
        this.CreatedBy(team.CreatedBy);
        this.CreationDate(team.CreationDate);
        this.Hide(team.Hide);
        this.Position(team.Position);

        this.lastPosition = team.Position;

        if(!team.Id || team.Id <= 0)
            this.ValuesHaveChanged(true);

        this.StartDate = ko.computed(() => {
            let minDate = moment('2100-01-01');
            this.Allocations().forEach((a : TeamAllocationViewModel) => {
                minDate = a.AbsoluteStartDate() < minDate ? a.AbsoluteStartDate() : minDate;
            });
            return minDate;
        });

        this.EndDate = ko.computed(() => {
            let maxDate = moment('1900-01-01');
            this.Allocations().forEach((a : TeamAllocationViewModel) => {
                maxDate = a.AbsoluteEndDate() > maxDate ? a.AbsoluteEndDate() : maxDate;
            });

            return maxDate;
        });

        this.ChildrenHaveChanged = ko.computed(() => {
            const allocationsAreChanged = this.Allocations().filter((a) => a.HasChanged()).length > 0;
            const workPoolsAreChanged = this.WorkPoolsEditor.HasChanges();

            return allocationsAreChanged || workPoolsAreChanged;
        });

        this.HasChanged = ko.computed(() => {
            return this.ChildrenHaveChanged() || this.ValuesHaveChanged();
        });

        this.Name.subscribe(() => this.ValuesHaveChanged(true));
        this.Hide.subscribe(this.onHideChange.bind(this));
        this.NewResourceId.subscribe(this.onNewResourceSelected.bind(this))
        this.NewTeamManagerResourceId.subscribe(this.onNewTeamManagerResourceSelected.bind(this))
        this.Position.subscribe((position: number) => {
            if (this.lockPositionElaboration) {
                this.ValuesHaveChanged(true);
                return;
            }

            if (position <= 0) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.WrongTeamPositionValue);
                this.lockPositionElaboration = true;
                this.Position(this.lastPosition);
                this.lockPositionElaboration = false;
                return;
            }

            this.ValuesHaveChanged(true);
            this.refreshTeamsPositions();
        });


        this.ShowFinishedAllocations.subscribe((show: boolean) => {
            if (!this.SelectedAllocation()) return;
            if (!this.SelectedAllocation().SelectedOperationalUnit()) return;
            this.SelectedAllocation().SelectedOperationalUnit().ShowFinishedAllocations(show);
        });

        this.ShowResourcesWithoutFutureAllocations.subscribe(() => {
            if(this.ShowResourcesWithoutFutureAllocations())
                return;
            const allocationsWithFutureHours = this.Allocations().filter((a : ITeamAllocationViewModel) => {
                return a.HasAllocatedFutureHours();
            });
            if(allocationsWithFutureHours.indexOf(this.SelectedAllocation()) < 0)
                this.SelectAllocation(allocationsWithFutureHours.length > 0 ? allocationsWithFutureHours[0] : null);
        });

        this.TeamCanBeFiltered.subscribe(() => {
            this.ValuesHaveChanged(true);
        });

        this.VisibleAllocations = ko.computed(() => {
            if(this.ShowResourcesWithoutFutureAllocations()) {
                return this.Allocations();
            } else {
                return this.Allocations().filter((a : ITeamAllocationViewModel) => {
                    return a.HasAllocatedFutureHours() || this.SelectedAllocation() == a;
                });
            }
        });

        this.VisibleTeamManagers = ko.computed(() => {
            let managers = this.TeamManagers();
            return managers.map(a => {
                let res: IVisibleTeamManagers = {
                    name: "",
                    type: "",
                    typeId: 0,
                    model: a
                }
                if ((a as unknown as IHumanResource).Resource)
                {
                    let mod = (a as unknown as IHumanResource).Resource;
                    res.name = mod.Name+ " " + mod.Surname ;
                    res.type = mod.ResourceType == 0 ? ProlifeSdk.TextResources.Allocations.HumanResource : ProlifeSdk.TextResources.Allocations.MaterialResource;
                    res.typeId = 0;
                }
                if ((a as unknown as IResourcesGroup).Name)
                {
                    let mod = (a as unknown as IResourcesGroup);
                    res.name = mod.Name;
                    res.type = ProlifeSdk.TextResources.Allocations.GroupResource;
                    res.typeId = 1;
                }
                return res;
            });
        });

        this.OpUnitsResourcesAllocatedHours = ko.computed(() => {
            const totals: { [opUnit: number]: IOpUnitsResourcesAllocatedHours; } = <any> {};
            const totalsArray: IOpUnitsResourcesAllocatedHours[] = [];

            this.Allocations().forEach((a: TeamAllocationViewModel) => {
                a.OperationalUnits().forEach((ou: ResourceOperationalUnitViewModel) => {
                    if (!totals[ou.OperationalUnit.Id]) {
                        totals[ou.OperationalUnit.Id] = <IOpUnitsResourcesAllocatedHours> { OpUnitId: ou.OperationalUnit.Id, OpUnitName: ou.Name(), AllocatedHours: ou.AllocatedHours() };
                        totalsArray.push(totals[ou.OperationalUnit.Id]);
                    } else {
                        totals[ou.OperationalUnit.Id].AllocatedHours += ou.AllocatedHours();
                    }
                });
            });

            return totalsArray;
        });

        this.CanHide = ko.computed(() => {
            return this.Allocations().filter((a) => a.HasAllocatedFutureHours()).length == 0
                //&& this.TheoreticalAllocations().filter((a) => a.AllocatedResources() > 0).length == 0
                && this.WorkPoolsEditor.WorkPools().filter((wp) => wp.HoursAmount() > 0).length == 0
        });
    }

    public Load() : Promise<any> {
        const loadAllocationsDef = this.LoadAllocations();
        const loadTheoreticalAllocationsDef = this.LoadTheoreticalAllocations();

        return Promise.all([loadAllocationsDef, loadTheoreticalAllocationsDef]);
    }

    private refreshTeamsPositions(): void {
        // Calcolo la direzioni in cui mi sono spostato
        // se direction = -1 allora mi sono spostato in avanti; +1  indietro; 0 non ho fatto spostamenti
        
        const teams = this.teamsManagerProvider.GetTeams(true);

        if (this.Position() > teams.length && !this.Hide())
            this.Position(teams.length);

        const direction = this.lastPosition - this.Position() == 0 ? 0 : (this.lastPosition - this.Position()) / Math.abs(this.lastPosition - this.Position());

        if (direction == 0)
            return;

        teams.forEach((t) => {
            if (t.Id() != this.Id() && (t.Position() * direction) >= (this.Position() * direction) && (t.Position() * direction) <= (this.lastPosition * direction)) {
                t.lockPositionElaboration = true;
                t.Position(t.Position() + direction);
                t.lockPositionElaboration = false;
            }
        });

        this.lastPosition = this.Position();

        this.refershTeamsGantt();
    }

    private refershTeamsGantt(): void {
        if (this.lastGanttUpdateTimeout)
                clearTimeout(this.lastGanttUpdateTimeout);

        this.lastGanttUpdateTimeout = setTimeout(() => { this.teamsManagerProvider.OnTeamsLoaded.Call(); }, 0);
    }

    private onHideChange(): void {
        this.ValuesHaveChanged(true);

        this.lockPositionElaboration = true;
        this.Position(this.teamsManagerProvider.GetTeams(!this.Hide()).length);
        this.lockPositionElaboration = false;

        this.refreshTeamsPositions();

        this.lastPosition = this.Position();
    }

    private onNewResourceSelected(): void {
        if(!this.NewResourceId())
            return;

        const existingResources: ITeamAllocationViewModel[] = this.Allocations().filter((a : ITeamAllocationViewModel) => a.ResourceId() == this.NewResourceId());
        if(existingResources.length > 0) {
            this.SelectAllocation(existingResources[0]);
            return;
        }

        const newAllocation : ITeamAllocation = {
            Id: this.allocationsService.GenerateNextId(),
            TeamId: this.team.Id,
            ResourceId: this.NewResourceId()
        };

        const teamAllocationViewModel : TeamAllocationViewModel = this.createAllocationViewModel(newAllocation);
        teamAllocationViewModel.Load().then(() => {
            this.teamsManagerProvider.SynchronizeResourceAllocations(newAllocation.ResourceId, newAllocation.TeamId);
        });
        this.Allocations.push(teamAllocationViewModel);
        this.ValuesHaveChanged(true);
        this.SelectAllocation(teamAllocationViewModel);
    }

    private async onNewTeamManagerResourceSelected() {
        if(!this.NewTeamManagerResourceId())
            return;

        const existingResources: (string|IHumanResource|IResourcesGroup)[] = this.TeamManagers().filter((a : (string|IHumanResource|IResourcesGroup)) => {
            if ((a as unknown as IHumanResource).Resource)
                return (a as unknown as IHumanResource).Resource.Id.toString() == this.NewTeamManagerResourceId().toString().split("|")[0]
            if ((a as unknown as IResourcesGroup).Id)
                return (a as unknown as IResourcesGroup).Id.toString() == this.NewTeamManagerResourceId().toString().split("|")[0]
            return false;
        });
        if(existingResources.length > 0) {
            this.SelectTeamManager(existingResources[0]);
            return;
        }
        let id = this.NewTeamManagerResourceId().toString();
        
        let selectedData = await this.ResourceAndGroupsDataSource.getById(null, [id]);
        if(selectedData.length > 0){
            let selected = selectedData.firstOrDefault()?.model;
        
            this.TeamManagers.push(selected);
            this.ValuesHaveChanged(true);
            this.SelectTeamManager(selected);
        }
        
    }

    public addTheoreticalAllocation(): void {
        this.WorkPoolsEditor.addWorkPool(this.m_workPoolBaseRoles);
    }

    public ShowRealAllocations(): void {
        this.AllocationsViewActive(true);
        this.TheoreticalAllocationsViewActive(false);
        this.AllocatedHoursForOpUnitsViewActive(false);
        this.TeamManagerViewActive(false);
    }

    public ShowTheoreticalAllocations(): void {
        this.AllocationsViewActive(false);
        this.TheoreticalAllocationsViewActive(true);
        this.AllocatedHoursForOpUnitsViewActive(false);
        this.TeamManagerViewActive(false);
    }

    public ShowAllocatedHoursForOpUnits(): void {
        this.AllocationsViewActive(false);
        this.TheoreticalAllocationsViewActive(false);
        this.AllocatedHoursForOpUnitsViewActive(true);
        this.TeamManagerViewActive(false);
    }

    public ShowTeamManager(): void {
        this.AllocationsViewActive(false);
        this.TheoreticalAllocationsViewActive(false);
        this.AllocatedHoursForOpUnitsViewActive(false);
        this.TeamManagerViewActive(true);
    }

    public LoadAllocations() : Promise<void> {
        const def = new Deferred<void>();

        this.allocationsService.getTeamAllocations(this.team.Id)
            .then((allocations : ITeamAllocation[]) => {
                const deferreds : Promise<any>[] = [];

                this.Allocations(allocations.map(this.createAllocationViewModel.bind(this)));
                this.Allocations().forEach((all : TeamAllocationViewModel) => deferreds.push(all.Load()));

                Promise.all(deferreds)
                    .then(() => {
                        if(this.VisibleAllocations().length > 0)
                            this.SelectAllocation(this.VisibleAllocations()[0])

                        def.resolve();
                    });
            });

        this.allocationsService.getTeamManagers(this.team.Id)
            .then((teamManagers : IFullTeamManagers[]) => {
                const deferreds : Promise<any>[] = [];
                let ids = teamManagers.map(a => a.IsGroup ? a.ResourceId+"|"+ResourcesAndGroupsDataSource.ResourcesGroupsType : a.ResourceId+"|"+ResourcesAndGroupsDataSource.HumanResourcesType)
                this.ResourceAndGroupsDataSource.getById(null, ids).then(res => {
                    this.TeamManagers(res.map(r => r.model));

                })
                
                Promise.all(deferreds)
                    .then(() => {
                        if(this.VisibleAllocations().length > 0)
                            this.SelectAllocation(this.VisibleAllocations()[0])

                        def.resolve();
                    });
            });
        

            
        return def.promise();
    }

    public LoadTheoreticalAllocations() : Promise<ITeamTheoreticalAllocation[]> {
        return this.allocationsService.getTeamTheoreticalAllocations(this.team.Id)
            .then((theoreticalAllocations: ITeamTheoreticalAllocation[]) => {
                const workPools = theoreticalAllocations.map((t: ITeamTheoreticalAllocation) => {
                    let workPool: IWorkPool = {
                        Id: t.Id,
                        HoursAmount: t.AllocatedResources,
                        Roles: t.Roles
                    };
                    
                    return workPool;
                });
                this.WorkPoolsEditor.loadWorkPools(workPools);
                return theoreticalAllocations;
            });
    }

    private createAllocationViewModel(allocation : ITeamAllocation) : TeamAllocationViewModel {
        return new TeamAllocationViewModel(this.serviceLocator, allocation, this.teamsManagerProvider);
    }

    public RemoveAllocation(allocation : TeamAllocationViewModel) {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ConfirmResourceDelete, ProlifeSdk.TextResources.Allocations.DoNotConfirmResourceDelete, ProlifeSdk.TextResources.Allocations.DoConfirmResourceDelete, (result : boolean) => {
            if(!result) return;

            this.Allocations.remove(allocation);
            this.SelectedAllocation(null);
            this.ValuesHaveChanged(true);

            this.teamsManagerProvider.RemoveTeamOnResource(allocation.ResourceId(), this.Id());
        });
    }

    public RemoveTeamManager(teamManager : IVisibleTeamManagers) {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ConfirmResourceDelete, ProlifeSdk.TextResources.Allocations.DoNotConfirmResourceDelete, ProlifeSdk.TextResources.Allocations.DoConfirmResourceDelete, (result : boolean) => {
            if(!result) return;
            this.TeamManagers.remove(teamManager.model);
            this.SelectTeamManager(null);
            this.ValuesHaveChanged(true);
        });
    }

    public SelectAllocation(allocation : ITeamAllocationViewModel) {
        this.SelectedAllocation(allocation);
    }

    public SelectTeamManager(teamManager : (string|IHumanResource|IResourcesGroup)) {
        this.SelectedTeamManager(teamManager);
    }

    public open() {
        this.teamsManagerProvider.selectTeam(this);
    }

    public getData() : IFullTeam {
        const allocations = this.Allocations().map(a => a.getData(this.team.Id));
        const teamManagers = this.TeamManagers().map((a: (string | IHumanResource | IResourcesGroup)) => {
            let res: IFullTeamManagers = {
                Id: 0,
                TeamId: this.team.Id,
                IsGroup: false,
                ResourceId: 0
            }
            if ((a as unknown as IHumanResource).Resource)
            {
                let mod = (a as unknown as IHumanResource).Resource;
                res.Id = -1;
                res.ResourceId = mod.Id,
                res.IsGroup = false
                return res;
            }
            if ((a as unknown as IResourcesGroup).Name)
            {
                let mod = (a as unknown as IResourcesGroup);
                res.Id = -1;
                res.ResourceId = mod.Id,
                res.IsGroup = true
                return res;
            }
            return res;
            
        });
        //var theoreticalAllocations = this.TheoreticalAllocations().map((ta) => { return ta.getData(); });
        const theoreticalAllocations = this.WorkPoolsEditor.WorkPools()
            .filter((wp) => wp.HoursAmount() > 0 && !!wp.Roles().firstOrDefault(r => r.IsSelected()))
            .map(wp => {
                let workPool = wp.getData();
                let theoreticalAllocation: ITeamTheoreticalAllocation = {
                    Id: workPool.Id,
                    AllocatedResources: workPool.HoursAmount,
                    TeamId: this.team.Id,
                    Roles: workPool.Roles
                };

                return theoreticalAllocation;
            });

        const team : IFullTeam = {
            Id: this.team.Id,
            Name: this.Name(),
            Hide: this.Hide(),
            Position: this.Position(),

            TheoreticalAllocations: theoreticalAllocations,
            Allocations: allocations,
            TeamManagers: teamManagers,
            CanBeFiltered: this.TeamCanBeFiltered()
        };

        return team;
    }

    public SaveChanges(): Promise<ITeam> {
        const team = this.getData();

        return this.allocationsService.createOrUpdateTeam(team)
            .then((team : ITeam) => {
                this.team = team;
                this.Name(team.Name);
                this.Hide(team.Hide);
                this.CreatedBy(team.CreatedBy);
                this.CreationDate(team.CreationDate);
                this.Position(team.Position);

                this.ValuesHaveChanged(false);

                this.LoadAllocations();
                return team;
            });
    }

    public Delete() {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Allocations.ConfirmTeamDelete, ProlifeSdk.TextResources.Allocations.DoNotConfirmTeamDelete, ProlifeSdk.TextResources.Allocations.DoConfirmTeamDelete, (result : boolean) => {
            if(!result) return;

            if(this.team.Id > 0) //E' un team esistente
                this.allocationsService.canDeleteTeam(this.team.Id)
                    .then((canDelete : boolean) => {
                        if(!canDelete) {
                            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CannotDeleteTeam);
                        }

                        this.teamsManagerProvider.deleteTeam(this);
                    });
            else
                this.teamsManagerProvider.deleteTeam(this);
        });
    }

    public Clone(teamsManagerProvider : ITeamsManagerProvider): TeamViewModel {
        const team: TeamViewModel = new TeamViewModel(this.serviceLocator, this.team, teamsManagerProvider);
        team.Allocations(this.Allocations().map((a: TeamAllocationViewModel) => { return a.Clone(teamsManagerProvider); }));
        //team.TheoreticalAllocations(this.TheoreticalAllocations().map((ta: TeamTheoreticalAllocationViewModel) => { return ta.Clone(); }));
        const workPools = this.WorkPoolsEditor.WorkPools().map(wp => wp.getData());
        team.WorkPoolsEditor.loadWorkPools(workPools);
        team.Id(this.Id());
        team.Name(this.Name());
        team.Hide(this.Hide());
        team.Position(this.Position());
        team.CreatedBy(this.CreatedBy());
        team.CreationDate(this.CreationDate());
        const selectedAllocation: ITeamAllocationViewModel = this.SelectedAllocation();
        if (selectedAllocation)
            team.SelectAllocation(team.Allocations().filter((a: TeamAllocationViewModel) => { return a.ResourceId() == selectedAllocation.ResourceId(); }).pop());
        return team;
    }

    GetAllocationByResource(resourceId : number) : ITeamAllocationViewModel {
        if(!resourceId) return null;
        const allocations = this.Allocations().filter((a : TeamAllocationViewModel) => a.ResourceId() == resourceId);
        if(allocations.length == 0) return null;
        return allocations[0];
    }

    GetOperationalUnitsWithAllocations(resourceId : number) : IResourceOperationalUnitViewModel[] {
        const allocation = this.GetAllocationByResource(resourceId);
        if(!allocation) return [];

        return allocation.GetOperationalUnits().filter((ou : IResourceOperationalUnitViewModel) => {
            return ou.AllocationRanges().length > 0;
        })
    }

    GetResourceOperationalUnit(resourceId : number, ouId : number) : IResourceOperationalUnitViewModel {
        const allocation = this.GetAllocationByResource(resourceId);
        if(!allocation) return null;

        return allocation.GetOperationalUnit(ouId);
    }
}