import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import {
    IProtocolSettingsViewModel,
    IVatRegisterEditingContainer,
} from "../../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class WarehouseLoadProtocolSettings implements IProtocolSettingsViewModel {
    public templateName = "warehouseLoadProtocolSettings";
    public templateUrl = "warehouse/templates/settings";

    public CFRequired: ko.Observable<boolean> = ko.observable();
    public DateSequenceRequired: ko.Observable<boolean> = ko.observable();
    public IsInventoryAdjustmentProtocol: ko.Observable<boolean> = ko.observable(false);

    private LockInventoryAdjustmentFlag: ko.Observable<boolean> = ko.observable(true);
    private loading = false;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    constructor(private container: IVatRegisterEditingContainer, private vatRegister: IVatRegister) {
        this.loading = true;

        this.documentsService
            .getDocumentsNumberForProtocol(this.vatRegister.IdRegistroIVA)
            .then((docsNumber: number) => {
                this.LockInventoryAdjustmentFlag(docsNumber > 0);
            });

        this.CFRequired(this.vatRegister.CFRequired);
        this.DateSequenceRequired(this.vatRegister.DateSequenceRequired);
        this.IsInventoryAdjustmentProtocol(vatRegister.IsInventoryAdjustmentProtocol);

        let onInventoryAdjustmentFlagChanges = false;
        this.IsInventoryAdjustmentProtocol.subscribe((val: boolean) => {
            if (this.loading || onInventoryAdjustmentFlagChanges || !this.LockInventoryAdjustmentFlag()) return;

            onInventoryAdjustmentFlagChanges = true;

            if (!this.authorizationsService.isAuthorized("Documents_ChangeVatRegisterInventoryAdjustmentFlag")) {
                this.IsInventoryAdjustmentProtocol(!val);
                this.dialogsService.Alert(
                    ProlifeSdk.TextResources.Invoices.OnVatRegisterInventoryAdjustmentChangesBlockingMessage,
                    ProlifeSdk.TextResources.Invoices.OnVatRegisterInventoryAdjustmentChangesBlockingMessageLabel,
                    () => {
                        onInventoryAdjustmentFlagChanges = false;
                    }
                );
                return;
            }

            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Invoices.OnVatRegisterInventoryAdjustmentChangesWarningMessage,
                ProlifeSdk.TextResources.Invoices.OnVatRegisterInventoryAdjustmentChangesBlockingMessageCancel,
                ProlifeSdk.TextResources.Invoices.OnVatRegisterInventoryAdjustmentChangesBlockingMessageConfirm,
                (confirm: boolean) => {
                    if (!confirm) this.IsInventoryAdjustmentProtocol(!val);

                    onInventoryAdjustmentFlagChanges = false;
                }
            );
        });

        this.loading = false;
    }

    public applyChangesToRegister(vatRegister: IVatRegister) {
        vatRegister.IsInventoryAdjustmentProtocol = this.IsInventoryAdjustmentProtocol();
        vatRegister.CFRequired = this.CFRequired();
        vatRegister.DateSequenceRequired = this.DateSequenceRequired();
    }

    public async load() {}

    public isChanged(): boolean {
        return (
            this.vatRegister.IsInventoryAdjustmentProtocol != this.IsInventoryAdjustmentProtocol() ||
            this.vatRegister.CFRequired != this.CFRequired() ||
            this.vatRegister.DateSequenceRequired != this.DateSequenceRequired()
        );
    }
}
