import * as React from "@abstraqt-dev/jsxknockout"
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { LazyImport } from "../../Core/DependencyInjection";
import { FunctionPointComputationMain, _FunctionPointComputationMain } from "./FunctionPointComputationMain";
import { BaseComponentApplication } from "../../Desktop/BaseComponentApplication";

export class FunctionPointComputationApplication extends BaseComponentApplication
{
    @LazyImport(nameof<IOPAService>())
    private oPAService : IOPAService;
    
    constructor() {
        super({});
    }
    
    getName(): string {
        return ProlifeSdk.TextResources.FunctionPointComputation.FunctionPointComputationApplication;
    }
    
    getIcon(): string {
        return "icon-briefcase";
    }

    getApplicationCode() {
        return "FunctionPointComputation";
    }
    
    registerRoutes(): void {
       
        this.oPAService.Get("#/" + ProlifeSdk.TextResources.FunctionPointComputation.FunctionPointComputationURL_Projects, (context) => this.start(0));
        this.oPAService.Get("#/" + ProlifeSdk.TextResources.FunctionPointComputation.FunctionPointComputationURL_Records, (context) => this.start(1));
    }
    
    getApplicationRoute(): string {
        return "#/" + ProlifeSdk.TextResources.FunctionPointComputation.FunctionPointComputationURL_Projects;
    }

    private start(startIndex: number) 
    {
        this.onGoingDown();       
        this.applicationsService.startApplication(this);
    }
    
    getComponent(): React.ReactNode {
        return <FunctionPointComputationMain navBarActionManager={this}/>
    }
}