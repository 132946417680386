/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 17:43
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ContactIdentifier } from "./ContactIdentifier";
import { QueueDetailsElement } from "./QueueDetailsElement";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    IContactsToCallNumbers,
    ISurveyWizardService,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IQueueState } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueState";
import { IContactForList } from "../../../../ProlifeSdk/interfaces/survey-wizard/IContactForList";
import { IQueueDetailsForWizard } from "../../../../ProlifeSdk/interfaces/survey-wizard/IQueueElement";
import { QueueWithAutomaticContactSelectionTable } from "./QueueWithAutomaticContactSelectionTable";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";

export class QueueWithAutomaticContactSelection {
    public templateName = "automatic-contact-selection";
    public templateUrl = "surveywizard/templates";

    public SelectedContact: ko.Observable<ContactIdentifier> = ko.observable();

    public CallsInProgress: ko.Observable<number> = ko.observable();
    public TerminatedCalls: ko.Observable<number> = ko.observable();
    public ShowOnlyCallableContacts: ko.Observable<boolean> = ko.observable(true);
    public ContactsToCall: ko.Observable<IContactsToCallNumbers> = ko.observable();

    private Queue: ko.ObservableArray<QueueDetailsElement> = ko.observableArray([]);
    public FilteredQueue: ko.ObservableArray<QueueDetailsElement> = ko.observableArray([]);
    public IsLoading: ko.Observable<boolean> = ko.observable(false);
    public EmptyList: ko.Computed<boolean>;

    private LockManager: EntitiesListLockManager;
    private canLoadNextBlock = true;

    private table: QueueWithAutomaticContactSelectionTable;
    private contactFilter: string | null = null;
    private phoneFilter: string | null = null;
    private selectedStatus: string[] = [];
    private selectedCallResults: string[] = [];
    private selectedCallResultsLogicalStatus: string[] = [];
    private plannedFrom: Date | null;
    private plannedTo: Date | null;
    private attemptsFrom: number | null;
    private attemptsTo: number | null;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<ISurveyWizardService>())
    private surveyWizardService: ISurveyWizardService;

    constructor(private campaignId: number) {
        this.LockManager = new EntitiesListLockManager(this.FilteredQueue, ProlifeSdk.SurveyEntityTypeCode);
        this.table = new QueueWithAutomaticContactSelectionTable({
            campaignId: this.campaignId,
            queueFilteredElements: this.FilteredQueue,
            queueAllElements: this.Queue,
            isLoading: this.IsLoading,
            showOnlyCallableContacts: this.ShowOnlyCallableContacts,
            onSelect: this.SelectContact.bind(this),
            onDeselect: this.DeselectContact.bind(this),
            onContactFilterChanges: this.handleContactFilterChanges.bind(this),
            onStatusFilterChanges: this.handleStatusFilterChanges.bind(this),
            onLastCallResultFilterChanges: this.handleLastCallResultFilterChanges.bind(this),
            onLastCallResultLogicalStatusFilterChanges: this.handleLastCallResultLogicalStatusFilterChanges.bind(this),
            onDateRangeFliterChanges: this.handleDateRangeFilterChanges.bind(this),
            onAttemptsNumberFilterChanges: this.handleAttemptsNumberFilterChanges.bind(this),
            onPhoneNumberFilterChanges: this.handlePhoneNumberFilterChanges.bind(this),
        });

        this.surveyWizardService
            .getCallsInProgressNumber(this.campaignId)
            .then((callsNumber: number) => {
                this.CallsInProgress(callsNumber);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetCallsInProgressNumberError);
            });

        this.surveyWizardService
            .getTerminatedCallsNumber(this.campaignId)
            .then((callsNumber: number) => {
                this.TerminatedCalls(callsNumber);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetTerminatedCallsNumberError);
            });

        this.surveyWizardService
            .getContactsToCallNumber(this.campaignId)
            .then((contactsNumber: IContactsToCallNumbers) => {
                this.ContactsToCall(contactsNumber);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetContactsToCallNumberError);
            });

        this.LoadNextContactToCall();
        this.LoadNextQueueBlock();

        this.ShowOnlyCallableContacts.subscribe(() => {
            this.FilteredQueue([]);
            this.LoadNextQueueBlock();
        });

        this.EmptyList = ko.computed(() => {
            return this.FilteredQueue().length == 0;
        });
    }

    private handleContactFilterChanges(contactFilter: string | null): void {
        this.contactFilter = contactFilter;
        this.applyFilters();
    }

    private handlePhoneNumberFilterChanges(phoneFilter: string | null): void {
        this.phoneFilter = phoneFilter;
        this.applyFilters();
    }

    private handleStatusFilterChanges(selectedStatus: string[]): void {
        this.selectedStatus = selectedStatus;
        this.applyFilters();
    }

    private handleLastCallResultFilterChanges(selectedResults: string[]): void {
        this.selectedCallResults = selectedResults;
        this.applyFilters();
    }

    private handleLastCallResultLogicalStatusFilterChanges(selectedResults: string[]): void {
        this.selectedCallResultsLogicalStatus = selectedResults;
        this.applyFilters();
    }

    private handleDateRangeFilterChanges(from: Date | null, to: Date | null): void {
        this.plannedFrom = from;
        this.plannedTo = to;

        this.applyFilters();
    }

    private handleAttemptsNumberFilterChanges(from: number | null, to: number | null): void {
        this.attemptsFrom = from;
        this.attemptsTo = to;

        this.applyFilters();
    }

    private applyFilters(): void {
        this.FilteredQueue(
            this.Queue().filter((q) => {
                return (
                    TextFiltersUtilities.contains(q.ContactIdentifier(), this.contactFilter) &&
                    this.selectedStatus.indexOf(q.Label()) >= 0 &&
                    this.selectedCallResults.indexOf(q.LastCallResultLabel()) >= 0 &&
                    this.selectedCallResultsLogicalStatus.indexOf(q.LastCallResultLogicalStatusLabel()) >= 0 &&
                    (!this.plannedFrom || q.DateTimeToCall().valueOf() >= this.plannedFrom.valueOf()) &&
                    (!this.plannedTo || q.DateTimeToCall().valueOf() <= this.plannedTo.valueOf()) &&
                    (!this.attemptsFrom || q.NumberOfAttempts() >= this.attemptsFrom) &&
                    (!this.attemptsTo || q.NumberOfAttempts() <= this.attemptsTo) &&
                    TextFiltersUtilities.contains(q.PhoneNumber(), this.phoneFilter)
                );
            })
        );
    }

    public Call(): void {
        if (!this.SelectedContact()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.ContactSelectionRequered);
            return;
        }

        this.surveyWizardService
            .getQueueStateLabelByLogicalState(ProlifeSdk.InProgressQueueState)
            .then((state: IQueueState) => {
                this.surveyWizardService
                    .updateQueueState(this.SelectedContact().QueueId, state.Id)
                    .then(() => {
                        location.href =
                            "#/" +
                            ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
                            "/" +
                            ProlifeSdk.TextResources.SurveyWizard.CampaignUrl +
                            "/" +
                            this.campaignId +
                            "/" +
                            ProlifeSdk.TextResources.SurveyWizard.WizardUrl +
                            "/" +
                            this.SelectedContact().QueueId;
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.StartNewCallError);
                    });
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.StartNewCallError);
            });
    }

    public SelectContact(contact: QueueDetailsElement): void {
        const contactForList: IContactForList = {
            QueueId: contact.ItemKey,
            Id: contact.PeopleId,
            Name: contact.Name(),
            Surname: contact.Surname(),
            BusinessName: contact.BusinessName(),
            PhoneNumber: contact.PhoneNumber(),
            Email: contact.Email(),
            PEC: contact.PEC(),
        };

        this.SelectedContact(new ContactIdentifier(contactForList));
    }

    public DeselectContact(contact: QueueDetailsElement): void {
        this.LoadNextContactToCall();
    }

    public async LoadNextContactToCall(): Promise<void> {
        try {
            const contact: IContactForList = await this.surveyWizardService.getNextContactToCall(this.campaignId);
            if (!contact) return;

            this.SelectedContact(new ContactIdentifier(contact));
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetNextContactToCallError);
        }
    }

    public LoadNextQueueBlock(): void {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);
        this.LockManager.WhenIsReady().then(() => {
            if (this.canLoadNextBlock) {
                this.canLoadNextBlock = false;

                this.IsLoading(true);

                this.surveyWizardService
                    //.getCallsQueueDetails(this.campaignId, this.Queue().length, 100, this.ShowOnlyCallableContacts())
                    .getCallsQueueDetails(this.campaignId, 0, 100000, this.ShowOnlyCallableContacts())
                    .then((block: IQueueDetailsForWizard[]) => {
                        const queue = [];
                        block.forEach((q: IQueueDetailsForWizard) => {
                            queue.push(new QueueDetailsElement(q));
                        });

                        this.Queue(queue);
                        this.applyFilters();
                    })
                    .finally(() => {
                        this.desktopService.UnblockPageUI();
                        this.IsLoading(false);
                        setTimeout(() => {
                            this.canLoadNextBlock = true;
                        }, 500);
                    });
            }
        });
    }

    public dispose(): void {}
}
