import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils} from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { DocumentTaxRelief } from "../../../../Invoices/invoices/documents/DocumentTaxReliefEditor";
import { TextResources } from "../../../ProlifeTextResources";
import { PercentageInput } from "../../../../Components/PercentageInputComponent";

const styleSheet = jss.createStyleSheet({
    taxReliefDiscountAutomation: {
        "& .row": {
            "width": "300px"
        }
    }
});
const { classes } = styleSheet.attach();

type TaxReliefRowPercentageAutomationDialogProps = {
    taxRelief: ko.ObservableArray<DocumentTaxRelief>;
}

export class TaxReliefRowPercentageAutomationDialog extends DialogComponentBase {
    static defaultProps: Partial<TaxReliefRowPercentageAutomationDialogProps> = {
    }

    private RowPercentage: ko.Observable<number> = ko.observable(100);

    constructor(private props : TaxReliefRowPercentageAutomationDialogProps) {
        super({ popover: true });
        this.title(TextResources.Invoices.TaxReliefRowPercentageAutomationTitle);
    }
    
    action() {
        for (const taxReliefRow of this.props.taxRelief()) {
            taxReliefRow.DocumentRowTotalPercentage(this.RowPercentage());
        }

        this.modal.close();
    }
    
    renderBody() {
        const rowPercentageAutomation = this;

        return ComponentUtils.bindTo((
            <div className={classes.taxReliefDiscountAutomation}>
                <div className="row">
                    <div className="col-md-12">
                        <PercentageInput
                            value={this.RowPercentage}
                            placeholder={TextResources.Invoices.DocumentTaxReliefDocumentRowPercentagePlaceholder}
                            selectOnFocus
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-primary btn-xs btn-circle" data-bind={{ click: rowPercentageAutomation.action.bind(rowPercentageAutomation) }}>
                            {TextResources.ProlifeSdk.Apply}
                        </button>
                    </div>
                </div>
            </div>
        ), this, "rowPercentageAutomation");
    }
}