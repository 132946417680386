import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 20/03/13
 * Time: 18.06
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BasePagedList } from "../Utilities/BasePagedList";
import { StockArticle } from "./StockArticle";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IStockService, IStockArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";

export class StockTransactionReport extends BasePagedList implements IDialog {
    templateName: string = "warehouse-stock-transaction-report";
    templateUrl: string = "warehouse/templates";
    title: string = ProlifeSdk.TextResources.Warehouse.MovementsSummary;
    modal: { close: (result?: any) => void; };

    @LazyImport(ProlifeSdk.StockServiceType)
    private stockService : IStockService;

    constructor(private transactionId : number, private readonly : boolean = false) {
        super();
        this.rowsPerPage = 17;

        this.loadRows();
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.stockService.commitStockTransaction(this.transactionId)
            .then(() => this.modal.close(true))
            .catch(() => this.modal.close(false));
    }

    public fetchRows() : Promise<IStockArticle[]> {
        return this.stockService.getStockTransactionById(this.transactionId, this.selectedPage() * this.rowsPerPage, this.rowsPerPage);
    }

    public fetchRowsCount() : Promise<number> {
        return this.stockService.getStockTransactionCountById(this.transactionId);
    }

    public createViewModelFor(stockArticle : IStockArticle) : StockArticle {
        return new StockArticle(stockArticle);
    }
}