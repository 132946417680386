import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ReportDataBoundValue } from "../../Components/ReportDataBoundValue";
import { CodeEditor } from "../../../CodeEditor";
import { With } from "../../../IfIfNotWith";
import { Layout } from "../../../Layouts";
import { Select } from "../../../Select";
import { Table } from "../../../TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ReportComponentDataSource } from "../../Components/ReportComponentWithDataSource";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ReportComponent } from "../../Components";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    dataBindingDialog: {
        "&.modal.large > .modal-dialog > .modal-content > .modal-body": {
            display: "flex",
            flexDirection: "column",
            overflow: "unset",
        },
    },
});
const { classes } = styleSheet.attach();

type ValidTypes = string | number | Date | boolean;
type ValidTypeName<T extends ValidTypes> = T extends string
    ? "string"
    : T extends number
    ? "number"
    : T extends Date
    ? "Date"
    : "boolean";

type DataSourceBindingDialogProps<T extends ValidTypes, I extends ValidTypeName<T>> = {
    type: I;
    component: ReportComponent;
    binding: ReportDataBoundValue<T>;
    dataSources: ReportComponentDataSource[];
};

export class DataSourceBindingDialog<T extends ValidTypes, I extends ValidTypeName<T>> extends DialogComponentBase {
    static defaultProps: Partial<DataSourceBindingDialogProps<any, any>> = {};

    private ui: _DataSourceBindingDialogUI<T, I>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props: DataSourceBindingDialogProps<T, I>) {
        super({ className: ComponentUtils.classNames("large", classes.dataBindingDialog) });
    }

    show(): Promise<ReportDataBoundValue<T>> {
        return this.dialogsService.ShowModal(this);
    }

    action() {
        this.ui.onAction().then((value) => this.modal.close(value));
    }

    onRemoveExpression() {
        this.modal.close(new ReportDataBoundValue(null, null, null, false));
    }

    renderFooter() {
        return (
            <>
                <a href="#" class="btn btn-danger" data-bind="click: onRemoveExpression">
                    Rimuovi Espressione
                </a>
                <a href="#" class="btn btn-default" data-bind="click: close">
                    {this.params.closeLabel ?? TextResources.ProlifeSdk.Close}
                </a>
                {!this.params.noPrompt && (
                    <a href="#" class="btn btn-primary" data-bind="click: action">
                        {this.params.actionLabel ?? TextResources.ProlifeSdk.Save}
                    </a>
                )}
            </>
        );
    }

    renderBody() {
        return <DataSourceBindingDialogUI {...this.props} forwardRef={(ui) => (this.ui = ui)} />;
    }
}

export function DataSourceBindingDialogUI<T extends ValidTypes, I extends ValidTypeName<T>>(
    props: DataSourceBindingDialogProps<T, I> & { forwardRef: (ui: _DataSourceBindingDialogUI<T, I>) => void }
) {
    const C = require("./DataSourceBindingDialog")._DataSourceBindingDialogUI as typeof _DataSourceBindingDialogUI;
    return <C {...props} />;
}

export class _DataSourceBindingDialogUI<T extends ValidTypes, I extends ValidTypeName<T>> {
    static defaultProps: Partial<DataSourceBindingDialogProps<any, any>> = {};

    boundExpression: ko.Observable<string> = ko.observable();
    compiledExpression: ko.Observable<string> = ko.observable();
    showEditor: ko.Observable<boolean> = ko.observable(false);

    errors: ko.ObservableArray<string> = ko.observableArray();
    selectedDataSourceId: ko.Observable<number> = ko.observable();
    selectedDataSource: ko.Observable<ReportComponentDataSource> = ko.observable();
    showCodeEditor: ko.Computed<boolean>;

    binding: ReportDataBoundValue<T> = new ReportDataBoundValue();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(
        private props: DataSourceBindingDialogProps<T, I> & {
            forwardRef: (ui: _DataSourceBindingDialogUI<T, I>) => void;
        }
    ) {
        if (this.props.forwardRef) this.props.forwardRef(this);

        this.binding.loadFrom(this.props.binding);

        const defaultResult =
            props.type === "string"
                ? '""'
                : props.type === "number"
                ? "0"
                : props.type === "boolean"
                ? "true"
                : props.type === "Date"
                ? "new Date()"
                : "undefined";

        this.boundExpression(
            this.binding.boundExpression ??
                `function getValue(record: DataSourceRecord) : ${props.type} {\r\n    return ${defaultResult};\r\n}`
        );
        this.compiledExpression(
            this.binding.compiledBoundExpression ?? `function getValue(record) {\r\n    return ${defaultResult};\r\n}`
        );
        this.selectedDataSourceId(this.binding.dataSource?.getData().id);
        this.selectedDataSource(
            this.props.dataSources.firstOrDefault((ds) => ds.getId() === this.selectedDataSourceId())
        );

        this.showCodeEditor = ko.computed(() => {
            const parentDataSourceId = this.props.component.getParentDataSourceId();
            return !!this.selectedDataSourceId() || !!parentDataSourceId;
        });
    }

    private getTypeDefinitions() {
        /*if(this.binding.dataSource?.getDataSourceId())
            return this.binding.getTypeDefinitions();*/

        const parentDataSourceId = this.selectedDataSourceId() ?? this.props.component.getParentDataSourceId();
        const dataSource = this.props.dataSources.firstOrDefault((ds) => ds.getId() === parentDataSourceId);
        if (!dataSource) return this.binding.getTypeDefinitions();

        const boundValue = new ReportDataBoundValue(
            dataSource.getData(),
            this.boundExpression(),
            this.compiledExpression(),
            true
        );
        return boundValue.getTypeDefinitions();
    }

    onAction(): Promise<ReportDataBoundValue<T>> {
        if (this.errors().length > 0) {
            this.infoToastService.Error(
                `Prima di salvare è necessario correggere gli errori presenti nell'espressione\r\n${this.errors().reduce(
                    (p, c) => (p += "\r\n" + c),
                    ""
                )}`
            );
            return new Promise((_, reject) => reject());
        }

        return new Promise((resolve) => {
            resolve(
                new ReportDataBoundValue<T>(
                    this.selectedDataSource()?.getData(),
                    this.boundExpression(),
                    this.compiledExpression(),
                    true
                )
            );
        });
    }

    onDataSourceSelected(i: IDataSourceModel<number, ReportComponentDataSource>): void {
        this.binding.dataSource = i.model;

        this.selectedDataSource(i.model);
    }

    render() {
        const dataSource = Table.defaultDataSource(this.props.dataSources, (item) => ({
            id: item.getId(),
            title: item.getName(),
            subTitle: item.getDataSourceId(),
            icon: { icon: "fa fa-list-alt" },
            model: item,
        }));
        const parentDataSourceId = this.props.component.getParentDataSourceId();

        return (
            <Layout.Vertical>
                <Select.WithLabel
                    label="Fonti dati"
                    dataSource={dataSource}
                    value={this.selectedDataSourceId}
                    onSelect={(i) => this.onDataSourceSelected(i)}
                    placeholder={
                        parentDataSourceId
                            ? "Utilizza la fonte dati del componente padre"
                            : "Seleziona una fonte dati..."
                    }
                />
                <With data={this.showCodeEditor}>
                    {() => (
                        <CodeEditor
                            code={this.boundExpression}
                            typeDefinitions={this.getTypeDefinitions()}
                            onCodeChanged={(c) => this.boundExpression(c)}
                            onCompiledCodeChanged={(c) => this.compiledExpression(c)}
                            onError={(e) => this.errors(e)}
                        />
                    )}
                </With>
            </Layout.Vertical>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DataSourceBindingDialog);
}
