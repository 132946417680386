import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IPurchaseTypeSettingManager, IPurchaseType } from "../SettingManagers/PurchaseTypeSettingManager";
import {
    INavigationMenuComponentDataSource,
    INavigationMenuComponentModel,
} from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IDataSourceView, IDataSourceModel } from "./IDataSource";

export interface IPurchaseTypesDataSource extends INavigationMenuComponentDataSource {}

export interface IPurchaseTypesDataSourceModel extends INavigationMenuComponentModel<number, IPurchaseType> {}

export class PurchaseTypesDataSource implements IPurchaseTypesDataSource {
    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesSettingManager!: IPurchaseTypeSettingManager;

    constructor() {}

    setView(view: IDataSourceView): void {}

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Desktop.PurchasesTypes;
    }

    isGroupedData(currentModel: IDataSourceModel, textFilter: string): boolean {
        return false;
    }

    areEqual(a: IPurchaseTypesDataSourceModel, b: IPurchaseTypesDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id == b.id);
    }

    async getData(
        currentModel: IDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IPurchaseTypesDataSourceModel[]> {
        const textFilterSplits = (textFilter || "").split(" ").filter((word) => word.length > 0);
        const purchaseTypes = this.purchaseTypesSettingManager
            .getAll()
            .filter((pt) => {
                let foundWords = textFilterSplits.filter(
                    (text) => pt.Title.toLowerCase().indexOf(text.toLowerCase()) >= 0
                );
                return foundWords.length == textFilterSplits.length;
            })
            .slice(skip, count);

        return this.createModelsFor(purchaseTypes);
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        const purchaseTypes = ids
            .map<IPurchaseType>((id) => {
                return this.purchaseTypesSettingManager.getById(id);
            })
            .filter((pt) => !!pt);
        return this.createModelsFor(purchaseTypes);
    }

    private createModelsFor(purchaseTypes: IPurchaseType[]): IPurchaseTypesDataSourceModel[] {
        return purchaseTypes.map<IPurchaseTypesDataSourceModel>((pt) => {
            return {
                id: pt.Id,
                isGroup: false,
                isLeaf: true,
                title: pt.Title,
                model: pt,
            };
        });
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
