import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam, ParamArray } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { ICurrenciesSettingsManager } from "../Invoices/invoices/settings/CurrenciesSettingsManager";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { CurrenciesDataSource } from "../DataSources/CurrenciesDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../DataSources/IDataSource";
import { ICurrencyWithCountriesIds } from "../Invoices/interfaces/ICurrenciesService";

let attributes = {
    Currency: "currency",
    ExchangeValue: "exchange-value",
    ExchangeValueForVat: "exchange-value-for-vat",

    IsDocumentCurrency: "is-document-currency",
    ReadOnly: "readonly",
    ShowEditButtonForExchangeValues: "show-edit-button-for-exchange-values",
    
    Listeners: "listeners",
    ShowLabels: "show-labels"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "currency-input": {
                params?: {
                    Currency: number;
                    ExchangeValue: number;
                    ExchangeValueForVat?: number;
                    
                    IsDocumentCurrency?: boolean,
                    ReadOnly?: boolean,
                    ShowEditButtonForExchangeValues?: boolean,
                    
                    Listeners?: IDataSourceListener[];
                    ShowLabels?: boolean;
                };
               
                "currency": number | (() => string);
                "exchange-value": number | (() => string);
                "exchange-value-for-vat"?: number | (() => string);

                "is-document-currency"?: boolean | (() => string);
                "readonly"?: boolean | (() => string);
                "show-edit-button-for-exchange-values"?: boolean | (() => string);
                
                "listeners"?: IDataSourceListener[] | (() => string);
                "show-labels"?: boolean | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ICurrencyInputParams {
    Currency: Param<number>,
    ExchangeValue: Param<number>,
    ExchangeValueForVat: Param<number>,

    IsDocumentCurrency: Param<boolean>,
    ReadOnly: Param<boolean>,
    ShowEditButtonForExchangeValues: Param<boolean>,
    
    DataSource: Param<IDataSource>,
    Listeners: ParamArray<IDataSourceListener>,
    ShowLabels: Param<boolean>
}

export class CurrencyInputComponent implements IDataSourceListener {
    public Currency: ComponentParam<number>;
    public ExchangeValue: ComponentParam<number>;
    public ExchangeValueForVat: ComponentParam<number>;

    public IsDocumentCurrency: ComponentParam<boolean>;
    public ReadOnly: ComponentParam<boolean>;
    public ShowEditButtonForExchangeValues: ComponentParam<boolean>;
    public ShowLabels: ComponentParam<boolean>;

    public ExchangeValueLabel: ko.Computed<string>;
    public ExchangeValueForVatLabel: ko.Computed<string>;
    public ShowEditButtonOnExchangeValuesFields: ko.Computed<boolean>;

    public CurrenciesDataSource: IDataSource;
    public Listeners: IDataSourceListener[];

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    private currenctCurrency: ko.Observable<ICurrencyWithCountriesIds> = ko.observable();
    private defaultCurrency: ko.Observable<ICurrencyWithCountriesIds> = ko.observable();

    constructor(params : ICurrencyInputParams) {
        let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();

        this.CurrenciesDataSource = this.createCurrenciesDataSource();
        this.Listeners = ko.utils.unwrapObservable(ComponentUtils.parseParameterArray(params.Listeners, []));
        this.Currency = ComponentUtils.parseParameter(params.Currency, defaultCurrency?.Id);

        let isDefaultCurrency = this.Currency() === defaultCurrency?.Id;

        this.ExchangeValue = ComponentUtils.parseParameter(params.ExchangeValue, 1);
        this.ExchangeValueForVat = ComponentUtils.parseParameter(params.ExchangeValueForVat, isDefaultCurrency ? 1 : null);
        this.IsDocumentCurrency = ComponentUtils.parseParameter(params.IsDocumentCurrency, false);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.ShowEditButtonForExchangeValues = ComponentUtils.parseParameter(params.ShowEditButtonForExchangeValues, false);
        
        this.ShowLabels = ComponentUtils.parseParameter(params.ShowLabels, true);

        this.ExchangeValueLabel = ko.computed(() => {
            return String.format(TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValueLabel, this.currenctCurrency()?.CodeISO4217alpha3 ?? "", this.defaultCurrency()?.CodeISO4217alpha3 ?? "");
        });

        this.ExchangeValueForVatLabel = ko.computed(() => {
            return String.format(TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValueForVatLabel, this.currenctCurrency()?.CodeISO4217alpha3 ?? "", this.defaultCurrency()?.CodeISO4217alpha3 ?? "");
        });

        this.ShowEditButtonOnExchangeValuesFields = ko.computed(() => {
            return this.ShowEditButtonForExchangeValues() && !this.ReadOnly();
        });

        this.defaultCurrency(defaultCurrency);

        if (this.Currency() !== defaultCurrency?.Id) {
            let currentCurrency = this.currenciesSettingsManager.getCurrencyById(this.Currency());
            this.currenctCurrency(currentCurrency);
        } else {
            this.currenctCurrency(defaultCurrency);
        }

        this.Listeners.push(this);
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.currenctCurrency(model?.model);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, ICurrencyWithCountriesIds>): void {
        this.currenctCurrency(model?.model);
    }

    private createCurrenciesDataSource(): IDataSource {
        let ds = new CurrenciesDataSource();
        ds.returnDeletedCurrencies(false);
        return ds;
    }
}

ko.components.register("currency-input", {
    viewModel: {
        createViewModel: (params: ICurrencyInputParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new CurrencyInputComponent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="row">
                    <div class="col-md-4">
                        <ko-ifnot data-bind="ShowLabels">
                            <select2 dataSource={() => "CurrenciesDataSource"} value={() => "Currency"} listener={() => "Listeners"} placeholder={TextResources.ProlifeSdk.SearchPlaceholder} allowClear={false} readOnly={() => "ReadOnly"}></select2>
                        </ko-ifnot>
                        <ko-if data-bind="ShowLabels">
                            <select2 dataSource={() => "CurrenciesDataSource"} value={() => "Currency"} listener={() => "Listeners"} placeholder={TextResources.ProlifeSdk.SearchPlaceholder} allowClear={false} readOnly={() => "ReadOnly"} label={TextResources.Invoices.CurrencyLabel}></select2>
                        </ko-if>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <ko-ifnot data-bind="ShowLabels">
                                <numeric-input value={() => "ExchangeValue"} format={"0,0.00[00]"} showEditButton={() => "ShowEditButtonOnExchangeValuesFields"} readOnly={() => "ReadOnly"} placeholder={TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValue}></numeric-input>
                            </ko-ifnot>
                            <ko-if data-bind="ShowLabels">
                                <numeric-input value={() => "ExchangeValue"} format={"0,0.00[00]"} showEditButton={() => "ShowEditButtonOnExchangeValuesFields"} readOnly={() => "ReadOnly"} placeholder={TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValue} label={() => "ExchangeValueLabel"}></numeric-input>
                            </ko-if>
                        </div>
                    </div>
                    <div class="col-md-4" data-bind="if: IsDocumentCurrency">
                        <div class="form-group">
                            <ko-ifnot data-bind="ShowLabels">
                                <numeric-input value={() => "ExchangeValueForVat"} format={"0,0.00[00]"} showEditButton={() => "ShowEditButtonOnExchangeValuesFields"} readOnly={() => "ReadOnly"} placeholder={TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValueForVat}></numeric-input>
                            </ko-ifnot>
                            <ko-if data-bind="ShowLabels">
                                <numeric-input value={() => "ExchangeValueForVat"} format={"0,0.00[00]"} showEditButton={() => "ShowEditButtonOnExchangeValuesFields"} readOnly={() => "ReadOnly"} placeholder={TextResources.Invoices.DocumentCurrencyToDefaultCurrencyExchangeValueForVat} label={() => "ExchangeValueForVatLabel"}></numeric-input>
                            </ko-if>
                        </div>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});