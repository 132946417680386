import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 18:26
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ITodoListResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITaskResource } from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IControlsMultipleEntitiesProvider, IItemIdWithType } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";

export class ResourcesAndGroupsManager
{
    private entitiesProviderService : IEntityProviderService;
    public resourcesProvider : IControlsMultipleEntitiesProvider;

    public Resources : ko.Observable<string> = ko.observable("");
    public ResourcesIdsAndTypes : ko.ObservableArray<ITodoListResource> = ko.observableArray([]);

    private originalResources : string;

    constructor(public serviceLocator : IServiceLocator)
    {
        this.entitiesProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.resourcesProvider = this.entitiesProviderService
            .GetMultipleEntitiesProviderForControls([ProlifeSdk.HumanResources, ProlifeSdk.ResourcesGroups], false, false);
    }

    public LoadResources(resources : ITodoListResource[])
    {
        this.ResourcesIdsAndTypes(resources);

        var resourcesString : string = this.resourcesProvider.formatItemsForControlValueField(resources.map((r : ITodoListResource) => {
            return <IItemIdWithType>{
                Id : r.ElementId,
                Type : r.ElementType
            };
        }));
        this.Resources(resourcesString);
        this.originalResources = resourcesString;
    }

    public IsChanged() : boolean
    {
        return this.originalResources != this.Resources();
    }

    public GetResources() : ITodoListResource[]
    {
        return this.resourcesProvider.extractItemsAndTypesFromStringValue(this.Resources()).map((i : IItemIdWithType) => {
            return <ITaskResource>{
                ElementType : i.Type,
                ElementId : i.Id
            }
        });
    }
}
