import { Service, LazyImport } from "../Core/DependencyInjection";
import { IService } from "../Core/interfaces/IService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";

export interface IGetVatRegistersRequest {
    textFilter?: string;
    fiscalDocumentType?: number;
    getOnlyInventoryAdjustment?: boolean;
    skip?: number;
    count?: number;
    userVisibleRegisters?: number[];
}

export interface IVatRegister {
    IdRegistroIVA : number;
    FKCompany?: number;
    NomeRegistroIVA?: string;
    Formato?: string;
    TipoDocumento : number;
    NuovoLabel?: string;
    Prefisso : string;
    Suffisso : string;
    Stato?: number;
    TipiFatture : string;
    DefaultDocument : number;
    DocumentoFiscale : number;
    Posizione : number;
    VisualizzaImporti : number;
    DescContributoSuImponibileIVA?: string;
    ContributoSuImponibileIVA : number;
    DescContributoSuCompenso?: string;
    ContributoSuCompenso : number;
    HtmlFooter?: string;
    Logo?: string;
    HeaderImage?: string;
    DefaultLayoutId : number;
    DefaultJobOrderFilteredByCustomer : boolean;
    DdtCanBeImportedToShippingInvoice : boolean;
    AccountingSoftwareCode?: string;
    Notes?: string;
    InsertJobOrderIntoNotes : boolean;
    InsertOriginatingDocumentsIntoNotes : boolean;
    JobOrderIntoNotesPrefix?: string;
    DocumentIntoNotesPrefix?: string;
    DefaultSearchProvider?: string;
    InvoiceCanBeImportedToInvoice : boolean;
    IsInventoryAdjustmentProtocol?: boolean;
    CFRequired : boolean;
    DateSequenceRequired : boolean;
    PaymentAndExpireModeRequired : boolean;
    ValidityRequired : boolean;
    ImportCalculatedExpiriesIntoDocumentNotes : boolean;
    InvoiceLabel?: string;
    CreditNoteLabel?: string;
    AccompanyingInvoiceLabel?: string;
    ExpiresIntoNotesPrefix?: string;
    ExpiresIntoNotesSuffix?: string;
    ForPartialInvoices : boolean;
    GroupLabel?: string;
    PositionInList : number;
    StampDuty : boolean;
    StampDutyValue : number;
    SalInvoiceLabel?: string;
    ShowAllCustomersAndSuppliers : boolean;
    ShowOnlyJobOrderWarehouses : boolean;
    VersionNumberPrefix?: string;
    VersionNumberSuffix?: string;
    VersionNumberSeparator?: string;
    RequireVersionRevision : boolean;
}

export interface IVatRegistersService extends IService {
    GetVatRegistersByIds(ids : number[] | null) : Promise<IVatRegister[]>;
    GetVatRegisters(request : IGetVatRegistersRequest) : Promise<IVatRegister[]>;
}

@Service(nameof<IVatRegistersService>())
class VatRegistersService implements IVatRegistersService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    GetVatRegistersByIds(ids : number[] | null) : Promise<IVatRegister[]> {
        let result = this.ajaxService.Post<IVatRegister[]>("Invoices-api/VatRegister", "GetVatRegistersByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetVatRegisters(request: IGetVatRegistersRequest): Promise<IVatRegister[]> {
        let result = this.ajaxService.Post<IVatRegister[]>("Invoices-api/VatRegister", "GetVatRegisters", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IVatRegistersService>());
}
