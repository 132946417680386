import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 27/06/2018
 * Time: 19:02
 * To change this template use File | Settings | File Templates.
 */

 import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ImportMonthlyInvoicingDataWizardInvoicingBaseStep } from "./ImportMonthlyInvoicingDataWizardInvoicingBaseStep";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IMonthlyInvoicingDataSource } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IEntityToImportInfoForMonthlyInvoicing } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";

export class ImportMonthlyInvoicingDataWizardGroupingStep extends ImportMonthlyInvoicingDataWizardInvoicingBaseStep {
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor(private destinationEntityTypeCode: string, protected dataSource: IMonthlyInvoicingDataSource, initializationInfo: IWizardInitializationInfo) {
        super('import-monthly-invoicing-data-wizard-grouping-step', 'invoices/templates/wizard/steps', ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardGroupingStepTitle, dataSource, initializationInfo);

        this.CanDoPartialInvoicing(this.authorizationsService.isAuthorized("Documents_InvoiceImport"));
    }

    public BeforeShow()
    {
        super.BeforeShow();

        var entitiesToImport: IEntityToImportInfoForMonthlyInvoicing[][] = this.dataSource.GetEntitiesToImport(this);
        var entitiesList: IEntityToImportInfoForMonthlyInvoicing[] = [];

        entitiesToImport.forEach((d: IEntityToImportInfoForMonthlyInvoicing[]) => {
            entitiesList = entitiesList.concat(d);
        });

        entitiesList.forEach((e: IEntityToImportInfoForMonthlyInvoicing) => {
            e.storage(this);
            e.Selected(false);
            e.GenerateLabel(this.LabelsMap);
        });

        this.EntitiesList(entitiesList);
    }

    public BeforeNext(): boolean
    {
        if (this.HasEntitiesWithoutLabel()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.MisssingLabels);
            return false;
        }

        this.dataSource.ImportData.WithPartialInvoicingStep = this.PartialInvoicing();

        return true;
    }

    public RegenerateLabels(): void {
        this.LabelsMap = {};
        this.EntitiesList().forEach((e: IEntityToImportInfoForMonthlyInvoicing) => e.GenerateLabel(this.LabelsMap));
    }

    public async GetDocumentsToImportInfo(): Promise<IEntityToImportInfoForMonthlyInvoicing[]>
    {
        let entities: IEntityToImportInfoForMonthlyInvoicing[] = [];

        for (let d of this.EntitiesList()) {
            entities.push(await d.GetCopy());
        }
        
        return entities;
    }
}