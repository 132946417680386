import * as ko from "knockout";
import { LazyImport, Service } from "../Core/DependencyInjection";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface IManufacturerAliases {
    Id?: number;
    ManufacturerId : number;
    CustomerId : number;
    NewManufacturerPrefix?: string;
}

export interface IManufacturerAlias {
    Id?: number;
    ManufacturerId : number;
    CustomerId : number;
    NewManufacturerPrefix?: string;
}

export interface IManufacturerAliasEx {
    Id : number;
    ManufacturerId : number;
    CustomerId : number;
    NewManufacturerPrefix?: string;
    OriginalManufacturerCode?: string;
}

export interface IArticleAlias {
    Id?: number;
    ArticleId : number;
    CustomerId : number;
    NewManufacturerPrefix?: string;
    NewCode?: string;
}

export interface IArticleAliasEx {
    Id?: number;
    ArticleId : number;
    CustomerId : number;
    NewManufacturerPrefix?: string;
    NewCode?: string;
    OriginalArticleCode?: string;
    AliasArticleCode?: string;
    Description?: string;
}

export interface IResolvedArticleAlias {
    ArticleId?: number;
    Code : string;
    Description?: string;
    AliasedCode?: string;
    CatalogId?: number;
}

export interface IAliasesService extends IService {
    RemoveArticleAlias(aliasId : number | null) : Promise<void>;
    GetArticleAliases(customerId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IArticleAliasEx[]>;
    GetArticleAliasesByIds(aliasIds : number[] | null) : Promise<IArticleAliasEx[]>;
    InsertOrUpdateManufacturerAlias(alias : IManufacturerAliases[] | null) : Promise<IManufacturerAlias[]>;
    RemoveManufacturerAlias(aliasId : number | null) : Promise<void>;
    GetManufacturerAliases(customerId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IManufacturerAliasEx[]>;
    GetManufacturerAliasesByIds(aliasIds : number[] | null) : Promise<IManufacturerAliasEx[]>;
    InsertOrUpdateArticleAlias(alias : IArticleAlias[] | null) : Promise<IArticleAliasEx[]>;
    ResolveArticleAliasesByIds(articleIds : number[] | null, customerId : number | null) : Promise<IResolvedArticleAlias[]>;
}

@Service(nameof<IAliasesService>())
export class AliasesService implements IAliasesService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    InitializeService() : void {

    }

    RemoveArticleAlias(aliasId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/ArticleAliases", "RemoveArticleAlias", {
            background: true,
            methodData: {
                aliasId: aliasId,
            }
        });
    }

    GetArticleAliases(customerId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IArticleAliasEx[]> {
        return this.ajaxService.Post<IArticleAliasEx[]>("Warehouse-api/ArticleAliases", "GetArticleAliases", {
            background: true,
            methodData: {
                customerId: customerId,
                textFilter: textFilter,
                skip: skip,
                count: count,
            }
        });
    }

    GetArticleAliasesByIds(aliasIds : number[] | null) : Promise<IArticleAliasEx[]> {
        return this.ajaxService.Post<IArticleAliasEx[]>("Warehouse-api/ArticleAliases", "GetArticleAliasesByIds", {
            background: true,
            methodData: {
                aliasIds: aliasIds,
            }
        });
    }

    InsertOrUpdateManufacturerAlias(alias : IManufacturerAliases[] | null) : Promise<IManufacturerAlias[]> {
        return this.ajaxService.Post<IManufacturerAlias[]>("Warehouse-api/ManufacturerAliases", "InsertOrUpdateManufacturerAlias", {
            background: true,
            methodData: {
                alias: alias,
            }
        });
    }

    RemoveManufacturerAlias(aliasId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Warehouse-api/ManufacturerAliases", "RemoveManufacturerAlias", {
            background: true,
            methodData: {
                aliasId: aliasId,
            }
        });
    }

    GetManufacturerAliases(customerId : number | null, textFilter : string | null, skip : number | null, count : number | null) : Promise<IManufacturerAliasEx[]> {
        return this.ajaxService.Post<IManufacturerAliasEx[]>("Warehouse-api/ManufacturerAliases", "GetManufacturerAliases", {
            background: true,
            methodData: {
                customerId: customerId,
                textFilter: textFilter,
                skip: skip,
                count: count,
            }
        });
    }

    GetManufacturerAliasesByIds(aliasIds : number[] | null) : Promise<IManufacturerAliasEx[]> {
        return this.ajaxService.Post<IManufacturerAliasEx[]>("Warehouse-api/ManufacturerAliases", "GetManufacturerAliasesByIds", {
            background: true,
            methodData: {
                aliasIds: aliasIds,
            }
        });
    }

    InsertOrUpdateArticleAlias(alias : IArticleAlias[] | null) : Promise<IArticleAliasEx[]> {
        return this.ajaxService.Post<IArticleAliasEx[]>("Warehouse-api/ArticleAliases", "InsertOrUpdateArticleAlias", {
            background: true,
            methodData: {
                alias: alias,
            }
        });
    }

    ResolveArticleAliasesByIds(articleIds: number[] | null, customerId: number | null): Promise<IResolvedArticleAlias[]> {
        let result = this.ajaxService.Post<IResolvedArticleAlias[]>("Warehouse-api/ArticleAliases", "ResolveArticleAliasesByIds", {
            background: true,
            methodData: {
        		articleIds: articleIds,
        		customerId: customerId,
        	}
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new AliasesService();
}
