import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as Core from "../../../../Core/Core";
import {
    INavigationMenuComponentAlert,
    INavigationMenuComponentBadge,
    INavigationMenuComponentModel,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IJobOrderWorkflowsDetails } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ITodolistWorkflowForAllocationsList } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IWorkflowForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { RelatedDocumentsPopOver } from "../workflows/RelatedDocumentsPopOver";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";

export class WorkflowsUtils {
    public static getAlertsForWorkflow(
        w: IWorkflowForTaskBoard | ITodolistWorkflowForAllocationsList
    ): INavigationMenuComponentAlert[] {
        const alerts: INavigationMenuComponentAlert[] = [];

        if (w.IsLateStart)
            alerts.push({
                icon: {
                    icon: "icon-hourglass",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsLateStart,
            });
        if (w.IsLateEnd)
            alerts.push({
                icon: {
                    icon: "icon-hourglass",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsLateEnd,
            });
        if (!w.IsReady)
            alerts.push({
                icon: {
                    icon: "fa fa-exclamation-circle",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowTasksAreNotReady,
            });
        if (!w.HasWarehouse)
            alerts.push({
                icon: {
                    icon: "fa fa-shopping-cart",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsWarehouseArticles,
            });
        if (!w.HasPurchases)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsPurchases,
            });
        if (!w.HasResources)
            alerts.push({
                icon: {
                    icon: "fa fa-users",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsResources,
            });
        if (!w.HasWorkflowWarehouse)
            alerts.push({
                icon: {
                    icon: "fa fa-shopping-cart",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsMoreWarehouseArticles,
            });
        if (!w.WorkflowIsReady)
            alerts.push({
                icon: {
                    icon: "fa fa-exclamation-circle",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsNotReady,
            });
        if (w.HasReportingTasks)
            alerts.push({
                icon: {
                    icon: "fa fa-euro",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasBillableTasks,
            });
        if (w.HoursToBeBilled > 0)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasToBeBilledHours,
            });
        if (w.HasNotApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasNotApprovedBudgetRequests,
            });
        if (w.HasApprovedBudgetRequests)
            alerts.push({
                icon: {
                    icon: "icon-wallet",
                    background: "transparent",
                    foreground: "green",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasApprovedBudgetRequests,
            });
        if (!w.HasEstimatedWork)
            alerts.push({
                icon: {
                    icon: "fa fa-terminal",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasNotEstimatedWork,
            });

        if (w.WorkflowMustBePlanned)
            alerts.push({
                icon: {
                    icon: "fa fa-warning",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBePlanned,
            });

        if (w.ActivitiesMustBeReviewed)
            alerts.push({
                icon: {
                    icon: "fa fa-bookmark",
                    background: "transparent",
                    foreground: "red",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasActivitiesToBeReviewed,
            });

        if (w.WorkflowMustBeRelatedToCustomerOrders) {
            alerts.push({
                icon: {
                    icon: "fa fa-files-o",
                    background: "transparent",
                    foreground: "orange",
                },
                tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBeRelatedToCustomerOrders,
            });
        }

        return alerts;
    }

    public static getAllocationBadge(
        workflow: IWorkflowForTaskBoard | ITodolistWorkflowForAllocationsList | IJobOrderWorkflowsDetails,
        dialogsService: IDialogsService
    ): INavigationMenuComponentBadge {
        const workflowId = (workflow as IJobOrderWorkflowsDetails).WorkflowId ?? (workflow as IWorkflowForTaskBoard).Id;
        return {
            text: numeral(workflow.AllocationPercentage / 100).format("0[.]0[0]%"),
            cssClass: WorkflowsUtils.getAllocationBadgeClass(workflow.HasNotEstimatedElementsAllocated),
            action: WorkflowsUtils.getAllocationBadgeAction(workflowId, workflow.JobOrderId, dialogsService),
        };
    }

    public static getAllocationBadgeClass(hasNotEstimatedElementsAllocated: boolean): string {
        if (hasNotEstimatedElementsAllocated) return "badge-danger";

        return "badge-success";
    }

    public static getAllocationBadgeAction(
        workflowId: number,
        jobOrderId: number,
        dialogsService: IDialogsService
    ): (sender: INavigationMenuComponentModel, event: Event) => void {
        return (sender: INavigationMenuComponentModel, event: Event) => {
            dialogsService.ShowPopoverComponent(
                event.currentTarget as HTMLElement,
                {
                    componentName: "workflow-allocation-teams",
                    title: "Team Attività",
                    params: "JobOrderId: JobOrderId, WorkflowId: WorkflowId",
                    model: {
                        JobOrderId: jobOrderId,
                        WorkflowId: workflowId,
                    },
                },
                "left"
            );
        };
    }

    public static getRelatedDocumentsBadge(
        workflow: IWorkflowForTaskBoard | ITodolistWorkflowForAllocationsList | IJobOrderWorkflowsDetails
    ): INavigationMenuComponentBadge {
        const workflowId = (workflow as IJobOrderWorkflowsDetails).WorkflowId ?? (workflow as IWorkflowForTaskBoard).Id;

        const authorizationService = Core.serviceLocator.findService<IAuthorizationService>(
            nameof<IAuthorizationService>()
        );

        const canViewPopOver = authorizationService.isAuthorized("TaskBoard_ViewWorkflowRelatedDocuments");

        return {
            text: workflow.RelatedDocuments.toString(),
            cssClass: WorkflowsUtils.getRelatedDocumentsBadgeClass(
                workflow.RelatedDocuments,
                workflow.WorkflowMustBeRelatedToCustomerOrders
            ),
            title: WorkflowsUtils.getRelatedDocumentsBadgeTitle(workflow.WorkflowMustBeRelatedToCustomerOrders),
            action: canViewPopOver ? WorkflowsUtils.getRelatedDocumentsBadgeAction(workflowId) : undefined,
        };
    }

    public static getRelatedDocumentsBadgeClass(
        relatedDocuments: number,
        workflowMustBeRelatedToCustomerOrders: boolean
    ): string {
        const baseClass = "badge-roundless";
        if (relatedDocuments === 0) return baseClass + " badge-danger";
        if (workflowMustBeRelatedToCustomerOrders) return baseClass + " badge-warning";
        return baseClass + " badge-success";
    }

    public static getRelatedDocumentsBadgeTitle(workflowMustBeRelatedToCustomerOrders: boolean): string {
        if (workflowMustBeRelatedToCustomerOrders)
            return TextResources.Todolist.WorkflowRelatedDocumentsBadgeWithAlertTitle;
        return TextResources.Todolist.WorkflowRelatedDocumentsBadgeTitle;
    }

    public static getRelatedDocumentsBadgeAction(
        workflowId: number
    ): (sender: INavigationMenuComponentModel, event: Event) => void {
        return (sender: INavigationMenuComponentModel, event: Event) => {
            const popOver = new RelatedDocumentsPopOver({ workflowId: workflowId });
            popOver.show(event.currentTarget as HTMLElement);
        };
    }

    public static getAllBadges() {
        const badges: INavigationMenuComponentBadge[] = [];

        badges.push({
            text: "0",
            cssClass: "badge badge-roundless badge-danger",
            title: TextResources.Todolist.WorkflowRelatedDocumentsBadgeWithAlertLegendRedTitle,
        });

        badges.push({
            text: "2",
            cssClass: "badge badge-roundless badge-warning",
            title: TextResources.Todolist.WorkflowRelatedDocumentsBadgeWithAlertLegendOrangeTitle,
        });

        badges.push({
            text: "1",
            cssClass: "badge badge-roundless badge-success",
            title: TextResources.Todolist.WorkflowRelatedDocumentsBadgeLegendGreenTitle,
        });

        badges.push({
            text: "10 %",
            cssClass: "badge badge-danger",
            title: TextResources.Todolist.WorkflowAllocationBadgeLegendRedTitle,
        });

        badges.push({
            text: "10 %",
            cssClass: "badge badge-success",
            title: TextResources.Todolist.WorkflowAllocationBadgeLegendGreenTitle,
        });

        return badges;
    }

    public static getAllAlerts() {
        const alerts: INavigationMenuComponentAlert[] = [];

        alerts.push({
            icon: {
                icon: "icon-hourglass",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsLateStart,
        });

        alerts.push({
            icon: {
                icon: "icon-hourglass",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsLateEnd,
        });

        alerts.push({
            icon: {
                icon: "fa fa-exclamation-circle",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowTasksAreNotReady,
        });

        alerts.push({
            icon: {
                icon: "fa fa-shopping-cart",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsWarehouseArticles,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsPurchases,
        });

        alerts.push({
            icon: {
                icon: "fa fa-users",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsResources,
        });

        alerts.push({
            icon: {
                icon: "fa fa-shopping-cart",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowNeedsMoreWarehouseArticles,
        });

        alerts.push({
            icon: {
                icon: "fa fa-exclamation-circle",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowIsNotReady,
        });

        alerts.push({
            icon: {
                icon: "fa fa-euro",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasBillableTasks,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasToBeBilledHours,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasNotApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "icon-wallet",
                background: "transparent",
                foreground: "green",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasApprovedBudgetRequests,
        });

        alerts.push({
            icon: {
                icon: "fa fa-terminal",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasNotEstimatedWork,
        });

        alerts.push({
            icon: {
                icon: "fa fa-warning",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBePlanned,
        });

        alerts.push({
            icon: {
                icon: "fa fa-bookmark",
                background: "transparent",
                foreground: "red",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowHasActivitiesToBeReviewed,
        });

        alerts.push({
            icon: {
                icon: "fa fa-files-o",
                background: "transparent",
                foreground: "orange",
            },
            tooltip: ProlifeSdk.TextResources.Todolist.WorkflowMustBeRelatedToCustomerOrders,
        });

        return alerts;
    }
}
