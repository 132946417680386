import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { ISupplierForList, ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";

export interface ISuppliersDataSourceModel extends IDataSourceModel<number, ISupplierForList> {

}

export class SuppliersDataSource implements IDataSource {
    @LazyImport(nameof<ICustomersService>())
    private customersService : ICustomersService;

    getTitle(currentModel: ISuppliersDataSourceModel): string {
        return "Fornitori";
    }    
    
    isGroupedData(currentModel: ISuppliersDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: ISuppliersDataSourceModel, b: ISuppliersDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: ISuppliersDataSourceModel, textFilter: string, skip: number, count: number): Promise<ISuppliersDataSourceModel[]> {
        let allCustomers = await this.customersService.GetSuppliersForList(textFilter, skip, count);

        return allCustomers.map(this.createModelFor, this);
    }

    createModelFor(customer: ISupplierForList): ISuppliersDataSourceModel {
        return {
            id: customer.IdCliente,
            title: customer.RagioneSociale,
            icon: {
                icon: 'icon-user'
            },
            isLeaf: true,
            isGroup: false,
            model: customer
        }
    }
    
    async getById(currentModel: ISuppliersDataSourceModel, ids: number[]): Promise<ISuppliersDataSourceModel[]> {
        let suppliers = await this.customersService.GetSuppliersForListByIds(ids);
        return suppliers.map(this.createModelFor, this);
    }
    
    setView(view: IDataSourceView): void {
        
    }
    
    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}