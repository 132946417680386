export enum DocumentsLogStatus {
    Queued = 0,
    Generated = 1,
    NotGeneratedForInvalidTrustBalance = 2,
    NotGeneratedForUnexpectedError = 3,
    Aborted = 4,
    MailSendSuccess = 5,
    MailSendPartial = 6,
    MailSendError = 7,
    Refused = 8,
}
