import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Document } from "./Document";

export function DocumentTotals() {
    let doc: Document;
    let $data: any;

    return (
        <ko-bind data-bind={{ let: { doc: $data }}}>
            <ko-bind data-bind={{ if: doc.CanShowTotals }}>
                <ko-bind data-bind={{ if: doc.CanHaveVAT }}>
                    <DocumentTotalsWithVAT></DocumentTotalsWithVAT>
                </ko-bind>
                <ko-bind data-bind={{ ifnot: doc.CanHaveVAT }}>
                    <DocumentTotalsWithoutVAT></DocumentTotalsWithoutVAT>
                </ko-bind>
            </ko-bind>
        </ko-bind>
    );
}

function WithWithholdingTaxField() {
    let doc: Document;

    return (
        <>
            <ko-bind data-bind={{ ifnot: doc.ReadOnly }}>
                <input autoComplete="off" type="text" id="withholdingTax" data-bind={{ percentagesValue: doc.WithholdingTax }} />
            </ko-bind>
            <ko-bind data-bind={{ if: doc.ReadOnly }}>
                <span id="withholdingTax" data-bind={{ percentagesText: doc.WithholdingTax }} />
            </ko-bind>
        </>
    );
}

function DocumentTotalsWithVAT() {
    let doc: Document;

    return  <div class="invoice-totals bootstrap" data-bind={{ if: doc.ShowAmounts }}>
                <ko-bind data-bind={{ if : doc.CanHaveIRPEFTax }}>
                    <ko-bind data-bind={{ if : doc.IRPEFTax() > 0 }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot">
                                    <ko-bind data-bind={{ text : doc.IRPEFTaxDescription }}></ko-bind> (<ko-bind data-bind={{ percentageText : doc.IRPEFTax }}></ko-bind>)
                                    <span data-bind={{ moneyTextEx: doc.IRPEFTaxTotal }}></span>
                                </label>
                            </div>
                        </div>
                    </ko-bind>
                    <ko-bind data-bind={{ if : doc.NonIRPEFTax() > 0 }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot">
                                    <ko-bind data-bind={{ text : doc.NonIRPEFTaxDescription }}></ko-bind> (<ko-bind data-bind={{ percentageText : doc.NonIRPEFTax }}></ko-bind>)
                                    <span data-bind={{ moneyTextEx: doc.NonIRPEFTaxTotal }}></span>
                                </label>
                            </div>
                        </div>
                    </ko-bind>
                </ko-bind>
                <div class="row single-field">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentTaxableTotal}</label>
                        <span data-bind={{ moneyTextEx: doc.TaxableTotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }}></span>
                    </div>
                </div>
                <div class="row single-field">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentTaxTotal}</label>
                        <span data-bind={{ moneyTextEx: doc.TaxTotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }}></span>
                    </div>
                </div>
                <div class="row single-field">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentNonTaxableTotal}</label>
                        <span data-bind={{ moneyTextEx: doc.NonTaxableTotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }}></span>
                    </div>
                </div>

                <div class="row single-field">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentTotal}&nbsp;<span class="text-uppercase" data-bind={{ text: doc.DocumentTypeLabel() }}></span>:</label>
                        <span data-bind={{ moneyTextEx: doc.TotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }} style="font-weight: bold"></span>
                    </div>
                </div>

                <ko-bind data-bind={{ if: doc.ShowTaxReliefTotal }}>
                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentTaxReliefTotalLabel}</label>
                            <span data-bind={{ moneyTextEx: doc.TaxReliefTotalInDocumentCurrency() * -1, currencySymbol: doc.DocumentCurrencySymbol }}></span>
                        </div>
                    </div>

                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentTotalWithTaxReliefTotalLabel}</label>
                            <span data-bind={{ moneyTextEx: doc.TotalWithTaxReliefDiscountInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }} style="font-weight: bold"></span>
                        </div>
                    </div>
                </ko-bind>

                <ko-bind data-bind={{ if : doc.SplitPayment }}>
                    <div class="row single-field">
                        <strong>{TextResources.Invoices.DocumentSplitPaymentInfo}</strong>
                    </div>
                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentNetTotal}</label>
                            <span data-bind={{ moneyTextEx: doc.NetTotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }} style="font-weight: bold"></span>
                        </div>
                    </div>
                </ko-bind>
                <ko-bind data-bind={{ if : doc.CanHaveWithholdingTax() && doc.WithWithholdingTax() }}>
                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentWithholdingTax} (<WithWithholdingTaxField></WithWithholdingTaxField>):</label>
                            <span data-bind={{ moneyTextEx: doc.WithholdingTaxTotal }} />
                        </div>
                    </div>

                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">
                                {TextResources.Invoices.DocumentReasonForPayment}
                            </label>
                            <select2 value={() => "ReasonForPayment"} dataSource={() => "ReasonForPaymentDataSource"} simple={true} allowClear={true} styles="width: 100px" readOnly={() => "doc.ReadOnly"}></select2>
                        </div>
                    </div>

                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentFinalTotal}</label>
                            <span data-bind={{ moneyTextEx: doc.FinalTotal }} style="font-weight: bold"></span>
                        </div>
                    </div>
                </ko-bind>

                <ko-bind data-bind={{ if : doc.CanHaveStampDuty() && doc.ShowStampDutyValueField() }}>
                    <ko-bind data-bind={{ ifnot: doc.ReadOnly() }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot" style="width: 100px;">{TextResources.Invoices.DocumentStampDutyOf}</label>
                                <input autoComplete="off" type="text" class="half-row form-control text-right" placeholder={TextResources.Invoices.DocumentStampDutyPlaceholder} data-bind={{ moneyValueEx: doc.StampDutyValue }} />
                                <label class="without-dot" style="width: 145px; text-align: left; margin-left: 5px;">&nbsp;{TextResources.Invoices.DocumentStampDutyVirtuallyPaid}</label>
                            </div>
                        </div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: doc.ReadOnly() }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot" style="width: 100px;">{TextResources.Invoices.DocumentStampDutyOf}</label>
                                <span data-bind={{ moneyTextEx: doc.StampDutyValue }} style="width: auto"></span>
                                <label class="without-dot" style="width: 145px; text-align: left; margin-left: 5px;">{TextResources.Invoices.DocumentStampDutyVirtuallyPaid}</label>
                            </div>
                        </div>
                    </ko-bind>
                </ko-bind>
            </div>;
}

function DocumentTotalsWithoutVAT() {
    let doc: Document;

    return  <div class="invoice-totals bootstrap" data-bind={{ if: doc.ShowAmounts }}>
                <div class="row single-field">
                    <div class="control-group">
                        <label class="without-dot">{TextResources.Invoices.DocumentTotal}&nbsp;<span class="text-uppercase" data-bind={{ text: doc.DocumentTypeLabel() }}></span>:</label>
                        <span data-bind={{ moneyTextEx: doc.TotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }} style="font-weight: bold"></span>
                    </div>
                </div>

                <ko-bind data-bind={{ if : doc.SplitPayment }}>
                    <div class="row single-field">
                        <strong>{TextResources.Invoices.DocumentSplitPaymentInfo}</strong>
                    </div>
                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentNetTotal}</label>
                            <span data-bind={{ moneyTextEx: doc.NetTotalInDocumentCurrency, currencySymbol: doc.DocumentCurrencySymbol }} style="font-weight: bold"></span>
                        </div>
                    </div>
                </ko-bind>
                <ko-bind data-bind={{ if : doc.CanHaveWithholdingTax() && doc.WithWithholdingTax() }}>
                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentWithholdingTax} (<WithWithholdingTaxField></WithWithholdingTaxField>):</label>
                            <span data-bind={{ moneyTextEx: doc.WithholdingTaxTotal }} />
                        </div>
                    </div>

                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">
                                {TextResources.Invoices.DocumentReasonForPayment}
                            </label>
                            <select2 value={() => "ReasonForPayment"} dataSource={() => "ReasonForPaymentDataSource"} simple={true} allowClear={true} styles="width: 100px" readOnly={() => "doc.ReadOnly"}></select2>
                        </div>
                    </div>

                    <div class="row single-field">
                        <div class="control-group">
                            <label class="without-dot">{TextResources.Invoices.DocumentFinalTotal}</label>
                            <span data-bind={{ moneyTextEx: doc.FinalTotal }} style="font-weight: bold"></span>
                        </div>
                    </div>
                </ko-bind>

                <ko-bind data-bind={{ if : doc.CanHaveStampDuty() && doc.ShowStampDutyValueField() }}>
                    <ko-bind data-bind={{ ifnot: doc.ReadOnly() }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot" style="width: 100px;">{TextResources.Invoices.DocumentStampDutyOf}</label>
                                <input autoComplete="off" type="text" class="half-row form-control text-right" placeholder={TextResources.Invoices.DocumentStampDutyPlaceholder} data-bind={{ moneyValueEx: doc.StampDutyValue }} />
                                <label class="without-dot" style="width: 145px; text-align: left; margin-left: 5px;">&nbsp;{TextResources.Invoices.DocumentStampDutyVirtuallyPaid}</label>
                            </div>
                        </div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: doc.ReadOnly() }}>
                        <div class="row single-field">
                            <div class="control-group">
                                <label class="without-dot" style="width: 100px;">{TextResources.Invoices.DocumentStampDutyOf}</label>
                                <span data-bind={{ moneyTextEx: doc.StampDutyValue }} style="width: auto"></span>
                                <label class="without-dot" style="width: 145px; text-align: left; margin-left: 5px;">{TextResources.Invoices.DocumentStampDutyVirtuallyPaid}</label>
                            </div>
                        </div>
                    </ko-bind>
                </ko-bind>
            </div>;
}