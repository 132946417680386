import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as EnjoyHint from "enjoyhint";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { AllocationsApplication } from "./Allocations/AllocationsApplication";
import { ManualAllocationDialog } from "./Allocations/ui/dialogs/ManualAllocationDialog";
import { ActivitiesDatesInfoDialog } from "./Allocations/ui/dialogs/ActivitiesDatesInfoDialog";
import { AdvancedFiltersManager } from "./Allocations/ui/AdvancedFiltersManager";
import { LazyImport } from "../Core/DependencyInjection";
import { CartWorkableHoursCalculationModes } from "../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IAllocationsService, ICartInfoDialogConfiguration, ITeam, ITeamAllocation, IResourceAllocationRange, IFullTeam, ICartAllocationTeam, ITeamAllocationsForGantt, ICartAllocation, IAllocatedCartForList, IAllocatedCart, IAllocationsJobOrderReport, IPlannedResource, ITeamWorkProgressInfo, ICartAllocationForTeamWithStartDate, IAllocationInfoForTask, ITeamTheoreticalAllocation, IRemainingHoursForJobOrder, IMissingRole, IWorkflowAllocationInfo, IFullInterval, IServiceOrderForInterval, ICartForAllocation, IManualAllocationsNumberPerDay, IActivitiesDatesInfo, IActivitiesDatesInfoDialog, IActivitiesDatesInfoWithMarker, IAdvancedFilterEditor, IAdvancedFilterProvider, ICartAndTeamAssociation, ICartWorkableHoursCalculationMode, IIncompleteAllocationInfo, IAllocationWithJobOrderActivities, IFullTeamManagers, TeamIdentifier } from "../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IManualAllocationDialog } from "./interfaces/IManualAllocationDialog";
import { IService } from "../Core/interfaces/IService";
import { IApplicationConfiguration, IApplicationsConfigurationsService } from "../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../ProlifeSdk/interfaces/desktop/IModulesService";
import { IOpUnitsResourcesAllocatedHours } from "./interfaces/IOpUnitsResourcesAllocatedHours";
import { IAllocationSetting } from "./interfaces/IAllocationSetting";
import { IManualAllocation, ITeamWorkableHoursAtDateFromWorkingHours } from "./interfaces/IManualAllocation";
import { IUserInfo } from "../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { AutoallocationDialog } from "./Allocations/ui/dialogs/AutoallocationDialog";
import { ActivityBacklogOption, ActivityBacklogOptions } from "./Allocations/ui/ActivityBacklogOption";

export class AllocationsService extends ProLifeService implements IAllocationsService {
    private lastId  = 0;

    private tutorial: EnjoyHint;
    private menuAdvancedFiltersManager: AdvancedFiltersManager;

    private defaultCartInfoDialogConfiguration: ICartInfoDialogConfiguration;
    private actualCartInfoDialogConfiguration: ICartInfoDialogConfiguration;
    private savedCartInfoDialogConfiguration: IApplicationConfiguration;

    private cartInfoDialogConfigurationLoaded = false;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService: IApplicationsConfigurationsService;
    @LazyImport(ProlifeSdk.ModulesServiceType)
    protected modulesService: IModulesService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IAllocationsService>(), this);
    }
    
    InitializeService() {
        super.InitializeService();

        this.modulesService.getModuleId(ProlifeSdk.AllocationsApplicationCode)
            .then((moduleId: number) => {
                if (!moduleId)
                    return;
                
                this.menuAdvancedFiltersManager = new AdvancedFiltersManager(ProlifeSdk.AllocationsApplicationCode, ProlifeSdk.AdvancedFiltersConfigAllocationType);
                this.menuAdvancedFiltersManager.initialize();
            })
            .catch((e) => {
                console.log(e);
            });

        this.defaultCartInfoDialogConfiguration = {
            CartsListCollapsed: false,
            GanttCollapsed: false,
            AllocationDataOpened: false,
            CartContentOpened: false,
            ManualAllocationsOpened: false
        };

        this.actualCartInfoDialogConfiguration = {
            CartsListCollapsed: this.defaultCartInfoDialogConfiguration.CartsListCollapsed,
            GanttCollapsed: this.defaultCartInfoDialogConfiguration.GanttCollapsed,
            AllocationDataOpened: this.defaultCartInfoDialogConfiguration.AllocationDataOpened,
            ManualAllocationsOpened: this.defaultCartInfoDialogConfiguration.ManualAllocationsOpened,
            CartContentOpened: this.defaultCartInfoDialogConfiguration.CartContentOpened
        };

        new AllocationsApplication(this.serviceLocator);
    }

    OnServerInitializationDataLoaded() {

    }

    getServiceType() : string {
        return ProlifeSdk.AllocationsServiceCode;
    }

    isOfType(serviceType : string) : boolean {
        return serviceType == this.getServiceType();
    }

    getTeams(query : string) : Promise<ITeam[]> {
        return this.ajaxService.Get("Allocations-api", "Teams?query=" + encodeURIComponent(query || ""), {
            background: true
        });
    }

    getTeamAllocations(teamId:number):Promise<ITeamAllocation[]> {
        if(!teamId)
            return Promise.resolve<ITeamAllocation[]>([]);

        return this.ajaxService.Get("Allocations-api", "Allocations?teamId=" + teamId, {
            background: true
        })
            ;
    }

    getTeamManagers(teamId : number) : Promise<IFullTeamManagers[]> {
        if(!teamId)
            return Promise.resolve<IFullTeamManagers[]>([]);

        return this.ajaxService.Post("Allocations-api", "Allocations/GetTeamManagers", {
            methodData: {
                teamId: teamId
            }
        })
            ;
    }

    getAllocationRanges(allocationId:number, operationalUnitId : number):Promise<IResourceAllocationRange[]> {
        if(!allocationId)
            return Promise.resolve<IResourceAllocationRange[]>([]);

        return this.ajaxService.Get("Allocations-api", "AllocationRanges?allocationId=" + allocationId + "&operationalUnitId=" + operationalUnitId, {
            background: true
        })
            ;
    }

    createOrUpdateTeam(team : IFullTeam) : Promise<ITeam> {
        return this.ajaxService.Post("Allocations-api", "Allocations", {
            methodData: team
        });
    }

    canDeleteTeam(teamId : number) : Promise<boolean> {
        return this.ajaxService.Post("Allocations-api", "Teams/CanDelete", {
            methodData: {
                teamId : teamId
            }
        });
    }

    deleteTeam(teamId : number) : Promise<void> {
        return this.ajaxService.Delete("Allocations-api", "Teams?teamId=" + teamId, {})
            ;
    }

    getAllocatedTeams(showAllTeams: boolean) : Promise<ICartAllocationTeam[]> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetAllocatedTeams", {
            background: true,
            methodData: {
                ShowAllTeams: showAllTeams
            }
        })
            ;
    }

    getCartAllocationForTeam(teamId : number) : Promise<ITeamAllocationsForGantt> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetCartAllocationForTeam", {
            background: true,
            methodData: {
                teamId: teamId
            }
        });
    }

    allocateCartOnTeam(teamId: number, cartId: number, allocationMode: number): Promise<void> {
        return this.ajaxService.Post("Allocations-api", "Allocations/AllocateCartOnTeam", {
            methodData: {
                TeamId: teamId,
                CartId: cartId,
                AllocationMode: allocationMode
            }
        });
    }

    deallocateCart(teamId: number, cartId: number): Promise<void> {
        return this.ajaxService.Post("Allocations-api", "Allocations/DeallocateCart", {
            methodData: {
                CartId: cartId,
                TeamId: teamId
            }
        });
    }

    getCartIdByAllocationId(allocationId : number) : Promise<number> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetCartIdByAllocationId", {
            background: true,
            methodData: {
                AllocationId: allocationId
            }
        });
    }

    getAllocation(allocationId : number) : Promise<ICartAllocation> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetAllocation", {
            background: true,
            methodData: {
                AllocationId: allocationId
            }
        });
    }

    GetAllocationsOnTeam(teamId: number): Promise<IAllocatedCartForList[]> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetAllocationsOnTeam", {
            background: true,
            methodData: {
                teamId: teamId
            }
        });
    }

    reorderCartsAllocation(teamId: number, movedId: number, neighbourId: number, before: boolean): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Allocations", "ReorderCartsAllocation", {
            methodData: {
                TeamId: teamId,
                MovedId: movedId,
                NeighbourId: neighbourId,
                Before: before
            }
        });
    }

    getAllocatedCartDetails(teamId: number, cartId: number): Promise<IAllocatedCart> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetAllocatedCartDetails", {
            background: true,
            methodData: {
                TeamId: teamId,
                CartId: cartId
            }
        });
    }

    getJobOrderReport(jobOrderId : number) : Promise<IAllocationsJobOrderReport> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetJobOrderReport", {
            background: true,
            methodData: {
                JobOrderId : jobOrderId
            }
        });
    }

    getPlannedResourcesForTask(taskId : number) : Promise<IPlannedResource[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetPlannedResourcesForTask", {
            background: true,
            methodData: {
                TaskId : taskId
            }
        });
    }
    getPlannedResourcesForWorkflow(workflowId : number) : Promise<IPlannedResource[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetPlannedResourcesForWorkflow", {
            background: true,
            methodData: {
                WorkflowId : workflowId
            }
        });
    }

    getTeamWorkProgress(teamId: number): Promise<ITeamWorkProgressInfo> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetTeamWorkProgress", {
            background: true,
            methodData: {
                teamId: teamId
            }
        });
    }

    getCartAllocationForTeamWithAllocationStartDate(teamId: number): Promise<ICartAllocationForTeamWithStartDate[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetCartAllocationForTeamWithStartDate", {
            background: true,
            methodData: {
                teamId: teamId
            }
        });
    }

    checkForUnestimatedTasks(elementId: number, isTask: boolean): Promise<boolean> {
        return this.ajaxService.Post("Allocations-api/Allocations", "CheckForUnestimatedTasks", {
            background: true,
            methodData: {
                ElementId: elementId,
                IsTask: isTask
            }
        });
    }

    checkForUnestimatedTasksForCart(cartId: number): Promise<boolean> {
        return this.ajaxService.Post("Allocations-api/Allocations", "CheckForUnestimatedTasksForCart", {
            background: true,
            methodData: {
                CartId: cartId
            }
        });
    }

    getAllocationInfoForTask(taskId: number, relatedWorkflow: number) : Promise<IAllocationInfoForTask[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetAllocationInfoForTask", {
            background: true,
            methodData: {
                TaskId: taskId,
                RelatedWorkflow: relatedWorkflow
            }
        });
    }

    getTeamTheoreticalAllocations(teamId: number): Promise<ITeamTheoreticalAllocation[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetTeamTheoreticalAllocations", {
            background: true,
            methodData: {
                teamId: teamId
            }
        });
    }

    getRemainingHoursForJobOrder(jobOrderId: number): Promise<IRemainingHoursForJobOrder> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetRemainingAllocatedHoursForJobOrder", {
            background: true,
            methodData: {
                JobOrderId: jobOrderId
            }
        });
    }

    getMissingRolesByAllocationId(allocationId: number, teamId: number): Promise<IMissingRole[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetMissingRolesByAllocationId", {
            background: true,
            methodData: {
                AllocationId: allocationId,
                TeamId: teamId
            }
        });
    }

    getMissingRolesByCartId(cartId: number, teamId: number): Promise<IMissingRole[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetMissingRolesByCartId", {
            background: true,
            methodData: {
                CartId: cartId,
                TeamId: teamId
            }
        });
    }

    getMissingRolesByElement(elementId: number, isTask: boolean, teamId: number): Promise<IMissingRole[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetMissingRolesByElement", {
            background: true,
            methodData: {
                ElementId: elementId,
                IsTask: isTask,
                TeamId: teamId
            }
        });
    }

    getWorkflowAllocationInfo(workflowId: number): Promise<IWorkflowAllocationInfo[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetWorkflowAllocationInfo", {
            background: true,
            methodData: {
                WorkflowId: workflowId
            }
        });
    }

    getResourceAllocationRangesForTeams(resourceId: number): Promise<IResourceAllocationRange[]> {
        return this.ajaxService.Post("Allocations-api/AllocationRanges", "GetResourceAllocationRangesForTeams", {
            background: true,
            methodData: {
                ResourceId: resourceId
            }
        });
    }

    getAllocationsIntervalsForResource(resourceId: number, opUnitId: number, teamId: number, startDate: Date, endDate: Date, editingMode: boolean): Promise<IFullInterval[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetAllocationsIntervalsForResource", {
            background: true,
            methodData: {
                ResourceId: resourceId,
                OpUnitId: opUnitId,
                TeamId: teamId,
                StartDate: startDate,
                EndDate: endDate,
                EditingMode: editingMode
            }
        });
    }

    getServiceOrderForInterval(resourceId: number, opUnitId: number, startDate: Date, endDate: Date): Promise<IServiceOrderForInterval> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetServiceOrderForInterval", {
            background: true,
            methodData: {
                ResourceId: resourceId,
                OpUnitId: opUnitId,
                StartDate: startDate,
                EndDate: endDate
            }
        });
    }

    getTeamAllocatedHoursForOpUnits(teamId: number): Promise<IOpUnitsResourcesAllocatedHours[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetTeamAllocatedHoursForOpUnits", {
            background: true,
            methodData: {
                TeamId: teamId
            }
        });
    }

    getResourceTotalAllocatedHoursOnTeam(teamId: number, resourceId: number): Promise<number> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetResourceTotalAllocatedHoursOnTeam", {
            background: true,
            methodData: {
                TeamId: teamId,
                ResourceId: resourceId
            }
        });
    }

    getResourceAllocatedHoursForOpUnit(teamId: number, resourceId: number, opUnitId: number): Promise<number> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetResourceAllocatedHoursForOpUnit", {
            background: true,
            methodData: {
                TeamId: teamId,
                ResourceId: resourceId,
                OpUnitId: opUnitId
            }
        });
    }

    getSetting(label: string): Promise<IAllocationSetting> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetSetting", {
            background: true,
            methodData: {
                Label: label
            }
        });
    }

    GenerateNextId() : number {
        return --this.lastId;
    }

    isTaskDirectlyAllocated(taskId : number) : Promise<boolean> {
        return this.ajaxService.Post("Allocations-api/Allocations", "IsTaskDirectlyAllocated", {
            background: true,
            methodData: {
                ElementId: taskId,
                IsTask: true
            }
        });
    }

    isWorkflowDirectlyAllocated(workflowId : number) : Promise<boolean> {
        return this.ajaxService.Post("Allocations-api/Allocations", "IsWorkflowDirectlyAllocated", {
            background: true,
            methodData: {
                ElementId: workflowId,
                IsTask: false
            }
        });
    }

    deleteElement(elementId : number, isTask : boolean) : Promise<void> {
        return this.ajaxService.Post("Allocations-api/Allocations", "DeleteElement", {
            methodData: {
                ElementId: elementId,
                IsTask: isTask
            }
        });
    }

    getAvailableCartsForAllocationOnTeam(teamId: number): Promise<ICartForAllocation[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetAvailableCartsForAllocationOnTeam", {
            methodData: {
                TeamId: teamId
            }
        });
    }

    getManualAllocationDialog(teamId: number, date: Date, cartId: number = null): IManualAllocationDialog {
        return new ManualAllocationDialog(teamId, date, cartId);
    }

    getManualAllocations(teamId: number, date: Date, cartIdFilter: number = null): Promise<IManualAllocation[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetManualAllocations", {
            methodData: {
                TeamId: teamId,
                Date: date,
                CartIdFilter: cartIdFilter
            }
        });
    }

    createOrUpdateManualAllocations(allocations: IManualAllocation[], teamId: number, date: Date): Promise<void> {
        return this.ajaxService.Post("Allocations-api/Allocations", "CreateOrUpdateManualAllocations", {
            methodData: {
                Allocations: allocations,
                TeamId: teamId,
                Date: date
            }
        });
    }

    getTeamWorkableHoursAtDateFromWorkingHours(teamId: number, date: Date): Promise<ITeamWorkableHoursAtDateFromWorkingHours> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetTeamWorkableHoursAtDateFromWorkingHours", {
            methodData: {
                TeamId: teamId,
                Date: date
            }
        });
    }

    getManualAllocationsNumberPerDays(teamId?: number, cartId?: number): Promise<IManualAllocationsNumberPerDay[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "GetManualAllocationsNumberPerDays", {
            methodData: {
                TeamId: teamId,
                CartId: cartId
            }
        });
    }

    getActivitiesDatesInfoDialog(activitiesDatesInfo: IActivitiesDatesInfo[]): IActivitiesDatesInfoDialog {
        return new ActivitiesDatesInfoDialog(activitiesDatesInfo as IActivitiesDatesInfoWithMarker[]);
    }

    startTutorial(): void {
        this.tutorial = new EnjoyHint({
            onSkip: () => {
                localStorage.setItem('AllocationsGanttTutorial1', 'true');
                this.tutorial = null;
            },
            onEnd: () => {
                localStorage.setItem('AllocationsGanttTutorial1', 'true');
                this.tutorial = null;
            }
        });

        const steps = [
            {
                selector: 'body > div.page-header.navbar.navbar-fixed-top > div > div.hor-menu.hor-menu-light.hidden-sm.hidden-xs > ul:nth-child(2) > li',
                description: 'Benvenuto sulla pagina di Allocazioni.<br/>Questo tour guidato ti insegnerà ad utilizzare le nuove funzionalità introdotte nell\'ultimo aggiornamento di ProLife',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.page-container > div > div > div.allocations-gantt > allocations-gantt > div > div > div > div.bottom-area > aside',
                description: 'Adesso è possibile allocare lo stesso carrello su più team.<br/>Per farlo, espandere le righe dei team per vedere i carrelli e trascinare il carrello da un team all\'altro.<br/><br/>Inoltre, è possibile ordinare i carrelli di un team semplicemente trascinando e rilasciando il carrello all\'interno dello stesso team.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.page-container > div.page-content-wrapper > div > a',
                description: 'Cliccando su questo pulsante è possibile abilitare la nuova modalità di "allocazione manuale".<br/>Queste allocazioni avranno durata di un giorno.<br/>La creazione di un\'allocazione manuale può essere fatta trascinando un carrello su una specifica casella della riga del team<br/> (viene presa la casella sulla quale si trova il puntatore del mouse al momento del drop del carrello)<br/> oppure semplicemente cliccando sulla casella.<br/>Verrà aperto un pop-up nel quale potrete gestire le allocazioni manuali di quel giorno.<br/><br/>Clicca per abilitare la modalità manuale e proseguire il tour guidato.',
                showNext: false,
                showSkip: true,
                skipButton: { text: "Salta" },
                event: "manual-allocation-enabled"
            },
            {
                selector: 'body > div.page-container > div > div > div.allocations-gantt > allocations-gantt > div > div > div',
                description: 'Trascina un carrello o clicca su una casella per aprire il pop-up delle allocazioni manuali.',
                showNext: false,
                showSkip: true,
                skipButton: { text: "Salta" },
                event: "manual-allocation-enabled"
            },
            {
                selector: 'body > div.modal.large.in > div > div > div.modal-body > div.manual-allocations-editor > div.manual-allocations-wrapper.ui-droppable',
                description: 'In quest\'area sono mostrate le allocazioni manuali per il giorno selezionato.<br/>È possibile aggiungere una nuova allocazione tramite il pulsante aggiungi in alto a sinistra oppure trascinando un carrello dal menù di destra.<br/>Al termine delle operazioni, cliccare su salva per applicare le modifiche.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
                event: "manual-allocation-editor-opened"
            },
            {
                selector: 'body > div.modal.large.in > div > div > div.modal-footer > a:nth-child(1)',
                description: 'Chiudi il pop-up.',
                showNext: false,
                showSkip: true,
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.page-container > div.page-content-wrapper > div > div.gantt-wrapper > div > aside.fixedTable-sidebar',
                description: 'Le allocazioni manuali e le allocazioni standard di ogni carrello<br/>verranno rappresentate su righe diverse e saranno contraddistinte da un\'icona.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
                event: "manual-allocation-editor-closed"
            },
            {
                selector: 'body > div.page-container > div.page-content-wrapper > div > div.gantt-wrapper > div > aside.fixedTable-sidebar > div > div:nth-child(1) > span.team > span.gantt-row-actions > button:nth-child(1) > i',
                description: 'Clicca per aprire il dettaglio del team.',
                showNext: false,
                showSkip: true,
                skipButton: { text: "Salta" },
                onBeforeStart: () => {
                    let firstTeam = $('body > div.page-container > div.page-content-wrapper > div > div.gantt-wrapper > div > aside.fixedTable-sidebar > div > div:nth-child(1) > span.team > span.text-ellipsis > span.bold.text-ellipsis');
                    if (firstTeam.length === 0)
                        this.triggerNextTutorialStep("end-tutorial");
                }
            },
            {
                selector: 'body > div.modal.fullscreen.minheight800.in > div > div > div.modal-body.cart-info-dialog > div:nth-child(1) > div:nth-child(1) > div:nth-child(3) > div.btn-group.pull-right > button',
                description: 'Questo pulsante mette a disposizione le tre modalità di allocazione differenti: è possibile creare un nuovo carrello, scegliere tra i carrelli esistenti (anche tra quelle già allocati su altri team; in quel caso sarà possibile vedere su quali team il carrello è già allocato) oppure creare allocazioni manuali. Ognuna di queste funzioni apre il proprio pop-up.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
                event: "team-details-opened"
            },
            {
                selector: 'body > div.modal.fullscreen.minheight800.in > div > div > div.modal-body.cart-info-dialog > div:nth-child(1) > div.dd',
                description: 'Sui carrelli viene evidenziato il numero di allocazioni, distinguendo tra allocazione standard (che al massimo può valere 1) e allocazioni manuali.<br/>Clicca su dettagli per popolare la parte centrale del pop-up.',
                showNext: false,
                showSkip: true,
                skipButton: { text: "Salta" }
            },
            {
                selector: 'body > div.modal.fullscreen.minheight800.in > div > div > div.modal-body.cart-info-dialog > div:nth-child(2)',
                description: 'In quest\'area è possibile consultare e modificare le informazioni relative alle allocazioni di un carrello. Allocaziona standard e allocazioni manuali sono separate in due tab differenti.',
                showNext: true,
                showSkip: true,
                nextButton: { text: "Avanti" },
                skipButton: { text: "Salta" },
                event: "cart-detail-click"
            },
            {
                selector: 'body > div.page-header.navbar.navbar-fixed-top > div > div.hor-menu.hor-menu-light.hidden-sm.hidden-xs > ul:nth-child(2) > li',
                description: 'Il tutorial è terminato.',
                showNext: true,
                showSkip: false,
                nextButton: { text: "Chiudi" },
                event: 'end-tutorial'
            }
        ];

        this.tutorial.set(steps);
        this.tutorial.run();
    }

    triggerNextTutorialStep(event: string): void {
        if (!this.tutorial)
            return;

        this.tutorial.trigger(event);
    }

    getMenuAdvancedFiltersEditor(): IAdvancedFilterEditor {
        return this.menuAdvancedFiltersManager;
    }

    getMenuAdvancedFiltersProvider(): IAdvancedFilterProvider {
        return this.menuAdvancedFiltersManager;
    }

    getCartTeamsNames(cartId: number): Promise<TeamIdentifier[]> {
        return this.ajaxService.Post("Allocations-api", "Allocations/GetCartTeamsNames", {
            methodData: {
                CartId: cartId
            }
        });
    }

    async getCartInfoDialogConfiguration(): Promise<ICartInfoDialogConfiguration> {
        let configuration: IApplicationConfiguration;
        
        if (!this.cartInfoDialogConfigurationLoaded) {
            const moduleId: number = await this.modulesService.getModuleId(ProlifeSdk.AllocationsApplicationCode);

            if (!moduleId)
                return this.defaultCartInfoDialogConfiguration;

            const configurations = await this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.CartInfoDialogConfigType, this.userInfo.getIdUser(), moduleId, null);
            configuration = configurations && configurations.length > 0 ? configurations[0] : null;

            this.cartInfoDialogConfigurationLoaded = true;

            if (!configuration || !configuration.Configuration)
                return this.actualCartInfoDialogConfiguration;

            this.savedCartInfoDialogConfiguration = configuration;
            const savedConfig: ICartInfoDialogConfiguration = JSON.parse(configuration.Configuration);

            this.actualCartInfoDialogConfiguration.CartsListCollapsed = savedConfig.CartsListCollapsed;
            this.actualCartInfoDialogConfiguration.GanttCollapsed = savedConfig.GanttCollapsed;
            this.actualCartInfoDialogConfiguration.AllocationDataOpened = savedConfig.AllocationDataOpened;
            this.actualCartInfoDialogConfiguration.ManualAllocationsOpened = savedConfig.ManualAllocationsOpened;
            this.actualCartInfoDialogConfiguration.CartContentOpened = savedConfig.CartContentOpened;
        }
        
        return this.actualCartInfoDialogConfiguration;
    }

    async saveCartInfoDialogConfiguration(config: ICartInfoDialogConfiguration): Promise<void> {
        const configToBeSaved: IApplicationConfiguration = $.extend(true, {}, this.savedCartInfoDialogConfiguration) as IApplicationConfiguration;
        configToBeSaved.Configuration = JSON.stringify(config);
        configToBeSaved.ConfigurationType = ProlifeSdk.CartInfoDialogConfigType;
        configToBeSaved.UserID = this.userInfo.getIdUser();
        
        if (!configToBeSaved.ModuleId) {
            const moduleId = await this.modulesService.getModuleId(ProlifeSdk.AllocationsApplicationCode);
            if (!moduleId)
                return;

            configToBeSaved.ModuleId = moduleId;
        }


        await this.applicationsConfigurationsService.AddOrUpdateConfiguration(configToBeSaved);

        this.actualCartInfoDialogConfiguration = config;
    }

    AllocateCartsAtPositionOnTeam(destTeamId: number | null, carts: ICartAndTeamAssociation[] | null, neighbourId: number | null, before: boolean | null, companyGuid: string, ignoreMissingOpUnits = false): Promise<void> {
        return this.ajaxService.Post<void>("Allocations-api/Allocations", "AllocateCartsAtPositionOnTeam", {
            background: true,
            methodData: {
        		destTeamId: destTeamId,
        		carts: carts,
        		neighbourId: neighbourId,
                before: before,
                companyGuid: companyGuid,
                ignoreMissingOpUnits: ignoreMissingOpUnits
        	}
        });
    }

    getCartWorkableHoursCalculationModesList(): ICartWorkableHoursCalculationMode[] {
        return [
            { Mode: CartWorkableHoursCalculationModes.JobOrderSpeed, Description: TextResources.Allocations.ReestimatedRemainingWorkByJobOrderSpeed },
            { Mode: CartWorkableHoursCalculationModes.WorkflowSpeed, Description: TextResources.Allocations.ReestimatedRemainingWorkByWorkflowSpeed },
            { Mode: CartWorkableHoursCalculationModes.TaskEstimatedWork, Description: TextResources.Allocations.TaskEstimatedWork }
        ];
    }

    getActivitiesBacklogOptions(): ActivityBacklogOption[] {
        return [
            { id: ActivityBacklogOptions.UseSystemDefault, label: TextResources.Allocations.ActivityBacklogSystemDefault },
            { id: ActivityBacklogOptions.IgnoreBacklog, label: TextResources.Allocations.ActivityIgnoreBacklog },
            { id: ActivityBacklogOptions.UseBacklog, label: TextResources.Allocations.ActivityUseBacklog }
        ];
    }

    SearchAllocationsByJobOrderId(jobOrderId: number | null): Promise<IAllocationWithJobOrderActivities[]> {
        const result = this.ajaxService.Post<IAllocationWithJobOrderActivities[]>("Allocations-api/Allocations", "SearchAllocationsByJobOrderId", {
            background: true,
            methodData: {
        		jobOrderId: jobOrderId,
        	}
        });



        return result;
    }

    GetIncompleteAllocationInfo(allocationsIds: number[] | null, allocationType: number | null): Promise<IIncompleteAllocationInfo[]> {
        const result = this.ajaxService.Post<IIncompleteAllocationInfo[]>("Allocations-api/Allocations", "GetIncompleteAllocationInfo", {
            background: true,
            methodData: {
        		allocationsIds: allocationsIds,
        		allocationType: allocationType,
        	}
        });



        return result;
    }

    async startAutoallocationProcess(): Promise<void> { 
        const queuedWorkflows = await this.runAutoAllocation();
        const modal = new AutoallocationDialog({ queuedWorkflows: queuedWorkflows });
        await this.dialogsService.ShowModal(modal);
    }

    async runAutoAllocation(): Promise<number[]> {
        return this.ajaxService.Post("Allocations-api/Allocations", "runAutoAllocation", {
            background: false
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new AllocationsService(serviceLocator);
}