import * as ko from "knockout";
import { DocumentsGroup } from "./DocumentsGroup";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class VatRegisterDocuments extends DocumentsGroup
{
    register;

    constructor(register : IVatRegister)
    {
        super();
        this.register = register;
    }
}
