import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IMailsService, TemporaryMailStorageChanges } from "../../../MailsService";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";

const styleSheet = jss.createStyleSheet({
    mailsToManageIndicator: {
        margin: "0px",
        padding: "17px 10px 8px 10px",
        minWidth: "48px",

        "& > i": {
            color: "#999999",
            fontSize: "17px"
        },

        "& .badge": {
            right: "12px !important"
        }
    }
});
const { classes } = styleSheet.attach();

type MailsToManageIndicatorProps = {
}

export class MailsToManageIndicatorUI {
    constructor(private props: MailsToManageIndicatorProps) {}

    render() {
        return <MailsToManageIndicator {...this.props}  />
    }
}

export function MailsToManageIndicator(props: MailsToManageIndicatorProps) {
    const C = require("./MailsToManageIndicator")._MailsToManageIndicator as typeof _MailsToManageIndicator;
    return <C {...props} />;
}

export class _MailsToManageIndicator {
    static defaultProps: Partial<MailsToManageIndicatorProps> = {
    }

    private MailsToManageNumber: ko.Observable<number> = ko.observable(0);
    private Tooltip: ko.Computed<string>;

    private eventHandler: number;

    @LazyImport(nameof<IMailsService>())
    private mailsService : IMailsService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private notificationsService : IChangesNotificationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    constructor(private props : MailsToManageIndicatorProps) {
        this.Tooltip = ko.computed(() => {
            return String.format(TextResources.Blog.MailsToManageTooltip, this.MailsToManageNumber());
        });
    }

    componentDidMount() {
        this.loadMailsToManageCount();
        this.eventHandler = this.notificationsService.RegisterEventHandler("OnTemporaryMailStorageChanges", this.onTemporaryMailStorageChanges.bind(this));
    }

    private async onTemporaryMailStorageChanges(evt: TemporaryMailStorageChanges): Promise<void> {
        const loggedUserId = this.userInfo.getIdUser();
        const companyGuid = this.userInfo.getCurrentCompanyGuid();

        if (evt.MailUserId !== loggedUserId || companyGuid !== evt.CompanyGuid)
            return;

        await this.loadMailsToManageCount();
    }

    private async loadMailsToManageCount(): Promise<number> {
        try {
            const mailsCount = await this.mailsService.GetMailsToManageCountForUser(null);
            this.MailsToManageNumber(mailsCount ?? 0);

            return mailsCount;
        } catch(e) {
            console.log(e);
        }

        return 0;
    }

    componentWillUnmount() {
        this.notificationsService.UnregisterEventHandler("OnTemporaryMailStorageChanges", this.eventHandler)
    }
    
    private async showMailEventsGenerator(): Promise<void> {
        return this.mailsService.openMailEventsGenerator();
    }

    render() {
        const mailsToManageIndicator = this;
        const classNames = ComponentUtils.classNames(classes.mailsToManageIndicator, "dropdown-toggle");

        return ComponentUtils.bindTo((
            <a href="javascript:void(0)" className={classNames} data-bind={{ click: mailsToManageIndicator.showMailEventsGenerator.bind(mailsToManageIndicator), attr: { title: mailsToManageIndicator.Tooltip } }}>
                <i className="fa fa-envelope"></i>
                <span className="badge badge-default" data-bind={{ text: mailsToManageIndicator.MailsToManageNumber, visible: mailsToManageIndicator.MailsToManageNumber() > 0 }}></span>
            </a>
        ), this, "mailsToManageIndicator");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MailsToManageIndicator);
}