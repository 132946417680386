import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { Document, IDocumentConfiguration } from "../Document";

export class EstimateConfiguration implements IDocumentConfiguration {
    DocumentType: string = ProlifeSdk.EstimateEntityTypeCode;

    configure(document: Document) {
        document.SalesCicle(true);

        document.DDTCausesDataSource.setShowOnlySalesCauses(true);

        document.CanHaveDestinationRecipient(false);
        document.CanBeAnElectronicInvoice(false);
        document.CanHaveStampDuty(false);
        document.CanBeDownPayment(false);
        document.CanHaveSecondaryRecipients(false);
        document.CanBeForPublicAdministration(false);
        document.CanHaveWithholdingTax(false);
        document.CanHideAmounts(false);
        document.CanSelectCustomers(true);
        document.CanSelectSuppliers(false);
        document.CanHaveRelatedDocuments(false);
        document.CanHaveSourceWarehouse(false);
        document.CanHaveDestinationWarehouse(false);
        document.CanHaveShippingInformation(false);
        document.CanHaveCause(false);
        document.CanShowTotals(true);
        document.CanHaveIRPEFTax(false);
        document.CanHaveTaxReport(false);
        document.CanReferenceArticles(true);
        document.CanHaveVAT(false); //TODO: Perchè?
        document.CanCloseRows(true);
        document.CanHaveLetterOfAttempts(false);
        document.CanViewOldLetterOfAttempts(false);
        document.CanChangeDiscountCatalog(true);
        document.CanShowProtocolNumber(true);
        document.CanHaveVersion(true);
        document.CanEditRevisionNumber(true);
        document.CanHaveSchedules(false);
        document.CanExportElectronicInvoices(false);
        document.CanHaveState(true);
        document.CanHaveValidity(true);
        document.CanSelectClosedLettersOfAttempt(false);
        document.CanHaveCIGCUP(false);
        document.MustCheckTrust(false);
        document.IsCustomerDocument(true);
        document.ShowVersionNumbersSuggestions(true);
        document.CanHaveMetadatas(document.MetadatasEnabled);
        document.RecipientPosition("right");

        document.ExcelExporterId("Invoices/EstimateExport");
        document.ExcelExporterMethod("GenerateExcel");
        document.ExcelExporterController("Invoices/DocumentExport");
        document.RefExcelExporterId("Invoices/DocumentWorkAndPurchasesExport");
        document.RefExcelExporterMethod("GenerateExcel");

        document.DocumentTypeLabel(document.RegisterCache.NuovoLabel);
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    print(documentId: number) {
        this.dialogsService.OpenUrlAsDialog("/Invoices/Print/Estimate/" + documentId, "Stampa Preventivo");
        /*var popup = window.open("about:blank", "_blank");
        popup.location.href = "/Service/Invoices/Print/Index/" + documentId;*/
    }
}
