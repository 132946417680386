import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase } from "../../../../../Core/utils/DialogComponentBase";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { IFullWarehouseInspection } from "../../../../WarehouseInspectionsService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { WarehouseInspectionEditor, _WarehouseInspectionEditor } from "../WarehouseInspectionEditor";

export class WarehouseInspectionEditorDialog extends DialogComponentBase {
    private editor: _WarehouseInspectionEditor;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private fullInspection: IFullWarehouseInspection) {
        super({ className: "fullscreen", actionLabel: TextResources.ProlifeSdk.Save, closeLabel: TextResources.ProlifeSdk.Close });

        this.title(TextResources.Warehouse.NewInspectionDefaultTitle);
    }

    public async show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    public async action(): Promise<void> {
        try {
            await this.editor.save();
            await this.close();
        } catch(e) {

        }
    }

    public async close(): Promise<void> {
        let canClose = await this.editor.abort();
        
        if (!canClose)
            return;
        
        super.close();
    }

    renderBody() {
        return <WarehouseInspectionEditor
            fullInspection={this.fullInspection}
            ref={(e) => this.editor = e}
            onDeleteSuccess={this.close.bind(this)}
            hideSaveAndCloseButtons={true}
            focusOnTitle={true} />;
    }
}