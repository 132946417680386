import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/09/2017
 * Time: 09:08
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CartForList } from "./CartForList";
import { CartsFactory } from "../utilities/CartsFactory";
import { Delay } from "../../../Decorators/Delay";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { AllocationMode } from "../enums/AllocationMode";
import { IAdvancedFilterProviderObserver, IAllocationsService, IDraggedAllocation, ICartAllocation } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ICartsService } from "../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IDraggedTask, IDraggedWorkflow } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICartsFactory } from "../../interfaces/ICartsFactory";
import { ICartsListObserver } from "../../../ProlifeSdk/interfaces/allocations/ICartsListObserver";
import { ICartWithContent, ICart } from "../../../ProlifeSdk/interfaces/allocations/ICart";
import { IJobOrderGeneralSettingsManager } from "../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";

export class CartsList implements IAdvancedFilterProviderObserver {
    public templateName = "carts-list";
    public templateUrl = "allocations/templates";

    public CartsList: ko.ObservableArray<CartForList> = ko.observableArray([]);
    public ShowClosedCarts: ko.Observable<boolean> = ko.observable(false);
    public ShowAllocatedCarts : ko.Observable<boolean> = ko.observable(false);
    public UseMenuAdvancedFilters: ko.Observable<boolean> = ko.observable(false);
    public ShowCartEditingFormOnActivityAllocation: ko.Observable<boolean> = ko.observable(false);
    public EnableShowCartEditorFlag: ko.Observable<boolean> = ko.observable(false);
    public AllocationMode: ko.Observable<number> = ko.observable();
    public TextFilter: ko.Observable<string> = ko.observable("");
    public DropScope: ko.Observable<string> = ko.observable("default");
    public DragScope: ko.Observable<string> = ko.observable("default");

    public EmptyNotAllocatedCartsList: ko.Computed<boolean>;
    public EmptyAllocatedCartsList: ko.Computed<boolean>;
    public NotAllocatedCarts: ko.Computed<CartForList[]>;
    public AllocatedCarts: ko.Computed<CartForList[]>;

    public AllowedMimeTypes: string[] = [
        'application/prolife-task',
        'application/prolife-workflow',
        'application/prolife-team-cart-allocation'
    ];

    private CartsFactory: ICartsFactory;

    @LazyImport(nameof<ICartsService>())
    private cartsService: ICartsService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;
    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettings: IJobOrderGeneralSettingsManager;

    private observers: ICartsListObserver[] = [];

    constructor(showCartEditorFlag = false) {
        this.EnableShowCartEditorFlag(showCartEditorFlag);
        this.CartsFactory = new CartsFactory();

        const showCartEditor = this.generalSettings.getShowCartEditingFormOnActivityAllocation();
        this.ShowCartEditingFormOnActivityAllocation(showCartEditor);

        this.ShowClosedCarts.subscribe(() => {
            this.LoadCarts();
        });

        this.UseMenuAdvancedFilters.subscribe(() => {
            this.LoadCarts();
        });

        this.ShowAllocatedCarts.subscribe(() => {
            this.LoadCarts();
        });

        this.AllocationMode.subscribe((mode: number) => {
            this.ShowAllocatedCarts(mode === AllocationMode.ManualAllocation);
        });

        this.TextFilter.subscribe(() => {
            this.Search();
        });

        this.DropScope.subscribe((newScope: string) => {
            this.CartsList()
                .forEach((c) => c.DropScope(newScope));
        });

        this.DragScope.subscribe((newScope: string) => {
            this.CartsList()
                .forEach((c) => c.DragScope(newScope));
        });

        this.AllocatedCarts = ko.computed(() => {
            return this.CartsList().filter(c => !c.Deallocated());
        });
        
        this.NotAllocatedCarts = ko.computed(() => {
            return this.CartsList().filter(c => c.Deallocated());
        });

        this.EmptyAllocatedCartsList = ko.computed(() => {
            return this.AllocatedCarts().length == 0;
        });

        this.EmptyNotAllocatedCartsList = ko.computed(() => {
            return this.NotAllocatedCarts().length == 0;
        });

        const advFilterProvider = this.allocationsService.getMenuAdvancedFiltersProvider();
        advFilterProvider.registerObserver(this);

        this.LoadCarts();
    }

    public onFilterChanges(): void {
        if (this.UseMenuAdvancedFilters())
            this.LoadCarts();
    }

    public LoadCarts(): void {
        const showAllocatedCarts: boolean = this.ShowAllocatedCarts();

        const menuAdvancedFiltersProvider = this.allocationsService.getMenuAdvancedFiltersProvider();
        const advancedFilters = menuAdvancedFiltersProvider.getCurrentFilters();

        this.cartsService
            .getCartsList(this.ShowClosedCarts(), showAllocatedCarts, this.UseMenuAdvancedFilters(), advancedFilters, this.TextFilter())
            .then((carts: ICartWithContent[]) => {
                this.CartsList(carts.map((c: ICartWithContent) => { return this.CreateViewModel(c) }));
            });
    }

    public RegisterObserver(o: ICartsListObserver): void {
        this.observers.push(o);
    }

    public NotifyObservers(): void {
        this.observers.forEach((o: ICartsListObserver) => {
            o.OnListChanges();
        });
    }

    public async AddCart(): Promise<void> {
        try {
            const cart = await this.cartsService.openCartEditorDialog();

            if (!cart)
                return;

            const cartWithContent: ICartWithContent = {
                Cart: cart,
                Content: [],
                RolesInfo: [],
                Deallocated: true
            };

            this.CartsList.push(this.CreateViewModel(cartWithContent))
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.AddCartError);
        }
    }

    public OnCartDeleted(cart: CartForList): void {
        this.RemoveCart(cart);
    }

    public Refresh(): void {
        this.NotifyObservers();
    }

    public async OnElementDropped(dataTransfer: DataTransfer): Promise<void> {
        if (dataTransfer.types.indexOf("application/prolife-team-cart-allocation") >= 0) {
            const draggedAllocation: IDraggedAllocation = JSON.parse(dataTransfer.getData("application/prolife-team-cart-allocation"));

            if (this.userInfo.getCurrentCompanyGuid() !== draggedAllocation.CompanyGuid) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
                return;
            }
            
            const allocation: ICartAllocation = await this.allocationsService.getAllocation(draggedAllocation.AllocationId)
            if (!allocation)
                return;
            
            const cartId = allocation.CartId;
            const teamId = allocation.TeamId;
            const cart: CartForList = this.GetCart(cartId);

            if (!cart) {
                try {
                    await this.allocationsService.deallocateCart(teamId, cartId);
                } catch(e) {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.DeallocateCartError);
                    return;
                }
                
                try {
                    const cartWithContent: ICartWithContent = await this.cartsService.getCartWithContent(cartId)
                    this.CartsList.push(this.CreateViewModel(cartWithContent));
                } catch(e) {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetCartDataError);
                }
            }

            return;
        }

        const task: IDraggedTask = dataTransfer.types.indexOf("application/prolife-task") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-task")) : null;
        const workflow: IDraggedWorkflow = dataTransfer.types.indexOf("application/prolife-workflow") >= 0 ? JSON.parse(dataTransfer.getData("application/prolife-workflow")) : null;
        
        if (this.userInfo.getCurrentCompanyGuid() !== (task ? task.CompanyGuid : workflow.CompanyGuid)) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.InvalidDropForCompany);
            return;
        }

        const elementId: number = task ? task.TaskId : workflow.WorkflowId;
        const isTask: boolean = task ? task.IsTask : workflow.IsTask;

        let cart: ICart = null;

        try {
            cart = await this.CartsFactory.createCartForElementAllocation(elementId, isTask, this.ShowCartEditingFormOnActivityAllocation());
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.CartCreationError);
            return;
        }

        if (!cart)
            return;
                    
        try {
            await this.cartsService.manageCartWorkEndDate(cart.Id, elementId, isTask);
            const cartWithContent: ICartWithContent = await this.cartsService.getCartWithContent(cart.Id);
            if (cartWithContent.Deallocated === null || cartWithContent.Deallocated === undefined)
                cartWithContent.Deallocated = true;
                
            this.CartsList.push(this.CreateViewModel(cartWithContent));
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Allocations.GetCartDataError);
            this.CartsList.push(this.CreateViewModel({ Cart: cart, Content: [], RolesInfo: [] }));
        }

        this.Refresh();
    }

    public GetCart(cartId : number) : CartForList {
        const foundCarts = this.CartsList().filter((c : CartForList) => c.Id == cartId);
        if(foundCarts.length > 0)
            return foundCarts[0];
        return null;
    }

    public RemoveCart(cart : CartForList) {
        this.CartsList.remove(cart);
        this.Refresh();
    }

    public RefreshCarts() {
        this.LoadCarts();
    }

    public Dispose(): void {
        const provider = this.allocationsService.getMenuAdvancedFiltersProvider();
        provider.unregisterObserver(this);
    }

    private CreateViewModel(cart: ICartWithContent): CartForList {
        return new CartForList(cart, this);
    }

    @Delay(350)
    private Search(): void {
        this.LoadCarts();
    }
}