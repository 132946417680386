import * as ko from "knockout";
import { MergedReportComponentConstructor, ReportComponent, ReportComponentConstructor } from "./ReportComponent";
import { ReportComponentDataSourceProps } from "./ReportComponentWithDataSource";
import { ReportDataBoundValue } from "./ReportDataBoundValue";

export type ReportComponentWithVisibility = {
    visible: ko.Observable<boolean>;
    boundVisible: ReportDataBoundValue<boolean>;
    doNotSplit: ko.Observable<boolean>;
} & ReportComponent;

export type ReportComponentWithVisibilityProps = {
    visible?: boolean;
    boundVisible?:{ 
        dataSource: ReportComponentDataSourceProps, 
        boundExpression: string, 
        compiledBoundExpression: string, 
        bound: boolean 
    };
    doNotSplit?: boolean;
}

export function HasVisibility<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithVisibility, ReportComponentWithVisibilityProps> {
    return class ReportComponentWithVisibility extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "Visibility"];

            const { visible, boundVisible, doNotSplit } = args[0] as ReportComponentWithVisibilityProps
            this.visible(visible ?? true);
            this.boundVisible = new ReportDataBoundValue<boolean>(boundVisible?.dataSource, boundVisible?.boundExpression, boundVisible?.compiledBoundExpression, boundVisible?.bound);
            this.doNotSplit(doNotSplit ?? false);
        }

        getData() {
            const data = super.getData();
            return {
                ...data,
                visible: this.visible(),
                boundVisible: {
                    dataSource: this.boundVisible.dataSource.getData(),
                    boundExpression: this.boundVisible.boundExpression,
                    compiledBoundExpression: this.boundVisible.compiledBoundExpression,
                    bound: this.boundVisible.bound()
                },
                doNotSplit: this.doNotSplit()
            }
        }

        visible: ko.Observable<boolean> = ko.observable();
        boundVisible: ReportDataBoundValue<boolean>;
        doNotSplit: ko.Observable<boolean> = ko.observable(false);
    }
}