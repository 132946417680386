import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { SuggestionSource } from "../../enums/SuggestionSource";
import { IDataSourceView, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { INavigationMenuComponentModel } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { ISuggestedWorkableElementsDataSource } from "../../../interfaces/ISuggestedWorkableElementsDataSource";
import {
    IWorkedHoursService,
    ISuggestedWorkableElement,
    IGetSuggestedWorkableElementsRequest,
} from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";

export interface IJobOrderElementNavigationMenuComponentModel extends INavigationMenuComponentModel {
    jobOrderId: number;
    jobOrderName: string;
}

export interface ITaskElementNavigationMenuComponentModel extends INavigationMenuComponentModel {
    taskId: number;
    workflowId: number;
    jobOrderId: number;

    taskName: string;
    workflowName: string;
    jobOrderName: string;

    taskProgressAvg: number;
    taskRemainingWork: number;
    taskDuration: number;
    activitiesProgressAmountMode: number;
    billable: boolean;
    canEditEstimate: boolean;
    hideAdministrativeData: boolean;
    callRight: boolean;
    workPlace: number;
    travelDistance: number;
    hasDefaultRolesSpecified: boolean;
    hasDefaultWorkTimeCategoriesSpecified: boolean;

    suggestionSource: SuggestionSource;
    note: string;
    estimatedWork: number;
    workedHours: number;
}

export class SuggestedWorkableElementsDataSource implements ISuggestedWorkableElementsDataSource {
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService!: IWorkedHoursService;

    private view!: IDataSourceView;
    private selectedResourceId!: number;
    private selectedJobOrderId!: number;
    private selectedDate!: Date;

    private cache: ISuggestedWorkableElement[] = null;
    private lastTextFilter = "";

    constructor() {}

    setView(view: IDataSourceView): void {
        this.view = view;
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): void {}

    public getTitle(): string {
        return ProlifeSdk.TextResources.WorkedHours.SuggestedWorkableElementsDataSourceTitle;
    }

    public isGroupedData(currentModel: INavigationMenuComponentModel, textFilter: string): boolean {
        return true;
    }

    public async getData(
        currentModel: INavigationMenuComponentModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<INavigationMenuComponentModel[]> {
        if (!this.selectedDate && !this.selectedResourceId) return new Promise((resolve, reject) => resolve([]));

        const textFilterIsChanged = this.lastTextFilter != (textFilter || "");

        if (this.cache === null || textFilterIsChanged) {
            const request: IGetSuggestedWorkableElementsRequest = {
                resourceId: this.selectedResourceId,
                jobOrderId: this.selectedJobOrderId,
                startDate: moment(this.selectedDate || new Date())
                    .startOf("day")
                    .toDate(),
                endDate: moment(this.selectedDate || new Date())
                    .endOf("day")
                    .toDate(),
                showHidden: false,
                textFilter: textFilter,
                skip: 0,
                count: 10000000,
            };

            this.cache = await this.workedHoursService.GetSuggestedWorkableElements(request);
            this.lastTextFilter = textFilter || "";
        }

        if (!currentModel) {
            const jobOrders = [];
            let lastJobOrderId = -1;

            for (const e of this.cache) {
                if (e.JobOrderId != lastJobOrderId) {
                    jobOrders.push({
                        isGroup: true,
                        collapsed: false,
                        isLeaf: false,
                        title: e.JobOrderName,
                        id: e.JobOrderId,

                        jobOrderId: e.JobOrderId,
                        jobOrderName: e.JobOrderName,
                    });
                    lastJobOrderId = e.JobOrderId;
                }
            }

            return jobOrders.slice(skip, skip + count);
        }

        const tasks = this.cache.filter((t) => t.JobOrderId == currentModel.id);
        return tasks
            .map((e) => {
                const icon =
                    e.SuggestionSource == SuggestionSource.Event
                        ? "icon-bubbles"
                        : e.SuggestionSource == SuggestionSource.Task
                        ? "icon-list"
                        : "fa fa-shopping-cart";
                const iconBakground =
                    e.SuggestionSource == SuggestionSource.Event
                        ? "#32833E"
                        : e.SuggestionSource == SuggestionSource.Task
                        ? "#2A6489"
                        : "#32833E";
                const iconForeground =
                    e.SuggestionSource == SuggestionSource.Event
                        ? "#FFF"
                        : e.SuggestionSource == SuggestionSource.Task
                        ? "#FFF"
                        : "#FFF";

                return {
                    isGroup: false,
                    isLeaf: true,
                    title: e.TaskName || e.Note,
                    subTitle: e.WorkflowName,

                    badge: [
                        {
                            text:
                                // eslint-disable-next-line import/namespace
                                moment.duration(e.WorkedHours || 0, "hours").format("hh:mm", { trim: false }) +
                                "/" +
                                // eslint-disable-next-line import/namespace
                                moment.duration(e.EstimatedWork || 0, "hours").format("hh:mm", { trim: false }),
                            cssClass: "badge-success",
                        },
                    ],

                    id: e.TaskId,

                    taskId: e.TaskId,
                    workflowId: e.WorkflowId,
                    jobOrderId: e.JobOrderId,

                    taskName: e.TaskName,
                    workflowName: e.WorkflowName,
                    jobOrderName: e.JobOrderName,

                    taskProgressAvg: e.TaskProgressAvg,
                    taskRemainingWork: e.TaskRemainingWork,
                    taskDuration: e.TaskDuration,
                    activitiesProgressAmountMode: e.ActivitiesProgressAmountMode,
                    billable: e.Billable,
                    canEditEstimate: e.CanEditEstimate,
                    hideAdministrativeData: e.HideAdministrativeData,
                    callRight: e.CallRight,
                    workPlave: e.WorkPlace,
                    travelDistance: e.TravelDistance,
                    hasDefaultRolesSpecified: e.HasDefaultRolesSpecified,
                    hasDefaultWorkTimeCategoriesSpecified: e.HasDefaultWorkTimeCategoriesSpecified,

                    suggestionSource: e.SuggestionSource,
                    note: e.Note,
                    estimatedWork: e.EstimatedWork,
                    workedHours: e.WorkedHours,
                    icon: {
                        icon: icon,
                        background: iconBakground,
                        forreground: iconForeground,
                    },
                };
            })
            .slice(skip, skip + count);
    }

    getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        //TODO: Implementare
        throw new Error("Method not implemented.");
    }

    public areEqual(a: INavigationMenuComponentModel, b: INavigationMenuComponentModel): boolean {
        if (a === b) return true;
        if (!a || !b) return false;

        const aAsTaskElement = a as ITaskElementNavigationMenuComponentModel;
        const bAsTaskElement = b as ITaskElementNavigationMenuComponentModel;

        if (aAsTaskElement.taskId == bAsTaskElement.taskId) return true;

        const aAsJobOrderElement = a as IJobOrderElementNavigationMenuComponentModel;
        const bAsJobOrderElement = b as IJobOrderElementNavigationMenuComponentModel;

        if (aAsJobOrderElement.jobOrderId == bAsJobOrderElement.jobOrderId) return true;

        return false;
    }

    public setResourceId(id: number): void {
        this.selectedResourceId = id;
        this.cache = null;
    }

    public setJobOrderId(id: number): void {
        this.selectedJobOrderId = id;
        this.cache = null;
    }

    public setWorkDay(date: Date): void {
        this.selectedDate = date;
        this.cache = null;
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
