import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { ComponentUtils, classNames, reloadNow } from "../Core/utils/ComponentUtils";
import { WorkflowsUtils } from "../Todolist/Todolist/ui/utils/WorkflowsUtils";
import { NumericText } from "./NumericText";
import { RelatedDocumentsPopOver } from "../Todolist/Todolist/ui/workflows/RelatedDocumentsPopOver";
import { IAuthorizationService } from "../Core/interfaces/IAuthorizationService";
import { LazyImport } from "../Core/DependencyInjection";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type RelatedDocumentsBadgeProps = {
    workflowId: number;
    relatedDocuments: number;
    workflowMustBeRelatedToCustomerOrders: boolean;
};

export function RelatedDocumentsBadge(props: RelatedDocumentsBadgeProps) {
    const C = require("./RelatedDocumentsBadge")._RelatedDocumentsBadge as typeof _RelatedDocumentsBadge;
    return <C {...props} />;
}

export class _RelatedDocumentsBadge {
    static defaultProps: Partial<RelatedDocumentsBadgeProps> = {};

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    constructor(private props: RelatedDocumentsBadgeProps) {}

    private action(sender: unknown, event: Event) {
        if (!this.authorizationService.isAuthorized("TaskBoard_ViewWorkflowRelatedDocuments")) return Promise.resolve();

        const popOver = new RelatedDocumentsPopOver({ workflowId: this.props.workflowId });
        return popOver.show(event.currentTarget as HTMLElement);
    }

    render() {
        const relatedDocsBadge = this;

        return (
            <>
                {(this.props.relatedDocuments > 0 || this.props.workflowMustBeRelatedToCustomerOrders) &&
                    ComponentUtils.bindTo(
                        <button
                            className={classNames(
                                "badge",
                                WorkflowsUtils.getRelatedDocumentsBadgeClass(
                                    this.props.relatedDocuments,
                                    this.props.workflowMustBeRelatedToCustomerOrders
                                )
                            )}
                            style={{ border: "none" }}
                            data-bind={{ asyncClick: relatedDocsBadge.action.bind(relatedDocsBadge) }}
                            title={WorkflowsUtils.getRelatedDocumentsBadgeTitle(
                                this.props.workflowMustBeRelatedToCustomerOrders
                            )}
                        >
                            <NumericText value={this.props.relatedDocuments} format="0,0" />
                        </button>,
                        this,
                        "relatedDocsBadge"
                    )}
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RelatedDocumentsBadge);
}
