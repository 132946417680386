/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 23/08/2018
 * Time: 17:00
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { IPaymentTypeManagerUi, IOrganizationalUnitForPaymentsManager, PaymentAccount } from "../../interfaces/prolife-sdk/ui/IPaymentTypeManagerUi";
import { IPaymentMode, IPaymentModes } from "../../interfaces/invoice/settings/IPaymentModes";
import { IAccount } from "../../interfaces/settings/IAccount";
import { IPaymentAccount } from "../../interfaces/invoice/IProtocolsSettingsManager";
import { ICompanySettingsManager } from "../../interfaces/settings/ICompanySettingsManager";
import { ICustomerBank } from "../../interfaces/customer/ICustomerBank";
import { ICompany } from "../../interfaces/settings/ICompany";
import { IBank } from "../../interfaces/settings/IBank";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../ValidationService";
import { TextResources } from "../../ProlifeTextResources";

export class PaymentTypeManagerUi implements IPaymentTypeManagerUi {
    public PaymentModes: ko.ObservableArray<IPaymentMode> = ko.observableArray([]);
    public ExternalAccounts : ko.ObservableArray<IPaymentAccount> = ko.observableArray([]); // Viene ancora usato??

    public IBANList: ko.Computed<PaymentAccount[]>;
    public ABIList: ko.Computed<string[]>;
    public CABList: ko.Computed<string[]>;

    public Payment: ko.Observable<IPaymentMode> = ko.observable();
    public PaymentId: ko.Observable<number> = ko.observable();
    public PaymentDescription: ko.Observable<string> = ko.observable();
    public PaymentAccount: ko.Observable<PaymentAccount> = ko.observable();
    public PaymentAccountId: ko.Observable<number> = ko.observable();
    public PaymentIBAN: ko.Observable<string> = ko.observable();
    public PaymentABI: ko.Observable<string> = ko.observable();
    public PaymentCAB: ko.Observable<string> = ko.observable();

    public RequireAbiAndCabSelection: ko.Observable<boolean> = ko.observable(true);
    public RequireIbanSelection: ko.Observable<boolean> = ko.observable(true);

    private SalesCicle : ko.Observable<boolean> = ko.observable(true);
    private CustomerOrganizationalUnits: ko.ObservableArray<IOrganizationalUnitForPaymentsManager> = ko.observableArray([]);

    private CompanyAccounts: ko.ObservableArray<IAccount> = ko.observableArray([]);
    private CompanyABIList: ko.ObservableArray<string> = ko.observableArray([]);
    private CompanyCABList: ko.ObservableArray<string> = ko.observableArray([]);
    
    private CustomerIbanList: ko.ObservableArray<ICustomerBank> = ko.observableArray([]);
    private CustomerABIList : ko.ObservableArray<string> = ko.observableArray([]);
    private CustomerCABList : ko.ObservableArray<string> = ko.observableArray([]);

    private updating = false;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    @LazyImportSettingManager(ProlifeSdk.PaymentMode)
    private paymentModesManager : IPaymentModes;
    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    private companySettings : ICompanySettingsManager;

    private validator : IValidator<PaymentTypeManagerUi>;

    constructor(salesCicle = true) {
        this.SalesCicle(salesCicle);
        this.PaymentModes(this.paymentModesManager.getPaymentModes(false));

        this.loadCompanyAccount();
        this.loadCompanyABICAB();

        this.SalesCicle.subscribe(() => {
            this.clear();

            this.loadCompanyAccount();
            this.loadCompanyABICAB();
            this.loadCustomerIBANList(this.CustomerOrganizationalUnits());
            this.loadCustomerAccountABICAB(this.CustomerOrganizationalUnits());
        });

        this.Payment.subscribe(this.updatePaymentIdAndDescription.bind(this));
        this.PaymentId.subscribe(this.loadPaymentIfNotSet.bind(this));
        this.PaymentABI.subscribe(this.updateCabListAndPaymentDescription.bind(this));
        this.PaymentCAB.subscribe(this.updatePaymentDeacription.bind(this));
        this.PaymentIBAN.subscribe(this.updatePaymentDeacription.bind(this));
        this.PaymentAccount.subscribe(this.updatePaymentAccountIdAndDescription.bind(this));
        this.PaymentAccountId.subscribe(this.loadPaymentAccountIfNotSet.bind(this));

        this.IBANList = ko.computed(() => {
            if (!this.Payment() || this.Payment().AssociaBanca !== 2)
                return [];

            return this.SalesCicle() ? this.CompanyAccounts().map(a => ({ Id: a.Id, IBAN: a.IBAN })) : this.CustomerIbanList().map(i => ({ Id: i.Id, IBAN: i.IBAN }));
        });

        this.ABIList = ko.computed(() => {
            if (!this.Payment() || this.Payment().AssociaBanca !== 1)
                return [];

            return this.SalesCicle() ? this.CustomerABIList() : this.CompanyABIList();
        });
        
        this.CABList = ko.computed(() => {
            if (!this.Payment() || this.Payment().AssociaBanca !== 1)
                return [];

            return this.SalesCicle() ? this.CustomerCABList() : this.CompanyCABList();
        });

        this.configurePaymentValidation();
    }

    public setPaymentInfoFromDescription(paymentDescription: string): void {
        if (!paymentDescription)
            return;

        this.PaymentDescription(paymentDescription);

        const paymentComponents: string[] = paymentDescription.split("-");
        const payment: string = paymentComponents[0].trim();
        const paymentModes: IPaymentMode[] = this.paymentModesManager.getPaymentModes(false).filter((p: IPaymentMode) => p.Descrizione.trim() == payment);
        if (paymentModes.length == 0) {
            this.clear();
            return;
        }

        this.PaymentId(paymentModes[0].IdTipoPagamento);
        this.Payment(paymentModes[0]);
        this.PaymentIBAN(this.extractPaymentInfo("IBAN", paymentComponents));
        this.PaymentABI(this.extractPaymentInfo("ABI", paymentComponents));
        this.PaymentCAB(this.extractPaymentInfo("CAB", paymentComponents));
    }

    public refreshCustomerPaymentsOptions(organizationalUnits: IOrganizationalUnitForPaymentsManager[]): void {
        this.CustomerOrganizationalUnits(organizationalUnits);
        this.loadCustomerAccountABICAB(organizationalUnits);
        this.loadCustomerIBANList(organizationalUnits);
    }

    public getValidator() : IValidator<PaymentTypeManagerUi> {
        return this.validator;
    }

    private extractPaymentInfo(part: string, paymentComponents: string[]): string {
        const parts: string[] = paymentComponents.map((p: string) => p.toLowerCase()).filter((p: string) => p.indexOf(part.toLowerCase()) >= 0);
        if (parts.length == 0)
            return "";

        const partComponents: string[] = parts[0].split(" ").filter(p => !!p && p !== ":");

        for (let i = 0; i < partComponents.length; i++) {
            const comp = partComponents[i].trim().toUpperCase();
            
            if (comp === part && !!partComponents[i + 1])
                return partComponents[i + 1].toUpperCase();
        }

        return "";
    }

    private loadPaymentIfNotSet(paymentId: number): void {
        if (this.updating)
            return;

        if (!paymentId) {
            this.clear();
            return;
        }

        if (!this.Payment()) {
            const matches: IPaymentMode[] = this.PaymentModes().filter((p: IPaymentMode) => p.IdTipoPagamento == paymentId);
            if (matches.length == 0)
                return;

            this.Payment(matches[0]);
        }
    }

    private loadPaymentAccountIfNotSet(accountId: number): void {
        if (this.updating)
            return;

        if (!accountId) {
            this.PaymentAccount(null);
            this.PaymentIBAN(null);
            return;
        }

        if (!this.PaymentAccount()) {
            const matches = this.IBANList().filter((a) => a.Id == accountId);
            if (matches.length == 0)
                return;

            this.PaymentAccount(matches[0]);
        }
    }

    private loadCompanyAccount(): void {
        this.CompanyAccounts([]);

        if (!this.SalesCicle())
            return;

        const company: ICompany = this.companySettings.get();
        const ibanList: IAccount[] = [];

        company.Banks.forEach((bank : IBank) => {
            if (!bank.Accounts || bank.Accounts.length == 0)
                return;

            bank.Accounts.filter((b : IAccount) => !!b.IBAN)
                .forEach((b : IAccount) => { ibanList.push(b); });


        });

        this.CompanyAccounts(ibanList);

        if (ibanList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 2)
            this.PaymentIBAN(ibanList[0].IBAN);
    }

    private loadCompanyABICAB(): void {
        this.CompanyABIList([]);
        this.CompanyCABList([]);

        if (this.SalesCicle())
            return;

        const company: ICompany = this.companySettings.get();
        const abiList: string[] = [];
        const cabList: string[] = [];
        
        company.Banks.forEach((bank : IBank) => {
            if (!(bank.ABI || "").trim() || !(bank.CAB || "").trim())
                return;

            abiList.push(bank.ABI);
            cabList.push(bank.CAB);
        });

        this.CompanyABIList(abiList);
        this.CompanyCABList(cabList);

        if (abiList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 1)
            this.PaymentABI(abiList[0]);

        if (cabList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 1)
            this.PaymentCAB(cabList[0]);
    }

    private loadCustomerIBANList(organizationalUnits: IOrganizationalUnitForPaymentsManager[]): void {
        this.CustomerIbanList([]);
        
        if (!organizationalUnits || this.SalesCicle())
            return;

        const ibanList: ICustomerBank[] = [];

        for (const ou of organizationalUnits) {
            ou.Banche.forEach((bank : ICustomerBank) => {
                if (bank.IBAN)
                    ibanList.push(bank);
            });
        }

        this.CustomerIbanList(ibanList);

        if (ibanList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 2) {
            const bank = ibanList[0];
            this.PaymentIBAN(bank.IBAN);
        }
    }

    private loadCustomerAccountABICAB(organizationalUnits: IOrganizationalUnitForPaymentsManager[])
    {
        this.loadCustomerAbiList(organizationalUnits);
        this.loadCustomerCabList(organizationalUnits);
    }

    private loadCustomerAbiList(organizationalUnits: IOrganizationalUnitForPaymentsManager[]): void {
        this.CustomerABIList([]);
        
        if (!organizationalUnits || !this.SalesCicle())
            return;

        const abiList = [];

        for (const uo of organizationalUnits) {
            uo.Banche.forEach((bank : ICustomerBank) => {
                if (bank.ABI)
                    abiList.push(bank.ABI);
            });
        }

        this.CustomerABIList(abiList);

        if (abiList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 1)
            this.PaymentABI(abiList[0]);
    }
    
    private loadCustomerCabList(organizationalUnits: IOrganizationalUnitForPaymentsManager[]): void {
        this.CustomerCABList([]);

        if (!organizationalUnits || !this.SalesCicle())
            return;

        const cabList = [];

        for (const uo of organizationalUnits) {
            uo.Banche.forEach((bank : ICustomerBank) => {
                if(bank.ABI == this.PaymentABI() && bank.CAB)
                    cabList.push(bank.CAB);
            });
        }

        this.CustomerCABList(cabList);

        if (cabList.length == 1 && this.Payment() && this.Payment().AssociaBanca === 1)
            this.PaymentCAB(cabList[0]);
    }

    private configurePaymentValidation(): void {
        this.validator = this.validationService.createValidator<PaymentTypeManagerUi>()
            .isNotNullOrUndefinedOrWhiteSpace(p => p.PaymentIBAN(), TextResources.ProlifeSdk.InsertPaymentIBAN, () => this.Payment() && this.Payment().AssociaBanca == 2 && this.RequireIbanSelection())
            .isNotNullOrUndefinedOrWhiteSpace(p => p.PaymentABI(), TextResources.ProlifeSdk.InsertPaymentABI, () => this.Payment() && this.Payment().AssociaBanca == 1 && this.RequireAbiAndCabSelection())
            .isNotNullOrUndefinedOrWhiteSpace(p => p.PaymentCAB(), TextResources.ProlifeSdk.InsertPaymentCAB, () => this.Payment() && this.Payment().AssociaBanca == 1 && this.RequireAbiAndCabSelection());
    }

    private updatePaymentIdAndDescription(payment: IPaymentMode): void {
        if (this.updating)
            return;

        if (!payment) {
            this.clear();
            return;
        }

        this.PaymentId(payment.IdTipoPagamento);
        this.PaymentDescription(this.preparePaymentDescription());
        this.setupAccountABICABIfNecessary();
    }
    
    private setupAccountABICABIfNecessary() {
        const payment = this.Payment();

        if (!payment)
            return;

        if (payment.AssociaBanca === 1) {
            const cabs = this.CustomerCABList();
            if (cabs.length === 1)
                this.PaymentCAB(cabs.firstOrDefault());

            const abis = this.CustomerABIList();
            if (abis.length === 1)
                this.PaymentABI(abis.firstOrDefault());
        } else {
            this.PaymentABI(null);
            this.PaymentCAB(null);
        }

        if (payment.AssociaBanca === 2) {
            const accounts = this.IBANList();
            if (accounts.length === 1) {
                const account = accounts.firstOrDefault();
                this.PaymentIBAN(account.IBAN);
                this.PaymentAccount(account);
            }
        } else {
            this.PaymentAccountId(null);
            this.PaymentAccount(null);
            this.PaymentIBAN(null);
        }
    }

    private updatePaymentAccountIdAndDescription(paymentAccount: IAccount): void {
        if (this.updating)
            return;

        if (!paymentAccount) {
            this.PaymentAccountId(null);
            this.PaymentIBAN(null);
            return;
        }

        this.PaymentAccountId(paymentAccount.Id);
        this.PaymentIBAN(paymentAccount.IBAN);
    }

    private updateCabListAndPaymentDescription(): void {
        this.loadCustomerCabList(this.CustomerOrganizationalUnits());
        this.PaymentDescription(this.preparePaymentDescription());

    }

    private updatePaymentDeacription(): void {
        this.PaymentDescription(this.preparePaymentDescription());
    }

    private preparePaymentDescription()
    {
        if(!this.Payment())
            return null;

        let description : string = this.Payment().Descrizione || "";

        description += this.Payment().AssociaBanca == 1 ? " - ABI : " + (this.PaymentABI() || "") + " CAB : " + (this.PaymentCAB() || "") : "";
        description += this.Payment().AssociaBanca == 2 ? " - IBAN : " + (this.PaymentIBAN() || "") : "";

        return description;
    }

    private clear(): void {
        this.updating = true;

        this.Payment(null);
        this.PaymentId(null);
        this.PaymentIBAN(null);
        this.PaymentABI(null);
        this.PaymentCAB(null);
        this.PaymentDescription(null);
        this.PaymentAccount(null);
        this.PaymentAccountId(null);

        this.updating = false;
    }
}