import * as ko from "knockout";
import * as moment from "moment";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTaskJobOrderTags } from "./tags/TodoListTaskJobOrderTags";
import { TodoListTaskLabelTags } from "./tags/TodoListTaskLabelTags";
import { TodoListTaskHumanResourceTags } from "./tags/TodoListTaskHumanResourceTags";
import { TodoListWorkflowsControlsEntityProvider } from "../../providers/TodoListWorkflowsControlsEntityProvider";
import { ActivityAttachmentsManager, IAttachmentsManagerObserver } from "./ActivityAttachmentsManager";
import { ResourcesAndGroupsManager } from "./ResourcesAndGroupsManager";
import { PurchasesDataSource, IPurchasesDataSourceModel } from "../../../../DataSources/PurchasesDataSource";
import { PurchaseTypesDataSource } from "../../../../DataSources/PurchaseTypesDataSource";
import { WarehouseArticlesDataSource } from "../../../../DataSources/WarehouseArticlesDataSource";
import { NumbersUtilities } from "../../../../ProlifeSdk/prolifesdk/utils/NumbersUtilities";
import { IPurchaseTypeSettingManager } from "../../../../SettingManagers/PurchaseTypeSettingManager";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { WorkflowsDataSource } from "../../../../DataSources/WorkflowsDataSource";
import { IHumanResourcesService, IGetUserCharacterMeanCostRequest } from "../../../../Users/HumanResourcesService";
import { DiscountsUtilities } from "../../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDiscountsCatalogsService,
    DiscountCatalogRowMode,
    IResolvedCustomerDiscountCatalogArticleWithCatalog,
} from "../../../../Warehouse/DiscountsCatalogsService";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import {
    ITodoListService,
    IArticleCostAndPrice,
    ITaskInsertOrUpdateResponse,
    ITodoListTemplateTaskInsertResponse,
    ReportingType,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IException } from "../../../../Core/interfaces/IException";
import {
    ITodoListElementsProvider,
    ITodoListTaskTag,
    IEstimateBudgetRow,
    ITodoListActivity,
    ITodoListTaskNew,
    ITodoListTask,
    ITodoListTaskNewSymbol,
    ITodoListTaskSymbol,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import {
    IDefaultRole,
    IDefaultWorkTimeCategory,
    ITodoListResource,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { IAllocationsService } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService, IPopoverComponentInfo } from "../../../../Core/interfaces/IDialogsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import {
    IArticle,
    IArticlesService,
    IArticleTransform,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IWarehouseSettingsManager } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehouseSettingsManager";
import { IJobOrder } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IJobOrderRolesPrices } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderRolesPrices";
import { Deferred } from "../../../../Core/Deferred";
import { IEstimatedBudgetForTaskState } from "../../../interfaces/IEstimatedBudgetForTaskStatesService";
import { IEstimatedBudgetForTaskStatesSettingsManager } from "../../../interfaces/IEstimatedBudgetForTaskStatesSettingsManager";
import "./EstimatedBudgetForTaskStatePopover";
import { Right } from "../../../../Core/Authorizations";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";
import { DefaultRole, DefaultWorkTimeCategory } from "../utils/WorkedHoursDefaultsEditor";
import { ResponseData } from "../../../../Core/response/ResponseBase";
import { TodolistTaskMapper } from "../../../mappings/TodolistTaskMapper";

interface IDraggedEbt {
    Id: number;
    TaskId: number;
    CompanyGuid: string;
}

export interface IEstimatedBudgetDetails {
    Id: number;
    Description: string;
    Cost: number;
    RealMarkup: number;
    MarkupAvg: number;
    DiscountAvg: number;
    Price: number;
    PriceWithoutDiscounts: number;
}

export interface IActivityProgressOption {
    label: string;
    value: number;
}

export class TodoListActivity implements IAttachmentsManagerObserver {
    @LazyImport(nameof<IInfoToastService>())
    protected infoToastService: IInfoToastService;

    @LazyImport(nameof<ITodoListService>())
    protected todoListService: ITodoListService;

    @LazyImport(nameof<IInfoToastService>())
    protected toastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;

    @LazyImport(nameof<IDesktopService>())
    protected desktopService: IDesktopService;

    @LazyImport(nameof<IEntityProviderService>())
    protected entitiesProviderService: IEntityProviderService;

    @LazyImport(nameof<IAllocationsService>())
    protected allocationsService: IAllocationsService;

    @LazyImport(nameof<IAuthorizationService>())
    protected authorizationsService: IAuthorizationService;

    @LazyImport(nameof<IUserInfo>())
    protected userInfoService: IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    protected rolesManager: IUserCharactersSettingsManager;

    //Templates da utilizzare per rappresentare l'attività negli elenchi
    public collapsedTemplateName: string = null;
    public templateForListInTaskEditor: string = null;

    //Templates da utilizzare per rappresentare l'attività nel dettaglio
    public expandedTemplateName: string = null;
    public editTemplateName: string = null;
    public editTemplateInnerName: string = null;
    public changeStatusTemplateName: string = null;
    public changePlanTemplateName: string = null;

    //Property dell'oggetto
    public Id: number;
    public ObservableId: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable("");
    public Description: ko.Observable<string> = ko.observable("");
    public Duration: ko.Observable<number> = ko.observable(0);
    public Priority: ko.Observable<number> = ko.observable(1);
    public InQuality: ko.Observable<boolean> = ko.observable(false);
    public Optional: ko.Observable<boolean> = ko.observable(false);
    public HideFromSuggestions: ko.Observable<boolean> = ko.observable(false);

    public CustomTagsManager: TodoListTaskLabelTags;
    public JobOrdersTagsManager: TodoListTaskJobOrderTags;
    public ResponsibleTagsManager: TodoListTaskHumanResourceTags;
    public WorkflowProvider: TodoListWorkflowsControlsEntityProvider;
    public AttachmentsManager: ActivityAttachmentsManager;

    public HasAttachments: ko.Observable<boolean> = ko.observable(false);

    public ForAdministration: ko.Observable<boolean> = ko.observable(false);
    public ForCommercial: ko.Observable<boolean> = ko.observable(false);
    public ForTechnical: ko.Observable<boolean> = ko.observable(false);

    public TitleHasFocus: ko.Observable<boolean> = ko.observable(false);

    public IsAllocated: ko.Observable<boolean> = ko.observable();
    public IsEstimated: ko.Observable<boolean> = ko.observable();

    public ActivitiesProgressAmountMode: ko.Observable<number> = ko.observable(0);
    public ActivitiesProgressAmountModeOptions: ko.ObservableArray<IActivityProgressOption> = ko.observableArray([]);

    public ShowInSuggestions: ko.Computed<boolean>;
    public ActivitiesProgressAmountModeName: ko.Computed<string>;

    public MultiplierUnitOfMeasure: ko.Observable<string> = ko.observable();
    public Multiplier: ko.Observable<number> = ko.observable(1);

    public IsNew: ko.Observable<boolean> = ko.observable(true);

    public ShowAttachmentsActions: ko.Observable<boolean> = ko.observable(false);

    public IsWarehouseEnabled: ko.Observable<boolean> = ko.observable(false);

    //Computed
    public Index: ko.Computed<number>;
    public IsSelected: ko.Computed<boolean>;

    public Resources: ResourcesAndGroupsManager;

    public ContainerIdField: ko.Computed<number>; //Campo da settare nelle derivate. Rappresenta il WorkflowId o il TemplateId

    private ScrollIntoViewTrigger: ko.Observable<boolean> = ko.observable(false);

    public ReportingTypes: ReportingType[] = [];
    public ReportingType: ko.Observable<number> = ko.observable();
    public ReportingTypeName: ko.Computed<string>;

    public WorkedHoursDefaultPlace: ko.Observable<number> = ko.observable();
    public WorkedHoursDefaultTravelDistance: ko.Observable<number> = ko.observable();
    public DDC: ko.Observable<boolean> = ko.observable(false);
    public WorkedHoursDefaultRoles: ko.ObservableArray<DefaultRole> = ko.observableArray([]);
    public WorkedHoursDefaultWorkTimeCategories: ko.ObservableArray<DefaultWorkTimeCategory> = ko.observableArray([]);
    public WorkedHoursDefaultsStrictMode: ko.Observable<boolean> = ko.observable(false);
    public HideAdministrativeDataOnWorkedHours: ko.Observable<boolean> = ko.observable(false);

    public Roles: IUserCharacter[] = [];
    public NewEstimatedWorkRoleId: ko.Observable<number> = ko.observable();
    public NewPurchaseDescription: ko.Observable<string> = ko.observable();
    public NewArticleId: ko.Observable<number> = ko.observable();

    public ShowTotals: ko.Observable<boolean> = ko.observable(true);
    public ShowSubTotals: ko.Observable<boolean> = ko.observable(false);
    public ShowSubTotalsDetails: ko.Observable<boolean> = ko.observable(false);

    public EstimatedWorkTotalCosts: ko.Computed<number>;
    public EstimatedWorkTotalRevenues: ko.Computed<number>;
    public EstimatedWorkTotalRevenuesWithoutDiscounts: ko.Computed<number>;

    public EstimatedPurchasesTotalCosts: ko.Computed<number>;
    public EstimatedPurchasesTotalRevenues: ko.Computed<number>;
    public EstimatedPurchasesTotalRevenuesWithoutDiscounts: ko.Computed<number>;

    public EstimatedArticlesTotalCosts: ko.Computed<number>;
    public EstimatedArticlesTotalRevenues: ko.Computed<number>;
    public EstimatedArticlesTotalRevenuesWithoutDiscounts: ko.Computed<number>;

    public TotalCosts: ko.Computed<number>;
    public TotalRevenues: ko.Computed<number>;
    public TotalRevenuesWithoutDiscounts: ko.Computed<number>;

    public UserCharactersDetails: ko.Computed<IEstimatedBudgetDetails[]>;
    public PurchasesTypesDetails: ko.Computed<IEstimatedBudgetDetails[]>;

    public AtLeastOneEstimateRowEditable: ko.Computed<boolean>;

    public EstimatedBudgetRows: ko.ObservableArray<EstimateBudgetRow> = ko.observableArray([]);

    public EstimatedWorkRows: ko.Computed<EstimateBudgetRow[]>;
    public EstimatedPurchasesRows: ko.Computed<EstimateBudgetRow[]>;
    public EstimatedArticlesRows: ko.Computed<EstimateBudgetRow[]>;

    public CanInsertWorkedHours: ko.Computed<boolean>;

    public PurchasesDataSource: PurchasesDataSource;
    public WorkflowsDataSource: WorkflowsDataSource;

    public IsTemplate = false;

    public estimatedBudgetRowColSpan = 4;

    @Right("TaskBoard_EditEstimatedBudgetForTaskState")
    protected canEditEstimatedBudgetForTaskState: boolean;
    @Right("TaskBoard_ViewCostsOnEsimatedBudgetRows")
    protected canViewCostsOnEsimatedBudgetRows: boolean;
    @Right("TaskBoard_ViewRevenuesOnEsimatedBudgetRows")
    protected canViewRevenuesOnEsimatedBudgetRows: boolean;

    private nextFakeId: number = -1 * (Math.random() * 100).Round(0);

    constructor(
        public todolist: any,
        public task: any,
        protected activityProvider: ITodoListElementsProvider,
        isTemplate: boolean = false
    ) {
        this.IsTemplate = isTemplate;

        this.ReportingTypes = this.todoListService.GetReportingTypes();

        if (this.canEditEstimatedBudgetForTaskState) this.estimatedBudgetRowColSpan += 1;

        if (this.canViewCostsOnEsimatedBudgetRows) this.estimatedBudgetRowColSpan += 2;

        if (this.canViewRevenuesOnEsimatedBudgetRows) this.estimatedBudgetRowColSpan += 5;

        this.IsWarehouseEnabled(this.authorizationsService.isAuthorized("!Warehouse"));

        this.PurchasesDataSource = new PurchasesDataSource();
        this.PurchasesDataSource.setFilter(true, false);

        this.WorkflowsDataSource = new WorkflowsDataSource();
        this.WorkflowsDataSource.setViewFilters(true, true, true);
        this.WorkflowsDataSource.setWorkFilters(true);
        this.WorkflowsDataSource.setGroupedModeEnabled(true);
        this.WorkflowsDataSource.setUserId(this.userInfoService.getIdUser());

        this.WorkflowProvider = new TodoListWorkflowsControlsEntityProvider(); // DEPRECATO
        this.AttachmentsManager = new ActivityAttachmentsManager();

        this.AttachmentsManager.registerObserver(this);

        this.CustomTagsManager = new TodoListTaskLabelTags();
        this.ResponsibleTagsManager = new TodoListTaskHumanResourceTags("RESPONSIBLE");
        this.Resources = new ResourcesAndGroupsManager();

        this.Roles = this.rolesManager.getUserCharacters();

        this.ActivitiesProgressAmountModeOptions.push({
            label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressWorkedHoursMode,
            value: 0,
        });
        this.ActivitiesProgressAmountModeOptions.push({
            label: ProlifeSdk.TextResources.Todolist.ActivitiesProgressAmountMode,
            value: 1,
        });

        this.EstimatedWorkRows = ko.computed(() => {
            return this.EstimatedBudgetRows().filter((r) => r.row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode);
        });

        this.EstimatedPurchasesRows = ko.computed(() => {
            return this.EstimatedBudgetRows().filter((r) => r.row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode);
        });

        this.EstimatedArticlesRows = ko.computed(() => {
            return this.EstimatedBudgetRows().filter((r) => r.row.Type === ProlifeSdk.WarehouseArticleEntityTypeCode);
        });

        this.ActivitiesProgressAmountModeName = ko.computed(() => {
            if (this.ActivitiesProgressAmountMode() == null || this.ActivitiesProgressAmountMode() == undefined)
                return "";

            const matches = this.ActivitiesProgressAmountModeOptions().filter(
                (o) => o.value == this.ActivitiesProgressAmountMode()
            );
            return matches.length == 0 ? "" : matches[0].label;
        });

        this.ReportingTypeName = ko.computed(() => {
            const matches: any[] = this.ReportingTypes.filter((t) => {
                return t.Id == this.ReportingType();
            });
            return matches.length == 0 ? null : matches[0].Description;
        });

        this.IsSelected = ko.computed(() => {
            return todolist && todolist.TaskInEditId() == this.ObservableId();
        });

        this.Index = ko.computed(() => {
            return todolist && todolist.Tasks().indexOf(this);
        });

        this.ShowInSuggestions = ko.computed({
            read: () => {
                return !this.HideFromSuggestions();
            },
            write: (value: boolean) => {
                this.HideFromSuggestions(!value);
            },
        });

        this.EstimatedWorkTotalCosts = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += w.Cost() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedWorkTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += w.Price() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedWorkTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedPurchasesTotalCosts = ko.computed(() => {
            let tot = 0;

            this.EstimatedPurchasesRows().forEach((w) => (tot += w.Cost() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedPurchasesTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.EstimatedPurchasesRows().forEach((w) => (tot += w.Price() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedPurchasesTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.EstimatedPurchasesRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedArticlesTotalCosts = ko.computed(() => {
            let tot = 0;

            this.EstimatedArticlesRows().forEach((w) => (tot += w.Cost() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedArticlesTotalRevenues = ko.computed(() => {
            let tot = 0;

            this.EstimatedArticlesRows().forEach((w) => (tot += w.Price() || 0));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.EstimatedArticlesTotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.EstimatedArticlesRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));
            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.TotalCosts = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += w.Cost() || 0));
            this.EstimatedPurchasesRows().forEach((w) => (tot += w.Cost() || 0));
            this.EstimatedArticlesRows().forEach((w) => (tot += w.Cost() || 0));

            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.TotalRevenues = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += w.Price() || 0));
            this.EstimatedPurchasesRows().forEach((w) => (tot += w.Price() || 0));
            this.EstimatedArticlesRows().forEach((w) => (tot += w.Price() || 0));

            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.TotalRevenuesWithoutDiscounts = ko.computed(() => {
            let tot = 0;

            this.EstimatedWorkRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));
            this.EstimatedPurchasesRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));
            this.EstimatedArticlesRows().forEach((w) => (tot += (w.UnitPrice() || 0) * (w.Amount() || 0)));

            tot = (tot || 0) * (this.Multiplier() || 0);

            return tot;
        });

        this.UserCharactersDetails = ko.computed(() => {
            const charactersMap = {};
            const resultList = [];

            this.EstimatedWorkRows().forEach((w) => {
                const characterId = w.EntityKeyId();
                if (!characterId) return;

                if (!charactersMap[characterId]) {
                    const character: IEstimatedBudgetDetails = this.createEstimatedBudgetDetails(w);

                    charactersMap[characterId] = character;
                    resultList.push(character);
                } else {
                    const character = charactersMap[characterId];
                    this.updateEstimatedBudgetDetails(character, w);
                }
            });

            return resultList;
        });

        this.PurchasesTypesDetails = ko.computed(() => {
            const typesMap = {};
            const resultList = [];

            this.EstimatedPurchasesRows().forEach((p) => {
                const typeId = p.EntityKeyId();
                if (!typeId) return;

                if (!typesMap[typeId]) {
                    const type: IEstimatedBudgetDetails = this.createEstimatedBudgetDetails(p);

                    typesMap[typeId] = type;
                    resultList.push(type);
                } else {
                    const type = typesMap[typeId];
                    this.updateEstimatedBudgetDetails(type, p);
                }
            });

            return resultList;
        });

        this.AtLeastOneEstimateRowEditable = ko.computed(() => {
            return (
                this.EstimatedWorkRows().filter((r) => r.Editable()).length > 0 ||
                this.EstimatedArticlesRows().filter((r) => r.Editable()).length > 0
            );
        });

        this.CanInsertWorkedHours = ko.computed(() => {
            return !this.IsTemplate && !this.IsNew();
        });
    }

    public ScrollIntoView() {
        this.ScrollIntoViewTrigger(!this.ScrollIntoViewTrigger());
    }

    public SwitchForAdministration() {
        this.ForAdministration(!this.ForAdministration());
    }

    public SwitchForCommercial() {
        this.ForCommercial(!this.ForCommercial());
    }

    public SwitchForTechnical() {
        this.ForTechnical(!this.ForTechnical());
    }

    public onEstimatedBudgetRowDropped(dataTransfer: DataTransfer, ebt: EstimateBudgetRow, before: boolean) {
        if (dataTransfer.types.indexOf("application/estimated-budget-for-task") >= 0) {
            const data = dataTransfer.getData("application/estimated-budget-for-task");
            const droppedEbt: IDraggedEbt = JSON.parse(data) as IDraggedEbt;

            const companyGuid = this.userInfoService.getCurrentCompanyGuid();
            const activityId = this.Id;

            if (activityId != droppedEbt.TaskId) {
                this.infoToastService.Error(TextResources.Todolist.InvalidEstimatedBudgetRowDrop);
                return;
            }

            if (companyGuid != droppedEbt.CompanyGuid) {
                this.infoToastService.Error(TextResources.Todolist.InvalidEstimatedBudgetRowDrop);
                return;
            }

            this.updateEstimatedBudgetRowsOrder(droppedEbt, ebt, before);
        }
    }

    private updateEstimatedBudgetRowsOrder(droppedEbt: IDraggedEbt, neighbor: EstimateBudgetRow, before: boolean) {
        const estimatedBudgetRows = this.EstimatedBudgetRows();

        const row = estimatedBudgetRows.firstOrDefault((r) => r.row.Id === droppedEbt.Id);
        const actualPosition = row.Order();
        let newPosition = actualPosition;
        let direction = null;

        if (!neighbor) {
            newPosition = estimatedBudgetRows.length;
            direction = newPosition - actualPosition;
        } else {
            const neighborRow = estimatedBudgetRows.firstOrDefault((r) => r.row.Id === neighbor.row.Id);
            const neighborOrder = neighborRow.Order();
            direction = neighborOrder - actualPosition;
            newPosition = before
                ? direction > 0
                    ? neighborOrder - 1
                    : neighborOrder
                : direction > 0
                ? neighborOrder
                : neighborOrder + 1;
        }

        for (const ebtRow of estimatedBudgetRows) {
            if (direction > 0 && ebtRow.Order() > actualPosition && ebtRow.Order() <= newPosition)
                ebtRow.Order(ebtRow.Order() - 1);

            if (direction < 0 && ebtRow.Order() < actualPosition && ebtRow.Order() >= newPosition)
                ebtRow.Order(ebtRow.Order() + 1);

            if (ebtRow.row.Id === droppedEbt.Id) ebtRow.Order(newPosition);
        }

        estimatedBudgetRows.sort((a, b) => a.Order() - b.Order());
        this.EstimatedBudgetRows(estimatedBudgetRows);
    }

    public onEstimatedBudgetRowBeginDrag(ebt: EstimateBudgetRow, dataTransfer: DataTransfer) {
        const draggedEbt: IDraggedEbt = {
            Id: ebt.row.Id,
            TaskId: ebt.row.TaskId,
            CompanyGuid: this.userInfoService.getCurrentCompanyGuid(),
        };

        dataTransfer.setData("text/plain", this.createDraggetEbtTitle(ebt));
        dataTransfer.setData("application/estimated-budget-for-task", JSON.stringify(draggedEbt));
    }

    private createDraggetEbtTitle(ebt: EstimateBudgetRow): string {
        if (ebt.row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode)
            return (ebt.RoleName() || "") + " - " + (ebt.Description() || "");

        if (ebt.row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode)
            return (ebt.PurchaseTypeTitle() || "") + " - " + (ebt.Description() || "");

        return ebt.Description();
    }

    public MoveElementBefore(beforeId, beforeIsTask, afterId, afterIsTask) {
        const afterOrBefore: boolean = beforeId ? false : true;
        const activityId: number = afterOrBefore ? afterId : beforeId;
        const activityIsTask: boolean = afterOrBefore ? afterIsTask : beforeIsTask;

        this.activityProvider.MoveActivity(this.Id, true, activityId, activityIsTask, afterOrBefore);
    }

    public OnJobOrderSelectionChanged(jobOrderId: number) {
        //Implementare nelle derivate se necessario
    }

    public SetFocusOnTitle() {
        this.TitleHasFocus(true);
    }

    public LoadFromModel(task: ITodoListActivity, refreshAttachmentsDetailsData?: boolean) {
        if (!task.Id) task.Id = this.nextFakeId--;

        this.task = task;
        this.Id = task.Id;
        this.ObservableId(task.Id);
        this.Title(task.Title);
        this.Description(task.Description);
        this.Duration(task.Duration);
        this.Priority(task.Priority);
        this.InQuality(task.InQuality);
        this.Optional(task.Optional);
        this.HideFromSuggestions(task.HideFromSuggestions);
        this.ReportingType(task.ReportingType || 0);
        this.HasAttachments(task.HasAttachments);

        this.CustomTagsManager.LoadFromModel(task.Tags);
        this.ResponsibleTagsManager.LoadFromModel(task.Tags);
        this.AttachmentsManager.LoadFromTags(task.Tags);

        if (!task.HasAttachments) this.HasAttachments(this.AttachmentsManager.NumberOfAttachments() > 0);

        this.Resources.LoadResources(task.Resources);
        this.PopulateSecurityFields(task.Tags);

        if (refreshAttachmentsDetailsData) this.LoadAttachmentsDetailsInfo();

        this.IsAllocated(task.IsAllocated);
        this.IsEstimated(task.IsEstimated);

        this.ActivitiesProgressAmountMode(task.ActivitiesProgressAmountMode);
        this.MultiplierUnitOfMeasure(task.MultiplierUnitOfMeasure);
        this.Multiplier(task.Multiplier);

        this.WorkedHoursDefaultPlace(task.DefaultWorkPlace);
        this.WorkedHoursDefaultTravelDistance(task.DefaultTravelDistance);
        this.DDC(task.DefaultCallRight);
        this.WorkedHoursDefaultsStrictMode(task.WorkedHoursDefaultsStrictMode);
        this.HideAdministrativeDataOnWorkedHours(task.HideAdministrativeFieldsOnWorkedHours);
        this.WorkedHoursDefaultRoles(this.createDefaultRoles(task.WorkedHoursDefaultRoles));
        this.WorkedHoursDefaultWorkTimeCategories(
            this.createDefaultWorkTimeCategories(task.WorkedHoursDefaultWorkTimeCategories)
        );

        this.IsNew(!task.Id || task.Id <= 0);
    }

    protected createDefaultRoles(roles: IDefaultRole[]): DefaultRole[] {
        return roles?.map((r) => ({ RoleId: ko.observable(r.RoleId), Order: ko.observable(r.Order) })) ?? [];
    }

    protected createDefaultWorkTimeCategories(
        workTimeCategories: IDefaultWorkTimeCategory[]
    ): DefaultWorkTimeCategory[] {
        return (
            workTimeCategories?.map((r) => ({
                WorkTimeCategoryId: ko.observable(r.WorkTimeCategoryId),
                Order: ko.observable(r.Order),
            })) ?? []
        );
    }

    public async resetEstimatesCostsAndPrices(): Promise<void> {
        const promises: Promise<any>[] = [];

        this.EstimatedWorkRows().forEach((r) => promises.push(r.resetCostsAndPrices()));
        this.EstimatedArticlesRows().forEach((r) => promises.push(r.resetCostsAndPrices()));

        await Promise.all(promises);
    }

    private PopulateSecurityFields(tags: ITodoListTaskTag[]) {
        const secMatches = tags.filter((t: ITodoListTaskTag) => {
            return t.Field == "SECURITYGROUPS";
        });
        this.ForAdministration(secMatches.length > 0 ? secMatches[0].Value[0] == "1" : false);
        this.ForCommercial(secMatches.length > 0 ? secMatches[0].Value[1] == "1" : false);
        this.ForTechnical(secMatches.length > 0 ? secMatches[0].Value[2] == "1" : false);
    }

    public ShowDetails() {
        this.activityProvider.GetActivityById(this.Id).then((t: any) => {
            this.LoadFromModel(t, true);
        });
    }

    public LoadAttachmentsDetailsInfo() {
        this.AttachmentsManager.LoadFilesThumbnails().then(() => {
            if (this.todolist) this.todolist.ShowActivityDetails(this);
        });
    }

    public Delete(): Promise<void> {
        return this.activityProvider.DeleteActivity(this.Id);
    }

    public SaveChanges(
        refreshAttachmentsDetailsData?: boolean
    ): Promise<ResponseData<ITaskInsertOrUpdateResponse> | ITodoListTemplateTaskInsertResponse> {
        const def = new Deferred<ResponseData<ITaskInsertOrUpdateResponse> | ITodoListTemplateTaskInsertResponse>();

        if ((this.Title() || "").length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.Todolist.InsertTaskTitle);
            this.SetFocusOnTitle();
            return def.reject().promise();
        }

        if (this.ReportingType() == null || this.ReportingType() == undefined || isNaN(this.ReportingType())) {
            this.toastService.Warning(ProlifeSdk.TextResources.Todolist.SelectReportingType);
            return def.reject().promise();
        }

        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Todolist.SavingInProgress);

        const taskData = this.GetData();
        if (
            taskData.EstimatedBudgetRows &&
            taskData.EstimatedBudgetRows.filter(
                (e) => !e.EntityKeyId || (e.Type == ProlifeSdk.EstimatedPurchaseEntityTypeCode && !e.Description)
            ).length > 0
        ) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.InvalidEstimatedBudgetRowsData);
            return def.reject().promise();
        }

        this.activityProvider
            .InsertOrUpdateActivity(this.GetData())
            .then((result: ResponseData<ITaskInsertOrUpdateResponse> | ITodoListTemplateTaskInsertResponse) => {
                if (result) {
                    if ((result as ResponseData<ITaskInsertOrUpdateResponse>).data?.task) {
                        const task = (result as ResponseData<ITaskInsertOrUpdateResponse>).data?.task;
                        const mappedTask = TodolistTaskMapper.toITodoListTask(task);
                        this.LoadFromModel(mappedTask, refreshAttachmentsDetailsData);
                    } else if ((result as ITodoListTemplateTaskInsertResponse).Task) {
                        const task = (result as ITodoListTemplateTaskInsertResponse).Task;
                        this.LoadFromModel(task, refreshAttachmentsDetailsData);
                    }
                }
                def.resolve(result);
            })
            .catch((err) => {
                console.error(err);
                def.reject([]);
            })
            .finally(() => {
                this.desktopService.UnblockPageUI();
            });

        return def.promise();
    }

    public SomeIsChanged(): boolean {
        if (!this.task) return true;

        let isChanged = false;

        isChanged = isChanged || (this.task.Title || "") != (this.Title() || "");
        isChanged = isChanged || (this.task.Description || "") != (this.Description() || "");
        isChanged = isChanged || this.task.Duration != this.Duration();
        isChanged = isChanged || this.task.InQuality != this.InQuality();
        isChanged = isChanged || this.task.Optional != this.Optional();
        isChanged = isChanged || this.task.HideFromSuggestions != this.HideFromSuggestions();
        isChanged = isChanged || (this.task.Priority || 0) != parseInt(this.Priority().toString());
        isChanged = isChanged || this.task.ReportingType != this.ReportingType();
        isChanged = isChanged || this.task.ActivitiesProgressAmountMode != this.ActivitiesProgressAmountMode();
        isChanged = isChanged || this.task.MultiplierUnitOfMeasure != this.MultiplierUnitOfMeasure();
        isChanged = isChanged || this.task.Multiplier != this.Multiplier();

        const currentResources: ITodoListResource[] = this.Resources.GetResources();
        isChanged = isChanged || this.task.Resources.length != currentResources.length;

        this.task.Resources.forEach((r: ITodoListResource) => {
            isChanged =
                isChanged ||
                currentResources.filter((r1: ITodoListResource) => {
                    return r.ElementId == r1.ElementId && r.ElementType == r1.ElementType;
                }).length == 0;
        });

        return isChanged;
    }

    public GetData() {
        const model = Object.assign({}, this.task) as ITodoListActivity;

        model.Title = this.Title();
        model.Description = this.Description();
        model.Duration = this.Duration();
        model.InQuality = this.InQuality();
        model.Optional = this.Optional();
        model.HideFromSuggestions = this.HideFromSuggestions();
        model.Priority = this.Priority();
        model.ReportingType = this.ReportingType();
        model.ActivitiesProgressAmountMode = this.ActivitiesProgressAmountMode();
        model.MultiplierUnitOfMeasure = this.MultiplierUnitOfMeasure();
        model.Multiplier = this.Multiplier();

        model.Resources = this.Resources.GetResources();

        this.CustomTagsManager.SetTagsOnCollection(model.Tags);
        this.ResponsibleTagsManager.SetTagsOnCollection(model.Tags);
        this.AttachmentsManager.SetTagsOnCollection(model.Tags);

        const securityGroups =
            (this.ForAdministration() ? "1" : "0") +
            (this.ForCommercial() ? "1" : "0") +
            (this.ForTechnical() ? "1" : "0");
        model.Tags.filter((t: ITodoListTaskTag) => {
            return t.Field == "SECURITYGROUPS";
        }).forEach((t1: ITodoListTaskTag) => {
            model.Tags.splice(model.Tags.indexOf(t1), 1);
        });
        model.Tags.push(<ITodoListTaskTag>{
            Type: ProlifeSdk.TagType_String,
            Field: "SECURITYGROUPS",
            Value: securityGroups,
        });

        model.WorkedHoursDefaultsStrictMode = this.WorkedHoursDefaultsStrictMode();
        model.HideAdministrativeFieldsOnWorkedHours = this.HideAdministrativeDataOnWorkedHours();
        model.DefaultWorkPlace = this.WorkedHoursDefaultPlace();
        model.DefaultTravelDistance = this.WorkedHoursDefaultTravelDistance();
        model.DefaultCallRight = this.DDC();

        return model;
    }

    public CancelChanges() {
        this.LoadFromModel(this.task, true);
        this.CustomTagsManager.CancelChanges();
        this.ResponsibleTagsManager.CancelChanges();
    }

    public SetActivitiesProgressAmountMode(value: number) {
        this.ActivitiesProgressAmountMode(value);
    }

    public AddNewPurchase(): void {}

    public SwitchAttachmentsActionsState(): void {
        this.ShowAttachmentsActions(!this.ShowAttachmentsActions());
    }

    public onAttachmentsDownload(): void {
        this.ShowAttachmentsActions(false);
    }

    public onAttachmentsRemoved(): void {
        this.ShowAttachmentsActions(false);
    }

    public onAttachmentsAdded(): void {
        this.ShowAttachmentsActions(false);
    }

    public onAttachmentsRemove(): void {
        this.ShowAttachmentsActions(false);
    }

    public onAttachmentsAdd(): void {
        this.ShowAttachmentsActions(false);
    }

    private createEstimatedBudgetDetails(estimatedBudgetRow: EstimateBudgetRow): IEstimatedBudgetDetails {
        const type: IEstimatedBudgetDetails = {
            Id: estimatedBudgetRow.EntityKeyId(),
            Description:
                estimatedBudgetRow.row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode
                    ? estimatedBudgetRow.RoleName()
                    : estimatedBudgetRow.PurchaseTypeTitle(),
            Cost: estimatedBudgetRow.Cost(),
            Price: estimatedBudgetRow.Price(),
            RealMarkup:
                (estimatedBudgetRow.Price() / (!estimatedBudgetRow.Cost() ? 1 : estimatedBudgetRow.Cost()) - 1) * 100,
            PriceWithoutDiscounts: estimatedBudgetRow.UnitPrice() * estimatedBudgetRow.Amount(),
            DiscountAvg:
                (1 -
                    estimatedBudgetRow.Price() /
                        (!(estimatedBudgetRow.UnitPrice() * estimatedBudgetRow.Amount())
                            ? 1
                            : estimatedBudgetRow.UnitPrice() * estimatedBudgetRow.Amount())) *
                100,
            MarkupAvg:
                ((estimatedBudgetRow.UnitPrice() * estimatedBudgetRow.Amount()) /
                    (!estimatedBudgetRow.Cost() ? 1 : estimatedBudgetRow.Cost()) -
                    1) *
                100,
        };

        return type;
    }

    private updateEstimatedBudgetDetails(details: IEstimatedBudgetDetails, budgetRow: EstimateBudgetRow): void {
        details.Cost += budgetRow.Cost();
        details.Price += budgetRow.Price();
        details.PriceWithoutDiscounts += budgetRow.UnitPrice() * budgetRow.Amount();

        details.RealMarkup = (details.Price / (!details.Cost ? 1 : details.Cost) - 1) * 100;
        details.MarkupAvg = (details.PriceWithoutDiscounts / (!details.Cost ? 1 : details.Cost) - 1) * 100;
        details.DiscountAvg =
            (1 - details.Price / (!details.PriceWithoutDiscounts ? 1 : details.PriceWithoutDiscounts)) * 100;
    }

    public AddEstimatedWork() {
        const newRow: IEstimateBudgetRow = this.createEstimatedBudgetRowModel(
            ProlifeSdk.EstimatedWorkEntityTypeCode,
            this.Multiplier()
        );
        this.EstimatedBudgetRows.push(
            new EstimateBudgetRow(newRow, this.JobOrdersTagsManager ? this.JobOrdersTagsManager.JobOrderId : null, this)
        );
    }

    public AddEstimatedArticle() {
        const newRow: IEstimateBudgetRow = this.createEstimatedBudgetRowModel(
            ProlifeSdk.WarehouseArticleEntityTypeCode,
            this.Multiplier()
        );
        this.EstimatedBudgetRows.push(
            new EstimateBudgetRow(newRow, this.JobOrdersTagsManager ? this.JobOrdersTagsManager.JobOrderId : null, this)
        );
    }

    public AddEstimatedPurchase() {
        const newRow: IEstimateBudgetRow = this.createEstimatedBudgetRowModel(
            ProlifeSdk.EstimatedPurchaseEntityTypeCode,
            this.Multiplier()
        );
        newRow.EntityKeyId = 1; // TODO rivedere
        this.EstimatedBudgetRows.push(
            new EstimateBudgetRow(newRow, this.JobOrdersTagsManager ? this.JobOrdersTagsManager.JobOrderId : null, this)
        );
    }

    public RemoveEstimatedBudgetRow(row: EstimateBudgetRow) {
        this.EstimatedBudgetRows.remove(row);

        if (this.EstimatedWorkRows().length == 0) this.Duration(0);
    }

    protected createEstimatedBudgetRowModel(typeCode: string, multiplier: number): IEstimateBudgetRow {
        return {
            Id: this.nextFakeId--,
            Description: "",
            UnitPrice: 0,
            Amount: 0,
            Price: 0,
            Type: typeCode,
            Multiplier: multiplier,
            Cost: 0,
            UnitCost: 0,
            Discount: 0,
            Markup: 0,
            Formula: null,
            NetUnitPrice: 0,
            DiscountValues: null,
            TaskId: this.Id,
            Order: this.EstimatedBudgetRows().length + 1,
        } as IEstimateBudgetRow;
    }
}

@DetectClassChanges
export class EstimateBudgetRow implements IDataSourceListener, IDetectChanges {
    @DetectChanges
    public Description: ko.Observable<string> = ko.observable();
    @DetectChanges
    public EntityKeyId: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Amount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public UnitCost: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Cost: ko.Observable<number> = ko.observable();
    @DetectChanges
    public UnitPrice: ko.Observable<number> = ko.observable();
    @DetectChanges
    public DiscountValues: ko.Observable<string> = ko.observable();
    @DetectChanges
    public NetUnitPrice: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Price: ko.Observable<number> = ko.observable();
    public Discount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public Markup: ko.Observable<number> = ko.observable();
    public Formula: ko.Observable<string> = ko.observable();
    @DetectChanges
    public IsComposite: ko.Observable<boolean> = ko.observable(false);
    public PurchaseTypeTitle: ko.Observable<string> = ko.observable();
    public Roles: IUserCharacter[] = [];
    public RoleName: ko.Computed<string>;
    public HasFocus: ko.Observable<boolean> = ko.observable();
    public Editable: ko.Observable<boolean> = ko.observable(false);
    public ReadOnly: ko.Observable<boolean> = ko.observable(true);
    public PriceAlert: ko.Observable<boolean> = ko.observable(false);
    @DetectChanges
    public Notes: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Order: ko.Observable<number> = ko.observable();
    @DetectChanges
    public FKState: ko.Observable<number> = ko.observable();

    public State: ko.Observable<IEstimatedBudgetForTaskState> = ko.observable();
    public ShowNotes: ko.Observable<boolean> = ko.observable(false);

    public NetUnitPriceAlert: ko.Computed<boolean>;
    public IsNew: ko.Observable<boolean> = ko.observable(false);

    public isChanged: ko.Observable<number> = ko.observable(0);

    public IsLastRow: ko.Computed<boolean>;

    public PurchaseTypesDataSource: PurchaseTypesDataSource;
    public PurchasesDataSource: PurchasesDataSource;
    public WarehouseArticlesDataSource: IDataSource<number, IArticle>;

    @Right("!Warehouse")
    private canViewWarehouse: boolean;
    @Right("JobOrders_EditTaskEstimate")
    private canEditEstimatedWork: boolean;
    @Right("TaskBoard_CanAddPurchaseEstimatedBudgetRows")
    private canEditEstimatedPurchases: boolean;
    @Right("TaskBoard_CanAddWarehouseEstimatedBudgetRows")
    private canEditEstimatedArticles: boolean;

    private CalculatedExpectedRevenue: ko.Computed<number>;

    private internalEditable = false;
    private initializing = false;
    private updatingPrices = false;

    private numberUtilities: NumbersUtilities;

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IArticlesService>())
    private articleService: IArticlesService;

    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService: IHumanResourcesService;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;

    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.WarehouseSettingsManager)
    private warehousesSettingsManager: IWarehouseSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypesSettingManager!: IPurchaseTypeSettingManager;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesManager: IUserCharactersSettingsManager;

    @LazyImportSettingManager(nameof<IEstimatedBudgetForTaskStatesSettingsManager>())
    private budgetStatesManager: IEstimatedBudgetForTaskStatesSettingsManager;

    constructor(
        public row: IEstimateBudgetRow,
        private jobOrderIdField: ko.Observable<number>,
        private activity: TodoListActivity,
        deferLoad = false
    ) {
        this.PurchaseTypesDataSource = new PurchaseTypesDataSource();
        this.PurchasesDataSource = new PurchasesDataSource();
        this.PurchasesDataSource.setFilter(true, false);
        this.WarehouseArticlesDataSource = new WarehouseArticlesDataSource();
        this.numberUtilities = new NumbersUtilities();

        this.Roles = this.rolesManager.getUserCharacters();

        this.UnitPrice.subscribe(this.OnUnitPriceChanged.bind(this));
        this.NetUnitPrice.subscribe(this.OnNetUnitPriceChanged.bind(this));
        this.DiscountValues.subscribe(this.OnDiscountValuesChanged.bind(this));
        this.Discount.subscribe(this.OnDiscountChanged.bind(this));
        this.UnitCost.subscribe(this.OnUnitCostChanged.bind(this));
        this.Markup.subscribe(this.OnMarkupChanged.bind(this));
        this.Amount.subscribe(this.OnAmountChanged.bind(this));
        this.EntityKeyId.subscribe(this.OnEntityChanged.bind(this));

        if (this.jobOrderIdField) this.jobOrderIdField.subscribe(this.SetCharacterPrice.bind(this));

        this.Editable.subscribe((value: boolean) => {
            if (!this.canEditRow()) {
                this.ReadOnly(true);
                return;
            }

            this.internalEditable = value;
            this.ReadOnly(!value);
        });

        this.Price.subscribe(() => {
            if (!this.initializing) this.PriceAlert(false);
        });

        this.RoleName = ko.computed(() => {
            const matches: any[] = this.Roles.filter((t: IUserCharacter) => {
                return t.IdUserCharacter == this.EntityKeyId();
            });
            return matches.length == 0 ? null : matches[0].Description;
        });

        this.IsLastRow = ko.computed(() => {
            return (
                this.activity.EstimatedWorkRows().indexOf(this) == this.activity.EstimatedWorkRows().length - 1 ||
                this.activity.EstimatedPurchasesRows().indexOf(this) ==
                    this.activity.EstimatedPurchasesRows().length - 1 ||
                this.activity.EstimatedArticlesRows().indexOf(this) == this.activity.EstimatedArticlesRows().length - 1
            );
        });

        this.CalculatedExpectedRevenue = ko.computed(() => {
            const unitCost = this.UnitCost() || 0;
            const netUnitPrice = this.NetUnitPrice() || 0;
            const amount = this.Amount() || 0;
            const markup = this.Markup() || 0;
            const discount = this.Discount() || 0;

            let expectedRevenue = !unitCost ? netUnitPrice * amount : unitCost * amount * (1 + markup / 100);
            const calculatedDiscount = expectedRevenue * discount;
            expectedRevenue = expectedRevenue - calculatedDiscount;
            return expectedRevenue;
        });

        this.NetUnitPriceAlert = ko
            .computed(() => {
                return (this.NetUnitPrice() || 0) < (this.UnitCost() || 0);
            })
            .extend({ rateLimit: { timeout: 100, method: "notifyWhenChangesStop" } });

        if (!deferLoad) this.Load();
    }

    private canEditRow(): boolean {
        if (!this.canEditEstimatedWork && this.row.Type === ProlifeSdk.EstimatedWorkEntityTypeCode) return false;

        if (!this.canEditEstimatedPurchases && this.row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode)
            return false;

        if (!this.canEditEstimatedArticles && this.row.Type === ProlifeSdk.WarehouseEntityTypeCode) return false;

        return true;
    }

    public dispose(): void {}

    public SwitchEditMode(): void {
        this.Editable(!this.Editable());
    }

    public async openStateSelectorPopover(item: any, event: Event): Promise<void> {
        if (this.ReadOnly()) return;

        const currentTarget = event.currentTarget as HTMLElement;
        const options: IPopoverComponentInfo = {
            componentName: "estimated-budget-for-task-state-popover",
            title: TextResources.Todolist.EstimatedBudgetForTaskStatePopover,
            params: "Value: Value, SelectedState: SelectedState",
            model: {
                Value: this.FKState,
                SelectedState: this.State,
            },
        };

        return this.dialogsService.ShowPopoverComponent(currentTarget, options, "bottom");
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (!model) {
            this.EntityKeyId(null);
            return;
        }

        if (sender === this.PurchasesDataSource) {
            const purchaseModel = model as IPurchasesDataSourceModel;
            this.Description(purchaseModel.model.Title);
            this.EntityKeyId(purchaseModel.model.PurchaseTypeId);
            this.UnitCost(purchaseModel.model.UnitPrice);
            this.UnitPrice(0);
        } else {
            if (sender === this.WarehouseArticlesDataSource) {
                const articleModel = model.model as IArticle;
                this.LoadArticleInfo(articleModel);
            } else {
                if (sender === this.PurchaseTypesDataSource) {
                    this.PurchaseTypeTitle(model.title);
                }
            }
        }
    }

    public async resetCostsAndPrices(): Promise<void> {
        if (this.row.Type == ProlifeSdk.EstimatedWorkEntityTypeCode) {
            await this.LoadUserCharacterCostsAndPrices();
            return;
        }

        if (this.row.Type == ProlifeSdk.WarehouseArticleEntityTypeCode) {
            const articleModel = await this.articleService.getArticleByCatalogRowId(this.EntityKeyId());
            await this.LoadArticleInfo(articleModel);
            return;
        }
    }

    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    public SomeIsChanged(): boolean {
        return this.isChanged() > 0;
    }

    public GetData(multiplier: number): IEstimateBudgetRow {
        const data: IEstimateBudgetRow = this.row;
        data.Description = this.Description();
        data.EntityKeyId = this.EntityKeyId();
        data.Amount = this.Amount();
        data.UnitPrice = this.UnitPrice();
        data.DiscountValues = this.DiscountValues();
        data.Discount = this.Discount();
        data.NetUnitPrice = this.NetUnitPrice();
        data.Price = this.Price();
        data.Multiplier = multiplier;
        data.Markup = this.Markup();
        data.Cost = this.Cost();
        data.UnitCost = this.UnitCost();
        data.Formula = this.Formula();
        data.FKState = this.FKState();
        data.Notes = this.Notes();
        data.Order = this.Order();
        return data;
    }

    private OnEntityChanged() {
        if (this.initializing) return;

        if (!this.EntityKeyId()) {
            this.UnitCost(0);
            this.Markup(0);
            this.Cost(0);

            this.UnitPrice(0);
            this.DiscountValues(null);
            this.Discount(0);
            this.NetUnitPrice(0);
            this.Price(0);

            this.PurchaseTypeTitle(null);
            return;
        }

        if (this.row.Type == ProlifeSdk.EstimatedWorkEntityTypeCode) this.LoadUserCharacterCostsAndPrices();
    }

    private OnDiscountChanged(discount: number): void {
        if (this.initializing) return;

        const safeDiscount = discount || 0;
        const safeUnitPrice = this.UnitPrice() || 0;

        const netUnitPrice = !safeDiscount ? safeUnitPrice : safeUnitPrice - safeUnitPrice * safeDiscount;
        this.NetUnitPrice(netUnitPrice);
    }

    private OnDiscountValuesChanged(discountValues: string): void {
        if (this.initializing) return;

        if (!discountValues) {
            this.Discount(0);
            return;
        }

        const discount = DiscountsUtilities.calculateDiscount(discountValues);
        this.Discount(!discount ? 0 : 1 - discount);
    }

    private OnNetUnitPriceChanged(netUnitPrice: number): void {
        if (this.initializing) return;

        this.updatingPrices = true;

        const safeNetUnitPrice = netUnitPrice || 0;
        const safeUnitPrice = this.UnitPrice() || 0;
        const amount = this.Amount() || 0;

        const discount = !safeUnitPrice ? 0 : 1 - safeNetUnitPrice / safeUnitPrice;
        const discountValue = numeral(discount).format("0.[########]%");
        const price = safeNetUnitPrice * amount;

        this.DiscountValues(discountValue);
        this.Price(price);

        this.updatingPrices = false;
    }

    private OnUnitPriceChanged(newUnitPrice: number): void {
        if (this.initializing) return;

        this.updatingPrices = true;

        const unitCost = this.UnitCost() || 0;
        const discount = this.Discount() || 0;

        const netUnitPrice = newUnitPrice * (!discount ? 1 : 1 - discount);
        const markup = !unitCost || !newUnitPrice ? 0 : (newUnitPrice / unitCost - 1) * 100;

        this.NetUnitPrice(netUnitPrice);
        this.Markup(markup);

        this.updatingPrices = false;
    }

    private OnUnitCostChanged(newUnitCost: number): void {
        if (this.initializing) return;

        const amount = this.Amount() || 0;
        const markup = this.Markup() || 0;

        const cost = amount * newUnitCost;
        const unitPrice = !newUnitCost ? this.UnitPrice() : newUnitCost + newUnitCost * (markup / 100);

        this.Cost(cost);
        this.UnitPrice(unitPrice);

        if (!newUnitCost) this.Markup(0);
    }

    private OnMarkupChanged(newMarkup: number): void {
        if (this.initializing || this.updatingPrices) return;

        const unitCost = this.UnitCost();

        if (!unitCost) {
            this.Markup(0);
            return;
        }

        const unitPrice = !newMarkup && !unitCost ? this.UnitPrice() : unitCost + unitCost * (newMarkup / 100);

        this.UnitPrice(unitPrice);
    }

    private OnAmountChanged(newAmount: number): void {
        if (this.initializing) return;

        const unitCost = this.UnitCost() || 0;
        const netUnitPrice = this.NetUnitPrice() || 0;

        const cost = unitCost * newAmount;
        const price = netUnitPrice * newAmount;

        this.Cost(cost);
        this.Price(price);
    }

    private async LoadArticleInfo(article: IArticle): Promise<void> {
        this.Description(article.Code + " " + article.Description);

        let jobOrderId = null;
        const referenceDate = moment().toDate();
        let customerId = null;
        let isCustomer = true;
        const articleId = article.ArticleId;
        const overrideGroupId = null;
        const warehouseId = this.warehousesSettingsManager.getDefaultWarehouse()?.Id;

        if (!warehouseId) {
            this.infoToastService.Error(TextResources.Todolist.MissingDefaultWarehouse);
            return;
        }

        if (this.jobOrderIdField) jobOrderId = this.jobOrderIdField();

        const promises: Promise<IArticleTransform | IJobOrder>[] = [];
        let results: (IArticleTransform | IJobOrder)[] = [];

        try {
            promises.push(this.articleService.GetArticleTransforms(article.ArticleId));
            if (!!jobOrderId && jobOrderId > 0) promises.push(this.jobOrderService.get(jobOrderId));

            results = await Promise.all(promises);
        } catch (e) {
            const exception = e as IException;

            this.infoToastService.Error(exception.ExceptionMessage);
            return;
        }

        const transform = results[0] as IArticleTransform;
        const jobOrder = results[1] as IJobOrder;

        this.IsComposite(transform != null);

        if (jobOrder) {
            customerId = jobOrder.CustomerId;
            isCustomer = true; //!!customerId && customerId > 0;
        }

        try {
            const costsAndPricesPromises: Promise<
                IResolvedCustomerDiscountCatalogArticleWithCatalog | IArticleCostAndPrice
            >[] = [];
            costsAndPricesPromises.push(
                this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
                    customerId: customerId,
                    isCustomer: isCustomer,
                    referenceDate: referenceDate,
                    warehouseId: warehouseId,
                    articleId: articleId,
                    overrideGroupId: overrideGroupId,
                })
            );

            costsAndPricesPromises.push(
                this.todoListService.GetArticleCostAndPriceForTask(jobOrderId, articleId, referenceDate)
            );

            const results = await Promise.all(costsAndPricesPromises);

            const discount = results[0] as IResolvedCustomerDiscountCatalogArticleWithCatalog;
            const articleCostAndPrice = results[1] as IArticleCostAndPrice;

            this.UnitCost(articleCostAndPrice.UnitCost);

            const finalPrice = discount.UnitPrice + (discount.TotalDiscount / 100) * discount.UnitPrice;

            if (DiscountCatalogRowMode.getDiscountSign(discount.Mode) > 0) {
                //E' un ricarico quindi non mostro il ricarico nel documento
                this.UnitPrice(finalPrice);
                this.DiscountValues(undefined);
                this.NetUnitPrice(finalPrice);
                this.Price(this.Amount() * finalPrice);
            } else {
                const discountString = (
                    (discount.Discount0 > 0 ? discount.Discount0 + "% " : "") +
                    (discount.Discount1 > 0 ? discount.Discount1 + "% " : "") +
                    (discount.Discount2 > 0 ? discount.Discount2 + "% " : "") +
                    (discount.Discount3 > 0 ? discount.Discount3 + "% " : "") +
                    (discount.Discount4 > 0 ? discount.Discount4 + "% " : "")
                ).trim();

                this.UnitPrice(discount.UnitPrice);
                this.DiscountValues(discountString);
                this.NetUnitPrice(finalPrice);
                this.Price(this.Amount() * finalPrice);
            }
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.GetArticleCostAndPriceError);
        }
    }

    private async LoadUserCharacterCostsAndPrices(): Promise<void> {
        const defs = [this.SetCharacterCost(), this.SetCharacterPrice()];
        this.initializing = true;

        try {
            await Promise.all(defs);
            this.CalculateCostAndPriceOnEntityChanged();
            this.initializing = false;
        } catch (e) {
            this.initializing = false;
        }
    }

    private async SetCharacterCost(): Promise<void> {
        const requestParams: IGetUserCharacterMeanCostRequest = {
            UserCharacterId: this.EntityKeyId(),
            referenceDate: moment().toDate(),
        };

        const response = await this.humanResourcesService.GetUserCharacterMeanCost(requestParams);
        this.UnitCost(response || 0);
    }

    private async SetCharacterPrice() {
        const rolesMatches: IUserCharacter[] = this.Roles.filter((r: IUserCharacter) => {
            return this.EntityKeyId() == r.IdUserCharacter;
        });
        this.UnitPrice(rolesMatches.length > 0 ? rolesMatches[0].Salary : 0);

        if (!this.jobOrderIdField || !this.jobOrderIdField()) {
            this.UnitPrice(rolesMatches.length > 0 ? rolesMatches[0].Salary : 0);
            return;
        }

        const j: IJobOrder = await this.jobOrderService.get(this.jobOrderIdField());

        const jobOrderMatches: IJobOrderRolesPrices[] = j.RolesPrices.filter((r: IJobOrderRolesPrices) => {
            return this.EntityKeyId() == r.FkUserCharacterId;
        });
        this.UnitPrice(
            jobOrderMatches.length > 0
                ? jobOrderMatches[0].Salary
                : rolesMatches.length > 0
                ? rolesMatches[0].Salary
                : 0
        );
    }

    private CalculateCostAndPriceOnEntityChanged() {
        const unitCost = this.UnitCost() || 0;
        const unitPrice = this.UnitPrice() || 0;
        const discount = this.Discount() || 0;
        const amount = this.Amount() || 0;

        const cost = amount * unitCost;
        const netUnitPrice = unitPrice - unitPrice * discount;
        const price = amount * netUnitPrice;
        const markup = !unitPrice || !unitCost ? 0 : (unitPrice / unitCost - 1) * 100;

        this.Cost(cost);
        this.NetUnitPrice(netUnitPrice);
        this.Price(price);
        this.Markup(markup);
    }

    public Load() {
        this.initializing = true;

        this.IsNew(!this.row.Id || this.row.Id <= 0);
        this.Editable(this.IsNew());

        this.EntityKeyId(this.row.EntityKeyId);
        this.Amount(this.row.Amount);

        this.Description(this.row.Description);
        this.UnitPrice(this.row.UnitPrice);
        this.DiscountValues(this.row.DiscountValues);
        this.Discount(this.row.Discount);
        this.NetUnitPrice(this.row.NetUnitPrice);
        this.Price(this.row.Price);

        this.Cost(this.row.Cost);
        this.UnitCost(this.row.UnitCost);
        this.Markup(this.row.Markup);

        this.Formula(this.row.Formula);

        this.FKState(this.row.FKState);
        this.Notes(this.row.Notes || ""); // Se undefined o null lo imposto a stringa vuota perché CKEditor lo imposta così e di conseguenza la riga risulta modificata
        this.Order(this.row.Order);

        if (this.row.Type === ProlifeSdk.EstimatedPurchaseEntityTypeCode) {
            const type = this.purchaseTypesSettingManager.getById(this.row.EntityKeyId);
            this.PurchaseTypeTitle(!type ? null : type.Title);
        }

        if (this.row.Type == ProlifeSdk.WarehouseArticleEntityTypeCode) {
            this.IsComposite(this.row.IsCompound);
        }

        if (
            this.numberUtilities.Round(this.Price(), 8) !==
            this.numberUtilities.Round(this.CalculatedExpectedRevenue(), 8)
        )
            this.PriceAlert(true);

        if (this.row.FKState) {
            const state = this.budgetStatesManager.getStateById(this.row.FKState);
            this.State(state);
        }

        this.isChanged(0);
        this.initializing = false;
    }
}
