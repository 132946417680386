import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextInput } from "../../TextInput";
import { ReportComponent, ReportComponentWithText } from "../Components";
import { EditorFor } from "./ReportEditorProvider";
import { SegmentedInput } from "../../SegmentedInput";
import jss from "jss";
import { DataSourceBindingDialog } from "./DataSourceEditor/DataSourceBindingDialog";

const styleSheet = jss.createStyleSheet({
    reportTextEditor: {
        '& .fa.fa-align-center.vertical-align': {
            display: 'inline-block',

            "&:before": {
                display: 'block',
                maxHeight: '7px',
                overflow: 'hidden',
                marginTop: '1px',
                marginBottom: '1px'
            },

            "&.top": {
                verticalAlign: 'top'
            },
            "&.middle": {
                verticalAlign: 'middle'
            },
            "&.bottom": {
                verticalAlign: 'bottom'
            }
        },

        "& .input-group": {
            display: 'flex',

            "& textarea": {
                minHeight: '34px'
            }
        }
    }
}).attach();
const { classes } = styleSheet;

type ReportTextEditorProps = {
    component: ReportComponentWithText & ReportComponent;
}

export function ReportTextEditor(props: ReportTextEditorProps) {
    const C = require("./ReportTextEditor")._ReportTextEditor as typeof _ReportTextEditor;
    return <C {...props} />;
}

@EditorFor("Text")
export class _ReportTextEditor {
    static defaultProps: Partial<ReportTextEditorProps> = {
    }

    constructor(private props : ReportTextEditorProps) {
        
    }

    private async openBindingEditor() {
        const result = await new DataSourceBindingDialog({
            type: 'string',
            component: this.props.component,
            binding: this.props.component.boundText,
            dataSources: this.props.component.getSection().dataSources()
        }).show();

        this.props.component.boundText.loadFrom(result);
    }
    
    render() {
        const { component } = this.props;
        let te = this;
        const classNames = ComponentUtils.classNames("editor-group", classes.reportTextEditor);

        return  ComponentUtils.bindTo(
                <div className={classNames}>
                    <span className="editor-group-title">Contenuto</span>
                    <div className="editor-group-body">
                        <div className="row">
                            <div className="col-md-6">
                                <SegmentedInput itemStyle={{ paddingTop: '4px' }} label="Orizzontale" value={component.textAlign} options={[{ icon: "fa fa-align-left", value: "left" }, { icon: "fa fa-align-center", value: "center" }, { icon: "fa fa-align-right", value: "right",}, { icon: "fa fa-align-justify", value: "justify" }]} />
                            </div>
                            <div className="col-md-6">
                                <SegmentedInput itemStyle={{ paddingTop: '2px', paddingBottom: '2px' }} label="Verticale" value={component.verticalAlign} options={[{ icon: "fa fa-align-center vertical-align top", value: "top" }, { icon: "fa fa-align-center vertical-align middle", value: "center" }, { icon: "fa fa-align-center vertical-align bottom", value: "bottom",}]} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextInput multiline selectOnFocus label="Testo" value={component.text} valueUpdate="afterkeydown" readonly={this.props.component.boundText.bound}>
                                    <button className="btn btn-primary btn-sm" onClick={() => this.openBindingEditor()} data-bind={{ css: { 'btn-primary': te.props.component.boundText.bound, 'btn-default': !te.props.component.boundText.bound() } }}>
                                        <i className="fa fa-link"/>
                                    </button>
                                </TextInput>
                            </div>
                        </div>
                    </div>
                </div>
        , this, "te");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportTextEditor);
}