import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { AgendaEditingDialog } from './dialogs/AgendaEditingDialog';
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IAgendasUIProvider, IDetailedAgenda } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";

export class AgendasUIProvider implements IAgendasUIProvider {
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
    }

    GetAgendasEditingDialog(folderId: number = null, agendaId: number = null): Promise<IDetailedAgenda> {
        var dialog = new AgendaEditingDialog(this.serviceLocator, agendaId, folderId);
        return this.dialogsService.ShowModal<IDetailedAgenda>(dialog, "fullscreen", null, "agenda/templates/dialogs", "agenda-dialog");
    }
}