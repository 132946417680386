import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 11:43
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { CampaignStateEditingViewModel } from "./ui/CampaignStateEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { ICampaignStateSettingManager } from "../../../../interfaces/ICampaignStateSettingManager";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ICampaignState, ICampaignLogicalState } from "../../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";

export class CampaignStateSettingManager implements ICampaignStateSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private campaignStates: ICampaignState[] = [];
    private campaignLogicalStates: ICampaignLogicalState[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);

        this.campaignLogicalStates.push({Id: 1, Description: ProlifeSdk.TextResources.Survey.DraftState});
        this.campaignLogicalStates.push({Id: 2, Description: ProlifeSdk.TextResources.Survey.PlannedState});
        this.campaignLogicalStates.push({Id: 3, Description: ProlifeSdk.TextResources.Survey.InProgressState});
        this.campaignLogicalStates.push({Id: 4, Description: ProlifeSdk.TextResources.Survey.OverState});
    }

    load(): Promise<ICampaignState[]> {
        return this.ajaxService.Post<ICampaignState[]>("Survey-api/CampaignApi", "GetCampaignStates", {})
            .then((states: ICampaignState[]) => {
                this.campaignStates = states;
                return states;
            });
    }

    getName(): string {
        return ProlifeSdk.CampaignState;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.CampaignStatus;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "campaign-state",
            templateUrl: "survey/templates/campaign/settings",
            viewModel: new CampaignStateEditingViewModel(this.serviceLocator, this)
        };
    }

    getCampaignLogicalState(): ICampaignLogicalState[] {
        return this.campaignLogicalStates;
    }

    getCampaignStates(): ICampaignState[] {
        return this.campaignStates;
    }

    getCampaignStateById(id: number): ICampaignState {
        var states: ICampaignState[] = this.campaignStates.filter((s: ICampaignState) => { s.Id == id });
        return states[0];
    }

    addOrUpdate(state: ICampaignState): Promise<ICampaignState> {
        return this.ajaxService.Post<ICampaignState>("Survey-api/CampaignApi", "AddOrUpdateCampaignState", {
            methodData: {
                State: state
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/CampaignApi", "RemoveCampaignState", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: ICampaignState): void {
        var found: boolean = false;

        this.campaignStates.forEach((s: ICampaignState) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Label = state.Label;
            s.StateId = state.StateId;

            found = true;
        });

        if (!found)
            this.campaignStates.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.campaignStates.length; i++) {
            if (this.campaignStates[i].Id == id) {
                this.campaignStates.splice(i, 1);
                break;
            }
        }
    }
}