import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/08/2017
 * Time: 18:47
 * To change this template use File | Settings | File Templates.
 */

import { SplashPage } from "./splash-page/SplashPage";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { CampaignViewModel } from "./campaigns/CampaignViewModel";
import { SurveyWizardViewModel } from "./wizard/SurveyWizardViewModel";
import { InboundWizard, WaitingForInboundWizard } from "./inbound/InboundWizard";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";

export class SurveyWizardPanelViewModel {
    public ShowedElement: any;

    public CurrentViewModel : ko.Observable<any> = ko.observable(null);
    public SplashPage : SplashPage;
    public Applications : ApplicationsMenu;

    constructor(private serviceLocator: IServiceLocator) {
        this.SplashPage = new SplashPage();
        this.Applications = new ApplicationsMenu();

        this.Applications.Add("Outbound", "fa-list-ul", this.OpenSplashPage.bind(this));
        this.Applications.Add("Inbound", "fa-phone", () => { location.href = "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/Inbound/Wait" });
    }

    initialize(): Promise<void>
    {
        return this.SplashPage.Initialize();
    }

    dispose() {

    }

    public OpenSplashPage()
    {
        if (this.ShowedElement)
            this.ShowedElement.dispose();

        this.CurrentViewModel(this.SplashPage);
        this.SplashPage.Refresh();
        this.Applications.ActivateApplication(0);
    }

    public OpenWaitingForInbound()
    {
        if (this.ShowedElement)
            this.ShowedElement.dispose();

        this.CurrentViewModel(new WaitingForInboundWizard(this.serviceLocator));
        this.SplashPage.Refresh();
        this.Applications.ActivateApplication(1);
    }

    public OpenInbound(callId : number, phoneNumber : string, calledPhoneNumber : string, ivrCode : string, questionnaireId : number)
    {
        if (this.ShowedElement)
            this.ShowedElement.dispose();

        this.CurrentViewModel(new InboundWizard(this.serviceLocator, callId, questionnaireId, phoneNumber, calledPhoneNumber, ivrCode));
        this.SplashPage.Refresh();
        this.Applications.ActivateApplication(1);
    }

    public OpenCampaign(campaignId: number) {
        if (this.ShowedElement)
            this.ShowedElement.dispose();

        this.ShowedElement = new CampaignViewModel(this.serviceLocator, campaignId);
        this.CurrentViewModel(this.ShowedElement);
    }

    public OpenWizard(campaignId: number, queueId: number, resume: string) {
        if (this.ShowedElement)
            this.ShowedElement.dispose();

        this.ShowedElement = new SurveyWizardViewModel(this.serviceLocator, queueId, resume, ProlifeSdk.WizardExecutionMode);
        this.CurrentViewModel(this.ShowedElement);
    }
}

export class ApplicationsMenu {
    public Applications : ko.ObservableArray<Application> = ko.observableArray();

    public Add(title : string, icon : string, action : () => void) {
        var app = new Application(title, icon, () => {
            this.Applications().forEach(a => a.Active(false));
            action();
        });

        this.Applications.push(app);
    }

    public ActivateApplication(index : number) {
        this.Applications().forEach(a => a.Active(false));
        this.Applications()[index].Active(true);
    }
}

class Application {
    public Title : ko.Observable<string> = ko.observable();
    public Icon : ko.Observable<string> = ko.observable();
    public Active : ko.Observable<boolean> = ko.observable();

    constructor(title : string, icon : string, private action : () => void) {
        this.Title(title);
        this.Icon(icon);
    }

    public Open() : void {
        this.action();
        this.Active(true);
    }
}