import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { MonthlyInvoicingDataWizard } from "./documents/wizard/MonthlyInvoicingDataWizard";
import { ElectronicInvoicingWizard } from "./documents/wizard/ElectronicInvoicingWizard";
import { MonthlyInvoicingLogDialog } from "./ui/dialogs/MonthlyInvoicingLogDialog";
import { TrustsAuthorizationsHistoryDialog } from "./ui/customerTrust/TrustsAuthorizationsHistoryDialog";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IDocumentsService } from "../DocumentsService";
import "./documents/DocumentsViewer";
import { DocumentsViewer } from "./documents/DocumentsViewer";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IInvoicesService } from "../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IMonthlyInvoicingImportData } from "../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IDocumentsProvider } from "../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IDocumentsServiceObserver } from "../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IEntityForMonthlyInvoicingTree } from "../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver";
import { IVatRegisters } from "../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import {
    IContextAction,
    INavBarAction,
    INavBarActionWithDropdown,
} from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { DocumentWithMetadataReportUI } from "./ui/dialogs/DocumentWithMetadatasReport";
import { ImportPassiveInvoicesDialogUI } from "./ui/dialogs/ImportPassiveInvoicesDialog";

export class InvoiceApplication implements IApplication, IDocumentsServiceObserver, IAuthorizationServiceObserver {
    public templateName = "documents";
    public templateUrl = "invoices/templates";
    public templateData: ko.Observable<any> = ko.observable();
    public tileColor = "tile-red";

    DocumentsViewer: ko.Observable<DocumentsViewer> = ko.observable();

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService: IApplicationsService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;

    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    public contextActions: ko.ObservableArray<IContextAction> = ko.observableArray([]);
    public navBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);

    private dependencies: string[] = [
        "invoices/ui/DocumentsViewer",
        "bindings/SlideUp",
        "bindings/ForEachWithZoom",
        "bindings/ContactValue",
        "bindings/CustomerValue",
        "bindings/RecipientValue",
        "bindings/HtmlValue",
        "bindings/DeleteOnEmpty",
        "bindings/FirstOnlyWithSlideInOut",
        "bindings/PercentagesValue",
        "bindings/SupplierValue",
        "bindings/PaymentModeValue",
        "bindings/ValidityTypeValue",
        "bindings/ExpireModeValue",
        "bindings/DocumentReferencesMapChart",
        "bindings/CustomerRecipientCodes",
    ];

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    private documentOperationRequested = false;
    private documentOperationCallback: () => void;

    constructor() {
        this.templateData(this);

        this.applicationsService.registerApplication(this);
        this.authorizationsService.addObserver(this);
        this.documentsService.RegisterObserver(this);

        this.OnDocumentsProviderAdded(null);

        this.DocumentsViewer.subscribe((dv) => {
            if (dv && this.documentOperationRequested) {
                this.documentOperationRequested = false;

                this.documentOperationCallback();
                this.documentOperationCallback = undefined;
            }
        });
    }

    OnDocumentsProviderAdded(provider: IDocumentsProvider) {
        const contextActions: IContextAction[] = [];
        let providers: IDocumentsProvider[] = this.documentsService.getRegisteredDocumentProviders();

        providers = providers.sort((provA: IDocumentsProvider, provB: IDocumentsProvider) => {
            const provAGroupLabel = (provA.GetDocumentGroupLabel() || "").trim().toLowerCase();
            const provBGroupLabel = (provB.GetDocumentGroupLabel() || "").trim().toLowerCase();

            if (provAGroupLabel < provBGroupLabel) return -1;

            if (provAGroupLabel > provBGroupLabel) return 1;

            if (provA.GetProviderPositionForMenu() < provB.GetProviderPositionForMenu()) return -1;

            if (provA.GetProviderPositionForMenu() > provB.GetProviderPositionForMenu()) return 1;

            return 0;
        });

        let lastGroup = "";
        providers.forEach((p: IDocumentsProvider) => {
            if (
                contextActions.length == 0 ||
                lastGroup.toLowerCase() != p.GetDocumentGroupLabel().trim().toLowerCase()
            ) {
                const enabledProviders: number = providers.filter(
                    (ep: IDocumentsProvider) =>
                        (ep.GetDocumentGroupLabel() || "").trim().toLowerCase() ==
                            (p.GetDocumentGroupLabel() || "").trim().toLowerCase() && ep.CanCreateNew()
                ).length;
                if (enabledProviders > 0) {
                    lastGroup = (p.GetDocumentGroupLabel() || "").trim();
                    contextActions.push({
                        Name: ko.observable(lastGroup),
                        CanRun: ko.observable(true),
                        Icon: ko.observable(""),
                        Run: null,
                    });
                }
            }

            if (p.CanCreateNew()) {
                const documentTypes = p.GetDocumentTypes();

                if (documentTypes.length > 0) {
                    const firstDocumentType = documentTypes[0].DocumentType;
                    if (firstDocumentType === "DOC" || firstDocumentType === "DOA" || firstDocumentType === "NDC") {
                        contextActions.push({
                            Name: ko.observable("📒 " + p.Name()),
                            CanRun: ko.observable(true),
                            Icon: ko.observable(""),
                            Run: null,
                        });

                        for (const documentType of documentTypes) {
                            contextActions.push({
                                Name: ko.computed(() => "📝 " + documentType.NewLabel),
                                CanRun: ko.observable(true),
                                Icon: ko.observable("margin-left-20"),
                                Run: () => this.newDocument(p.Id, documentType.DocumentType, null), //p.CreateNewDocument.bind(p, documentType.DocumentType)
                            });
                        }
                    } else {
                        contextActions.push({
                            Name: ko.observable("📝 " + p.Name()),
                            CanRun: ko.observable(true),
                            Icon: ko.observable("margin-left-20"),
                            Run: () => this.newDocument(p.Id, firstDocumentType, null),
                        });
                    }
                }
            }
        });

        this.contextActions(contextActions);
    }

    onGoingDown() {
        /*if(this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            //delete this.templateData();
            this.templateData(undefined);
        }*/
        this.DocumentsViewer(undefined);
    }

    getApplicationCode(): string {
        return "Documents";
    }

    getName(): string {
        return ProlifeSdk.TextResources.Invoices.AdministrativeDocuments;
    }

    getIcon(): string {
        return "icon-docs";
    }

    registerRoutes(): void {
        this.opaService.Get("#/" + TextResources.Invoices.DocumentsURL, (context) => this.start());
        this.opaService.Get(
            "#/" + String.format(TextResources.Invoices.OpenDocumentsURLv2, ":documentType", ":documentId"),
            (context: any) => this.openDocument(context.params.documentId, context.params.documentType)
        );
        this.opaService.Get(
            "#/" + String.format(TextResources.Invoices.OpenDocumentsByIdURLv2, ":documentId"),
            (context: any) => this.openDocument(context.params.documentId, null)
        );
        this.opaService.Get(
            "#/" + String.format(TextResources.Invoices.NewDocumentURLv2, ":documentType", ":registerId"),
            (context: any) => this.newDocument(context.params.registerId, context.params.documentType, null)
        );
        this.opaService.Get(
            "#/" +
                String.format(
                    TextResources.Invoices.NewDocumentJobOrderURLv2,
                    ":documentType",
                    ":registerId",
                    ":jobOrderId"
                ),
            (context: any) =>
                this.newDocument(context.params.registerId, context.params.documentType, context.params.jobOrderId)
        );
        /*this.opaService.BeforeLeave("#/" + ProlifeSdk.TextResources.Invoices.DocumentsURL, () => {
            var viewer = this.templateData();
            if(viewer) {
                var message = viewer.onBeforeUnload();
                if(message) {
                    return confirm(message + "\n\n" + ProlifeSdk.TextResources.Invoices.ContinueMsg);
                }
            }
            return true;
        })*/
    }

    getApplicationRoute(): string {
        return "#/" + TextResources.Invoices.DocumentsURL;
    }

    authorizationLoaded(rights: any): void {
        this.navBarActions([]);

        this.navBarActions.push({
            Name: TextResources.Invoices.ElectronicInvoicing,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_ElectronicInvoicing")),
            Icon: ko.observable("fa-file-text-o"),
            Run: this.openElectronicInvoicingWizard.bind(this),
        });

        this.navBarActions.push({
            Name: TextResources.Invoices.MonthlyInvoicingWizard,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_MonthlyInvoicing")),
            Icon: ko.observable("fa-files-o"),
            Run: this.openMonthlyInvoicingWizard.bind(this),
        });

        const actionsGroup: INavBarActionWithDropdown = {
            Name: TextResources.Invoices.LogsButtonLabel,
            CanRun: ko.observable(
                this.authorizationsService.isAuthorized("Documents_ViewInvoicingOperationsLog") ||
                    (this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled") &&
                        this.authorizationsService.isAuthorized("Documents_ViewTrustsAuthorizationsHistory"))
            ),
            Icon: ko.observable("fa-history"),
            Run: null,
            Actions: [],
        };

        actionsGroup.Actions.push({
            Name: TextResources.Invoices.InvoicingOperationsLog,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_ViewInvoicingOperationsLog")),
            Icon: ko.observable("fa-list-alt"),
            Run: this.openMonthlyInvoicingWizardLog.bind(this),
        });

        actionsGroup.Actions.push({
            Name: TextResources.Invoices.TrustsAuthorizationsHistory,
            CanRun: ko.observable(
                this.authorizationsService.isAuthorized("Customers_CustomerTrustEnabled") &&
                    this.authorizationsService.isAuthorized("Documents_ViewTrustsAuthorizationsHistory")
            ),
            Icon: ko.observable("fa-history"),
            Run: this.showTrustsAuthorizationsHistoryDialog.bind(this),
        });

        const reportsGroup: INavBarActionWithDropdown = {
            Name: TextResources.Invoices.ReportsButtonLabel,
            CanRun: ko.observable(
                this.authorizationsService.isAuthorized("Documents_ShowDocumentsWithMetadatasReport") ||
                    this.authorizationsService.isAuthorized("Documents_CanAddResourcesOnDocuments")
            ),
            Icon: ko.observable("fa-file-excel-o"),
            Run: null,
            Actions: [],
        };

        reportsGroup.Actions.push({
            Name: TextResources.Invoices.OpenDocumentsWithMetadatasReport,
            CanRun: ko.observable(
                this.authorizationsService.isAuthorized("Documents_ShowDocumentsWithMetadatasReport")
            ),
            Icon: ko.observable("fa-file-o"),
            Run: this.openDocumentsWithMetadatasReportDialog.bind(this),
        });

        reportsGroup.Actions.push({
            Name: TextResources.Invoices.OpenAllocatedResourcesOnDocumentsReport,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_CanAddResourcesOnDocuments")),
            Icon: ko.observable("fa-users"),
            Run: this.openAllocatedResourcesOnDocumentsReportDialog.bind(this),
        });

        reportsGroup.Actions.push({
            Name: TextResources.Invoices.OpenResourcesAssignmentsReport,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_CanAddResourcesOnDocuments")),
            Icon: ko.observable("fa-users"),
            Run: this.openResourcesAssignmentsReportDialog.bind(this),
        });

        this.navBarActions.push(reportsGroup);
        this.navBarActions.push(actionsGroup);

        this.navBarActions.push({
            Name: TextResources.Invoices.ImportPassiveInvoices,
            CanRun: ko.observable(this.authorizationsService.isAuthorized("Documents_ImportPassiveInvoices")),
            Icon: ko.observable("fa-cloud-upload"),
            Run: this.openImportPassiveInvoicesWizard.bind(this),
        });
    }

    private start() {
        //var viewer = this.templateData() || new DocumentsViewer();
        //this.templateData(viewer);
        this.applicationsService.startApplication(this);
    }

    private openDocument(documentId: number, documentType: string) {
        /*var viewer : DocumentsViewer = this.templateData() || new DocumentsViewer();
        viewer.openDocumentByProviderId(providerId, documentId);

        this.templateData(viewer);*/
        this.applicationsService.startApplication(this);
        if (!this.DocumentsViewer()) {
            this.documentOperationRequested = true;
            this.documentOperationCallback = () => this.DocumentsViewer().openDocumentById(documentId, documentType);
        } else {
            this.DocumentsViewer().openDocumentById(documentId, documentType);
        }
    }

    private newDocument(registerId: number, documentType: string, jobOrderId: number) {
        /*var viewer = this.templateData() || new DocumentsViewer();
        viewer.newDocument(providerId, jobOrderId);

        this.templateData(viewer);*/
        this.applicationsService.startApplication(this);
        if (!this.DocumentsViewer()) {
            this.documentOperationRequested = true;
            this.documentOperationCallback = () =>
                this.DocumentsViewer().newDocument(registerId, documentType, jobOrderId);
        } else {
            this.DocumentsViewer().newDocument(registerId, documentType, jobOrderId);
        }
    }

    private showTrustsAuthorizationsHistoryDialog(): void {
        new TrustsAuthorizationsHistoryDialog().show();
    }

    private openDocumentsWithMetadatasReportDialog(): void {
        const report = new DocumentWithMetadataReportUI();
        report.show();
    }

    private openAllocatedResourcesOnDocumentsReportDialog(): void {
        this.documentsService.ShowResourcesOnDocumentsAllocation();
    }

    private openResourcesAssignmentsReportDialog(): void {
        this.documentsService.ShowResourcesAssignmentsReport();
    }

    private openElectronicInvoicingWizard(): void {
        const wizard = new ElectronicInvoicingWizard();
        wizard.Show();
    }

    private openImportPassiveInvoicesWizard() {
        const ui = new ImportPassiveInvoicesDialogUI();
        ui.showDialog();
    }

    private openMonthlyInvoicingWizard(): void {
        const wizard = new MonthlyInvoicingDataWizard(<IWizardInitializationInfo>{}, ProlifeSdk.InvoiceEntityTypeCode);
        wizard.Show().then((importData: IMonthlyInvoicingImportData) => {
            if (!importData) {
                this.infoToastService.Error(ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardError);
                return;
            }

            this.generateMonthlyInvoicing(importData);
        });
    }

    private generateMonthlyInvoicing(monthlyInvoicingData: IMonthlyInvoicingImportData) {
        return this.invoicesService
            .GenerateMonthlyInvoicing(monthlyInvoicingData)
            .then((notGeneratedDocuments: IEntityForMonthlyInvoicingTree[]) => {
                if (notGeneratedDocuments.length == 0)
                    this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationSuccess);
                else
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationWarnings);

                /*if (this.templateData() && this.templateData().menu && this.templateData().menu.CurrentView())
                    this.templateData().menu.CurrentView().Reload();*/
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Invoices.MonthlyInvoicingGenerationError);
            });
    }

    private openMonthlyInvoicingWizardLog(): void {
        const log = new MonthlyInvoicingLogDialog();
        log.show();
    }
}
