import * as ko from "knockout";
import { IPreferredSupplier, IPreferredSuppliersService } from "../Warehouse/PreferredSuppliersService";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";

export interface IPreferredSuppliersDataSourceModel extends IDataSourceModel<number, IPreferredSupplier> {

}

export interface IPreferredSuppliersDataSource extends IDataSource {

}

export interface IPreferredSuppliersDataSourceFilter {
    ManufacturerId?: number;
    DiscountFamilyId?: number;
    StatisticFamilyId?: number;
    ArticleFilter?: string;
    SupplierId?: number;
}

export interface IPreferredSuppliersDataSourceObserver {
    onItemChanged() : void;
}

export class PreferredSuppliersDataSource implements IPreferredSuppliersDataSource {
    @LazyImport(nameof<IPreferredSuppliersService>())
    private preferredSuppliersService : IPreferredSuppliersService;

    private filter : IPreferredSuppliersDataSourceFilter = {};
    private view: IDataSourceView;
    private refreshRequested: boolean = false;
    selectRequested: boolean;
    selectRequestedModels: IPreferredSupplier[];

    private observers : IPreferredSuppliersDataSourceObserver[] = [];

    addObserver(observer : IPreferredSuppliersDataSourceObserver) {
        this.observers.push(observer);
    }

    setFilter(filter : IPreferredSuppliersDataSourceFilter) {
        this.filter = filter;
    }

    getTitle(currentModel: IPreferredSuppliersDataSourceModel): string {
        return "Fornitori Abituali";
    }    
    
    isGroupedData(currentModel: IPreferredSuppliersDataSourceModel, textFilter: string): boolean {
        return false;
    }
    
    areEqual(a: IPreferredSuppliersDataSourceModel, b: IPreferredSuppliersDataSourceModel): boolean {
        return (!!a && !!b && a.id == b.id);
    }
    
    async getData(currentModel: IPreferredSuppliersDataSourceModel, textFilter: string, skip: number, count: number): Promise<IPreferredSuppliersDataSourceModel[]> {
        let preferredSuppliers = await this.preferredSuppliersService.GetPreferredSuppliers({
            fkManufacturer: this.filter.ManufacturerId,
            fkDiscountFamily: this.filter.DiscountFamilyId,
            fkStatisticFamily: this.filter.StatisticFamilyId,
            articleFilter: this.filter.ArticleFilter,
            fkSupplier: this.filter.SupplierId,
            skip: skip,
            count: count
        });

        return preferredSuppliers.map(this.createModelFor, this);
    }
    
    async getById(currentModel: IPreferredSuppliersDataSourceModel, ids: number[]): Promise<IPreferredSuppliersDataSourceModel[]> {
        let preferredSuppliers = await this.preferredSuppliersService.GetPreferredSuppliersByIds(ids);
        return preferredSuppliers.map(this.createModelFor, this);
    }

    private createModelFor(preferredSupplier : IPreferredSupplier) : IPreferredSuppliersDataSourceModel {
        return {
            id: preferredSupplier.Id,
            title: "",
            isLeaf: true,
            isGroup: false,
            dragEnabled: true,
            model: preferredSupplier
        }
    }
    
    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.selectRequested) {
            this.selectRequested = false;
            let models = this.selectRequestedModels;
            this.selectRequestedModels = [];
            this.select(...models);
        }

        if(this.refreshRequested) {
            this.refreshRequested = false;
            this.refresh();
        }
    }

    refresh() {
        if(!this.view) {
            this.refreshRequested = true;
            return;
        }

        this.view.refresh(true);
    }

    async select(...models : IPreferredSupplier[]) : Promise<void> {
        if(!this.view) {
            this.selectRequested = true;
            this.selectRequestedModels = models;
            return;
        }

        let dataModels = await this.getById(null, models.map(m => m.Id));
        this.view.select(...dataModels);
    }
    
    getSupportedDropMimeTypes(): string[] {
        return ["application/prolife-preferred-supplier"];
    }
    
    onItemBeginMove(model: IPreferredSuppliersDataSourceModel, dataTransfer: DataTransfer) {
        let draggedPreferredSupplier = {
            Id: model.id
        };

        dataTransfer.setData("application/prolife-preferred-supplier", JSON.stringify(draggedPreferredSupplier));
    }
    
    async onItemMoved(dataTransfer: DataTransfer, model: IPreferredSuppliersDataSourceModel, before: boolean) : Promise<void> {
        if(dataTransfer.types.indexOf("application/prolife-preferred-supplier") != -1) {
            let draggedPreferredSupplier = JSON.parse(dataTransfer.getData("application/prolife-preferred-supplier"));

            await this.preferredSuppliersService.MovePreferredSupplier(draggedPreferredSupplier.Id, model.id, before);

            for(let observer of this.observers)
                    observer.onItemChanged();

            this.refresh();
        }
    }    
}