import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: n.angeli
 * Date: 19/02/13
 * Time: 15.02
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IJobOrderState } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderStateSettingsManager } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderLogicalState } from "../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderLogicalState";

export interface IJobOrderStateProvider {
	getData() : IJobOrderState;
	update(joborderState : IJobOrderState) : void;
}

export class JobOrderStateEditingViewModel{
	title : string;
	elements : ko.ObservableArray<IJobOrderStateProvider> = ko.observableArray();
	newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
	newSettingName : ko.Observable<string> = ko.observable();

	constructor(private serviceLocator : IServiceLocator, private jobOrderStateManager : IJobOrderStateSettingsManager) {
		this.title = jobOrderStateManager.getLabel();

		this.reloadElements();
	}

    private reloadElements() {
        this.elements([]);
        this.jobOrderStateManager.load();
        var joborderState = this.jobOrderStateManager.getJobOrderStates();
        joborderState.forEach(this.createViewModelFor.bind(this));
    }

	private createViewModelFor(joborderState : IJobOrderState) {
		this.elements.push(new JobOrderStateViewModel(this, joborderState, this.jobOrderStateManager.getJobOrderLogicalState()));
	}

    public createNew()
    {
        this.jobOrderStateManager.createOrUpdate({
            Description: '',
            LogicalState: 0,
            Position: -1,
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF"
        }).then((state : IJobOrderState) => {
            this.createViewModelFor(state);
        });
    }

	public addNewSetting() {
		var jobOrderStateViewModel = new JobOrderStateViewModel(this, {
			Description: this.newSettingName(),
			LogicalState: 0,
            Position: 0,
            Icon: "icon-action-redo",
            Background: "#000000",
            Foreground: "#FFFFFF"
		}, this.jobOrderStateManager.getJobOrderLogicalState());
		this.elements.push(jobOrderStateViewModel);
		this.createOrUpdateEntry(jobOrderStateViewModel);

		this.newSettingName("");
	}

	public setIsSelectedNewSetting() {
		this.newSettingHasFocus(true);
	}

	public createOrUpdateEntry(element : IJobOrderStateProvider) {
		var joborderState = element.getData();
		this.jobOrderStateManager.createOrUpdate(joborderState)
			.then((updatedjoborderState) => element.update(updatedjoborderState));
	}

	public deleteEntry(element : IJobOrderStateProvider) {
		this.elements.remove(element);
		var joborderState = element.getData();
		this.jobOrderStateManager.remove(joborderState.IdJobOrderStateId);
	}

    public OnStateMoved(beforeId, movedId, afterId)
    {
        this.jobOrderStateManager.moveState(movedId, beforeId, afterId).then(() => {
            this.reloadElements();
        });
    }
}


class JobOrderStateViewModel implements IJobOrderStateProvider {
    Id: ko.Observable<number> = ko.observable();
	Description : ko.Observable<string> = ko.observable();
	LogicalState : ko.Observable<number> = ko.observable();
    Icon : ko.Observable<string> = ko.observable();
    Background : ko.Observable<string> = ko.observable();
    Foreground : ko.Observable<string> = ko.observable();
	StatoLogicoDescrizione : ko.Observable<string> = ko.observable();
	hasFocus : ko.Observable<boolean> = ko.observable(false);
	private updating : boolean = false;

	constructor(private container : JobOrderStateEditingViewModel, private jobOrderState : IJobOrderState, private joborderLogicalState : IJobOrderLogicalState[]) {
        this.Id(jobOrderState.IdJobOrderStateId);
		this.Description(jobOrderState.Description);
		this.LogicalState(jobOrderState.LogicalState);
        this.Icon(jobOrderState.Icon);
        this.Background(jobOrderState.Background);
        this.Foreground(jobOrderState.Foreground);

		this.Description.subscribe(this.onNameChanged.bind(this));
		this.LogicalState.subscribe(this.onDataChanged.bind(this));
        this.Icon.subscribe(this.onDataChanged.bind(this));
        this.Background.subscribe(this.onDataChanged.bind(this));
        this.Foreground.subscribe(this.onDataChanged.bind(this));
	}

	private onNameChanged(newValue : string) {
		/*if(!newValue) {
			this.container.deleteEntry(this);
		} else {*/
			this.container.createOrUpdateEntry(this);
		/*}*/
	}

	getData() : IJobOrderState {
		var jobOrderState : IJobOrderState = <IJobOrderState> $.extend({}, this.jobOrderState);
		jobOrderState.Description = this.Description();
		jobOrderState.LogicalState = this.LogicalState();
        jobOrderState.Icon = this.Icon();
        jobOrderState.Background = this.Background();
        jobOrderState.Foreground = this.Foreground();
		return jobOrderState;

	}

	setLogicalState(state : IJobOrderLogicalState) : void{
		this.LogicalState(state.IdJobOrderLogicalState);
	}


	private onDataChanged(newValue : string) {
		if(this.updating) return;
		this.container.createOrUpdateEntry(this);
	}

	update(jobOrderState : IJobOrderState) : void {
		this.updating = true;
		this.jobOrderState = jobOrderState;
		this.Description(jobOrderState.Description);
		this.LogicalState(jobOrderState.LogicalState);
        this.Icon(jobOrderState.Icon);
        this.Background(jobOrderState.Background);
        this.Foreground(jobOrderState.Foreground);

		this.updating = false;
	}
}