import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 01/09/2017
 * Time: 09:33
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ISurveyWizardService } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IMotivation } from "../../../../../ProlifeSdk/interfaces/survey-wizard/IMotivation";
import { ICallInQueueData, ICallResultData } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";

export class FinishInterviewDialog implements IDialog {
    public templateName: string = "finish-interview-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs/";
    public title: string = ProlifeSdk.TextResources.SurveyWizard.FinishInterviewTitle;
    public modal: { close: (result?: any) => void; };

    public Motivations: ko.ObservableArray<IMotivation> = ko.observableArray([]);
    public SelectedMotivation: ko.Observable<number> = ko.observable();

    @LazyImport(nameof<ISurveyWizardService>())
    private surveyWizardService: ISurveyWizardService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private call: ICallInQueueData, private actualSelectedJobOrderId: number) {
        this.surveyWizardService.getMotivations(ProlifeSdk.ClosingMotivationType)
            .then((motivations: IMotivation[]) => {
                this.Motivations(motivations);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.SurveyWizard.GetMotivationsError);
            });

    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if (!this.SelectedMotivation()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.MissingRequiredData);
            return;
        }

        var resultCall: ICallResultData = {
            Id: null,
            CallsQueueId: this.call.Id,
            CampaignId: this.call.CampaignId,
            PeopleId: this.call.PeopleId,
            AttemptNumber: this.call.AttemptNumber,
            OperatorId: null,
            CallDate: null,
            ResultId: this.SelectedMotivation(),
            JobOrderId: this.actualSelectedJobOrderId
        }

        this.surveyWizardService.saveCallState(null, true, resultCall)
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.SurveyWizard.FinishCallSuccess);
                this.modal.close(true);
            })
            .catch(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.SurveyWizard.FinishCallError);
            });
    }
}