import * as ko from "knockout";
import { IProLifeNumbersUtilities } from "../../interfaces/prolife-sdk/IProLifeUtilitiesLibrary";

export class NumbersUtilities implements IProLifeNumbersUtilities
{
    public Round(value : number, numberOfDecimals : number) : number
    {
        if(numberOfDecimals == null || numberOfDecimals == undefined || value == null || value == undefined)
            return value;

        var result : number = value;
        var multiplier = 1;

        for(var i = 0; i< numberOfDecimals; i++)
        {
            multiplier = multiplier * 10;
        }

        return Math.round(result * multiplier) / multiplier;
    }

    public ZeroPad(str : string, digits : number) : string
    {
        return str.length < digits ? this.ZeroPad("0" + str, digits) : str;
    }

}
