import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { AdvancedFiltersDialog, IAdvancedFiltersEditingResult } from "../../../Todolist/Todolist/ui/navigation/joborder-navigator/AdvancedFiltersDialog";
import { IAdvancedFilterEditor, IAdvancedFilterProvider, IAdvancedFilterProviderObserver } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IJobOrdersMenuAdvancedFilters } from "../../../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IApplicationConfiguration, IApplicationsConfigurationsService } from "../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";

export class AdvancedFiltersManager implements IAdvancedFilterEditor, IAdvancedFilterProvider {
    private currentConfiguration: IJobOrdersMenuAdvancedFilters;
    private initialConfigurationJson: IApplicationConfiguration;
    private observers: IAdvancedFilterProviderObserver[];

    @LazyImport(nameof<IModulesService>())
    private modulesService!: IModulesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;
    @LazyImport(nameof<IApplicationsConfigurationsService>())
    private applicationsConfigurationsService!: IApplicationsConfigurationsService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;
    
    private showActivitiesMustBeAllocatedFlag: boolean = true;

    constructor(private applicationCode: string, private configurationType: number) {
        this.observers = [];
    }

    public async initialize(): Promise<void> {
        try {
            let moduleId: number = await this.modulesService.getModuleId(this.applicationCode);
            if (!moduleId) {
                return;
            }

            let filtersConfig: IApplicationConfiguration[] = await this.applicationsConfigurationsService.GetApplicationConfiguration(this.configurationType, this.userInfo.getIdUser(), moduleId, null);
            let config = filtersConfig && filtersConfig.length > 0 ? filtersConfig[0] : null;
            this.initialConfigurationJson = config;
            this.currentConfiguration = this.createAdvancedFiltersObject(config);
        } catch(e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.GetJobOrderNavigatorAdvancedFiltersError);
        }
    }

    public async editFilters(): Promise<IJobOrdersMenuAdvancedFilters> {
        let advFiltersDialog = new AdvancedFiltersDialog({ filters: this.currentConfiguration, currentFiltersConfig: this.initialConfigurationJson, options: { showActivitiesMustBeAllocatedFlag: this.showActivitiesMustBeAllocatedFlag }, applicationCode: this.applicationCode });
        let newConfig: IAdvancedFiltersEditingResult = await advFiltersDialog.show();

        if (!newConfig)
            return this.currentConfiguration;

        this.currentConfiguration = newConfig.SelectedFilters;
        this.initialConfigurationJson = newConfig.FiltersConfig;

        this.observers.forEach(o => o.onFilterChanges());

        return this.currentConfiguration;
    }

    public getCurrentFilters(): IJobOrdersMenuAdvancedFilters {
        return this.currentConfiguration;
    }

    public registerObserver(observer: IAdvancedFilterProviderObserver): void {
        if (this.observers.indexOf(observer) < 0)
            this.observers.push(observer);
    }

    public unregisterObserver(observer: IAdvancedFilterProviderObserver): void {
        let i = this.observers.indexOf(observer);

        if (i >= 0)
            this.observers.splice(i, 1);
    }

    public setShowActivitiesMustBeAllocatedFlag(value: boolean): void {
        this.showActivitiesMustBeAllocatedFlag = value;
    }

    private createAdvancedFiltersObject(filtersConfig: IApplicationConfiguration): IJobOrdersMenuAdvancedFilters {
        if (!filtersConfig || !filtersConfig.Configuration) {
            return <IJobOrdersMenuAdvancedFilters> {
                SelectedJobOrdersStates: [],
                SelectedJobOrdersTypes: [],
                SelectedWorkflowTypes: [],
                SelectedOpUnits: [],
                OnlyActivitiesWithRequiredAllocation: false
            };
        }
        var filters: IJobOrdersMenuAdvancedFilters = JSON.parse(filtersConfig.Configuration);
        filters.SelectedOpUnits = [];
        return filters;
    }
}