import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { Application } from "../../Components/Application";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { ArrayDataSource } from "../../DataSources/ArrayDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../DataSources/IDataSource";
import { Template } from "../../Bindings/ForceBinding";
import { QuotationCategoriesEditor } from "./QuotationCategories";
import { QuotationInfoEditor } from "./QuotationDetails";
import { IQuotationForList } from "../QuotationService";
import { ReportDesigner } from "../../Components/ReportDesigner";
import { QuotationBillOfMaterials } from "./QuotationBillOfMaterials";
import { IDataSourceInput } from "../../Desktop/DataSourcesService";
import { Select } from "../../Components/Select";
import { JobOrdersDataSource } from "../../DataSources/JobOrdersDataSource";
import { SelectMultiple } from "../../Components/SelectMultiple";

interface IQuotationItem {
    Id: number;
    Name: string;
    SerialNumber: string;
    Description: string;
    Notes: string;

    EntityType: string;
    EntityKeyId: number;

    Quantity: number;
    UnitPrice: number;
    Margin: number;
    Price: number;
    TotalPrice: number;
}

interface IQuotationSection {
    Id: number;
    Name: string;
    Description: string;

    Items: IQuotationItem[];
}

interface IQuotation {
    Id: number;
    Name: string;
    Description: string;

    Sections: IQuotationSection[];
}

export type QuotationForList = Omit<IQuotationForList, "FKJobOrder"> & { FKJobOrder: ko.Observable<number> };

type QuotationEditorProps = {
    quotation: ko.Observable<QuotationForList> | ko.Computed<QuotationForList>;
    customerId: number;
    onClose: () => void;
}

const reportInputParameters : IDataSourceInput[] = [{
    Name: "EstimateId",
    Description: "Id Preventivo",
    EntityType: "EST",
    DataType: "Int32Key"
}]

export class QuotationEditor implements IDataSourceListener {
    navigationDataSource = new ArrayDataSource();
    jobOrdersDataSource = new JobOrdersDataSource();
    selectedPage: ko.Observable<() => React.ReactNode> = ko.observable();
    
    constructor(private props : QuotationEditorProps) {
        this.jobOrdersDataSource.setUserId(15);
        this.jobOrdersDataSource.setViewFilters(true, true, true);

        this.navigationDataSource.setData(
            { id: 1, title: "Generali", isLeaf: true, isGroup: false, model: () => <div style={{ width: '900px' }}>
                    <SelectMultiple dataSource={this.jobOrdersDataSource} value={ko.observableArray()} allowClear placeholder="Seleziona una o più commesse" />
                    <Select dataSource={this.jobOrdersDataSource} value={ko.observable()} allowClear placeholder="Seleziona una commessa" />
                </div> },
            { id: 2, title: "Categorie", isLeaf: true, isGroup: false, model: () => <QuotationCategoriesEditor onClose={this.props.onClose} quotationId={() => this.props.quotation()?.Id} />},
            { id: 3, title: "Dettagli", isLeaf: true, isGroup: false, model: () => <QuotationInfoEditor quotation={this.props.quotation} onClose={() => this.props.onClose()} />},
            { id: 5, title: "Distinte Base", isLeaf: true, isGroup: false, model: () => <QuotationBillOfMaterials quotationId={() => this.props.quotation()?.Id} customerId={this.props.customerId} jobOrderId={this.props.quotation()?.FKJobOrder} onClose={() => this.props.onClose()} /> },
            { id: 4, title: "Anteprima", isLeaf: true, isGroup: false, model: () => <ReportDesigner inputDefinition={reportInputParameters} style={{ margin: '-25px -20px -10px -20px' }} canImport canSaveToFile />},
        );

        this.navigationDataSource.selectByIds(3);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(sender === this.navigationDataSource) {
            this.selectedPage(model.model);
        }
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        
    }

    render() {
        return ComponentUtils.bindTo(
            <Application>
                <Application.LeftMenu>
                    <NavigationMenu allowSearch={false} dataSource={this.navigationDataSource} listener={this} />
                </Application.LeftMenu>
                <Application.Content noGutters>
                    <Template component={this.selectedPage} />
                </Application.Content>
            </Application>
        , this, 'qe');
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationEditor);
}