import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import jss from "jss"
import { TreeNodeProps, TreeView } from "../TreeView";
import { _ReportDesigner } from "./ReportDesigner";
import { ReportComponent, ReportPage } from "./Components";
import { CanHaveChildren, ReportComponentWithChildren } from "./Components/ReportComponentWithChildren";
import { Layout } from "../Layouts";
import { ReportSection } from "./Components/ReportSection";
import { ReportComponentProvider } from "./Components/ReportComponentProvider";

const styleSheet = jss.createStyleSheet({
    reportDesignerOutline: {
        borderTop: '1px solid #ddd',
        borderLeft: '1px solid #ddd',
        margin: 0,
        width: '250px',

        "& .toolbar": {
            backgroundColor: "#eee",
            borderBottom: '1px solid #ddd',
            paddingLeft: '5px',
            paddingRight: '5px'
        },

        "& .document-outline": {
            
            padding: 5,
        }
    }
});
const { classes } = styleSheet.attach();

type ReportDesignerOutlineProps = {
    reportDesigner: _ReportDesigner;
}

export function ReportDesignerOutline(props: ReportDesignerOutlineProps) {
    const C = require("./ReportDesignerOutline")._ReportDesignerOutline as typeof _ReportDesignerOutline;
    return <C {...props} />;
}

export class _ReportDesignerOutline {
    Roots : ko.ObservableArray<TreeNodeProps> = ko.observableArray();
    ReportNode : TreeNodeProps;

    static defaultProps: Partial<ReportDesignerOutlineProps> = {
    }

    canMoveUp : ko.Computed<boolean>;
    canMoveDown : ko.Computed<boolean>;

    constructor(private props : ReportDesignerOutlineProps) {
        this.ReportNode = {
            name: "Report 1",
            onSelect: () => {},
            icon: "fa fa-book",
            background: "transparent",
            foreground: "black",
            onChildrenNeeded: () => this.getSectionNodes(),
            dependencies: [this.props.reportDesigner.sections]
        };
        this.Roots([this.ReportNode]);

        this.canMoveUp = ko.computed(() => {
            const component = this.props.reportDesigner.selectedComponent();
            if(!component) return false;
            if(!component.hasFeature("Position")) return false;
            if(!component.parent()?.hasFeature("Children")) return false;
            return component.parent()?.children.indexOf(component) > 0;
        });

        this.canMoveDown = ko.computed(() => {
            const component = this.props.reportDesigner.selectedComponent();
            if(!component) return false;
            if(!component.hasFeature("Position")) return false;
            if(!component.parent()?.hasFeature("Children")) return false;
            return component.parent()?.children.indexOf(component) < (component.parent().children().length - 1);
        });
    }

    private boundOnKeyUp = this.onKeyUp.bind(this);
    private boundOnCopy = this.onCopy.bind(this);
    private boundOnPaste = this.onPaste.bind(this);
    private boundOnCut = this.onCut.bind(this);

    componentDidMount() {
        console.log("ReportDesignerOutline events attached");
        document.addEventListener("keyup", this.boundOnKeyUp);
        document.addEventListener("copy", this.boundOnCopy);
        document.addEventListener("paste", this.boundOnPaste);
        document.addEventListener("cut", this.boundOnCut);
    }

    componentWillUnmount() {
        console.log("ReportDesignerOutline events detached");
        document.removeEventListener("keyup", this.boundOnKeyUp);
        document.removeEventListener("copy", this.boundOnCopy);
        document.removeEventListener("paste", this.boundOnPaste);
        document.removeEventListener("cut", this.boundOnCut);
    }

    private onKeyUp(e: KeyboardEvent) {
        const inTree = $(document.activeElement).hasClass("jstree-anchor");
        if(e.code === "Delete" && inTree) {
            const component = this.props.reportDesigner.selectedComponent();
            if(!component || component instanceof ReportPage) 
                return;
            component.parent().children.remove(component);
            this.props.reportDesigner.selectedComponent(undefined);
        }
    }

    private onCopy(e: Event) {
        const inTree = $(document.activeElement).hasClass("jstree-anchor");
        if(!inTree) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if(!component || component instanceof ReportPage) 
            return;

        this.props.reportDesigner.setClipboard(JSON.stringify(component.getData()));
    }

    private onCut(e: Event) {
        const inTree = $(document.activeElement).hasClass("jstree-anchor");
        if(!inTree) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if(!component || component instanceof ReportPage) 
            return;

        this.props.reportDesigner.setClipboard(JSON.stringify(component.getData()));

        component.parent().children.remove(component);
        this.props.reportDesigner.selectedComponent(undefined);
    }

    private onPaste(e: Event) {
        const inTree = $(document.activeElement).hasClass("jstree-anchor");
        if(!inTree) return;

        e.preventDefault();
        e.stopPropagation();

        const component = this.props.reportDesigner.selectedComponent();
        if(!component || component.features.indexOf("Children") === -1) 
            return;

        const copied = this.props.reportDesigner.getClipboard();
        const parsed = JSON.parseWithDate(copied);

        const pastedComponent = ReportComponentProvider.createComponent(parsed);

        const componentWithChildren = component as ReportComponentWithChildren & ReportComponent;
        componentWithChildren.children.push(pastedComponent);
    }

    private async getSectionNodes(): Promise<TreeNodeProps[]> {
        const sections = this.props.reportDesigner.sections();
        return sections.map(s => this.getSectionNode(s))
    }

    private getSectionNode(s: ReportSection): TreeNodeProps {
        return {
            name: s.name,
            onSelect: () => this.props.reportDesigner.selectedComponent(s),
            icon: "fa fa-columns",
            background: "transparent",
            foreground: "black",
            selected: () => this.props.reportDesigner.selectedComponent() === s,
            onChildrenNeeded: () => this.getPageNodes(s),
            dependencies: [s.name, s.children]
        }
    }

    private async getPageNodes(s: ReportSection) : Promise<TreeNodeProps[]> {
        const headerNode = this.getComponentNode(s.header);
        const footerNode = this.getComponentNode(s.footer);
        const pages = (s.children() as ReportPage[]).map(p => this.getPageNode(p));
        return [headerNode, footerNode, ...pages];
    }

    private getPageNode(p: ReportPage): TreeNodeProps {
        return {
            name: p.name,
            onSelect: () => this.props.reportDesigner.selectedComponent(p),
            icon: "fa fa-file-o",
            background: "transparent",
            foreground: "black",
            selected: () => this.props.reportDesigner.selectedComponent() === p,
            onChildrenNeeded: () => this.getComponentChildren(p),
            dependencies: [p.name, p.children]
        }
    }

    private async getComponentChildren(p: ReportComponentWithChildren): Promise<TreeNodeProps[]> {
        const children = p.children();
        return children.map(c => this.getComponentNode(c));
    }

    getComponentNode(c: ReportComponent): TreeNodeProps {
        if(CanHaveChildren(c)) {
            return {
                name: c.name,
                onSelect: () => this.props.reportDesigner.selectedComponent(c),
                icon: "fa fa-cube",
                background: "transparent",
                foreground: "black",
                selected: () => this.props.reportDesigner.selectedComponent() === c,
                onChildrenNeeded: () => this.getComponentChildren(c),
                dependencies: [c.name, c.children]
            }
        } else {
            return {
                name: c.name,
                onSelect: () => this.props.reportDesigner.selectedComponent(c),
                icon: "fa fa-cube",
                background: "transparent",
                foreground: "black",
                selected: () => this.props.reportDesigner.selectedComponent() === c,
                children: [],
                dependencies: [c.name]
            }
        }
    }

    private addPage() {
        const selectedComponent = this.props.reportDesigner.selectedComponent();
        if(selectedComponent) {
            const section = selectedComponent.getSection();
            const allNames =  this.props.reportDesigner.getChildrenNames();
            const newName = ReportComponent.findNextName(allNames, "Pagina");

            section.children.push(new ReportPage({ 
                id: -1, 
                name: newName,
                type: nameof<ReportPage>(), 
                x: 0, 
                y: 0, 
                width: 21, 
                height: 29.7
            }))
        }
    }

    private addSection() {
        const allNames =  this.props.reportDesigner.getChildrenNames();
        const newNameSection = ReportComponent.findNextName(allNames, "Sezione");
        const newNamePage = ReportComponent.findNextName(allNames, "Pagina");

        const newSection = new ReportSection({
            id: -1,
            name: newNameSection,
            type: nameof<ReportSection>(),
            children: [{
                id: -1,
                name: newNamePage,
                type: nameof<ReportPage>(),
                x: 0,
                y: 0,
                width: 21,
                height: 29.7
            }]
        });

        this.props.reportDesigner.sections.push(newSection)
    }

    public moveUp() {
        if(!this.canMoveUp()) return;
        const component = this.props.reportDesigner.selectedComponent();
        const parent = component.parent();
        const actualIndex = component.remove();
        parent.children.splice(actualIndex - 1, 0, component);
    }

    public moveDown() {
        if(!this.canMoveDown()) return;
        const component = this.props.reportDesigner.selectedComponent();
        const parent = component.parent();
        const actualIndex = component.remove();
        parent.children.splice(actualIndex + 1, 0, component);
    }
    
    render() {
        let _rdo = this;
    
        return  ComponentUtils.bindTo(
                <Layout.Grid className={classes.reportDesignerOutline} columns={["1fr"]} rows={["34px", "1fr"]}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <div className="flex-1 flex-container flex-child-center toolbar">
                            <button className="btn btn-primary btn-sm" title="Nuova Pagina" data-bind={{ click: _rdo.addPage.bind(_rdo), disable: !_rdo.props.reportDesigner.selectedComponent() }}>
                                <i className="fa fa-file-o"></i>
                            </button>
                            <button className="btn btn-primary btn-sm" title="Nuova Sezione" data-bind={{ click: _rdo.addSection.bind(_rdo) }}>
                                <i className="fa fa-book"></i>
                            </button>

                            <button className="btn btn-primary btn-sm" title="Sposta Su" style={{ marginLeft: 'auto' }} data-bind={{ click: _rdo.moveUp.bind(_rdo), enable: _rdo.canMoveUp }}>
                                <i className="fa fa-angle-double-up"></i>
                            </button>
                            <button className="btn btn-primary btn-sm" title="Sposta Giù" data-bind={{ click: _rdo.moveDown.bind(_rdo), enable: _rdo.canMoveDown }}>
                                <i className="fa fa-angle-double-down"></i>
                            </button>
                        </div>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Layout.ScrollContainer systemScrollable>
                            <TreeView className="document-outline" roots={this.Roots} />
                        </Layout.ScrollContainer>
                    </Layout.Grid.Cell>
                </Layout.Grid>
        , this, '_rdo');
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportDesignerOutline);
}