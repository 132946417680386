import * as React from "@abstraqt-dev/jsxknockout";
import { With } from "../../../../Components/IfIfNotWith";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IWorkedHoursRowForEditing } from "../../../interfaces/IWorkedHoursEditor";
import { WorkedHoursRowForEditing } from "./WorkedHoursRowForEditing";
import { _WorkedHoursEditor } from "./WorkedHoursEditor";

export interface IWorkedHoursEditorFormProps {
    hours: ko.Observable<IWorkedHoursRowForEditing>;

    editFromJobOrderPanel: boolean;
    insertPerJobOrder: boolean;
    canInsertTask: boolean;
    canEditTask: boolean;
    canEditTaskProgress: boolean;

    onSubmit: () => any | Promise<any>;
}

function renderFakeRoleField() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <label>{TextResources.WorkedHours.Role}</label>
            <div
                className="form-control text-ellipsis"
                data-bind={{
                    text: hoursOnEdit.RoleSelectionStatusMessage,
                    css: { disabled: !hoursOnEdit.canViewOrEditRole },
                    attr: { title: hoursOnEdit.RoleSelectionStatusMessage },
                    style: {
                        color:
                            hoursOnEdit.RoleId() || (!hoursOnEdit.JobOrderId() && !hoursOnEdit.TaskId())
                                ? "black"
                                : "red",
                    },
                }}
                tabIndex={4}></div>
        </>
    );
}

function renderRoleSelector() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <ko-bind data-bind={{ if: !!hoursOnEdit.RoleId() }}>
                <label>{TextResources.WorkedHours.Role}</label>
                <select
                    className="form-control"
                    data-bind={{
                        value: hoursOnEdit.RoleId,
                        options: hoursOnEdit.ValidRoles,
                        optionsValue: "RoleId",
                        optionsText: "RoleName",
                        disable: hoursOnEdit.IsReadOnly,
                    }}
                    tabIndex={4}></select>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: !!hoursOnEdit.RoleId() }}>
                <>{renderFakeRoleField()}</>
            </ko-bind>
        </>
    );
}

function renderFakeWorkTimeCategoryField() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <label>{TextResources.WorkedHours.WorkTimeCategory}</label>
            <div
                className="form-control text-ellipsis"
                data-bind={{
                    text: hoursOnEdit.WorkTimeCategorySelectionStatusMessage,
                    css: { disabled: !hoursOnEdit.canViewOrEditWorkTimeCategory },
                    attr: { title: hoursOnEdit.WorkTimeCategorySelectionStatusMessage },
                    style: {
                        color:
                            hoursOnEdit.WorkTimeCategoryId() || (!hoursOnEdit.JobOrderId() && !hoursOnEdit.TaskId())
                                ? "black"
                                : "red",
                    },
                }}
                tabIndex={6}></div>
        </>
    );
}

function renderWorkTimeCategorySelector() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <ko-bind data-bind={{ if: !!hoursOnEdit.WorkTimeCategoryId() }}>
                <label>{TextResources.WorkedHours.WorkTimeCategory}</label>
                <select
                    className="form-control"
                    data-bind={{
                        value: hoursOnEdit.WorkTimeCategoryId,
                        options: hoursOnEdit.ValidWorkTimeCategories,
                        optionsValue: "WorkTimeCategoryId",
                        optionsText: "WorkTimeCategoryName",
                        disable: hoursOnEdit.IsReadOnly,
                    }}
                    tabIndex={6}></select>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: !!hoursOnEdit.WorkTimeCategoryId() }}>
                <>{renderFakeWorkTimeCategoryField()}</>
            </ko-bind>
        </>
    );
}

function renderFakeBillableAndCallRightFields() {
    return (
        <>
            <div className="flex-container margin-top-10">
                <input id="billable" type="checkbox" disabled />
                <label htmlFor="billable" style={{ width: "100px" }} className="flex-container flex-child-center">
                    <span className="flex-1">{TextResources.WorkedHours.Billable}</span>
                    <span className="fa fa-eur"></span>
                </label>
            </div>
            <div className="flex-container">
                <input id="callright" type="checkbox" disabled />
                <label htmlFor="callright" style={{ width: "100px" }} className="flex-container flex-child-center">
                    <span className="flex-1">{TextResources.WorkedHours.CallRightLabel}</span>
                    <span className="fa fa-bullhorn"></span>
                </label>
            </div>
        </>
    );
}

function renderFakeWorkPlaceField() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <label>{TextResources.WorkedHours.Place}</label>
            <div className="form-control" data-bind={{ css: { disabled: !hoursOnEdit.canViewOrEditWorkPlace } }}>
                {TextResources.WorkedHours.Autoselection}
            </div>
        </>
    );
}

function renderFakeTravelDistanceField() {
    let hoursOnEdit: WorkedHoursRowForEditing;
    return (
        <>
            <label>{TextResources.WorkedHours.Km}</label>
            <div
                className="form-control"
                data-bind={{
                    css: { disabled: !hoursOnEdit.canViewOrEditTravelDistance },
                    numberText: 0.0,
                    format: "0,0.00",
                }}></div>
        </>
    );
}

export function WorkedHoursEditorForm(props: IWorkedHoursEditorFormProps) {
    let hoursOnEdit: WorkedHoursRowForEditing;
    let editor: _WorkedHoursEditor;

    return (
        <With data={props.hours} as="hoursOnEdit">
            {() => (
                <form
                    className="form"
                    onSubmit={() => {
                        props.onSubmit();
                        return false;
                    }}
                    data-bind={"css: { flexibilityOutline: hoursOnEdit.isInsertFlexibility}"}>
                    <div className="form-body">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    {!props.editFromJobOrderPanel && !props.insertPerJobOrder && (
                                        <select2
                                            className="col-md-6 form-group"
                                            label="Progetto"
                                            allowClear={true}
                                            placeholder="Digita per ricercare una commessa..."
                                            value={() => "hoursOnEdit.JobOrderId"}
                                            dataSource={() => "hoursOnEdit.JobOrderDataSource"}
                                            listener={() => "hoursOnEdit"}
                                            readOnly={() => "hoursOnEdit.IsReadOnly"}
                                            tabIndex={1}
                                            hasFocus={() => "hoursOnEdit.JobOrderHasFocus"}>
                                            <button
                                                tabIndex={-1}
                                                className="btn btn-primary"
                                                data-bind={{ click: hoursOnEdit.GoToContainerDetail.bind(hoursOnEdit) }}
                                                type="button">
                                                <i className="icon-action-redo"></i>
                                            </button>
                                        </select2>
                                    )}
                                    {(props.insertPerJobOrder || props.editFromJobOrderPanel) && (
                                        <select2
                                            className="col-md-6 form-group"
                                            label="Risorsa"
                                            allowClear={true}
                                            value={() => "editor.ResourceId"}
                                            placeholder={TextResources.WorkedHours.ResourceFieldPlaceholder}
                                            dataSource={() => "editor.ResourcesDataSource"}
                                            readOnly={() => "editor.IsResourceFieldReadOnly"}
                                            listener={() => "editor"}>
                                            <button
                                                tabIndex={-1}
                                                className="btn btn-primary"
                                                data-bind={{
                                                    asyncClick: editor.showMonthSyntheticDataForResource.bind(editor),
                                                }}
                                                type="button">
                                                <span
                                                    data-bind={{
                                                        timeTextFromNumber: editor.TotalOrdinaryHours,
                                                    }}></span>
                                            </button>
                                        </select2>
                                    )}
                                    <select2
                                        className="form-group col-md-6"
                                        label="Attività"
                                        allowClear={true}
                                        placeholder="Digita per ricercare una attività..."
                                        value={() => "hoursOnEdit.TaskId"}
                                        dataSource={() => "hoursOnEdit.TasksDataSource"}
                                        listener={() => "hoursOnEdit"}
                                        readOnly={() => "hoursOnEdit.IsReadOnly"}
                                        tabIndex={2}>
                                        <button
                                            tabIndex={-1}
                                            type="button"
                                            className="btn btn-primary"
                                            data-toggle="dropdown">
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                        <ul className="dropdown-menu pull-right">
                                            {props.canEditTask && (
                                                <li
                                                    data-bind={{
                                                        click: hoursOnEdit.GoToActivityDetail.bind(hoursOnEdit),
                                                    }}>
                                                    <a href="#">
                                                        <i className="icon-action-redo"></i>&nbsp;
                                                        <span className="text">
                                                            {TextResources.WorkedHours.GoToActivityDetails}
                                                        </span>
                                                    </a>
                                                </li>
                                            )}

                                            {props.canInsertTask && (
                                                <li data-bind={{ click: editor.CreateNewTask.bind(editor) }}>
                                                    <a href="#">
                                                        <i className="fa fa-plus"></i>&nbsp;
                                                        <span className="text">
                                                            {TextResources.WorkedHours.CreateActivity}
                                                        </span>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </select2>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <duration
                                        allowNegative={() => "hoursOnEdit.isInsertFlexibility"}
                                        signPositionLeft={true}
                                        style={{ width: "22%" }}
                                        className="col-md-3"
                                        placeholder="Ore"
                                        label="Ore"
                                        labelIcon="fa fa-clock-o"
                                        value={() => "OrdinaryHours"}
                                        readOnly={() => "hoursOnEdit.IsReadOnly"}
                                        tabIndex={3}
                                        hasFocus={() => "hoursOnEdit.HoursHasFocus"}
                                        submitOnEnter={true}></duration>
                                    <time-picker
                                        style={{ width: "28%" }}
                                        className="col-md-3"
                                        placeholder="Ora inizio"
                                        label="Inizio"
                                        labelIcon="fa fa-flash"
                                        value={() => "hoursOnEdit.WorkDate"}
                                        timeZone={() => "hoursOnEdit.WorkDateTimezone"}
                                        readOnly={() => "hoursOnEdit.IsReadOnly"}
                                        injectTo={() => "hoursOnEdit.StartTimeComponent"}></time-picker>
                                    <time-picker
                                        style={{ width: "28%" }}
                                        className="col-md-3"
                                        placeholder="Ora fine"
                                        label="Fine"
                                        labelIcon="fa fa-flag-checkered"
                                        value={() => "hoursOnEdit.EndTime"}
                                        timeZone={() => "hoursOnEdit.EndTimeTimezone"}
                                        readOnly={() => "hoursOnEdit.IsReadOnly"}
                                        canChangeDate={true}
                                        injectTo={() => "hoursOnEdit.EndTimeComponent"}></time-picker>
                                    <duration
                                        style={{ width: "22%" }}
                                        className="col-md-3"
                                        placeholder="Pausa"
                                        label="Pausa"
                                        labelIcon="fa fa-coffee"
                                        value={() => "hoursOnEdit.BreakHours"}
                                        readOnly={() =>
                                            "hoursOnEdit.IsReadOnly() || hoursOnEdit.isInsertFlexibility()"
                                        }></duration>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 form-group" data-bind={{ visible: hoursOnEdit.ShowServiceOrders }}>
                                <label>{TextResources.WorkedHours.ServiceOrder}</label>
                                <select
                                    className="form-control"
                                    data-bind={{
                                        value: hoursOnEdit.ServiceOrderId,
                                        options: hoursOnEdit.ServiceOrders,
                                        optionsValue: "ServiceOrderId",
                                        optionsText: "ServiceOrderName",
                                        disable: hoursOnEdit.IsReadOnly,
                                    }}
                                    tabIndex={5}></select>
                            </div>
                            <div
                                className="col-md-2 form-group"
                                data-bind={{
                                    css: {
                                        "col-md-2": hoursOnEdit.ShowServiceOrders,
                                        "col-md-3": !hoursOnEdit.ShowServiceOrders(),
                                    },
                                }}>
                                <ko-bind data-bind={{ if: hoursOnEdit.RenderRolesSelector }}>
                                    {renderRoleSelector()}
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: hoursOnEdit.RenderRolesSelector }}>
                                    {renderFakeRoleField()}
                                </ko-bind>
                            </div>
                            <div
                                className="col-md-2 form-group"
                                data-bind={{
                                    css: {
                                        "col-md-2": hoursOnEdit.ShowServiceOrders,
                                        "col-md-3": !hoursOnEdit.ShowServiceOrders(),
                                    },
                                }}>
                                <ko-bind data-bind={{ if: hoursOnEdit.RenderWorktimeCategoriesSelector }}>
                                    {renderWorkTimeCategorySelector()}
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: hoursOnEdit.RenderWorktimeCategoriesSelector }}>
                                    {renderFakeWorkTimeCategoryField()}
                                </ko-bind>
                            </div>
                            <div className="col-md-2 form-group">
                                <ko-bind
                                    data-bind={{
                                        if:
                                            !hoursOnEdit.HideAdministrativeData() &&
                                            hoursOnEdit.canViewOrEditBillableAndCallRightFlag,
                                    }}>
                                    <div className="flex-container margin-top-10">
                                        <input
                                            id="billable"
                                            type="checkbox"
                                            data-bind={{
                                                checkbox: hoursOnEdit.Billable,
                                                disable: hoursOnEdit.isInsertFlexibility,
                                            }}
                                        />
                                        <label
                                            htmlFor="billable"
                                            style={{ width: "100px" }}
                                            className="flex-container flex-child-center">
                                            <span className="flex-1">{TextResources.WorkedHours.Billable}</span>
                                            <span
                                                className="fa fa-eur"
                                                data-bind={{
                                                    style: { color: !hoursOnEdit.Billable() ? "#bebebe" : "#428bca" },
                                                }}></span>
                                        </label>
                                    </div>
                                    <div className="flex-container">
                                        <input
                                            id="callright"
                                            type="checkbox"
                                            data-bind={{
                                                checkbox: hoursOnEdit.CallRight,
                                                disable: hoursOnEdit.isInsertFlexibility,
                                            }}
                                        />
                                        <label
                                            htmlFor="callright"
                                            style={{ width: "100px" }}
                                            className="flex-container flex-child-center">
                                            <span className="flex-1">{TextResources.WorkedHours.CallRightLabel}</span>
                                            <span
                                                className="fa fa-bullhorn"
                                                data-bind={{
                                                    style: { color: !hoursOnEdit.CallRight() ? "#bebebe" : "#428bca" },
                                                }}></span>
                                        </label>
                                    </div>
                                </ko-bind>
                                <ko-bind
                                    data-bind={{
                                        if:
                                            hoursOnEdit.HideAdministrativeData() ||
                                            !hoursOnEdit.canViewOrEditBillableAndCallRightFlag,
                                    }}>
                                    {renderFakeBillableAndCallRightFields()}
                                </ko-bind>
                            </div>
                            <div className="col-md-2 form-group">
                                <ko-bind
                                    data-bind={{
                                        if: !hoursOnEdit.HideAdministrativeData() && hoursOnEdit.canViewOrEditWorkPlace,
                                    }}>
                                    <label>{TextResources.WorkedHours.Place}</label>
                                    <select
                                        className="form-control"
                                        data-bind={{
                                            value: hoursOnEdit.WorkPlaceId,
                                            options: hoursOnEdit.WorkPlaces,
                                            optionsValue: "Id",
                                            optionsText: "Description",
                                            disable: hoursOnEdit.IsReadOnly() || hoursOnEdit.isInsertFlexibility(),
                                        }}></select>
                                </ko-bind>
                                <ko-bind
                                    data-bind={{
                                        if: hoursOnEdit.HideAdministrativeData() || !hoursOnEdit.canViewOrEditWorkPlace,
                                    }}>
                                    {renderFakeWorkPlaceField()}
                                </ko-bind>
                            </div>
                            <div className="col-md-2 form-group">
                                <ko-bind
                                    data-bind={{
                                        if:
                                            !hoursOnEdit.HideAdministrativeData() &&
                                            hoursOnEdit.canViewOrEditTravelDistance,
                                    }}>
                                    <label>{TextResources.WorkedHours.Km}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        data-bind={{
                                            numberValue: hoursOnEdit.TravelDistance,
                                            disable: hoursOnEdit.IsReadOnly() || hoursOnEdit.isInsertFlexibility(),
                                        }}
                                    />
                                </ko-bind>
                                <ko-bind
                                    data-bind={{
                                        if:
                                            hoursOnEdit.HideAdministrativeData() ||
                                            !hoursOnEdit.canViewOrEditTravelDistance,
                                    }}>
                                    {renderFakeTravelDistanceField()}
                                </ko-bind>
                            </div>
                        </div>

                        <div className="row flex-container">
                            <div className="col-md-4 flex-container flex-vertical">
                                <label>{TextResources.WorkedHours.Notes}</label>
                                <textarea
                                    className="flex-fill"
                                    style={{
                                        width: "100%",
                                        minHeight: "80px",
                                        resize: "none",
                                        borderColor: "lightgray",
                                    }}
                                    placeholder="Inserire le note..."
                                    maxLength={4000}
                                    data-bind={{
                                        selectOnFocus: {},
                                        value: hoursOnEdit.Notes,
                                        autoHeight: true,
                                        disable: hoursOnEdit.IsReadOnly,
                                    }}></textarea>
                            </div>
                            <div className="col-md-8">
                                <div className="row activity-state">
                                    <div className="col-md-5">
                                        <label>{TextResources.WorkedHours.ActivityState}</label>
                                        <div className="row">
                                            <div className="col-md-9" style={{ paddingRight: 0 }}>
                                                <span style={{ display: "inline-block", lineHeight: "34px" }}>
                                                    {TextResources.WorkedHours.AlreadyWorked}{" "}
                                                    <b
                                                        style={{ fontSize: "1.2em" }}
                                                        data-bind={{
                                                            timeTextFromNumber: hoursOnEdit.WorkedHoursMinusOriginal,
                                                            css: { "font-red": hoursOnEdit.OverEstimate },
                                                        }}></b>
                                                    &nbsp;(+&nbsp;
                                                    <span
                                                        data-bind={{
                                                            timeTextFromNumber: hoursOnEdit.OrdinaryHours,
                                                            css: { "font-red": hoursOnEdit.OverEstimate },
                                                        }}></span>
                                                    ){TextResources.WorkedHours.EstimatedHours}
                                                </span>
                                            </div>
                                            <div className="col-md-3" style={{ paddingLeft: 0 }}>
                                                <b
                                                    data-bind={{ timeTextFromNumber: hoursOnEdit.EstimatedWork }}
                                                    style={{
                                                        fontSize: "1.2em",
                                                        paddingTop: "6px",
                                                        display: "inline-block",
                                                    }}></b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <label>{TextResources.WorkedHours.Progress}</label>
                                        <div className="row">
                                            {props.canEditTaskProgress && (
                                                <>
                                                    <div className="col-md-6">
                                                        <span style={{ lineHeight: "34px" }}>
                                                            {TextResources.WorkedHours.ProgressShort}
                                                        </span>
                                                        <div
                                                            className="input-group pull-right"
                                                            style={{ width: "150px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                style={{ textAlign: "right" }}
                                                                data-bind={{
                                                                    percentageValue: hoursOnEdit.Progress,
                                                                    selectOnFocus: {},
                                                                    disable:
                                                                        hoursOnEdit.ProgressFieldDisabled() ||
                                                                        hoursOnEdit.isInsertFlexibility(),
                                                                }}
                                                            />
                                                            <span className="input-group-btn">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="button"
                                                                    data-bind={{
                                                                        click: hoursOnEdit.CloseTask.bind(hoursOnEdit),
                                                                        disable:
                                                                            hoursOnEdit.ProgressFieldDisabled() ||
                                                                            hoursOnEdit.isInsertFlexibility(),
                                                                    }}>
                                                                    {TextResources.WorkedHours.Complete}
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div
                                                            className="row"
                                                            data-bind={{
                                                                visible: hoursOnEdit.ActivityProgressMode() == 0,
                                                            }}>
                                                            <div className="col-md-4" style={{ padding: "0px" }}>
                                                                <span style={{ lineHeight: "34px" }}>
                                                                    {TextResources.WorkedHours.Residual}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="col-md-4"
                                                                style={{ padding: "0px", height: "34px" }}>
                                                                <duration
                                                                    style={{ height: "34px" }}
                                                                    value={() => "hoursOnEdit.ProgressInHours"}
                                                                    readOnly={() =>
                                                                        "hoursOnEdit.ProgressFieldDisabled() || hoursOnEdit.isInsertFlexibility()"
                                                                    }></duration>
                                                            </div>
                                                            <div className="col-md-4" style={{ padding: "0px" }}>
                                                                <span style={{ lineHeight: "34px", marginLeft: "5px" }}>
                                                                    &nbsp;h/u
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {!props.canEditTaskProgress && (
                                                <div style={{ minWidth: "365px", padding: "4px 15px" }}>
                                                    <span>{TextResources.WorkedHours.Progress}</span>
                                                    <b
                                                        style={{ fontSize: "1.2em" }}
                                                        data-bind={{ percentageText: hoursOnEdit.Progress }}></b>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span>{TextResources.WorkedHours.Residual}</span>
                                                    <b
                                                        style={{ fontSize: "1.2em" }}
                                                        data-bind={{
                                                            timeTextFromNumber: hoursOnEdit.ProgressInHours,
                                                            visible: hoursOnEdit.ActivityProgressMode() == 0,
                                                        }}></b>
                                                    &nbsp;{TextResources.WorkedHours.ProgressUnitOfMeasure}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ paddingTop: "9px" }}>
                                        <div
                                            style={{ position: "absolute", width: "185px" }}
                                            data-bind={{
                                                visible:
                                                    editor.EnableMultipleInsert() && !hoursOnEdit.isInsertFlexibility(),
                                            }}>
                                            <button
                                                className="btn btn-default"
                                                data-bind={{ click: editor.setMultipleInsertMode.bind(editor) }}>
                                                <i className="fa fa-files-o" style={{ marginRight: "5px" }}></i>
                                                <span
                                                    className="btn-small-text"
                                                    data-bind={{
                                                        text: editor.MultipleInsertEnabled()
                                                            ? "Inserimento standard"
                                                            : "Inserimento multiplo",
                                                    }}></span>
                                            </button>
                                            <div
                                                className="popover left inline-block"
                                                style={{ right: "194px", top: "0px", width: "400px", left: "auto" }}
                                                data-bind={{
                                                    visible:
                                                        editor.ShowMultipleInsertPopover() &&
                                                        !hoursOnEdit.isInsertFlexibility(),
                                                }}>
                                                <div className="arrow" style={{ top: "16px" }}></div>
                                                <h3 className="popover-title">
                                                    {TextResources.WorkedHours.MultipleInsert}
                                                </h3>
                                                <div className="popover-content">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {TextResources.WorkedHours.MultipleInsertHelp}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 text-right">
                                                            <button
                                                                className="btn btn-primary btn-xs"
                                                                data-bind={{
                                                                    click: editor.switchMultipleInsertMode.bind(editor),
                                                                }}>
                                                                {TextResources.WorkedHours.Enable}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-bind={{
                                                    click: editor.newRow.bind(editor),
                                                    disable: hoursOnEdit.IsReadOnly,
                                                }}>
                                                <i className="fa fa-undo" style={{ marginRight: "5px" }}></i>
                                                <span>{TextResources.WorkedHours.Restart}</span>
                                            </button>
                                            &nbsp;
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-bind={{
                                                    click: editor.save.bind(editor),
                                                    disable: hoursOnEdit.IsReadOnly,
                                                }}
                                                tabIndex={6}>
                                                <i className="fa fa-floppy-o" style={{ marginRight: "5px" }}></i>
                                                <span>{TextResources.WorkedHours.Save}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </With>
    );
}
