import * as ko from "knockout";
export class PopoverPositioning {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext): void {
        let anchorSelector: string = valueAccessor();

        if (!anchorSelector)
            throw Error("Passare un selettore per l'elemento ancora del popover.");

        let target = $(element);
        let anchor = target.prev(anchorSelector);

        let mutationObserver = new MutationObserver((mutationsList: MutationRecord[], observer: MutationObserver) => {
            // TODO implementare gli altri posizionamenti
            
            if (target.hasClass("right")) {
                PopoverPositioning.rightPositioning(element, anchor[0]);
                return;
            }
        });

        mutationObserver.observe(element, { characterData: true, attributes: true, childList: true });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            mutationObserver.disconnect();
        });
    }


    private static rightPositioning(DOMTarget: HTMLElement, DOMAnchor: HTMLElement): void {
        let target = $(DOMTarget);
        
        let anchorPosition = PopoverPositioning.getPosition(DOMAnchor);
        let targetHeight = DOMTarget.offsetHeight;

        let top = anchorPosition.top + anchorPosition.height / 2 - targetHeight / 2 - 20;
        let left = anchorPosition.left + anchorPosition.width + 3;

        target.css("top", top + 'px');
        target.css("left", left + 'px');
    }

    private static getPosition(element: HTMLElement) {
        let jqEl = $(element);
        return $.extend({}, (typeof element.getBoundingClientRect == 'function') ? element.getBoundingClientRect() : {
            width: element.offsetWidth,
            height: element.offsetHeight
        }, jqEl.offset());
    }
}

ko.bindingHandlers["popoverPositioning"] = new PopoverPositioning();