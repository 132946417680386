import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ICurrencyWithCountriesIds, ICurrenciesService } from "../../interfaces/ICurrenciesService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { INationsSettingsManager } from "../../../ProlifeSdk/interfaces/settings/INationsSettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface ICurrenciesSettingsManager extends ISettingsManager {
    getCurrencies(textFilter: string, getDeleted?: boolean): ICurrencyWithCountriesIds[];
    getCurrenciesByIds(ids: number[]): ICurrencyWithCountriesIds[];
    getCurrencyById(id: number): ICurrencyWithCountriesIds;
    getCurrencyByCode(code: string): ICurrencyWithCountriesIds;

    getDefaultCurrency(): ICurrencyWithCountriesIds;

    saveCurrencies(currencies: ICurrencyWithCountriesIds[]): Promise<ICurrencyWithCountriesIds[]>;
}

export class CurrenciesSettingsManager implements ICurrenciesSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    @LazyImport(nameof<ICurrenciesService>())
    private currenciesService: ICurrenciesService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImportSettingManager(ProlifeSdk.NationsSettings)
    private nationsSettingsManager: INationsSettingsManager;

    private currencies: ICurrencyWithCountriesIds[] = [];

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Invoices.AdministrativeDocumentsSettingsGroup);
    }
    
    public load(): Promise<any> {
        return this.currenciesService.GetCurrencies("", true, 0, 100000)
            .then((currencies: ICurrencyWithCountriesIds[]) => {
                this.currencies = currencies;
            });
    }
    
    public getName(): string {
        return nameof<ICurrenciesSettingsManager>();
    }
    
    public getLabel(): string {
        return TextResources.Invoices.CurrenciesSettingsManagerLabel;
    }
    
    public hasEditingUI(): boolean {
        return this.authorizationsService.isAuthorized("Documents_EnableCurrenciesSettings");
    }
    
    public getEditingUI(): IView {
        if (!this.hasEditingUI())
            throw new Error("L'editing delle valute non è abilitata perché non si hanno i diritti necessari.");

        return {
            templateName: undefined,
            templateUrl: undefined,
            viewModel: undefined,
            component: 'currencies-editor'
        };
    }

    public getCurrencies(textFilter: string, getDeleted: boolean = false): ICurrencyWithCountriesIds[] {
        return this.currencies.filter((c) => (getDeleted || !c.Deleted) && this.matchTextFilter(c, textFilter));
    }

    public getCurrenciesByIds(ids: number[]): ICurrencyWithCountriesIds[] {
        return this.currencies.filter(c => ids.indexOf(c.Id) >= 0);
    }

    public getCurrencyById(id: number): ICurrencyWithCountriesIds {
        return this.currencies.firstOrDefault(c => c.Id === id);
    }

    public getCurrencyByCode(code: string): ICurrencyWithCountriesIds {
        return this.currencies.firstOrDefault(c => c.CodeISO4217alpha3 === code);
    }

    public getDefaultCurrency(): ICurrencyWithCountriesIds {
        return this.getCurrencyByCode(ProlifeSdk.EuroCurrencyAlpha3Code);
    }

    public async saveCurrencies(currencies: ICurrencyWithCountriesIds[]): Promise<ICurrencyWithCountriesIds[]> {
        let updatedCurrencies = await this.currenciesService.CreateOrUpdateCurrencies(currencies);
        for (let currency of updatedCurrencies) {
            let oldCurrency = this.currencies.firstOrDefault(c => c.Id === currency.Id);

            if (!oldCurrency)
                this.insertCurrencyInList(currency);
            else
                this.updateCurrency(oldCurrency, currency);
            
        }
        return updatedCurrencies;
    }
    
    private updateCurrency(oldCurrency: ICurrencyWithCountriesIds, currency: ICurrencyWithCountriesIds) {
        oldCurrency.CodeISO4217alpha3 = currency.CodeISO4217alpha3;
        oldCurrency.Symbol = currency.Symbol;
        oldCurrency.Name = currency.Name;
        oldCurrency.FractionalUnit = currency.FractionalUnit;
        oldCurrency.Deleted = currency.Deleted;
        oldCurrency.CountriesIds = currency.CountriesIds;
    }
    
    private insertCurrencyInList(currency: ICurrencyWithCountriesIds) {
        let index = 0;
        
        while (this.currencies[index].CodeISO4217alpha3 <= currency.CodeISO4217alpha3)
            index++;

        this.currencies.splice(index, 0, currency);
    }

    private matchTextFilter(currency: ICurrencyWithCountriesIds, textFilter: string): boolean {
        if (!textFilter)
            return true;

        let words = textFilter.split(" ");
        let match: boolean = true;
        
        for (let word of words) {
            let lowerWord = word.toLowerCase();
            if (!(currency.Name.toLowerCase().indexOf(lowerWord) >= 0) && !(currency.CodeISO4217alpha3.toLowerCase().indexOf(lowerWord) >= 0) && !(currency.Symbol.toLowerCase().indexOf(lowerWord) >= 0) && !this.matchCountries(currency.CountriesIds, word))
                match = false;
        }

        return match;
    }

    private matchCountries(countriesIds: number[], word: string): boolean {
        if (!word)
            return true;

        let match = false;

        for (let id of countriesIds) {
            let country = this.nationsSettingsManager.getNationById(id);

            if (!country)
                continue;

            if (country.Name.toLowerCase().indexOf(word.toLowerCase()) >= 0)
                match = true;
        }

        return match;
    }
}