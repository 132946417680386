import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { reloadNow, ComponentUtils, useEffect } from "../../../Core/utils/ComponentUtils";
import { Portlet } from "../../../Components/Portlet";
import { Table, ITableItem } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody, ColumnFooter } from "../../../Components/TableComponent/CustomColumn";
import { Layout } from "../../../Components/Layouts";
import { TextInput } from "../../../Components/TextInput";
import { Select2 } from "../../../Components/Select2Component";
import { ArticlesDataSource } from "../../../DataSources/ArticlesDataSource";
import { DropdownList } from "../../../Components/DropdownList";
import { IUserCharacter, IUserCharactersSettingsManager } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { LazyImportSettingManager, LazyImport, useSettingsManager, useService } from "../../../Core/DependencyInjection";
import { Typeahead } from "../../../Components/TypeaheadComponent";
import { PurchasesDataSource } from "../../../DataSources/PurchasesDataSource";
import { NumberInput } from "../../../Components/NumberInput";
import { MoneyInput } from "../../../Components/MoneyInput";
import { PercentageInput } from "../../../Components/PercentageInputComponent";
import { DiscountsUtilities } from "../../../Warehouse/warehouse/ui/Utilities/DiscountsUtilities";
import { DiscountInput } from "../../../Components/DiscountInput";
import { IQuotationBillOfMaterials, IQuotationBillOfMaterialsRow, QuotationBillOfMaterialsRowType, IQuotationRow, IQuotationRowEntity } from "../../QuotationService";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { QuotationDescriptionDialog } from "../QuotationDetails/QuotationDescriptionDialog";
import { IResolvedCustomerDiscountCatalogArticleWithCatalog, IDiscountsCatalogsService, DiscountCatalogRowMode } from "../../../Warehouse/DiscountsCatalogsService";
import { IArticleCostAndPrice, ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWarehouseSettingsManager } from "../../../ProlifeSdk/interfaces/warehouse/IWarehouseSettingsManager";
import { IHumanResourcesService } from "../../../Users/HumanResourcesService";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import numeral = require("numeral");

type QuotationBillOfMaterialsEditorProps = {
    billOfMaterials: ko.Observable<IQuotationBillOfMaterials>;
    customerId: number;
    jobOrderId: ko.Observable<number>;
    onSave: (b: IQuotationBillOfMaterials) => void;
    onDelete: (b: IQuotationBillOfMaterials) => void;
}


export type QuotationBillOfMaterialsRow = {
    id: number;
    fkBillOfMaterials: number;
    type: QuotationBillOfMaterialsRowType
    identifierColor: string;

    entityKeyId: ko.Observable<number>;
    description: ko.Observable<string>;
    amount: ko.Observable<number>;
    unitCost: ko.Observable<number>;
    totalCost: ko.Computed<number>;
    margin: ko.Computed<number>;
    unitPrice: ko.Computed<number>;
    discount: ko.Observable<string>;
    netUnitPrice: ko.Computed<number>;
    totalPrice: ko.Computed<number>;
}

export function getEntityColor(type: QuotationBillOfMaterialsRowType) {
    switch(type)
    {
        case "WAR":
            return "#43b545";
        case "EWK":
            return "#ff893e";
        case "EPC":
            return "#56b7f7";
        default:
            return "red";
    }
}

export function createQuotationBillOfMaterialsRow(row: IQuotationBillOfMaterialsRow | IQuotationRow | IQuotationRowEntity, customerId: number, jobOrderId: ko.Observable<number>) : QuotationBillOfMaterialsRow {
    const amount = ko.observable(row.Amount);
    const unitCost = ko.observable(row.UnitCost);
    const totalCost = ko.computed(() => amount() * unitCost());

    const internalMargin = ko.observable(row.Margin);
    const internalUnitPrice = ko.observable(row.UnitPrice);

    const updater = ko.computed(() => {
        const newUnitCost = unitCost();
        internalUnitPrice(newUnitCost * (1 + internalMargin()));
    })

    const margin = ko.computed({
        read: () => internalMargin() * 100,
        write: (m) => {
            internalMargin(m / 100);
            internalUnitPrice(unitCost() * (1 + (m / 100)))
        }
    });
    const unitPrice = ko.computed({
        read: () => internalUnitPrice(),
        write: (p) => {
            internalMargin((p / unitCost()) - 1);
            internalUnitPrice(p);
        } 
    });

    const discount = ko.observable(row.Discount);
    const netUnitPrice = ko.computed({
        read: () => unitPrice() * DiscountsUtilities.calculateDiscount(discount()),
        write: (nup) => {
            unitPrice(nup / DiscountsUtilities.calculateDiscount(discount()));
        }
    });
    const totalPrice = ko.computed(() => netUnitPrice() * amount());

    const type = !row.hasOwnProperty("Type") ? "" : (row as IQuotationBillOfMaterialsRow).Type;
    const entityKeyId = ko.observable(!row.hasOwnProperty("EntityKeyId") ? null : (row as IQuotationBillOfMaterialsRow).EntityKeyId);

    const warehousesSettingsManager = useSettingsManager<IWarehouseSettingsManager>(ProlifeSdk.WarehouseSettingsManager);
    const discountsCatalogsService = useService<IDiscountsCatalogsService>(nameof<IDiscountsCatalogsService>());
    const todoListService = useService<ITodoListService>(nameof<ITodoListService>());
    const humanResourcesService = useService<IHumanResourcesService>(nameof<IHumanResourcesService>());
    const userCharactersSettingsManager = useSettingsManager<IUserCharactersSettingsManager>(ProlifeSdk.UserCharactersServiceType);
    const jobOrdersService = useService<IJobOrderService>(nameof<IJobOrderService>());

    const updateCostAndPrices = () => {
        const updateCostsAndPrices = async () => {
            const referenceDate = new Date();

            if(type === "WAR") {
                const articleId = entityKeyId();
                const warehouseId = warehousesSettingsManager.getDefaultWarehouse()?.Id;
                
                const [discountValue, articleCostAndPrice] = await Promise.all([
                    discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticle({
                        customerId: customerId,
                        isCustomer: true,
                        referenceDate: referenceDate,
                        warehouseId: warehouseId,
                        articleId: articleId,
                        overrideGroupId: null
                    }),
                    todoListService.GetArticleCostAndPriceForTask(jobOrderId(), articleId, referenceDate)
                ]);

                unitCost(articleCostAndPrice.UnitCost);

                let finalPrice = discountValue.UnitPrice + ((discountValue.TotalDiscount / 100) * discountValue.UnitPrice);
    
                if (DiscountCatalogRowMode.getDiscountSign(discountValue.Mode) > 0) //E' un ricarico quindi non mostro il ricarico nel documento
                {
                    unitPrice(finalPrice);
                    discount(undefined);
                }
                else
                {
                    let discountString = [discountValue.Discount0, discountValue.Discount1, discountValue.Discount2, discountValue.Discount3, discountValue.Discount4]
                        .filter(d => d > 0)
                        .map(d => numeral(d / 100).format("0.[##]%"))
                        .join(" ");
    
                    unitPrice(discountValue.UnitPrice);
                    discount(discountString);
                }
            } else if(type === "EWK") {
                const roleId = entityKeyId();
                const [meanCost, salary] = await Promise.all([
                    humanResourcesService.GetUserCharacterMeanCost({ UserCharacterId: roleId, referenceDate }),
                    (async () => {
                        const role = userCharactersSettingsManager.getUserCharacterById(roleId);
                        if(!jobOrderId() || jobOrderId() <= 0)
                            return role?.Salary ?? 0;

                        const j = await jobOrdersService.get(jobOrderId());
                        var jobOrderRole = j.RolesPrices.firstOrDefault(r => role.IdUserCharacter == r.FkUserCharacterId);
                        return jobOrderRole?.Salary ?? role?.Salary ?? 0;
                    })()
                ]);

                unitCost(meanCost ?? 0);
                unitPrice(salary);
            }
        }

        updateCostsAndPrices();
    }

    entityKeyId.subscribe(updateCostAndPrices);

    return {
        id: row.Id,
        fkBillOfMaterials: row.FKParent,
        type: type,
        identifierColor: getEntityColor(type),

        entityKeyId: entityKeyId,
        description: ko.observable(row.Description),
        amount,
        unitCost,
        totalCost,
        margin,
        unitPrice,
        discount,
        netUnitPrice,
        totalPrice
    };
}

export function QuotationBillOfMaterialsEditor(props: QuotationBillOfMaterialsEditorProps) {
    const C = require("./QuotationBillOfMaterialsEditor")._QuotationBillOfMaterialsEditor as typeof _QuotationBillOfMaterialsEditor;
    return <C {...props} />;
}

export class _QuotationBillOfMaterialsEditor extends React.Component<QuotationBillOfMaterialsEditorProps> {
    static defaultProps: Partial<QuotationBillOfMaterialsEditorProps> = {
    }

    rows : ko.ObservableArray<QuotationBillOfMaterialsRow> = ko.observableArray();
    articlesDataSource : ArticlesDataSource = new ArticlesDataSource();
    purchasesDataSource: PurchasesDataSource = new PurchasesDataSource();
    roles: IUserCharacter[] = [];

    name: ko.Observable<string> = ko.observable();
    description: ko.Observable<string> = ko.observable();
    textDescription : ko.Computed<string>;

    totalCost : ko.Computed<number>;
    totalPrice : ko.Computed<number>;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private userCharactersSettingsManager : IUserCharactersSettingsManager;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(props : QuotationBillOfMaterialsEditorProps) {
        super(props);
        
        this.roles = this.userCharactersSettingsManager.getUserCharacters();

        this.totalCost = ko.computed(() => this.rows().sum(r => r.totalCost()));
        this.totalPrice = ko.computed(() => this.rows().sum(r => r.totalPrice()));
        this.textDescription = ko.computed(() => {
            const desc = this.description();
            if(!desc) return desc;
            return $(desc).text();
        })

        useEffect(() => {
            const bom = this.props.billOfMaterials();
            if(!bom) return;

            this.name(bom.Name);
            this.description(bom.Description);
            this.rows(bom.Rows.map(r => createQuotationBillOfMaterialsRow(r, this.props.customerId, this.props.jobOrderId)));

        }, [this.props.billOfMaterials])
    }

    private factory(row : QuotationBillOfMaterialsRow) {
        return {
            id: -1,
            title: "",
            isLeaf: true,
            isGroup: false,
            model: row
        }
    }

    private addNew(type: QuotationBillOfMaterialsRowType) {
        const newRow = createQuotationBillOfMaterialsRow({
            Id: this.idGeneratorService.getNextId(),
            FKParent: this.props.billOfMaterials().Id,
            Type: type,
            EntityKeyId: null,
            Description: "",

            Amount: 0,
            UnitCost: 0,
            TotalCost: 0,
            Margin: 0,
            UnitPrice: 0,
            Discount: null,
            NetUnitPrice: 0,
            TotalPrice: 0,
            Categories: [],
            Entities: [],
        }, this.props.customerId, this.props.jobOrderId);
        this.rows.push(newRow);
    }

    removeRow(row: QuotationBillOfMaterialsRow): void {
        this.rows.remove(row);
    }

    private renderEditor(item: ITableItem<QuotationBillOfMaterialsRow>) {
        const model = item.Data.model;
        switch(model.type)
        {
            case "WAR":
                return <Select2 dataSource={this.articlesDataSource} value={model.entityKeyId} simple placeholder="Seleziona un articolo..." />
            case "EWK":
                return <DropdownList simple value={model.entityKeyId} values={this.roles} valueGetter={r => String(r.IdUserCharacter)} textGetter={r => r.Description} idGetter={r => r.IdUserCharacter} noSelectionPlaceholder="Seleziona un ruolo..." />
            case "EPC":
                return <Typeahead dataSource={this.purchasesDataSource} value={model.description} simple placeholder="Seleziona un acquisto..." />
            case "BOM":
                return <Select2 dataSource={this.articlesDataSource} value={model.entityKeyId} simple placeholder="Seleziona una distinta base..." />
            default:
                return <TextInput value={model.description} simple placeholder="Inserisci una descrizione..." />;
        }
    }

    private getRowsData() : IQuotationBillOfMaterialsRow[] {
        return this.rows().map(r => ({
            Id: r.id,
            FKParent: r.fkBillOfMaterials,
            Type: r.type,
            EntityKeyId: r.entityKeyId(),
            Description: r.description(),
            Amount: r.amount(),
            UnitCost: r.unitCost(),
            TotalCost: r.totalCost(),
            Margin: r.margin(),
            UnitPrice: r.unitPrice(),
            Discount: r.discount(),
            NetUnitPrice: r.netUnitPrice(),
            TotalPrice: r.totalPrice()
        }));
    }

    private async openDescriptionEditor() {
        const C = require("../QuotationDetails/QuotationDescriptionDialog").QuotationDescriptionDialog as typeof QuotationDescriptionDialog;
        try
        {
            const result = await this.dialogsService.ShowModal<string>(new C({ description: this.description }));
            this.description(result);
        }
        catch (e)
        {
            console.log(e);
        }        
    }
    
    render() {
        const qbome = this;

        const onSave = () => {
            this.props.onSave({
                ...this.props.billOfMaterials(),
                Name: this.name(),
                Description: this.description(),
                Rows: this.getRowsData()
            })
        };

        const onCancel = () => {
            const bom = this.props.billOfMaterials();
            this.name(bom.Name);
            this.description(bom.Description);
            this.rows(bom.Rows.map(r => createQuotationBillOfMaterialsRow(r, this.props.customerId, this.props.jobOrderId)));
        };

        const onDelete = () => {
            this.props.onDelete(this.props.billOfMaterials());
        };

        return  <Portlet collapsible={false} style={{ marginLeft: 20}} noOverflow>
                    <Portlet.Header>
                        <Portlet.Header.Default title={() => this.props.billOfMaterials()?.Id > 0 ? "Modifica Distinta" : "Nuova Distinta"} className="font-red-sunglo bold uppercase" />
                    </Portlet.Header>
                    <Portlet.Actions>
                        <button className="btn btn-circle blue" onClick={onSave}><i className="fa fa-floppy-o"></i>&nbsp;Salva</button>&nbsp;
                        <button className="btn btn-circle yellow-gold" onClick={onCancel}><i className="fa fa-times"></i>&nbsp;Annulla</button>&nbsp;
                        <button className="btn btn-circle red" onClick={onDelete}><i className="fa fa-trash-o"></i>&nbsp;Elimina</button>&nbsp;
                    </Portlet.Actions>
                    <Portlet.Body>
                        {() => <>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextInput label="Nome" value={this.name} />
                                </div>
                                <div className="col-md-6">
                                    <TextInput label="Descrizione" value={this.textDescription} readonly>
                                        <span className="input-group-btn">
                                            <button className=" btn btn-primary" onClick={() => this.openDescriptionEditor()}>
                                                <i className="fa fa-pencil" />
                                            </button>
                                        </span>
                                    </TextInput>
                                </div>
                            </div>
                            <div className="btn-group flex-container" style={{ alignSelf: 'flex-end'}}>
                                <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("WAR"), color: 'white' }} onClick={() => this.addNew("WAR")}>
                                    <i className="fa fa-plus"/>&nbsp;Articolo
                                </button>
                                <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("EWK"), color: 'white' }} onClick={() => this.addNew("EWK")}>
                                    <i className="fa fa-plus"/>&nbsp;Lavorazione
                                </button>
                                <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("EPC"), color: 'white' }} onClick={() => this.addNew("EPC")}>
                                    <i className="fa fa-plus"/>&nbsp;Acquisto
                                </button>
                                <button className="btn btn-xs btn-circle" style={{ backgroundColor: getEntityColor("BOM"), color: 'white' }} onClick={() => this.addNew("BOM")}>
                                    <i className="fa fa-plus"/>&nbsp;Distinta Base
                                </button>
                            </div>
                            <Layout.ScrollContainer>
                                <Table className="fixed-height" verticalAlign="middle" editable compact scrollable dataSource={{ array: this.rows, factory: this.factory }}>
                                    <Column title="Oggetto">
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => 
                                                <Column.WithIdentifier style={{ backgroundColor: item.Data.model.identifierColor }}>
                                                    {this.renderEditor(item)}
                                                </Column.WithIdentifier>
                                            }
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Quantità" className="text-right" style={{ width: 80 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <NumberInput selectOnFocus simple value={item.Data.model.amount} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Costo U." className="text-right" style={{ width: 100 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitCost} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Costo" className="text-right" style={{ width: 100 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalCost} />}
                                        </ColumnBody>
                                        <ColumnFooter>
                                            {(items : ITableItem<QuotationBillOfMaterialsRow>[]) => ComponentUtils.bindTo(<strong data-bind={{ moneyText: qbome.totalCost }}></strong>, this, 'qbome')}
                                        </ColumnFooter>
                                    </Column>
                                    <Column title="Magine" className="text-right" style={{ width: 80, borderLeft: '5px solid #6685c5' }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <PercentageInput selectOnFocus simple value={item.Data.model.margin} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="P.Unit." className="text-right" style={{ width: 100 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.unitPrice} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Sconto" className="text-right" style={{ width: 80 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <DiscountInput selectOnFocus simple value={item.Data.model.discount} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="P.N.Unit." className="text-right" style={{ width: 100 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus simple value={item.Data.model.netUnitPrice} />}
                                        </ColumnBody>
                                    </Column>
                                    <Column title="Totale" className="text-right" style={{ width: 100 }}>
                                        <ColumnBody>
                                            {(item : ITableItem<QuotationBillOfMaterialsRow>) => <MoneyInput selectOnFocus readonly simple value={item.Data.model.totalPrice} />}
                                        </ColumnBody>
                                        <ColumnFooter>
                                            {(items : ITableItem<QuotationBillOfMaterialsRow>[]) => ComponentUtils.bindTo(<strong data-bind={{ moneyText: qbome.totalPrice }}></strong>, this, 'qbome')}
                                        </ColumnFooter>
                                    </Column>
                                    <Column style={{ width: 115 }} className="text-right">
                                        <ColumnBody>
                                        {(item : ITableItem<QuotationBillOfMaterialsRow>) => <>
                                            {item.Data.model.type === "BOM" &&  <button className="btn btn-xs btn-circle blue">
                                                                                    <i className="fa fa-search"></i>&nbsp;Dettagli
                                                                                </button>}
                                            <button className="btn btn-xs btn-icon-only btn-circle red" onClick={() => this.removeRow(item.Data.model)}>
                                                <i className="fa fa-trash-o"></i>
                                            </button>
                                        </>}
                                        </ColumnBody>
                                    </Column>
                                </Table>
                            </Layout.ScrollContainer>
                        </>}
                    </Portlet.Body>
                </Portlet>
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(QuotationBillOfMaterialsEditor);
}