import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../../../Core/utils/ComponentUtils";
import "../../../Warehouse/warehouse/ui/Articles/ArticleAliasesEditor";
import "../../../Warehouse/warehouse/ui/Articles/ManufacturerAliasesEditor";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IManufacturerAliasesEditor } from "../../../Warehouse/warehouse/ui/Articles/ManufacturerAliasesEditor";
import { IArticleAliasesEditor } from "../../../Warehouse/warehouse/ui/Articles/ArticleAliasesEditor";

let attributes = {
    CustomerId: "customerId",
    Editable: "editable",
    InjectTo: "injectTo"
};

export interface ICustomerAliasesEditor {
    HasChanges: ko.Computed<boolean>;
}

export interface ICustomerAliasesEditorParams {
    CustomerId : Param<number>;
    Editable : Param<boolean>;
    InjectTo : Param<ICustomerAliasesEditor>;
}

export class CustomerAliasesEditor implements ICustomerAliasesEditor {
    CustomerId : ComponentParam<number>;
    Editable : ComponentParam<boolean>;

    ManufacturerAliasesEditor: ko.Observable<IManufacturerAliasesEditor> = ko.observable();
    ArticleAliasesEditor: ko.Observable<IArticleAliasesEditor> = ko.observable();

    HasChanges: ko.Computed<boolean>;

    EditingArticles : ko.Observable<boolean> = ko.observable(false);

    constructor(params : ICustomerAliasesEditorParams) {
        this.CustomerId = ComponentUtils.parseParameter(params.CustomerId, -1);
        this.Editable = ComponentUtils.parseParameter(params.Editable, false);

        this.HasChanges = ko.computed(() => {
            return (this.ManufacturerAliasesEditor() && this.ManufacturerAliasesEditor().HasChanges())
                || (this.ArticleAliasesEditor() && this.ArticleAliasesEditor().HasChanges());
        });

        let injectTo = ComponentUtils.parseParameter(params.InjectTo, undefined);
        if (injectTo)
            injectTo(this);
    }
    
    SwitchToArticles() {
        this.EditingArticles(true);
    }

    SwitchToManufacturers() { 
        this.EditingArticles(false);
    }
}

ko.components.register("customer-aliases-editor", {
    viewModel: {
        createViewModel: (params: ICustomerAliasesEditorParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new CustomerAliasesEditor(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div style="height: 100%; display: flex;">
                    <div class="col-md-4">
                        <div style="width: 230px; top: 0; position: absolute;">
                            <ul class="ver-inline-menu tabbable margin-bottom-10">
                                <li data-bind="css: { 'active': !EditingArticles() }">
                                    <a href="#" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" data-bind="click: SwitchToManufacturers">
                                        <i class="fa fa-truck"></i>&nbsp;{TextResources.Warehouse.ManufacturerAliasesManagement}
                                    </a>
                                </li>
                                <li data-bind="css: { 'active': EditingArticles }">
                                    <a href="#" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" data-bind="click: SwitchToArticles">
                                        <i class="fa fa-truck"></i>&nbsp;{TextResources.Warehouse.ArticleAliasesManagement}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8" style="width: 100%">
                        <ko-ifnot data-bind="EditingArticles">
                            <manufacturer-aliases-editor params="CustomerId: CustomerId, Editable: Editable, InjectTo: ManufacturerAliasesEditor"></manufacturer-aliases-editor>
                        </ko-ifnot>
                        <ko-if data-bind="EditingArticles">
                            <article-aliases-editor params="CustomerId: CustomerId, Editable: Editable, InjectTo: ArticleAliasesEditor"></article-aliases-editor>
                        </ko-if>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});