import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { QueueDetailsElement } from "./QueueDetailsElement";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { TelephoneText } from "../../../../Components/TelephoneText";
import { If } from "../../../../Components/IfIfNotWith";
import { NumericText } from "../../../../Components/NumericText";
import { DateTimeText } from "../../../../Components/DateTimeText";
import { CheckBox } from "../../../../Components/Checkbox";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { DateRangeInput } from "../../../../Components/DateRangeInput";
import { TextInput } from "../../../../Components/TextInput";
import { RangeInput } from "../../../../Components/RangeInput";
import { ExcelExporterButton } from "../../../../Components/ExcelExporterComponent/ExcelExporterComponent";

const styleSheet = jss.createStyleSheet({
    "contacts-table": {
        position: "absolute",
        top: "218px",
        right: "10px",
        bottom: "0px",
        left: "10px",
        overflow: "hidden",

        "& .tempus-dominus-widget": {
            zIndex: "10052",
        },

        "& > .flex-container": {
            height: "100%",
        },

        "& .contact-name": {
            width: "20%",
        },

        "& .contact-phone": {
            width: "10%",
        },

        "& .attempts-number": {
            width: "15%",
        },

        "& .status": {
            width: "15%",
        },

        "& .planned-for": {
            width: "15%",
        },

        "& .reason": {
            width: "10%",
        },

        "& .logical-status": {
            width: "15%",
        },
    },
});
const { classes } = styleSheet.attach();

type QueueWithAutomaticContactSelectionTableProps = {
    campaignId: number;
    queueFilteredElements: ko.ObservableArray<QueueDetailsElement>;
    queueAllElements: ko.ObservableArray<QueueDetailsElement>;
    isLoading: ko.Observable<boolean>;
    showOnlyCallableContacts: ko.Observable<boolean>;
    onSelect?: (contact: QueueDetailsElement) => void;
    onDeselect?: (contact: QueueDetailsElement) => void;
    onContactFilterChanges?: (contact: string | null) => void;
    onStatusFilterChanges?: (selectedStatus: string[]) => void;
    onLastCallResultFilterChanges?: (selectedStatus: string[]) => void;
    onLastCallResultLogicalStatusFilterChanges?: (selectedStatus: string[]) => void;
    onDateRangeFliterChanges?: (from: Date | null, to: Date | null) => void;
    onAttemptsNumberFilterChanges?: (min: number | null, max: number | null) => void;
    onPhoneNumberFilterChanges?: (phoneNumber: string | null) => void;
};

export class QueueWithAutomaticContactSelectionTable implements IDataSourceListener {
    static defaultProps: Partial<QueueWithAutomaticContactSelectionTableProps> = {};
    private manualSelectionEnabled = false;
    private viewAllContacts = false;

    private ContactSearchField: ko.Observable<string> = ko.observable(null);
    private PhoneSearchField: ko.Observable<string> = ko.observable(null);
    private PlannedFrom: ko.Observable<Date> = ko.observable(null);
    private PlannedTo: ko.Observable<Date> = ko.observable(null);
    private AttemptsNumberMin: ko.Observable<number> = ko.observable(null);
    private AttemptsNumberMax: ko.Observable<number> = ko.observable(null);

    private subscriptions: ko.Subscription[] = [];

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    constructor(private props: QueueWithAutomaticContactSelectionTableProps) {
        this.manualSelectionEnabled = this.authorizationService.isAuthorized(
            "SurveyWizard_ManualNextContactSelecitonOnWizard"
        );

        this.viewAllContacts = this.authorizationService.isAuthorized("SurveyWizard_ViewAllCampaignContactsToCall");

        this.subscriptions.push(
            this.ContactSearchField.subscribe((value) => this.props?.onContactFilterChanges(value)),
            this.PlannedFrom.subscribe((value) => this.props.onDateRangeFliterChanges?.(value, this.PlannedTo())),
            this.PlannedTo.subscribe((value) => this.props.onDateRangeFliterChanges?.(this.PlannedFrom(), value)),
            this.AttemptsNumberMin.subscribe((value) =>
                this.props.onAttemptsNumberFilterChanges?.(value, this.AttemptsNumberMax())
            ),
            this.AttemptsNumberMax.subscribe((value) =>
                this.props.onAttemptsNumberFilterChanges?.(this.AttemptsNumberMin(), value)
            ),
            this.PhoneSearchField.subscribe((value) => this.props.onPhoneNumberFilterChanges?.(value))
        );
    }

    onItemSelected(
        sender: IDataSource<number, QueueDetailsElement>,
        model: IDataSourceModel<number, QueueDetailsElement>
    ): void {
        this.onSelect(model.model);
    }

    onItemDeselected(
        sender: IDataSource<number, QueueDetailsElement>,
        model: IDataSourceModel<number, QueueDetailsElement>
    ): void {
        this.onDeselect(model.model);
    }

    private onSelect(contact: QueueDetailsElement): void {
        if (!this.manualSelectionEnabled) return;

        this.props.onSelect?.(contact);
    }

    private onDeselect(contact: QueueDetailsElement): void {
        if (!this.manualSelectionEnabled) return;

        this.props.onDeselect?.(contact);
    }

    private onStatusFilterChanges(selectedStatus: string[]): void {
        this.props.onStatusFilterChanges?.(selectedStatus);
    }

    private onLastCallResultFilterChanges(selectedStatus: string[]): void {
        this.props.onLastCallResultFilterChanges?.(selectedStatus);
    }

    private onLastCallResultLogicalStatusFilterChanges(selectedStatus: string[]): void {
        this.props.onLastCallResultLogicalStatusFilterChanges?.(selectedStatus);
    }

    render() {
        const qe: IDataSourceModel<number, QueueDetailsElement> = null;
        const css = ComponentUtils.classNames("dataTables_scroll", classes["contacts-table"]);
        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<QueueDetailsElement>();

        const renderHeaderActions = () => (
            <div className="flex-container flex-fill">
                <ExcelExporterButton
                    className="btn-sm"
                    position="right"
                    exporterId="SurveyWizard/QueueAdvancedExport"
                    exporterMethod="GenerateExcel"
                    dataProvider={() => ({
                        id: this.props.campaignId,
                        showOnlyCallableContacts: this.props.showOnlyCallableContacts(),
                    })}>
                    <i className="fa fa-download"></i>&nbsp;{TextResources.SurveyWizard.ExportQueueToExcel}
                </ExcelExporterButton>
                {this.viewAllContacts && (
                    <div style="padding-bottom: 5px">
                        <CheckBox
                            checked={this.props.showOnlyCallableContacts}
                            switch
                            switchSize="small"
                            switchLabels={{
                                onLabel: TextResources.ProlifeSdk.Yes,
                                offLabel: TextResources.ProlifeSdk.No,
                            }}
                        />
                        <span style={{ marginLeft: "10px" }}>
                            {TextResources.SurveyWizard.ShowOnlyCallableContacts}
                        </span>
                    </div>
                )}
            </div>
        );

        return ComponentUtils.bindTo(
            <div className={css}>
                <Table
                    id={ProlifeSdk.AutomaticContactSelectionTable}
                    bordered
                    compact
                    systemScrollable
                    selectableRows={this.manualSelectionEnabled}
                    showColumnSelector
                    columnSelectorPosition="right"
                    showLoadingIndicator={this.props.isLoading}
                    sortableColumns
                    enableAggregators
                    fixedLayout
                    headerActions={renderHeaderActions}
                    listener={this}
                    rowAs="qe"
                    dataSource={Table.defaultDataSource(this.props.queueFilteredElements, (c) => ({
                        id: c.PeopleId,
                        title: c.ContactIdentifier(),
                    }))}>
                    <Column
                        id={1}
                        title={TextResources.SurveyWizard.ContactNameColumn}
                        className="contact-name"
                        sorter={sortString((c) => c.ContactIdentifier())}
                        aggregateOn={(item) => item.Data.model.ContactIdentifier()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.ContactNameColumn}</span>
                                    <TableFilter customFilterStateHandler={() => !!this.ContactSearchField()}>
                                        {() => (
                                            <TextInput
                                                value={this.ContactSearchField}
                                                label={TextResources.SurveyWizard.ConatctSearchFilterLabel}
                                                placeholder={TextResources.SurveyWizard.ConatctSearchFilterPlacehoder}
                                            />
                                        )}
                                    </TableFilter>
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <span>{contact.Data.model.ContactIdentifier()}</span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={2}
                        title={TextResources.SurveyWizard.ContactPhoneNumberColumn}
                        className="contact-phone"
                        sorter={sortString((c) => c.PhoneNumber())}
                        aggregateOn={(item) => item.Data.model.PhoneNumber()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.ContactPhoneNumberColumn}</span>
                                    <TableFilter customFilterStateHandler={() => !!this.PhoneSearchField()}>
                                        {() => (
                                            <TextInput
                                                value={this.PhoneSearchField}
                                                label={TextResources.SurveyWizard.PhoneSearchFilterLabel}
                                                placeholder={TextResources.SurveyWizard.PhoneSearchFilterPlacehoder}
                                            />
                                        )}
                                    </TableFilter>
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <TelephoneText phoneNumber={contact.Data.model.PhoneNumber} />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={3}
                        title={TextResources.SurveyWizard.NumberOfAttemptsColumn}
                        className="attempts-number text-right"
                        sorter={sortNumber((c) => c.NumberOfAttempts())}
                        aggregateOn={(item) => item.Data.model.NumberOfAttempts()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.NumberOfAttemptsColumn}</span>
                                    <TableFilter
                                        customFilterStateHandler={() =>
                                            (this.AttemptsNumberMin() !== null &&
                                                this.AttemptsNumberMin() !== undefined) ||
                                            (this.AttemptsNumberMax() !== null &&
                                                this.AttemptsNumberMax() !== undefined)
                                        }>
                                        {() => (
                                            <RangeInput
                                                title={TextResources.SurveyWizard.AttemptsNumberFilter}
                                                minValue={this.AttemptsNumberMin}
                                                maxValue={this.AttemptsNumberMax}
                                                format="0,0"
                                            />
                                        )}
                                    </TableFilter>
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <span>
                                    <NumericText value={contact.Data.model.NumberOfAttempts} format="0,0" />
                                    <If condition={contact.Data.model.ShowAttemptsDetails}>
                                        {() => (
                                            <button
                                                className="attempts-details btn btn-xs btn-link"
                                                data-bind={{
                                                    asyncClick: qe.model.ShowAttemptsPopOver.bind(qe.model),
                                                    clickBubble: false,
                                                }}
                                                title="Dettaglio tentativi"
                                                data-placement="bottom"
                                                style={{
                                                    display: "inline-block",
                                                    width: "25px",
                                                    height: "auto",
                                                    textAlign: "center",
                                                }}>
                                                <i className="fa fa-chevron-down" style={{ cursor: "pointer" }}></i>
                                            </button>
                                        )}
                                    </If>
                                </span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={4}
                        title={TextResources.SurveyWizard.StatusColumn}
                        className="status"
                        sorter={sortString((c) => c.Label())}
                        aggregateOn={(item) => item.Data.model.Label()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.StatusColumn}</span>
                                    <TableFilter
                                        filterSource={this.props.queueAllElements}
                                        itemKeyGetter={(item: QueueDetailsElement) => item.Label()}
                                        itemLabelGetter={(item: QueueDetailsElement) => item.Label()}
                                        onSelectionChange={this.onStatusFilterChanges.bind(this)}
                                    />
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => <span>{contact.Data.model.Label()}</span>}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={5}
                        title={TextResources.SurveyWizard.PlannedForColumn}
                        className="planned-for"
                        sorter={sortDate((c) => c.DateTimeToCall())}
                        aggregateOn={(item) => item.Data.model.DateTimeToCall()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.PlannedForColumn}</span>
                                    <TableFilter
                                        customFilterStateHandler={() => !!this.PlannedFrom() || !!this.PlannedTo()}
                                        popoverContainer=".dataTables_scroll"
                                        popoverViewport=".dataTables_scroll">
                                        {() => (
                                            <DateRangeInput
                                                title={TextResources.SurveyWizard.PlannedForColumn}
                                                startDate={this.PlannedFrom}
                                                endDate={this.PlannedTo}
                                                datePickerParent=".dataTables_scroll"
                                            />
                                        )}
                                    </TableFilter>
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <DateTimeText dateTime={contact.Data.model.DateTimeToCall} />
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={6}
                        title={TextResources.SurveyWizard.ReasonColumn}
                        className="reason"
                        sorter={sortString((c) => c.LastCallResultLabel())}
                        aggregateOn={(item) => item.Data.model.LastCallResultLabel()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.ReasonColumn}</span>
                                    <TableFilter
                                        filterSource={this.props.queueAllElements}
                                        itemKeyGetter={(item: QueueDetailsElement) => item.LastCallResultLabel()}
                                        itemLabelGetter={(item: QueueDetailsElement) =>
                                            !item.LastCallResultLabel()
                                                ? TextResources.SurveyWizard.FirstCall
                                                : item.LastCallResultLabel()
                                        }
                                        onSelectionChange={this.onLastCallResultFilterChanges.bind(this)}
                                    />
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <span>
                                    {!contact.Data.model.LastCallResultLabel()
                                        ? TextResources.SurveyWizard.FirstCall
                                        : contact.Data.model.LastCallResultLabel()}
                                </span>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column
                        id={7}
                        title={TextResources.SurveyWizard.ReasonLogicalStatusColumn}
                        className="logical-status"
                        sorter={sortString((c) => c.LastCallResultLogicalStatusLabel())}
                        aggregateOn={(item) => item.Data.model.LastCallResultLogicalStatusLabel()}>
                        <ColumnHeader>
                            {() => (
                                <>
                                    <span>{TextResources.SurveyWizard.ReasonLogicalStatusColumn}</span>
                                    <TableFilter
                                        filterSource={this.props.queueAllElements}
                                        itemKeyGetter={(item: QueueDetailsElement) =>
                                            item.LastCallResultLogicalStatusLabel()
                                        }
                                        itemLabelGetter={(item: QueueDetailsElement) =>
                                            !item.LastCallResultLogicalStatusLabel()
                                                ? TextResources.ProlifeSdk.NotAvailable
                                                : item.LastCallResultLogicalStatusLabel()
                                        }
                                        onSelectionChange={this.onLastCallResultLogicalStatusFilterChanges.bind(this)}
                                    />
                                </>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(contact: ITableItem<QueueDetailsElement>) => (
                                <span>
                                    {!contact.Data.model.LastCallResultLogicalStatusLabel()
                                        ? TextResources.ProlifeSdk.NotAvailable
                                        : contact.Data.model.LastCallResultLogicalStatusLabel()}
                                </span>
                            )}
                        </ColumnBody>
                    </Column>
                </Table>
            </div>,
            this,
            "qt"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(QueueWithAutomaticContactSelectionTable);
}
