import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IHumanResourcesService, IHumanResource } from "../../HumanResourcesService";
import { IHumanResourcesSettingsManager } from "../Settings/HumanResourcesSettingsManager";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { IHumanResourcesControlsEntityProvider } from "../../../ProlifeSdk/interfaces/users/IHumanResourcesControlsEntityProvider";

export interface ISelect2Resource {
    id: number;
    text: string;
    deleted?: boolean;
    disabled?: boolean;
}

export class HumanResourcesControlsEntityProvider implements IHumanResourcesControlsEntityProvider {
    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService: IHumanResourcesService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourceSettingsManager: IHumanResourcesSettingsManager;
    private lastTimeout: ReturnType<typeof setTimeout>;

    public GetHumanResources = true;
    public GetMaterialResources = true;
    public ShowDeletedResources = false;
    public ShowDisabledResources = false;
    public SimpleSearch = false;

    constructor() {}

    public findEntities(query: any) {
        if (this.lastTimeout) clearTimeout(this.lastTimeout);

        if (this.SimpleSearch) this.doSimpleSearch(query);
        else this.doComplexSearch(query);
    }

    private doSimpleSearch(query: any) {
        this.lastTimeout = setTimeout(() => {
            this.humanResourcesService.GetHumanResourcesForList({ textFilter: query.term }).then((data) => {
                query.callback({
                    results: data.map((user) => {
                        return {
                            id: user.Id,
                            text: this.humanResourcesService.getFullName({
                                Resource: <any>user,
                                Orders: [],
                                OrdersRoles: [],
                                OrdersSalaries: [],
                                OrdersWorkingHours: [],
                            }),
                        };
                    }),
                });
            });
        }, 500);
    }

    private doComplexSearch(query: any) {
        this.lastTimeout = setTimeout(() => {
            this.humanResourcesService
                .GetHumanResources(
                    query.term,
                    this.ShowDeletedResources,
                    this.ShowDisabledResources,
                    this.GetMaterialResources
                )
                .then((data) => {
                    query.callback({
                        results: data
                            .filter(
                                (r: IHumanResource) =>
                                    r.Resource.ResourceType !== 0 ||
                                    (r.Resource.ResourceType === 0 && this.GetHumanResources)
                            )
                            .map((user: IHumanResource) => {
                                return {
                                    id: user.Resource.Id,
                                    text: this.humanResourcesService.getFullName(user),
                                };
                            }),
                    });
                });
        }, 500);
    }

    /***
     * Estrae l'id già impostato sull'elemento e lo ritorna in formato lista
     * @param element
     * @param callback
     */
    public findEntity(element, callback) {
        const id = $(element).val();
        if (id !== "" && id > 0) {
            this.humanResourcesService.GetHumanResource(<number>id).then((user: IHumanResource) =>
                callback({
                    id: user.Resource.Id,
                    text: this.humanResourcesService.getFullName(user),
                    disabled: user.Resource.Disabled,
                    deleted: user.Resource.Deleted,
                })
            );
        }
    }

    /***
     * Estrae gli id già impostati sull'elemento e li ritorna in formato lista
     * @param element
     * @param callback
     */
    public findMultipleEntities(element, callback) {
        const ids: string[] = (<string>$(element).val()).split("|");
        if (ids.length == 0) return;

        const result: any[] = [];
        ids.map((s: string) => {
            return parseInt(s);
        }).forEach((id: number) => {
            const r: IHumanResource = this.humanResourceSettingsManager.getHumanResourceById(id);

            result.push({
                id: id,
                text: this.humanResourcesService.getFullName(r),
                disabled: r.Resource.Disabled,
                deleted: r.Resource.Deleted,
            });
        });

        callback(result);
    }

    public GetFormattedNameForSelection(resource: ISelect2Resource): string {
        return (
            '<span style="' +
            (resource.deleted || resource.disabled ? "text-decoration: line-through" : "") +
            '" >' +
            resource.text +
            "</span>"
        );
    }
}
