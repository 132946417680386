import { InvoiceConfiguration } from "./InvoiceConfiguration";
import { AccompanyingInvoiceConfiguration } from "./AccompanyingInvoiceConfiguration";
import { CreditNoteConfiguration } from "./CreditNoteConfiguration";
import { EstimateConfiguration } from "./EstimateConfiguration";
import { DDTConfiguration } from "./DDTConfiguration";
import { SalConfiguration } from "./SALConfiguration";
import { CustomerOrderConfiguration } from "./CustomerOrderConfiguration";
import { SupplierOrderConfiguration } from "./SupplierOrderConfiguration";
import { WarehouseLoadConfiguration } from "./WarehouseLoadConfiguration";
import { RequestForQuotationConfiguration } from "./RequestForQuotationConfiguration";
import { PurchaseRequestConfiguration } from "./PurchaseRequestConfiguration";
import { PassiveInvoiceConfiguration } from "./PassiveInvoiceConfiguration";

export const Configurations = [
    new InvoiceConfiguration(),
    new AccompanyingInvoiceConfiguration(),
    new CreditNoteConfiguration(),
    new EstimateConfiguration(),
    new DDTConfiguration(),
    new SalConfiguration(),
    new CustomerOrderConfiguration(),
    new SupplierOrderConfiguration(),
    new WarehouseLoadConfiguration(),
    new RequestForQuotationConfiguration(),
    new PurchaseRequestConfiguration(),
    new PassiveInvoiceConfiguration()
];