/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 16.08
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { SettingsViewModel } from "./settings/ui/SettingsViewModel";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IOPAService } from "../Core/interfaces/IOPAService";
import { IApplicationsService } from "../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../Desktop/interfaces/IApplication";
import { LazyImport } from "../Core/DependencyInjection";

export class SettingsApplication implements IApplication {
    public templateName = "settings";
    public templateUrl = "settings/templates";
    public templateData: any;
    public tileColor = "tile-grey";

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService: IApplicationsService;
    @LazyImport(nameof<IOPAService>())
    private opaService: IOPAService;

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService.registerApplication(this);
    }

    getApplicationCode() {
        return "Settings";
    }

    getName(): string {
        return ProlifeSdk.TextResources.Settings.Settings;
    }

    getIcon(): string {
        return "icon-settings";
    }

    registerRoutes(): void {
        this.opaService.Get(ProlifeSdk.TextResources.Settings.SettingsURL, () => this.start());
    }

    getApplicationRoute(): string {
        return ProlifeSdk.TextResources.Settings.SettingsURL;
    }

    onGoingDown(): void {
        this.templateData.dispose();
        delete this.templateData;
        this.templateData = null;
    }

    private start() {
        this.templateData = new SettingsViewModel(this.serviceLocator);
        this.applicationsService.startApplication(this);
    }
}
