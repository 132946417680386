import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { PositionableContainer } from "./PositionableContainer";
import { ReportPanel } from "../Components/ReportPanel";
import TsxForEach from "../../ForEach";
import { ReportComponent, ReportPage } from "../Components";
import { RendererFor, ReportRendererProvider } from "./ReportRendererProvider";
import jsPDF, { Matrix } from "jspdf";
import { ExportBackground } from "../Exporters/ReportBackgroundExporter";
import { CentimetersToPDFUnit } from "../Utilities/UnitOfMeasureUtils";
import { ExportChildren } from "../Exporters/ReportChildrenExporter";
import { ReportFlowPanel } from "../Components/ReportFlowPanel";
import { _ReportDesigner } from "../ReportDesigner";

type ReportPanelRendererProps = {
    component: ReportPanel;
    parentPage: ReportPage;
    reportDesigner: _ReportDesigner;
}

export function ReportPanelRenderer(props: ReportPanelRendererProps) {
    const C = require("./ReportPanelRenderer")._ReportPanelRenderer as typeof _ReportPanelRenderer;
    return <C {...props} />;
}

@RendererFor(nameof<ReportPanel>())
@RendererFor(nameof<ReportFlowPanel>())
export class _ReportPanelRenderer {
    static defaultProps: Partial<ReportPanelRendererProps> = {
    }

    constructor(private props : ReportPanelRendererProps) {
        
    }
    
    render() {
        const _rdt = this;
        return <PositionableContainer component={this.props.component}>
            {() => ComponentUtils.bindTo(
                <div style={{ position: 'absolute', inset: 0, overflow: 'hidden' }} data-bind={{ style: { backgroundColor: _rdt.props.component.backgroundColor, backgroundImage: _rdt.props.component.backgroundImageCSSUrl, backgroundSize: _rdt.props.component.backgroundSize }}}>
                    <TsxForEach data={this.props.component.children} as="child">
                        {(child) => this.renderChild(child)}
                    </TsxForEach>
                </div>
            , this, '_rdt')}
        </PositionableContainer>;
    }

    renderChild(child: ReportComponent) {
        const Renderer = ReportRendererProvider.createRenderer(child);
        return <Renderer component={child} parentPage={this.props.parentPage} reportDesigner={this.props.reportDesigner} />
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportPanelRenderer);
}