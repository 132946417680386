import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/08/2017
 * Time: 10:19
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { SplashPage } from "./SplashPage";
import { EntitiesListLockManager } from "../../../../ProlifeSdk/prolifesdk/controls/behaviours/EntitiesListLockManager";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ISurveyWizardService } from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { ICampaignForWizardSplash } from "../../../../ProlifeSdk/interfaces/survey/ICampaign";
import { ILockableItem } from "../../../../ProlifeSdk/interfaces/desktop/IEntitiesLockService";

export class SplashPageList {
    public templateName: string = "splash-page-list";
    public templateUrl: string = "surveywizard/templates/";

    public Campaigns: ko.ObservableArray<CampignForWizardSplash> = ko.observableArray([]);

    @LazyImport(nameof<ISurveyWizardService>())
    private surveyWizardService: ISurveyWizardService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;

    private LockManager : EntitiesListLockManager;
	
	private canLoadNextBlock: boolean = true;

    constructor(private parent: SplashPage) {
        this.LockManager = new EntitiesListLockManager(this.Campaigns, ProlifeSdk.SurveyEntityTypeCode);
    }

    public dispose()
    {
        this.LockManager.Dispose();
    }

    Refresh() {
        this.Campaigns([]);
        this.LoadNextBlock();
        this.LoadCount();
    }

    LoadNextBlock()
    {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Survey.Loading);

        //Prima di caricare i dati aspetto che il lock manager sia pronto...
        this.LockManager.WhenIsReady().then(() => {
			if (this.canLoadNextBlock) {
				this.canLoadNextBlock = false;
				this.surveyWizardService.getCampaignsForWizardSplash(this.parent.GetFilters(), this.Campaigns().length, 30)
					.then((campaigns: ICampaignForWizardSplash[]) => {
						var newList = this.Campaigns();
						campaigns.map((j: ICampaignForWizardSplash) => { return this.CreateViewModelFor(j)}).forEach((j) => { newList.push(j)});
						this.Campaigns(newList);
						this.LoadCount();
					}).finally(() => { 
						this.desktopService.UnblockPageUI();
						setTimeout(() => {
							this.canLoadNextBlock = true;
						}, 500);
					});
			}
        });
    }

    LoadCount() {
        this.surveyWizardService.getCampaignsListForWizardSplashCount(this.parent.GetFilters())
            .then((count: number) => {
                this.parent.ListElementsCount(count);
            });
    }

    private CreateViewModelFor(j: ICampaignForWizardSplash)
    {
        return new CampignForWizardSplash(j, this);
    }
}

export class CampignForWizardSplash implements ILockableItem {
    public ItemKey: number;
    public IsItemLocked: ko.Observable<boolean> = ko.observable(false);

    public CampaignUrl: ko.Observable<string> = ko.observable();

    public QuestionnaireIdentifier: ko.Observable<string> = ko.observable();
    public SampleIdentifier: ko.Observable<string> = ko.observable();

    private CanOpen: ko.Computed<boolean>;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(public campaign: ICampaignForWizardSplash, private parent: SplashPageList) {
        this.ItemKey = campaign.Id;

        this.CampaignUrl("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/" + this.campaign.Id);

        this.QuestionnaireIdentifier(campaign.QuestionnaireTitle + " - Ver. " + campaign.QuestionnaireVersion);
        this.SampleIdentifier(campaign.SampleTitle + " (" + campaign.CalledSamples + "/" + campaign.SampleSize + ")");

        this.CanOpen = ko.computed(() => {
            var now: moment.Moment = moment();
            var campaignDate: moment.Moment = moment(this.campaign.StartDate).startOf("day");
            return this.campaign.LogicalState != ProlifeSdk.OverCampaignState && campaignDate.isBefore(now);
        });
    }

    public OpenCampaign()
    {
        if (!this.CanOpen()) {
            this.infoToastService.Warning(String.format(TextResources.SurveyWizard.CampaignCannotStart, moment(this.campaign.StartDate).format("L")));
            return;
        }

        location.href = this.CampaignUrl();    
    }
}