import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 23/11/2017
 * Time: 17:02
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ResourceAllocationsGantt } from "../ResourceAllocationsGantt";
import { ResourceAllocationRangeViewModel } from "../ResourceAllocationRangeViewModel";
import { TeamForResourceViewModel, AllocationRangesForTeamViewModel, ITeamViewModel, ITeamsManagerProvider, ITeamsManagerResource, ITeamsManagerOperationalUnit, ITeamAllocationViewModel, IResourceOperationalUnitViewModel, IResourceAllocationRangeViewModel } from "../TeamsManager";
import { IHumanResourceOrders } from "../../../../Users/HumanResourcesService";
import { IAllocationsService, IFullInterval, IAllocationInterval, IServiceOrderForInterval, IAllocationIntervalDetails } from "../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IOperationalUnit } from "../../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IAllocationPerDays } from "../../../interfaces/IAllocationPerDays";
import { IDateForList, IDateRange } from "../../../interfaces/IDateRange";

export class ResourceReallocationDialog implements IDialog  {
    public templateName = "resource-reallocation-dialog";
    public templateUrl = "allocations/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Allocations.ResourceReallocationDialogTitle;

    public FirstTitlePart: string = ProlifeSdk.TextResources.Allocations.ResourceReallocationDialogFirstTitlePart;
    public SecondTitlePart: string = ProlifeSdk.TextResources.Allocations.ResourceReallocationDialogSecondTitlePart;
    public ThirdTitlePart: string = ProlifeSdk.TextResources.Allocations.ResourceReallocationDialogThirdTitlePart;

    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();
    public ResourceName: ko.Observable<string> = ko.observable();
    public OpUnitName: ko.Observable<string> = ko.observable();
    public TeamName: ko.Observable<string> = ko.observable();
    public PercentageAmount: ko.Observable<number> = ko.observable();
    public HoursAmount: ko.Observable<number> = ko.observable();
    public AllocationPerDays: any;
    public EditingMode: ko.Observable<number> = ko.observable();
    private lastEditingMode: number;
    public EditingModes: ko.ObservableArray<EditingMode> = ko.observableArray([]);

    public ResourceGantt: ResourceAllocationsGantt;
    public Intervals: ko.ObservableArray<FullInterval> = ko.observableArray([]);

    public PendingIntervalApplication: ko.Observable<boolean> = ko.observable(false);
    public IsValidInterval: ko.Observable<boolean> = ko.observable(true);
    public ResetDataBeforeApplyNewAllocation: ko.Observable<boolean> = ko.observable(true);
    public ApplyAllocationOnSelectedIntervals: ko.Observable<boolean> = ko.observable(false);

    public selectedTeam: ITeamViewModel;

    public PercentageEditingEnabled: ko.Computed<boolean>;
    public HoursEditingEnabled: ko.Computed<boolean>;
    public HoursPerDayEditingEnabled: ko.Computed<boolean>;
    public ManualEditingOnIntervalsEnabled: ko.Computed<boolean>;
    public HelperFieldsHasChanged: ko.Computed<boolean>;

    modal: { close: (result?: any) => void; };
    
    private allocationsService: IAllocationsService;
    private infoToastService: IInfoToastService;
    private dialogsService: IDialogsService;

    private teamsManagerProvider : ITeamsManagerProvider;

    private helperInputFieldsState: any;

    constructor(private serviceLocator: IServiceLocator, public selectedTeamId: number, public resourceId: number, public opUnit: IOperationalUnit, startDate: Date, endDate: Date, allocationPercentage: number, allocationPerDays: IAllocationPerDays, resourceName: string, private originalTeamsManagerProvider: ITeamsManagerProvider) {
        this.allocationsService = <IAllocationsService> this.serviceLocator.findService(ProlifeSdk.AllocationsServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.teamsManagerProvider = originalTeamsManagerProvider.Clone();

        this.ResourceGantt = new ResourceAllocationsGantt(this.serviceLocator, this.teamsManagerProvider, this.resourceId, this.opUnit.Id, resourceName);
        this.ResourceGantt.Gantt.StartDate(startDate);
        //this.ResourceGantt.Gantt.ScrollToTodayMarker(2);
        this.selectedTeam = originalTeamsManagerProvider.GetTeam(this.selectedTeamId);

        this.StartDate(startDate);
        this.EndDate(endDate);
        this.ResourceName(resourceName);
        this.OpUnitName(this.opUnit.Name);
        this.TeamName(this.selectedTeam.Name());
        this.PercentageAmount(allocationPercentage);
        this.HoursAmount(0);
        this.AllocationPerDays = this.CreateAllocationPerHoursViewModel(allocationPerDays);

        this.EditingModes.push(new EditingMode(ProlifeSdk.PercentageEditigMode, ProlifeSdk.TextResources.Allocations.PercentageEditingMode));
        this.EditingModes.push(new EditingMode(ProlifeSdk.HoursEditigMode, ProlifeSdk.TextResources.Allocations.HoursEditingMode));
        this.EditingModes.push(new EditingMode(ProlifeSdk.ServiceOrderEditigMode, ProlifeSdk.TextResources.Allocations.ServiceOrderEditingMode));
        this.EditingModes.push(new EditingMode(ProlifeSdk.IntervalEditigMode, ProlifeSdk.TextResources.Allocations.IntervalEditingMode));

        this.StartDate.subscribe(() => {
            if(moment(this.StartDate()) > moment(this.EndDate()))
                this.StartDate(this.EndDate());

            this.PendingIntervalApplication(true);
        });

        this.EndDate.subscribe(() => {
            if(moment(this.EndDate()) < moment(this.StartDate()))
                this.EndDate(this.StartDate());

            this.PendingIntervalApplication(true);
        });

        this.EditingMode(!allocationPerDays ? ProlifeSdk.PercentageEditigMode : ProlifeSdk.ServiceOrderEditigMode);
        this.lastEditingMode = this.EditingMode();

        this.PercentageEditingEnabled = ko.computed(() => {
            return this.EditingMode() == ProlifeSdk.PercentageEditigMode;
        });

        this.HoursEditingEnabled = ko.computed(() => {
            return this.EditingMode() == ProlifeSdk.HoursEditigMode;
        });

        this.HoursPerDayEditingEnabled = ko.computed(() => {
            return this.EditingMode() == ProlifeSdk.ServiceOrderEditigMode;
        });

        this.ManualEditingOnIntervalsEnabled = ko.computed(() => {
            return this.EditingMode() == ProlifeSdk.IntervalEditigMode;
        });

        this.PendingIntervalApplication(false);

        this.ApplyAllocationOnSelectedIntervals.subscribe((value: boolean) => {
            if (!value) {
                const availableIntervals: FullInterval[] = this.Intervals().filter((i: FullInterval) => { return i.Selected(); });
                if (availableIntervals.length > 0) {
                    for (let i = 1; i < availableIntervals.length; i++)
                        availableIntervals[i].Selected(false);
                    this.HighlightIntervalOnGantt(availableIntervals[0].Interval.StartDate(), availableIntervals[0].Interval.EndDate())
                }
            }
        });

        this.EditingMode.subscribe((value: number) => {
            if (value != this.lastEditingMode && value == ProlifeSdk.IntervalEditigMode) {
                this.SaveIntervalsState();
            }
            if (value != this.lastEditingMode && this.lastEditingMode == ProlifeSdk.IntervalEditigMode) {
                if (this.HasPendingAllocations()) {
                    this.EditingMode(this.lastEditingMode);
                    this.dialogsService.Alert(ProlifeSdk.TextResources.Allocations.PendingAllocationApplicationError, ProlifeSdk.TextResources.Allocations.PendingAllocationAlert, () => {});
                    return;
                }
            }
            this.lastEditingMode = value;
        });

        this.helperInputFieldsState = {
            percentageValue: ko.observable(),
            hoursAmount: ko.observable(),
            dailyHours: ko.observable()
        };

        this.HelperFieldsHasChanged = ko.computed(() => {
            return (this.PercentageEditingEnabled() && this.helperInputFieldsState.percentageValue() != this.PercentageAmount()) ||
                (this.HoursEditingEnabled() && this.helperInputFieldsState.hoursAmount() != this.HoursAmount()) ||
                (this.HoursPerDayEditingEnabled() && !!this.helperInputFieldsState.dailyHours() && !this.AllocationPerDays.IsEquivalent(this.helperInputFieldsState.dailyHours())) ||
                (this.ManualEditingOnIntervalsEnabled && this.HasPendingAllocations());
        });

        //this.SaveHelperInputFieldsState();

        this.LoadIntervals();
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        if (this.ManualEditingOnIntervalsEnabled() && this.HasPendingAllocations()) {
            this.EditingMode(this.lastEditingMode);
            this.dialogsService.Alert(ProlifeSdk.TextResources.Allocations.PendingAllocationApplicationError, ProlifeSdk.TextResources.Allocations.PendingAllocationAlert, () => {});
            return;
        }

        if (this.HasOverAllocations()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Allocations.OverAllocationsWarning,
                ProlifeSdk.TextResources.Allocations.CancelButton,
                ProlifeSdk.TextResources.Allocations.ConfirmButton,
                (confirm: boolean) => {
                    if (confirm) {
                        /*this.dialogsService.LockUI(ProlifeSdk.TextResources.Core.Loading);
                        setTimeout(() => { this.modal.close(this.CreateAllocationRanges()); }, 150);*/
                        this.teamsManagerProvider.OptimizeAllocations(this.resourceId, this.opUnit.Id);
                        this.modal.close(this.teamsManagerProvider);
                    }
                }
            );
            return;
        }

        this.teamsManagerProvider.OptimizeAllocations(this.resourceId, this.opUnit.Id);
        this.modal.close(this.teamsManagerProvider);

        /*this.dialogsService.LockUI(ProlifeSdk.TextResources.Core.Loading);
        setTimeout(() => { this.modal.close(this.CreateAllocationRanges()); }, 150);*/
    }

    private SaveHelperInputFieldsState(): void {
        this.helperInputFieldsState.percentageValue(this.PercentageAmount());
        this.helperInputFieldsState.hoursAmount(this.HoursAmount());
        this.helperInputFieldsState.dailyHours(this.AllocationPerDays.Copy());
    }

    private SaveIntervalsState(): void {
        const intervals: FullInterval[] = this.Intervals().filter((i: FullInterval) => !i.Locked());
        intervals.forEach((i: FullInterval) => {
            i.SaveIntervalsState();
        });
    }

    private HasPendingAllocations(): boolean {
        const intervals: FullInterval[] = this.Intervals().filter((i: FullInterval) => !i.Locked() && i.HasManualChanges());

        if (intervals.length > 0) {
            return true;
        }

        return false;
    }

    public HighlightIntervalOnGantt(startDate: any, endDate: any): void {
        const newEndDate: Date = !endDate ? moment("2100-01-01").toDate() : moment(endDate).toDate();
        this.ResourceGantt.HighlightInterval(moment(startDate).toDate(), newEndDate);
    }

    private HasOverAllocations(): boolean {
        let overWork = false;

        this.Intervals().forEach((i: FullInterval) => {
            if (i.Totals.HasOverAllocations())
                overWork = true;
        });

        return overWork;
    }

    private LoadIntervals(): void {
        const resource: ITeamsManagerResource = this.originalTeamsManagerProvider.GetResource(this.resourceId);
        const ou: ITeamsManagerOperationalUnit = resource.GetOperationalUnit(this.opUnit.Id);
        if (!ou)
            return;

        this.IsValidInterval(true);

        const orderedDatesList: IDateForList[] = this.getOrderedDatesList(ou);
        const ranges: IDateRange[] = this.createIntervals(orderedDatesList);
        const intervals: FullInterval[] = ranges.map((r: IDateRange) => { return this.createFullInterval(r, ou); });

        this.Intervals(intervals);
        this.InitializeCurrentTeamAllocationsFromIntervals();
    }

    private createFullInterval(range: IDateRange, ou: ITeamsManagerOperationalUnit): FullInterval {
        const fullInterval: IFullInterval = <IFullInterval> {
            Interval: <IAllocationInterval> range,
            Intervals: [],
            ServiceOrder: ou.ServiceOrders().filter(o => {
                return moment(o.FromDate).startOf('day') <= moment(range.StartDate) && (!o.ToDate || moment(o.ToDate).endOf('day') > moment(range.EndDate))
            }).map(o => {
                return <IServiceOrderForInterval> {
                    Id: o.Id,
                    HoursMonday: o.HoursMonday,
                    HoursTuesday: o.HoursTuesday,
                    HoursWednesday: o.HoursWednesday,
                    HoursThursday: o.HoursThursday,
                    HoursFriday: o.HoursFriday,
                    HoursSaturday: o.HoursSaturday,
                    HoursSunday: o.HoursSunday
                };
            })[0]
        };

        ou.Teams().forEach((t: TeamForResourceViewModel) => {
            t.AllocationRanges().forEach((r: AllocationRangesForTeamViewModel) => {
                if (moment(r.StartDate()) <= moment(range.StartDate) && moment(r.EndDate()) >= moment(range.EndDate)
                    || (moment(r.StartDate()) >= moment(range.StartDate) && moment(r.StartDate()) <= moment(range.EndDate))
                    || (moment(r.EndDate()) >= moment(range.StartDate) && moment(r.EndDate()) <= moment(range.EndDate))) {
                    fullInterval.Intervals.push(<IAllocationIntervalDetails> {
                        TeamId: t.Id,
                        TeamName: t.Name(),
                        OperationalUnitId: ou.Id,
                        AllocationId: r.Id,
                        AllocationType: r.AllocationType(),
                        Amount: r.Amount(),
                        Monday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder? fullInterval.ServiceOrder.HoursMonday * r.Amount() / 100 : r.Monday(),
                        Tuesday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursTuesday * r.Amount() / 100 :r.Tuesday(),
                        Wednesday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursWednesday * r.Amount() / 100 :r.Wednesday(),
                        Thursday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursThursday * r.Amount() / 100 :r.Thursday(),
                        Friday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursFriday * r.Amount() / 100 :r.Friday(),
                        Saturday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursSaturday * r.Amount() / 100 : r.Saturday(),
                        Sunday: r.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage && !! fullInterval.ServiceOrder ? fullInterval.ServiceOrder.HoursSunday * r.Amount() / 100 :r.Sunday()
                    });
                }
            });
        });

        return this.CreateViewModelForInterval(fullInterval);
    }

    private createIntervals(orderedDates: IDateForList[]): IDateRange[] {
        const intervals : IDateRange[] = [];

        for(let i = 0; i < orderedDates.length - 1; i++) {
            const range: IDateRange = { StartDate: null, EndDate: null };
            range.StartDate = orderedDates[i].Date;
            range.EndDate = orderedDates[i + 1].Date == null ? null : moment(orderedDates[i + 1].Date).add('days',-1).toDate();
            intervals.push(range);
        }

        return intervals;
    }

    private getOrderedDatesList(ou: ITeamsManagerOperationalUnit): IDateForList[] {
        const datesList: IDateForList[] = [];
        datesList.push({ Date: moment(this.StartDate()).startOf('day').toDate(), Type: 0});
        datesList.push({ Date: moment(this.EndDate()).startOf('day').add('days', 1).toDate(), Type: 1});

        ou.ServiceOrders().forEach(s => {
            datesList.push({ Date: moment(s.FromDate).startOf('day').toDate(), Type: 0});
            if (s.ToDate)
                datesList.push({ Date: moment(s.ToDate).startOf('day').toDate(), Type: 1});
        });

        ou.Teams().forEach((t: TeamForResourceViewModel) => {
            t.AllocationRanges().forEach((a: AllocationRangesForTeamViewModel) => {
                datesList.push({ Date: moment(a.StartDate()).startOf('day').toDate(), Type: 0 });
                datesList.push({ Date: moment(a.EndDate()).startOf('day').add('days', 1).toDate(), Type: 1 });
            });
        });

        datesList.sort((left: IDateForList, right: IDateForList) => {
            if (moment(left.Date) < moment(right.Date))
                return -1;
            if (moment(left.Date) > moment(right.Date))
                return 1;
            return 0;
        });

        const finalDates = [];
        let lastValue : IDateForList= { Date: moment('1900-01-01').toDate(), Type: 0 };
        for(var i = 0; i < datesList.length; i++) {
            if(lastValue.Date.valueOf() == datesList[i].Date.valueOf())
                continue;
            finalDates.push(datesList[i]);
            lastValue = datesList[i];
        }

        let lastDateBeforeStart  = -1;
        let nextDateAfterEnd  = -1;
        for(var i = 0; i < finalDates.length; i++) {
            if(finalDates[i].Date.valueOf() < moment(this.StartDate()).valueOf())
                lastDateBeforeStart = i;
            if(finalDates[i].Date.valueOf() > moment(this.EndDate()).add('days', 1).valueOf()) {
                nextDateAfterEnd = i;
                break;
            }
        }

        if(nextDateAfterEnd != -1)
            finalDates.splice(nextDateAfterEnd + 1, finalDates.length - nextDateAfterEnd - 1);
        finalDates.splice(0, lastDateBeforeStart < 0 ? 0 : lastDateBeforeStart);

        if(lastDateBeforeStart == -1) {
            finalDates.unshift({ Date: null, Type: 0 });
        }
        if(nextDateAfterEnd == -1) {
            finalDates.push({Date : null, Type: 0});
        }

        return finalDates;
    }

    private InitializeCurrentTeamAllocationsFromIntervals(): void {
        this.Intervals().forEach((i: FullInterval) => {
            const currentTeamAllocations: AllocationIntervalDetails[] = i.Intervals().filter((all: AllocationIntervalDetails) => { return all.TeamId == this.selectedTeam.Id(); });
            if (currentTeamAllocations.length > 0) {
                const allocation: AllocationIntervalDetails = i.Intervals.remove(currentTeamAllocations[0])[0];
                i.NewAllocationForInterval(allocation);
                i.NewAllocationForComputing(allocation);
                i.InitialAllocation = allocation;
            }
        });
    }

    private CreateViewModelForInterval(interval: IFullInterval): FullInterval {
        return new FullInterval(this.serviceLocator, interval, this);
    }

    private CreateAllocationPerHoursViewModel(allocation: IAllocationPerDays): AllocationPerDays {
        return new AllocationPerDays(allocation);
    }

    private GetAllocationHoursFromPercentage(percentage: number, serviceOrder: IServiceOrderForInterval): AllocationPerDays {
        const alloc: AllocationPerDays = new AllocationPerDays(null);
        if (!serviceOrder)
            return alloc;
        alloc.HoursMonday(serviceOrder.HoursMonday * (percentage / 100));
        alloc.HoursTuesday(serviceOrder.HoursTuesday * (percentage / 100));
        alloc.HoursWednesday(serviceOrder.HoursWednesday * (percentage / 100));
        alloc.HoursThursday(serviceOrder.HoursThursday * (percentage / 100));
        alloc.HoursFriday(serviceOrder.HoursFriday * (percentage / 100));
        alloc.HoursSaturday(serviceOrder.HoursSaturday * (percentage / 100));
        alloc.HoursSunday(serviceOrder.HoursSunday * (percentage / 100));

        return alloc;
    }

    private GetAllocationHoursFromHours(hours: number): AllocationPerDays {
        const alloc: AllocationPerDays = new AllocationPerDays(null);
        alloc.HoursMonday(hours);
        alloc.HoursTuesday(hours);
        alloc.HoursWednesday(hours);
        alloc.HoursThursday(hours);
        alloc.HoursFriday(hours);
        alloc.HoursSaturday(hours);
        alloc.HoursSunday(hours);
        return alloc;
    }

    private GetAllocationHoursFromWorkTable(): AllocationPerDays {
        return new AllocationPerDays(this.AllocationPerDays.GetData());
    }

    public GetAllocationHours(interval: FullInterval): AllocationPerDays {
        if (this.PercentageEditingEnabled())
            return this.GetAllocationHoursFromPercentage(this.PercentageAmount(), interval.ServiceOrder());
        if (this.HoursEditingEnabled())
            return this.GetAllocationHoursFromHours(this.HoursAmount());
        if (this.HoursPerDayEditingEnabled())
            return this.GetAllocationHoursFromWorkTable();
        return new AllocationPerDays((<AllocationIntervalDetails>interval.NewAllocationForInterval()).GetHours());
    }

    public ResetIntervalsSelection(): void {
        if (!this.ApplyAllocationOnSelectedIntervals()) {
        this.Intervals().forEach((i: FullInterval) => {
            i.Selected(false);
        });
        this.ResourceGantt.HighlightInterval(null, null);
    }
    }

    private IsValidAllocation(): boolean {
        let result = true;
        if (this.EditingMode() == ProlifeSdk.PercentageEditigMode && this.PercentageAmount() > 100)
            result = false;
        if (this.EditingMode() == ProlifeSdk.HoursEditigMode) {
            this.Intervals().forEach((i: FullInterval) => {
                const newAllocation: AllocationPerDays = new AllocationPerDays(null);
                newAllocation.HoursMonday(this.HoursAmount());
                newAllocation.HoursTuesday(this.HoursAmount());
                newAllocation.HoursWednesday(this.HoursAmount());
                newAllocation.HoursThursday(this.HoursAmount());
                newAllocation.HoursFriday(this.HoursAmount());
                newAllocation.HoursSaturday(this.HoursAmount());
                newAllocation.HoursSunday(this.HoursAmount());
                if (this.IsOverAllocated(i.ServiceOrder(), newAllocation))
                    result = false;
            });
        }
        if (this.EditingMode() == ProlifeSdk.ServiceOrderEditigMode) {
            this.Intervals().forEach((i: FullInterval) => {
                if (this.IsOverAllocated(i.ServiceOrder(), this.AllocationPerDays))
                    result = false;
            });
        }
        return result;
    }

    private IsOverAllocated(serviceOrder: IServiceOrderForInterval, newAllocation: AllocationPerDays): boolean {
        return (serviceOrder.HoursMonday > 0 &&  serviceOrder.HoursMonday < newAllocation.HoursMonday()) ||
               (serviceOrder.HoursTuesday > 0 &&  serviceOrder.HoursTuesday < newAllocation.HoursTuesday()) ||
               (serviceOrder.HoursWednesday > 0 &&  serviceOrder.HoursWednesday < newAllocation.HoursWednesday()) ||
               (serviceOrder.HoursThursday > 0 &&  serviceOrder.HoursThursday < newAllocation.HoursThursday()) ||
               (serviceOrder.HoursFriday > 0 &&  serviceOrder.HoursFriday < newAllocation.HoursFriday()) ||
               (serviceOrder.HoursSaturday > 0 &&  serviceOrder.HoursSaturday < newAllocation.HoursSaturday()) ||
               (serviceOrder.HoursSunday > 0 &&  serviceOrder.HoursSunday < newAllocation.HoursSunday())
    }

    private CheckIntervalsModifiedState(): boolean {
        let looseModifies = false;
        this.Intervals().forEach((i: FullInterval) => {
            if (i.UnallocatedHours.Modified())
                looseModifies = true;
            i.Intervals().forEach((it: AllocationIntervalDetails) => {
                if (it.Modified())
                    looseModifies = true;
            });
        });
        return looseModifies;
    }

    public ApplyInterval(): void {
        if (moment(this.EndDate()).startOf("day") < moment(this.StartDate()).startOf("day")) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.InvalidRange);
            return;
        }

        this.IsValidInterval(true);
        this.PendingIntervalApplication(false);

        if (this.CheckIntervalsModifiedState()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Allocations.LooseAllocationWarning,
                ProlifeSdk.TextResources.Allocations.CancelButton,
                ProlifeSdk.TextResources.Allocations.ConfirmButton,
                (confirm: boolean) => {
                    if (confirm) {
                        this.LoadIntervals();
                        this.ResetAllocations();
                        this.ResourceGantt.Gantt.StartDate(this.StartDate());
                        this.ResourceGantt.Gantt.HighlightInterval(null, null);
                    }
                }
            );
            return;
        }
        this.LoadIntervals();
        this.ResetAllocations();
        this.ResourceGantt.Gantt.StartDate(this.StartDate());
        this.ResourceGantt.Gantt.HighlightInterval(null, null);
    }

    public ApplyAllocation(): void {
        if (this.PendingIntervalApplication()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Allocations.PendingIntervalApplicationMessage,
                ProlifeSdk.TextResources.Allocations.PendingIntervalApplicationCancel,
                ProlifeSdk.TextResources.Allocations.PendingIntervalApplicationConfirm,
                (confirm: boolean) => {
                    if (confirm)
                        this.DoAllocation();
                });
            return;
        }

        this.DoAllocation();
    }

    private DoAllocation(): void {
        if (!this.IsValidInterval()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.ResourceRangeIntersectionError);
            return;
        }

        /*if (!this.IsValidAllocation()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.CanNotOverAllocate);
            return;
        }*/

        this.dialogsService.LockUI(ProlifeSdk.TextResources.Allocations.CalculatingAllocationsLoading);

        setTimeout(this.ComputeAllocation.bind(this), 150);
    }

    private reduceOverwork(getOverworkForDay : () => number, getAllocatedForDay : () => number, setOverworkForDay : (number) => void, setAllocatedForDay : (number) => void) {
        let overworkForDay = getOverworkForDay();
        let allocatedForDay = getAllocatedForDay();

        const hoursToReduce = Math.min(overworkForDay, allocatedForDay);

        overworkForDay -= hoursToReduce;
        allocatedForDay -= hoursToReduce;

        setOverworkForDay(overworkForDay);
        setAllocatedForDay(allocatedForDay);
    }

    private ComputeAllocation(): void {
        if(this.EditingMode() != ProlifeSdk.IntervalEditigMode) {
            const intervals: FullInterval[] = this.Intervals();
            if (this.ResetDataBeforeApplyNewAllocation())
                this.ResetAllocations();

            intervals
                .filter((interval:FullInterval) => !interval.Locked() && !interval.Freeze())
                .forEach((interval:FullInterval) => {
                    const targetAllocationForInterval: AllocationPerDays = this.GetAllocationHours(interval);
                    const details = new AllocationIntervalDetails(
                        this.serviceLocator, {
                            TeamId: this.selectedTeam.Id(),
                            TeamName: this.TeamName(),
                            OperationalUnitId: this.opUnit.Id,
                            AllocationId: null,
                            AllocationType: this.EditingMode(),
                            Amount: 0,
                            Monday: targetAllocationForInterval.HoursMonday(),
                            Tuesday: targetAllocationForInterval.HoursTuesday(),
                            Wednesday: targetAllocationForInterval.HoursWednesday(),
                            Thursday: targetAllocationForInterval.HoursThursday(),
                            Friday: targetAllocationForInterval.HoursFriday(),
                            Saturday: targetAllocationForInterval.HoursSaturday(),
                            Sunday: targetAllocationForInterval.HoursSunday()
                        }, interval, this);

                    interval.NewAllocationForInterval(details);
                    interval.NewAllocationForComputing(details);

                    const overworkData = interval.OverworkHours.GetData();

                    interval.Intervals()
                        .filter((subInterval:AllocationIntervalDetails) => subInterval.Selected())
                        .forEach((subInterval:AllocationIntervalDetails) => {
                            const lastAllocationType = subInterval.AllocationType();

                            this.reduceOverwork(() => overworkData.HoursMonday, () => subInterval.Monday.Value(), (value:number) => overworkData.HoursMonday = value, (value:number) => subInterval.Monday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursTuesday, () => subInterval.Tuesday.Value(), (value:number) => overworkData.HoursTuesday = value, (value:number) => subInterval.Tuesday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursWednesday, () => subInterval.Wednesday.Value(), (value:number) => overworkData.HoursWednesday = value, (value:number) => subInterval.Wednesday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursThursday, () => subInterval.Thursday.Value(), (value:number) => overworkData.HoursThursday = value, (value:number) => subInterval.Thursday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursFriday, () => subInterval.Friday.Value(), (value:number) => overworkData.HoursFriday = value, (value:number) => subInterval.Friday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursSaturday, () => subInterval.Saturday.Value(), (value:number) => overworkData.HoursSaturday = value, (value:number) => subInterval.Saturday.Value(value));
                            this.reduceOverwork(() => overworkData.HoursSunday, () => subInterval.Sunday.Value(), (value:number) => overworkData.HoursSunday = value, (value:number) => subInterval.Sunday.Value(value));

                            subInterval.TryChangeAllocationType(lastAllocationType);
                        });
                });
            this.Intervals(intervals);
        }

        this.SynchronizeTeamManager();
        this.dialogsService.UnlockUI();
        this.SaveIntervalsState();
        this.SaveHelperInputFieldsState();
    }

    public ManualReset(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Allocations.ResetDataMessage,
            ProlifeSdk.TextResources.Allocations.CancelButton,
            ProlifeSdk.TextResources.Allocations.ConfirmButton,
            (confirm: boolean) => {
                if (!confirm)
                    return;
                this.ResetAllocations();
            }
        );
    }

    private ResetAllocations(): void {
        this.Intervals().forEach((i: FullInterval) => {
            i.ResetData();
        });

        this.SynchronizeTeamManager();
    }

    private ClearWorkingRangeOnTeamManager(teamsManagerProvider : ITeamsManagerProvider)
    {
        const actualResource = teamsManagerProvider.GetResource(this.resourceId);
        const actualUO = actualResource.GetOperationalUnit(this.opUnit.Id);
        actualUO.Teams().forEach((team : TeamForResourceViewModel) => {
            const teamViewModel : ITeamViewModel = teamsManagerProvider.GetTeam(team.Id);
            const resourceAllocation : ITeamAllocationViewModel = teamViewModel.GetAllocationByResource(this.resourceId);
            const operationalUnit : IResourceOperationalUnitViewModel = resourceAllocation.GetOperationalUnit(this.opUnit.Id);

            let rangeIndex = 0;
            const updatedAllocationRanges = [];
            const updatedAllocationRangesForUO = [];

            team.AllocationRanges().forEach((allocationRange : AllocationRangesForTeamViewModel) => {
                const uoRange : IResourceAllocationRangeViewModel = operationalUnit.AllocationRanges()[rangeIndex];

                if(moment(allocationRange.StartDate()) < moment(this.StartDate()) && moment(allocationRange.EndDate()) > moment(this.EndDate())) {
                    updatedAllocationRangesForUO.push(uoRange);
                    updatedAllocationRanges.push(allocationRange);

                    const endAllocationRange = allocationRange.Clone();
                    const uoEndAllocationRange = uoRange.Clone(operationalUnit, teamsManagerProvider, true);

                    allocationRange.EndDate(moment(this.StartDate()).add('days', -1).toDate());
                    uoRange.EndDate(allocationRange.EndDate());

                    endAllocationRange.StartDate(moment(this.EndDate()).add('days', 1).toDate());
                    uoEndAllocationRange.StartDate(endAllocationRange.StartDate());

                    updatedAllocationRanges.push(endAllocationRange);
                    updatedAllocationRangesForUO.push(uoEndAllocationRange);
                } else {
                    if(moment(allocationRange.StartDate()) < moment(this.StartDate()) && moment(allocationRange.EndDate()) <= moment(this.EndDate()) && moment(allocationRange.EndDate()) >= moment(this.StartDate())) {
                        allocationRange.EndDate(moment(this.StartDate()).add('days', -1).toDate());
                        uoRange.EndDate(allocationRange.EndDate());

                        updatedAllocationRangesForUO.push(uoRange);
                        updatedAllocationRanges.push(allocationRange);
                    } else if(moment(allocationRange.EndDate()) > moment(this.EndDate()) && moment(allocationRange.StartDate()) <= moment(this.EndDate()) && moment(allocationRange.StartDate()) >= moment(this.StartDate())) {
                        allocationRange.StartDate(moment(this.EndDate()).add('days', 1).toDate());
                        uoRange.StartDate(allocationRange.StartDate());

                        updatedAllocationRangesForUO.push(uoRange);
                        updatedAllocationRanges.push(allocationRange);
                    } else if(moment(allocationRange.EndDate()) < moment(this.StartDate()) || moment(allocationRange.StartDate()) > moment(this.EndDate())) {
                        updatedAllocationRangesForUO.push(uoRange);
                        updatedAllocationRanges.push(allocationRange);
                    }
                }

                rangeIndex++;
            });

            team.AllocationRanges(updatedAllocationRanges);
            operationalUnit.AllocationRanges(updatedAllocationRangesForUO);
        });
        }

    private CreateNewAllocationRangeForResources(interval : FullInterval, allocationDetails : AllocationIntervalDetails, operationalUnitId : number) {
        let amount = 0;
        let allocationType = allocationDetails.AllocationType();
        if(allocationDetails.CanConvertToPercentageValue(interval.ServiceOrder()))
            amount = allocationDetails.GetAsPercentageValue(interval.ServiceOrder());
        else
            allocationType = ProlifeSdk.ResourceAllocationByHours;

        return new AllocationRangesForTeamViewModel({
            TeamResourceAllocationId: null,
            StartDate: interval.Interval.StartDate(),
            EndDate: interval.Interval.EndDate(),
            Amount: amount,
            Monday: allocationDetails.Monday.Value(),
            Tuesday: allocationDetails.Tuesday.Value(),
            Wednesday: allocationDetails.Wednesday.Value(),
            Thursday: allocationDetails.Thursday.Value(),
            Friday: allocationDetails.Friday.Value(),
            Saturday: allocationDetails.Saturday.Value(),
            Sunday: allocationDetails.Sunday.Value(),
            OperationalUnitId: operationalUnitId,
            AllocationType: allocationType
            });
        }

    private CreateNewAllocationRangeForTeams(teamsManagerProvider: ITeamsManagerProvider, interval : FullInterval, allocationDetails : AllocationIntervalDetails, operationalUnit : IResourceOperationalUnitViewModel) {
        let amount = 0;
        let allocationType = allocationDetails.AllocationType();
        if(allocationDetails.CanConvertToPercentageValue(interval.ServiceOrder()))
            amount = allocationDetails.GetAsPercentageValue(interval.ServiceOrder());
        else
            allocationType = ProlifeSdk.ResourceAllocationByHours;

        return new ResourceAllocationRangeViewModel(this.serviceLocator, {
            Id: this.allocationsService.GenerateNextId(),
            TeamResourceAllocationId: operationalUnit.Id,
            StartDate: interval.Interval.StartDate(),
            EndDate: interval.Interval.EndDate(),
            Amount: amount,
            Monday: allocationDetails.Monday.Value(),
            Tuesday: allocationDetails.Tuesday.Value(),
            Wednesday: allocationDetails.Wednesday.Value(),
            Thursday: allocationDetails.Thursday.Value(),
            Friday: allocationDetails.Friday.Value(),
            Saturday: allocationDetails.Saturday.Value(),
            Sunday: allocationDetails.Sunday.Value(),
            OperationalUnitId: operationalUnit.OperationalUnit.Id,
            AllocationType: allocationType
        }, operationalUnit.OperationalUnit.Id, operationalUnit, this.resourceId, this.ResourceName(), teamsManagerProvider);
    }

    private FillWorkingRangeOnTeamManager(teamsManagerProvider : ITeamsManagerProvider)
    {
        const actualResource = teamsManagerProvider.GetResource(this.resourceId);
        const actualUO = actualResource.GetOperationalUnit(this.opUnit.Id);

        const activeIntervals = this.Intervals().filter((interval : FullInterval) => !interval.Locked())

        actualUO.Teams().forEach((team : TeamForResourceViewModel) => {
            const teamViewModel : ITeamViewModel = teamsManagerProvider.GetTeam(team.Id);
            const resourceAllocation : ITeamAllocationViewModel = teamViewModel.GetAllocationByResource(this.resourceId);
            const operationalUnit : IResourceOperationalUnitViewModel = resourceAllocation.GetOperationalUnit(this.opUnit.Id);

            if(team.Id == this.selectedTeamId) {
                activeIntervals.forEach((interval : FullInterval) => {
                    const newAllocationForResources = this.CreateNewAllocationRangeForResources(interval, interval.NewAllocationForInterval(), actualUO.Id);
                    team.AllocationRanges.push(newAllocationForResources);

                    const newAllocationForTeam = this.CreateNewAllocationRangeForTeams(teamsManagerProvider, interval, interval.NewAllocationForInterval(), operationalUnit);
                    operationalUnit.AllocationRanges.push(newAllocationForTeam);
                });
            } else {
                activeIntervals.forEach((interval : FullInterval) => {
                    const teamInterval:AllocationIntervalDetails = interval.GetIntervalForTeam(team.Id);
                    if(!teamInterval) return;

                    const newAllocationForResources = this.CreateNewAllocationRangeForResources(interval, teamInterval, actualUO.Id);
                    team.AllocationRanges.push(newAllocationForResources);

                    const newAllocationForTeam = this.CreateNewAllocationRangeForTeams(teamsManagerProvider, interval, teamInterval, operationalUnit);
                    operationalUnit.AllocationRanges.push(newAllocationForTeam);
                });
            }

            team.AllocationRanges.sort((a : AllocationRangesForTeamViewModel,b : AllocationRangesForTeamViewModel) => moment(a.StartDate()).toDate().valueOf() - moment(b.StartDate()).toDate().valueOf());
            operationalUnit.AllocationRanges.sort((a : ResourceAllocationRangeViewModel, b: ResourceAllocationRangeViewModel) => moment(a.StartDate()).toDate().valueOf() - moment(b.StartDate()).toDate().valueOf());
        });
     }

    private SynchronizeTeamManager(): void {
        this.originalTeamsManagerProvider.CloneToProvider(this.teamsManagerProvider);

        this.ClearWorkingRangeOnTeamManager(this.teamsManagerProvider);
        this.FillWorkingRangeOnTeamManager(this.teamsManagerProvider);

        this.ResourceGantt.Refresh();
    }
}

export class FullInterval {
    public Interval: AllocationInterval;
    public Intervals: ko.ObservableArray<AllocationIntervalDetails> = ko.observableArray([]);
    public ServiceOrder: ko.Observable<any> = ko.observable();
    public UnallocatedHours: DailyUnallocatedHours;
    public Totals: DailyTotalHours;
    public OverworkHours: OverworkHours;
    public Selected: ko.Observable<boolean> = ko.observable(false);
    public Freeze: ko.Observable<boolean> = ko.observable(false);

    public NewAllocationForInterval: ko.Observable<AllocationIntervalDetails> = ko.observable();
    public NewAllocationForComputing: ko.Observable<AllocationIntervalDetails> = ko.observable();
    public InitialAllocation: AllocationIntervalDetails;

    public IsEditable: ko.Computed<boolean>;
    public ManualEditingOnIntervalsEnabled : ko.Computed<boolean>;
    public HasManualChanges: ko.Computed<boolean>;
    public Opacity: ko.Computed<number>;
    public Locked: ko.Computed<boolean>;

    private originalData: IFullInterval;

    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, interval: IFullInterval, private editor: ResourceReallocationDialog) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.originalData = interval;
        this.Interval = new AllocationInterval(interval.Interval);
        this.ServiceOrder(interval.ServiceOrder);
        this.ManualEditingOnIntervalsEnabled = editor.ManualEditingOnIntervalsEnabled;

        this.Locked = ko.computed(() => {
            return (moment(this.Interval.StartDate()) > moment(this.editor.EndDate()) || moment(this.Interval.EndDate()) < moment(this.editor.StartDate()));
        });

        this.Opacity = ko.computed(() => {
            return this.Locked() ? 0.4 : 1.0;
        });

        this.IsEditable = ko.computed(() => {
            return !!this.ServiceOrder() && !this.Locked();
        });

        this.NewAllocationForInterval(new AllocationIntervalDetails(
            this.serviceLocator,
            <IAllocationIntervalDetails>{
                TeamId: this.editor.selectedTeam.Id(),
                TeamName: this.editor.TeamName(),
                OperationalUnitId: this.editor.opUnit.Id,
                AllocationId: null,
                AllocationType: this.editor.EditingMode(),
                Amount: 0,
                Monday: 0,
                Tuesday: 0,
                Wednesday: 0,
                Thursday: 0,
                Friday: 0,
                Saturday: 0,
                Sunday: 0
            }, this, this.editor));

        this.Intervals(interval.Intervals.map((i: IAllocationIntervalDetails) => { return new AllocationIntervalDetails(this.serviceLocator ,i, this, this.editor); }));
        this.Totals = new DailyTotalHours(this.Intervals(), this.ServiceOrder(), this.NewAllocationForComputing);
        this.UnallocatedHours = new DailyUnallocatedHours(this.Totals, this.ServiceOrder());
        this.OverworkHours = new OverworkHours(this.Totals, this.ServiceOrder());

        this.HasManualChanges = ko.computed(() => {
            return this.OverworkHours.HasManualChanges() || this.UnallocatedHours.HasManualChanges() || this.Intervals().filter((a: AllocationIntervalDetails) => a.HasManualChanges()).length > 0;
        });

        this.Freeze.subscribe(() => {});
    }

    public SaveIntervalsState(): void {
        this.UnallocatedHours.SaveActualState();
        this.OverworkHours.SaveActualState();
        this.Intervals().forEach((a: AllocationIntervalDetails) => {
            a.SaveActualState();
        });
    }

    public SelectAllTeams(): void {
        this.Intervals().forEach((a: AllocationIntervalDetails) => { a.Selected(true); });
    }

    public DeselectAllTeams(): void {
        this.Intervals().forEach((a: AllocationIntervalDetails) => { a.Selected(false); });
    }

    public MultipleSelect(): void {
        if (!this.editor.ApplyAllocationOnSelectedIntervals()) {
            return;
        }
        this.Selected(!this.Selected());
    }

    public SingleSelect(): void {
        if (this.editor.ApplyAllocationOnSelectedIntervals()) {
            return;
        }

        this.editor.ResetIntervalsSelection();
        this.Selected(true);
        this.editor.HighlightIntervalOnGantt(this.Interval.StartDate(), this.Interval.EndDate());
    }

    public ManualReset(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Allocations.ResetDataMessage,
            ProlifeSdk.TextResources.Allocations.CancelButton,
            ProlifeSdk.TextResources.Allocations.ConfirmButton,
            (confirm: boolean) => {
                if (!confirm)
                    return;
                this.ResetData();
            }
        );
    }

    public ResetData(): void {
        let allocation = this.InitialAllocation;
        if(!allocation) {
            allocation = new AllocationIntervalDetails(
                this.serviceLocator, {
                    TeamId: this.editor.selectedTeam.Id(),
                    TeamName: this.editor.TeamName(),
                    OperationalUnitId: this.editor.opUnit.Id,
                    AllocationId: null,
                    AllocationType: this.editor.EditingMode(),
                    Amount: 0,
                    Monday: 0,
                    Tuesday: 0,
                    Wednesday: 0,
                    Thursday: 0,
                    Friday: 0,
                    Saturday: 0,
                    Sunday: 0
                }, this, this.editor);
        }

            this.NewAllocationForInterval(allocation);
        this.NewAllocationForComputing(allocation);
            this.Intervals().forEach((all:AllocationIntervalDetails) => {
                all.ResetData();
            });
        }

    public GetData(): IFullInterval {
        return <IFullInterval> {
            Interval: this.Interval.GetData(),
            Intervals: this.Intervals().map((i: AllocationIntervalDetails) => { return i.GetData(); }),
            ServiceOrder: this.ServiceOrder()
        }
    }

    public GetIntervalForTeam(teamId : number) : AllocationIntervalDetails {
        if(!teamId) return null;
        const intervals = this.Intervals().filter((i : AllocationIntervalDetails) => i.TeamId == teamId);
        if(intervals.length == 0) return null;
        return intervals[0];
}
}

export class OverworkHours {
    public HoursMonday: ko.Computed<number>;
    public HoursTuesday: ko.Computed<number>;
    public HoursWednesday: ko.Computed<number>;
    public HoursThursday: ko.Computed<number>;
    public HoursFriday: ko.Computed<number>;
    public HoursSaturday: ko.Computed<number>;
    public HoursSunday: ko.Computed<number>;

    public Modified: ko.Computed<boolean>;
    public HasManualChanges: ko.Computed<boolean>;

    public originalData: IAllocationPerDays;

    public stateOnManualEditingEnabled: ko.Observable<IAllocationPerDays> = ko.observable();

    constructor(private dailyTotals: DailyTotalHours, private serviceOrder: IHumanResourceOrders) {
        this.HoursMonday = ko.computed(() => {
            return Math.max(dailyTotals.HoursMonday.Value() - serviceOrder.HoursMonday, 0);
        });

        this.HoursTuesday = ko.computed(() => {
            return Math.max(dailyTotals.HoursTuesday.Value() - serviceOrder.HoursTuesday, 0);
        });

        this.HoursWednesday = ko.computed(() => {
            return Math.max(dailyTotals.HoursWednesday.Value() - serviceOrder.HoursWednesday, 0);
        });

        this.HoursThursday = ko.computed(() => {
            return Math.max(dailyTotals.HoursThursday.Value() - serviceOrder.HoursThursday, 0);
        });

        this.HoursFriday= ko.computed(() => {
            return Math.max(dailyTotals.HoursFriday.Value() - serviceOrder.HoursFriday, 0);
        });

        this.HoursSaturday = ko.computed(() => {
            return Math.max(dailyTotals.HoursSaturday.Value() - serviceOrder.HoursSaturday, 0);
        });

        this.HoursSunday = ko.computed(() => {
            return Math.max(dailyTotals.HoursSunday.Value() - serviceOrder.HoursSunday, 0);
        });

        this.originalData = this.GetData();

        this.Modified = ko.computed(() => {
            return this.originalData.HoursMonday != this.HoursMonday() ||
                this.originalData.HoursTuesday != this.HoursTuesday() ||
                this.originalData.HoursWednesday != this.HoursWednesday() ||
                this.originalData.HoursThursday != this.HoursThursday() ||
                this.originalData.HoursFriday != this.HoursFriday() ||
                this.originalData.HoursSaturday != this.HoursSaturday() ||
                this.originalData.HoursSunday != this.HoursSunday()
        });

        this.HasManualChanges = ko.computed(() => {
            const oldState = this.stateOnManualEditingEnabled();
            if (!oldState)
                return false;
            return this.HoursMonday() != oldState.HoursMonday ||
                this.HoursTuesday() != oldState.HoursTuesday ||
                this.HoursWednesday() != oldState.HoursWednesday ||
                this.HoursThursday() != oldState.HoursThursday ||
                this.HoursFriday() != oldState.HoursFriday ||
                this.HoursSaturday() != oldState.HoursSaturday ||
                this.HoursSunday() != oldState.HoursSunday;
        });
    }

    public SaveActualState(): void {
        this.stateOnManualEditingEnabled(this.GetData());
    }

    public GetData(): IAllocationPerDays {
        return {
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday()
        };
    }
}

export class AllocationInterval {
    public StartDate: ko.Observable<Date> = ko.observable();
    public EndDate: ko.Observable<Date> = ko.observable();

    constructor(interval: IAllocationInterval) {
        this.StartDate(interval.StartDate);
        this.EndDate(interval.EndDate);
    }

    public GetData(): IAllocationInterval {
        return <IAllocationInterval> {
            StartDate: this.StartDate(),
            EndDate: this.EndDate()
        };
    }
}

export class AllocationPerDays {
    public HoursMonday: ko.Observable<number> = ko.observable(0);
    public HoursTuesday: ko.Observable<number> = ko.observable(0);
    public HoursWednesday: ko.Observable<number> = ko.observable(0);
    public HoursThursday: ko.Observable<number> = ko.observable(0);
    public HoursFriday: ko.Observable<number> = ko.observable(0);
    public HoursSaturday: ko.Observable<number> = ko.observable(0);
    public HoursSunday: ko.Observable<number> = ko.observable(0);

    public IsEditable: ko.Observable<boolean> = ko.observable(true);

    constructor(allocation: IAllocationPerDays) {
        if (allocation) {
            this.HoursMonday(allocation.HoursMonday);
            this.HoursTuesday(allocation.HoursTuesday);
            this.HoursWednesday(allocation.HoursWednesday);
            this.HoursThursday(allocation.HoursThursday);
            this.HoursFriday(allocation.HoursFriday);
            this.HoursSaturday(allocation.HoursSaturday);
            this.HoursSunday(allocation.HoursSunday);
        }
    }

    public HasHours(): boolean {
        return !!this.HoursMonday() ||
               !!this.HoursTuesday() ||
               !!this.HoursWednesday() ||
               !!this.HoursFriday() ||
               !!this.HoursSaturday() ||
               !!this.HoursSunday();
    }

    public Copy(): AllocationPerDays {
        const data: IAllocationPerDays = this.GetData();
        return new AllocationPerDays(data);
    }

    public IsEquivalent(hours: AllocationPerDays): boolean {
        return this.HoursMonday() == hours.HoursMonday() &&
            this.HoursTuesday() == hours.HoursTuesday() &&
            this.HoursWednesday() == hours.HoursWednesday() &&
            this.HoursThursday() == hours.HoursThursday() &&
            this.HoursFriday() == hours.HoursFriday() &&
            this.HoursSunday() == hours.HoursSaturday() &&
            this.HoursSunday() == hours.HoursSunday()
    }

    public GetData(): IAllocationPerDays {
        return {
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday()
        };
    }
}

export class DailyUnallocatedHours {
    public HoursMonday: ko.Computed<number>;
    public HoursTuesday: ko.Computed<number>;
    public HoursWednesday: ko.Computed<number>;
    public HoursThursday: ko.Computed<number>;
    public HoursFriday: ko.Computed<number>;
    public HoursSaturday: ko.Computed<number>;
    public HoursSunday: ko.Computed<number>;
    public Modified: ko.Computed<boolean>;
    public HasManualChanges: ko.Computed<boolean>;

    public originalData: IAllocationPerDays;

    public stateOnManualEditingEnabled: ko.Observable<IAllocationPerDays> = ko.observable();

    constructor(private dailyTotals: DailyTotalHours, private serviceOrder: IServiceOrderForInterval) {
        this.HoursMonday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursMonday - this.dailyTotals.HoursMonday.Value(), 0);
        });

        this.HoursTuesday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursTuesday - this.dailyTotals.HoursTuesday.Value(), 0);
        });

        this.HoursWednesday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursWednesday - this.dailyTotals.HoursWednesday.Value(), 0);
        });

        this.HoursThursday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursThursday - this.dailyTotals.HoursThursday.Value(), 0);
        });

        this.HoursFriday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursFriday - this.dailyTotals.HoursFriday.Value(), 0);
        });

        this.HoursSaturday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursSaturday - this.dailyTotals.HoursSaturday.Value(), 0);
        });

        this.HoursSunday = ko.computed(() => {
            return !this.serviceOrder ? 0 : Math.max(this.serviceOrder.HoursSunday - this.dailyTotals.HoursSunday.Value(), 0);
        });

        this.originalData = this.GetData();

        this.Modified = ko.computed(() => {
            return this.HoursMonday() != this.originalData.HoursMonday ||
                this.HoursTuesday() != this.originalData.HoursTuesday ||
                this.HoursWednesday() != this.originalData.HoursWednesday ||
                this.HoursThursday() != this.originalData.HoursThursday ||
                this.HoursFriday() != this.originalData.HoursFriday ||
                this.HoursSaturday() != this.originalData.HoursSaturday ||
                this.HoursSunday() != this.originalData.HoursSunday;
        });

        this.HasManualChanges = ko.computed(() => {
            const oldState = this.stateOnManualEditingEnabled();
            if (!oldState)
                return false;
            return this.HoursMonday() != oldState.HoursMonday ||
                this.HoursTuesday() != oldState.HoursTuesday ||
                this.HoursWednesday() != oldState.HoursWednesday ||
                this.HoursThursday() != oldState.HoursThursday ||
                this.HoursFriday() != oldState.HoursFriday ||
                this.HoursSaturday() != oldState.HoursSaturday ||
                this.HoursSunday() != oldState.HoursSunday;
        });
    }

    public SaveActualState(): void {
        this.stateOnManualEditingEnabled(this.GetData());
    }

    public GetData(): IAllocationPerDays {
        return {
            HoursMonday: this.HoursMonday(),
            HoursTuesday: this.HoursTuesday(),
            HoursWednesday: this.HoursWednesday(),
            HoursThursday: this.HoursThursday(),
            HoursFriday: this.HoursFriday(),
            HoursSaturday: this.HoursSaturday(),
            HoursSunday: this.HoursSunday()
        };
    }

    public IsChanged(): boolean {
        return this.HoursMonday() != this.originalData.HoursMonday ||
               this.HoursTuesday() != this.originalData.HoursTuesday ||
               this.HoursWednesday() != this.originalData.HoursWednesday ||
               this.HoursThursday() != this.originalData.HoursThursday ||
               this.HoursFriday() != this.originalData.HoursFriday ||
               this.HoursSaturday() != this.originalData.HoursSaturday ||
               this.HoursSunday() != this.originalData.HoursSunday;
    }
}

export class DailyTotalHours {
    public HoursMonday: DailyHours;
    public HoursTuesday: DailyHours;
    public HoursWednesday: DailyHours;
    public HoursThursday: DailyHours;
    public HoursFriday: DailyHours;
    public HoursSaturday: DailyHours;
    public HoursSunday: DailyHours;

    public OnMondayHoursChanges: ko.Computed<void>;
    public OnTuesdayHoursChanges: ko.Computed<void>;
    public OnWednesdayHoursChanges: ko.Computed<void>;
    public OnThursdayHoursChanges: ko.Computed<void>;
    public OnFridayHoursChanges: ko.Computed<void>;
    public OnSaturdayHoursChanges: ko.Computed<void>;
    public OnSundayHoursChanges: ko.Computed<void>;

    public HasOverAllocations: ko.Computed<boolean>;
    public HasUnderAllocations: ko.Computed<boolean>;

    constructor(private intervals: AllocationIntervalDetails[], private serviceOrder: IServiceOrderForInterval, private NewAllocation: ko.Observable<AllocationIntervalDetails>) {
        this.HoursMonday = new DailyHours();
        this.HoursTuesday = new DailyHours();
        this.HoursWednesday = new DailyHours();
        this.HoursThursday = new DailyHours();
        this.HoursFriday = new DailyHours();
        this.HoursSaturday = new DailyHours();
        this.HoursSunday = new DailyHours();

        this.OnMondayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Monday.Value();
            });
            sum += !this.NewAllocation() ? 0 : parseFloat(this.NewAllocation().Monday.Value().toString());
            this.HoursMonday.Value(sum);
            this.HoursMonday.OverWork(sum > this.serviceOrder.HoursMonday);
            this.HoursMonday.LittleWork(sum < this.serviceOrder.HoursMonday);
            return;
        });

        this.OnTuesdayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Tuesday.Value();
            });
            sum += !this.NewAllocation() ? 0 :  parseFloat(this.NewAllocation().Tuesday.Value().toString());
            this.HoursTuesday.Value(sum);
            this.HoursTuesday.OverWork(sum > this.serviceOrder.HoursTuesday);
            this.HoursTuesday.LittleWork(sum < this.serviceOrder.HoursTuesday);
            return;
        });

        this.OnWednesdayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Wednesday.Value();
            });
            sum += !this.NewAllocation() ? 0 :  parseFloat(this.NewAllocation().Wednesday.Value().toString());
            this.HoursWednesday.Value(sum);
            this.HoursWednesday.OverWork(sum > this.serviceOrder.HoursWednesday);
            this.HoursWednesday.LittleWork(sum < this.serviceOrder.HoursWednesday);
            return;
        });

        this.OnThursdayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Thursday.Value();
            });
            sum += !this.NewAllocation() ? 0 : parseFloat(this.NewAllocation().Thursday.Value().toString());
            this.HoursThursday.Value(sum);
            this.HoursThursday.OverWork(sum > this.serviceOrder.HoursThursday);
            this.HoursThursday.LittleWork(sum < this.serviceOrder.HoursThursday);
            return;
        });

        this.OnFridayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Friday.Value();
            });
            sum += !this.NewAllocation() ? 0 : parseFloat(this.NewAllocation().Friday.Value().toString());
            this.HoursFriday .Value(sum);
            this.HoursFriday.OverWork(sum > this.serviceOrder.HoursFriday);
            this.HoursFriday.LittleWork(sum < this.serviceOrder.HoursFriday);
            return;
        });

        this.OnSaturdayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Saturday.Value();
            });
            sum += !this.NewAllocation() ? 0 : parseFloat(this.NewAllocation().Saturday.Value().toString());
            this.HoursSaturday.Value(sum);
            this.HoursSaturday.OverWork(sum > this.serviceOrder.HoursSaturday);
            this.HoursSaturday.LittleWork(sum < this.serviceOrder.HoursSaturday);
            return;
        });

        this.OnSundayHoursChanges = ko.computed(() => {
            if (!this.serviceOrder)
                return;
            let sum = 0;
            this.intervals.forEach((i: AllocationIntervalDetails) => {
                sum += i.Sunday.Value();
            });
            sum += !this.NewAllocation() ? 0 : parseFloat(this.NewAllocation().Sunday.Value().toString());
            this.HoursSunday.Value(sum);
            this.HoursSunday.OverWork(sum > this.serviceOrder.HoursSunday);
            this.HoursSunday.LittleWork(sum < this.serviceOrder.HoursSunday);
            return;
        });

        this.HasOverAllocations = ko.computed(() => {
            return this.HoursMonday.OverWork() ||
                   this.HoursTuesday.OverWork() ||
                   this.HoursWednesday.OverWork() ||
                   this.HoursThursday.OverWork() ||
                   this.HoursFriday.OverWork() ||
                   this.HoursSaturday.OverWork() ||
                   this.HoursSunday.OverWork()
        });

        this.HasUnderAllocations = ko.computed(() => {
            return this.HoursMonday.LittleWork() ||
                this.HoursTuesday.LittleWork() ||
                this.HoursWednesday.LittleWork() ||
                this.HoursThursday.LittleWork() ||
                this.HoursFriday.LittleWork() ||
                this.HoursSaturday.LittleWork() ||
                this.HoursSunday.LittleWork()
        });
    }
}

export class DailyHours {
    public Value: ko.Observable<number> = ko.observable();
    public OverWork: ko.Observable<boolean> = ko.observable();
    public LittleWork: ko.Observable<boolean> = ko.observable();

    public IsEditable: ko.Observable<boolean> = ko.observable(true);

    constructor() {}
}

export class AllocationIntervalDetails {
    public TeamId: number;
    public TeamName: string;
    public OpUnitId: number;
    public AllocationId: number;
    public AllocationType: ko.Observable<number> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public Monday: DailyHours = new DailyHours();
    public Tuesday: DailyHours = new DailyHours();
    public Wednesday: DailyHours = new DailyHours();
    public Thursday: DailyHours = new DailyHours();
    public Friday: DailyHours = new DailyHours();
    public Saturday: DailyHours = new DailyHours();
    public Sunday: DailyHours = new DailyHours();

    public Selected: ko.Observable<boolean> = ko.observable(true);
    public CannotConvertToPercentage : ko.Observable<boolean> = ko.observable(false);

    public Modified: ko.Computed<boolean>;
    public HasManualChanges: ko.Computed<boolean>;
    public SelectionEnabled: ko.Computed<boolean>;
    public PreventEnabling: ko.Computed<boolean>;
    public PercentageAllocationEnabled: ko.Computed<boolean>;

    public originalData: IAllocationIntervalDetails;

    private stateOnManualEditingEnabled: ko.Observable<any> = ko.observable();

    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, interval: IAllocationIntervalDetails, private fullInterval: FullInterval, private editor : ResourceReallocationDialog) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.originalData = interval;
        this.TeamId = interval.TeamId;
        this.TeamName = interval.TeamName;
        this.OpUnitId = interval.OperationalUnitId;
        this.AllocationId = interval.AllocationId;
        this.AllocationType(interval.AllocationType);
        this.Amount(interval.Amount);
        this.Monday.Value(interval.Monday);
        this.Tuesday.Value(interval.Tuesday);
        this.Wednesday.Value(interval.Wednesday);
        this.Thursday.Value(interval.Thursday);
        this.Friday.Value(interval.Friday);
        this.Saturday.Value(interval.Saturday);
        this.Sunday.Value(interval.Sunday);

        this.PercentageAllocationEnabled = ko.computed(() => {
            return this.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage;
        });

        let updating = false;

        this.Amount.subscribe((newAmount: number) => {
            if(updating) return;

            const allocationType = this.AllocationType();

            const serviceOrder: IServiceOrderForInterval = this.fullInterval.ServiceOrder();
            this.Monday.Value(serviceOrder.HoursMonday * newAmount / 100);
            this.Tuesday.Value(serviceOrder.HoursTuesday * newAmount / 100);
            this.Wednesday.Value(serviceOrder.HoursWednesday * newAmount / 100);
            this.Thursday.Value(serviceOrder.HoursThursday * newAmount / 100);
            this.Friday.Value(serviceOrder.HoursFriday * newAmount / 100);
            this.Saturday.Value(serviceOrder.HoursSaturday * newAmount / 100);
            this.Sunday.Value(serviceOrder.HoursSunday * newAmount / 100);

            this.TryChangeAllocationType(allocationType);
        });

        this.SelectionEnabled = ko.computed(() => {
            return this.editor.EditingMode() != ProlifeSdk.IntervalEditigMode;
        });

        this.PreventEnabling = ko.computed(() => {
            return !this.editor.ManualEditingOnIntervalsEnabled() || !this.fullInterval.IsEditable();
        });

        const convertionInterceptor = ko.computed(() => {
            let serviceOrder: IServiceOrderForInterval = this.fullInterval.ServiceOrder();
            let percentageValue = this.GetAsPercentageValue(serviceOrder);
            if(percentageValue == null) {
                this.CannotConvertToPercentage(true);
                this.AllocationType(ProlifeSdk.ResourceAllocationByHours);
            } else {
                updating = true;
                this.Amount(percentageValue);
                this.CannotConvertToPercentage(false);
                updating = false;
            }
        });

        this.Modified = ko.computed(() => {
            if (this.Monday.Value() != this.originalData.Monday || this.Tuesday.Value() != this.originalData.Tuesday || this.Wednesday.Value() != this.originalData.Wednesday || this.Thursday.Value() != this.originalData.Thursday || this.Friday.Value() != this.originalData.Friday || this.Saturday.Value() != this.originalData.Saturday || this.Sunday.Value() != this.originalData.Sunday)
                return true;
            return false;
        });

        this.HasManualChanges = ko.computed(() => {
            const oldState: IAllocationIntervalDetails = this.stateOnManualEditingEnabled();
            if (!oldState)
                return false;
            return oldState.Amount != this.Amount() ||
                oldState.Monday != this.Monday.Value() ||
                oldState.Tuesday != this.Tuesday.Value() ||
                oldState.Wednesday != this.Wednesday.Value() ||
                oldState.Thursday != this.Thursday.Value() ||
                oldState.Friday != this.Friday.Value() ||
                oldState.Saturday != this.Saturday.Value() ||
                oldState.Sunday != this.Sunday.Value();
        });
    }

    public TryChangeAllocationType(newType: number) {
        if (newType == ProlifeSdk.ResourceAllocationByPercentage) {
            if (!this.CanConvertToPercentageValue(this.fullInterval.ServiceOrder()))
                return;
        }
        this.AllocationType(newType);
    }

    public GetAsPercentageValue(serviceOrder: IServiceOrderForInterval): number {
        if (!this.CanConvertToPercentageValue(serviceOrder))
            return null;
        return this.Monday.Value() / serviceOrder.HoursMonday * 100;
    }

    public SaveActualState(): void {
        this.stateOnManualEditingEnabled(this.GetData());
    }

    public CanConvertToPercentageValue(serviceOrder: IServiceOrderForInterval): boolean {
        const soAccessor = (day : string) => {
            return (<any>serviceOrder)["Hours" + day];
        };
        let canConvert = true;
        let totalRatio : any = null;
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        daysOfWeek.forEach((day : string) => {
            const thisValue = this.GetHoursFromDay(day);
            const soValue = soAccessor(day);
            if(soValue == 0 && thisValue != 0) {
                canConvert = false;
                return;
        }
            const ratio = parseFloat((thisValue == 0 && soValue == 0 ? totalRatio : thisValue / soValue).toFixed(3));
            if(totalRatio == null) {
                totalRatio = ratio;
            } else if(totalRatio != ratio) {
                canConvert = false;
        }
        });

        return canConvert;
        }

    public IsEmpty(): boolean {
        return this.Monday.Value() == 0 &&
               this.Tuesday.Value() == 0 &&
               this.Wednesday.Value() == 0 &&
               this.Thursday.Value() == 0 &&
               this.Friday.Value() == 0 &&
               this.Saturday.Value() == 0 &&
               this.Sunday.Value() == 0
    }

    public HasHours(): boolean {
        return this.Monday.Value() > 0 ||
               this.Tuesday.Value() > 0 ||
               this.Wednesday.Value() > 0 ||
               this.Thursday.Value() > 0 ||
               this.Friday.Value() > 0 ||
               this.Saturday.Value() > 0 ||
               this.Sunday.Value() > 0
    }

    public GetHours(): IAllocationPerDays {
        const serviceOrder: IServiceOrderForInterval = this.fullInterval.ServiceOrder();
        if (this.AllocationType() == ProlifeSdk.ResourceAllocationByPercentage) {
            this.Monday.Value(this.Amount() / 100 * serviceOrder.HoursMonday);
            this.Tuesday.Value(this.Amount() / 100 * serviceOrder.HoursTuesday);
            this.Wednesday.Value(this.Amount() / 100 * serviceOrder.HoursWednesday);
            this.Thursday.Value(this.Amount() / 100 * serviceOrder.HoursThursday);
            this.Friday.Value(this.Amount() / 100 * serviceOrder.HoursFriday);
            this.Saturday.Value(this.Amount() / 100 * serviceOrder.HoursSaturday);
            this.Sunday.Value(this.Amount() / 100 * serviceOrder.HoursSunday);
        }
        return {
            HoursMonday: this.Monday.Value(),
            HoursTuesday: this.Tuesday.Value(),
            HoursWednesday: this.Wednesday.Value(),
            HoursThursday: this.Thursday.Value(),
            HoursFriday: this.Friday.Value(),
            HoursSaturday: this.Saturday.Value(),
            HoursSunday: this.Sunday.Value()
        };
    }

    public GetData(): IAllocationIntervalDetails {
        return <IAllocationIntervalDetails> {
            TeamId: this.TeamId,
            TeamName: this.TeamName,
            AllocationId: this.AllocationId,
            AllocationType: this.AllocationType(),
            Amount: this.Amount(),
            Monday: this.Monday.Value(),
            Tuesday: this.Tuesday.Value(),
            Wednesday: this.Wednesday.Value(),
            Thursday: this.Thursday.Value(),
            Friday: this.Friday.Value(),
            Saturday: this.Saturday.Value(),
            Sunday: this.Sunday.Value()
        };
    }

    public SwitchAllocationMode(): void {
        if(this.AllocationType() == ProlifeSdk.ResourceAllocationByHours) {
            if(!this.CanConvertToPercentageValue(this.fullInterval.ServiceOrder())) {
                //TODO: Warning perdita dati

            } else {
                this.AllocationType(ProlifeSdk.ResourceAllocationByPercentage);
            }
        } else {
            this.AllocationType(ProlifeSdk.ResourceAllocationByHours);
        }
    }

    public Select(): void {
        if(this.SelectionEnabled())
            this.Selected(!this.Selected());
    }

    public ManualReset(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Allocations.ResetDataMessage,
            ProlifeSdk.TextResources.Allocations.CancelButton,
            ProlifeSdk.TextResources.Allocations.ConfirmButton,
            (confirm: boolean) => {
                if (!confirm)
                    return;
                this.ResetData();
            }
        );
    }

    public ResetData(): void {
        this.AllocationType(this.originalData.AllocationType);
        this.Amount(this.originalData.Amount);
        this.Monday.Value(this.originalData.Monday);
        this.Tuesday.Value(this.originalData.Tuesday);
        this.Wednesday.Value(this.originalData.Wednesday);
        this.Thursday.Value(this.originalData.Thursday);
        this.Friday.Value(this.originalData.Friday);
        this.Saturday.Value(this.originalData.Saturday);
        this.Sunday.Value(this.originalData.Sunday);
    }

    public GetHoursFromDay(day : string) {
        return (<any>this)[day].Value();
    }
}

class EditingMode {
    public ModeId: number;
    public ModeDescription: string;

    constructor(modeId: number, modeDescription: string) {
        this.ModeId = modeId;
        this.ModeDescription = modeDescription;
    }
}