import * as React from "@abstraqt-dev/jsxknockout"
import ko = require("knockout");
import { reloadNow, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { RichTextInput } from "../../Components/RichTextInput";
import jss from "jss";
import { If, IfNot } from "../../Components/IfIfNotWith";
import { Layout } from "../../Components/Layouts";
import { ITicketRepliesSidebarDataSourceModel, TicketRepliesSidebarDataSource } from "../../DataSources/TicketRepliesSidebarDataSource";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../DataSources/IDataSource";
import { Select2 } from "../../Components/Select2Component";
import { ITicketCategoriesDataSourceModel, TicketCategoriesDataSource } from "../../DataSources/TicketCategoriesDataSource";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { ITableItem, Table } from "../../Components/TableComponent/TableComponent";
import { LazyImport } from "../../Core/DependencyInjection";
import { IFullTicketData, IFullTicketResources, ITicketsService } from "../TicketsService";
import { IIdGeneratorService } from "../../ProlifeSdk/IdGeneratorService";
import { Column, ColumnBody, ColumnHeader } from "../../Components/TableComponent/CustomColumn";
import { IUserInfo } from "../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { Portlet } from "../../Components/Portlet";
import { BackButton } from "../../Components/BackButton";
import { TicketsApplication } from "./TicketsApplication";
import { Application } from "../../Components/Application";
import { NavigationMenu } from "../../Components/NavigationMenu";
import { TextInput } from "../../Components/TextInput";
import { IResourceDataSourceModel, ResourcesDataSource } from "../../DataSources/ResourcesDataSource";
import  * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { TicketClosingTypesDataSource } from "../../DataSources/TicketClosingTypesDataSource";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { TicketTask } from "./viewModels/TicketTask";
import { TicketReply } from "./viewModels/TicketReply";
import { FileContainer } from "../../Components/FileContainer";
import TsxForEach from "../../Components/ForEach";
import { INavBarAction } from "../../ProlifeSdk/interfaces/desktop/ISystemHeader";
import { ITreatmentsDataSourceModel, TreatmentsDataSource } from "../../DataSources/TreatmentsDataSource";
import { JobOrdersDataSource } from "../../DataSources/JobOrdersDataSource";
import { DateTimeInput } from "../../Components/DateTimeInput";
import { ITicketOriginsDataSourceModel, TicketOriginsDataSource } from "../../DataSources/TicketOriginsDataSource";
import moment = require("moment");
import { IValidationService, IValidator } from "../../ProlifeSdk/ValidationService";

const { classes } = jss.createStyleSheet({
    ticketTimeline : {
        "& .timeline-blue > .timeline-body": {
            border: "1px solid #4b8df8",
            backgroundColor: "#FFF !important",
            color: "#4b8df8"
        },
        "& [class*='timeline'] > .timeline-body > h2": {
            borderBottom: "1px solid"
        },
        "& .timeline-green > .timeline-body": {
            border: "1px solid #35aa47",
            backgroundColor: "#FFF !important",
            color: "#35aa47"
        },
        "& .timeline-grey > .timeline-body": {
            border: "1px solid #555555",
            backgroundColor: "#FFF !important",
            color: "#555555"
        },
        "& [class*='timeline'] > .timeline-body .no-color": {
            color: "#000000"
        }
    },
    shortDescription: {
        "&.short-description": {
            height: "6.5em",
            border: "1px solid #4b8df8",
            "-webkit-line-clamp": "4",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            overflow: "hidden"
        }
    }
}).attach();

/* TODO
Rosso maiuscolo autore e data - OK
Allegati del ticket - OK
Rimozione campo titolo ticket - OK
pulsanti tondi, abilita modifica in blu, meno dettagli in verde - OK
nascondi pulsanti in portlet collassata - OK
invia -> salva - OK
x -> chiudi - OK
Dati file allegati - OK
Bordo campi caselle testo ridotte - OK
Descrizione ridotta 3 righe plain text - OK
Colore pulsanti superiori - ???
*/

export interface ITicketComponentProps {
    application: TicketsApplication;
    ticketId?: number;
    ref?: any;
    onBack: () => void;
}

export class TicketResource {
    FkResource: ko.Observable<number> = ko.observable();
    ResourceType: string = ProlifeSdk.HumanResources;
    FullName: string;

    constructor(resource: IFullTicketResources, fullName: string = null){
        this.FkResource(resource.FkResource);

        if (resource.ResourceType)
            this.ResourceType = resource.ResourceType;

        if (fullName)
            this.FullName = fullName;
    }
}

export class TicketTreatment {
    FkTreatment: number;
    Title: string;
}

export class TicketComponent implements IDataSourceListener {
    @LazyImport(nameof<ITicketsService>())
    private ticketsService: ITicketsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    validator: IValidator<TicketComponent>;

    public Author: ko.Observable<string> = ko.observable();
    public InitialAuthor: ko.Observable<string> = ko.observable();
    public Date: ko.Observable<Date> = ko.observable();
    public InitialTitle: ko.Observable<string> = ko.observable("Nuovo Ticket");
    public InitialSubtitle: ko.Observable<string> = ko.observable();
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreationUserFullName: ko.Observable<string> = ko.observable();
    public IsClosed: ko.Observable<boolean> = ko.observable(false);

    public Description: ko.Observable<string> = ko.observable();
    public DescriptionTrigger: ko.Observable<any> = ko.observable();

    public IsReply: ko.Observable<boolean> = ko.observable(false);
    public Reply: ko.Observable<TicketReply> = ko.observable();

    public IsPrivateNote: ko.Observable<boolean> = ko.observable(false);
    public PrivateNote: ko.Observable<TicketReply> = ko.observable();

    public IsCompactView: ko.Observable<boolean> = ko.observable(true);

    public SidebarDataSource: TicketRepliesSidebarDataSource;

    public Tasks: ko.ObservableArray<TicketTask> = ko.observableArray();
    public JobOrders: ko.Computed<string[]>;
    public JobOrdersDataSource: JobOrdersDataSource;
    
    public CategoriesDataSource: TicketCategoriesDataSource;
    public SelectedCategory: ko.Observable<number> = ko.observable();
    public SelectedCategoryTitle: ko.Observable<string> = ko.observable();

    public OriginsDataSource: TicketOriginsDataSource;
    public SelectedOrigin: ko.Observable<number> = ko.observable();
    public SelectedOriginTitle: ko.Observable<string> = ko.observable();

    public Resources: ko.ObservableArray<TicketResource> = ko.observableArray();
    public ResourcesDataSource: ResourcesDataSource;
    public SelectedResourceId: ko.Observable<number> = ko.observable();
    public SelectedResourceFullName: ko.Observable<string> = ko.observable();

    public Replies: ko.ObservableArray<TicketReply> = ko.observableArray();
    public SelectedReply: ko.Observable<number> = ko.observable();

    public ItemData: IFullTicketData;

    private actions: INavBarAction[] = [];

    public ReadOnly: ko.Observable<boolean> = ko.observable(true);

    public Treatments: ko.ObservableArray<TicketTreatment> = ko.observableArray();
    public TreatmentsDataSource: TreatmentsDataSource;
    public SelectedTreatmentId: ko.Observable<number> = ko.observable();
    public SelectedTreatmentTitle: ko.Observable<string> = ko.observable();

    public TicketFiles: ko.ObservableArray<string> = ko.observableArray();

    constructor(private props: ITicketComponentProps){
        this.SidebarDataSource = new TicketRepliesSidebarDataSource();
        this.CategoriesDataSource = new TicketCategoriesDataSource();
        this.OriginsDataSource = new TicketOriginsDataSource();
        this.ResourcesDataSource = new ResourcesDataSource();
        this.TreatmentsDataSource = new TreatmentsDataSource();
        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrders = ko.computed(() => {
            return this.Tasks().map(t => t.JobOrderName()).distinctValues();
        }).extend({ trackArrayChanges: true });

        const userName = this.userInfo.getUserName();
        this.InitialAuthor(userName);

        this.validator = this.validationService.createValidator<TicketComponent>()
            .isNotNullUndefinedOrLessOrEqualZero(t => t.SelectedCategory(), TextResources.Tickets.SelectACategory)
            .isNotNullUndefinedOrLessOrEqualZero(t => t.SelectedOrigin(), TextResources.Tickets.SelectAnOrigin)
            .isNotNullOrUndefinedOrWhiteSpace(t => t.Author(), TextResources.Tickets.ErrorNoAuthor)
            .isNotNullOrUndefined(t => t.Date(), TextResources.Tickets.ErrorNoDate)
            .isNotNullOrUndefinedOrWhiteSpace(t => t.Description(), TextResources.Tickets.ErrorNoDescription);
    }
    
    async componentDidMount(){
        this.props.application.canShowSidebar(false);
        if (this.props.ticketId > 0) {
            this.loadData();
            this.actions.push(this.props.application.addNavBarAction("Aggiungi Risposta", ko.observable("fa fa-comment"), () => !this.IsClosed(), () => this.reply()));
            this.actions.push(this.props.application.addNavBarAction("Aggiungi Nota Privata", ko.observable("fa fa-eye-slash"), () => !this.IsClosed(), () => this.addPrivateNote()));
            this.actions.push(this.props.application.addNavBarAction("Chiudi Ticket", ko.observable("fa fa-lock"), () => !this.IsClosed(), () => this.closeTicket()));
        } else {
            this.ReadOnly(false);
            this.Author(this.InitialAuthor());
            this.Date(new Date());
            this.IsCompactView(false);
        }
    }

    componentWillUnmount(){
        for (let action of this.actions){
            this.props.application.removeNavBarAction(action);
        }
    }
    
    async loadData(){
        let item = await this.ticketsService.GetFullTicketData(this.props.ticketId, true);
        this.ItemData = item;
        this.IsClosed(item.Ticket.FkClosingType > 0);
        this.InitialTitle(item.Ticket.Author + " - " + moment(item.Ticket.Date).format("DD/MM/YYYY HH:mm") + (this.IsClosed() ? " (Chiuso)" : "" ));
        this.CategoriesDataSource.selectByIds(...[item.Ticket.FkCategory]);
        this.OriginsDataSource.selectByIds(...[item.Ticket.FkOrigin]);
        let [selectedCat] = await this.CategoriesDataSource.getById(null, [item.Ticket.FkCategory]);
        let catTitle = selectedCat.model.Title;
        let [selectedOr] = await this.OriginsDataSource.getById(null, [item.Ticket.FkOrigin]);
        let orTitle = selectedOr.model.Title;
        this.InitialSubtitle(catTitle + " - " + orTitle);
        this.Author(item.Ticket.Author);
        this.Date(item.Ticket.Date);
        this.Description(item.Ticket.Description);
        this.CreationDate(item.Ticket.CreationDate);
        this.CreationUserFullName(item.Ticket.CreationUserFullName);
        
        let resourcesFromDataSource = await this.ResourcesDataSource.getById(null, item.Resources.map((r) => r.FkResource));
        let loadedResources = resourcesFromDataSource.map((x) => new TicketResource({
            FkTicket: this.props.ticketId, 
            FkResource: x.model.Resource.Id,
            ResourceType: null
        }, x.title));
        this.Resources.push(...loadedResources);

        this.Tasks(item.Tasks.map(t => new TicketTask(t)));

        this.Treatments(item.Treatments.map(t => ({FkTreatment: t.FkTreatment, Title: t.Title})));

        this.TicketFiles(item.Attachments.map(f => f.AttachmentGUID));

        this.Replies(item.Replies.map(r => new TicketReply(r, item.RepliesAttachments.filter(ra => ra.FkReply == r.Id))));
        this.SidebarDataSource.setReplies(item.Replies);
        this.SidebarDataSource.refresh();
    }

    async reloadReplies(){
        let item = await this.ticketsService.GetFullTicketData(this.props.ticketId, true);
        this.Replies(item.Replies.map(r => new TicketReply(r, item.RepliesAttachments.filter(ra => ra.FkReply == r.Id))));
        this.SidebarDataSource.setReplies(item.Replies);
        this.SidebarDataSource.refresh();
    }
    
    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (!!model && sender === this.ResourcesDataSource) {
            let resourceModel = model as IResourceDataSourceModel;
            this.SelectedResourceFullName(resourceModel.model.Resource.Name + ' ' + resourceModel.model.Resource.Surname);
            this.addResource();
            setTimeout(() => {
                this.ResourcesDataSource.select(...[]);
                this.SelectedResourceId(undefined);
                this.SelectedResourceFullName(undefined);
            }, 250);
        }
        if (!!model && sender === this.TreatmentsDataSource) {
            let treatmentModel = model as ITreatmentsDataSourceModel;
            this.SelectedTreatmentTitle(treatmentModel.model.Title);
            this.addTreatment();
            setTimeout(() => {
                this.TreatmentsDataSource.select(...[]);
                this.SelectedTreatmentId(undefined);
                this.SelectedTreatmentTitle(undefined);
            }, 250)
        }
        if (!!model && sender === this.CategoriesDataSource){
            let categoryModel = model as ITicketCategoriesDataSourceModel;
            this.SelectedCategoryTitle(categoryModel.model.Title);
        }
        if (!!model && sender === this.OriginsDataSource){
            let originModel = model as ITicketOriginsDataSourceModel;
            this.SelectedOriginTitle(originModel.model.Title);
        }
        if (sender === this.SidebarDataSource) {
            let replyModel = model as ITicketRepliesSidebarDataSourceModel;
            this.SelectedReply(replyModel.model.Id);
        }
    }

    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {

    }

    addResource(){
        if (!this.SelectedResourceId()){
            this.infoToastService.Error(TextResources.Tickets.SelectAResource);
            return;
        }

        if (this.Resources().any(r => r.FkResource() == this.SelectedResourceId())){
            this.infoToastService.Error(TextResources.Tickets.ErrorResourceAlreadyInList);
            return;
        }

        let er = new TicketResource({
            FkTicket: this.props.ticketId,
            FkResource: this.SelectedResourceId(),
            ResourceType: null
        }, this.SelectedResourceFullName());

        this.Resources.push(er);
    }

    deleteResource(resource: TicketResource){
        this.dialogsService.Confirm(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem, (result) => {
            if (result)
                this.Resources.remove(resource);
        });
    }

    toggleEdit(){
        this.ReadOnly(false);
    }

    toggleCollapse(){
        this.IsCompactView(!this.IsCompactView());
    }

    reply(){
        if (!this.IsReply()){
            let reply = new TicketReply();
            reply.Id = this.idGeneratorService.getNextId();
            reply.Author(this.InitialAuthor());
            reply.Date(new Date());
            this.Reply(reply);
            this.IsReply(true);
        }
    }

    cancelReply(){
        this.IsReply(false);
    }

    addPrivateNote(){
        if (!this.IsPrivateNote()){
            let note = new TicketReply();
            note.Id = this.idGeneratorService.getNextId();
            note.Author(this.InitialAuthor());
            note.Date(new Date());
            note.IsPrivate = true;
            this.PrivateNote(note);
            this.IsPrivateNote(true);
        }
    }

    cancelPrivateNote(){
        this.IsPrivateNote(false);
    }

    back(){
        this.props.onBack();
    }

    createTaskModel(task: TicketTask){
        return {
            id: -1,
            title: "",
            isLeaf: true,
            isGroup: false,
            model: task
        };
    }

    addTask(){
        let task = new TicketTask();
        task.FkTicket = this.props.ticketId;
        this.Tasks.push(task);
    }

    deleteTask(task: TicketTask){
        this.dialogsService.Confirm(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem, (result) => {
            if (result)
                this.Tasks.remove(task);
        });
    }

    createTreatmentModel(treatment: TicketTreatment){
        return {
            id: treatment.FkTreatment,
            title: "",
            isLeaf: true,
            isGroup: false,
            model: treatment
        }
    }

    addTreatment(){
        this.Treatments.push({
            FkTreatment: this.SelectedTreatmentId(),
            Title: this.SelectedTreatmentTitle()
        });
    }

    deleteTreatment(treatment: TicketTreatment){
        this.dialogsService.Confirm(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem, (result) => {
            if (result)
                this.Treatments.remove(treatment);
        });
    }

    async insertOrUpdateReply(reply: TicketReply) {
        let isValid = reply.validator.validateAndShowInfoToast(reply);

        if (isValid){   
            try {
                await this.ticketsService.InsertOrUpdateReply([{
                        Id: reply.Id,
                        Author: reply.Author(),
                        Date: reply.Date(),
                        FkTicket: this.props.ticketId,
                        IsPrivate: reply.IsPrivate,
                        Text: reply.Text()
                    }], 
                    reply.Attachments(),
                    this.userInfo.getIdUser());
                
                if (reply.Id < 0) {
                    if (reply.IsPrivate){
                        this.IsPrivateNote(false);
                    } else {
                        this.IsReply(false);
                    }
                }

                this.infoToastService.Success(TextResources.Tickets.UpdateSuccess);
                await this.reloadReplies();
            } catch (err){
                this.infoToastService.Error(err.message);
            }
        }
    }

    async deleteReply(reply: TicketReply) {
        let result = await this.dialogsService.ConfirmAsync(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem); 
        if (result) {
            try {
                await this.ticketsService.DeleteReply(reply.Id, this.userInfo.getIdUser());
                this.infoToastService.Success(TextResources.Tickets.UpdateSuccess);
                await this.reloadReplies();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    async closeTicket() {
        let dialog = new CloseTicketDialog(this.props.ticketId);
        let result = await dialog.showModal();

        if (result) {
            this.infoToastService.Success(TextResources.GDPR.AddExistingSuccess);
            this.props.onBack();
        }
    }

    async saveTicket(){
        let isValid = this.validator.validateAndShowInfoToast(this);

        if (isValid){
            for (let task of this.Tasks()){
                if (!task.validator.validateAndShowInfoToast(task)) {
                    isValid = false;
                    break;
                }
            }
        }

        if (isValid){
            try {
                await this.ticketsService.InsertOrUpdateTicket({
                    ticket: [{
                        Id: this.props.ticketId,
                        Author: this.Author(),
                        Date: this.Date(),
                        Description: this.Description(),
                        FkCategory: this.SelectedCategory(),
                        FkOrigin: this.SelectedOrigin()
                    }],
                    fkUser: this.userInfo.getIdUser(),
                    tasks: this.Tasks().map(t => ({
                        FkTicket: this.props.ticketId,
                        FkJobOrder: t.FkJobOrder(),
                        FkWorkflow: t.FkWorkflow(),
                        FkTask: t.FkTask()
                    })),
                    resources: this.Resources().map(r => ({
                        FkResource: r.FkResource(),
                        ResourceType: r.ResourceType
                    })),
                    treatmentsIds: this.Treatments().map(t => t.FkTreatment),
                    attachments: this.TicketFiles()
                });

                this.infoToastService.Success(TextResources.Tickets.TicketSavedSuccessfully);

                if (!(this.props.ticketId > 0)) {
                    this.props.onBack();
                } 
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    renderPortlet(){
        let vm = this;
        let ticketTasks: IDataSourceModel<number, TicketTask>;
        let portletVM: ko.Observable<Portlet> = ko.observable();

        return ComponentUtils.bindTo(
        <Portlet collapsible={true} initialCollapsed={false} forwardRef={(p) => portletVM(p)}>
            <Portlet.Header>
                <BackButton onClick={vm.back.bind(vm)} style={{marginRight: "20px"}} />
                <Portlet.Header.Default title={vm.InitialTitle} subTitle={vm.InitialSubtitle} className="font-red-sunglo bold uppercase" />
            </Portlet.Header>
            <Portlet.Actions>
                <If condition={() => portletVM() ? !portletVM().Collapsed() : false}>
                    {() => <div className="flex-container flex-child-center">
                    <If condition={() => !vm.IsCompactView() && !vm.IsClosed()}>
                        {() => <>
                            <If condition={vm.ReadOnly}>
                                {() => <button className="btn btn-sm btn-circle btn-primary" data-bind={{click: vm.toggleEdit.bind(vm), clickBubble: false}}>Abilita Modifica</button>}
                            </If>
                            <IfNot condition={vm.ReadOnly}>
                                {() => <button className="btn btn-sm btn-circle btn-success" data-bind={{click: vm.saveTicket.bind(vm), clickBubble: false}}>Salva Ticket</button>}
                            </IfNot>
                        </>}
                    </If>
                    <button className="btn btn-sm btn-circle green" data-bind={{click: vm.toggleCollapse.bind(vm), clickBubble: false}}>
                        <If condition={vm.IsCompactView}>
                            {() => <>Più dettagli</>}
                        </If>
                        <IfNot condition={vm.IsCompactView}>
                            {() => <>Meno dettagli</>}
                        </IfNot>
                    </button>
                </div> }
                </If>
                {/* <If condition={vm.InitialCollapsed}>
                    {() => (
                    <div className="flex-container flex-child-center">
                        <button className="btn btn-sm btn-circle btn-primary" data-bind={{click: vm.reply.bind(vm), clickBubble: false}}>Rispondi</button>
                        <button className="btn btn-sm btn-circle btn-primary" data-bind={{click: vm.addPrivateNote.bind(vm), clickBubble: false}}>Aggiungi nota privata</button>
                        <button className="btn btn-sm btn-circle btn-danger" data-bind={{click: vm.closeTicket.bind(vm), clickBubble: false}}>Chiudi Ticket</button>
                    </div>)}
                </If> */}
            </Portlet.Actions>
            <Portlet.Body>
                {() => (
                    <>
                    <If condition={vm.IsCompactView}>
                        {() => <div className="row">
                                <div className="col-md-6" style={{height: "100%"}}>
                                    <div className={ComponentUtils.classNames(classes.shortDescription, "form-control short-description")} style={{margin: "5px 0", width: "100%"}} data-bind={{text: $(vm.Description()).text()}}></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-control" style={{margin: "5px 0", height: "auto", minHeight: "34px", border: "1px solid #4b8df8"}}>
                                                <If condition={() => vm.Resources().length == 0}>
                                                    {() => <span>Nessuna risorsa da visualizzare.</span>}
                                                </If>
                                                <TsxForEach data={vm.Resources} as={"ticketResource"}>
                                                    {(ticketResource: TicketResource) => (
                                                        <span className="label label-primary" style={{marginBottom: "5px", marginRight: "5px", marginTop: "5px", display: "inline-block"}}>
                                                            <span data-bind={{text: ticketResource.FullName}}></span>
                                                        </span>
                                                    )}
                                                </TsxForEach>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-control" style={{margin: "5px 0", height: "auto", minHeight: "34px", border: "1px solid #4b8df8"}}>
                                                <If condition={() => vm.JobOrders().length == 0}>
                                                    {() => <span>Nessuna commessa da visualizzare.</span>}
                                                </If>
                                                <TsxForEach data={vm.JobOrders} as={"ticketJobOrder"}>
                                                    {(ticketJobOrder: string) => <span className="label label-primary" style={{marginBottom: "5px", marginRight: "5px", marginTop: "5px", display: "inline-block"}}>
                                                        <span data-bind={{text: ticketJobOrder}}></span>
                                                    </span>}
                                                </TsxForEach>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </If>
                    <IfNot condition={vm.IsCompactView}>
                        {() => <>
                        <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <TextInput label={"Autore"} value={vm.Author} selectOnFocus readonly={vm.ReadOnly} />
                                </div>
                                <div className="col-md-6">
                                    <DateTimeInput label={"Data"} value={vm.Date} noIcon allowClear parent={"body"} readonly={vm.ReadOnly} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <TextInput value={vm.Title} label={TextResources.Tickets.Title} selectOnFocus readonly={vm.ReadOnly} /> */}
                                    <RichTextInput readonly={vm.ReadOnly} value={vm.Description} trigger={vm.DescriptionTrigger} placeholder={'Descrizione Ticket'} resizable simple/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{marginTop: "5px"}}>
                                        <FileContainer readOnly={vm.ReadOnly} files={vm.TicketFiles} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select2 dataSource={vm.CategoriesDataSource} readonly={vm.ReadOnly}
                                        label={TextResources.Tickets.Category}
                                        value={vm.SelectedCategory}
                                        placeholder={TextResources.Tickets.SelectACategory}
                                        listener={vm}
                                        />
                                </div>
                                <div className="col-md-6">
                                    <Select2 dataSource={vm.OriginsDataSource} readonly={vm.ReadOnly}
                                        label={TextResources.Tickets.Origin}
                                        value={vm.SelectedOrigin}
                                        placeholder={TextResources.Tickets.SelectAnOrigin}
                                        listener={vm}
                                        />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Select2 value={vm.SelectedResourceId} label={TextResources.Tickets.RelatedResources}
                                        dataSource={vm.ResourcesDataSource} 
                                        allowClear={true}
                                        placeholder={TextResources.Tickets.AddAResource}
                                        readonly={vm.ReadOnly}
                                        listener={vm}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-control" style={{margin: "5px 0", height: "auto", minHeight: "34px"}}>
                                        <TsxForEach data={vm.Resources} as={"ticketResource"}>
                                            {(ticketResource: TicketResource) => (
                                                <span className="label label-primary" style={{marginBottom: "5px", marginRight: "5px", marginTop: "5px", display: "inline-block"}}>
                                                    <span className="btn btn-xs" data-bind={{hidden: vm.ReadOnly, click: vm.deleteResource.bind(vm, ticketResource)}}>&times;</span>
                                                    <span data-bind={{text: ticketResource.FullName}}></span>
                                                </span>
                                            )}
                                        </TsxForEach>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Select2 label={"Trattamenti Correlati"} 
                                        placeholder={"Aggiungi un Trattamento..."} 
                                        dataSource={vm.TreatmentsDataSource} 
                                        value={vm.SelectedTreatmentId}
                                        readonly={vm.ReadOnly}
                                        allowClear 
                                        listener={vm} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-control" style={{margin: "5px 0", height: "auto", minHeight: "34px"}}>
                                        <TsxForEach data={vm.Treatments} as={"ticketTreatment"}>
                                            {(ticketTreatment: TicketTreatment) => (
                                                <span className="label label-primary" style={{marginBottom: "5px", marginRight: "5px", marginTop: "5px", display: "inline-block"}}>
                                                    <span className="btn btn-xs" data-bind={{hidden: vm.ReadOnly, click: vm.deleteTreatment.bind(vm, ticketTreatment)}}>&times;</span>
                                                    <span data-bind={{text: ticketTreatment.Title}}></span>
                                                </span>
                                            )}
                                        </TsxForEach>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                        <div className="col-md-12">
                            <Table rowAs={"ticketTasks"} className="fixed-height" compact editable fixedLayout dataSource={{ array: this.Tasks, factory: this.createTaskModel }}>
                                <Column title={"Commessa"}>
                                    <ColumnBody>
                                        {(item: ITableItem<TicketTask>) => (
                                            <Select2 simple allowClear readonly={vm.ReadOnly}
                                            dataSource={item.Data.model.JobOrdersDataSource} 
                                            value={item.Data.model.FkJobOrder} 
                                            listener={item.Data.model}/>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column title={"Piano"}>
                                    <ColumnBody>
                                        {(item: ITableItem<TicketTask>) => (
                                            <Select2 simple allowClear readonly={vm.ReadOnly}
                                            dataSource={item.Data.model.WorkflowsDataSource} 
                                            value={item.Data.model.FkWorkflow} 
                                            listener={item.Data.model}/>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column title={"Task (facoltativo)"}>
                                    <ColumnBody>
                                        {(item: ITableItem<TicketTask>) => (
                                            <Select2 simple allowClear readonly={vm.ReadOnly}
                                            dataSource={item.Data.model.TasksDataSource} 
                                            value={item.Data.model.FkTask} 
                                            listener={item.Data.model}/>
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column headerCssClasses="text-right" cssClasses="text-right" style={{width: "30px"}}>
                                    <ColumnHeader>
                                        <ko-bind data-bind={{ifnot: vm.ReadOnly}}>
                                            <button className="btn btn-primary btn-xs" title={TextResources.Tickets.Add} data-bind={{click: vm.addTask.bind(vm)}}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </ko-bind>
                                    </ColumnHeader>
                                    <ColumnBody>
                                        <ko-bind data-bind={{ifnot: vm.ReadOnly}}>
                                            <button className="btn btn-danger btn-xs" title={TextResources.Tickets.Delete} data-bind={{click: vm.deleteTask.bind(vm, ticketTasks.model)}}>
                                                <i className="fa fa-trash-o"></i> 
                                            </button>
                                        </ko-bind>
                                    </ColumnBody>
                                </Column>
                            </Table>
                        </div>
                    </div>
                        </div>
                    </div>
                    </>}
                    </IfNot>
                    </>
                )}
            </Portlet.Body>
        </Portlet>, this, "vm");
    }

    render(){
        let vm = this;
        let reply: TicketReply;

        return ComponentUtils.bindTo(
            <Application>
                <Application.LeftMenu>
                    <NavigationMenu allowNoSelection wrapperClassName="page-sidebar" dataSource={this.SidebarDataSource} listener={this} />
                </Application.LeftMenu>
                <Application.Content>
                    <Layout.Grid columns={['1fr']} rows={['auto', '1fr']}>
                        <Layout.Grid.Cell row={1} column={1}>
                            {this.renderPortlet()}
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell className="flex-vertical" row={2} column={1}>
                    <Layout.ScrollContainer systemScrollable>
                    <If condition={() => this.props.ticketId > 0}>
                        {() => {
                                        return (
                                            <ul className={ComponentUtils.classNames("timeline", classes.ticketTimeline)} style={{ marginTop: "10px", paddingRight: "5px" }}>
                                                <If condition={() => vm.Replies().length == 0}>
                                                    {() => (
                                                        <li className="timeline-grey">
                                                            <div className="timeline-icon">
                                                                <i className="fa fa-info"></i>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <h2>Il Ticket non contiene risposte.</h2>
                                                            </div>
                                                        </li>
                                                    )}
                                                </If>
                                                <TsxForEach data={vm.Replies} as={"reply"}>
                                                    {(reply: TicketReply) => (
                                                    <li data-bind={{ scrollIntoViewIfNeeded: () => vm.SelectedReply() === reply.Id, css: { "timeline-blue": !reply.IsPrivate, "timeline-grey": reply.IsPrivate } }}>
                                                        <div className="timeline-time">
                                                            <If condition={reply.ReadOnly}>
                                                                {() => <>
                                                                    <span className="time" data-bind={{ text: reply.Author }}></span>
                                                                    <span className="date" data-bind={{ dateTimeText: reply.Date }}></span>
                                                                </>}
                                                            </If>
                                                            <IfNot condition={reply.ReadOnly}>
                                                                {() => <>
                                                                    <TextInput simple selectOnFocus value={reply.Author} />
                                                                    <DateTimeInput simple value={reply.Date} />
                                                                </>}
                                                            </IfNot>
                                                        </div>
                                                        <div className="timeline-icon">
                                                            <i className="fa" data-bind={{ css: { "fa-comment": !reply.IsPrivate, "fa-eye-slash": reply.IsPrivate } }}></i>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <div className="timeline-content flex-container flex-vertical flex-1">
                                                                <div className="row">
                                                                    <div className="col-md-10 no-color">
                                                                        <RichTextInput inline value={reply.Text} trigger={reply.TextTrigger} readonly={reply.ReadOnly} resizable simple />
                                                                    </div>
                                                                    <div className="col-md-2 text-right">
                                                                        <IfNot condition={vm.IsClosed}>
                                                                            {() => <>
                                                                                <If condition={reply.ReadOnly}>
                                                                                    {() => <button className="btn btn-circle btn-warning btn-sm" data-bind={{click: reply.toggleEdit.bind(reply)}}><i className="fa fa-edit"></i></button> }
                                                                                </If>
                                                                                <IfNot condition={reply.ReadOnly}>
                                                                                    {() => <>
                                                                                    <button className="btn btn-circle btn-danger btn-sm" data-bind={{click: vm.deleteReply.bind(vm, reply)}}><i className="fa fa-trash-o"></i></button>
                                                                                    <button className="btn btn-circle btn-success btn-sm" style={{marginRight: "5px"}} data-bind={{click: vm.insertOrUpdateReply.bind(vm, reply)}}><i className="fa fa-save"></i></button>
                                                                                    </>}
                                                                                </IfNot>
                                                                            </>}
                                                                        </IfNot>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="timeline-footer" style={{ borderTop: "1px solid" }}>
                                                                <div className="row" style={{ marginTop: "10px" }}>
                                                                    <div className="col-md-12">
                                                                        {/* <span>Allegati: <span className="label label-primary"><i className="fa fa-paperclip"></i>Screenshot.jpg</span></span> */}
                                                                        <FileContainer files={reply.Attachments} readOnly={reply.ReadOnly} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>)}
                                                </TsxForEach>
                                                <If condition={vm.IsReply}>
                                                    {() => (
                                                        <li className="timeline-green">
                                                            <div className="timeline-time">
                                                                <TextInput simple selectOnFocus value={vm.Reply().Author} />
                                                                <DateTimeInput simple value={vm.Reply().Date} />
                                                            </div>
                                                            <div className="timeline-icon">
                                                                <i className="fa fa-comment"></i>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <h2>
                                                                    Inserisci Risposta
                                                                    <span className="pull-right flex-container flex-child-center">
                                                                        <button className="btn btn-success btn-circle btn-sm pull-right" data-bind={{click: vm.insertOrUpdateReply.bind(vm, vm.Reply())}}>Salva</button> 
                                                                        <button className="btn btn-danger btn-sm btn-circle pull-right" data-bind={{ click: vm.cancelReply.bind(vm) }}>Chiudi</button>
                                                                    </span>
                                                                </h2> 
                                                                <div className="timeline-content no-color">
                                                                    <RichTextInput inline value={vm.Reply().Text} trigger={vm.Reply().TextTrigger} placeholder={'Test Risposta'} resizable simple />
                                                                </div>
                                                                <div className="timeline-footer">
                                                                    <div className="row" style={{ marginTop: "10px" }}>
                                                                        <div className="col-md-12">
                                                                            <FileContainer files={vm.Reply().Attachments} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </If>
                                                <If condition={vm.IsPrivateNote}>
                                                    {() => (
                                                        <li className="timeline-grey">
                                                            <div className="timeline-time">
                                                                <TextInput simple selectOnFocus value={vm.PrivateNote().Author} />
                                                                <DateTimeInput simple value={vm.PrivateNote().Date} />
                                                            </div>
                                                            <div className="timeline-icon">
                                                                <i className="fa fa-eye-slash"></i>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <h2>
                                                                    Inserisci Nota Privata 
                                                                    <span className="pull-right flex-container flex-child-center">
                                                                        <button className="btn btn-success btn-circle btn-sm pull-right" data-bind={{click: vm.insertOrUpdateReply.bind(vm, vm.PrivateNote())}}>Salva</button> 
                                                                        <button className="btn btn-danger btn-sm btn-circle pull-right" data-bind={{ click: vm.cancelPrivateNote.bind(vm) }}>Chiudi</button>
                                                                    </span>
                                                                </h2>
                                                                <div className="timeline-content no-color">
                                                                    <RichTextInput inline value={vm.PrivateNote().Text} trigger={vm.PrivateNote().TextTrigger} placeholder={'Test Nota'} resizable simple />
                                                                </div>
                                                                <div className="timeline-footer">
                                                                    <div className="row" style={{ marginTop: "10px" }}>
                                                                        <div className="col-md-12">
                                                                            <FileContainer files={vm.PrivateNote().Attachments} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </If>
                                            </ul>
                                        );
                                    }}
                    </If>
                    </Layout.ScrollContainer>
                        </Layout.Grid.Cell>
                    </Layout.Grid> 
                </Application.Content>
            </Application>, this, "vm");
    }
}

export class CloseTicketDialog extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<ITicketsService>())
    private ticketsService: ITicketsService;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    public TicketClosingTypesDataSouce: TicketClosingTypesDataSource;
    public SelectedClosingTypeId: ko.Observable<number> = ko.observable();

    constructor(private ticketId: number){
        super({});
        this.title("Conferma chiusura Ticket");
        this.TicketClosingTypesDataSouce = new TicketClosingTypesDataSource();
    }

    showModal(){
        return this.dialogsService.ShowModal(this);
    }

    async action(){
        if (!this.SelectedClosingTypeId()) {
            this.infoToastService.Error(TextResources.Tickets.ErrorZeroClosingTypes);
        } else {
            try {
                await this.ticketsService.CloseTicket(this.ticketId, this.SelectedClosingTypeId(), this.userInfo.getIdUser());
                this.modal.close(true);
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    renderBody() {
        let vm = this;

        return ComponentUtils.bindTo(<>
            <h3>Sei sicuro di voler chiudere il ticket?</h3>
            <Select2 value={vm.SelectedClosingTypeId} 
            dataSource={vm.TicketClosingTypesDataSouce}
            label={"Inserisci il motivo:"} />
        </>, this, "vm");
    }

}

if (module.hot) {
    module.hot.accept();
    reloadNow(TicketComponent);
}