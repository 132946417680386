import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { EstimateBudgetRow, TodoListActivity } from "./TodoListActivity";
import { CombinedChangesHistory, TodoListTask } from "./providers/TodoListTask";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { ActivityDetailsDialogBase as ActivityDetailsEditorBase } from "./dialogs/ActivityDetailsDialogBase";
import { List, ListItem } from "../../../../Components/ListComponent";
import { TasksDataSource, ITasksDataSourceModel } from "../../../../DataSources/TasksDataSource";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../../../DataSources/JobOrdersDataSource";
import { WorkflowsDataSource, IWorkflowsDataSourceModel } from "../../../../DataSources/WorkflowsDataSource";
import { TemplatesDataSource, ITemplateDataSourceModel } from "../../../../DataSources/TemplatesDataSource";
import {
    TemplateTasksDataSource,
    ITemplateTasksDataSourceModel,
} from "../../../../DataSources/TemplateTasksDataSource";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import {
    IDataSourceListener,
    IDataSourceModel,
    IDataSource,
    IDataSourceIcon,
} from "../../../../DataSources/IDataSource";
import { ITodoListService, ITaskForTaskBoard } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    INewActivityDefaultData,
    ITodoListElementsProvider,
    ITodoListTask,
    ITodoListTaskWorkHistory,
} from "../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListTemplateTask, ITodoListTemplate } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ITodoListWorkflow } from "../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IException } from "../../../../Core/interfaces/IException";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { Right } from "../../../../Core/Authorizations";
import {
    INavigationMenuComponentAlert,
    INavigationMenuComponentAlerts,
} from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { ActivityAttachmentsManager } from "./ActivityAttachmentsManager";
import { If, With } from "../../../../Components/IfIfNotWith";
import { WorkedHoursDefaultsEditor } from "../utils/WorkedHoursDefaultsEditor";

const styleSheet = jss.createStyleSheet({});
const { classes } = styleSheet.attach();

type WorkedHoursData = {
    resourceId: number;
    date: Date;
};

type ActivityDetailsDialogProps = {
    activity?: TodoListActivity;
    isTemplate?: boolean;
    activitiesProvider?: ITodoListElementsProvider;
    dataSource?: IDataSource;
    containerId?: number;
    workflow?: ITodoListWorkflow;
    viewAll?: boolean;
    defaultData?: INewActivityDefaultData;
    showClosedJobOrders?: boolean;
    forTaskCopy?: boolean;
    fromTemplate?: boolean;
    workedHoursData?: WorkedHoursData;
    isMobileBrowser?: boolean;

    afterSaveCallback?: ActivityDetailsDialogAfterSaveCallback;
    afterDeleteCallback?: ActivityDetailsDialogAfterDeleteCallback;
    onCloseCallback?: ActivityDetailsDialogOnCloseCallback;
};

type ActivityDetailsEditorProps = ActivityDetailsDialogProps & {
    title: ko.Observable<string>;
    forwardRef?: (taskEditor: _ActivityDetailsEditor) => void;
};

type ActivityDetailsDialogAfterSaveCallback = (taskId: number) => void;
type ActivityDetailsDialogAfterDeleteCallback = ActivityDetailsDialogAfterSaveCallback;
type ActivityDetailsDialogOnCloseCallback = () => void;

export class ActivityDetailsDialog extends DialogComponentBase {
    static defaultProps: Partial<ActivityDetailsDialogProps> = {};

    @Right("WorkedHours_Start")
    private canStartWorkedHours: boolean;
    @Right("TaskBoard_InsertTask")
    private canInsertTasks: boolean;

    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;

    private taskEditorComponent: ko.Observable<_ActivityDetailsEditor> = ko.observable();
    private taskOnEdit: ko.Computed<TodoListActivity>;

    private modalDeferred: Promise<boolean> = null;

    constructor(private props: ActivityDetailsDialogProps) {
        super({ className: "fullscreen" + (props.isMobileBrowser ? " mobile-dialog" : "") });

        this.taskOnEdit = ko.computed(() => {
            return this.taskEditorComponent()?.TaskOnEdit();
        });
    }

    ShowModal(): Promise<boolean> {
        this.modalDeferred = this.dialogsService.ShowModal<boolean>(this);
        this.taskEditorComponent().scheduleTutorial();

        return this.modalDeferred;
    }

    cancelChanges(): void {
        const task = this.taskOnEdit();
        if (!task) return;

        task.CancelChanges();
    }

    close() {
        this.taskEditorComponent()
            .close()
            .then((confirm: boolean) => {
                if (confirm) {
                    this.modal.close(this.taskEditorComponent().someTasksSaved);
                    this.props.onCloseCallback && this.props.onCloseCallback();
                }
            });
    }

    action() {
        this.taskEditorComponent().action();
    }

    renderHeader() {
        return (
            <>
                {this.props.isMobileBrowser && this.renderMobileHeader()}
                {!this.props.isMobileBrowser && super.renderHeader()}
            </>
        );
    }

    private renderMobileHeader() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditDialog = this;

        return ComponentUtils.bindTo(
            <h4 class="modal-title bold" data-bind={{ text: taskEditDialog.title }}></h4>,
            this,
            "taskEditDialog"
        );
    }

    renderBody() {
        return (
            <ActivityDetailsEditor
                {...this.props}
                title={this.title}
                forwardRef={(taskEditor) => {
                    this.taskEditorComponent(taskEditor);
                }}
            />
        );
    }

    renderFooter() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditDialog = this;

        return ComponentUtils.bindTo(
            <>
                <ko-bind data-bind={{ with: taskEditDialog.taskEditorComponent, as: "taskEditor" }}>
                    <>
                        {this.props.isMobileBrowser && this.renderMobileFooter()}
                        {!this.props.isMobileBrowser && this.renderDesktopFooter()}
                    </>
                </ko-bind>
            </>,
            this,
            "taskEditDialog"
        ) as React.ReactElement;
    }

    private renderMobileFooter() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditDialog = this;
        const taskEditor: _ActivityDetailsEditor = null;

        return (
            <>
                <a href="#" class="btn default" data-bind={{ click: taskEditor.NewActivity.bind(taskEditor) }}>
                    Nuovo
                </a>

                <a href="#" class="btn btn-primary" data-bind={{ click: taskEditDialog.action.bind(taskEditDialog) }}>
                    Salva
                </a>
                <ko-bind data-bind={{ if: taskEditDialog.taskOnEdit() && !taskEditDialog.taskOnEdit().IsNew() }}>
                    <a href="#" class="btn btn-danger" data-bind={{ click: taskEditor.Delete.bind(taskEditor) }}>
                        Elimina
                    </a>
                </ko-bind>

                <a href="#" class="btn btn-default" data-bind={{ click: taskEditDialog.close.bind(taskEditDialog) }}>
                    Chiudi
                </a>
            </>
        );
    }

    private renderDesktopFooter() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditDialog = this;
        const taskVM: TodoListTask = null;
        const taskEditor: _ActivityDetailsEditor = null;

        return (
            <>
                <a
                    href="javascript:;"
                    class="btn btn-info pull-left"
                    data-bind={{ click: taskEditor.launchTutorial.bind(taskEditor) }}
                >
                    <i class="fa fa-book"></i>
                    Tutorial
                </a>

                {this.canStartWorkedHours && (
                    <ko-bind data-bind={{ with: taskEditDialog.taskOnEdit, as: "taskVM" }}>
                        <ko-bind data-bind={{ if: taskVM.CanInsertWorkedHours }}>
                            <a
                                href="#"
                                class="btn btn-success pull-left"
                                data-bind={{ click: taskVM.InsertWorkedHoursOnTask.bind(taskVM) }}
                            >
                                Inserisci ore lavorate
                            </a>
                        </ko-bind>
                    </ko-bind>
                )}

                {this.canInsertTasks && (
                    <a href="#" class="btn default" data-bind={{ click: taskEditor.NewActivity.bind(taskEditor) }}>
                        Nuovo
                    </a>
                )}
                <ko-bind data-bind={{ with: taskEditDialog.taskOnEdit, as: "taskVM" }}>
                    <ko-bind data-bind={{ ifnot: taskVM.IsNew }}>
                        <a
                            href="#"
                            class="btn default"
                            data-bind={{ click: taskEditDialog.cancelChanges.bind(taskEditDialog) }}
                        >
                            Annulla modifiche
                        </a>
                    </ko-bind>
                    <a
                        href="#"
                        class="btn btn-primary"
                        data-bind={{ asyncClick: taskEditDialog.action.bind(taskEditDialog) }}
                    >
                        Salva
                    </a>
                    <ko-bind data-bind={{ ifnot: taskVM.IsNew }}>
                        <a
                            href="#"
                            class="btn btn-danger"
                            data-bind={{ asyncClick: taskEditor.Delete.bind(taskEditor) }}
                        >
                            Elimina
                        </a>
                    </ko-bind>
                </ko-bind>
                <a href="#" class="btn btn-default" data-bind={{ click: taskEditDialog.close.bind(taskEditDialog) }}>
                    Chiudi
                </a>
            </>
        );
    }
}

export function ActivityDetailsEditor(props: ActivityDetailsEditorProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./ActivityDetailsDialog")._ActivityDetailsEditor as typeof _ActivityDetailsEditor;
    return <C {...props} />;
}

export class _ActivityDetailsEditor extends ActivityDetailsEditorBase implements IDataSourceListener {
    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;

    @LazyImport(ProlifeSdk.TodoListServiceType)
    protected todoListService: ITodoListService;

    @LazyImport(nameof<IBlogService>())
    protected blogService: IBlogService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    protected resourcesManager: IHumanResourcesSettingsManager;

    TaskOnEdit: ko.Observable<TodoListActivity> = ko.observable(null);
    SelectedTask: ko.Observable<IDataSourceModel> = ko.observable();
    List: ko.Observable<List<number, ITasksDataSourceModel>> = ko.observable();

    CanDeleteActivities: ko.Observable<boolean> = ko.observable(false);
    DetailedMode: ko.Observable<boolean> = ko.observable(false);

    get someTasksSaved(): boolean {
        return this._someTasksSaved;
    }

    private _someTasksSaved = false;
    private subscriptionOnContainer: ko.Subscription = null;
    private activity: TodoListActivity = null;

    private newActivityDefaultData: INewActivityDefaultData = null;
    private selectedContainerId: number = null;

    private afterSaveCallback: (taskId: number) => void;
    private afterDeleteCallback: (taskId: number) => void;

    public TasksDataSource: IDataSource;

    private selectedResourceId: number;
    private selectedDate: Date;

    public ShowTasksEditor: ko.Computed<boolean>;

    /* copia task*/
    public CopyTaskSectionExpanded: ko.Observable<boolean> = ko.observable(false);
    public SelectedJobOrderForTaskCopy: ko.Observable<number> = ko.observable();
    public SelectedWorkflowForTaskCopy: ko.Observable<number> = ko.observable();
    public SelectedTaskForCopy: ko.Observable<number> = ko.observable();

    public JobOrdersDataSourceForTaskCopy: JobOrdersDataSource;
    public WorkflowsDataSourceForTaskCopy: WorkflowsDataSource;
    public TasksDataSourceForCopy: TasksDataSource;

    private lastJobOrder: IJobOrderDataSourceModel;
    private lastWorkflow: IWorkflowsDataSourceModel;
    private lastTask: ITasksDataSourceModel;
    /* fine copia task*/

    /* creazione da template */
    public ShowSourceTemplateFields: ko.Observable<boolean> = ko.observable(true);
    public CreateFromTemplateSectionExpanded: ko.Observable<boolean> = ko.observable(false);
    public TemplatesDataSource: TemplatesDataSource;
    public TemplateTasksDataSource: TemplateTasksDataSource;
    public TemplateId: ko.Observable<number> = ko.observable();
    public TaskTemplateId: ko.Observable<number> = ko.observable();

    private lastTemplate: ITemplateDataSourceModel;
    private lastTaskTemplate: ITemplateTasksDataSourceModel;
    private onInitSelection = false;
    /* fine creazione da template */

    private activitiesProvider: ITodoListElementsProvider;

    @Right("TaskBoard_CanViewAll")
    private canViewAll: boolean;
    @Right("TaskBoard_CanViewWorkTabOnTask")
    private canViewWork: boolean;
    @Right("JobOrders_BudgetRequest_View")
    private canViewBudget: boolean;
    @Right("TaskBoard_CanAddAttachmentsOnTasks")
    private canAddAttachments: boolean;
    @Right("JobOrders_EditTaskEstimate")
    private canEditTaskWorkEstimate: boolean;
    @Right("TaskBoard_CanAddWarehouseEstimatedBudgetRows")
    private canEditTaskWarehouseEstimates: boolean;
    @Right("TaskBoard_CanAddPurchaseEstimatedBudgetRows")
    private canEditTaskPurchasesEstimates: boolean;
    @Right("JobOrders_EditTaskProgress")
    private canEditTaskProgress: boolean;
    @Right("Allocations_Start")
    private canStartAllocations: boolean;
    @Right("TaskBoard_CanEditTaskMultiplier")
    private canEditTaskMultiplier: boolean;
    @Right("TaskBoard_EditEstimatedBudgetForTaskState")
    private canEditEstimatedBudgetRowState: boolean;
    @Right("TaskBoard_ViewCostsOnEsimatedBudgetRows")
    private canViewCostsOnEstimatedBudgetRows: boolean;
    @Right("TaskBoard_ViewRevenuesOnEsimatedBudgetRows")
    private canViewRevenuesOnEstimatedBudgetRows: boolean;
    @Right("!Warehouse")
    private canStartWarehouse: boolean;
    @Right("TaskBoard_ViewHoursReportingSection")
    private canViewHourReportingSection: boolean;

    constructor(private props: ActivityDetailsEditorProps) {
        super();

        this.CanDeleteActivities(this.authorizationsService.isAuthorized("TaskBoard_DeleteTask"));

        this.activitiesProvider = this.props.activitiesProvider;
        this.TasksDataSource = this.props.dataSource;
        (this.props.dataSource as any).enableNotifications(true);

        /* copia task */
        this.JobOrdersDataSourceForTaskCopy = new JobOrdersDataSource();
        this.WorkflowsDataSourceForTaskCopy = new WorkflowsDataSource();
        this.TasksDataSourceForCopy = new TasksDataSource();

        const userId = this.userInfoService.getIdUser();
        const resourceId = this.resourcesManager.getLoggedResourceId();

        this.JobOrdersDataSourceForTaskCopy.setShowClosed(true);
        this.JobOrdersDataSourceForTaskCopy.setViewFilters(true, true, true);
        this.JobOrdersDataSourceForTaskCopy.setWorkFilters(true);
        this.JobOrdersDataSourceForTaskCopy.setUserId(userId);
        this.JobOrdersDataSourceForTaskCopy.setResourceId(resourceId);

        this.WorkflowsDataSourceForTaskCopy.setGroupedModeEnabled(true);
        this.WorkflowsDataSourceForTaskCopy.setViewFilters(true, true, true);
        this.WorkflowsDataSourceForTaskCopy.setWorkFilters(true);
        this.WorkflowsDataSourceForTaskCopy.setUserId(userId);

        this.TasksDataSourceForCopy.setShowClosed(true);
        this.TasksDataSourceForCopy.setShowTaskState(true);
        this.TasksDataSourceForCopy.setViewAll(true);
        this.TasksDataSourceForCopy.setUserId(userId);
        this.TasksDataSourceForCopy.setResourceId(resourceId);

        /* fine copia task */

        /* creazione task da template */
        this.TemplatesDataSource = new TemplatesDataSource();
        this.TemplateTasksDataSource = new TemplateTasksDataSource();

        this.TaskTemplateId.subscribe(() => {
            this.onInitSelection = true;
        });
        /* fine creazione task da template */

        this.ShowTasksEditor = ko.computed(() => {
            return (
                (!this.CopyTaskSectionExpanded() && !this.CreateFromTemplateSectionExpanded()) ||
                (this.CopyTaskSectionExpanded() && !!this.SelectedTaskForCopy()) ||
                (this.CreateFromTemplateSectionExpanded() && !!this.TaskTemplateId())
            );
        });

        if ((this.TasksDataSource as TasksDataSource).setViewAll)
            (this.TasksDataSource as TasksDataSource).setViewAll(this.ViewAll());

        this.ViewAll.subscribe((value: boolean) => {
            const dataSource = this.TasksDataSource as TasksDataSource;
            if (dataSource.setViewAll) {
                dataSource.setViewAll(value);
                dataSource.refresh();
            }
        });

        this.TaskOnEdit.subscribe((task: TodoListTask) => {
            if (task) {
                if (task.SetDataForWorkedHoursInsert)
                    task.SetDataForWorkedHoursInsert(this.selectedResourceId, this.selectedDate);

                if (task.ShowClosedJobOrders) task.ShowClosedJobOrders(this.ShowClosedJobOrders());
            }
        });

        this.ShowClosedJobOrders.subscribe((value: boolean) => {
            const task = this.TaskOnEdit() as TodoListTask;

            if (task && task.ShowClosedJobOrders) {
                task.ShowClosedJobOrders(this.ShowClosedJobOrders());
            }
        });

        this.initialize();

        this.props.forwardRef && this.props.forwardRef(this);
    }

    private initialize(): void {
        this.IsTemplate(this.props.isTemplate);
        this.ViewAll(this.props.viewAll);
        this.SetNewActivityDefaultData(this.props.defaultData);
        this.ShowClosedJobOrders(this.props.showClosedJobOrders);
        this.SetAfterSaveCallback(this.props.afterSaveCallback);
        this.SetAfterDeleteCallback(this.props.afterDeleteCallback);

        if (this.props.forTaskCopy) this.OpenCopyTaskSection();
        else if (this.props.fromTemplate) this.OpenCreateFromTemplateSection();

        if (!this.props.activity) this.PrepareNewTaskForInsert();
        else this.PrepareSelectedActivity(this.props.activity);
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.JobOrdersDataSourceForTaskCopy) {
            if (model && this.lastJobOrder && model.id === this.lastJobOrder.id) return;

            const jobOrderDataSourceModel = model as IJobOrderDataSourceModel;
            this.lastJobOrder = jobOrderDataSourceModel;

            this.SelectedTaskForCopy(null);
            this.SelectedWorkflowForTaskCopy(null);

            this.WorkflowsDataSourceForTaskCopy.setJobOrders(!model ? null : jobOrderDataSourceModel.id);
            this.TasksDataSourceForCopy.setWorkflows([]);

            if (this.TaskOnEdit().Id > 0) this.PrepareNewTaskForInsert();

            return;
        }

        if (sender === this.WorkflowsDataSourceForTaskCopy) {
            if (model && this.lastWorkflow && model.id === this.lastWorkflow.id) return;

            const workflowDataSourceModel = model as IWorkflowsDataSourceModel;
            this.lastWorkflow = workflowDataSourceModel;

            this.SelectedTaskForCopy(null);
            this.TasksDataSourceForCopy.setWorkflows(!model ? [] : [workflowDataSourceModel.id]);

            if (this.TaskOnEdit().Id > 0) this.PrepareNewTaskForInsert();

            return;
        }

        if (sender === this.TasksDataSourceForCopy) {
            if (model && this.lastTask && model.id === this.lastTask.id) return;

            const taskDataSourceModel = model as ITasksDataSourceModel;
            this.lastTask = taskDataSourceModel;

            if (!model || this.TaskOnEdit().Id > 0) {
                this.PrepareNewTaskForInsert();
            } else {
                this.todoListService.GetTaskById(taskDataSourceModel.id).then((task: ITodoListTask) => {
                    const taskOnEdit = (this.TaskOnEdit() ? this.TaskOnEdit().GetData() : null) as ITodoListTask;

                    if (!taskOnEdit && !this.newActivityDefaultData) {
                        throw new Error("Impossibile determinare il piano e la commessa per il nuovo task.");
                    }

                    const jobOrderTag = (
                        this.newActivityDefaultData ? this.newActivityDefaultData : taskOnEdit
                    ).Tags.firstOrDefault(
                        (t) => t.Type === ProlifeSdk.JobOrderEntityTypeCode && t.Field === "JOBORDER"
                    );

                    const paddedTaskId = "00000" + task.Id.toString();
                    const taskIdOnTitle = paddedTaskId.substring(paddedTaskId.length - 6) + " - ";

                    if (task.Title.indexOf(taskIdOnTitle) === 0)
                        task.Title = task.Title.substring(taskIdOnTitle.length);

                    task.Id = null;
                    task.RelatedWorkFlow = this.newActivityDefaultData
                        ? this.newActivityDefaultData.ContainerId
                        : taskOnEdit.RelatedWorkFlow;
                    task.EstimatedBudgetRows.forEach((eb) => {
                        eb.TaskId = null;
                        eb.Id = null;
                    });
                    task.TaskBoardStatus = -1;
                    task.ProgressAvg = 0;
                    task.Resources.forEach((r) => (r.TaskId = null));
                    task.Links = [];
                    task.OperationalUnitsRemainingWork = [];

                    const destJobOrderTag = task.Tags.firstOrDefault((t) => t.Type === "JOB" && t.Field === "JOBORDER");
                    if (destJobOrderTag) {
                        destJobOrderTag.Value = jobOrderTag.Value;
                    } else {
                        jobOrderTag.TaskId = null;
                        task.Tags.push(jobOrderTag);
                    }

                    const taskViewModel = new TodoListTask(null, task, this.activitiesProvider);
                    this.SetActivity(taskViewModel);
                });
            }
        }

        if (sender === this.TemplatesDataSource) {
            if (model && this.lastTemplate && model.id === this.lastTemplate.id) return;

            const templateDataSourceModel = model as ITemplateDataSourceModel;
            this.lastTemplate = templateDataSourceModel;

            this.TaskTemplateId(null);
            if (!templateDataSourceModel) this.TemplateTasksDataSource.setTemplates([]);
            else this.TemplateTasksDataSource.setTemplates([templateDataSourceModel.id]);

            if (this.TaskOnEdit().Id > 0) this.PrepareNewTaskForInsert();

            return;
        }

        if (sender === this.TemplateTasksDataSource) {
            if (model && this.lastTaskTemplate && model.id === this.lastTaskTemplate.id) return;

            const taskTemplateModel = model as ITemplateTasksDataSourceModel;
            this.lastTaskTemplate = taskTemplateModel;

            if (!taskTemplateModel) {
                this.PrepareNewTaskForInsert();
            } else {
                const taskTemplate: ITodoListTemplateTask = taskTemplateModel.model;
                const taskOnEdit = (this.TaskOnEdit() ? this.TaskOnEdit().GetData() : null) as ITodoListTask;

                if (!taskOnEdit && !this.newActivityDefaultData) {
                    throw new Error("Impossibile determinare il piano e la commessa per il nuovo task.");
                }

                const task: ITodoListTask = $.extend(true, {}, taskTemplate as any) as ITodoListTask;
                task.Id = null;
                task.SourceTemplateTaskId = taskTemplate.Id;
                task.RelatedWorkFlow = this.newActivityDefaultData
                    ? this.newActivityDefaultData.ContainerId
                    : taskOnEdit.RelatedWorkFlow;
                task.ProgressAvg = 0;
                task.TaskBoardStatus = -1;
                task.StateFolder =
                    this.newActivityDefaultData && this.newActivityDefaultData.FolderId
                        ? this.newActivityDefaultData.FolderId
                        : -1;
                task.Locked = false;
                task.EstimatedBudget = 0;
                task.RemainingWork = 0;
                task.Links = [];
                task.OperationalUnitsRemainingWork = [];
                task.Tags =
                    this.newActivityDefaultData && this.newActivityDefaultData.Tags
                        ? this.newActivityDefaultData.Tags
                        : [];
                task.EstimatedBudgetRows.forEach((e) => {
                    e.Id = null;
                    e.TaskId = null;
                });

                const taskViewModel = new TodoListTask(null, task, this.activitiesProvider);
                this.SetActivity(taskViewModel);
            }
        }
    }

    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.JobOrdersDataSourceForTaskCopy) {
            this.SelectedTaskForCopy(null);
            this.SelectedWorkflowForTaskCopy(null);

            this.WorkflowsDataSourceForTaskCopy.setJobOrders(null);
            this.TasksDataSourceForCopy.setWorkflows([]);

            this.PrepareNewTaskForInsert();

            return;
        }

        if (sender === this.WorkflowsDataSourceForTaskCopy) {
            this.SelectedTaskForCopy(null);
            this.TasksDataSourceForCopy.setWorkflows([]);

            this.PrepareNewTaskForInsert();

            return;
        }

        if (sender === this.TasksDataSourceForCopy) {
            this.PrepareNewTaskForInsert();
        }
    }

    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        if (!this.TaskOnEdit() || !this.TaskOnEdit().SomeIsChanged() || this.onInitSelection) {
            this.onInitSelection = false;
            return true;
        }

        return this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Todolist.WarningChangesWillBeLost,
            ProlifeSdk.TextResources.Todolist.DoNotLooseChanges,
            ProlifeSdk.TextResources.Todolist.ContinueAndLooseChanges
        );
    }

    public OpenCopyTaskSection(): void {
        this.CopyTaskSectionExpanded(true);
    }

    public CloseCopyTaskSection(): void {
        this.CopyTaskSectionExpanded(false);
    }

    public SwitchCopyTaskSectionState(): void {
        this.CopyTaskSectionExpanded(!this.CopyTaskSectionExpanded());
    }

    public OpenCreateFromTemplateSection(): void {
        this.CreateFromTemplateSectionExpanded(true);
        this.ShowSourceTemplateFields(false);
    }

    public CloseCreateFromTemplateSection(): void {
        this.CreateFromTemplateSectionExpanded(false);
    }

    public SwitchCreateFromTemplateSectionState(): void {
        this.CreateFromTemplateSectionExpanded(!this.CreateFromTemplateSectionExpanded());
    }

    public SetDataForWorkedHoursInsert(resourceId: number, date: Date): void {
        this.selectedResourceId = resourceId;
        this.selectedDate = date;

        const task = this.TaskOnEdit() as TodoListTask;
        if (task && task.SetDataForWorkedHoursInsert) task.SetDataForWorkedHoursInsert(resourceId, date);
    }

    public SetNewActivityDefaultData(data: INewActivityDefaultData) {
        this.newActivityDefaultData = data;
    }

    public scheduleTutorial() {
        setTimeout(() => {
            this.launchTutorialIfNeverLaunched();
        }, 1000);
    }

    public SetActivity(activity: TodoListActivity) {
        this.CheckChanges().then((confirm) => {
            if (confirm) this.SetActivityAfterCheck(activity);
        });
    }

    private async CheckChanges(): Promise<boolean> {
        let confirm = true;
        if (this.TaskOnEdit() && this.TaskOnEdit().SomeIsChanged())
            confirm = await this.dialogsService.ConfirmAsync(
                ProlifeSdk.TextResources.Todolist.WarningChangesWillBeLost,
                ProlifeSdk.TextResources.Todolist.DoNotLooseChanges,
                ProlifeSdk.TextResources.Todolist.ContinueAndLooseChanges
            );

        if (confirm) this.TaskOnEdit().CancelChanges();

        return confirm;
    }

    private SetActivityAfterCheck(activity: TodoListActivity) {
        //NOTA g.biasci: E' importante usare l'oggetto activity passato in input perchè su di esso sono caricate le info degli allegati.
        //La property this.activity va pensata come un riferimento all'attuale task nella lista!!!
        //var matches = this.todoList.Tasks().filter((a : TodoListActivity) => { return activity && a.ObservableId() == activity.ObservableId(); });
        //this.activity = matches.length > 0 ? matches[0] : null;
        this.activity = activity;
        if (this.activity) this.PrepareSelectedActivity(activity);
        else this.PrepareNewTaskForInsert();
    }

    public NewActivity() {
        this.SetActivity(null);
    }

    private OnContainerChanged() {
        let containerId: number = this.TaskOnEdit().ContainerIdField();
        containerId = containerId && !isNaN(containerId) && containerId > 0 ? containerId : null;
        this.setSelectedContainer(containerId);
    }

    private setSelectedContainer(containerId: number): void {
        //Non faccio niente in caso di valore uguale altrimenti si refresha la lista inutilmente
        if (this.selectedContainerId == containerId) return;

        this.selectedContainerId = containerId;
        this.SetTitle();

        const containersId: number[] = containerId && !isNaN(containerId) && containerId > 0 ? [containerId] : [];

        //TODO: Cambiare questo codice e trovare un modo più generico per settare il piano o il template
        if ((this.TasksDataSource as any).setWorkflows) (this.TasksDataSource as any).setWorkflows(containersId);
        if ((this.TasksDataSource as any).setTemplates) (this.TasksDataSource as any).setTemplates(containersId);
    }

    private SetTitle() {
        const activityName =
            this.activity && this.activity.Title() ? this.activity.Title() : ProlifeSdk.TextResources.Todolist.NewTask;

        if (this.selectedContainerId) {
            if (!this.IsTemplate()) {
                this.todoListService.GetWorkflow(this.selectedContainerId).then((w: ITodoListWorkflow) => {
                    this.props.title(w.Title + " - " + activityName);
                });
            } else {
                this.todoListService.GetTemplate(this.selectedContainerId).then((w: ITodoListTemplate) => {
                    this.props.title(w.Title + " - " + activityName);
                });
            }
        } else {
            this.props.title(activityName);
        }
    }

    public PrepareSelectedActivity(activity: TodoListActivity) {
        if (this.subscriptionOnContainer) this.subscriptionOnContainer.dispose();

        this.activity = activity;
        this.TaskOnEdit(activity);
        this.subscriptionOnContainer = this.TaskOnEdit().ContainerIdField.subscribe(this.OnContainerChanged.bind(this));

        this.SetTitle();
        this.OnContainerChanged();
    }

    public PrepareNewTaskForInsert() {
        const newActivityModel = this.activitiesProvider.GetEmptyActivityModel(this.newActivityDefaultData);
        const newActivity = this.activitiesProvider.CreateViewModelForActivity(newActivityModel, null);

        this.PrepareSelectedActivity(newActivity);

        if (this.TaskOnEdit()) this.TaskOnEdit().SetFocusOnTitle();
    }

    async close(): Promise<boolean> {
        return this.CheckChanges();
    }

    async Delete(): Promise<void> {
        try {
            const result: boolean = await this.todoListService.HasBillableHours(null, this.TaskOnEdit().Id, true);
            if (result) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Todolist.CannotDeleteTaskWithBillableHours);
                return;
            }

            const confirm: boolean = await this.dialogsService.ConfirmAsync(
                ProlifeSdk.TextResources.Todolist.SureToDeleteTask,
                ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
                ProlifeSdk.TextResources.Todolist.DeleteTask
            );
            if (!confirm) return;

            await this.TaskOnEdit().Delete();
            if (this.afterDeleteCallback) this.afterDeleteCallback(this.TaskOnEdit().Id);

            //Qui non devo fare controlli su eventuali cambiamenti visto che ho eliminato il task!
            this.SetActivityAfterCheck(null);
            this._someTasksSaved = true;
        } catch (e) {
            const ex = e as IException;
            this.infoToastService.Error(ex.ExceptionMessage || TextResources.ProlifeSdk.GenericError);
        }
    }

    public SaveChangesIfEnterIsPressed(
        data,
        event // N.B. non rimuovere il parametro data, altrimenti non funziona niente
    ) {
        if (event.keyCode != 13) return true;

        event.preventDefault();
        this.action();
        return false;
    }

    async action(): Promise<void> {
        try {
            const activityWasNew = this.TaskOnEdit().IsNew();
            const result = await this.TaskOnEdit().SaveChanges(true);

            if (!result) return;

            this._someTasksSaved = true;

            // this.infoToastService.Success(this.activity ? ProlifeSdk.TextResources.Todolist.TaskUpdated : ProlifeSdk.TextResources.Todolist.TaskInserted);

            if (this.afterSaveCallback) this.afterSaveCallback(this.TaskOnEdit().Id);

            if (activityWasNew) this.PrepareNewTaskForInsert();

            this.SetTitle();
        } catch (e) {
            const ex = e as IException;
            this.infoToastService.Error(ex?.ExceptionMessage || TextResources.ProlifeSdk.GenericError);
        }
    }

    public SetAfterSaveCallback(callback: (taskId: number) => void) {
        this.afterSaveCallback = callback;
    }

    public SetAfterDeleteCallback(callback: (taskId: number) => void) {
        this.afterDeleteCallback = callback;
    }

    public ShowDetails(task: ITaskForTaskBoard) {
        this.activitiesProvider.GetActivityById(task.Id).then((activity: ITodoListTask) => {
            const vm = this.activitiesProvider.CreateViewModelForActivity(activity, null);
            vm.ShowDetails();
            this.SetActivity(vm);
            this.SetTitle();

            if (this.List()) {
                const modelForList = (this.TasksDataSource as TasksDataSource).createModelsForTasks([task])[0];
                this.List().select(modelForList);
            }
        });
    }

    public OpenBlog(task: ITaskForTaskBoard) {
        const taskOnEdit: TodoListTask = this.TaskOnEdit() as TodoListTask;
        const jobOrderId = taskOnEdit.JobOrdersTagsManager.JobOrderId();

        this.blogService.openBlogInNewWindow(jobOrderId, task.Id);
    }

    public EditWorkflow() {
        this.todoListService.ShowEditWorkflowDialog(this.selectedContainerId).then(() => {
            //TODO: Cambiare questo codice!
            if ((this.TasksDataSource as any).refresh) (this.TasksDataSource as any).refresh();
        });
    }

    public SwitchCompactView() {
        this.DetailedMode(false);
    }

    public SwitchDetailedView() {
        this.DetailedMode(true);
    }

    render() {
        return this.props.isMobileBrowser ? this.renderMobileEditor() : this.renderDesktopEditor();
    }

    private renderMobileEditor() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditor = this;
        const taskVM: TodoListTask = null;
        const workHistory: ITodoListTaskWorkHistory = null;
        const attachmentsManager: ActivityAttachmentsManager = null;
        const estimateBudgetRow: EstimateBudgetRow = null;

        return ComponentUtils.bindTo(
            <div class="col-md-6" style="height: 100%; padding: 0">
                <ko-bind data-bind={{ with: taskEditor.TaskOnEdit, as: "taskVM" }}>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable tabbable-custom tabbable-full-width" style="overflow: visible">
                                <ul class="nav nav-tabs">
                                    <li class="active" style="text-align: center">
                                        <a
                                            href="#tab_details"
                                            class="btn btn-primary"
                                            style="padding: 10px 2px; overflow:hidden; width:80px"
                                            data-toggle="tab"
                                        >
                                            Dettagli
                                        </a>
                                    </li>
                                    <li class="" style="text-align: center">
                                        <a
                                            href="#tab_links"
                                            class="btn btn-primary"
                                            style="padding: 10px 2px; overflow:hidden; width:80px"
                                            data-toggle="tab"
                                        >
                                            Prerequisiti
                                        </a>
                                    </li>
                                    <li class="" style="text-align: center">
                                        <a
                                            href="#tab_report"
                                            class="btn btn-primary"
                                            style="padding: 10px 2px; overflow:hidden; width:80px"
                                            data-toggle="tab"
                                            data-bind="click: ShowChangeHistoryPane"
                                        >
                                            Storico
                                        </a>
                                    </li>
                                    <ko-bind data-bind={{ if: taskVM.CanViewWork }}>
                                        <li class="" style="text-align: center">
                                            <a
                                                href="#tab_work"
                                                class="btn btn-primary"
                                                style="padding: 10px 2px; overflow:hidden; width:80px"
                                                data-toggle="tab"
                                                data-bind="click: ShowWorkHistoryPane"
                                            >
                                                Lavoro
                                            </a>
                                        </li>
                                    </ko-bind>
                                    <ko-bind data-bind={{ if: taskVM.CanViewBudgets }}>
                                        <li class="" style="text-align: center">
                                            <a
                                                href="#tab_budgets"
                                                class="btn btn-primary"
                                                style="padding: 10px 2px; overflow:hidden; width:80px"
                                                data-toggle="tab"
                                                data-bind="click: ShowAssignedBudgetPane"
                                            >
                                                Budgets
                                            </a>
                                        </li>
                                    </ko-bind>
                                </ul>
                            </div>
                        </div>

                        <div class="tab-content" style="bottom: 0px">
                            <div class="tab-pane active" id="tab_details">
                                <div data-bind="slimScroll : '100%'">
                                    <div
                                        class="form"
                                        style="padding: 10px !important; margin: 0 5px; border-left: 5px solid rgba(144, 216, 231, 1); background: aliceblue;"
                                    >
                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-12">
                                                <textarea
                                                    spellCheck={false}
                                                    placeholder="Inserisci il titolo dell'attivit&agrave;"
                                                    class="form-control"
                                                    style="max-width: 100%; height: 34px; resize: none; overflow: hidden; -ms-word-wrap: break-word; word-wrap: break-word"
                                                    data-bind="value : Title, event : { keydown : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, hasfocus : TitleHasFocus, selectOnFocus : {}, valueUpdate : 'afterkeydown', autoHeight: true"
                                                    maxLength={250}
                                                    rows={1}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            {this.canEditTaskWorkEstimate && (
                                                <div class="col-md-4">
                                                    <label class="control-label">Durata (ore)</label>
                                                    <input
                                                        class="form-control"
                                                        spellCheck={false}
                                                        placeholder="Durata..."
                                                        type="text"
                                                        style="max-width: 100%;"
                                                        data-bind="numberValue : Duration, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, selectOnFocus : {}, enable: CanEditDuration"
                                                    />
                                                </div>
                                            )}
                                            {!this.canEditTaskWorkEstimate && (
                                                <div class="col-md-4">
                                                    <label class="control-label">Durata (ore)</label>
                                                    <div
                                                        class="form-control form-control-static"
                                                        style="max-width: 100%; margin: 0;"
                                                        data-bind="numberText: Duration"
                                                    ></div>
                                                </div>
                                            )}

                                            <div class="col-md-4">
                                                <label class="control-label">Cartella</label>
                                                <select
                                                    class="form-control"
                                                    style="max-width: 100%;"
                                                    data-bind="options : Folders, optionsCaption : 'Seleziona...', optionsText : 'title', value : StateFolder"
                                                ></select>
                                            </div>

                                            <div class="col-md-4">
                                                <label class="control-label">Priorit&agrave;</label>
                                                <select
                                                    class="form-control"
                                                    style="max-width: 100%;"
                                                    data-bind="optionsCaption : 'Seleziona...', value : Priority"
                                                >
                                                    <option value="0">Bassa</option>
                                                    <option value="1">Normale</option>
                                                    <option value="2">Alta</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-12">
                                                <div class="form-control" style="height: 200px; overflow:hidden">
                                                    <textarea
                                                        data-bind="htmlEditor : Description, htmlEditorOptions: { inlineMode : true}, html: Description"
                                                        placeholder="Inserisci la descrizione dell'attivit&agrave;..."
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-4">
                                                <label class="control-label">Tipo Rendicontazione</label>
                                                <select
                                                    class="form-control"
                                                    style="max-width: 100%;"
                                                    data-bind="options : ReportingTypes, optionsCaption : 'Seleziona...', optionsText : 'Description', optionsValue : 'Id', value : ReportingType"
                                                ></select>
                                            </div>

                                            <div class="col-md-4">
                                                <label class="control-label">Risorse</label>
                                                <ko-bind data-bind={{ with: taskVM.Resources }}>
                                                    <input
                                                        class="form-control default"
                                                        type="hidden"
                                                        data-bind="value: Resources, select2: { ajax : { quietMillis : 500 }, query : resourcesProvider.findEntities.bind(resourcesProvider), initSelection: resourcesProvider.findEntity.bind(resourcesProvider), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1 }"
                                                    />
                                                </ko-bind>
                                            </div>

                                            <div class="col-md-4">
                                                <label class="control-label">Responsabili</label>
                                                <input
                                                    class="form-control default"
                                                    type="hidden"
                                                    data-bind="value: ResponsibleTagsManager.TagsValue, select2: { query : ResponsibleTagsManager.SearchTags.bind(ResponsibleTagsManager), initSelection: ResponsibleTagsManager.GetStartTagsForListControl.bind(ResponsibleTagsManager), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1, maximumSelectionSize: 1 }"
                                                />
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-4">
                                                <label class="control-label">Inizio</label>
                                                <input
                                                    class="form-control"
                                                    style="max-width: 100%;"
                                                    data-bind="datepicker : StartDate"
                                                    type="text"
                                                />
                                            </div>

                                            <div class="col-md-4">
                                                <label class="control-label">Scadenza</label>
                                                <input
                                                    class="form-control"
                                                    style="max-width: 100%;"
                                                    data-bind="datepicker : DueDate"
                                                    type="text"
                                                />
                                            </div>

                                            {this.canEditTaskProgress && (
                                                <div class="col-md-4">
                                                    <label class="control-label">Avanzamento</label>
                                                    <input
                                                        class="form-control"
                                                        type="text"
                                                        style="max-width: 100%;"
                                                        data-bind="touchSpin : { min : 0, max : 100, step : 5, decimals : 0, postfix : '%', mousewheel: false }, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, value : ProgressAvg"
                                                    />
                                                </div>
                                            )}
                                            {!this.canEditTaskProgress && (
                                                <div class="col-md-4">
                                                    <label class="control-label">Avanzamento</label>
                                                    <div
                                                        class="form-control form-control-static"
                                                        style="max-width: 100%; margin: 0;"
                                                        data-bind="numberText: ProgressAvg"
                                                    ></div>
                                                </div>
                                            )}
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Commerciale</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : ForCommercial, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Amministrazione</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : ForAdministration, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Tecnico</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : ForTechnical, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-4">
                                                <label class="control-label">Commessa</label>
                                                <ko-bind data-bind={{ with: taskVM.JobOrdersTagsManager }}>
                                                    <input
                                                        class="form-control"
                                                        type="hidden"
                                                        data-bind="value: JobOrderId, select2: { dropdownCssClass: 'prolife-styles dark select2-result', allowClear: true, query: jobOrderSearchService.findEntities.bind(jobOrderSearchService), placeholder: 'Seleziona...', initSelection: jobOrderSearchService.findEntity.bind(jobOrderSearchService) }"
                                                    />
                                                </ko-bind>
                                            </div>

                                            <div class="col-md-4">
                                                <label class="control-label">Flusso</label>
                                                <input
                                                    class="form-control"
                                                    type="hidden"
                                                    data-bind="value: RelatedWorkFlow, select2: { dropdownCssClass: 'prolife-styles dark select2-result', allowClear: true, query: WorkflowProvider.findEntities.bind(WorkflowProvider), placeholder: 'Seleziona...', initSelection: WorkflowProvider.findEntity.bind(WorkflowProvider) }"
                                                />
                                            </div>
                                        </div>

                                        <div class="row" style="margin-bottom: 10px">
                                            <div class="col-md-4">
                                                <label class="control-label">Etichette</label>
                                                <input
                                                    class="form-control default"
                                                    type="hidden"
                                                    data-bind="value: CustomTagsManager.TagsValue, select2: { dropdownCssClass: 'select2-result', query : CustomTagsManager.SearchTags.bind(CustomTagsManager), initSelection: CustomTagsManager.GetStartTagsForListControl.bind(CustomTagsManager), allowClear: true, placeholder: 'Aggiungi una etichetta all\'attività...', multiple: true, minimumInputLength: 1 }"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Facoltativo</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : Optional, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Milestone</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : IsMilestone, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">In qualit&agrave;</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : InQuality, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>

                                            <div class="col-xs-6 col-md-4">
                                                <label class="control-label">Nascondi dai Suggerimenti</label>
                                                <br />
                                                <input
                                                    class="form-control"
                                                    data-bind="onOffSwitch : HideFromSuggestions, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                                    type="checkbox"
                                                    checked={false}
                                                    data-on-text="Si"
                                                    data-off-text="No"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="portlet"
                                        style="padding: 10px !important; margin: 0 5px; border-left: 5px solid rgba(144, 216, 231, 1); background: aliceblue;"
                                    >
                                        <div class="portlet-title bg-blue" style="padding: 0 10px">
                                            <div class="caption">Spese previste</div>
                                        </div>
                                        <div class="portlet-body">
                                            <div class="row" style="margin-bottom: 30px">
                                                <div class="col-md-6">
                                                    <label class="control-label">Causale</label>
                                                    <select
                                                        class="form-control"
                                                        data-bind="options : BudgetRequestCauses, value : BudgetRequestCause, optionsCaption: 'Seleziona...', optionsText: 'Description', optionsValue : 'Id'"
                                                    ></select>
                                                </div>

                                                <div class="col-md-6">
                                                    <label class="control-label">Quantità</label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        data-bind="numberValue: Multiplier"
                                                    />
                                                </div>
                                            </div>

                                            <div style="position: relative">
                                                <h4>Stime</h4>
                                            </div>

                                            <ul class="estimated-budget-rows">
                                                <ko-bind
                                                    data-bind={{
                                                        if:
                                                            taskVM.EstimatedWorkRows().length == 0 &&
                                                            taskVM.EstimatedPurchasesRows().length == 0 &&
                                                            taskVM.EstimatedArticlesRows().length == 0,
                                                    }}
                                                >
                                                    <li class="estimated-budget-row">
                                                        <h4 class="text-center">Nessuna stima prevista</h4>
                                                    </li>
                                                </ko-bind>
                                                <ko-bind
                                                    data-bind={{
                                                        foreach: {
                                                            data: taskVM.EstimatedWorkRows,
                                                            as: "estimateBudgetRow",
                                                        },
                                                    }}
                                                >
                                                    <li class="estimated-budget-row work">
                                                        {this.canEditEstimatedBudgetRowState && (
                                                            <div
                                                                class="state"
                                                                data-bind="asyncClick: openStateSelectorPopover"
                                                            >
                                                                <ko-bind
                                                                    data-bind={{ if: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button
                                                                        class="btn"
                                                                        data-bind="style: { 'background-color': State().Background }, attr: { title: State().Label }"
                                                                    >
                                                                        <span data-bind="style: { color: State().Foreground }, css: State().Icon"></span>
                                                                    </button>
                                                                </ko-bind>
                                                                <ko-bind
                                                                    data-bind={{ ifnot: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button class="btn" title="Seleziona uno stato">
                                                                        <span
                                                                            style="color: black"
                                                                            class="fa fa-pencil"
                                                                        ></span>
                                                                    </button>
                                                                </ko-bind>
                                                            </div>
                                                        )}
                                                        <div class="description">
                                                            <span data-bind="text: Description"></span>
                                                        </div>
                                                        <div class="type-and-amount">
                                                            <div class="type">
                                                                <span data-bind="text: RoleName"></span>
                                                            </div>
                                                            <div class="amount">
                                                                <span data-bind="numberText : Amount"></span>
                                                            </div>
                                                        </div>
                                                        {this.canViewCostsOnEstimatedBudgetRows && (
                                                            <div class="costs">
                                                                <label>Costi</label>
                                                                <div class="unit-cost">
                                                                    <span data-bind="moneyText : UnitCost"></span>
                                                                </div>
                                                                <div class="cost">
                                                                    <span data-bind="moneyText : Cost"></span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                </ko-bind>
                                                <ko-bind
                                                    data-bind={{
                                                        foreach: {
                                                            data: taskVM.EstimatedPurchasesRows,
                                                            as: "estimateBudgetRow",
                                                        },
                                                    }}
                                                >
                                                    <li class="estimated-budget-row purchase">
                                                        {this.canEditEstimatedBudgetRowState && (
                                                            <div
                                                                class="state"
                                                                data-bind="asyncClick: openStateSelectorPopover"
                                                            >
                                                                <ko-bind
                                                                    data-bind={{ if: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button
                                                                        class="btn"
                                                                        data-bind="style: { 'background-color': State().Background }, attr: { title: State().Label }"
                                                                    >
                                                                        <span data-bind="style: { color: State().Foreground }, css: State().Icon"></span>
                                                                    </button>
                                                                </ko-bind>
                                                                <ko-bind
                                                                    data-bind={{ ifnot: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button class="btn" title="Seleziona uno stato">
                                                                        <span
                                                                            style="color: black"
                                                                            class="fa fa-pencil"
                                                                        ></span>
                                                                    </button>
                                                                </ko-bind>
                                                            </div>
                                                        )}
                                                        <div class="description">
                                                            <span data-bind="text: Description"></span>
                                                        </div>
                                                        <div class="type-and-amount">
                                                            <div class="type">
                                                                <span data-bind="text: PurchaseTypeTitle"></span>
                                                            </div>
                                                            <div class="amount">
                                                                <span data-bind="numberText : Amount"></span>
                                                            </div>
                                                        </div>
                                                        {this.canViewCostsOnEstimatedBudgetRows && (
                                                            <div class="costs">
                                                                <label>Costi</label>
                                                                <div class="unit-cost">
                                                                    <span data-bind="moneyText : UnitCost"></span>
                                                                </div>
                                                                <div class="cost">
                                                                    <span data-bind="moneyText : Cost"></span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                </ko-bind>
                                                <ko-bind
                                                    data-bind={{
                                                        foreach: {
                                                            data: taskVM.EstimatedArticlesRows,
                                                            as: "estimateBudgetRow",
                                                        },
                                                    }}
                                                >
                                                    <li class="estimated-budget-row article">
                                                        {this.canEditEstimatedBudgetRowState && (
                                                            <div
                                                                class="state"
                                                                data-bind="asyncClick: openStateSelectorPopover"
                                                            >
                                                                <ko-bind
                                                                    data-bind={{ if: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button
                                                                        class="btn"
                                                                        data-bind="style: { 'background-color': State().Background }, attr: { title: State().Label }"
                                                                    >
                                                                        <span data-bind="style: { color: State().Foreground }, css: State().Icon"></span>
                                                                    </button>
                                                                </ko-bind>
                                                                <ko-bind
                                                                    data-bind={{ ifnot: !!estimateBudgetRow.State() }}
                                                                >
                                                                    <button class="btn" title="Seleziona uno stato">
                                                                        <span
                                                                            style="color: black"
                                                                            class="fa fa-pencil"
                                                                        ></span>
                                                                    </button>
                                                                </ko-bind>
                                                            </div>
                                                        )}
                                                        <div class="description"></div>
                                                        <div class="type-and-amount">
                                                            <div class="type" data-bind="text: Description">
                                                                &nbsp;
                                                            </div>
                                                            <div class="amount">
                                                                <span data-bind="numberText : Amount"></span>
                                                            </div>
                                                        </div>
                                                        {this.canViewCostsOnEstimatedBudgetRows && (
                                                            <div class="costs">
                                                                <label>Costi</label>
                                                                <div class="unit-cost">
                                                                    <span data-bind="moneyText : UnitCost"></span>
                                                                </div>
                                                                <div class="cost">
                                                                    <span data-bind="moneyText : Cost"></span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                </ko-bind>
                                            </ul>
                                        </div>
                                    </div>

                                    <ko-bind data-bind={{ with: taskVM.AttachmentsManager, as: "attachmentsManager" }}>
                                        <div
                                            class="portlet"
                                            style="padding: 10px !important; margin: 0 5px; border-left: 5px solid rgba(144, 216, 231, 1); background: aliceblue;"
                                        >
                                            <div class="portlet-title bg-blue" style="padding: 0 10px">
                                                <div class="caption">Documenti</div>
                                                <div class="actions">
                                                    <div class="btn-group pull-right">
                                                        <button
                                                            type="button"
                                                            class="btn btn-default dropdown-toggle btn-fit-height"
                                                            data-toggle="dropdown"
                                                            data-close-others="true"
                                                            data-delay="1000"
                                                        >
                                                            Azioni
                                                            <i class="fa fa-angle-down"></i>
                                                        </button>

                                                        <ul class="dropdown-menu pull-right" role="menu">
                                                            <li>
                                                                <a href="#" data-bind="click : AddAttachments">
                                                                    <i class="fa fa-paperclip"></i>&nbsp;Allega
                                                                    documento
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    data-bind="click : AddLastVersionAttachments"
                                                                >
                                                                    <i class="fa fa-file-code-o"></i>&nbsp;Allega
                                                                    modello
                                                                </a>
                                                            </li>
                                                            <ko-bind
                                                                data-bind={{
                                                                    if:
                                                                        attachmentsManager.SelectedAttachments()
                                                                            .length > 0,
                                                                }}
                                                            >
                                                                <li>
                                                                    <a
                                                                        href="#"
                                                                        data-bind="click : RemoveSelectedAttachments"
                                                                    >
                                                                        <i class="fa fa-trash-o"></i>&nbsp;Elimina
                                                                        selezionati
                                                                    </a>
                                                                </li>
                                                            </ko-bind>
                                                            <ko-bind
                                                                data-bind={{
                                                                    if:
                                                                        attachmentsManager.SelectedAttachments()
                                                                            .length == 1,
                                                                }}
                                                            >
                                                                <li>
                                                                    <a href="#" data-bind="click : DownloadAttachments">
                                                                        <i class="fa fa-download"></i>&nbsp;Scarica
                                                                        allegato
                                                                    </a>
                                                                </li>
                                                            </ko-bind>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="portlet-body" data-bind="fileDrop: uploadFile">
                                                <div class="listview" style="min-height: 80px;">
                                                    <ko-bind
                                                        data-bind={{
                                                            if: attachmentsManager.attachments().length == 0,
                                                        }}
                                                    >
                                                        <h4
                                                            class="text-center"
                                                            style="margin: 0; padding-top: 20px; pointer-events: none;"
                                                        >
                                                            Nessun file allegato
                                                        </h4>
                                                    </ko-bind>

                                                    <ko-bind data-bind={{ foreach: attachmentsManager.attachments }}>
                                                        <a
                                                            href="#"
                                                            class="list multi-select"
                                                            data-bind="click : switchSelection, css : { selected : selected }"
                                                        >
                                                            <div class="list-content">
                                                                <div
                                                                    class="icon file"
                                                                    data-bind="css : previewClasses, attr : { style : previewStyle }"
                                                                ></div>
                                                                <div class="data"></div>
                                                                <span
                                                                    class="list-title"
                                                                    data-bind="text : fileName"
                                                                ></span>
                                                                <span
                                                                    class="list-remark"
                                                                    data-bind="visible : fileVersion, text : 'Versione' + fileVersion()"
                                                                ></span>
                                                            </div>
                                                        </a>
                                                    </ko-bind>
                                                    <ko-bind
                                                        data-bind={{
                                                            foreach: attachmentsManager.lastVersionAttachments,
                                                        }}
                                                    >
                                                        <a
                                                            href="#"
                                                            class="list multi-select"
                                                            data-bind="click : switchSelection, css : { selected : selected }"
                                                        >
                                                            <div class="list-content">
                                                                <div
                                                                    class="icon file"
                                                                    data-bind="css : previewClasses, attr : { style : previewStyle }"
                                                                ></div>
                                                                <div class="data"></div>
                                                                <span
                                                                    class="list-title"
                                                                    data-bind="text : fileName"
                                                                ></span>
                                                                <span
                                                                    class="list-remark"
                                                                    data-bind="visible : fileVersion, text : 'MODELLO'"
                                                                ></span>
                                                            </div>
                                                        </a>
                                                    </ko-bind>
                                                </div>
                                            </div>
                                        </div>
                                    </ko-bind>
                                </div>
                            </div>

                            <div class="tab-pane" id="tab_links">
                                <h3>Prerequisiti</h3>
                                <ko-bind data-bind={{ if: taskVM.Links().length == 0 }}>
                                    <h4>Non sono presenti vincoli</h4>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: taskVM.Links }}>
                                    <div style="border: 1px solid black; padding: 5px 20px; margin-top: 10px">
                                        <div class="row">
                                            <div>
                                                <b>Commessa</b>
                                            </div>
                                            <div class="" data-bind="text: JobOrderName"></div>
                                        </div>
                                        <div class="row">
                                            <div>
                                                <b>Piano</b>
                                            </div>
                                            <div class="" data-bind="text: WorkflowName"></div>
                                        </div>
                                        <div class="row">
                                            <div>
                                                <b>Attivit&agrave;</b>
                                            </div>
                                            <div class="" data-bind="text: TaskName"></div>
                                        </div>
                                        <div class="row">
                                            <button
                                                class="btn btn-warning btn-sm pull-right"
                                                data-bind="click: $parent.RemoveTaskLink.bind($parent, $data)"
                                            >
                                                <i class="fa fa-trash-o"></i>
                                                Rimuovi
                                            </button>
                                        </div>
                                    </div>
                                </ko-bind>
                                <div
                                    style="border: 1px solid black; padding: 5px 20px; margin-top: 5px"
                                    data-bind="with: NewTaskLink"
                                >
                                    <div class="row">
                                        <input
                                            type="hidden"
                                            data-bind="value: JobOrderId, select2SetValueFromField: JobOrderId, select2: { allowClear: true, query: findJobOrders.bind($data), initSelection: findJobOrder.bind($data), placeholder: 'Seleziona una commessa...' }"
                                            class="form-control"
                                            style="max-width: 100%"
                                        />
                                    </div>
                                    <div class="row" style="margin-top: 5px;">
                                        <input
                                            type="hidden"
                                            data-bind="value: WorkflowId, select2SetValueFromField: WorkflowId, select2: { allowClear: true, query: findWorkflows.bind($data), initSelection: findWorkflow.bind($data), placeholder: 'Seleziona un piano...' }"
                                            class="form-control"
                                            style="max-width: 100%"
                                        />
                                    </div>
                                    <div class="row" style="margin-top: 5px;">
                                        <input
                                            type="hidden"
                                            data-bind="value: TaskId, select2SetValueFromField: TaskId, select2: { allowClear: true, query: findTasks.bind($data), initSelection: findTask.bind($data), placeholder: 'Seleziona una attività...' }"
                                            class="form-control"
                                            style="max-width: 100%"
                                        />
                                    </div>
                                    <div class="row" style="margin-top: 5px;">
                                        <button
                                            class="btn btn-success pull-right"
                                            data-bind="click: $parent.AddNewLink.bind($parent)"
                                        >
                                            <i class="fa fa-plus"></i>
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane" id="tab_report">
                                <h3 style="margin-bottom: 10px">
                                    Storico Modifiche
                                    <button class="btn btn-primary pull-right" data-bind="click: LoadChangesHistory">
                                        <i class="fa fa-refresh"></i>
                                    </button>
                                    <button
                                        class="btn btn-danger pull-right"
                                        style="margin-right: 5px"
                                        data-bind="click: SaveChangesHistory"
                                    >
                                        <i class="fa fa-floppy-o"></i>
                                    </button>
                                </h3>
                                <ko-bind
                                    data-bind={{
                                        if: taskVM.ChangesHistory().length == 0 && taskVM.IsChangesHistoryLoaded(),
                                    }}
                                >
                                    <div class="text-center" style="font-size: 15px">
                                        Nessuna modifica da visualizzare
                                    </div>
                                </ko-bind>
                                <ko-bind data-bind={{ ifnot: taskVM.IsChangesHistoryLoaded }}>
                                    <div class="text-center" style="font-size: 15px">
                                        Per caricare lo storico modifiche premere il pulsante "Carica"
                                    </div>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: taskVM.ChangesHistory }}>
                                    <div style="border: 1px solid black; padding: 5px 20px; margin-top: 10px">
                                        <div class="row">
                                            <div style="display: inline">Data</div>
                                            <div class="pull-right" data-bind="dateText: Date"></div>
                                        </div>
                                        <div class="row" data-bind="visible: IsStateChange">
                                            <div style="display: inline">Data di riferimento</div>
                                            <input
                                                type="text"
                                                class="pull-right form-control input-sm"
                                                data-bind="visible: IsStateChange, datepicker: ReferenceDate, customDateTimeFormat: 'L LT'"
                                                style="max-width: 100%"
                                            />
                                        </div>
                                        <div class="row">
                                            <div style="display: inline">Utente</div>
                                            <div class="pull-right" data-bind="text: ResourceName"></div>
                                        </div>
                                        <div class="row">
                                            <div style="display: inline">Stima</div>
                                            <div class="pull-right" data-bind="numberText: NewEstimatedWork"></div>
                                        </div>
                                        <div class="row">
                                            <div style="display: inline">Stato</div>
                                            <div class="pull-right" data-bind="taskStateText: NewTaskBoardStatus"></div>
                                        </div>
                                        <div class="row">
                                            <div style="display: inline">Avanz.</div>
                                            <div class="pull-right" data-bind="percentageText: NewProgressAvg"></div>
                                        </div>
                                        <div class="row">
                                            <div style="display: inline">Azione</div>
                                            <span class="pull-right" data-bind="visible: IsCreation">
                                                (Creazione)
                                            </span>
                                            <span
                                                class="pull-right"
                                                data-bind="text: IsStateChange() ? 'Cambio Stato' : 'Cambio Stima'"
                                            ></span>
                                        </div>
                                    </div>
                                </ko-bind>
                            </div>

                            <ko-bind data-bind={{ if: taskVM.CanViewWork }}>
                                <div class="tab-pane" id="tab_work">
                                    <h3>
                                        Storico Lavoro
                                        <button class="btn btn-primary pull-right" data-bind="click: LoadWorkHistory">
                                            <i class="fa fa-refresh"></i>
                                            Carica
                                        </button>
                                    </h3>
                                    <ko-bind
                                        data-bind={{
                                            if: taskVM.WorkHistory().length == 0 && taskVM.IsWorkHistoryLoaded(),
                                        }}
                                    >
                                        <div class="text-center" style="font-size: 15px">
                                            Nessuna lavorazione da visualizzare
                                        </div>
                                    </ko-bind>
                                    <ko-bind data-bind={{ ifnot: taskVM.IsWorkHistoryLoaded }}>
                                        <div class="text-center" style="font-size: 15px">
                                            Per caricare lo storico lavoro premere il pulsante "Carica"
                                        </div>
                                    </ko-bind>
                                    <ko-bind data-bind={{ foreach: { data: taskVM.WorkHistory, as: "workHistory" } }}>
                                        <div style="border: 1px solid black; padding: 5px 20px; margin-top: 10px">
                                            <div class="row">
                                                <div style="display: inline">Data</div>
                                                <div class="pull-right" data-bind="dateText: WorkDate"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Utente</div>
                                                <div class="pull-right" data-bind="text: ResourceName"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Mansione</div>
                                                <div class="pull-right" data-bind="text: RoleName"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Tipologia</div>
                                                <div class="pull-right" data-bind="text: WorkTimeCategory"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Fatturabile</div>
                                                <div class="pull-right">
                                                    <i class="fa fa-check" data-bind="visible: Billable"></i>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">D.d.C.</div>
                                                <div class="pull-right">
                                                    <i class="fa fa-check" data-bind="visible: CallRight"></i>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Lavoro</div>
                                                <div class="pull-right" data-bind="numberText: Hours"></div>
                                            </div>
                                            <ko-bind data-bind={{ if: taskVM.CanViewCosts }}>
                                                <div class="row">
                                                    <div style="display: inline">Costo</div>
                                                    <div class="pull-right" data-bind="moneyText: Cost"></div>
                                                </div>
                                            </ko-bind>
                                        </div>
                                        <ko-bind data-bind={{ if: (workHistory.Notes || "").length > 0 }}>
                                            <div
                                                class="row"
                                                style="margin-top: 10px; margin-left: 15px; margin-right: 15px;"
                                            >
                                                <div style="display: inline">Note</div>
                                                <div class="pull-right" data-bind="text: Notes"></div>
                                            </div>
                                        </ko-bind>
                                    </ko-bind>
                                    <div style="padding: 5px 20px; margin-top: 10px">
                                        <div class="row">
                                            <b class="text-right">TOTALE</b>
                                            <ko-bind data-bind={{ if: taskVM.CanViewCosts }}>
                                                <div class="pull-right" data-bind="moneyText: TotalCost"></div>
                                            </ko-bind>
                                            <br />
                                            <b class="text-right">TOTALE LAVORO</b>
                                            <div class="pull-right" data-bind="numberText: TotalWork"></div>
                                        </div>
                                    </div>
                                </div>
                            </ko-bind>
                            <ko-bind data-bind={{ if: taskVM.CanViewBudgets }}>
                                <div class="tab-pane" id="tab_budgets">
                                    <h3>
                                        Budgets
                                        <button
                                            class="btn btn-primary pull-right"
                                            data-bind="click: LoadAssignedBudgets"
                                        >
                                            <i class="fa fa-refresh"></i>
                                            Carica
                                        </button>
                                    </h3>
                                    <ko-bind data-bind={{ foreach: taskVM.AssignedBudgets }}>
                                        <div style="border: 1px solid black; padding: 5px 20px; margin-top: 10px">
                                            <div class="row">
                                                <div style="display: inline">Data</div>
                                                <div class="pull-right" data-bind="dateText: CreationDate"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Data Autorizzazione</div>
                                                <div class="pull-right" data-bind="dateText: ApprovedDate"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Autorizzata da</div>
                                                <div
                                                    class="pull-right"
                                                    data-bind="HumanResourceFromUser: ApprovedUser"
                                                ></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Causale</div>
                                                <div
                                                    class="pull-right"
                                                    data-bind="text: $parent.GetBudgetRequestCause($data)"
                                                ></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Lavorazioni</div>
                                                <div class="pull-right" data-bind="moneyText: WorkBudget"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Acquisti</div>
                                                <div class="pull-right" data-bind="moneyText: PurchasesBudget"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Magazzino</div>
                                                <div class="pull-right" data-bind="moneyText: WarehouseBudget"></div>
                                            </div>
                                            <div class="row">
                                                <div style="display: inline">Totale</div>
                                                <div
                                                    class="pull-right"
                                                    data-bind="moneyText: WorkBudget + PurchasesBudget + WarehouseBudget"
                                                ></div>
                                            </div>
                                        </div>
                                    </ko-bind>
                                </div>
                            </ko-bind>
                        </div>
                    </div>
                </ko-bind>
            </div>,
            this,
            "taskEditor"
        );
    }

    private renderDesktopEditor() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditor = this;
        const taskModel: ITasksDataSourceModel = null;
        const taskAlert: INavigationMenuComponentAlerts = null;
        const taskAlertIcon: INavigationMenuComponentAlert = null;
        const icon: IDataSourceIcon = null;

        return ComponentUtils.bindTo(
            <div class="flex-container flex-vertical flex-full-height no-gutters">
                <div class="flex-container flex-vertical no-gutters">
                    <div class="row" data-bind={{ visible: taskEditor.CopyTaskSectionExpanded }}>
                        <div class="col-md-4">
                            <select2 params="Value: SelectedJobOrderForTaskCopy, DataSource: JobOrdersDataSourceForTaskCopy, Listener: $data, Label: 'Progetto', Placeholder: 'Seleziona...', AllowClear: true"></select2>
                        </div>
                        <div class="col-md-4">
                            <select2 params="Value: SelectedWorkflowForTaskCopy, DataSource: WorkflowsDataSourceForTaskCopy, Listener: $data, Label: 'Piano', Placeholder: 'Seleziona...', AllowClear: true"></select2>
                        </div>
                        <div class="col-md-4">
                            <select2 params="Value: SelectedTaskForCopy, DataSource: TasksDataSourceForCopy, Listener: $data, Label: 'Attività', Placeholder: 'Seleziona...', AllowClear: true"></select2>
                        </div>
                    </div>
                    <div class="row" data-bind={{ visible: taskEditor.CreateFromTemplateSectionExpanded }}>
                        <div class="col-md-6">
                            <select2 params="Value: TemplateId, DataSource: TemplatesDataSource, Listener: $data, Label: 'Modelllo di piano', Placeholder: 'Seleziona...', AllowClear: true"></select2>
                        </div>
                        <div class="col-md-6">
                            <select2 params="Value: TaskTemplateId, DataSource: TemplateTasksDataSource, Listener: $data, Label: 'Modelllo di attività', Placeholder: 'Seleziona...', AllowClear: true"></select2>
                        </div>
                    </div>
                </div>

                <div
                    class="flex-container flex-fill"
                    data-bind={{ css: { hide: !taskEditor.ShowTasksEditor() } }}
                    style="height: 100%"
                >
                    <div
                        class=""
                        style="height: 100%; padding: 0"
                        data-bind={{
                            css: {
                                "col-md-5": taskEditor.ShowActivitiesList,
                                "col-md-1": !taskEditor.ShowActivitiesList(),
                            },
                        }}
                    >
                        <div class="page-bar" style="min-height: 30px">
                            <button
                                type="button"
                                class="btn btn-primary btn-fit-height"
                                data-bind={{
                                    toggle: taskEditor.ShowActivitiesList,
                                    visible: taskEditor.ShowActivitiesList,
                                }}
                                style="margin-right: 10px"
                            >
                                Nascondi le attività
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-fit-height"
                                data-bind={{
                                    toggle: taskEditor.ShowActivitiesList,
                                    visible: !taskEditor.ShowActivitiesList(),
                                }}
                                style="margin-right: 10px"
                            >
                                Mostra le attività
                            </button>
                            <div class="btn-group pull-right" data-bind={{ visible: taskEditor.ShowActivitiesList }}>
                                <ko-bind data-bind={{ ifnot: taskEditor.IsTemplate }}>
                                    <>
                                        {this.canViewAll && (
                                            <button
                                                class="btn"
                                                style="margin-right: 10px"
                                                data-bind={{
                                                    css: {
                                                        "bg-red": taskEditor.ViewAll,
                                                        "btn-default": !taskEditor.ViewAll(),
                                                    },
                                                    toggle: taskEditor.ViewAll,
                                                }}
                                            >
                                                <i class="glyphicon glyphicon-exclamation-sign"></i>
                                                &nbsp;Visualizza tutto
                                            </button>
                                        )}

                                        <button
                                            class="btn btn-primary btn-fit-height"
                                            data-bind={{ click: taskEditor.EditWorkflow }}
                                            style="margin-right: 10px"
                                        >
                                            <i class="fa fa-pencil"></i>
                                            Modifica piano
                                        </button>
                                    </>
                                </ko-bind>

                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle btn-fit-height"
                                    data-toggle="dropdown"
                                    data-close-others="true"
                                    data-delay="1000"
                                >
                                    Azioni
                                    <i class="fa fa-angle-down"></i>
                                </button>

                                <ul class="dropdown-menu pull-right" role="menu">
                                    <li style="padding-left: 15px">
                                        <a
                                            href="#"
                                            data-bind={{ click: taskEditor.SwitchCompactView.bind(taskEditor) }}
                                        >
                                            <span
                                                style="position: absolute; left: 5px"
                                                class="fa fa-check"
                                                data-bind={{ visible: !taskEditor.DetailedMode() }}
                                            ></span>
                                            Vista compatta
                                        </a>
                                    </li>
                                    <li style="padding-left: 15px">
                                        <a
                                            href="#"
                                            data-bind={{ click: taskEditor.SwitchDetailedView.bind(taskEditor) }}
                                        >
                                            <span
                                                style="position: absolute; left: 5px"
                                                class="fa fa-check"
                                                data-bind={{ visible: taskEditor.DetailedMode }}
                                            ></span>
                                            Vista dettagliata
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <If condition={this.ShowActivitiesList}>
                            {() => (
                                <List
                                    dataSource={this.TasksDataSource}
                                    containerHeight="flex"
                                    ref={(list: List<number, ITasksDataSourceModel>) => this.List(list)}
                                    sortable
                                    data-bind={{ visible: taskEditor.ShowActivitiesList }}
                                    itemRenderer={(item: ListItem<number, ITaskForTaskBoard>) =>
                                        this.renderActivitiesListItem(item)
                                    }
                                />
                            )}
                        </If>
                        <ko-bind data-bind={{ if: taskEditor.ShowActivitiesList() && !taskEditor.IsTemplate() }}>
                            <ko-bind data-bind={{ with: taskEditor.SelectedTask, as: "taskModel" }}>
                                <div>
                                    <a
                                        href="#"
                                        class="btn btn-primary"
                                        data-bind={{ click: taskEditor.OpenBlog.bind(taskEditor, taskModel.model) }}
                                        style="margin-right: 10px"
                                    >
                                        <i class="fa fa-comments-o"></i>
                                        Appunti&nbsp;(
                                        <ko-bind data-bind={{ text: taskModel.model.BlogEventsCount }}></ko-bind>)
                                    </a>
                                </div>
                                <div data-bind={{ with: taskModel.alerts, as: "taskAlert" }} class="margin-top-20">
                                    <span
                                        class="pull-left"
                                        style="margin-right: 5px; line-height: 22px"
                                        data-bind={{ text: taskAlert.label }}
                                    ></span>
                                    <ko-bind data-bind={{ foreach: { data: taskAlert.icons, as: "taskAlertIcon" } }}>
                                        <div
                                            class="btn btn-default workflow-navigator-alert-icon pull-left"
                                            style="margin-right: 5px; width: 1.25em"
                                            data-bind={{
                                                attr: { title: taskAlertIcon.tooltip },
                                                tooltip: {},
                                                with: taskAlertIcon.icon,
                                                as: "icon",
                                            }}
                                        >
                                            <i data-bind={{ css: icon.icon, style: { color: icon.foreground } }}></i>
                                        </div>
                                    </ko-bind>
                                </div>
                            </ko-bind>
                        </ko-bind>
                    </div>

                    <div
                        class="col-md-7 task-editor-dialog flex-container flex-vertical"
                        style="height: 100%; padding: 0; padding-left: 10px"
                        data-bind={{
                            css: {
                                "col-md-7": taskEditor.ShowActivitiesList,
                                "col-md-11": !taskEditor.ShowActivitiesList(),
                                template: taskEditor.IsTemplate,
                            },
                        }}
                    >
                        <div class="row" style="height: 100%">
                            <div class="col-md-12" style="height: 100%">
                                <With data={this.TaskOnEdit} as="taskOnEdit">
                                    {() => (
                                        <>
                                            {!this.props.isTemplate && this.renderTaskOnEdit()}
                                            {this.props.isTemplate && this.renderDetailsTab()}
                                        </>
                                    )}
                                </With>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "taskEditor"
        );
    }

    private renderTaskOnEdit() {
        const taskOnEdit: TodoListTask = null;
        const stateChange: CombinedChangesHistory = null;
        const workHistory: ITodoListTaskWorkHistory = null;

        return (
            <>
                <ul class="nav nav-tabs">
                    <li data-bind="css: { active: DetailsShown }">
                        <a href="#" data-bind="click: ShowDetailsPane">
                            Dettagli
                        </a>
                    </li>
                    <li data-bind="css: { active: PrerequisitesShown }">
                        <a href="#" data-bind="click: ShowPrerequisitesPane">
                            Prerequisiti
                        </a>
                    </li>
                    <li data-bind="css: { active: ChangeHistoryShown }">
                        <a href="#" data-bind="click: ShowChangeHistoryPane">
                            Storico Modifiche
                        </a>
                    </li>
                    <ko-bind data-bind={{ if: taskOnEdit.CanViewWork }}>
                        <li data-bind="css: { active: WorkHistoryShown }">
                            <a href="#" data-bind="click: ShowWorkHistoryPane">
                                Lavoro
                            </a>
                        </li>
                    </ko-bind>
                    <ko-bind data-bind={{ if: taskOnEdit.CanViewBudgets }}>
                        <li data-bind="css: { active: AssignedBudgetsShown }">
                            <a href="#" data-bind="click: ShowAssignedBudgetPane">
                                Budgets
                            </a>
                        </li>
                    </ko-bind>
                </ul>
                <div class="tab-content task-tabs">
                    <div class="tab-pane" data-bind="css: { active: DetailsShown }">
                        {this.renderDetailsTab()}
                    </div>
                    <div class="tab-pane" data-bind="css: { active: PrerequisitesShown }">
                        <h3>Prerequisiti</h3>
                        <table class="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th>Commessa</th>
                                    <th>Flusso</th>
                                    <th>Attività</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if: taskOnEdit.Links().length == 0 }}>
                                    <tr>
                                        <td colSpan={4} style="text-align: center">
                                            <h4>Non sono presenti vincoli</h4>
                                        </td>
                                    </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: taskOnEdit.Links }}>
                                    <tr>
                                        <td data-bind="text: JobOrderName" style="vertical-align: middle"></td>
                                        <td data-bind="text: WorkflowName" style="vertical-align: middle"></td>
                                        <td data-bind="text: TaskName" style="vertical-align: middle"></td>
                                        <td>
                                            <button
                                                class="btn btn-danger btn-sm pull-right"
                                                data-bind="click: $parent.RemoveTaskLink.bind($parent, $data)"
                                            >
                                                <i class="fa fa-trash-o"></i>
                                                Rimuovi
                                            </button>
                                        </td>
                                    </tr>
                                </ko-bind>
                            </tbody>
                            <tfoot>
                                <tr data-bind="with: NewTaskLink">
                                    <td>
                                        <input
                                            type="hidden"
                                            data-bind="value: JobOrderId, select2SetValueFromField: JobOrderId, select2: { allowClear: true, query: findJobOrders.bind($data), initSelection: findJobOrder.bind($data), placeholder: 'Seleziona una commessa...' }"
                                            class="form-control"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="hidden"
                                            data-bind="value: WorkflowId, select2SetValueFromField: WorkflowId, select2: { allowClear: true, query: findWorkflows.bind($data), initSelection: findWorkflow.bind($data), placeholder: 'Seleziona un flusso...' }"
                                            class="form-control"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="hidden"
                                            data-bind="value: TaskId, select2SetValueFromField: TaskId, select2: { allowClear: true, query: findTasks.bind($data), initSelection: findTask.bind($data), placeholder: 'Seleziona una attività...' }"
                                            class="form-control"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-success pull-right"
                                            data-bind="click: $parent.AddNewLink.bind($parent)"
                                        >
                                            <i class="fa fa-plus"></i>
                                            Aggiungi
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="tab-pane" data-bind="css: { active: ChangeHistoryShown }">
                        <h3 style="margin-top: 10px;">
                            Storico Modifiche
                            <button class="btn btn-primary pull-right" data-bind="click: LoadChangesHistory">
                                <i class="fa fa-refresh"></i>
                                Carica
                            </button>
                            <button
                                class="btn btn-danger pull-right"
                                style="margin-right: 5px"
                                data-bind="click: SaveChangesHistory"
                            >
                                <i class="fa fa-floppy-o"></i>
                                Salva
                            </button>
                        </h3>
                        <div style="position: absolute; top: 95px; overflow: hidden; right: 15px; bottom: 0px; left: 15px;">
                            <table
                                class="table table-condensed table-striped table-hover table-bordered"
                                style="margin: 0"
                            >
                                <thead>
                                    <tr>
                                        <th colSpan={2}></th>
                                        <th colSpan={2}>Cambio di stato</th>
                                        <th colSpan={3}>Cambio di stima</th>
                                    </tr>
                                    <tr>
                                        <th style="width: 120px">Data</th>
                                        <th style="width: 130px">Utente</th>
                                        <th style="width: 170px">Data di riferimento</th>
                                        <th style="width: 120px">Stato</th>
                                        <th style="width: 170px">Data di riferimento</th>
                                        <th style="width: 85px">Stima (h)</th>
                                        <th style="width: 70px">Avanz.</th>
                                    </tr>
                                </thead>
                                <tbody style="height: 0px !important;"></tbody>
                            </table>
                            <div
                                style="position: absolute; top: 63px; right: 0px; bottom: 0px; left: 0px;"
                                data-bind="slimScroll"
                            >
                                <table class="table table-condensed table-striped table-hover">
                                    <thead style="height: 0px !important"></thead>
                                    <tbody>
                                        <ko-bind
                                            data-bind={{
                                                if:
                                                    taskOnEdit.CombinedChangesHistory().length == 0 &&
                                                    taskOnEdit.IsChangesHistoryLoaded(),
                                            }}
                                        >
                                            <tr>
                                                <td colSpan={7} class="text-center">
                                                    Nessuna modifica da visualizzare
                                                </td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind data-bind={{ ifnot: taskOnEdit.IsChangesHistoryLoaded }}>
                                            <tr>
                                                <td colSpan={7} class="text-center">
                                                    Per caricare lo storico modifiche premere il pulsante "Carica"
                                                </td>
                                            </tr>
                                        </ko-bind>
                                        <ko-bind
                                            data-bind={{
                                                foreach: { data: taskOnEdit.CombinedChangesHistory, as: "stateChange" },
                                            }}
                                        >
                                            <tr style="height: 39px">
                                                <td
                                                    style="vertical-align: middle; width: 120px"
                                                    data-bind="dateTimeText: Date"
                                                ></td>
                                                <td
                                                    style="vertical-align: middle; width: 130px"
                                                    data-bind="text: ResourceName"
                                                ></td>
                                                <td style="vertical-align: middle; width: 170px; position: relative;">
                                                    <ko-bind data-bind={{ with: stateChange.StateChange }}>
                                                        <input
                                                            type="text"
                                                            class="form-control input-sm"
                                                            data-bind="datepicker: ReferenceDate, customDateTimeFormat: 'L LT'"
                                                        />
                                                    </ko-bind>
                                                </td>
                                                <td style="vertical-align: middle; width: 120px">
                                                    <ko-bind data-bind={{ with: stateChange.StateChange }}>
                                                        <span data-bind="visible: IsCreation">Creato in: </span>
                                                        <span data-bind="taskStateText: NewTaskBoardStatus"></span>
                                                    </ko-bind>
                                                </td>
                                                <td style="vertical-align: middle; width: 170px; position: relative;">
                                                    <ko-bind data-bind={{ with: stateChange.EstimateChange }}>
                                                        <input
                                                            type="text"
                                                            class="form-control input-sm"
                                                            data-bind="datepicker: ReferenceDate, customDateTimeFormat: 'L LT'"
                                                        />
                                                    </ko-bind>
                                                </td>
                                                <td style="vertical-align: middle; width: 85px">
                                                    <ko-bind data-bind={{ with: stateChange.EstimateChange }}>
                                                        <span data-bind="numberText: NewEstimatedWork"></span>&nbsp;h
                                                    </ko-bind>
                                                </td>

                                                <td style="vertical-align: middle; width: 70px">
                                                    <ko-bind data-bind={{ with: stateChange.EstimateChange }}>
                                                        <span data-bind="percentageText: NewProgressAvg, visible: $parents[1].ActivitiesProgressAmountMode() == 0"></span>
                                                        <span data-bind="visible: $parents[1].ActivitiesProgressAmountMode() != 0">
                                                            <span data-bind="numberText: WorkedAmount"></span>/
                                                            <span data-bind="numberText: NewMultiplier"></span>&nbsp;
                                                            <span data-bind="text: NewMultiplierUnitOfMeasure"></span> (
                                                            <span data-bind="percentageText: NewProgressAvg"></span>)
                                                        </span>
                                                    </ko-bind>
                                                </td>
                                            </tr>
                                        </ko-bind>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ko-bind data-bind={{ if: taskOnEdit.CanViewWork }}>
                        <div class="tab-pane" data-bind="css: { active: WorkHistoryShown }">
                            <h3>
                                Storico Lavoro
                                <button class="btn btn-primary pull-right" data-bind="click: LoadWorkHistory">
                                    <i class="fa fa-refresh"></i>
                                    Carica
                                </button>
                            </h3>
                            <div style="position: absolute; top: 95px; right: 15px; bottom: 30px; left: 15px;">
                                <table class="table table-condensed table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 12%">Inizio lavoro</th>
                                            <th style="width: 12%">Fine lavoro</th>
                                            <th style="width: 12%">Utente</th>
                                            <th
                                                style="width: 12%"
                                                data-bind="style: { width: (CanViewCosts() ? '12%' : '17%') }"
                                            >
                                                Mansione
                                            </th>
                                            <th
                                                style="width: 12%"
                                                data-bind="style: { width: (CanViewCosts() ? '12%' : '17%') }"
                                            >
                                                Tipologia
                                            </th>
                                            <th style="width: 10%">Fatturabile</th>
                                            <th style="width: 10%">D.d.C.</th>
                                            <th style="width: 10%">Lavoro</th>
                                            <ko-bind data-bind={{ if: taskOnEdit.CanViewCosts }}>
                                                <th style="width: 10%">Costo</th>
                                            </ko-bind>
                                        </tr>
                                    </thead>
                                    <tbody style="height: 0px !important"></tbody>
                                </table>
                                <div
                                    style="position: absolute; top: 30px; right: 0px; bottom: 0px; left: 0px"
                                    data-bind="slimScroll"
                                >
                                    <table class="table table-condensed table-striped table-hover">
                                        <thead style="height: 0px !important"></thead>
                                        <tbody>
                                            <ko-bind
                                                data-bind={{
                                                    if:
                                                        taskOnEdit.WorkHistory().length == 0 &&
                                                        taskOnEdit.IsWorkHistoryLoaded(),
                                                }}
                                            >
                                                <tr>
                                                    <td
                                                        class="text-center"
                                                        data-bind="attr: { colspan: CanViewCosts() ? 8 : 7 }"
                                                    >
                                                        Nessuna lavorazione da visualizzare
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsWorkHistoryLoaded }}>
                                                <tr>
                                                    <td
                                                        class="text-center"
                                                        data-bind="attr: { colspan: CanViewCosts() ? 8 : 7 }"
                                                    >
                                                        Per caricare lo storico lavoro premere il pulsante "Carica"
                                                    </td>
                                                </tr>
                                            </ko-bind>
                                            <ko-bind
                                                data-bind={{
                                                    foreach: { data: taskOnEdit.WorkHistory, as: "workHistory" },
                                                }}
                                            >
                                                <tr>
                                                    <td style="width: 12%">
                                                        <span data-bind="timezonedDateTimeText: WorkStart, timezone: WorkStartTimezone"></span>{" "}
                                                        (<span data-bind="text: WorkStartTimezone"></span>)
                                                    </td>
                                                    <td style="width: 12%">
                                                        <span data-bind="timezonedDateTimeText: WorkEnd, timezone: WorkEndTimezone"></span>{" "}
                                                        (<span data-bind="text: WorkEndTimezone"></span>)
                                                    </td>
                                                    <td style="width: 12%" data-bind="text: ResourceName"></td>
                                                    <td
                                                        style="width: 12%"
                                                        data-bind="text: RoleName, style: { width: ($parent.CanViewCosts() ? '12%' : '17%') }"
                                                    ></td>
                                                    <td
                                                        style="width: 12%"
                                                        data-bind="text: WorkTimeCategory, style: { width: ($parent.CanViewCosts() ? '12%' : '17%') }"
                                                    ></td>
                                                    <td style="width: 10%">
                                                        <i class="fa fa-check" data-bind="visible: Billable"></i>
                                                    </td>
                                                    <td style="width: 10%">
                                                        <i class="fa fa-check" data-bind="visible: CallRight"></i>
                                                    </td>
                                                    <td style="width: 10%" data-bind="numberText: Hours"></td>
                                                    <ko-bind data-bind={{ if: taskOnEdit.CanViewCosts }}>
                                                        <td style="width: 10%" data-bind="moneyText: Cost"></td>
                                                    </ko-bind>
                                                </tr>
                                                <ko-bind data-bind={{ if: (workHistory.Notes || "").length > 0 }}>
                                                    <tr>
                                                        <td data-bind="attr: { colspan: $parent.CanViewCosts() ? 8 : 7 }, text: Notes"></td>
                                                    </tr>
                                                </ko-bind>
                                            </ko-bind>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style="position: absolute; right: 0px; bottom: 0px; left: 0px; height: 30px;">
                                <table
                                    class="table table-condensed table-striped table-hover"
                                    style="margin-bottom: 0px;"
                                >
                                    <thead style="height: 0px !important"></thead>
                                    <tbody style="height: 0px !important"></tbody>
                                    <tfoot>
                                        <tr>
                                            <td data-bind="style: { width: (CanViewCosts() ? '80%' : '90%') }">
                                                <b class="text-right">TOTALE</b>
                                            </td>
                                            <td style="width: 10%" data-bind="numberText: TotalWork"></td>
                                            <ko-bind data-bind={{ if: taskOnEdit.CanViewCosts }}>
                                                <td style="width: 10%" data-bind="moneyText: TotalCost"></td>
                                            </ko-bind>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: taskOnEdit.CanViewBudgets }}>
                        <div class="tab-pane" data-bind="css: { active: AssignedBudgetsShown }">
                            <h3>
                                Budgets
                                <button class="btn btn-primary pull-right" data-bind="click: LoadAssignedBudgets">
                                    <i class="fa fa-refresh"></i>
                                    Carica
                                </button>
                            </h3>
                            <table class="table table-condensed table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Data Autorizzazione</th>
                                        <th>Autorizzata da</th>
                                        <th>Causale</th>
                                        <th>Lavorazioni</th>
                                        <th>Acquisti</th>
                                        <th>Magazzino</th>
                                        <th>Totale</th>
                                    </tr>
                                </thead>
                                <tbody data-bind="foreach: AssignedBudgets">
                                    <tr>
                                        <td data-bind="dateText: CreationDate"></td>
                                        <td data-bind="dateText: ApprovedDate"></td>
                                        <td data-bind="HumanResourceFromUser: ApprovedUser"></td>
                                        <td data-bind="text: $parent.GetBudgetRequestCause($data)"></td>
                                        <td data-bind="moneyText: WorkBudget"></td>
                                        <td data-bind="moneyText: PurchasesBudget"></td>
                                        <td data-bind="moneyText: WarehouseBudget"></td>
                                        <td data-bind="moneyText: WorkBudget + PurchasesBudget + WarehouseBudget"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ko-bind>
                </div>
            </>
        );
    }

    private renderDetailsTab() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditor = this;
        const taskOnEdit: TodoListTask = null;
        const attachmentsManager: ActivityAttachmentsManager = null;

        return (
            <>
                <div data-bind="slimScroll : 'auto'" class="task-details">
                    {this.renderGeneralInfoEdit()}
                    {this.canAddAttachments && (
                        <ko-bind data-bind={{ with: taskOnEdit.AttachmentsManager, as: "attachmentsManager" }}>
                            <div
                                class="form"
                                style="padding: 10px !important; margin: 0 5px; border-left: 5px solid rgba(144, 216, 231, 1); background: aliceblue;"
                            >
                                <div
                                    class="portlet light bordered"
                                    data-bind="scrollIntoView: $parents[1].DocumentsScroller"
                                >
                                    <div
                                        class="portlet-title"
                                        data-bind="click: $parents[1].switchDocumentsOpenedState.bind($parents[1])"
                                    >
                                        <div class="caption bold" style="color: #d6d529">
                                            <i class="fa fa-files-o"></i>
                                            Documenti
                                        </div>

                                        <div class="tools">
                                            <a
                                                href="javascript:void(0)"
                                                class="collapse"
                                                data-bind="css: { collapse: $parents[1].DocumentsOpened, expand: !$parents[1].DocumentsOpened() }, click: $parents[1].switchDocumentsOpenedState.bind($parents[1]), clickBubble: false"
                                            ></a>
                                        </div>

                                        <div
                                            class="actions"
                                            style="margin-right: 15px"
                                            data-bind="visible: $parents[1].DocumentsOpened"
                                        >
                                            <div class="btn-group pull-right">
                                                <button
                                                    type="button"
                                                    class="btn btn-default dropdown-toggle btn-fit-height"
                                                    data-toggle="dropdown"
                                                    data-close-others="true"
                                                    data-delay="1000"
                                                    data-bind="click: $parent.SwitchAttachmentsActionsState.bind($parent), clickBubble: false"
                                                >
                                                    Azioni
                                                    <i class="fa fa-angle-down"></i>
                                                </button>

                                                <ul
                                                    class="dropdown-menu pull-right"
                                                    role="menu"
                                                    data-bind="style: { display: ($parent.ShowAttachmentsActions() ? 'block' : 'none') }"
                                                >
                                                    <li>
                                                        <a
                                                            href="#"
                                                            data-bind="click: AddAttachments, clickBubble: false"
                                                        >
                                                            <i class="fa fa-paperclip"></i>&nbsp;Allega documento
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#"
                                                            data-bind="click: AddLastVersionAttachments, clickBubble: false"
                                                        >
                                                            <i class="fa fa-file-code-o"></i>&nbsp;Allega modello
                                                        </a>
                                                    </li>
                                                    <ko-bind
                                                        data-bind={{
                                                            if: attachmentsManager.SelectedAttachments().length > 0,
                                                        }}
                                                    >
                                                        <li>
                                                            <a
                                                                href="#"
                                                                data-bind="click: RemoveSelectedAttachments, clickBubble: false"
                                                            >
                                                                <i class="fa fa-trash-o"></i>&nbsp;Elimina selezionati
                                                            </a>
                                                        </li>
                                                    </ko-bind>
                                                    <ko-bind
                                                        data-bind={{
                                                            if: attachmentsManager.SelectedAttachments().length == 1,
                                                        }}
                                                    >
                                                        <li>
                                                            <a
                                                                href="#"
                                                                data-bind="click: DownloadAttachments, clickBubble: false"
                                                            >
                                                                <i class="fa fa-download"></i>&nbsp;Scarica allegato
                                                            </a>
                                                        </li>
                                                    </ko-bind>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="portlet-body"
                                        data-bind="fileDrop: uploadFile, visible: $parents[1].DocumentsOpened"
                                    >
                                        <div class="listview" style="min-height: 80px;">
                                            <ko-bind
                                                data-bind={{
                                                    if:
                                                        attachmentsManager.attachments().length === 0 &&
                                                        attachmentsManager.lastVersionAttachments().length === 0,
                                                }}
                                            >
                                                <h4
                                                    class="text-center"
                                                    style="margin: 0; padding-top: 20px; pointer-events: none;"
                                                >
                                                    Nessun file allegato
                                                </h4>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: attachmentsManager.attachments }}>
                                                <a
                                                    href="#"
                                                    class="list multi-select"
                                                    data-bind="click: switchSelection, css : { selected : selected }, dblClickEx: downloadVersion"
                                                >
                                                    <div class="list-content">
                                                        <div
                                                            class="icon file"
                                                            data-bind="css : previewClasses, attr : { style : previewStyle }"
                                                        ></div>
                                                        <div class="data"></div>
                                                        <span class="list-title" data-bind="text : fileName"></span>
                                                        <span
                                                            class="list-remark"
                                                            data-bind="visible : fileVersion, text : 'Versione' + fileVersion()"
                                                        ></span>
                                                    </div>
                                                </a>
                                            </ko-bind>
                                            <ko-bind data-bind={{ foreach: attachmentsManager.lastVersionAttachments }}>
                                                <a
                                                    href="#"
                                                    class="list multi-select"
                                                    data-bind="click: switchSelection, css : { selected : selected }, dblClickEx: downloadLastVersion"
                                                >
                                                    <div class="list-content">
                                                        <div
                                                            class="icon file"
                                                            data-bind="css : previewClasses, attr : { style : previewStyle }"
                                                        ></div>
                                                        <div class="data"></div>
                                                        <span class="list-title" data-bind="text : fileName"></span>
                                                        <span
                                                            class="list-remark"
                                                            data-bind="visible : fileVersion, text : 'MODELLO'"
                                                        ></span>
                                                    </div>
                                                </a>
                                            </ko-bind>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ko-bind>
                    )}
                </div>
                <div class="navbar task-sections">
                    <div class="collapse navbar-collapse">
                        <h3 class="sections-title">Sezioni</h3>
                        <ul class="nav navbar-nav">
                            <li class="">
                                <button
                                    class="btn btn-default btn-scroller btn-general-data"
                                    style="width: 100%; text-align: center"
                                    data-bind="click: $parent.scrollToGeneralData.bind($parent)"
                                >
                                    Dati generali
                                </button>
                            </li>
                            <li class="">
                                <button
                                    class="btn btn-default btn-xs btn-status"
                                    data-bind="css: { 'btn-primary': $parent.TaskStateOpened }, toggle: $parent.TaskStateOpened, attr: { 'data-original-title': ($parent.TaskStateOpened() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' }"
                                >
                                    <i
                                        class="fa"
                                        data-bind="css: { 'fa-folder-open-o': $parent.TaskStateOpened, 'fa-folder-o': !$parent.TaskStateOpened() }"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-default btn-scroller btn-task-state"
                                    data-bind="click: $parent.scrollToTaskState.bind($parent)"
                                >
                                    <i class="fa fa-info"></i>
                                    Stato attività
                                </button>
                            </li>
                            <li class="">
                                <button
                                    class="btn btn-default btn-xs btn-status"
                                    data-bind="css: { 'btn-primary': $parent.EstimatedCostsOpened }, toggle: $parent.EstimatedCostsOpened, attr: { 'data-original-title': ($parent.EstimatedCostsOpened() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' }"
                                >
                                    <i
                                        class="fa"
                                        data-bind="css: { 'fa-folder-open-o': $parent.EstimatedCostsOpened, 'fa-folder-o': !$parent.EstimatedCostsOpened() }"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-default btn-scroller btn-estimated-costs"
                                    data-bind="click: $parent.scrollToEstimatedCosts.bind($parent)"
                                >
                                    <i class="fa fa-eur"></i>
                                    Spese previste
                                </button>
                            </li>
                            <li class="">
                                <button
                                    class="btn btn-default btn-xs btn-status"
                                    data-bind="css: { 'btn-primary': $parent.ResourcesOpened }, toggle: $parent.ResourcesOpened, attr: { 'data-original-title': ($parent.ResourcesOpened() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' }"
                                >
                                    <i
                                        class="fa"
                                        data-bind="css: { 'fa-folder-open-o': $parent.ResourcesOpened, 'fa-folder-o': !$parent.ResourcesOpened() }"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-default btn-scroller btn-resources"
                                    data-bind="click: $parent.scrollToResources.bind($parent)"
                                >
                                    <i class="fa fa-users"></i>
                                    Risorse
                                </button>
                            </li>
                            {this.canViewHourReportingSection && (
                                <li class="">
                                    <button
                                        class="btn btn-default btn-xs btn-status"
                                        data-bind={{
                                            css: { "btn-primary": taskEditor.WorkedHoursDefaultsOpened },
                                            toggle: taskEditor.WorkedHoursDefaultsOpened,
                                            attr: {
                                                "data-original-title": taskEditor.WorkedHoursDefaultsOpened()
                                                    ? "La sezione è aperta"
                                                    : "La sezione è chiusa",
                                            },
                                            tooltip: { placement: "left" },
                                        }}
                                    >
                                        <i
                                            class="fa"
                                            data-bind={{
                                                css: {
                                                    "fa-folder-open-o": taskEditor.WorkedHoursDefaultsOpened,
                                                    "fa-folder-o": !taskEditor.WorkedHoursDefaultsOpened(),
                                                },
                                            }}
                                        ></i>
                                    </button>
                                    <button
                                        class="btn btn-default btn-scroller btn-advanced"
                                        data-bind={{ click: taskEditor.scrollToWorkedHoursDefaults.bind(taskEditor) }}
                                    >
                                        <i class="fa fa-clock-o"></i>
                                        Rendicontazione ore
                                    </button>
                                </li>
                            )}
                            <li class="">
                                <button
                                    class="btn btn-default btn-xs btn-status"
                                    data-bind="css: { 'btn-primary': $parent.AdvancedSettingsOpened }, toggle: $parent.AdvancedSettingsOpened, attr: { 'data-original-title': ($parent.AdvancedSettingsOpened() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' }"
                                >
                                    <i
                                        class="fa"
                                        data-bind="css: { 'fa-folder-open-o': $parent.AdvancedSettingsOpened, 'fa-folder-o': !$parent.AdvancedSettingsOpened() }"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-default btn-scroller btn-advanced"
                                    data-bind="click: $parent.scrollToAdvancedSettings.bind($parent)"
                                >
                                    <i class="fa fa-cogs"></i>
                                    Avanzate
                                </button>
                            </li>
                            {this.canAddAttachments && (
                                <li class="">
                                    <button
                                        class="btn btn-default btn-xs btn-status"
                                        data-bind="css: { 'btn-primary': $parent.DocumentsOpened }, toggle: $parent.DocumentsOpened, attr: { 'data-original-title': ($parent.DocumentsOpened() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' }"
                                    >
                                        <i
                                            class="fa"
                                            data-bind="css: { 'fa-folder-open-o': $parent.DocumentsOpened, 'fa-folder-o': !$parent.DocumentsOpened() }"
                                        ></i>
                                    </button>
                                    <button
                                        class="btn btn-default btn-scroller btn-documents"
                                        data-bind="click: $parent.scrollToDocuments.bind($parent)"
                                    >
                                        <i class="fa fa-files-o"></i>
                                        Documenti
                                    </button>
                                </li>
                            )}
                            <li class="actions-separator">&nbsp;</li>
                            <li class="save-button text-right">
                                <button class="btn" data-bind="click: $parent.saveSectionsConfig.bind($parent)">
                                    Salva impostazioni
                                </button>
                            </li>
                            <li class="defaults-button text-right">
                                <button class="btn" data-bind="click: $parent.loadSectionsSettings.bind($parent)">
                                    Carica impostazioni
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    private renderGeneralInfoEdit() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditor = this;
        const taskOnEdit: TodoListTask = null;
        const ebt: EstimateBudgetRow = null;

        return (
            <div
                class="form"
                style="padding: 10px !important; margin: 0 5px; border-left: 5px solid rgba(144, 216, 231, 1); background: aliceblue;"
            >
                <div
                    class="row task-general-data"
                    data-bind="scrollIntoView: $parent.GeneralDataScroller"
                    style="margin-bottom: 60px"
                >
                    <div class="col-md-12">
                        <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                            <div class="row" style="margin-bottom: 25px">
                                <div class="col-md-6 joborder-field-wrapper">
                                    <ko-bind data-bind={{ with: taskOnEdit.JobOrdersTagsManager }}>
                                        <select2 params="Value: JobOrderId, DataSource: JobOrdersDataSource, AllowClear: true, Placeholder: 'Seleziona una commessa...', ReadOnly: !$parent.IsNew(), Simple: true"></select2>
                                    </ko-bind>
                                </div>

                                <div class="col-md-6 workflow-field-wrapper">
                                    <select2 params="Value: RelatedWorkFlow, DataSource: WorkflowsDataSource, AllowClear: true, Placeholder: 'Seleziona un piano...'"></select2>
                                </div>
                            </div>
                        </ko-bind>

                        <div class="row" style="margin-bottom: 30px">
                            <div class="col-md-12">
                                <button
                                    class="btn btn-default"
                                    title="Milestone"
                                    style="display: inline-block; vertical-align: top;"
                                    data-bind="toggle: IsMilestone, css: { 'bg-red': IsMilestone, 'btn-default': !IsMilestone() }, tooltip: { placement: 'right' }"
                                >
                                    <i class="fa fa-star"></i>
                                </button>
                                <div style="display: inline-block; width: calc(100% - 45px)">
                                    <input
                                        placeholder="Inserisci il titolo dell'attivit&agrave;"
                                        class="form-control"
                                        style="height: 34px; resize: none; overflow: hidden; -ms-word-wrap: break-word; word-wrap: break-word"
                                        data-bind="value : Title, event : { keydown : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, hasfocus : TitleHasFocus, selectOnFocus : {}, valueUpdate : 'afterkeydown'"
                                        maxLength={240}
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-bottom: 30px">
                            <div class="col-md-12">
                                <div class="task-inline-description-editor-wrapper">
                                    <textarea
                                        class="task-inline-description-editor"
                                        data-bind="htmlEditor : Description, html: Description, htmlEditorOptions: { 'inlineMode': true }"
                                        placeholder="Inserisci la descrizione dell'attivit&agrave;..."
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-bottom: 20px">
                            {this.canEditTaskWorkEstimate && (
                                <div class="col-md-4">
                                    <label class="control-label">Durata (ore.decimali)</label>
                                    <input
                                        class="form-control"
                                        spellCheck={false}
                                        placeholder="Durata..."
                                        type="text"
                                        data-bind="numberValue : Duration, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, selectOnFocus : {}, enable: CanEditDuration"
                                    />
                                </div>
                            )}

                            {!this.canEditTaskWorkEstimate && (
                                <div class="col-md-4">
                                    <label class="control-label">Durata (ore.decimali)</label>
                                    <div
                                        class="form-control form-control-static"
                                        style="margin: 0;"
                                        data-bind="numberText: Duration"
                                    ></div>
                                </div>
                            )}

                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                <div class="col-md-4" data-bind="visible: !IsMilestone()">
                                    <label class="control-label">Inizio</label>
                                    <input
                                        class="form-control"
                                        data-bind="nullableDatePicker : StartDate, disable: IsMilestone"
                                        type="text"
                                        placeholder="Data inizio"
                                    />
                                </div>

                                <div class="col-md-4">
                                    <label class="control-label">Scadenza</label>
                                    <input
                                        class="form-control"
                                        data-bind="nullableDatePicker : DueDate"
                                        type="text"
                                        placeholder="Data scadenza"
                                    />
                                </div>
                            </ko-bind>
                        </div>
                    </div>
                </div>

                <div class="portlet light bordered task-section" data-bind="scrollIntoView: $parent.TaskStateScroller">
                    <div class="portlet-title" data-bind="click: $parent.switchTaskStateOpenedState.bind($parent)">
                        <div class="caption bold" style="color: #67b5ca">
                            <i class="fa fa-info"></i>
                            Stato attività
                        </div>

                        <div class="tools">
                            <a
                                href="javascript:void(0)"
                                class="collapse"
                                data-bind="css: { collapse: $parent.TaskStateOpened, expand: !$parent.TaskStateOpened() }, click: $parent.switchTaskStateOpenedState.bind($parent), clickBubble: false"
                            ></a>
                        </div>
                    </div>

                    <div class="portlet-body" data-bind="visible: $parent.TaskStateOpened">
                        <div class="row" style="margin-bottom: 10px">
                            <div class="col-md-4">
                                <label class="control-label">Priorit&agrave;</label>
                                <select
                                    class="form-control"
                                    data-bind="optionsCaption : 'Seleziona...', value : Priority"
                                >
                                    <option value="0">Bassa</option>
                                    <option value="1">Normale</option>
                                    <option value="2">Alta</option>
                                </select>
                            </div>

                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                <div class="col-md-4">
                                    <label class="control-label">Stato</label>
                                    <select
                                        class="form-control"
                                        data-bind="options : Folders, optionsCaption : 'Seleziona...', optionsText : 'title', value : StateFolder"
                                    ></select>
                                </div>
                            </ko-bind>
                            <div class="col-md-4">
                                <label class="control-label">Causale</label>
                                <select
                                    class="form-control"
                                    data-bind="options : BudgetRequestCauses, value : BudgetRequestCause, optionsCaption: 'Seleziona...', optionsText: 'Description', optionsValue : 'Id'"
                                ></select>
                            </div>
                        </div>

                        <div class="row" style="margin-bottom: 10px">
                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                <div className="col-md-4 task-progress-avg">
                                    <label class="control-label">Avanzamento</label>
                                    {this.canEditTaskProgress && (
                                        <ko-bind data-bind={{ if: taskOnEdit.ActivitiesProgressAmountMode() == 0 }}>
                                            <input
                                                class="form-control"
                                                type="text"
                                                data-bind="touchSpin : { min: 0.00, max: 100.00, step: 5, decimals: 2, postfix: '%', mousewheel: false, forcestepdivisibility: 'none' }, event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }, value : ProgressAvg, disable: ActivitiesProgressAmountMode() != 0"
                                            />
                                        </ko-bind>
                                    )}
                                    {!this.canEditTaskProgress && (
                                        <ko-bind data-bind={{ if: taskOnEdit.ActivitiesProgressAmountMode() == 0 }}>
                                            <div
                                                class="form-control form-control-static"
                                                style="margin: 0;"
                                                data-bind="numberText: ProgressAvg"
                                            ></div>
                                        </ko-bind>
                                    )}
                                    <ko-bind data-bind={{ ifnot: taskOnEdit.ActivitiesProgressAmountMode() == 0 }}>
                                        <span style="display: block; font-size: 14px; height: 34px; line-height: 30px;">
                                            <span data-bind="percentageText: ProgressAvg"></span>&nbsp;(
                                            <span data-bind="numberText: WorkedAmount"></span>/
                                            <span data-bind="numberText: Multiplier"></span>&nbsp;
                                            <span data-bind="text: MultiplierUnitOfMeasure"></span>)
                                        </span>
                                    </ko-bind>
                                </div>
                            </ko-bind>

                            <div class="col-md-4">
                                <label class="control-label">Avanzamento attività</label>
                                <select
                                    class="form-control"
                                    data-bind="options: ActivitiesProgressAmountModeOptions, value: ActivitiesProgressAmountMode, optionsText: 'label', optionsValue: 'value'"
                                ></select>
                            </div>

                            <div class="col-md-4">
                                <label class="control-label">Tipo Rendicontazione</label>
                                <select
                                    class="form-control"
                                    data-bind="options : ReportingTypes, optionsCaption : 'Seleziona...', optionsText : 'Description', optionsValue : 'Id', value : ReportingType"
                                ></select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label class="control-label">Suggerimenti</label>
                                <div>
                                    <input
                                        data-bind="onOffSwitch : ShowInSuggestions, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                        type="checkbox"
                                        checked={false}
                                        data-on-text="Si"
                                        data-off-text="No"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="portlet light bordered task-section estimated-costs"
                    data-bind="scrollIntoView: $parent.EstimatedCostsScroller"
                >
                    <div class="portlet-title" data-bind="click: $parent.switchEstimatedCostsOpenedState.bind($parent)">
                        <div class="caption bold" style="color: #65d066">
                            <i class="fa fa-eur"></i>
                            Spese previste
                        </div>

                        <div class="tools">
                            <a
                                href="javascript:void(0)"
                                class="collapse"
                                data-bind="css: { collapse: $parent.EstimatedCostsOpened, expand: !$parent.EstimatedCostsOpened() }, click: $parent.switchEstimatedCostsOpenedState.bind($parent), clickBubble: false"
                            ></a>
                        </div>
                    </div>
                    <div class="portlet-body" data-bind="visible: $parent.EstimatedCostsOpened">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row" style="margin-bottom: 30px">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <label class="control-label">Quantità da produrre</label>
                                                {this.canEditTaskMultiplier && (
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="N. volte"
                                                        data-bind="numberValue: Multiplier"
                                                    />
                                                )}
                                                {!this.canEditTaskMultiplier && (
                                                    <div
                                                        class="form-control disabled"
                                                        data-bind="numberText: Multiplier"
                                                    ></div>
                                                )}
                                            </div>
                                            <div class="col-md-4">
                                                <label class="control-label">U.d.m.</label>
                                                {this.canEditTaskMultiplier && (
                                                    <input
                                                        type="text"
                                                        placeholder="Unità di misura"
                                                        class="form-control"
                                                        data-bind="value: MultiplierUnitOfMeasure"
                                                    />
                                                )}
                                                {!this.canEditTaskMultiplier && (
                                                    <div
                                                        class="form-control disabled"
                                                        data-bind="text: MultiplierUnitOfMeasure"
                                                    ></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-right add-actions">
                                            {this.canEditTaskWorkEstimate &&
                                                this.canEditTaskWarehouseEstimates &&
                                                this.canEditTaskPurchasesEstimates && (
                                                    <a
                                                        class="btn btn-primary btn-sm"
                                                        style="margin-left: 10px"
                                                        data-bind="click: resetEstimatesCostsAndPrices"
                                                        title="Ricarica in dati di default per le righe di stima"
                                                    >
                                                        <i class="fa fa-refresh"></i>
                                                    </a>
                                                )}

                                            {this.canEditTaskWorkEstimate && (
                                                <a
                                                    class="btn btn-primary btn-sm estimated-work-color"
                                                    data-bind="click: AddEstimatedWork"
                                                >
                                                    <i class="fa fa-plus"></i> Lavorazione
                                                </a>
                                            )}

                                            {this.canEditTaskWarehouseEstimates && (
                                                <a
                                                    class="btn btn-primary btn-sm estimated-articles-color"
                                                    data-bind="click: AddEstimatedArticle"
                                                >
                                                    <i class="fa fa-plus"></i> Articolo
                                                </a>
                                            )}

                                            {this.canEditTaskPurchasesEstimates && (
                                                <a
                                                    class="btn btn-primary btn-sm estimated-purchases-color"
                                                    data-bind="click: AddEstimatedPurchase"
                                                >
                                                    <i class="fa fa-plus"></i> Acquisto
                                                </a>
                                            )}
                                        </div>
                                        <table class="table table-condensed estimated-costs">
                                            <thead>
                                                <tr>
                                                    <th class="anchor-col"></th>
                                                    {this.canEditEstimatedBudgetRowState && (
                                                        <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                                            <th class="state-col"></th>
                                                        </ko-bind>
                                                    )}
                                                    <th style="text-align: left">Descrizione</th>
                                                    <th class="amount-col">Quantità</th>
                                                    {this.canViewCostsOnEstimatedBudgetRows && (
                                                        <>
                                                            <th class="amount-col">Costo unitario</th>
                                                            <th class="amount-col">Costo</th>
                                                        </>
                                                    )}

                                                    {this.canViewRevenuesOnEstimatedBudgetRows && (
                                                        <>
                                                            <th class="amount-col costs-revenues-separator">
                                                                Ricarico (%)
                                                            </th>
                                                            <th class="amount-col">Prezzo unitario</th>
                                                            <th class="amount-col">Sconto</th>
                                                            <th class="amount-col">Prezzo un. netto</th>
                                                            <th class="amount-col">Ricavo</th>
                                                        </>
                                                    )}

                                                    <th class="actions-col"></th>
                                                </tr>
                                            </thead>
                                            <tbody data-bind="droppableListEx: { hoverClass: 'droppable-hover', hoverBeforeClass: 'droppable-hover-before', hoverAfterClass: 'droppable-hover-after', itemSelector: '.costs-row', allowedMimeTypes: ['application/estimated-budget-for-task', 'application/estimated-budget-for-task'], onDrop: onEstimatedBudgetRowDropped.bind($data) }">
                                                <tr data-bind="visible: EstimatedBudgetRows().length === 0">
                                                    <td
                                                        class="text-center"
                                                        data-bind="attr: { colSpan: estimatedBudgetRowColSpan }"
                                                    >
                                                        Nessuna spesa prevista.
                                                    </td>
                                                </tr>
                                                <ko-bind
                                                    data-bind={{
                                                        foreach: { data: taskOnEdit.EstimatedBudgetRows, as: "ebt" },
                                                    }}
                                                >
                                                    <tr
                                                        class="costs-row"
                                                        data-bind="visible: row.Type !== 'WAR' || canViewWarehouse, draggableEx: { CanDrag: true, OnDrag: $parent.onEstimatedBudgetRowBeginDrag.bind($parent, $data) }"
                                                    >
                                                        <td
                                                            class="anchor-col"
                                                            data-bind="css: { 'estimated-work-marker': row.Type === 'EWK', 'estimated-articles-marker': row.Type === 'WAR', 'estimated-purchases-marker': row.Type === 'EPC' }"
                                                        >
                                                            <i class="fa fa-bars"></i>
                                                        </td>
                                                        {this.canEditEstimatedBudgetRowState && (
                                                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                                                <td class="state-col edit-field">
                                                                    <div
                                                                        class="state"
                                                                        data-bind="asyncClick: openStateSelectorPopover"
                                                                    >
                                                                        <ko-bind data-bind={{ if: !!ebt.State() }}>
                                                                            <button
                                                                                class="btn btn-sm"
                                                                                data-bind="style: { 'background-color': State().Background }, attr: { title: State().Label }, disable: ReadOnly"
                                                                            >
                                                                                <span data-bind="style: { color: State().Foreground }, css: State().Icon"></span>
                                                                            </button>
                                                                        </ko-bind>
                                                                        <ko-bind data-bind={{ ifnot: !!ebt.State() }}>
                                                                            <button
                                                                                class="btn btn-sm"
                                                                                title="Seleziona uno stato"
                                                                                data-bind="disable: ReadOnly"
                                                                            >
                                                                                <span
                                                                                    style="color: black"
                                                                                    class="fa fa-pencil"
                                                                                ></span>
                                                                            </button>
                                                                        </ko-bind>
                                                                    </div>
                                                                </td>
                                                            </ko-bind>
                                                        )}
                                                        <td
                                                            class="edit-field flex-container no-gutters"
                                                            style="margin-top: -1px"
                                                        >
                                                            <ko-bind data-bind={{ if: ebt.row.Type === "EWK" }}>
                                                                <input
                                                                    class="form-control input-sm work-row-descr"
                                                                    style="display: inline-block; width: 60%"
                                                                    maxLength={4000}
                                                                    placeholder="Inserisci una descrizione..."
                                                                    data-bind="value: Description, attr: { readonly: ReadOnly }"
                                                                />
                                                                <select
                                                                    class="form-control input-sm"
                                                                    style="display: inline-block; width: 40%"
                                                                    data-bind="options: Roles, value: EntityKeyId, optionsCaption: 'Seleziona...', optionsText: 'Description', optionsValue : 'IdUserCharacter', disable: ReadOnly"
                                                                ></select>
                                                            </ko-bind>
                                                            <ko-bind data-bind={{ if: ebt.row.Type === "WAR" }}>
                                                                <select2 params="Value: EntityKeyId, DataSource: WarehouseArticlesDataSource, Placeholder: 'Inserisci un articolo...', AllowClear: true, CssClass: 'input-sm', ReadOnly: ReadOnly, Listener: $data, InputCSS: 'small-btn'">
                                                                    <button
                                                                        class="btn btn-primary btn-sm"
                                                                        style="margin: 0px"
                                                                        data-bind="click: $parents[2].OpenTransformsDialog.bind($parents[2], $data), enable: IsComposite, css: { hide: !IsComposite() }"
                                                                    >
                                                                        <i class="fa fa-search"></i>
                                                                    </button>
                                                                </select2>
                                                            </ko-bind>
                                                            <ko-bind data-bind={{ if: ebt.row.Type === "EPC" }}>
                                                                <typeahead
                                                                    style="display: inline-block; width: 60%"
                                                                    class="pull-left"
                                                                    value={() => "Description"}
                                                                    dataSource={() => "PurchasesDataSource"}
                                                                    placeholder="Inserisci una descrizione..."
                                                                    listener={() => "$data"}
                                                                    bindings="css: { 'input-sm': true, readonly: ReadOnly }"
                                                                    readOnly={() => "ReadOnly"}
                                                                    /* params="Value: Description, DataSource: PurchasesDataSource, Placeholder: 'Inserisci una descrizione...', Listener: $data, Bindings: 'css: { 'input-sm': true, readonly: ReadOnly }', ReadOnly: ReadOnly" */
                                                                ></typeahead>
                                                                <select2
                                                                    style="display: inline-block; width: 40%"
                                                                    class="pull-right"
                                                                    params="Value: EntityKeyId, DataSource: PurchaseTypesDataSource, CssClass: 'input-sm purchase-type', ReadOnly: ReadOnly, Listener: $data"
                                                                ></select2>
                                                            </ko-bind>
                                                        </td>
                                                        <td class="edit-field">
                                                            <input
                                                                class="form-control input-sm number"
                                                                placeholder="Q.tà"
                                                                data-bind="numberValue: Amount, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                            />
                                                        </td>
                                                        {this.canViewCostsOnEstimatedBudgetRows && (
                                                            <>
                                                                <ko-bind data-bind={{ if: ebt.row.Type === "EPC" }}>
                                                                    <td class="edit-field number">
                                                                        {this.canEditTaskPurchasesEstimates && (
                                                                            <input
                                                                                class="form-control text-right purchase-price input-sm"
                                                                                data-bind="moneyValue: UnitCost, extendedMoneyFormat: true, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                                            />
                                                                        )}
                                                                        {!this.canEditTaskPurchasesEstimates && (
                                                                            <div
                                                                                class="form-control text-right purchase-price input-sm disabled"
                                                                                data-bind="moneyText: UnitCost, extendedMoneyFormat: true"
                                                                            ></div>
                                                                        )}
                                                                    </td>
                                                                </ko-bind>
                                                                <ko-bind data-bind={{ ifnot: ebt.row.Type === "EPC" }}>
                                                                    <td class="number">
                                                                        <span data-bind="moneyText: UnitCost, extendedMoneyFormat: true"></span>
                                                                    </td>
                                                                </ko-bind>
                                                                <td class="number">
                                                                    <span
                                                                        class="bold"
                                                                        data-bind="moneyText: Cost, extendedMoneyFormat: true"
                                                                    ></span>
                                                                </td>
                                                            </>
                                                        )}
                                                        {this.canViewRevenuesOnEstimatedBudgetRows && (
                                                            <>
                                                                <td class="edit-field costs-revenues-separator">
                                                                    <input
                                                                        class="form-control input-sm number"
                                                                        placeholder="Ricarico"
                                                                        data-bind="percentageValue: Markup, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                                    />
                                                                </td>
                                                                <td class="edit-field">
                                                                    <input
                                                                        class="form-control input-sm number"
                                                                        placeholder="Prezzo unitario"
                                                                        data-bind="moneyValue: UnitPrice, extendedMoneyFormat: true, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                                    />
                                                                </td>
                                                                <td class="edit-field">
                                                                    <input
                                                                        class="form-control input-sm number"
                                                                        placeholder="Sconto"
                                                                        data-bind="discountValue: DiscountValues, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                                    />
                                                                </td>
                                                                <td
                                                                    class="edit-field"
                                                                    data-bind="css: { 'net-unit-price-alert': NetUnitPriceAlert }"
                                                                >
                                                                    <input
                                                                        class="form-control input-sm number"
                                                                        placeholder="Prezzo un. netto"
                                                                        data-bind="moneyValue: NetUnitPrice, extendedMoneyFormat: true, selectOnFocus: {}, attr: { readonly: ReadOnly }"
                                                                    />
                                                                </td>
                                                                <td
                                                                    class="number bold"
                                                                    data-bind="css: { 'revenue-alert': PriceAlert }"
                                                                >
                                                                    <span data-bind="moneyText: Price, extendedMoneyFormat: true"></span>
                                                                </td>
                                                            </>
                                                        )}

                                                        <td class="actions-col edit-field">
                                                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                                                <a
                                                                    class="btn btn-xs pull-left"
                                                                    style="width: 25px; margin-left: 2px; margin-right: 5px"
                                                                    href="#"
                                                                    data-bind="toggle: ShowNotes"
                                                                    title="Mostra/nascondi le note"
                                                                >
                                                                    <i
                                                                        class="fa"
                                                                        data-bind="css: { 'fa-chevron-down': !ShowNotes(), 'fa-chevron-up': ShowNotes }"
                                                                    ></i>
                                                                </a>
                                                            </ko-bind>
                                                            {this.canEditTaskWorkEstimate && (
                                                                <>
                                                                    <ko-bind data-bind={{ if: ebt.row.Type === "EWK" }}>
                                                                        <a
                                                                            class="btn btn-primary btn-xs"
                                                                            style="width: 25px; margin-right: 5px"
                                                                            href="#"
                                                                            data-bind="click: resetCostsAndPrices"
                                                                            title="Ricarica in dati di default"
                                                                        >
                                                                            <i class="fa fa-refresh"></i>
                                                                        </a>
                                                                        <a
                                                                            class="btn btn-primary btn-xs"
                                                                            style="width: 25px; margin-right: 5px"
                                                                            href="#"
                                                                            data-bind="click: SwitchEditMode, visible: !IsNew()"
                                                                            title="Abilita modifica dei dati"
                                                                        >
                                                                            <i class="fa fa-edit"></i>
                                                                        </a>

                                                                        <a
                                                                            class="btn btn-danger btn-xs"
                                                                            style="width: 25px; margin-right: 0px"
                                                                            href="#"
                                                                            data-bind="click : $parent.RemoveEstimatedBudgetRow.bind($parent)"
                                                                            title="Elimina"
                                                                        >
                                                                            <i class="fa fa-trash-o"></i>
                                                                        </a>
                                                                    </ko-bind>
                                                                </>
                                                            )}

                                                            {this.canEditTaskWarehouseEstimates && (
                                                                <ko-bind data-bind={{ if: ebt.row.Type === "WAR" }}>
                                                                    <a
                                                                        class="btn btn-primary btn-xs"
                                                                        style="width: 25px; margin-right: 5px"
                                                                        href="#"
                                                                        data-bind="click: resetCostsAndPrices"
                                                                        title="Ricarica in dati di default"
                                                                    >
                                                                        <i class="fa fa-refresh"></i>
                                                                    </a>
                                                                    <a
                                                                        class="btn btn-primary btn-xs"
                                                                        style="width: 25px; margin-right: 5px"
                                                                        href="#"
                                                                        data-bind="click: SwitchEditMode, visible: !IsNew()"
                                                                        title="Abilita modifica dei dati"
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>

                                                                    <a
                                                                        class="btn btn-danger btn-xs"
                                                                        style="width: 25px; margin-right: 0px"
                                                                        href="#"
                                                                        data-bind="click : $parent.RemoveEstimatedBudgetRow.bind($parent)"
                                                                        title="Elimina"
                                                                    >
                                                                        <i class="fa fa-trash-o"></i>
                                                                    </a>
                                                                </ko-bind>
                                                            )}

                                                            {this.canEditTaskPurchasesEstimates && (
                                                                <ko-bind data-bind={{ if: ebt.row.Type === "EPC" }}>
                                                                    <a
                                                                        class="btn btn-primary btn-xs"
                                                                        style="width: 25px; margin-right: 5px"
                                                                        href="#"
                                                                        data-bind="click: SwitchEditMode, visible: !IsNew()"
                                                                        title="Abilita modifica dei dati"
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>

                                                                    <a
                                                                        class="btn btn-danger btn-xs"
                                                                        style="width: 25px; margin-right: 0px"
                                                                        href="#"
                                                                        data-bind="click : $parent.RemoveEstimatedBudgetRow.bind($parent)"
                                                                        title="Elimina"
                                                                    >
                                                                        <i class="fa fa-trash-o"></i>
                                                                    </a>
                                                                </ko-bind>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                                        <tr class="costs-row" data-bind="visible: ShowNotes">
                                                            <td data-bind="attr: { colSpan: $parent.estimatedBudgetRowColSpan }, css: { 'estimated-work-marker': row.Type === 'EWK', 'estimated-articles-marker': row.Type === 'WAR', 'estimated-purchases-marker': row.Type === 'EPC' }">
                                                                <textarea
                                                                    class="task-inline-description-editor"
                                                                    data-bind="htmlEditor : Notes, html: Notes, htmlEditorOptions: { 'inlineMode': true }, readonly: ReadOnly"
                                                                    placeholder="Note aggiuntive..."
                                                                ></textarea>
                                                            </td>
                                                        </tr>
                                                    </ko-bind>
                                                </ko-bind>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.canViewCostsOnEstimatedBudgetRows ||
                                    (this.canViewRevenuesOnEstimatedBudgetRows && (
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div
                                                        class="col-md-12"
                                                        style="padding-top: 5px; padding-bottom: 5px;"
                                                    >
                                                        <i
                                                            class="pull-right fa"
                                                            data-bind="toggle: ShowTotals, css: { 'fa-chevron-up': ShowTotals, 'fa-chevron-down': !ShowTotals() }, attr: { 'data-original-title': (ShowTotals() ? 'Nascondi totali' : 'Mostra totali') }, tooltip: { placement: 'left' }"
                                                        ></i>
                                                    </div>
                                                </div>

                                                <div
                                                    class="row task-totals-row"
                                                    style="margin: 0px"
                                                    data-bind="visible: ShowTotals"
                                                >
                                                    {this.canViewCostsOnEstimatedBudgetRows && (
                                                        <div class="col-md-6 task-totals-col">
                                                            <i
                                                                class="fa"
                                                                style="position: relative; left: -10px;"
                                                                data-bind="toggle: ShowSubTotals, css: { 'fa-caret-right': !ShowSubTotals(), 'fa-caret-down': ShowSubTotals }"
                                                            ></i>
                                                            <span>Costi totali:</span>
                                                            <span
                                                                class="bold pull-right"
                                                                data-bind="moneyText: TotalCosts"
                                                            ></span>
                                                        </div>
                                                    )}

                                                    {this.canViewRevenuesOnEstimatedBudgetRows && (
                                                        <div class="col-md-6 task-totals-col">
                                                            <i
                                                                class="fa"
                                                                style="position: relative; left: -10px;"
                                                                data-bind="toggle: ShowSubTotals, css: { 'fa-caret-right': !ShowSubTotals(), 'fa-caret-down': ShowSubTotals }"
                                                            ></i>
                                                            <span>Ricavi totali:</span>
                                                            <span
                                                                class="bold pull-right"
                                                                data-bind="moneyText: TotalRevenues"
                                                            ></span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    class="row"
                                                    style="margin: 0px"
                                                    data-bind="visible: ShowTotals() && ShowSubTotals()"
                                                >
                                                    {this.canViewCostsOnEstimatedBudgetRows && (
                                                        <div class="col-md-6">
                                                            <div class="row task-totals-row">
                                                                <div
                                                                    class="task-totals-col task-estimated-work-cell"
                                                                    data-bind="css: { 'col-md-4': IsWarehouseEnabled, 'col-md-6': !IsWarehouseEnabled() }"
                                                                >
                                                                    <i
                                                                        class="fa"
                                                                        style="position: relative; left: -10px;"
                                                                        data-bind="toggle: ShowSubTotalsDetails, css: { 'fa-caret-right': !ShowSubTotalsDetails(), 'fa-caret-down': ShowSubTotalsDetails }"
                                                                    ></i>
                                                                    <span>Lavoro:</span>
                                                                    <span
                                                                        class="bold pull-right"
                                                                        data-bind="moneyText: EstimatedWorkTotalCosts"
                                                                    ></span>
                                                                </div>
                                                                {this.canStartWarehouse && (
                                                                    <div class="col-md-4 task-totals-col task-estimated-articles-cell">
                                                                        <span>Magazzino:</span>
                                                                        <span
                                                                            class="bold pull-right"
                                                                            data-bind="moneyText: EstimatedArticlesTotalCosts"
                                                                        ></span>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    class="task-totals-col task-estimated-purchase-cell"
                                                                    data-bind="css: { 'col-md-4': IsWarehouseEnabled, 'col-md-6': !IsWarehouseEnabled() }"
                                                                >
                                                                    <span>Acquisti:</span>
                                                                    <span
                                                                        class="bold pull-right"
                                                                        data-bind="moneyText: EstimatedPurchasesTotalCosts"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="row"
                                                                data-bind="visible: ShowSubTotalsDetails() && ShowSubTotals()"
                                                            >
                                                                <div class="col-md-12">
                                                                    <div class="row task-totals-row">
                                                                        <div
                                                                            class="col-md-6 task-estimated-work-cell details"
                                                                            data-bind="foreach: UserCharactersDetails"
                                                                        >
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <span data-bind="text: Description"></span>
                                                                                    :
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-6 bold text-right"
                                                                                    data-bind="moneyText: Cost"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-md-6 task-estimated-purchase-cell details"
                                                                            data-bind="foreach: PurchasesTypesDetails"
                                                                        >
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <span data-bind="text: Description"></span>
                                                                                    :
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-6 bold text-right"
                                                                                    data-bind="moneyText: Cost"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {this.canViewRevenuesOnEstimatedBudgetRows && (
                                                        <div class="col-md-6">
                                                            <div class="row task-totals-row">
                                                                <div
                                                                    class="task-totals-col task-estimated-work-cell"
                                                                    data-bind="css: { 'col-md-4': IsWarehouseEnabled, 'col-md-6': !IsWarehouseEnabled() }"
                                                                >
                                                                    <i
                                                                        class="fa"
                                                                        style="position: relative; left: -10px;"
                                                                        data-bind="toggle: ShowSubTotalsDetails, css: { 'fa-caret-right': !ShowSubTotalsDetails(), 'fa-caret-down': ShowSubTotalsDetails }"
                                                                    ></i>
                                                                    <span>Lavoro:</span>
                                                                    <span
                                                                        class="bold pull-right"
                                                                        data-bind="moneyText: EstimatedWorkTotalRevenues"
                                                                    ></span>
                                                                </div>
                                                                ì
                                                                {this.canStartWarehouse && (
                                                                    <div class="col-md-4 task-totals-col task-estimated-articles-cell">
                                                                        <span>Magazzino:</span>
                                                                        <span
                                                                            class="bold pull-right"
                                                                            data-bind="moneyText: EstimatedArticlesTotalRevenues"
                                                                        ></span>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    class="task-totals-col task-estimated-purchase-cell"
                                                                    data-bind="css: { 'col-md-4': IsWarehouseEnabled, 'col-md-6': !IsWarehouseEnabled() }"
                                                                >
                                                                    <span>Acquisti:</span>
                                                                    <span
                                                                        class="bold pull-right"
                                                                        data-bind="moneyText: EstimatedPurchasesTotalRevenues"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="row"
                                                                data-bind="visible: ShowSubTotalsDetails() && ShowSubTotals()"
                                                            >
                                                                <div class="col-md-12">
                                                                    <div class="row task-totals-row">
                                                                        <div
                                                                            class="col-md-6 task-estimated-work-cell details"
                                                                            data-bind="foreach: UserCharactersDetails"
                                                                        >
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <span data-bind="text: Description"></span>
                                                                                    :
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-6 bold text-right"
                                                                                    data-bind="moneyText: Price"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-md-6 task-estimated-purchase-cell details"
                                                                            data-bind="foreach: PurchasesTypesDetails"
                                                                        >
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <span data-bind="text: Description"></span>
                                                                                    :
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-6 bold text-right"
                                                                                    data-bind="moneyText: Price"
                                                                                ></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="portlet light bordered task-section" data-bind="scrollIntoView: $parent.ResourcesScroller">
                    <div class="portlet-title" data-bind="click: $parent.switchResourceOpenedState.bind($parent)">
                        <div class="caption bold" style="color: #c56565">
                            <i class="fa fa-users"></i>
                            Risorse
                        </div>

                        <div class="tools">
                            <a
                                href="javascript:void(0)"
                                class="collapse"
                                data-bind="css: { collapse: $parent.ResourcesOpened, expand: !$parent.ResourcesOpened() }, click: $parent.switchResourceOpenedState.bind($parent), clickBubble: false"
                            ></a>
                        </div>
                    </div>

                    <div class="portlet-body" data-bind="visible: $parent.ResourcesOpened">
                        <div class="row" style="margin-bottom: 10px">
                            <div class="col-md-8">
                                <label class="control-label">Risorse</label>
                                <ko-bind data-bind={{ with: taskOnEdit.Resources }}>
                                    <input
                                        class="form-control default"
                                        type="hidden"
                                        data-bind="value: Resources, select2: { ajax : { quietMillis : 500 }, query : resourcesProvider.findEntities.bind(resourcesProvider), initSelection: resourcesProvider.findEntity.bind(resourcesProvider), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1 }"
                                    />
                                </ko-bind>
                            </div>

                            <div class="col-md-4">
                                <label class="control-label">Responsabile</label>
                                <input
                                    class="form-control default"
                                    type="hidden"
                                    data-bind="value: ResponsibleTagsManager.TagsValue, select2: { query : ResponsibleTagsManager.SearchTags.bind(ResponsibleTagsManager), initSelection: ResponsibleTagsManager.GetStartTagsForListControl.bind(ResponsibleTagsManager), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1, maximumSelectionSize: 1 }"
                                />
                            </div>
                        </div>

                        {this.canStartAllocations && (
                            <ko-bind data-bind={{ ifnot: taskOnEdit.IsTemplate }}>
                                <div
                                    class="row allocations-info"
                                    style="margin-bottom: 10px"
                                    data-bind="visible: ShowAllocationsInfo"
                                >
                                    <div class="col-md-8">
                                        <label>Pianificazione</label>
                                        <button
                                            class="btn btn-primary btn-xs pull-right"
                                            data-bind="click: DeallocateTaskFromCart, visible: CanDeallocateFromCart"
                                        >
                                            <i class="fa fa-trash-o"></i>
                                            Dealloca
                                        </button>
                                        <table class="table table-condensed">
                                            <thead>
                                                <tr>
                                                    <th style="width: 30%">Team</th>
                                                    <th style="width: 30%">Carrello</th>
                                                    <th style="width: 20%">Inizio</th>
                                                    <th style="width: 20%">Fine</th>
                                                </tr>
                                            </thead>
                                            <tbody data-bind="foreach: AllocationInfo">
                                                <tr>
                                                    <td data-bind="text: TeamName ? TeamName : 'N/A'"></td>
                                                    <td data-bind="text: CartName ? CartName : 'N/A'"></td>
                                                    <td data-bind="dateText: AllocationStartDate, dateTextNoValue: 'N/A'"></td>
                                                    <td data-bind="dateText: RealAllocationEndDate, dateTextNoValue: 'N/A'"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Risorse Pianificate</label>
                                        <div class="task-planned-resources" data-bind="slimScroll">
                                            <ko-bind data-bind={{ foreach: taskOnEdit.PlannedResources }}>
                                                <span class="label label-primary" data-bind="text: ResourceName"></span>
                                            </ko-bind>
                                        </div>
                                    </div>
                                </div>
                            </ko-bind>
                        )}
                    </div>
                </div>

                {this.canViewHourReportingSection && (
                    <div
                        class="portlet light bordered task-section"
                        data-bind={{ scrollIntoView: taskEditor.WorkedHoursDefaultsScroller }}
                    >
                        <div
                            class="portlet-title"
                            data-bind={{ click: taskEditor.switchWorkedHoursDefaultsOpenedState.bind(taskEditor) }}
                        >
                            <div class="caption bold" style={{ color: "#c56565" }}>
                                <i class="fa fa-clock-o"></i>
                                Rendicontazione ore
                            </div>

                            <div class="tools">
                                <a
                                    href="javascript:void(0)"
                                    class="collapse"
                                    data-bind={{
                                        css: {
                                            collapse: taskEditor.WorkedHoursDefaultsOpened,
                                            expand: !taskEditor.WorkedHoursDefaultsOpened(),
                                        },
                                        click: taskEditor.switchWorkedHoursDefaultsOpenedState.bind(taskEditor),
                                        clickBubble: false,
                                    }}
                                ></a>
                            </div>
                        </div>

                        <div class="portlet-body" data-bind={{ visible: taskEditor.WorkedHoursDefaultsOpened }}>
                            <WorkedHoursDefaultsEditor
                                hideTitle
                                reportingType={this.TaskOnEdit().ReportingType}
                                workPlace={this.TaskOnEdit().WorkedHoursDefaultPlace}
                                travelDistance={this.TaskOnEdit().WorkedHoursDefaultTravelDistance}
                                forceDataOnWorkedHours={this.TaskOnEdit().WorkedHoursDefaultsStrictMode}
                                ddc={this.TaskOnEdit().DDC}
                                hideAdministrativeDataOnWorkedHours={
                                    this.TaskOnEdit().HideAdministrativeDataOnWorkedHours
                                }
                                defaultRoles={this.TaskOnEdit().WorkedHoursDefaultRoles}
                                defaultWorkTimeCategories={this.TaskOnEdit().WorkedHoursDefaultWorkTimeCategories}
                            />
                        </div>
                    </div>
                )}

                <div
                    class="portlet light bordered task-section"
                    data-bind="scrollIntoView: $parent.AdvancedSettingsScroller"
                    style="margin-bottom: 5px"
                >
                    <div
                        class="portlet-title"
                        data-bind="click: $parent.switchAdvancedSettingsOpenedState.bind($parent)"
                    >
                        <div class="caption bold" style="color: #b5974b">
                            <i class="fa fa-cogs"></i>
                            Avanzate
                        </div>

                        <div class="tools">
                            <a
                                href="javascript:void(0)"
                                class="collapse"
                                data-bind="css: { collapse: $parent.AdvancedSettingsOpened, expand: !$parent.AdvancedSettingsOpened() }, click: $parent.switchAdvancedSettingsOpenedState.bind($parent), clickBubble: false"
                            ></a>
                        </div>
                    </div>

                    <div class="portlet-body" data-bind="visible: $parent.AdvancedSettingsOpened">
                        <div class="row" style="margin-bottom: 10px">
                            <div class="col-md-12">
                                <label class="control-label">Etichette</label>
                                <input
                                    class="form-control default"
                                    type="hidden"
                                    data-bind="value: CustomTagsManager.TagsValue, select2: { dropdownCssClass: 'select2-result', query : CustomTagsManager.SearchTags.bind(CustomTagsManager), initSelection: CustomTagsManager.GetStartTagsForListControl.bind(CustomTagsManager), allowClear: true, placeholder: 'Aggiungi una etichetta all\'attività...', multiple: true, minimumInputLength: 1 }"
                                />
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px">
                            <div class="col-md-6" style="background-image: url('./Content/img/task-flags-back.png')">
                                <div class="row">
                                    <div class="col-md-12 form-group" style="padding-top: 15px">
                                        <label class="control-label" style="width: 50%">
                                            Commerciale
                                        </label>
                                        <input
                                            class=""
                                            data-bind="onOffSwitch : ForCommercial, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                            type="checkbox"
                                            checked={false}
                                            data-on-text="Si"
                                            data-off-text="No"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <label class="control-label" style="width: 50%">
                                            Amministrazione
                                        </label>
                                        <input
                                            class=""
                                            data-bind="onOffSwitch : ForAdministration, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                            type="checkbox"
                                            checked={false}
                                            data-on-text="Si"
                                            data-off-text="No"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <label class="control-label" style="width: 50%">
                                            Tecnico
                                        </label>
                                        <input
                                            class=""
                                            data-bind="onOffSwitch : ForTechnical, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                            type="checkbox"
                                            checked={false}
                                            data-on-text="Si"
                                            data-off-text="No"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <label class="control-label" style="width: 50%">
                                            Facoltativo
                                        </label>
                                        <input
                                            class=""
                                            data-bind="onOffSwitch : Optional, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                            type="checkbox"
                                            checked={false}
                                            data-on-text="Si"
                                            data-off-text="No"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <label class="control-label" style="width: 50%">
                                            In qualit&agrave;
                                        </label>
                                        <input
                                            class=""
                                            data-bind="onOffSwitch : InQuality, switchSize: 'small', event : { keyup : $parent.SaveChangesIfEnterIsPressed.bind($parent) }"
                                            type="checkbox"
                                            checked={false}
                                            data-on-text="Si"
                                            data-off-text="No"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ko-bind data-bind={{ if: taskEditor.ShowSourceTemplateFields() && !taskOnEdit.IsTemplate }}>
                            <div class="row">
                                <div class="col-md-6">
                                    <select2 params="Value: SourceTemplateRelatedWorkflowId, DataSource: TemplatesDataSource, Label: 'Piano template', Placeholder: 'Seleziona...', AllowClear: true, ReadOnly: !CanEditSourceTemplate, Listener: $data"></select2>
                                </div>
                                <div class="col-md-6">
                                    <select2 params="Value: SourceTemplateTaskId, DataSource: TemplateTasksDataSource, Label: 'Attività template', Placeholder: 'Seleziona...', AllowClear: true, ReadOnly: !CanEditSourceTemplate, Listener: $data"></select2>
                                </div>
                            </div>
                        </ko-bind>
                    </div>
                </div>
            </div>
        );
    }

    private renderActivitiesListItem(item: ListItem<number, ITaskForTaskBoard>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const taskEditor = this;
        const menuModel: ITasksDataSourceModel = null;
        const iconInfo: INavigationMenuComponentAlert = null;
        const alertIcon: IDataSourceIcon = null;

        return (
            <>
                <div class="list-notification-item-title">
                    <ko-bind data-bind={{ if: !!item.Icon() }}>
                        <i
                            class="list-notification-item-title-icon"
                            data-bind={{
                                css: item.Icon,
                                style: { "background-color": item.Background, color: item.Foreground },
                            }}
                        ></i>
                    </ko-bind>
                    <i
                        class="list-notification-item-title-icon fa fa-star bg-red"
                        data-bind={{ visible: item.Model.IsMilestone }}
                    ></i>
                    <i
                        class="list-notification-item-title-icon fa fa-shopping-cart bg-yellow"
                        data-bind={{ visible: item.Model.IsAllocated }}
                    ></i>
                    <span class="list-notification-item-title-text" data-bind={{ text: item.Title }}></span>
                    <div class="list-notification-item-title-actions">
                        <ko-bind data-bind={{ if: item.Model.IsTask }}>
                            <div
                                class="list-notification-item-title-alerts"
                                data-bind={{ with: item.dataSourceModel, as: "menuModel" }}
                            >
                                <span
                                    class="list-notification-item-title-alerts-label"
                                    data-bind={{
                                        text: menuModel.alerts.label,
                                        visible: menuModel.alerts.icons.length > 0,
                                    }}
                                ></span>
                                <ko-bind data-bind={{ foreach: { data: menuModel.alerts.icons, as: "iconInfo" } }}>
                                    <div
                                        class="btn btn-default btn-xs"
                                        data-bind={{
                                            attr: { title: iconInfo.tooltip },
                                            with: iconInfo.icon,
                                            as: "alertIcon",
                                        }}
                                    >
                                        <i
                                            data-bind={{
                                                css: alertIcon.icon,
                                                style: {
                                                    backgroundColor: alertIcon.background,
                                                    color: alertIcon.foreground,
                                                },
                                            }}
                                        ></i>
                                    </div>
                                </ko-bind>
                            </div>
                        </ko-bind>
                        <a
                            href="#"
                            class="btn pull-right btn-primary btn-xs"
                            data-bind={{ click: taskEditor.ShowDetails.bind(taskEditor, item.Model) }}
                            style="margin-right: 10px"
                        >
                            <i class="fa fa-search"></i>
                            Dettagli
                        </a>
                        <a
                            href="#"
                            class="btn pull-right btn-primary btn-xs"
                            data-bind={{
                                click: taskEditor.OpenBlog.bind(taskEditor, item.Model),
                                visible: item.Model.IsTask,
                            }}
                            style="margin-right: 10px"
                        >
                            <i class="fa fa-comments-o"></i>
                            Appunti&nbsp;(<ko-bind data-bind={{ text: item.Model.BlogEventsCount }}></ko-bind>)
                        </a>
                    </div>
                </div>
                <div class="list-notification-item-time" data-bind={{ visible: taskEditor.DetailedMode }}>
                    <ko-bind data-bind={{ if: !!item.Model.Description }}>
                        <div
                            class="list-notification-item-description well"
                            data-bind={{ html: item.Model.Description }}
                        ></div>
                    </ko-bind>
                    <ko-bind data-bind={{ if: item.Model.IsTask !== undefined }}>
                        <table class="table table-condensed">
                            <thead>
                                <tr>
                                    <th>Creato il</th>
                                    <th>Inizio</th>
                                    <th>Fine</th>
                                    <th>Stima Lavoro</th>
                                    <th>Lavorato</th>
                                    <th>Stima Acquisti</th>
                                    <th>Acquisti</th>
                                    <th>Stima Magazzino</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-bind={{ dateText: item.Model.CreationDate }}></td>
                                    <td data-bind={{ dateText: item.Model.StartDate }}></td>
                                    <td data-bind={{ dateText: item.Model.ExpireDate }}></td>
                                    <td
                                        data-bind={{
                                            numberText: item.Model.EstimatedDuration,
                                            numberTextSuffix: "ore",
                                        }}
                                    ></td>
                                    <td
                                        data-bind={{
                                            numberText: item.Model.WorkedHours,
                                            numberTextSuffix: "ore",
                                            css: { "font-red": item.Model.WorkedHours > item.Model.EstimatedDuration },
                                        }}
                                    ></td>
                                    <td data-bind={{ moneyText: item.Model.EstimatedPurchasesCosts }}></td>
                                    <td
                                        data-bind={{
                                            moneyText: item.Model.PurchasesCosts,
                                            css: {
                                                "font-red":
                                                    item.Model.PurchasesCosts > item.Model.EstimatedPurchasesCosts,
                                            },
                                        }}
                                    ></td>
                                    <td data-bind={{ moneyText: item.Model.EstimatedWarehouseCosts }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </ko-bind>
                    <ko-bind data-bind={{ if: item.Model.IsTask === undefined }}>
                        <table class="table table-condensed">
                            <thead>
                                <tr>
                                    <th>Stima Lavoro</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        data-bind={{
                                            numberText: item.Model.EstimatedDuration,
                                            numberTextSuffix: "ore",
                                        }}
                                    ></td>
                                </tr>
                            </tbody>
                        </table>
                    </ko-bind>
                </div>
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ActivityDetailsDialog);
}
