import * as ko from "knockout";
import moment = require("moment");
import { IMonthlyHour } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IHoursContainer } from "./IHoursContainer";
import { ISelectableHour } from "./ISelectableHour";
import { _HoursApprovalPanelViewModel } from "./HoursApprovalPanelViewModel";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { ISelectableDate } from "./IDate";

import { HoursApprovalStateUtilities } from "./HoursApprovalStateUtilities";

export type HoursApproval = {
    id: number;
    date: Date;
    isHoliday: boolean,
    resourceId;
}

export class HourApprovalViewModel implements ISelectableHour {
    public Id: number;
    public Date: Date;
    public StartOfDay: Date;
    public EndOfDay: Date;
    public Day: number;
    public Hours: ko.ObservableArray<IMonthlyHour> = ko.observableArray([]);
    
    public Amount: ko.Computed<number>;
    public HasOvertime: ko.Computed<boolean>;
    public IsApproved: ko.Computed<boolean>;
    public IsHoliday: boolean;
    public HasHours: ko.Computed<boolean>;

    public HasApprovedHours: ko.Computed<boolean>;
    public HasRejectedHours: ko.Computed<boolean>;
    public HasUnmanagedHours: ko.Computed<boolean>;

    public Selected: ko.Computed<boolean>;
    public SelectedByColumn: ko.Computed<boolean>;
    public SelectedByRow: ko.Computed<boolean>;

    private resourceId: number;
    
    constructor(hourApproval: HoursApproval, protected date: ISelectableDate, protected row: IHoursContainer, protected panel: _HoursApprovalPanelViewModel) {
        this.Id = hourApproval.id;
        this.Date = moment(hourApproval.date).startOf('day').toDate();
        this.StartOfDay = moment(hourApproval.date).startOf('day').toDate();
        this.EndOfDay = moment(hourApproval.date).endOf('day').toDate();
        this.Day = moment(hourApproval.date).date();
        this.IsHoliday = hourApproval.isHoliday;

        this.resourceId = hourApproval.resourceId;
    }

    public initialize(): void {
        this.HasHours = ko.computed(() => this.Hours().length != 0);

        this.Amount = ko.computed(() => {
            const hours = this.Hours();
            let total = 0;

            hours.forEach(h => total += h.Hours);

            return total;
        });

        this.HasOvertime = ko.computed(() => {
            const hours = this.Hours();
            let hasOvertime = false;

            hours.forEach(h => hasOvertime = hasOvertime || h.IsOvertime);

            return hasOvertime;
        });

        this.IsApproved = ko.computed(() => {
            const hours = this.Hours();
            let isApproved = true;

            hours.forEach(h => isApproved = isApproved && h.ApprovalState === HoursApprovalState.Approved);

            return isApproved;
        });

        this.HasApprovedHours = ko.computed(() => {
            const hours = this.Hours();

            return hours.filter((h) => h.ApprovalState === HoursApprovalState.Approved).length > 0;
        });

        this.HasRejectedHours = ko.computed(() => {
            const hours = this.Hours();

            return hours.filter((h) => h.ApprovalState === HoursApprovalState.Rejected).length > 0;
        });

        this.HasUnmanagedHours = ko.computed(() => {
            const hours = this.Hours();

            return hours.filter((h) => HoursApprovalStateUtilities.isUnmanagedWorkedHoursRow(h.ApprovalState)).length > 0;
        });

        this.SelectedByRow = ko.computed(() => {
            return this.row.Selected();
        });
        
        this.SelectedByColumn = ko.computed(() => {
            return this.date.Selected();
        });

        this.Selected = ko.computed(() => {
            return this.SelectedByRow() && this.SelectedByColumn();
        });
    }

    public addHour(hour: IMonthlyHour): void {
        this.Hours.push(hour);
    }

    public showWorkedHoursEditorDialog(): void {
        const hours = this.Hours();

        // const hoursId = hours.length === 1 ? hours[0].HoursId : null;
        const hoursId = null;
        
        const jobOrders = hours.filter(h => !!h.JobOrderId).map(h => h.JobOrderId).distinct();
        const jobOrderId = jobOrders.length === 1 ? jobOrders[0] : null;

        const tasks = hours.filter(h => !!h.TaskId).map(h => h.TaskId).distinct();
        const taskId = tasks.length === 1 ? tasks[0] : null;
        
        this.panel.showWorkedHoursEditorDialog(this.resourceId, this.StartOfDay, jobOrderId, taskId, hoursId);
    }
}
