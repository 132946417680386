import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow, ComponentUtils, useEffect } from "../../../Core/utils/ComponentUtils";
import { Layout } from "../../../Components/Layouts";
import { Portlet } from "../../../Components/Portlet";
import { BackButton } from "../../../Components/BackButton";
import { List } from "../../../Components/ListComponent";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader } from "../../../Components/TableComponent/CustomColumn";
import { QuotationBillOfMaterialsEditor } from "./QuotationBillOfMaterialsEditor";
import { QuotationBillOfMaterialsList } from "./QuotationBillOfMaterialsList";
import { IQuotationBillOfMaterials, IQuotationService } from "../../QuotationService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type QuotationBillOfMaterialsProps = {
    quotationId: ko.Observable<number> | ko.Computed<number> | (() => number);
    customerId: number;
    jobOrderId: ko.Observable<number>;
    onClose: () => void;
}

export function QuotationBillOfMaterials(props: QuotationBillOfMaterialsProps) {
    const C = require("./QuotationBillOfMaterials")._QuotationBillOfMaterials as typeof _QuotationBillOfMaterials;
    return <C {...props} />;
}

export class _QuotationBillOfMaterials extends React.Component<QuotationBillOfMaterialsProps> {
    static defaultProps: Partial<QuotationBillOfMaterialsProps> = {
    }

    selectedBillOfMaterials : ko.Observable<IQuotationBillOfMaterials> = ko.observable();
    billsOfMaterials : ko.ObservableArray<IQuotationBillOfMaterials> = ko.observableArray();

    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IQuotationService>())
    private quotationService : IQuotationService;

    componentDidMount() {
        useEffect(() => {
            if(!this.props.quotationId()) return;

            const loadBillsOfMaterials = async () => {
                this.billsOfMaterials(await this.quotationService.getBillsOfMaterials(this.props.quotationId()));
                this.newBOM();
            };

            loadBillsOfMaterials();
        }, [this.props.quotationId])
        
    }

    private goBack() {
        this.props.onClose();
    }

    private newBOM() {
        this.selectedBillOfMaterials({
            Id: this.idGeneratorService.getNextId(),
            FKQuotation: this.props.quotationId(),
            Name: "",
            Description: "",
            Rows: []
        })
    }
    
    render() {
        const onAddOrUpdateBOM = async (b : IQuotationBillOfMaterials) => {
            const newBillOfMaterials = await this.quotationService.createOrUpdateBillOfMaterials(b)
            const existingCategory = this.billsOfMaterials().find(ec => ec.Id === newBillOfMaterials.Id);
            if(existingCategory) {
                this.billsOfMaterials.replace(existingCategory, newBillOfMaterials);
                this.selectedBillOfMaterials(newBillOfMaterials);
            } else {
                this.billsOfMaterials.push(newBillOfMaterials);
                this.newBOM();
            }
        };

        const onRemoveBOM = async (b : IQuotationBillOfMaterials) => {
            await this.quotationService.removeCategory(b.Id);
            const existingBillOfMaterials = this.billsOfMaterials().find(ec => ec.Id === b.Id);
            if(existingBillOfMaterials)
                this.billsOfMaterials.remove(existingBillOfMaterials);
        };

        return  <Layout.Grid columns={["300px", "3fr"]} rows={["auto", "10fr"]} autoSizeMode="max">
                    <Layout.Grid.Cell column={"1/3"} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <BackButton onClick={() => this.goBack()} style={{ marginRight: 20 }} />
                                <Portlet.Header.Default title="Distinte Base" className="font-red-sunglo bold uppercase" />
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <QuotationBillOfMaterialsList billsOfMaterials={this.billsOfMaterials} onNew={() => this.newBOM()} onBillOfMaterialsSelected={(b) => this.selectedBillOfMaterials(b)} selectedBillOfMaterials={this.selectedBillOfMaterials} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={2}>
                        <QuotationBillOfMaterialsEditor billOfMaterials={this.selectedBillOfMaterials} customerId={this.props.customerId} jobOrderId={this.props.jobOrderId} onSave={onAddOrUpdateBOM} onDelete={onRemoveBOM} />
                    </Layout.Grid.Cell>
                </Layout.Grid>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(QuotationBillOfMaterials);
}