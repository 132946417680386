import * as ko from "knockout";
import * as numeral from "numeral";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import jss from "jss";
import DocumentHeader from "./DocumentHeader";
import "./DocumentActions";
import "./DocumentRows";
import "./DocumentLayoutSelection";
import "../../../ProlifeSdk/prolifesdk/documents/DocumentCurrenciesPopOver";
import "../../../Components/WorkflowOutcomeChangesAlert";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ComponentParam, Param } from "../../../Core/utils/ComponentUtils";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { DocumentInformationReadOnly, DocumentInformationNonReadOnly } from "./DocumentInformation";
import { DocumentSecondaryRecipients, DocumentSecondaryRecipientsReadOnly } from "./DocumentSecondaryRecipients";
import { DocumentTotals } from "./DocumentTotals";
import { DocumentVatRows } from "./DocumentVatRows";
import {
    DocumentAdditionaInformationReadOnly,
    DocumentAdditionaInformationNonReadOnly,
    DocumentNotesNonReadOnly,
    DocumentNotesReadOnly,
} from "./DocumentAdditionalInformation";
import { DocumentShippingInformation } from "./DocumentShippingInformation";
import { DocumentAttachmentsReadOnly, DocumentAttachmentsNonReadOnly } from "./DocumentAttachments";
import { DocumentFooter } from "./DocumentFooter";
import { DocumentRecipientInfo } from "./DocumentCustomer";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DocumentsJournalDialog } from "../../../ProlifeSdk/prolifesdk/ui/DocumentsJournalDialog";
import { PaymentModesDataSource } from "../../../DataSources/PaymentModesDataSource";
import { CompanyIBANDataSource, ICompanyIBANDataSourceModel } from "../../../DataSources/CompanyIBANDataSource";
import { CustomerABIDataSource, CustomerABIDataSourceModel } from "../../../DataSources/CustomerABIDataSource";
import { CustomerCABDataSource, CustomerCABDataSourceModel } from "../../../DataSources/CustomerCABDataSource";
import { ExpiriesDataSource, IExpiriesDataSourceModel } from "../../../DataSources/ExpiriesDataSource";
import { JobOrdersDataSource, IJobOrderDataSourceModel } from "../../../DataSources/JobOrdersDataSource";
import { ResourcesDataSource } from "../../../DataSources/ResourcesDataSource";
import { WorkflowOutcomesDataSource } from "../../../DataSources/WorkflowOutcomesDataSource";
import {
    WarehousesDocumentDataSource,
    IWarehouseDataSourceModel,
} from "../../../DataSources/WarehousesDocumentDataSource";
import { ReasonForPaymentDataSource } from "../../../DataSources/ReasonForPaymentDataSource";
import { Attachment } from "../../../ProlifeSdk/prolifesdk/blog/Attachment";
import { AttachmentsManager } from "../../../FileRepository/FileRepository/attachments/AttachmentsManager";
import { DDTCausesDataSource, IDDTCausesDataSourceModel } from "../../../DataSources/DDTCausesDataSource";
import { AspectsDataSource } from "../../../DataSources/AspectsDataSource";
import { TransportsDataSource } from "../../../DataSources/TransportsDataSource";
import { CarriagesDataSource as CarriagesDataSource } from "../../../DataSources/PortsDataSource";
import { IDocumentAction } from "./DocumentAction";
import { IDocumentRowHelperListener } from "../../../Bindings/DocumentRowHelper";
import { DocumentRow } from "./DocumentRows";
import { CurrencyUtils } from "../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { DocumentVatRow } from "./DocumentVatRow";
import {
    IDocumentRowInlineReferenceProvider,
    IDocumentRowInlineReferenceProviderFactory,
    IDocumentInfoForInlineRefProvider,
} from "../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import {
    IDocumentsService,
    IDocumentBuilderDocumentWithOptions,
    IDefaultMetadatas,
    IRowRefMapping,
    SentMail,
} from "../../DocumentsService";
import { IAliasesService } from "../../../Warehouse/AliasesService";
import { ImportDocumentDataWizard, IDocumentDataWizardRow } from "./wizard/ImportDocumentDataWizard";
import {
    NumberPrintChoicesProvider as NumberPrintChoicesProvider,
    IDocumentNumberPrintChoice,
} from "../ui/documents/utils/NumberPrintChoicesProvider";
import {
    DocumentVersionNumberGenerationModesProvider,
    IVersionNumberGenerationMode,
} from "../ui/documents/utils/DocumentVersionNumberGenerationModesProvider";
import { DocumentVersionNumbersDataSource } from "../../../DataSources/DocumentVersionNumbersDataSource";
import { CustomerGroupSelectorDialog } from "../../../ProlifeSdk/prolifesdk/documents/CustomerGroupSelectorDialog";
import { ICustomerGroup } from "../../../Customers/CustomersGroupsService";
import { SchedulesRebuildRequestDialog } from "../ui/dialogs/SchedulesRebuildRequestDialog";
import { ITrustAuthorizationProcessService } from "../../TrustAuthorizationProcessService";
import { OnWorkflowOutcomeChangesActions } from "../../../Todolist/Todolist/enums/OnWorkflowOutcomeChangesActions";
import { WarehouseMovementsChangesAlert } from "../../../ProlifeSdk/prolifesdk/WarehouseMovementsChangesAlert";
import { SecondaryRecipient } from "../../../ProlifeSdk/prolifesdk/documents/SecondaryRecipient";
import { RelatedDocumentInfo } from "./RelatedDocumentInfo";
import { EstimateStatesDataSource } from "../../../DataSources/EstimateStatesDataSource";
import { Configurations } from "./configurations/Index";
import { ReferencesMapViewer } from "../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/ReferencesMapViewer";
import { ApproveDocumentDialog } from "../../../ProlifeSdk/prolifesdk/documents/ApproveDocumentDialog";
import { CreateNewArticleDialog } from "../../../Warehouse/warehouse/ui/Articles/CreateNewArticleDialog";
import { PaymentInfoFromImportsDialog } from "../../../ProlifeSdk/prolifesdk/PaymentInfoFromImportsDialog";
import { DocumentActions } from "./DocumentActions";
import { DocumentsViewer } from "./DocumentsViewer";
import { Task } from "../../../ProlifeSdk/prolifesdk/utils/Task";
import { Right } from "../../../Core/Authorizations";
import { ElectronicInvoiceTypesDataSource } from "../../../DataSources/ElectronicInvoiceTypesDataSource";
import { DocumentVersionNumberGenerationModes } from "../enums/DocumentVersionNumberGenerationModes";
import { DocumentNumberPrintChoices } from "../enums/DocumentNumberPrintChoices";
import { SchedulesRebuildOptions } from "../enums/ShedulesRebuildOptions";
import { IDocumentCurrenciesManagerListener } from "../../../ProlifeSdk/prolifesdk/documents/DocumentCurrenciesPopOver";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IInvoicesService } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IScheduleService } from "../../../ProlifeSdk/interfaces/schedule/IScheduleService";
import {
    IDocumentCurrencyViewModel,
    IDocumentCurrenciesEditorParams,
    IRowLeafReference,
    IEntityKey,
    IDocumentBuilderDocumentVatRows,
    IDocumentBuilderDocumentVatRowsExt,
    IDocumentBuilderDocument,
    IDocumentBuilderDocumentSchedules,
    IDocumentBuilderDocumentDocumentCurrencies,
    IDocumentBuilderDocumentRelatedWorkflows,
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRows,
    IDocumentBuilderDocumentSecondaryRecipients,
    IDocumentBuilderDocumentRelatedDocuments,
    IDocumentBuilderDocumentMetadatas,
    IProtocolDefaults,
    IDocumentBuilderDocumentTaxRelief,
} from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import {
    IEntityRefKey,
    IEntityRefKeyWithReferencingRowNumber,
} from "../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IDocumentPaymentInfo } from "../../../ProlifeSdk/interfaces/invoice/IRegisterDocument";
import { IWizardInitializationInfo } from "../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import {
    ITodoListService,
    IJobOrderForTaskBoard,
    IWorkflowForSelectList,
} from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    IConfirmSendMailValidationExceptionData,
    IException,
    INegativeStockValidationExceptionData,
    IValidationException,
    IWarehouseMovementChangesValidationException,
    IWorkflowOutcomeChangeValidationException,
} from "../../../Core/interfaces/IException";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import {
    WarehouseLoadReasonsForShipmentDataSource,
    IWarehouseLoadReasonForShipmentModel,
} from "../../../DataSources/WarehouseLoadReasonsForShipmentDataSource";
import { DocumentCause } from "./DocumentCause";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IDialogsService, IPopoverComponentInfo } from "../../../Core/interfaces/IDialogsService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ICustomersService, ILetterOfAttempt } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { IWarehouse } from "../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { IAttachmentsManagerPathProvider } from "../../../ProlifeSdk/interfaces/files/IAttachmentsManager";
import { IVatRegisters, IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IIvaModes, IIvaMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import { ICompanySettingsManager } from "../../../ProlifeSdk/interfaces/settings/ICompanySettingsManager";
import { IPaymentMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { IJobOrder } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { ICompany } from "../../../ProlifeSdk/interfaces/settings/ICompany";
import { IExpireMode } from "../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { IRecipientCode } from "../../../ProlifeSdk/interfaces/customer/IRecipientCode";
import { IDocumentsJournalDialogResult } from "../../../ProlifeSdk/interfaces/prolife-sdk/IDocumentsJournalRow";
import { IDDTCause } from "../../../ProlifeSdk/interfaces/invoice/settings/IDDTCauses";
import { IRelatedDocument } from "../../../ProlifeSdk/interfaces/invoice/IRelatedDocument";
import { IExercises } from "../../../ProlifeSdk/interfaces/invoice/settings/IExercises";
import { IValidator, IValidationService } from "../../../ProlifeSdk/ValidationService";
import { components } from "knockout";
import { Deferred } from "../../../Core/Deferred";
import {
    IWarehouseInspectionsService,
    IFullWarehouseInspection,
    IFullWarehouseInspectionWarehouseInspectionOperations,
    IFullWarehouseInspectionWarehouseInspectionOperationsSources,
} from "../../../Warehouse/WarehouseInspectionsService";
import { WarehouseInspectionStatus } from "../../../Warehouse/warehouse/ui/WarehouseInspections/Enums/WarehouseInspectionStatus";
import { WarehouseInspectionAction } from "../../../Warehouse/warehouse/ui/WarehouseInspections/Enums/WarehouseInspectionAction";
import { FullWarehouseInspectionFactory } from "../../../Warehouse/warehouse/ui/WarehouseInspections/FullWarehouseInspectionFactory";
import {
    DocumentMetadata,
    IDocumentMetadata,
} from "../../../ProlifeSdk/prolifesdk/documents/default-values/DocumentMetadata";
import { MetadataType } from "../enums/MetadataType";
import { DocumentMetadatas } from "./DocumentMetadatas";
import {
    DocumentMetadatasEditorUI,
    _DocumentMetadatasEditor,
} from "../../../ProlifeSdk/prolifesdk/documents/default-values/DocumentMetadatasEditor";
import { DocumentResource, DocumentResourcesUI, DocumentResourcesEditor } from "./DocumentResources";
import { SplashPageDetailsDialog } from "../../../JobOrder/jobOrder/ui/splash-page/SplashPageDetailsComponent";
import { DocumentMetadatasDataSource } from "../../../DataSources/DocumentMetadatasDataSource";
import { _ReportPrintButton } from "../../../Components/ReportDesigner/ReportPrintButton";
import { NegativeStockExceptionViewerDialog } from "./NegativeStockExceptionViewer";
import { DocumentValidityTypesDataSource } from "../../../DataSources/DocumentValidityTypesDataSource";
import {
    TaxReliefEditorUI,
    DocumentTaxRelief,
    IDocumentTaxRelief,
    DocumentTaxReliefEditor,
} from "./DocumentTaxReliefEditor";
import { CustomerIBANDataSource } from "../../../DataSources/CustomerIBANDataSource";
import { CompanyABICABDataSource } from "../../../DataSources/CompanyABICABDataSource";
import { DocumentLayouts } from "./DocumentLayouts";
import { DocumentFieldsReader, IDocumentFieldsReaderSource } from "./DocumentFieldsReader";
import { MailLogModal } from "./MailLogModal";
import { DocumentConfirmSendMailViewerDialog } from "./DocumentConfirmSendMailViewer";
import { SendMailAction } from "./SendMailAction";
import { _MailSendButton } from "./MailSendButton";
import { DocumentCauseLogicType } from "../enums/DocumentCauseLogicType";

const { classes } = jss
    .createStyleSheet({
        document: {
            display: "block",
            width: "21cm",
            position: "absolute",
            left: "50%",
            marginLeft: "-10.5cm",
            backgroundColor: "white",
            border: "1px solid black",
            fontSize: "12px",
            padding: "1cm 0.5cm",

            "&.shadow": {
                boxShadow: "0px 0px 60px black",

                "& .offsets-panel": {
                    boxShadow: "4px 0px 20px black",
                },
            },

            "& .documents-logo": {
                width: "8cm",
                height: "3cm",
                overflow: "hidden",

                "& .logo": {
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "8cm 3cm",
                    height: "100%",
                },
            },

            "& > .document-menu": {
                position: "absolute",
                top: "5px",
                left: "5px",

                "&.right-actions": {
                    left: "auto",
                    right: "5px",
                },
            },

            "& > .document-header-image": {
                width: "100%",
                height: "1cm",
                left: "0px",
                top: "0px",
            },

            "& > .invoice-header": {
                position: "relative",
            },

            "& > .invoice-informations": {
                marginBottom: "1cm",

                "& > .row": {
                    display: "block",
                    marginBottom: "0.2cm",
                    marginLeft: "0px",
                    marginRight: "0px",

                    "&.single-field": {
                        "& > .control-group": {
                            display: "block",
                        },
                    },

                    "&.striped": {
                        backgroundColor: "#F5F5F5",
                        backgroundImage:
                            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAB4CAIAAAAhVwZfAAACYElEQVR4nNXZu6rDQAxF0X0dgzuny/9/YQpXCbi4hSGEPJyxRtKcOb1gs1r93W636/WKaafT6XK52G5rNizLYj4+n8+OKeUb1nW1XY7jOE2Tb03J7vf7YD6e59kxpXzLshijGzKv62qMbsgMWKLbMmOLbsuMIbo5M4bo5swcjVZg5mi0AjOHokWYORQtwkx5tA4z5dE6zBRGSzFTGC3FTEm0GjMl0WrM/IwWZOZntCAz+9GazOxHazKzEy3LzE60LDPfopWZ+RatzMzHaHFmPkaLM/Merc/Me7Q+My/RXTDzEt0FM8/RvTDzHN0LM4/ojph5RHfEzBbdFzNbdF/MwNAdMzB0xwwM3TGP42j/I9ashnme5wbRlczTNDWIrmTG9pKrWT0z+dH1zCRHuzCTHO3CTGa0FzOZ0V7MpEU7MpMW7chMTrQvMznRvswkRLszkxDtzkx0dAQz0dERzIRGBzETGh3ETFx0HDNx0XHMBEWHMhMUHcpMRHQ0MxHR0cy4Rycw4x6dwIxvdA4zvtE5zDhGpzHjGJ3GjFd0JjNe0ZnMuEQnM+MSncxMfXQ+M/XR+cxURjdhpjK6CTM10a2YqYluxYw5uiEz5uiGzNii2zJji27LjCG6OTOG6ObMHI1WYOZotAIzh6JFmDkULcJMebQOM+XROswURksxUxgtxUxJtBozJdFqzPyMFmTmZ7QgM/vRmszsR2sysxMty8xOtCwz36KVmfkWrczMx2hxZj5GizPzHq3PzHu0PjMv0V0w8xLdBTPP0b0w8xzdCzOP6I6YeUR3xMwW3RczW3RfzMA/w/wdDvqNIGIAAAAASUVORK5CYII=)",
                        border: "1px solid lightgray",
                    },

                    "& .control-group": {
                        display: "inline-block",
                        marginBottom: "0px",

                        "& > span": {
                            marginLeft: "5px",
                        },

                        "& label": {
                            position: "relative",
                            fontWeight: "bold",
                            fontSize: "1.1em",
                            marginRight: "5px",
                            display: "inline-block",
                            width: "80px",
                            marginLeft: "20px",

                            "&.without-dot:before": {
                                content: "none",
                            },

                            "&:before": {
                                position: "absolute",
                                left: "-20px",
                                backgroundColor: "red",
                                width: "11px",
                                height: "11px",
                                display: "block",
                                content: "''",
                                top: "50%",
                                marginTop: "-5px",
                            },

                            "& input[type='checkbox']": {
                                position: "absolute",
                                left: "-20px",
                                top: "50%",
                                width: "11px",
                                height: "11px",
                                display: "block",
                                marginTop: "-6px",
                                backgroundColor: "white",
                                border: "1px solid #F00",
                                "-webkit-appearance": "none",
                            },

                            "&.invoice-number": {
                                width: "auto",
                                maxWidth: "250px",
                                whiteSpace: "nowrap",
                            },
                        },

                        "& select2": {
                            "-webkit-appearance": "none",
                            color: "#222",
                            border: "0px",
                            borderBottom: "1px solid #F90",
                            boxShadow: "none",
                            marginBottom: "0px",
                            padding: "0px",
                            minHeight: "12px",
                            fontSize: "12px",
                            borderRadius: "0px",
                            lineHeight: "21px",
                            marginLeft: "5px",
                            height: "27px !important",

                            "& .select2-container": {
                                border: "1px solid #F90",
                                borderColor: "#F90 !important",

                                "& .select2-choice": {
                                    border: "none !important",
                                    height: "25px",
                                    backgroundImage: "none",

                                    "& .select2-search-choice-close": {
                                        marginTop: "-2px",
                                    },

                                    "& .select2-arrow": {
                                        borderLeft: "1px solid #F90",

                                        "& > b": {
                                            marginTop: "-2px",
                                        },
                                    },
                                },
                            },
                        },

                        "& input[type='text']": {
                            "-webkit-appearance": "none",
                            color: "#222",
                            border: "0px",
                            borderBottom: "1px solid #F90",
                            boxShadow: "none",
                            marginBottom: "0px",
                            padding: "0px",
                            minHeight: "12px",
                            fontSize: "12px",
                            borderRadius: "0px",
                            lineHeight: "21px",
                            height: "21px",
                            marginLeft: "5px",
                        },
                    },

                    "&.document-payment-group": {
                        padding: "3px",
                        border: "1px solid #ddd",

                        "& legend": {
                            position: "relative",
                            top: "-14px",
                            backgroundColor: "white",
                            width: "80px",
                            fontWeight: "bold",
                            fontSize: "1.1em",
                            textDecoration: "none",
                            margin: "0px 0px 5px 0px",
                            border: "0px",
                        },
                    },
                },
            },

            "& > .document-expansible-area": {
                minHeight: "15cm",

                "& .metadata-selector, & .metadata-list-value-selector, & .prolife-select": {
                    border: "1px solid #F90",
                    borderColor: "#F90 !important",
                    height: "30px",

                    "& .placeholder": {
                        fontSize: "12px",
                    },

                    "& .dropdown-icon": {
                        lineHeight: "23px",
                    },

                    "& .selected-item": {
                        height: "24px",
                    },
                },

                "& > .invoice-rows": {
                    position: "relative",

                    "& .table": {
                        tableLayout: "auto",

                        "&.no-border td": {
                            borderTop: "none",
                        },

                        "& thead": {
                            backgroundColor: "#F2F2F2",
                        },
                    },

                    "& th, & td": {
                        verticalAlign: "bottom !important",
                        position: "relative",
                        fontSize: "12px",
                        whiteSpace: "nowrap",

                        "& .btn.btn-xs.btn-invoice": {
                            width: "12px",
                            height: "12px",
                            fontSize: "6px",
                            display: "inline",
                            marginTop: "-3px",

                            "& > i.fa": {
                                fontSize: "6px",
                                lineHeight: "8px",
                                marginLeft: "-2px",
                            },
                        },
                    },

                    "& input[type='text'], & textarea": {
                        "-webkit-appearance": "none",
                        color: "#222",
                        border: "0px",
                        borderBottom: "1px solid #F90",
                        boxShadow: "none",
                        marginBottom: "0px",
                        padding: "0px",
                        minHeight: "12px",
                        fontSize: "12px",
                        borderRadius: "0px",
                        lineHeight: "21px",
                        height: "21px",
                        marginLeft: "5px",
                        resize: "none !important",
                        overflowY: "hidden",
                        width: "100%",
                    },

                    "& select2": {
                        /*'-webkit-appearance': 'none',
                    color: '#222',
                    border: '0px',
                    borderBottom: '1px solid #F90',
                    boxShadow: 'none',
                    marginBottom: '0px',
                    padding: '0px',
                    minHeight: '12px',
                    fontSize: '12px',
                    borderRadius: '0px',
                    lineHeight: '21px',
                    marginLeft: '5px',
                    height: '27px !important',*/

                        "& .select2-container": {
                            //border: 'none',
                            borderColor: "#F90 !important",

                            "& .select2-choice": {
                                height: "25px",
                                backgroundImage: "none",

                                "& .select2-search-choice-close": {
                                    marginTop: "-2px",
                                },

                                "& .select2-arrow": {
                                    borderLeft: "1px solid #F90",

                                    "& > b": {
                                        marginTop: "-2px",
                                    },
                                },
                            },
                        },
                    },

                    "& .offsets-panel": {
                        width: "230px",
                        position: "absolute",
                        top: "-10px",
                        right: "-272px",
                        border: "1px solid lightgray",
                        padding: "10px",

                        "& .table": {
                            background: "white",
                            border: "1px solid lightgray",
                            padding: "10px",
                            margin: "0 !important",
                            tableLayout: "fixed",

                            "& tr th": {
                                padding: "4px 5px",
                            },

                            "& tr td": {
                                padding: "3px 5px",
                            },

                            "&.readonly": {
                                "& tr": {
                                    height: "27px",

                                    "& td": {
                                        height: "27px",
                                    },
                                },
                            },
                        },

                        "& .related-workflows-badge": {
                            position: "absolute",
                            bottom: "12px",
                            right: "-10px",

                            "& i": {
                                fontSize: "10px",
                            },
                        },

                        "& .btn-related-workflows": {
                            height: "15px",
                            minWidth: "15px",
                            padding: "0",
                            display: "inline-block",
                            marginLeft: "3px",
                            marginRight: "0",
                            fontSize: "8px",

                            "& i": {
                                fontSize: "10px",
                            },
                        },
                    },
                },

                "& .metadatas, & .resources, & .tax-relief": {
                    "&.row": {
                        marginRight: 0,
                        marginLeft: 0,
                        marginBottom: "1cm",
                        paddingTop: "15px",
                        paddingBottom: "15px",

                        "& h3": {
                            fontWeight: "bold",
                            fontSize: "1.1em",
                            margin: 0,
                        },

                        "& table": {
                            fontSize: "12px",
                            backgroundColor: "white",

                            "& th": {
                                verticalAlign: "middle",
                            },

                            "& td": {
                                "& > span": {
                                    fontSize: "12px",
                                },
                            },

                            "& tbody": {
                                "& tr": {
                                    "& td": {
                                        paddingTop: "2px",
                                        paddingBottom: "2px",

                                        "& .select2-container": {
                                            height: "30px !important",
                                            border: "1px solid #F90",
                                            borderColor: "#F90 !important",

                                            "& .select2-choice": {
                                                border: "none !important",
                                                height: "25px",

                                                "& .select2-search-choice-close": {
                                                    marginTop: "-2px",
                                                },

                                                "& .select2-arrow": {
                                                    borderLeft: "1px solid #F90",

                                                    "& .presentation": {
                                                        marginTop: "-2px",
                                                    },
                                                },
                                            },
                                        },

                                        "& input[type='text'], textarea, select, typeahead, select2": {
                                            "-webkit-appearance": "none",
                                            color: "#222",
                                            border: "0px",
                                            borderBottom: "1px solid #F90",
                                            boxShadow: "none",
                                            margin: "0px",
                                            padding: "0px 3px",
                                            minHeight: "28px",
                                            fontSize: "12px",
                                            borderRadius: "0px",
                                            lineHeight: "28px",
                                            height: "28px",
                                        },

                                        "& .input-icon": {
                                            "&.right": {
                                                "& > i": {
                                                    right: "0px",
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },

                "& .metadatas": {
                    "&.row": {
                        "& > div": {
                            padding: 0,
                        },
                    },

                    "& .row-selector": {
                        "& input[type=checkbox]": {
                            width: "11px",
                            height: "11px",
                            marginTop: "-5px",
                            backgroundColor: "white",
                            border: "1px solid #F00",
                            "-webkit-appearance": "none",
                        },
                    },
                },
            },

            "& .invoice-taxes-summary": {
                marginTop: "1cm",
                marginBottom: "1cm",

                "& thead": {
                    backgroundColor: "#F2F2F2",
                },

                "& th, & td": {
                    verticalAlign: "bottom !important",
                    position: "relative",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                },
            },

            "& .invoice-payment-info": {
                "& .payment-mode-info": {
                    "& .row-span": {
                        display: "block",
                    },
                },

                "& input[type='checkbox']": {
                    width: "11px",
                    height: "11px",
                    display: "inline-block",
                    marginTop: "0px",
                    marginTight: "10px",
                    backgroundColor: "white",
                    border: "1px solid #F00",
                    "-webkit-appearance": "none",
                },
            },

            "& .invoice-totals": {
                marginBottom: "1cm",

                "& .row": {
                    textAlign: "right",
                    display: "block",
                    marginBottom: "0.2cm",
                    marginLeft: "0px",
                    marginRight: "0px",

                    "& .control-group": {
                        display: "block",
                        marginBottom: "0px",

                        "& > label": {
                            width: "300px",
                            textAlign: "right",
                            position: "relative",
                            fontWeight: "bold",
                            fontSize: "1.1em",
                            marginRight: "5px",
                            display: "inline-block",
                            marginLeft: "20px",
                        },

                        "& > span": {
                            display: "inline-block",
                            width: "100px",
                            textAlign: "right",
                            marginLeft: "5px",
                        },
                    },
                },
            },

            "& .invoice-shipping-informations": {
                marginTop: "1cm",

                "& th, & td": {
                    verticalAlign: "bottom !important",
                    position: "relative",
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                },

                "& select2": {
                    "-webkit-appearance": "none",
                    color: "#222",
                    border: "0px",
                    borderBottom: "1px solid #F90",
                    boxShadow: "none",
                    marginBottom: "0px",
                    padding: "0px",
                    minHeight: "12px",
                    fontSize: "12px",
                    borderRadius: "0px",
                    lineHeight: "21px",
                    marginLeft: "5px",
                    width: "100%",
                    height: "27px !important",

                    "& .select2-container": {
                        border: "1px solid #F90",
                        borderColor: "#F90 !important",
                        width: "100%",

                        "& .select2-choice": {
                            border: "none !important",
                            height: "25px",
                            backgroundImage: "none",

                            "& .select2-search-choice-close": {
                                marginTop: "-2px",
                            },

                            "& .select2-arrow": {
                                borderLeft: "1px solid #F90",

                                "& > b": {
                                    marginTop: "-2px",
                                },
                            },
                        },
                    },
                },

                "& input[type='text']": {
                    "-webkit-appearance": "none",
                    color: "#222",
                    border: "0px",
                    borderBottom: "1px solid #F90",
                    boxShadow: "none",
                    marginBottom: "0px",
                    padding: "0px",
                    minHeight: "12px",
                    fontSize: "12px",
                    borderRadius: "0px",
                    lineHeight: "21px",
                    height: "21px",
                    marginLeft: "5px",
                },
            },

            "& input[type='text'].document-control, .select2-container.document-control": {
                "-webkit-appearance": "none",
                color: "#222",
                border: "0px",
                borderBottom: "1px solid #F90",
                boxShadow: "none",
                marginBottom: "0px",
                padding: "0px",
                minHeight: "12px",
                fontSize: "12px",
                borderRadius: "0px",
                lineHeight: "21px",
                height: "21px",
                width: "100%",

                "&.no-arrow": {
                    "& .select2-chosen": {
                        marginRight: 0,
                    },

                    "& .select2-arrow": {
                        right: 0,

                        "& > b": {
                            marginTop: "-2px",
                        },
                    },
                },
            },

            "& .select2-container.document-control": {
                "& .select2-choice": {
                    border: "0px",
                    height: "22px",
                    padding: "0px",
                    backgroundColor: "transparent",

                    "& .select2-search-choice-close": {
                        top: "6px",
                        right: "0px",
                    },
                },
            },
        },
    })
    .attach();

const attributes = {
    FKRegister: "fkRegister",
    DocumentId: "documentId",
    DocumentType: "documentType",
    FKJobOrder: "fkJobOrder",
    Viewer: "viewer",
    InjectTo: "injectTo",
    Listener: "listener",
    Shadow: "shadow",
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            document: {
                params?: {
                    FKRegister: string;
                    DocumentId: string;
                    DocumentType: string;
                    FKJobOrder: string;
                    Viewer: string;
                    InjectTo: string;
                    Listener: string;
                    Shadow: string;
                };

                fkRegister: number | (() => string);
                documentId: number | (() => string);
                documentType: string | (() => string);
                fkJobOrder?: number | (() => string);
                viewer: DocumentsViewer | (() => string);
                injectTo: () => string;
                listener?: () => string;
                shadow?: boolean;
            } & HTMLAttributes<HTMLElement>;
        }
    }
}

type MetadataValueGetter<T> = (metadata: IDocumentBuilderDocumentMetadatas) => T;

type MetadataValueGettersDictionary = {
    [type: string]: MetadataValueGetter<any>;
};

export interface IDocumentParams {
    FKRegister: Param<number>;
    DocumentId: Param<number>;
    DocumentType: Param<string>;
    FKJobOrder: Param<number>;
    Viewer: Param<DocumentsViewer>;
    InjectTo: Param<Document>;
    Listener: Param<IDocumentListener>;
    Shadow: Param<boolean>;
}

export interface IDocumentListener {
    OnClose(document: Document): Promise<void>;
    OnCopyToEditingDocument(document: Document): Promise<void>;
    OnEdit(document: Document): Promise<void>;
    OnEndEdit(document: Document): Promise<void>;
}

export interface IDocumentConfiguration {
    DocumentType: string;
    configure(document: Document);
    print(documentId: number);
}

export class Document
    implements
        IDataSourceListener,
        IAttachmentsManagerPathProvider,
        IDocumentRowHelperListener,
        IDocumentCurrenciesManagerListener,
        IDocumentFieldsReaderSource
{
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrdersService: IJobOrderService;

    @LazyImport(nameof<IBlogService>())
    private blogService: IBlogService;

    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModes: IIvaModes;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    @LazyImport(nameof<IAliasesService>())
    private aliasesService: IAliasesService;

    @LazyImport(nameof<IScheduleService>())
    private schedulesService: IScheduleService;

    @LazyImport(nameof<IInvoicesService>())
    private invoicesServices: IInvoicesService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<ICustomersService>())
    private customersService: ICustomersService;

    @LazyImport(nameof<ITrustAuthorizationProcessService>())
    private trustAuthorizationProcessService: ITrustAuthorizationProcessService;

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;

    @LazyImportSettingManager(ProlifeSdk.CompanySettingsType)
    private companyService: ICompanySettingsManager;

    @LazyImportSettingManager(ProlifeSdk.Exercises)
    private exercisesManager: IExercises;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;

    DocumentId: ko.Observable<number> = ko.observable();
    DocumentType: ko.Observable<string> = ko.observable();
    IsOnTop: ComponentParam<boolean>;
    Listener: ComponentParam<IDocumentListener>;

    Saving: ko.Observable<boolean> = ko.observable();
    IsSelected: ko.Observable<boolean> = ko.observable();
    CanEnterEditMode: ko.Computed<boolean>;
    ShowMetadatasTable: ko.Computed<boolean>;

    // ----------------------------- CONFIGURAZIONI ----------------------------
    SalesCicle: ko.Observable<boolean> = ko.observable(true);
    CanHaveDestinationRecipient: ko.Observable<boolean> = ko.observable(false);
    CanBeAnElectronicInvoice: ko.Observable<boolean> = ko.observable(true); //La fattura accompagnatoria non può essere elettronica
    CanHaveStampDuty: ko.Observable<boolean> = ko.observable(true);
    CanBeDownPayment: ko.Observable<boolean> = ko.observable(true); //Se è fattura ma non nota di credito
    CanHaveSecondaryRecipients: ko.Observable<boolean> = ko.observable(true);
    CanBeForPublicAdministration: ko.Observable<boolean> = ko.observable(true);
    CanHaveWithholdingTax: ko.Observable<boolean> = ko.observable(true);
    CanHideAmounts: ko.Observable<boolean> = ko.observable(true);
    CanSelectCustomers: ko.Observable<boolean> = ko.observable(true);
    CanSelectSuppliers: ko.Observable<boolean> = ko.observable();
    CanHaveRelatedDocuments: ko.Observable<boolean> = ko.observable(true);
    CanHaveSourceWarehouse: ko.Observable<boolean> = ko.observable(true);
    CanHaveDestinationWarehouse: ko.Observable<boolean> = ko.observable(false);
    CanSetCustomerFromJobOrder: ko.Observable<boolean> = ko.observable(true);
    CanHaveShippingInformation: ko.Observable<boolean> = ko.observable(true);
    CanHaveCause: ko.Observable<boolean> = ko.observable(true);
    CanShowTotals: ko.Observable<boolean> = ko.observable(true);
    CanHaveIRPEFTax: ko.Observable<boolean> = ko.observable(true);
    CanHaveTaxReport: ko.Observable<boolean> = ko.observable(true);
    CanReferenceArticles: ko.Observable<boolean> = ko.observable(true);
    CanHaveVAT: ko.Observable<boolean> = ko.observable(true);
    CanCloseRows: ko.Observable<boolean> = ko.observable(true);
    CanHaveLetterOfAttempts: ko.Observable<boolean> = ko.observable(true);
    CanViewOldLetterOfAttempts: ko.Observable<boolean> = ko.observable(true);
    CanChangeDiscountCatalog: ko.Observable<boolean> = ko.observable(true);
    CanShowProtocolNumber: ko.Observable<boolean> = ko.observable(true);
    CanHaveVersion: ko.Observable<boolean> = ko.observable(true);
    CanEditRevisionNumber: ko.Observable<boolean> = ko.observable(true);
    CanHaveSchedules: ko.Observable<boolean> = ko.observable(true);
    CanExportElectronicInvoices: ko.Observable<boolean> = ko.observable();
    CanHaveState: ko.Observable<boolean> = ko.observable(true);
    CanHaveValidity: ko.Observable<boolean> = ko.observable(false);
    CanSelectClosedLettersOfAttempt: ko.Observable<boolean> = ko.observable(false); // Solo note di credito
    CanHaveCIGCUP: ko.Observable<boolean> = ko.observable(true);
    MustCheckTrust: ko.Observable<boolean> = ko.observable(true);
    IsCustomerDocument: ko.Observable<boolean> = ko.observable(true);
    ShowVersionNumbersSuggestions: ko.Observable<boolean> = ko.observable(true);
    RecipientPosition: ko.Observable<"left" | "right"> = ko.observable("right");
    CanCreateWarehouseInspections: ko.Observable<boolean> = ko.observable(false);
    CanHaveMetadatas: ko.Observable<boolean> = ko.observable(false);
    CanHaveResources: ko.Observable<boolean> = ko.observable(false);
    CanHaveTaxRelief: ko.Observable<boolean> = ko.observable(false);

    // -- Queste vengono configurate nei singoli documenti
    ExcelExporterId: ko.Observable<string> = ko.observable();
    ExcelExporterMethod: ko.Observable<string> = ko.observable();
    ExcelExporterController: ko.Observable<string> = ko.observable();
    RefExcelExporterId: ko.Observable<string> = ko.observable();
    RefExcelExporterMethod: ko.Observable<string> = ko.observable();

    // ----------------------------- UTILITIES ---------------------------------
    @Right("Documents_CanAddResourcesOnDocuments")
    ResourcesEnabled: boolean;
    @Right("Documents_CanAddMetadataOnDocuments")
    MetadatasEnabled: boolean;
    @Right("Warehouse_CanCreateWarehouseInspectionFromDocument")
    CanCreateWarehouseInspectionFromDocument: boolean;
    @Right("Documents_EnableCurrenciesOnDocuments")
    CurrenciesEnabled: boolean;
    @Right("!Warehouse")
    IsWarehouseEnabled: boolean;
    @Right("!NewPrint")
    CanUseNewPrint: boolean;
    @Right("Documents_ShowArticleStockInfo")
    CanViewArticleStockInfoOnRows: boolean;
    @Right("Documents_CanAddOrRemoveMetadatas")
    private canAddOrRemoveMetadatas: boolean;

    DocumentCurrency: ko.Computed<IDocumentCurrencyViewModel>;
    DocumentCurrencySymbol: ko.Observable<string> = ko.observable();
    LockDocumentCurrencyChanges: ko.Computed<boolean>;

    ProtocolNumberNotPrinted: ko.Computed<boolean>;
    ProtocolVersionNotPrinted: ko.Computed<boolean>;

    CanCopy: ko.Computed<boolean>;
    VersionNumberSelected: ko.Observable<boolean> = ko.observable();

    CanBeEdited: ko.Observable<boolean> = ko.observable(false);
    CanBeDeleted: ko.Observable<boolean> = ko.observable();
    Blocked: ko.Observable<boolean> = ko.observable();

    ForPartialInvoices: ko.Observable<boolean> = ko.observable();
    ReadOnly: ko.Observable<boolean> = ko.observable(true);
    JobOrderReadOnly: ko.Computed<boolean>;

    BlockSourceWarehouse: ko.Computed<boolean>;
    BlockDestinationWarehouse: ko.Computed<boolean>;
    FilterJobOrdersByCustomer: ko.Observable<boolean> = ko.observable();
    ShowAllCustomers: ko.Observable<boolean> = ko.observable();

    DocumentTypeLabel: ko.Observable<string> = ko.observable("Sconosciuto");
    ShowRecipientCodePECField: ko.Computed<boolean>;

    SourceWarehouseName: ko.Observable<string> = ko.observable();
    DestinationWarehouseName: ko.Observable<string> = ko.observable();

    ShowTaxReliefSection: ko.Observable<boolean> = ko.observable(false);

    //TODO: Verificare cosa devono fare questi 4 campi
    ArticlesHintSearchEnabledActionVisibility: ko.Computed<boolean>;
    SearchOnRowQuery: ko.Observable<string> = ko.observable();
    SearchOnRowProvider: ko.Observable<string> = ko.observable();
    SearchingRequestsCountOnRows: ko.Observable<number> = ko.observable();

    HasLetterOfAttempts: ko.Observable<boolean> = ko.observable();
    RowsForStampDutyTotal: ko.Observable<number> = ko.observable();

    SplitPayment: ko.Computed<boolean>;

    Payment: ko.Observable<IPaymentMode> = ko.observable();
    JobOrder: ko.Observable<IJobOrderForTaskBoard> = ko.observable();
    JobOrderCache: IJobOrder;
    RegisterCache: IVatRegister;
    CompanyCache: ICompany;
    SourceWarehouseCache: IWarehouse;
    ExpiryTypeCache: IExpireMode;

    CustomerIsChanged: ko.Observable<boolean> = ko.observable(true).extend({ notify: "always" }); // HACK per far rigenerare il typeahead perché non si aggiorna finché non si scrive qualcosa nel campo input, quindi continua a mostrare i suggerimenti del vecchio cliente

    PaymentModesDataSource: PaymentModesDataSource = new PaymentModesDataSource();
    CompanyIBANDataSource: CompanyIBANDataSource = new CompanyIBANDataSource();
    CompanyABIDataSource: CompanyABICABDataSource = new CompanyABICABDataSource();
    CompanyCABDataSource: CompanyABICABDataSource = new CompanyABICABDataSource();
    CustomerIBANDataSource: CustomerIBANDataSource = new CustomerIBANDataSource();
    CustomerABIDataSource: CustomerABIDataSource = new CustomerABIDataSource();
    CustomerCABDataSource: CustomerCABDataSource = new CustomerCABDataSource();
    ExpiriesDataSource: ExpiriesDataSource = new ExpiriesDataSource();
    JobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    ElectronicInvoiceTypesDataSource: ElectronicInvoiceTypesDataSource = new ElectronicInvoiceTypesDataSource();
    CommercialResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();
    AdministrativeResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();
    OutcomesDataSource: WorkflowOutcomesDataSource = new WorkflowOutcomesDataSource();
    SourceWarehousesDocumentDataSource: WarehousesDocumentDataSource = new WarehousesDocumentDataSource();
    DestinationWarehousesDocumentDataSource: WarehousesDocumentDataSource = new WarehousesDocumentDataSource();
    ReasonForPaymentDataSource: ReasonForPaymentDataSource = new ReasonForPaymentDataSource();
    DDTCausesDataSource: DDTCausesDataSource = new DDTCausesDataSource();
    WarehouseLoadCausesDataSource: WarehouseLoadReasonsForShipmentDataSource =
        new WarehouseLoadReasonsForShipmentDataSource();
    AspectsDataSource: AspectsDataSource = new AspectsDataSource();
    TransportsDataSource: TransportsDataSource = new TransportsDataSource();
    CarriagesDataSource: CarriagesDataSource = new CarriagesDataSource();
    DocumentVersionNumbersDataSource: DocumentVersionNumbersDataSource = new DocumentVersionNumbersDataSource();
    EstimateStatesDataSource: EstimateStatesDataSource = new EstimateStatesDataSource();
    MetadatasDataSource: DocumentMetadatasDataSource = new DocumentMetadatasDataSource();
    DocumentValidityTypesDataSource: DocumentValidityTypesDataSource = new DocumentValidityTypesDataSource();

    AttachmentsManager: AttachmentsManager;

    // ----- necessari per usare i componenti stile react
    MetadatasEditorUI: DocumentMetadatasEditorUI;
    ResourcesEditorUI: DocumentResourcesUI;
    ReportPrintButton: _ReportPrintButton;
    MailSendButton: _MailSendButton;
    TaxReliefEditorUI: TaxReliefEditorUI;

    private MetadatasEditor: _DocumentMetadatasEditor;
    // -----

    Actions: ko.ObservableArray<IDocumentAction> = ko.observableArray();
    EditActions: ko.ObservableArray<IDocumentAction> = ko.observableArray();
    HasActions: ko.Computed<boolean>;
    HasEditActions: ko.Computed<boolean>;

    // ----------------------------- PROPRIETA' --------------------------------
    FKRegister: ko.Observable<number> = ko.observable();
    FKFiscalYear: ko.Observable<number> = ko.observable();

    HeaderImage: ko.Observable<string> = ko.observable();
    CompanyLogo: ko.Observable<string> = ko.observable();
    ElectronicInvoice: ko.Observable<boolean> = ko.observable();
    ElectronicInvoiceTypeCode: ko.Observable<string> = ko.observable();
    IncludeAttachmentsAndDocumentInElectronicInvoiceExport: ko.Observable<boolean> = ko.observable();
    ShowImportedDocumentsTableOnPrintedDocument: ko.Observable<boolean> = ko.observable();
    DownPayment: ko.Observable<boolean> = ko.observable();
    SecondaryRecipientsEnabled: ko.Observable<boolean> = ko.observable();
    ForPublicAdministration: ko.Observable<boolean> = ko.observable();
    WithWithholdingTax: ko.Observable<boolean> = ko.observable();
    ShowAmounts: ko.Observable<boolean> = ko.observable(true);
    ShowResources: ko.Observable<boolean> = ko.observable(false);
    LayoutId: ko.Observable<number> = ko.observable(DocumentLayouts.Detailed);

    Date: ko.Observable<Date> = ko.observable(new Date());
    DateSelected: ko.Observable<boolean> = ko.observable();
    NumberPrefix: ko.Observable<string> = ko.observable();
    Number: ko.Observable<string> = ko.observable();
    NumberSuffix: ko.Observable<string> = ko.observable();
    VatRegisterNumber: ko.Observable<number> = ko.observable();

    FullVersionRevisionNumber: ko.Observable<string> = ko.observable();
    VersionRevisionNumberPrefix: ko.Observable<string> = ko.observable();
    VersionRevisionNumberSuffix: ko.Observable<string> = ko.observable();
    VersionRevisionNumberSeparator: ko.Observable<string> = ko.observable();
    VersionNumber: ko.Observable<number> = ko.observable();
    VersionRevision: ko.Observable<number> = ko.observable();
    VersionNumberGenerationMode: ko.Observable<number> = ko.observable(
        DocumentVersionNumberGenerationModes.AutoFromProtocolNumber
    );
    VersionNumberGenerationModes: ko.ObservableArray<IVersionNumberGenerationMode> = ko.observableArray(
        DocumentVersionNumberGenerationModesProvider.getAvailableGenerationModes()
    );
    NumberPrintChoice: ko.Observable<number> = ko.observable(DocumentNumberPrintChoices.OnlyProtocolNumber);
    NumberPrintChoices: ko.ObservableArray<IDocumentNumberPrintChoice> = ko.observableArray(
        NumberPrintChoicesProvider.getAvailableChoices()
    );

    FKOrganizationalUnit: ko.Observable<number> = ko.observable(); //TODO: Spostare nel posto più corretto dopo discussione con Andrea

    Recipient: DocumentRecipientInfo = new DocumentRecipientInfo();
    RecipientCodeData: ko.Observable<IRecipientCode> = ko.observable();
    RecipientCode: ko.Observable<string> = ko.observable();
    RecipientCodePEC: ko.Observable<string> = ko.observable();
    DestinationRecipient: DocumentRecipientInfo = new DocumentRecipientInfo(true);

    FKPaymentType: ko.Observable<number> = ko.observable();
    PaymentIBAN: ko.Observable<string> = ko.observable();
    PaymentABI: ko.Observable<string> = ko.observable();
    PaymentCAB: ko.Observable<string> = ko.observable();
    PaymentBankName: ko.Observable<string> = ko.observable();

    FKExpiryType: ko.Observable<number> = ko.observable();
    ExpiryType: ko.Observable<string> = ko.observable();

    FKJobOrder: ko.Observable<number> = ko.observable();
    JobOrderName: ko.Observable<string> = ko.observable();
    JobOrderSelected: ko.Observable<boolean> = ko.observable();

    FKCommercialResponsible: ko.Observable<number | string> = ko.observable();
    CommercialResponsibleName: ko.Observable<string> = ko.observable();
    FKAdministrativeResponsible: ko.Observable<number | string> = ko.observable();
    AdministrativeResponsibleName: ko.Observable<string> = ko.observable();

    FKOutcome: ko.Observable<number> = ko.observable();
    DefaultFKOffset: ko.Observable<number> = ko.observable();
    DefaultOffsetCode: ko.Observable<string> = ko.observable();

    FKState: ko.Observable<number> = ko.observable();
    StateName: ko.Observable<string> = ko.observable();
    StateFieldUnlocked: ko.Observable<boolean> = ko.observable(false);

    ReferenceNumber: ko.Observable<string> = ko.observable();
    ReferenceDate: ko.Observable<Date> = ko.observable();
    ExternalReference: ko.Observable<string> = ko.observable();

    SourceWarehouseId: ko.Observable<number> = ko.observable();
    WarehouseId: ko.Observable<number> = ko.observable();

    Notes: ko.Observable<string> = ko.observable();
    NotesTrigger: ko.Observable<any> = ko.observable(); //TODO: chiamare un valueHasMutated su questo trigger per forzare un reload da parte di ckEditor
    AdministrativeNotes: ko.Observable<string> = ko.observable();
    AdministrativeNotesTrigger: ko.Observable<any> = ko.observable(); //TODO: chiamare un valueHasMutated su questo trigger per forzare un reload da parte di ckEditor

    IRPEFTax: ko.Observable<number> = ko.observable(0);
    IRPEFTaxDescription: ko.Observable<string> = ko.observable();
    IRPEFTaxTotal: ko.Observable<number> = ko.observable(0);
    NonIRPEFTax: ko.Observable<number> = ko.observable(0);
    NonIRPEFTaxDescription: ko.Observable<string> = ko.observable();
    NonIRPEFTaxTotal: ko.Observable<number> = ko.observable(0);

    TaxableTotal: ko.Observable<number> = ko.observable();
    TaxTotal: ko.Observable<number> = ko.observable();
    NonTaxableTotal: ko.Observable<number> = ko.observable();
    Total: ko.Observable<number> = ko.observable();
    NetTotal: ko.Observable<number> = ko.observable();
    FinalTotal: ko.Observable<number> = ko.observable();

    TaxableTotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    TaxTotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    NonTaxableTotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    TotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    NetTotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    FinalTotalInDocumentCurrency: ko.Observable<number> = ko.observable();

    TaxReliefTotal: ko.Observable<number> = ko.observable();
    TaxReliefTotalInDocumentCurrency: ko.Observable<number> = ko.observable();
    TotalWithTaxReliefDiscount: ko.Observable<number> = ko.observable();
    TotalWithTaxReliefDiscountInDocumentCurrency: ko.Observable<number> = ko.observable();
    ShowTaxReliefTotal: ko.Computed<boolean>;

    WithholdingTax: ko.Observable<string> = ko.observable();
    WithholdingTaxTotal: ko.Observable<number> = ko.observable(0);
    ReasonForPayment: ko.Observable<string> = ko.observable();

    StampDuty: ko.Observable<boolean> = ko.observable();
    StampDutyDisabled: ko.Computed<boolean>;
    StampDutyValue: ko.Observable<number> = ko.observable();
    StampDutyThreshold: ko.Observable<number> = ko.observable();
    ShowStampDutyValueField: ko.Computed<boolean>;

    ShippingInfoSelected: ko.Observable<boolean> = ko.observable();
    Weight: ko.Observable<number> = ko.observable();
    Packages: ko.Observable<number> = ko.observable(1);
    FKCause: ko.Observable<number> = ko.observable();
    CauseLogicType: ko.Observable<number> = ko.observable();
    Cause: ko.Observable<string> = ko.observable();
    FKAspect: ko.Observable<number> = ko.observable();
    Aspect: ko.Observable<string> = ko.observable();
    FKTransport: ko.Observable<number> = ko.observable();
    Transport: ko.Observable<string> = ko.observable();
    FKCarriage: ko.Observable<number> = ko.observable();
    Carriage: ko.Observable<string> = ko.observable();
    FKValidityType: ko.Observable<number> = ko.observable();
    ValidityType: ko.Observable<string> = ko.observable();
    ValiditySelected: ko.Observable<boolean> = ko.observable();
    TransportStart: ko.Observable<Date> = ko.observable();

    CIG: ko.Observable<string> = ko.observable();
    CUP: ko.Observable<string> = ko.observable();

    HtmlFooter: ko.Observable<string> = ko.observable();

    // ------------------------------ ADDITIONAL DATA --------------------------
    documentJournalSettings: IDocumentsJournalDialogResult;
    SelectedMetadatum: DocumentMetadata[] = [];

    Rows: ko.ObservableArray<DocumentRow> = ko.observableArray();
    AtLeastOneRowSelected: ko.Computed<boolean>;

    document: IDocumentBuilderDocument;

    InlineRefProvider: ko.ObservableArray<IDocumentRowInlineReferenceProvider> = ko.observableArray();

    TaxesReport: ko.ObservableArray<DocumentVatRow> = ko.observableArray();
    SecondaryRecipients: ko.ObservableArray<SecondaryRecipient> = ko.observableArray();
    RelatedDocuments: ko.ObservableArray<RelatedDocumentInfo> = ko.observableArray();
    DocumentCurrencies: ko.ObservableArray<IDocumentCurrencyViewModel> = ko.observableArray([]);
    OverrideCustomerGroup: ko.Observable<number> = ko.observable();
    Schedules: ko.ObservableArray<IDocumentBuilderDocumentSchedules> = ko.observableArray();
    Metadatas: ko.ObservableArray<DocumentMetadata> = ko.observableArray([]);
    Resources: ko.ObservableArray<DocumentResource> = ko.observableArray([]);
    TaxRelief: ko.ObservableArray<DocumentTaxRelief> = ko.observableArray([]);

    SynchronizePrices: ko.Computed<boolean>;
    MetadataObserver: ko.Computed<void>;
    IsWarehouseTransfer: ko.Computed<boolean>;

    private metadataValueGetters: MetadataValueGettersDictionary = {};
    private validator: IValidator<Document>;

    // ------------------------------- MAIL ------------------------------------
    SendDocumentMail: ko.Observable<boolean> = ko.observable(false);
    IncludeDocumentAttachmentsInMail: ko.Observable<boolean> = ko.observable(false);
    LastMailSentStatus: ko.Observable<SentMail> = ko.observable();

    // ------------------------------- COPIA DOCUMENTO -------------------------
    PaymentSelected: ko.Observable<boolean> = ko.observable();
    ExpirySelected: ko.Observable<boolean> = ko.observable();

    // ------------------------------- DEFAULTS --------------------------------
    DefaultFKVatCode: ko.Observable<number> = ko.observable();
    DefaultVatCode: ko.Observable<string> = ko.observable();
    WarehouseWorkflowId: number;
    registerId: number;
    requestedFkJobOrder = -1;
    loading: boolean;
    disableDefaultsLoading = false;
    dataLoadingAfterJobOrderSet: Promise<void>; // Utilizzata in fase di copia tra documenti per attendere che tutti i dati necessari al caricamento dei default vengano caricati dopo aver settato la commessa
    configuration: IDocumentConfiguration;

    private fieldsReader: DocumentFieldsReader;

    constructor(params: IDocumentParams) {
        this.CompanyABIDataSource.getABIs();
        this.CompanyCABDataSource.getCABs();

        this.JobOrdersDataSource.setUserId(this.userInfo.getIdUser());
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.CommercialResourcesDataSource.setAllowFreeText(true);
        this.CommercialResourcesDataSource.setGetMaterialResources(false);

        this.AdministrativeResourcesDataSource.setAllowFreeText(true);
        this.AdministrativeResourcesDataSource.setGetMaterialResources(false);

        this.MetadatasDataSource.setGetDeleted(false);

        this.DocumentId(ComponentUtils.parseParameter(params.DocumentId, 0)());
        this.DocumentType(ComponentUtils.parseParameter(params.DocumentType, null)());
        this.Listener = ComponentUtils.parseParameter(params.Listener, null);

        const viewer = ComponentUtils.parseParameter(params.Viewer, null);
        this.IsOnTop = ko.computed(() => viewer()?.OnTopDocument()?.viewModel() === this);

        this.CanCopy = ko.computed(() => {
            return this.IsOnTop() && this.ReadOnly() && !!viewer()?.EditingDocument();
        });

        this.fieldsReader = new DocumentFieldsReader(this);

        this.initializeMetadataValueGetters();
        this.initializeComputed();
        this.initializeComponents(params);
        this.initializeEvents();

        this.CanEnterEditMode = ko.computed(() => {
            return (
                this.IsOnTop() &&
                !this.Blocked() &&
                this.ReadOnly() &&
                !viewer()?.EditingDocument() &&
                this.CanBeEdited()
            );
        });

        this.JobOrderReadOnly = ko.computed(() => {
            return this.ReadOnly() || this.Rows().any((r) => r.IsLocked());
        });

        this.registerId = ComponentUtils.parseParameter(params.FKRegister, -1)();
        this.requestedFkJobOrder = ComponentUtils.parseParameter(params.FKJobOrder, -1)();

        this.MetadatasDataSource.setProtocolId(this.registerId);

        this.CompanyCache = this.companyService.get();

        this.configureCanEnterEditMode();
        this.configureActions();
        this.initializeInterceptors();
        this.initializeRowProviders();
        this.configureValidations();

        this.FKState.subscribe(this.handleStateChanges.bind(this));

        this.reload();

        if (!this.DocumentId() || this.DocumentId() <= 0) {
            this.Listener()?.OnEdit(this);
            this.ReadOnly(false);
        }

        const injectTo = ComponentUtils.parseParameter(params.InjectTo, null);
        injectTo(this);
    }

    private initializeComponents(params: IDocumentParams): void {
        this.AttachmentsManager = new AttachmentsManager(this);

        this.MetadatasEditorUI = new DocumentMetadatasEditorUI({
            selectable: this.CanCopy,
            metadatas: this.Metadatas,
            commonMetadatasDataSource: this.MetadatasDataSource,
            readOnly: this.ReadOnly,
            showValueColumn: true,
            title: TextResources.Invoices.DocumentMetadatasTableTitle,
            titleOnTop: true,
            className: "metadatas striped",
            compactTable: true,
            tableAdvanceStyle: true,
            customActions: this.renderMetadatasCustomActions.bind(this),

            onMetadataSelected: (metadata) => this.SelectedMetadatum.push(metadata),
            onMetadataDeselected: (metadata) => this.SelectedMetadatum.remove(metadata),

            forwardRef: (editor) => (this.MetadatasEditor = editor),
        });

        this.ResourcesEditorUI = new DocumentResourcesUI({
            resources: this.Resources,
            documentFieldsReader: this.fieldsReader,
            readonly: this.ReadOnly,
        });

        this.ReportPrintButton = new _ReportPrintButton({
            documentId: ko.unwrap(params.DocumentId),
            registerId: ko.unwrap(params.FKRegister),
        });

        this.MailSendButton = new _MailSendButton({
            documentId: this.DocumentId,
            documentType: this.DocumentType,
            lastMailSentStatus: this.LastMailSentStatus,
        });

        this.TaxReliefEditorUI = new TaxReliefEditorUI({
            taxRelief: this.TaxRelief,
            documentRows: this.Rows,
            readOnly: this.ReadOnly,
            documentCurrencySymbol: this.DocumentCurrencySymbol,

            onTaxReliefChange: () => this.updateTotals(),
        });
    }

    private configureCanEnterEditMode() {
        if (this.registerId <= 0) return;

        const documentsProvider = this.documentsService
            .getRegisteredDocumentProviders()
            .firstOrDefault((p) => p.Id === this.registerId);

        if (!documentsProvider) {
            this.CanBeEdited(false);
            return;
        }

        this.CanBeEdited(documentsProvider.CanCreateNew());
    }

    private initializeMetadataValueGetters() {
        this.metadataValueGetters[MetadataType.Text] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.StringValue;
        this.metadataValueGetters[MetadataType.Integer] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.IntValue;
        this.metadataValueGetters[MetadataType.Decimal] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.DecimalValue;
        this.metadataValueGetters[MetadataType.DateTime] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.DateValue;
        this.metadataValueGetters[MetadataType.Boolean] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.BoolValue;
        this.metadataValueGetters[MetadataType.List] = (metadata: IDocumentBuilderDocumentMetadatas) =>
            metadata.IntValue;
    }

    private initializeComputed() {
        this.BlockSourceWarehouse = ko.computed(() => this.ReadOnly() || this.Rows().length > 0);
        this.BlockDestinationWarehouse = ko.computed(() => this.ReadOnly() || this.Rows().length > 0);

        this.ProtocolNumberNotPrinted = ko.computed(() => this.NumberPrintChoice() == 2);
        this.ProtocolVersionNotPrinted = ko.computed(() => this.NumberPrintChoice() == 1);

        this.SplitPayment = ko.computed(() => {
            if (!this.TaxesReport() || this.TaxesReport().length == 0) return false;

            return this.TaxesReport().filter((i) => i.IsSplitPayment()).length > 0;
        });

        this.DocumentCurrency = ko.computed(() => {
            return this.DocumentCurrencies().firstOrDefault((c) => c.IsDocumentCurrency());
        });

        this.ShowRecipientCodePECField = ko.computed(() => {
            return this.ElectronicInvoice() && (this.RecipientCode() == "0000000" || !this.RecipientCode());
        });

        this.LockDocumentCurrencyChanges = ko.computed(() => {
            return this.ReadOnly() || this.Blocked();
        });

        this.ArticlesHintSearchEnabledActionVisibility = ko.computed(() => {
            return this.IsWarehouseEnabled && this.CanReferenceArticles();
        });

        this.StampDutyDisabled = ko.computed(() => {
            return this.HasLetterOfAttempts() && this.ShowStampDutyValueField();
        });

        this.ShowStampDutyValueField = ko.computed(() => {
            return this.StampDuty() && this.StampDutyThreshold() <= this.RowsForStampDutyTotal();
        });

        this.ShowTaxReliefTotal = ko.computed(() => {
            return this.CanHaveTaxRelief() && this.TaxRelief().length > 0 && this.ShowTaxReliefSection();
        });

        this.AtLeastOneRowSelected = ko.computed(() => {
            return this.Rows().any((r) => r.IsSelected());
        });

        this.HasActions = ko.pureComputed(() => this.Actions().length > 0);
        this.HasEditActions = ko.pureComputed(() => this.EditActions().length > 0);

        this.SynchronizePrices = ko.computed(() => {
            return this.LayoutId() === DocumentLayouts.Standard;
        });

        this.ShowMetadatasTable = ko.computed(() => {
            this.FKRegister();
            const canHaveMetadatas = this.CanHaveMetadatas();
            const actualMetadatas = this.Metadatas() ?? [];
            const isReadOnly = this.ReadOnly();
            const canAddMetadatas = this.RegisterCache?.ProtocolsDefaultMetadatas.length > 0;

            return canHaveMetadatas && (actualMetadatas.length > 0 || (!isReadOnly && canAddMetadatas));
        });

        this.MetadataObserver = ko.computed(() => {
            const selectedMetadataIds = this.Metadatas().map((m) => m.MetadataId());
            this.MetadatasDataSource.setMetadataToOmit(selectedMetadataIds);
        });

        this.IsWarehouseTransfer = ko.computed(() => {
            return this.CauseLogicType() === DocumentCauseLogicType.Transfer;
        });
    }

    private configureDocument() {
        if (this.DocumentType()) {
            this.configuration = Configurations.firstOrDefault((c) => c.DocumentType === this.DocumentType());
            this.configuration.configure(this);
        }

        this.CanExportElectronicInvoices(this.authorizationService.isAuthorized("Documents_ElectronicInvoicing"));
    }

    private initializeEvents() {
        this.FKRegister.subscribe((value: number) => {
            this.RegisterCache = this.vatRegisters.getVatRegisterById(value);

            this.configureDocument();
            this.Recipient.TAXCodeRequired(this.RegisterCache?.CFRequired);
            this.FilterJobOrdersByCustomer(this.RegisterCache?.DefaultJobOrderFilteredByCustomer);
            this.ForPartialInvoices(this.RegisterCache.ForPartialInvoices);
            this.ShowAllCustomers(this.RegisterCache?.ShowAllCustomersAndSuppliers);
            this.ShowAmounts(!!this.RegisterCache?.VisualizzaImporti);
            this.ShowResources(this.CanHaveResources() && this.RegisterCache?.ShowResourcesTable);

            if (this.loading) return;

            this.scheduleLoadDefaults(true, false, false);
        });

        this.FKJobOrder.subscribe((value: number) => {
            const def = new Deferred<void>();
            this.dataLoadingAfterJobOrderSet = def.promise();

            if (this.CanHaveVersion()) this.DocumentVersionNumbersDataSource.setJobOrderId(value);
            if (this.CanHaveSourceWarehouse()) this.SourceWarehousesDocumentDataSource.setJobOrderId(value);
            if (this.CanHaveDestinationWarehouse()) this.DestinationWarehousesDocumentDataSource.setJobOrderId(value);

            if (this.loading) {
                def.resolve();
                this.dataLoadingAfterJobOrderSet = undefined;
                return;
            }

            if (value) {
                this.jobOrdersService.get(value).then((j) => {
                    if (!j) {
                        this.FKJobOrder(null);
                        def.resolve();
                        this.dataLoadingAfterJobOrderSet = undefined;
                        this.infoToastService.Error(TextResources.Invoices.JobOrderAccessDenied);
                        return;
                    }

                    this.JobOrderCache = j;

                    if (this.CanSetCustomerFromJobOrder() && !this.Recipient.HasName() && !this.Recipient.Id()) {
                        this.Recipient.loadFromCustomerId(this.JobOrderCache.CustomerId)
                            .then(() => {
                                def.resolve();
                                this.dataLoadingAfterJobOrderSet = undefined;
                            })
                            .catch(() => {
                                def.resolve();
                                this.dataLoadingAfterJobOrderSet = undefined;
                            });

                        if (this.CanHaveDestinationRecipient() && this.JobOrderCache.OrganizationalUnitId)
                            this.DestinationRecipient.loadFromCustomerIdAndOUId(
                                this.JobOrderCache.CustomerId,
                                this.JobOrderCache.OrganizationalUnitId,
                                null
                            );
                    }

                    this.FKCommercialResponsible(
                        !this.JobOrderCache.FkCommercialResponsible && this.JobOrderCache.CommercialResponsibleName
                            ? this.JobOrderCache.CommercialResponsibleName
                            : this.JobOrderCache.FkCommercialResponsible
                    );
                    this.CommercialResponsibleName(this.JobOrderCache.CommercialResponsibleName);

                    this.FKAdministrativeResponsible(
                        !this.JobOrderCache.FkAdministrativeResponsible &&
                            this.JobOrderCache.AdministrativeResponsibleName
                            ? this.JobOrderCache.AdministrativeResponsibleName
                            : this.JobOrderCache.FkAdministrativeResponsible
                    );
                    this.AdministrativeResponsibleName(this.JobOrderCache.AdministrativeResponsibleName);

                    if (!this.CanSetCustomerFromJobOrder() || this.Recipient.Id()) {
                        def.resolve();
                        this.dataLoadingAfterJobOrderSet = undefined;
                    }
                });
            } else {
                def.resolve();
                this.dataLoadingAfterJobOrderSet = undefined;
            }

            this.scheduleLoadDefaults(false, true, false);
        });

        this.ExpiryType.subscribe(() => {
            if (this.loading) return;

            this.FKExpiryType(undefined);
        });

        this.FilterJobOrdersByCustomer.subscribe((value: boolean) => {
            this.JobOrdersDataSource.setCustomerIds(value ? this.Recipient.Id() : undefined);
        });

        this.Recipient.Id.subscribe((value: number) => {
            this.JobOrdersDataSource.setCustomerIds(this.FilterJobOrdersByCustomer() ? value : undefined);
            this.DocumentVersionNumbersDataSource.setCustomerId(value);

            if (this.loading) return;

            this.scheduleLoadDefaults(false, false, true);
        });

        this.Recipient.OnCustomerLoaded.Add(() => {
            this.CustomerIBANDataSource.setCustomer(this.Recipient.CustomerCache);
            this.CustomerABIDataSource.setCustomer(this.Recipient.CustomerCache);
            this.CustomerCABDataSource.setCustomer(this.Recipient.CustomerCache);

            // HACK per far rigenerare i typeahead perché non si aggiornano finché non si scrive qualcosa nel campo input, quindi continuano a mostrare i suggerimenti del vecchio cliente
            this.CustomerIsChanged(false);
            this.CustomerIsChanged(true);

            if (this.loading) return;

            if (this.CanBeAnElectronicInvoice() && !this.ForPartialInvoices())
                this.ElectronicInvoice(this.Recipient?.CustomerCache?.ElectronicInvoicing);

            if (this.ElectronicInvoice()) {
                this.setCustomerElectronicInvoiceRecipientInfo();
            }

            if (this.CanBeForPublicAdministration()) {
                this.ForPublicAdministration(this.Recipient?.CustomerCache?.PublicAdministration);
            }

            if (this.Recipient.LegalPerson() && this.SecondaryRecipients().length > 0 && !this.ReadOnly()) {
                this.ShowConfirmSecondaryRecipientsOnInvoice().then((r) => {
                    if (!r) this.Recipient.BusinessName(undefined);
                });
            }
        });

        if (!this.loading && this.CanHaveSecondaryRecipients()) {
            this.Recipient.LegalPerson.subscribe((value: boolean) => {
                if (value && this.SecondaryRecipients().length > 0) {
                    this.ShowConfirmSecondaryRecipientsOnInvoice().then((r) => {
                        this.Recipient.LegalPerson(r);
                    });
                }
            });

            this.SecondaryRecipientsEnabled.subscribe((value: boolean) => {
                if (value && this.Recipient.LegalPerson()) {
                    this.ShowConfirmSecondaryRecipientsOnInvoice().then((r) => {
                        this.SecondaryRecipientsEnabled(r);
                    });
                }
            });
        }

        this.AttachmentsManager.attachments.subscribe((attachments: Attachment[]) => {
            attachments.forEach((a) => {
                //TODO: Questa roba è una schifezza...
                a.entityAttachmentType(this.DocumentType());
                a.entityAttachmentKey(this.DocumentId());
                a.showIncludeInElectronicInvoiceFlag(this.ElectronicInvoice());
            });
        });

        this.WarehouseId.subscribe((value: number) => {
            if (this.loading) return;

            //Devo impostare come destinatario l'indirizzo del magazzino di destinazione selezionato
            if (this.CanHaveDestinationRecipient()) this.DestinationRecipient.loadFromWarehouseId(value);
        });

        this.StampDuty.subscribe((newValue: boolean) => {
            //this.updateTotalsForInvoice();
            if (this.loading) return;

            if (newValue) {
                if (!this.StampDutyValue() || this.StampDutyValue() == 0)
                    this.StampDutyValue(this.RegisterCache?.StampDutyValue);

                if (!this.StampDutyThreshold() || this.StampDutyThreshold() == 0)
                    this.StampDutyThreshold(this.CompanyCache.StampDutyThreshold || 0);

                return;
            }

            this.StampDutyValue(0);
            this.StampDutyThreshold(0);
        });

        this.StampDuty.subscribe((val: boolean) => {
            if (this.loading) return;

            let notes = this.Notes();
            if (!notes || notes.trim().length === 0) notes = "<p></p>";

            const oldNotes = $(notes).wrapAll("<div></div>").parent();

            const paragraphsToRemove = oldNotes.children().filter(function () {
                return $(this).text().indexOf(ProlifeSdk.TextResources.Invoices.InvoiceNotesStampDutyIndication) === 0;
            });

            paragraphsToRemove.each(function () {
                $(this).remove();
            });

            if (val) {
                $("<p></p>").text(ProlifeSdk.TextResources.Invoices.InvoiceNotesStampDutyIndication).appendTo(oldNotes);
            }

            this.Notes(oldNotes.html());
            this.NotesTrigger.valueHasMutated();
        });

        this.ElectronicInvoice.subscribe((value: boolean) => {
            if (this.loading) return;

            this.setElectronicInvoiceTypeCode();
            if (value) this.setCustomerElectronicInvoiceRecipientInfo();
            else {
                this.RecipientCodeData(null);
                this.RecipientCode(null);
                this.RecipientCodePEC(null);
            }
        });

        this.DownPayment.subscribe(() => {
            this.setElectronicInvoiceTypeCode();
        });

        this.ShowTaxReliefSection.subscribe((value) => {
            if (this.loading || value) return;

            this.TaxRelief([]);
        });

        //TODO: Ricordarsi di implementare questa logica in salvataggio
        /*this.ElectronicInvoice.subscribe((value: boolean) => {
            if (!value) {
                this.lastIncludeInAttachmentsValue = this.IncludeAttachmentsAndDocumentInElectronicInvoiceExport();
                this.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(false);
                return;
            }

            this.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(this.lastIncludeInAttachmentsValue);
        });*/
    }

    private initializeInterceptors() {
        const totalsInterceptor = ko
            .computed(this.updateTotals.bind(this))
            .extend({ rateLimit: { timeout: 100, method: "notifyWhenChangesStop" } });
        const lettersOfAttempInterceptor = ko.computed(this.lettersOfAttemptInterceptor.bind(this));
    }

    private lettersOfAttemptInterceptor() {
        const rows = this.Rows();
        const hasLetterOfAttempts = rows.any((r) => {
            r.LetterOfAttemptCache(); //Creiamo una dipendenza
            if (!r.VatTypeCache()) return false;
            return r.VatTypeCache().RequireLetterOfAttempt;
        });

        this.HasLetterOfAttempts(hasLetterOfAttempts);

        if (this.loading || !this.CanHaveLetterOfAttempts()) return;

        //this.setLettersOfAttemptsOnNotes(); RIMOSSA IL 10/02/2022

        if (hasLetterOfAttempts) {
            this.StampDuty(true);
            return;
        }

        if (!hasLetterOfAttempts && !this.RegisterCache?.StampDuty) {
            this.StampDuty(false);
            return;
        }
    }

    public showMailingLog(documentId: number): void {
        const logModal = new MailLogModal({ documentId: documentId });
        logModal.show();
    }

    /* private setLettersOfAttemptsOnNotes(): void {
        if (!this.CanHaveLetterOfAttempts() || this.RegisterCache?.ForPartialInvoices)
            return;

        const rows = this.Rows.peek();
        const letters: string[] = [];

        rows.forEach(r => {
            const ivaMode = r.VatTypeCache.peek();
            const loa = r.LetterOfAttemptCache.peek();
            if (!ivaMode || !ivaMode.RequireLetterOfAttempt || !loa)
                return;

            const letterDescription = String.format(ProlifeSdk.TextResources.Invoices.InvoiceNotesLetterOfAttemptDescription, loa.Description, moment(loa.RevenueAgencyReceiptDate || loa.StartDate).format("L"));

            if (ivaMode.RequireLetterOfAttempt && letters.indexOf(letterDescription) < 0)
                letters.push(letterDescription);
        });

        let notes = this.Notes.peek();

        const oldNotes = !notes ? $("<div></div>") : $(notes).wrapAll("<div></div>").parent();
        let inside = false;

        const paragraphsToRemove = oldNotes.find("p").filter(function () {
            const text = $(this).text();

            if (inside == true) {
                if (text.lastIndexOf(TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionEnd) == text.length - TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionEnd.length)
                    inside = false;
                return true;
            }

            if (text.indexOf(TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionStart) == 0) {
                if (text.lastIndexOf(TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionEnd) == text.length - TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionEnd.length)
                    return true;
                inside = true;

                return true;
            }

            return false;
        });

        paragraphsToRemove.each(function () {
            $(this).remove();
        });

        if (letters.length > 0) {
            $("<p></p>").text(TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionStart).appendTo(oldNotes);
            letters.forEach((l) => $("<p></p>").text(l).appendTo(oldNotes));
            $("<p></p>").text(TextResources.Invoices.InvoiceNotesLettersOfAttemptsSectionEnd).appendTo(oldNotes);
        }

        notes = oldNotes.html();

        this.Notes(notes);
        this.NotesTrigger.valueHasMutated();
    } */

    private initializeRowProviders() {
        const providers = this.documentsService
            .getRowReferencesGeneratorFactories(this.DocumentType())
            .map((p) => p.create(this.GetDocumentInfoForInlineRefProvider()));

        this.InlineRefProvider(providers);

        this.DocumentVersionNumbersDataSource.setDocumentType(this.DocumentType());
        this.DocumentVersionNumbersDataSource.setProtocolId(this.FKRegister());
    }

    public updateTotals() {
        const documentCurrency = this.DocumentCurrency();
        const rows = this.Rows();

        if (!documentCurrency) return;

        let taxableTotalInEuro = 0;
        let taxableTotalInEuroForVat = 0;
        let taxableTotalInDocumentCurrency = 0;
        let nonTaxableTotalInEuro = 0;
        let nonTaxableTotalInDocumentCurrency = 0;
        let taxesTotalInEuro = 0;
        let totalToUseForWitholdingTaxInEuro = 0;
        let totalToUseForStampDutyThresholdInEuro = 0;
        let index = 1;
        let order = 0;
        const taxesReportMap: { [vatCode: string]: IDocumentBuilderDocumentVatRowsExt } = {};
        const taxReport: IDocumentBuilderDocumentVatRows[] = [];

        for (const row of rows) {
            row.Index(index++);
            row.Order(order++);

            if (row.Amount() != 0) {
                const vatType = row.VatTypeCache();

                const rowTaxableTotalInEuroForVat = row.TaxableTotalInEuroForVat();

                taxableTotalInDocumentCurrency += row.TaxableTotal();
                taxableTotalInEuro += row.TaxableTotalInEuro();
                taxableTotalInEuroForVat += rowTaxableTotalInEuroForVat;
                nonTaxableTotalInDocumentCurrency += row.NonTaxableTotal();
                nonTaxableTotalInEuro += row.NonTaxableTotalInEuro();

                if (!vatType) continue;

                if (vatType.SubjectToWithholdingTax) totalToUseForWitholdingTaxInEuro += row.TotalPrice();

                if (vatType.SubjectToStampDuty) totalToUseForStampDutyThresholdInEuro += row.TotalPrice();

                const taxRow = this.getTaxesReportForVatCode(taxesReportMap, taxReport, vatType);
                //let tax = (rowTaxableTotalInEuroForVat * (row.VatTypeCache().Iva / 100)).Round(2);
                taxRow.Taxable += rowTaxableTotalInEuroForVat;
                //taxRow.Tax += tax;
                //taxRow.Total = taxRow.Taxable + taxRow.Tax;
            }
        }

        // TODO chiedere conferma sul fatto che l'irpef viene calcolato sul totale con il cambio NON iva

        let irpefTaxTotalInEuro = 0;
        let nonIrpefTaxTotalInEuro = 0;

        if (this.RegisterCache?.ContributoSuImponibileIVA > 0) {
            const ivaMode = this.ivaModes.getDefaultIva();
            irpefTaxTotalInEuro = taxableTotalInEuro * (this.RegisterCache?.ContributoSuImponibileIVA / 100.0);

            const taxRow = this.getTaxesReportForVatCode(taxesReportMap, taxReport, ivaMode);
            taxRow.Taxable += irpefTaxTotalInEuro;
            //taxRow.Tax += irpefTaxTotalInEuro * (ivaMode.Iva / 100.0);
            //taxRow.Total = taxRow.Taxable + taxRow.Tax;
        }

        if (this.RegisterCache?.ContributoSuCompenso > 0) {
            const ivaMode = this.ivaModes.getDefaultIva();
            nonIrpefTaxTotalInEuro =
                (taxableTotalInEuro + irpefTaxTotalInEuro) * (this.RegisterCache?.ContributoSuCompenso / 100.0);

            const taxRow = this.getTaxesReportForVatCode(taxesReportMap, taxReport, ivaMode);
            taxRow.Taxable += nonIrpefTaxTotalInEuro;
            //taxRow.Tax += nonIrpefTaxTotalInEuro * (ivaMode.Iva / 100.0);
            //taxRow.Total = taxRow.Taxable + taxRow.Tax;
        }

        for (const row of taxReport) {
            row.Tax = (row.Taxable * (row.VatPercentage / 100)).Round(2);
            row.Total = row.Taxable + row.Tax;
            taxesTotalInEuro += row.Tax;
        }

        const hasWithholdingTax = this.WithWithholdingTax();

        if (this.loading) return;

        this.WithholdingTaxTotal(
            (hasWithholdingTax
                ? this.calculateWithholdigTax() * (totalToUseForWitholdingTaxInEuro + irpefTaxTotalInEuro)
                : 0
            ).Round(2)
        );
        this.RowsForStampDutyTotal(totalToUseForStampDutyThresholdInEuro);

        const irpefTaxTotalInCurrency = CurrencyUtils.applyCurrencyReverseExchange(
            irpefTaxTotalInEuro,
            documentCurrency
        );
        const nonIrpefTaxTotalInCurrency = CurrencyUtils.applyCurrencyReverseExchange(
            nonIrpefTaxTotalInEuro,
            documentCurrency
        );
        taxableTotalInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(
            taxableTotalInDocumentCurrency + irpefTaxTotalInCurrency + nonIrpefTaxTotalInCurrency,
            documentCurrency
        );

        this.IRPEFTaxTotal(irpefTaxTotalInEuro);
        this.NonIRPEFTaxTotal(nonIrpefTaxTotalInEuro);

        const taxesInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(
            CurrencyUtils.applyCurrencyReverseExchange(taxesTotalInEuro, documentCurrency, true),
            documentCurrency
        );
        const withholdingTaxInDocumentCurrency = CurrencyUtils.applyCurrencyRoundingRules(
            CurrencyUtils.applyCurrencyReverseExchange(this.WithholdingTaxTotal.peek(), documentCurrency),
            documentCurrency
        );

        const taxRelief = this.TaxRelief.peek();
        let taxReliefTotal = 0;
        let taxReliefTotalInCurrency = 0;

        // Contiene il valore totale su cui calcolare l'IVA calcolato per ogni agevolazione fiscale sulla somma dei totali delle righe soggette a IVA associate all'agevolazione fiscale
        const vatTotalsPerTaxRelief: { [taxReliefId: number]: { [vatId: number]: number } } = {};
        const taxReliefRows = taxRelief.groupBy((tr) => tr.TaxReliefId.peek());
        for (const taxReliefId in taxReliefRows) {
            const rows = taxReliefRows[taxReliefId];

            const vatGroups = rows
                .filter((r) => !!r.DocumentRow.peek()?.VatTypeCache.peek())
                .groupBy((v) => v.DocumentRow.peek().VatTypeCache.peek().IdTipoIVA);
            vatTotalsPerTaxRelief[taxReliefId] = {};
            for (const vatId in vatGroups) {
                const vatRows = vatGroups[vatId];
                const rowsTotal = vatRows.sum((t) => t.DocumentRow.peek().TaxableTotal());

                vatTotalsPerTaxRelief[taxReliefId][vatId] = rowsTotal;
            }
        }
        // ----

        for (const taxReliefRow of taxRelief) {
            const taxReliefId = taxReliefRow.TaxReliefId.peek();
            const taxReliefDiscount = taxReliefRow.TaxReliefDiscount.peek();
            const taxReliefDiscountMultiplier = !taxReliefDiscount ? 0 : (1 / 100) * taxReliefDiscount;

            const documentRow = taxReliefRow.DocumentRow.peek();
            const docRowTotalPercentage = taxReliefRow.DocumentRowTotalPercentage.peek();
            const rowPartMultiplier = !docRowTotalPercentage ? 0 : (1 / 100) * docRowTotalPercentage;

            const rowTotalPriceInCurrency = documentRow?.TotalPriceInDocumentCurrency() || 0;
            const rowTotalPrice = documentRow?.TotalPrice() || 0;
            const vatType = documentRow.VatTypeCache.peek();
            let vatPartInCurrency = 0;

            if (vatType.Imponibile === 0) {
                const vatTotal = vatTotalsPerTaxRelief[taxReliefId][vatType.IdTipoIVA];

                if (vatTotal) {
                    const ratio = rowTotalPriceInCurrency / vatTotal;
                    const vat = CurrencyUtils.applyCurrencyRoundingRules(
                        vatTotal * (vatType.Iva / 100),
                        this.DocumentCurrency()
                    );
                    vatPartInCurrency = vat * ratio;
                }
            }

            const rowPartInCurrency = (rowTotalPriceInCurrency + vatPartInCurrency) * rowPartMultiplier;
            const rowPart =
                (rowTotalPrice +
                    CurrencyUtils.applyCurrencyExchange(vatPartInCurrency, this.DocumentCurrency(), true)) *
                rowPartMultiplier;

            taxReliefTotalInCurrency += rowPartInCurrency * taxReliefDiscountMultiplier;
            taxReliefTotal += rowPart * taxReliefDiscountMultiplier;

            taxReliefRow.TotalDiscountInDocumentCurrency(rowPartInCurrency * taxReliefDiscountMultiplier);
            taxReliefRow.TotalDiscount(rowPart * taxReliefDiscountMultiplier);
        }

        taxReliefTotalInCurrency = CurrencyUtils.applyCurrencyRoundingRules(taxReliefTotalInCurrency, documentCurrency);
        taxReliefTotal = taxReliefTotal.Round(2);

        this.TaxReliefTotalInDocumentCurrency(taxReliefTotalInCurrency);
        this.TaxableTotalInDocumentCurrency(taxableTotalInDocumentCurrency);
        this.NonTaxableTotalInDocumentCurrency(nonTaxableTotalInDocumentCurrency);
        this.TaxTotalInDocumentCurrency(taxesInDocumentCurrency);
        this.TotalInDocumentCurrency(
            nonTaxableTotalInDocumentCurrency + taxableTotalInDocumentCurrency + taxesInDocumentCurrency
        );
        this.NetTotalInDocumentCurrency(
            nonTaxableTotalInDocumentCurrency + taxableTotalInDocumentCurrency - taxReliefTotalInCurrency
        );
        this.FinalTotalInDocumentCurrency(
            this.TotalInDocumentCurrency.peek() - withholdingTaxInDocumentCurrency - taxReliefTotalInCurrency
        );
        this.TotalWithTaxReliefDiscountInDocumentCurrency(
            this.TotalInDocumentCurrency.peek() - taxReliefTotalInCurrency
        );

        const taxableTotal = (taxableTotalInEuroForVat + irpefTaxTotalInEuro + nonIrpefTaxTotalInEuro).Round(2);
        this.TaxReliefTotal(taxReliefTotal);
        this.TaxableTotal(taxableTotal);
        this.NonTaxableTotal(nonTaxableTotalInEuro);
        this.TaxTotal(taxesTotalInEuro);
        this.Total(nonTaxableTotalInEuro + taxableTotal + taxesTotalInEuro);
        this.NetTotal(nonTaxableTotalInEuro + taxableTotal - taxReliefTotal);
        this.FinalTotal(this.Total.peek() - this.WithholdingTaxTotal.peek() - taxReliefTotal);
        this.TotalWithTaxReliefDiscount(this.Total.peek() - taxReliefTotal);

        ko.ignoreDependencies(() => {
            this.TaxesReport(taxReport.sort((a, b) => a.Order - b.Order).map(this.createViewModelForTaxRow, this));
        });
    }

    private async handleStateChanges(newState: number): Promise<void> {
        const documentId = this.DocumentId();
        if (this.loading || !documentId || documentId <= 0 || !this.CanHaveState() || !this.ReadOnly()) return;

        if (newState < 0 || newState === null || newState === undefined) {
            this.infoToastService.Error(TextResources.Invoices.MissingStatus);
            return;
        }

        try {
            await this.documentsService.SetDocumentsState(newState, [documentId]);
            this.infoToastService.Success(TextResources.Invoices.DocumentStateChangedSuccess);
        } catch (e) {
            this.infoToastService.Success(TextResources.ProlifeSdk.GenericError);
        }
    }

    public calculateWithholdigTax(): number {
        if (!this.WithholdingTax()) return 0;

        let totalTax = 1;
        const taxes = (this.WithholdingTax() || "").replace(/[^0-9.,]/g, " ");

        taxes
            .split(" ")
            .filter((taxString: string) => taxString.length > 0)
            .map((taxString: string) => numeral(taxString + "%").value())
            .forEach((tax: number) => (totalTax *= tax));

        return totalTax;
    }

    private createViewModelForTaxRow(taxRow: IDocumentBuilderDocumentVatRows) {
        return new DocumentVatRow(taxRow);
    }

    private timeout: ReturnType<typeof setTimeout>;
    private registerChanged = false;
    private jobOrderChanged = false;
    private customerChanged = false;
    private scheduleLoadDefaults(registerChanged: boolean, jobOrderChanged: boolean, customerChanged: boolean) {
        if (this.disableDefaultsLoading) return;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.registerChanged = this.registerChanged || registerChanged;
        this.jobOrderChanged = this.jobOrderChanged || jobOrderChanged;
        this.customerChanged = this.customerChanged || customerChanged;

        this.timeout = setTimeout(() => {
            this.loadDefaults(this.registerChanged, this.jobOrderChanged, this.customerChanged);

            this.registerChanged = false;
            this.jobOrderChanged = false;
            this.customerChanged = false;
        }, 100);
    }

    private async loadSystemDefaults(): Promise<void> {
        const defaults = await this.documentsService.ComputeDefaultsForDocument(
            this.FKRegister(),
            this.Recipient.Id(),
            this.FKJobOrder(),
            this.DocumentType()
        );

        if (!this.DefaultFKVatCode()) {
            this.DefaultFKVatCode(this.CanHaveVAT() ? defaults.FKVatCode : null);
            this.DefaultVatCode(this.CanHaveVAT() ? defaults.VatCode : null);
        }

        if (!this.DefaultFKOffset()) {
            this.DefaultFKOffset(defaults.FKOffset);
            this.DefaultOffsetCode(defaults.OffsetCode);
        }
    }

    private async loadDefaults(registerChanged: boolean, jobOrderChanged: boolean, customerChanged: boolean) {
        const forceUpdate = jobOrderChanged || customerChanged;

        const deferreds = [];
        deferreds.push(
            this.documentsService.ComputeDefaultsForDocument(
                this.FKRegister(),
                this.Recipient.Id(),
                this.FKJobOrder(),
                this.DocumentType()
            )
        );
        deferreds.push(
            this.documentsService.ComputeDefaultMetadatas(this.FKRegister(), this.Recipient.Id(), this.FKJobOrder())
        );

        const deferredsResults = await Promise.all(deferreds);

        const defaults = deferredsResults[0] as IProtocolDefaults;
        if (this.CanHaveShippingInformation()) {
            if (!this.FKCause()) {
                await this.DDTCausesDataSource.selectByIds(defaults.FKCause);
            }

            if (!this.Aspect() || forceUpdate) {
                const aspect = (await this.AspectsDataSource.getById(null, [defaults.FKAspect])).firstOrDefault();
                this.Aspect(aspect?.title);
            }

            if (!this.FKCarriage() || forceUpdate) await this.CarriagesDataSource.selectByIds(defaults.FKCarriage);

            if (!this.Transport() || forceUpdate) {
                const transport = (
                    await this.TransportsDataSource.getById(null, [defaults.FKTransport])
                ).firstOrDefault();
                this.Transport(transport?.title);
            }
        }

        if (this.CanHaveCause()) {
            await this.WarehouseLoadCausesDataSource.selectByIds(defaults.FKCause);
        }

        if (!this.FKExpiryType() || forceUpdate) await this.ExpiriesDataSource.selectByIds(defaults.FKExpiryType);

        if (!this.DefaultFKOffset() || forceUpdate) {
            this.DefaultFKOffset(defaults.FKOffset);
            this.DefaultOffsetCode(defaults.OffsetCode);
        }

        if (!this.FKOutcome() || forceUpdate) await this.OutcomesDataSource.selectByIds(defaults.FKOutcome);

        // 08/04/2021 A. Bicocchi ha chiesto che se è settata una modalità di pagamento sul documento questa non deve essere cambiata mai, anche se cambia il cliente
        if (!this.FKPaymentType()) await this.PaymentModesDataSource.selectByIds(defaults.FKPaymentType);

        if (!this.PaymentIBAN() && defaults.PaymentIBAN) this.PaymentIBAN(defaults.PaymentIBAN);
        if (!this.PaymentABI() && defaults.PaymentABI) this.PaymentABI(defaults.PaymentABI);
        if (!this.PaymentCAB() && defaults.PaymentCAB) this.PaymentCAB(defaults.PaymentCAB);

        if (!this.DefaultFKVatCode() || forceUpdate) {
            this.DefaultFKVatCode(this.CanHaveVAT() ? defaults.FKVatCode : null);
            this.DefaultVatCode(this.CanHaveVAT() ? defaults.VatCode : null);
        }
        if (!this.DocumentCurrency() || forceUpdate) {
            if (this.DocumentCurrency()?.CurrencyId() != defaults.FKCurrency) {
                //La valuta di default sul documento è diversa da quella che sto cercando di impostare... se non ho righe, tutto ok. Se ho delle righe c'è un problema.
                let skipCurrencyChange = false;

                if (this.Rows().length > 0) {
                    const deleteRows = await this.dialogsService.ConfirmAsync(
                        TextResources.Invoices.DifferentCurrencyFromDefaultsAlert,
                        TextResources.Invoices.IgnoreCurrencyFromDefaults,
                        TextResources.Invoices.DropDocumentRowsAndApplyCurrencyFromDefaults
                    );
                    if (deleteRows) {
                        this.Rows([]);
                    } else {
                        skipCurrencyChange = true;
                    }
                }

                if (!skipCurrencyChange) {
                    const currentDefaultCurrency = this.DocumentCurrency();
                    const currency = this.documentsService.DocumentCurrenciesFactory.create(
                        this.DocumentId(),
                        this.DocumentType(),
                        defaults.FKCurrency
                    );
                    currency.IsDocumentCurrency(true);
                    this.DocumentCurrencies.push(currency);
                    this.DocumentCurrencies.remove(currentDefaultCurrency);

                    this.DocumentCurrencySymbol(this.DocumentCurrency()?.Currency()?.Symbol);
                }
            }
        }
        if ((!this.NumberPrintChoice() || forceUpdate) && !!defaults.NumberPrintChoice)
            this.NumberPrintChoice(defaults.NumberPrintChoice);
        if ((!this.VersionNumberGenerationMode() || forceUpdate) && !!defaults.VersionNumberGenerationMode)
            this.VersionNumberGenerationMode(defaults.VersionNumberGenerationMode);

        if (this.CanReferenceArticles() && this.IsWarehouseEnabled) {
            if (this.CanHaveSourceWarehouse() && !this.SourceWarehouseId())
                await this.SourceWarehousesDocumentDataSource.selectDefaultWarehouse();

            if (this.CanHaveDestinationWarehouse() && !this.WarehouseId())
                await this.DestinationWarehousesDocumentDataSource.selectDefaultWarehouse();
        }

        this.SendDocumentMail(defaults.SendDocumentMail);
        this.IncludeDocumentAttachmentsInMail(defaults.IncludeDocumentAttachmentsInMail);

        const defaultMetadatas = deferredsResults[1] as IDefaultMetadatas[];
        this.applyDefaultMetadatas(defaultMetadatas);
    }

    private applyDefaultMetadatas(defaultMetadatas: IDefaultMetadatas[]) {
        let actualMetadatas = this.Metadatas();

        for (let i = 0; i < actualMetadatas.length; i++) {
            const actualMetadata = actualMetadatas[i];
            const defMet = defaultMetadatas.firstOrDefault((m) => m.MetadataId === actualMetadata.MetadataId());
            if (!defMet && (actualMetadata.MetadataValue() === null || actualMetadata.MetadataValue() === undefined)) {
                actualMetadatas.remove(actualMetadata);
                i--;
                continue;
            }
        }

        const newMetadatas = [];
        for (const defaultMetadata of defaultMetadatas) {
            if (actualMetadatas.firstOrDefault((m) => m.MetadataId() === defaultMetadata.MetadataId)) continue;

            newMetadatas.push(
                new DocumentMetadata(this.MetadatasDataSource, {
                    Id: null,
                    MetadataId: defaultMetadata.MetadataId,
                    MetadataListValues: [],
                    MetadataValueType: defaultMetadata.MetadataValueType as MetadataType,
                    ShowMarkerOnAllocationsGantt: defaultMetadata.ShowMarkerOnAllocationsGantt,
                    MetadataValue: null,
                })
            );
        }

        actualMetadatas = actualMetadatas.concat(newMetadatas);

        actualMetadatas.sort((a, b) => {
            const orderOfA = defaultMetadatas.firstOrDefault((m) => m.MetadataId === a.MetadataId())?.Order ?? 1000;
            const orderOfB = defaultMetadatas.firstOrDefault((m) => m.MetadataId === b.MetadataId())?.Order ?? 1000;
            return orderOfA - orderOfB;
        });

        this.Metadatas(actualMetadatas);
    }

    private getTaxesReportForVatCode(
        taxesReportMap: { [vatCode: string]: IDocumentBuilderDocumentVatRowsExt },
        taxReport: IDocumentBuilderDocumentVatRows[],
        vatType: IIvaMode
    ): IDocumentBuilderDocumentVatRows {
        const vatCode = vatType.CodiceIVA;

        if (taxesReportMap[vatCode] == undefined) {
            const order = (taxReport.max((t) => t.Order) ?? 0) + 1;

            taxesReportMap[vatCode] = {
                FKDocument: this.DocumentId(),
                FKVatCode: vatType.IdTipoIVA,
                Order: order,
                VatDescription: vatType.CodiceIVA + " - " + vatType.Descrizione,
                Taxable: 0,
                VatPercentage: vatType.Iva,
                Tax: 0,
                Total: 0,
                Nature: vatType.Nature,
                VatPaymentMode: vatType.ChargeableVat,
                VatRegulationsCode: vatType.VatRegulationsCode,
                IsTaxable: vatType.Imponibile === 0,
                RequireLetterOfAttempt: vatType.RequireLetterOfAttempt,
                TaxableInDocumentCurrency: 0,
                TaxInDocumentCurrency: 0,
                TotalInDocumentCurrency: 0,
            };
            taxReport.push(taxesReportMap[vatCode]);
        }

        return taxesReportMap[vatCode];
    }

    private configureActions() {
        const debug = false;

        this.EditActions.push({
            ActionText: TextResources.ProlifeSdk.Abort,
            Visible: ko.computed(() => !this.ReadOnly()),
            CanRun: ko.computed(() => !this.ReadOnly()),
            Icon: "fa fa-undo",
            Class: "btn-default",
            Run: this.undo.bind(this),
        });

        this.EditActions.push({
            ActionText: TextResources.ProlifeSdk.Delete,
            Visible: ko.computed(
                () =>
                    !this.ReadOnly() && this.CanBeDeleted() && !!this.DocumentId() && !this.Blocked() && !this.Saving()
            ),
            CanRun: ko.computed(
                () => !this.ReadOnly() && this.CanBeDeleted() && !!this.DocumentId() && !this.Blocked()
            ),
            Icon: "fa fa-trash-o",
            Class: "btn-danger",
            Run: this.deleteDocument.bind(this),
        });

        this.EditActions.push({
            ActionText: TextResources.ProlifeSdk.Save,
            Visible: ko.computed(() => !this.ReadOnly()),
            CanRun: ko.computed(() => !this.ReadOnly()),
            Icon: "fa fa-floppy-o",
            Class: "btn-success",
            Run: this.save.bind(this),
        });

        this.EditActions.push({
            ActionText: TextResources.ProlifeSdk.Edit,
            Visible: ko.computed(() => this.ReadOnly() && this.CanEnterEditMode()),
            CanRun: ko.computed(() => this.ReadOnly() && this.CanEnterEditMode()),
            Icon: "fa fa-pencil",
            Class: "btn-success",
            Run: async () => {
                this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading, true);
                this.getAndSetWarehouseEntitiesStockInfoOnRows(this.Rows());

                setTimeout(() => {
                    try {
                        this.ReadOnly(false);

                        this.Listener()?.OnEdit(this);
                    } finally {
                        this.dialogsService.UnlockUI();
                    }
                }, 100);
            },
        });

        this.EditActions.push({
            ActionText: TextResources.ProlifeSdk.Close,
            Visible: ko.computed(() => this.ReadOnly()),
            CanRun: ko.computed(() => this.ReadOnly()),
            Icon: "fa fa-times",
            Class: "btn-default",
            Run: this.Close.bind(this),
        });

        const actionsGroup: IDocumentAction = {
            ActionText: TextResources.Invoices.OtherDocumentActions,
            IsGroup: true,
            CanRun: ko.computed(() => true),
            Icon: "fa-ellipsis-h",
            Visible: ko.computed(() => true),
            Run: () => {},
            Actions: ko.observableArray([
                {
                    ActionText: TextResources.ProlifeSdk.Map,
                    Run: this.OpenReferencesMapDialog.bind(this),
                    CanRun: ko.computed(() => true),
                    Visible: ko.computed(() => true),
                    Icon: "fa-chain",
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.DocumentEconomics,
                    Run: () => {},
                    CanRun: ko.computed(() => true),
                    Visible: ko.computed(() => true),
                    Icon: "",
                    IsGroup: false,
                    render: () => {
                        return (
                            <span className="dropdown-label with-divider">
                                {TextResources.Invoices.DocumentEconomics}
                            </span>
                        );
                    },
                },
                {
                    ActionText: TextResources.ProlifeSdk.ReviewPrices,
                    Run: this.showApprovePopup.bind(this),
                    CanRun: this.ReadOnly,
                    Visible: this.ReadOnly,
                    Icon: "fa-bookmark",
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.Expirations,
                    Run: () => {
                        this.schedulesService.ShowInvoiceSchedulesDialog(this.DocumentId(), this.DocumentType());
                    },
                    CanRun: ko.computed(() => debug || (this.ReadOnly() && this.CanHaveSchedules())),
                    Visible: ko.computed(() => debug || (this.ReadOnly() && this.CanHaveSchedules())),
                    Icon: "fa-calendar",
                    Class: "blue",
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.CurrenciesManager,
                    Run: this.showCurrenciesManager.bind(this),
                    CanRun: this.CurrenciesEnabled,
                    Visible: this.CurrenciesEnabled,
                    Icon: "fa fa-money",
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.Warehouse,
                    Run: () => {},
                    CanRun: ko.computed(() => true),
                    Visible: ko.computed(() => true),
                    Icon: "",
                    IsGroup: false,
                    render: () => {
                        return <span className="dropdown-label with-divider">{TextResources.Invoices.Warehouse}</span>;
                    },
                },
                {
                    ActionText: TextResources.Invoices.Article,
                    Icon: "fa-plus",
                    Run: this.createNewWarehouseArticle.bind(this),
                    CanRun: ko.computed(() => this.authorizationService.isAuthorized("Warehouse_Start")),
                    Visible: ko.computed(() => this.authorizationService.isAuthorized("Warehouse_Start")),
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.ReloadArticlesDescriptions,
                    Icon: "fa-refresh",
                    Run: this.updateWarehouseArticleCodes.bind(this),
                    CanRun: ko.computed(
                        () => this.authorizationService.isAuthorized("Warehouse_Start") && !this.ReadOnly()
                    ),
                    Visible: ko.computed(
                        () => this.authorizationService.isAuthorized("Warehouse_Start") && !this.ReadOnly()
                    ),
                    IsGroup: false,
                },
                {
                    ActionText: TextResources.Invoices.CreateWarehouseInspection,
                    Icon: "fa-plus",
                    Run: this.createWarehouseInspection.bind(this),
                    CanRun: ko.computed(() => this.CanCreateWarehouseInspections() && this.ReadOnly()),
                    Visible: ko.computed(() => this.CanCreateWarehouseInspections() && this.ReadOnly()),
                    IsGroup: false,
                },
            ]),
        };

        this.Actions.push(actionsGroup);

        this.Actions.push({
            ActionText: ProlifeSdk.TextResources.ProlifeSdk.DefaultValues,
            Run: this.scheduleLoadDefaults.bind(this, true, !!this.FKJobOrder(), !!this.Recipient.Id()),
            CanRun: ko.computed(() => !this.ReadOnly()),
            Visible: ko.computed(() => !this.ReadOnly()),
            Icon: "fa-eraser",
            IsGroup: false,
        });

        this.Actions.push({
            ActionText: "" /*ProlifeSdk.TextResources.ProlifeSdk.Print*/,
            Run: this.print.bind(this),
            CanRun: this.ReadOnly,
            Visible: this.ReadOnly,
            Icon: "fa-print",
            IsGroup: false,
        });

        this.Actions.push({
            ActionText: "" /*ProlifeSdk.TextResources.ProlifeSdk.Print*/,
            Run: () => {},
            CanRun: this.ReadOnly,
            Visible: this.ReadOnly,
            Icon: "fa-print",
            IsGroup: false,
            render: () => {
                const doc = this;
                return (
                    <ko-bind data-bind={{ if: doc.CanUseNewPrint && doc.ReadOnly() }}>
                        <ko-bind data-bind={{ tsxtemplate: doc.ReportPrintButton }} />
                    </ko-bind>
                );
            },
        });

        this.Actions.push({
            ActionText: TextResources.Invoices.ExportElectronicInvoice,
            Run: this.exportElectronicInvoice.bind(this),
            CanRun: ko.computed(
                () =>
                    debug ||
                    (this.ReadOnly() &&
                        this.CanBeAnElectronicInvoice() &&
                        this.CanExportElectronicInvoices() &&
                        this.ElectronicInvoice())
            ),
            Visible: ko.computed(
                () =>
                    debug ||
                    (this.ReadOnly() &&
                        this.CanBeAnElectronicInvoice() &&
                        this.CanExportElectronicInvoices() &&
                        this.ElectronicInvoice())
            ),
            Icon: "fa-share-square-o",
            IsGroup: false,
        });

        this.Actions.push({
            ActionText: ProlifeSdk.TextResources.ProlifeSdk.Close,
            Run: this.CloseAllRowsManually.bind(this),
            CanRun: ko.computed(() => !this.ReadOnly() && this.Rows().any((r) => r.CanBeClosedButIsOpen())),
            Visible: ko.computed(() => !this.ReadOnly() && this.Rows().any((r) => r.CanBeClosedButIsOpen())),
            Icon: "fa-lock",
            IsGroup: false,
        });

        if (this.documentsService.getDataWizardStepsForDocumentType(this.DocumentType()).length > 0)
            this.Actions.push({
                ActionText: TextResources.ProlifeSdk.Import,
                Run: this.importData.bind(this),
                CanRun: ko.computed(() => debug || !this.ReadOnly()),
                Visible: ko.computed(() => debug || (!this.ReadOnly() && !this.Saving())),
                Icon: "fa-magic",
                Class: "blue",
                IsGroup: false,
            });

        this.Actions.push({
            ActionText: "",
            Icon: "fa-euro",
            Run: this.chooseDiscountCatalog.bind(this),
            CanRun: ko.computed(
                () => !this.ReadOnly() && !this.Blocked() && !this.CanCopy() && this.IsCustomerDocument()
            ),
            Visible: ko.computed(() => !this.ReadOnly() && this.IsCustomerDocument()),
            IsGroup: false,
        });

        this.Actions.push({
            ActionText: TextResources.ProlifeSdk.Layout,
            Icon: "fa fa-file-text-o",
            Run: (item: any, event: Event) =>
                this.dialogsService.ShowPopoverComponent(
                    event.currentTarget as HTMLElement,
                    {
                        componentName: "document-layout-selection",
                        model: {
                            Document: this,
                        },
                        title: TextResources.Invoices.LayoutSelectionMenuTitle,
                        params: "Document: Document",
                    },
                    "bottom"
                ),
            CanRun: ko.computed(() => !this.ReadOnly()),
            Visible: ko.computed(() => !this.ReadOnly()),
            IsGroup: false,
        });
    }

    private async OpenReferencesMapDialog() {
        const vm = new ReferencesMapViewer(this.DocumentId(), this.DocumentType(), -1, this.Recipient.Id());
        return this.dialogsService.ShowModal<void>(
            vm,
            "fullscreen doc-references-map",
            null,
            vm.templateUrl,
            vm.templateName
        );
    }

    private async showApprovePopup() {
        const dialog = new ApproveDocumentDialog(this.DocumentId(), this.DocumentType(), this.Recipient.Id());
        dialog.setEditMode(!this.Blocked());

        return this.dialogsService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    private async createNewWarehouseArticle(): Promise<void> {
        const createNewArticleDialog = new CreateNewArticleDialog();
        return createNewArticleDialog.loadAndShow();
    }

    private updateWarehouseArticleCodes(): Promise<void> {
        return this.updateWarehouseArticleCodesOnRows(this.Rows());
    }

    private async createWarehouseInspection(): Promise<void> {
        try {
            const fullWarehouseInspection: IFullWarehouseInspection = {
                WarehouseInspection: [],
                WarehouseInspectionOperations: [],
                WarehouseInspectionOperationsSources: [],
                DocumentsGenerated: null,
                LastDocumentsGenerationError: null,
            };

            const inspection = {
                Id: this.warehouseInspectionsService.GetNextFakeId(),
                Title: String.format(
                    TextResources.Invoices.DocumentName,
                    this.DocumentTypeLabel(),
                    this.Number(),
                    moment(this.Date()).format("L")
                ),
                Status: WarehouseInspectionStatus.Draft,
                CreationDate: null,
                CreationUserId: null,
                LastModificationDate: null,
                LastModificationUserId: null,
                AutomaticDocumentGeneration: true,
            };

            const articles = await this.warehouseInspectionsService.GetArticlesRequirementsFromDocument(
                this.DocumentId()
            );

            const operationSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[] = [];
            const operations: IFullWarehouseInspectionWarehouseInspectionOperations[] = [];

            let lastOperation: IFullWarehouseInspectionWarehouseInspectionOperations;

            for (const article of articles) {
                if (
                    !lastOperation ||
                    lastOperation.FKArticle !== article.ArticleId ||
                    lastOperation.FKDestinationWarehouse !== article.DestinationWarehouseId
                ) {
                    lastOperation = FullWarehouseInspectionFactory.newFullWarehouseInspectionOperation(inspection.Id);
                    operations.push(lastOperation);

                    lastOperation.Id = this.warehouseInspectionsService.GetNextFakeId();
                    lastOperation.FKArticle = article.ArticleId;
                    lastOperation.ArticleDescription = article.ArticleDescription;
                    lastOperation.ArticleCode = article.ArticleCode;
                    lastOperation.MefCode = article.MefCode;
                    lastOperation.EanCode = article.EanCode;
                    lastOperation.FKSourceWarehouse = article.SourceWarehouseId;
                    lastOperation.SourceWarehouse = article.SourceWarehouse;
                    lastOperation.FKDestinationWarehouse = article.DestinationWarehouseId;
                    lastOperation.DestinationWarehouse = article.DestinationWarehouse;
                    lastOperation.JobOrderId = article.JobOrderId;
                    lastOperation.JobOrderName = article.JobOrder;
                    lastOperation.CustomerId = article.CustomerId;
                    lastOperation.CustomerName = article.Customer;
                    lastOperation.OperationType =
                        article.SourceWarehouseId === article.DestinationWarehouseId
                            ? WarehouseInspectionAction.StockCheck
                            : WarehouseInspectionAction.WarehouseTransfer;
                }

                lastOperation.RequestedAmount += article.RequestedAmount;

                operationSources.push({
                    FKDocument: this.DocumentId(),
                    FKInspectionOperation: lastOperation.Id,
                    FKRef: article.RefId,
                    RefAmount: article.RequestedAmount,
                    DocumentDate: this.Date(),
                    DocumentNumber: this.Number(),
                    DocumentLabel: this.DocumentTypeLabel(),
                    EntityType: this.DocumentType(),
                    FKRegister: this.RegisterCache.IdRegistroIVA,
                    RegisterName: this.RegisterCache.NomeRegistroIVA,
                });
            }

            fullWarehouseInspection.WarehouseInspection.push(inspection);
            fullWarehouseInspection.WarehouseInspectionOperations = operations;
            fullWarehouseInspection.WarehouseInspectionOperationsSources = operationSources;

            await this.warehouseInspectionsService.ShowWarehouseInspectionEditor(fullWarehouseInspection);
        } catch (e) {
            console.log(e);
        }
    }

    private async CloseAllRowsManually() {
        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.ProlifeSdk.ConfirmCloseOpenRows,
                TextResources.ProlifeSdk.DoNotCloseOpenRows,
                TextResources.ProlifeSdk.CloseOpenRows
            ))
        )
            return;

        this.Rows().forEach((r) => {
            r.ManuallyClosed(true);
            r.ClosedAmount(r.Amount());
        });
    }

    public print() {
        this.configuration.print(this.DocumentId());
    }

    public printNew() {
        //this.configuration.printNew(this.DocumentId());
    }

    private async deleteDocument() {
        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.ProlifeSdk.ConfirmDeleteDocument,
                TextResources.ProlifeSdk.DoNotDeleteDocument,
                TextResources.ProlifeSdk.DeleteDocument
            ))
        )
            return;

        try {
            this.dialogsService.LockUI("Eliminazione documento in corso...", true);

            await this.documentsService.DeleteDocument(this.DocumentId(), this.DocumentType());
            this.Listener()?.OnClose(this);
        } catch (e) {
            if (await this.handleValidationException(null, e)) return;

            this.onAfterSave();
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    private async undo() {
        if (!this.DocumentId() || this.DocumentId() <= 0) {
            await this.Close();
            return;
        }

        if (
            !(await this.dialogsService.ConfirmAsync(
                TextResources.Invoices.UnsavedChanges + "\r\n" + TextResources.Invoices.ContinueMsg,
                TextResources.Invoices.KeedEditing,
                TextResources.Invoices.LooseChanges
            ))
        )
            return;

        this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading, true);

        setTimeout(async () => {
            try {
                this.ReadOnly(true);

                this.Listener()?.OnEndEdit(this);
            } finally {
                this.dialogsService.UnlockUI();
            }

            await this.load(this.document);
        }, 100);
    }

    private configureValidations() {
        this.validator = this.validationService
            .createValidator<Document>()
            .isNotNullOrUndefined(
                (d) => d.FKPaymentType(),
                TextResources.ProlifeSdk.InsertPaymentMode,
                () => this.RegisterCache?.PaymentAndExpireModeRequired
            )
            .isNotNullOrUndefined(
                (d) => d.FKExpiryType(),
                TextResources.ProlifeSdk.InsertExpiryType,
                () => this.RegisterCache?.PaymentAndExpireModeRequired
            )
            .isNotNullOrUndefined(
                (d) => d.FKValidityType(),
                TextResources.ProlifeSdk.InsertDocumentValidity,
                () => this.RegisterCache?.ValidityRequired
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (d) => d.PaymentIBAN(),
                TextResources.ProlifeSdk.InsertPaymentIBAN,
                () => this.Payment() && this.Payment().AssociaBanca == 2
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (d) => d.PaymentABI(),
                TextResources.ProlifeSdk.InsertPaymentABI,
                () => this.Payment() && this.Payment().AssociaBanca == 1
            )
            .isNotNullOrUndefinedOrWhiteSpace(
                (d) => d.PaymentCAB(),
                TextResources.ProlifeSdk.InsertPaymentCAB,
                () => this.Payment() && this.Payment().AssociaBanca == 1
            )
            .isNotNullOrUndefined(
                (d) => d.VatRegisterNumber(),
                TextResources.ProlifeSdk.DocumentNumberRequiredIfOnEdit,
                () => this.DocumentId() > 0
            )
            .isNotNullOrUndefined(
                (d) => d.SourceWarehouseId(),
                TextResources.Invoices.OriginWarehouse,
                () => this.IsWarehouseEnabled && this.CanReferenceArticles() && this.CanHaveSourceWarehouse()
            )
            .isNotNullOrUndefined(
                (d) => d.WarehouseId(),
                TextResources.Invoices.DestinationWarehouse,
                () => this.IsWarehouseEnabled && this.CanReferenceArticles() && this.CanHaveDestinationWarehouse()
            )
            .isNotNullOrUndefined(
                (d) => d.FKState(),
                TextResources.Invoices.MissingStatus,
                () => this.CanHaveState()
            )
            .isNotNullOrUndefined(
                (d) => d.FKCause(),
                TextResources.Invoices.Reason,
                () => this.CanHaveShippingInformation() || this.CanHaveCause()
            )
            .isNotNullOrUndefined(
                (d) => d.ReasonForPayment(),
                TextResources.Invoices.ReasonForPaymentRequired,
                () => this.ElectronicInvoice() && this.WithWithholdingTax()
            )
            .isTrue((d) => d.Rows().length > 0, TextResources.Invoices.MissingRows);
    }

    public async Close() {
        if (!this.ReadOnly()) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.Invoices.UnsavedChanges + "\r\n" + TextResources.Invoices.ContinueMsg,
                    TextResources.Invoices.KeedEditing,
                    TextResources.Invoices.LooseChanges
                ))
            )
                return;
        }

        this.Listener()?.OnClose(this);
    }

    async getDestinationPathForAttachments(): Promise<string> {
        const protocolName = this.replaceInvalidDocRepositoryChar(
            this.RegisterCache.NomeRegistroIVA || ProlifeSdk.TextResources.ProlifeSdk.UnknownProtocol
        );
        const documentNumber =
            this.replaceInvalidDocRepositoryChar(this.Number() || ProlifeSdk.TextResources.ProlifeSdk.Other) +
            ProlifeSdk.TextResources.ProlifeSdk.DocAttachmentsSuffix;

        const documentDate = this.Date();
        const exercise = this.exercisesManager
            .getExercises()
            .firstOrDefault((e) => e.StartDay <= documentDate && e.EndDay >= documentDate);

        const exercisePart = !exercise
            ? ProlifeSdk.TextResources.ProlifeSdk.UnknownFiscalYear
            : moment(exercise.StartDay).format("DD.MM.YYYY") + " - " + moment(exercise.EndDay).format("DD.MM.YYYY");
        const destinationPath = String.format(
            ProlifeSdk.TextResources.ProlifeSdk.AdministrativeDocumentsFolderDefault,
            exercisePart,
            protocolName,
            documentNumber
        );

        if ((this.Number() || "").length > 0 && (this.RegisterCache.NomeRegistroIVA || "").length > 0 && !!exercise)
            return destinationPath;

        const confirm = await this.dialogsService.ConfirmAsync(
            String.format(ProlifeSdk.TextResources.ProlifeSdk.ConfirmAttachFile, destinationPath),
            ProlifeSdk.TextResources.ProlifeSdk.DoAttachFile,
            ProlifeSdk.TextResources.ProlifeSdk.DoNotAttachFile
        );
        if (confirm) return "";

        return destinationPath;
    }

    private replaceInvalidDocRepositoryChar(pathComponent: string): string {
        return pathComponent.trim().replace(new RegExp("/", "g"), "_");
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender == this.PaymentModesDataSource) {
            this.Payment(model?.model);
        } else if (sender == this.CompanyIBANDataSource) {
            const ibanModel = model as ICompanyIBANDataSourceModel;
            this.PaymentBankName(ibanModel?.bank.Name);
        } else if (sender == this.CustomerABIDataSource) {
            const abiModel = model as CustomerABIDataSourceModel;
            this.PaymentBankName(abiModel?.model.Name);
        } else if (sender == this.CustomerCABDataSource) {
            const cabModel = model as CustomerCABDataSourceModel;
            this.PaymentBankName(cabModel?.model.Name);
        } else if (sender == this.ExpiriesDataSource) {
            const expireModel = model as IExpiriesDataSourceModel;
            this.FKExpiryType(expireModel?.id);
            this.ExpiryTypeCache = expireModel?.model;
        } else if (sender == this.JobOrdersDataSource) {
            this.JobOrderName((model as IJobOrderDataSourceModel)?.model.Name);
            this.JobOrder((model as IJobOrderDataSourceModel)?.model);
            this.FKJobOrder(model?.id as number);
        } else if (sender == this.CommercialResourcesDataSource) {
            this.CommercialResponsibleName(model?.title);
        } else if (sender == this.AdministrativeResourcesDataSource) {
            this.AdministrativeResponsibleName(model?.title);
        } else if (sender == this.SourceWarehousesDocumentDataSource) {
            const wh = model as IWarehouseDataSourceModel;
            this.SourceWarehouseCache = wh?.model;
            this.SourceWarehouseName(wh?.title);
            this.onWarehouseLoaded();
        } else if (sender == this.DestinationWarehousesDocumentDataSource) {
            this.DestinationWarehouseName(model?.title);
        } else if (sender == this.DDTCausesDataSource) {
            const causeModel = model as IDDTCausesDataSourceModel;
            const cause = causeModel?.model;

            this.CauseLogicType(cause?.Tipologia);
            this.Cause(cause?.Descrizione);
            this.Recipient.MustBeCompany(cause?.Tipologia == DocumentCauseLogicType.Transfer);

            this.handleTransportCause(cause);
        } else if (sender == this.WarehouseLoadCausesDataSource) {
            const causeModel = model as IWarehouseLoadReasonForShipmentModel;
            const cause = causeModel?.model;

            this.CauseLogicType(0); // TODO
            this.Cause(cause?.Description);
        } /* else if (sender == this.AspectsDataSource) { // Non più necessario prché il select2 è stato sostituito con il typeahead, il quale setta direttamente il titolo
            this.Aspect(model?.title);
        } else if (sender == this.TransportsDataSource) {
            this.Transport(model?.title);
        } */ else if (sender == this.CarriagesDataSource) {
            this.Carriage(model?.title);
        } else if (sender == this.EstimateStatesDataSource) {
            this.StateName(model?.title);
        } else if (sender === this.DocumentValidityTypesDataSource) {
            this.ValidityType(model?.title);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {}

    onDocumentCurrenciesEditEnd(): void {
        const documentCurrency = this.DocumentCurrency();

        this.DocumentCurrencySymbol(documentCurrency?.Currency()?.Symbol);

        // TODO aggiorna le informazioni di valuta sulle righe
        // Valutare bene cosa fare, soprattutto nel caso di documenti importati in altri
        /* let rows = this.Rows();
        for (let row of rows) {
            row.onDocumentCurrencyChanges()
        } */

        this.updateTotals();
    }

    OnEntitySearchOnRowStarted(provider: IDocumentRowInlineReferenceProviderFactory, query: string) {}
    OnEntitySearchOnRowFinished(provider: IDocumentRowInlineReferenceProviderFactory, query: string) {}

    private onWarehouseLoaded() {
        if (!this.SourceWarehouseCache || this.SourceWarehouseCache.JobOrderId == this.FKJobOrder()) {
            this.WarehouseWorkflowId = this.SourceWarehouseCache?.WorkflowId;
            this.Rows().forEach((r) => r.setDefaultWorkflow(this.SourceWarehouseCache?.WorkflowId, !this.ReadOnly()));
        }
    }

    private GetFooterForNewDocument() {
        return String.format(
            ProlifeSdk.TextResources.ProlifeSdk.DefaultDocumentFooter,
            this.CompanyCache.RagioneSociale || "",
            this.CompanyCache.Indirizzo || "",
            this.CompanyCache.CAP || "",
            this.CompanyCache.Citta || "",
            this.CompanyCache.Provincia || "",
            this.CompanyCache.Telefono || "",
            this.CompanyCache.Fax || "",
            this.CompanyCache.Mail || "",
            this.CompanyCache.Website || "",
            this.CompanyCache.PIVA || "",
            this.CompanyCache.RegImprese || "",
            this.CompanyCache.REA || "",
            numeral(this.CompanyCache.CapSociale || 0).format("0,0[.]00 $")
        );
    }

    async reload() {
        let document: IDocumentBuilderDocument;
        if (this.DocumentId() && this.DocumentId() > 0)
            document = await this.documentsService.GetDocument(this.DocumentId(), this.DocumentType());
        await this.load(document);
    }

    async load(document: IDocumentBuilderDocument) {
        this.loading = true;

        this.StateFieldUnlocked(false);

        this.document = document;
        if (document) {
            this.registerId = document.Document.FKRegister;
            this.DocumentType(document.Document.EntityType);
            this.CanBeDeleted((document.ReferencingRows || []).length === 0);

            this.configureCanEnterEditMode();
        }

        this.FKRegister(this.registerId);

        if (!document) {
            this.LayoutId(this.RegisterCache?.DefaultLayoutId ?? DocumentLayouts.Standard);
            this.CompanyLogo(this.RegisterCache?.Logo || this.CompanyCache.Logo);
            this.HeaderImage(this.RegisterCache?.HeaderImage);
            this.HtmlFooter(this.RegisterCache?.HtmlFooter || this.GetFooterForNewDocument());
            this.Notes(this.RegisterCache?.Notes || "");
            this.NotesTrigger.valueHasMutated();
            this.AdministrativeNotes("");
            this.AdministrativeNotesTrigger.valueHasMutated();

            this.setElectronicInvoiceTypeCode();
            this.loading = false;

            if (this.requestedFkJobOrder > 0) await this.JobOrdersDataSource.selectByIds(this.requestedFkJobOrder);
            else await this.loadDefaults(true, false, false);

            return;
        }

        this.DocumentId(document.Document.Id);

        this.HeaderImage(document.Details.HeaderImage);
        this.CompanyLogo(document.Details.CompanyLogo);
        this.ElectronicInvoice(document.Document.ElectronicInvoicing);
        if (document.Document.ElectronicInvoiceTypeCode)
            await this.ElectronicInvoiceTypesDataSource.selectByIds(document.Document.ElectronicInvoiceTypeCode);
        this.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(
            document.Details.IncludeAttachmentsAndDocumentInElectronicInvoiceExport
        );
        this.ShowImportedDocumentsTableOnPrintedDocument(document.Details.ShowImportedDocumentsTableOnPrintedDocument);
        this.DownPayment(document.Document.DownPayment);
        this.SecondaryRecipientsEnabled(document.Details.SecondaryRecipientsEnabled);
        this.ForPublicAdministration(document.Document.ForPublicAdministration);
        this.WithWithholdingTax(document.Details.HasWithholdingTax);
        this.ShowAmounts(document.Details.ShowAmounts);
        this.LayoutId(document.Details.LayoutId);
        this.Blocked(document.Document.Blocked);

        this.FKFiscalYear(document.Document.FKFiscalYear);
        this.Date(document.Document.Date);
        this.NumberPrefix(document.Document.NumberPrefix);
        this.Number(document.Document.Number);
        this.NumberSuffix(document.Document.NumberSuffix);
        this.VatRegisterNumber(document.Document.VatRegisterNumber);

        this.FullVersionRevisionNumber(document.Document.FullVersionRevisionNumber);
        this.VersionRevisionNumberPrefix(document.Document.VersionRevisionNumberPrefix);
        this.VersionRevisionNumberSuffix(document.Document.VersionRevisionNumberSuffix);
        this.VersionRevisionNumberSeparator(document.Document.VersionRevisionNumberSeparator);
        this.VersionNumber(document.Document.VersionNumber);
        this.VersionRevision(document.Document.VersionRevision);
        this.VersionNumberGenerationMode(document.Document.VersionNumberGenerationMode);
        this.NumberPrintChoice(document.Document.NumberPrintChoice);

        this.FKOrganizationalUnit(document.Document.FKRecipientOrganizationalUnit);

        await this.Recipient.loadFromDocument(document, false);
        this.RecipientCode(document.Details.RecipientCode);
        this.RecipientCodeData(this.Recipient.getRecipientCodeById(document.Details.RecipientCodeId));
        this.RecipientCodePEC(document.Details.RecipientCodePEC);
        await this.DestinationRecipient.loadFromDocument(document, true);

        await this.PaymentModesDataSource.selectByIds(document.Document.FKPaymentType);
        this.PaymentIBAN(document.Details.PaymentIBAN);
        this.PaymentCAB(document.Details.PaymentCAB);
        this.PaymentABI(document.Details.PaymentABI);
        this.PaymentBankName(document.Details.PaymentBankName);

        this.FKExpiryType(document.Document.FKExpiryType);
        this.ExpiryType(document.Document.ExpiryType);

        await this.JobOrdersDataSource.selectByIds(document.Document.FKJobOrder);
        this.JobOrderName(document.Document.JobOrderName);

        this.FKCommercialResponsible(
            !document.Document.FKCommercialResponsible && document.Document.CommercialResponsibleName
                ? document.Document.CommercialResponsibleName
                : document.Document.FKCommercialResponsible
        );
        this.CommercialResponsibleName(document.Document.CommercialResponsibleName);

        this.FKAdministrativeResponsible(
            !document.Document.FKAdministrativeResponsible && document.Document.AdministrativeResponsibleName
                ? document.Document.AdministrativeResponsibleName
                : document.Document.FKAdministrativeResponsible
        );
        this.AdministrativeResponsibleName(document.Document.AdministrativeResponsibleName);

        this.FKOutcome(document.Document.FKOutcome);

        this.FKState(document.Document.FKEstimateState);
        this.StateName(document.Document.EstimateStateName);

        this.ReferenceNumber(document.Document.ReferenceNumber);
        this.ReferenceDate(document.Document.ReferenceDate);
        this.ExternalReference(document.Document.ExternalReference);

        this.FKCause(document.Document.FKCause);
        this.CauseLogicType(document.Document.CauseLogicType);
        this.Cause(document.Document.Cause);

        if (this.CanReferenceArticles()) {
            if (this.CanHaveSourceWarehouse() && document.Document.SourceWarehouseId)
                await this.SourceWarehousesDocumentDataSource.selectByIds(document.Document.SourceWarehouseId);
            if (this.CanHaveDestinationWarehouse() && document.Document.WarehouseId)
                await this.DestinationWarehousesDocumentDataSource.selectByIds(document.Document.WarehouseId);
        }

        this.Notes(document.Details.Notes);
        this.NotesTrigger.valueHasMutated();
        this.AdministrativeNotes(document.Details.AdministrativeNotes);
        this.AdministrativeNotesTrigger.valueHasMutated();

        this.IRPEFTax(document.Details.IRPEFTax);
        this.IRPEFTaxDescription(document.Details.IRPEFTaxDescription);
        this.IRPEFTaxTotal(document.Details.IRPEFTaxTotal);
        this.NonIRPEFTax(document.Details.NonIRPEFTax);
        this.NonIRPEFTaxDescription(document.Details.NonIRPEFTaxDescription);
        this.NonIRPEFTaxTotal(document.Details.NonIRPEFTaxTotal);

        this.TaxableTotal(document.Document.TaxableTotal);
        this.TaxTotal(document.Document.VAT);
        this.NonTaxableTotal(document.Document.NotTaxableTotal);
        this.Total(document.Document.Total);
        this.NetTotal(document.Document.NetTotal);
        this.FinalTotal(document.Document.FinalTotal);
        this.TaxReliefTotal(document.Document.TaxReliefTotal);
        this.TotalWithTaxReliefDiscount(document.Document.TotalWithTaxReliefDiscount);

        this.TaxableTotalInDocumentCurrency(document.Document.TaxableTotalInDocumentCurrency);
        this.TaxTotalInDocumentCurrency(document.Document.VatInDocumentCurrency);
        this.NonTaxableTotalInDocumentCurrency(document.Document.NotTaxableTotalInDocumentCurrency);
        this.TotalInDocumentCurrency(document.Document.TotalInDocumentCurrency);
        this.NetTotalInDocumentCurrency(document.Document.NetTotalInDocumentCurrency);
        this.FinalTotalInDocumentCurrency(document.Document.FinalTotalInDocumentCurrency);
        this.TaxReliefTotalInDocumentCurrency(document.Document.TaxReliefTotalInDocumentCurrency);
        this.TotalWithTaxReliefDiscountInDocumentCurrency(
            document.Document.TotalWithTaxReliefDiscountInDocumentCurrency
        );

        this.WithholdingTax(document.Details.WithholdingTax);
        this.WithholdingTaxTotal(document.Details.WithholdingTaxTotal);
        this.ReasonForPayment(document.Details.ReasonForPayment);

        this.StampDuty(document.Details.StampDuty);
        this.StampDutyValue(document.Details.StampDutyValue);
        this.StampDutyThreshold(document.Details.StampDutyThreshold);

        this.Weight(document.Details.Weight);
        this.Packages(document.Details.Packages);
        this.FKAspect(document.Details.FKAspect);
        this.Aspect(document.Details.Aspect);
        this.FKTransport(document.Details.FKTransport);
        this.Transport(document.Details.Transport);
        this.FKCarriage(document.Details.FKCarriage);
        this.Carriage(document.Details.Carriage);
        this.FKValidityType(document.Document.FKValidityType);
        this.ValidityType(document.Document.ValidityType);
        this.TransportStart(document.Details.TransportStart);

        this.CIG(document.Document.CIG);
        this.CUP(document.Document.CUP);

        this.HtmlFooter(document.Details.HtmlFooter);

        const rows = [];
        let totalForStampDuty = 0;
        for (const row of document.Rows) {
            const rowVM = await this.createViewModel(row);

            if (rowVM.VatTypeCache()?.SubjectToStampDuty) totalForStampDuty += rowVM.TotalPrice();

            rows.push(rowVM);
        }
        this.Rows(rows);
        this.RowsForStampDutyTotal(totalForStampDuty);
        this.TaxesReport(document.VatRows.sort((a, b) => a.Order - b.Order).map(this.createViewModelForTaxRow, this));

        if (this.SecondaryRecipientsEnabled()) {
            this.SecondaryRecipients([]);
            document.SecondaryRecipients.forEach((r) => this.createAndInsertSecondaryRecipientViewModel(r));
        }

        this.RelatedDocuments([]);
        document.RelatedDocuments.forEach((d) => this.createAndInsertRelatedDocumentViewModel(d));

        this.DocumentCurrencySymbol(document.Document.Currency);
        this.DocumentCurrencies(
            document.DocumentCurrencies.map((c) => this.documentsService.DocumentCurrenciesFactory.createFromModel(c))
        );

        this.Schedules(document.Schedules);

        await this.AttachmentsManager.loadAttachments(document.Attachments.map((a) => a.AttachmentId));

        this.AttachmentsManager.attachments().forEach((a: Attachment) => {
            const entityAttachmentMatch = document.Attachments.filter(
                (ea) => ea.AttachmentId.toUpperCase() == (a.id || "").toUpperCase()
            );
            if (entityAttachmentMatch.length > 0)
                a.loadEntityAttachmentInfo({
                    FileId: entityAttachmentMatch[0].AttachmentId,
                    EntityKey: document.Document.Id,
                    EntityType: document.Document.EntityType,
                    IncludeInElectronicInvoice: entityAttachmentMatch[0].IncludeInElectronicInvoice,
                });
        });

        this.Metadatas(
            document.Metadatas.map((m) => new DocumentMetadata(this.MetadatasDataSource, this.toDocumentMetadata(m)))
        );

        this.Resources(document.Resources.map((r) => new DocumentResource(r, this.Resources, this.fieldsReader)));
        this.TaxRelief(document.TaxRelief.map((tr) => new DocumentTaxRelief(this.createTaxReliefData(tr))));

        if (document.TaxRelief.length > 0 && this.CanHaveTaxRelief()) this.ShowTaxReliefSection(true);

        this.SendDocumentMail(document.Details.SendDocumentMail);
        this.IncludeDocumentAttachmentsInMail(document.Details.IncludeDocumentAttachmentsInMail);

        await this.loadSystemDefaults();
        await this.loadLastMailSentStatus();

        this.loading = false;
    }

    private async loadLastMailSentStatus(): Promise<void> {
        const mailSentStatus = await this.documentsService.getLastMailSent(this.DocumentId());
        this.LastMailSentStatus(mailSentStatus);
    }

    private createTaxReliefData(documentBuilderTaxRelief: IDocumentBuilderDocumentTaxRelief): IDocumentTaxRelief {
        const taxRelief: IDocumentTaxRelief = {
            TaxReliefId: documentBuilderTaxRelief.FKTaxRelief,
            TaxReliefLabel: documentBuilderTaxRelief.TaxReliefLabel,
            TaxReliefDescription: documentBuilderTaxRelief.TaxReliefDescription,
            TaxReliefDiscount: documentBuilderTaxRelief.TaxReliefDiscount,
            DocumentRowTotalPercentage: documentBuilderTaxRelief.DocumentRowTotalPercentage,
            TotalDiscount: documentBuilderTaxRelief.TotalDiscount,
            TotalDiscountInDocumentCurrency: documentBuilderTaxRelief.TotalDiscountInDocumentCurrency,
            DocumentRow: this.Rows().firstOrDefault((r) => r.Id() === documentBuilderTaxRelief.FKDocumentRow),
        };

        return taxRelief;
    }

    async save() {
        if (this.CanHaveLetterOfAttempts()) {
            //Se non sono una nota di credito e non sono riuscito ad applicare automaticamente le LdI alle righe che non ce l'hanno e la richiedono, esco
            if (!this.CanViewOldLetterOfAttempts() && !(await this.applyLetterOfAttemptsToRows())) return;
            //Se sono una nota di credito e c'è almeno una riga senza lettera d'intento dove richiesta, esco
            if (this.CanViewOldLetterOfAttempts() && !this.checkLettersOfAttemptsSelectionOnRows()) return;
            //Su tutte le righe dove è richiesta una LdI, quella è selezionata e disponibile

            //Adesso dobbiamo verificare se le LdI sono scadute o hanno sforato il budget

            //Prendo le lettere di intento dal DB, facendo finta che il documento che sto lavorando non esista
            const letters = (
                await this.customersService.GetLettersOfAttempts({
                    CustomerId: this.Recipient.Id(),
                    ShowClosed: this.CanViewOldLetterOfAttempts(),
                    Skip: 0,
                    Count: 1000,
                    ReferenceDate: this.Date(),
                    OnlyValidAtReferenceDate: true,

                    documentId: this.DocumentId(),
                    documentType: this.DocumentType(),
                })
            ).reverse();

            //Controllo riga per riga se la LdI selezionata è scaduta, futura o se ha sforato il massimale
            //TODO: Capire bene cosa fare nel caso delle note di credito!
            if (!this.checkLettersOfAttemptsForExpirationOrOverflow(letters)) return;

            /* Vecchio codice da rivedere
            if (this.CreditNote()) {
                let lettersTotals = this.getUsedLettersOfAttemptsTotals();

                let processedLetters: number[] = [];

                (this.customer.ValidLettersOfAttempts || []).forEach((l: ILetterOfAttempt) => {
                    let total = lettersTotals.get(l.Id);
                    if (total === null || total === undefined)
                        return;

                    let letterResidualAmount = l.Amount - l.UsedAmount + total.OriginalTotal - total.ActualTotal;
                    if (l.Amount > 0 && l.Amount < letterResidualAmount && processedLetters.indexOf(l.Id) < 0) {
                        warnings.push(String.format(ProlifeSdk.TextResources.Invoices.LetterOfAttemptOverflowOnCreditNote, l.Description, numeral(l.Amount).format("0,0.00 €"), numeral(letterResidualAmount).format("0,0.00 €")));
                        processedLetters.push(l.Id);
                    }
                });
            }*/
        }

        const rowsWithBadRelatedWorkflows = this.Rows()
            .selectMultiple((r) => r.RelatedWorkflows())
            .filter((r) => r.WorkflowAmount < 0);
        if (rowsWithBadRelatedWorkflows.length != 0) {
            this.infoToastService.Error(
                String.format(
                    TextResources.ProlifeSdk.DocumentRelatedWorkflowWithLowerThanZeroAmountError,
                    rowsWithBadRelatedWorkflows.join(", ")
                )
            );
            return;
        }

        if (!this.validator.validateAndShowInfoToast(this)) {
            return;
        }

        if (!this.Recipient.validateAndShowInfoToast()) return;

        if (this.CanHaveMetadatas()) {
            const metadatas = this.Metadatas();

            for (const metadata of metadatas) {
                if (!metadata.validateAndShowInfoToast()) return;
            }
        }

        if (this.CanHaveResources()) {
            const resources = this.Resources();

            for (const resource of resources) {
                if (!resource.validateAndShowInfoToast()) return;
            }
        }

        if (this.CanHaveTaxRelief()) {
            const taxRelief = this.TaxRelief();

            for (const relief of taxRelief) {
                if (!relief.validateAndShowInfoToast()) return;
            }
        }

        if (this.CanHaveDestinationRecipient() && !this.DestinationRecipient.validateAndShowInfoToast()) return;

        //Se è per pubblica amministrazione tutte le righe devono avere tipologia iva con Split Payment
        if (this.ForPublicAdministration() && this.SplitPayment()) {
            if (
                this.Rows().any(
                    (r) =>
                        r.VatTypeCache()?.Imponibile == 0 &&
                        r.VatTypeCache()?.ChargeableVat != ProlifeSdk.SplitPaymentVatChargeability
                )
            ) {
                if (
                    !(await this.dialogsService.ConfirmAsync(
                        TextResources.Invoices.PublicAdministrationRowsWithNoSplitPayment,
                        TextResources.Desktop.No,
                        TextResources.Desktop.Yes
                    ))
                )
                    return;
            }
        }

        //Verifichiamo che non ci siano righe con riferimenti ma quantità zero
        if (this.Rows().any((r) => r.Amount() == 0 && r.OriginatingRows().length != 0)) {
            if (
                !(await this.dialogsService.ConfirmAsync(
                    TextResources.ProlifeSdk.RowsWithZeroAmountButReferencesMessage,
                    TextResources.ProlifeSdk.RowsWithZeroAmountButReferencesCancel,
                    TextResources.ProlifeSdk.RowsWithZeroAmountButReferencesConfirm
                ))
            )
                return;
        }

        //Se ho selezionato una scadenza verifico che abbia la configurazione per la generazione automatica delle scadenze
        if (this.FKExpiryType() && this.CanHaveSchedules()) {
            if (!(await this.invoicesServices.isExpiryModeConfigured(this.ExpiryTypeCache?.IdTipoScadenza))) {
                if (
                    !(await this.dialogsService.ConfirmAsync(
                        TextResources.Invoices.ExpiryModeNotConfiguredAlert,
                        TextResources.ProlifeSdk.No,
                        TextResources.ProlifeSdk.Yes
                    ))
                )
                    return;
            }
        }

        const model = this.getData();
        //Controllo se devo rigenerare le scadenze
        if (this.CanHaveSchedules()) {
            if (!!this.DocumentId() && this.DocumentId() > 0) {
                const dialog = new SchedulesRebuildRequestDialog();
                model.RebuildSchedulesAction = await dialog.show();
            } else {
                model.RebuildSchedulesAction = SchedulesRebuildOptions.Rebuild; // Di default devo calcolare le scadenze per i nuovi documenti
            }
        }

        this.doSave(model);
    }

    private async doSave(model: IDocumentBuilderDocumentWithOptions) {
        this.Saving(true);
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving, true);

        await Task.Delay(100); //Mi assicuro che appaia il lock screen

        try {
            await this.internalDoSave(model);
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    private async internalDoSave(model: IDocumentBuilderDocumentWithOptions) {
        if (this.MustCheckTrust()) {
            const pendindTrustAuthorizationRequest =
                await this.trustAuthorizationProcessService.GetCustomerPendingAuthorizationRequests(
                    this.Recipient.Id()
                );
            if (pendindTrustAuthorizationRequest) {
                this.dialogsService.UnlockUI();

                await this.trustAuthorizationProcessService.OnCustomerPendingRequests(pendindTrustAuthorizationRequest);
                return;
            }
        }

        try {
            const updatedModel = await this.documentsService.createOrUpdate(model);

            this.onSaveSuccessful(updatedModel);
            this.onAfterSave();
        } catch (e) {
            this.dialogsService.UnlockUI();

            if (await this.handleValidationException(model, e)) return;

            this.onAfterSave();
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    private onSaveSuccessful(model: IDocumentBuilderDocument) {
        this.infoToastService.Success(ProlifeSdk.TextResources.ProlifeSdk.DocumentSavedSuccessfully);

        this.Listener()?.OnEndEdit(this);
        this.ReadOnly(true);

        this.load(model);
    }

    private onAfterSave() {
        this.Saving(false);
    }

    private async handleValidationException<T extends IException>(
        model: IDocumentBuilderDocumentWithOptions,
        e: T
    ): Promise<boolean> {
        if (e.ExceptionType == ProlifeSdk.ServerException_ProLifeValidationException) {
            const ve: IValidationException = e as unknown as IValidationException;
            switch (ve.ExceptionCode) {
                case 0: //Reference Number Duplicate
                    {
                        const result = await this.handleReferenceNumberDuplicationError(model);
                        if (result) return true;

                        this.onAfterSave();
                    }
                    break;

                case 1: //Trust Overflow
                    {
                        const result = await this.handleTrustAuthorizationProcess(model, ve);

                        if (!result.Authorized) return false;

                        await this.lockScreenAndSave(model);
                    }
                    break;
                case 2:
                    {
                        // Stock negativo
                        const result = await this.handleNegativeStockError(ve);
                        if (result) {
                            model.IgnoreNegativeStockAmount = true;
                            await this.lockScreenAndSave(model);
                        } else {
                            this.onAfterSave();
                        }
                    }
                    break;
                case 50600: {
                    const result = await this.handleConfirmSendMail(ve, this.LastMailSentStatus());
                    if (result !== SendMailAction.Abort) {
                        model.SendMailAction = result;
                        await this.lockScreenAndSave(model);
                    } else {
                        this.onAfterSave();
                    }
                }
            }

            return true;
        }

        if (e.ExceptionType === ProlifeSdk.ServerException_WarehouseMovementChangesValidationException) {
            const ex = e as unknown as IWarehouseMovementChangesValidationException;
            await this.handleWarehouseMovementChangesValidationException(ex);

            this.onAfterSave();
            return true;
        }

        if (e.ExceptionType === ProlifeSdk.ServerException_WorkflowOutcomeChangeValidationException) {
            const ex = e as unknown as IWorkflowOutcomeChangeValidationException;
            const result = await this.handleWorkflowOutcomeChangeValidationException(ex);
            if (result) {
                model.OnWorkflowOutcomeChangeAction = result;
                await this.lockScreenAndSave(model);
            } else {
                this.onAfterSave();
            }

            return true;
        }

        return false;
    }

    private async handleNegativeStockError(validationException: IValidationException): Promise<boolean> {
        const data = validationException.ValidationData as INegativeStockValidationExceptionData[];
        return await new NegativeStockExceptionViewerDialog({ exceptionData: data }).show();
    }

    private async handleConfirmSendMail(
        validationException: IValidationException,
        lastMailSentStatus: SentMail
    ): Promise<SendMailAction> {
        const validationData = validationException.ValidationData as IConfirmSendMailValidationExceptionData;
        return await new DocumentConfirmSendMailViewerDialog({
            ...validationData,
            LastMailSentStatus: lastMailSentStatus,
        }).show();
    }

    private async lockScreenAndSave(model: IDocumentBuilderDocumentWithOptions) {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving, true);
        await Task.Delay(100);
        await this.internalDoSave(model);
    }

    private async handleTrustAuthorizationProcess(
        model: IDocumentBuilderDocumentWithOptions,
        validationException: IValidationException
    ) {
        const result = await this.trustAuthorizationProcessService.ExecuteAuthorizationProcess(this, {
            AuthorizationRequestId: null,
            Authorized: null,
            AuthorizedByResourceId: null,
            AuthorizedByResourceName: null,
            TrustOverflow: validationException.ValidationData.TrustOverflow,
        });

        if (result.Authorized) {
            model.TrustAuthorized = true;
            model.TrustAuthorizationId = result.AuthorizationRequestId;
            model.TrustAuthorizedById = result.AuthorizedByResourceId;
            model.TrustAuthorizedByName = result.AuthorizedByResourceName;
        }

        return result;
    }

    private async handleReferenceNumberDuplicationError(model: IDocumentBuilderDocumentWithOptions): Promise<boolean> {
        const result = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Invoices.DuplicateReferenceNumberFound,
            ProlifeSdk.TextResources.Invoices.DuplicateReferenceNumberFoundCancel,
            ProlifeSdk.TextResources.Invoices.DuplicateReferenceNumberFoundConfirm
        );
        if (result) {
            model.IgnoreReferenceNumberDuplicate = true;

            this.dialogsService.LockUI(TextResources.ProlifeSdk.Saving, true);
            await Task.Delay(100);

            await this.internalDoSave(model);
        }

        return result;
    }

    private setElectronicInvoiceTypeCode(): void {
        if (!this.CanBeAnElectronicInvoice() || !this.ElectronicInvoice()) {
            this.ElectronicInvoiceTypeCode(null);
            return;
        }

        const defaultTypeFromRegister = this.getDefaultElectronicInvoiceTypeFromRegister();
        this.ElectronicInvoiceTypeCode(defaultTypeFromRegister);
    }

    private getDefaultElectronicInvoiceTypeFromRegister(): string {
        if (this.DocumentType() === ProlifeSdk.CreditNoteEntityTypeCode)
            return this.RegisterCache.CreditNoteElectronicInvoiceType;

        if (this.DocumentType() === ProlifeSdk.AccompanyingInvoiceEntityTypeCode)
            return this.RegisterCache.AccompanyingInvoiceElectronicInvoiceType;

        if (this.DownPayment()) return this.RegisterCache.DownPaymentElectronicInvoiceType;

        return this.RegisterCache.InvoiceElectronicInvoiceType;
    }

    private setCustomerElectronicInvoiceRecipientInfo() {
        const rci = this.Recipient.getFirstRecipientCodeInfo();
        this.RecipientCodeData(rci);
        this.RecipientCode(rci?.RecipientCode);
        this.RecipientCodePEC(rci?.PEC);
    }

    protected async handleWarehouseMovementChangesValidationException(
        exception: IWarehouseMovementChangesValidationException
    ): Promise<boolean> {
        const warehouseMovementsChangesAlert = new WarehouseMovementsChangesAlert(
            exception.ExceptionMessage,
            exception.WarehouseMovementsChanges
        );
        const result = await warehouseMovementsChangesAlert.show();
        return false; // TODO return result: dovrà essere possibile scegliere se salvare comunque il documento. Per ora l'errore è bloccante.
    }

    protected async handleWorkflowOutcomeChangeValidationException(
        ex: IWorkflowOutcomeChangeValidationException
    ): Promise<OnWorkflowOutcomeChangesActions> {
        const componentInfo: IPopoverComponentInfo = {
            componentName: "workflow-outcome-changes-alert",
            model: {
                PromptText: TextResources.ProlifeSdk.WorkflowOutcomeChangesAlert,
                OutcomeChanges: ex.OutcomeChanges,

                close: function () {
                    this.modal.close(OnWorkflowOutcomeChangesActions.ApplyOutcomeChanges);
                },

                action: function () {
                    this.modal.close(OnWorkflowOutcomeChangesActions.NotApplyOutcomeChanges);
                },
            },
            title: TextResources.ProlifeSdk.WorkflowOutcomeChangesAlertTitle,
            params: "OutcomeChanges: OutcomeChanges, PromptText: PromptText, OnNotApplyOutcomesClick: $data.action.bind($data), OnApplyOutcomesClick: $data.close.bind($data)",
        };

        const result = await this.dialogsService.ShowModalComponent<OnWorkflowOutcomeChangesActions>(
            componentInfo,
            "medium",
            { noPrompt: true }
        );
        return result;
    }

    private getData(): IDocumentBuilderDocumentWithOptions {
        const data: IDocumentBuilderDocumentWithOptions = {
            Document: {
                BillingLogicalStatus: this.document?.Document.BillingLogicalStatus || 0,
                Blocked: false,
                Bulk: false, //TODO: Eliminare questo campo e la funzionalità
                Currency: this.DocumentCurrency().Currency().Symbol,
                Date: this.Date(),
                DownPayment: this.DownPayment(),
                ElectronicInvoicing: this.ElectronicInvoice(),
                ElectronicInvoiceTypeCode: this.ElectronicInvoiceTypeCode(),
                EntityType: this.DocumentType(),
                FKCurrency: this.DocumentCurrency().CurrencyId(),
                FKFiscalYear: this.FKFiscalYear(),
                FKRegister: this.FKRegister(),
                FinalTotal: this.FinalTotal(),
                FinalTotalInDocumentCurrency: this.FinalTotalInDocumentCurrency(),
                ForPublicAdministration: this.CanBeForPublicAdministration() ? this.ForPublicAdministration() : false,
                Id: this.DocumentId(),
                NetTotal: this.NetTotal(),
                NetTotalInDocumentCurrency: this.NetTotalInDocumentCurrency(),
                NotTaxableTotal: this.NonTaxableTotal(),
                NotTaxableTotalInDocumentCurrency: this.NonTaxableTotalInDocumentCurrency(),
                NumberPrintChoice: this.NumberPrintChoice(),
                TaxableTotal: this.TaxableTotal(),
                TaxableTotalInDocumentCurrency: this.TaxableTotalInDocumentCurrency(),
                Total: this.Total(),
                TotalInDocumentCurrency: this.TotalInDocumentCurrency(),
                VAT: this.TaxTotal(),
                VatInDocumentCurrency: this.TaxTotalInDocumentCurrency(),
                VatRegisterNumber: this.VatRegisterNumber(),
                VersionNumberGenerationMode: this.VersionNumberGenerationMode(),
                AdministrativeResponsibleName: this.AdministrativeResponsibleName(),
                CIG: this.CIG(),
                CUP: this.CUP(),
                Cause: this.Cause(),
                CauseLogicType: this.CauseLogicType(),
                CommercialResponsibleName: this.CommercialResponsibleName(),
                CustomerBusinessName: this.Recipient.FormattedName(),
                EstimateStateName: this.StateName(),
                ExpiryType: this.ExpiryType(),
                ExternalReference: this.ExternalReference(),
                FKAdministrativeResponsible:
                    typeof this.FKAdministrativeResponsible() === "string"
                        ? -1
                        : (this.FKAdministrativeResponsible() as number),
                FKCause: this.FKCause(),
                FKCommercialResponsible:
                    typeof this.FKCommercialResponsible() === "string"
                        ? null
                        : (this.FKCommercialResponsible() as number),
                FKCustomer: this.Recipient.Id(),
                FKEstimateState: this.FKState(),
                FKExpiryType: this.FKExpiryType(),
                FKJobOrder: this.FKJobOrder(),
                FKOutcome: this.FKOutcome(),
                FKPaymentType: this.FKPaymentType(),
                FKRecipient: this.DestinationRecipient.Id(),
                FKRecipientOrganizationalUnit: this.FKOrganizationalUnit(),
                FKValidityType: this.FKValidityType(),
                FullVersionRevisionNumber: this.FullVersionRevisionNumber(),
                JobOrderName: this.JobOrderName(),
                Number: this.Number(),
                NumberPrefix: this.NumberPrefix(),
                NumberSuffix: this.NumberSuffix(),
                PaymentType: this.Payment()?.Descrizione,
                RecipientBusinessName: this.DestinationRecipient.FormattedName(),
                ReferenceDate: this.ReferenceDate(),
                ReferenceNumber: this.ReferenceNumber(),
                SourceWarehouseId: this.SourceWarehouseId(),
                ValidityType: this.ValidityType(),
                VersionNumber: this.VersionNumber(),
                VersionRevision: this.VersionRevision(),
                VersionRevisionNumberPrefix: this.VersionRevisionNumberPrefix(),
                VersionRevisionNumberSeparator: this.VersionRevisionNumberSeparator(),
                VersionRevisionNumberSuffix: this.VersionRevisionNumberSuffix(),
                WarehouseId: this.WarehouseId(),
                ClosureStatus: 0,
                SchedulesStatus: 0,
                TaxReliefTotal: this.TaxReliefTotal(),
                TaxReliefTotalInDocumentCurrency: this.TaxReliefTotalInDocumentCurrency(),
                TotalWithTaxReliefDiscount: this.TotalWithTaxReliefDiscount(),
                TotalWithTaxReliefDiscountInDocumentCurrency: this.TotalWithTaxReliefDiscountInDocumentCurrency(),
            },
            Details: {
                FKDocument: this.DocumentId(),
                LayoutId: this.LayoutId(),

                CompanyAddress: this.CompanyCache.Indirizzo,
                CompanyBusinessName: this.CompanyCache.RagioneSociale,
                CompanyCity: this.CompanyCache.Citta,
                CompanyCommercialRegister: this.CompanyCache.RegImprese,
                CompanyEMail: this.CompanyCache.Mail,
                CompanyFax: this.CompanyCache.Fax,
                CompanyLogo: this.CompanyLogo(),
                CompanyMunicipality: this.CompanyCache.Comune,
                CompanyPhone: this.CompanyCache.Telefono,
                CompanyPostalCode: this.CompanyCache.CAP,
                CompanyProvince: this.CompanyCache.Provincia,
                CompanyREA: this.CompanyCache.REA,
                CompanyRegisteredCapital: this.CompanyCache.CapSociale,
                CompanyState: this.CompanyCache.Stato,
                CompanyTAXNumber: this.CompanyCache.CF,
                CompanyVATNumber: this.CompanyCache.PIVA,
                CompanyWebsite: this.CompanyCache.Website,

                CustomerLegalPerson: this.Recipient.LegalPerson(),
                CustomerAddress: this.Recipient.Address(),
                CustomerBusinessName: this.Recipient.BusinessName(),
                CustomerCity: this.Recipient.City(),
                CustomerEORICode: this.Recipient.EORICode(),
                CustomerMunicipality: this.Recipient.Municipality(),
                CustomerName: this.Recipient.Name(),
                CustomerPostalCode: this.Recipient.CAP(),
                CustomerProvince: this.Recipient.Province(),
                CustomerState: this.Recipient.Nation(),
                CustomerSurname: this.Recipient.Surname(),
                CustomerTAXNumber: this.Recipient.TAXCode(),
                CustomerVATNumber: this.Recipient.VATCode(),

                RecipientLegalPerson: this.DestinationRecipient.LegalPerson(),
                RecipientAddress: this.DestinationRecipient.Address(),
                RecipientBusinessName: this.DestinationRecipient.BusinessName(),
                RecipientCity: this.DestinationRecipient.City(),
                RecipientEORICode: this.DestinationRecipient.EORICode(),
                RecipientMunicipality: this.DestinationRecipient.Municipality(),
                RecipientName: this.DestinationRecipient.Name(),
                RecipientPostalCode: this.DestinationRecipient.CAP(),
                RecipientProvince: this.DestinationRecipient.Province(),
                RecipientState: this.DestinationRecipient.Nation(),
                RecipientSurname: this.DestinationRecipient.Surname(),
                RecipientTAXNumber: this.DestinationRecipient.TAXCode(),
                RecipientVATNumber: this.DestinationRecipient.VATCode(),

                SecondaryRecipientsEnabled: this.SecondaryRecipientsEnabled(),
                ShowAmounts: this.ShowAmounts(),
                ShowImportedDocumentsTableOnPrintedDocument: this.ShowImportedDocumentsTableOnPrintedDocument(),
                StampDuty: this.StampDuty(),
                StampDutyThreshold: this.StampDutyThreshold(),
                StampDutyValue: this.StampDutyValue(),
                StampDutyValueInDocumentCurrency: this.StampDutyValue().ToCurrency(this.DocumentCurrency()),

                HasWithholdingTax: this.WithWithholdingTax(),
                WithholdingTaxTotal: this.WithholdingTaxTotal(),
                WithholdingTaxTotalInDocumentCurrency: this.WithholdingTaxTotal().ToCurrency(this.DocumentCurrency()),
                WithholdingTax: this.WithholdingTax(),

                AdministrativeNotes: this.AdministrativeNotes(),
                Notes: this.Notes(),

                Aspect: this.Aspect(),
                Carriage: this.Carriage(),
                FKAspect: this.FKAspect(),
                FKCarriage: this.FKCarriage(),
                FKTransport: this.FKTransport(),
                HeaderImage: this.HeaderImage(),
                HtmlFooter: this.HtmlFooter(),
                IncludeAttachmentsAndDocumentInElectronicInvoiceExport:
                    this.IncludeAttachmentsAndDocumentInElectronicInvoiceExport(),
                Packages: this.Packages(),
                PaymentABI: this.PaymentABI(),
                PaymentBankName: this.PaymentBankName(),
                PaymentCAB: this.PaymentCAB(),
                PaymentIBAN: this.PaymentIBAN(),
                ReasonForPayment: this.ReasonForPayment(),
                RecipientCode: this.RecipientCode(),
                RecipientCodeId: this.RecipientCodeData()?.Id,
                RecipientCodePEC: this.RecipientCodePEC(),
                Transport: this.Transport(),
                TransportStart: this.TransportStart(),
                Weight: this.Weight(),

                IRPEFTax: this.IRPEFTax(),
                IRPEFTaxTotal: this.IRPEFTaxTotal(),
                IRPEFTaxDescription: this.IRPEFTaxDescription(),
                IRPEFTotalInDocumentCurrency: this.IRPEFTaxTotal().ToCurrency(this.DocumentCurrency()),

                NonIRPEFTax: this.NonIRPEFTax(),
                NonIRPEFTaxTotal: this.NonIRPEFTaxTotal(),
                NonIRPEFTaxDescription: this.NonIRPEFTaxDescription(),
                NonIRPEFTotalInDocumentCurrency: this.NonIRPEFTaxTotal().ToCurrency(this.DocumentCurrency()),
                SendDocumentMail: this.SendDocumentMail(),
                IncludeDocumentAttachmentsInMail: this.IncludeDocumentAttachmentsInMail(),
            },
            Rows: this.Rows().map((r) => r.getData()),
            OriginatingRows: this.Rows().selectMultiple((r) => r.OriginatingRows()),
            ReferencingRows: [],
            RelatedDocuments: this.RelatedDocuments().map((r) => r.getData()),
            Attachments: this.AttachmentsManager.attachments().map((a) => ({
                AttachmentId: a.id,
                IncludeInElectronicInvoice: a.includeInElectronicInvoice(),
            })),
            DocumentCurrencies: this.DocumentCurrencies().map((c) => {
                const curr = c.getData() as IDocumentBuilderDocumentDocumentCurrencies;
                this.setCurrencyData(curr);
                return curr;
            }),
            RelatedWorkflows: this.Rows().selectMultiple(
                (r) => r.RelatedWorkflows() as IDocumentBuilderDocumentRelatedWorkflows[]
            ),
            VatRows: this.TaxesReport().map((r) => r.getData()),
            SecondaryRecipients: this.SecondaryRecipients().map((r) => r.getData()),
            Schedules: [],
            Metadatas: this.Metadatas().map((m) => this.toDocumentBuilderDocumentMetadata(m, this.DocumentId())),
            Resources: this.Resources().map((r) => {
                const resource = r.getData();
                resource.FKDocument = this.DocumentId();
                return resource;
            }),
            TaxRelief: this.TaxRelief().map((r) => {
                const taxRelief = r.getData();
                taxRelief.FKDocument = this.DocumentId();
                return taxRelief;
            }),
            DocumentJournalSettings: this.documentJournalSettings,
            IgnoreReferenceNumberDuplicate: false,
            OnWorkflowOutcomeChangeAction: null,
            RebuildSchedulesAction: null,
            TrustAuthorizationId: null,
            TrustAuthorized: false,
            TrustAuthorizedById: null,
            TrustAuthorizedByName: null,
            IgnoreNegativeStockAmount: false,
            SendMailAction: SendMailAction.ShowPrompt,
        };

        return data;
    }

    private toDocumentBuilderDocumentMetadata(
        documentMetadata: DocumentMetadata,
        documentId: number
    ): IDocumentBuilderDocumentMetadatas {
        const valueType = documentMetadata.MetadataValueType();
        return {
            Id: documentMetadata.Id ?? this.documentsService.getFakeId(),
            FKDocument: documentId,
            FKMetadata: documentMetadata.MetadataId(),
            ShowMarkerOnAllocationsGantt: documentMetadata.ShowMarkerOnAllocationsGantt(),
            ValueType: valueType,
            IntValue:
                valueType === MetadataType.Integer || valueType === MetadataType.List
                    ? documentMetadata.MetadataValue()
                    : null,
            DecimalValue: valueType === MetadataType.Decimal ? documentMetadata.MetadataValue() : null,
            StringValue: valueType === MetadataType.Text ? documentMetadata.MetadataValue() : null,
            DateValue: valueType === MetadataType.DateTime ? documentMetadata.MetadataValue() : null,
            BoolValue: valueType === MetadataType.Boolean ? documentMetadata.MetadataValue() : null,
        };
    }

    private toDocumentMetadata(metadata: IDocumentBuilderDocumentMetadatas): IDocumentMetadata {
        return {
            Id: metadata.Id,
            MetadataId: metadata.FKMetadata,
            MetadataListValues: [],
            MetadataValueType: metadata.ValueType as MetadataType,
            MetadataValue: this.metadataValueGetters[metadata.ValueType](metadata),
            ShowMarkerOnAllocationsGantt: metadata.ShowMarkerOnAllocationsGantt,
        };
    }

    private setCurrencyData(currency: IDocumentBuilderDocumentDocumentCurrencies) {
        if (!currency.Id || currency.Id <= 0) currency.Id = this.getNextId();

        currency.DocumentId = this.DocumentId();
        currency.DocumentType = this.DocumentType();
    }

    private getRowsWithoutLetterOfAttempts(): DocumentRow[] {
        return this.Rows().filter((r) => r.VatTypeCache()?.RequireLetterOfAttempt && !r.FKLetterOfAttempt());
    }

    private checkLettersOfAttemptsSelectionOnRows(): boolean {
        const rowsWithoutLoA = this.getRowsWithoutLetterOfAttempts();

        if (rowsWithoutLoA.length > 0) {
            this.infoToastService.Error(
                String.format(
                    TextResources.Invoices.MissingLetterOfAttemptOnRows,
                    rowsWithoutLoA.map((r) => r.Index()).join(", ")
                )
            );
        }

        return rowsWithoutLoA.length == 0;
    }

    private checkLettersOfAttemptsForExpirationOrOverflow(letters: ILetterOfAttempt[]): boolean {
        if (this.CanSelectClosedLettersOfAttempt()) return true;

        const expiredLoA: ILetterOfAttempt[] = [];
        const usedLoA: ILetterOfAttempt[] = [];
        const notFoundLoA: DocumentRow[] = [];

        //Prendo tutte le righe con lettera d'intento selezionata
        const rowsWithLoA = this.Rows().filter((r) => r.FKLetterOfAttempt());
        for (const row of rowsWithLoA) {
            //Cerco la lettera d'intento selezionata sulla riga nella lista ritornata da DB
            const loa = letters.firstOrDefault((l) => l.Id == row.FKLetterOfAttempt());
            if (!loa) {
                //Se non trovo la lettera d'intento su DB la aggiungo alla lista delle LdI non trovate per dare errore successivamente
                if (!notFoundLoA.any((l) => l.FKLetterOfAttempt() == row.FKLetterOfAttempt())) notFoundLoA.push(row);
                continue;
            }

            //Se la lettera d'intento è scaduta o futura la aggiungo alla lista per dare errore in seguito
            if (loa.StartDate > this.Date() || loa.DueDate < this.Date()) {
                if (!expiredLoA.any((l) => l.Id == loa.Id)) expiredLoA.push(loa);
            }

            //Mi tengo da pare la LdI perchè l'ho utilizzata
            if (usedLoA.any((l) => l.Id == loa.Id)) usedLoA.push(loa);

            //TODO: Iva o non iva? Questo è il problema!
            loa.UsedAmount += row.TotalPrice();
        }

        //Se ho almeno una LdI scaduta, do errore
        if (expiredLoA.length > 0)
            this.infoToastService.Error(
                String.format(
                    TextResources.Invoices.InvalidLettersOfAttemptSelected,
                    expiredLoA.map((l) => l.Description).join(", ")
                )
            );

        //Se ho almeno una LdI non trovata, do errore
        if (notFoundLoA.length > 0)
            this.infoToastService.Error(
                String.format(
                    TextResources.Invoices.LettersOfAttemptNotFound,
                    notFoundLoA.map((l) => l.LetterOfAttempt()).join(", ")
                )
            );

        //Se ho almeno una LdI che ha sforato il massimale, do errore
        const overflowedLoA = usedLoA.filter((l) => l.UsedAmount > l.Amount && l.Amount > 0);
        if (overflowedLoA.length > 0) {
            let message = "";
            for (const loa of overflowedLoA) {
                message +=
                    String.format(
                        TextResources.Invoices.LetterOfAttemptOverflowEx,
                        loa.Description,
                        loa.UsedAmount - loa.Amount
                    ) + "<br/>";
            }
            this.infoToastService.Error(message);
        }

        //Ritorno true solo se non ho errori
        return !expiredLoA.length && !notFoundLoA.length && !overflowedLoA.length;
    }

    private async applyLetterOfAttemptsToRows() {
        const rowsWithoutLoA = this.getRowsWithoutLetterOfAttempts();
        if (rowsWithoutLoA.length === 0) return true;

        const letters = (
            await this.customersService.GetLettersOfAttempts({
                CustomerId: this.Recipient.Id(),
                ShowClosed: this.CanViewOldLetterOfAttempts(),
                Skip: 0,
                Count: 1000,
                ReferenceDate: this.Date(),
                OnlyValidAtReferenceDate: true,
            })
        ).reverse();

        const rowsWithErrors: DocumentRow[] = [];

        for (const row of this.Rows().filter(
            (r) => r.VatTypeCache()?.RequireLetterOfAttempt && !r.FKLetterOfAttempt()
        )) {
            const letter = letters.firstOrDefault((l) => l.Amount - l.UsedAmount >= row.TotalPrice()); //TODO: Iva inclusa o esclusa?

            if (!letter) {
                rowsWithErrors.push(row);
                continue;
            }

            row.FKLetterOfAttempt(letter.Id);
            row.LetterOfAttempt(letter.Description);
            row.LetterOfAttemptCache(letter);

            letter.UsedAmount += row.TotalPrice();
        }

        if (rowsWithErrors.length > 0) {
            this.infoToastService.Error(
                String.format(
                    TextResources.Invoices.MissingLetterOfAttemptOnRows,
                    rowsWithErrors.map((r) => r.Index()).join(", ")
                )
            );
            return false;
        }

        return true;
    }

    async handleTransportCause(cause: IDDTCause) {
        if (cause) {
            const requireDestinationWarehouse = cause.Tipologia == 3;
            this.CanHaveDestinationWarehouse(requireDestinationWarehouse);

            if (!requireDestinationWarehouse) {
                this.DestinationWarehousesDocumentDataSource.setJobOrderId(null);
                this.WarehouseId(null);
            } else {
                this.DestinationWarehousesDocumentDataSource.setJobOrderId(this.FKJobOrder());

                await this.Recipient.loadCurrentCompany();
                await this.DestinationWarehousesDocumentDataSource.selectDefaultWarehouse();
            }
        }
    }

    private async manageCurrenciesForCopiedDocuments(source: Document): Promise<boolean> {
        if (!source.AtLeastOneRowSelected()) return true;

        const sourceCurrency = source.DocumentCurrency().Currency();
        const sourceDocumentCurrencyCode = sourceCurrency.CodeISO4217alpha3;
        const destinationDocumentCurrencies = this.DocumentCurrencies();

        const result = this.verifySourceDocumentCurrencyCompatibility(
            sourceCurrency.CodeISO4217alpha3,
            destinationDocumentCurrencies
        );

        if (result) return true;

        const currency = this.documentsService.DocumentCurrenciesFactory.create(
            this.DocumentId(),
            this.DocumentType(),
            sourceCurrency.Id
        );
        this.DocumentCurrencies.push(currency);

        const params: IDocumentCurrenciesEditorParams = {
            DocumentCurrencies: this.DocumentCurrencies(),
            ReadOnly: this.LockDocumentCurrencyChanges(),
        };

        await this.documentsService.ShowDocumentCurrenciesEditingDialog(params);
        return this.verifySourceDocumentCurrencyCompatibility(
            sourceDocumentCurrencyCode,
            this.DocumentCurrencies(),
            true
        );
    }

    private verifySourceDocumentCurrencyCompatibility(
        sourceDocumentCurrencyCode: string,
        destinationDocumentCurrencies: IDocumentCurrencyViewModel[],
        showWarnings = false
    ): boolean {
        for (const documentCurrency of destinationDocumentCurrencies) {
            const currencyCode = documentCurrency.Currency().CodeISO4217alpha3;
            if (currencyCode === sourceDocumentCurrencyCode) return true;
        }

        if (showWarnings)
            this.infoToastService.Warning(
                String.format(TextResources.Invoices.CurrencyExchangeNotConfigured, sourceDocumentCurrencyCode)
            );

        return false;
    }

    private async getRowsLeafsReferences(
        selectedRows: DocumentRow[],
        entityType: string
    ): Promise<IRowLeafReference[]> {
        const rowsKeys: IEntityKey[] = selectedRows
            .filter((r) => r.OriginatingRows().length > 0)
            .map((r) => {
                return { EntityKeyId: r.Id(), EntityKeyType: entityType };
            });
        let leafsReferences = [];

        if (rowsKeys.length > 0) {
            leafsReferences = await this.documentsService.GetRowsLeafsReferences(rowsKeys);
        }

        return leafsReferences;
    }

    protected generateOriginatingRowsForDestinationRow(
        sourceRowId: number,
        sourceRowType: string,
        sourceDocumentRowsOriginatingRows: IRowLeafReference[],
        destinationRowType: string
    ): IDocumentBuilderDocumentOriginatingRows[] {
        const sourceRowOriginatingRows = sourceDocumentRowsOriginatingRows.filter(
            (r) => r.DestinationRowId === sourceRowId && r.DestinationRowType === sourceRowType
        );
        return sourceRowOriginatingRows.map((or) => {
            return {
                RefId: null,
                SourceEntityKeyId: or.SourceEntityKeyId,
                SourceEntityType: or.SourceEntityType,
                DestEntityKeyId: null,
                DestEntityType: destinationRowType,
                SalId: or.SalId,
                CatalogId: or.CatalogId,
                Amount: or.Amount,
                UnitPrice: or.UnitPrice,
                Discounts: or.Discounts,
                NetUnitPrice: or.NetPrice,
                NetPrice: or.NetPrice,
                Action: or.Action,
                WarehouseId: or.WarehouseId,
                DocumentId: this.DocumentId(),
            };
        });
    }

    copyToEditingDocument() {
        this.Listener()?.OnCopyToEditingDocument(this);
    }

    public resetSelection() {
        this.Recipient.Selected(false);
        this.DestinationRecipient.Selected(false);
        this.JobOrderSelected(false);
        this.DateSelected(false);
        this.PaymentSelected(false);
        this.ExpirySelected(false);
        this.ValiditySelected(false);
        this.ShippingInfoSelected(false);

        this.MetadatasEditor?.resetSelection();

        this.Rows().forEach((r) => r.IsSelected(false));
    }

    async copyFrom(other: Document) {
        //this.loading = true;

        this.disableDefaultsLoading = true;

        const canCopy = await this.manageCurrenciesForCopiedDocuments(other);
        if (!canCopy) {
            //this.loading = false;
            this.disableDefaultsLoading = false;
            return false;
        }

        let customerIsChanged = false;
        let jobOrderIsChanged = false;

        if (other.Recipient.Selected()) {
            await this.Recipient.copyFrom(other.Recipient);
            customerIsChanged = true;
        }

        if (other.DestinationRecipient.Selected() && this.CanHaveDestinationRecipient())
            await this.DestinationRecipient.copyFrom(other.DestinationRecipient);

        if (other.VersionNumberSelected() && this.CanHaveVersion()) {
            this.VersionNumberGenerationMode(other.VersionNumberGenerationMode());

            this.VersionNumber(other.VersionNumber());
            this.VersionRevisionNumberPrefix(other.VersionRevisionNumberPrefix());
            this.VersionRevisionNumberSuffix(other.VersionRevisionNumberSuffix());
            this.VersionRevisionNumberSeparator(other.VersionRevisionNumberSeparator());
        }

        if (other.JobOrderSelected()) {
            await this.JobOrdersDataSource.selectByIds(other.FKJobOrder());
            jobOrderIsChanged = true;
        }

        if (other.DateSelected()) this.Date(other.Date());

        if (this.dataLoadingAfterJobOrderSet) await this.dataLoadingAfterJobOrderSet;

        await this.loadDefaults(false, jobOrderIsChanged, customerIsChanged);

        if (other.PaymentSelected()) {
            await this.PaymentModesDataSource.selectByIds(other.FKPaymentType());
            this.PaymentIBAN(other.PaymentIBAN());
            this.PaymentABI(other.PaymentABI());
            this.PaymentCAB(other.PaymentCAB());
        }

        if (other.ExpirySelected()) {
            this.FKExpiryType(other.FKExpiryType());
            if (!this.FKExpiryType()) {
                this.ExpiryType(other.ExpiryType());
            }
        }

        if (other.ValiditySelected() && this.FKValidityType) {
            this.FKValidityType(other.FKValidityType());
        }

        if (other.ShippingInfoSelected() && this.CanHaveShippingInformation()) {
            this.Packages(other.Packages());

            await this.CarriagesDataSource.selectByIds(other.FKCarriage());
            await this.DDTCausesDataSource.selectByIds(other.FKCause());
            /* await this.AspectsDataSource.selectByIds(other.FKAspect());
            await this.TransportsDataSource.selectByIds(other.FKTransport()); */
            this.Aspect(other.Aspect());
            this.Transport(other.Transport());
        }

        if (other.AtLeastOneRowSelected()) {
            const selectedRows = other.Rows().filter((row) => row.IsSelected());
            const leafsReferences = await this.getRowsLeafsReferences(selectedRows, other.DocumentType());

            const internalRows = this.Rows.peek();
            this.Rows.valueWillMutate();

            for (const row of selectedRows) {
                const originatingRows = this.generateOriginatingRowsForDestinationRow(
                    row.Id(),
                    other.DocumentType(),
                    leafsReferences,
                    this.DocumentType()
                );
                const newRow = await this.createNewRowFrom(row, other, originatingRows);
                internalRows.push(newRow);
            }

            this.Rows.valueHasMutated();
        }

        if (other.SelectedMetadatum.length > 0) {
            await this.copyMetadatumFrom(other);
        }

        other.resetSelection();

        //this.loading = false;
        this.disableDefaultsLoading = false;

        this.updateTotals();

        return true;
    }

    private async copyMetadatumFrom(other: Document): Promise<void> {
        const invalidMetadatum = [];
        const newMetadatum = [];

        if (!this.CanHaveMetadatas() || !this.canAddOrRemoveMetadatas) {
            this.infoToastService.Warning(TextResources.Invoices.DocumentMetadatumEditNotAllowed);
            return;
        }

        const availableMetadatum = await this.MetadatasDataSource.getData(null, "", 0, 10000);
        const currentMetadatum = this.Metadatas();

        for (const m of other.SelectedMetadatum) {
            const existingMetadata = currentMetadatum.firstOrDefault((md) => md.MetadataId() == m.MetadataId());

            if (existingMetadata) {
                existingMetadata.initializeFrom(m);
                continue;
            }

            const admittedMetadata = availableMetadatum.firstOrDefault((am) => am.model.Id === m.MetadataId());
            if (!admittedMetadata) {
                invalidMetadatum.push(m);
            } else {
                const newMetadata = new DocumentMetadata(this.MetadatasDataSource, {
                    Id: m.Id,
                    Order: m.Order(),
                    MetadataId: m.MetadataId(),
                    MetadataValue: m.MetadataValue(),
                    MetadataListValues: [],
                    MetadataValueType: m.MetadataValueType(),
                    ShowMarkerOnAllocationsGantt: m.ShowMarkerOnAllocationsGantt(),
                    PreloadOnDocument: m.PreloadOnDocument(),
                });
                newMetadatum.push(newMetadata);
            }
        }

        this.Metadatas(currentMetadatum.concat(newMetadatum));

        if (invalidMetadatum.length > 0) {
            const message = String.format(
                TextResources.Invoices.InvalidMetadatumError,
                invalidMetadatum.map((m) => m.MetadataLabel()).join("<br />")
            );
            this.infoToastService.Warning(message);
        }
    }

    protected getCurrencyFromDestinationDocument(currencyCode: string): IDocumentCurrencyViewModel {
        return this.DocumentCurrencies().firstOrDefault((c) => c.Currency().CodeISO4217alpha3 === currencyCode);
    }

    private getRelatedWorkflows(source: DocumentRow, rowId: number): IDocumentBuilderDocumentRelatedWorkflows[] {
        const workflows = {};
        const workflowIds = [];
        const total = source.TotalPrice();

        for (const rw of source.RelatedWorkflows()) {
            const eurPerWorkflow = rw.WorkflowAmount * source.NetUnitPrice();

            if (workflowIds.indexOf(rw.WorkflowId) == -1) {
                workflows[rw.WorkflowId] = 0;
                workflowIds.push(rw.WorkflowId);
            }

            workflows[rw.WorkflowId] += eurPerWorkflow;
        }

        const result: IDocumentBuilderDocumentRelatedWorkflows[] = [];
        for (const id of workflowIds) {
            const w = workflows[id];

            result.push({
                Id: null,
                WorkflowId: id,
                DocumentId: null,
                DocumentType: ProlifeSdk.SalEntityTypeCode,
                DocumentNumber: null,
                DocumentDate: null,
                DocumentProtocolId: null,
                RowId: rowId,
                RowDescription: null,
                RowAmount: source.Amount(),
                RowUoM: source.UoM(),
                RowPrice: source.NetUnitPrice(),
                WorkflowAmount: Math.max(0, w / total) * source.Amount(),
                ValidityStartDate: null,
                WorkflowName: null,
            });
        }

        return result;
    }

    private async createNewRowFrom(
        source: DocumentRow,
        sourceDocument: Document,
        originatingRows: IDocumentBuilderDocumentOriginatingRows[] = []
    ): Promise<DocumentRow> {
        const sourceDocumentCurrency = sourceDocument.DocumentCurrency();
        const destinationDocumentCurrency = this.DocumentCurrency();

        const sourceDocumentCurrencyCode = sourceDocumentCurrency.Currency().CodeISO4217alpha3;
        const sourceToDestCurrency = this.getCurrencyFromDestinationDocument(sourceDocumentCurrencyCode);

        const priceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(
            source.UnitPriceInDocumentCurrency(),
            sourceToDestCurrency
        );
        const totalPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(
            source.TotalPriceInDocumentCurrency(),
            sourceToDestCurrency
        );
        const netUnitPriceInDocumentCurrency = CurrencyUtils.applyCurrencyExchange(
            source.NetUnitPriceInDocumentCurrency(),
            sourceToDestCurrency
        );

        const priceInEuro = CurrencyUtils.applyCurrencyExchange(priceInDocumentCurrency, destinationDocumentCurrency);
        const totalPriceInEuro = CurrencyUtils.applyCurrencyExchange(
            totalPriceInDocumentCurrency,
            destinationDocumentCurrency
        );
        const netUnitPriceInEuro = CurrencyUtils.applyCurrencyExchange(
            netUnitPriceInDocumentCurrency,
            destinationDocumentCurrency
        );

        const newRow: IDocumentBuilderDocumentRows = {
            Id: this.getNextId(),
            FKDocument: this.DocumentId(),
            EntityType: this.DocumentType(),
            Description: source.Description(),
            AmountFormula: source.AmountFormula(),
            Amount: source.Amount(),
            FKUoM: source.FKUoM(),
            UoM: source.UoM(),
            FKCurrency: destinationDocumentCurrency.CurrencyId(),
            Currency: destinationDocumentCurrency.Currency().Symbol,
            UnitPrice: priceInEuro,
            Discounts: source.Discounts(),
            NetUnitPrice: netUnitPriceInEuro,
            TotalPrice: totalPriceInEuro,
            UnitPriceInDocumentCurrency: priceInDocumentCurrency,
            NetUnitPriceInDocumentCurrency: netUnitPriceInDocumentCurrency,
            TotalPriceInDocumentCurrency: totalPriceInDocumentCurrency,
            Order: this.Rows().length,
            Index: this.Rows().length + 1,
            FKVatCode: this.CanHaveVAT() ? source.FKVatCode() || this.DefaultFKVatCode() : null,
            VatCode: this.CanHaveVAT() ? source.VatCode() || this.DefaultVatCode() : null,
            ClosedAmount: 0,
            ManuallyClosed: false,
            FKLetterOfAttempt: null,
            LetterOfAttemptDescription: null,
            FKOffset: this.DefaultFKOffset(),
            OffsetCode: this.DefaultOffsetCode(),
        };

        originatingRows.forEach((o) => (o.DestEntityKeyId = newRow.Id));
        const relatedWorkflows = this.getRelatedWorkflows(source, newRow.Id);

        const rowVM = await this.createViewModel(newRow);
        rowVM.OriginatingRows(originatingRows);
        rowVM.RelatedWorkflows(relatedWorkflows);
        return rowVM;
    }

    async openDocumentsJournalAtDate(): Promise<void> {
        if (!this.Date()) {
            this.infoToastService.Warning(TextResources.ProlifeSdk.SelectDocumentDate);
            return;
        }

        const dialog = new DocumentsJournalDialog(this);
        this.documentJournalSettings = await dialog.show();
    }

    async LoadPaymentInfoFromReferences(): Promise<void> {
        const entities: IEntityRefKey[] = [];

        this.Rows().forEach((r) => {
            const ref = r.OriginatingRows().firstOrDefault();
            if (ref) {
                if (
                    !entities.any(
                        (e) => e.EntityKeyType == ref.SourceEntityType && e.EntityKeyId == ref.SourceEntityKeyId
                    )
                )
                    entities.push({ EntityKeyId: ref.SourceEntityKeyId, EntityKeyType: ref.SourceEntityType });
            }
        });

        let paymentInfo: IDocumentPaymentInfo[] = [];
        if (entities.length !== 0)
            paymentInfo = await this.documentsService.GetPaymentInfoFromRefDocumentRows(entities);

        const defaults = await this.documentsService.ComputeDefaultsForDocument(
            this.FKRegister(),
            this.Recipient.Id(),
            this.FKJobOrder(),
            this.DocumentType()
        );

        if (defaults.FKPaymentType) {
            const paymentType = (
                await this.PaymentModesDataSource.getById(null, [defaults.FKPaymentType])
            ).firstOrDefault();
            paymentInfo.push({
                DataSource: defaults.PaymentInfoSource,
                PaymentTypeId: defaults.FKPaymentType,
                PaymentType: paymentType?.model.Descrizione,
                PaymentIBAN: defaults.PaymentIBAN,
                PaymentABI: defaults.PaymentABI,
                PaymentCAB: defaults.PaymentCAB,
                ExpiryTypeId: null,
                ExpiryType: null,
            });
        }

        if (defaults.FKExpiryType) {
            const expiryType = (await this.ExpiriesDataSource.getById(null, [defaults.FKExpiryType])).firstOrDefault();
            const paymentInfoWithSameSource = paymentInfo.firstOrDefault(
                (p) => p.DataSource === defaults.ExpireInfoSource
            );
            if (paymentInfoWithSameSource) {
                paymentInfoWithSameSource.ExpiryTypeId = defaults.FKExpiryType;
                paymentInfoWithSameSource.ExpiryType = expiryType?.model.Descrizione;
            } else {
                paymentInfo.push({
                    DataSource: defaults.ExpireInfoSource,
                    PaymentTypeId: null,
                    PaymentType: null,
                    PaymentIBAN: null,
                    PaymentABI: null,
                    PaymentCAB: null,
                    ExpiryTypeId: defaults.FKExpiryType,
                    ExpiryType: expiryType?.model.Descrizione,
                });
            }
        }

        if (paymentInfo.length == 0) {
            this.infoToastService.Success(
                ProlifeSdk.TextResources.ProlifeSdk.PaymentInfoLoadedFromImportsEmptyDefaultsMessage
            );
            return;
        }

        if (paymentInfo.length == 1) {
            const pi = paymentInfo.firstOrDefault();

            await this.ExpiriesDataSource.selectByIds(pi.ExpiryTypeId);
            await this.PaymentModesDataSource.selectByIds(pi.PaymentTypeId);
            this.PaymentIBAN(pi.PaymentIBAN);
            this.PaymentABI(pi.PaymentABI);
            this.PaymentCAB(pi.PaymentCAB);

            this.infoToastService.Success(
                String.format(ProlifeSdk.TextResources.ProlifeSdk.PaymentInfoLoadedFromImportsMessage, pi.DataSource)
            );
            return;
        }

        const hasSameOrNoteSetExpiryType =
            paymentInfo.filter((pi) => !!pi.ExpiryTypeId).distinctBy((pi) => pi.ExpiryTypeId).length <= 1;
        const hasSameOrNotSetExpiryLabel =
            paymentInfo.filter((pi) => !!pi.ExpiryType).distinctBy((pi) => pi.ExpiryType).length <= 1;
        const hasSameOrNotSetPaymentMode =
            paymentInfo.filter((pi) => !!pi.PaymentTypeId).distinctBy((pi) => pi.PaymentTypeId).length <= 1;

        if (hasSameOrNoteSetExpiryType && hasSameOrNotSetExpiryLabel && hasSameOrNotSetPaymentMode) {
            const pi = paymentInfo.firstOrDefault((pi) => !!pi.PaymentTypeId);
            const ei = paymentInfo.firstOrDefault((ei) => !!ei.ExpiryTypeId || !!ei.ExpiryType);

            if (ei.ExpiryTypeId) await this.ExpiriesDataSource.selectByIds(ei.ExpiryTypeId);
            else this.ExpiryType(ei.ExpiryType);

            await this.PaymentModesDataSource.selectByIds(pi.PaymentTypeId);
            this.PaymentIBAN(pi.PaymentIBAN);
            this.PaymentABI(pi.PaymentABI);
            this.PaymentCAB(pi.PaymentCAB);

            if (pi.DataSource === ei.DataSource)
                this.infoToastService.Success(
                    String.format(
                        ProlifeSdk.TextResources.ProlifeSdk.PaymentInfoLoadedFromImportsMessageWithConsistentData,
                        pi.DataSource,
                        pi.DataSource
                    )
                );
            else
                this.infoToastService.Success(
                    String.format(
                        ProlifeSdk.TextResources.ProlifeSdk
                            .PaymentInfoLoadedFromImportsMessageWithConsistentDataButDifferentSources,
                        pi.DataSource,
                        ei.DataSource
                    )
                );

            return;
        }

        const dialog = new PaymentInfoFromImportsDialog(paymentInfo);
        const chosenInfo = await dialog.Show();

        if (chosenInfo) {
            await this.ExpiriesDataSource.selectByIds(chosenInfo.ExpiryTypeId);
            await this.PaymentModesDataSource.selectByIds(chosenInfo.PaymentTypeId);
            this.PaymentIBAN(chosenInfo.PaymentIBAN);
            this.PaymentABI(chosenInfo.PaymentABI);
            this.PaymentCAB(chosenInfo.PaymentCAB);
        }
    }

    async AddSecondaryRecipient(): Promise<void> {
        if (this.Recipient.LegalPerson()) {
            const confirm = await this.ShowConfirmSecondaryRecipientsOnInvoice();
            if (confirm) this.createAndInsertSecondaryRecipientViewModel();
            return;
        }

        this.createAndInsertSecondaryRecipientViewModel();
    }

    private createAndInsertSecondaryRecipientViewModel(
        recipient: IDocumentBuilderDocumentSecondaryRecipients = null
    ): void {
        this.SecondaryRecipients.push(new SecondaryRecipient(recipient, this));
    }

    public ShowConfirmSecondaryRecipientsOnInvoice(): Promise<boolean> {
        return this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.ProlifeSdk.SecondaryRecipientsOnInvoiceWithLegalPerson,
            ProlifeSdk.TextResources.ProlifeSdk.AbortSecondaryRecipient,
            ProlifeSdk.TextResources.ProlifeSdk.ConfirmSecondaryRecipient
        );
    }

    public RemoveSecondaryRecipient(recipient: SecondaryRecipient): void {
        const recipients = this.SecondaryRecipients();
        const index = recipients.indexOf(recipient);
        if (index > -1) {
            recipients.splice(index, 1);
            this.SecondaryRecipients(recipients);
        }
    }

    private entityIsLeaf(entityCode: string): boolean {
        return (
            entityCode == ProlifeSdk.EstimatedCallRightTypeCode ||
            entityCode == ProlifeSdk.EstimatedPurchaseEntityTypeCode ||
            entityCode == ProlifeSdk.EstimatedWorkEntityTypeCode ||
            entityCode == ProlifeSdk.PurchasesEntityTypeCode ||
            entityCode == ProlifeSdk.WarehouseArticleEntityTypeCode ||
            entityCode == ProlifeSdk.WorkedHoursEntityTypeCode
        );
    }

    public async getRelatedDocumentsFromRefDocumentRows(): Promise<void> {
        let refs: IEntityRefKeyWithReferencingRowNumber[] = [];
        this.Rows().forEach((row: DocumentRow) => {
            refs = refs.concat(
                row
                    .OriginatingRows()
                    .map((ref) => ({
                        EntityKeyId: ref.SourceEntityKeyId,
                        EntityKeyType: ref.SourceEntityType,
                        RowNumber: row.Index(),
                    }))
                    .filter((key) => !this.entityIsLeaf(key.EntityKeyType))
            );
        });

        if (refs.length == 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.RelatedDocumentInfoEmptyRefsError);
            return;
        }

        const documents = await this.documentsService.getRelatedDocumentsFromRefDocumentRows(
            this.DocumentId(),
            this.DocumentType(),
            refs
        );

        let matches: RelatedDocumentInfo[] = [];
        //let matchesFound = false;
        documents.forEach((d: IRelatedDocument) => {
            matches = this.RelatedDocuments().filter((i) => i.isDocument(d.RelatedDocumentId, d.RelatedDocumentType));
            if (matches.length == 0) {
                d.Id = this.documentsService.getFakeId();
                d.DocumentId = this.DocumentId();
                d.DocumentType = this.DocumentType();

                this.createAndInsertRelatedDocumentViewModel(d);

                //matchesFound = true;

                return;
            }

            matches[0].updateFrom(d);
        });
        const message = ProlifeSdk.TextResources.Invoices.RelatedDocumentInfoLoaded;

        /*if (matchesFound)
            message += "<br/>" + ProlifeSdk.TextResources.Invoices.RelatedDocumentInfoAlreadyLoaded;*/

        this.infoToastService.Success(message);
    }

    public createAndInsertRelatedDocumentViewModel(
        documentInfo: IDocumentBuilderDocumentRelatedDocuments = null
    ): void {
        if (!documentInfo) {
            documentInfo = {
                Id: this.documentsService.getFakeId(),
                DocumentId: this.DocumentId(),
                DocumentType: this.DocumentType(),
                RelatedDocumentId: null,
                RelatedDocumentType: null,
                RelatedDocumentNumber: null,
                RelatedDocumentDate: moment().toDate(),
                RelatedDocumentProtocolId: null,
                RelatedDocumentCIG: null,
                RelatedDocumentCUP: null,
                CreditNote: false,
                AccompanyingInvoice: false,
                SALInvoice: false,
                RowsIndices: null,
            };
        }
        this.RelatedDocuments.push(new RelatedDocumentInfo(documentInfo, this.RelatedDocuments));
    }

    addRelatedDocument(): void {
        this.createAndInsertRelatedDocumentViewModel();
    }

    public async createViewModel(row: IDocumentBuilderDocumentRows): Promise<DocumentRow> {
        const newRow = new DocumentRow(
            row,
            this.document?.ReferencingRows.filter((ref) => ref.SourceEntityKeyId == row.Id) ?? [],
            this.document?.OriginatingRows.filter((ref) => ref.DestEntityKeyId == row.Id) ?? [],
            this.document?.RelatedWorkflows.filter((rw) => rw.RowId == row.Id) ?? [],
            {
                Document: this,
                SynchronizePrices: this.SynchronizePrices,
            }
        );
        await newRow.load();

        newRow.setCustomerId(this.Recipient.Id(), this.loading);
        newRow.setDefaultWorkflow(this.WarehouseWorkflowId, !this.ReadOnly());

        return newRow;
    }

    private nextId = -1;

    public getNextId() {
        return this.nextId--;
    }

    public async createViewModelFromWizardRow(
        row: IDocumentDataWizardRow,
        workflows: IWorkflowForSelectList[]
    ): Promise<DocumentRow> {
        const data = row.Row.getData();
        data.Id = this.getNextId();
        row.OriginatingRows.forEach((r) => (r.DestEntityKeyId = data.Id));
        row.RelatedWorkflows.forEach((r) => {
            r.RowId = data.Id;
            r.RowDescription = data.Description;
            r.RowPrice = data.TotalPrice;
            r.RowUoM = data.UoM;
            r.DocumentType = this.DocumentType();
            r.DocumentId = this.DocumentId();
            r.DocumentNumber = this.Number();
            r.DocumentProtocolId = this.FKRegister();
            r.WorkflowName = workflows.firstOrDefault((w) => w.Id == r.WorkflowId)?.Title;
        });

        if (data.Amount == 0) row.OriginatingRows = [];

        const newRow = new DocumentRow(data, [], row.OriginatingRows, row.RelatedWorkflows, {
            Document: this,
            SynchronizePrices: this.SynchronizePrices,
        });
        await newRow.load();
        newRow.updateRelatedWorkflowsTotalsInfo();
        return newRow;
    }

    public GetDocumentInfoForInlineRefProvider(): IDocumentInfoForInlineRefProvider {
        return {
            DocTypeCode: this.DocumentType(),
            IsWarehouseTransfer: this.IsWarehouseTransfer,
            CustomerId: this.Recipient.Id,
            JobOrderId: this.FKJobOrder,
            DocumentDate: this.Date,
            OverrideDiscountCatalog: this.OverrideCustomerGroup,
            DocumentCurrencies: this.DocumentCurrencies,
            SourceWarehouseId: this.SourceWarehouseId,
        };
    }

    public RemoveRow(row: DocumentRow) {
        this.removeRowReferencesFromTaxRelief(row);
        this.Rows.remove(row);
    }

    private removeRowReferencesFromTaxRelief(row: DocumentRow) {
        const taxRelief = this.TaxRelief();

        for (const taxReliefRow of taxRelief) {
            if (taxReliefRow.DocumentRow() === row) {
                taxReliefRow.DocumentRow(null);
            }
        }
    }

    public GetWizardInitializationInfo(): IWizardInitializationInfo {
        const info: IWizardInitializationInfo = {
            CustomerId: this.Recipient.Id(),
            IsWarehouseTransfer: this.IsWarehouseTransfer(),
            IsCustomer: this.CanSelectCustomers(),
            JobOrderId: this.FKJobOrder(),
            SourceWarehouseId: this.SourceWarehouseId(),
            DestinationDocumentProtocol: this.RegisterCache,
            OverrideDiscountGroupId: this.OverrideCustomerGroup(),
            DocumentDate: this.Date(),
            DocTypeCode: this.DocumentType(),
            DocumentName: this.DocumentTypeLabel(),
            DocumentOriginatingRows: this.Rows().selectMultiple((r) => r.OriginatingRows()),
            DefaultFKVatCode: this.DefaultFKVatCode(),
            DefaultVatCode: this.DefaultVatCode(),
            DocumentCurrenciesInfo: {
                DocumentCurrency: this.DocumentCurrency,
                DocumentCurrencies: this.DocumentCurrencies,
                DocumentCurrencySymbol: this.DocumentCurrencySymbol,
                ReadOnly: this.LockDocumentCurrencyChanges,
            },
            SourceWarehouseNotSupported:
                !this.CanReferenceArticles() || this.DocumentType() === ProlifeSdk.WarehouseLoadEntityTypeCode,
            CanHaveVat: this.CanHaveVAT(),
            DestinationWarehouseId: this.WarehouseId(),
        };
        return info;
    }

    public async importData(): Promise<void> {
        const wizard = new ImportDocumentDataWizard(this.GetWizardInitializationInfo());
        const newRows = await wizard.showModal();
        const newRowsVMs: DocumentRow[] = [];
        let hasAtLeastOneImportedDocument = false;

        let workflows: IWorkflowForSelectList[] = [];
        const allWorkflowIds = newRows
            .selectMultiple((r) => r.RelatedWorkflows)
            .map((r) => r.WorkflowId)
            .distinct();
        if (allWorkflowIds.length > 0) {
            workflows = await this.todolistService.GetWorkflowsForSelectListByIds(allWorkflowIds);
        }

        for (const newRow of newRows) {
            const row = newRow.Row;
            row.FKDocument(this.DocumentId());
            row.Order(this.Rows().length);

            if (!row.FKOffset()) {
                row.FKOffset(this.DefaultFKOffset());
                row.OffsetCode(this.DefaultOffsetCode());
            }

            hasAtLeastOneImportedDocument =
                hasAtLeastOneImportedDocument || newRow.OriginatingRows.any((r) => !this.isLeaf(r.SourceEntityType));
            newRowsVMs.push(await this.createViewModelFromWizardRow(newRow, workflows));
        }

        this.updateWarehouseArticleCodesOnRows(newRowsVMs);
        this.getAndSetWarehouseEntitiesStockInfoOnRows(newRowsVMs);
        this.Rows.push(...newRowsVMs);

        if (hasAtLeastOneImportedDocument) this.LoadPaymentInfoFromReferences();

        //Se configurato su registro scrivo i nomi dei documenti importati nelle note
        if (!this.RegisterCache?.InsertOriginatingDocumentsIntoNotes) return;

        await this.updateNotesFromReferencedDocuments();
    }

    private async getAndSetWarehouseEntitiesStockInfoOnRows(rows: DocumentRow[]): Promise<void> {
        if (!this.CanViewArticleStockInfoOnRows) return;

        const rowRefsMapping: IRowRefMapping[] = rows
            .filter((r) => r.OriginatingRows().length === 1)
            .map(this.getRowRefMapping, this);
        const warehouseId =
            this.DocumentType() === ProlifeSdk.WarehouseLoadEntityTypeCode
                ? this.WarehouseId()
                : this.SourceWarehouseId();

        if (!warehouseId || rowRefsMapping.length === 0) return;

        try {
            const articleStocksInfo = await this.documentsService.GetWarehouseEntitiesStocksInfoOnRows(
                rowRefsMapping,
                warehouseId
            );
            for (const stockInfo of articleStocksInfo) {
                const targetRow = rows.firstOrDefault((r) => r.Id() == stockInfo.DestEntityKey);
                if (!targetRow) continue;

                targetRow.ReferencedArticleStocksInfo(stockInfo);
            }
        } catch (e) {
            this.infoToastService.Error(TextResources.Invoices.GetWarehouseEntitiesStocksInfoOnRowsError);
        }
    }

    private getRowRefMapping(r: DocumentRow) {
        const ref = r.OriginatingRows().firstOrDefault();
        return {
            DestEntityKey: r.Id(),
            DestEntityType: this.DocumentType(),
            SourceEntityKey: ref.SourceEntityKeyId,
            SourceEntityType: ref.SourceEntityType,
        };
    }

    private async updateNotesFromReferencedDocuments() {
        const $notes = $(this.Notes() || "<div></div>");
        $notes.find("div.documentNotes").remove();
        const rowsReferences = this.Rows()
            .selectMultiple((r) => r.OriginatingRows())
            .map((r) => ({
                RefId: r.RefId,
                DestinationId: r.DestEntityKeyId,
                DestinationEntityType: r.DestEntityType,
                CatalogId: r.CatalogId,
                EstimatedBudgetForTaskId: r.EstimatedBudgetForTaskId,
                TaskId: r.TaskId,
                Id: r.SourceEntityKeyId,
                FKDocument: r.DocumentId,
                EntityType: r.SourceEntityType,
                Amount: r.Amount,
                FKCurrency: null,
                UnitPrice: r.UnitPrice,
                Discounts: r.Discounts,
                NetUnitPrice: r.NetUnitPrice,
                TotalPrice: r.NetPrice,
                WarehouseId: r.WarehouseId,
            }));
        const docsInfo = await this.documentsService.GetDocumentsInfoFromRefDocumentRows(rowsReferences);
        let notes = '<div class="documentNotes">';
        for (const docInfo of docsInfo) {
            notes +=
                (this.RegisterCache?.DocumentIntoNotesPrefix || "") +
                String.format(
                    TextResources.Invoices.DocumentName,
                    docInfo.NomeRegistroIVA,
                    docInfo.Number,
                    moment(docInfo.Date).format("L")
                );
        }
        notes += "</div>";
        $(notes).prependTo($notes);
        this.Notes($notes.html());
        this.NotesTrigger.valueHasMutated();
    }

    private isLeaf(entityType: string): boolean {
        switch (entityType) {
            case ProlifeSdk.WarehouseArticleEntityTypeCode:
            case ProlifeSdk.PurchasesEntityTypeCode:
            case ProlifeSdk.WorkedHoursEntityTypeCode:
            case ProlifeSdk.EstimatedCallRightTypeCode:
            case ProlifeSdk.EstimatedPurchaseEntityTypeCode:
            case ProlifeSdk.EstimatedWorkEntityTypeCode:
                return true;

            default:
                return false;
        }
    }

    public async updateWarehouseArticleCodesOnRows(rows: DocumentRow[]): Promise<void> {
        const allArticleIds: number[] = [];
        const rowsToUpdate: DocumentRow[] = [];

        for (const row of rows) {
            if ((row.OriginatingRows || []).length == 1) {
                // Se c'è un solo riferimento
                const ref = row.OriginatingRows[0];

                if (ref.SourceEntityType == ProlifeSdk.WarehouseArticleEntityTypeCode) {
                    // Se il riferimento è di tipo articolo
                    if (allArticleIds.indexOf(ref.SourceEntityKeyId) == -1)
                        //Faccio una distinct dei codici articolo
                        allArticleIds.push(ref.SourceEntityKeyId);

                    rowsToUpdate.push(row);
                }
            }
        }

        const aliases = await this.aliasesService.ResolveArticleAliasesByIds(allArticleIds, this.Recipient.Id());

        for (const row of rowsToUpdate) {
            const ref = row.OriginatingRows[0];
            const articleId = ref.SourceEntityKeyId;

            const alias = aliases.firstOrDefault((a) => a.ArticleId == articleId);
            if (!alias) continue;

            row.Description(alias.AliasedCode + " " + alias.Description);
        }
    }

    private async showCurrenciesManager(): Promise<void> {
        const listener: IDocumentCurrenciesManagerListener = this;

        const componentInfo: IPopoverComponentInfo = {
            componentName: "document-currencies-manager",
            title: TextResources.Invoices.CurrenciesLabel,
            model: {
                DocumentCurrencies: this.DocumentCurrencies,
                ReadOnly: this.ReadOnly,
                ShowEditButtonForExchangeValues: this.DocumentId() > 0,
                CurrenciesManager: ko.observable(),

                close: function () {
                    const editor = this.CurrenciesManager();

                    if (!editor) return;

                    if (editor.validate()) {
                        if (listener) listener.onDocumentCurrenciesEditEnd();
                        this.modal.close(null);
                    }
                },
            },
            params: "DocumentCurrencies: DocumentCurrencies, ReadOnly: ReadOnly, ShowEditButtonForExchangeValues: ShowEditButtonForExchangeValues, InjectTo: CurrenciesManager",
        };

        return this.dialogsService.ShowModalComponent(componentInfo, "medium");
    }

    private async exportElectronicInvoice() {
        if (!this.CanExportElectronicInvoices()) {
            this.infoToastService.Error(TextResources.Invoices.ElectronicInvoicingAccessDenied);
            return;
        }

        this.dialogsService.LockUI(TextResources.Invoices.ElectronicInvoicingOnExport);

        try {
            await this.invoicesServices.ExportDocumentForElectronicInvoicing(this.DocumentType(), this.DocumentId());
            this.Blocked(true);
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    private async chooseDiscountCatalog(item: unknown, event: Event): Promise<void> {
        const selector = new CustomerGroupSelectorDialog(this.OverrideCustomerGroup());

        const result = await this.dialogsService.ShowPopover<ICustomerGroup>(
            event.currentTarget as HTMLElement,
            selector,
            "bottom"
        );

        this.OverrideCustomerGroup(result.Id);

        for (const row of this.Rows()) {
            if (!row.canChangeDiscountCatalog()) continue;

            await row.doChangePrice(result.Id);
        }
    }

    public excelExporterDataProvider() {
        return {
            documentId: this.DocumentId(),
        };
    }

    public refExcelExporterDataProvider() {
        return {
            documentId: this.DocumentId(),
            documentType: this.DocumentType(),
        };
    }

    public moveRowToIndex(row: DocumentRow, newIndex: number) {
        if (row.Index() == newIndex)
            //La posizione non è cambiata, non faccio nulla
            return;

        const allRows = this.Rows();
        this.Rows.valueWillMutate();
        allRows.splice(allRows.indexOf(row), 1);

        if (newIndex <= 1) {
            //Move to first position
            allRows.unshift(row);
        } else {
            const maxIndex = this.Rows().max((r) => r.Index());
            if (newIndex >= maxIndex) {
                //Move to last position
                allRows.push(row);
            } else {
                allRows.splice(newIndex - 1, 0, row);
            }
        }

        this.updateTotals();

        this.Rows.valueHasMutated();
    }

    public OpenJobOrder() {
        if (!this.FKJobOrder() || this.FKJobOrder() < 0) return;

        window.open(this.jobOrdersService.getJobOrderUrl(this.FKJobOrder()), "_blank");
    }

    public async openJobOrderDetailedInfo(viewModel: unknown, e: Event): Promise<void> {
        if (!this.FKJobOrder() || this.FKJobOrder() < 0) return;

        return this.dialogsService.ShowModal(
            new SplashPageDetailsDialog({
                Title: String.format(TextResources.JobOrder.DetailedInfoTitle, this.JobOrderName()),
                JobOrderId: this.FKJobOrder(),
            })
        );
    }

    public OpenBlog() {
        if (!this.FKJobOrder() || this.FKJobOrder() < 0) return;

        this.blogService.openBlogInNewWindow(this.FKJobOrder());
    }

    private async loadAndApplyDefaultMetadatas(): Promise<void> {
        const defaultMetadatas = await this.documentsService.ComputeDefaultMetadatas(
            this.FKRegister(),
            this.Recipient.Id(),
            this.FKJobOrder()
        );
        this.applyDefaultMetadatas(defaultMetadatas);
    }

    private renderMetadatasCustomActions() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        return (
            <button
                className="btn btn-primary btn-xs"
                data-bind={{ asyncClick: vm.loadAndApplyDefaultMetadatas.bind(vm) }}
                title={TextResources.Invoices.LoadDefaultMetadatasTitle}
            >
                <i className="fa fa-database"></i>
            </button>
        );
    }
}

function DocumentReadOnly(props: { document: Document }) {
    let $data: Document;
    const { document } = props;

    return (
        <>
            <DocumentHeader document={document} />
            <DocumentInformationReadOnly document={document} />

            <div class="document-expansible-area">
                <DocumentSecondaryRecipientsReadOnly />

                <div class="invoice-rows bootstrap">
                    <document-rows document={() => "$data"} />
                </div>

                <DocumentVatRows />
                <DocumentTaxReliefEditor />
                <DocumentTotals />
                <DocumentAdditionaInformationReadOnly />
                <DocumentMetadatas />
                <DocumentResourcesEditor />
                <DocumentShippingInformation />
                <DocumentCause />
                <DocumentNotesReadOnly />
            </div>

            <DocumentAttachmentsReadOnly />
            <DocumentFooter />
            <DocumentActions document={props.document} />

            <div
                class="copy hidden-print fa fa-arrow-right"
                data-bind={{
                    visible: $data.CanCopy() && $data.IsOnTop(),
                    click: $data.copyToEditingDocument.bind($data),
                    tooltip: { placement: "right" },
                }}
                title="Copia gli elementi selezionati sul documento in editing"
            ></div>
        </>
    );
}

function DocumentNonReadOnly(props: { document: Document }) {
    const { document } = props;

    return (
        <>
            <DocumentHeader document={document} />
            <DocumentInformationNonReadOnly document={document} />

            <div class="document-expansible-area">
                <DocumentSecondaryRecipients />

                <div class="invoice-rows bootstrap">
                    <document-rows document={() => "$data"} />
                </div>

                <DocumentVatRows />
                <DocumentTaxReliefEditor />
                <DocumentTotals />
                <DocumentAdditionaInformationNonReadOnly />
                <DocumentMetadatas />
                <DocumentResourcesEditor />
                <DocumentShippingInformation />
                <DocumentCause />
                <DocumentNotesNonReadOnly />
            </div>

            <DocumentAttachmentsNonReadOnly />
            <DocumentFooter />
            <DocumentActions document={props.document} />
        </>
    );
}

function render(params: IDocumentParams, document: Document) {
    let vm: Document;
    const shadowClass = ko.unwrap(params.Shadow) ? " shadow" : "";

    return (
        <div class={"document " + classes.document + shadowClass} data-as={{ vm }}>
            <ko-bind data-bind={{ if: vm.ReadOnly }}>
                <DocumentReadOnly document={document}></DocumentReadOnly>
            </ko-bind>
            <ko-bind data-bind={{ ifnot: vm.ReadOnly }}>
                <DocumentNonReadOnly document={document}></DocumentNonReadOnly>
            </ko-bind>
        </div>
    );
}

ko.components.register("document", {
    viewModel: {
        createViewModel: (params: IDocumentParams, componentInfo: components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new Document(params);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [render(params, vm)]);

            return vm;
        },
    },
    template: [],
});
