import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 05/02/2018
 * Time: 12:48
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { Agenda } from "./ui/Agenda";
import { FestivitiesAgenda } from "./ui/FestivitiesAgenda";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../Core/interfaces/IAuthenticationService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { IAuthenticationServiceObserver } from "../../Core/interfaces/IAuthenticationServiceObserver";
import { IAuthorizationServiceObserver } from "../../Core/interfaces/IAuthorizationServiceObserver";
import { IUserForClient } from "../../Core/interfaces/IPrincipal";
import { LazyImport } from "../../Core/DependencyInjection";

export class AgendaApplication implements IApplication, IAuthenticationServiceObserver, IAuthorizationServiceObserver {
    templateName = "agenda-application";
    templateUrl = "agenda/templates";
    templateData: ko.Observable<Agenda|FestivitiesAgenda> = ko.observable();
    public tileColor  = 'tile-blue';

    public navBarActions: ko.ObservableArray<any> = ko.observableArray([]);
    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService : IAuthorizationService;
    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService : IAuthenticationService;

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService.registerApplication(this);
        this.authenticationService.addObserver(this);
        this.authorizationService.addObserver(this);

        var sidebarAvailabilityInterceptor = ko.computed(() => {
            if (this.templateData() instanceof Agenda) {
                if (!this.templateData().SelectedAgenda()) {
                    this.canShowSidebar(false);
                    return;
                }
                this.canShowSidebar(this.templateData().SelectedAgenda().TimeslotsModeEnabled());
                return;
            }

            this.canShowSidebar(false);
        });
    }

    getName(): string {
        return ProlifeSdk.TextResources.Agenda.AgendaApplicationName;
    }

    getIcon(): string {
        return "fa fa-book";
    }

    registerRoutes(): void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);

        opaService.Get("#/Agenda", (context) => this.start(context));
        opaService.Get("#/Agenda/:id", (context) => this.start(context));
    }

    getApplicationRoute(): string {
        return "#/Agenda";
    }

    getApplicationCode(): string {
        return ProlifeSdk.AgendaApplication;
    }

    onGoingDown(): void {
        if(this.templateData()) {
            this.templateData().dispose && this.templateData().dispose();
            this.templateData(undefined);
        }
    }

    userLoggedIn(user: IUserForClient): void {
    }

    userLoggedOut(): void {
    }

    loginFailed(jqXHR: JQueryXHR, textStatus: string, errorThrown: any, options: any): void {
    }

    authorizationLoaded(rights: any): void {
    }

    private start(context : any)
    {
        this.onGoingDown();

        var agendaId = context.params["id"];

        this.templateData(new Agenda(this.serviceLocator, agendaId));

        this.navBarActions([
        {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.CreateAgendaEvent),
            CanRun: ko.computed(() => {
                return this.templateData() instanceof Agenda
                    && this.authorizationService.isAuthorized("Agenda_CreateEvents")
                    && !!this.templateData().SelectedAgenda();
            }),
            Icon: ko.observable("fa fa-plus"),
            Run: () => { (this.templateData() as Agenda).AddEvent(); }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.CreateFestivity),
            CanRun: ko.computed(() => { return this.templateData() instanceof FestivitiesAgenda && !!this.templateData().SelectedAgenda(); }),
            Icon: ko.observable("fa fa-plus"),
            Run: () => { (this.templateData() as FestivitiesAgenda).AddFestivity(); }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.ModifyAgendaEvent),
            CanRun: ko.computed(() => {
                return this.templateData() instanceof Agenda
                    && !!this.templateData().SelectedEvent();
            }),
            Icon: ko.observable("fa fa-edit"),
            Run: () => { (this.templateData() as Agenda).EditSelectedEvent(); }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.ModifyFestivity),
            CanRun: ko.computed(() => { return this.templateData() instanceof FestivitiesAgenda && !!this.templateData().SelectedEvent(); }),
            Icon: ko.observable("fa fa-edit"),
            Run: () => { (this.templateData() as FestivitiesAgenda).EditSelectedFestivity(); }
        },{
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.CreateAgenda),
            CanRun: ko.computed(() => {
                return this.templateData() instanceof Agenda
                    && this.authorizationService.isAuthorized("Agenda_CreateAgendas");
            }),
            Icon: ko.observable("fa fa-plus"),
            Run: () => { (this.templateData() as Agenda).AddAgenda(); }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.ModifyAgenda),
            CanRun: ko.computed(() => {
                return this.templateData() instanceof Agenda
                    && !!this.templateData().SelectedAgenda();
            }),
            Icon: ko.observable("fa fa-edit"),
            Run: () => { (this.templateData() as Agenda).EditSelectedAgenda(); }
        },{
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.UserConfig),
            CanRun: ko.computed(() => { return true; }),
            Icon: ko.observable("fa fa-gear"),
            Run: () => { this.templateData().ShowUserConfigDialog(); }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.FestivitiesAgendas),
            CanRun: ko.computed(() => {
                return this.templateData() instanceof Agenda && this.authorizationService.isAuthorized("Agenda_ManageFestivitiesAndClosingDays");
            }),
            Icon: ko.observable("fa fa-home"),
            Right: true,
            Run: () => {
                this.templateData().dispose && this.templateData().dispose();
                //delete this.templateData();

                this.templateData(new FestivitiesAgenda(this.serviceLocator));
                //this.canShowSidebar(false);
            }
        }, {
            Name: ko.observable(ProlifeSdk.TextResources.Agenda.Agendas),
            CanRun: ko.computed(() => { return this.templateData() instanceof FestivitiesAgenda; }),
            Icon: ko.observable("fa fa-book"),
            Right: true,
            Run: () => {
                this.templateData().dispose && this.templateData().dispose();
                //delete this.templateData();

                this.templateData(new Agenda(this.serviceLocator));
                //this.canShowSidebar(true);
            }
        }]);

        this.applicationsService.startApplication(this);
    }
}