import * as ProlifeSdk from "../../ProlifeSdk";
import { CommentBlogEvent } from "./CommentBlogEvent";
import { EventBaseInput } from "./EventBaseInput";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../interfaces/ILogEvent";
import { ITag } from "../../interfaces/ITag";

export class CommentInput extends EventBaseInput {
    public templateName = "comment-details";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.NewComment;

    constructor(
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver,
        private event: CommentBlogEvent
    ) {
        super(serviceLocator, contextEventsObserver);
        this.iconName = "f-icon-pencil";
        this.setValues(event);
    }

    public setValues(item: CommentBlogEvent): void {
        super.setValues(item);
    }

    getBlogEvent(): ILogEvent {
        const Tags: ITag[] = [];

        const localBlogEvent: ILogEvent = this.getBaseBlogEvent("Blog.Comment", Tags);
        localBlogEvent.ReferenceDate = localBlogEvent.TimeStamp;
        return localBlogEvent;
    }
}
