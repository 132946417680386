import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { SalDocumentsProvider } from "./jobOrder/providers/SalDocumentsProvider";
import { SalsDataSource } from "./jobOrder/documents/data-sources/SalsDataSource";
import { SalRefDetailsViewModelFactory } from "./jobOrder/providers/refs-details-factories/SalRefDetailsViewModelFactory";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { SalProtocolType } from "./jobOrder/documents/SalProtocolType";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IJobOrderService } from "../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ISal, ISalRow, ISalForWizard, IFullSal } from "../ProlifeSdk/interfaces/sal/ISal";
import { ISalService, ISalServiceObserver, IGetSalsForListRequest, ISalForList } from "../ProlifeSdk/interfaces/sal/ISalService";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService, IEntityDescriptor } from "../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IProLifeSdkService } from "../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IService } from "../Core/interfaces/IService";
import { IVatRegisters, IVatRegister } from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

class SalService extends ProLifeService implements ISalService
{
    private jobOrderService : IJobOrderService;
    private sdkService : IProLifeSdkService;
	private ajaxService : IAjaxService;
    private entitiesService : IEntityProviderService;
    private documentsService: IDocumentsService;
    private hasRegisteredDocumentProviders : boolean = false;
    private observers : ISalServiceObserver[] = [];

	constructor(private serviceLocator : IServiceLocator)
    {
        super(ProlifeSdk.JobOrderApplicationCode);
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ISalService>(), this);
		this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }
    
    InitializeService() {
        super.InitializeService();

        this.entitiesService = <IEntityProviderService> this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
        this.jobOrderService = <IJobOrderService> this.serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.sdkService = <IProLifeSdkService> this.serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.documentsService = <IDocumentsService> this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);

        this.entitiesService.RegisterEntity(<IEntityDescriptor>{
            EntityCode : ProlifeSdk.SalEntityTypeCode,
            EntityName : ProlifeSdk.TextResources.JobOrder.SAL,
            PluralEntityName : ProlifeSdk.TextResources.JobOrder.SAL,
            EntityIsMale : true
        });

        this.settingsService.addObserver(this);
    }

    public OnServerInitializationDataLoaded()
    {
        //Se il modulo commesse è disabilitato non devo gestire i SAL
        if(!this.jobOrderService.IsServiceEnabled())
            return;

        new SalsDataSource(this.serviceLocator);
        this.entitiesService.registerReferenceDetailsViewModelFactory(ProlifeSdk.SalEntityTypeCode, new SalRefDetailsViewModelFactory());

        if(!this.hasRegisteredDocumentProviders)
            this.RegisterProtocols();
    }

	getServiceType(): string {
		return ProlifeSdk.SalServiceType;
	}

	isOfType(serviceType: string): boolean {
		return serviceType == this.getServiceType();
	}

	getAll(jobOrderId? : number, filter? : string) : Promise<ISal[]>
    {
        let query = "";
        let options = [];
        if(jobOrderId || filter) {
            query = "?$filter=";
        }

        if(jobOrderId) {
            options.push("(JobOrderId eq " + jobOrderId + ")");
        }
        if(filter) {
            options.push("substringof('" + encodeURIComponent(filter) + "', Details/CustomerName)");
        }
        query += options.join(" and ");

		return this.ajaxService.Get("JobOrder-api", "Sal" + query,{});
	}

    getPart(skip : number, count : number, jobOrderId? : number) : Promise<ISal[]>
    {
        let query = "";

        if(jobOrderId)
            query = "&$filter=JobOrderId eq " + jobOrderId;

        return this.ajaxService.Get("JobOrder-api", "Sal?$skip=" + skip + "&$top=" + count + query,{});
    }

    getDocumentRowsById(documentId : number) : Promise<ISalRow[]> {
        if(!documentId) {
            return Promise.reject<ISalRow[]>(undefined);
        }

        return this.ajaxService.Post("JobOrder-api", "SalRows/GetSalRows", {
            methodData: {
                DocumentId: documentId
            }
        });

        //return this.ajaxService.Get("JobOrder-api", "SalRows/" + documentId, {});
    }

    GetSalsForWizard(textFilter : string,
                          getWithoutJobOrder : boolean, jobOrderId : number,
                          getForAllCustomers : boolean, customerId : number,
                          from : Date, to : Date,
                          skip : number, count : number) : Promise<ISalForWizard[]>
    {
        return this.ajaxService.Post("JobOrder-api", "Sal/GetSalsForWizard", {
            methodData: {
                TextFilter : textFilter,
                JobOrderId : jobOrderId,
                GetWithoutJobOrder : getWithoutJobOrder,
                CustomerId : customerId,
                GetForAllCustomers : getForAllCustomers,
                From : this.sdkService.Utilities.Dates.AddGmtTime(from),
                To : this.sdkService.Utilities.Dates.AddGmtTime(to),
                Skip : skip,
                Count : count
            }
        });
    }


    get(salId : number): Promise<ISal>
	{
		return this.ajaxService.Get("JobOrder-api", "Sal/GetSalById/" + salId, {});
	}

	save(sal : IFullSal) : Promise<IFullSal> {
		if(sal.Sal.SalId) {
			return this.ajaxService.Put<IFullSal>("JobOrder-api", "Sal", {
				methodData: sal
			}).then((savedSal : IFullSal) => { this.onSalChanged(savedSal.Sal); return savedSal; });
		}

		return this.ajaxService.Post<IFullSal>("JobOrder-api", "Sal", {
			methodData: sal
		})/*.then((savedSal : IFullSal) => {
            if (savedSal.TrustAuthorization.Saved)
                this.onSalAdded(savedSal.Sal);
        });*/
	}

	delete(salId : number) : Promise<void> {
		return this.ajaxService.Delete<void>("JobOrder-api", "Sal/" + salId, {})
            .then(() => this.onSalDeleted(salId));
	}

    onSettingsUpdated(updateType : string) : void
    {
        if(updateType != ProlifeSdk.SettingsUpdateType_DocumentsProtocols || !this.jobOrderService.IsServiceEnabled())
            return;

        let documentsService = <IDocumentsService> this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        let registersManager : IVatRegisters = <IVatRegisters>this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);

        //Rimuovo i registri di questo tipo precedentemente registrati
        documentsService.getRegisteredDocumentProviders().slice(0).forEach((p : IDocumentsProvider) => {
            if(p instanceof SalDocumentsProvider)
                documentsService.unregisterDocumentsProvider(p);
        });

        registersManager.unregisterCustomProtocolType(cpt => cpt instanceof SalProtocolType);

        //Aggiungo i nuovi registri
        this.RegisterProtocols();
    }

    private RegisterProtocols()
    {
        let documentsService = <IDocumentsService> this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        let registersManager : IVatRegisters = <IVatRegisters>this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        let vatRegisters = <IVatRegisters> this.settingsService.findSettingsManager(ProlifeSdk.VatRegisters);
        registersManager.registerCustomProtocolType(new SalProtocolType());

        vatRegisters.getVatRegisters().forEach((protocol : IVatRegister) => {
            if(protocol.Stato != ProlifeSdk.HiddenProtocolState && protocol.TipoDocumento == ProlifeSdk.SalTypeId)
                documentsService.registerDocumentsProvider(new SalDocumentsProvider(protocol));
        });

        this.hasRegisteredDocumentProviders = true;
    }

    getSalDetailUrl(sal : ISal) : string
    {
        return "#/" + ProlifeSdk.TextResources.JobOrder.OpenSALURL + sal.SalId;
    }

    getNewSalUrl() : string
    {
        return "#/" + ProlifeSdk.TextResources.JobOrder.NewSALURL;
    }

    getNewSalForJobOrderUrl(jobOrderId : number) : string
    {
        return "#/" + ProlifeSdk.TextResources.JobOrder.NewJobOrderURL + jobOrderId;
    }

	setReferenceInvoiceOn(invoiceId : number, salIds : number[], salRowIds: number[]) : Promise<void> {
        return this.ajaxService.Post("JobOrder-api/Sal", "ReferenceInvoice", {
            methodData: { invoiceId : invoiceId, salIds : salIds, salRowIds: salRowIds }
        });
    }

    addObserver(observer : ISalServiceObserver) : void {
        this.observers.push(observer);
    }

    removeObserver(observer : ISalServiceObserver) : void {
        let index = this.observers.indexOf(observer);
        if(index < 0) return;
        this.observers.splice(index, 1);
    }

    onSalAdded(sal : ISal) : void {
        if(sal)
            sal.Number = sal.Number ? sal.Number : "";
        this.observers.forEach((observer : ISalServiceObserver) => observer.onSalAdded(sal));
    }

    onSalChanged(sal : ISal) : void {
        this.observers.forEach((observer : ISalServiceObserver) => observer.onSalChanged(sal));
    }

    onSalDeleted(salId : number) : void {
        this.observers.forEach((observer : ISalServiceObserver) => observer.onSalDeleted(salId));
    }

    GetSalsForList(request: IGetSalsForListRequest): Promise<ISalForList[]> {
        return this.ajaxService.Post<ISalForList[]>("JobOrder-api/Sal", "GetSalsForList", {
            background: true,
            methodData: request
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new SalService(serviceLocator);
}
