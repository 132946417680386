import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../../../Users/HumanResourcesService";
import { IDocumentsProvider } from "../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWorkSheetRow } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkSheet";
import { IBlogDocumentRef, IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IWorkTimeCategoriesSettingsManager } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IWorkTimeCategory } from "../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { ILogEvent } from "../../../ProlifeSdk/interfaces/ILogEvent";
import { ITag } from "../../../ProlifeSdk/interfaces/ITag";
import { Deferred } from "../../../Core/Deferred";

export class HoursRecordEvent extends BlogEvent implements IView {
    public viewModel: any;

    protected humanResourcesService: IHumanResourcesSettingsManager;
    protected workTimeCategoriesService: IWorkTimeCategoriesSettingsManager;
    protected rolesService: IUserCharactersSettingsManager;
    protected documentsService: IDocumentsService;
    protected entitiesService: IEntityProviderService;
    public humanResourcesSearchService: IControlsEntityProvider;

    public userCharacters: ko.ObservableArray<IUserCharacter> = ko.observableArray([]);
    public workTimeCategories: ko.ObservableArray<IWorkTimeCategory> = ko.observableArray([]);

    public Hours: ko.Observable<number> = ko.observable(0);
    public WorkerId: ko.Observable<any> = ko.observable(0);
    public RoleId: ko.Observable<any> = ko.observable(null);
    public WorkTimeCategoryId: ko.Observable<any> = ko.observable(0);
    public WorkTimeCategoryName: ko.Observable<string> = ko.observable("");
    public FromTime: ko.Observable<any> = ko.observable(moment("09:00", "HH:mm").toDate());
    public ToTime: ko.Observable<any> = ko.observable(moment("17:00", "HH:mm").toDate());
    public Breaks: ko.Observable<any> = ko.observable(0);
    public WorkerDescription: ko.Observable<any> = ko.observable();
    public Billable: ko.Observable<boolean> = ko.observable(true);
    public Billed: ko.Observable<boolean> = ko.observable(false);
    public SalId: ko.Observable<any> = ko.observable(null);
    public TravelDistance: ko.Observable<number> = ko.observable(0);
    public CallRight: ko.Observable<boolean> = ko.observable(false);

    public AttachedTask: ko.Observable<any> = ko.observable();

    private initialState: HoursRecordEvent;
    private workedHours: IWorkSheetRow = null;

    public HasReferences: ko.Observable<boolean> = ko.observable(false);
    public ReferencesDocs: ko.ObservableArray<IBlogDocumentRef> = ko.observableArray([]);

    public resource: IHumanResource = null;
    private loading = false;

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);

        const entityProviderService: IEntityProviderService = <IEntityProviderService>(
            serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.humanResourcesSearchService = entityProviderService
            .getEntityProvider(ProlifeSdk.HumanResources)
            .getControlsProvider();
        const settingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.humanResourcesService = <IHumanResourcesSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.HumanResources)
        );
        this.documentsService = <IDocumentsService>serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        this.entitiesService = <IEntityProviderService>(
            this.serviceLocator.findService(ProlifeSdk.EntityProviderServiceType)
        );
        this.rolesService = <IUserCharactersSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType)
        );
        this.userCharacters(this.rolesService.getUserCharacters());

        this.workTimeCategoriesService = <IWorkTimeCategoriesSettingsManager>(
            settingsService.findSettingsManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
        );

        this.blogService = <IBlogService>serviceLocator.findService(ProlifeSdk.BlogServiceType);

        this.Billable.subscribe((v) => {
            if (!v) this.Billed(false);
        });

        this.ReferenceDate.subscribe((d) => {
            if (this.loading) return;

            this.loadRoles();
            this.loadWorkTimeCategories();
        });

        this.WorkerId.subscribe((v) => {
            this.WorkerDescription("");

            if (!v || v <= 0) return;

            this.resource = this.humanResourcesService.getHumanResourceById(v);
            this.WorkerDescription(this.humanResourcesService.getFullName(this.resource));

            if (this.loading) return;

            this.loadRoles().then(() => {
                this.loadWorkTimeCategories();
                const role: IUserCharacter = this.rolesService.getUserCharacterById(
                    this.resource.Resource.FkDefaultCharacter
                );
                const workTimeCategory: IWorkTimeCategory = role
                    ? this.workTimeCategoriesService.getById(role.DefaultWorkTimeCategory)
                    : null;
                this.RoleId(
                    role && role.ResourceType == this.resource.Resource.ResourceType
                        ? this.resource.Resource.FkDefaultCharacter
                        : null
                );
                this.WorkTimeCategoryId(
                    workTimeCategory && workTimeCategory.ResourceType == this.resource.Resource.ResourceType
                        ? workTimeCategory.Id
                        : null
                );
            });
        });

        this.Clear();
        this.RoleId(this.userCharacters().length > 0 ? this.userCharacters()[0].IdUserCharacter : null);
        this.WorkTimeCategoryId(
            this.userCharacters().length > 0 ? this.userCharacters()[0].DefaultWorkTimeCategory : null
        );

        this.WorkTimeCategoryId.subscribe((v) => {
            this.WorkTimeCategoryName("");

            if (!v || v <= 0) return;

            const d = this.workTimeCategoriesService.getById(v).Name;
            this.WorkTimeCategoryName(d);
        });
    }

    private loadWorkTimeCategories() {
        const allCategories: IWorkTimeCategory[] = this.workTimeCategoriesService
            .GetAllForHumans(true)
            .filter((c: IWorkTimeCategory) => {
                return this.isWorkTimeCategoryValidForWorkSheet(c.Id);
            });

        this.workTimeCategories(allCategories);
    }

    private isWorkTimeCategoryValidForWorkSheet(wtcId: number) {
        const validServiceOrderIds = this.resource.Orders.filter(
            (o) => o.FromDate <= this.ReferenceDate() && (!o.ToDate || o.ToDate > this.ReferenceDate())
        ).map((o) => o.Id);
        const validWorkTimeCategoryIds = this.resource.OrdersSalaries.filter(
            (s) => validServiceOrderIds.indexOf(s.FkServiceOrder) != -1
        )
            .map((s) => s.FkWorkTimeCategoryId)
            .distinct();
        return validWorkTimeCategoryIds.indexOf(wtcId) != -1;
    }

    private async loadRoles(): Promise<void> {
        if (!this.JobOrder()) {
            this.userCharacters([]);
            return;
        }

        const jobOrderRoles = await this.jobOrderService.getRolesConfiguredOnJobOrder(this.JobOrder());
        const jobOrderRolesIds = jobOrderRoles.map((r) => r.FkUserCharacterId);

        const validServiceOrderIds = this.resource.Orders.filter(
            (o) => o.FromDate <= this.ReferenceDate() && (!o.ToDate || o.ToDate > this.ReferenceDate())
        ).map((o) => o.Id);
        const validRoleIds = this.resource.OrdersRoles.filter(
            (s) => validServiceOrderIds.indexOf(s.FkServiceOrder) != -1
        ).map((s) => s.FkCharacter);
        const intersection = validRoleIds.filter((id) => jobOrderRolesIds.indexOf(id) != -1);

        const roles = this.rolesService
            .getUserCharactersWithDeleted()
            .filter((c) => intersection.indexOf(c.IdUserCharacter) > -1);

        this.userCharacters(roles);
    }

    public LoadReferencesList() {
        if (this.ReferencesDocs().length > 0) return;

        (<any>this.blogService).workedHoursProvider
            .GetReferencerDocsForHours(this.workedHours.HoursId)
            .then((refsInfo: any[]) => {
                const docsRefsList: IBlogDocumentRef[] = [];

                refsInfo.forEach((r: any) => {
                    const matches = this.documentsService
                        .getRegisteredDocumentProviders()
                        .filter((p: IDocumentsProvider) => {
                            return p.Id == r.ProtocolId;
                        });

                    if (matches.length == 0) return;

                    docsRefsList.push({
                        Description: String.format(
                            ProlifeSdk.TextResources.Blog.Description,
                            this.entitiesService.GetEntityDescriptor(r.DocumentType).EntityName,
                            r.DocumentNumber,
                            moment(r.DocumentDate).format("L")
                        ),
                        Url: this.documentsService.GetOpenDocumentURL(r.DocumentType, r.DocumentId), //"#/" + String.format(ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, matches[0].ProviderId, r.DocumentId)
                    });
                });

                this.ReferencesDocs(docsRefsList);
            });
    }

    Clear() {
        super.Clear();
        this.WorkerId(0);
        this.RoleId(0);
        this.Hours(0);
        this.FromTime(moment("09:00", "HH:mm").toDate());
        this.ToTime(moment("17:00", "HH:mm").toDate());
        this.Breaks(0);
        this.WorkerDescription("");
        this.Billable(true);
        this.Billed(false);
        this.SalId(null);
        this.WorkTimeCategoryId(0);
        this.TravelDistance(0);
        this.CallRight(false);
    }

    public SetCallRightObserver(callRightSelectedRecord?: ko.Observable<any>) {
        this.CallRight.subscribe((v: boolean) => {
            if (v) callRightSelectedRecord(this);
            else if (callRightSelectedRecord() == this) callRightSelectedRecord(null);
        });

        callRightSelectedRecord.subscribe(() => {
            if (callRightSelectedRecord() == this) return;
            this.CallRight(false);
        });
    }

    public IsChanged(): boolean {
        let isChanged = false;

        isChanged =
            isChanged ||
            !(<any>moment(moment(this.FromTime()).year(1900).toDate().toString())).isSame(
                moment(this.initialState.FromTime()).year(1900).toDate().toString()
            );
        isChanged =
            isChanged ||
            !(<any>moment(moment(this.ToTime()).year(1900).toDate().toString())).isSame(
                moment(this.initialState.ToTime()).year(1900).toDate().toString()
            );
        isChanged = isChanged || this.WorkerId() != this.initialState.WorkerId();
        isChanged = isChanged || this.RoleId() != this.initialState.RoleId();
        isChanged = isChanged || this.Breaks() != this.initialState.Breaks();
        isChanged = isChanged || this.Billable() != this.initialState.Billable();
        isChanged = isChanged || this.Billed() != this.initialState.Billed();
        isChanged = isChanged || this.Hours() != this.initialState.Hours();
        isChanged = isChanged || this.TravelDistance() != this.initialState.TravelDistance();
        isChanged = isChanged || this.CallRight() != this.initialState.CallRight();
        isChanged = isChanged || this.WorkTimeCategoryId() != this.initialState.WorkTimeCategoryId();
        isChanged = isChanged || this.AttachedTask() != this.initialState.AttachedTask();
        return isChanged;
    }

    public InitFromOther(otherEvent: HoursRecordEvent) {
        this.CloneStatusFromOther(otherEvent);
        this.Billed(false);
    }

    private CloneStatusFromOther(otherEvent: HoursRecordEvent) {
        //Prepara un nuovo record
        this.FromTime(otherEvent.FromTime());
        this.ToTime(otherEvent.ToTime());
        this.Breaks(otherEvent.Breaks());
        this.Hours(otherEvent.Hours());
        this.Billable(otherEvent.Billable());
        this.TravelDistance(otherEvent.TravelDistance());
        this.WorkTimeCategoryId(otherEvent.WorkTimeCategoryId());
        this.Billed(otherEvent.Billed());
    }

    public setCharacter(character: IUserCharacter): void {
        this.RoleId(character.IdUserCharacter);
        this.WorkTimeCategoryId(character.DefaultWorkTimeCategory);
    }

    public setWorkTimeCategory(category: IWorkTimeCategory): void {
        this.WorkTimeCategoryId(category.Id);
    }

    public setTask(task: any): void {
        this.AttachedTask(task);
    }

    public ToBlogEvent(): ILogEvent {
        const Tags: ITag[] = [];

        Tags.push({
            TagName: ProlifeSdk.Tag_JobOrder,
            Value: this.JobOrder(),
            TagTypeId: ProlifeSdk.JobOrderEntityType,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_Worker,
            Value: this.WorkerId(),
            TagTypeId: ProlifeSdk.HumanResources,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_Billable,
            Value: this.Billable() ? ProlifeSdk.TagWork_Invoiced_true : ProlifeSdk.TagWork_Invoiced_false,
            TagTypeId: ProlifeSdk.TagType_Bool,
        });

        Tags.push({
            TagName: ProlifeSdk.Tag_Billed,
            Value: this.Billed(),
            TagTypeId: ProlifeSdk.TagType_Bool,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_Sal,
            Value: this.SalId(),
            TagTypeId: ProlifeSdk.SalEntityType,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_WorkerRole,
            Value: this.RoleId(),
            TagTypeId: ProlifeSdk.RolesEntityType,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWork_WorkTimeCategory,
            Value: this.WorkTimeCategoryId(),
            TagTypeId: ProlifeSdk.WorkTimeCategoryEntityType,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_FromTime,
            Value: moment(this.FromTime()).year(1900).toDate(),
            TagTypeId: ProlifeSdk.TagType_DateTime,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_ToTime,
            Value: moment(this.ToTime()).year(1900).toDate(),
            TagTypeId: ProlifeSdk.TagType_DateTime,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_TravelDistance,
            Value: this.TravelDistance(),
            TagTypeId: ProlifeSdk.TagType_Decimal,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_CallRight,
            Value: this.CallRight(),
            TagTypeId: ProlifeSdk.TagType_Bool,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_Hours,
            Value: this.Hours(),
            TagTypeId: ProlifeSdk.TagType_Decimal,
        });

        Tags.push({
            TagName: ProlifeSdk.TagWorkHours_Breaks,
            Value: this.Breaks(),
            TagTypeId: ProlifeSdk.TagType_Decimal,
        });

        if (this.AttachedTask()) {
            Tags.push({
                TagName: ProlifeSdk.Tag_TodoListTask,
                TagTypeId: ProlifeSdk.EventsTodoListTask,
                Value: this.AttachedTask().Id,
                DisplayName: this.AttachedTask().Description,
            });
        }

        const blogEvent = <ILogEvent>{
            EventId: this.IdEvent() != 0 ? this.IdEvent() : undefined,
            EventType: "Blog.WorkedHours",
            Text: this.Message(),
            ModuleName: "Blog",
            TimeStamp: this.EventTime(),
            ReferenceDate: this.ReferenceDate(),
            UserId: this.EventUser(),
            Tags: Tags,
            FkParentId: this.FkParentId(),
            EventData: {},
            JobOrder: this.JobOrder(),
            Tasks: this.AttachedTask() ? [this.AttachedTask().Id] : [],
        };

        return blogEvent;
    }

    public switchBillable() {
        this.Billable(!this.Billable());
    }

    public switchBilled() {
        this.Billed(!this.Billed());
    }

    public LoadDetailsAsync(): Promise<void> {
        const def = new Deferred<void>();

        if (this.tagsDetailsAreLoaded) return def.resolve().promise();

        this.loading = true;

        super.LoadDetails();

        this.Tags().forEach((item) => {
            if (item.TagName() == ProlifeSdk.TagWorkHours_Worker) this.WorkerId(item.Value());

            this.Hours(item.TagName() == ProlifeSdk.TagWorkHours_Hours ? parseFloat(item.Value()) : this.Hours());
            this.FromTime(
                item.TagName() == ProlifeSdk.TagWorkHours_FromTime ? moment(item.Value()).toDate() : this.FromTime()
            );
            this.ToTime(
                item.TagName() == ProlifeSdk.TagWorkHours_ToTime ? moment(item.Value()).toDate() : this.ToTime()
            );
            this.Breaks(item.TagName() == ProlifeSdk.TagWorkHours_Breaks ? item.Value() : this.Breaks());
            this.TravelDistance(
                item.TagName() == ProlifeSdk.TagWorkHours_TravelDistance
                    ? parseFloat(item.Value())
                    : this.TravelDistance()
            );
            this.CallRight(
                item.TagName() == ProlifeSdk.TagWorkHours_CallRight
                    ? item.Value().toLowerCase() == ProlifeSdk.TagWork_Invoiced_true.toLowerCase()
                    : this.CallRight()
            );
            this.SalId(
                item.TagName() == ProlifeSdk.SalEntityType && item.Value() != null && item.Value().length > 0
                    ? item.Value()
                    : this.SalId()
            );
            this.Billable(
                item.TagName() == ProlifeSdk.TagWorkHours_Billable
                    ? item.Value().toLowerCase() == ProlifeSdk.TagWork_Invoiced_true.toLowerCase()
                    : this.Billable()
            );
            this.Billed(
                item.TagName() != ProlifeSdk.Tag_Billed
                    ? this.Billed()
                    : item.Value().toLowerCase() == ProlifeSdk.TagWork_Invoiced_true.toLowerCase()
            );
        });

        this.initialState = new HoursRecordEvent(this.serviceLocator, this.contextEventsObserver);
        this.initialState.CloneStatusFromOther(this);
        this.initialState.WorkerId(this.WorkerId());

        this.loadWorkTimeCategories();
        this.loadRoles()
            .then(() => {
                this.Tags().forEach((item) => {
                    this.RoleId(item.TagName() == ProlifeSdk.TagWorkHours_WorkerRole ? item.Value() : this.RoleId());
                    this.WorkTimeCategoryId(
                        item.TagName() == ProlifeSdk.TagWork_WorkTimeCategory ? item.Value() : this.WorkTimeCategoryId()
                    );
                });

                this.initialState.RoleId(this.RoleId());
                this.initialState.WorkTimeCategoryId(this.WorkTimeCategoryId());
                def.resolve();
            })
            .catch(() => {
                def.resolve();
            });

        this.loading = false;
        return def.promise();
    }

    public LoadDetails() {
        this.LoadDetailsAsync();
    }

    public LoadLocalTags() {
        super.loadTags();
    }

    public setWorkedHoursRecord(record: IWorkSheetRow) {
        this.workedHours = record;
        this.HasReferences(record && record.ReferencingRows.length > 0);
    }

    public getWorkedHoursRecord(): IWorkSheetRow {
        const record: IWorkSheetRow =
            this.workedHours != null
                ? this.workedHours
                : <IWorkSheetRow>{
                      EventId: 0,
                      WorkEventId: 0, //TODO: Trovare un modo sensato per mappare gli eventi ritornati con le righe del viewmodel... così non va bene
                      JobOrderId: 0,
                      WorkDate: <any>JSON.stringify(moment(new Date())).replace(/"/g, ""),
                      Description: null,
                      ResourceId: null,
                      ResourceName: null,
                      RoleId: null,
                      WorkTimeCategoryId: null,
                      From: moment(new Date()).format("LTS"),
                      To: moment(new Date()).format("LTS"),
                      BreakHours: 0,
                      Hours: 0,
                      Billable: false,
                      Billed: false,
                      SalId: null,
                      TravelDistance: 0,
                      CallRight: false,
                      WorkPlaceId: 0,
                      BusinessManagement: false,
                      IsPreviousMonthImbalance: false,
                      IsApproved: null,
                      ApprovedBy: null,
                      ApprovalNote: null,
                      ApprovalDate: null,
                  };

        record.WorkEventId = this.IdEvent();
        record.JobOrderId = this.JobOrder();
        record.WorkDate = <any>JSON.stringify(moment(this.ReferenceDate())).replace(/"/g, "");
        record.Description = this.Message();
        record.ResourceId = this.WorkerId();
        record.ResourceName = this.WorkerDescription();
        record.RoleId = this.RoleId();
        record.WorkTimeCategoryId = this.WorkTimeCategoryId();
        record.From = moment(this.FromTime()).format("LTS");
        record.To = moment(this.ToTime()).format("LTS");
        record.BreakHours = this.Breaks();
        record.Hours = this.Hours();
        record.Billable = this.Billable();
        record.Billed = this.Billed();
        record.SalId = this.SalId();
        record.TravelDistance = this.TravelDistance();
        record.CallRight = this.CallRight();
        record.TaskId = this.AttachedTask() ? this.AttachedTask().Id : null;
        return record;
    }
}
