import { ServiceLocator } from "./services/ServiceLocator";
import { IConfig } from "./interfaces/IConfig";
import { IServiceLocator } from "./interfaces/IServiceLocator";
import { IService } from "./interfaces/IService";

export var serviceLocator : IServiceLocator = new ServiceLocator();
export var configuration : IConfig = null;

export async function Initialize(config : IConfig) : Promise<void> {
    configuration = config;
    
    const allServices : IService[] = [];
    for(const service of configuration.services) {
        const serviceOrServicesArray = service(serviceLocator);
        if(Array.isArray(serviceOrServicesArray))
            allServices.push(...serviceOrServicesArray);
        else
            allServices.push(serviceOrServicesArray);
    }

    for(const service of allServices) {
        if(service.InitializeService) {
            const result = service.InitializeService();
            if(result)
                await result;
        }
    }
}