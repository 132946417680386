import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 16/06/2017
 * Time: 18:49
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { SampleDetails } from "./samples/SampleDetails";
import { CampaignDetails } from "./campaigns/CampaignDetails";
import { SampleEditor } from "./samples/SampleEditor";
import { SplashPage } from "./splash-page/SplashPage";
import { SampleEditDialog } from "./samples/dialogs/SampleEditDialog";
import { EditCampaignDialog } from "./campaigns/dialogs/EditCampaignDialog";
import { QuestionnaireDetailsViewModel } from "./questionnaires/questionnaire-details/QuestionnaireDetailsViewModel";
import { QuestionnaireEditViewModel } from "./questionnaires/questionnaire-edit/QuestionnaireEditViewModel";
import { QuestionnaireEditDialog } from "./questionnaires/questionnaire-edit/dialogs/QuestionnaireEditDialog";
import { Questionnaire } from "./questionnaires/Questionnaire";
import { Campaign } from "./campaigns/Campaign";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ISurveyDetailsObserver } from "../../../ProlifeSdk/interfaces/survey/ISurveyDetailsObserver";
import { IApplication } from "../../../Desktop/interfaces/IApplication";

export class SurveyPanelViewModel implements ISurveyDetailsObserver {
    public ShowedElement: any;

    public CurrentViewModel : ko.Observable<any> = ko.observable(null);
    public SplashPage : SplashPage;

    public Reset: boolean = false;

    private dialogService : IDialogsService;

    constructor(private serviceLocator: IServiceLocator, SurveyApplication: IApplication) {
        this.SplashPage = new SplashPage(serviceLocator, SurveyApplication, this);
        this.dialogService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
    }

    public initialize() : Promise<void>
    {
        return this.SplashPage.Initialize();
    }

    public dispose()
    {
        this.SplashPage.dispose();

        if(this.ShowedElement)
            this.ShowedElement.dispose();
    }

    public OpenSplashPage()
    {
        if(this.ShowedElement)
            this.ShowedElement.dispose();

        this.CurrentViewModel(this.SplashPage);
        this.SplashPage.Refresh();
    }

    public OpenQuestionnaire(questionnaireId: number) {
        if (this.ShowedElement)
            this.ShowedElement.dispose();
        this.ShowedElement = new QuestionnaireDetailsViewModel(this.serviceLocator, questionnaireId, this);
        this.SplashPage.setNavBarActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.NewQuestionnaire),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.NewQuestionnaire.bind(this)
        },{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.NewQuestionnaireVersion),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.NewQuestionnaireVersion.bind(this)
        }]);
        this.CurrentViewModel(this.ShowedElement);
    }

    public NewQuestionnaire() {
        var editQuestionnaireDialog = new QuestionnaireEditDialog(this.serviceLocator, null);
        this.dialogService.ShowModal<Questionnaire>(editQuestionnaireDialog)
            .then((questionnaire: Questionnaire) => {
                this.EditQuestionnaire(questionnaire.Id);
            });
    }

    public NewQuestionnaireVersion() {
        this.dialogService.Confirm(
            ProlifeSdk.TextResources.Survey.NewQuestionnaireVersionConfirmDialogText,
            ProlifeSdk.TextResources.Survey.NewQuestionnaireVersionConfirmDialogCancel,
            ProlifeSdk.TextResources.Survey.NewQuestionnaireVersionConfirmDialogConfirm,
            (confirm) => {
                if (confirm) {
                    this.CurrentViewModel().CreateNewVersion();
                }
            }
        );
    }

    public EditQuestionnaire(questionnaireId: number) {
        if (this.ShowedElement && !this.ShowedElement.IsQuestionnaireEditor)
            this.ShowedElement.dispose();

        if (!this.ShowedElement || (this.ShowedElement && !this.ShowedElement.IsQuestionnaireEditor))
            this.ShowedElement = new QuestionnaireEditViewModel(this.serviceLocator, questionnaireId, this);

        this.CurrentViewModel(this.ShowedElement);
    }

    public OpenSample(sampleId : number) {
        if (this.ShowedElement)
            this.ShowedElement.dispose();
        this.ShowedElement = new SampleDetails(this.serviceLocator, sampleId, this);
        this.SplashPage.setNavBarActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.NewSample),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.NewSample.bind(this)
        }]);
        this.CurrentViewModel(this.ShowedElement);
    }

    public NewSample() {
        var newSampleDialog = new SampleEditDialog(this.serviceLocator, null);
        this.dialogService.ShowModal<number>(newSampleDialog)
            .then((ids: number) => {
                if (!ids)
                    return;
                this.EditSample(ids);
            });
    }

    public EditSample(sampleId : number) {
        if (this.ShowedElement && !this.ShowedElement.IsSampleEditor)
            this.ShowedElement.dispose();

        if (!this.ShowedElement || (this.ShowedElement && !this.ShowedElement.IsSampleEditor))
            this.ShowedElement = new SampleEditor(this.serviceLocator, sampleId, this);

        this.CurrentViewModel(this.ShowedElement);
    }

    public OpenCampaign(campaignId) {
        if (this.ShowedElement)
            this.ShowedElement.dispose();
        this.ShowedElement = new CampaignDetails(this.serviceLocator, campaignId, this);
        this.SplashPage.setNavBarActions([{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.NewCampaign),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.NewCampaign.bind(this)
        }]);
        this.CurrentViewModel(this.ShowedElement);
    }

    public NewCampaign() {
        var editCampaignDialog = new EditCampaignDialog(this.serviceLocator, null, []);
        this.dialogService.ShowModal<Campaign>(editCampaignDialog, null, null, "survey/templates/campaign/dialogs", "add-or-update-campaign")
            .then((campaign: Campaign) => {
                if (!campaign)
                    return;
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.CampaignUrl + "/" + campaign.Id;
            });
    }

    public notifyChanges() {

    }
}