import * as ko from "knockout";
import { LazyImport, Service } from "../Core/DependencyInjection";
import { CustomersGroupsSettingsManager } from "./customers/settings/CustomersGroupsSettingsManager";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";

export interface ICustomerGroup {
    Id : number;
    Name?: string;
    IsDefault : boolean;
}

export interface ICustomersGroupsService extends IService {
    CreateOrUpdateGroup(id : number | null, name : string | null) : Promise<ICustomerGroup>;
    DeleteGroup(id : number | null) : Promise<void>;
    AssignGroupToCustomer(groupId : number | null, customerId : number | null) : Promise<void>;
    GetCustomerGroups(customerId : number | null) : Promise<ICustomerGroup[]>;
}

@Service(nameof<ICustomersGroupsService>())
class CustomersGroupsService implements ICustomersGroupsService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    InitializeService() {
        new CustomersGroupsSettingsManager();
    }

    CreateOrUpdateGroup(id : number | null, name : string | null) : Promise<ICustomerGroup> {
        return this.ajaxService.Post<ICustomerGroup>("Customers-api/CustomerGroup", "CreateOrUpdateGroup", {
            methodData: {
                id: id,
                name: name,
            }
        });
    }

    DeleteGroup(id : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Customers-api/CustomerGroup", "DeleteGroup", {
            methodData: {
                id: id,
            }
        });
    }

    AssignGroupToCustomer(groupId : number | null, customerId : number | null) : Promise<void> {
        return this.ajaxService.Post<void>("Customers-api/CustomerGroup", "AssignGroupToCustomer", {
            methodData: {
                groupId: groupId,
                customerId: customerId,
            }
        });
    }

    GetCustomerGroups(customerId: number | null): Promise<ICustomerGroup[]> {
        return this.ajaxService.Post<ICustomerGroup[]>("Customers-api/CustomerGroup", "GetCustomerGroups", {
            methodData: {
        		customerId: customerId,
        	}
        });
    }
}

export default function Create() {
    return new CustomersGroupsService();
}
