import * as ko from "knockout";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDataSourcesService } from "../../../Desktop/DataSourcesService";
import { DataSourceConfiguration } from "../Editors/DataSourceEditor/DataSourceEditingDialog";
import { MergedReportComponentConstructor, ReportComponentConstructor } from "./ReportComponent";

export type ReportComponentDataSourceProps = {

} & DataSourceConfiguration;

export class ReportComponentDataSource {
    @LazyImport(nameof<IDataSourcesService>())
    private dataSourcesService : IDataSourcesService;
    
    constructor(private props: ReportComponentDataSourceProps) {

    }

    getData() {
        return {
            ...this.props
        }
    }

    getId(): number {
        return this.props.id;
    }

    getName() {
        return this.props.name;
    }

    getDataSourceId() {
        return this.props.dataSourceId;
    }

    getColumns() {
        const dataSource = this.dataSourcesService.getDataSources([]).firstOrDefault(d => d.Name === this.props.dataSourceId);
        if(!dataSource) return [];

        return dataSource.Columns;
    }

    getValue() {
        this.dataSourcesService.getRow(this.props, { Name: 'EstimateId', Value: 3302 });
    }
}

export interface ReportComponentWithDataSource {
    dataSources: ko.ObservableArray<ReportComponentDataSource>;
}

export type ReportComponentWithDataSourceProps = {
    dataSources?: ReportComponentDataSourceProps[];
}

export function HasDataSource<TBase extends ReportComponentConstructor>(Base: TBase) : MergedReportComponentConstructor<TBase, ReportComponentWithDataSource, ReportComponentWithDataSourceProps> {
    return class ReportComponentWithFont extends Base {
        constructor(...args: any[]) {
            super(...args);
            this.features = [...this.features, "DataSource"];

            const { dataSources } = args[0] as ReportComponentWithDataSourceProps
            this.dataSources((dataSources ?? []).map(ds => new ReportComponentDataSource(ds)));
        }

        getData() {
            const data = super.getData();
            return {
                ...data,
                dataSources: this.dataSources().map(ds => ds.getData())
            }
        }

        dataSources: ko.ObservableArray<ReportComponentDataSource> = ko.observableArray();
    }
}