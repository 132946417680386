import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 18/09/2017
 * Time: 17:23
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ICallResult, ILogicalCallResult } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ICallData";
import { ICallResultSettingManager } from "../../../../interfaces/ICallResultSettingManager";

export enum CallResultProviderTypes {
    RescheduleCall = 0,
    CompletedCall = 1
}

export interface ICallResultProvider {
    type: ko.Observable<CallResultProviderTypes>;

    getData() : ICallResult;
    update(callResult : ICallResult) : void;
    withError: ko.Observable<boolean>;
}

export class CallResultEditingViewModel {
    public title : string;
    public elements : ko.ObservableArray<ICallResultProvider> = ko.observableArray([]);
    public newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);

    public MotivationsForRescheduleCall: ko.Computed<ICallResultProvider[]>;
    public LogicalStatesForRescheduleCall: ko.ObservableArray<ILogicalCallResult> = ko.observableArray([]);
    public NewStateForRescheduleCall: ko.Observable<string> = ko.observable();

    public MotivationsForTerminateCall: ko.Computed<ICallResultProvider[]>;
    public LogicalStatesForTerminateCall: ko.ObservableArray<ILogicalCallResult> = ko.observableArray([]);
    public NewStateForTerminateCall: ko.Observable<string> = ko.observable();

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator : IServiceLocator, private callResultManager: ICallResultSettingManager) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.title = callResultManager.getLabel();

        this.LogicalStatesForRescheduleCall(this.callResultManager.getLogicalCallResults().filter((r) => r.TypeId === ProlifeSdk.RecallMotivationType));
        this.LogicalStatesForTerminateCall(this.callResultManager.getLogicalCallResults().filter((r) => r.TypeId === ProlifeSdk.ClosingMotivationType || r.TypeId === ProlifeSdk.SystemReservedClosingMotivationType));

        this.MotivationsForRescheduleCall = ko.computed(() => {
            return this.elements().filter((e) => e.type() === CallResultProviderTypes.RescheduleCall);
        });

        this.MotivationsForTerminateCall = ko.computed(() => {
            return this.elements().filter((e) => e.type() === CallResultProviderTypes.CompletedCall);
        });

        this.reloadElements();
    }

    private reloadElements() {
        this.elements([]);
        this.callResultManager.load();
        var callResults = this.callResultManager.getAllCallResults();
        callResults.forEach(this.createViewModelFor.bind(this));
    }

    private createViewModelFor(callResult: ICallResult) {
        let type = callResult.TypeId > ProlifeSdk.RecallMotivationType ? CallResultProviderTypes.CompletedCall : CallResultProviderTypes.RescheduleCall;
        let logicalCallResults = type === CallResultProviderTypes.RescheduleCall ?
            this.callResultManager.getLogicalCallResults().filter((r) =>  r.TypeId === ProlifeSdk.RecallMotivationType) :
            this.callResultManager.getLogicalCallResults().filter((r) =>  r.TypeId === ProlifeSdk.ClosingMotivationType || r.TypeId === ProlifeSdk.SystemReservedClosingMotivationType);
        this.elements.push(new CallResultViewModel(this, callResult, logicalCallResults, type));
    }

    public addNewSettingForRescheduleCall() {
        if (!this.NewStateForRescheduleCall())
            return;

        let logicalCallResults = this.callResultManager.getAllCallResults().filter((s) => s.TypeId === ProlifeSdk.RecallMotivationType);
        let logicalCallResult = logicalCallResults.firstOrDefault();

        let model = {
            Id: null,
            Label: this.NewStateForRescheduleCall(),
            TypeId: logicalCallResult?.TypeId,
            CallResultId: logicalCallResult?.Id
        };

        var callResultViewModel = new CallResultViewModel(this, model, this.callResultManager.getLogicalCallResults().filter(r => r.TypeId === ProlifeSdk.RecallMotivationType), CallResultProviderTypes.RescheduleCall);

        this.elements.push(callResultViewModel);
        this.createOrUpdateEntry(callResultViewModel);

        this.NewStateForRescheduleCall("");
    }

    public addNewSettingForTerminatedCall() {
        if (!this.NewStateForTerminateCall())
            return;

        let logicalCallResults = this.callResultManager.getAllCallResults().filter((s) => s.TypeId === ProlifeSdk.ClosingMotivationType || s.TypeId === ProlifeSdk.SystemReservedClosingMotivationType);
        let logicalCallResult = logicalCallResults.firstOrDefault();

        let model = {
            Id: null,
            Label: this.NewStateForTerminateCall(),
            TypeId: logicalCallResult?.TypeId,
            CallResultId: logicalCallResult?.Id
        };

        var callResultViewModel = new CallResultViewModel(this, model, this.callResultManager.getLogicalCallResults().filter(r => r.TypeId === ProlifeSdk.ClosingMotivationType || r.TypeId === ProlifeSdk.SystemReservedClosingMotivationType), CallResultProviderTypes.CompletedCall);

        this.elements.push(callResultViewModel);
        this.createOrUpdateEntry(callResultViewModel);

        this.NewStateForTerminateCall("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element: ICallResultProvider) {
        var callResult = element.getData();
        this.callResultManager.addOrUpdate(callResult)
            .then((updatedCallResult) => element.update(updatedCallResult));
    }

    public deleteEntry(element: ICallResultProvider) {
        var callResult = element.getData();
        this.callResultManager.remove(callResult.Id)
            .then((result: number) => {
                if (result == 1) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.SurveyWizard.CanNotDeleteUsedData);
                    element.withError(true);
                    return;
                }
                this.elements.remove(element);
            });
    }
}

class CallResultViewModel implements ICallResultProvider {
    public Id: ko.Observable<number> = ko.observable();
    public TypeId: ko.Observable<number> = ko.observable();
    public Label : ko.Observable<string> = ko.observable();
    public LogicalState : ko.Observable<number> = ko.observable();
    public SelectedLogicalState : ko.Observable<string> = ko.observable();
    public hasFocus : ko.Observable<boolean> = ko.observable(false);

    public type: ko.Observable<CallResultProviderTypes> = ko.observable();

    public withError: ko.Observable<boolean> = ko.observable(false);
    private updating : boolean = false;

    constructor(private container : CallResultEditingViewModel, private callResult : ICallResult, public callLogicalStates : ILogicalCallResult[], type: CallResultProviderTypes) {
        this.Id(callResult.Id);
        this.LogicalState(callResult.CallResultId);
        this.Label(callResult.Label);
        this.TypeId(callResult.TypeId);

        this.type(type);

        this.setInitialState();

        this.Label.subscribe(this.onNameChanged.bind(this));
        this.LogicalState.subscribe(this.onDataChanged.bind(this));
    }
    
    private setInitialState(): void {
        this.SelectedLogicalState(this.callLogicalStates.filter((s: ILogicalCallResult) => {
            return s.Id == this.LogicalState();
        })[0].Description);
    }

    public SetLogicalState(state: ILogicalCallResult): void {
        this.LogicalState(state.Id);
        this.TypeId(state.TypeId);
        this.SelectedLogicalState(state.Description);
    }

    private onNameChanged(newValue : string) {
        if(this.updating)
            return;
        this.withError(false);
        this.container.createOrUpdateEntry(this);
    }

    getData() : ICallResult {
        var callResult: ICallResult = <ICallResult> $.extend({}, this.callResult);
        callResult.Id = this.Id();
        callResult.Label = this.Label();
        callResult.TypeId = this.TypeId();
        callResult.CallResultId = this.LogicalState();
        return callResult;
    }

    private onDataChanged(newValue : string) {
        if(this.updating)
            return;
        this.container.createOrUpdateEntry(this);
    }

    update(callResult : ICallResult) : void {
        this.updating = true;

        this.callResult = callResult;
        this.Label(callResult.Label);
        this.LogicalState(callResult.CallResultId);
        this.TypeId(callResult.TypeId);
        this.Id(callResult.Id);

        this.updating = false;
    }
}