import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam, ParamArray, ComponentParamArray } from "../Core/utils/ComponentUtils";
import { ICurrenciesSettingsManager } from "../Invoices/invoices/settings/CurrenciesSettingsManager";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceListener } from "../DataSources/IDataSource";

let attributes = {
    Currency: "currency",
    ExchangeValue: "exchange-value",
    Listeners: "listeners"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "currency-input-popover": {
               params?: {
                   Currency: string,
                   ExchangeValue: string,
                   Listeners: string
               };
               currency: number | (() => string);
               "exchange-value": number | (() => string);
               listeners: IDataSourceListener[] | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ICurrencyInputPopoverComponentParams {
    Currency: Param<number>;
    ExchangeValue: Param<number>;
    Listeners: ParamArray<IDataSourceListener>;
}

export class CurrencyInputPopoverComponent {
    public Currency: ComponentParam<number>;
    public ExchangeValue: ComponentParam<number>;
    public Listeners: ComponentParamArray<IDataSourceListener>;

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(params : ICurrencyInputPopoverComponentParams) {
        let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();

        this.Currency = ComponentUtils.parseParameter(params.Currency, defaultCurrency?.Id);
        this.ExchangeValue = ComponentUtils.parseParameter(params.ExchangeValue, 1);
        this.Listeners = ComponentUtils.parseParameterArray(params.Listeners, []);
    }
}

ko.components.register("currency-input-popover", {
    viewModel: {
        createViewModel: (params: ICurrencyInputPopoverComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new CurrencyInputPopoverComponent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <currency-input currency={() => "Currency"} exchange-value={() => "ExchangeValue"} listeners={() => "Listeners"}></currency-input>
            ]);
            
            return vm;
        },
    },
    template: []
});