import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { PaymentAndExpireAccountingSoftwareMappingsDialog } from "./PaymentAndExpireAccountingSoftwareMappingsDialog";
import { ExpiriesExtensionsDialog } from "./ExpiriesExtensionsDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IExpireMode, IScheduleCalculationSetting, IExpireModes } from "../../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IValidationService, IValidator, IValidation } from "../../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Deferred } from "../../../../Core/Deferred";

export interface IExpireModeProvider {
    getData() : IExpireMode;
    update(expireMode : IExpireMode) : void;
    isChanged(): boolean;
}

export interface IScheduleCalculationSettingViewModel {
    ScheduleTitle: ko.Observable<string>;
    DaysNumberSinceDocumentIssue: ko.Observable<number>;
    EndOfMonthPostponement: ko.Observable<boolean>;
    DayOfMonthPostponement: ko.Observable<boolean>;
    DayOfMonth: ko.Observable<number>;
    DocumentPricePartPercentage: ko.Observable<number>;
    DocumentPriceThreshold: ko.Observable<number>;
    DocumentPricePartValue: ko.Observable<number>;
    IsChanged: ko.Computed<boolean>;
    IsNew: ko.Computed<boolean>;
    IsLast: ko.Computed<boolean>;

    getData(): IScheduleCalculationSetting;
    update(scheduleSetting: IScheduleCalculationSetting): void;
    getValidation(): IValidation[];

    Delete(): Promise<void>;
}

export class ExpireModesEditingViewModel {
    private dialogsService : IDialogsService;

    title : string;
    elements : ko.ObservableArray<IExpireModeProvider> = ko.observableArray();
    newSettingHasFocus : ko.Observable<boolean> = ko.observable(false);
    newSettingName : ko.Observable<string> = ko.observable();
    ShowDeleted : ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator : IServiceLocator, private expireModesManager : IExpireModes) {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.title = expireModesManager.getLabel();
        this.RefreshList();
        this.ShowDeleted.subscribe(this.RefreshList.bind(this));
    }

    private RefreshList()
    {
        this.elements([]);
        var expires = this.expireModesManager.getExpireModes(this.ShowDeleted());
        expires.forEach(this.createViewModelFor.bind(this));
    }

    public ShowAccountingSoftwareMappings()
    {
        var dialog : IDialog = new PaymentAndExpireAccountingSoftwareMappingsDialog(this.serviceLocator);
        this.dialogsService.ShowModal<void>(dialog, "fullscreen", {}, dialog.templateUrl, dialog.templateName);
    }

    public ShowExpiriesExtensionsDialog(): void {
        var dialog = new ExpiriesExtensionsDialog(this.serviceLocator, this.expireModesManager);
        dialog.Show();
    }

    private createViewModelFor(expireMode : IExpireMode) {
        this.elements.push(new ExpireModeViewModel(this.serviceLocator, this, expireMode));
    }

    public addNewSetting() {
        var expireModeViewModel = new ExpireModeViewModel(this.serviceLocator, this, {
            Descrizione: this.newSettingName(),
            Eliminato: 0
        });
        this.elements.push(expireModeViewModel);
        this.createOrUpdateEntry(expireModeViewModel);

        this.newSettingName("");
    }

    public setIsSelectedNewSetting() {
        this.newSettingHasFocus(true);
    }

    public createOrUpdateEntry(element : IExpireModeProvider) {
        var appearance = element.getData();
        this.expireModesManager.createOrUpdate(appearance)
            .then((updatedAppearance) => element.update(updatedAppearance));
    }

    public deleteEntry(element : IExpireModeProvider) {

        var appearance = element.getData();
        this.expireModesManager.remove(appearance.IdTipoScadenza);

        if(this.ShowDeleted())
            return;

        this.elements.remove(element);
    }

    public isChanged(): boolean {
        var hasChanges = false;
        this.elements().forEach((e: IExpireModeProvider) => { if (e.isChanged()) hasChanges = true; });
        return hasChanges;
    }

    public getScheduleCalculationSettings(expireModeId: number): IScheduleCalculationSetting[] {
        return this.expireModesManager.getScheduleCalculationSettings(expireModeId);
    }

    public createOrUpdateScheduleCalculationSetting(scheduleSettings: IScheduleCalculationSettingViewModel[], paymentFeePriceCalculation: number, scheduleCalculationMode: number): Promise<IScheduleCalculationSetting[]> {
        var settings: IScheduleCalculationSetting[] = scheduleSettings.map((s: IScheduleCalculationSettingViewModel) => s.getData());
        return this.expireModesManager.createOrUpdateScheduleCalculationSettings(settings, paymentFeePriceCalculation, scheduleCalculationMode);
    }

    public deleteScheduleCalculationSetting(scheduleSetting: IScheduleCalculationSettingViewModel): Promise<void> {
        return this.expireModesManager.removeScheduleCalculationSetting(scheduleSetting.getData().Id);
    }
}

class ExpireModeViewModel implements IExpireModeProvider {
    name : ko.Observable<string> = ko.observable();
    hasFocus : ko.Observable<boolean> = ko.observable(false);
    withError : ko.Observable<boolean> = ko.observable(false);
    IsDeleted : ko.Observable<boolean> = ko.observable(false);
    Expanded: ko.Observable<boolean> = ko.observable(false);

    ScheduleCalculationSettings: ko.ObservableArray<IScheduleCalculationSettingViewModel> = ko.observableArray([]);
    PaymentFeePriceCalculation: ko.Observable<string> = ko.observable();
    ScheduleCalculationMode: ko.Observable<string> = ko.observable();
    HasScheduleCalculationSettings: ko.Computed<boolean>;
    ScheduleDocumentPricePartTotal: ko.Computed<number>;
    ScheduleCalculationSettingsHasChanges: ko.Computed<boolean>;
    IncompleteScheduleCalculationSettings: ko.Computed<boolean>;

    private infoToastService: IInfoToastService;
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private container : ExpireModesEditingViewModel, private expireMode : IExpireMode) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.update(expireMode);
        this.name.subscribe(this.onNameChanged.bind(this));
        this.ScheduleCalculationSettings(this.container.getScheduleCalculationSettings(this.expireMode.IdTipoScadenza).map((s: IScheduleCalculationSetting) => { return this.createScheduleCalculationSettingViewModel(s); }));

        this.HasScheduleCalculationSettings = ko.computed(() => {
            return this.ScheduleCalculationSettings().length > 0;
        });

        this.ScheduleDocumentPricePartTotal = ko.computed(() => {
            var scheduleSettings: IScheduleCalculationSettingViewModel[] = this.ScheduleCalculationSettings();
            var total: number = 0;
            for (var i = 0; i < scheduleSettings.length; i++)
                total += scheduleSettings[i].DocumentPricePartPercentage();

            return total;
        });

        this.IncompleteScheduleCalculationSettings = ko.computed(() => {
            return this.ScheduleDocumentPricePartTotal() < 100 && parseInt(this.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartPercentageCalculation;
        });

        this.PaymentFeePriceCalculation(!this.expireMode.PaymentFeePriceCalculation ? "0" : this.expireMode.PaymentFeePriceCalculation.toString());
        this.ScheduleCalculationMode(!this.expireMode.ScheduleCalculationMode ? "0" : this.expireMode.ScheduleCalculationMode.toString());

        this.ScheduleCalculationSettingsHasChanges = ko.computed(() => {
            var hasChanges: boolean = false;
            this.ScheduleCalculationSettings().forEach((s: IScheduleCalculationSettingViewModel) => { if (s.IsChanged()) hasChanges = true; });
            return hasChanges ||
                this.PaymentFeePriceCalculation() != (!this.expireMode.PaymentFeePriceCalculation ? '0' : this.expireMode.PaymentFeePriceCalculation).toString() ||
                this.ScheduleCalculationMode() != (!this.expireMode.ScheduleCalculationMode ? '0' : this.expireMode.ScheduleCalculationMode).toString();
        });
    }

    private onNameChanged(newValue : string) {
        if(!newValue) {
            this.container.deleteEntry(this);
        } else {
            this.container.createOrUpdateEntry(this);
        }
    }

    public Delete()
    {
        this.IsDeleted(true);
        this.container.deleteEntry(this);
    }

    public Restore()
    {
        this.IsDeleted(false);
        this.container.createOrUpdateEntry(this);
    }

    public AddScheduleCalculationSetting(): void {
        this.ScheduleCalculationSettings.push(this.createScheduleCalculationSettingViewModel(this.createEmptyScheduleCalculationSetting()));
    }

    isChanged(): boolean {
        if (this.IsDeleted())
            return false;

        var hasChanges = false;
        hasChanges = hasChanges || this.ScheduleCalculationSettingsHasChanges();
        return hasChanges;
    }

    getData() : IExpireMode {
        var expireMode : IExpireMode = <IExpireMode> $.extend({}, this.expireMode);
        expireMode.Descrizione = this.name();
        expireMode.Eliminato = this.IsDeleted() ? 1 : 0;
        return expireMode;
    }

    update(expireMode : IExpireMode) : void {
        this.expireMode = expireMode;
        this.name(this.expireMode.Descrizione);
        this.IsDeleted(this.expireMode.Eliminato == 1);
    }

    saveScheduleCalculationSettings(): Promise<IScheduleCalculationSetting[]> {
        var def = new Deferred<IScheduleCalculationSetting[]>();

        var errors: any[] = [];

        this.ScheduleCalculationSettings().forEach((s: IScheduleCalculationSettingViewModel) => {
            var validation = s.getValidation();
            errors = errors.concat(validation.filter(v => !v.valid).map(e => e.message));
        });


        if (errors.length != 0) {
            this.infoToastService.Warning(errors.map((e) => e()).join("<br/><br/>"));
            return def.reject().promise();
        }

        if (this.IncompleteScheduleCalculationSettings()) {
            this.confirmSaving()
                .then((confirm: boolean) => {
                    if (!confirm) {
                        def.reject();
                        return;
                    }

                    this.createOrUpdateScheduleCalculationSetting()
                        .then((updatedSchedules: IScheduleCalculationSetting[]) => {
                            this.expireMode.PaymentFeePriceCalculation = parseInt(this.PaymentFeePriceCalculation());
                            this.expireMode.ScheduleCalculationMode = parseInt(this.ScheduleCalculationMode());
                            this.ScheduleCalculationSettings(updatedSchedules.map((s: IScheduleCalculationSetting) => { return this.createScheduleCalculationSettingViewModel(s); }));
                            def.resolve(updatedSchedules);
                            this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ScheduleCalculationSettingsSaved);
                        })
                        .catch(() => {
                            def.reject();
                        });
                });

            return def.promise();
        }

        this.createOrUpdateScheduleCalculationSetting()
            .then((updatedSchedules: IScheduleCalculationSetting[]) => {
                this.expireMode.PaymentFeePriceCalculation = parseInt(this.PaymentFeePriceCalculation());
                this.expireMode.ScheduleCalculationMode = parseInt(this.ScheduleCalculationMode());
                this.ScheduleCalculationSettings(updatedSchedules.map((s: IScheduleCalculationSetting) => { return this.createScheduleCalculationSettingViewModel(s); }));
                def.resolve(updatedSchedules);
                this.infoToastService.Success(ProlifeSdk.TextResources.Invoices.ScheduleCalculationSettingsSaved);
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    public createOrUpdateScheduleCalculationSetting(): Promise<IScheduleCalculationSetting[]> {
        return this.container.createOrUpdateScheduleCalculationSetting(this.ScheduleCalculationSettings(), parseInt(this.PaymentFeePriceCalculation()), parseInt(this.ScheduleCalculationMode()));
    }

    public deleteScheduleCalculationSetting(scheduleSetting: IScheduleCalculationSettingViewModel): Promise<void> {
        return this.container.deleteScheduleCalculationSetting(scheduleSetting)
            .then(() => {
                this.ScheduleCalculationSettings.remove(scheduleSetting);
                this.calculateDocumentPricePartPercentage(true);
                this.refreshDocumentPricePartValuesAndThresholds();
                this.refreshDaysNumberSinceDocumentIssueValues();
            });
    }

    public refreshDocumentPricePartValuesAndThresholds(): void {
        var settingsLength: number = this.ScheduleCalculationSettings().length;

        for (var i = 0; i < settingsLength; i++) {
            if (i == 0) {
                this.ScheduleCalculationSettings()[i].DocumentPricePartValue(this.ScheduleCalculationSettings()[i].DocumentPriceThreshold());
                continue;
            }

            if (this.ScheduleCalculationSettings()[i].DocumentPriceThreshold() <= this.ScheduleCalculationSettings()[i - 1].DocumentPriceThreshold())
                this.ScheduleCalculationSettings()[i].DocumentPriceThreshold(this.ScheduleCalculationSettings()[i - 1].DocumentPriceThreshold() + 1);

            var previousSchedulesValue = 0;
            for (var j = 0; j < i; j++) {
                previousSchedulesValue += this.ScheduleCalculationSettings()[j].DocumentPricePartValue();
            }

            this.ScheduleCalculationSettings()[i].DocumentPricePartValue(this.ScheduleCalculationSettings()[i].DocumentPriceThreshold() - previousSchedulesValue);
        }
    }

    public refreshDaysNumberSinceDocumentIssueValues(): void {
        var settingsLength: number = this.ScheduleCalculationSettings().length;

        for (var i = 1; i < settingsLength; i++) {
            if (this.ScheduleCalculationSettings()[i].DaysNumberSinceDocumentIssue() <= this.ScheduleCalculationSettings()[i - 1].DaysNumberSinceDocumentIssue())
                this.ScheduleCalculationSettings()[i].DaysNumberSinceDocumentIssue(this.ScheduleCalculationSettings()[i - 1].DaysNumberSinceDocumentIssue() + 1);
        }
    }

    private confirmSaving(): Promise<boolean> {
        var def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Invoices.ConfirmScheduleCalculationSettingsNotComplete,
            ProlifeSdk.TextResources.Invoices.No,
            ProlifeSdk.TextResources.Invoices.Yes,
            (confirm: boolean) => { def.resolve(confirm); }
        );

        return def.promise();
    }

    private createEmptyScheduleCalculationSetting(): IScheduleCalculationSetting {
        return {
            Id: 0,
            PaymentDeadlineTypeId: this.expireMode.IdTipoScadenza,
            ScheduleTitle: null,
            DaysNumberSinceDocumentIssue: this.calculateDaysNumberSinceDocumentIssue(),
            EndOfMonthPostponement: false,
            DayOfMonthPostponement: false,
            DayOfMonth: 0,
            DocumentPricePartPercentage: this.calculateDocumentPricePartPercentage(),
            DocumentPriceThreshold: this.calculateDocumentPriceThreshold(),
            DocumentPricePartValue: this.calculateDocumentPricePartValue()
        };
    }

    private calculateDaysNumberSinceDocumentIssue(): number {
        if (this.ScheduleCalculationSettings().length == 0)
            return 0;

        return this.ScheduleCalculationSettings()[this.ScheduleCalculationSettings().length - 1].DaysNumberSinceDocumentIssue() + 1;
    }

    private calculateDocumentPricePartPercentage(fromDeletion: boolean = false): number {
        var total: number = 100 - this.ScheduleDocumentPricePartTotal();

        if (total == 0 || fromDeletion) {
            var schedulesSettingsCountAfterInsert: number = this.ScheduleCalculationSettings().length + 1;
            var schedulesSettingsCount: number = this.ScheduleCalculationSettings().length;
            var partial: number = Math.round(100 / (!fromDeletion ? schedulesSettingsCountAfterInsert : (schedulesSettingsCount > 0 ? schedulesSettingsCount : 1)));
            this.ScheduleCalculationSettings().forEach((s: IScheduleCalculationSettingViewModel) => { s.DocumentPricePartPercentage(partial); });
            var existingSchedulePart = partial * schedulesSettingsCount;
            total = 100 - existingSchedulePart;
            if (fromDeletion && schedulesSettingsCount > 0)
                this.ScheduleCalculationSettings()[schedulesSettingsCount - 1].DocumentPricePartPercentage(total + partial);
        }

        return total;
    }

    private calculateDocumentPriceThreshold(): number {
        if (this.ScheduleCalculationSettings().length == 0)
            return Number.MAX_VALUE;

        if (this.ScheduleCalculationSettings().length == 1)
            this.ScheduleCalculationSettings()[0].DocumentPriceThreshold(1000);

        var lastSchedule: IScheduleCalculationSettingViewModel = this.ScheduleCalculationSettings()[this.ScheduleCalculationSettings().length - 1];
        return lastSchedule.DocumentPriceThreshold() + 1;
    }

    private calculateDocumentPricePartValue(): number {
        if (this.ScheduleCalculationSettings().length == 0)
            return Number.MAX_VALUE;

        if (this.ScheduleCalculationSettings().length == 1)
            this.ScheduleCalculationSettings()[0].DocumentPricePartValue(1000);

        var lastSchedule: IScheduleCalculationSettingViewModel = this.ScheduleCalculationSettings()[this.ScheduleCalculationSettings().length - 1];
        return (lastSchedule.DocumentPriceThreshold() + 1) - lastSchedule.DocumentPricePartValue();
    }

    private createScheduleCalculationSettingViewModel(scheduleSetting: IScheduleCalculationSetting): IScheduleCalculationSettingViewModel {
        return new ScheduleCalculationSettingViewModel(this.serviceLocator, this, scheduleSetting);
    }
}

class ScheduleCalculationSettingViewModel implements IScheduleCalculationSettingViewModel {
    public ScheduleTitle: ko.Observable<string> = ko.observable();
    public DaysNumberSinceDocumentIssue: ko.Observable<number> = ko.observable();
    public EndOfMonthPostponement: ko.Observable<boolean> = ko.observable();
    public DayOfMonthPostponement: ko.Observable<boolean> = ko.observable();
    public DayOfMonth: ko.Observable<number> = ko.observable();
    public DocumentPricePartPercentage: ko.Observable<number> = ko.observable();
    public DocumentPriceThreshold: ko.Observable<number> = ko.observable();
    public DocumentPricePartValue: ko.Observable<number> = ko.observable();

    public IsNew: ko.Computed<boolean>;
    public IsChanged: ko.Computed<boolean>;
    public IsLast: ko.Computed<boolean>;

    private Id: ko.Observable<number> = ko.observable();
    private scheduleSetting: ko.Observable<any> = ko.observable();

    private lastThresholdValue: number = 0;
    private lastDaysNumberSinceDocumentIssueValue: number = 0;

    private infoToastService: IInfoToastService;

    @LazyImport(nameof<IValidationService>())
    protected validationService : IValidationService;

    protected validator : IValidator<ScheduleCalculationSettingViewModel>;

    constructor(private serviceLocator: IServiceLocator, private editor: ExpireModeViewModel, scheduleSetting: IScheduleCalculationSetting) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.update(scheduleSetting);

        this.IsNew = ko.computed(() => {
            return this.Id() <= 0;
        });

        this.IsChanged = ko.computed(() => {
            return this.ScheduleTitle() != this.scheduleSetting().ScheduleTitle
                || this.DaysNumberSinceDocumentIssue() !== this.scheduleSetting().DaysNumberSinceDocumentIssue
                || this.EndOfMonthPostponement() != this.scheduleSetting().EndOfMonthPostponement
                || this.DayOfMonthPostponement() != this.scheduleSetting().DayOfMonthPostponement
                || this.DayOfMonth() !== this.scheduleSetting().DayOfMonth
                || this.DocumentPricePartPercentage() !== this.scheduleSetting().DocumentPricePartPercentage
                || this.DocumentPricePartValue() !== this.scheduleSetting().DocumentPricePartValue
                || this.DocumentPriceThreshold() !== this.scheduleSetting().DocumentPriceThreshold
                || this.IsNew();
        });

        this.IsLast = ko.computed(() => {
            return this.editor.ScheduleCalculationSettings.indexOf(this) == this.editor.ScheduleCalculationSettings().length - 1;
        });

        this.EndOfMonthPostponement.subscribe((value: boolean) => {
            if (value)
                this.DayOfMonthPostponement(false);
        });

        this.DayOfMonthPostponement.subscribe((value: boolean) => {
            if (value)
                this.EndOfMonthPostponement(false);
        });

        var disableThresholdValidation: boolean = false;
        this.DocumentPriceThreshold.subscribe((newValue: number) => {
            if (disableThresholdValidation)
                return;
            disableThresholdValidation = true;

            var index: number = this.editor.ScheduleCalculationSettings.indexOf(this);
            if (index == 0) {
                if (newValue <= 0) {
                    this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ScheduleCalculationInvalidTrhesholdValue);
                    this.DocumentPriceThreshold(this.lastThresholdValue);
                    setTimeout(() => disableThresholdValidation = false, 200);
                    return;
                }

                this.lastThresholdValue = newValue;
                this.editor.refreshDocumentPricePartValuesAndThresholds();

                setTimeout(() => disableThresholdValidation = false, 200);
                return;
            }

            var previousSchedule: IScheduleCalculationSettingViewModel = this.editor.ScheduleCalculationSettings()[index - 1];
            if (newValue < previousSchedule.DocumentPriceThreshold()) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ScheduleCalculationInvalidTrhesholdValue);
                this.DocumentPriceThreshold(this.lastThresholdValue);
                setTimeout(() => disableThresholdValidation = false, 200);
                return;
            }

            this.lastThresholdValue = newValue;
            this.editor.refreshDocumentPricePartValuesAndThresholds();

            setTimeout(() => disableThresholdValidation = false, 200);
        });

        var disableDaysNumberSinceDocumentIssueValidation: boolean = false;
        this.DaysNumberSinceDocumentIssue.subscribe((newValue: number) => {
            if (disableDaysNumberSinceDocumentIssueValidation)
                return;
            disableDaysNumberSinceDocumentIssueValidation = true;

            if (newValue < 0) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ScheduleCalculationInvalidDaysNumberSinceDocumentIssueValue);
                this.DaysNumberSinceDocumentIssue(this.lastDaysNumberSinceDocumentIssueValue);
                setTimeout(() => disableDaysNumberSinceDocumentIssueValidation = false, 200);
            }

            var index = this.editor.ScheduleCalculationSettings.indexOf(this);
            if (index == 0) {
                this.lastDaysNumberSinceDocumentIssueValue = newValue;
                this.editor.refreshDaysNumberSinceDocumentIssueValues();
                setTimeout(() => disableDaysNumberSinceDocumentIssueValidation = false, 200);
                return;
            }

            if (newValue <= this.editor.ScheduleCalculationSettings()[index - 1].DaysNumberSinceDocumentIssue()) {
                this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.ScheduleCalculationInvalidDaysNumberSinceDocumentIssueValue);
                this.DaysNumberSinceDocumentIssue(this.lastDaysNumberSinceDocumentIssueValue);
                setTimeout(() => disableDaysNumberSinceDocumentIssueValidation = false, 200);
                return;
            }

            this.lastDaysNumberSinceDocumentIssueValue = newValue;
            this.editor.refreshDaysNumberSinceDocumentIssueValues();
            setTimeout(() => disableDaysNumberSinceDocumentIssueValidation = false, 200);
        });

        this.configureValidation();
    }

    public getData(): IScheduleCalculationSetting {
        return {
            Id: this.scheduleSetting().Id,
            PaymentDeadlineTypeId: this.scheduleSetting().PaymentDeadlineTypeId,
            ScheduleTitle: this.ScheduleTitle(),
            DaysNumberSinceDocumentIssue: this.DaysNumberSinceDocumentIssue(),
            EndOfMonthPostponement: this.EndOfMonthPostponement(),
            DayOfMonthPostponement: this.DayOfMonthPostponement(),
            DayOfMonth: this.DayOfMonth(),
            DocumentPricePartPercentage: this.DocumentPricePartPercentage(),
            DocumentPriceThreshold: this.DocumentPriceThreshold(),
            DocumentPricePartValue: this.DocumentPricePartValue()
        };
    }

    public update(scheduleSetting: IScheduleCalculationSetting): void {
        this.scheduleSetting(scheduleSetting);
        scheduleSetting.DayOfMonth = scheduleSetting.DayOfMonth || 1;
        scheduleSetting.DocumentPricePartValue = scheduleSetting.DocumentPricePartValue || 0;
        scheduleSetting.DocumentPriceThreshold = scheduleSetting.DocumentPriceThreshold || 0;

        this.Id(this.scheduleSetting().Id);

        this.ScheduleTitle(this.scheduleSetting().ScheduleTitle);
        this.DaysNumberSinceDocumentIssue(this.scheduleSetting().DaysNumberSinceDocumentIssue);
        this.EndOfMonthPostponement(this.scheduleSetting().EndOfMonthPostponement);
        this.DayOfMonthPostponement(this.scheduleSetting().DayOfMonthPostponement);
        this.DayOfMonth(this.scheduleSetting().DayOfMonth);
        this.DocumentPricePartPercentage(this.scheduleSetting().DocumentPricePartPercentage);
        this.DocumentPricePartValue(!this.scheduleSetting().DocumentPricePartValue ? 0 : this.scheduleSetting().DocumentPricePartValue);
        this.DocumentPriceThreshold(!this.scheduleSetting().DocumentPriceThreshold ? 0 : this.scheduleSetting().DocumentPriceThreshold);

        this.lastThresholdValue = this.DocumentPriceThreshold();
        this.lastDaysNumberSinceDocumentIssueValue = this.DaysNumberSinceDocumentIssue();
    }

    public Delete(): Promise<void> {
        return this.editor.deleteScheduleCalculationSetting(this);
    }

    public getValidation(): IValidation[] {
        return this.validator.validate(this);
    }

    private configureValidation() {
        this.validator = this.validationService.createValidator<ScheduleCalculationSettingViewModel>()
            .isNotNullOrUndefined(s => s.DaysNumberSinceDocumentIssue(), TextResources.Invoices.DaysNumberSinceDocumentIssueRequired)
            .isTrue(s => s.DaysNumberSinceDocumentIssue() >= 0)
            .isNotNullOrUndefined(s => s.DocumentPricePartPercentage(), TextResources.Invoices.DocumentPricePartRequired, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartPercentageCalculation)
            .isTrue(s => s.DocumentPricePartPercentage() >= 0, undefined, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartPercentageCalculation)
            .isNotNullOrUndefined(s => s.DocumentPricePartValue(), TextResources.Invoices.DocumentPricePartRequired, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartValueCalculation)
            .isTrue(s => s.DocumentPricePartValue() >= 0, undefined, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartValueCalculation)
            .isNotNullOrUndefined(s => s.DocumentPriceThreshold(), TextResources.Invoices.DocumentPricePartRequired, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartValueCalculation)
            .isTrue(s => s.DocumentPriceThreshold() >= 0, undefined, () => parseInt(this.editor.PaymentFeePriceCalculation()) == ProlifeSdk.PricePartValueCalculation)
            .isNotNullOrUndefined(s => s.DayOfMonth(), TextResources.Invoices.DayOfMonthRequired, () => this.DayOfMonthPostponement())
            .isTrue(s => s.DayOfMonth() >= 0, undefined, () => this.DayOfMonthPostponement());
    }
}