import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { HoursApprovalState as HoursApprovalStateEnum } from "../../enums/HoursApprovalState";
import { HoursApprovalStateUtilities } from "../approval/HoursApprovalStateUtilities";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type HoursApprovalStateProps = {
    state: ko.Subscribable<HoursApprovalStateEnum>;
}

export function HoursApprovalState(props: HoursApprovalStateProps) {
    const C = require("./HoursApprovalState")._HoursApprovalState as typeof _HoursApprovalState;
    return <C {...props} />;
}

export class _HoursApprovalState {
    static defaultProps: Partial<HoursApprovalStateProps> = {}

    private label : ko.Observable<string> = ko.observable("");
    private icon : ko.Observable<string> = ko.observable("");

    private subscription: ko.Subscription;

    constructor(private props : HoursApprovalStateProps) {
        
    }

    componentDidMount() {
        this.subscription = this.props.state.subscribe((v) => this.update(v));
    }

    componentWillUnmount() {
        this.subscription.dispose();
        this.subscription = undefined;
    }
    
    private update(state: HoursApprovalStateEnum): void {
        this.label(HoursApprovalStateUtilities.getLabelFor(state));
        this.icon(HoursApprovalStateUtilities.getIconFor(state));
    }

    render() {
        const has = this;

        return ComponentUtils.bindTo((
            <div data-bind={{ attr: { title: has.label } }}>
                <i className="fa" data-bind={{ css: has.icon }}></i>
            </div>
        ), this, "has");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(HoursApprovalState);
}