import * as React from "@abstraqt-dev/jsxknockout";

interface IFlagParams {
    className?: string;
    disabled?: string;
    checked: string;
    visible?: string;
    label?: string;
}

export function Flag(params: IFlagParams) {
    const checkerParams = {};
    const checkboxParams = {
        "data-bind": `checked: ${params.checked}`,
    };

    if (params.disabled !== null && params.disabled !== undefined) {
        checkerParams["data-bind"] = `css : { disabled : ${params.disabled} }`;
        checkboxParams["data-bind"] += `, attr: { disabled: ${params.disabled} }`;
    }

    const flagNodes = (
        <div className={params.className}>
            <div class="checker" {...checkerParams}>
                <span data-bind={`css : { checked : ${params.checked} }`}>
                    <input type="checkbox" {...checkboxParams} />
                </span>
            </div>
            {params.label ?? ""}
        </div>
    );

    if (!params.visible) return flagNodes;

    return <ko-if data-bind={params.visible}>{flagNodes}</ko-if>;
}
