import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { Select } from "../Select";
import { ResourcesDataSource } from "../../DataSources/ResourcesDataSource";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type ResourceColumnFilterProps = {
    initialValue: number;
    onResourceSelected: (resourceId: number) => void;
}

export function ResourceColumnFilter(props: ResourceColumnFilterProps) {
    const C = require("./ResourceColumnFilter")._ResourceColumnFilter as typeof _ResourceColumnFilter;
    return <C {...props} />;
}

export class _ResourceColumnFilter extends DialogComponentBase {
    static defaultProps: Partial<ResourceColumnFilterProps> = {
    }

    public ResourcesDataSource: ResourcesDataSource = new ResourcesDataSource();
    ResourceId : ko.Observable<number> = ko.observable();

    constructor(private props : ResourceColumnFilterProps) {
        super({ popover: true });

        this.ResourceId(props.initialValue);
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        return <Select 
                    style={{ width: '200px' }}
                    dataSource={this.ResourcesDataSource}
                    allowClear
                    placeholder="Seleziona una risorsa..."
                    value={this.ResourceId}
                    onSelect={(i) => this.props.onResourceSelected(i.id as number)}
                    onDeselect={(i) => this.props.onResourceSelected(undefined)} />;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ResourceColumnFilter);
}