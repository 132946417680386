import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ComponentUtils, PropsWithChildren, reloadNow } from "../../../../Core/utils/ComponentUtils";
//import { PreOrderRowSource } from "./PreOrderRowSource";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ISavePreOrderRequestRowSource } from "../../../ProvisioningService";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import moment = require("moment-timezone");
import { PreOrderRowSource } from "../../ui/panels/PreOrderEditor/PreOrderRowsEditor";
import { PercentageInput } from "../../../../Components/PercentageInputComponent";
import jss from "jss";

const styleSheet = jss.createStyleSheet({
    customerOrderRowsTableEditor: {
        "& table > tbody > tr.danger input": {
            backgroundColor: '#fff2f2',
            color: '#ff6b68'
        }
    }
});
const { classes } = styleSheet.attach();

export interface CustomerOrdersRowsTableEditorProps {
    preOrderRowId : number;
    sources: ko.ObservableArray<PreOrderRowSource>;
}

export function CustomerOrdersRowsTableEditor(props: CustomerOrdersRowsTableEditorProps) {
    const C = require("./CustomerOrdersRowsTableEditor")._CustomerOrdersRowsTableEditor as typeof _CustomerOrdersRowsTableEditor;
    return <C {...props} />
}

export class _CustomerOrdersRowsTableEditor {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;

    public AllSourcesSelected: ko.Computed<boolean>;
    
    private onDocumentRowExpiryVariationHandle: number;

    constructor(private props : CustomerOrdersRowsTableEditorProps) {
        this.AllSourcesSelected = ko.computed({
            read: () => this.props.sources().filter(r => r.Selected()).length == this.props.sources().length,
            write: (value) => {
                this.props.sources().forEach(r => r.Selected(value));
            }
        });
    }

    componentDidMount() {
        this.onDocumentRowExpiryVariationHandle = this.changesNotificationsService.RegisterEventHandler("OnDocumentRowExpiryVariation", (data: { RowId: number, EntityType: string, DueDate: Date }) => {
            const rowsToUpdate = this.props.sources().filter(r => r.CustomerOrderRowId === data.RowId);
            rowsToUpdate.forEach(r => r.CustomerOrderRowDeliveryDate(data.DueDate));
        });
    }

    componentWillUnmount() {
        this.changesNotificationsService.UnregisterEventHandler("OnDocumentRowExpiryVariation", this.onDocumentRowExpiryVariationHandle);
    }

    getSources() : ISavePreOrderRequestRowSource[] {
        return this.props.sources().map(s => ({
            Id: s.Id ?? -1,
            Amount: s.RowAmount,
            DocumentId: s.DocumentId,
            DocumentRowDescription: s.Description,
            DocumentRowId: s.CustomerOrderRowId,
            DocumentRowNumber: s.CustomerOrderRowNumber,
            DocumentType: "COR", //TODO: Cambiare!
            EntityRefDescription: s.Description,
            EntityRefId: s.EntityRefId,
            UoM: s.UoM,
            WarehouseId: s.SourceWarehouseId
        }));
    }

    

    public async removeSource(rowSource: PreOrderRowSource): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            ProlifeSdk.TextResources.Provisioning.RemoveSourceRowFromPreOrderRowMessage,
            ProlifeSdk.TextResources.Provisioning.Abort,
            ProlifeSdk.TextResources.Provisioning.Confirm);

        if (!confirm)
            return;

        this.props.sources.remove(rowSource);
    }

    render() {
        const _corte = this;
        let corRow: IDataSourceModel<number, PreOrderRowSource>;
            
        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<PreOrderRowSource>();

        const factory = (source: PreOrderRowSource) => ({
            id: source.Id,
            title: source.Description,
            isGroup: false,
            isLeaf: true,
            model: source
        });

        const RowWithBackground = (props: PropsWithChildren<unknown>) => {
            return  <tr className="prolife-table-row" data-bind={{ css: corRow.model.MarginWarning }}>
                        {props.children}
                    </tr>
        }

        return ComponentUtils.bindTo(
            <div className={classes.customerOrderRowsTableEditor}>
                <Table compact striped dataSource={{ array: _corte.props.sources, factory: factory }} rowAs="corRow" components={{ row: RowWithBackground }}>
                    <Column title="Documento" sorter={sortString(r => r.Description)} className="text-left text-ellipsis" style={{ maxWidth: '250px' }}>
                        <a href="#" data-bind={{ text: corRow.model.DocumentInfo, click: corRow.model.goToDocument.bind(corRow.model) }}></a>
                    </Column>
                    {/* <Column title="Riga documento" sorter={sortString(r => r.Description)} className="text-left text-ellipsis" style={{ maxWidth: '200px' }}>
                        <span data-bind={{ text: corRow.model.Description }}></span>
                    </Column> */}
                    <Column title="Fabbisogno" sorter={sortNumber(r => r.NeededAmount)} className="text-right">
                        <span data-bind={{ numberText: corRow.model.NeededAmount }}></span>
                    </Column>
                    <Column title="Q.tà riga" sorter={sortNumber(r => r.RowAmount)} className="text-right">
                        <span data-bind={{ numberText: corRow.model.RowAmount }}></span>
                    </Column>
                    <Column title="In stock" sorter={sortNumber(r => r.WarehouseStock)} className="text-right">
                        <span data-bind={{ numberText: corRow.model.WarehouseStock }}></span>
                    </Column>
                    <Column title="Margine" className="text-right">
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRowSource>) => <PercentageInput value={item.Data.model.Margin} simple selectOnFocus />}
                        </ColumnBody>
                    </Column>
                    <Column title="Costo Medio" className="text-right">
                        <span data-bind={{ moneyText: corRow.model.EntityMeanCost }}></span>
                    </Column>
                    <Column title="P. Unit." className="text-right">
                        <span data-bind={{ moneyText: corRow.model.CustomerOrderRowUnitPrice }}></span>
                    </Column>
                    <Column title="Sconti" className="text-right">
                        <span data-bind={{ text: corRow.model.CustomerOrderRowDiscounts }}></span>
                    </Column>
                    <Column title="P. Netto" className="text-right">
                        <span data-bind={{ moneyText: corRow.model.CustomerOrderRowNetUnitPrice }}></span>
                    </Column>
                    <Column title="Totale" className="text-right">
                        <span data-bind={{ moneyText: corRow.model.CustomerOrderRowTotalPrice }}></span>
                    </Column>
                    <Column title="Data cons." sorter={sortDate(r => r.CustomerOrderRowDeliveryDate())} className="text-right">
                        <a href="#" data-bind={{ dateText: corRow.model.CustomerOrderRowDeliveryDate, dateTextNoValue: 'N/A', click: corRow.model.editDeliveryDate.bind(corRow.model) }} title="Cambia data di consegna"></a>
                    </Column>
                    <Column title="Commessa" sorter={sortString(r => r.JobOrderName)} className="text-left text-ellipsis" style={{ maxWidth: '200px' }}>
                        <span style={{ display: 'inline' }} data-bind={{ text: corRow.model.JobOrderName }}></span>
                    </Column>
                    {/* <Column title="Magazzino" sorter={sortString(r => r.SourceWarehouse)} className="text-left text-ellipsis" style={{ maxWidth: '200px' }}>
                        <span style={{ display: 'inline' }} data-bind={{ text: corRow.model.SourceWarehouse }}></span>
                    </Column>
                    <Column title="Cliente" sorter={sortString(r => r.CustomerName)} className="text-left text-ellipsis" style={{ maxWidth: '200px' }}>
                        <span style={{ display: 'inline' }} data-bind={{ text: corRow.model.CustomerName }}></span>
                    </Column> */}
                    <Column className="text-right">
                        <button type="button" className="btn btn-danger btn-xs btn-circle" title="Rimuovi dal preordine" data-bind={{ click: _corte.removeSource.bind(_corte, corRow.model), tooltip: { placement: 'left' } }}>
                            <i class="fa fa-trash-o"></i>&nbsp;Elimina
                        </button>
                    </Column>
                </Table>
            </div>
       , this, "_corte");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CustomerOrdersRowsTableEditor);
}