import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { LazyImport } from "../Core/DependencyInjection";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { DocumentClosingStatusHelper } from "../Invoices/invoices/utils/DocumentClosingStatusHelper";

const styleSheet = jss.createStyleSheet({
    documentClosingStatusIndicator: {
        "&.btn.icon": {
            "&[data-state='0']": {
                backgroundColor: 'transparent',
                color: 'black'
            },

            "&[data-state='1']": {
                backgroundColor: '#46c72f',
                color: 'white'
            },

            "&[data-state='2']": {
                backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #ecbc29 100%) !important',
                color: 'white'
            },

            "&[data-state='3']": {
                backgroundColor: '#f3565d',
                color: 'white'
            },
            
            "&[data-state='4']": {
                backgroundImage: 'linear-gradient(to bottom, #ecbc29 0, #f3565d 100%) !important',
                color: 'white'
            },

            "&[data-state='5']": {
                backgroundColor: '#ecbc29',
                color: 'white'
            },

            "&[data-state='6']": {
                backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #f3565d 100%) !important',
                color: 'white'
            },

            "&[data-state='7']": {
                backgroundImage: 'linear-gradient(to bottom, #46c72f 0, #ecbc29 50%, #f3565d 100%) !important',
                color: 'white'
            },
        }
    }
});
const { classes } = styleSheet.attach();

type DocumentClosingStatusIndicatorProps = {
    documentId: number;
    documentType: string;
    protocolId: number;
    documentState: number;
    documentClosingState: number;
    documentCauseLogicType: number;

    disableClick?: boolean;

    className?: string;
    content: React.ReactElement | (() => React.ReactElement);
}

export function DocumentClosingStatusIndicator(props: DocumentClosingStatusIndicatorProps) {
    const C = require("./DocumentClosingStatusIndicator")._DocumentClosingStatusIndicator as typeof _DocumentClosingStatusIndicator;
    return <C {...props} />;
}

export class _DocumentClosingStatusIndicator {
    static defaultProps: Partial<DocumentClosingStatusIndicatorProps> = {
        className: "",
        disableClick: false
    }

    public closingState: number;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;
    
    constructor(private props : DocumentClosingStatusIndicatorProps) {
        this.closingState = DocumentClosingStatusHelper.translateDocumentClosingStatus(this.props.documentType, this.props.protocolId, this.props.documentCauseLogicType, this.props.documentState, this.props.documentClosingState);
    }

    public OpenDocument() {
        if (this.props.disableClick)
            return;

        this.documentsService.OpenDocumentOverlay(this.props.documentType, this.props.documentId);
    }
    
    render() {
        const vm = this;

        const cssClasses = ComponentUtils.classNames(classes.documentClosingStatusIndicator, "btn icon", this.props.className);

        return ComponentUtils.bindTo((
            <button type="button" className={cssClasses}
               style={{ display: 'inline-block', minWidth: '21px' }} 
               data-bind={{ click: vm.OpenDocument.bind(vm), attr: { 'data-state': vm.closingState } }}
            >
                {typeof this.props.content === "function" ? this.props.content() : this.props.content}
            </button>
        ), this, "vm");
    }
}

export class DocumentClosingStatusIndicatorUI {
    constructor(private props : DocumentClosingStatusIndicatorProps) {}

    render() {
        return <DocumentClosingStatusIndicator {...this.props} />;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentClosingStatusIndicator);
}