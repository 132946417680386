import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import moment = require("moment");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { DateDataSource, IMonthNavigationMenuComponentModel } from "../navigation/DateDataSource";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { INavigationMenuComponentModel } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IWorkedHoursService, IMonthlyHour } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { NavigationMenu } from "../../../../Components/NavigationMenu";
import { CheckBox } from "../../../../Components/Checkbox";
import { Layout } from "../../../../Components/Layouts";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Application } from "../../../../Components/Application";
import { If, IfNot, With } from "../../../../Components/IfIfNotWith";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { ISelectableDate } from "./IDate";
import { IHoursContainer } from "./IHoursContainer";
import { HourApprovalViewModel } from "./HourApprovalViewModel";
import { TaskApprovalViewModel } from "./TaskApprovalViewModel";
import { JobOrderApprovalViewModel } from "./JobOrderApprovalViewModel";
import { ResourceApprovalViewModel } from "./ResourceApprovalViewModel";
import { HoursApprovalGroupsSelector } from "./HoursApprovalGroupsSelector";
import { TextInput } from "../../../../Components/TextInput";
import { Delay } from "../../../../Decorators/Delay";
import { IGetResourceHoursApprovalGroupsRequest, IUsersService } from "../../../../Users/UsersService";
import { HoursApprovalSummary } from "./HoursApprovalSummary";
import { HoursApprovalState } from "../../enums/HoursApprovalState";
import { HoursApprovalStateUtilities } from "./HoursApprovalStateUtilities";

const styleSheet = jss.createStyleSheet({
    hoursApprovalPanel: {
        "& .page-bar": {
            zIndex: 5,

            "& .btn-group": {
                "& .btn:not(:last-child)": {
                    marginRight: "5px",
                },
            },

            "& .filters": {
                "& .filter-wrapper": {
                    width: "300px",
                },
            },
        },

        "& table": {
            borderSpacing: "0",
            border: "1px solid #ddd",

            "& thead": {
                "& tr": {
                    "& th": {
                        border: "1px solid #ddd !important",
                    },
                },
            },

            "& .resource-column": {
                padding: "0px",
                width: "300px",

                "& .all-days-select": {
                    fontSize: "14px",
                },
            },

            "& td": {
                height: "50px",

                "& .item": {
                    borderLeft: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "5px",
                    height: "100%",
                    width: "calc(100% + 2px)",
                    position: "relative",
                    left: "-1px",
                    alignItems: "center",

                    "& .elem-title": {
                        whiteSpace: "initial",
                    },
                },

                "& .name": {
                    display: "block",
                },

                "& .cell": {
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",

                    "&.selected": {
                        border: "1px solid red",
                    },
                },
            },

            "& .date-column": {
                padding: "0px",
                width: "60px",
                verticalAlign: "top",

                "& .day": {
                    display: "block",
                },
            },

            "& tbody": {
                "& tr": {
                    "&.resource": {
                        backgroundColor: "#ffffff",

                        "& .alerts": {
                            padding: "0px 10px",

                            "& .max-hours-alert": {
                                color: "red",
                            },

                            "& .min-hours-alert": {
                                color: "orange",
                            },
                        },

                        "&.opened": {
                            backgroundColor: "#3d76b1 !important",

                            "& > td": {
                                color: "white",

                                "&.sticky-column": {
                                    background: "#3d76b1 !important",
                                },

                                "&.text-danger": {
                                    color: "#ffb5b3",
                                },

                                "& .text-danger": {
                                    color: "#ffb5b3",
                                },

                                "& .approval-icons i": {
                                    color: "#7e94ab",

                                    "&.enabled": {
                                        color: "#222",
                                    },
                                },
                            },
                        },

                        "&.active": {
                            color: "black",

                            "& > td": {
                                backgroundColor: "inherit",

                                "& .approval-icons i": {
                                    color: "#d6d6d6",

                                    "&.enabled": {
                                        color: "#222",
                                    },
                                },

                                "&.text-danger": {
                                    color: "#ffb5b3",
                                },

                                "& .text-danger": {
                                    color: "#ffb5b3",
                                },
                            },
                        },

                        "& td": {
                            color: "black",

                            minWidth: "44px",
                            maxWidth: "89px",

                            "&.active": {
                                backgroundColor: "inherit",

                                "&.text-danger": {
                                    color: "#ffb5b3",
                                },

                                "& .text-danger": {
                                    color: "#ffb5b3",
                                },
                            },

                            "&:first-of-type": {
                                cursor: "pointer",
                                width: "240px",
                                minWidth: "240px",

                                fontSize: "13px",
                            },

                            "&.text-danger": {
                                color: "#f90700",
                            },

                            "& .text-danger": {
                                color: "#f90700",
                            },
                        },

                        "&:hover": {
                            backgroundColor: "#80c2ff !important",
                            background: "#80c2ff !important",
                        },

                        "&:hover td": {
                            color: "white",

                            "&.text-danger": {
                                color: "#ffb5b3",
                            },

                            "& .text-danger": {
                                color: "#ffb5b3",
                            },

                            "&.sticky-column": {
                                background: "#80c2ff !important",
                            },
                        },

                        "& .approval-icons": {
                            fontSize: "10px",
                            minWidth: "33px",

                            "& i": {
                                fontSize: "10px",
                                color: "#d6d6d6",

                                "&.enabled": {
                                    color: "#222",
                                },
                            },
                        },
                    },

                    "&.joborder": {
                        backgroundColor: "#ccc",

                        "&.active": {
                            "& td": {
                                backgroundColor: "inherit",

                                "&.sticky-column": {
                                    background: "inherit !important",
                                },
                            },
                        },

                        "&:hover": {
                            backgroundColor: "#bbb",

                            "& td": {
                                "&.sticky-column": {
                                    background: "#bbb !important",
                                },
                            },
                        },

                        "& td": {
                            color: "black",

                            "&.sticky-column": {
                                background: "#ccc !important",
                            },

                            "&.active": {
                                color: "inherit",
                                backgroundColor: "inherit",

                                "&.sticky-column": {
                                    background: "inherit !important",
                                },
                            },

                            "&:first-of-type": {
                                cursor: "pointer",
                                fontSize: "13px",
                            },

                            "&.text-danger": {
                                color: "#a94442",
                            },

                            "& .text-danger": {
                                color: "#a94442",
                            },
                        },

                        "& .approval-icons": {
                            fontSize: "10px",
                            minWidth: "33px",

                            "& i": {
                                fontSize: "10px",
                                color: "#eee",

                                "&.enabled": {
                                    color: "black",
                                },
                            },
                        },
                    },

                    "&.task": {
                        "&:hover": {
                            backgroundColor: "#eee",

                            "& td": {
                                "&.sticky-column": {
                                    background: "#eee !important",
                                },
                            },
                        },

                        "&.active": {
                            "& > td": {
                                backgroundColor: "inherit",

                                "&.sticky-column": {
                                    background: "white !important",
                                },

                                "& .text-muted": {
                                    color: "#002f61",
                                },
                            },
                        },

                        "& td": {
                            "&.active": {
                                color: "inherit",
                                backgroundColor: "inherit",

                                "&.sticky-column": {
                                    background: "inherit",
                                },
                            },

                            "&:first-of-type": {
                                fontSize: "13px",
                            },

                            "&.text-danger": {
                                color: "#a94442",
                            },

                            "& .text-danger": {
                                color: "#a94442",
                            },
                        },

                        "& .approval-icons": {
                            fontSize: "10px",
                            minWidth: "33px",

                            "& i": {
                                fontSize: "10px",
                                color: "#ccc",

                                "&.enabled": {
                                    color: "black",
                                },
                            },
                        },
                    },
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

export function HoursApprovalPanelViewModel(props: HoursApprovalPanelViewModelProps) {
    const C = require("./HoursApprovalPanelViewModel")
        ._HoursApprovalPanelViewModel as typeof _HoursApprovalPanelViewModel;
    return <C {...props} />;
}

export type HoursApprovalPanelViewModelProps = {
    forwardRef?: (panel: _HoursApprovalPanelViewModel) => void;
};

export class _HoursApprovalPanelViewModel implements IDataSourceListener {
    public Resources: ko.ObservableArray<IHoursContainer> = ko.observableArray();
    public Dates: ko.ObservableArray<ISelectableDate> = ko.observableArray();
    public ShowOnlySelectedDays: ko.Observable<boolean> = ko.observable(false);
    public AllDaysSelected: ko.Computed<boolean>;
    public AllActivitiesSelected: ko.Computed<boolean>;
    public SelectedDates: ko.Computed<ISelectableDate[]>;
    public DateDataSource: DateDataSource;

    public DisableFilterButton: ko.Computed<boolean>;
    public CalculatedTableWidth: ko.Computed<string>;
    public CalculatedTableWidthWithScrollbar: ko.Computed<string>;
    public LockButtonLabel: ko.Computed<string>;
    public LockButtonTitle: ko.Computed<string>;

    public IsLoading: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService: IWorkedHoursService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IUsersService>())
    private usersService: IUsersService;

    private collapsedStateCache: ICache = {};
    private selectedRowsCache: ICache = {};
    private selectedColumnsCache: ISelectableDate[] = [];

    private currentMonth: number = null;
    private currentStartDate: ko.Observable<Date> = ko.observable();
    private currentEndDate: Date = null;
    private currentMonthHours: IMonthlyHour[] = [];

    private DataLoaded: ko.Observable<boolean> = ko.observable(false);
    private ResourcesTextFilter: ko.Observable<string> = ko.observable("");
    private SelectedGroups: ko.ObservableArray<number> = ko.observableArray([]);

    private EnableGroupsSelector: ko.Observable<boolean> = ko.observable(false);
    private SelectedMonthIsLocked: ko.Observable<boolean> = ko.observable(false);

    constructor(private props: HoursApprovalPanelViewModelProps) {
        this.DateDataSource = new DateDataSource(false, true);

        this.AllDaysSelected = ko.computed({
            read: () => {
                const dates = this.Dates();
                const selectedDates = dates.filter((d) => d.Selected());

                if (dates.length === selectedDates.length && selectedDates.length > 0) return true;

                if (dates.length !== selectedDates.length && selectedDates.length > 0) return null;

                return false;
            },
            write: (value: boolean) => {
                const dates = this.Dates();
                for (const date of dates) {
                    date.Selected(value);
                }
            },
        });

        this.AllActivitiesSelected = ko.computed({
            read: () => {
                if (this.IsLoading()) return true;

                const selectableResources = this.Resources().filter((r) => r.IsSelectable());
                const selectedResources = selectableResources.filter((d) => d.Selected());

                if (selectableResources.length === selectedResources.length && selectedResources.length > 0)
                    return true;

                if (selectableResources.length !== selectedResources.length && selectedResources.length > 0)
                    return null;

                return false;
            },
            write: (value: boolean) => {
                const resources = this.Resources();
                for (const resource of resources) {
                    resource.Selected(value);
                }
            },
        });

        this.SelectedDates = ko.computed(() => {
            return this.Dates().filter((d) => d.Selected());
        });

        this.DisableFilterButton = ko.computed(() => {
            return (this.AllDaysSelected() || this.SelectedDates().length == 0) && !this.ShowOnlySelectedDays();
        });

        this.ResourcesTextFilter.subscribe(() => this.debouncedLoad());
        this.SelectedGroups.subscribe(() => this.load());

        this.setGroupsSelectorEnabler();

        this.LockButtonLabel = ko.computed(() => {
            let month = "";
            if (this.currentStartDate()) month = moment(this.currentStartDate()).format("MMMM");

            if (this.SelectedMonthIsLocked())
                return String.format(TextResources.WorkedHours.UnlockMonthlyHoursInsertion, month);
            else return String.format(TextResources.WorkedHours.LockMonthlyHoursInsertion, month);
        });

        this.LockButtonTitle = ko.computed(() => {
            return this.SelectedMonthIsLocked()
                ? TextResources.WorkedHours.UnlockMonthlyHoursInsertionTooltip
                : TextResources.WorkedHours.LockMonthlyHoursInsertionTooltip;
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    private async setGroupsSelectorEnabler(): Promise<void> {
        try {
            const approvalGroupsRequest: IGetResourceHoursApprovalGroupsRequest = {
                textFilter: "",
            };
            const groups = await this.usersService.GetResourceHoursApprovalGroups(approvalGroupsRequest);
            this.EnableGroupsSelector(groups && groups.length > 1);
        } catch (e) {
            console.log(e);
        }
    }

    async setInterval(startDate: Date, endDate: Date): Promise<void> {
        this.DataLoaded(false);

        this.currentStartDate(startDate);
        this.currentEndDate = endDate;

        await this.DateDataSource.selectMonth(startDate);

        this.Resources([]);
        this.updateDates(startDate, endDate);

        this.AllDaysSelected(false);
        this.AllActivitiesSelected(false);

        await Promise.all([this.load(), this.loadSelectedMonthLockStatus()]);

        this.DataLoaded(true);

        this.AllDaysSelected(true);
        this.AllActivitiesSelected(true);
    }

    private async switchMonthLockStatus(): Promise<void> {
        try {
            const date = this.currentStartDate();
            if (!date) return;

            const currentLockStatus = this.SelectedMonthIsLocked();
            await this.workedHoursService.SetLockStatusForMonth(
                date.getFullYear(),
                date.getMonth() + 1,
                !currentLockStatus
            );
            this.SelectedMonthIsLocked(!currentLockStatus);

            this.DateDataSource.refresh();
        } catch (e) {
            console.log(e);
        }
    }

    private async loadSelectedMonthLockStatus(): Promise<void> {
        try {
            const date = this.currentStartDate();
            if (!date) return;

            const lockStatus = await this.workedHoursService.GetLockStatusForMonth(
                date.getFullYear(),
                date.getMonth() + 1
            );
            this.SelectedMonthIsLocked(lockStatus);
        } catch (e) {
            console.log(e);
        }
    }

    @Delay(300)
    private async debouncedLoad(): Promise<void> {
        await this.load();
    }

    private async load(): Promise<void> {
        this.IsLoading(true);

        try {
            this.currentMonthHours = await this.workedHoursService.GetMonthlyHours(
                this.currentStartDate(),
                this.currentEndDate,
                this.ResourcesTextFilter(),
                this.SelectedGroups()
            );
            this.renderMonthlyReport();
        } catch (e) {
            console.log(e);
        }

        this.IsLoading(false);
    }

    public filterSelectedDays(): void {
        this.DataLoaded(false);

        this.ShowOnlySelectedDays(!this.ShowOnlySelectedDays());

        const showOnlySelected = this.ShowOnlySelectedDays();
        if (showOnlySelected) {
            this.selectedColumnsCache = this.SelectedDates();
            this.Dates(this.selectedColumnsCache);
        } else {
            this.updateDates(this.currentStartDate(), this.currentEndDate);

            this.AllDaysSelected(false);

            this.Dates().forEach((d) => {
                const date = this.selectedColumnsCache.filter((cd) => cd.Day == d.Day);
                if (date.length > 0) d.Selected(true);
            });

            this.selectedColumnsCache = [];
        }

        this.renderMonthlyReport();
        this.DataLoaded(true);
    }

    updateDates(startDate: Date, endDate: Date): void {
        const dates: ISelectableDate[] = [];
        const currentDate = moment(startDate).clone();
        const lastDate = moment(endDate).clone();

        while (currentDate < lastDate) {
            dates.push({
                Date: currentDate.toDate(),
                Day: currentDate.date(),
                IsHoliday: currentDate.isoWeekday() >= 6, //Sabato e Domenica
                Selected: ko.observable(true),
                SwitchSelectedState: function () {
                    this.Selected(!this.Selected());
                },
            });
            currentDate.add(1, "day");
        }

        this.Dates(dates);
    }

    createTask(hour: IMonthlyHour, jobOrder: JobOrderApprovalViewModel): TaskApprovalViewModel {
        return new TaskApprovalViewModel(hour, this.Dates(), jobOrder, this);
    }

    createJobOrder(hour: IMonthlyHour, resource: ResourceApprovalViewModel): JobOrderApprovalViewModel {
        return new JobOrderApprovalViewModel(hour, this.Dates(), resource, this);
    }

    createNewResource(hour: IMonthlyHour): ResourceApprovalViewModel {
        return new ResourceApprovalViewModel(hour, this.Dates(), this);
    }

    onItemSelected(sender: IDataSource, model: INavigationMenuComponentModel): void {
        const month = model as IMonthNavigationMenuComponentModel;
        const date = moment().year(month.year).month(month.month).date(1);

        if (this.currentMonth && this.currentMonth != month.month) this.collapsedStateCache = {};

        this.currentMonth = month.month;

        location.href = "#/WorkedHours/Approval/" + date.format("DDMMYYYY");
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    public async approve(): Promise<void> {
        const resources = this.Resources();

        const selectedHours = resources
            .filter((r) => r.level === 0)
            .selectMultiple((r) => r.getSelectedHours())
            .filter(
                (h) =>
                    HoursApprovalStateUtilities.isUnmanagedWorkedHoursRow(h.ApprovalState) ||
                    h.ApprovalState === HoursApprovalState.PartiallyApproved
            );

        const approvalSummary = new HoursApprovalSummary({ hours: selectedHours });
        await this.dialogsService.ShowModal(approvalSummary);
    }

    public async showWorkedHoursEditorDialog(
        resourceId: number,
        workDate: Date,
        jobOrderId: number = null,
        taskId: number = null,
        hoursId: number = null
    ): Promise<void> {
        const someHasChanged = await this.workedHoursService.ShowWorkedHoursEditorDialog(
            resourceId,
            workDate,
            jobOrderId,
            taskId,
            hoursId
        );

        if (someHasChanged) {
            await this.load();
        }
    }

    public cacheRowSelection(key: string, value: boolean): void {
        this.selectedRowsCache[key] = value;
    }

    public getRowSelectionStateFromCache(key: string) {
        return this.selectedRowsCache[key] ?? false;
    }

    public cacheCollapsedState(key: string, value: boolean): void {
        this.collapsedStateCache[key] = value;
    }

    public getCollapsedStateFromCache(key: string) {
        return this.collapsedStateCache[key];
    }

    private renderMonthlyReport() {
        let lastResourceId = -1;
        let lastJobOrderId = -1;
        let lastTaskId = -1;

        let lastResource: ResourceApprovalViewModel = null;
        let lastJobOrder: JobOrderApprovalViewModel = null;
        let lastTask: TaskApprovalViewModel = null;

        const resources: ResourceApprovalViewModel[] = [];

        for (const hour of this.currentMonthHours) {
            if (lastResourceId != hour.ResourceId) {
                if (lastJobOrder) {
                    if (lastTask) this.removeTaskFromJobOrderIfEmpty(lastJobOrder, lastTask);

                    this.removeJobOrderFromResourceIfEmpty(lastResource, lastJobOrder);
                }

                lastResource = this.createNewResource(hour);
                lastResourceId = hour.ResourceId;

                resources.push(lastResource);

                lastJobOrderId = -1;
                lastTaskId = -1;
            }

            if (lastJobOrderId != hour.JobOrderId) {
                if (lastJobOrder) {
                    if (lastTask) this.removeTaskFromJobOrderIfEmpty(lastJobOrder, lastTask);

                    this.removeJobOrderFromResourceIfEmpty(lastResource, lastJobOrder);
                }

                lastJobOrder = this.createJobOrder(hour, lastResource);
                lastJobOrderId = hour.JobOrderId;
                lastTaskId = -1;

                lastResource.Children.push(lastJobOrder);
            }

            if (lastTaskId != hour.TaskId) {
                this.removeTaskFromJobOrderIfEmpty(lastJobOrder, lastTask);

                lastTaskId = hour.TaskId ?? -1;

                if (hour.TaskId) {
                    lastTask = this.createTask(hour, lastJobOrder);
                    lastJobOrder.Children.push(lastTask);
                } else {
                    lastTask = null;
                }
            }

            if (lastTask && hour.TaskId) lastTask.addHour(hour);

            lastJobOrder.addHour(hour);
            lastResource.addHour(hour);
        }

        if (lastTask && lastJobOrder) this.removeTaskFromJobOrderIfEmpty(lastJobOrder, lastTask);

        if (lastJobOrder) this.removeJobOrderFromResourceIfEmpty(lastResource, lastJobOrder);

        let flattenArray = [];
        for (const resource of resources) {
            resource.initialize();
            flattenArray.push(resource);
            const jobOrders = resource.Children();
            if (jobOrders.length > 0) {
                for (const jo of jobOrders) {
                    flattenArray.push(jo);
                    const tasks = jo.Children();
                    if (tasks.length > 0) flattenArray = flattenArray.concat(jo.Children());
                }
            }
        }

        this.Resources(flattenArray);
    }

    private removeTaskFromJobOrderIfEmpty(lastJobOrder: JobOrderApprovalViewModel, lastTask: TaskApprovalViewModel) {
        if (!this.ShowOnlySelectedDays()) return;

        if (!this.taskHasHoursInSelectedDays(lastTask)) {
            const taskIndex = lastJobOrder.Children.indexOf(lastTask);
            if (taskIndex >= 0) lastJobOrder.Children.splice(taskIndex, 1);
        }
    }

    private removeJobOrderFromResourceIfEmpty(
        resource: ResourceApprovalViewModel,
        jobOrder: JobOrderApprovalViewModel
    ): void {
        if (!this.ShowOnlySelectedDays()) return;

        const validTasks = jobOrder.Children().filter((t) => this.taskHasHoursInSelectedDays(t));

        if (validTasks.length == 0) {
            const jobOrderIndex = resource.Children.indexOf(jobOrder);

            if (jobOrderIndex >= 0) resource.Children.splice(jobOrderIndex, 1);
        }
    }

    private taskHasHoursInSelectedDays(lastTask: TaskApprovalViewModel) {
        if (!lastTask) return false;

        const taskHours = lastTask.Hours();
        return taskHours.filter((h: HourApprovalViewModel) => h.HasHours()).length > 0;
    }

    private createHoursContainerDataModel(hoursContainer: IHoursContainer): IDataSourceModel<number, IHoursContainer> {
        return {
            id: hoursContainer.id,
            title: hoursContainer.name,
            isGroup: false,
            isLeaf: true,
            model: hoursContainer,
        };
    }

    private renderTable() {
        const hoursContainer: IDataSourceModel<number, IHoursContainer> = null;

        return (
            <Table
                dataSource={{ array: this.Resources, factory: this.createHoursContainerDataModel.bind(this) }}
                systemScrollable
                selectRowsByCheckbox
                fixedLayout
                bordered
                rowAs="hoursContainer"
                stickyColumns={1}
                components={{
                    rowDecorator: (item, rowRenderer) => {
                        return item.Data.model.type === "resource" ? (
                            rowRenderer()
                        ) : (
                            <If condition={item.Data.model.IsVisible}>{() => rowRenderer()}</If>
                        );
                    },
                    row: (props: { item: ITableItem<IHoursContainer>; children: React.ReactNode }) => (
                        <tr
                            data-bind={{
                                visible: hoursContainer.model.IsVisible,
                                css: {
                                    active: hoursContainer.model.HasSelectedHours,
                                    opened: !hoursContainer.model.Collapsed(),
                                },
                            }}
                            className={ComponentUtils.classNames({
                                resource: props.item.Data.model.type === ProlifeSdk.HumanResourceEntityTypeCode,
                                joborder: props.item.Data.model.type === ProlifeSdk.JobOrderEntityTypeCode,
                                task: props.item.Data.model.type === ProlifeSdk.JobOrderTaskEntityTypeCode,
                            })}>
                            {props.children}
                        </tr>
                    ),
                }}>
                <Column className="resource-column">
                    <ColumnHeader>
                        {() => (
                            <div
                                style={{
                                    height: "calc(100% + 2px)",
                                    width: "calc(100% + 2px)",
                                    border: "1px solid #ddd",
                                    padding: "5px",
                                    position: "relative",
                                    top: "1px",
                                    left: "-1px",
                                }}>
                                <div className="text-right">
                                    <CheckBox
                                        labelCss="all-days-select"
                                        checked={this.AllDaysSelected}
                                        label={TextResources.WorkedHours.HoursApprovalSelectAllDays}
                                        reverse
                                    />
                                </div>
                                <div>
                                    <CheckBox
                                        checked={this.AllActivitiesSelected}
                                        label={TextResources.WorkedHours.HoursApprovalResourceColumn}
                                    />
                                </div>
                            </div>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(resourceTableItem: ITableItem<IHoursContainer>) => (
                            <div
                                className="flex-container item"
                                style={{ paddingLeft: 5 + 25 * resourceTableItem.Data.model.level + "px" }}
                                onClick={resourceTableItem.Data.model.toggleCollapsed.bind(
                                    resourceTableItem.Data.model
                                )}>
                                <div>
                                    <If condition={resourceTableItem.Data.model.IsSelectable}>
                                        {() => <CheckBox checked={resourceTableItem.Data.model.Selected} />}
                                    </If>
                                </div>
                                {resourceTableItem.Data.model.action && (
                                    <div>{resourceTableItem.Data.model.action()}</div>
                                )}
                                <div className="flex-fill elem-title" title={resourceTableItem.Data.model.name}>
                                    <span className="name">{resourceTableItem.Data.model.name}</span>
                                    {resourceTableItem.Data.model.info && (
                                        <small className="text-muted">{resourceTableItem.Data.model.info}</small>
                                    )}
                                </div>
                                <div className="alerts text-right">
                                    {resourceTableItem.Data.model.alerts && resourceTableItem.Data.model.alerts()}
                                </div>
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-angle-right": hoursContainer.model.Collapsed,
                                            "fa-angle-down": !hoursContainer.model.Collapsed(),
                                        },
                                        visible: hoursContainer.model.CanExpand,
                                    }}></i>
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                {(this.Dates() || []).map((date: ISelectableDate) => (
                    <Column className="date-column text-center">
                        <ColumnHeader>
                            {() => (
                                <div
                                    style={{
                                        height: "calc(100% + 2px)",
                                        borderTop: "1px solid #ddd",
                                        borderBottom: "1px solid #ddd",
                                        padding: "5px",
                                        position: "relative",
                                        top: "-1px",
                                    }}>
                                    <CheckBox checked={date.Selected} />
                                    <span
                                        className={ComponentUtils.classNames("day", { "text-danger": date.IsHoliday })}>
                                        {date.Day}
                                    </span>
                                </div>
                            )}
                        </ColumnHeader>
                        <ColumnBody>
                            {(item: ITableItem<IHoursContainer>) => this.renderHoursCell(item.Data.model, date)}
                        </ColumnBody>
                    </Column>
                ))}
            </Table>
        );
    }

    private renderHoursCell(hc: IHoursContainer, date: ISelectableDate) {
        const hours = hc.Hours();
        const hour = hours.firstOrDefault((h) => h.Day === date.Day);

        const resHour: HourApprovalViewModel = null;

        return (
            <With data={hour} as="resHour">
                {() => (
                    <div
                        className="flex-container flex-vertical cell"
                        data-bind={{
                            if: resHour.HasHours,
                            dblClick: resHour.showWorkedHoursEditorDialog.bind(resHour),
                            css: {
                                active: resHour.SelectedByColumn,
                                selected: resHour.Selected() && resHour.HasHours(),
                            },
                        }}>
                        <span
                            data-bind={{
                                numberText: resHour.Amount,
                                css: { "text-danger": resHour.IsHoliday },
                            }}></span>
                        <div className="approval-icons">
                            <i
                                className="fa fa-check"
                                data-bind={{ css: { enabled: resHour.HasApprovedHours } }}
                                title={TextResources.WorkedHours.HoursApprovalApprovedTooltip}></i>
                            <i
                                className="fa fa-times"
                                data-bind={{ css: { enabled: resHour.HasRejectedHours } }}
                                title={TextResources.WorkedHours.HoursApprovalRejectedTooltip}></i>
                            <i
                                className="fa fa-exclamation-circle"
                                data-bind={{ css: { enabled: resHour.HasUnmanagedHours } }}
                                title={TextResources.WorkedHours.HoursApprovalUnmanagedTooltip}></i>
                        </div>
                    </div>
                )}
            </With>
        );
    }

    render() {
        const panel = this;

        return ComponentUtils.bindTo(
            <Application>
                <Application.LeftMenu>
                    <NavigationMenu wrapperClassName="page-sidebar" dataSource={this.DateDataSource} listener={this} />
                </Application.LeftMenu>
                <Application.Content>
                    <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]} className={classes.hoursApprovalPanel}>
                        <Layout.Grid.Cell row={1} column={1} className="flex-vertical">
                            <div className="page-title">{TextResources.WorkedHours.HoursApprovalTitle}</div>
                            <div className="page-bar flex-container">
                                <div className="flex-container flex-fill filters">
                                    <div className="filter-wrapper">
                                        <TextInput
                                            value={this.ResourcesTextFilter}
                                            simple
                                            placeholder={TextResources.Users.ResourcesSearchPlaceholder}
                                            valueUpdate="afterkeydown"
                                        />
                                    </div>
                                    <div className="filter-wrapper">
                                        <HoursApprovalGroupsSelector
                                            value={this.SelectedGroups}
                                            noLabel
                                            readOnly={ko.computed(() => !this.EnableGroupsSelector())}
                                        />
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        className="btn"
                                        data-bind={{
                                            css: {
                                                "btn-danger": panel.SelectedMonthIsLocked,
                                                "btn-primary": !panel.SelectedMonthIsLocked(),
                                            },
                                            attr: { title: panel.LockButtonTitle },
                                        }}
                                        onClick={this.switchMonthLockStatus.bind(this)}>
                                        <i
                                            className="fa"
                                            data-bind={{
                                                css: {
                                                    "fa-unlock": panel.SelectedMonthIsLocked,
                                                    "fa-lock": !panel.SelectedMonthIsLocked(),
                                                },
                                            }}></i>
                                        &nbsp;<span data-bind={{ text: panel.LockButtonLabel }}></span>
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        data-bind={{
                                            click: panel.filterSelectedDays.bind(panel),
                                            css: { "btn-primary": panel.ShowOnlySelectedDays },
                                            disable: panel.DisableFilterButton,
                                        }}>
                                        <i className="fa fa-filter"></i>
                                        <span
                                            data-bind={{
                                                text: panel.ShowOnlySelectedDays()
                                                    ? "Mostra tutti"
                                                    : "Mostra solo i selezionati",
                                            }}></span>
                                    </button>
                                    <button
                                        className="btn btn-success"
                                        data-bind={{ click: panel.approve.bind(panel) }}>
                                        <i className="fa fa-check"></i>
                                        {TextResources.WorkedHours.HoursApprovalApproveButton}
                                    </button>
                                </div>
                            </div>
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell row={2} column={1}>
                            <IfNot condition={this.DataLoaded}>{() => <></>}</IfNot>
                            <If condition={this.DataLoaded}>{() => this.renderTable()}</If>
                        </Layout.Grid.Cell>
                    </Layout.Grid>
                </Application.Content>
            </Application>,
            this,
            "panel"
        );
    }
}

interface ICache {
    [key: string]: boolean;
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(HoursApprovalPanelViewModel);
}
