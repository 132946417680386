import * as ko from "knockout";
import { CustomerGroupsDataSource } from "../../../DataSources/CustomerGroupsDataSource";
import { IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IDialog } from "../../../Core/interfaces/IDialogsService";

export class CustomerGroupSelectorDialog implements IDialog {
    templateName: string = "customerGroupSelector";
    templateUrl: string = "prolifesdk/templates/documents";
    title: string = "Selezione Gruppo Clienti";
    modal: {
        close: (result?: any) => void;
    };

    TextFilter : ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } });
    CustomerGroupsDataSource: CustomerGroupsDataSource = new CustomerGroupsDataSource();
    
    constructor(private selectedGroupId : number) {
        this.CustomerGroupsDataSource.addNoSelectionItem("Scontistica Cliente");
        this.CustomerGroupsDataSource.select(selectedGroupId);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if(this.selectedGroupId == model.id) return;
        this.modal.close(model.model);
    }
    onItemDeselected(sender: IDataSource, model: IDataSourceModel<string | number, any, string | number, any>): void {
    }
    close(): void {
        this.modal.close();
    }
    action(): void {
        this.modal.close();
    }
}
