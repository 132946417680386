import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow, ComponentUtils } from "../Core/utils/ComponentUtils";
import { CSSProperties } from "@abstraqt-dev/jsxknockout";

const styleSheet = jss.createStyleSheet({
    segmentedControl: {
        "&.btn-group-xs": {
            marginTop: '0px'
        },
        "& .btn": {
            //backgroundColor: "#4b8df8",
            boxShadow: '0px 0px 0px 1px inset #b3b3b3',
            //color: "#4b8df8",

            "&.blue": {
                backgroundColor: "#4b8df8",
                boxShadow: '0px 0px 0px 1px inset #4b8df8',
                color: "white"
            }
        }
    }
});
const { classes } = styleSheet.attach();

type SegmentInputOption = {
    value: string;
    text: string;
} | {
    icon: string;
    value: string;
}

type SegmentedInputProps = {
    label?: string;
    simple?: boolean;
    size?: "lg" | "md" | "sm" | "xs";
    options: SegmentInputOption[];
    value: ko.Observable<string>;
    itemStyle?: CSSProperties;
    noCircleBtn?: boolean;
    activeClass?: string
}

export function SegmentedInput(props: SegmentedInputProps) {
    const C = require("./SegmentedInput")._SegmentedInput as typeof _SegmentedInput;
    return <C {...props} />;
}

export class _SegmentedInput {
    static defaultProps: Partial<SegmentedInputProps> = {
        size: "xs",
        noCircleBtn: false,
        activeClass: "blue"
    }

    constructor(private props : SegmentedInputProps) {
        
    }

    private onChange(opt: SegmentInputOption) {
        this.props.value(opt.value);
    }

    private wrapWithLabel(toolbar : React.ReactElement) {
        if(this.props.simple)
            return toolbar;

        const classNames = ComponentUtils.classNames("form-group");

        return  <div className={classNames}>
                    {this.props.label && <label className="control-label">{this.props.label}</label>}
                    {toolbar}
                </div>
    }
    
    render() {
        const si = this;
        const opt : { value: string; icon: string; text: string; } = null;
        const classNames = ComponentUtils.classNames(classes.segmentedControl, "btn-group", "btn-group-" + this.props.size, "btn-group-solid", {
            "btn-group-circle": !this.props.noCircleBtn
        });

        return ComponentUtils.bindTo(this.wrapWithLabel(
            <div className="btn-toolbar">
                <div className={classNames} data-bind={{ foreach: { data: si.props.options, as: 'opt' }}}>
                    <button type="button" className="btn blue" data-bind={{ css: { 'blue': si.props.value() === opt.value }, click: si.onChange.bind(si, opt) }} style={this.props.itemStyle}>
                        <i data-bind={{ css: opt.icon }}/><span data-bind={{ text: opt.text }}></span>
                    </button>
                </div>
            </div>
        ), this, "si");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(SegmentedInput);
}