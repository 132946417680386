import * as React from "@abstraqt-dev/jsxknockout";
import { PropsWithChildren, ComponentUtils } from "../Core/utils/ComponentUtils";

export interface IDashboardStatProps {
    style?: React.CSSProperties;
    color: "red"|"green"|"blue";
    title: string;
}

export interface IDashboardStatItemProps {
    className?: string;
    desc?: string;
}

export function DashboardStat(props: PropsWithChildren<IDashboardStatProps>) {
    const styles: React.CSSProperties = props.style ?? {};
    styles.height = styles.height ?? "200px";

    const classes = ComponentUtils.classNames("dashboard-stat", {
        "red-intense": props.color === "red",
        "blue-madison": props.color === "blue",
        "green-haze": props.color === "green",
    });

    return (
        <div className={classes} style={styles}>
            <div className="visual"></div>
            <div className="details" style={{ left: "15px", paddingLeft: "15px" }}>
                <div className="number" style={{ borderBottom: "1px solid white" }}>
                    {props.title}
                </div>
                {props.children}
            </div>
        </div>
    );
}

export function DashboardStatItem(props: PropsWithChildren<IDashboardStatItemProps>) {
    return (
        <>
            <div className={props.className}>
                {props.children}
            </div>
            {!!props.desc && <div className="desc">
                {props.desc}
            </div>}
        </>
    );
}