import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/08/2017
 * Time: 17:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { SurveyWizardApplication } from "./SurveyWizard/SurveyWizardApplication";
import { QueueStateSettingManager } from "./SurveyWizard/ui/settings/QueueStateSettingManager";
import { CallResultSettingManager } from "./SurveyWizard/ui/settings/CallResultSettingManager";
import { IncomingCallDialog } from "./SurveyWizard/ui/wizard/dialogs/IncomingCallDialog";
import { LazyImport } from "../Core/DependencyInjection";
import "./SurveyWizard/ui/queue/popover/AttemptsDetailsPopover";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IDialogsService, IPopoverComponentInfo } from "../Core/interfaces/IDialogsService";
import { IService } from "../Core/interfaces/IService";
import {
    ISurveyWizardService,
    IContactsToCallNumbers,
} from "../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IChangesNotificationsService } from "../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { ICampaignFilters, ICampaignForWizardSplash } from "../ProlifeSdk/interfaces/survey/ICampaign";
import { ICampaignIdentifier, ICampaignEnvelope } from "../ProlifeSdk/interfaces/survey-wizard/ICampaignIdentifier";
import { IContactForListFilters, IContactForList } from "../ProlifeSdk/interfaces/survey-wizard/IContactForList";
import { IQuestionnaireIdentifier } from "../ProlifeSdk/interfaces/survey-wizard/IQuestionnaireIdentifier";
import { IAnswer } from "../ProlifeSdk/interfaces/survey/IAnswer";
import { IMotivation } from "../ProlifeSdk/interfaces/survey-wizard/IMotivation";
import {
    ICallResultData,
    ICallInQueueData,
    ICallAttemptDetails,
} from "../ProlifeSdk/interfaces/survey-wizard/ICallData";
import { IQueueStateLabel } from "../ProlifeSdk/interfaces/survey-wizard/IQueueStateLabel";
import { IInterruptedInterviewInfo } from "../ProlifeSdk/interfaces/survey-wizard/IInterruptedInterviewInfo";
import { IAnswerForWizard } from "../ProlifeSdk/interfaces/survey-wizard/IAnswerForWizard";
import { IQueueState } from "../ProlifeSdk/interfaces/survey-wizard/IQueueState";
import { IQueueDetailsForWizard } from "../ProlifeSdk/interfaces/survey-wizard/IQueueElement";
import { IAnswerFromWizard } from "../ProlifeSdk/interfaces/survey-wizard/IAnswerFromWizard";

export class SurveyWizardService extends ProLifeService implements ISurveyWizardService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IDialogsService>())
    dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);

        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<ISurveyWizardService>(), this);
    }

    public getServiceType(): string {
        return ProlifeSdk.SurveyWizardServiceType;
    }

    public isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    public InitializeService(): void {
        super.InitializeService();

        new QueueStateSettingManager(this.serviceLocator);
        new CallResultSettingManager(this.serviceLocator);
        new SurveyWizardApplication(this.serviceLocator);

        var changesNotificationService = <IChangesNotificationsService>(
            this.serviceLocator.findService(ProlifeSdk.ChangesNotificationsServiceType)
        );
        changesNotificationService.RegisterEventHandler("NotifyIncomingCall", this.OnIncomingCall.bind(this));
        changesNotificationService.RegisterEventHandler("NotifyPickupCall", this.OnPickupCall.bind(this));
        changesNotificationService.RegisterEventHandler("NotifyHangupCall", this.OnHangupCall.bind(this));
    }

    notifyRejectedCall(phoneNumber: string, calledPhoneNumber: string, IVRCode: string): Promise<void> {
        return this.ajaxService.Post("Survey-api/SurveyWizardApi", "NotifyRejectedCall", {
            methodData: {
                PhoneNumber: phoneNumber,
                CalledPhoneNumber: calledPhoneNumber,
                IVRCode: IVRCode,
            },
        });
    }

    notifyAcceptedCall(phoneNumber: string, calledPhoneNumber: string, IVRCode: string): Promise<number> {
        return this.ajaxService.Post("Survey-api/SurveyWizardApi", "NotifyAcceptedCall", {
            methodData: {
                PhoneNumber: phoneNumber,
                CalledPhoneNumber: calledPhoneNumber,
                IVRCode: IVRCode,
            },
        });
    }

    openSurveyWizard(
        callId: number,
        phoneNumber: string,
        calledPhoneNumber: string,
        IVRCode: string,
        questionnaireId: number
    ): void {
        location.href =
            "#/" +
            ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl +
            "/Inbound/" +
            callId +
            "/" +
            encodeURIComponent(phoneNumber) +
            "/" +
            encodeURIComponent(calledPhoneNumber) +
            "/" +
            encodeURIComponent(IVRCode) +
            "/" +
            questionnaireId;
    }

    private OnIncomingCall(phoneNumber: string, calledPhoneNumber: string, IVRCode: string) {
        var dialog = new IncomingCallDialog(this.serviceLocator, phoneNumber, calledPhoneNumber, IVRCode);
        dialog.ShowModal();
    }

    private OnPickupCall(phoneNumber: string, calledPhoneNumber: string, IVRCode: string) {}

    private OnHangupCall(phoneNumber: string, calledPhoneNumber: string, IVRCode: string) {}

    getCampaignsForWizardSplash(
        filters: ICampaignFilters,
        skip: number,
        count: number
    ): Promise<ICampaignForWizardSplash[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignsListForWizardSplash", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count,
            },
        });
    }

    getCampaignsListForWizardSplashCount(filters: any, background?: boolean): Promise<number> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignsListForWizardSplashCount", {
            methodData: {
                Filters: filters,
            },
        });
    }

    getCampaignData(campaignId: number): Promise<ICampaignIdentifier> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCampaignData", {
            methodData: {
                Id: campaignId,
            },
        });
    }

    getContactSelectionConfiguration(): Promise<number> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetContactSelectionConfiguration", {});
    }

    getCallsQueueForWizard(campaignId: number, filters: IContactForListFilters): Promise<IContactForList[]> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCallsQueueForWizard", {
            methodData: {
                CampaignId: campaignId,
                Filters: filters,
            },
        });
    }

    getQuestionnaireDataForWizard(questionnaireId: number): Promise<IQuestionnaireIdentifier> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetQuestionnaireDataForWizard", {
            methodData: {
                QuestionnaireId: questionnaireId,
            },
        });
    }

    getCampaignDataForWizard(campaignId: number): Promise<ICampaignEnvelope> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCampaignDataForWizard", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    getPeopleDataForWizard(peopleId: number): Promise<IContactForList> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetPeopleDataForWizard", {
            methodData: {
                PeopleId: peopleId,
            },
        });
    }

    saveWizardStep(
        campaignId: number,
        peopleId: number,
        questionId: number,
        answerTypeId: number,
        answers: IAnswerFromWizard[]
    ): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "SaveWizardStep", {
            methodData: {
                CampaignId: campaignId,
                PeopleId: peopleId,
                QuestionId: peopleId,
                AnswerTypeId: answerTypeId,
                Answers: answers,
            },
        });
    }

    saveInboundWizardStep(callId: number, answers: IAnswer[]): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "SaveInboundWizardStep", {
            methodData: {
                InboundCallId: callId,
                Answers: answers,
            },
        });
    }

    updateInboundCall(callId: number, customerId: number): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "UpdateInboundCall", {
            methodData: {
                CallId: callId,
                CustomerId: customerId,
            },
        });
    }

    getMotivations(type: number): Promise<IMotivation[]> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetMotivations", {
            methodData: {
                Type: type,
            },
        });
    }

    saveCallState(dateTimeToRecall: Date, terminate: boolean, callData: ICallResultData): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "SaveCallState", {
            methodData: {
                DateTimeToRecall: dateTimeToRecall,
                Terminate: terminate,
                Call: callData,
            },
        });
    }

    updateQueueState(queueId: number, newState: number): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "UpdateQueueState", {
            methodData: {
                QueueId: queueId,
                NewState: newState,
            },
        });
    }

    getCallInQueueData(queueId: number): Promise<ICallInQueueData> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCallInQueueData", {
            methodData: {
                QueueId: queueId,
            },
        });
    }

    getQueueStateLabel(logicalState: number): Promise<IQueueStateLabel> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetQueueStateLabel", {
            methodData: {
                LogicalState: logicalState,
            },
        });
    }

    getInterruptedInterviewForUser(campaignId: number): Promise<IInterruptedInterviewInfo> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetInterruptedInterviewForUser", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    deleteQuestionnaireResult(campaignId: number, peopleId: number, answerId: number): Promise<void> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "DeleteQuestionnaireResult", {
            methodData: {
                CampaignId: campaignId,
                PeopleId: peopleId,
                AnswerId: answerId,
            },
        });
    }

    getQuestionAnswersForWizard(
        questionId: number,
        resume: boolean,
        questionnaireId: number,
        campaignId?: number,
        peopleId?: number
    ): Promise<IAnswerForWizard[]> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetQuestionAnswersForWizard", {
            methodData: {
                QuestionId: questionId,
                Resume: resume,
                QuestionnaireId: questionnaireId,
                CampaignId: campaignId || null,
                PeopleId: peopleId || null,
            },
        });
    }

    getCallsInProgressNumber(campaignId: number): Promise<number> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCallsInProgressNumber", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    getTerminatedCallsNumber(campaignId: number): Promise<number> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetTerminatedCallsNumber", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    getNextContactToCall(campaignId: number): Promise<IContactForList> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetNextContactToCall", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    getPreviousCallResult(peopleId: number, campaignId: number): Promise<string> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetPreviousCallResult", {
            methodData: {
                PeopleId: peopleId,
                CampaignId: campaignId,
            },
        });
    }

    getContactsToCallNumber(campaignId: number): Promise<IContactsToCallNumbers> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetContactsToCallNumber", {
            methodData: {
                CampaignId: campaignId,
            },
        });
    }

    getQueueStateLabelByLogicalState(logicalState: number): Promise<IQueueState> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetQueueStateLabelByLogicalState", {
            methodData: {
                LogicalState: logicalState,
            },
        });
    }

    getCallsQueueDetails(
        campaignId: number,
        skip: number,
        count: number,
        showOnlyCallableContacts: boolean
    ): Promise<IQueueDetailsForWizard[]> {
        return this.ajaxService.Post("SurveyWizard-api/SurveyWizardApi", "GetCallsQueueDetails", {
            methodData: {
                CampaignId: campaignId,
                Skip: skip,
                Count: count,
                ShowOnlyCallableContacts: showOnlyCallableContacts,
            },
        });
    }

    async showAttemptsPopOver(attemptsDetails: ICallAttemptDetails[], evt: MouseEvent): Promise<void> {
        const componentInfo: IPopoverComponentInfo = {
            componentName: "surveywizard-queue-attempts-details",
            title: ProlifeSdk.TextResources.SurveyWizard.AttemptsDetail,
            model: {
                AttemptsDetails: attemptsDetails,

                close: function () {
                    this.modal.close(null);
                },
            },
            params: "AttemptsDetails: AttemptsDetails",
        };

        return this.dialogsService.ShowPopoverComponent(evt.currentTarget as HTMLElement, componentInfo, "bottom");
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new SurveyWizardService(serviceLocator);
}
