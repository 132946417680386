import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IDocumentsProvider } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IArticlesService, IDocumentCost } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class CostsOnProtocolsReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;
    @LazyImport(nameof<IDocumentsService>())
    private docsService: IDocumentsService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    Name: string = ProlifeSdk.TextResources.Warehouse.ProtocolsArticlesCosts;
    templateName = "report-list-item";
    templateUrl = "reports/templates";
    private navigator: IReportsNavigator;
    viewModel: any;
    detailsTemplateName = "articles-costs-on-protocol-report";
    detailsTemplateUrl = "warehouse/templates/reports";

    public From: ko.Observable<Date> = ko.observable();
    public To: ko.Observable<Date> = ko.observable();
    public Protocol: ko.Observable<any> = ko.observable(null);

    private lastFrom: Date = null;
    private lastTo: Date = null;
    private lastProtocol = -1;
    private lastProtocolType = "";

    public Protocols: ko.ObservableArray<IDocumentsProvider> = ko.observableArray([]);
    public SelectedProtocolDescription: ko.Computed<string>;

    public JobOrders: ko.ObservableArray<JobOrderGroup> = ko.observableArray([]);
    public Total: ko.Computed<number>;
    public PriceTotal: ko.Computed<number>;

    constructor(groupId: number) {
        super(groupId, 1);

        this.From.subscribe(this.NotifyFilterChange.bind(this));
        this.To.subscribe(this.NotifyFilterChange.bind(this));
        this.Protocol.subscribe(this.NotifyFilterChange.bind(this));

        this.Total = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((d: JobOrderGroup) => {
                total += d.Total;
            });
            return total;
        });

        this.PriceTotal = ko.computed(() => {
            let total = 0;
            this.JobOrders().forEach((d: JobOrderGroup) => {
                total += d.PriceTotal;
            });
            return total;
        });

        this.SelectedProtocolDescription = ko.computed(() => {
            return this.Protocol() ? this.Protocol().Name() : ProlifeSdk.TextResources.Warehouse.SelectProtocol;
        });
    }

    public SetProtocol(protocol: IDocumentsProvider) {
        this.Protocol(protocol);
    }

    public ExportAsPdf(versionId: number) {
        if (!this.Protocol()) return;

        const url = "Warehouse/ProtocolCostReportPrint/GeneratePdf?" + this.getQueryString(versionId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    private getQueryString(versionId: number): string {
        return (
            "versionId=" +
            versionId +
            "&protocolId=" +
            this.lastProtocol +
            "&docType=" +
            this.lastProtocolType +
            "&from=" +
            encodeURIComponent(moment(this.lastFrom).format()) +
            "&to=" +
            encodeURIComponent(moment(this.lastTo).format())
        );
    }

    public ExportAsExcel(versionId: number) {
        if (!this.Protocol()) return;

        const url = "Warehouse/ProtocolCostReportPrint/GenerateExcel?" + this.getQueryString(versionId);

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public RefreshReportData(): Promise<IDocumentCost[]> {
        if (!this.Protocol()) return Promise.resolve<IDocumentCost[]>([]);

        return this.articlesService
            .CalculateProtocolCostReport(this.Protocol().DocumentEntityCode, this.From(), this.To(), this.Protocol().Id)
            .then((documents: IDocumentCost[]) => {
                this.lastFrom = this.From();
                this.lastTo = this.To();
                this.lastProtocol = this.Protocol().Id;
                this.lastProtocolType = this.Protocol().DocumentEntityCode;

                const jobOrdersIds: number[] = [];
                const jobOrdersNames: string[] = [];
                documents.forEach((d: IDocumentCost) => {
                    if (jobOrdersIds.indexOf(d.JobOrderId) == -1) {
                        jobOrdersIds.push(d.JobOrderId);
                        jobOrdersNames.push(d.JobOrderName);
                    }
                });

                const jobOrders: JobOrderGroup[] = jobOrdersIds.map((id: number) => {
                    return new JobOrderGroup(documents, id, jobOrdersNames[jobOrdersIds.indexOf(<any>id)]);
                });

                this.JobOrders(jobOrders);

                return documents;
            });
    }

    initialize() {
        super.initialize();

        this.Protocols(this.docsService.getRegisteredDocumentProviders());

        this.From(moment(new Date()).subtract("month", 1).startOf("month").toDate());
        this.To(moment(new Date()).subtract("month", 1).endOf("month").toDate());
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}

export class JobOrderGroup {
    public Documents: IDocumentCost[] = [];
    public Total = 0;
    public PriceTotal = 0;
    public JobOrderName = "";

    constructor(documents: IDocumentCost[], jobOrderId: number, jobOrderName: string) {
        this.JobOrderName = jobOrderName || ProlifeSdk.TextResources.Warehouse.NoJobOrder;
        this.Documents = documents.filter((d: IDocumentCost) => {
            return d.JobOrderId == jobOrderId;
        });
        this.Documents.forEach((d: IDocumentCost) => {
            this.Total += d.Cost;
            this.PriceTotal += d.Price;
        });
    }
}
