import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { TreeGrid, TreeGridDateTimeColumn, TreeGridStringColumn, TreeGridItem } from "../../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import { ImportFromProjectDialog } from "./ActivitiesTree/ImportFromProjectDialog";
import { CreateOrEditFolderDialog } from "./ActivitiesTree/CreateOrEditFolderDialog";
import { Delay } from "../../../../../Decorators/Delay";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IHumanResourcesSettingsManager } from "../../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../../../../../Users/HumanResourcesService";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IActivityTreeEntry } from "../../../../interfaces/IGridValuesProvider";

export class JobOrderActivitiesTreeFactory implements IJobOrderEditorPanelFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor): IJobOrderEditorPanel {
        return new JobOrderActivitiesTree(serviceLocator, editor, this.Color);
    }

    hasRequiredModules(): boolean {
        return true;
    }

    hasAuthorization(): boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewActivitiesTree");
    }

}
class JobOrderActivitiesTree implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.ActivitiesTree);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "activitiesTree";

    private ajaxService : IAjaxService;
    private settingsManager : ISettingsService;
    private humanResourcesSettingsManager : IHumanResourcesSettingsManager;
    private todoListService : ITodoListService;

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    public ActivityTree : TreeGrid = new TreeGrid();
    public TaskSelected : ko.Computed<boolean>;
    public FolderSelected : ko.Computed<boolean>;

    private priorities : any[] = [
        { id: 0, text: ProlifeSdk.TextResources.JobOrder.Low},
        { id: 1, text: ProlifeSdk.TextResources.JobOrder.Normal},
        { id: 2, text: ProlifeSdk.TextResources.JobOrder.Hi}
    ];
    private reportingTypes : any[] = [
        { id: 0, text: ProlifeSdk.TextResources.JobOrder.InTheBody},
        { id: 1, text: ProlifeSdk.TextResources.JobOrder.Reporting},
        { id: 2, text: ProlifeSdk.TextResources.JobOrder.NoInvoiceable}
    ];

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
        this.settingsManager = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.humanResourcesSettingsManager = <IHumanResourcesSettingsManager> this.settingsManager.findSettingsManager(ProlifeSdk.HumanResources);
        this.todoListService = <ITodoListService> serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        //this.TasksGridProvider = new TasksGridProvider.TasksGridProvider(serviceLocator);

        this.ActivityTree.Columns.push(new TreeGridDateTimeColumn(ProlifeSdk.TextResources.JobOrder.Start, value => value.StartDate, 120));
        this.ActivityTree.Columns.push(new TreeGridDateTimeColumn(ProlifeSdk.TextResources.JobOrder.Expiration, value => value.DueDate, 120));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Duration, value => value.Duration, 120).Format(this.hoursFormatter.bind(this)));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.WorkedHours, value => value.WorkedHours, 120).Format(this.hoursFormatter.bind(this)).Icon("glyphicon glyphicon-time"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Billed, value => value.BilledWorkedHours, 120).Format(this.hoursFormatter.bind(this)).Icon("glyphicon glyphicon-time"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.ToBill, value => value.ToBeBilledWorkedHours, 120).Format(this.hoursFormatter.bind(this)).Icon("glyphicon glyphicon-time"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Progress, value => value.ProgressAvg, 120).Format((value) => String.format("{0}%", value)));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Priority, value => value.Priority, 120).Format((value) => this.priorities[value]));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.InQuality, value => value.InQuality, 120).Format(this.checkFormatter.bind(this)));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.CreatedBy, value => value.CreatedBy, 120).Format(this.createdByFormatter.bind(this)));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.EstimatedBudget, value => value.EstimatedBudget, 120).Format(this.moneyFormatter.bind(this)).Align('right'));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.EstimatedHours, value => value.EstimatedWorkBudget, 120).Format(this.moneyFormatter.bind(this)).Align('right'));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.PurchasesBudget, value => value.EstimatedPurchasesBudget, 120).Format(this.moneyFormatter.bind(this)).Align('right'));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.WarehouseBudget, value => value.EstimatedWarehouseBudget, 120).Format(this.moneyFormatter.bind(this)).Align('right'));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Costs, value => value.PurchasesCosts, 120).Format(this.moneyFormatter.bind(this)).Align('right').Icon("glyphicon glyphicon-shopping-cart"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Revenue, value => value.PurchasesRevenues, 120).Format(this.moneyFormatter.bind(this)).Align('right').Icon("glyphicon glyphicon-shopping-cart"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Billedm, value => value.BilledPurchasesRevenues, 120).Format(this.moneyFormatter.bind(this)).Align('right').Icon("glyphicon glyphicon-shopping-cart"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.ToBill, value => value.ToBeBilledPurchasesRevenues, 120).Format(this.moneyFormatter.bind(this)).Align('right').Icon("glyphicon glyphicon-shopping-cart"));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Workflow, value => value.AssignedWorkflow, 200));
        //this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.WorkflowId, value => value.AssignedWorkflowId, 120));
        this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.ReportingType, value => value.ReportingType, 120).Format(this.reportingTypeFormatter.bind(this)));
        //this.ActivityTree.Columns.push(new TreeGridStringColumn(ProlifeSdk.TextResources.JobOrder.Locked, value => value.Locked, 120));

        this.TaskSelected = ko.computed(() => {
            const item = this.ActivityTree.SelectedItem();
            if(item)
                return !item.CanHaveChildren();
            return false;
        });

        this.FolderSelected = ko.computed(() => {
            const item = this.ActivityTree.SelectedItem();
            if(item)
                return item.CanHaveChildren();
            return false;
        });
    }
    
    onSelect2Cleared?(sender: IDataSource<string | number, any>): void {
        throw new Error("Method not implemented.");
    }
    canSelectItem?(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    canClearSelection?(sender: IDataSource<string | number, any>): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    onNavigate?(sender: IDataSource<string | number, any>, ...path: IDataSourceModel<string | number, any, string | number, any>[]) {
        throw new Error("Method not implemented.");
    }

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    private hoursFormatter(value : number) {
        return String.format(ProlifeSdk.TextResources.JobOrder.DurationFormat, value);
    }

    private checkFormatter(value : boolean) {
        if(value)
            return '<i class="fa fa-bookmark"></i>';
        return '';
    }

    private createdByFormatter(value : number) {
        const user : IHumanResource = this.humanResourcesSettingsManager.getHumanResourceById(value);
        if(user == null)
            return ProlifeSdk.TextResources.JobOrder.System;
        return this.humanResourcesSettingsManager.getFullName(user);
    }

    private moneyFormatter(value : number) {
        return numeral(value).format('0,0.00 $');
    }

    private reportingTypeFormatter(value : number) {
        if(this.reportingTypes[value])
            return this.reportingTypes[value].text;
        return "";
    }

    dispose() {

    }

    @Delay()
    load(): Promise<boolean> {
        //this.TasksGridProvider.setJobOrder(this.editor.JobOrderId);

        this.ajaxService.Post("Todolist-api", "ActivityTree/GetSubTree", {
            methodData : {
                jobOrderId: this.editor.JobOrderId,
                parentId: null
            }
        }).then((data : IActivityTreeEntry[]) => {
            this.ActivityTree.ClearItems();

            data.forEach(item => {
                const newItem = this.ActivityTree.AddItem(item, item.Title);
                newItem.CanHaveChildren(item.IsFolder);
                newItem.OnChildrenNeeded = this.loadChildren.bind(this, newItem);
            });
        });

        return Promise.resolve<boolean>(true);
    }

    private loadChildren(parent : TreeGridItem, childrenLoadedCallback : () => void) {
        this.ajaxService.Post("Todolist-api", "ActivityTree/GetSubTree", {
            methodData : {
                jobOrderId: this.editor.JobOrderId,
                parentId: parent.Value().Id
            }
        }).then((data : any[]) => {
            data.forEach(item => {
                const newItem = parent.AddChild(item, item.Title);
                newItem.CanHaveChildren(item.IsFolder);
                newItem.OnChildrenNeeded = this.loadChildren.bind(this, newItem);
            });

            childrenLoadedCallback();
        });
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        await this.load();
        this.desktopService.SystemHeader.setSidebarVisibility(true);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }
    
    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) { this.load(); }

    OnWorkflowChanged(workflowId:number) { this.load(); }

    OnWorkflowCreated(workflowId:number) { this.load(); }

    OnWorkflowImportedFromTemplate(workflowId:number) { this.load(); }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}

    public AddTask() {
        const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
        if(!selectedItem) return;

        const value = selectedItem.Value();
        this.todoListService.ShowCreateNewTaskDialog(this.editor.JobOrderId, value.AssignedWorkflowId, {
            afterSaveCallback: this.OnTaskAdded.bind(this, value.Id, value.IsFolder)
        });
    }

    private OnTaskAdded(parentId : number, parentIsFolder: boolean, taskId : number) {
        if(parentIsFolder) {
            this.ajaxService.Post("Todolist-api", "ActivityTree/MoveActivitiesAsChildren", {
                methodData: {
                    taskIds: [taskId],
                    jobOrderId: this.editor.JobOrderId,
                    parentId: parentId,
                    isFolder: parentIsFolder
                }
            }).then((tasks : any[]) => {
                const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
                tasks.forEach((task) => {
                    const newItem = selectedItem.AddChild(task, task.Title);
                    newItem.CanHaveChildren(false);
                });
            });
        } else {
            this.ajaxService.Post("Todolist-api", "ActivityTree/MoveActivities", {
                methodData : {
                    taskIds: [taskId],
                    jobOrderId: this.editor.JobOrderId,
                    previousSibling: parentId,
                    previousIsFolder: parentIsFolder,
                    nextSibling: null,
                    nextIsFolder: false
                }
            }).then((tasks : any[]) => {
                const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
                tasks.forEach((task) => {
                    const parent = selectedItem.Parent();
                    let newItem = null;
                    if(parent)
                        newItem = parent.AddChildAfter(task, task.Title, selectedItem);
                    else
                        newItem = this.ActivityTree.AddChildAfter(task, task.Title, selectedItem);
                    newItem.CanHaveChildren(false);
                });
            })
        }
    }

    public EditTask() {
        const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
        if(!selectedItem) return;

        const value = selectedItem.Value();
        this.todoListService.ShowEditTaskDialog(value.Id, {
            afterSaveCallback: this.OnTaskEdited.bind(this, selectedItem),
            afterDeleteCallback: this.OnTaskDeleted.bind(this, selectedItem)
        });
    }

    private OnTaskEdited(item : TreeGridItem, editedTaskId : number) {
        this.ajaxService.Post("Todolist-api", "ActivityTree/GetEntry", {
            methodData: {
                entryId: editedTaskId,
                entryIsFolder: false
            }
        }).then((tasks : any[]) => {
            const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
            tasks.forEach((task) => {
                selectedItem.Title(task.Title);
                selectedItem.Value(task);
            });
        });
    }

    private OnTaskDeleted(item : TreeGridItem, taskId : number) {
        item.Parent().Children.remove(item);
    }

    public CreateFolder() {
        const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
        if(!selectedItem) return;

        let parentId = 0;
        const parent = selectedItem.Parent();
        if(parent) {
            parentId = parent.Value().Id;
        }

        const dialog = new CreateOrEditFolderDialog(this.serviceLocator);
        dialog.ShowCreateDialog(this.editor.JobOrderId, parentId)
            .then((newFolderId : number) => {
                this.ajaxService.Post("Todolist-api", "ActivityTree/GetEntry", {
                    methodData: {
                        entryId: newFolderId[0],
                        entryIsFolder: true
                    }
                }).then((tasks : IActivityTreeEntry[]) => {
                    tasks.forEach((task) => {
                        if(parent) {
                            parent.AddChild(task, task.Title);
                        } else {
                            this.ActivityTree.AddItem(task, task.Title);
                        }
                    });
                });
            })
    }

    public EditFolder() {
        const selectedItem = <TreeGridItem> this.ActivityTree.SelectedItem();
        if(!selectedItem) return;

        const dialog = new CreateOrEditFolderDialog(this.serviceLocator);
        dialog.ShowEditDialog(this.editor.JobOrderId, 0, selectedItem.Value().Id)
            .then((newFolderId : number) => {
                this.ajaxService.Post("Todolist-api", "ActivityTree/GetEntry", {
                    methodData: {
                        entryId: newFolderId[0],
                        entryIsFolder: true
                    }
                }).then((tasks : IActivityTreeEntry[]) => {
                    tasks.forEach((task) => {
                        selectedItem.Title(task.Title);
                        selectedItem.Value(task);
                    });
                });
            })
    }

    public ImportFromProject() {
        const dialog = new ImportFromProjectDialog(this.serviceLocator, this.editor.JobOrderId);
        dialog.showModal();
    }
}

