import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/05/2017
 * Time: 10:10
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import { Moment } from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ResourceOperationalUnitViewModel } from "./ResourceOperationalUnitViewModel";
import { ResourceAllocationRangeViewModel } from "./ResourceAllocationRangeViewModel";
import { IHumanResource, IHumanResourcesService } from "../../../Users/HumanResourcesService";
import { ITeamAllocationViewModel, IResourceOperationalUnitViewModel, ITeamsManagerProvider } from "./TeamsManager";
import { ITeamAllocation, IFullTeamAllocation } from "../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IOperationalUnitsSettingsManager } from "../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { Deferred } from "../../../Core/Deferred";

export class TeamAllocationViewModel implements ITeamAllocationViewModel {
    Id : number;

    ResourceId : ko.Observable<number> = ko.observable();
    ResourceName : ko.Observable<string> = ko.observable();
    ResourceType : ko.Observable<string> = ko.observable();
    TotalAllocatedHours: ko.Computed<number>;
    Deleted : ko.Observable<boolean> = ko.observable(false);

    OperationalUnits : ko.ObservableArray<IResourceOperationalUnitViewModel> = ko.observableArray();
    SelectedOperationalUnit : ko.Observable<IResourceOperationalUnitViewModel> = ko.observable();

    AbsoluteStartDate : ko.Computed<Moment>;
    AbsoluteEndDate : ko.Computed<Moment>;
    HasChanged : ko.Computed<boolean>;
    AllocationLoaded: ko.Computed<boolean>;

    FilteredOperationalUnits: ko.Computed<IResourceOperationalUnitViewModel[]>;
    ShowOverOperationalUnits: ko.Observable<boolean> = ko.observable(false);

    HasAllocatedFutureHours : ko.Computed<boolean>;

    private operationalUnitSelectionInterceptor : ko.Computed<void>;

    private humanResourcesService : IHumanResourcesService;
    private ouSettings : IOperationalUnitsSettingsManager;

    constructor(public serviceLocator : IServiceLocator, private allocation : ITeamAllocation, private teamsManagerProvider: ITeamsManagerProvider) {
        this.humanResourcesService = <IHumanResourcesService> serviceLocator.findService(ProlifeSdk.HumanResourcesServiceType);
        const settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.ouSettings = <IOperationalUnitsSettingsManager>settingsService.findSettingsManager(ProlifeSdk.OperationalUnitsSettingsServiceType);

        this.Id = allocation.Id;
        this.ResourceId(allocation.ResourceId);

        this.AbsoluteStartDate = ko.computed(() => {
            let minDate = moment('2100-01-01');
            this.OperationalUnits().forEach((r : ResourceOperationalUnitViewModel) => {
                minDate = moment(r.AbsoluteStartDate()) < minDate ? moment(r.AbsoluteStartDate()) : minDate;
            });
            return minDate;
        });

        this.AbsoluteEndDate = ko.computed(() => {
            let maxDate = moment('1900-01-01');
            this.OperationalUnits().forEach((r : ResourceOperationalUnitViewModel) => {
                maxDate = moment(r.AbsoluteEndDate()) > maxDate ? moment(r.AbsoluteEndDate()) : maxDate;
            });
            return maxDate;
        });

        this.HasChanged = ko.computed(() => {
            return this.OperationalUnits().filter((r) => r.HasChanged()).length > 0 || !allocation.Id || allocation.Id <= 0;
        });

        this.AllocationLoaded = ko.computed(() => {
            let opUnitsLoaded: boolean = true;
            if (this.OperationalUnits().length == 0)
                return false;
            this.OperationalUnits().forEach((ou: ResourceOperationalUnitViewModel) => {
                opUnitsLoaded = opUnitsLoaded && ou.RangesLoaded();
            });
            return opUnitsLoaded;
        });

        this.HasAllocatedFutureHours = ko.computed(() => {
            let hasAllocatedFutureHours = false;
            const today: Moment = moment().startOf("day");
            this.OperationalUnits().forEach((ou: ResourceOperationalUnitViewModel) => {
                ou.AllocationRanges().forEach((r: ResourceAllocationRangeViewModel) => {
                    if (moment(r.StartDate()) >= today || moment(r.EndDate()) >= today)
                        hasAllocatedFutureHours = true;
                });
            });
            return hasAllocatedFutureHours;
        });

        this.TotalAllocatedHours = ko.computed(() => {
            let total: number = 0;
            this.OperationalUnits().forEach((ou: ResourceOperationalUnitViewModel) => {
                total += ou.AllocatedHours();
            });
            return total;
        });

        this.FilteredOperationalUnits = ko.computed(() => {
            const operationalUnits: IResourceOperationalUnitViewModel[] = this.OperationalUnits();
            return operationalUnits.filter((ou: IResourceOperationalUnitViewModel) => this.ShowOverOperationalUnits() || ou.ServiceOrders().length > 0);
        });

        this.operationalUnitSelectionInterceptor = ko.computed(() => {
            if (this.ShowOverOperationalUnits() && this.FilteredOperationalUnits().length > 0 && !this.SelectedOperationalUnit()) {
                this.SelectOperationalUnit(this.FilteredOperationalUnits()[0]);
                return;
            }

            if (!this.ShowOverOperationalUnits() && this.SelectedOperationalUnit() && this.FilteredOperationalUnits().filter((ou: ResourceOperationalUnitViewModel) => ou.Id == this.SelectedOperationalUnit().Id).length == 0) {
                this.SelectOperationalUnit(null);
            }
        });
    }

    public Load() : Promise<void> {
        return this.InternalLoadResource();
    }

    private InternalLoadResource() : Promise<void> {
        const def = new Deferred<void>();

        this.humanResourcesService.GetHumanResource(this.allocation.ResourceId)
            .then((resource : IHumanResource) => {
                this.ResourceName(this.humanResourcesService.getFullName(resource));
                this.ResourceType(resource.Resource.ResourceType == 0 ? ProlifeSdk.TextResources.Allocations.HumanResource : ProlifeSdk.TextResources.Allocations.MaterialResource);

                const ous = this.ouSettings.getAll(false);
                const ousIds = resource.Orders.map(o => o.FkOperationalUnit);
                const distinctOus = [];
                ousIds.forEach((ouId) => {
                    if(distinctOus.indexOf(ouId) < 0)
                        distinctOus.push(ouId);
                });

                this.OperationalUnits(ous.filter(ou => distinctOus.indexOf(ou.Id) >= 0).map(ou => new ResourceOperationalUnitViewModel(this.serviceLocator, this.allocation, ou, resource.Orders.filter(so => so.FkOperationalUnit == ou.Id), resource, this.teamsManagerProvider)));
                if (this.FilteredOperationalUnits().length > 0)
                    this.SelectOperationalUnit(this.FilteredOperationalUnits()[0]);

                const deferreds : Promise<any>[] = [];
                this.OperationalUnits().forEach((ou : IResourceOperationalUnitViewModel) => deferreds.push(ou.Load()));

                Promise.all(deferreds)
                    .then(() => {
                        def.resolve();
                    })
            });

        return def.promise();
    }

    public SelectOperationalUnit(ou : IResourceOperationalUnitViewModel) {
        this.SelectedOperationalUnit(ou);
    }

    public getData(teamId : number) : IFullTeamAllocation {
        let ranges = [];
        this.OperationalUnits().forEach(ou => ranges = ranges.concat(ou.getData(this.allocation.Id)));

        const alloc : IFullTeamAllocation = {
            Id: this.allocation.Id,
            TeamId: teamId,
            ResourceId: this.ResourceId(),

            AllocationRanges: ranges
        };

        return alloc;
    }

    public Clone(teamsManagerProvider: ITeamsManagerProvider): TeamAllocationViewModel {
        const allocation: TeamAllocationViewModel = new TeamAllocationViewModel(this.serviceLocator, this.allocation, teamsManagerProvider);
        allocation.Id = this.Id;
        allocation.ResourceId(this.ResourceId());
        allocation.ResourceName(this.ResourceName());
        allocation.ResourceType(this.ResourceType());
        allocation.OperationalUnits(this.OperationalUnits().map((ou: IResourceOperationalUnitViewModel) => { return ou.Clone(teamsManagerProvider); }));
        const selectedOu: IResourceOperationalUnitViewModel = this.SelectedOperationalUnit();
        if (selectedOu)
            allocation.SelectedOperationalUnit(allocation.OperationalUnits().filter((ou: IResourceOperationalUnitViewModel) => { return ou.OperationalUnit.Id == selectedOu.OperationalUnit.Id; })[0]);
        return allocation;
    }

    GetOperationalUnit(operationalUnitId : number) : IResourceOperationalUnitViewModel {
        if(!operationalUnitId) return null;
        const ous = this.OperationalUnits().filter((ou : ResourceOperationalUnitViewModel) => ou.OperationalUnit.Id == operationalUnitId);
        if(ous.length == 0) return null;
        return ous[0];
    }

    GetOperationalUnits(): IResourceOperationalUnitViewModel[] {
        return this.OperationalUnits();
    }
}