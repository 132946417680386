import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 09/05/2018
 * Time: 17:39
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import { ITrustMovementsService, ITrustMovementDocumentInfo } from "../../../ProlifeSdk/interfaces/customer/ITrustMovementsService";
import { ITrustMovementViewModel } from "../../interfaces/ITrustMovementViewModel";
import { ITrustMovementRowInfo } from "../../../ProlifeSdk/interfaces/customer/ICustomer";

export class TrustMovementViewModel implements ITrustMovementViewModel {
    public DocumentLabel: ko.Observable<string> = ko.observable();
    public VatRegisterName: ko.Observable<string> = ko.observable();
    public MovementDate: ko.Observable<Date> = ko.observable();
    public ScheduleTitle: ko.Observable<string> = ko.observable();

    public TotalPrice: ko.Observable<number> = ko.observable();
    public MovementRows: ko.ObservableArray<ITrustMovementRowInfo> = ko.observableArray([]);
    public Collapsed: ko.Observable<boolean> = ko.observable(true);
    //public DocumentUrl: ko.Observable<string> = ko.observable("");

    public IsSchedule: ko.Observable<boolean> = ko.observable();
    public ExpiredSchedule: ko.Observable<boolean> = ko.observable();

    public CanShowRows: ko.Computed<boolean>;

    @LazyImport(nameof<ITrustMovementsService>())
    private trustMovementsService: ITrustMovementsService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    constructor(private movement: ITrustMovementDocumentInfo) {
        this.DocumentLabel(this.movement.DocumentLabel + ' ' + this.movement.DocumentNumber);
        this.VatRegisterName(this.movement.VatRegisterName);
        this.MovementDate(this.movement.MovementDate);
        this.ScheduleTitle(this.movement.ScheduleTitle);
        this.TotalPrice(this.movement.TotalPrice);
        this.IsSchedule(this.movement.EntityType == ProlifeSdk.ScheduleEntityTypeCode);
        this.ExpiredSchedule(this.IsSchedule() && moment(this.movement.MovementDate ).startOf("day") < moment().startOf("day"));

        if (this.IsSchedule())
            this.DocumentLabel(ProlifeSdk.TextResources.Customers.Expire + ' ' + this.DocumentLabel());

        let vat = this.createVatUrlPart();
        //this.DocumentUrl("#/" + String.format(ProlifeSdk.TextResources.Invoices.OpenDocumentsURL, vat, this.movement.DocumentId));
        //this.DocumentUrl(this.documentsService.GetOpenDocumentURL(this.movement.EntityType, this.movement.DocumentId));

        this.CanShowRows = ko.computed(() => {
            return !this.IsSchedule();
        });

        this.MovementRows.subscribe((rows: ITrustMovementRowInfo[]) => {
            var sum: number = 0;
            rows.forEach((r: ITrustMovementRowInfo) => sum += (!r.TotalPrice ? 0 : r.TotalPrice));

            if (sum != this.TotalPrice()) {
                this.TotalPrice(sum);
                this.movement.TotalPrice = sum;
            }
        });
    }

    public OpenDocument() : Promise<void> {
        return this.documentsService.OpenDocumentOverlay(this.movement.EntityType, this.movement.DocumentId);
    }

    public SwitchCollapsed(): void {
        if (this.MovementRows().length > 0) {
            this.Collapsed(!this.Collapsed());
            return;
        }

        this.LoadMovementRows()
            .then(() => {
                this.Collapsed(!this.Collapsed());
            });
    }

    public LoadMovementRows():Promise<ITrustMovementRowInfo[]> {
        return this.trustMovementsService.GetMovementRows(this.movement.DocumentId, this.movement.EntityType)
            .then((movementRows: ITrustMovementRowInfo[]) => {
                this.MovementRows(movementRows);
                return movementRows;
            });
    }

    private createVatUrlPart(): string {
        switch (this.movement.EntityType) {
            case ProlifeSdk.CustomerOrderEntityTypeCode: {
                return  "Cor" + this.movement.VatRegisterId
            }
            case ProlifeSdk.SalEntityTypeCode: {
                return  "Sal" + this.movement.VatRegisterId
            }
            default: {
                return  "Vat" + this.movement.VatRegisterId
            }
        }
    }
}