import ko = require("knockout");
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IJobOrderDataSourceModel, JobOrdersDataSource } from "../../../DataSources/JobOrdersDataSource";
import { ITasksDataSourceModel, TasksDataSource } from "../../../DataSources/TasksDataSource";
import { IWorkflowsDataSourceModel, WorkflowsDataSource } from "../../../DataSources/WorkflowsDataSource";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { IFullTicketTasks } from "../../TicketsService";

export class TicketTask implements IDataSourceListener {  
    @LazyImport(nameof<IUserInfo>())
    private userInfo: IUserInfo;

    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;
    validator: IValidator<TicketTask>;

    public FkTicket: number;
    public FkJobOrder: ko.Observable<number> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public FkWorkflow: ko.Observable<number> = ko.observable();
    public FkTask: ko.Observable<number> = ko.observable();
        
    public TasksDataSource: TasksDataSource;
    public WorkflowsDataSource: WorkflowsDataSource;
    public JobOrdersDataSource: JobOrdersDataSource;

    constructor(item: IFullTicketTasks = null){
        let userId = this.userInfo.getIdUser();
        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setUserId(userId);
        this.JobOrdersDataSource.setShowClosed(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);
        this.JobOrdersDataSource.setWorkFilters(true);

        this.WorkflowsDataSource = new WorkflowsDataSource();
        this.WorkflowsDataSource.setUserId(userId);
        this.WorkflowsDataSource.setViewFilters(true, true, true);
        this.WorkflowsDataSource.setWorkFilters(true);
        this.WorkflowsDataSource.setGroupedModeEnabled(false);

        this.TasksDataSource = new TasksDataSource();
        this.TasksDataSource.setUserId(userId);
        this.TasksDataSource.setGetTasksIfNoFilter(true);
        this.TasksDataSource.setGetWorkflowsInWorkflows(false);
        this.TasksDataSource.setShowClosed(false);
        this.TasksDataSource.setViewAll(true);

        this.validator = this.validationService.createValidator<TicketTask>()
            .isNotNullUndefinedOrLessOrEqualZero(t => t.FkJobOrder(), TextResources.Tickets.SelectAJobOrder)
            .isNotNullUndefinedOrLessOrEqualZero(t => t.FkWorkflow(), TextResources.Tickets.SelectAWorkflow);

        if (item) {
            this.FkTicket = item.FkTicket;
            this.JobOrderName(item.JobOrderName);
            this.loadData(item);
        }
    }

    async loadData(item) {
        await this.JobOrdersDataSource.selectByIds(item.FkJobOrder);
        await this.WorkflowsDataSource.select({id: item.FkWorkflow, isGroup: false, isLeaf: true, title: ""});
        this.FkTask(item.FkTask);
    }

    onItemSelected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.JobOrdersDataSource){
            let jobOrderModel = model as IJobOrderDataSourceModel;
            if (jobOrderModel){
                this.JobOrderName(jobOrderModel.model.Name);
                this.WorkflowsDataSource.setJobOrders(...[jobOrderModel.model.Id]);
                this.TasksDataSource.setJobOrderId(jobOrderModel.model.Id);
            }
        }
        if (sender === this.WorkflowsDataSource){
            let workflowModel = model as IWorkflowsDataSourceModel;
            if (workflowModel){
                this.TasksDataSource.setWorkflows([workflowModel.model.Id]);
            }
        }
        if (sender === this.TasksDataSource){
            let taskModel = model as ITasksDataSourceModel;
            if (taskModel){
                this.WorkflowsDataSource.setJobOrders(taskModel.model.WorkflowId);
                this.WorkflowsDataSource.select({id: taskModel.model.WorkflowId, isGroup: false, isLeaf: true, title: ""});
                this.JobOrdersDataSource.selectByIds(taskModel.model.JobOrderId);
            }
        }
    }
    onItemDeselected(sender: IDataSource<string | number, any>, model: IDataSourceModel<string | number, any, string | number, any>): void {
        if (sender === this.JobOrdersDataSource){
            this.WorkflowsDataSource.setJobOrders(null);
            this.TasksDataSource.setJobOrderId(null);
        }
        if (sender === this.WorkflowsDataSource){
            this.TasksDataSource.setWorkflows(null);
        }
    }
}