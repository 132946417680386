import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IService } from "../Core/interfaces/IService";
import { IUserSession } from "./interfaces/IUserSession";

export class UserSessionService implements IUserSession
{
	constructor(private serviceLocator : IServiceLocator){
		serviceLocator.registerServiceInstance(this);

        this.SetBrowserTabId();

        $(window).on('storage', function (e) {
            if(e.originalEvent.oldValue == e.originalEvent.newValue)
                return;

            if((e.originalEvent.key == "sessionToken" && localStorage.getItem("sessionToken_onChangeTabHasRaised") != this.GetBrowserTabId())
                ||
                (e.originalEvent.key == "selectedCompany") && localStorage.getItem("selectedCompany_onChangeTabHasRaised") != this.GetBrowserTabId())
            {
                location.reload();
            }
        }.bind(this));
	}

    private GetNewGuid() : string
    {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
            this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    private s4()
    {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    SetBrowserTabId()
    {
        sessionStorage.setItem("browserTabId", this.GetNewGuid());
    }

    GetBrowserTabId() : string
    {
        return sessionStorage.getItem("browserTabId");
    }

	getServiceType(): string {
		return ProlifeSdk.UserSessionServiceType;
	}

	isOfType(serviceType: string): boolean {
		return serviceType == this.getServiceType();
	}

	SetCurrentCompany(guid:string){
        localStorage.setItem("selectedCompany_onChangeTabHasRaised", this.GetBrowserTabId());

		if(guid == null)
			localStorage.removeItem("selectedCompany");
		else
            localStorage.setItem("selectedCompany", guid);
	}

	GetCurrentCompany() :string{
		return localStorage.getItem("selectedCompany");
	}

	SetLoggedUser(user:string){
		if(user == null)
            localStorage.removeItem("loggedUser");
		else
            localStorage.setItem("loggedUser", user);
	}

	SetLoggedUserRights(rights:string){
		if(rights == null)
            localStorage.removeItem("rights");
		else
            localStorage.setItem("rights", rights);
	}

	GetLoggedUser():string{
		return localStorage.getItem("loggedUser");
	}

	GetSessionToken() : string {
		return localStorage.getItem("sessionToken");
	}

	SetSessionToken(sessionToken : string) {

        localStorage.setItem("sessionToken_onChangeTabHasRaised", this.GetBrowserTabId());

		if(sessionToken == null)
            localStorage.removeItem("sessionToken");
		else
            localStorage.setItem("sessionToken", sessionToken);
	}
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new UserSessionService(serviceLocator);
}