import * as ko from "knockout";
﻿/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 24/09/13
 * Time: 15.07
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IFileRepositoryService } from "../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IFileOrFolder } from "../../ProlifeSdk/interfaces/files/IFileOrFolder";
import { IAjaxServiceNew } from "../../Core/interfaces/IAjaxService";
import { LazyImport } from "../../Core/DependencyInjection";

export class ImageEditorDialog implements IDialog {
    Canvas : ko.Observable<any> = ko.observable();

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew : IAjaxServiceNew;

    templateName: string = "file-repository-image-editor-dialog";
    templateUrl: string = "fileRepository/templates";
    title: string = ProlifeSdk.TextResources.FileRepository.ModifyImage;
    modal: {
        close: (result?: any) => void;
    };

    private fileRepository : IFileRepositoryService;
    private dialogsService : IDialogsService;
    private fileUrl : string;
    private canvas : fabric.Canvas;


    constructor(private serviceLocator : IServiceLocator, private file : IFileOrFolder) {
        this.fileRepository = <IFileRepositoryService> serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.fileUrl = this.fileRepository.getDownloadUrl(file, true);

        this.Canvas.subscribe(this.initialize.bind(this));
    }

    private async initialize() {
        this.canvas = this.Canvas();
        //Funzione deprecata ma ancora presente nella nostra libreria
        (<any>this.canvas).backgroundImageStretch = false;

        this.canvas.setBackgroundImage(this.fileUrl, () => {
            this.canvas.setDimensions({
                width: (<fabric.Image>this.canvas.backgroundImage).width,
                height: (<fabric.Image>this.canvas.backgroundImage).height
            });
            this.canvas.isDrawingMode = true;
            this.canvas.freeDrawingBrush.width = 2;
            this.canvas.renderAll();
        });
    }

    close(): void {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.FileRepository.SureCancelImageChanges,
            ProlifeSdk.TextResources.FileRepository.Cancel,
            ProlifeSdk.TextResources.FileRepository.SureCancelImageChangesConfirm, this.onClose.bind(this));
    }

    private onClose(result : boolean) {
        if(!result) return;
        this.canvas.dispose();
        this.modal.close();
    }

    action(): void {
        var finalFile = (<any>this.canvas.toDataURL)("image/jpeg", 0.8);
        var blob = this.dataURItoBlob(finalFile);

        var result = {}
        result[this.file.Name] = blob;

        this.canvas.dispose();
        this.modal.close(result);
    }

    private dataURItoBlob(dataURI) : Blob {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var dataView = new DataView(ab);
        return new Blob([dataView], { 'type': mimeString });
    }

    setColor(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        var color = $(event.target).css('color');
        this.canvas.freeDrawingBrush.color = color;
    }

    setSmallSize(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.freeDrawingBrush.width = 2;
    }

    setMediumSize(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.freeDrawingBrush.width = 5;
    }

    setBigSize(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.freeDrawingBrush.width = 10;
    }

    setPencilBrush(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.isDrawingMode = true;
        this.canvas.selection = true;
        this.canvas.allowTouchScrolling = false;

        var actualColor = this.canvas.freeDrawingBrush.color;
        var actualWidth = this.canvas.freeDrawingBrush.width;

        this.canvas.freeDrawingBrush = new (<any>fabric.PencilBrush)(this.canvas);
        this.canvas.freeDrawingBrush.color = actualColor;
        this.canvas.freeDrawingBrush.width = actualWidth;

        this.canvas.forEachObject((o) => {
            o.hasBorders = o.hasControls = o.selectable = true;
        });
    }

    setMoveMode(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.isDrawingMode = false;
        this.canvas.selection = false;
        this.canvas.allowTouchScrolling = true;

        this.canvas.forEachObject((o) => {
            o.hasBorders = o.hasControls = o.selectable = false;
        });
    }

    setEditMode(item, event : Event) {
        var element = $(event.currentTarget);
        element.siblings(".active").removeClass("active");
        element.addClass("active");

        this.canvas.isDrawingMode = false;
        this.canvas.selection = true;
        this.canvas.allowTouchScrolling = false;

        this.canvas.forEachObject((o) => {
            o.hasBorders = o.hasControls = o.selectable = true;
        });
    }

    deleteSelection() {
        if((<any>this.canvas).getActiveGroup()) {
            (<any>this.canvas).getActiveGroup().forEachObject(this.canvas.remove.bind(this.canvas));
            (<any>this.canvas).discardActiveGroup().renderAll();
        } else {
            this.canvas.remove(this.canvas.getActiveObject());
        }
    }
}