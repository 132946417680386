import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    "priority-marker": {
        width: "15px",
        height: "100%",
        display: "inline-block",
        backgroundColor: "red",
        color: "white"
    }
});
const { classes } = styleSheet.attach();

type PriorityMarkerProps = {
}

export function PriorityMarker(props: PriorityMarkerProps) {
    const C = require("./PriorityMarker")._PriorityMarker as typeof _PriorityMarker;
    return <C {...props} />;
}

export class _PriorityMarker {
    static defaultProps: Partial<PriorityMarkerProps> = {
    }

    constructor(private props : PriorityMarkerProps) {
        
    }
    
    render() {
        return  <span className={ComponentUtils.classNames("text-center", classes["priority-marker"])} title={TextResources.Allocations.CartWithPriority}>
                    <i className="fa fa-arrow-up"></i>
                </span>;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PriorityMarker);
}