import * as ko from "knockout";
import * as moment from "moment";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ProLifeReport } from "../../../../ProlifeSdk/prolifesdk/reports/ProLifeReport";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import {
    IJobOrderService,
    IJobOrdersAndProjectsSearchResultsProvider,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IProjectsService } from "../../../../ProlifeSdk/interfaces/projects/IProjectsService";
import { IDesktopService } from "../../../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IReport } from "../../../../ProlifeSdk/interfaces/report/IReport";
import { IReportViewModel } from "../../../../ProlifeSdk/interfaces/report/IReportViewModel";
import { IWorkTimeCategoriesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategoriesSettingsManager";
import { ICallRightTypesSettingsManager } from "../../../../ProlifeSdk/interfaces/worked-hours/ICallRightTypesSettingsManager";
import { IReportsNavigator } from "../../../../ProlifeSdk/interfaces/report/IReportsNavigator";
import { IWorkTimeCategory } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkTimeCategory";
import { IWorkedHoursAnomaliesRule } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursAnomaliesRule";
import { IControlsEntityProvider } from "../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../../Core/Deferred";

export class WorkedHoursAnomaliesReport extends ProLifeReport implements IReport, IReportViewModel {
    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;

    @LazyImport(nameof<IDesktopService>())
    desktopService: IDesktopService;

    @LazyImport(nameof<IInfoToastService>())
    toastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    dialogService: IDialogsService;

    @LazyImportSettingManager(ProlifeSdk.WorkTimeCategoriesSettingsServiceType)
    private workTimeCatManager: IWorkTimeCategoriesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.CallRightTypesSettingsManagerType)
    private callRightTypesManager: ICallRightTypesSettingsManager;

    Name: string = ProlifeSdk.TextResources.WorkedHours.WorkedHoursAnomalies;
    templateName: string = "report-list-item";
    templateUrl: string = "reports/templates";
    detailsTemplateName: string = "worked-hours-anomalies-report";
    detailsTemplateUrl: string = "workedhours/templates/reports";
    private navigator: IReportsNavigator;
    viewModel: any;

    Anomalies: ko.ObservableArray<WorkedHoursDateAnomalies> = ko.observableArray([]);
    WorkTimeCategories: IWorkTimeCategory[];
    Rules: ko.ObservableArray<WorkedHoursAnomaliesRule> = ko.observableArray([]);

    LastFrom: Date;
    LastTo: Date;
    From: ko.Observable<Date> = ko.observable();
    To: ko.Observable<Date> = ko.observable();

    constructor(groupId: number) {
        super(groupId, 3);

        this.WorkTimeCategories = this.workTimeCatManager.getAll(false);

        var from = moment(new Date()).date(1);
        var to = moment().add(1, "months").date(1).subtract(1, "days");
        this.From(from.toDate());
        this.To(to.toDate());

        this.LastFrom = this.From();
        this.LastTo = this.To();

        this.From.subscribe(this.NotifyFilterChange.bind(this));
        this.To.subscribe(this.NotifyFilterChange.bind(this));
    }

    public NewRule() {
        this.Rules.splice(0, 0, new WorkedHoursAnomaliesRule(this.WorkTimeCategories, this, null));
    }

    public Save() {
        var thereAreErrors: boolean = false;
        this.Rules().forEach((r: WorkedHoursAnomaliesRule) => {
            if (!thereAreErrors) thereAreErrors = thereAreErrors || !r.Validate();
        });

        if (thereAreErrors) return;

        this.workedHoursService
            .SaveAnomaliesRules(
                this.Rules().map((r: WorkedHoursAnomaliesRule) => {
                    return r.GetData();
                })
            )
            .then(() => {
                this.toastService.Success(ProlifeSdk.TextResources.WorkedHours.AnomaliesSearchRulesUpdated);
            });
    }

    public RefreshReportData(): Promise<void> {
        var def = new Deferred<void>();
        this.LastFrom = this.From();
        this.LastTo = this.To();

        this.workedHoursService
            .GetAnomaliesRules()
            .then((rules: IWorkedHoursAnomaliesRule[]) => {
                this.Rules(
                    rules.map((r: IWorkedHoursAnomaliesRule) => {
                        return new WorkedHoursAnomaliesRule(this.WorkTimeCategories, this, r);
                    })
                );

                if (this.From() && this.To() && this.From() > this.To()) {
                    this.Anomalies([]);
                    this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SpecifyValidDateInterval);
                    return;
                }

                this.workedHoursService.CalculateAnomalies(this.From(), this.To()).then((anomalies) => {
                    var anomaliesArray = [];
                    var dates = [];

                    anomalies
                        .map((a) => {
                            return a.WorkDate;
                        })
                        .forEach((ad: Date) => {
                            var matches = dates.filter((d: Date) => {
                                return d == ad;
                            });

                            if (matches.length == 0) dates.push(ad);
                        });

                    dates.sort(function (a, b) {
                        return a > b ? -1 : a < b ? 1 : 0;
                    });

                    dates.forEach((d: Date) => {
                        var dateAnomalies = anomalies.filter((a) => {
                            return a.WorkDate == d;
                        });
                        anomaliesArray.push(new WorkedHoursDateAnomalies(d, dateAnomalies));
                    });

                    this.Anomalies(anomaliesArray);
                });
            })
            .finally(() => {
                def.resolve();
            });
        return def.promise();
    }

    public InputValidation(): Promise<boolean> {
        var def = new Deferred<boolean>();

        var to = this.To() || new Date();
        if (
            (this.From() != this.LastFrom || this.To() != this.LastTo) &&
            (!this.From() || moment(to).diff(moment(this.From()), "days") > 90)
        ) {
            this.dialogService.Confirm(
                ProlifeSdk.TextResources.WorkedHours.DateIntervalTooBig,
                ProlifeSdk.TextResources.WorkedHours.DoNotProceed,
                ProlifeSdk.TextResources.WorkedHours.Proceed,
                (confirm) => {
                    if (!confirm) {
                        this.From(this.LastFrom);
                        this.To(this.LastTo);
                    }

                    def.resolve(confirm);
                }
            );
        } else def.resolve(true);

        return def.promise();
    }

    initialize() {
        super.initialize();
        this.Refresh();
    }

    Open() {
        this.navigator.openReport(this);
    }

    getViewModel(): IReportViewModel {
        return this;
    }

    setNavigator(navigator: IReportsNavigator) {
        this.navigator = navigator;
    }
}

export class WorkedHoursDateAnomalies {
    WorkDate: Date;
    Resources: WorkedHoursResourceAnomalies[];

    constructor(workDate: Date, anomalies: any[]) {
        this.WorkDate = workDate;
        this.Resources = [];

        var resources = [];

        anomalies
            .map((a) => {
                return a.ResourceId;
            })
            .forEach((ar: number) => {
                var matches = resources.filter((r: number) => {
                    return r == ar;
                });

                if (matches.length == 0) resources.push(ar);
            });

        resources.forEach((id: number) => {
            var resourceAnomalies = anomalies.filter((a) => {
                return a.ResourceId == id;
            });
            var resourceName = (
                (resourceAnomalies[0].ResourceSurname ? resourceAnomalies[0].ResourceSurname + " " : "") +
                (resourceAnomalies[0].ResourceName ? resourceAnomalies[0].ResourceName : "")
            ).trim();
            this.Resources.push(new WorkedHoursResourceAnomalies(id, workDate, resourceName, resourceAnomalies));
        });
    }
}

export class WorkedHoursResourceAnomalies {
    @LazyImport(nameof<IWorkedHoursService>())
    workedHoursService: IWorkedHoursService;

    Rules: string[] = [];
    Name: string;

    constructor(private resourceId: number, private workDate: Date, resourceName: string, anomalies: any[]) {
        this.Name = resourceName;
        anomalies
            .map((a) => {
                return a.RuleTitle;
            })
            .forEach((r: string) => {
                if (this.Rules.indexOf(r) == -1) this.Rules.push(r);
            });
    }

    ShowDetails() {
        window.open(this.workedHoursService.GetWorkSheetUrl(this.resourceId, this.workDate));
    }
}

export class WorkedHoursAnomaliesRule {
    @LazyImport(nameof<IJobOrderService>())
    jobOrderService: IJobOrderService;
    @LazyImport(nameof<IProLifeSdkService>())
    sdkService: IProLifeSdkService;
    @LazyImport(nameof<IProjectsService>())
    projectsService: IProjectsService;
    @LazyImport(nameof<IInfoToastService>())
    toastService: IInfoToastService;
    @LazyImport(nameof<IEntityProviderService>())
    entityProvidersService: IEntityProviderService;

    mainActivitySearchService: IJobOrdersAndProjectsSearchResultsProvider;
    subActivitySearchService: IControlsEntityProvider;

    Title: ko.Observable<string> = ko.observable();
    From: ko.Observable<Date> = ko.observable();
    To: ko.Observable<Date> = ko.observable();
    WorkTimeCategories: IWorkTimeCategory[];
    WorkTimeCategoryId: ko.Observable<number> = ko.observable();
    MainActivity: ko.Observable<any> = ko.observable();
    SubActivity: ko.Observable<any> = ko.observable();

    ShowWi: ko.Computed<boolean>;
    IsNew: boolean;

    constructor(
        workTimeCategories: IWorkTimeCategory[],
        private parent: WorkedHoursAnomaliesReport,
        private ruleData: IWorkedHoursAnomaliesRule
    ) {
        this.WorkTimeCategories = workTimeCategories;

        this.mainActivitySearchService = this.jobOrderService.GetJobOrdersAndProjectsSearchProvider();
        this.subActivitySearchService = this.entityProvidersService
            .getEntityProvider(ProlifeSdk.WorkItemEntityTypeCode)
            .getControlsProvider();

        this.ShowWi = ko.computed(() => {
            return this.mainActivitySearchService.ResolveType(this.MainActivity()) == ProlifeSdk.ProjectEntityTypeCode;
        });

        this.MainActivity.subscribe((v) => {
            var id = this.mainActivitySearchService.ResolveId(v);
            (<any>this.subActivitySearchService).SetProjectFilter(id);
        });

        this.IsNew = !ruleData;
        this.Title(ruleData ? ruleData.Title : "");

        this.From(
            ruleData && ruleData.From
                ? this.sdkService.Utilities.Dates.AddGmtTime(moment(ruleData.From).startOf("day").toDate())
                : null
        );
        this.To(
            ruleData && ruleData.To
                ? this.sdkService.Utilities.Dates.AddGmtTime(moment(ruleData.To).startOf("day").toDate())
                : null
        );
        this.MainActivity(ruleData ? ruleData.MainActivityId + "#" + ruleData.MainActivityType : null);
        this.SubActivity(ruleData ? ruleData.WorkItemId : null);
        this.WorkTimeCategoryId(ruleData ? ruleData.WorkTimeCategoryId : null);
    }

    SetWorkTimeCategory(c: IWorkTimeCategory) {
        this.WorkTimeCategoryId(c.Id);
    }

    Delete() {
        this.parent.Rules.remove(this);
    }

    Validate() {
        if (!this.Title() || this.Title().trim().length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.InsertTitle);
            return false;
        }

        if (!this.WorkTimeCategoryId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectWorktimeCategory);
            return false;
        }

        if (!this.MainActivity()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectJobOrder);
            return false;
        }

        if (!this.SubActivity() && this.ShowWi()) {
            this.toastService.Warning(ProlifeSdk.TextResources.WorkedHours.SelectWorkItem);
            return false;
        }

        return true;
    }

    GetData() {
        var data =
            this.ruleData ||
            <IWorkedHoursAnomaliesRule>{
                WorkTimeCategoryId: 0,
                MainActivityId: 0,
                MainActivityType: "",
            };

        data.Title = this.Title();
        data.WorkTimeCategoryId = this.WorkTimeCategoryId();
        data.MainActivityId = this.mainActivitySearchService.ResolveId(this.MainActivity());
        data.MainActivityType = this.mainActivitySearchService.ResolveType(this.MainActivity());
        data.WorkItemId = this.SubActivity();
        data.From = this.From();
        data.To = this.To();
        return data;
    }
}
