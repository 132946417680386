import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { VatRegistersEditingViewModel } from "./ui/protocols/VatRegistersEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxServiceNew } from "../../../Core/interfaces/IAjaxService";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import {
    IVatRegisters,
    IVatRegister,
    IVatRegistersObserver,
    IVatRegisterImagesInfo,
    IVatRegisterWithPrintTemplates,
    IVatRegisterPrintTemplateInfo,
    IVatRegisterNew,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IProtocolType } from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { ResponseData } from "../../../Core/response/ResponseBase";
import { SaveProtocolError } from "./errors/SaveProtocolError";
import { VatRegistersMapper } from "./VatRegistersMapper";

export class VatRegistersSettingsManager implements IVatRegisters {
    private vatRegisters: IVatRegisterWithPrintTemplates[] = [];
    private observers: IVatRegistersObserver[] = [];

    private customProtocolTypes: IProtocolType[] = [];

    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    constructor() {
        this.settingsService.registerSettingsManager(
            this,
            ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup
        );
    }

    public registerCustomProtocolType(protocolType: IProtocolType) {
        this.customProtocolTypes.push(protocolType);
    }

    public unregisterCustomProtocolType(filter: (IProtocolType) => boolean) {
        const protocolsToRemove = this.customProtocolTypes.filter(filter);
        protocolsToRemove.forEach((pt: IProtocolType) => {
            const index = this.customProtocolTypes.indexOf(pt);
            this.customProtocolTypes.splice(index, 1);
        });
    }

    public getCustomProtocolTypes(): IProtocolType[] {
        return this.customProtocolTypes;
    }

    async load(): Promise<IVatRegisterWithPrintTemplates[]> {
        const vatRegistersResponse = await this.ajaxServiceNew.Get<ResponseData<IVatRegisterNew[]>>(
            "d/protocols",
            "",
            {}
        );

        if (!vatRegistersResponse.succeeded) {
            // Per ora loggo l'errore e ritorno un array vuoto
            console.error(vatRegistersResponse.errors);
            return [];
        }

        const vatRegisters = vatRegistersResponse.data.map(VatRegistersMapper.mapToOldVatRegister);

        for (const register of vatRegisters) {
            register.ProtocolsDefaultMetadatas.sort((a, b) => a.Order - b.Order);
            register.printTemplates = [];
        }

        const printTemplatesInfo = await this.ajaxServiceNew.Get<IVatRegisterPrintTemplateInfo[]>(
            "r/api/DocumentPrintTemplate",
            "getAllAssociations",
            {}
        );

        for (const pi of printTemplatesInfo) {
            const vatRegister = vatRegisters.firstOrDefault((vr) => vr.IdRegistroIVA == pi.vatRegisterId);
            if (!vatRegister) continue;
            vatRegister.printTemplates = pi.printTemplates;
            vatRegister.preferredPrintTemplateId = pi.preferredPrintTemplateId;
        }

        this.vatRegisters = vatRegisters;
        this.settingsService.NotifySettingsUpdated(ProlifeSdk.SettingsUpdateType_DocumentsProtocols);

        return this.vatRegisters.slice();
    }

    getName(): string {
        return ProlifeSdk.VatRegisters;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.ProtocolNumbers;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "vatregister",
            templateUrl: "invoices/templates/settings",
            viewModel: new VatRegistersEditingViewModel(this),
        };
    }

    getVatRegisters(): IVatRegisterWithPrintTemplates[] {
        return this.vatRegisters;
    }

    getVatRegisterById(vatRegisterId: number): IVatRegisterWithPrintTemplates {
        const registers = this.vatRegisters.filter((vatRegister) => vatRegister.IdRegistroIVA == vatRegisterId);
        if (registers.length == 0) return null;
        return registers[0];
    }

    async createOrUpdate(
        vatRegister: IVatRegister,
        imagesInfo?: IVatRegisterImagesInfo,
        printTemplates?: number[],
        preferredPrintTemplateId?: number
    ): Promise<void> {
        let callbackFunc: (vatRegister: IVatRegisterWithPrintTemplates) => void;
        let registerResponse: ResponseData<IVatRegisterNew>;
        try {
            if (vatRegister.IdRegistroIVA > 0) {
                registerResponse = await this.ajaxServiceNew.Put<ResponseData<IVatRegisterNew>>("d/protocols", "", {
                    methodData: vatRegister,
                });
                callbackFunc = this.onVatRegisterChanged.bind(this);
            } else {
                registerResponse = await this.ajaxServiceNew.Post<ResponseData<IVatRegisterNew>>("d/protocols", "", {
                    methodData: vatRegister,
                });
                callbackFunc = this.onVatRegisterAdded.bind(this);
            }

            if (!registerResponse.succeeded) throw new SaveProtocolError(registerResponse.errors ?? []);
        } catch (e) {
            throw new SaveProtocolError(e.errors);
        }

        const register = registerResponse.data;

        if (imagesInfo?.HasCustomLogo && imagesInfo.CustomLogo) {
            const data = { image: imagesInfo.CustomLogo };
            await this.ajaxServiceNew.Upload(
                "d/protocolCustomImages",
                "CustomLogo/" + register.idRegistroIva,
                data,
                false
            );
        }

        if (imagesInfo?.HasHeaderImage && imagesInfo.HeaderImage) {
            const data = { image: imagesInfo.HeaderImage };
            await this.ajaxServiceNew.Upload(
                "d/protocolCustomImages",
                "HeaderImage/" + register.idRegistroIva,
                data,
                false
            );
        }

        if (printTemplates != null) {
            await this.ajaxServiceNew.Post("r/api/DocumentPrintTemplate", "assignTo/" + register.idRegistroIva, {
                methodData: { printTemplates, preferredPrintTemplateId },
            });
        }

        const updatedRegisterResponse = await this.ajaxServiceNew.Get<ResponseData<IVatRegisterNew>>(
            "d/protocols",
            register.idRegistroIva.toString(),
            {}
        );

        if (!updatedRegisterResponse.succeeded) {
            // Per ora loggo l'errore e ritorno un array vuoto
            console.error(updatedRegisterResponse.errors);
            return;
        }

        const updatedRegister = VatRegistersMapper.mapToOldVatRegister(updatedRegisterResponse.data);

        updatedRegister.printTemplates = (printTemplates || []).slice();
        updatedRegister.preferredPrintTemplateId = preferredPrintTemplateId;

        callbackFunc(updatedRegister);
    }

    async remove(vatRegisterId: number): Promise<void> {
        await this.ajaxServiceNew.Delete("d/protocols", vatRegisterId.toString(), {});
        this.onVatRegisterDeleted(vatRegisterId);
    }

    addObserver(observer: IVatRegistersObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IVatRegistersObserver): void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    private onVatRegisterChanged(vatRegister: IVatRegisterWithPrintTemplates) {
        const index = this.getIndexOfVatRegister(vatRegister.IdRegistroIVA);
        this.vatRegisters[index] = vatRegister;
        this.observers.forEach((obs) => obs.onVatRegisterChanged(vatRegister));
    }

    private onVatRegisterAdded(vatRegister: IVatRegisterWithPrintTemplates) {
        this.vatRegisters.push(vatRegister);
        this.observers.forEach((obs) => obs.onVatRegisterAdded(vatRegister));
        this.settingsService.NotifySettingsUpdated(ProlifeSdk.SettingsUpdateType_DocumentsProtocols);
    }

    private onVatRegisterDeleted(vatRegisterId: number) {
        const index = this.getIndexOfVatRegister(vatRegisterId);
        this.vatRegisters.splice(index, 1);
        this.observers.forEach((obs) => obs.onVatRegisterDeleted(vatRegisterId));
        this.settingsService.NotifySettingsUpdated(ProlifeSdk.SettingsUpdateType_DocumentsProtocols);
    }

    private getIndexOfVatRegister(IdRegistroIVA: number): number {
        const item = this.vatRegisters.filter(
            (vatRegister: IVatRegister) => vatRegister.IdRegistroIVA == IdRegistroIVA
        )[0];
        return this.vatRegisters.indexOf(item);
    }
}
