import * as ProlifeSdk from "../../../../ProlifeSdk";
import { BlogEventBaseInput } from "../../../blog/BlogEventBaseInput";
import { DocumentEvent } from "../DocumentEvent";
import { IContextEventsObserver } from "../../../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { ILogEvent } from "../../../../interfaces/ILogEvent";

export class DocumentEventDetailsEdit extends BlogEventBaseInput {
    public templateName = "document-details";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.Document;

    constructor(
        serviceLocator: IServiceLocator,
        contextEventsObserver: IContextEventsObserver,
        protected event: DocumentEvent
    ) {
        super(serviceLocator, contextEventsObserver);
        this.iconName = "f-icon-doc-text";
        this.templateUrl = "prolifesdk/templates/events/details";
        this.setValues(event);
    }

    public setValues(item: DocumentEvent): void {
        super.setValues(item);
        item.LoadDetails();
    }

    getBlogEvent(): ILogEvent {
        const localBlogEvent: ILogEvent = super.getBlogEvent();

        localBlogEvent.Tags.push({
            TagName: ProlifeSdk.Tag_DocumentId,
            Value: this.event.DocumentId(),
            TagTypeId: ProlifeSdk.TagType_Int,
        });

        localBlogEvent.Tags.push({
            TagName: ProlifeSdk.Tag_DocumentTypeCode,
            Value: this.event.DocumentTypeCode(),
            TagTypeId: ProlifeSdk.TagType_String,
        });

        localBlogEvent.Tags.push({
            TagName: ProlifeSdk.Tag_DocumentTypeDescription,
            Value: this.event.DocumentTypeDescription(),
            TagTypeId: ProlifeSdk.TagType_String,
        });

        return localBlogEvent;
    }

    public getValidationMessage(): string[] {
        let message: string[] = super.getValidationMessage();

        if (this.subject() == null || this.subject().trim() == "")
            message = message.concat([ProlifeSdk.TextResources.ProlifeSdk.SubjectRequired]);

        return message;
    }
}
