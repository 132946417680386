import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { IEntityProviderService } from "../../interfaces/IEntityProviderService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ISearchResultsProvider } from "../../interfaces/ISearchResultsProvider";
import { ITag, IEventTag } from "../../interfaces/ITag";
import { IEntitySearchResult } from "../../interfaces/IEntitySearchResult";
import { Deferred } from "../../../Core/Deferred";
import { LazyImport } from "../../../Core/DependencyInjection";

export class CustomTagList implements ISearchResultsProvider{
	@LazyImport(nameof<IInfoToastService>())
	public infoToastService : IInfoToastService;
	@LazyImport(nameof<IEntityProviderService>())
	public entityProviderService : IEntityProviderService;
	
	private lastTimeout : ReturnType<typeof setTimeout>;
	private customTags : ko.Observable<string> = ko.observable(""); //Solo stringhe

	constructor() {}

	private search(searchFilter : string, callback : (data : any) => void)
    {

        var data = { results: [] };
        var providersDef = new Deferred();

        var menuGroup = {
            text : "",
            children : [{
                id : JSON.stringify(<ITag>
                {
                    TagName : ProlifeSdk.Tag_CustomTag,
                    TagTypeId : ProlifeSdk.TagType_String,
                    Value : searchFilter
                }) + '#' + searchFilter,
                text : searchFilter
            }]
        }
        data.results.push(menuGroup);

        this.entityProviderService.search(searchFilter).then((results : IEntitySearchResult[]) => {

            results.forEach((result : IEntitySearchResult) => {

                if(result.entities.length == 0)
                    return;

                menuGroup = {
                    text : result.provider.getEntityTypeName(),
                    children : []
                }

                result.entities.forEach(e => {

                    var displayName  = result.provider.getDisplayName(e);
                    var tag : ITag = <ITag>
                    {
                        TagName : ProlifeSdk.Tag_CustomTag,
                        TagTypeId : result.provider.getType(),
                        Value : result.provider.getPkValue(e)
                    };

                    menuGroup.children.push({
                        id : JSON.stringify(tag) + '#' + displayName,
                        text : displayName
                    });
                });

                data.results.push(menuGroup);
            })
            providersDef.resolve();
        }).catch(() => providersDef.reject());

        Promise.all([providersDef.promise()]).then(() => {
            callback(data);
        });
	}

	private searchById(serializedItem : string)
    {
		var result : any[] = [];
		var tags : string[] = serializedItem.split("|");
		tags.forEach((serializedTag : string) => {
			var tag : string[] = serializedTag.split("#");
			result.push({id: serializedTag,text: tag[1]});
		});

		return result;
	}

	public findSearchMatches(query : any)
    {
		if(this.lastTimeout) {
			clearTimeout(this.lastTimeout);
		}

		this.lastTimeout = setTimeout(() =>
        {
			this.search(query.term, query.callback);
		}, 500);
	}

	public findSearchMatch(element, callback)
    {
		var id = <string> $(element).val();
		if(id !== "") {
			var result = this.searchById(id);
			callback(result);
		}
	}

	public addElements(elements: IEventTag[])
    {
		var result = elements.map((tag : IEventTag) => {return JSON.stringify(tag.getTag()) + "#" + tag.displayName()}).join("|");
		if(this.customTags().length > 0)
			this.customTags(this.customTags() + "|" + result);
		else
			this.customTags(result);
	}

	public getElement() : ITag[]
    {
		if (this.customTags().length ==0)
			return [];

		var tags : string[] = this.customTags().split("|");

		return tags.map((serializedTag : string) => {
			return JSON.parse(serializedTag.split("#")[0]);
		});
	}

	public clear()
    {
		return this.customTags("");
	}
}




