import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { EventSettingsForProtocolDialog } from "./EventSettingsForProtocolDialog";
import { FileRepositorySettingsForProtocolDialog } from "./FileRepositorySettingsForProtocolDialog";
import { AccountingSoftwareRoutingDialog } from "./AccountingSoftwareRoutingDialog";
import { IDocumentsService } from "../../../../DocumentsService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IBlogService } from "../../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IFileRepositoryService } from "../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import {
    IVatRegister,
    IVatRegisterImagesInfo,
} from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IDocumentLayout } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentLayout";

export class VatRegisterAdvancedSettings {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IBlogService>())
    private blogService: IBlogService;
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogService: IDialogsService;

    public HtmlFooter: ko.Observable<string> = ko.observable("");
    public HtmlNotes: ko.Observable<string> = ko.observable("");

    public InsertJobOrderIntoNotes: ko.Observable<boolean> = ko.observable(false);
    public InsertOriginatingDocumentsIntoNotes: ko.Observable<boolean> = ko.observable(false);
    public JobOrderIntoNotesPrefix: ko.Observable<string> = ko.observable();
    public DocumentIntoNotesPrefix: ko.Observable<string> = ko.observable();
    public CustomLogoEnabled: ko.Observable<boolean> = ko.observable(false);
    public CustomHeaderImageEnabled: ko.Observable<boolean> = ko.observable(false);
    public CustomFooterEnabled: ko.Observable<boolean> = ko.observable(false);
    public CustomNotesEnabled: ko.Observable<boolean> = ko.observable(false);

    public ImportCalculatedExpiriesIntoDocumentNotes: ko.Observable<boolean> = ko.observable(false);
    public ExpiresIntoNotesPrefix: ko.Observable<string> = ko.observable();
    public ExpiresIntoNotesSuffix: ko.Observable<string> = ko.observable();

    public LogoToShow: ko.Observable<string> = ko.observable(null);
    public LogoToSend: ko.Observable<File> = ko.observable(null);

    public HeaderImageToShow: ko.Observable<string> = ko.observable(null);
    public HeaderImageToSend: ko.Observable<File> = ko.observable(null);

    public Layouts: IDocumentLayout[] = [];
    public DefaultLayout: ko.Observable<IDocumentLayout> = ko.observable(null);

    private IsBlogEnabled: boolean;
    private IsFileRepositoryEnabled: boolean;

    private EventSettings: EventSettingsForProtocolDialog = null;
    private ScheduleStatusChangeEventSettings: EventSettingsForProtocolDialog = null;

    private FileRepositorySettings: FileRepositorySettingsForProtocolDialog;
    private AccountingSoftwareRoutingSettings: AccountingSoftwareRoutingDialog;

    private DefaultShowOnlyCustomerJobOrders: ko.Observable<boolean> = ko.observable(false);
    private DefaultShowAllCustomersAndSuppliers: ko.Observable<boolean> = ko.observable(false);
    private DefaultShowOnlyJobOrderWarehouses: ko.Observable<boolean> = ko.observable(false);

    public ShowJobOrderWarehousesFlag: ko.Observable<boolean> = ko.observable(false);
    private SupportVatCodes: boolean;

    public IsInvoice: boolean;

    constructor(private vatRegister: IVatRegister, private protocolNameField: ko.Observable<string>) {
        this.SupportVatCodes = vatRegister.TipoDocumento == 0 || vatRegister.TipoDocumento == 1; //Solo Fatture e DDT
        this.IsInvoice = vatRegister.TipoDocumento == 0;
        this.Layouts = this.documentsService.GetBusinessDocumentLayouts();

        const matches = this.Layouts.filter((l: IDocumentLayout) => {
            return l.LayoutId == vatRegister.DefaultLayoutId;
        });

        this.DefaultLayout(matches.length > 0 ? matches[0] : null);

        this.CustomLogoEnabled(!!vatRegister.FkCurrentLogo);
        this.CustomHeaderImageEnabled(!!vatRegister.HeaderImage);
        this.CustomFooterEnabled(!!vatRegister.HtmlFooter);
        this.CustomNotesEnabled(!!vatRegister.Notes);
        this.InsertJobOrderIntoNotes(vatRegister.InsertJobOrderIntoNotes);
        this.InsertOriginatingDocumentsIntoNotes(vatRegister.InsertOriginatingDocumentsIntoNotes);
        this.JobOrderIntoNotesPrefix(vatRegister.JobOrderIntoNotesPrefix);
        this.DocumentIntoNotesPrefix(vatRegister.DocumentIntoNotesPrefix);

        this.ImportCalculatedExpiriesIntoDocumentNotes(vatRegister.ImportCalculatedExpiriesIntoDocumentNotes);
        this.ExpiresIntoNotesPrefix(vatRegister.ExpiresIntoNotesPrefix);
        this.ExpiresIntoNotesSuffix(vatRegister.ExpiresIntoNotesSuffix);

        this.HtmlFooter(vatRegister.HtmlFooter);
        this.HtmlNotes(vatRegister.Notes);
        this.LogoToShow(vatRegister.Logo ? "data:image/jpg;base64," + vatRegister.Logo : null);
        this.HeaderImageToShow(vatRegister.HeaderImage ? "data:image/jpg;base64," + vatRegister.HeaderImage : null);
        this.DefaultShowOnlyCustomerJobOrders(vatRegister.DefaultJobOrderFilteredByCustomer);
        this.DefaultShowAllCustomersAndSuppliers(vatRegister.ShowAllCustomersAndSuppliers);
        this.DefaultShowOnlyJobOrderWarehouses(vatRegister.ShowOnlyJobOrderWarehouses);

        this.IsBlogEnabled = this.blogService.IsServiceEnabled();
        this.IsFileRepositoryEnabled = this.fileRepositoryService.IsServiceEnabled();
        this.FileRepositorySettings = new FileRepositorySettingsForProtocolDialog(vatRegister);
        this.AccountingSoftwareRoutingSettings = new AccountingSoftwareRoutingDialog(vatRegister, protocolNameField);
    }

    public ShowBlogSettings() {
        this.EventSettings =
            this.EventSettings ||
            new EventSettingsForProtocolDialog(
                this.vatRegister,
                ProlifeSdk.BlogEventForProtocol_GeneratedDocument,
                ProlifeSdk.TextResources.Invoices.DocumentCreationSettings
            );
        this.dialogService.ShowModal<void>(
            this.EventSettings,
            "fullscreen events-settings-for-protocol",
            null,
            this.EventSettings.templateUrl,
            this.EventSettings.templateName
        );
    }

    public ShowScheduleStatusChangeEventSettings() {
        this.ScheduleStatusChangeEventSettings =
            this.ScheduleStatusChangeEventSettings ||
            new EventSettingsForProtocolDialog(
                this.vatRegister,
                ProlifeSdk.BlogEventForProtocol_ScheduleStatusChanged,
                ProlifeSdk.TextResources.Invoices.ExpirationChangesSettings
            );
        this.dialogService.ShowModal<void>(
            this.ScheduleStatusChangeEventSettings,
            "fullscreen events-settings-for-protocol",
            null,
            this.ScheduleStatusChangeEventSettings.templateUrl,
            this.ScheduleStatusChangeEventSettings.templateName
        );
    }

    public ShowFileRepositorySettings() {
        this.dialogService.ShowModal<void>(
            this.FileRepositorySettings,
            "fullscreen file-repository-settings-for-protocol file-repository",
            null,
            this.FileRepositorySettings.templateUrl,
            this.FileRepositorySettings.templateName
        );
    }

    public ShowAccountingSoftwareRoutingSettings() {
        this.dialogService.ShowModal<void>(
            this.AccountingSoftwareRoutingSettings,
            "fullscreen",
            null,
            this.AccountingSoftwareRoutingSettings.templateUrl,
            this.AccountingSoftwareRoutingSettings.templateName
        );
    }

    public ApplyChangesOnRegister(register: IVatRegister) {
        register.HtmlFooter = this.CustomFooterEnabled() ? this.HtmlFooter() : null;
        register.Notes = this.CustomNotesEnabled() ? this.HtmlNotes() : null;
        register.InsertJobOrderIntoNotes = this.InsertJobOrderIntoNotes();
        register.InsertOriginatingDocumentsIntoNotes = this.InsertOriginatingDocumentsIntoNotes();
        register.JobOrderIntoNotesPrefix = this.JobOrderIntoNotesPrefix();
        register.DocumentIntoNotesPrefix = this.DocumentIntoNotesPrefix();
        register.FkCurrentLogo = this.CustomLogoEnabled() ? register.FkCurrentLogo : null;
        register.HeaderImage = this.CustomHeaderImageEnabled() ? register.HeaderImage : null;
        register.DefaultLayoutId = this.DefaultLayout().LayoutId;

        register.ImportCalculatedExpiriesIntoDocumentNotes = this.ImportCalculatedExpiriesIntoDocumentNotes();
        register.ExpiresIntoNotesPrefix = !this.ExpiresIntoNotesPrefix() ? null : this.ExpiresIntoNotesPrefix();
        register.ExpiresIntoNotesSuffix = !this.ExpiresIntoNotesSuffix() ? null : this.ExpiresIntoNotesSuffix();

        if (this.EventSettings) this.EventSettings.SetTagsOnList(register.DefaultBlogTags);

        if (this.ScheduleStatusChangeEventSettings)
            this.ScheduleStatusChangeEventSettings.SetTagsOnList(register.DefaultBlogTags);

        register.DefaultFileTags = this.FileRepositorySettings.GetData();
        register.AccountingSoftwareRoutes = this.AccountingSoftwareRoutingSettings.GetRoutes();
        register.AccountingSoftwareCode = this.AccountingSoftwareRoutingSettings.GetProtocolCode();
        register.DefaultJobOrderFilteredByCustomer = this.DefaultShowOnlyCustomerJobOrders();
        register.ShowAllCustomersAndSuppliers = this.DefaultShowAllCustomersAndSuppliers();
        register.ShowOnlyJobOrderWarehouses = this.DefaultShowOnlyJobOrderWarehouses();
    }

    public SelectLayout(layout: IDocumentLayout) {
        this.DefaultLayout(layout);
    }

    public SomeIsChanged() {
        let isChanged = false;
        isChanged =
            isChanged ||
            (this.vatRegister.Logo ? "data:image/jpg;base64," + this.vatRegister.Logo : null) !=
                (this.CustomLogoEnabled() ? this.LogoToShow() : null);
        isChanged =
            isChanged ||
            (this.vatRegister.HeaderImage ? "data:image/jpg;base64," + this.vatRegister.HeaderImage : null) !=
                (this.CustomHeaderImageEnabled() ? this.HeaderImageToShow() : null);
        isChanged = isChanged || this.vatRegister.HtmlFooter != (this.CustomFooterEnabled() ? this.HtmlFooter() : null);
        isChanged = isChanged || this.vatRegister.Notes != (this.CustomNotesEnabled() ? this.HtmlNotes() : null);
        isChanged = isChanged || this.vatRegister.DefaultLayoutId != this.DefaultLayout().LayoutId;
        isChanged = isChanged || (this.EventSettings?.SomeIsChanged() ?? false);
        isChanged = isChanged || (this.ScheduleStatusChangeEventSettings?.SomeIsChanged() ?? false);
        isChanged = isChanged || this.FileRepositorySettings.SomeIsChanged();
        isChanged = isChanged || this.AccountingSoftwareRoutingSettings.SomeIsChanged();
        isChanged = isChanged || this.vatRegister.InsertJobOrderIntoNotes != this.InsertJobOrderIntoNotes();
        isChanged =
            isChanged ||
            this.vatRegister.InsertOriginatingDocumentsIntoNotes != this.InsertOriginatingDocumentsIntoNotes();
        isChanged = isChanged || this.vatRegister.DocumentIntoNotesPrefix != this.DocumentIntoNotesPrefix();
        isChanged = isChanged || this.vatRegister.JobOrderIntoNotesPrefix != this.JobOrderIntoNotesPrefix();
        isChanged =
            isChanged || this.vatRegister.DefaultJobOrderFilteredByCustomer != this.DefaultShowOnlyCustomerJobOrders();
        isChanged =
            isChanged || this.vatRegister.ShowAllCustomersAndSuppliers != this.DefaultShowAllCustomersAndSuppliers();
        isChanged =
            isChanged || this.vatRegister.ShowOnlyJobOrderWarehouses != this.DefaultShowOnlyJobOrderWarehouses();
        isChanged =
            isChanged ||
            this.vatRegister.ImportCalculatedExpiriesIntoDocumentNotes !=
                this.ImportCalculatedExpiriesIntoDocumentNotes();
        isChanged = isChanged || this.vatRegister.ExpiresIntoNotesPrefix != this.ExpiresIntoNotesPrefix();
        isChanged = isChanged || this.vatRegister.ExpiresIntoNotesSuffix != this.ExpiresIntoNotesSuffix();

        return isChanged;
    }

    public GetImagesInfo() {
        return <IVatRegisterImagesInfo>{
            HasCustomLogo: this.CustomLogoEnabled(),
            HasHeaderImage: this.CustomHeaderImageEnabled(),
            CustomLogo: this.LogoToSend(),
            HeaderImage: this.HeaderImageToSend(),
        };
    }

    public LoadHeaderImage(file: File) {
        this.LoadFile(file, this.HeaderImageToShow, this.HeaderImageToSend);
    }

    public LoadLogo(file: File) {
        this.LoadFile(file, this.LogoToShow, this.LogoToSend, () => this.CustomLogoEnabled(true));
    }

    private LoadFile(
        file: File,
        fieldForShow: ko.Observable<string | ArrayBuffer>,
        fieldForSend: ko.Observable<File>,
        onLoad: () => void = null
    ) {
        if (file.type.indexOf("image") == -1) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.InvalidFormat);
            return;
        }

        if (file.size > 500000) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Invoices.InvalidLogoSize);
            return;
        }

        const reader: FileReader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
            const result = (<FileReader>event.target).result;
            fieldForShow(result);
            fieldForSend(file);

            onLoad?.();
        };

        reader.readAsDataURL(file);
    }

    public OnInvalidDropLogo() {
        //TODO
    }
}
