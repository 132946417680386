import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IReferenceDetailsViewModelFactory, IEntityRefInfo, IReferenceDetailsViewModel, ISimpleEntitiesViewModel } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IPurchaseRow } from "../../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IDocumentReferencesMap, IReferenceForMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { Deferred } from "../../../../Core/Deferred";

export class PurchaseRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    public UrlProvider : (docId : number, regId : number) => string;

    constructor()
    {
    }

    GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.Blog.Purchases;
    }

    CreateViewModelsFor(references : IRefDocumentRow[], details : IEntityRefInfo[], customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        references.forEach((r : IRefDocumentRow) => {
            var matches = details.filter((d : IEntityRefInfo) => { return d.EntityKeyId == r.SourceEntityKeyId; });
            viewModels.push(new PurchaseRefDetailsViewModel(r, matches[0]));
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(referencedLeafsInfo : ILeafReferenceInfo[], warehouseId : number, customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r : ILeafReferenceInfo) => {
            viewModels.push(new PurchaseLeafRefDetailsViewModel(r));
        });
        return viewModels;
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(referenceMap : IDocumentReferencesMap) : ISimpleEntitiesViewModel
    {
        return new PurchasesEntitiesViewModel(referenceMap);
    }
}

export class PurchasesEntitiesViewModel implements ISimpleEntitiesViewModel
{
    @LazyImport(nameof<IBlogService>())
    private blogService : IBlogService;

    templateName : string = "documents-map-viewer-purchases";
    templateUrl : string = "blog/templates/referencemap";
    EntityType : string = ProlifeSdk.PurchasesEntityTypeCode;

    public Purchases : ko.ObservableArray<PurchaseForReferenceMap> = ko.observableArray([]);

    constructor(private referenceMap : IDocumentReferencesMap)
    {
    }

    public LoadEntities(references : IReferenceForMap[]) : Promise<void>
    {
        var entitiesIds : number[] = references
            .filter((r : IReferenceForMap) => { return r.SourceEntityType == this.EntityType; })
            .map((r : IReferenceForMap) => { return r.SourceId; });

        var def = new Deferred<void>();
        this.blogService.purchasesProvider.GetPurchasesByIds(entitiesIds).then((purchases : IPurchaseRow[]) => {
            this.Purchases(purchases.map((p : IPurchaseRow) => { return new PurchaseForReferenceMap(p, this); }));
        })
        .finally(() => { def.resolve(); });
        return def.promise();
    }

    public SelectRelatedRows(entityId : number, entityType : string)
    {
        this.Purchases().forEach((h: PurchaseForReferenceMap) => {
            h.IsSelected(h.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }

    public OnSelectionChanged(selected : PurchaseForReferenceMap)
    {
        this.Purchases().forEach((p : PurchaseForReferenceMap) => {
            p.IsSelected(p == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.purchase.PurchaseId, this.EntityType);
    }
}

export class PurchaseForReferenceMap extends NodeSubElement
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);

    constructor(public purchase : IPurchaseRow, private container : PurchasesEntitiesViewModel)
    {
        super(container, purchase.PurchaseId, container.EntityType);
    }
}

export class PurchaseRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    public templateName : string;
    public templateUrl : string;
    public JobOrderId : number;

    @LazyImport(nameof<IBlogService>())
    private blogService : IBlogService;

    constructor(protected jobOrderId : number)
    {
        this.templateUrl = "blog/templates/referencemap/referencedetails";
        this.JobOrderId = jobOrderId;
    }

    ShowDetails()
    {
        this.blogService.openBlogInNewWindow(this.JobOrderId);
    }
}

export class PurchaseRefDetailsViewModel extends PurchaseRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(protected reference : IRefDocumentRow, public details : IEntityRefInfo)
    {
        super(details.JobOrderId)
        this.templateName = "purchase";
    }
}

export class PurchaseLeafRefDetailsViewModel extends PurchaseRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(public reference : ILeafReferenceInfo)
    {
        super(reference.JobOrderId)
        this.templateName = "purchase-leaf";
    }
}