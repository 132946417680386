import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 01/03/13
 * Time: 18.02
 * To change this template use File | Settings | File Templates.
 */

export class EanText {
    private static patters : any[] = [
        [0,0,0,0,0,0],
        [0,0,1,0,1,1],
        [0,0,1,1,0,1],
        [0,0,1,1,1,0],
        [0,1,0,0,1,1],
        [0,1,1,0,0,1],
        [0,1,1,1,0,0],
        [0,1,0,1,0,1],
        [0,1,0,1,1,0],
        [0,1,1,0,1,0]
    ];
    private static characters : string[] = [
        "0123456789",
        "pqwertyuio",
        ";asdfghjkl"
    ];

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed(() => {
            var value = ko.isObservable(underlyingObservable) ? underlyingObservable() : underlyingObservable;
            if(!value)
                return "";

            var patternIndex = parseInt(value[0]);
            var selectedPattern = EanText.patters[patternIndex];

            var firstSequence = "";
            for(var i = 1; i <= 6 && i < value.length; i++) {
                var character = value[i];
                var characterSetIndex = selectedPattern[i-1];
                var characterIndex = parseInt(character);

                firstSequence += EanText.characters[characterSetIndex][characterIndex];
            }
            var secondSequence = "";
            for(var i = 7; i < value.length; i++) {
                var character = value[i];
                var characterIndex = parseInt(character);
                secondSequence += EanText.characters[2][characterIndex];
            }

            return "*" + firstSequence + "#" + secondSequence + "*";
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["eanText"] = new EanText();