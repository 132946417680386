import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../Core/DependencyInjection";
import { IWarehouseMovementChanges } from "../../Core/interfaces/IException";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";

export class WarehouseMovementsChangesAlert implements IDialog {
    public templateName: string = "";
    public templateUrl: string = "";
    public renderTemplateNodes() : Node[] {
        return [<>
            <div class="row" style="margin-bottom: 15px">
                <div class="col-md-12">
                    <span data-bind="text: Message"></span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered table-condensed">
                        <thead>
                        <tr>
                            <th colSpan={3}>Vecchio movimento</th>
                            <th></th>
                            <th colSpan={3}>Nuovo movimento</th>
                        </tr>
                        </thead>
                        <tbody data-bind="foreach: MovementsChanges">
                        <tr>
                            <td style="width: 35%" data-bind="text: (!OldArticleCode ? 'N/A' : OldArticleCode)"></td>
                            <td class="text-right" data-bind="numberText: (!OldArticleCode ? 'N/A' : OldAmount)"></td>
                            <td class="text-right" data-bind="warehouseText: (!OldArticleCode ? 'N/A' : OldWarehouseId)"></td>
                            <td class="text-center">
                                <i class="fa fa-arrow-right"></i>
                            </td>
                            <td style="width: 35%" data-bind="text: (!NewArticleCode ? 'N/A' : NewArticleCode)"></td>
                            <td class="text-right" data-bind="numberText: (!NewArticleCode ? 'N/A' : NewAmount)"></td>
                            <td class="text-right" data-bind="warehouseText: (!NewArticleCode ? 'N/A' : NewWarehouseId)"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>]
    }

    public title: string = ProlifeSdk.TextResources.ProlifeSdk.WarehouseMovementsChangesDialogTitle;

    public modal?: {
        close: (result?: any) => void;
    };

    public Message: ko.Observable<string> = ko.observable();
    public MovementsChanges: ko.ObservableArray<IWarehouseMovementChanges> = ko.observableArray([]);

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(message: string, warehouseMovementsChanges: IWarehouseMovementChanges[]) {
        this.Message(message);
        this.MovementsChanges(warehouseMovementsChanges);
    }

    public show(): Promise<boolean> {
        return this.dialogsService.ShowModal(this, "medium", { noPrompt: true });
    }

    public close(): void {
        this.modal.close(false);
    }

    public action(): void {
        this.modal.close(true);
    }
}
