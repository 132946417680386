import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDropDownList } from "../../../../ProlifeSdk/interfaces/prolife-sdk/controls/IDropDownList";

export class BlogEventsDropDownList implements IDropDownList
{
    private blogService : IBlogService;
    private lastTimeout : ReturnType<typeof setTimeout>;
    public selectedId : ko.Observable<number> = ko.observable();

    constructor(private serviceLocator : IServiceLocator, private jobOrderIdGetter : () => number, private eventTypeGetter : () => string)
    {
        this.blogService = <IBlogService>serviceLocator.findService(ProlifeSdk.BlogServiceType);
    }

    public search(query : any)
    {
        if(this.lastTimeout)
            clearTimeout(this.lastTimeout);

        this.lastTimeout = setTimeout(() => {
            var textFilters = [];

            if((query.term || "").length > 0)
                textFilters.push(query.term);

            this.blogService.searchEventsForList(this.jobOrderIdGetter(), this.eventTypeGetter(), (query.term || ""))
                .then((data) => {
                    query.callback({
                        results: data.map((e) =>
                        {
                            return {
                                id: e.Id,
                                text: e.Description
                            };
                        })
                    });
                });
        }, 500);
    }

    public getSelected(element, callback)
    {
        if(this.selectedId()) {
            this.blogService.getEventForList(this.selectedId())
                .then((e) => callback({
                    id: e.Id,
                    text: e.Description
                }));
        }
    }
}