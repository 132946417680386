import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../Invoices/DocumentsService";
import {
    IRowForPricesValidation,
    IDocumentRowForPricesControl,
    IPricesValidation,
} from "../../interfaces/invoice/IDocumentsService";
import { IDialog } from "../../../Core/interfaces/IDialogsService";

export class RowForPricesValidation implements IRowForPricesValidation {
    constructor(public row: IDocumentRowForPricesControl, private calculatedColumns: any[]) {}

    public getFieldValue(columnId: number): number {
        var value: number = -1;
        value = columnId == 0 ? this.row.LastLoadPrice : value;
        value = columnId == 1 ? this.row.LastLoadPriceFromSupplier : value;
        value = columnId == 2 ? this.row.LoadPriceAvg : value;
        value = columnId == 3 ? this.row.LoadPriceAtTodayAvg : value;
        value = columnId == 4 ? this.row.LoadPriceFromSupplierAvg : value;
        value = columnId == 5 ? this.row.LastSellPriceToCustomer : value;
        value = columnId == 6 ? this.row.SellPriceToCustomerAvg : value;
        return value;
    }

    public getFieldDelta(columnId: number): number {
        var fieldValue: number = this.getFieldValue(columnId);

        if (fieldValue <= 0) return 0;

        var delta = this.row.NetUnitPrice - fieldValue;
        return (delta / fieldValue) * 100;
    }
}

export class ApproveDocumentDialog implements IDialog {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;

    public templateName: string = "approve-document-dialog";
    public templateUrl: string = "prolifesdk/templates/documents";
    public title: string = ProlifeSdk.TextResources.ProlifeSdk.PricesReview;
    modal: { close: (result?: any) => void };

    private originalHtmlReport: ko.Observable<string> = ko.observable("");
    public approveHtmlReport: ko.Observable<string> = ko.observable("");
    public validationDate: ko.Observable<Date> = ko.observable();
    public validatedBy: ko.Observable<number> = ko.observable();
    public editable: ko.Computed<boolean>;
    public firstApprove: ko.Computed<boolean>;

    public CanEdit: ko.Observable<boolean> = ko.observable(true);

    public documentRows: ko.ObservableArray<IRowForPricesValidation> = ko.observableArray([]);
    public totalPrice: ko.Computed<number>;

    public calculatedColumns: any[];

    constructor(private documentId: number, private documentType: string, private customerId?: number) {
        this.calculatedColumns = [
            {
                id: 0,
                title: ProlifeSdk.TextResources.ProlifeSdk.LastBuyPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 1,
                title: ProlifeSdk.TextResources.ProlifeSdk.LastSupplierBuyPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 2,
                title: ProlifeSdk.TextResources.ProlifeSdk.AvgBuyPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 3,
                title: ProlifeSdk.TextResources.ProlifeSdk.AvgTodayBuyPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 4,
                title: ProlifeSdk.TextResources.ProlifeSdk.AvgSupplierBuyPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 5,
                title: ProlifeSdk.TextResources.ProlifeSdk.LastCustomerSellPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
            {
                id: 6,
                title: ProlifeSdk.TextResources.ProlifeSdk.LastSellPrice,
                selected: ko.observable(true),
                total: ko.observable(0),
                totalDelta: ko.observable(0),
            },
        ];

        this.totalPrice = ko.computed(() => {
            var total: number = 0;
            this.documentRows().forEach((r: IRowForPricesValidation) => {
                total += r.row.NetUnitPrice * r.row.Amount;
            });
            return total;
        });

        this.firstApprove = ko.computed(() => {
            var v: boolean = (this.originalHtmlReport() || "").length == 0;
            return v;
        });

        this.editable = ko.computed(() => {
            var v: boolean = this.firstApprove() || (this.approveHtmlReport() || "").length == 0;
            return v;
        });

        this.initialization();
    }

    public setEditMode(canEdit: boolean) {
        this.CanEdit(canEdit);
    }

    private initialization() {
        this.documentsService
            .getCurrentPricesValidation(this.documentType, this.documentId)
            .then((validation: IPricesValidation) => {
                this.originalHtmlReport(validation ? validation.HtmlValue : null);
                this.approveHtmlReport(validation ? validation.HtmlValue : null);
                this.validationDate(validation ? validation.CreationDate : null);
                this.validatedBy(validation ? validation.CreatedByUser : null);

                if (this.firstApprove()) this.refreshTable();
            });
    }

    private getColumnTotal(columnId: number) {
        var total: number = 0;

        this.documentRows().forEach((r: RowForPricesValidation) => {
            var val: number = r.getFieldValue(columnId);
            total += (val ?? 0) * r.row.Amount;
        });

        return total;
    }

    private getColumnTotalDelta(columnId: number) {
        var total: number = this.getColumnTotal(columnId);

        if (total <= 0) return 0;

        var delta = this.totalPrice() - total;

        return (delta / total) * 100;
    }

    public refreshTable() {
        this.documentsService
            .getPricesValidationInfo(this.documentType, this.documentId, this.customerId)
            .then((rows: IDocumentRowForPricesControl[]) => {
                var rowsForValidation = rows.map((r) => {
                    return new RowForPricesValidation(r, this.calculatedColumns);
                });
                this.documentRows(rowsForValidation);

                this.calculatedColumns.forEach((c) => {
                    c.total(this.getColumnTotal(c.id));
                    c.totalDelta(this.getColumnTotalDelta(c.id));
                });
            });
    }

    public edit() {
        this.approveHtmlReport("");
        this.refreshTable();
    }

    public cancelEdit() {
        this.approveHtmlReport(this.originalHtmlReport());
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        $(".to-hide-when-validate").each((i, e) => {
            $(e).remove();
        });

        var html: string = $("#validation-table-container").html();
        this.documentsService.validatePricesForDocument(this.documentType, this.documentId, html).then(() => {
            this.initialization();
        });
    }
}
