import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { ExpireModesEditingViewModel } from "./ui/ExpireModesEditingViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import {
    IExpireModes,
    IExpireMode,
    IScheduleCalculationSetting,
    IExpiryExtension,
    IExpireModesObserver,
    IExpireModeSettings,
    IPaymentExtension,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IExpireModes";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../Core/Deferred";

export class ExpireModesSettingsManager implements IExpireModes {
    private ajaxService: IAjaxService;
    private expireModes: IExpireMode[] = [];
    private scheduleCalculationSettings: IScheduleCalculationSetting[] = [];
    private expiriesExtensions: IExpiryExtension[] = [];
    private observers: IExpireModesObserver[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        const settingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(
            this,
            ProlifeSdk.TextResources.Invoices.AdministrativeDocumentsSettingsGroup
        );

        this.ajaxService = <IAjaxService>serviceLocator.findService(ServiceTypes.Ajax);
    }

    addObserver(observer: IExpireModesObserver): void {
        this.observers.push(observer);
    }

    removeObserver(observer: IExpireModesObserver): void {
        const index = this.observers.indexOf(observer);
        if (index < 0) return;
        this.observers.splice(index, 1);
    }

    load(): Promise<IExpireModeSettings> {
        const def = new Deferred<IExpireModeSettings>();

        Promise.all([
            this.ajaxService.Get<IExpireMode[]>("Invoices-api", "ExpireMode", {}),
            this.ajaxService.Get<IScheduleCalculationSetting[]>(
                "Invoices-api",
                "ScheduleCalculationSetting?paymentDeadlineTypeId=",
                {}
            ),
            this.ajaxService.Get<IExpiryExtension[]>("invoices-api", "ExpiriesExtensions", {}),
        ])
            .then(([expireModes, scheduleSettings, expiriesExtensions]) => {
                this.expireModes = expireModes;
                this.scheduleCalculationSettings = scheduleSettings;
                this.expiriesExtensions = expiriesExtensions;
                def.resolve({
                    ExpireModes: expireModes,
                    ScheduleCalculationSettings: scheduleSettings,
                    ExpiriesExtensions: expiriesExtensions,
                });
            })
            .finally(() => {
                def.resolve();
            });

        return def.promise();
    }

    getName(): string {
        return ProlifeSdk.ExpireModes;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Invoices.InvoiceExpirations;
    }

    hasEditingUI(): boolean {
        return true;
    }

    getEditingUI(): IView {
        return {
            templateName: "expire-modes",
            templateUrl: "invoices/templates/settings",
            viewModel: new ExpireModesEditingViewModel(this.serviceLocator, this),
        };
    }

    getExpireModes(getDeleted: boolean = false): IExpireMode[] {
        return this.expireModes.filter((m: IExpireMode) => {
            return getDeleted || m.Eliminato == 0;
        });
    }

    getExpireModeIdByName(expireModeName: string): number {
        const expires = this.expireModes.filter((expire: IExpireMode) => expire.Descrizione == expireModeName);
        if (expires.length == 0) return null;
        return expires[0].IdTipoScadenza;
    }

    createOrUpdate(expireMode: IExpireMode): Promise<IExpireMode> {
        const def = new Deferred<IExpireMode>();

        if (expireMode.IdTipoScadenza > 0) {
            this.ajaxService
                .Put("Invoices-api", "ExpireMode", { methodData: expireMode })
                .then((expireMode: IExpireMode) => {
                    this.onExpireModeChanged(expireMode);
                    def.resolve(expireMode);
                })
                .catch(() => def.reject());
        } else {
            this.ajaxService
                .Post("Invoices-api", "ExpireMode", { methodData: expireMode })
                .then((expireMode: IExpireMode) => {
                    this.onExpireModeAdded(expireMode);
                    def.resolve(expireMode);
                })
                .catch(() => def.reject());
        }

        return def.promise();
    }

    remove(expireModeId: number): Promise<void> {
        const def = new Deferred<void>();

        this.ajaxService
            .Delete("Invoices-api", "ExpireMode/" + expireModeId, {})
            .then(() => {
                this.onExpireModeDeleted(expireModeId);
                def.resolve();
            })
            .catch(() => def.reject());

        return def.promise();
    }

    getScheduleCalculationSettings(expireModeId: number): IScheduleCalculationSetting[] {
        return this.scheduleCalculationSettings.filter(
            (s: IScheduleCalculationSetting) => s.PaymentDeadlineTypeId == expireModeId
        );
    }

    createOrUpdateScheduleCalculationSettings(
        scheduleCalculationSettings: IScheduleCalculationSetting[],
        paymentFeePriceCalculation: number,
        scheduleCalculationMode: number
    ): Promise<IScheduleCalculationSetting[]> {
        const def = new Deferred<IScheduleCalculationSetting[]>();

        this.ajaxService
            .Post("Invoices-api", "ScheduleCalculationSetting", {
                methodData: {
                    ScheduleCalculationSettings: scheduleCalculationSettings,
                    PaymentFeePriceCalculation: paymentFeePriceCalculation,
                    ScheduleCalculationMode: scheduleCalculationMode,
                },
            })
            .then((newOrUpdatedScheduleSettings: IScheduleCalculationSetting[]) => {
                this.insertOrUpdateScheduleSettingList(newOrUpdatedScheduleSettings);
                def.resolve(newOrUpdatedScheduleSettings);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    removeScheduleCalculationSetting(id: number): Promise<void> {
        const def = new Deferred<void>();

        this.ajaxService
            .Delete("Invoices-api", "ScheduleCalculationSetting/" + id, {})
            .then(() => {
                this.deleteScheduleCalculationSettingFromList(id);
                def.resolve();
            })
            .catch(() => def.reject());

        return def.promise();
    }

    getExpiriesExtensions(): IExpiryExtension[] {
        return this.expiriesExtensions;
    }

    createOrUpdateExpiriesExtensions(extensions: IExpiryExtension[]): Promise<IExpiryExtension[]> {
        const def = new Deferred<IExpiryExtension[]>();

        this.ajaxService
            .Post("Invoices-api", "ExpiriesExtensions", { methodData: extensions })
            .then((updatedExtensions: IExpiryExtension[]) => {
                this.expiriesExtensions = updatedExtensions;
                def.resolve(updatedExtensions);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    private insertOrUpdateScheduleSettingList(newOrUpdatedScheduleSettings: IScheduleCalculationSetting[]): void {
        newOrUpdatedScheduleSettings.forEach((s: IScheduleCalculationSetting) => {
            for (let i = 0; i < this.scheduleCalculationSettings.length; i++) {
                if (this.scheduleCalculationSettings[i].Id == s.Id) {
                    this.scheduleCalculationSettings.splice(i, 1, s);
                    return;
                }
            }

            this.scheduleCalculationSettings.push(s);
        });
    }

    private deleteScheduleCalculationSettingFromList(id: number): void {
        for (let i = 0; i < this.scheduleCalculationSettings.length; i++) {
            if (this.scheduleCalculationSettings[i].Id == id) {
                this.scheduleCalculationSettings.splice(i, 1);
                return;
            }
        }
    }

    private onExpireModeChanged(expireMode: IExpireMode) {
        this.load();
        this.observers.forEach((obs) => obs.onExpireModeChanged(expireMode));
    }

    private onExpireModeAdded(expireMode: IExpireMode) {
        this.load();
        this.observers.forEach((obs) => obs.onExpireModeAdded(expireMode));
    }

    private onExpireModeDeleted(expireModeId: number) {
        this.load();
        this.observers.forEach((obs) => obs.onExpireModeDeleted(expireModeId));
    }

    CreateOrUpdatePaymentExtensions(
        customerId: number | null,
        paymentExtensions: IPaymentExtension[] | null
    ): Promise<void> {
        return this.ajaxService.Post<void>("Customers-api/PaymentExtensions", "CreateOrUpdatePaymentExtensions", {
            background: true,
            methodData: {
                customerId: customerId,
                paymentExtensions: paymentExtensions,
            },
        });
    }

    GetPaymentExtensions(customerId: number | null): Promise<IPaymentExtension[]> {
        return this.ajaxService.Post<IPaymentExtension[]>("Customers-api/PaymentExtensions", "GetPaymentExtensions", {
            background: true,
            methodData: {
                customerId: customerId,
            },
        });
    }
}
