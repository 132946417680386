import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 24/07/2018
 * Time: 18:16
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { EventHandler } from "../../../../ProlifeSdk/prolifesdk/utils/EventHandler";
import { SplashPage } from "../splash-page/SplashPage";
import { QuestionnairesProvider } from "../questionnaires/providers/QuestionnairesProvider";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IAgendasProvider, IAgendaService } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { ISurveyService, IInboundConfiguredSurvey } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { IQuestionnaireEnvelope } from "../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";

export class InboundConfigurationPage {
    public templateName: string = "configuration";
    public templateUrl: string = "survey/templates/inbound";

    public NavBarActions = [];

    public Configurations : ko.ObservableArray<InboundConfiguration> = ko.observableArray();
    public SelectedConfiguration: ko.Observable<any> = ko.observable();
    public questionnairesProvider : QuestionnairesProvider;
    public agendasProvider : IAgendasProvider;

    private surveyService : ISurveyService;
    private infoToastService : IInfoToastService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator: IServiceLocator, private parent: SplashPage) {
        this.surveyService = <ISurveyService> serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        var agendaService = <IAgendaService> serviceLocator.findService(ProlifeSdk.AgendaServiceCode);

        this.agendasProvider = agendaService.CreateAgendasProvider();
        this.questionnairesProvider = new QuestionnairesProvider(this.serviceLocator);

        this.NavBarActions = [{
            Name: ko.observable(ProlifeSdk.TextResources.Survey.NewInboundConfiguration),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-plus"),
            Run: this.NewConfiguration.bind(this)
        }];

        this.Load();
    }

    public Load() {
        this.surveyService.getConfiguredInboundSurveys()
            .then((surveys : IInboundConfiguredSurvey[]) => {
                var configurations = [];
                surveys.forEach(s => {
                    configurations.push(this.createViewModelFor(s));
                });

                this.Configurations(configurations);

                if(this.SelectedConfiguration()) {
                    var selectedConfiguration = configurations.filter(c => c.Id() == this.SelectedConfiguration().Id());
                    if(selectedConfiguration.length > 0)
                        this.SelectedConfiguration(selectedConfiguration[0]);
                    else
                        this.SelectedConfiguration(null);
                }
            });
    }

    public SelectConfiguration(config : InboundConfiguration) {
        if(this.SelectedConfiguration() && this.SelectedConfiguration().IsChanged()) {
            this.dialogsService.Confirm(ProlifeSdk.TextResources.Survey.InboundLoosingChanges, ProlifeSdk.TextResources.Survey.InboundLoosingChangesCancel, ProlifeSdk.TextResources.Survey.InboundLoosingChangesConfirm, (result : boolean) => {
                if(result) {
                    this.SelectedConfiguration().Load();
                    this.SelectedConfiguration(config);
                }
            });

            return;
        }

        this.SelectedConfiguration(config);
    }

    public dispose() {

    }

    private createViewModelFor(survey : IInboundConfiguredSurvey) : InboundConfiguration {
        var vm = new InboundConfiguration(survey, this.surveyService, this.infoToastService);
        vm.OnSaved.Add(this.Load.bind(this));
        return vm;
    }

    private NewConfiguration() {
        this.SelectConfiguration(this.createViewModelFor({ Id: null, FkQuestionnaire: null, PhoneNumber: "", ExternalURL: "", FkAgenda: null, Disabled: false }));
    }

    public DeleteConfiguration(config : InboundConfiguration) {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.Survey.InboundDeleteConfiguration, ProlifeSdk.TextResources.Survey.InboundDeleteConfigurationCancel, ProlifeSdk.TextResources.Survey.InboundDeleteConfigurationConfirm, (result : boolean) => {
            if(result) {
                this.surveyService.deleteInboundConfiguration(config.Id())
                    .then(() => {
                        this.Load();
                    });
            }
        });

    }
}

export class InboundConfiguration {
    public Id : ko.Observable<number> = ko.observable();
    public SurveyId : ko.Observable<number> = ko.observable();
    public SurveyName : ko.Observable<string> = ko.observable();
    public PhoneNumber : ko.Observable<string> = ko.observable();
    public ExternalURL : ko.Observable<string> = ko.observable();
    public ResultExportUrl : ko.Observable<string> = ko.observable();
    public ResultPivotExportUrl : ko.Observable<string> = ko.observable();
    public AgendaId : ko.Observable<number> = ko.observable();
    public Disabled : ko.Observable<boolean> = ko.observable();
    public IsChanged : ko.Computed<boolean>;

    public OnSaved : EventHandler = new EventHandler();

    constructor(private survey : IInboundConfiguredSurvey, private surveyService : ISurveyService, private infoToastService : IInfoToastService) {
        this.Id(survey.Id);
        this.SurveyId(survey.FkQuestionnaire);
        this.PhoneNumber(survey.PhoneNumber);
        this.ExternalURL(survey.ExternalURL);
        this.AgendaId(survey.FkAgenda);
        this.Disabled(survey.Disabled);

        this.ResultExportUrl("/Service/SurveyWizard/InboundQuestionnaireResultsExport/ExcelExport/" + this.survey.Id);
        this.ResultPivotExportUrl("/Service/SurveyWizard/InboundQuestionnaireResultsExport/ExcelPivotExport?configId=" + this.survey.Id);

        var interceptor = ko.computed(() => {
            surveyService.getQuestionnaireEnvelope(this.SurveyId())
                .then((q : IQuestionnaireEnvelope) => {
                    if(!q) {
                        this.SurveyName(ProlifeSdk.TextResources.Survey.NewInboundConfiguration);
                        return;
                    }
                    this.SurveyName(q.Title + " - Ver. " + q.Version);
                });
        });

        this.IsChanged = ko.computed(() => {
            return this.Id() != survey.Id || this.SurveyId() != survey.FkQuestionnaire || this.PhoneNumber() != survey.PhoneNumber || this.ExternalURL() != survey.ExternalURL || this.AgendaId() != survey.FkAgenda || this.Disabled() != survey.Disabled;
        });
    }

    private Load() {
        this.Id(this.survey.Id);
        this.SurveyId(this.survey.FkQuestionnaire);
        this.PhoneNumber(this.survey.PhoneNumber);
        this.ExternalURL(this.survey.ExternalURL);
        this.AgendaId(this.survey.FkAgenda);
        this.Disabled(this.survey.Disabled);
    }

    public Save() {
        if(!this.SurveyId()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.InboundNoSurveySelected);
            return;
        }

        if(!this.PhoneNumber()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Survey.InboundNoPhoneEntered);
            return;
        }

        this.surveyService.createOrUpdateConfiguredInboundSurvey(this.Id(), this.SurveyId(), this.PhoneNumber(), this.ExternalURL(), this.AgendaId(), this.Disabled())
            .then((newSurvey : IInboundConfiguredSurvey) => {
                this.survey = newSurvey;
                this.Load();
                this.OnSaved.Call();
            });
    }

    public SwitchDisable() {
        this.Disabled(!this.Disabled());
    }
}