import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 11/07/2017
 * Time: 10:59
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SampleViewModel } from "./SampleViewModel";
import { SampleLogViewModel } from "./SampleLogViewModel";
import { SurveyPanelViewModel } from "../SurveyPanelViewModel";
import { SampleImportDialog } from "./dialogs/SampleImportDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { ISampleService } from "../../../../ProlifeSdk/interfaces/survey/ISampleService";
import { IPeopleForList } from "../../../../ProlifeSdk/interfaces/survey/IPeopleService";
import { ISampleDataToImport, ISample, ISampleLog, ISampleForImport, IExcelFileUploadResult } from "../../../../ProlifeSdk/interfaces/survey/ISample";
import { INavBarAction } from "../../../../ProlifeSdk/interfaces/desktop/ISystemHeader";

export class SampleEditor {
    public templateName: string = 'modify-sample-content';
    public templateUrl: string = 'survey/templates/sample';

    public Sample: ko.Observable<any> = ko.observable();

    public Actions: ko.ObservableArray<SampleLogForEditor> = ko.observableArray([]);
    public EmptyActionsList: ko.Computed<boolean>;

    public DataToImport: ISampleDataToImport;

    private ajaxService: IAjaxService;
    private sampleService: ISampleService;
    private infoToast: IInfoToastService;
    private dialogsService: IDialogsService;

    private NavBarActions: ko.ObservableArray<INavBarAction> = ko.observableArray([]);

    public IsSampleEditor: boolean = true;
    public ShowPrompt: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator: IServiceLocator, private sampleId: number, private parent: SurveyPanelViewModel) {
        this.ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);
        this.sampleService = <ISampleService>serviceLocator.findService(ProlifeSdk.SampleServiceType);
        this.infoToast = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);

        this.sampleService.getSampleDetails(this.sampleId)
            .then((sample : ISample) => {
                this.Sample(new SampleViewModel(this.serviceLocator, sample));
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
            });

        this.sampleService.getSampleLogActions(this.sampleId)
            .then((results :ISampleLog[]) => {
                results.forEach((l: ISampleLog) => {
                    this.Actions.push(new SampleLogForEditor(l, false));
                });
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
            });

        this.DataToImport = <ISampleDataToImport> {
            SelectedSamples: [],
            SelectedFiles: [],
            SelectedContacts: []
        };

        this.EmptyActionsList = ko.computed(() => {
            return this.Actions().length == 0;
        });

        this.parent.SplashPage.setNavBarActions(this.NavBarActions());
        $(window).on("beforeunload.sampleEditor", this.onBeforeUnload.bind(this));
    }

    public onBeforeUnload() {
        return ProlifeSdk.TextResources.Survey.UnsavedChanges;
    }

    public BackToSplash(): void {
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl;
    }

    public Abort()
    {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Survey.ConfirmUnsavedChanges,
            ProlifeSdk.TextResources.Survey.ConfirmUnsavedChangesCancel,
            ProlifeSdk.TextResources.Survey.ConfirmUnsavedChangesConfirm,
            (confirm: boolean) => {
                if (confirm) {
                    this.ShowPrompt(false);
                    this.onAbort();
                }
            });
    }

    public Save()
    {
        if(!this.Sample().Title()) {
            this.infoToast.Warning(ProlifeSdk.TextResources.Survey.SampleTitleRequired);
            this.Sample().Title(this.Sample().currentSample.Title);
            return;
        }
        this.ShowPrompt(false);
        this.save();
    }

    public Delete()
    {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Survey.DeleteSampleMsg,
            ProlifeSdk.TextResources.Survey.DeleteSampleMsgCancel,
            ProlifeSdk.TextResources.Survey.DeleteSampleMsgConfirm,
            (confirm: boolean) => {
                if (confirm)
                    this.onDelete();
            });
    }

    public save()
    {
        var sample: ISample = this.Sample().ToJSON();
        this.saveSample(sample);
        return;
    }

    private saveSample(sample: ISample)
    {
        var samplesToImport: ISampleForImport[] = this.DataToImport.SelectedSamples;
        var singleContactsToImport: number[] = this.DataToImport.SelectedContacts.map((c: IPeopleForList) => c.Id);
        var Files = {};

        this.DataToImport.SelectedFiles.forEach((f: File, index: number) => {
            Files[index] = f;
        });
        if (!!Files[0]) {
            this.uploadExcel(Files)
                .then((uploadResult: IExcelFileUploadResult[]) => {
                    this.sampleService.saveSample(sample, samplesToImport, uploadResult, singleContactsToImport, false)
                        .then((result: number) => {
                            if (result == -1) {
                                this.infoToast.Warning(ProlifeSdk.TextResources.Survey.CanNotChangeSampleState);
                                return;
                            }
                            location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.sampleId;
                        })
                        .catch(() => {
                            this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
                        });
                })
                .catch(() => {
                    this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
                });
            return;
        }

        this.sampleService.saveSample(sample, samplesToImport, [], singleContactsToImport, false)
            .then((result: number) => {
                if (result == -1) {
                    this.infoToast.Warning(ProlifeSdk.TextResources.Survey.CanNotChangeSampleState);
                    return;
                }
                location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.sampleId;
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
            });
    }

    private uploadExcel(files: any): Promise<IExcelFileUploadResult[]> {
        return this.ajaxService.Upload<IExcelFileUploadResult[]>("Survey-api", "SampleApi/UploadExcelFiles?sampleId=" + this.sampleId, files);
    }

    private onDelete() {
        this.sampleService.sampleLogicalDelete(this.sampleId)
            .then((result: number) => {
                if (result == -1) {
                    this.infoToast.Warning(ProlifeSdk.TextResources.Survey.CanNotDeleteSample);
                    return;
                }
                this.ShowPrompt(false);
                this.parent.Reset = false;
                this.infoToast.Success(ProlifeSdk.TextResources.Survey.SampleDeleteSuccess);
                this.BackToSplash();
            })
            .catch(() => {
                this.infoToast.Error(ProlifeSdk.TextResources.Survey.SampleDeleteError);
            });
    }

    private onAbort() {
        if (this.Sample()) {
            this.Sample().Title(this.Sample().currentSample.Title);
            this.Sample().Description(this.Sample().currentSample.Description);
        }
        location.href = "#/" + ProlifeSdk.TextResources.Survey.SurveyUrl + "/" + ProlifeSdk.TextResources.Survey.SampleUrl + "/" + this.sampleId;
    }

    private cleanEnvironment()
    {
        this.Actions([]);
        this.DataToImport.SelectedSamples = [];
        this.DataToImport.SelectedFiles = [];
    }

    private clearTemporaryLog(): void {
        var log: SampleLogForEditor[] = this.Actions();
        for (var index = 0; index < log.length; index++){
            if (!log[index].IsTemporary) {
                break;
            }
            log.splice(index, 1);
            index -= 1;
        }
        this.Actions(log);
    }

    public OpenImportDialog()
    {
        var dialog = new SampleImportDialog(this.serviceLocator, this.sampleId, this.DataToImport);
        this.dialogsService.ShowModal(
            dialog,
            "fullscreen",
            null,
            "survey/templates/sample/dialogs",
            "import-dialog"
        ).then(() => {
            this.clearTemporaryLog();
            this.DataToImport.SelectedSamples.forEach((s: ISampleForImport) => {
                this.Actions.unshift(new SampleLogForEditor(
                    <ISampleLog>{
                        Id: null,
                        Action: ProlifeSdk.TextResources.Survey.ImportFromExistingSampleLogAction + s.Id + ' - ' + s.Title,
                        ActionMaker: null,
                        ActionDate: null,
                        ResultId: null
                    },
                    true));
            });
            this.DataToImport.SelectedFiles.forEach((f: File) => {
                this.Actions.unshift(new SampleLogForEditor(
                    <ISampleLog>{
                        Id: null,
                        Action: ProlifeSdk.TextResources.Survey.ImportFromExcelSampleLogAction + f.name,
                        ActionMaker: null,
                        ActionDate: null,
                        ResultId: null
                    },
                    true));
            });
                this.DataToImport.SelectedContacts.forEach((c: IPeopleForList) => {
                    this.Actions.unshift(new SampleLogForEditor(
                        <ISampleLog>{
                            Id: null,
                            Action: String.format(ProlifeSdk.TextResources.Survey.ImportSingleContactLogAction, c.Id.toString()),
                            ActionMaker: null,
                            ActionDate: null,
                            ResultId: null
                        },
                        true));
                });
        })
        .catch(() => {
            this.infoToast.Error(ProlifeSdk.TextResources.Survey.Failed);
        });
    }

    public dispose() {
        this.cleanEnvironment();
        $(window).off("beforeunload.sampleEditor");
    }
}

export class SampleLogForEditor extends SampleLogViewModel {
    public IsTemporary: boolean;

    constructor(log: ISampleLog, isTemp: boolean) {
        super(log);
        this.IsTemporary = isTemp;
    }
}