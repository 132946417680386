import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProtocolsApplication } from "./protocols/ProtocolsApplication";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IProtocolsService } from "../ProlifeSdk/interfaces/protocols/IProtocolsService";

class ProtocolsService implements IProtocolsService
{
    private application : ProtocolsApplication;
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.application = new ProtocolsApplication(this.serviceLocator);
    }

    getServiceType(): string
    {
        return ProlifeSdk.ProtocolsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ProtocolsService(serviceLocator);
}
