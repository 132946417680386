import * as ko from "knockout";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { ICurrenciesSettingsManager } from "../../../Invoices/invoices/settings/CurrenciesSettingsManager";
import { IDocumentCurrencyViewModel } from "../../interfaces/invoice/IDocumentsService";
import { IProLifeSdkService } from "../../interfaces/prolife-sdk/IProlifeSdkService";

export class CurrencyUtils {
    @LazyImport(nameof<IProLifeSdkService>())
    private static prolifeSdkService: IProLifeSdkService;

    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private static currenciesSettingsManager: ICurrenciesSettingsManager;

    public static applyCurrencyExchange(targetValue: number, currency: IDocumentCurrencyViewModel, forVat = false): number {
        let convertedValue = (targetValue || 0) * (currency?.ExchangeValue() || 0);

        if (forVat && currency.IsDocumentCurrency())
            convertedValue = (targetValue || 0) * (currency?.ExchangeValueForVat() || 0);    

        return convertedValue;
    }

    public static applyCurrencyExchangeValue(targetValue: number, exchangeValue: number): number {
        return targetValue * exchangeValue;
    }

    public static applyCurrencyRoundingRules(value: number, currency: IDocumentCurrencyViewModel): number {
        let fractionalUnit = currency?.Currency()?.FractionalUnit;
        if (!fractionalUnit) {
            const defaultCurrency = CurrencyUtils.currenciesSettingsManager.getDefaultCurrency();
            fractionalUnit = defaultCurrency.FractionalUnit;
        }

        // applica arrotondamento
        return this.roundValue(value, fractionalUnit);
    }

    public static applyCurrencyReverseExchange(value: number, documentCurrency: IDocumentCurrencyViewModel, forVat = false): number {
        const reverseExchange = CurrencyUtils.getCurrencyReverseExchange(documentCurrency, forVat);
        return CurrencyUtils.applyCurrencyExchangeValue(value, reverseExchange);
    }

    public static getCurrencyReverseExchange(documentCurrency: IDocumentCurrencyViewModel, forVat = false) {
        const exchangeValue = forVat ? documentCurrency?.ExchangeValueForVat() : documentCurrency?.ExchangeValue();
        return 1 / (!exchangeValue ? 1 : exchangeValue);
    }

    public static getNumberOfDecimalsForCurrency(documentCurrency: IDocumentCurrencyViewModel): number | undefined {
        const currency = documentCurrency?.Currency();
        if (!currency)
            return undefined;
        
        return CurrencyUtils.getNumberOfDecimals(currency.FractionalUnit); 
    }

    public static getCurrencyFormat(currencyId: number): string {
        const currency = this.currenciesSettingsManager.getCurrencyById(currencyId);
        const numberOfDecimals = this.getNumberOfDecimals(currency.FractionalUnit);

        let format = "0,0";

        if (!numberOfDecimals)
            return format;

        format += ".";

        for (let i = 0; i < numberOfDecimals; i++)
            format += "0";

        return format;
    }

    private static roundValue(value: number, fractionalUnit: number): number {
        const numberofDecimals = CurrencyUtils.getNumberOfDecimals(fractionalUnit);
        let newValue = CurrencyUtils.prolifeSdkService.Utilities.Numbers.Round(value, numberofDecimals);
        
        const remainder = CurrencyUtils.prolifeSdkService.Utilities.Numbers.Round(value % fractionalUnit, numberofDecimals);
        if (remainder > 0 && value != newValue)
            newValue = remainder > (fractionalUnit / 2) ? newValue + fractionalUnit - remainder : newValue - remainder;

        return CurrencyUtils.prolifeSdkService.Utilities.Numbers.Round(newValue, numberofDecimals);
    }

    private static getNumberOfDecimals(fractionalUnit: number): number {
        let numberOfDecimals = 0;
        
        let intPart = Math.trunc(fractionalUnit);

        while (intPart === 0) {
            numberOfDecimals++;
            fractionalUnit *= 10;
            intPart = Math.trunc(fractionalUnit);
        }

        return numberOfDecimals;
    }
}