import { LazyImport, Service } from "../Core/DependencyInjection";
import { IService } from "../Core/interfaces/IService";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";

export interface IPaymentPortalService extends IService {
    getPaymentPortalUrl() : Promise<string>;
}

@Service(nameof<IPaymentPortalService>())
class PaymentPortalService implements IPaymentPortalService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    public getPaymentPortalUrl() : Promise<string> {
        return this.ajaxService.Get<string>("Core-api/PaymentPortal", "", { background: true });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return serviceLocator.findService(nameof<IPaymentPortalService>());
}