import jsPDF from "jspdf";
import { ReportFlowPanel } from "../Components/ReportFlowPanel";
import { ReportPanel } from "../Components/ReportPanel";
import { ExportBackground } from "./ReportBackgroundExporter";
import { ExportChildren } from "./ReportChildrenExporter";
import { ExporterFor, ReportExporter } from "./ReportExporterProvider";

@ExporterFor(nameof<ReportPanel>())
@ExporterFor(nameof<ReportFlowPanel>())
export class ReportPanelExporter implements ReportExporter {
    async export(doc: jsPDF, component: ReportPanel): Promise<void> {
        await ExportBackground(doc, component, component.x(), component.y(), component.width(), component.height());
        await ExportChildren(doc, component, component.x(), component.y());
    }
}