import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk";
import { DocumentLayout } from "./DocumentLayout";

export class DetailedLayout extends DocumentLayout
{
    constructor()
    {
        super();
        this.LayoutId = 2;
        this.Description = ProlifeSdk.TextResources.ProlifeSdk.DetailedDetailDescription;
        this.Title = ProlifeSdk.TextResources.ProlifeSdk.DetailedDetailTitle;
        this.ShortTitle = ProlifeSdk.TextResources.ProlifeSdk.DetailedDetailShortTitle;
    }
}
