import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 22/02/13
 * Time: 9.21
 * To change this template use File | Settings | File Templates.
 */


export class Sortable {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var defaultOptions : JQueryUI.SortableOptions = {
            axis: "y",
            opacity: 0.35,
            revert: false,
            stop: function (event: Event, ui: JQueryUI.SortableUIParams) { viewModel.sort.bind(viewModel, ko.dataFor(ui.item[0]), ko.dataFor(this)) }
        };
        var options : JQueryUI.SortableOptions = ko.utils.unwrapObservable(valueAccessor());
        options = <JQueryUI.SortableOptions> $.extend(defaultOptions, options);

        $(element).sortable(options);
    }
}

ko.bindingHandlers["sortable"] = new Sortable();