/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 10/11/2017
 * Time: 14:38
 * To change this template use File | Settings | File Templates.
 */
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IWorkflowCategory } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IWorkflowCategoriesSettingsManager } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowCategoriesSettingsManager";
import { IJobOrdersMenuAdvancedFilters, IJobOrderMenuFilter } from "../../../../../ProlifeSdk/interfaces/todolist/IJobOrderNavigator";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IApplicationConfiguration, IApplicationsConfigurationsService } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/IApplicationsConfigurationsService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { IJobOrderStateSettingsManager } from "../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { IJobOrderTypesSettingsManager } from "../../../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderTypesSettingsManager";
import { IJobOrderState } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IJobOrderType } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { DialogComponentBase } from "../../../../../Core/utils/DialogComponentBase";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../../Components/Layouts";
import { CheckBox } from "../../../../../Components/Checkbox";
import { List, ListItem } from "../../../../../Components/ListComponent";
import { IDataSource, IDataSourceListener, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { If } from "../../../../../Components/IfIfNotWith";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";

const styleSheet = jss.createStyleSheet({
    filtersDialog: {
        "& .list-notification-container": {
            "& .list-notification": {
                "&.list-notification-fit": {
                    "& .list-notification-item": {
                        backgroundColor: "transparent",
                        padding: "0.11em 0",

                        "&.selected": {
                            background: "#428bca",
                            color: "white"
                        },

                        "& .filter-item": {
                            cursor: "pointer",
                            margin: "0",
                            alignItems: "baseline",
        
                            "& .icon": {
                                minWidth: "30px",
                                minHeight: "30px",
                                maxWidth: "30px",
                                maxHeight: "30px",
                                textAlign: "center",
                                lineHeight: "30px",
                                padding: "0px"
                            }
                        }
                    }
                }
            },
        },

        "& .content": {
            borderTop: "1px solid #efefef",
            "& h3": {
                paddingLeft: "10px"
            },

            "& .job-orders": {
                borderRight: "1px solid #efefef",

                "& .job-order-states, & .job-order-types": {
                    padding: "0 10px"
                }
            },

            "& .workflows": {
                "& .workflow-categories": {
                    padding: "0 10px"
                }
            }
        }
    }
});
const { classes } = styleSheet.attach();

type AdvancedFiltersDialogOptions = {
    showActivitiesMustBeAllocatedFlag: boolean;
};

export type AdvancedFiltersDialogProps = {
    filters?: IJobOrdersMenuAdvancedFilters;
    currentFiltersConfig?: IApplicationConfiguration;
    options?: AdvancedFiltersDialogOptions;
    applicationCode: string;
};

type AdvancedFiltersProps = AdvancedFiltersDialogProps & { forwardRef?: (advancedFilters: _AdvancedFilters) => void };

export interface IAdvancedFiltersEditingResult { 
    SelectedFilters: IJobOrdersMenuAdvancedFilters;
    FiltersConfig: IApplicationConfiguration;
}

export class AdvancedFiltersDialog extends DialogComponentBase {
    static defaultProps: Partial<AdvancedFiltersDialogProps> = {
    }

    private advancedFilters: _AdvancedFilters;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor(private props : AdvancedFiltersDialogProps) {
        super({ className: "fullscreen" });
        this.title(ProlifeSdk.TextResources.Todolist.JobOrdersNavigatorAdvFiltersDialogTitle);
    }

    action() {
        const filters = this.advancedFilters.getFiltersToApply();
        
        this.modal.close({
            SelectedFilters: filters,
            FiltersConfig: this.advancedFilters.CurrentFiltersConfig()
        });
    }

    show(): Promise<IAdvancedFiltersEditingResult> {
        return this.dialogsService.ShowModal(this);
    }
    
    renderBody() {
        return <AdvancedFilters {...this.props} forwardRef={(advancedFilters) => this.advancedFilters = advancedFilters} />;
    }

    renderFooter() {
        const $data = this;

        return (
            <>
                <div class="pull-left">
                    <a href="#" class="btn green" data-bind={{ click: $data.advancedFilters.saveFilters.bind($data.advancedFilters) }}>{TextResources.ProlifeSdk.Save}</a>
                </div>
                <a href="#" class="btn btn-default" data-bind={{ click: $data.close.bind($data) }}>{TextResources.ProlifeSdk.Close}</a>
                <a href="#" class="btn btn-danger" data-bind={{ click: $data.advancedFilters.resetFilters.bind($data.advancedFilters) }}>{TextResources.ProlifeSdk.Reset}</a>
                <a href="#" class="btn btn-primary" data-bind={{ click: $data.action.bind($data) }}>{TextResources.ProlifeSdk.Apply}</a>
            </>
        );
    }
}

export function AdvancedFilters(props: AdvancedFiltersProps) {
    const C = require("./AdvancedFiltersDialog")._AdvancedFilters as typeof _AdvancedFilters;
    return <C {...props} />;
}

export class _AdvancedFilters implements IDataSourceListener {
    public OnlyActivitiesWithRequiredAllocation : ko.Observable<boolean> = ko.observable(false);
    public JobOrderStates: ko.ObservableArray<AdvancedFilter> = ko.observableArray([]);
    public JobOrderTypes: ko.ObservableArray<AdvancedFilter> = ko.observableArray([]);
    public WorkflowTypes: ko.ObservableArray<AdvancedFilter> = ko.observableArray([]);
    
    public CurrentFiltersConfig: ko.Observable<IApplicationConfiguration> = ko.observable();

    private showActivitiesMustBeAllocatedFlag: ko.Observable<boolean> = ko.observable(true);

    private jobOrderStatesList: List<number, AdvancedFilter>;
    private jobOrderTypesList: List<number, AdvancedFilter>;
    private workflowTypesList: List<number, AdvancedFilter>;

    private selectedItems: AdvancedFilter[] = [];

    @LazyImport(ProlifeSdk.ApplicationsConfigurationsServiceCode)
    private applicationsConfigurationsService!: IApplicationsConfigurationsService;
    @LazyImport(ProlifeSdk.ModulesServiceType)
    private modulesService: IModulesService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IUserInfo>())
    private userInfo : IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.JobOrderState)
    private jobOrderStateSettingsManager : IJobOrderStateSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderType)
    private jobOrderTypesSettingsManager : IJobOrderTypesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.WorkflowCategoriesType)
    private workflowCategoriesSettingsManager : IWorkflowCategoriesSettingsManager;

    private moduleId: number;

    constructor(private props: AdvancedFiltersProps) {
        this.CurrentFiltersConfig(this.props.currentFiltersConfig);
        this.showActivitiesMustBeAllocatedFlag(this.props.options.showActivitiesMustBeAllocatedFlag);

        if (this.props.forwardRef)
            this.props.forwardRef(this);
    }

    componentDidMount() {
        this.JobOrderStates(this.jobOrderStateSettingsManager.getJobOrderStates().filter((s: IJobOrderState) => s.LogicalState == 0).map((s: IJobOrderState) => { return this.createViewModelForJobOrderState(s); }));
        this.JobOrderTypes(this.jobOrderTypesSettingsManager.getVisibleJobOrderTypes().map((t: IJobOrderType) => { return this.createViewModelForjobOrderType(t); }));
        
        this.workflowCategoriesSettingsManager.GetCategories()
            .then((categories: IWorkflowCategory[]) => {
                if (categories) {
                    this.WorkflowTypes(categories.map((c: IWorkflowCategory) => { return this.createViewModelForWorkflowCategory(c); }));
                }

                this.loadSavedFilters(this.props.filters);
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.GetWorkflowCategoriesForFilterError);
            });
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel<number, AdvancedFilter>): void {
        if (!model?.model)
            return;

        this.selectedItems.push(model.model);
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel<number, AdvancedFilter>): void {
        if (!model?.model)
            return;

        for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            if (item?.FilterObjectId() === model.model.FilterObjectId() && item?.FilterObjectType() === model.model.FilterObjectType()) {
                this.selectedItems.splice(i, 1);
                break;
            }
        }
    }
    
    getFiltersToApply(): IJobOrdersMenuAdvancedFilters {
        return {
            SelectedJobOrdersStates: this.selectedItems
                .filter(f => f.FilterObjectType() === ProlifeSdk.JobOrderStateFilterCode)
                .map(f => f.toJson()),
            SelectedJobOrdersTypes: this.selectedItems
                .filter(f => f.FilterObjectType() === ProlifeSdk.JobOrderTypeFilterCode)
                .map(f => f.toJson()),
            SelectedWorkflowTypes: this.selectedItems
                .filter(f => f.FilterObjectType() === ProlifeSdk.WorkflowTypeFilterCode)
                .map(f => f.toJson()),
            SelectedOpUnits: [],
            OnlyActivitiesWithRequiredAllocation: this.OnlyActivitiesWithRequiredAllocation()
        };
    }

    resetFilters(): void {
        this.deselectFilters();
        this.OnlyActivitiesWithRequiredAllocation(false);
    }

    async saveFilters(): Promise<void> {
        const filtersToSave: IJobOrdersMenuAdvancedFilters = {
            SelectedJobOrdersStates: this.selectedItems.filter((f: AdvancedFilter) => { return f.FilterObjectType() === ProlifeSdk.JobOrderStateFilterCode }).map((f: AdvancedFilter) => { return f.toJson(); }),
            SelectedJobOrdersTypes: this.selectedItems.filter((f: AdvancedFilter) => { return f.FilterObjectType() === ProlifeSdk.JobOrderTypeFilterCode }).map((f: AdvancedFilter) => { return f.toJson(); }),
            SelectedWorkflowTypes: this.selectedItems.filter((f: AdvancedFilter) => { return f.FilterObjectType() === ProlifeSdk.WorkflowTypeFilterCode }).map((f: AdvancedFilter) => { return f.toJson(); }),
            SelectedOpUnits: [],
            OnlyActivitiesWithRequiredAllocation: this.OnlyActivitiesWithRequiredAllocation()
        };

        if (!this.CurrentFiltersConfig())
            this.CurrentFiltersConfig(this.createEmptyConfig());

        this.CurrentFiltersConfig().Configuration = JSON.stringify(filtersToSave);
        this.CurrentFiltersConfig().UserID = this.userInfo.getIdUser();

        let config: IApplicationConfiguration = await this.applicationsConfigurationsService.AddOrUpdateConfiguration(this.CurrentFiltersConfig());
        this.CurrentFiltersConfig().ConfigurationId = config.ConfigurationId;
    }

    private createEmptyConfig(): IApplicationConfiguration {
        return {
            ConfigurationId: null,
            ConfigurationType: ProlifeSdk.AdvancedFiltersConfigAllocationType,
            UserID: this.userInfo.getIdUser(),
            ModuleId: this.moduleId,
            Configuration: ""
        };
    }

    public async reloadSavedFilters(): Promise<void> {
        try {
            const moduleId: number = await this.modulesService.getModuleId(this.props.applicationCode);
            const filtersConfig: IApplicationConfiguration[] = await this.applicationsConfigurationsService.GetApplicationConfiguration(ProlifeSdk.AdvancedFiltersConfigAllocationType, this.userInfo.getIdUser(), moduleId, null);
            let config = filtersConfig && filtersConfig.length > 0 ? filtersConfig[0] : null;
            this.deselectFilters();
            await this.loadSavedFilters(this.createAdvancedFiltersObject(config));
        } catch (e) {
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.GetJobOrderNavigatorAdvancedFiltersError);
        }
    }

    private createAdvancedFiltersObject(filtersConfig: IApplicationConfiguration): IJobOrdersMenuAdvancedFilters {
        if (!filtersConfig || !filtersConfig.Configuration) {
            return {
                SelectedJobOrdersStates: [],
                SelectedJobOrdersTypes: [],
                SelectedWorkflowTypes: [],
                SelectedOpUnits: [],
                OnlyActivitiesWithRequiredAllocation: false
            };
        }
        var filters: IJobOrdersMenuAdvancedFilters = JSON.parse(filtersConfig.Configuration);
        return filters;
    }

    private deselectFilters(): void {
        this.jobOrderTypesList.clearSelection();
        this.jobOrderStatesList.clearSelection();
        this.workflowTypesList.clearSelection();
    }

    private createViewModelForJobOrderState(state: IJobOrderState): AdvancedFilter {
        return new AdvancedFilter(state.IdJobOrderStateId, state.Description, ProlifeSdk.JobOrderStateFilterCode, state.Icon, state.Background, state.Foreground);
    }

    private createViewModelForjobOrderType(type: IJobOrderType): AdvancedFilter {
        return new AdvancedFilter(type.Id, type.Description, ProlifeSdk.JobOrderTypeFilterCode, type.Icon, type.Background, type.Foreground);
    }

    private createViewModelForWorkflowCategory(cat: IWorkflowCategory): AdvancedFilter {
        return new AdvancedFilter(cat.Id, cat.Name, ProlifeSdk.WorkflowTypeFilterCode, cat.Icon, cat.Background, cat.Foreground);
    }

    private async loadSavedFilters(filters: IJobOrdersMenuAdvancedFilters): Promise<void> {
        const moduleId: number = await this.modulesService.getModuleId(this.props.applicationCode);
        if (!moduleId)
            this.infoToastService.Error(ProlifeSdk.TextResources.Todolist.ModuleNotFoundError);
        else
            this.moduleId = moduleId;

        if (!filters)
            return;

        this.applySelection(filters.SelectedJobOrdersStates, this.JobOrderStates(), this.jobOrderStatesList);
        this.applySelection(filters.SelectedJobOrdersTypes, this.JobOrderTypes(), this.jobOrderTypesList);
        this.applySelection(filters.SelectedWorkflowTypes, this.WorkflowTypes(), this.workflowTypesList);

        this.OnlyActivitiesWithRequiredAllocation(filters.OnlyActivitiesWithRequiredAllocation ?? false);
    }

    private applySelection(selection: IJobOrderMenuFilter[], itemsPool: AdvancedFilter[], list: List<number, AdvancedFilter>): void {
        if (!selection || selection.length === 0)
            return;

        let itemsToSelect = [];
        for (const selectedItem of selection) {
            const itemToSelect = itemsPool.firstOrDefault(af => af.FilterObjectId() === selectedItem.FilterObjectId);
            if (!itemsToSelect)
                continue;

            itemsToSelect.push(itemToSelect);
        }

        list.select(...itemsToSelect);
    }

    private createDataSourceModels(filter: AdvancedFilter): IDataSourceModel<number, AdvancedFilter> {
        return {
            id: filter.FilterObjectId(),
            title: filter.Label(),
            isGroup: false,
            isLeaf: true,
            model: filter
        };
    }

    private renderFilterItem(item: ListItem<number, AdvancedFilter>) {
        return  <div className="filter-item flex-container" data-bind={{ click: item.Select.bind(item) }}>
                    <div className="btn icon" data-bind={{ style: { background: item.Model.Background, color: item.Model.Foreground }, visible: item.Model.HasIcon }}>
                        <i data-bind={{ css: item.Model.Icon }}></i>
                    </div>
                    <div className="flex-fill">
                        <span data-bind={{ text: item.Model.Label }}></span>
                    </div>
                </div>;
    }

    render() {
        const filtersDialog = this;

        return ComponentUtils.bindTo((
            <Layout.WithHeader className={classes.filtersDialog}>
                <Layout.WithHeader.Header>
                    <div className="flex-fill" style={{ alignSelf: "center" }}>
                        <If condition={this.showActivitiesMustBeAllocatedFlag}>
                            {() => <CheckBox checked={this.OnlyActivitiesWithRequiredAllocation} label={"Mostra solo attività che richiedono allocazione"} />}
                        </If>
                    </div>
                    <div>
                        <button className="btn btn-primary" data-bind={{ click: filtersDialog.reloadSavedFilters.bind(filtersDialog) }}>
                            {TextResources.Todolist.LoadSavedJobOrdersNavigatorAdvancedFilters}
                        </button>
                    </div>
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content>
                    <Layout.Grid rows={["100%"]} columns={["66.6%", "33.4%"]} className="content">
                        <Layout.Grid.Cell row={1} column={1} className="job-orders">
                            <Layout.WithHeader className="flex-fill">
                                <Layout.WithHeader.Header>
                                    <h3>{TextResources.JobOrder.Orders}</h3>
                                </Layout.WithHeader.Header>
                                <Layout.WithHeader.Content>
                                    <Layout.Grid rows={["100%"]} columns={["50%", "50%"]}>
                                        <Layout.Grid.Cell row={1} column={1} className="job-order-states">
                                            <Layout.WithHeader className="flex-fill">
                                                <Layout.WithHeader.Header>
                                                    <h4>{TextResources.JobOrder.OrderStatus}</h4>
                                                </Layout.WithHeader.Header>
                                                <Layout.WithHeader.Content>
                                                    <List dataSource={{ array: this.JobOrderStates, factory: this.createDataSourceModels.bind(this) }} 
                                                          listener={this}
                                                          scrollable
                                                          multipleSelection
                                                          itemRenderer={this.renderFilterItem.bind(this)}
                                                          containerHeight="flex"
                                                          ref={(list: List<number, AdvancedFilter>) => this.jobOrderStatesList = list} />
                                                </Layout.WithHeader.Content>
                                            </Layout.WithHeader>
                                        </Layout.Grid.Cell>
                                        <Layout.Grid.Cell row={1} column={2} className="job-order-types">
                                            <Layout.WithHeader className="flex-fill">
                                                <Layout.WithHeader.Header>
                                                    <h4>{TextResources.JobOrder.OrderTypes}</h4>
                                                </Layout.WithHeader.Header>
                                                <Layout.WithHeader.Content>
                                                    <List dataSource={{ array: this.JobOrderTypes, factory: this.createDataSourceModels.bind(this) }}
                                                          listener={this}
                                                          scrollable
                                                          multipleSelection
                                                          itemRenderer={this.renderFilterItem.bind(this)}
                                                          containerHeight="flex"
                                                          ref={(list: List<number, AdvancedFilter>) => this.jobOrderTypesList = list} />
                                                </Layout.WithHeader.Content>
                                            </Layout.WithHeader>
                                        </Layout.Grid.Cell>
                                    </Layout.Grid>
                                </Layout.WithHeader.Content>
                            </Layout.WithHeader>
                        </Layout.Grid.Cell>
                        <Layout.Grid.Cell row={1} column={2}>
                            <Layout.WithHeader className="flex-fill workflows">
                                <Layout.WithHeader.Header>
                                    <h3>{TextResources.Todolist.Workflows}</h3>
                                </Layout.WithHeader.Header>
                                <Layout.WithHeader.Content className="workflow-categories">
                                    <Layout.WithHeader>
                                        <Layout.WithHeader.Header>
                                            <h4>{TextResources.Todolist.WorkflowCategories}</h4>
                                        </Layout.WithHeader.Header>
                                        <Layout.WithHeader.Content>
                                            <List dataSource={{ array: this.WorkflowTypes, factory: this.createDataSourceModels.bind(this) }}
                                                  listener={this}
                                                  scrollable
                                                  multipleSelection
                                                  itemRenderer={this.renderFilterItem.bind(this)}
                                                  containerHeight="flex"
                                                  ref={(list: List<number, AdvancedFilter>) => this.workflowTypesList = list} />
                                        </Layout.WithHeader.Content>
                                    </Layout.WithHeader>
                                </Layout.WithHeader.Content>
                            </Layout.WithHeader>
                        </Layout.Grid.Cell>
                    </Layout.Grid>
                </Layout.WithHeader.Content>
            </Layout.WithHeader>
        ), this, "filtersDialog");
    }
}

class AdvancedFilter {
    public FilterObjectId: ko.Observable<number> = ko.observable();
    public FilterObjectType: ko.Observable<string> = ko.observable();
    public Label: ko.Observable<string> = ko.observable();
    public Icon: ko.Observable<string>  = ko.observable();
    public Background: ko.Observable<string> = ko.observable();
    public Foreground: ko.Observable<string> = ko.observable();
    public Selected: ko.Observable<boolean> = ko.observable();

    public HasIcon: ko.Computed<boolean>;

    constructor(filterObjectId: number, label: string, type: string, icon: string, background: string, foreground: string) {
        this.FilterObjectId(filterObjectId);
        this.FilterObjectType(type);
        this.Icon(icon);
        this.Background(background);
        this.Foreground(foreground);
        this.Label(label);
        this.Selected(false);

        this.HasIcon = ko.computed(() => {
            return !!this.Icon();
        });
    }

    public toJson(): IJobOrderMenuFilter {
        return {
            FilterObjectId: this.FilterObjectId(),
            FilterObjectType: this.FilterObjectType()
        };
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(AdvancedFilters);
}