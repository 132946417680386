import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 13:02
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { AgendasNavigationProvider } from "./navigation/providers/AgendasNavigationProvider";
import { FestivitiesCalendar } from "./FestivitiesCalendar";
import { UiUtilities } from "./utils/UiUtilities";
import { Moment } from "moment";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IFestivitiesAgendaViewModel } from "../../interfaces/IFestivitiesAgenda";
import {
    IFullCalendarEventsSource,
    IFullCalendarViewObject,
    IFullCalendarEvent,
    IFullCalendarBusinessHours,
    IFullCalendar,
    IFullCalendarHeader,
} from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { IFestivitiesService, IFestivity } from "../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IAgenda, IAgendaTimetableViewModel } from "../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { OptionsInput } from "fullcalendar/src/types/input-types";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";

export class FestivitiesAgendaViewModel
    extends AgendasNavigationProvider
    implements IFestivitiesAgendaViewModel, IFullCalendarEventsSource
{
    public Calendar: ko.Observable<FestivitiesCalendar> = ko.observable();
    public CalendarOptions: OptionsInput;
    public WaitingList: any = null;

    public AgendaId: ko.Observable<number> = ko.observable();
    public Title: ko.Observable<string> = ko.observable();

    public SelectedDate: ko.Observable<Date> = ko.observable();

    public Timetables: ko.ObservableArray<any> = ko.observableArray([]);
    public TimetablesVariations: ko.ObservableArray<any> = ko.observableArray([]);
    public TimeslotsModeEnabled: ko.Observable<boolean> = ko.observable();
    public ShowDialogOnDropOnWaitingList: ko.Observable<boolean> = ko.observable();
    public EventCanceledMotivations: ko.ObservableArray<any> = ko.observableArray([]);
    public ShowEventCanceledMotivationsList: ko.Observable<boolean> = ko.observable(false);

    public CanShowExportButton: ko.Computed<boolean>;
    public ExportUrl: ko.Computed<string>;
    public CalendarTitle: ko.Computed<string>;

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    private festivitiesService: IFestivitiesService;

    constructor(serviceLocator: IServiceLocator, agenda: IAgenda) {
        super(serviceLocator, agenda);
        this.festivitiesService = <IFestivitiesService>(
            this.serviceLocator.findService(ProlifeSdk.FestivitiesServiceCode)
        );

        this.SetupCalendar();

        this.AgendaId(this.agenda.Id);
        this.Title(this.agenda.Name);
        this.Deleted(this.agenda.Deleted);

        this.AgendaId.subscribe((newId: number) => {
            this.Id = newId;
        });

        this.Title.subscribe((newTitle: string) => {
            this.Name = newTitle;
        });

        this.CanShowExportButton = ko.computed(() => {
            return false;
        });

        this.ExportUrl = ko.computed(() => {
            return "#";
        });

        this.CalendarTitle = ko.computed(() => {
            if (!this.Calendar()) return "";
            const currentView: IFullCalendarViewObject = this.Calendar().GetActualView();
            if (!currentView) return "";

            return currentView.title;
        });

        this.SelectedDate.subscribe((date: Date) => {
            if (!this.Calendar()) return;

            this.Calendar().GoToDate(moment(date));
        });
    }

    public SimpleExport(): void {
        if (this.ExportUrl() == "#") return;
        this.ajaxService.DownloadFileFromUrl(this.ExportUrl(), {});
    }

    GetEventsSources(): any[] {
        return [{ events: this.GetEvents.bind(this) }];
    }

    public GetEvents(
        start: Moment,
        end: Moment,
        timezone: any,
        callback: (events: IFullCalendarEvent[]) => void
    ): void {
        this.festivitiesService
            .GetFestivities(start.toDate(), end.toDate(), this.AgendaId())
            .then((festivities: IFestivity[]) => {
                if (!festivities) {
                    callback([]);
                    return;
                }
                callback(
                    festivities.map((f: IFestivity) => {
                        const color = f.color;
                        f.editable = true;
                        f.durationEditable = true;
                        f.startEditable = true;
                        f.color = UiUtilities.GetColorWithOpacity(f.color, 0.6);
                        f.textColor = UiUtilities.GetForegroundColorForAlphaColor(color, 0.6, "#FFFFFF");
                        return f;
                    })
                );
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFestivitiesError);
            });
    }

    GetBusinessHours(periodStart: any, periodEnd: any): IFullCalendarBusinessHours[] {
        return undefined;
    }

    RenderEventOnCalendar(event: IFestivity): void {
        this.Calendar().RenderEvent(event);
    }

    UpdateEventOnCalendar(event: IFestivity): void {
        this.Calendar().UpdateEvent(event);
    }

    RemoveEventFromCalendar(eventId: number): void {
        this.Calendar().RemoveEvent(eventId);
    }

    RefreshWaitingList(): void {}

    RefreshCalendar(): void {}

    GetTimetableForDay(date: any): IAgendaTimetableViewModel {
        return undefined;
    }

    IsTimetableRespected(eventStart: any, eventEnd: any, allDayEvent: boolean): boolean {
        return true;
    }

    IsMaxNumberOfEventsForTimeRespected(eventStart: any, eventId: any = null): boolean {
        return true;
    }

    OnEventDroppedOnCanceledMotivation(event: IFullCalendarEvent, canceledMotivationId): void {}

    OnEventModified(event: IFullCalendarEvent): void {}

    private setCalendarOptions(calendar: IFullCalendar): void {
        const agendaHeader: IFullCalendarHeader = {
            left: "",
            center: "",
            right:
                ProlifeSdk.CalendarPrevButton +
                "," +
                ProlifeSdk.CalendarNextButton +
                "," +
                ProlifeSdk.CalendarTodayButton +
                "," +
                ProlifeSdk.CalendarMonthView +
                "," +
                ProlifeSdk.CalendarAgendaWeekView +
                "," +
                ProlifeSdk.CalendarAgendaDayView,
        };

        this.CalendarOptions = {
            header: agendaHeader,
            defaultView: ProlifeSdk.CalendarMonthView,
            slotDuration: "00:15:00",
            snapDuration: "00:15:00",
            editable: true,
            droppable: true,
            selectable: true,
            dropAccept: "*",
            locale: "it",
            height: "parent",
            customButtons: undefined,
            buttonText: undefined,
            unselectAuto: false,
            allDaySlot: true,
            eventOverlap: false,
            eventSources: this.GetEventsSources(),
            viewRender: calendar.OnViewRenderCallback.bind(calendar),
            eventRender: calendar.OnEventRenderCallback.bind(calendar),
            eventDrop: calendar.OnEventDropCallback.bind(calendar),
            eventResize: calendar.OnEventResizeCallback.bind(calendar),
            eventClick: calendar.OnEventClickCallback, //.bind(calendar)
            dayRender: calendar.OnDayRenderCallback.bind(calendar),
            drop: calendar.OnDropCallback.bind(calendar),
            eventReceive: calendar.OnEventReceiveCallback.bind(calendar),
            select: calendar.OnSelectCallback.bind(calendar),
            unselect: calendar.OnUnselectCallback.bind(calendar),
        };
    }

    private SetupCalendar(): void {
        const calendar = new FestivitiesCalendar(this.serviceLocator, this);
        this.setCalendarOptions(calendar);
        this.Calendar(calendar);
    }
}
