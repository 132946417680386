import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListTaskTags } from "./TodoListTaskTags";
import { TodoListActivity } from "../TodoListActivity";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { JobOrdersDataSource } from "../../../../../DataSources/JobOrdersDataSource";
import { IHumanResourcesSettingsManager } from "../../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { IEntityProviderService } from "../../../../../ProlifeSdk/interfaces/IEntityProviderService";
import { ITodoListTaskTag } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IUserInfo } from "../../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { IControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IJobOrdersControlsEntityProvider } from "../../../../../ProlifeSdk/interfaces/job-order/entityProviders/IJobOrdersControlsEntityProvider";

export class TodoListTaskJobOrderTags extends TodoListTaskTags
{
    @LazyImport(ProlifeSdk.EntityProviderServiceType)
    private entityProviderService : IEntityProviderService;

    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private resourcesManager: IHumanResourcesSettingsManager;

    public jobOrderSearchService: IControlsEntityProvider;
    
    public JobOrderId : ko.Observable<any> = ko.observable("");  //Per ora solo 1 commessa
    public JobOrdersIds : ko.ObservableArray<number> = ko.observableArray([]);

    public JobOrdersDataSource: JobOrdersDataSource;

    constructor(private task : TodoListActivity)
    {
        super(ProlifeSdk.JobOrderEntityTypeCode, "JOBORDER");

        this.jobOrderSearchService = this.entityProviderService.getEntityProvider(ProlifeSdk.JobOrderEntityTypeCode).getControlsProvider(); // DEPRECATO
        (<IJobOrdersControlsEntityProvider> this.jobOrderSearchService).setDeletedFilter(false);
        
        this.JobOrdersDataSource = new JobOrdersDataSource();
        this.JobOrdersDataSource.setShowClosed(false);
        this.JobOrdersDataSource.setViewFilters(true, true, this.authorizationsService.isAuthorized("JobOrders_ViewAllJobOrders"));
        this.JobOrdersDataSource.setWorkFilters(true);
        this.JobOrdersDataSource.setUserId(this.userInfoService.getIdUser());
        this.JobOrdersDataSource.setResourceId(this.resourcesManager.getLoggedResourceId());
        
        this.JobOrderId.subscribe(this.OnSelectionChanged.bind(this));
    }

    public LoadFromModel(tags : ITodoListTaskTag[])
    {
        super.LoadFromModel(tags);

        var jobOrdersIds : number[] = this.ExtractTagsByField(tags).map((t : ITodoListTaskTag) => { return parseInt(t.Value); });
        this.JobOrdersIds(jobOrdersIds);

        var matches : ITodoListTaskTag[] = this.ExtractTagsByField(tags);
        //this.JobOrderId(matches.length > 0 ? matches[0].Value.toString() : "");
        this.JobOrderId(matches.length > 0 ? parseInt(matches[0].Value) : null);
    }

    private OnSelectionChanged()
    {
        //var id : number = parseInt(this.JobOrderId());
        var id : number = this.JobOrderId();
        this.task.OnJobOrderSelectionChanged(isNaN(id) ? -1 : id);
    }

    public SetTagsOnCollection(list : ITodoListTaskTag[])
    {
        this.ExtractTagsByField(list)
            .forEach((t1 : ITodoListTaskTag) => { return list.splice(list.indexOf(t1), 1); });

        /*if(this.JobOrderId().length == 0)
            return [];

        list.push(TodoListTaskJobOrderTags.GetTag(this.JobOrderId()));*/

        if(!this.JobOrderId())
            return [];

        list.push(TodoListTaskJobOrderTags.GetTag(this.JobOrderId().toString()));

        return [];
    }

    public static GetTag(value : string)
    {
        return <ITodoListTaskTag>{
            Type : ProlifeSdk.JobOrderEntityTypeCode,
            Field : "JOBORDER",
            Value : value
        }
    }

    public ShowClosedJobOrders(value: boolean) {
        this.JobOrdersDataSource.setShowClosed(value);
    }
}

