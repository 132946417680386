import * as numeral from "numeral";
import * as ProlifeSdk from "./../../../../ProlifeSdk/ProlifeSdk"; 
import moment = require("moment");
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDataSourceView, IDataSourceModel } from "../../../../DataSources/IDataSource";
import { INavigationMenuComponentModel, INavigationMenuComponentDataSource, INavigationMenuComponent } from "../../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IResourceDailyPlanEx } from "../../../../ProlifeSdk/interfaces/worked-hours/IResourceDailyPlan";

export interface IYearNavigationMenuComponentModel extends INavigationMenuComponentModel {
    resourceId: number;
    isYear: boolean;
    year: number;

    isFullyLocked: boolean;
    isPartiallyLocked: boolean;
}

export interface IMonthNavigationMenuComponentModel extends INavigationMenuComponentModel {
    resourceId: number;
    isMonth: boolean;
    year: number;
    month: number;

    isLocked: boolean;
}

export interface IDayNavigationMenuComponentModel extends INavigationMenuComponentModel {
    resourceId: number;
    isDay: true;
    year: number;
    month: number;
    day: number;

    date: Date;
    dayNumber: string;
    isToday: boolean;

    hasExtraordinaryHours?: boolean;
    hasOverImbalance?: boolean;
    hasDownImbalance?: boolean;
    isHoliday?: boolean;
    thereIsAnAwayActivity?: boolean;
    thereIsAnOffsiteActivity?: boolean;
    detail?: string;
    totalHours?: string;
    numericTotalHours?: number;
    allApproved?: boolean;
    partiallyApproved?: boolean;
    hasFlexibility?: boolean;
    someRejected?: boolean;
    isLocked?: boolean;
}

type LockStatus = {
    [year: number]: YearLockStatus;
}

type YearLockStatus = {
    year: number;
    lockedMonths: MonthLockStatus;
    isFullyLocked: boolean;
    isPartiallyLocked: boolean;
}

type MonthLockStatus = {
    [month: number]: boolean;
}

export class DateDataSource implements INavigationMenuComponentDataSource {
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;

    protected showDays : boolean;
    protected showLockStatus : boolean;
    protected view: IDataSourceView;
    protected resourceId: number;

    protected hasPendingSelect = false;
    protected requestedMonth : Date;
    protected requestedDay : Date;

    private lockStatus: LockStatus = {};
    private lockStatusInitialized = false;

    constructor(showDays = true, showLockStatus = false) {
        this.showDays = showDays;
        this.showLockStatus = showLockStatus;
    }

    refresh() {
        this.internalRefresh();
    }

    private async internalRefresh(): Promise<void> {
        if (this.view) {
            await this.loadLockStatus();
            this.view.refresh();
        }
    }

    setView(view: IDataSourceView): void {
        this.view = view;

        if(this.hasPendingSelect && this.requestedMonth != null) {
            this.selectMonth(this.requestedMonth);
        }

        if (this.hasPendingSelect && this.requestedDay != null) {
            this.selectDay(this.requestedDay);
        }
    }

    select(...models: IDataSourceModel[]): Promise<void> {
        return Promise.resolve();
    }

    navigateTo(...history: IDataSourceModel[]): void {
    }

    setNavigator(navigator: INavigationMenuComponent): void {
        
    }

    getTitle(currentModel  : IDataSourceModel | null) : string {
        if(currentModel == null)
            return ProlifeSdk.TextResources.WorkedHours.DateDataSourceTitle;

        const yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if(yearModel.isYear)
            return yearModel.title;

        const monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if(monthModel.isMonth)
            return monthModel.title;

        return "";
    }

    isGroupedData(currentModel: INavigationMenuComponentModel, textFilter: string): boolean {
        return false;
    }    
    
    async getData(currentModel: INavigationMenuComponentModel, textFilter: string, skip: number, count: number): Promise<INavigationMenuComponentModel[]> {
        if (skip > 0)
            return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => resolve([]));

        if (this.showLockStatus && !this.lockStatusInitialized)
            await this.loadLockStatus();

        if (currentModel == null) {
            return this.getYearsData();
        }

        const yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if (yearModel.isYear)
            return this.getMonthsData(yearModel.year);

        const monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if (monthModel.isMonth)
            return this.getDaysData(monthModel.year, monthModel.month);

        throw new Error("DateDataSource: Unsupported Model Type");
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        if (currentModel == null) {
            return ids.map(id => this.createYear(id));
        }
        const yearModel = (currentModel as IYearNavigationMenuComponentModel);
        if (yearModel.isYear) {
            return ids.map(id => this.createMonth(yearModel.year, id));
        }
        const monthModel = (currentModel as IMonthNavigationMenuComponentModel);
        if (monthModel.isMonth) {
            const monthPlan : IResourceDailyPlanEx[] = await this.workedHoursService.GetResourceMonthPlan(monthModel.year, monthModel.month + 1, this.resourceId);
            return monthPlan.filter(m => ids.indexOf(m.Id) != -1).map(m => this.createDay(m.Id, monthModel.month, monthModel.year, m));
        }

        return [];
    }

    private async loadLockStatus(): Promise<void> {
        try {
            const lockStatus = await this.workedHoursService.GetLockStatusForMonths();
            const years = lockStatus.groupBy((s) => s.year);

            this.lockStatus = {};

            for (const year in years) {
                const lockedMonths = years[year];
                const numericYear = parseInt(year);

                this.lockStatus[numericYear] = {
                    year: numericYear,
                    isFullyLocked: lockedMonths.length === 12,
                    isPartiallyLocked: lockedMonths.length !== 12 && lockedMonths.length > 0,
                    lockedMonths: {}
                };

                for (const status of lockedMonths) {
                    this.lockStatus[numericYear].lockedMonths[status.month] = true;
                }
            }

            this.lockStatusInitialized = true;

        } catch (e) {
            console.log(e);
        }
    }
    
    getYearsData(): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            const years : INavigationMenuComponentModel[] = [];
            const nextYear = moment().year() + 1;
            const oldestYear = nextYear - 20;

            for(let year = nextYear; year >= oldestYear; year--) {
                const yearModel : IYearNavigationMenuComponentModel = this.createYear(year);

                years.push(yearModel);
            }

            resolve(years);
        });
    }

    getMonthsData(year : number): Promise<INavigationMenuComponentModel[]> {
        return new Promise<INavigationMenuComponentModel[]>((resolve, reject) => {
            const months: IMonthNavigationMenuComponentModel[] = [];

            for(let month = 0; month < 12; month++) {
                const monthModel : IMonthNavigationMenuComponentModel = this.createMonth(year, month)

                months.push(monthModel);
            }

            resolve(months);
        });
    }

    async getDaysData(year: number, month: number): Promise<INavigationMenuComponentModel[]> {
        const monthPlan : IResourceDailyPlanEx[] = await this.workedHoursService.GetResourceMonthPlan(year, month + 1, this.resourceId);
        
        const daysModels: IDayNavigationMenuComponentModel[] = [];
        monthPlan.forEach((p: IResourceDailyPlanEx) => {
            daysModels.push(this.createDay(p.Id, month, year, p));
        });
        
        return daysModels;
    }
    
    protected createDay(day: number, month: number, year: number, dailyPlan: IResourceDailyPlanEx = null): IDayNavigationMenuComponentModel {
        const calendarDate = moment(new Date()).year(year).month(month).date(day).toDate();
        const today = moment();

        const model: IDayNavigationMenuComponentModel = {
            id: day,
            resourceId: this.resourceId,
            isDay: true,
            isGroup: false,
            isLeaf: true,
            title: moment().year(year).month(month).date(day).format("dddd"),

            year: year,
            month: month,
            day: day,
            
            date: calendarDate,
            dayNumber: moment(calendarDate).format("DD"),
            isToday: today.year() == year && today.month() == month && today.date() == day
        };

        model.hasExtraordinaryHours = dailyPlan && dailyPlan.ExtraordinaryHours > 0;
        model.hasOverImbalance = dailyPlan && dailyPlan.OrdinaryHours > dailyPlan.ContractHours;
        model.hasDownImbalance = !dailyPlan || (dailyPlan.OrdinaryHours < dailyPlan.ContractHours && !dailyPlan.IsHoliday);
        model.isHoliday = dailyPlan && dailyPlan.IsHoliday;
        model.thereIsAnAwayActivity = dailyPlan && dailyPlan.ThereIsAnAwayActivity;
        model.thereIsAnOffsiteActivity = dailyPlan && dailyPlan.ThereIsAnOffsiteActivity;
        model.detail = dailyPlan ? dailyPlan.Detail : "";
        model.totalHours = dailyPlan ? numeral(dailyPlan.OrdinaryHours + dailyPlan.ExtraordinaryHours).format("0.00") : numeral(0).format("0.00");
        model.numericTotalHours = dailyPlan ? dailyPlan.OrdinaryHours + dailyPlan.ExtraordinaryHours : 0;
        model.allApproved = dailyPlan && dailyPlan.AllApproved;
        model.partiallyApproved = dailyPlan && dailyPlan.PartiallyApproved;
        model.hasFlexibility = dailyPlan && dailyPlan.HasFlexibility;
        model.someRejected = dailyPlan && dailyPlan.SomeRejected;
        model.isLocked = dailyPlan && dailyPlan.IsLocked;

        return model;
    }

    protected createYear(year: number): IYearNavigationMenuComponentModel {
        const yearModel: IYearNavigationMenuComponentModel = {
            id: year,
            resourceId: this.resourceId,
            isYear: true,
            year: year,
            isGroup: false,
            isLeaf: false,
            title: year.toString(),

            isFullyLocked: this.lockStatus[year]?.isFullyLocked ?? false,
            isPartiallyLocked: this.lockStatus[year]?.isPartiallyLocked ?? false
        };

        if (this.showLockStatus) {
            yearModel.icon = {
                icon: yearModel.isFullyLocked || yearModel.isPartiallyLocked ? "fa fa-lock" : "fa fa-unlock",
                background: "transparent",
                foreground: yearModel.isFullyLocked ? "red" : (yearModel.isPartiallyLocked ? "orange" : "#ddd")
            }
        }

        return yearModel;
    }

    protected createMonth(year: number, month: number): IMonthNavigationMenuComponentModel {
        const monthModel: IMonthNavigationMenuComponentModel =  {
            id: month,
            resourceId: this.resourceId,
            isMonth: true,
            year: year,
            month: month,
            isGroup: false,
            isLeaf: !this.showDays,
            title: String.capitalize(moment().year(year).month(month).date(1).format("MMMM YYYY")),

            isLocked: this.lockStatus[year]?.lockedMonths[month + 1] ?? false
        };

        if (this.showLockStatus) {
            monthModel.icon = {
                icon: monthModel.isLocked ? "fa fa-lock" : "fa fa-unlock",
                background: "transparent",
                foreground: monthModel.isLocked ? "red" : "#ddd"
            }
        }

        return monthModel;
    }

    public async selectMonth(startDate: Date) : Promise<void> {
        if (!this.view) {
            this.requestedMonth = startDate;
            this.hasPendingSelect = true;
            return;
        }

        const year = startDate.getFullYear();
        const month = startDate.getMonth();

        const yearModel = this.createYear(year);
        const monthModel = this.createMonth(year, month);

        this.view.navigateTo(yearModel);
        await this.view.select(monthModel);

        this.hasPendingSelect = false;
    }

    public async selectDay(startDate: Date) : Promise<void> {
        if (!this.showDays) {
            throw new Error("Cannot select a day item on a navigator not set to show days");
        }
        
        if (!this.view) {
            this.requestedDay = startDate;
            this.hasPendingSelect = true;
            return;
        }

        const year = startDate.getFullYear();
        const month = startDate.getMonth();
        const day = startDate.getDate();

        const yearModel = this.createYear(year);
        const monthModel = this.createMonth(year, month);
        const dayModel = this.createDay(day, month, year);

        this.view.navigateTo(yearModel, monthModel);
        await this.view.select(dayModel);

        this.hasPendingSelect = false;
    }

    public areEqual(a: INavigationMenuComponentModel, b: INavigationMenuComponentModel): boolean {
        if(a === b) return true;
        if(!a || !b) return false;
        
        const aAsMonth = a as IMonthNavigationMenuComponentModel;
        const bAsMonth = b as IMonthNavigationMenuComponentModel;

        if(aAsMonth.isMonth && bAsMonth.isMonth) {
            return aAsMonth.month == bAsMonth.month && aAsMonth.year == bAsMonth.year && aAsMonth.resourceId == bAsMonth.resourceId;
        }

        const aAsYear = a as IYearNavigationMenuComponentModel;
        const bAsYear = b as IYearNavigationMenuComponentModel;

        if(aAsYear.isYear && bAsYear.isYear) {
            return aAsYear.year == bAsYear.year && aAsYear.resourceId == bAsYear.resourceId;
        }

        const aAsDay = a as IDayNavigationMenuComponentModel;
        const bAsDay = b as IDayNavigationMenuComponentModel;

        if(aAsDay.isDay && bAsDay.isDay) {
            return aAsDay.day == bAsDay.day && aAsDay.month == bAsDay.month && aAsDay.year == bAsDay.year && aAsDay.resourceId == bAsDay.resourceId;
        }

        return false;
    }
    
    public setResourceId(id: number): void {
        this.resourceId = id;
    }

    public getSupportedDropMimeTypes() : string[] {
        return [];
    }
}