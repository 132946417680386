import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type ActivityIndicatorProps = {
    className?: string;
}

export function ActivityIndicator(props: ActivityIndicatorProps) {
    const C = require("./ActivityIndicator")._ActivityIndicator as typeof _ActivityIndicator;
    return <C {...props} />;
}

export class _ActivityIndicator {
    static defaultProps: Partial<ActivityIndicatorProps> = {
    }

    constructor(private props : ActivityIndicatorProps) {
        
    }
    
    render() {
        const classes = ComponentUtils.classNames("flex-container", "flex-child-center", this.props.className);
        return <div className={classes} style={{ justifyContent: 'center' }}><i className="fa fa-circle-o-notch fa-spin" /></div>;
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ActivityIndicator);
}