import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { LazyImport, useService } from "../../Core/DependencyInjection";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IOPAService } from "../../Core/interfaces/IOPAService";

type PromptProps = {
    isBlocking: ko.Observable<boolean> | ko.Computed<boolean>;
    message: string | (() => string);
    confirm: string | (() => string);
    cancel: string | (() => string);
}

export function Prompt(props: PromptProps) {
    const C = require("./Prompt")._Prompt as typeof _Prompt;
    return <C {...props} />;
}

export class _Prompt {
    static defaultProps: Partial<PromptProps> = {
    }
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IOPAService>())
    private opaService : IOPAService;


    private onBeforeLeave: () => Promise<boolean>;
    constructor(private props : PromptProps) {
        this.onBeforeLeave = this.handleBeforeLeave.bind(this);
    }

    componentDidMount() {
        this.opaService.BeforeLeaveAsync(this.onBeforeLeave);
    }

    componentWillUnmount() {
        this.opaService.RemoveBeforeLeaveAsync(this.onBeforeLeave);
    }

    private async handleBeforeLeave() : Promise<boolean> {
        if(!this.props.isBlocking()) return true;

        const message = typeof this.props.message === "function" ? this.props.message() : this.props.message;
        const confirm = typeof this.props.confirm === "function" ? this.props.confirm() : this.props.confirm;
        const cancel = typeof this.props.cancel === "function" ? this.props.cancel() : this.props.cancel;

        return await this.dialogsService.ConfirmAsync(message, cancel, confirm);
    }
    
    render() {
        return <div></div>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(Prompt);
}