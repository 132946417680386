import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 20/10/2016
 * Time: 14:44
 * To change this template use File | Settings | File Templates.
 */

export class TreeGridBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var $body, $header, $sidebar, $outer, $headerOuter, $sidebarOuter;
        $outer = $(element);
        $body = $(element).find('.fixedTable-body');
        $headerOuter = $(element).find('.fixedTable-header');
        $sidebar = $(element).find('.fixedTable-sidebar table');
        $sidebarOuter = $(element).find('.fixedTable-sidebar');
        $header = $(element).find('.fixedTable-header table');

        const observer = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
            $($headerOuter).css({'width': $(element).width() - $($sidebarOuter).width() - 20, 'margin-left': $($sidebarOuter).width() });
            $($body).css('width', $(element).width() -  $($sidebarOuter).width() - 20);
            $($body).css('height', $(element).height() - $($headerOuter).height());
            $($sidebarOuter).css('height', $($body).height());
        });
        observer.observe(element);

        /*$($outer).resize(() => {
            $($headerOuter).css({'width': $(element).width() - $($sidebarOuter).width() - 20, 'margin-left': $($sidebarOuter).width() });
            $($body).css('width', $(element).width() -  $($sidebarOuter).width() - 20);
            $($body).css('height', $(element).height() - $($headerOuter).height());
            $($sidebarOuter).css('height', $($body).height());
        });

        $($outer).trigger('resize');*/

        $($body).scroll(() => {
            $($sidebar).css('margin-top', -$($body).scrollTop());
            $($header).css('margin-left', -$($body).scrollLeft());
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            observer.unobserve(element);
        })
    }
}

ko.bindingHandlers["treeGrid"] = new TreeGridBinding();