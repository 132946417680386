import * as ko from "knockout";


function pageFlipIn(valueAccessor: () => any, element, index, data)
{
    //$(element).addClass("in");

    /*var halfWidth = $(element).outerWidth() / 2;
    var instance = Math.random();

    $(element)
        .filter(function() { return this instanceof HTMLElement })
        .css("-webkit-transform", "translate3d(" + halfWidth + "px, 0px, 0px) rotateY(180deg) translate3d(-" + halfWidth + "px, 0px, 0px)");

    setTimeout(function(){
        $(element)
            .filter(function() { return this instanceof HTMLElement })
            .css("transition", "-webkit-transform 1s linear")
            .css("-webkit-transform", "translate3d(" + halfWidth + "px, 0px, 0px) rotateY(0deg) translate3d(-" + halfWidth + "px, 0px, 0px)")
    }, 0);*/
}

function pageFlipOut(valueAccessor: () => any, element, index, data)
{
    ko.removeNode(element);

    /*var instance = Math.random();
    var halfWidth = $(element).outerWidth() / 2;

    $(element)
        .filter(function() { return this instanceof HTMLElement })
        .on('webkitTransitionEnd.' + instance, function()
        {
            ko.removeNode(element);
            $(element).off('webkitTransitionEnd.' + instance);
        })
        .css("-webkit-transform", "translate3d(" + halfWidth + "px, 0px, 0px) rotateY(180deg) translate3d(-" + halfWidth + "px, 0px, 0px)");*/

}

export class Booklet
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any
    {
        ko.applyBindingsToNode(element, {
            foreach: {
                data: valueAccessor(),
                afterAdd: pageFlipIn.bind(null, allBindingsAccessor) ,
                beforeRemove: pageFlipOut.bind(null, allBindingsAccessor)
            }
        }, viewModel);

        $(element).find('.flip-page').addClass("in");

        return {
            controlsDescendantBindings: true
        };
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void
    {
    }
}

ko.bindingHandlers["booklet"] = ko.bindingHandlers["booklet"] = new Booklet();
