import moment = require("moment");
import { LazyImport } from "../Core/DependencyInjection";
import { IPlanningService, IPlanningSnapshot } from "../ProlifeSdk/interfaces/planning/IPlanningService";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";

export type PlanningSnapshotsDataSourceModel = {

} & IDataSourceModel<number, IPlanningSnapshot>;

export class PlanningSnapshotsDataSource extends BaseDataSource<PlanningSnapshotsDataSourceModel, number> {
    @LazyImport(nameof<IPlanningService>())
    private planningService : IPlanningService;

    getTitle(currentModel: PlanningSnapshotsDataSourceModel): string {
        return "Snapshots";
    }

    async getData(currentModel: PlanningSnapshotsDataSourceModel, textFilter: string, skip: number, count: number): Promise<PlanningSnapshotsDataSourceModel[]> {
        const snapshots = await this.planningService.GetPlanningSnapshots(textFilter, skip, count);
        return snapshots.map(this.createModel, this);
    }

    async getById(currentModel: PlanningSnapshotsDataSourceModel, ids: number[]): Promise<PlanningSnapshotsDataSourceModel[]> {
        const snapshots = await this.planningService.GetPlanningSnapshotsByIds(ids);
        return snapshots.map(this.createModel, this);
    }

    private createModel(snapshot: IPlanningSnapshot) : PlanningSnapshotsDataSourceModel {
        return {
            id: snapshot.Id,
            title: snapshot.Title,
            subTitle: moment(snapshot.ReferenceDate).format("L"),
            isLeaf: true,
            isGroup: false,
            model: snapshot
        }
    }
}