import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IFileRepositoryService } from "../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { ITagHint } from "../../ProlifeSdk/interfaces/files/ITagHint";

export class TagsContextMenu
{
    private fileRepositoryService : IFileRepositoryService;
    private dialogsService : IDialogsService;

    SelectedTagFilter : ko.Observable<any> = ko.observable();

    constructor(serviceLocator : IServiceLocator, private onTagDeleteCallback : () => {}, private onTagChangeCallback : () => {})
    {
        this.fileRepositoryService = <IFileRepositoryService>serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
    }

    public toggleAdministration(tag : ITagHint)
    {
        this.fileRepositoryService.setAdministration(tag.LabelId, !tag.Administration)
            .then(() => {
                if(this.onTagChangeCallback)
                    this.onTagChangeCallback()
            });
    }

    public toggleCommercial(tag : ITagHint)
    {
        this.fileRepositoryService.setCommercial(tag.LabelId, !tag.Commercial)
            .then(() => {
                if(this.onTagChangeCallback)
                    this.onTagChangeCallback()
            });
    }

    public toggleTechnical(tag : ITagHint)
    {
        this.fileRepositoryService.setTechnical(tag.LabelId, !tag.Technical)
            .then(() => {
                if(this.onTagChangeCallback)
                    this.onTagChangeCallback()
            });
    }

    public deleteLabel(tag : ITagHint)
    {
        this.dialogsService.Confirm(ProlifeSdk.TextResources.FileRepository.SureDeleteTag,
            ProlifeSdk.TextResources.FileRepository.Cancel,
            ProlifeSdk.TextResources.FileRepository.SureDeleteTagConfirm,
            this.onDeleteLabel.bind(this, tag));
    }

    private onDeleteLabel(tag : ITagHint, result : boolean = true)
    {
        if(!result)
            return;

        this.fileRepositoryService.deleteTag(tag.LabelId)
            .then(() => {
                if(this.onTagDeleteCallback)
                    this.onTagDeleteCallback();
            });
    }

    public changeTagColor(tag : ITagHint, color : number)
    {
        this.fileRepositoryService.changeTagColor(tag.LabelId, color)
            .then(() => {
                if(this.onTagChangeCallback)
                    this.onTagChangeCallback()
            });
    }
}
