import {
    IContactReference,
    IContactTag,
    ICustomer,
    ICustomerRolePrice,
    ICustomerTrust,
    IFullCustomer,
    IOrganizationalUnit,
    IProtocolDefaultMetadataForCustomer,
    IProtocolDefaultValueForCustomer,
    IProtocolSettingsForCustomer,
    ITrustAttachment,
    IUoAddress,
} from "../ProlifeSdk/interfaces/customer/ICustomer";
import { ICustomerBank } from "../ProlifeSdk/interfaces/customer/ICustomerBank";
import { ILetterOfAttemptForEditor } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { IMetadata } from "../ProlifeSdk/interfaces/customer/IMetadata";
import {
    INewAddress,
    INewBank,
    INewContactReference,
    INewCustomer,
    INewElectronicInvoicingRecipientCode,
    INewLetterOfAttempt,
    INewMetadata,
    INewOrganizationalUnit,
    INewPaymentExtension,
    INewProtocolDefaultMetadata,
    INewProtocolDefaultValue,
    INewProtocolSetting,
    INewTag,
    INewTrust,
    INewTrustAttachment,
    INewUserCharacterPrice,
} from "../ProlifeSdk/interfaces/customer/INewCustomer";
import { IRecipientCode } from "../ProlifeSdk/interfaces/customer/IRecipientCode";
import { IPaymentExtension } from "../ProlifeSdk/interfaces/invoice/settings/IExpireModes";

export class CustomerMapper {
    toINewCustomer(fullCustomer: IFullCustomer): INewCustomer {
        const customer = fullCustomer.Customer;

        return {
            id: customer.IdCliente,
            businessName: customer.RagioneSociale,
            fiscalCode: customer.CF,
            taxCode: customer.PIVA,
            address: customer.Indirizzo,
            postalCode: customer.CAP,
            city: customer.Citta,
            province: customer.Provincia,
            state: customer.Stato,
            phoneNumber: customer.Telefono,
            fax: customer.FAX,
            email: customer.EMail,
            administrativeRef: customer.RifAmministrativo,
            gender: customer.Sesso,
            name: customer.Nome,
            surname: customer.Cognome,
            middleName: customer.SecondoNome,
            deleted: customer.Eliminato,
            municipality: customer.Comune,
            isCustomer: customer.IsCliente,
            isSupplier: customer.IsFornitore,
            isCompany: customer.IsCompany,
            isPrivateContact: customer.IsPrivateContact,
            qualification: customer.Qualification,
            notes: customer.Notes,
            creationUserId: customer.CreationUserId,
            formattedContactName: customer.FormattedContactName,
            pec: customer.PEC,
            fkCommercialResponsible: customer.FkCommercialResponsible,
            commercialResponsibleName: customer.CommercialResponsibleName,
            fkAdministrativeResponsible: customer.FkAdministrativeResponsible,
            administrativeResponsibleName: customer.AdministrativeResponsibleName,
            fkBlockCause: customer.FkBlockCause,
            blockedBy: customer.BlockedBy,
            creationDate: customer.CreationDate,
            lastModificationDate: customer.LastModificationDate,
            customUserCharacters: customer.CustomRoles,
            trustManagementEnabled: customer.TrustManagementEnabled,
            defaultIvaMode: customer.DefaultIvaMode,
            defaultPaymentExpiryTypeId: customer.DefaultPaymentExpiryTypeId,
            defaultPaymentExpiryType: customer.DefaultPaymentExpiryType,
            defaultPaymentTypeId: customer.DefaultPaymentTypeId,
            defaultPaymentAccountId: customer.DefaultPaymentAccountId,
            defaultPaymentAbi: customer.DefaultPaymentABI,
            defaultPaymentCab: customer.DefaultPaymentCAB,
            trustMovementsReferenceDate: customer.StartDocumentsEmissionDateForTrustMovements,
            customMaterialsCharacters: customer.CustomMaterialsRoles,
            publicAdministration: customer.PublicAdministration,
            electronicInvoicing: customer.ElectronicInvoicing,
            eoriCode: customer.EORICode,
            defaultOffset: customer.DefaultOffset,
            defaultPassivePaymentExpiryTypeId: customer.DefaultPassivePaymentExpiryTypeId,
            defaultPassivePaymentExpiryType: customer.DefaultPassivePaymentExpiryType,
            defaultPassivePaymentTypeId: customer.DefaultPassivePaymentTypeId,
            defaultPassivePaymentAccountId: customer.DefaultPassivePaymentAccountId,
            defaultPassivePaymentAbi: customer.DefaultPassivePaymentABI,
            defaultPassivePaymentCab: customer.DefaultPassivePaymentCAB,
            defaultOutcome: customer.DefaultOutcome,
            defaultCurrency: customer.DefaultCurrency,
            userCharactersPrices: customer.RolesPrices.map(this.toINewUserCharacterPrice, this),
            organizationalUnits: customer.OrganizationalUnits.map(this.toINewOrganizationalUnit, this),
            tags: customer.Tags.map(this.toINewTag, this),
            electronicInvoicingRecipientCodes: customer.RecipientCodesList.map(
                this.toINewElectronicInvoicingRecipientCode,
                this
            ),
            trusts: fullCustomer.Trusts.map(this.toINewTrust, this),
            lettersOfAttempts: fullCustomer.LettersOfAttempts.map(this.toINewLetterOfAttempt, this),
            paymentExtensions: fullCustomer.PaymentExtensions.map(this.toINewPaymentExtension, this),
        };
    }

    toINewUserCharacterPrice(rolePrice: ICustomerRolePrice): INewUserCharacterPrice {
        return {
            id: rolePrice.Id,
            salary: rolePrice.Salary,
            description: rolePrice.Description,
            fkCustomer: rolePrice.FkCustomer,
            fkUserCharacterId: rolePrice.FkUserCharacterId,
        };
    }

    toINewOrganizationalUnit(organizationalUnit: IOrganizationalUnit): INewOrganizationalUnit {
        return {
            id: organizationalUnit.Id,
            customerId: organizationalUnit.CustomerId,
            description: organizationalUnit.Description,
            forTechnical: organizationalUnit.ForTechnical,
            forAdministration: organizationalUnit.ForAdministration,
            forCommercial: organizationalUnit.ForCommercial,
            department: organizationalUnit.Department,
            qualification: organizationalUnit.Qualification,
            isDefault: organizationalUnit.IsDefault,
            creationUserId: null,
            fkCommercialResponsible: organizationalUnit.FkCommercialResponsible,
            commercialResponsibleName: organizationalUnit.CommercialResponsibleName,
            fkAdministrativeResponsible: organizationalUnit.FkAdministrativeResponsible,
            administrativeResponsibleName: organizationalUnit.AdministrativeResponsibleName,
            recipientCodeId: organizationalUnit.RecipientCodeId,
            code: organizationalUnit.Code,
            addresses: organizationalUnit.Addresses.map(this.toINewAddress, this),
            banks: organizationalUnit.Banche.map(this.toINewBank, this),
            referenceOrganizationalUnits: organizationalUnit.References.map(this.toINewContactReference, this),
            referencedOrganizationalUnits: organizationalUnit.ReferencedBy.map(this.toINewContactReference, this),
            bccsMailRecipients: organizationalUnit.bccsMailRecipients.slice(),
            ccsMailRecipients: organizationalUnit.ccsMailRecipients.slice(),
            tosMailRecipients: organizationalUnit.tosMailRecipients.slice(),
            metadata: organizationalUnit.Metadati.map(this.toINewMetadata, this),
            protocolsDefaultMetadata: organizationalUnit.ProtocolsDefaultMetadatas.map(
                this.toINewProtocolDefaultMetadata,
                this
            ),
            protocolsDefaultValues: organizationalUnit.ProtocolsDefaultValues.map(
                this.toINewProtocolDefaultValue,
                this
            ),
            protocolsSettings: organizationalUnit.ProtocolsSettings.map(this.toINewProtocolSetting, this),
        };
    }

    toINewAddress(address: IUoAddress): INewAddress {
        return {
            id: address.Id,
            organizationalUnitId: address.OrganizationalUnitId,
            streetAndNumber: address.Address,
            cap: address.CAP,
            city: address.City,
            province: address.Province,
            state: address.State,
            municipality: address.Municipality,
            label: address.Label,
        };
    }

    toINewBank(bank: ICustomerBank): INewBank {
        return {
            id: bank.Id,
            name: bank.Name,
            abi: bank.ABI,
            cab: bank.CAB,
            iban: bank.IBAN,
            organizationalUnitId: null,
        };
    }

    toINewContactReference(reference: IContactReference): INewContactReference {
        return {
            contactOrganizationalUnitId: reference.ContactOrganizationalUnitId,
            referencedOrganizationalUnitId: reference.ReferencedOrganizationalUnitId,
            referenceType: reference.ReferenceType,
        };
    }

    toINewMetadata(metadata: IMetadata): INewMetadata {
        return {
            id: null,
            fkCategory: metadata.FkCategoria,
            label: metadata.Label,
            value: metadata.Valore,
            organizationalUnitId: metadata.OrganizationalUnitId,
            logicalType: metadata.LogicalType,
        };
    }

    toINewProtocolDefaultMetadata(
        protocolDefaultMetadata: IProtocolDefaultMetadataForCustomer
    ): INewProtocolDefaultMetadata {
        return {
            id: protocolDefaultMetadata.Id,
            fkOrganizationalUnit: protocolDefaultMetadata.FKOrganizationalUnit,
            protocolId: protocolDefaultMetadata.ProtocolId,
            fkMetadata: protocolDefaultMetadata.FKMetadata,
            metadataValueType: protocolDefaultMetadata.MetadataValueType,
            showMarkerOnAllocationsGantt: protocolDefaultMetadata.ShowMarkerOnAllocationsGantt,
            preloadOnDocument: protocolDefaultMetadata.PreloadOnDocument,
        };
    }

    toINewProtocolDefaultValue(protocolDefaultValue: IProtocolDefaultValueForCustomer): INewProtocolDefaultValue {
        return {
            organizationalUnitId: protocolDefaultValue.OrganizationalUnitId,
            protocolId: protocolDefaultValue.ProtocolId,
            settingKey: protocolDefaultValue.SettingKey,
            intValue: protocolDefaultValue.IntValue,
            decimalValue: protocolDefaultValue.DecimalValue,
            stringValue: protocolDefaultValue.StringValue,
            dateValue: protocolDefaultValue.DateValue,
        };
    }

    toINewProtocolSetting(protocolSetting: IProtocolSettingsForCustomer): INewProtocolSetting {
        return {
            organizationalUnitId: protocolSetting.OrganizationalUnitId,
            protocolId: protocolSetting.ProtocolId,
            defaultValuesEnabled: protocolSetting.DefaultValuesEnabled,
            defaultMetadatasEnabled: protocolSetting.DefaultMetadatasEnabled,
        };
    }

    toINewTag(tag: IContactTag): INewTag {
        return {
            id: null,
            customerId: tag.CustomerId,
            value: tag.Value,
        };
    }

    toINewElectronicInvoicingRecipientCode(recipientCode: IRecipientCode): INewElectronicInvoicingRecipientCode {
        return {
            id: recipientCode.Id,
            customerId: recipientCode.CustomerId,
            recipientCode: recipientCode.RecipientCode,
            description: recipientCode.Description,
            pec: recipientCode.PEC,
            organizationalUnitsIds: recipientCode.OrganizationalUnitsIds.slice(),
        };
    }

    toINewTrust(trust: ICustomerTrust): INewTrust {
        return {
            id: trust.Id,
            customerId: trust.CustomerId,
            startDate: trust.StartDate,
            endDate: trust.EndDate,
            value: trust.Value,
            creationDate: trust.CreationDate,
            creatorId: trust.CreatorId,
            modifyDate: trust.ModifyDate,
            modifierId: trust.ModifierId,
            insuranceTrust: trust.InsuranceTrust,
            note: trust.Note,
            deleted: trust.Deleted,
            attachments: trust.Attachments.map(this.toINewTrustAttachment, this),
        };
    }

    toINewTrustAttachment(attachment: ITrustAttachment): INewTrustAttachment {
        return {
            id: attachment.Id,
            trustId: attachment.TrustId,
            attachmentGuid: attachment.AttachmentGuid,
        };
    }

    toINewLetterOfAttempt(letterOfAttempt: ILetterOfAttemptForEditor): INewLetterOfAttempt {
        return {
            id: letterOfAttempt.Id,
            description: letterOfAttempt.Description,
            startDate: letterOfAttempt.StartDate,
            dueDate: letterOfAttempt.DueDate,
            amount: letterOfAttempt.Amount,
            customerId: letterOfAttempt.CustomerId,
            note: letterOfAttempt.Note,
            manuallyClosed: letterOfAttempt.ManuallyClosed,
            deleted: letterOfAttempt.Deleted,
            revenueAgencyReceiptDate: letterOfAttempt.RevenueAgencyReceiptDate,
        };
    }

    toINewPaymentExtension(paymentExtension: IPaymentExtension): INewPaymentExtension {
        return {
            id: paymentExtension.Id,
            customerId: paymentExtension.CustomerId,
            extensionDate: paymentExtension.ExtensionDate,
            extensionValue: paymentExtension.ExtensionValue,
        };
    }

    toICustomer(customer: INewCustomer): ICustomer {
        return {
            IdCliente: customer.id,
            RagioneSociale: customer.businessName,
            CF: customer.fiscalCode,
            PIVA: customer.taxCode,
            Indirizzo: customer.address,
            CAP: customer.postalCode,
            Citta: customer.city,
            Provincia: customer.province,
            Stato: customer.state,
            Telefono: customer.phoneNumber,
            FAX: customer.fax,
            EMail: customer.email,
            RifAmministrativo: customer.administrativeRef,
            Sesso: customer.gender,
            Nome: customer.name,
            Cognome: customer.surname,
            SecondoNome: customer.middleName,
            Eliminato: customer.deleted,
            Comune: customer.municipality,
            IsCliente: customer.isCustomer,
            IsFornitore: customer.isSupplier,
            IsCompany: customer.isCompany,
            IsPrivateContact: customer.isPrivateContact,
            Qualification: customer.qualification,
            Notes: customer.notes,
            CreationUserId: customer.creationUserId,
            FormattedContactName: customer.formattedContactName,
            PEC: customer.pec,
            FkCommercialResponsible: customer.fkCommercialResponsible,
            CommercialResponsibleName: customer.commercialResponsibleName,
            FkAdministrativeResponsible: customer.fkAdministrativeResponsible,
            AdministrativeResponsibleName: customer.administrativeResponsibleName,
            FkBlockCause: customer.fkBlockCause,
            BlockedBy: customer.blockedBy,
            CreationDate: customer.creationDate,
            LastModificationDate: customer.lastModificationDate,
            CustomRoles: customer.customUserCharacters,
            TrustManagementEnabled: customer.trustManagementEnabled,
            DefaultIvaMode: customer.defaultIvaMode,
            DefaultPaymentExpiryTypeId: customer.defaultPaymentExpiryTypeId,
            DefaultPaymentExpiryType: customer.defaultPaymentExpiryType,
            DefaultPaymentTypeId: customer.defaultPaymentTypeId,
            DefaultPaymentAccountId: customer.defaultPaymentAccountId,
            DefaultPaymentABI: customer.defaultPaymentAbi,
            DefaultPaymentCAB: customer.defaultPaymentCab,
            StartDocumentsEmissionDateForTrustMovements: customer.trustMovementsReferenceDate,
            CustomMaterialsRoles: customer.customMaterialsCharacters,
            PublicAdministration: customer.publicAdministration,
            ElectronicInvoicing: customer.electronicInvoicing,
            EORICode: customer.eoriCode,
            DefaultOffset: customer.defaultOffset,
            DefaultPassivePaymentExpiryTypeId: customer.defaultPassivePaymentExpiryTypeId,
            DefaultPassivePaymentExpiryType: customer.defaultPassivePaymentExpiryType,
            DefaultPassivePaymentTypeId: customer.defaultPassivePaymentTypeId,
            DefaultPassivePaymentAccountId: customer.defaultPassivePaymentAccountId,
            DefaultPassivePaymentABI: customer.defaultPassivePaymentAbi,
            DefaultPassivePaymentCAB: customer.defaultPassivePaymentCab,
            DefaultOutcome: customer.defaultOutcome,
            DefaultCurrency: customer.defaultCurrency,
            RolesPrices: customer.userCharactersPrices.map(this.toICustomerRolePricePrice, this),
            OrganizationalUnits: customer.organizationalUnits.map(this.toIOrganizationalUnit, this),
            Tags: customer.tags.map(this.toIContactTag, this),
            RecipientCodesList: customer.electronicInvoicingRecipientCodes.map(this.toIRecipientCode, this),
        };
    }

    toICustomerRolePricePrice(rolePrice: INewUserCharacterPrice): ICustomerRolePrice {
        return {
            Id: rolePrice.id,
            Salary: rolePrice.salary,
            Description: rolePrice.description,
            FkCustomer: rolePrice.fkCustomer,
            FkUserCharacterId: rolePrice.fkUserCharacterId,
        };
    }

    toIOrganizationalUnit(organizationalUnit: INewOrganizationalUnit): IOrganizationalUnit {
        return {
            Id: organizationalUnit.id,
            CustomerId: organizationalUnit.customerId,
            Description: organizationalUnit.description,
            ForTechnical: organizationalUnit.forTechnical,
            ForAdministration: organizationalUnit.forAdministration,
            ForCommercial: organizationalUnit.forCommercial,
            Department: organizationalUnit.department,
            Qualification: organizationalUnit.qualification,
            IsDefault: organizationalUnit.isDefault,
            FkCommercialResponsible: organizationalUnit.fkCommercialResponsible,
            CommercialResponsibleName: organizationalUnit.commercialResponsibleName,
            FkAdministrativeResponsible: organizationalUnit.fkAdministrativeResponsible,
            AdministrativeResponsibleName: organizationalUnit.administrativeResponsibleName,
            RecipientCodeId: organizationalUnit.recipientCodeId,
            Code: organizationalUnit.code,
            Addresses: organizationalUnit.addresses.map(this.toIUoAddress, this),
            Banche: organizationalUnit.banks.map(this.toICustomerBank, this),
            References: organizationalUnit.referenceOrganizationalUnits.map(this.toIContactReference, this),
            ReferencedBy: organizationalUnit.referencedOrganizationalUnits.map(this.toIContactReference, this),
            bccsMailRecipients: organizationalUnit.bccsMailRecipients.slice(),
            ccsMailRecipients: organizationalUnit.ccsMailRecipients.slice(),
            tosMailRecipients: organizationalUnit.tosMailRecipients.slice(),
            Metadati: organizationalUnit.metadata.map(this.toIMetadata, this),
            ProtocolsDefaultMetadatas: organizationalUnit.protocolsDefaultMetadata.map(
                this.toIProtocolDefaultMetadata,
                this
            ),
            ProtocolsDefaultValues: organizationalUnit.protocolsDefaultValues.map(this.toIProtocolDefaultValue, this),
            ProtocolsSettings: organizationalUnit.protocolsSettings.map(this.toIProtocolSetting, this),
        };
    }

    toIUoAddress(address: INewAddress): IUoAddress {
        return {
            Id: address.id,
            OrganizationalUnitId: address.organizationalUnitId,
            Address: address.streetAndNumber,
            CAP: address.cap,
            City: address.city,
            Province: address.province,
            State: address.state,
            Municipality: address.municipality,
            Label: address.label,
        };
    }

    toICustomerBank(bank: INewBank): ICustomerBank {
        return {
            Id: bank.id,
            Name: bank.name,
            ABI: bank.abi,
            CAB: bank.cab,
            IBAN: bank.iban,
        };
    }

    toIContactReference(reference: INewContactReference): IContactReference {
        return {
            ContactOrganizationalUnitId: reference.contactOrganizationalUnitId,
            ReferencedOrganizationalUnitId: reference.referencedOrganizationalUnitId,
            ReferenceType: reference.referenceType,
        };
    }

    toIMetadata(metadata: INewMetadata): IMetadata {
        return {
            FkCategoria: metadata.fkCategory,
            Label: metadata.label,
            Valore: metadata.value,
            OrganizationalUnitId: metadata.organizationalUnitId,
            LogicalType: metadata.logicalType,
        };
    }

    toIProtocolDefaultMetadata(
        protocolDefaultMetadata: INewProtocolDefaultMetadata
    ): IProtocolDefaultMetadataForCustomer {
        return {
            Id: protocolDefaultMetadata.id,
            FKOrganizationalUnit: protocolDefaultMetadata.fkOrganizationalUnit,
            ProtocolId: protocolDefaultMetadata.protocolId,
            FKMetadata: protocolDefaultMetadata.fkMetadata,
            MetadataValueType: protocolDefaultMetadata.metadataValueType,
            ShowMarkerOnAllocationsGantt: protocolDefaultMetadata.showMarkerOnAllocationsGantt,
            PreloadOnDocument: protocolDefaultMetadata.preloadOnDocument,
        };
    }

    toIProtocolDefaultValue(protocolDefaultValue: INewProtocolDefaultValue): IProtocolDefaultValueForCustomer {
        return {
            OrganizationalUnitId: protocolDefaultValue.organizationalUnitId,
            ProtocolId: protocolDefaultValue.protocolId,
            SettingKey: protocolDefaultValue.settingKey,
            IntValue: protocolDefaultValue.intValue,
            DecimalValue: protocolDefaultValue.decimalValue,
            StringValue: protocolDefaultValue.stringValue,
            DateValue: protocolDefaultValue.dateValue,
        };
    }

    toIProtocolSetting(protocolSetting: INewProtocolSetting): IProtocolSettingsForCustomer {
        return {
            OrganizationalUnitId: protocolSetting.organizationalUnitId,
            ProtocolId: protocolSetting.protocolId,
            DefaultValuesEnabled: protocolSetting.defaultValuesEnabled,
            DefaultMetadatasEnabled: protocolSetting.defaultMetadatasEnabled,
        };
    }

    toIContactTag(tag: INewTag): IContactTag {
        return {
            CustomerId: tag.customerId,
            Value: tag.value,
        };
    }

    toIRecipientCode(recipientCode: INewElectronicInvoicingRecipientCode): IRecipientCode {
        return {
            Id: recipientCode.id,
            CustomerId: recipientCode.customerId,
            RecipientCode: recipientCode.recipientCode,
            Description: recipientCode.description,
            PEC: recipientCode.pec,
            OrganizationalUnitsIds: recipientCode.organizationalUnitsIds.slice(),
        };
    }
}
