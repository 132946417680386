import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 24/04/2018
 * Time: 05:54
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAgendaTimetableViewModel } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";

export class SelectTimetableForVariationDialog implements IDialog {
    public templateName: string = "timetable-selection";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string = ProlifeSdk.TextResources.Agenda.SelectTimetableDialogTitle;

    modal: { close: (result?: any) => void; };

    public Timetables: ko.ObservableArray<TimetableViewModelForSelection> = ko.observableArray([]);
    public SelectedTimetable: ko.Computed<TimetableViewModelForSelection>;

    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private timetables: IAgendaTimetableViewModel[]) {
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.SelectedTimetable = ko.computed(() => {
            var tt: TimetableViewModelForSelection[] = this.Timetables().filter((t: TimetableViewModelForSelection) => { return t.Selected(); });
            if (tt.length == 0)
                return null;
            return tt[0];
        });

        this.Timetables(this.timetables.map((t: IAgendaTimetableViewModel) => { return new TimetableViewModelForSelection(t, this); }));
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        if (!this.SelectedTimetable()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.SelectTimetable);
            return;
        }
        this.modal.close(this.SelectedTimetable().timetable);
    }

    public SelectTimetable(timetable: TimetableViewModelForSelection): void {
        this.Timetables().forEach((t: TimetableViewModelForSelection) => {
            t.Selected(false);
        });

        timetable.Selected(true);
    }
}

export class TimetableViewModelForSelection {
    public Selected: ko.Observable<boolean> = ko.observable(false);

    constructor(public timetable: IAgendaTimetableViewModel, private parent: SelectTimetableForVariationDialog) {

    }

    public Select(): void {
        this.parent.SelectTimetable(this);
    }
}