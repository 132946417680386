import * as ko from "knockout";
import { SpeedFilter } from "./SpeedFilter";

export class CreatedBySystemFilter extends SpeedFilter
{
    private excludeSystemEvents : ko.Observable<boolean> = ko.observable(true);

    constructor(private onApply : () => void)
    {
        super();
    }

    public switchFilter()
    {
        this.excludeSystemEvents(!this.excludeSystemEvents());

        if (this.onApply)
            this.onApply();
    }

    public getFilter() : boolean
    {
        return this.excludeSystemEvents();
    }
}
