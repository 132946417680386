import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { BlogBooklet } from "./BlogBooklet";
import { LazyImport } from "../../Core/DependencyInjection";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplication, IApplicationContextAction } from "../../Desktop/interfaces/IApplication";

export class BlogApplication implements IApplication
{
    public templateName : string = '';
    public templateUrl : string = 'blog/Templates';
    public templateData : BlogBooklet;
    public tileColor : string = 'tile-green';

    @LazyImport(nameof<IApplicationsService>())
    private applicationsService : IApplicationsService;
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    public contextActions: ko.ObservableArray<IApplicationContextAction> = ko.observableArray([]);

    public canShowSidebar: ko.Observable<boolean> = ko.observable(true);

    constructor(private serviceLocator : IServiceLocator)
    {
        this.applicationsService.registerApplication(this);

        /*this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Work),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-clock-o"),
            Run: () => { this.templateData.NewWork(); }
        });*/
        this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Purchase),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-shopping-cart"),
            Run: () => { this.templateData.NewMaterial(); }
        });
        this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Mail),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-envelope"),
            Run: () => { this.templateData.NewMail(); }
        });
        this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Call),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-phone"),
            Run: () => { this.templateData.NewCall(); }
        });
        this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Meeting),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-users"),
            Run: () => { this.templateData.NewMeeting(); }
        });
        this.contextActions.push({
            Name: ko.observable(ProlifeSdk.TextResources.Blog.Decision),
            CanRun: ko.observable(true),
            Icon: ko.observable("fa-flag"),
            Run: () => { this.templateData.NewDecision(); }
        });
    }

    openContextMenu(){

    }

    onGoingDown()
    {
        this.templateData.dispose();
        this.templateData = null;
        this.templateName = "";
    }

    getApplicationCode() {
        return "Blog";
    }

    getName(): string {
        return ProlifeSdk.TextResources.Blog.Blog;
    }

    getIcon(): string {
        return "icon-bubbles";
    }

    registerRoutes() : void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/WorkBook", (context) => this.start());
        opaService.Get("#/WorkBook/JobOrders/:ids/Tasks/:tasks", (context) => this.startAndFilterOnTasks(context.params["ids"], context.params["tasks"]));
        opaService.Get("#/WorkBook/:id", (context) => this.start(parseInt(context.params["id"])));
        opaService.BeforeLeave("#/WorkBook", () => {
            return this.templateData.onBeforeUnload.apply(this.templateData);
        });
    }

    getApplicationRoute() : string {
        return "#/WorkBook";
    }

    private startAndFilterOnTasks(jobOrders : string, tasks : string)
    {
        var tasksIds : number[] = tasks.split("_").map((c : string) => { return parseInt(c); });
        var jobOrdersIds : number[] = jobOrders.split("_").map((c : string) => { return parseInt(c); });

        var viewModel = this.templateData || new BlogBooklet(this.serviceLocator);
        this.templateName = viewModel.templateName;
        this.templateData = viewModel;
        this.applicationsService.startApplication(this);

        if (jobOrdersIds.length > 0 && tasksIds.length > 0) {
            setTimeout(() => viewModel.selectJobOrdersAndTasks(jobOrdersIds, tasksIds), 500);
        }
    }

    private start(id? : number) {

        var viewModel = this.templateData || new BlogBooklet(this.serviceLocator);
        this.templateName = viewModel.templateName;
        this.templateData = viewModel;

        this.desktopService.SystemHeader.setContextMenu({ templateName: "blog-right-menu", templateUrl: "Blog/Templates", viewModel: this.templateData });
        this.applicationsService.startApplication(this);

        if (id) {
            setTimeout(() => viewModel.navigateToJobOrderId(id), 500);
        }
    }

}
