import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { Document } from "../../../../Invoices/invoices/documents/Document";
import { IJobOrderService } from "../../../interfaces/job-order/IJobOrderService";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";

export class DueDateVariationAutomationDialog implements IDialog {
    templateName: string = "dueDateVariationAutomation";
    templateUrl: string = "prolifesdk/templates/documents/automations";
    title: string = "Variazione Date di Consegna";
    modal: { close: (result?: any) => void; };

    public DueDate: ko.Observable<Date> = ko.observable();
    public Notes : ko.Observable<string> = ko.observable();

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;

    constructor(private document : Document) {

    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        
    }

    public async setDueDateVariations() : Promise<void> {
        for(let row of this.document.Rows()) {
            if(row.DueDate()) {
                await this.jobOrderService.insertDocumentExpiryVariation(row.Id(), row.Document.DocumentType(), this.DueDate(), this.Notes())
                    .then(() => {
                        row.HasDueDateChanges(true);
                    });
            }
        }

        this.modal.close(true);
    }
}