/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 06/02/13
 * Time: 16.20
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { SettingsMenu } from "./SettingsMenu";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { ISettingsViewModel } from "../../interfaces/ISettingsViewModel";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { ReactNode } from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../../../Core/DependencyInjection";

export class SettingsViewModel implements ISettingsViewModel {
    templateUrl = "settings/templates";

    public menu: SettingsMenu;
    public settingsEditor: ko.Observable<IView | { render(): ReactNode<any> }> = ko.observable();
    public selectedManager: ko.Observable<any> = ko.observable();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.menu = new SettingsMenu(this.serviceLocator, this);
    }

    dispose() {
        this.menu.dispose();
        delete this.menu;
        this.menu = null;
    }

    public goHome() {
        location.href = "#/";
    }

    setEditingView(settingsManager: ISettingsManager): void {
        const oldEditor = this.settingsEditor() as IView;
        if (oldEditor && oldEditor.viewModel && oldEditor.viewModel.isChanged && oldEditor.viewModel.isChanged()) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.Settings.ConfirmLooseChanges,
                ProlifeSdk.TextResources.Settings.DoNotLooseChanges,
                ProlifeSdk.TextResources.Settings.LooseChanges,
                this.changeEditor.bind(this, settingsManager)
            );
        } else {
            this.changeEditor(settingsManager);
        }
    }

    private changeEditor(settingsManager: ISettingsManager, result = true) {
        if (!result) return;
        const editingView = settingsManager.getEditingUI();
        this.settingsEditor(editingView);
        this.selectedManager(settingsManager);
    }
}
