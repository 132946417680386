import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IMetadataService, IMetadata, IFullMetadata, IGetMetadatasRequest } from "../../MetadataService";
import { Right } from "../../../Core/Authorizations";
import { MetadataEditorUI } from "./ui/MetadataEditor";
import { MetadataType } from "../enums/MetadataType";

type MetadataTypeLabelsDictionary = { [type: string]: string };

export type MetadataTypeDescriptor = {
    ValueType: MetadataType;
    TypeLabel: string;
};

export interface IMetadataSettingsManager extends ISettingsManager {
    getMetadata(id?: number, includeDeletedListValues?: boolean): IFullMetadata;
    getMetadatas(getDeleted?: boolean, includeDeletedListValues?: boolean): IFullMetadata[];
    createOrUpdateMetadatas(metadatas: IFullMetadata[]): Promise<IFullMetadata[]>;

    getValueTypeLabel(type: MetadataType): string;
    getMetadataTypeDescriptors(): MetadataTypeDescriptor[];
}

export class MetadataSettingsManager implements IMetadataSettingsManager {
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    @LazyImport(nameof<IMetadataService>())
    private metadataService: IMetadataService;

    private metadata: IFullMetadata[] = [];
    private metadataTypeLabelsDictionary: MetadataTypeLabelsDictionary = {};

    @Right("Documents_ManageDocumentsMetadata")
    private canManageMetadatas: boolean;

    constructor() {
        this.settingsService.registerSettingsManager(this, TextResources.Invoices.AdministrativeDocumentsSettingsGroup);

        this.populateMetadataTypeLabelsDictionary();
    }

    public async load(): Promise<void> {
        try {
            const request: IGetMetadatasRequest = {
                textFilter: null,
                getDeleted: true,
                protocolId: null,
                includeDeletedListValues: true,
                skip: null,
                count: null,
            };
            const metadatas = await this.metadataService.GetMetadatas(request);
            this.metadata = this.createFullMetadatas(metadatas);
        } catch (e) {
            console.error(TextResources.Invoices.MetadatasLoadingError);
        }
    }

    public getName(): string {
        return nameof<IMetadataSettingsManager>();
    }

    public getLabel(): string {
        return TextResources.Invoices.MetadatasSettingsManagerName;
    }

    public hasEditingUI(): boolean {
        return this.canManageMetadatas;
    }

    public getEditingUI(): IView {
        const notDeletedMetadatas = this.getMetadatas();
        return new MetadataEditorUI({ metadata: notDeletedMetadatas });
    }

    public getMetadata(id: number, includeDeletedListValues = false): IFullMetadata {
        if (!id) return null;

        const metadatum = this.metadata.firstOrDefault((m) => m.Id === id);

        if (!metadatum || includeDeletedListValues) return metadatum;

        if (!includeDeletedListValues && !!metadatum) {
            const metadatumCopy = Object.assign({}, metadatum);
            metadatumCopy.ListValues = metadatum.ListValues.filter((lv) => !lv.Deleted);

            return metadatumCopy;
        }

        return null;
    }

    public getMetadatas(getDeleted = false, includeDeletedListValues = false): IFullMetadata[] {
        const metadata = this.metadata.filter((m) => getDeleted || !m.Deleted);

        if (!includeDeletedListValues) {
            const metadataCopies = [];

            for (const metadatum of metadata) {
                const metadatumCopy = Object.assign({}, metadatum);
                metadatumCopy.ListValues = metadatum.ListValues.filter((lv) => !lv.Deleted);
                metadataCopies.push(metadatum);
            }

            return metadataCopies;
        }

        return metadata;
    }

    public async createOrUpdateMetadatas(metadatas: IFullMetadata[]): Promise<IFullMetadata[]> {
        const { Metadatas, MetadatasListValues } = this.createMetadatasToSave(metadatas);
        const savedMetadatas = await this.metadataService.CreateOrUpdateMetadata(Metadatas, MetadatasListValues);
        const fullMetadatas = this.createFullMetadatas(savedMetadatas);
        this.updateLocalData(fullMetadatas);
        return this.getMetadatas();
    }

    public getValueTypeLabel(type: MetadataType): string {
        return this.metadataTypeLabelsDictionary[type];
    }

    public getMetadataTypeDescriptors(): MetadataTypeDescriptor[] {
        return [
            { ValueType: MetadataType.Text, TypeLabel: this.getValueTypeLabel(MetadataType.Text) },
            { ValueType: MetadataType.Integer, TypeLabel: this.getValueTypeLabel(MetadataType.Integer) },
            { ValueType: MetadataType.Decimal, TypeLabel: this.getValueTypeLabel(MetadataType.Decimal) },
            { ValueType: MetadataType.DateTime, TypeLabel: this.getValueTypeLabel(MetadataType.DateTime) },
            { ValueType: MetadataType.List, TypeLabel: this.getValueTypeLabel(MetadataType.List) },
        ];
    }

    private createFullMetadatas(metadatas: IMetadata): IFullMetadata[] {
        const fullMetadatas = [];

        for (const metadata of metadatas.Metadatas) {
            const fullMetadata = Object.assign({}, metadata) as IFullMetadata;
            fullMetadata.ListValues = metadatas.MetadatasListValues.filter((m) => m.FKMetadata === metadata.Id);
            fullMetadatas.push(fullMetadata);
        }

        return fullMetadatas;
    }

    private createMetadatasToSave(metadatas: IFullMetadata[]): IMetadata {
        const result = { Metadatas: [], MetadatasListValues: [] };
        let fakeId = -1;
        for (const fm of metadatas) {
            if (!fm.Id) fm.Id = fakeId--;

            result.Metadatas.push(fm);
            fm.ListValues.forEach((lv) => (lv.FKMetadata = fm.Id));

            result.MetadatasListValues = result.MetadatasListValues.concat(
                fm.ListValues.map((lv) => Object.assign({}, lv))
            );
        }

        return result;
    }

    private updateLocalData(savedMetadatas: IFullMetadata[]) {
        for (const newData of savedMetadatas) {
            let found = false;

            for (let i = 0; i < this.metadata.length; i++) {
                const localData = this.metadata[i];
                if (newData.Id === localData.Id) {
                    this.metadata[i] = newData;
                    found = true;
                    break;
                }
            }

            if (!found) this.metadata.push(newData);
        }
    }

    private populateMetadataTypeLabelsDictionary() {
        this.metadataTypeLabelsDictionary[MetadataType.Text] = TextResources.Invoices.TextMetadataLabel;
        this.metadataTypeLabelsDictionary[MetadataType.Integer] = TextResources.Invoices.IntegerMetadataLabel;
        this.metadataTypeLabelsDictionary[MetadataType.Decimal] = TextResources.Invoices.DecimalMetadataLabel;
        this.metadataTypeLabelsDictionary[MetadataType.DateTime] = TextResources.Invoices.DateTimeMetadataLabel;
        this.metadataTypeLabelsDictionary[MetadataType.List] = TextResources.Invoices.ListMetadataLabel;
    }
}
