import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { CustomersPaymentExtensionsDataSource } from "../../../../DataSources/CustomersPaymentExtensions";
import { Table } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { IDataSourceListener, IDataSourceModel, IDataSource } from "../../../../DataSources/IDataSource";
import { ICustomerPaymentExtension } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../../Core/DependencyInjection";

export class AllCustomersPaymentExtensionsDialog extends DialogComponentBase implements IDataSourceListener {
    public CustomerId: ko.Observable<number> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();

    public CustomersDataSource: CustomersDataSource = new CustomersDataSource();
    public PaymentExtensionsDataSource: CustomersPaymentExtensionsDataSource = new CustomersPaymentExtensionsDataSource();

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        super({ className: "large", noPrompt: true });

        this.title(TextResources.Customers.AllCustomersPaymentExtensionsDialogTitle);

        this.CustomersDataSource.setReturnGroupedData(false);

        this.StartDate.subscribe((date: Date) => {
            this.PaymentExtensionsDataSource.setStartDateFilter(date);
            this.PaymentExtensionsDataSource.refresh();
        });

        this.EndDate.subscribe((date: Date) => {
            this.PaymentExtensionsDataSource.setEndDateFilter(date);
            this.PaymentExtensionsDataSource.refresh();
        });
    }

    public show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }
    
    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.CustomersDataSource) {
            this.PaymentExtensionsDataSource.setCustomerIdFilter((model?.id) as number);
            this.PaymentExtensionsDataSource.refresh();
        }
    }
    
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.CustomersDataSource) {
            this.PaymentExtensionsDataSource.setCustomerIdFilter(null);
            this.PaymentExtensionsDataSource.refresh();
        }
    }

    renderBody() {
        let vm: AllCustomersPaymentExtensionsDialog;
        let model: ICustomerPaymentExtension;

        return ComponentUtils.bindTo((
            <div class="flex-container flex-vertical flex-full-height">
                <div class="flex-container search-bar">
                    <div class="flex-1">
                        <select2 value={() => "CustomerId"} dataSource={() => "CustomersDataSource"} listener={() => "$data"} label={TextResources.Customers.Customer} placeholder={TextResources.ProlifeSdk.SearchPlaceholder} allowClear={true}></select2>
                    </div>
                    <div class="flex-1">
                        <label>{TextResources.Customers.StartDateFilter}</label>
                        <input type="text" class="form-control" data-bind={{ nullableDatePickerEx: vm.StartDate, customDateTimeFormat: 'DD/MM', dayViewHeaderFormat: 'MMMM' }} placeholder={TextResources.Customers.StartDateFilter}></input>
                    </div>
                    <div class="flex-1">
                        <label>{TextResources.Customers.EndDateFilter}</label>
                        <input type="text" class="form-control" data-bind={{ nullableDatePickerEx: vm.EndDate, customDateTimeFormat: 'DD/MM', dayViewHeaderFormat: 'MMMM' }} placeholder={TextResources.Customers.EndDateFilter}></input>
                    </div>
                </div>
                <div class="flex-container flex-vertical flex-fill">
                    <Table bordered scrollable striped dataSource={this.PaymentExtensionsDataSource}>
                        <Column title={TextResources.Customers.Customer}>
                            <span data-bind={{ text: model.CustomerName }}></span>
                        </Column>
                        <Column title={TextResources.Customers.CustomerVatNumber}>
                            <span data-bind={{ text: model.CustomerVatNumber }}></span>
                        </Column>
                        <Column title={TextResources.Customers.CustomerTaxNumber}>
                            <span data-bind={{ text: model.CustomerTaxCode }}></span>
                        </Column>
                        <Column title={TextResources.Customers.CustomerPaymentExtensionPaymentDate}>
                            <span data-bind={{ dateText: model.ExtensionDate, customDateTimeFormat: 'DD/MM' }}></span>
                        </Column>
                        <Column title={TextResources.Customers.CustomerPaymentExtensionDaysNumber}>
                            <span data-bind={{ numberText: model.ExtensionValue, format: '0,0' }}></span>
                        </Column>
                    </Table>
                </div>
            </div>
        ), this, "vm");
    }
}