import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { DocumentRow } from "../Invoices/invoices/documents/DocumentRows";
import { IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider } from "../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { ISelectedEntityInlineInfo, IEntityForRef } from "../ProlifeSdk/interfaces/invoice/IDocumentRow";

interface IDocumentRowHelperValue {
    Document: ko.Observable<IDocumentRowHelperDocument>;
    Listener: ko.Observable<IDocumentRowHelperListener>;
    Row: DocumentRow;
}

interface IDocumentRowHelper {
    Data: IDocumentRowHelperValue;
    Providers: IDocumentRowInlineReferenceProviderFactory[];
    lastTimeout: number;
}

export interface IDocumentRowHelperRow {
    Description: ko.Observable<string>;
    OnEntitySelected(entity : ISelectedEntityInlineInfo | null, description : string) : Promise<void>;
}

export interface IDocumentRowHelperListener {
    OnEntitySearchOnRowStarted(provider : IDocumentRowInlineReferenceProviderFactory, query : string);
    OnEntitySearchOnRowFinished(provider : IDocumentRowInlineReferenceProviderFactory, query : string);
}

export interface IDocumentRowHelperDocument {
    DocumentType: ko.Observable<string>;
    CanReferenceArticles : ko.Observable<boolean>;
    GetDocumentInfoForInlineRefProvider() : IDocumentInfoForInlineRefProvider;
    RemoveRow(row : DocumentRow): void;
}

function findEntities(query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) {

    if((query || "").length == 0) {
        setTimeout(() => {
            asyncProcess([]);
        }, 0);
        return;
    }

    //Quando l'utente scrive chiocciola mostro l'elenco dei provider disponibili
    if(query == "@")
    {
        setTimeout(() => {
            const entities = this.Providers
                .sort((a, b) => { return a.ActivationCode < b.ActivationCode ? -1 : 1; })
                .map(p => DocumentRowHelper.transformEntity({ 
                    Id : null, 
                    EntityType : "PROVIDER",
                    Code: p.ActivationCode,
                    Description : " - " + p.Description,
                    Icon: {
                        background: "white",
                        foreground: "black",
                        icon: p.Icon
                    }
                }, p));

            asyncProcess(entities);
        }, 0);
        return;
    }

    const activationCode = (query && query.length > 1 ? query.substring(0, 3) : "").toUpperCase();
    const provider: IDocumentRowInlineReferenceProviderFactory = this.Providers.firstOrDefault(p => p.ActivationCode == activationCode);
    const adaptedQuery = ((query || "").length > 0 && query[0] == "@" ? query.substring(3) : query).trim(); //Rimuovo dalla testa l'activation code

    if(adaptedQuery.length == 0 || provider == null || (!this.Data.Document().CanReferenceArticles() && provider.EntityType === ProlifeSdk.WarehouseArticleEntityTypeCode)) {
        setTimeout(() => {
            asyncProcess([]);
        }, 0);
        return;
    }

    if(this.lastTimeout)
        clearTimeout(this.lastTimeout);

    this.lastTimeout = setTimeout(() => {
        if(this.Data.Listener && this.Data.Listener())
            this.Data.Listener().OnEntitySearchOnRowStarted(provider, adaptedQuery);

        provider.findEntities(adaptedQuery, 0, 50, this.Data.Document().GetDocumentInfoForInlineRefProvider())
            .then((entities : IEntityForRef[]) => {
                if(this.Data.Listener && this.Data.Listener())
                    this.Data.Listener().OnEntitySearchOnRowFinished(provider, adaptedQuery);

                const result = entities.map(e => DocumentRowHelper.transformEntity(e, provider));
                return asyncProcess(result);
            });
    }, 1000);
}

export class DocumentRowHelper implements IDocumentRowHelper
{
    Data: IDocumentRowHelperValue;
    Providers: IDocumentRowInlineReferenceProviderFactory[];
    lastTimeout: number;

    init(element: any, valueAccessor: () => IDocumentRowHelperValue, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        const params = ko.unwrap(valueAccessor());
        const documentsService : IDocumentsService = Core.serviceLocator.findService(nameof<IDocumentsService>());
        const entitiesReferencesProviders = documentsService.getRowReferencesGeneratorFactories(params.Document().DocumentType());

        const self : IDocumentRowHelper = {
            Data: params,
            Providers: entitiesReferencesProviders,
            lastTimeout: 0
        };
        $(element).data("documentRowHelper", self);

        const descriptionSub = self.Data.Row.Description.subscribe((value) => {
            typeahead.typeahead('val', value);
        });
        $(element).val(self.Data.Row.Description());

        const typeahead = ($(element) as any).typeahead({
            items: 50,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            display: (json : string) => {
                var item = JSON.parse(json);
                if(!item)
                    return "";
                if(item.EntityType == "PROVIDER")
                    return item.ActivationCode;
                if(item.Code)
                    return item.Code + " " + item.Description;
                return item.Description;
            },
            limit: 1000,
            source : findEntities.bind(self),
            templates : {
                suggestion : function(json)
                {
                    const data = JSON.parse(json);
                    let template = `<div style="border-bottom: 1px solid #DDDDDD">
                                        <table style="min-height: ${data.SubTitle ? '32px' : '16px'}; table-layout: fixed">
                                            <tbody>
                                                <tr>`;
                    if(data.Icon)
                        template +=                 `<td rowspan="2" style="padding-right: 5px; vertical-align: top !important; width: 32px">
                                                        <span style="background-color: ${data.Icon.background}; color: ${data.Icon.foreground}; text-align: center; display: inline-block; height: 18px; width: 18px; line-height: 18px;" class="${data.Icon.icon}"></span>
                                                    </td>`;
                    
                    if(data.Code == null)
                        template +=                     `<td><small>${data.Description}<small></td>`;
                    else
                        template +=                     `<td>${data.Code}&nbsp;<small>${data.Description}<small></td>`;
                        
                    template += `                </tr>`;
                    if(data.SubTitle)
                        template +=             `<tr>
                                                    <td colspan="2"><small class="text-muted">${data.SubTitle}</span></td>
                                                </tr>`;
                    template +=             `</tbody>
                                        </table>
                                    </div>`;
                    return template;
                }
            }

        }).on("typeahead:selected", (event, json) => {
            const item = JSON.parse(json);

            if(!item)
            {
                self.Data.Row.OnEntitySelected(null, "");
                return "";
            }

            //Caso elenco provider disponibili
            if(item.EntityType == "PROVIDER")
            {
                self.Data.Row.OnEntitySelected(null, item.ActivationCode);
                return item.ActivationCode;
            }

            const info : ISelectedEntityInlineInfo = {
                ProviderActivationCode : item.ActivationCode,
                Entity : {
                    EntityKeyType : item.EntityType,
                    EntityKeyId : item.id,
                    Type: item.Type
                }
            };

            self.Data.Row.OnEntitySelected(info, item.Code + " " + item.Description);
            return item.Code + " " + item.Description;
        })
        .on("blur", () => {
            const actualValue = $(element).val() as string;
            if(actualValue == null || actualValue == undefined || actualValue.trim() == "") {
                self.Data.Document().RemoveRow(self.Data.Row);
            }
        })
        .on("typeahead:change", () => {
            const actualValue = (<any>$(element)).typeahead('val');
            self.Data.Row.Description(actualValue);
        });

        const closestForm = $(element).closest("form");
        if(closestForm.length > 0) {
            closestForm.first().on('submit', () => {
                typeahead.typeahead('val', self.Data.Row.Description());
            });
        }
    }

    

    public static transformEntity(entity : IEntityForRef, provider : IDocumentRowInlineReferenceProviderFactory) : any {
        return {
            id: entity.Id,
            EntityType : entity.EntityType,
            Code: entity.Code,
            Description: entity.Description,
            ActivationCode : provider.ActivationCode,
            SubTitle: entity.SubTitle,
            Icon: entity.Icon,
            Type: entity.Type,

            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return (this.Code + " " + this.Description).toLowerCase();
            },
            indexOf: function(...args) {
                return String.prototype.indexOf.apply((this.Code + " " + this.Description), args);
            },
            replace: function(...args) {
                return String.prototype.replace.apply((this.Code + " " + this.Description), args);
            }
        }
    }
}

ko.bindingHandlers["documentRowHelper"] = new DocumentRowHelper();