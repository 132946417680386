import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import jss from "jss";
import { SelectedArticleOperationsInfo } from "./SelectedArticleOperationsInfo";
import { SelectedArticleInfo, SelectedArticleOperationInfo } from "./SelectedArticleInfo";
import { WarehouseInspectionOperationInfo } from "../../WarehouseInspectionWarehouseInfo";
import { IDataSourceModel } from "../../../../../../DataSources/IDataSource";
import { With } from "../../../../../../Components/IfIfNotWith";
import { Table, ITableItem } from "../../../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { SecondaryRow } from "../../../../../../Components/TableComponent/SecondaryRow";
import { ComponentUtils } from "../../../../../../Core/utils/ComponentUtils";

const { classes } = jss.createStyleSheet({
    selectedArticles: {
        "& .checkbox-col": {
            width: "30px"
        },

        "& .selected-articles-col": {
            width: "13%"
        },

        "& .warehouse-col": {
            width: "30%"
        },

        "& .action-col": {
            width: "40px"
        }
    }
}).attach();

export interface IWarehouseInspectionArticlesInfoTableProps {
    operationsInfo: ko.ObservableArray<SelectedArticleInfo>;
    showAmountColumn?: boolean;
}

export class SelectedArticlesInfoTable {
    public OperationsInfo: ko.ObservableArray<SelectedArticleInfo> = ko.observableArray([]);
    
    constructor(private props: IWarehouseInspectionArticlesInfoTableProps) {
        this.OperationsInfo = this.props.operationsInfo;
    }

    public deleteOperation(operation: SelectedArticleOperationInfo, inspectionArticleInfo: SelectedArticleInfo): void {
        inspectionArticleInfo.Operations.remove(operation);
        if (inspectionArticleInfo.Operations().length === 0)
            this.OperationsInfo.remove(inspectionArticleInfo);
    }

    public render() {
        let selectedArticleInfo: IDataSourceModel<number, SelectedArticleInfo>;

        let { sortString, sortNumber } = ComponentUtils.useSorter<SelectedArticleInfo>();

        return (
            <With data={this} as="selectedArticlesInfoTable">
                {() => (
                    <Table compact={true} scrollable={true} striped={true} fixedLayout={true} dataSource={{ array: this.OperationsInfo, factory: this.articleFactory.bind(this) }} rowAs="selectedArticleInfo" className={classes.selectedArticles}>
                        <Column title={TextResources.Warehouse.Warehouse} className="warehouse-col" sorter={sortString(m => m.DestinationWarehouse)}>
                            <div className="text-ellipsis">
                                <span data-bind={{ text: selectedArticleInfo.model.DestinationWarehouse, attr: { title: selectedArticleInfo.model.DestinationWarehouse } }}></span>
                            </div>
                        </Column>
                        <Column title={TextResources.Warehouse.SelectedArticles} className="selected-articles-col" sorter={sortNumber(m => m.OperationsNumber())}>
                            <span>
                                <span data-bind={{ numberText: selectedArticleInfo.model.OperationsNumber, format: '0,0' }}></span>
                            </span>
                        </Column>
                        <Column title={TextResources.Warehouse.Customer} sorter={sortString(m => m.CustomerName)}>
                            <div className="text-ellipsis">
                                <span data-bind={{ text: (!selectedArticleInfo.model.CustomerName ? 'N/A' : selectedArticleInfo.model.CustomerName), attr: { title: (!selectedArticleInfo.model.CustomerName ? '' : selectedArticleInfo.model.CustomerName) } }}></span>
                            </div>
                        </Column>
                        <Column title={TextResources.Warehouse.JobOrder} sorter={sortString(m => m.JobOrderName)}>
                            <div className="text-ellipsis">
                                <span data-bind={{ text: (!selectedArticleInfo.model.JobOrderName ? 'N/A' : selectedArticleInfo.model.JobOrderName), attr: { title: (!selectedArticleInfo.model.JobOrderName ? '' : selectedArticleInfo.model.JobOrderName) } }}></span>
                            </div>
                        </Column>
                        <Column className="action-col text-center">
                            <button type="button" className="btn btn-xs" data-bind={{ toggle: selectedArticleInfo.model.ShowOperations }}>
                                <i className="fa" data-bind={{ css: { 'fa-chevron-down': !selectedArticleInfo.model.ShowOperations(), 'fa-chevron-up': selectedArticleInfo.model.ShowOperations } }}></i>
                            </button>
                        </Column>
                        <SecondaryRow visible={() => "selectedArticleInfo.model.ShowOperations"}>
                            {(item: ITableItem<SelectedArticleInfo>) => (<td colSpan={5}><SelectedArticleOperationsInfo operations={item.Data.model.Operations} factory={this.operationFactory.bind(this)} showAmountColumn={!!this.props.showAmountColumn}></SelectedArticleOperationsInfo></td>)}
                        </SecondaryRow>
                    </Table>
                )}
            </With>
        );
    }

    private articleFactory(article: SelectedArticleInfo): IDataSourceModel<number, SelectedArticleInfo> {
        return {
            id: article.DestinationWarehouseId,
            title: article.DestinationWarehouse,
            isGroup: false,
            isLeaf: true,
            model: article
        };
    }

    private operationFactory(operation: SelectedArticleOperationInfo): IDataSourceModel<number, SelectedArticleOperationInfo> {
        return {
            id: operation.ArticleId,
            title: operation.ArticleCode + " - " + operation.ArticleDescription,
            isGroup: false,
            isLeaf: true,
            model: operation
        };
    }
}