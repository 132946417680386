import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 11/11/2016
 * Time: 14:44
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../../../../../Core/interfaces/IAjaxService";
import { IDesktopService } from "../../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class ImportFromProjectDialog implements IDialog {
    templateName: string = "importFromProjectDialog";
    templateUrl: string = "joborder/templates/joborderdetail";
    title: string = ProlifeSdk.TextResources.JobOrder.ImportFromProject;
    modal: {
        close: (result?: any) => void;
    };

    public selectedFiles : ko.ObservableArray<File> = ko.observableArray();

    private dialogsService : IDialogsService;
    private ajaxService : IAjaxService;
    private desktopService : IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private jobOrderId : number) {
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.desktopService = <IDesktopService> serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    close():void {
        this.modal.close();
    }

    action():void {
        if(this.selectedFiles().length == 0)
        {
            this.close();
            return;
        }

        this.uploadFile()
            .then(() => {
                this.close();
            })
    }

    public showModal() {
        this.dialogsService.ShowModal<void>(this, "", {}, this.templateUrl, this.templateName);
    }

    public uploadFile() : Promise<void>
    {
        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.JobOrder.ImportInProgress);
        var files = this.selectedFiles();
        var data = {};
        for(var i = 0; i < files.length; i++)
        {
            data['project' + i] = files[i];
        }

        return this.ajaxService.Upload<void>("JobOrder-api", "MSProject?jobOrderId=" + this.jobOrderId, data)
            .finally(() => { this.desktopService.UnblockPageUI(); });
    }

}