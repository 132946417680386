import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 25/08/2016
 * Time: 17:48
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IJobOrderEditorPanelFactory, IJobOrderEditor, IJobOrderEditorPanel, IJobOrderProjectViewModel } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../../Core/interfaces/IAuthorizationService";
import { IDesktopService } from "../../../../../ProlifeSdk/interfaces/desktop/IDesktopService";

export class JobOrderProjectsFactory implements IJobOrderEditorPanelFactory {
    private authorizationService : IAuthorizationService;

    constructor(private serviceLocator : IServiceLocator, public Color : string) {
        this.authorizationService = <IAuthorizationService> serviceLocator.findService(ServiceTypes.Authorization);
    }

    createPanel(serviceLocator:IServiceLocator, editor:IJobOrderEditor):IJobOrderEditorPanel {
        return new JobOrderProjects(serviceLocator, editor, this.Color);
    }

    hasRequiredModules():boolean {
        return true;
    }

    hasAuthorization():boolean {
        return this.authorizationService.isAuthorized("JobOrders_ViewProjects");
    }

}

class JobOrderProjects implements IJobOrderEditorPanel {
    Title: ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.JobOrder.Projects);
    TemplateUrl = "joborder/templates/joborderdetail";
    TemplateName = "projects";

    RelatedProjects : ko.ObservableArray<IJobOrderProjectViewModel> = ko.observableArray();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor, public Color : string) {}

    public canShow(): boolean {
        return !!this.editor.JobOrderId && this.editor.JobOrderId > 0;
    }

    dispose() {

    }

    load(): Promise<boolean> {
        this.RelatedProjects(this.editor.JobOrder().RelatedProjects());
        return Promise.resolve<boolean>(true);
    }

    isDefaultOnNew():boolean {
        return false;
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel() : Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    async beforeShowPanel() : Promise<void> {
        this.desktopService.SystemHeader.setSidebarVisibility(false);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        this.load();
    }
    
    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    OnWorkflowSelectionChanged(selection:number[]) { this.load(); }

    OnWorkflowDeleted(workflowId:number) {
    }

    OnWorkflowChanged(workflowId:number) {
    }

    OnWorkflowCreated(workflowId:number) {
    }

    OnWorkflowImportedFromTemplate(workflowId:number) {
    }

    public OnWorkflowUpdate(workflow: ITodoListWorkflow) {}
}