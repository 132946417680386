/* eslint-disable @typescript-eslint/no-this-alias */
import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as moment from "moment";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { reloadNow, ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import {
    IMetadataService,
    IDocumentsWithMetadataReportDocuments,
    IDocumentsWithMetadataReportDocumentsMetadata,
    IDocumentsWithMetadataReportMetadata,
    IGetDocumentsWithMetadataReportRequest,
} from "../../../MetadataService";
import { Layout } from "../../../../Components/Layouts";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { YesNo } from "../../../../Components/YesNo";
import { DocumentExternalReferenceInfo } from "../../../../Components/DocumentExternalReferenceInfo";
import { MetadataType } from "../../enums/MetadataType";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { Delay } from "../../../../Decorators/Delay";
import { TextInput } from "../../../../Components/TextInput";
import { DateRangeInput } from "../../../../Components/DateRangeInput";
import { MetadataFilter } from "./MetadataFilter";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import { VatRegistersDataSource } from "../../../../DataSources/VatRegistersDataSource";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { JobOrdersDataSource } from "../../../../DataSources/JobOrdersDataSource";
import { IHumanResourcesSettingsManager } from "../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import { JobOrderTypesDataSource } from "../../../../DataSources/JobOrderTypesDataSource";
import { JobOrderStatesDataSource } from "../../../../DataSources/JobOrderStatesDataSource";
import { RangeInput } from "../../../../Components/RangeInput";
import { IChangesNotificationsService } from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { IDocumentEventBase } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { DocumentClosingStatusIndicator } from "../../../../Components/DocumentClosingStatusIndicator";
import { DocumentClosingStatusHelper, DocumentClosingStatusInfo } from "../../utils/DocumentClosingStatusHelper";
import { CheckBox } from "../../../../Components/Checkbox";
import { DocumentClosingStatus } from "../../enums/DocumentClosingStatus";
import { InvoiceClosingStatus } from "../../enums/InvoiceClosingStatus";
import {
    IJobOrderMetadata,
    IJobOrderMetadataSettingsManager,
} from "../../../../JobOrder/jobOrder/settings/JobOrderMetadataSettingsManager";
import { SelectMultiple } from "../../../../Components/SelectMultiple";
import { JobOrderMetadata } from "../../../../Components/JobOrderMetadata";
import { AssignmentToWorkflowsState } from "../../../../Components/AssignmentToWorkflowsState";
import { JobOrderLink } from "../../../../JobOrder/jobOrder/ui/JobOrderLink";
import { JobOrderDetailsButton } from "../../../../JobOrder/jobOrder/ui/JobOrderDetailsButton";
import { Button } from "../../../../Components/ButtonGroup";

const styleSheet = jss.createStyleSheet({
    "tempus-dominus-fix": {
        "& .tempus-dominus-widget": {
            zIndex: "10052",
        },
    },
    report: {
        "& > thead": {
            "& > tr": {
                "& > th": {
                    minWidth: "190px",

                    "&.doc-number, &.doc-date": {
                        minWidth: "120px",
                    },

                    "&.doc-version-revision": {
                        minWidth: "135px",
                    },

                    "& .dropdown-menu": {
                        minWidth: "290px !important",
                    },
                },
            },
        },
        "& .metadata-column": {
            "&.deleted": {
                backgroundColor: "#ffa8a8 !important",
            },
        },
    },
    collapser: {
        borderBottom: "1px solid #e5e5e5",
        marginBottom: "10px",
        height: "20px",

        "& .collapse-button": {
            border: "0",
            position: "absolute",
            right: "15px",
            top: "8px",
            padding: "0px 10px",

            "&:focus": {
                outline: "none",
                backgroundColor: "white",
            },

            "&:hover": {
                outline: "none",
                backgroundColor: "white",
            },

            "&:active": {
                outline: "none",
                backgroundColor: "white",
            },
        },
    },
    filters: {
        columnGap: "5px",

        "& .form-group": {
            margin: "0px",
        },

        marginBottom: "20px",
    },
    "results-info": {
        borderTop: "1px solid #e5e5e5",
        padding: "10px 0px",
    },
});
const { classes } = styleSheet.attach();

// eslint-disable-next-line @typescript-eslint/ban-types
type DocumentWithMetadataReportProps = {};

type DocumentWithMetadata = {
    document: IDocumentsWithMetadataReportDocuments;
    metadata: IDocumentsWithMetadataReportDocumentsMetadata[];
    jobOrderMetadata: IJobOrderMetadata;
};

type MetadataFilterDictionary = { [metadataId: number]: MetadataFilterChecker };

type DocumentEventHandler = { handle: number; eventName: string };

class MetadataFilterChecker {
    firstValue: any;
    secondValue: any;
    valueType: MetadataType;

    public isEmpty(): boolean {
        return (
            (this.firstValue === null || this.firstValue === undefined) &&
            (this.secondValue === null || this.secondValue === undefined)
        );
    }

    public match(metadata: IDocumentsWithMetadataReportDocumentsMetadata): boolean {
        if (this.isEmpty()) return true;

        if (this.valueType === MetadataType.Text)
            return TextFiltersUtilities.contains(metadata.StringValue, this.firstValue);

        if (this.valueType === MetadataType.List)
            return (
                ((metadata?.IntValue === null || metadata?.IntValue === undefined) &&
                    this.firstValue.filter((s) => s === null || s === undefined).length > 0) ||
                this.firstValue.indexOf(metadata?.IntValue) >= 0
            );

        if (this.valueType === MetadataType.Integer)
            return this.firstValue === null || this.firstValue === undefined || this.firstValue === metadata.IntValue;

        if (this.valueType === MetadataType.Decimal)
            return (
                this.firstValue === null || this.firstValue === undefined || this.firstValue === metadata.DecimalValue
            );

        if (this.valueType === MetadataType.Boolean)
            return this.firstValue === null || this.firstValue === undefined || this.firstValue === metadata.BoolValue;

        if (!metadata.DateValue) return false;

        const startDate = moment(this.firstValue || "1900-01-01T00:00:00+01:00");
        const endDate = moment(this.secondValue || "2100-01-01T00:00:00+01:00");
        const metadataDate = moment(metadata.DateValue);

        return startDate.isSameOrBefore(metadataDate) && endDate.isSameOrAfter(metadataDate);
    }
}

type DocumentClosingStatusFilter = {
    statusInfo: DocumentClosingStatusInfo;
    selected: ko.Observable<boolean>;
};

export type IDocumentWithMetadataDataSourceModel = IDataSourceModel<number, DocumentWithMetadata>;

export class DocumentWithMetadataReportUI extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor() {
        super({ className: "fullscreen " + classes["tempus-dominus-fix"], noPrompt: true });
        this.title(TextResources.Invoices.OpenDocumentsWithMetadatasReportTitle);
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    renderBody() {
        return <DocumentWithMetadataReport />;
    }
}

export function DocumentWithMetadataReport(props: DocumentWithMetadataReportProps) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const C = require("./DocumentWithMetadatasReport")
        ._DocumentWithMetadataReport as typeof _DocumentWithMetadataReport;
    return <C {...props} />;
}

export class _DocumentWithMetadataReport {
    static defaultProps: Partial<DocumentWithMetadataReportProps> = {};

    private GeneralStartDateFilter: ko.Observable<Date> = ko.observable();
    private GeneralEndDateFilter: ko.Observable<Date> = ko.observable();
    private SelectedProtocols: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedDocumentClosingStatus: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedCustomers: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrders: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrderTypes: ko.ObservableArray<number> = ko.observableArray([]);
    private SelectedJobOrderStates: ko.ObservableArray<number> = ko.observableArray([]);

    private documentsLastMailSentFromDateFilter: ko.Observable<Date> = ko.observable();
    private documentsLastMailSentToDateFilter: ko.Observable<Date> = ko.observable();

    private documentsStartDateFilter: ko.Observable<Date> = ko.observable();
    private documentsEndDateFilter: ko.Observable<Date> = ko.observable();
    private documentsNumberSearchField: ko.Observable<string> = ko.observable();
    private documentClosingStatusFilters: DocumentClosingStatusFilter[] = [];
    private documentsVersionRevisionSearchField: ko.Observable<string> = ko.observable();

    private customersSearchField: ko.Observable<string> = ko.observable();
    private jobOrdersSearchField: ko.Observable<string> = ko.observable();
    private jobOrderClassificationSearchField: ko.Observable<string> = ko.observable();
    private selectedProtocols: number[] = [];
    private selectedJobOrderClassifications: number[] = [];
    private selectedAssignmentToWorkflowsState: number[] = [];
    private selectedSourceWarehouse: number[] = [];
    private selectedDestinationWarehouse: number[] = [];
    private selectedAdministrativeResponsible: string[] = [];
    private selectedCommercialResponsible: string[] = [];
    private selectedExpiries: string[] = [];
    private selectedPayments: string[] = [];
    private selectedStates: string[] = [];
    private selectedCause: number[] = [];
    private selectedOutcomes: number[] = [];
    private externalReferenceSearchField: ko.Observable<string> = ko.observable();
    private externalReferenceStartDateFilter: ko.Observable<Date> = ko.observable();
    private externalReferenceEndDateFilter: ko.Observable<Date> = ko.observable();
    private taxableTotalMinValueFilter: ko.Observable<number> = ko.observable();
    private taxableTotalMaxValueFilter: ko.Observable<number> = ko.observable();
    private vatMinValueFilter: ko.Observable<number> = ko.observable();
    private vatMaxValueFilter: ko.Observable<number> = ko.observable();
    private notTaxableTotalMinValueFilter: ko.Observable<number> = ko.observable();
    private notTaxableTotalMaxValueFilter: ko.Observable<number> = ko.observable();
    private totalMinValueFilter: ko.Observable<number> = ko.observable();
    private totalMaxValueFilter: ko.Observable<number> = ko.observable();
    private assignedToWorkflowsMinValueFilter: ko.Observable<number> = ko.observable();
    private assignedToWorkflowsMaxValueFilter: ko.Observable<number> = ko.observable();
    private waitingToStartTotalMinValueFilter: ko.Observable<number> = ko.observable();
    private waitingToStartTotalMaxValueFilter: ko.Observable<number> = ko.observable();
    private inProgressTotalMinValueFilter: ko.Observable<number> = ko.observable();
    private inProgressTotalMaxValueFilter: ko.Observable<number> = ko.observable();
    private inProgressTotalCompletedMinValueFilter: ko.Observable<number> = ko.observable();
    private inProgressTotalCompletedMaxValueFilter: ko.Observable<number> = ko.observable();
    private completedTotalMinValueFilter: ko.Observable<number> = ko.observable();
    private completedTotalMaxValueFilter: ko.Observable<number> = ko.observable();
    private assignedResources: ko.Observable<string> = ko.observable();

    private metadatasFilters: MetadataFilterDictionary = {};
    private affectedRows: ko.Observable<number> = ko.observable(0);
    private showedResultsLimit = 1000;

    private Documents: ko.ObservableArray<DocumentWithMetadata> = ko.observableArray([]);
    private FilteredDocuments: ko.ObservableArray<DocumentWithMetadata> = ko.observableArray([]);
    private DataAreLoaded: ko.Observable<boolean> = ko.observable(false);
    private CollapseFilters: ko.Observable<boolean> = ko.observable(false);

    private availableMetadatas: IDocumentsWithMetadataReportMetadata[] = [];

    private protocolsDataSource: VatRegistersDataSource = new VatRegistersDataSource();
    private customersDataSource: CustomersDataSource = new CustomersDataSource();
    private jobOrdersDataSource: JobOrdersDataSource = new JobOrdersDataSource();
    private jobOrderTypesDataSource: JobOrderTypesDataSource = new JobOrderTypesDataSource();
    private jobOrderStatesDataSource: JobOrderStatesDataSource = new JobOrderStatesDataSource();

    @LazyImport(nameof<IMetadataService>())
    private metadataService: IMetadataService;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;
    @LazyImportSettingManager(ProlifeSdk.JobOrderMetadataSettings)
    private metadataSettingsManager: IJobOrderMetadataSettingsManager;

    private subscriptions: ko.Subscription[] = [];
    private eventHandlers: DocumentEventHandler[] = [];
    private firstLoad = true;

    constructor(private props: DocumentWithMetadataReportProps) {
        this.initializeFilters();
        this.customersDataSource.setReturnGroupedData(false);
        this.initialize();
    }

    private async initialize(): Promise<void> {
        const request: IGetDocumentsWithMetadataReportRequest = {
            startDateFilter: moment().startOf("day").toDate(),
            endDateFilter: moment().endOf("day").toDate(),
            vatRegisters: [],
            customers: [],
            jobOrders: [],
            jobOrderTypes: [],
            jobOrderStates: [],
            documentTypes: [],
            documentClosingStatus: [],
            documentSchedulesStatus: [],
            skip: 0,
            count: 1,
        };

        try {
            this.dialogsService.LockUI();

            const data = await this.metadataService.GetDocumentsWithMetadataReport(request);

            // I metadati disponibili vengono caricati solamente all'inizio perché ricaricarli ogni volta vorrebbe dire far scatenare di nuovo il rendering della tabella per aggiornare le colonne in base ai metadati
            // e questo porterebbe alla perdita della configurazione dei footer perché verrebbero renderizzati di nuovo.
            if (this.firstLoad) {
                this.DataAreLoaded(false);
                this.availableMetadatas = data.Metadatas ?? [];
                this.DataAreLoaded(true);

                this.firstLoad = false;
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    componentDidMount() {
        this.subscriptions.push(this.documentsLastMailSentFromDateFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.documentsLastMailSentToDateFilter.subscribe(() => this.applyFilters()));

        this.subscriptions.push(this.documentsStartDateFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.documentsEndDateFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.documentsNumberSearchField.subscribe(() => this.applyFilters()));

        for (const closingStatusFilter of this.documentClosingStatusFilters)
            this.subscriptions.push(closingStatusFilter.selected.subscribe(() => this.applyFilters()));

        this.subscriptions.push(this.documentsVersionRevisionSearchField.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.customersSearchField.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.jobOrdersSearchField.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.jobOrderClassificationSearchField.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.externalReferenceSearchField.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.externalReferenceStartDateFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.externalReferenceEndDateFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.taxableTotalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.taxableTotalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.vatMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.vatMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.notTaxableTotalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.notTaxableTotalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.totalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.totalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.assignedToWorkflowsMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.assignedToWorkflowsMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.waitingToStartTotalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.waitingToStartTotalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.inProgressTotalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.inProgressTotalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.inProgressTotalCompletedMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.inProgressTotalCompletedMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.completedTotalMinValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.completedTotalMaxValueFilter.subscribe(() => this.applyFilters()));
        this.subscriptions.push(this.assignedResources.subscribe(() => this.applyFilters()));

        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentCreated",
                this.refreshDocumentsListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentCreated",
        });
        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentChanged",
                this.refreshDocumentsListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentChanged",
        });
        this.eventHandlers.push({
            handle: this.changesNotificationsService.RegisterEventHandler(
                "OnDocumentDeleted",
                this.refreshDocumentsListIfNeeded.bind(this)
            ),
            eventName: "OnDocumentDeleted",
        });
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];

        for (const handler of this.eventHandlers)
            this.changesNotificationsService.UnregisterEventHandler(handler.eventName, handler.handle);
    }

    private refreshDocumentsListIfNeeded(event: IDocumentEventBase): void {
        const documentId = event.id;

        const documents = this.Documents();

        if (documents.firstOrDefault((d) => d.document.Id === documentId)) {
            this.load();
        }
    }

    private initializeFilters() {
        this.GeneralStartDateFilter(moment().add(-1, "month").startOf("month").toDate());
        this.GeneralEndDateFilter(moment().endOf("day").toDate());

        this.jobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.jobOrdersDataSource.setShowClosed(true);
        this.jobOrdersDataSource.setViewFilters(true, true, true);
        this.jobOrdersDataSource.setWorkFilters(true);
        this.jobOrdersDataSource.setResourceId(this.humanResourcesSettingsManager.getLoggedResourceId());

        const closingStatus = DocumentClosingStatusHelper.getDocumentClosingStatusInfo();

        this.documentClosingStatusFilters.push({
            statusInfo: { value: -1, color: "transparent", label: TextResources.Invoices.Closed },
            selected: ko.observable(true),
        });
        this.documentClosingStatusFilters.push({
            statusInfo: closingStatus.firstOrDefault((s) => s.value === DocumentClosingStatus.Opened),
            selected: ko.observable(true),
        });
        this.documentClosingStatusFilters.push({
            statusInfo: closingStatus.firstOrDefault((s) => s.value === DocumentClosingStatus.PartiallyClosed),
            selected: ko.observable(true),
        });
        this.documentClosingStatusFilters.push({
            statusInfo: closingStatus.firstOrDefault((s) => s.value === DocumentClosingStatus.Closed),
            selected: ko.observable(true),
        });
    }

    private onCauseSelectionChange(selectedItems: number[]) {
        this.selectedCause = selectedItems;
        this.applyFilters();
    }

    private onOutcomeSelectionChange(selectedItems: number[]) {
        this.selectedOutcomes = selectedItems;
        this.applyFilters();
    }

    private onStateSelectionChange(selectedItems: string[]) {
        this.selectedStates = selectedItems;
        this.applyFilters();
    }

    private onPaymentSelectionChange(selectedItems: string[]) {
        this.selectedPayments = selectedItems;
        this.applyFilters();
    }

    private onExpirySelectionChange(selectedItems: string[]) {
        this.selectedExpiries = selectedItems;
        this.applyFilters();
    }

    private onAdministrativeResponsibleSelectionChange(selectedItems: string[]): void {
        this.selectedAdministrativeResponsible = selectedItems;
        this.applyFilters();
    }

    private onSourceWarehouseSelectionChange(selectedItems: number[]): void {
        this.selectedSourceWarehouse = selectedItems;
        this.applyFilters();
    }

    private onDestinationWarehouseSelectionChange(selectedItems: number[]): void {
        this.selectedDestinationWarehouse = selectedItems;
        this.applyFilters();
    }

    private onCommercialResponsibleSelectionChange(selectedItems: string[]): void {
        this.selectedCommercialResponsible = selectedItems;
        this.applyFilters();
    }

    private onProtocolsSelectionChanges(selectedProtocols: number[]) {
        this.selectedProtocols = selectedProtocols;
        this.applyFilters();
    }

    private onJobOrderClassificationSelectionChange(selectedJobOrderMetadataIds: number[]) {
        this.selectedJobOrderClassifications = selectedJobOrderMetadataIds;
        this.applyFilters();
    }

    private onAssignmentToWorkflowsStateSelectionChange(selectedAssignmentToWorkflowsState: number[]) {
        this.selectedAssignmentToWorkflowsState = selectedAssignmentToWorkflowsState;
        this.applyFilters();
    }

    private excelDataProvider() {
        const protocols = this.SelectedProtocols();
        const customers = this.SelectedCustomers();
        const jobOrders = this.SelectedJobOrders();
        const jobOrderTypes = this.SelectedJobOrderTypes();
        const jobOrderStates = this.SelectedJobOrderStates();
        const closingStatus = this.SelectedDocumentClosingStatus();
        const schedulesStatus = this.getSchedulesStatusMappingFromClosingStatus(closingStatus);

        return {
            startDateFilter: this.GeneralStartDateFilter(),
            endDateFilter: this.GeneralEndDateFilter(),
            vatRegisters: protocols ?? [],
            customers: customers ?? [],
            jobOrders: jobOrders ?? [],
            jobOrderTypes: jobOrderTypes ?? [],
            jobOrderStates: jobOrderStates ?? [],
            documentClosingStatus: closingStatus,
            documentSchedulesStatus: schedulesStatus,
        };
    }

    private async load(): Promise<void> {
        this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading, true);

        const closingStatus = this.SelectedDocumentClosingStatus();
        const schedulesStatus = this.getSchedulesStatusMappingFromClosingStatus(closingStatus);

        const request: IGetDocumentsWithMetadataReportRequest = {
            startDateFilter: this.GeneralStartDateFilter(),
            endDateFilter: this.GeneralEndDateFilter(),
            vatRegisters: this.SelectedProtocols() ?? [],
            customers: this.SelectedCustomers() ?? [],
            jobOrders: this.SelectedJobOrders() ?? [],
            jobOrderTypes: this.SelectedJobOrderTypes() ?? [],
            jobOrderStates: this.SelectedJobOrderStates() ?? [],
            documentTypes: [],
            documentClosingStatus: closingStatus,
            documentSchedulesStatus: schedulesStatus,
            skip: 0,
        };

        try {
            const data = await this.metadataService.GetDocumentsWithMetadataReport(request);

            // I metadati disponibili vengono caricati solamente all'inizio perché ricaricarli ogni volta vorrebbe dire far scatenare di nuovo il rendering della tabella per aggiornare le colonne in base ai metadati
            // e questo porterebbe alla perdita della configurazione dei footer perché verrebbero renderizzati di nuovo.
            if (this.firstLoad) {
                this.DataAreLoaded(false);
                this.availableMetadatas = data.Metadatas ?? [];
                this.DataAreLoaded(true);

                this.firstLoad = false;
            }

            const documents: DocumentWithMetadata[] = [];
            for (const doc of data.Documents) {
                const jobOrderMetadata: IJobOrderMetadata = doc.JobOrderMetadataId
                    ? this.metadataSettingsManager.getByIds([doc.JobOrderMetadataId]).firstOrDefault()
                    : null;

                documents.push({
                    document: doc,
                    metadata: data.DocumentsMetadatas.filter((m) => m.FKDocument === doc.Id),
                    jobOrderMetadata: jobOrderMetadata ?? null,
                });
            }

            this.affectedRows(data.AffectedRows);
            this.Documents(documents);
            this.applyFilters();
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    private getSchedulesStatusMappingFromClosingStatus(closingStatus: number[]) {
        let schedulesStatus = closingStatus.map((cs) =>
            cs === -1
                ? InvoiceClosingStatus.NoSchedules
                : DocumentClosingStatusHelper.mapDocumentClosingStatusToInvoiceClosingStatus(cs)
        );

        if (schedulesStatus.indexOf(InvoiceClosingStatus.OpenedSchedules) >= 0)
            schedulesStatus.push(InvoiceClosingStatus.ClosedAndOpenedSchedules);

        if (schedulesStatus.indexOf(InvoiceClosingStatus.ExpiredSchedules) >= 0)
            schedulesStatus = schedulesStatus.concat([
                InvoiceClosingStatus.OpenedAndExpiredSchedules,
                InvoiceClosingStatus.ClosedAndExpiredSchedules,
                InvoiceClosingStatus.OpenedClosedAndExpiredSchedules,
            ]);
        return schedulesStatus;
    }

    @Delay(300)
    private applyFilters(): void {
        let documents = this.Documents();

        documents = this.applyDocumentsFilters(documents);

        this.FilteredDocuments(documents);
    }

    private onMetadataFiltersChanges(
        metadataId: number,
        metadataType: MetadataType,
        firstValue: any,
        secondValue: any
    ): void {
        let checker = this.metadatasFilters[metadataId];
        if (!checker) {
            checker = new MetadataFilterChecker();
            this.metadatasFilters[metadataId] = checker;
        }

        checker.valueType = metadataType;
        checker.firstValue = firstValue;
        checker.secondValue = secondValue;

        this.applyFilters();
    }

    private applyDocumentsFilters(actualDocuments: DocumentWithMetadata[]): DocumentWithMetadata[] {
        const documentNumberFilter = this.documentsNumberSearchField();
        const documentVersionRevisionFilter = this.documentsVersionRevisionSearchField();
        const startDate = moment(this.documentsStartDateFilter() ?? "1900-01-01T00:00:00+01:00");
        const endDate = moment(this.documentsEndDateFilter() ?? "2100-01-01T00:00:00+01:00");
        const mailSentFromDate = !this.documentsLastMailSentFromDateFilter()
            ? null
            : moment(this.documentsLastMailSentFromDateFilter());
        const mailSentToDate = !this.documentsLastMailSentToDateFilter()
            ? null
            : moment(this.documentsLastMailSentToDateFilter());
        const customersFilter = this.customersSearchField();
        const jobOrdersFilter = this.jobOrdersSearchField();
        const jobOrderClassificationFilter = this.jobOrderClassificationSearchField();
        const externalReferenceFilter = this.externalReferenceSearchField();
        const externalReferenceStartDateFilter = this.externalReferenceStartDateFilter();
        const externalReferenceEndDateFilter = this.externalReferenceEndDateFilter();
        const taxableTotalMinValueFilter = this.taxableTotalMinValueFilter();
        const taxableTotalMaxValueFilter = this.taxableTotalMaxValueFilter();
        const vatMinValueFilter = this.vatMinValueFilter();
        const vatMaxValueFilter = this.vatMaxValueFilter();
        const notTaxableTotalMinValueFilter = this.notTaxableTotalMinValueFilter();
        const notTaxableTotalMaxValueFilter = this.notTaxableTotalMaxValueFilter();
        const totalMinValueFilter = this.totalMinValueFilter();
        const totalMaxValueFilter = this.totalMaxValueFilter();
        const assignedToWorkflowsMinValueFilter = this.assignedToWorkflowsMinValueFilter();
        const assignedToWorkflowsMaxValueFilter = this.assignedToWorkflowsMaxValueFilter();
        const waitingToStartTotalMaxValueFilter = this.waitingToStartTotalMaxValueFilter();
        const waitingToStartTotalMinValueFilter = this.waitingToStartTotalMinValueFilter();
        const inProgressTotalMaxValueFilter = this.inProgressTotalMaxValueFilter();
        const inProgressTotalMinValueFilter = this.inProgressTotalMinValueFilter();
        const inProgressTotalCompletedMaxValueFilter = this.inProgressTotalCompletedMaxValueFilter();
        const inProgressTotalCompletedMinValueFilter = this.inProgressTotalCompletedMinValueFilter();
        const completedTotalMinValueFilter = this.completedTotalMinValueFilter();
        const completedTotalMaxValueFilter = this.completedTotalMaxValueFilter();
        const selectedClosingStatus = this.getRemappedSelectedClosingStatus();
        const assignedResources = this.assignedResources();

        return actualDocuments.filter((d) => {
            const docDate = moment(d.document.Date);

            return (
                (!documentNumberFilter ||
                    (d.document.Number || "").toLocaleLowerCase().indexOf(documentNumberFilter.toLocaleLowerCase()) >=
                        0) &&
                (!documentVersionRevisionFilter ||
                    (d.document.FullVersionRevisionNumber || "")
                        .toLocaleLowerCase()
                        .indexOf(documentVersionRevisionFilter.toLocaleLowerCase()) >= 0) &&
                this.applyExternalReferenceFilters(
                    d.document,
                    externalReferenceFilter,
                    externalReferenceStartDateFilter,
                    externalReferenceEndDateFilter
                ) &&
                TextFiltersUtilities.contains(d.document.CustomerName, customersFilter) &&
                TextFiltersUtilities.contains(d.document.JobOrderName, jobOrdersFilter) &&
                TextFiltersUtilities.contains(d.jobOrderMetadata?.Name ?? "", jobOrderClassificationFilter) &&
                docDate.isSameOrAfter(startDate) &&
                docDate.isSameOrBefore(endDate) &&
                this.applyMailSentFilters(d.document.LastMailSentDate, mailSentFromDate, mailSentToDate) &&
                (taxableTotalMinValueFilter === null ||
                    taxableTotalMinValueFilter === undefined ||
                    taxableTotalMinValueFilter <= d.document.TaxableTotal) &&
                (taxableTotalMaxValueFilter === null ||
                    taxableTotalMaxValueFilter === undefined ||
                    taxableTotalMaxValueFilter >= d.document.TaxableTotal) &&
                (vatMinValueFilter === null ||
                    vatMinValueFilter === undefined ||
                    vatMinValueFilter <= d.document.VAT) &&
                (vatMaxValueFilter === null ||
                    vatMaxValueFilter === undefined ||
                    vatMaxValueFilter >= d.document.VAT) &&
                (notTaxableTotalMinValueFilter === null ||
                    notTaxableTotalMinValueFilter === undefined ||
                    notTaxableTotalMinValueFilter <= d.document.NotTaxableTotal) &&
                (notTaxableTotalMaxValueFilter === null ||
                    notTaxableTotalMaxValueFilter === undefined ||
                    notTaxableTotalMaxValueFilter >= d.document.NotTaxableTotal) &&
                (totalMinValueFilter === null ||
                    totalMinValueFilter === undefined ||
                    totalMinValueFilter <= d.document.Total) &&
                (totalMaxValueFilter === null ||
                    totalMaxValueFilter === undefined ||
                    totalMaxValueFilter >= d.document.Total) &&
                (assignedToWorkflowsMinValueFilter === null ||
                    assignedToWorkflowsMinValueFilter === undefined ||
                    assignedToWorkflowsMinValueFilter <= d.document.AssignedToWorkflowsTotal) &&
                (assignedToWorkflowsMaxValueFilter === null ||
                    assignedToWorkflowsMaxValueFilter === undefined ||
                    assignedToWorkflowsMaxValueFilter >= d.document.AssignedToWorkflowsTotal) &&
                (waitingToStartTotalMinValueFilter === null ||
                    waitingToStartTotalMinValueFilter === undefined ||
                    waitingToStartTotalMinValueFilter <= d.document.WaitingToStartTotal) &&
                (waitingToStartTotalMaxValueFilter === null ||
                    waitingToStartTotalMaxValueFilter === undefined ||
                    waitingToStartTotalMaxValueFilter >= d.document.WaitingToStartTotal) &&
                (inProgressTotalMinValueFilter === null ||
                    inProgressTotalMinValueFilter === undefined ||
                    inProgressTotalMinValueFilter <= d.document.InProgressTotal) &&
                (inProgressTotalMaxValueFilter === null ||
                    inProgressTotalMaxValueFilter === undefined ||
                    inProgressTotalMaxValueFilter >= d.document.InProgressTotal) &&
                (inProgressTotalCompletedMinValueFilter === null ||
                    inProgressTotalCompletedMinValueFilter === undefined ||
                    inProgressTotalCompletedMinValueFilter <= d.document.InProgressTotalCompleted) &&
                (inProgressTotalCompletedMaxValueFilter === null ||
                    inProgressTotalCompletedMaxValueFilter === undefined ||
                    inProgressTotalCompletedMaxValueFilter >= d.document.InProgressTotalCompleted) &&
                (completedTotalMinValueFilter === null ||
                    completedTotalMinValueFilter === undefined ||
                    completedTotalMinValueFilter <= d.document.CompletedTotal) &&
                (completedTotalMaxValueFilter === null ||
                    completedTotalMaxValueFilter === undefined ||
                    completedTotalMaxValueFilter >= d.document.CompletedTotal) &&
                this.applyProtocolsFilter(d.document.RegisterId) &&
                this.applyMetadatasFilters(d.metadata) &&
                this.applyDocumentStatusFilter(d.document, selectedClosingStatus) &&
                this.applyJobOrderClassificationsFilter(d.jobOrderMetadata) &&
                this.applyAssignmentToWorkflowsStateFilter(d.document) &&
                this.applyCommercialResponsibleFilter(d.document) &&
                this.applyAdministrativeResponsibleFilter(d.document) &&
                this.applySourceWarehouseFilter(d.document) &&
                this.applyDestinationWarehouseFilter(d.document) &&
                this.applyOutcomesFilter(d.document) &&
                this.applyDocStatusFilter(d.document) &&
                this.applyCauseFilter(d.document) &&
                this.applyPaymentTypeFilter(d.document) &&
                this.applyExpirationFilter(d.document) &&
                TextFiltersUtilities.contains(d.document.AssignedResources, assignedResources)
            );
        });
    }

    private applyMailSentFilters(
        lastMailSentDate: Date | null,
        mailSentFromDate: moment.Moment | null,
        mailSentToDate: moment.Moment | null
    ) {
        return (
            (!mailSentFromDate || (lastMailSentDate && moment(lastMailSentDate).isSameOrAfter(mailSentFromDate))) &&
            (!mailSentToDate || (lastMailSentDate && moment(lastMailSentDate).isSameOrBefore(mailSentToDate)))
        );
    }

    private applyExpirationFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedExpiries.indexOf(!document.ExpiryType ? null : document.ExpiryType) >= 0;
    }

    private applyPaymentTypeFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedPayments.indexOf(!document.PaymentType ? null : document.PaymentType) >= 0;
    }

    private applyCauseFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedCause.indexOf(document.CauseId) >= 0;
    }

    private applyDocStatusFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedStates.indexOf(!document.DocumentState ? null : document.DocumentState) >= 0;
    }

    private applyOutcomesFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedOutcomes.indexOf(document.FKOutcome) >= 0;
    }

    private applyDestinationWarehouseFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedDestinationWarehouse.indexOf(document.DestinationWarehouseId) >= 0;
    }

    private applySourceWarehouseFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return this.selectedSourceWarehouse.indexOf(document.SourceWarehouseId) >= 0;
    }

    private applyAdministrativeResponsibleFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return (
            this.selectedAdministrativeResponsible.indexOf(
                !document.AdministrativeResponsibleName ? null : document.AdministrativeResponsibleName
            ) >= 0
        );
    }

    private applyCommercialResponsibleFilter(document: IDocumentsWithMetadataReportDocuments): boolean {
        return (
            this.selectedCommercialResponsible.indexOf(
                !document.CommercialResponsibleName ? null : document.CommercialResponsibleName
            ) >= 0
        );
    }

    private applyProtocolsFilter(registerId: number): boolean {
        return this.selectedProtocols.indexOf(registerId) >= 0;
    }

    private applyAssignmentToWorkflowsStateFilter(document: IDocumentsWithMetadataReportDocuments): unknown {
        return this.selectedAssignmentToWorkflowsState.indexOf(document.AssignmentToWorkflowsState) >= 0;
    }

    private applyJobOrderClassificationsFilter(jobOrderMetadata: IJobOrderMetadata): boolean {
        const viewItemsWithoutValue: boolean = this.selectedJobOrderClassifications.filter((f) => !f).length > 0;
        return (
            this.selectedJobOrderClassifications.indexOf(jobOrderMetadata?.Id ?? null) >= 0 ||
            (viewItemsWithoutValue && !jobOrderMetadata)
        );
    }

    private getRemappedSelectedClosingStatus() {
        const selectedStatus = [];

        for (const filter of this.documentClosingStatusFilters) {
            if (filter.selected())
                selectedStatus.push(
                    DocumentClosingStatusHelper.mapDocumentClosingStatusToInvoiceClosingStatus(filter.statusInfo.value)
                );
        }

        return selectedStatus;
    }

    private applyDocumentStatusFilter(
        document: IDocumentsWithMetadataReportDocuments,
        selectedClosingStatus: InvoiceClosingStatus[]
    ): boolean {
        const documentClosingStatus = DocumentClosingStatusHelper.translateDocumentClosingStatus(
            document.EntityType,
            document.RegisterId,
            document.CauseLogicType,
            document.DocumentStateId,
            document.DocumentClosingState
        );
        return selectedClosingStatus.indexOf(this.getDocumentStatusForFilter(document, documentClosingStatus)) >= 0;
    }

    private getDocumentStatusForFilter(
        document: IDocumentsWithMetadataReportDocuments,
        documentClosingStatus: InvoiceClosingStatus
    ): InvoiceClosingStatus {
        if (!this.isDocumentWithPaymentSchedules(document)) return documentClosingStatus;

        switch (documentClosingStatus) {
            case InvoiceClosingStatus.ClosedAndOpenedSchedules:
                return InvoiceClosingStatus.OpenedSchedules;
            case InvoiceClosingStatus.OpenedAndExpiredSchedules:
            case InvoiceClosingStatus.ClosedAndExpiredSchedules:
            case InvoiceClosingStatus.OpenedClosedAndExpiredSchedules:
                return InvoiceClosingStatus.ExpiredSchedules;
            default:
                return documentClosingStatus;
        }
    }

    private isDocumentWithPaymentSchedules(document: IDocumentsWithMetadataReportDocuments) {
        return (
            (document.EntityType === ProlifeSdk.InvoiceEntityTypeCode ||
                document.EntityType === ProlifeSdk.AccompanyingInvoiceEntityTypeCode) &&
            !document.ForPartialInvoicesRegister
        );
    }

    private applyExternalReferenceFilters(
        document: IDocumentsWithMetadataReportDocuments,
        textFilter: string,
        startDate: Date,
        endDate: Date
    ): boolean {
        const mStartDate = moment(startDate ?? "1900-01-01T00:00:00+01:00");
        const mEndDate = moment(endDate ?? "2100-01-01T00:00:00+01:00");
        const referenceDate = moment(document.ReferenceDate ?? "2000-01-01T00:00:00+01:00");

        return (
            (TextFiltersUtilities.contains(document.ReferenceNumber, textFilter) ||
                TextFiltersUtilities.contains(document.ExternalReference, textFilter)) &&
            ((!startDate && !endDate && !document.ReferenceDate) ||
                (mStartDate.isSameOrBefore(referenceDate) && mEndDate.isSameOrAfter(referenceDate)))
        );
    }

    private applyMetadatasFilters(metadatas: IDocumentsWithMetadataReportDocumentsMetadata[]): boolean {
        for (const metadataId in this.metadatasFilters) {
            const checker = this.metadatasFilters[metadataId];
            if (checker.isEmpty()) continue;

            const numericId = parseInt(metadataId);
            const docMetadata = metadatas.firstOrDefault((m) => m.FKMetadata === numericId);

            if ((!docMetadata && checker.valueType !== MetadataType.List) || !checker.match(docMetadata)) return false;
        }

        return true;
    }

    private documentsFactory(document: DocumentWithMetadata): IDocumentWithMetadataDataSourceModel {
        return {
            id: document.document.Id,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: document,
        };
    }

    render() {
        const report = this;

        return ComponentUtils.bindTo(
            <Layout.Grid
                columns={["1fr"]}
                rows={["min-content", "min-content", "min-content", "1fr"]}
                noOverflow
                style={{ position: "absolute", inset: "15px" }}
            >
                <Layout.Grid.Cell column={1} row={1} className={"flex-container " + classes.collapser}>
                    <Button
                        color="default"
                        className="collapse-button"
                        onClick={() => {
                            this.CollapseFilters(!this.CollapseFilters());
                        }}
                    >
                        <If condition={this.CollapseFilters}>{() => <i className="fa fa-chevron-down"></i>}</If>
                        <IfNot condition={this.CollapseFilters}>{() => <i className="fa fa-chevron-up"></i>}</IfNot>
                    </Button>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={2} className="flex-vertical">
                    <IfNot condition={this.CollapseFilters}>{() => this.renderTableHeader()}</IfNot>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={3} className={classes["results-info"]}>
                    <span>{TextResources.Invoices.DocumentWithMetadatasAffectedRows}</span>
                    <span data-bind={{ numberText: report.affectedRows, format: "0,0" }}></span>
                    <span
                        style={{ color: "red", textDecoration: "underline" }}
                        data-bind={{ visible: report.showedResultsLimit < report.affectedRows() }}
                    >
                        &nbsp;
                        {String.format(
                            TextResources.Invoices.DocumentWithMetadatasShowedResultsLimit,
                            this.showedResultsLimit
                        )}
                    </span>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={4}>
                    <IfNot condition={this.DataAreLoaded}>
                        {() => (
                            <span className="flex-1 text-center">
                                {TextResources.Invoices.DocumentWithMetadatasHelpText}
                            </span>
                        )}
                    </IfNot>
                    <If condition={this.DataAreLoaded}>{() => this.renderTable()}</If>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "report"
        );
    }

    private renderTableHeader() {
        return (
            <Layout.Grid
                rows={["min-content", "min-content"]}
                columns={["130px", "130px", "1fr", "1fr", "1fr", "1fr", "1fr", "1fr", "1fr", "auto"]}
                className={classes.filters}
            >
                {this.renderFirstFiltersLine()}
                {this.renderSecondFiltersLine()}
            </Layout.Grid>
        );
    }

    private renderFirstFiltersLine() {
        const report = this;
        return (
            <>
                <Layout.Grid.Cell row={1} column={1} className="flex-vertical">
                    <DateTimeInput
                        value={this.GeneralStartDateFilter}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralStartDateFilter}
                        dateonly
                        parent=".modal-body"
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={2} className="flex-vertical">
                    <DateTimeInput
                        value={this.GeneralEndDateFilter}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralEndDateFilter}
                        dateonly
                        parent=".modal-body"
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={"3/5"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.protocolsDataSource}
                        value={this.SelectedProtocols}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralProtocolFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={"5/7"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={Table.defaultDataSource(this.documentClosingStatusFilters, (item) => ({
                            id: item.statusInfo.value,
                            title: item.statusInfo.label,
                        }))}
                        renderItem={(item) => this.renderClosingStatusFilterLabel(item.model)}
                        value={this.SelectedDocumentClosingStatus}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralClocingStatusFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={"7/10"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.customersDataSource}
                        value={this.SelectedCustomers}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralCustomerFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={10} style={{ alignItems: "end" }}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-bind={{ asyncClick: report.load.bind(report) }}
                        style={{ width: "100%" }}
                    >
                        <i className="fa fa-search"></i>&nbsp;{TextResources.ProlifeSdk.Search}
                    </button>
                </Layout.Grid.Cell>
            </>
        );
    }

    private renderSecondFiltersLine() {
        return (
            <>
                <Layout.Grid.Cell row={2} column={"1/6"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrdersDataSource}
                        value={this.SelectedJobOrders}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralJobOrderFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={"6/8"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrderTypesDataSource}
                        value={this.SelectedJobOrderTypes}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralJobOrderTypeFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={"8/10"} className="flex-vertical">
                    <SelectMultiple.WithLabel
                        dataSource={this.jobOrderStatesDataSource}
                        value={this.SelectedJobOrderStates}
                        label={TextResources.Invoices.DocumentWithMetadatasGeneralJobOrderStatesFilter}
                        placeholder={TextResources.ProlifeSdk.Select2Placeholder}
                        allowClear
                    />
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={2} column={10} style={{ alignItems: "end" }}>
                    <excel-exporter
                        exporterId="Invoices/DocumentsWithMetadatasReportExport"
                        exporterMethod="GenerateExcel"
                        dataProvider={() => "report.excelDataProvider.bind(report)"}
                        position="left"
                    >
                        <i class="fa fa-download"></i>&nbsp;{TextResources.Invoices.DocsWithMetadataExport}
                    </excel-exporter>
                </Layout.Grid.Cell>
            </>
        );
    }

    private renderMetadataValue(
        metadata: IDocumentsWithMetadataReportMetadata,
        documentWithMetadatas: DocumentWithMetadata
    ) {
        const documentMetadata = documentWithMetadatas.metadata.firstOrDefault((dm) => dm.FKMetadata === metadata.Id);

        if (!documentMetadata) return <></>;

        const stringValue = documentMetadata.StringValue || documentMetadata.ValueLabel;
        if (stringValue) return <span>{stringValue}</span>;

        if (documentMetadata.IntValue !== null && documentMetadata.IntValue !== undefined)
            return <span>{numeral(documentMetadata.IntValue).format("0,0")}</span>;

        if (documentMetadata.DecimalValue !== null && documentMetadata.DecimalValue !== undefined)
            return <span>{numeral(documentMetadata.DecimalValue).format("0,0[.][000000000]")}</span>;

        if (documentMetadata.BoolValue !== null && documentMetadata.BoolValue !== undefined)
            return <YesNo value={documentMetadata.BoolValue} />;

        if (documentMetadata.DateValue !== null && documentMetadata.DateValue !== undefined)
            return <span>{moment(documentMetadata.DateValue).format("L")}</span>;

        return <></>;
    }

    private renderClosingStatusFilterLabel(filter: DocumentClosingStatusFilter) {
        return (
            <div style={{ display: "inline-block" }}>
                <div
                    style={{
                        display: "inline-block",
                        margin: "0 5px",
                        width: "10px",
                        height: "10px",
                        backgroundColor: filter.statusInfo.color,
                    }}
                ></div>
                {filter.statusInfo.label}
            </div>
        );
    }

    private renderTable() {
        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<DocumentWithMetadata>();

        const defaultDate = moment("1900-01-01T00:00:00+01:00").toDate();
        let document: IDocumentWithMetadataDataSourceModel;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const report = this;

        const getMetadataSorter = (metadata: IDocumentsWithMetadataReportMetadata) => {
            if (metadata.ValueType === MetadataType.Text || metadata.ValueType === MetadataType.List)
                return sortString((d) => {
                    const dm = d.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                    return (dm?.StringValue || dm?.ValueLabel) ?? "";
                });

            if (metadata.ValueType === MetadataType.Integer || metadata.ValueType === MetadataType.Decimal)
                return sortNumber((d) => {
                    const dm = d.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                    return (dm?.IntValue || dm?.DecimalValue) ?? 0;
                });

            if (metadata.ValueType === MetadataType.DateTime)
                return sortDate((d) => {
                    const dm = d.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                    return dm?.DateValue ?? defaultDate;
                });

            return sortNumber((d) => {
                const dm = d.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                return dm?.BoolValue ?? false ? 1 : 0;
            });
        };

        const getMetadataAggregator = (metadata: IDocumentsWithMetadataReportMetadata) => {
            if (metadata.ValueType === MetadataType.Text || metadata.ValueType === MetadataType.List)
                return (item: ITableItem<DocumentWithMetadata>) => {
                    const dm = item.Data.model.metadata.firstOrDefault((m) => metadata.Id === m.FKMetadata);
                    return dm?.StringValue ?? dm?.ValueLabel;
                };

            if (metadata.ValueType === MetadataType.Integer || metadata.ValueType === MetadataType.Decimal)
                return (item: ITableItem<DocumentWithMetadata>) => {
                    const dm = item.Data.model.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                    return dm?.IntValue || dm?.DecimalValue;
                };

            if (metadata.ValueType === MetadataType.DateTime)
                return (item: ITableItem<DocumentWithMetadata>) => {
                    const dm = item.Data.model.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                    return dm?.DateValue;
                };

            return (item: ITableItem<DocumentWithMetadata>) => {
                const dm = item.Data.model.metadata.firstOrDefault((mm) => mm.FKMetadata === metadata.Id);
                return dm?.BoolValue;
            };
        };

        const getMetadataAggregatorFormatter = (metadata: IDocumentsWithMetadataReportMetadata) => {
            if (metadata.ValueType === MetadataType.Text || metadata.ValueType === MetadataType.List)
                return (value: string) => {
                    return value;
                };

            if (metadata.ValueType === MetadataType.Integer) {
                return (value: number) => {
                    return value === null || value === undefined ? null : numeral(value).format("0,0");
                };
            }

            if (metadata.ValueType === MetadataType.Decimal) {
                return (value: number) => {
                    return value === null || value === undefined ? null : numeral(value).format("0,0[.][000000]");
                };
            }

            if (metadata.ValueType === MetadataType.DateTime) {
                return (value: Date) => {
                    return value === null || value === undefined ? null : moment(value).format("L");
                };
            }

            return (value: boolean) => {
                return value ? TextResources.ProlifeSdk.Yes : TextResources.ProlifeSdk.No;
            };
        };

        const renderJobOrderClassificationItem = (document: DocumentWithMetadata) => (
            <>
                {document.jobOrderMetadata && (
                    <JobOrderMetadata
                        id={document.jobOrderMetadata.Id}
                        name={document.jobOrderMetadata.Name}
                        code={document.jobOrderMetadata.Code}
                        parentId={document.jobOrderMetadata.ParentId}
                        entityKeyId={document.jobOrderMetadata.EntityKeyId}
                        entityType={document.jobOrderMetadata.EntityType}
                        hasChildren={document.jobOrderMetadata.HasChildren}
                    />
                )}
            </>
        );

        const renderJobOrderClassificationItemForFilter = (document: DocumentWithMetadata) => (
            <div className="flex-container" style={{ alignItems: "center", height: "26px", fontWeight: 300 }}>
                {!document.jobOrderMetadata && TextResources.ProlifeSdk.EmptyTableFilterLabel}
                {document.jobOrderMetadata && renderJobOrderClassificationItem(document)}
            </div>
        );

        const renderAssignmentToWorkflowsState = (document: DocumentWithMetadata) => {
            return <AssignmentToWorkflowsState state={document.document.AssignmentToWorkflowsState} />;
        };

        return (
            <Table
                id={ProlifeSdk.DocumentWithMetadataTable}
                dataSource={{ array: this.FilteredDocuments, factory: this.documentsFactory.bind(this) }}
                showColumnSelector
                columnSelectorPosition="right"
                systemScrollable
                compact
                rowAs="document"
                className={classes.report}
                enableAggregators
                sortableColumns
                useHoverEffect
                selectableRows
                selectRowsByCheckbox
                multipleRowsSelection
            >
                <Column
                    id={-1}
                    title={TextResources.Invoices.DocumentNumberColumn}
                    sorter={sortString((d) => d.document.Number)}
                    aggregateOn={(item) => item.Data.model.document.Number}
                    className="doc-number"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.DocumentNumberColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.documentsNumberSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <>
                                            <TextInput
                                                value={this.documentsNumberSearchField}
                                                label={TextResources.Invoices.DocumentNumberFilter}
                                                placeholder={TextResources.Invoices.DocumentNumberFilter}
                                            />
                                            <label className="control-label">
                                                {TextResources.Invoices.DocumentStateFiltersTitle}
                                            </label>
                                            {this.documentClosingStatusFilters.map((f) => (
                                                <CheckBox
                                                    key={f.statusInfo.value}
                                                    checked={f.selected}
                                                    label={this.renderClosingStatusFilterLabel(f)}
                                                    simple
                                                />
                                            ))}
                                        </>
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <DocumentClosingStatusIndicator
                                documentId={item.Data.model.document.Id}
                                documentType={item.Data.model.document.EntityType}
                                protocolId={item.Data.model.document.RegisterId}
                                documentClosingState={item.Data.model.document.DocumentClosingState}
                                documentState={item.Data.model.document.DocumentStateId}
                                documentCauseLogicType={item.Data.model.document.CauseLogicType}
                                className="btn-xs"
                                content={<span>{item.Data.model.document.Number}</span>}
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-2}
                    title={TextResources.Invoices.DocumentDateColumn}
                    sorter={sortDate((d) => d.document.Date)}
                    aggregateOn={(item) => item.Data.model.document.Date}
                    className="doc-date"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.DocumentDateColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.documentsStartDateFilter() || !!this.documentsEndDateFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.Invoices.DocumentDateFilter}
                                            startDate={this.documentsStartDateFilter}
                                            endDate={this.documentsEndDateFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <span data-bind={{ dateText: document.model.document.Date }}></span>
                </Column>
                <Column
                    id={-3}
                    title={TextResources.Invoices.DocumentVersionRevisionColumn}
                    sorter={sortString((d) => d.document.FullVersionRevisionNumber)}
                    aggregateOn={(item) => item.Data.model.document.FullVersionRevisionNumber}
                    className="doc-version-revision"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.DocumentVersionRevisionColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.documentsVersionRevisionSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.documentsVersionRevisionSearchField}
                                            label={TextResources.Invoices.DocumentVersionRevisionFilter}
                                            placeholder={TextResources.Invoices.DocumentVersionRevisionFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <span data-bind={{ text: document.model.document.FullVersionRevisionNumber }}></span>
                </Column>
                <Column
                    id={-4}
                    title={TextResources.Invoices.RegisterColumn}
                    sorter={sortString((d) => d.document.RegisterName)}
                    aggregateOn={(item) => item.Data.model.document.RegisterName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.RegisterColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item) => item.document.RegisterId}
                                    itemLabelGetter={(item) => item.document.RegisterName}
                                    onSelectionChange={this.onProtocolsSelectionChanges.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.RegisterName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-5}
                    title={TextResources.Invoices.CustomerColumn}
                    sorter={sortString((d) => d.document.CustomerName)}
                    aggregateOn={(item) => item.Data.model.document.CustomerName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.CustomerColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.customersSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.customersSearchField}
                                            label={TextResources.Invoices.DocumentCustomerFilter}
                                            placeholder={TextResources.Invoices.DocumentCustomerFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.CustomerName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-6}
                    title={TextResources.Invoices.JobOrderColumn}
                    sorter={sortString((d) => d.document.JobOrderName)}
                    aggregateOn={(item) => item.Data.model.document.JobOrderName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.JobOrderColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrdersSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.jobOrdersSearchField}
                                            label={TextResources.Invoices.DocumentJobOrderFilter}
                                            placeholder={TextResources.Invoices.DocumentJobOrderFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <div className="flex-container">
                                <div>
                                    <JobOrderDetailsButton
                                        jobOrderId={item.Data.model.document.JobOrderId}
                                        jobOrderTitle={item.Data.model.document.JobOrderName}
                                        extraSmall
                                    />
                                </div>
                                <JobOrderLink
                                    className="flex-fill text-ellipsis"
                                    jobOrderId={item.Data.model.document.JobOrderId}
                                    jobOrderName={item.Data.model.document.JobOrderName}
                                    openNewTab
                                />
                            </div>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-21}
                    title={TextResources.Invoices.JobOrderClassification}
                    sorter={sortString((d) => d.jobOrderMetadata?.Name ?? "")}
                    aggregateOn={(item: ITableItem<any, IDocumentWithMetadataDataSourceModel>) =>
                        item.Data.model.jobOrderMetadata?.Name
                    }
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.JobOrderClassification}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.jobOrderClassificationSearchField()}
                                    filterSource={this.Documents}
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.jobOrderMetadata?.Id}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.jobOrderMetadata?.Name}
                                    itemRenderer={renderJobOrderClassificationItemForFilter}
                                    onSelectionChange={this.onJobOrderClassificationSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => renderJobOrderClassificationItem(item.Data.model)}
                    </ColumnBody>
                </Column>
                <Column
                    id={-7}
                    title={TextResources.Invoices.ExternalReferenceColumn}
                    sorter={sortString((d) => d.document.ReferenceNumber)}
                    aggregateOn={(item) => item.Data.model.document.ReferenceNumber}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.ExternalReferenceColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.externalReferenceSearchField()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <>
                                            <TextInput
                                                value={this.externalReferenceSearchField}
                                                label={TextResources.Invoices.DocumentExternalReferenceFilter}
                                                placeholder={TextResources.Invoices.DocumentExternalReferenceFilter}
                                            />
                                            <DateRangeInput
                                                title={TextResources.Invoices.DocumentDateFilter}
                                                startDate={this.externalReferenceStartDateFilter}
                                                endDate={this.externalReferenceEndDateFilter}
                                            />
                                        </>
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <If
                                condition={() =>
                                    item.Data.model.document.ExternalReference ||
                                    item.Data.model.document.ReferenceNumber ||
                                    item.Data.model.document.ReferenceDate
                                }
                            >
                                {() => (
                                    <DocumentExternalReferenceInfo
                                        externalReference={item.Data.model.document.ExternalReference}
                                        referenceNumber={item.Data.model.document.ReferenceNumber}
                                        referenceDate={item.Data.model.document.ReferenceDate}
                                    />
                                )}
                            </If>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-8}
                    title={TextResources.Invoices.AdministrativeResponsibleColumn}
                    sorter={sortString((d) => d.document.AdministrativeResponsibleName)}
                    aggregateOn={(item) => item.Data.model.document.AdministrativeResponsibleName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.AdministrativeResponsibleColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) =>
                                        item.document.AdministrativeResponsibleName
                                    }
                                    itemLabelGetter={(item: DocumentWithMetadata) =>
                                        item.document.AdministrativeResponsibleName
                                    }
                                    onSelectionChange={this.onAdministrativeResponsibleSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.AdministrativeResponsibleName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-9}
                    title={TextResources.Invoices.CommercialResponsibleColumn}
                    sorter={sortString((d) => d.document.CommercialResponsibleName)}
                    aggregateOn={(item) => item.Data.model.document.CommercialResponsibleName}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.CommercialResponsibleColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) =>
                                        item.document.CommercialResponsibleName
                                    }
                                    itemLabelGetter={(item: DocumentWithMetadata) =>
                                        item.document.CommercialResponsibleName
                                    }
                                    onSelectionChange={this.onCommercialResponsibleSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.CommercialResponsibleName}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-10}
                    title={TextResources.Invoices.SourceWarehouseColumn}
                    sorter={sortString((d) => d.document.SourceWarehouse)}
                    aggregateOn={(item) => item.Data.model.document.SourceWarehouse}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.SourceWarehouseColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.SourceWarehouseId}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.SourceWarehouse}
                                    onSelectionChange={this.onSourceWarehouseSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.SourceWarehouse}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-11}
                    title={TextResources.Invoices.DestinationWarehouseColumn}
                    sorter={sortString((d) => d.document.DestinationWarehouse)}
                    aggregateOn={(item) => item.Data.model.document.DestinationWarehouse}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.DestinationWarehouseColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.DestinationWarehouseId}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.DestinationWarehouse}
                                    onSelectionChange={this.onDestinationWarehouseSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.DestinationWarehouse}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-12}
                    title={TextResources.Invoices.OutcomeColumn}
                    sorter={sortString((d) => d.document.Outcome)}
                    aggregateOn={(item) => item.Data.model.document.Outcome}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.OutcomeColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.FKOutcome}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.Outcome}
                                    onSelectionChange={this.onOutcomeSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => <span>{item.Data.model.document.Outcome}</span>}
                    </ColumnBody>
                </Column>
                <Column
                    id={-13}
                    title={TextResources.Invoices.StateColumn}
                    sorter={sortString((d) => d.document.DocumentState)}
                    aggregateOn={(item) => item.Data.model.document.DocumentState}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.StateColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.DocumentState}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.DocumentState}
                                    onSelectionChange={this.onStateSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.DocumentState}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-14}
                    title={TextResources.Invoices.CauseColumn}
                    sorter={sortString((d) => d.document.Cause)}
                    aggregateOn={(item) => item.Data.model.document.Cause}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.CauseColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.CauseId}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.Cause}
                                    onSelectionChange={this.onCauseSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => <span>{item.Data.model.document.Cause}</span>}
                    </ColumnBody>
                </Column>
                <Column
                    id={-15}
                    title={TextResources.Invoices.PaymentColumn}
                    sorter={sortString((d) => d.document.PaymentType)}
                    aggregateOn={(item) => item.Data.model.document.PaymentType}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.PaymentColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.PaymentType}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.PaymentType}
                                    onSelectionChange={this.onPaymentSelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.PaymentType}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-16}
                    title={TextResources.Invoices.ExpiryColumn}
                    sorter={sortString((d) => d.document.ExpiryType)}
                    aggregateOn={(item) => item.Data.model.document.ExpiryType}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.ExpiryColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                    itemKeyGetter={(item: DocumentWithMetadata) => item.document.ExpiryType}
                                    itemLabelGetter={(item: DocumentWithMetadata) => item.document.ExpiryType}
                                    onSelectionChange={this.onExpirySelectionChange.bind(this)}
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => <span>{item.Data.model.document.ExpiryType}</span>}
                    </ColumnBody>
                </Column>
                <Column
                    id={-17}
                    title={TextResources.Invoices.TaxableTotalColumn}
                    sorter={sortNumber((d) => d.document.TaxableTotal)}
                    aggregateOn={(item) => item.Data.model.document.TaxableTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.TaxableTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.taxableTotalMinValueFilter() !== null &&
                                            this.taxableTotalMinValueFilter() !== undefined) ||
                                        (this.taxableTotalMaxValueFilter() !== null &&
                                            this.taxableTotalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.TaxableTotalColumn}
                                            minValue={this.taxableTotalMinValueFilter}
                                            maxValue={this.taxableTotalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.TaxableTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-18}
                    title={TextResources.Invoices.VatColumn}
                    sorter={sortNumber((d) => d.document.VAT)}
                    aggregateOn={(item) => item.Data.model.document.VAT}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.VatColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.vatMinValueFilter() !== null && this.vatMinValueFilter() !== undefined) ||
                                        (this.vatMaxValueFilter() !== null && this.vatMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.VatColumn}
                                            minValue={this.vatMinValueFilter}
                                            maxValue={this.vatMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.VAT }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-19}
                    title={TextResources.Invoices.NotTaxableTotalColumn}
                    sorter={sortNumber((d) => d.document.NotTaxableTotal)}
                    aggregateOn={(item) => item.Data.model.document.NotTaxableTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.NotTaxableTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.notTaxableTotalMinValueFilter() !== null &&
                                            this.notTaxableTotalMinValueFilter() !== undefined) ||
                                        (this.notTaxableTotalMaxValueFilter() !== null &&
                                            this.notTaxableTotalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.NotTaxableTotalColumn}
                                            minValue={this.notTaxableTotalMinValueFilter}
                                            maxValue={this.notTaxableTotalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.NotTaxableTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-20}
                    title={TextResources.Invoices.TotalColumn}
                    sorter={sortNumber((d) => d.document.Total)}
                    aggregateOn={(item) => item.Data.model.document.Total}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.TotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.totalMinValueFilter() !== null &&
                                            this.totalMinValueFilter() !== undefined) ||
                                        (this.totalMaxValueFilter() !== null &&
                                            this.totalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.TotalColumn}
                                            minValue={this.totalMinValueFilter}
                                            maxValue={this.totalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.Total }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-22}
                    title={TextResources.Invoices.AssignedToWorkflowsTotalColumn}
                    sorter={sortNumber((d) => d.document.AssignedToWorkflowsTotal)}
                    aggregateOn={(item) => item.Data.model.document.AssignedToWorkflowsTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.AssignedToWorkflowsTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.assignedToWorkflowsMinValueFilter() !== null &&
                                            this.assignedToWorkflowsMinValueFilter() !== undefined) ||
                                        (this.assignedToWorkflowsMaxValueFilter() !== null &&
                                            this.assignedToWorkflowsMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.AssignedToWorkflowsTotalColumn}
                                            minValue={this.assignedToWorkflowsMinValueFilter}
                                            maxValue={this.assignedToWorkflowsMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.AssignedToWorkflowsTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-25}
                    title={TextResources.Invoices.WaitingToStartTotalColumn}
                    sorter={sortNumber((d) => d.document.WaitingToStartTotal)}
                    aggregateOn={(item) => item.Data.model.document.WaitingToStartTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.WaitingToStartTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.waitingToStartTotalMinValueFilter() !== null &&
                                            this.waitingToStartTotalMinValueFilter() !== undefined) ||
                                        (this.waitingToStartTotalMaxValueFilter() !== null &&
                                            this.waitingToStartTotalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.WaitingToStartTotalColumn}
                                            minValue={this.waitingToStartTotalMinValueFilter}
                                            maxValue={this.waitingToStartTotalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.WaitingToStartTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-26}
                    title={TextResources.Invoices.InProgressTotalColumn}
                    sorter={sortNumber((d) => d.document.InProgressTotal)}
                    aggregateOn={(item) => item.Data.model.document.InProgressTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.InProgressTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.inProgressTotalMinValueFilter() !== null &&
                                            this.inProgressTotalMinValueFilter() !== undefined) ||
                                        (this.inProgressTotalMaxValueFilter() !== null &&
                                            this.inProgressTotalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.InProgressTotalColumn}
                                            minValue={this.inProgressTotalMinValueFilter}
                                            maxValue={this.inProgressTotalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.InProgressTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-28}
                    title={TextResources.Invoices.InProgressTotalCompletedColumn}
                    sorter={sortNumber((d) => d.document.InProgressTotalCompleted)}
                    aggregateOn={(item) => item.Data.model.document.InProgressTotalCompleted}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.InProgressTotalCompletedColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.inProgressTotalCompletedMinValueFilter() !== null &&
                                            this.inProgressTotalCompletedMinValueFilter() !== undefined) ||
                                        (this.inProgressTotalCompletedMaxValueFilter() !== null &&
                                            this.inProgressTotalCompletedMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.InProgressTotalCompletedColumn}
                                            minValue={this.inProgressTotalCompletedMinValueFilter}
                                            maxValue={this.inProgressTotalCompletedMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.InProgressTotalCompleted }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-27}
                    title={TextResources.Invoices.CompletedTotalColumn}
                    sorter={sortNumber((d) => d.document.CompletedTotal)}
                    aggregateOn={(item) => item.Data.model.document.CompletedTotal}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0.00 $")}
                    className="text-right"
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.CompletedTotalColumn}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        (this.completedTotalMinValueFilter() !== null &&
                                            this.completedTotalMinValueFilter() !== undefined) ||
                                        (this.completedTotalMaxValueFilter() !== null &&
                                            this.completedTotalMaxValueFilter() !== undefined)
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <RangeInput
                                            title={TextResources.Invoices.CompletedTotalColumn}
                                            minValue={this.completedTotalMinValueFilter}
                                            maxValue={this.completedTotalMaxValueFilter}
                                            format="0,0.00"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        <span data-bind={{ moneyText: document.model.document.CompletedTotal }}></span>
                    </ColumnBody>
                </Column>
                <Column
                    id={-23}
                    title={TextResources.Invoices.AssignmentToWorkflowsStateColumn}
                    sorter={sortNumber((d) => d.document.Total)}
                    aggregateOn={(item) => item.Data.model.document.AssignedToWorkflowsTotalColumn}
                    aggregationFormatter={(value: number) => numeral(value ?? 0).format("0,0")}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.AssignmentToWorkflowsStateColumn}</span>
                                <TableFilter
                                    filterSource={this.Documents}
                                    itemKeyGetter={(item: DocumentWithMetadata) =>
                                        item.document?.AssignmentToWorkflowsState
                                    }
                                    itemLabelGetter={() => ""}
                                    itemRenderer={renderAssignmentToWorkflowsState}
                                    onSelectionChange={this.onAssignmentToWorkflowsStateSelectionChange.bind(this)}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                />
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <AssignmentToWorkflowsState state={item.Data.model.document.AssignmentToWorkflowsState} />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-24}
                    title={TextResources.Invoices.AssignedResources}
                    sorter={sortString((d) => d.document.AssignedResources)}
                    aggregateOn={(item) => item.Data.model.document.AssignedResources}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.AssignedResources}</span>
                                <TableFilter
                                    customFilterStateHandler={() => !!this.assignedResources()}
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <TextInput
                                            value={this.assignedResources}
                                            label={TextResources.Invoices.AssignedResources}
                                            placeholder={TextResources.Invoices.AssignedResourcesFilter}
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<DocumentWithMetadata>) => (
                            <span>{item.Data.model.document.AssignedResources}</span>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    id={-29}
                    title={TextResources.Invoices.LasMailSentDateColumn}
                    sorter={sortDate((d) => d.document.LastMailSentDate)}
                    aggregateOn={(item) => item.Data.model.document.LastMailSentDate}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Invoices.LasMailSentDate}</span>
                                <TableFilter
                                    customFilterStateHandler={() =>
                                        !!this.documentsLastMailSentFromDateFilter() ||
                                        !!this.documentsLastMailSentToDateFilter()
                                    }
                                    popoverContainer=".modal-body"
                                    popoverViewport=".modal-body"
                                >
                                    {() => (
                                        <DateRangeInput
                                            title={TextResources.Invoices.LasMailSentDateFilter}
                                            startDate={this.documentsLastMailSentFromDateFilter}
                                            endDate={this.documentsLastMailSentToDateFilter}
                                            datePickerParent=".modal-body"
                                        />
                                    )}
                                </TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <span data-bind={{ dateText: document.model.document.LastMailSentDate }}></span>
                </Column>
                {this.availableMetadatas.map((m) => {
                    const classNames = ComponentUtils.classNames("metadata-column", {
                        deleted: m.Deleted,
                        "text-right":
                            m.ValueType === MetadataType.DateTime ||
                            m.ValueType === MetadataType.Integer ||
                            m.ValueType === MetadataType.Decimal,
                        "text-center": m.ValueType === MetadataType.Boolean,
                    });

                    return (
                        <Column
                            key={m.Id}
                            id={m.Id}
                            title={m.Label}
                            sorter={getMetadataSorter(m)}
                            visible={!m.Deleted}
                            className={classNames}
                            aggregateOn={getMetadataAggregator(m)}
                            aggregationFormatter={getMetadataAggregatorFormatter(m)}
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{m.Label}</span>
                                        {m.ValueType !== MetadataType.List && (
                                            <TableFilter popoverContainer=".modal-body" popoverViewport=".modal-body">
                                                {() => (
                                                    <MetadataFilter
                                                        metadataId={m.Id}
                                                        metadataType={m.ValueType as MetadataType}
                                                        onFilterChanges={this.onMetadataFiltersChanges.bind(this)}
                                                        datePickerParent=".modal-body"
                                                    />
                                                )}
                                            </TableFilter>
                                        )}
                                        {m.ValueType === MetadataType.List && (
                                            <TableFilter
                                                filterSource={this.Documents}
                                                popoverContainer=".modal-body"
                                                popoverViewport=".modal-body"
                                                itemKeyGetter={(item: DocumentWithMetadata) => {
                                                    const dmd = item.metadata.firstOrDefault(
                                                        (md) => md.FKMetadata === m.Id
                                                    );
                                                    return dmd ? dmd.IntValue : null;
                                                }}
                                                itemLabelGetter={(item: DocumentWithMetadata) => {
                                                    const dmd = item.metadata.firstOrDefault(
                                                        (md) => md.FKMetadata === m.Id
                                                    );
                                                    return dmd ? dmd.ValueLabel : null;
                                                }}
                                                onSelectionChange={(values) =>
                                                    this.onMetadataFiltersChanges(
                                                        m.Id,
                                                        m.ValueType as MetadataType,
                                                        values,
                                                        null
                                                    )
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<DocumentWithMetadata>) =>
                                    this.renderMetadataValue(m, item.Data.model)
                                }
                            </ColumnBody>
                        </Column>
                    );
                })}
            </Table>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentWithMetadataReport);
}
