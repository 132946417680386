import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 26/02/2018
 * Time: 16:36
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { AgendaEventEditingDialog } from "./dialogs/AgendaEventEditingDialog";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IEventsUIProvider, IEventsService, IAgendaEventForEditingEx, IAgendaEventForEditing, IAgendaEventResource } from "../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IFullCalendar } from "../../../ProlifeSdk/interfaces/desktop/IFullCalendar";
import { Deferred } from "../../../Core/Deferred";

export class EventsUIProvider implements  IEventsUIProvider {

    private dialogsService: IDialogsService;
    private eventsService: IEventsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
    }

    GetEventEditingDialog(eventId: any = null, agendaId: number = null, calendar: IFullCalendar = null): Promise<IAgendaEventForEditingEx> {
        var def = new Deferred<IAgendaEventForEditingEx>();

        var id = this.ExtractEventId(eventId);
        this.eventsService.GetEventForEditing(id, false)
            .then((event: IAgendaEventForEditingEx) => {
                var dialog = new AgendaEventEditingDialog(this.serviceLocator, event, agendaId, calendar);
                this.dialogsService.ShowModal<IAgendaEventForEditingEx>(dialog, "large", null, "agenda/templates/dialogs", "agenda-event-dialog")
                    .then((result) => {
                        def.resolve(result);
                    });
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    GetEventEditingDialogByEventObject(event: IAgendaEventForEditing = null, agendaId: number = null, calendar: IFullCalendar = null): Promise<IAgendaEventForEditingEx> {
        var def = new Deferred<IAgendaEventForEditingEx>();

        var extendedEvent: IAgendaEventForEditingEx = <IAgendaEventForEditingEx> event;
        extendedEvent.CustomersIds = [];
        extendedEvent.Resources = [];
        extendedEvent.Tags = [];

        this.LoadCustomersAndResources(extendedEvent)
            .then((fullEvent: IAgendaEventForEditingEx) => {
                var dialog = new AgendaEventEditingDialog(this.serviceLocator, fullEvent, agendaId, calendar);
                this.dialogsService.ShowModal<IAgendaEventForEditingEx>(dialog, "large", null, "agenda/templates/dialogs", "agenda-event-dialog")
                    .then((result) => {
                        def.resolve(result);
                    });
            })
            .catch(() => {
                def.reject();
            });

        return def.promise();
    }

    private LoadCustomersAndResources(event: IAgendaEventForEditingEx): Promise<IAgendaEventForEditingEx> {
        var def = new Deferred<IAgendaEventForEditingEx>();
        var eventId = this.ExtractEventId(event.id);

        Promise.all([
            this.eventsService.GetEventCustomersIds(eventId),
            this.eventsService.GetEventResources(eventId)
        ]).then(([customersIdsResult, resourcesResult]) => {
                event.CustomersIds = customersIdsResult;
                event.Resources = resourcesResult;

                def.resolve(event);
            })
            .catch(() => {
                def.reject()
            });

        return def.promise();
    }

    private ExtractEventId(eventId: any): number {
        if (!eventId)
            return null;
        return typeof(eventId) == "number" ? eventId : parseInt(eventId.split("-")[1]);
    }
}