import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService, IGetCustomersForDocumentsRequest, ICustomerForDocuments } from "../ProlifeSdk/interfaces/customer/ICustomersService";

export class ContactValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var otherBindings = allBindingsAccessor();
        var alternativeDisplayKey = otherBindings.alternativeDisplayKey;

        var customersService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var instance = {};

        let preventChange = false;

        if(otherBindings.value && ko.isObservable(otherBindings.value)) {
            otherBindings.value.subscribe((newValue) => {
                if(preventChange) return;

                (<any>$(element)).typeahead('val', newValue);
            });
        }

        (<any>$(element)).typeahead({
            items: 50,
            minLength: 1,
            menu: '<ul class="typeahead dropdown-menu"></ul>',
            item: '<li><a href="#"></a></li>'
        }, {
            source : ContactValue.findContacts.bind(null, instance, customersService, otherBindings),
            display: alternativeDisplayKey || 'displayKey',
            limit: 50,
            templates : {
                suggestion : function(json)
                {
                    var item = JSON.parse(json);
                    var element = "<div>";
                    element += item.IsLocked ? "<i class='fa fa-lock'></i>&nbsp;" : "";
                    element += item.FullName ? "<b>" + item.FullName + "</b>" : "";
                    element += (item.PIVA && item.PIVA.trim().length > 0) ? "<small>&nbsp;(" + item.PIVA + ")</small>" : "";
                    element += (item.Address && item.Address.trim().length > 0) ? "<br/><small>" + item.Address + "</small>" : "";
                    return element + "</div>";
                }
            }

        }).on("typeahead:selected", (event, json) => {
            preventChange = true;

            var item = JSON.parse(json);
            valueAccessor()(item.id);
            otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(item.FullName);

            if(otherBindings.contactItem)
                otherBindings.contactItem(item);

            setTimeout(() => preventChange = false, 500);

            return item.FullName;
        });

        /*$(element).on("typeahead:queryChanged", (event) => {
            if(preventChange)
                return;

            console.log(element, "onchange", $(element).val(), ko.unwrap(valueAccessor()));

            if($(element).val() == ko.unwrap(valueAccessor()))
                return;

            valueAccessor()(null);
            otherBindings.value && ko.isObservable(otherBindings.value) && otherBindings.value(null);
            if(otherBindings.contactItem)
                otherBindings.contactItem(null);
        });*/
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var otherBindings = allBindingsAccessor();

        if(!newValue) {
            !otherBindings.value && $(element).val("");
            return;
        }
    }

    private static findContacts(_this : any, customersService : ICustomersService, otherBindings: any, query : string, process: (items : any[]) => any, asyncProcess: (items : any[]) => any) {
        if (_this.lastTimeout) {
            clearTimeout(_this.lastTimeout);
        }

        let forCustomers = !otherBindings.forCustomers ? null : ko.utils.unwrapObservable(otherBindings.forCustomers);
        let forSuppliers = !otherBindings.forSuppliers ? null : ko.utils.unwrapObservable(otherBindings.forSuppliers);
        let showAllCustomers = !otherBindings.forSuppliers ? false : (ko.utils.unwrapObservable(otherBindings.showAllCustomers) ?? false);

        let request: IGetCustomersForDocumentsRequest = {
            textFilter: query,
            forCustomers: forCustomers,
            forSuppliers: forSuppliers,
            showAllCustomers: showAllCustomers,
            skip: 0,
            count: 50
        };

        _this.lastTimeout = setTimeout(function() {
            _this.lastTimeout = 0;
            customersService.GetCustomersForDocuments(request)
                .then((contacts : ICustomerForDocuments[]) => {
                    return asyncProcess(ContactValue.transformContacts(contacts));
                });
        }, 500);
    }

    private static transformContacts(contacts : ICustomerForDocuments[]) : any[] {
        return contacts.map(ContactValue.transformContact);
    }

    private static transformContact(contact : ICustomerForDocuments) : any {
        return {
            id: contact.IdCliente,
            FullName: contact.FormattedContactName || "",
            Nome: contact.Nome,
            Cognome: contact.Cognome,
            displayKey: ((contact.BlockedBy || 0) > 0 ? "(▲) " : "") + (contact.FormattedContactName || ""),
            Address : contact.Indirizzo,
            PIVA: contact.PIVA || "",
            Provincia: contact.Provincia || "",
            IsLocked : (contact.BlockedBy || 0) > 0,
            toString: function() {
                return JSON.stringify(this);
            },
            toLowerCase: function() {
                return this.FullName.toLowerCase();
            },
            indexOf: function() {
                return String.prototype.indexOf.apply(this.FullName, arguments);
            },
            replace: function() {
                return String.prototype.replace.apply(this.FullName, arguments);
            }
        }
    }
}

ko.bindingHandlers["contactsValue"] = new ContactValue();