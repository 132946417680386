import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss"
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { If, IfNot } from "../../../../../Components/IfIfNotWith";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { AllocationStartType } from "../../../enums/AllocationStartType";
import { ResourcesAndGroupsManager } from "../../ResourcesAndGroupsManager";
import { CartLinksSelector } from "../editCartDialog/CartLinks";
import { ICart, ICartContentWithRolesInfo, ICartResource, ICartRoleInfo, ICartStatus } from "../../../../../ProlifeSdk/interfaces/allocations/ICart";
import { CartWorkableHoursCalculationModes } from "../../../../../JobOrder/jobOrder/settings/enums/CartWorkableHoursCalculationModes";
import { IAllocationsService, ICartWorkableHoursCalculationMode } from "../../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { ICartLink, ICartsService } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsService";
import { IAllocationType } from "../../../../interfaces/IAllocationType";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IJobOrderGeneralSettingsManager } from "../../../../../JobOrder/interfaces/settings/IJobOrderGeneralSettingsManager";
import { ICartsStatesSettingManager } from "../../../../../ProlifeSdk/interfaces/allocations/ICartsStatesSettingManager";
import { IValidationException } from "../../../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { ActivityBacklogOption, ActivityBacklogOptions } from "../../ActivityBacklogOption";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type CartFormProps = {
    cart?: ICart;
    resources?: ICartResource[];
    links?: ICartLink[];

    onCartNameChanged?: (cartName: string) => void;
    onCartWorkableHoursCalculationModeChange: (mode: CartWorkableHoursCalculationModes) => void;
    forwardRef?: (ref: _CartForm) => void;
}

export function CartForm(props: CartFormProps) {
    const C = require("./CartForm")._CartForm as typeof _CartForm;
    return <C {...props} />;
}

export class _CartForm {
    static defaultProps: Partial<CartFormProps> = {
        resources: [],
        links: []
    }

    public Id: number;
    public CartTitle: ko.Observable<string> = ko.observable();
    public Color: ko.Observable<string> = ko.observable();
    public SelectedState: ko.Observable<number> = ko.observable();
    public CartStatuses: ko.ObservableArray<ICartStatus> = ko.observableArray([]);
    public CreationDate: ko.Observable<Date> = ko.observable();
    public CreatorId: ko.Observable<number> = ko.observable();
    public ModifyDate: ko.Observable<Date> = ko.observable();
    public ModifierId: ko.Observable<number> = ko.observable();
    public ActivitiesBacklogOption : ko.Observable<ActivityBacklogOptions> = ko.observable();
    public CartWorkableHoursCalculationMode: ko.Observable<CartWorkableHoursCalculationModes> = ko.observable(CartWorkableHoursCalculationModes.JobOrderSpeed);
    public AllocationStartType : ko.Observable<number> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public Priority: ko.Observable<boolean> = ko.observable(false);
    public WorkEndDate: ko.Observable<Date> = ko.observable();
    public Links: ko.ObservableArray<ICartLink> = ko.observableArray([]);
    public AllocationsTypes: ko.ObservableArray<IAllocationType> = ko.observableArray([]);
    public CartWorkableHoursCalculationModesList: ko.ObservableArray<ICartWorkableHoursCalculationMode> = ko.observableArray([]);
    public CartContent: ko.ObservableArray<ICartContentWithRolesInfo> = ko.observableArray([]);
    public RolesInfo: ko.ObservableArray<ICartRoleInfo> = ko.observableArray([]);
    public ActivitiesBacklogOptions: ko.ObservableArray<ActivityBacklogOption> = ko.observableArray([]);
    
    public Resources: ResourcesAndGroupsManager;
    
    public IsManuallyAllocated: ko.Computed<boolean>;
    public ShowAdvancedOptions: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService!: IInfoToastService;
    @LazyImport(nameof<IAllocationsService>())
    private allocationsService: IAllocationsService;
    @LazyImportSettingManager(ProlifeSdk.CartStatus)
    private statusesManager: ICartsStatesSettingManager;
    @LazyImportSettingManager(ProlifeSdk.JobOrderGeneralSettings)
    private generalSettingsManager: IJobOrderGeneralSettingsManager;

    private cart: ICart;
    private calcModeInterceptor: ko.Subscription;
    private cartNameInterceptor: ko.Subscription;

    constructor(private props: CartFormProps) {
        this.CartWorkableHoursCalculationModesList(this.allocationsService.getCartWorkableHoursCalculationModesList());
        this.ActivitiesBacklogOptions(this.allocationsService.getActivitiesBacklogOptions());

        this.CartStatuses(this.statusesManager.getCartsStatuses());
        this.AllocationsTypes.push({ Id: AllocationStartType.FixedDate, Description: TextResources.Allocations.ManualAllocationType });
        this.AllocationsTypes.push({ Id: AllocationStartType.AsSoonAsPossible, Description: TextResources.Allocations.AutoAllocationType });

        this.Resources = new ResourcesAndGroupsManager();
        
        this.IsManuallyAllocated = ko.computed(() => {
            return this.AllocationStartType() == AllocationStartType.FixedDate;
        });

        this.props.forwardRef && this.props.forwardRef(this);
    }

    componentDidMount() {
        this.cartNameInterceptor = this.CartTitle.subscribe(this.props.onCartNameChanged);
        this.calcModeInterceptor = this.CartWorkableHoursCalculationMode.subscribe(this.props.onCartWorkableHoursCalculationModeChange);

        this.CartWorkableHoursCalculationMode(this.generalSettingsManager.getCartWorkableHoursCalculationMode() as CartWorkableHoursCalculationModes);
        this.loadData(this.props.cart);
    }

    private async loadData(cart?: ICart): Promise<void> {
        this.cart = cart;

        this.Id = this.cart?.Id;
        this.CartTitle(this.cart?.Title);
        this.Color(this.cart?.Color ?? "#FFFFFF");
        this.SelectedState(this.cart?.Status);
        this.CreationDate(this.cart?.CreationDate);
        this.CreatorId(this.cart?.CreatorId);
        this.ModifyDate(this.cart?.ModifyDate);
        this.ModifierId(this.cart?.ModifierId);
        this.ActivitiesBacklogOption(this.cart?.IgnoreBacklog === true ? ActivityBacklogOptions.IgnoreBacklog : (this.cart?.IgnoreBacklog === false ? ActivityBacklogOptions.UseBacklog : ActivityBacklogOptions.UseSystemDefault));
        this.CartWorkableHoursCalculationMode(this.cart?.CartWorkableHoursCalculationMode as CartWorkableHoursCalculationModes ?? this.CartWorkableHoursCalculationMode())
        this.AllocationStartType(this.cart ? (this.cart.AllocationStartType === null || this.cart.AllocationStartType === undefined ? AllocationStartType.AsSoonAsPossible : this.cart.AllocationStartType) : AllocationStartType.AsSoonAsPossible);
        this.StartDate(this.cart?.StartDate);
        this.Priority(!!this.cart?.Priority);
        this.WorkEndDate(this.cart?.WorkEndDate);

        if (this.props.resources.length > 0)
            this.Resources.LoadResources(this.props.resources);

        this.Links(this.props.links);
    }

    componentWilUnmount() {
        this.cartNameInterceptor.dispose();
        this.calcModeInterceptor.dispose();
    }

    public getCartData(): ICart {
        return {
            Id: this.Id,
            Title: this.CartTitle(),
            Color: this.Color(),
            Status: this.SelectedState(),
            CreationDate: null,
            CreatorId: null,
            ModifyDate: null,
            ModifierId: null,
            IgnoreBacklog: this.ActivitiesBacklogOption() === ActivityBacklogOptions.UseSystemDefault ? null : (this.ActivitiesBacklogOption() === ActivityBacklogOptions.IgnoreBacklog ? true : false),
            CartWorkableHoursCalculationMode: this.CartWorkableHoursCalculationMode(),
            AllocationStartType: this.AllocationStartType(),
            StartDate: this.StartDate(),
            Priority: this.Priority() ? 1 : 0,
            WorkEndDate: this.WorkEndDate()
        };
    }

    public async save(): Promise<ICart> {
        const startDate = this.StartDate();

        if (!this.CartTitle() || !this.Color() || (this.AllocationStartType() == AllocationStartType.FixedDate && !startDate)) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Allocations.MissingRequiredCartData);
            return null;
        }

        if (this.AllocationStartType() == AllocationStartType.FixedDate && moment(startDate).startOf('day').diff(moment().startOf("day"), "days") > 365 * 2) {
            this.infoToastService.Error(TextResources.Allocations.InvalidCartStartDate);
            return null;
        }

        if (this.Links().filter(l => l.DelayInDays < 0).length > 0) {
            this.infoToastService.Error(TextResources.Allocations.InvalidLinksDelayValue);
            return null;
        }

        const cartData: ICart = this.getCartData();
        const cartLinks: ICartLink[] = this.Links();

        if (!this.cart || !this.cart.Id || this.cart.Id < 0) {
            try {
                const insertedCart: ICart = await this.cartsService.addCart(cartData, this.Resources.GetResources(), cartLinks);
                this.cart = Object.assign(this.cart ?? {}, insertedCart);
                this.Id = insertedCart.Id;
                this.infoToastService.Success(TextResources.Allocations.CartSavedSuccessfully);
                return insertedCart;
            } catch (e) {
                this.handleException(e, ProlifeSdk.TextResources.Allocations.InsertCartError);
                return null;
            }   
        }

        try {
            const updatedCart: ICart = await this.cartsService.updateCart(cartData, this.Resources.GetResources(), cartLinks);
            this.cart = Object.assign(this.cart ?? {}, updatedCart);
            this.infoToastService.Success(TextResources.Allocations.CartSavedSuccessfully);
            return updatedCart;
        } catch(e) {
            this.handleException(e, ProlifeSdk.TextResources.Allocations.UpdateCartError);
            return null;
        }
    }

    private async handleException(exception: IValidationException, defaultMessage: string): Promise<void> {
        if (exception.ExceptionCode == 34333) {
            await this.cartsService.showCircularDependenciesAlert(exception.ValidationData);
            return;
        }

        this.infoToastService.Error(defaultMessage);
    }

    render() {
        const cartEditor = this;
        const resourcesManager: ResourcesAndGroupsManager = null;
        
        return ComponentUtils.bindTo((
            <>
                <div className="row">
                    <div className="col-md-12 flex-container">
                        <div className="form-group" style={{ marginRight: "30px", paddingLeft: "1px" }}>
                            <label>{TextResources.Allocations.CartColor}</label>
                            <input type="text" className="form-control" data-bind={{ colorPicker: { colorField: cartEditor.Color } }} />
                        </div>
                        <div className="form-group flex-fill">
                            <label>{TextResources.Allocations.CartNameAlt}</label>
                            <input type="text" className="form-control" data-bind={{ value: cartEditor.CartTitle }} placeholder={TextResources.Allocations.CartTitlePlaceholder} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="control-label">{TextResources.Allocations.RemainingWorkAllocationMode}</label>
                            <select className="form-control" data-bind={{ options: cartEditor.CartWorkableHoursCalculationModesList, value: cartEditor.CartWorkableHoursCalculationMode, optionsValue: 'Mode', optionsText: 'Description' }}></select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <div class="form-group">
                                <label class="control-label">{TextResources.Allocations.ManageActivityBackloglabel}</label>
                                <select class="form-control" data-bind={{ value: cartEditor.ActivitiesBacklogOption, options: cartEditor.ActivitiesBacklogOptions, optionsValue: 'id', optionsText: 'label' }}></select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label>{TextResources.Allocations.CartWithPriorityAlt}</label><br/>
                        <input type="checkbox" data-bind={{ onOffSwitch: cartEditor.Priority }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4>
                            {TextResources.Allocations.ShowCartAdvancedOptions}&nbsp;
                            <span onClick={() => this.ShowAdvancedOptions(!this.ShowAdvancedOptions())}>
                                <If condition={this.ShowAdvancedOptions}>
                                    {() => <i className="fa fa-chevron-up"></i>}
                                </If>
                                <IfNot condition={this.ShowAdvancedOptions}>
                                    {() => <i className="fa fa-chevron-down"></i>}
                                </IfNot>
                            </span>
                        </h4>
                    </div>
                </div>
                <If condition={this.ShowAdvancedOptions}>
                    {() => (
                        <>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Allocations.AllocationMode}</label>
                                        <select className="form-control" data-bind={{ options: cartEditor.AllocationsTypes, optionsValue: 'Id', optionsText: 'Description', value: AllocationStartType }}></select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Allocations.ManualAllocationStart}</label>
                                        <input type="text" className="form-control" placeholder={TextResources.Allocations.DateSelectionPlaceholder} data-bind={{ nullableDatePicker: cartEditor.StartDate, attr: { 'disabled': !cartEditor.IsManuallyAllocated() } }}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Allocations.WorkEndDate}</label>
                                        <input type="text" className="form-control" placeholder={TextResources.Allocations.DateSelectionPlaceholder} data-bind={{ nullableDatePicker: cartEditor.WorkEndDate }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">{TextResources.Allocations.CartResponsibles}</label>
                                        <ko-bind data-bind={{ with : cartEditor.Resources, as: "resourcesManager" }}>
                                        <input className="form-control default" type="hidden" data-bind={{ value: resourcesManager.Resources, select2: { ajax : { quietMillis : 500 }, query : resourcesManager.resourcesProvider.findEntities.bind(resourcesManager.resourcesProvider), initSelection: resourcesManager.resourcesProvider.findEntity.bind(resourcesManager.resourcesProvider), allowClear: true, placeholder: 'Seleziona...', multiple: true, minimumInputLength: 1 } }} />
                                        </ko-bind>
                                    </div>
                                </div>
                            </div>
                            <CartLinksSelector
                                value={this.Links}
                                cartId={this.props.cart?.Id}
                                onLinkAdded={(linkId, delay) => this.Links.push({ LinkCartId: linkId, DelayInDays: delay, CartId: this.Id })}
                                onLinkRemoved={(linkId) => { const link = this.Links().firstOrDefault(l => l.LinkCartId === linkId); link && this.Links.remove(link); }}
                                onLinkDelayChange={(value: number) => this.Links().forEach(l => l.DelayInDays = value)}
                            />
                        </>
                    )}
                </If>
                {!!this.props.cart && this.props.cart.Id > 0 && (
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <a href="#" className="btn btn-primary" data-bind={{ click: cartEditor.save.bind(cartEditor) }}>{TextResources.ProlifeSdk.Save}</a>
                        </div>
                    </div>
                )}
            </>
        ), this, "cartEditor");
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(CartForm);
}