import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/02/13
 * Time: 12.21
 * To change this template use File | Settings | File Templates.
 */

function isNumberChar(val)
{
    if((val < 48 || val > 57) && (val < 96 || val > 105))
        return true;
    return false;
}

function isPunctuationDate(val)
{
    // Point, .Canc, barra , slash, space
    if(val == 190 || val == 110 || val == 111 || val == 220 || val == 32)
        return true;

    return false;
}

function isPunctuationChar(val)
{
    // Point, .Canc, comma  ,apex, space, -
    if(val == 190 || val == 110 || val == 188 || val == 219 || val == 32 || val == 109)
        return true;

    return false;
}

function isUserFriendlyChar(val) {
    // Backspace, Tab, Enter, Insert, and Delete
    if(val == 8 || val == 9 || val == 13 || val == 45 || val == 46)
        return true;


    // Ctrl, Alt, CapsLock, Home, End, and Arrows
    if((val > 16 && val < 21) || (val > 34 && val < 41))
        return true;


    // The rest
    return false;
}

export class NumbersOnly {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        $(element).keydown(function(event) {

            if(event.ctrlKey || event.altKey)
                return true;

            if(isUserFriendlyChar(event.keyCode))
                return true;

            if(isPunctuationChar(event.keyCode))
                return true;

            if(isNumberChar(event.keyCode))
                return false;

            return true;
        });
    }
}

ko.bindingHandlers["numbersOnly"] = new NumbersOnly();