import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss" ;
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ParamArray, Param, ComponentParamArray, ComponentParam } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IWorkflowOutcomeChange } from "../Core/interfaces/IException";

const { classes } = jss.createStyleSheet({
    tableBody: {
        "border-bottom": "1px solid #ddd"
    },
    outcomesActions: {
        "margin-top": "10px",

        "& .action-desc": {
            position: "relative",
            "padding-top": "30px",

            "& .default": {
                position: "absolute",
                top: "10px"
            }
        }
    }
}).attach();

let attributes = {
    PromptText: "prompt-text",
    OutcomeChanges: "outcome-changes",
    OnNotApplyOutcomesClick: "on-not-apply-outcomes-click",
    OnApplyOutcomesClick: "on-apply-outcomes-click"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "workflow-outcome-changes-alert": {
               params?: {
                   PromptText: string;
                   OutcomeChanges: IWorkflowOutcomeChange[],
                   OnNotApplyOutcomesClick: () => void;
                   OnApplyOutcomesClick: () => void;
               };
               "prompt-text": string | (() => string);
               "outcome-changes": IWorkflowOutcomeChange[] | (() => string);
               "on-not-apply-outcomes-click": () => void,
               "on-apply-outcomes-click": () => void
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface IWorkflowOutcomeChangesAlertParams {
    PromptText: Param<string>;
    OutcomeChanges: ParamArray<IWorkflowOutcomeChange>;
    OnNotApplyOutcomesClick: () => void;
    OnApplyOutcomesClick: () => void;
}

export class WorkflowOutcomeChangesAlert {
    public PromptText: ComponentParam<string>;
    public OutcomeChanges: ComponentParamArray<IWorkflowOutcomeChange>;

    public OnNotApplyOutcomesClick: () => void;
    public OnApplyOutcomesClick: () => void;

    constructor(params : IWorkflowOutcomeChangesAlertParams) {
        this.PromptText = ComponentUtils.parseParameter(params.PromptText, "");
        this.OutcomeChanges = ComponentUtils.parseParameterArray(params.OutcomeChanges, []);

        this.OnNotApplyOutcomesClick = params.OnNotApplyOutcomesClick || (() => {});
        this.OnApplyOutcomesClick = params.OnApplyOutcomesClick || (() => {});
    }
}

ko.components.register("workflow-outcome-changes-alert", {
    viewModel: {
        createViewModel: (params: IWorkflowOutcomeChangesAlertParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new WorkflowOutcomeChangesAlert(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="flex-container flex-fill flex-full-height flex-vertical">
                    <div class="flex-container flex-vertical flex-fill">
                        <div class="row">
                            <div class="col-md-12">
                                <span data-bind="text: PromptText"></span>
                            </div>
                        </div>
                        <div class="flex-container flex-fill flex-vertical no-gutters">
                            <table class="table table-condensed table-striped" style="margin-bottom: 0px;">
                                <thead>
                                    <tr>
                                        <th style="width: 50%">{TextResources.Todolist.Workflow}</th>
                                        <th style="width: 25%">{TextResources.Todolist.WorkflowActualOutcomeLabel}</th>
                                        <th style="width: 25%">{TextResources.Todolist.WorkflowNewOutcomeLabel}</th>
                                    </tr>
                                </thead>
                                <tbody style="height: 0px !important"></tbody>
                            </table>
                            <div class={"flex-fill flex-container flex-vertical " + classes.tableBody}>
                                <div class="flex-full-height" data-bind="slimScroll: '100%'">
                                    <table class="table table-condensed table-striped">
                                        <thead style="height: 0px !important"></thead>
                                        <tbody data-bind="foreach: OutcomeChanges">
                                            <tr>
                                                <td style="width: 50%" data-bind="text: WorkflowTitle"></td>
                                                <td style="width: 25%" data-bind="text: (OldOutcome ? OldOutcome : 'Nessun esito')"></td>
                                                <td style="width: 25%" data-bind="text: NewOutcome"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class={"row " + classes.outcomesActions}>
                        <div class="col-md-6">
                            <button class="btn btn-default" data-bind="click: OnNotApplyOutcomesClick">{TextResources.Todolist.WorkflowOutcomeChangesNotApplyChangesButton}</button>
                            <div class="action-desc">
                                {TextResources.Todolist.WorkflowOutcomeChangesNotApplyAction}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-primary" data-bind="click: OnApplyOutcomesClick">{TextResources.Todolist.WorkflowOutcomeChangesApplyChangesButton}</button>
                            <div class="action-desc">
                                <span class="bold default">{TextResources.ProlifeSdk.Default}</span>
                                {TextResources.Todolist.WorkflowOutcomeChangesApplyAction}
                            </div>
                        </div>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});