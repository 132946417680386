import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProjectsApplication } from "./projects/ProjectsApplication";
import { ProjectsEntityProvider } from "./projects/entityProviders/ProjectsEntityProvider";
import { WorkItemsEntityProvider } from "./projects/entityProviders/WorkItemsEntityProvider";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IProjectsService } from "../ProlifeSdk/interfaces/projects/IProjectsService";
import { IService } from "../Core/interfaces/IService";
import { IProject } from "../ProlifeSdk/interfaces/projects/IProject";
import { IWorkItemHint, IWorkItemBase } from "../ProlifeSdk/interfaces/projects/IWorkItem";

class ProjectsService implements IProjectsService
{
    private application : ProjectsApplication;
    private ajaxService : IAjaxService;

    constructor(private serviceLocator : IServiceLocator)
    {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IProjectsService>(), this);
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    InitializeService() {
        this.application = new ProjectsApplication(this.serviceLocator);
        new ProjectsEntityProvider(this.serviceLocator);
        new WorkItemsEntityProvider(this.serviceLocator);
    }

    getServiceType(): string
    {
        return ProlifeSdk.ProjectsServiceType;
    }

    isOfType(serviceType: string): boolean
    {
        return serviceType == this.getServiceType();
    }

    hintSearch(value : string) : Promise<IProject[]>
    {
        return this.ajaxService.Get("Projects-api/Project", "HintSearch?value=" + value, {
            background: true
        });
    }

    workItemsHintSearch(value : string, projectId? : number) : Promise<IWorkItemHint[]>
    {
        var queryString = projectId ? "&projectId=" + projectId : "";

        return this.ajaxService.Get("Projects-api/WorkItem", "HintSearch?value=" + value + queryString, {
            background: true
        });
    }

    get(id : number) : Promise<IProject>
    {
        if(!id)
            return Promise.reject();

        return this.ajaxService.Get("Projects-api/Project", "" + id, {});
    }

    getWorkItem(id : number) : Promise<IWorkItemBase>
    {
        if(!id)
            return Promise.reject()

        return this.ajaxService.Get("Projects-api/WorkItem", "" + id, {});
    }

    getJobOrderProjects(jobOrderId : number) : Promise<any[]>
    {
        if(!jobOrderId)
            return Promise.reject();

        return this.ajaxService.Get("Projects-api/Project", "JobOrderProjects?id=" + jobOrderId, {});
    }

    getAllProjects() : Promise<any[]>
    {
        return this.ajaxService.Get("Projects-api/AllProjects", "", {});
    }

    getWorkItemUrl(workItemId: number): string
    {
        return "projects/index.html#/workitemdetail/" + workItemId;
    }

    getProjectUrl(projectId: number): string
    {
        return "projects/index.html#/projectdetail/" + projectId;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new ProjectsService(serviceLocator);
}
