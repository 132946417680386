import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 17/04/13
 * Time: 17.48
 * To change this template use File | Settings | File Templates.
 */

export class HtmlValue {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        ko.utils.registerEventHandler(element, "keyup", function() {
                var modelValue = valueAccessor();
                var elementValue = element.innerHTML.replace('<br>', '\n');
                if (ko.isWriteableObservable(modelValue)) {
                    modelValue(elementValue);
                }
                else { //handle non-observable one-way binding
                    var allBindings = allBindingsAccessor();
                    if (allBindings['_ko_property_writers'] && allBindings['_ko_property_writers'].htmlValue)
                        allBindings['_ko_property_writers'].htmlValue(elementValue);
                }
            }
        )
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var value = (ko.utils.unwrapObservable(valueAccessor()) || "").replace('\n', '<br>');
        if (element.innerHTML !== value) {
            element.innerHTML = value;
        }
    }
}

ko.bindingHandlers["htmlValue"] = new HtmlValue();