import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IScheduleStateSettingsManager } from "../ProlifeSdk/interfaces/schedule/settings/IScheduleStateSettingsManager";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IScheduleLogicalState } from "../ProlifeSdk/interfaces/schedule/settings/IScheduleLogicalState";

export class ScheduleStateDescription {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

		var interceptor = ko.computed(() => {
			var options = valueAccessor();

			var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
			var scheduleStateSettingsManager : IScheduleStateSettingsManager = <IScheduleStateSettingsManager> settingsService.findSettingsManager(ProlifeSdk.ScheduleState);

			var elements = scheduleStateSettingsManager.getScheduleLogicalState().filter((elem : IScheduleLogicalState)=> elem.IdScheduleLogicalState == options.logicalState);

			return elements[0].Descrizione;
		});

		ko.applyBindingsToNode(element, { text: interceptor }, undefined);
	}
}

ko.bindingHandlers["ScheduleStateDescription"] = new ScheduleStateDescription();
ko.virtualElements.allowedBindings["ScheduleStateDescription"] = true;