import * as ko from "knockout";
import { NodeSubElement } from "./NodeSubElement";
import { NodeDetails } from "./NodeDetails";
import { INodeRow } from "../../../interfaces/invoice/IReferencesMap";

export class DocumentRowElement extends NodeSubElement
{
    constructor(public row : INodeRow, node : NodeDetails)
    {
        super(node, row.Id, row.EntityType);
    }
}
