import { ReportComponent } from "../Components";
import { ConstructableReportEditor } from "./ReportEditor";

type ReportEditorMap = {
    [feature: string] : ConstructableReportEditor;
}

class _ReportEditorProvider {
    private editors : ReportEditorMap = {};

    public registerEditor(feature: string, editorFactory : ConstructableReportEditor) {
        if(this.editors[feature])
            console.warn(`An editor for feature "${feature}" has already been registered`);

        this.editors[feature] = editorFactory;
    }

    public createEditors(component : ReportComponent) : ConstructableReportEditor[] {
        let editors = [];

        for(let feature of component.features) {
            if(!this.editors[feature])
                continue;//throw `No editor is registered for feature "${feature}"`;
            
            editors.push(this.editors[feature]);
        }
        
        return editors;
    }
}

export var ReportEditorProvider = new _ReportEditorProvider();
export function EditorFor(feature : string) : ClassDecorator {
    return (constructor : any) => {
        ReportEditorProvider.registerEditor(feature, constructor);
    }
}