import * as ko from "knockout";
import { IService } from "../../../Core/interfaces/IService";
import {
    ICustomer,
    ICustomerHint,
    IFullCustomer,
    IContactUoForList,
    IContactForDiscountList,
    ICustomerTrust,
} from "./ICustomer";
import { ICustomersUI } from "./ICustomersUI";
import { ICustomersServiceObserver } from "./ICustomersServiceObserver";
import { ISelect2ResponsibleProvider } from "./providers/ISelect2ResponsibleProvider";
import { IRecipientCode } from "./IRecipientCode";

export interface IGetCustomersForListByGroupRequest {
    groupId?: number;
    textFilter?: string;
    skip?: number;
    count?: number;
    isCustomer?: boolean;
    isSupplier?: boolean;
}

export interface ISupplierForList {
    IdCliente: number;
    RagioneSociale?: string;
}

export interface ILetterOfAttemptInvoice {
    InvoiceId: number;
    InvoiceNumber?: string;
    InvoiceDate?: Date;
    ProtocolId: number;
    ProtocolName?: string;
    InvoicePriceForLetterOfAttempt?: number;
    LetterOfAttemptId?: number;
}

export interface ILetterOfAttemptForEditor extends ILetterOfAttempt {
    Deleted: boolean;
    RevenueAgencyReceiptDate?: Date;
}

export interface ICustomerForList {
    Id: number;
    NameForGroup?: string;
    Name?: string;
    IsCustomer: boolean;
    IsSupplier: boolean;
    IsPrivateContact: boolean;
    IsCompany: boolean;
    BlockedBy?: number;
    CustomerGroup?: string;
}

export interface ICustomerForListWithGroup {
    Id: number;
    NameForGroup?: string;
    Name?: string;
    IsCustomer: boolean;
    IsSupplier: boolean;
    IsPrivateContact: boolean;
    IsCompany: boolean;
    BlockedBy?: number;
    CustomerGroup?: string;
}

export interface IGetCustomersForDocumentsRequest {
    textFilter?: string;
    forCustomers?: boolean;
    forSuppliers?: boolean;
    showAllCustomers?: boolean;
    skip?: number;
    count?: number;
}

export interface ICustomerForDocuments {
    IdCliente: number;
    FormattedContactName?: string;
    Nome?: string;
    Cognome?: string;
    BlockedBy?: number;
    Indirizzo?: string;
    PIVA?: string;
    Provincia?: string;
}

export interface ICustomerFiscalInfo {
    Id: number;
    Name?: string;
    TaxCode?: string;
    VatNumber?: string;
    IsCompany: boolean;
    IsCustomer: boolean;
    IsSupplier: boolean;
}

export interface IGetAllCustomersPaymentExtensionsRequest {
    customerId?: number;
    startDate?: Date;
    endDate?: Date;
    skip?: number;
    count?: number;
}

export interface ICustomerPaymentExtension {
    PaymentExtensionId: number;
    CustomerId: number;
    CustomerName?: string;
    CustomerVatNumber?: string;
    CustomerTaxCode?: string;
    ExtensionDate: Date;
    ExtensionValue: number;
}

export interface IGetLettersOfAttemptsRequest {
    CustomerId?: number;
    Skip?: number;
    Count?: number;
    ShowClosed?: boolean;
    ReferenceDate?: Date;
    OnlyValidAtReferenceDate?: boolean;
    documentId?: number;
    documentType?: string;
}

export interface ILetterOfAttempt {
    Id: number;
    Description?: string;
    StartDate: Date;
    DueDate: Date;
    Amount: number;
    CustomerId: number;
    Note?: string;
    ManuallyClosed: boolean;
    RevenueAgencyReceiptDate?: Date;
    UsedAmount?: number;
}

export type MetadataLogicalType = {
    id: number;
    label: string;
    metadataCategory?: number;
};

export enum MetadataLogicalTypeSource {
    All = 0,
    CustomerMetadata = 1,
    CustomerPEC = 2,
    ElectronicInvoicingPEC = 3,
}

export interface ICustomersService extends IService {
    searchMetadataLogicalTypes(
        category: number,
        textFilter: string,
        dataSources: MetadataLogicalTypeSource[],
        skip: number,
        count: number
    ): Promise<MetadataLogicalType[]>;
    getMetadataLogicalTypes(addressTypes: number[]): Promise<MetadataLogicalType[]>;

    getAllCustomers(filter?: string, customers?: boolean, suppliers?: boolean): Promise<ICustomer[]>;
    getAllCustomersLimited(
        filter?: string,
        customers?: boolean,
        suppliers?: boolean,
        showAllCustomers?: boolean
    ): Promise<ICustomer[]>;
    getAllCustomersEnvelope(filter?: string, customers?: boolean, suppliers?: boolean): Promise<ICustomer[]>;
    getCustomerById(customerId: number): Promise<ICustomer>;
    hintSearch(query: string): Promise<ICustomerHint[]>;

    ui: ICustomersUI;

    getCustomer(customerId: number): Promise<ICustomer>;
    saveCustomer(customer: IFullCustomer): Promise<ICustomer>;
    deleteCustomer(customerId: number): Promise<number>;

    addObserver(observer: ICustomersServiceObserver): void;
    removeObserver(observer: ICustomersServiceObserver): void;

    GetCustomersForList(
        textFilter: string,
        skip: number,
        count: number,
        getCustomers?: boolean,
        getSuppliers?: boolean,
        getItalianCustomers?: boolean,
        getForeignCustomers?: boolean
    ): Promise<ICustomerForList[]>;
    GetCustomersCount(textFilter: string): Promise<number>;

    GetContactName(customer: ICustomer): string;
    GetCustomerUrl(customerId: number): string;

    GetCustomerByVatNumber(vatNumber: string): Promise<ICustomer>;
    GetCustomersByPhoneNumber(phoneNumber: string): Promise<ICustomer[]>;
    GetCustomerForList(customerId: number): Promise<ICustomerForList>;
    GetContactsUoForList(textFilter: string, onlyCompanies: boolean): Promise<IContactUoForList[]>;
    GetContactUoForList(id: number): Promise<IContactUoForList>;

    GetContactsForWarehouseDiscountsList(
        textFilter: string,
        skip: number,
        count: number,
        excludeWithouthDiscounts: boolean,
        isCustomer: boolean,
        isSupplier: boolean
    ): Promise<IContactForDiscountList[]>;
    GetContactsCountForDiscountsList(
        textFilter: string,
        excludeWithouthDiscounts: boolean,
        isCustomer: boolean,
        isSupplier: boolean
    ): Promise<number>;

    SearchTags(textFilter: string, count: number): Promise<string[]>;

    GetSelect2ResponsibleProvider(): ISelect2ResponsibleProvider;

    SetCustomerBlockStatus(
        customerId: number,
        locked: boolean,
        lockJobOrders: boolean,
        lockMotivation?: number
    ): Promise<ICustomer>;

    GetActualTrust(customerId: number): Promise<ICustomerTrust>;

    getCustomerRecipientCodes(customerId: number): Promise<IRecipientCode[]>;
    generateFakeId(): number;
    GetCustomersForListByGroup(request: IGetCustomersForListByGroupRequest): Promise<ICustomerForListWithGroup[]>;
    GetSuppliersForList(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<ISupplierForList[]>;
    GetSuppliersForListByIds(ids: number[] | null): Promise<ISupplierForList[]>;
    GetLetterOfAttemptInvoices(
        LetterOfAttemptId: number | null,
        Skip: number | null,
        Count: number | null
    ): Promise<ILetterOfAttemptInvoice[]>;
    GetLetterOfAttemptInvoicesByIds(Ids: number[] | null): Promise<ILetterOfAttemptInvoice[]>;
    InsertOrUpdateLettersOfAttempts(LettersOfAttempts: ILetterOfAttemptForEditor[] | null): Promise<void>;
    GetCustomersForDocuments(request: IGetCustomersForDocumentsRequest): Promise<ICustomerForDocuments[]>;
    GetCustomerByTaxCodeOrVatNumber(taxCode: string | null, vatNumber: string | null): Promise<ICustomerFiscalInfo[]>;
    GetAllCustomersPaymentExtensionsByIds(ids: number[] | null): Promise<ICustomerPaymentExtension[]>;
    GetAllCustomersPaymentExtensions(
        request: IGetAllCustomersPaymentExtensionsRequest
    ): Promise<ICustomerPaymentExtension[]>;
    GetLettersOfAttempts(request: IGetLettersOfAttemptsRequest): Promise<ILetterOfAttempt[]>;
    GetLettersOfAttemptsByIds(Ids: number[] | null): Promise<ILetterOfAttempt[]>;
}
