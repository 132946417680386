import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import "../../../../Bindings/HighChart";
import {
    IJobOrderWarehouseStockValue,
    IJobOrderWarehouseSentValue,
    IJobOrderWorkCostPerDay,
    IJobOrderInvoiceRevenue,
    IJobOrderEstimatedRevenue,
    IJobOrderAuthorizedBudget,
    IJobOrderPurchasesCostPerDay,
    IJobOrderEstimatedCost,
    IJobOrderHistoricalEconomics,
    IJobOrderEconomicsData,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { ILogService } from "../../../../ProlifeSdk/interfaces/desktop/ILogService";
import {
    IEconomicsProviderObserver,
    IEconomicsProvider,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrdersProvider";
import { IGraphInfo } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { CostMode, RevenuesMode } from "./panels/JobOrderCostsRevenues";

//
//  Brief : ViewModel della dashboard dei ricavi
//
export class RevenuesDashboard implements IEconomicsProviderObserver {
    public provider: IEconomicsProvider;

    private costsColor = "#E35B5A";
    private revenuesColor = "#578EBE";
    private otherColor = "#000000";
    private dashStyle = "ShortDot";

    //Costi
    public warehouseData: ko.Observable<Highcharts.Options> = ko.observable();
    public otherData: ko.Observable<Highcharts.Options> = ko.observable();
    public workData: ko.Observable<Highcharts.Options> = ko.observable();
    public purchaseData: ko.Observable<Highcharts.Options> = ko.observable();
    public totalData: ko.Observable<Highcharts.Options> = ko.observable();
    public costsChartData: ko.Observable<Highcharts.Options> = ko.observable();
    public estimatedCostsChartData: ko.Observable<Highcharts.Options> = ko.observable();
    public revenuesChartData: ko.Observable<Highcharts.Options> = ko.observable();
    public estimatedRevenuesChartData: ko.Observable<Highcharts.Options> = ko.observable();

    @LazyImport(nameof<ILogService>())
    public LogService: ILogService;

    private Dates: ko.ObservableArray<Date> = ko.observableArray();

    private WarehouseStockValues: ko.ObservableArray<IJobOrderWarehouseStockValue> = ko.observableArray();
    private WarehouseSentValues: ko.ObservableArray<IJobOrderWarehouseSentValue> = ko.observableArray();
    private WorkCostPerDayValues: ko.ObservableArray<IJobOrderWorkCostPerDay> = ko.observableArray();
    private RevenuesData: ko.ObservableArray<IJobOrderInvoiceRevenue> = ko.observableArray();
    private EstimatedRevenuesData: ko.ObservableArray<IJobOrderEstimatedRevenue> = ko.observableArray();
    private AuthorizedData: ko.ObservableArray<IJobOrderAuthorizedBudget> = ko.observableArray();
    private PurchasesCostPerDay: ko.ObservableArray<IJobOrderPurchasesCostPerDay> = ko.observableArray();
    private EstimatedCosts: ko.ObservableArray<IJobOrderEstimatedCost> = ko.observableArray();

    //Riepilogo Totali
    private TotalCosts: ko.Observable<number> = ko.observable();
    private TotalExpectedRevenues: ko.Observable<number> = ko.observable();
    private TotalExpectedAssignedRevenues: ko.Observable<number> = ko.observable();
    private TotalRevenue: ko.Observable<number> = ko.observable();
    private TotalEstimatedCosts: ko.Observable<number> = ko.observable();
    private TotalEstimatedCostsWithoutSpeed: ko.Observable<number> = ko.observable();

    //Riepilogo Magazzino
    private BilledArticlesCosts: ko.Observable<number> = ko.observable();
    private WarehouseArticlesCosts: ko.Observable<number> = ko.observable();
    private WarehouseTurnover: ko.Observable<number> = ko.observable();
    private ExpectedRevenue: ko.Observable<number> = ko.observable();
    private ExpectedAssignedRevenue: ko.Observable<number> = ko.observable();
    private EstimatedWarehouseCosts: ko.Observable<number> = ko.observable();

    //Riepilogo Lavoro
    private HoursCosts: ko.Observable<number> = ko.observable();
    private BilledHours: ko.Observable<number> = ko.observable();
    private WorkedHoursExpectedRevenue: ko.Observable<number> = ko.observable();
    private ExpectedWorkRevenue: ko.Observable<number> = ko.observable();
    private ExpectedAssignedWorkRevenue: ko.Observable<number> = ko.observable();
    private EstimatedWorkCost: ko.Observable<number> = ko.observable();
    private EstimatedWorkWithoutSpeedCost: ko.Observable<number> = ko.observable();

    //Riepilogo Acquisti
    private PurchasesCosts: ko.Observable<number> = ko.observable();
    private EstimatedPurchasesCosts: ko.Observable<number> = ko.observable();
    private BilledPurchases: ko.Observable<number> = ko.observable();
    private PurchasesExpectedRevenue: ko.Observable<number> = ko.observable();
    private PurchasesAssignedExpectedRevenue: ko.Observable<number> = ko.observable();

    //Riepologo Altro
    private OtherInvoiced: ko.Observable<number> = ko.observable();
    private OtherExpectedRevenue: ko.Observable<number> = ko.observable();
    private OtherAssignedExpectedRevenue: ko.Observable<number> = ko.observable();

    constructor(
        provider: IEconomicsProvider,
        private costsMode: ko.Observable<CostMode>,
        private revenuesMode: ko.Observable<RevenuesMode>
    ) {
        this.provider = provider;
        this.provider.addObserver(this);

        /*this.ResidualEstimate = ko.computed(() => {
            var result = this.provider.TotalEstimate() - this.provider.TotalToBeBilled() - this.provider.TotalRevenues();
            return  result > 0 ? result : 0;
        });*/

        this.totalData(this.createGraphConfiguration("#92bbdd", []));
        this.warehouseData(this.createGraphConfiguration("#92bbdd", []));
        this.workData(this.createGraphConfiguration("#92bbdd", []));
        this.purchaseData(this.createGraphConfiguration("#92bbdd", []));
        this.otherData(this.createGraphConfiguration("#92bbdd", []));
        this.costsChartData(this.generateNewPieGraph([], [], ""));
        this.estimatedCostsChartData(this.generateNewPieGraph([], [], ""));
        this.revenuesChartData(this.generateNewPieGraph([], [], ""));
        this.estimatedRevenuesChartData(this.generateNewPieGraph([], [], ""));
    }

    public async refreshGraphics(): Promise<void> {
        await this.provider.refresh();
    }

    onDataLoaded(): void {
        this.calculateGlobalValues();
        this.totalData(this.calculateTotalData());
        this.warehouseData(this.calculateWarehouseData());
        this.workData(this.calculateWorkData());
        this.purchaseData(this.calculatePurchaseData());
        this.otherData(this.calculateOtherData());
        this.costsChartData(this.calculateCosts());
        this.estimatedCostsChartData(this.calculateEstimatedCosts());
        this.revenuesChartData(this.calculateRevenues());
        this.estimatedRevenuesChartData(this.calculateEstimatedRevenues());
    }

    private findNextValue<T extends { WorkDay: Date }>(
        values: T[],
        lastValidIndex: number,
        date: Date
    ): [number | null, T] {
        for (let i = lastValidIndex + 1; i < values.length; i++) {
            if (values[i].WorkDay >= date) {
                return [i, i >= 0 ? values[i] : null];
            }
        }

        return [null, null];
    }

    private calculateGlobalValues() {
        const historical: IJobOrderHistoricalEconomics = <IJobOrderHistoricalEconomics>(
            this.provider.HistoricalEconomics()
        );
        if (!historical) return;

        this.WarehouseStockValues(
            historical.WarehouseStockValues.map((s: IJobOrderWarehouseStockValue) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.WarehouseSentValues(
            historical.WarehouseSentValues.map((s: IJobOrderWarehouseSentValue) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.WorkCostPerDayValues(
            historical.WorkCostPerDay.map((s: IJobOrderWorkCostPerDay) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.RevenuesData(
            historical.InvoiceRevenues.map((s: IJobOrderInvoiceRevenue) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.EstimatedRevenuesData(
            historical.EstimatedRevenues.map((s: IJobOrderEstimatedRevenue) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.AuthorizedData(
            historical.AuthorizedBudget.map((s: IJobOrderAuthorizedBudget) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.PurchasesCostPerDay(
            historical.PurchasesCostPerDay.map((s: IJobOrderPurchasesCostPerDay) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );
        this.EstimatedCosts(
            historical.EstimatedCosts.map((s: IJobOrderEstimatedCost) => {
                s.WorkDay = <any>moment(s.WorkDay).valueOf();
                return s;
            })
        );

        const dateDistinct = [];
        this.WarehouseStockValues().forEach((s: IJobOrderWarehouseStockValue) => {
            dateDistinct.push(s.WorkDay);
        });
        this.WarehouseSentValues().forEach((s: IJobOrderWarehouseSentValue) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.WorkCostPerDayValues().forEach((s: IJobOrderWorkCostPerDay) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.RevenuesData().forEach((s: IJobOrderInvoiceRevenue) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.EstimatedRevenuesData().forEach((s: IJobOrderEstimatedRevenue) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.AuthorizedData().forEach((s: IJobOrderAuthorizedBudget) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.PurchasesCostPerDay().forEach((s: IJobOrderPurchasesCostPerDay) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });
        this.EstimatedCosts().forEach((s: IJobOrderEstimatedCost) => {
            const existingDate = dateDistinct.indexOf(s.WorkDay);
            if (existingDate < 0) {
                dateDistinct.push(s.WorkDay);
            }
        });

        this.Dates(dateDistinct.sort());
    }

    private calculateTotalData(): Highcharts.Options {
        const costsValues = [];
        const authorizedCostsValues = [];
        const authorizedInternalCostsValues = [];
        const authorizedOtherCostsValues = [];
        const estimatedRevenuesValues = [];
        const estimatedAssignedRevenuesValues = [];
        const revenuesValues = [];
        const estimatedCostValues = [];
        const estimatedCostWithoutSpeedValues = [];

        let workCostsIndex = -1,
            purchaseCostsIndex = -1,
            warehouseCostsIndex = -1,
            estimatedRevenuesIndex = -1,
            authorizedIndex = -1,
            revenueIndex = -1,
            estimatedCostIndex = -1;

        this.Dates().forEach((date) => {
            const [newWorkCostsIndex, workCostsValue] = this.findNextValue(
                this.WorkCostPerDayValues(),
                workCostsIndex,
                date
            );
            workCostsIndex = newWorkCostsIndex;
            const [newPurchaseCostsIndex, purchaseCostsValue] = this.findNextValue(
                this.PurchasesCostPerDay(),
                purchaseCostsIndex,
                date
            );
            purchaseCostsIndex = newPurchaseCostsIndex;
            const [newWarehouseCostsIndex, warehouseCostsValue] = this.findNextValue(
                this.WarehouseSentValues(),
                warehouseCostsIndex,
                date
            );
            warehouseCostsIndex = newWarehouseCostsIndex;
            const [newEstimatedRevenuesIndex, estimatedRevenuesValue] = this.findNextValue(
                this.EstimatedRevenuesData(),
                estimatedRevenuesIndex,
                date
            );
            estimatedRevenuesIndex = newEstimatedRevenuesIndex;
            const [newAuthorizedIndex, authorizedValue] = this.findNextValue(
                this.AuthorizedData(),
                authorizedIndex,
                date
            );
            authorizedIndex = newAuthorizedIndex;
            const [newRevenueIndex, revenueValue] = this.findNextValue(this.RevenuesData(), revenueIndex, date);
            revenueIndex = newRevenueIndex;
            const [newEstimatedCostIndex, estimatedCostValue] = this.findNextValue(
                this.EstimatedCosts(),
                estimatedCostIndex,
                date
            );
            estimatedCostIndex = newEstimatedCostIndex;

            costsValues.push([
                date,
                (workCostsValue?.WorkCostWithCallRight || 0) +
                    (purchaseCostsValue?.PurchasesCosts || 0) +
                    (warehouseCostsValue?.Cost || 0),
            ]);
            authorizedCostsValues.push([
                date,
                (authorizedValue?.WorkBudget || 0) +
                    (authorizedValue?.WarehouseBudget || 0) +
                    (authorizedValue?.PurchasesBudget || 0),
            ]);
            authorizedInternalCostsValues.push([
                date,
                (authorizedValue?.InternalWorkBudget || 0) +
                    (authorizedValue?.InternalWarehouseBudget || 0) +
                    (authorizedValue?.InternalPurchasesBudget || 0),
            ]);
            authorizedOtherCostsValues.push([
                date,
                (authorizedValue?.OtherWorkBudget || 0) +
                    (authorizedValue?.OtherWarehouseBudget || 0) +
                    (authorizedValue?.OtherPurchasesBudget || 0),
            ]);
            estimatedRevenuesValues.push([
                date,
                (estimatedRevenuesValue?.EstimatedWorkRevenues || 0) +
                    (estimatedRevenuesValue?.EstimatedPurchasesRevenues || 0) +
                    (estimatedRevenuesValue?.EstimatedWarehouseRevenues || 0) +
                    (estimatedRevenuesValue?.OtherEstimatedRevenues || 0),
            ]);
            estimatedAssignedRevenuesValues.push([
                date,
                (estimatedRevenuesValue?.EstimatedAssignedWorkRevenues || 0) +
                    (estimatedRevenuesValue?.EstimatedAssignedPurchasesRevenues || 0) +
                    (estimatedRevenuesValue?.EstimatedAssignedWarehouseRevenues || 0) +
                    (estimatedRevenuesValue?.OtherEstimatedAssignedRevenues || 0),
            ]);
            revenuesValues.push([date, revenueValue?.Revenue || 0]);
            estimatedCostValues.push([
                date,
                (estimatedCostValue?.EstimatedWorkCost || 0) +
                    (estimatedCostValue?.EstimatedWarehouseCost || 0) +
                    (estimatedCostValue?.EstimatedPurchasesCost || 0),
            ]);
            estimatedCostWithoutSpeedValues.push([
                date,
                (estimatedCostValue?.EstimatedWorkWithoutSpeedCost || 0) +
                    (estimatedCostValue?.EstimatedWarehouseCost || 0) +
                    (estimatedCostValue?.EstimatedPurchasesCost || 0),
            ]);
        });

        this.TotalCosts(costsValues.length > 0 ? costsValues[costsValues.length - 1][1] : 0);
        this.TotalExpectedRevenues(
            estimatedRevenuesValues.length > 0 ? estimatedRevenuesValues[estimatedRevenuesValues.length - 1][1] : 0
        );
        this.TotalExpectedAssignedRevenues(
            estimatedAssignedRevenuesValues.length > 0
                ? estimatedAssignedRevenuesValues[estimatedAssignedRevenuesValues.length - 1][1]
                : 0
        );
        this.TotalRevenue(revenuesValues.length > 0 ? revenuesValues[revenuesValues.length - 1][1] : 0);
        this.TotalEstimatedCosts(
            estimatedCostValues.length > 0 ? estimatedCostValues[estimatedCostValues.length - 1][1] : 0
        );
        this.TotalEstimatedCostsWithoutSpeed(
            estimatedCostWithoutSpeedValues.length > 0
                ? estimatedCostWithoutSpeedValues[estimatedCostWithoutSpeedValues.length - 1][1]
                : 0
        );

        const graphs = [];
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
            data: authorizedInternalCostsValues,
            step: "left",
            type: "area",
            stack: "authorized",
            stacking: "normal",
            color: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
            fillColor: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
            data: authorizedCostsValues,
            step: "left",
            type: "area",
            stack: "authorized",
            stacking: "normal",
            color: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
            fillColor: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
            data: authorizedOtherCostsValues,
            step: "left",
            type: "area",
            stack: "authorized",
            stacking: "normal",
            color: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
            fillColor: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.Costs,
            data: costsValues,
            step: "left",
            color: this.costsColor,
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.ExpectedRevenues,
            data: this.revenuesMode() === "allOrders" ? estimatedRevenuesValues : estimatedAssignedRevenuesValues,
            step: "left",
            dashStyle: this.dashStyle,
            color: this.revenuesColor,
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.Revenue,
            data: revenuesValues,
            step: "left",
            color: this.revenuesColor,
        });
        graphs.push({
            name: ProlifeSdk.TextResources.JobOrder.EstimatedCosts,
            data: this.costsMode() === "reestimated" ? estimatedCostValues : estimatedCostWithoutSpeedValues,
            step: "left",
            dashStyle: this.dashStyle,
            color: this.costsColor,
        });

        return this.createGraphConfiguration("#92bbdd", graphs);
    }

    private calculateWarehouseData(): Highcharts.Options {
        const stockValues = [];
        const sentValues = [];
        const revenuesValues = [];
        const estimatedRevenuesValues = [];
        const estimatedAssignedRevenuesValues = [];
        const authorizedValues = [];
        const authorizedInternalValues = [];
        const authorizedOtherValues = [];
        const estimatedWarehouseCostValues = [];

        let warehouseStockIndex = -1,
            warehouseSentIndex = -1,
            revenuesIndex = -1,
            estimatedRevenuesIndex = -1,
            authorizedIndex = -1,
            estimatedCostsIndex = -1;

        this.Dates().forEach((date) => {
            const [newWarehouseStockIndex, warehouseStockValue] = this.findNextValue(
                this.WarehouseStockValues(),
                warehouseStockIndex,
                date
            );
            warehouseStockIndex = newWarehouseStockIndex;
            const [newWarehouseSentIndex, warehouseSentValue] = this.findNextValue(
                this.WarehouseSentValues(),
                warehouseSentIndex,
                date
            );
            warehouseSentIndex = newWarehouseSentIndex;
            const [newRevenuesIndex, revenuesValue] = this.findNextValue(this.RevenuesData(), revenuesIndex, date);
            revenuesIndex = newRevenuesIndex;
            const [newEstimatedRevenuesIndex, estimatedRevenuesValue] = this.findNextValue(
                this.EstimatedRevenuesData(),
                estimatedRevenuesIndex,
                date
            );
            estimatedRevenuesIndex = newEstimatedRevenuesIndex;
            const [newAuthorizedIndex, authorizedValue] = this.findNextValue(
                this.AuthorizedData(),
                authorizedIndex,
                date
            );
            authorizedIndex = newAuthorizedIndex;
            const [newEstimatedCostsIndex, estimatedCostsValue] = this.findNextValue(
                this.EstimatedCosts(),
                estimatedCostsIndex,
                date
            );
            estimatedCostsIndex = newEstimatedCostsIndex;

            stockValues.push([date, warehouseStockValue?.TotalCost || 0]);
            sentValues.push([date, warehouseSentValue?.Cost || 0]);
            revenuesValues.push([date, revenuesValue?.ArticleRevenues || 0]);
            estimatedRevenuesValues.push([date, estimatedRevenuesValue?.EstimatedWarehouseRevenues || 0]);
            estimatedAssignedRevenuesValues.push([
                date,
                estimatedRevenuesValue?.EstimatedAssignedWarehouseRevenues || 0,
            ]);
            authorizedValues.push([date, authorizedValue?.WarehouseBudget || 0]);
            authorizedInternalValues.push([date, authorizedValue?.InternalWarehouseBudget || 0]);
            authorizedOtherValues.push([date, authorizedValue?.OtherWarehouseBudget || 0]);
            estimatedWarehouseCostValues.push([date, estimatedCostsValue?.EstimatedWarehouseCost || 0]);
        });

        this.BilledArticlesCosts(sentValues.length > 0 ? sentValues[sentValues.length - 1][1] : 0);
        this.WarehouseArticlesCosts(stockValues.length > 0 ? stockValues[stockValues.length - 1][1] : 0);
        this.WarehouseTurnover(revenuesValues.length > 0 ? revenuesValues[revenuesValues.length - 1][1] : 0);
        this.ExpectedRevenue(
            estimatedRevenuesValues.length > 0 ? estimatedRevenuesValues[estimatedRevenuesValues.length - 1][1] : 0
        );
        this.ExpectedAssignedRevenue(
            estimatedAssignedRevenuesValues.length > 0
                ? estimatedAssignedRevenuesValues[estimatedAssignedRevenuesValues.length - 1][1]
                : 0
        );
        this.EstimatedWarehouseCosts(
            estimatedWarehouseCostValues.length > 0
                ? estimatedWarehouseCostValues[estimatedWarehouseCostValues.length - 1][1]
                : 0
        );

        return this.createGraphConfiguration("#92bbdd", [
            {
                name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
                data: authorizedInternalValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
                data: authorizedValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
                data: authorizedOtherValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.BilledArticlesCosts,
                data: sentValues,
                step: "left",
                color: this.costsColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.WarehouseArticlesCosts,
                data: stockValues,
                step: "left",
                color: this.otherColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.WarehouseTurnover,
                data: revenuesValues,
                step: "left",
                color: this.revenuesColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.ExpectedRevenue,
                data: this.revenuesMode() === "allOrders" ? estimatedRevenuesValues : estimatedAssignedRevenuesValues,
                step: "left",
                color: this.revenuesColor,
                dashStyle: this.dashStyle,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.EstimatedWarehouseCosts,
                data: estimatedWarehouseCostValues,
                step: "left",
                color: this.costsColor,
                dashStyle: this.dashStyle,
            },
        ]);
    }

    private calculateWorkData(): Highcharts.Options {
        const workValues = [];
        const workPriceValues = [];
        const workRevValues = [];
        const estimatedWorkRevenuesValues = [];
        const estimatedAssignedWorkRevenuesValues = [];
        const authorizedWorkValues = [];
        const authorizedInternalWorkValues = [];
        const authorizedOtherWorkValues = [];
        const estimatedWorkCostValues = [];
        const estimatedWorkCostWithoutSpeedValues = [];

        let workCostPerDayIndex = -1,
            revenuesIndex = -1,
            estimatedRevenuesIndex = -1,
            authorizedIndex = -1,
            estimatedCostsIndex = -1;

        this.Dates().forEach((date) => {
            const [newWorkCostPerDayIndex, workCostPerDayValue] = this.findNextValue(
                this.WorkCostPerDayValues(),
                workCostPerDayIndex,
                date
            );
            workCostPerDayIndex = newWorkCostPerDayIndex;
            const [newRevenuesIndex, revenuesValue] = this.findNextValue(this.RevenuesData(), revenuesIndex, date);
            revenuesIndex = newRevenuesIndex;
            const [newEstimatedRevenuesIndex, estimatedRevenuesValue] = this.findNextValue(
                this.EstimatedRevenuesData(),
                estimatedRevenuesIndex,
                date
            );
            estimatedRevenuesIndex = newEstimatedRevenuesIndex;
            const [newAuthorizedIndex, authorizedValue] = this.findNextValue(
                this.AuthorizedData(),
                authorizedIndex,
                date
            );
            authorizedIndex = newAuthorizedIndex;
            const [newEstimatedCostsIndex, estimatedCostsValue] = this.findNextValue(
                this.EstimatedCosts(),
                estimatedCostsIndex,
                date
            );
            estimatedCostsIndex = newEstimatedCostsIndex;

            workValues.push([date, workCostPerDayValue?.WorkCostWithCallRight || 0]);
            workPriceValues.push([date, workCostPerDayValue?.WorkPriceWithCallRight || 0]);
            workRevValues.push([date, revenuesValue?.WorkRevenues || 0]);
            estimatedWorkRevenuesValues.push([date, estimatedRevenuesValue?.EstimatedWorkRevenues || 0]);
            estimatedAssignedWorkRevenuesValues.push([
                date,
                estimatedRevenuesValue?.EstimatedAssignedWorkRevenues || 0,
            ]);
            authorizedWorkValues.push([date, authorizedValue?.WorkBudget || 0]);
            authorizedInternalWorkValues.push([date, authorizedValue?.InternalWorkBudget || 0]);
            authorizedOtherWorkValues.push([date, authorizedValue?.OtherWorkBudget || 0]);
            estimatedWorkCostValues.push([date, estimatedCostsValue?.EstimatedWorkCost || 0]);
            estimatedWorkCostWithoutSpeedValues.push([date, estimatedCostsValue?.EstimatedWorkWithoutSpeedCost || 0]);
        });

        this.HoursCosts(workValues.length > 0 ? workValues[workValues.length - 1][1] : 0);
        this.BilledHours(workRevValues.length > 0 ? workRevValues[workRevValues.length - 1][1] : 0);
        this.WorkedHoursExpectedRevenue(
            workPriceValues.length > 0 ? workPriceValues[workPriceValues.length - 1][1] : 0
        );
        this.ExpectedWorkRevenue(
            estimatedWorkRevenuesValues.length > 0
                ? estimatedWorkRevenuesValues[estimatedWorkRevenuesValues.length - 1][1]
                : 0
        );
        this.ExpectedAssignedWorkRevenue(
            estimatedAssignedWorkRevenuesValues.length > 0
                ? estimatedAssignedWorkRevenuesValues[estimatedAssignedWorkRevenuesValues.length - 1][1]
                : 0
        );
        this.EstimatedWorkCost(
            estimatedWorkCostValues.length > 0 ? estimatedWorkCostValues[estimatedWorkCostValues.length - 1][1] : 0
        );
        this.EstimatedWorkWithoutSpeedCost(
            estimatedWorkCostWithoutSpeedValues.length > 0
                ? estimatedWorkCostWithoutSpeedValues[estimatedWorkCostWithoutSpeedValues.length - 1][1]
                : 0
        );

        return this.createGraphConfiguration("#92bbdd", [
            {
                name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
                data: authorizedInternalWorkValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
                data: authorizedWorkValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
                data: authorizedOtherWorkValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.HoursCosts,
                data: workValues,
                step: "left",
                color: this.costsColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.BilledHours,
                data: workRevValues,
                step: "left",
                color: this.revenuesColor,
            } /*,
            {
                name: ProlifeSdk.TextResources.JobOrder.WorkedHoursExpectedRevenue,
                data: workPriceValues,
                step: 'left',
                dashStyle: this.dashStyle,
                color: this.revenuesColor
            }*/,
            {
                name: ProlifeSdk.TextResources.JobOrder.ExpectedWorkRevenue,
                data:
                    this.revenuesMode() === "allOrders"
                        ? estimatedWorkRevenuesValues
                        : estimatedAssignedWorkRevenuesValues,
                step: "left",
                dashStyle: this.dashStyle,
                color: this.revenuesColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.EstimatedWorkCost,
                data:
                    this.costsMode() === "reestimated" ? estimatedWorkCostValues : estimatedWorkCostWithoutSpeedValues,
                step: "left",
                dashStyle: this.dashStyle,
                color: this.costsColor,
            },
        ]);
    }

    private calculatePurchaseData(): Highcharts.Options {
        const purchValues = [];
        const purchRevValues = [];
        const estimatedPurchaseRevenuesValues = [];
        const estimatedAssignedPurchaseRevenuesValues = [];
        const estimatedPurchaseCostsValues = [];
        const authorizedPurchasesValues = [];
        const authorizedInternalPurchasesValues = [];
        const authorizedOtherPurchasesValues = [];

        let validPurchIndex = -1,
            validRevenueIndex = -1,
            validEstimatedRevenuesIndex = -1,
            validAuthorizedIndex = -1,
            validEstimatedCostsIndex = -1;

        this.Dates().forEach((date) => {
            const [newValidPurchIndex, validPurchValue] = this.findNextValue(
                this.PurchasesCostPerDay(),
                validPurchIndex,
                date
            );
            validPurchIndex = newValidPurchIndex;
            const [newValidRevenueIndex, validRevenueValue] = this.findNextValue(
                this.RevenuesData(),
                validRevenueIndex,
                date
            );
            validRevenueIndex = newValidRevenueIndex;
            const [newValidEstimatedRevenuesIndex, validEstimatedRevenuesValue] = this.findNextValue(
                this.EstimatedRevenuesData(),
                validEstimatedRevenuesIndex,
                date
            );
            validEstimatedRevenuesIndex = newValidEstimatedRevenuesIndex;
            const [newValidAuthorizedIndex, validAuthorizedValue] = this.findNextValue(
                this.AuthorizedData(),
                validAuthorizedIndex,
                date
            );
            validAuthorizedIndex = newValidAuthorizedIndex;
            const [newValidEstimatedCostsIndex, validEstimatedCostsValue] = this.findNextValue(
                this.EstimatedCosts(),
                validEstimatedCostsIndex,
                date
            );
            validEstimatedCostsIndex = newValidEstimatedCostsIndex;

            purchValues.push([date, validPurchValue?.PurchasesCosts || 0]);
            purchRevValues.push([date, validRevenueValue?.PurchaseRevenues || 0]);
            estimatedPurchaseRevenuesValues.push([date, validEstimatedRevenuesValue?.EstimatedPurchasesRevenues || 0]);
            estimatedAssignedPurchaseRevenuesValues.push([
                date,
                validEstimatedRevenuesValue?.EstimatedAssignedPurchasesRevenues || 0,
            ]);
            estimatedPurchaseCostsValues.push([date, validEstimatedCostsValue?.EstimatedPurchasesCost || 0]);
            authorizedPurchasesValues.push([date, validAuthorizedValue?.PurchasesBudget || 0]);
            authorizedInternalPurchasesValues.push([date, validAuthorizedValue?.InternalPurchasesBudget || 0]);
            authorizedOtherPurchasesValues.push([date, validAuthorizedValue?.OtherPurchasesBudget || 0]);
        });

        this.PurchasesCosts(purchValues.length > 0 ? purchValues[purchValues.length - 1][1] : 0);
        this.BilledPurchases(purchRevValues.length > 0 ? purchRevValues[purchRevValues.length - 1][1] : 0);
        this.PurchasesExpectedRevenue(
            estimatedPurchaseRevenuesValues.length > 0
                ? estimatedPurchaseRevenuesValues[estimatedPurchaseRevenuesValues.length - 1][1]
                : 0
        );
        this.PurchasesAssignedExpectedRevenue(
            estimatedAssignedPurchaseRevenuesValues.length > 0
                ? estimatedAssignedPurchaseRevenuesValues[estimatedAssignedPurchaseRevenuesValues.length - 1][1]
                : 0
        );
        this.EstimatedPurchasesCosts(
            estimatedPurchaseCostsValues.length > 0
                ? estimatedPurchaseCostsValues[estimatedPurchaseCostsValues.length - 1][1]
                : 0
        );

        return this.createGraphConfiguration("#92bbdd", [
            {
                name: ProlifeSdk.TextResources.JobOrder.InternalEstimatedBudget,
                data: authorizedInternalPurchasesValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#f7a35c")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.ExternalEstimatedBudget,
                data: authorizedPurchasesValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#90ed7d")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.OtherEstimatedBudget,
                data: authorizedOtherPurchasesValues,
                step: "left",
                type: "area",
                stack: "authorized",
                stacking: "normal",
                color: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
                fillColor: (<Highcharts.Gradient>Highcharts.Color("#7cb5ec")).setOpacity(0.5).get("rgba"),
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.PurchasesCosts,
                data: purchValues,
                step: "left",
                color: this.costsColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.BilledPurchases,
                data: purchRevValues,
                step: "left",
                color: this.revenuesColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.PurchasesExpectedRevenue,
                data:
                    this.revenuesMode() === "allOrders"
                        ? estimatedPurchaseRevenuesValues
                        : estimatedAssignedPurchaseRevenuesValues,
                step: "left",
                dashStyle: this.dashStyle,
                color: this.revenuesColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.PurchasesExpectedCost,
                data: estimatedPurchaseCostsValues,
                step: "left",
                dashStyle: this.dashStyle,
                color: this.costsColor,
            },
        ]);
    }

    private calculateOtherData(): Highcharts.Options {
        const otherRevValues = [];
        const estimatedOtherRevenuesValues = [];
        const estimatedAssignedOtherRevenuesValues = [];

        let revenuesIndex = -1,
            estimatedRevenuesIndex = -1;

        this.Dates().forEach((date) => {
            const [newRevenuesIndex, revenuesValue] = this.findNextValue(this.RevenuesData(), revenuesIndex, date);
            revenuesIndex = newRevenuesIndex;
            const [newEstimatedRevenuesIndex, estimatedRevenuesValue] = this.findNextValue(
                this.EstimatedRevenuesData(),
                estimatedRevenuesIndex,
                date
            );
            estimatedRevenuesIndex = newEstimatedRevenuesIndex;

            otherRevValues.push([date, revenuesValue?.OtherRevenues || 0]);
            estimatedOtherRevenuesValues.push([date, estimatedRevenuesValue?.OtherEstimatedRevenues || 0]);
            estimatedAssignedOtherRevenuesValues.push([
                date,
                estimatedRevenuesValue?.OtherEstimatedAssignedRevenues || 0,
            ]);
        });

        this.OtherInvoiced(otherRevValues.length > 0 ? otherRevValues[otherRevValues.length - 1][1] : 0);
        this.OtherExpectedRevenue(
            estimatedOtherRevenuesValues.length > 0
                ? estimatedOtherRevenuesValues[estimatedOtherRevenuesValues.length - 1][1]
                : 0
        );
        this.OtherAssignedExpectedRevenue(
            estimatedAssignedOtherRevenuesValues.length > 0
                ? estimatedAssignedOtherRevenuesValues[estimatedAssignedOtherRevenuesValues.length - 1][1]
                : 0
        );

        return this.createGraphConfiguration("#92bbdd", [
            {
                name: ProlifeSdk.TextResources.JobOrder.OtherInvoiced,
                data: otherRevValues,
                step: "left",
                color: this.otherColor,
            },
            {
                name: ProlifeSdk.TextResources.JobOrder.OtherExpectedRevenue,
                data:
                    this.revenuesMode() === "allOrders"
                        ? estimatedOtherRevenuesValues
                        : estimatedAssignedOtherRevenuesValues,
                step: "left",
                dashStyle: this.dashStyle,
                color: this.otherColor,
            },
        ]);
    }

    private calculateCosts() {
        const economicsData: IJobOrderEconomicsData = <IJobOrderEconomicsData>this.provider.EconomicsData();
        const total = !economicsData
            ? 0
            : economicsData.ArticlesSentCosts +
              economicsData.ArticlesEstimatedCosts +
              economicsData.ArticlesIntoWarehousesCosts +
              economicsData.PurchasesCosts +
              economicsData.HoursCosts;

        return this.generateNewPieGraph(
            ["#d83636", "#E35B5A", "#e57e7e", "#eaa4a4", "#edc0c0"],
            [
                [
                    <any>ProlifeSdk.TextResources.JobOrder.BilledArticlesCosts,
                    <any>(total ? (economicsData.ArticlesSentCosts / total) * 100 : 20),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.WarehouseArticlesExpectedCosts,
                    <any>(total ? (economicsData.ArticlesEstimatedCosts / total) * 100 : 20),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.WarehouseArticlesCosts,
                    <any>(total ? (economicsData.ArticlesIntoWarehousesCosts / total) * 100 : 20),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.PurchasesCosts,
                    <any>(total ? (economicsData.PurchasesCosts / total) * 100 : 20),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.HoursCosts,
                    <any>(total ? (economicsData.HoursCosts / total) * 100 : 20),
                ],
            ],
            ProlifeSdk.TextResources.JobOrder.Costs
        );
    }

    private calculateEstimatedCosts() {
        const estimatedWorkCost =
            this.costsMode() === "reestimated" ? this.EstimatedWorkCost() : this.EstimatedWorkWithoutSpeedCost();

        const total = this.EstimatedPurchasesCosts() + estimatedWorkCost + this.EstimatedWarehouseCosts();

        return this.generateNewPieGraph(
            ["#d83636", "#E35B5A", "#e57e7e"],
            [
                [
                    <any>ProlifeSdk.TextResources.JobOrder.PurchasesExpectedCost,
                    <any>(total ? (this.EstimatedPurchasesCosts() / total) * 100 : 33.333333),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.EstimatedWorkCost,
                    <any>(total ? (estimatedWorkCost / total) * 100 : 33.333333),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.EstimatedWarehouseCosts,
                    <any>(total ? (this.EstimatedWarehouseCosts() / total) * 100 : 33.333333),
                ],
            ],
            ProlifeSdk.TextResources.JobOrder.EstimatedCosts
        );
    }

    private calculateRevenues() {
        const economicsData: IJobOrderEconomicsData = <IJobOrderEconomicsData>this.provider.EconomicsData();
        const total = !economicsData
            ? 0
            : economicsData.ArticlesRevenues +
              economicsData.OthersRevenues +
              economicsData.PurchasesRevenues +
              economicsData.HoursRevenues;

        return this.generateNewPieGraph(
            ["#578EBE", "#82aacc", "#a0bfd8", "#bed2e2"],
            [
                //['#44B6AE', '#71c6bf', '#95d6d0', '#b7e2df'], [
                [
                    <any>ProlifeSdk.TextResources.JobOrder.WarehouseTurnover,
                    <any>(total ? (economicsData.ArticlesRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.OtherInvoiced,
                    <any>(total ? (economicsData.OthersRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.BilledPurchases,
                    <any>(total ? (economicsData.PurchasesRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.BilledHours,
                    <any>(total ? (economicsData.HoursRevenues / total) * 100 : 25),
                ],
            ],
            ProlifeSdk.TextResources.JobOrder.BilledAmount
        );
    }

    private calculateEstimatedRevenues() {
        const economicsData: IJobOrderEconomicsData = <IJobOrderEconomicsData>this.provider.EconomicsData();
        const total = !economicsData
            ? 0
            : economicsData.ArticlesEstimatedRevenues +
              economicsData.HoursEstimatedRevenues +
              economicsData.PurchasesEstimatedRevenues +
              economicsData.OthersEstimatedRevenues;

        return this.generateNewPieGraph(
            ["#578EBE", "#82aacc", "#a0bfd8", "#bed2e2"],
            [
                [
                    <any>ProlifeSdk.TextResources.JobOrder.ExpectedWarehouseRevenue,
                    <any>(total ? (economicsData.ArticlesEstimatedRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.WorkedHoursExpectedRevenue,
                    <any>(total ? (economicsData.HoursEstimatedRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.PurchasesExpectedRevenue,
                    <any>(total ? (economicsData.PurchasesEstimatedRevenues / total) * 100 : 25),
                ],
                [
                    <any>ProlifeSdk.TextResources.JobOrder.OtherExpectedRevenue,
                    <any>(total ? (economicsData.OthersEstimatedRevenues / total) * 100 : 25),
                ],
            ],
            ProlifeSdk.TextResources.JobOrder.ExpectedRevenue
        );
    }

    setEstimate(estimateTotal: number) {
        //this.TotalEstimate(estimateTotal);
        this.refreshGraphics();
    }

    private createGraphConfiguration(color: string, data: IGraphInfo[], yAxis?: any[]): Highcharts.Options {
        const config: Highcharts.Options = {
            /*chart: {
             type: 'line',
             plotBackgroundColor: null,
             plotBorderWidth: null,
             plotShadow: false,
             backgroundColor : null,
             borderColor : null
             //marginTop: 30,
             //spacing: [0,0,0,0],
             // width: 200
             },*/
            credits: {
                enabled: false,
            },
            title: {
                text: null,
            },
            subtitle: {
                text: null,
            },
            legend: {
                enabled: true /*,
                align: 'right',
                 verticalAlign: 'bottom',
                layout: 'vertical',
                backgroundColor: 'rgba(255,255,255,0.75)',
                borderWidth: 1,
                //width: '100%',
                itemStyle: {
                    fontWeight: 'normal'
                },
                useHTML: true,
                labelFormatter: function() {
                    if(this.options.total != undefined)
                        return "<div style=\"width: 250px;\">" + this.name + " <b style='float: right;'>" + numeral(this.options.total).format('0,0[.]00 $') + "</b></div>";
                    else
                        return this.name;
                }*/,
            },
            tooltip: {
                animation: true,
                valueDecimals: 2,
                valueSuffix: " €",
                xDateFormat: "%d/%m/%Y",
            },
            /*plotOptions: {
                line: {
                    getExtremesFromAll: true
                }
            },*/
            series: data,
            xAxis: <any>{
                //visible: true,
                type: "linear",
                ordinal: false,
                /*labels: {
                 formatter: function() {
                 return moment(<any>this.value).format("DD MMM")
                 }
                 }*/
            } /*,
             yAxis: {
             visible: true,
             title: {
             text: null
             },
             maxPadding: 0.1
             }*/,
        };

        if (yAxis) {
            config.yAxis = yAxis;
        }

        return config;
    }

    private generateNewPieGraph(colors: string[], seriesData: any, name: string): any {
        const chart = {
            chart: {
                type: "pie",
                options3d: {
                    enabled: true,
                    alpha: 45,
                },
            },
            colors: colors,
            title: {
                text: null,
            },
            subTitle: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    borderColor: "rgba(0,0,0,0.125)",
                    innerSize: 60,
                    depth: 15,
                    dataLabels: {
                        enabled: false,
                        crop: false,
                        overflow: "none",
                    },
                },
            },
            tooltip: {
                valueSuffix: " %",
                valueDecimals: 2,
            },
            series: [
                {
                    data: seriesData,
                    name: name,
                },
            ],
        };

        return chart;
    }

    private generateNewGraph(seriesData: any): any {
        const chart = {
            chart: {
                type: "column",
                //backgroundColor : "#606060"
                backgroundColor: "#ffffff",
                height: 300,
                /*options3d: {
                    enabled: false,
                    alpha: 15,
                    beta: 15,
                    viewDistance: 25,
                    depth: 160
                },*/
                /*marginTop: 80,
                marginRight: 160,
                marginBottom: 120*/
            },
            colors: ["#D3D3D3" /*Grigio*/, "#ff8403" /*Arancione*/, "#FFBF49" /*Giallo*/, "#4CC900" /*Verde*/],
            xAxis: {
                categories: [
                    ProlifeSdk.TextResources.JobOrder.Costs,
                    ProlifeSdk.TextResources.JobOrder.ExpectedRevenue,
                    ProlifeSdk.TextResources.JobOrder.Turnover,
                ],
            },
            legend: {
                enabled: true,
                align: "right",
                verticalAlign: "top",
                layout: "vertical",
                backgroundColor: "rgba(255,255,255,0.75)",
                borderWidth: 1,
                //width: '100%',
                itemStyle: {
                    fontWeight: "normal",
                },
                useHTML: true,
                labelFormatter: function () {
                    if (this.options.total != undefined)
                        return (
                            '<div style="width: 250px;">' +
                            this.name +
                            " <b style='float: right;'>" +
                            numeral(this.options.total).format("0,0[.]00 $") +
                            "</b></div>"
                        );
                    else return this.name;
                },
            },
            title: {
                text: "",
                style: { color: "#000000" },
            },
            yAxis: {
                title: {
                    text: "Euro",
                    style: { color: "#000000" },
                },
                min: 0,
                minRange: 10,
                stackLabels: {
                    enabled: false,
                    style: {
                        fontWeight: "bold",
                        color: "#000000",
                    },
                    formatter: function () {
                        return numeral(this.total).format("0,0[.]00 $");
                    },
                },
            },
            credits: {
                enabled: false,
            },
            subtitle: {
                text: null,
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: true,
                        color: "#000000",
                        formatter: function () {
                            return numeral(this.y).format("0,0[.]00 $");
                        },
                    },
                    depth: 80,
                    maxPointWidth: 60 /*,
                    pointWidth: 60*/,
                },
                series: {
                    dataLabels: {
                        //color : "#FFFFFF",
                        enabled: true,
                        formatter: function () {
                            if (this.y > 0) return numeral(this.y).format("0,0[.]00 $");
                            return "";
                        },
                    },
                    connectNulls: false,
                },
            },
            tooltip: {
                /*formatter: function() {
                    return false;
                },*/
                valueDecimals: 2,
            },
            series: seriesData,
        };

        return chart;
    }
}
