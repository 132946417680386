import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, classNames, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";

type WorkflowEditorButtonProps = {
    workflowId: number;
    extraSmall?: boolean;
};

export function WorkflowEditorButton(props: WorkflowEditorButtonProps) {
    const C = require("./WorkflowEditorButton")._WorkflowEditorButton as typeof _WorkflowEditorButton;
    return <C {...props} />;
}

export class _WorkflowEditorButton {
    static defaultProps: Partial<WorkflowEditorButtonProps> = {};

    @LazyImport(nameof<ITodoListService>())
    private todolistService: ITodoListService;

    constructor(private props: WorkflowEditorButtonProps) {}

    private async openEditor(): Promise<void> {
        if (!this.props.workflowId) return;

        await this.todolistService.ShowEditWorkflowDialog(this.props.workflowId);
    }

    render() {
        const web = this;

        return ComponentUtils.bindTo(
            <button
                type="button"
                className={classNames("btn btn-primary", { "btn-xs": this.props.extraSmall })}
                title={TextResources.JobOrder.WorkflowEditorButtonTooltip}
                data-bind={{
                    enable: !!web.props.workflowId,
                    click: web.openEditor.bind(web),
                }}
            >
                <i className="fa fa-edit"></i>
            </button>,
            this,
            "web"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(WorkflowEditorButton);
}
