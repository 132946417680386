import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { UsersSettingsManager } from "./Users/Settings/UsersSettingsManager";
import { UserCharactersSettingsManager } from "./Users/Settings/UserCharactersSettingsManager";
import { UserEntityProvider } from "./Users/EntityProviders/UserEntityProvider";
import { RoleEntityProvider } from "./Users/EntityProviders/RoleEntityProvider";
import { RoleRefConverterFactory } from "./Users/documents/converters/RoleRefConverterFactory";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IUser } from "../ProlifeSdk/interfaces/users/IUser";
import { LazyImport } from "../Core/DependencyInjection";

export interface IUserForList {
    IdUser : number;
    Nome?: string;
    Cognome?: string;
    Username?: string;
}

export interface IResourceGroupMembership {
    ResourceId : number;
    GroupId : number;
    GroupName?: string;
}

export interface IGetResourceHoursApprovalGroupsRequest {
    textFilter?: string;
    userId?: number;
    canSignAll?: boolean;
    skip?: number;
    count?: number;
}

export interface IHoursApprovalGroup {
    ApproverResource?: number;
    GroupId : number;
    GroupName?: string;
    WorkedHoursApprovalRole?: string;
    CanApprove?: boolean;
}

export interface IUsersService extends IService {
    getCompanyUsers(filter?: string) : Promise<IUser[]>;
    getUserFromKeyValue(filter?: number) : Promise<IUser>;
    GetFullName(u : IUser) : string;
    GetUsersForList(textFilter : string | null, skip : number | null, count : number | null) : Promise<IUserForList[]>;
    GetUsersForListByIds(ids : number[] | null) : Promise<IUserForList[]>;
    GetResourceHoursApprovalGroupsByIds(ids : number[] | null, userId : number | null) : Promise<IHoursApprovalGroup[]>;
    GetUsersGroupsMembership(resources : number[] | null) : Promise<IResourceGroupMembership[]>;
    GetResourceHoursApprovalGroups(request : IGetResourceHoursApprovalGroupsRequest) : Promise<IHoursApprovalGroup[]>;
}
export class UsersService implements IUsersService {
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;

    constructor (private serviceLocator : IServiceLocator) {
        serviceLocator.registerServiceInstance(this);
        serviceLocator.registerServiceInstanceWithName(nameof<IUsersService>(), this);
    }

    InitializeService() {
        new UsersSettingsManager(this.serviceLocator);
        new UserCharactersSettingsManager(this.serviceLocator);
        new UserEntityProvider(this.serviceLocator);
        new RoleEntityProvider(this.serviceLocator);

        const documentsService : IDocumentsService = this.serviceLocator.findService(ProlifeSdk.DocumentsServiceType);
        documentsService.registerRowReferencesGeneratorFactory(new RoleRefConverterFactory(this.serviceLocator));
    }

    getServiceType() : string {
        return ProlifeSdk.UsersServiceType;
    }

    isOfType(serviceType : string) : boolean {
        return serviceType == this.getServiceType();
    }

    getCompanyUsers(filter?: string) : Promise<IUser[]> {
        return this.ajaxService.Post("Users-api/Users", "DetailedSearch", {
            methodData: {
                HintSearch: filter
            }
        });
    }

    getUserFromKeyValue(filter?: number) : Promise<IUser> {
        return this.ajaxService.Get("Users", "Users/" + filter.toString(), {});
    }


    GetFullName(u : IUser) : string {
        let fullname = "";
        fullname += (u && u.Nome) ? u.Nome + " " : "";
        fullname += (u && u.Cognome) ? u.Cognome : "";
        return fullname.trim();
    }

    GetUsersForList(textFilter : string | null, skip : number | null, count : number | null) : Promise<IUserForList[]> {
        const result = this.ajaxService.Post<IUserForList[]>("Users-api/Users", "GetUsersForList", {
            background: true,
            methodData: {
                textFilter: textFilter,
                skip: skip,
                count: count,
            }
        });



        return result;
    }

    GetUsersForListByIds(ids : number[] | null) : Promise<IUserForList[]> {
        let result = this.ajaxService.Post<IUserForList[]>("Users-api/Users", "GetUsersForListByIds", {
            background: true,
            methodData: {
                ids: ids,
            }
        });



        return result;
    }

    GetResourceHoursApprovalGroupsByIds(ids : number[] | null, userId : number | null) : Promise<IHoursApprovalGroup[]> {
        let result = this.ajaxService.Post<IHoursApprovalGroup[]>("Users-api/HumanResources", "GetResourceHoursApprovalGroupsByIds", {
            background: true,
            methodData: {
                ids: ids,
                userId: userId,
            }
        });



        return result;
    }

    GetUsersGroupsMembership(resources : number[] | null) : Promise<IResourceGroupMembership[]> {
        let result = this.ajaxService.Post<IResourceGroupMembership[]>("Users-api/HumanResources", "GetUsersGroupsMembership", {
            background: true,
            methodData: {
                resources: resources,
            }
        });



        return result;
    }

    GetResourceHoursApprovalGroups(request: IGetResourceHoursApprovalGroupsRequest): Promise<IHoursApprovalGroup[]> {
        let result = this.ajaxService.Post<IHoursApprovalGroup[]>("Users-api/HumanResources", "GetResourceHoursApprovalGroups", {
            background: true,
            methodData: request
        });



        return result;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new UsersService(serviceLocator);
}
