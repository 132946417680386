import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { IHumanResourcesSettingsManager } from "../Users/Users/Settings/HumanResourcesSettingsManager";
import { IHumanResource } from "../Users/HumanResourcesService";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";

export class HumanResourceDisplayFullName
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var interceptor = ko.computed(() => {
            var options = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
            var humanResourcesSettingsManager : IHumanResourcesSettingsManager = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);

            var user : IHumanResource = humanResourcesSettingsManager.getHumanResourceById(ko.utils.unwrapObservable(options.id));

            if (user != null)
                return user.Resource.Name  + " " + user.Resource.Surname;

            return options.defaultDescription || "";
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

export class HumanResourceFromUser
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {
        var interceptor = ko.computed(() => {
            var userId = valueAccessor();

            var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);

            var humanResourcesSettingsManager:IHumanResourcesSettingsManager = <IHumanResourcesSettingsManager> settingsService.findSettingsManager(ProlifeSdk.HumanResources);
            var resource : IHumanResource = humanResourcesSettingsManager.getHumanResourceByUserId(ko.utils.unwrapObservable(userId));

            if (resource != null)
                return resource.Resource.Name + " " + resource.Resource.Surname;

            return ProlifeSdk.TextResources.Users.UnavailableResource;
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["HumanResourceDisplayFullName"] = new HumanResourceDisplayFullName();
ko.virtualElements.allowedBindings["HumanResourceDisplayFullName"] = true;
ko.bindingHandlers["HumanResourceFromUser"] = new HumanResourceFromUser();
ko.virtualElements.allowedBindings["HumanResourceFromUser"] = true;
