import jss from "jss";
import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { LazyImport } from "../../../Core/DependencyInjection";
import { IValidationService, IValidator } from "../../../ProlifeSdk/ValidationService";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IIdGeneratorService } from "../../../ProlifeSdk/IdGeneratorService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { LayoutWithHeader, LayoutHeader, LayoutContent } from "../../../Components/Layouts";
import { Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnHeader, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { ITicketCategory } from "../../TicketsService";
import { ITicketCategoriesSettingsManager } from "../TicketCategoriesSettingsManager";

const { classes } = jss.createStyleSheet({
    ticketsTable : {
        "& .actions-col": {
            width: "60px"
        },
        "& .noMargin input[type=text]": {
            margin: "0 !important"
        }
    }
}).attach();

export class TicketCategory {
    @LazyImport(nameof<IValidationService>())
    private validationService : IValidationService;

    public validator: IValidator<TicketCategory>;

    Id: number;
    Title: ko.Observable<string> = ko.observable();
    Deleted: ko.Observable<boolean> = ko.observable();

    constructor(ticketCategory: ITicketCategory){
        this.Id = ticketCategory.Id;
        this.Title(ticketCategory.Title);
        this.Deleted(ticketCategory.Deleted);

        this.validator = this.validationService.createValidator<TicketCategory>()
        .isNotNullOrUndefinedOrWhiteSpace((d) => d.Title(), TextResources.Tickets.ErrorNoDescription)
    }
}

export interface ITicketCategoriesSettingsComponentProps {
    TicketCategoriesSettingsManager: ITicketCategoriesSettingsManager;
}

export class TicketCategoriesSettingsComponent {
    @LazyImport(nameof<IIdGeneratorService>())
    private idGeneratorService : IIdGeneratorService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    TicketCategories: ko.ObservableArray<TicketCategory> = ko.observableArray();

    constructor(private props: ITicketCategoriesSettingsComponentProps){
        this.loadData();
    }

    loadData(){
        this.TicketCategories(this.props.TicketCategoriesSettingsManager.getTicketCategories(null, false).map((x) => new TicketCategory(x)));
    }

    addNewTicketCategory(){
        this.TicketCategories.push(new TicketCategory({
            Id: this.idGeneratorService.getNextId(),
            Deleted: false,
            Title: null
        }));
    }

    async deleteTicketCategory(item: TicketCategory){
        let result = await this.dialogsService.ConfirmAsync(TextResources.Tickets.DeleteItemMessage, TextResources.Tickets.CancelDeleteItem, TextResources.Tickets.ConfirmDeleteItem);
        if (result) {
            if (item.Id < 0)
                this.TicketCategories.remove(item);
            else
                item.Deleted(true);
        }
    }

    restoreTicketCategory(item: TicketCategory){
        item.Deleted(false);
    }

    async saveAll(){
        let isValid = true;
        for (let eventType of this.TicketCategories()){
            if (!eventType.validator.validateAndShowInfoToast(eventType)){
                isValid = false;
                break;
            }
        }

        if (this.TicketCategories().length < 1){
            this.infoToastService.Error(TextResources.Tickets.ErrorZeroCategories);
            isValid = false;
        }

        if (isValid){
            try {
                await this.props.TicketCategoriesSettingsManager.insertOrUpdateTicketCategories(this.TicketCategories().map(x => ({ 
                    Id: x.Id,
                    Title: x.Title(),
                    Deleted : x.Deleted()
                })));
            
                this.infoToastService.Success(TextResources.Tickets.AddExistingSuccess);
                this.loadData();
            } catch (err) {
                this.infoToastService.Error(err.message);
            }
        }
    }

    createTicketCategoryModel(item : TicketCategory) {
        return {
            id: item.Id,
            title: item.Title(),
            isLeaf: true,
            isGroup: false,
            model: item
        }
    }

    render() {
        let vm = this;
        let ticketCategories: IDataSourceModel<number, TicketCategory>;

        return ComponentUtils.bindTo(
            <LayoutWithHeader>
                <LayoutHeader className="row no-gutters">
                    <div className="col-md-10">
                        <h1>{TextResources.Tickets.Categories}</h1>
                    </div>
                    <div className="col-md-2 text-right" style="margin-top: 20px">
                        <button className="btn btn-success" data-bind={{click: vm.saveAll.bind(vm)}}>
                            <i className="fa fa-save"></i> {TextResources.Tickets.Save}
                        </button>
                    </div>
                </LayoutHeader>
                <LayoutContent>
                    <Table rowAs="ticketCategories" dataSource={{array: vm.TicketCategories, factory: vm.createTicketCategoryModel }} compact={true} editable={true} scrollable={true} className={classes.ticketsTable}>
                        <Column title={TextResources.Tickets.Notes}>
                            {/* <span data-bind={{if: ticketCategories.model.IsInUse}}>
                                <i className="fa fa-exclamation-triangle" title={TextResources.Tickets.TicketInUseAlert}></i>
                            </span> */}
                            <span data-bind={{if: ticketCategories.model.Deleted}}>
                                <i className="fa fa-trash-o" title={TextResources.Tickets.CategoryIsDeletedAlert}></i>
                            </span>
                        </Column>
                        <Column title={TextResources.Tickets.Title} cssClasses="noMargin">
                            <text-input value={() => "ticketCategories.model.Title"} readOnly={() => "ticketCategories.model.Deleted()"} placeholder={TextResources.Tickets.TitlePlaceholder}/>
                        </Column>
                        <Column title={TextResources.Tickets.Actions}>
                            <ColumnHeader>
                                <button className="btn btn-primary btn-xs" data-bind={{click: vm.addNewTicketCategory.bind(vm)}}>
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <div data-bind={{ifnot: ticketCategories.model.Deleted}}>
                                    <button className="btn btn-danger btn-xs" data-bind={{click: vm.deleteTicketCategory.bind(vm, ticketCategories.model)}}>
                                        <i className="fa fa-trash-o"></i>
                                    </button>
                                </div>
                                <div data-bind={{if: ticketCategories.model.Deleted}}>
                                    <button className="btn btn-success btn-xs" data-bind={{click: vm.restoreTicketCategory.bind(vm, ticketCategories.model)}}>
                                        <i className="fa fa-recycle"></i>
                                    </button>
                                </div>
                            </ColumnBody>
                        </Column>
                    </Table>
                </LayoutContent>
            </LayoutWithHeader>, this, "vm");
    }
}   