import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NodeSubElement } from "../../../../ProlifeSdk/prolifesdk/documents/references-map-viewer/NodeSubElement";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IReferenceDetailsViewModelFactory, IEntityRefInfo, IReferenceDetailsViewModel, ISimpleEntitiesViewModel } from "../../../../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IRefDocumentRow } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { ILeafReferenceInfo } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWorkedHoursService } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IBlogService } from "../../../../ProlifeSdk/interfaces/blog/IBlogService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDocumentReferencesMap, IReferenceForMap } from "../../../../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { Deferred } from "../../../../Core/Deferred";

export class WorkedHoursRefDetailsViewModelFactory implements IReferenceDetailsViewModelFactory
{
    public UrlProvider : (docId : number, regId : number) => string;

    constructor(private serviceLocator : IServiceLocator)
    {
    }

    GetEntityTypeDescription() : string
    {
        return ProlifeSdk.TextResources.Blog.WorkedHours;
    }

    CreateViewModelsFor(references : IRefDocumentRow[], details : IEntityRefInfo[], customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        references.forEach((r : IRefDocumentRow) => {
            var matches = details.filter((d : IEntityRefInfo) => { return d.EntityKeyId == r.SourceEntityKeyId; });
            viewModels.push(new WorkedHoursRefDetailsViewModel(r, matches[0], this.serviceLocator));
        });
        viewModels.sort((a : WorkedHoursRefDetailsViewModel, b : WorkedHoursRefDetailsViewModel) => {
            var dateA = a.details.ReferenceDate;
            var dateB = b.details.ReferenceDate;
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        });
        return viewModels;
    }

    CreateViewModelForReferencedLeafs(referencedLeafsInfo : ILeafReferenceInfo[], warehouseId : number, customerId: number) : IReferenceDetailsViewModel[]
    {
        var viewModels : IReferenceDetailsViewModel[] = [];
        referencedLeafsInfo.forEach((r : ILeafReferenceInfo) => {
            viewModels.push(new WorkedHoursLeafRefDetailsViewModel(r, this.serviceLocator));
        });
        return viewModels;
    }

    CreateSimpleEntitiesSourceViewModelForReferenceMapViewer(referenceMap : IDocumentReferencesMap) : ISimpleEntitiesViewModel
    {
        return new WorkedHoursEntitiesViewModel(referenceMap);
    }
}

export class WorkedHoursEntitiesViewModel implements ISimpleEntitiesViewModel
{
    @LazyImport(nameof<IWorkedHoursService>())
    private workedHoursService : IWorkedHoursService;

    templateName : string = "documents-map-viewer-worked-hours";
    templateUrl : string = "blog/templates/referencemap";
    EntityType : string = ProlifeSdk.WorkedHoursEntityTypeCode;

    public Hours : ko.ObservableArray<WorkedHoursForReferenceMap> = ko.observableArray([]);

    constructor(private referenceMap : IDocumentReferencesMap)
    {
    }

    public LoadEntities(references : IReferenceForMap[]) : Promise<void>
    {
        var entitiesIds : number[] = references
            .filter((r : IReferenceForMap) => { return r.SourceEntityType == this.EntityType; })
            .map((r : IReferenceForMap) => { return r.SourceId; });

        var def = new Deferred<void>();
        this.workedHoursService.GetWorkedHoursByIds(entitiesIds).then((hours) => {
            this.Hours(hours.map((h : any) => { return new WorkedHoursForReferenceMap(h, this); }));
        })
        .finally(() => { def.resolve(); });
        return def.promise();
    }

    public SelectRelatedRows(entityId : number, entityType : string)
    {
        this.Hours().forEach((h: WorkedHoursForReferenceMap) => {
            h.IsSelected(h.IsSourceFor(entityId, entityType, this.referenceMap.References()));
        });
    }

    public OnSelectionChanged(selected : WorkedHoursForReferenceMap)
    {
        this.Hours().forEach((a : WorkedHoursForReferenceMap) => {
            a.IsSelected(a == selected);
        });
        this.referenceMap.OnSubSelectionChanged(this, selected.hours.HoursId, this.EntityType);
    }
}

export class WorkedHoursForReferenceMap extends NodeSubElement
{
    public IsSelected : ko.Observable<boolean> = ko.observable(false);
    private RoleDescription : ko.Observable<string> = ko.observable("");

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private ucService : IUserCharactersSettingsManager;

    constructor(public hours : any, private container : WorkedHoursEntitiesViewModel)
    {
        super(container, hours.HoursId, container.EntityType);
        var role : IUserCharacter = this.ucService.getUserCharacterById(hours.RoleId);
        this.RoleDescription(role ? role.Description : ProlifeSdk.TextResources.Blog.UnknownRole);
    }
}

export class WorkedHoursRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    public templateName : string;
    public templateUrl : string;
    public JobOrderId : number;

    constructor(public jobOrderId : number, private isWorkSheetHours : boolean,
                private resourceId : number, private referenceDate : Date,
                protected serviceLocator : IServiceLocator)
    {
        this.templateUrl = "blog/templates/referencemap/referencedetails";
        this.JobOrderId = jobOrderId;
    }

    ShowDetails()
    {
        if(this.isWorkSheetHours)
        {
            var workedHoursService : IWorkedHoursService = <IWorkedHoursService>this.serviceLocator.findService(ProlifeSdk.WorkedHoursServiceType);

            if(workedHoursService)
                window.open(workedHoursService.GetWorkSheetUrl(this.resourceId, this.referenceDate));
        }
        else
        {
            var blogService : IBlogService = <IBlogService>this.serviceLocator.findService(ProlifeSdk.BlogServiceType);
            blogService.openBlogInNewWindow(this.JobOrderId);
        }
    }
}


export class WorkedHoursRefDetailsViewModel extends WorkedHoursRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(public reference : IRefDocumentRow, public details : IEntityRefInfo, serviceLocator : IServiceLocator)
    {
        super(details.JobOrderId, details.IsWorkSheetHours, details.ResourceId, details.ReferenceDate, serviceLocator);
        this.templateName = "worked-hours";
    }


}

export class WorkedHoursLeafRefDetailsViewModel extends WorkedHoursRefDetailsViewModelBase implements IReferenceDetailsViewModel
{
    constructor(public reference : ILeafReferenceInfo, serviceLocator : IServiceLocator)
    {
        super(reference.JobOrderId, reference.IsWorkSheetHours, reference.ResourceId, reference.ReferenceDate, serviceLocator);
        this.templateName = "worked-hours-leaf";
    }
}