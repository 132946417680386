import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { HumanResourcesEditingViewModel } from "./ui/HumanResourcesEditingViewModel";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IHumanResource, IHumanResourcesService } from "../../HumanResourcesService";
import { IUserInfo } from "../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IModulesService } from "../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsManager } from "../../../ProlifeSdk/interfaces/settings/ISettingsManager";
import { IView } from "../../../ProlifeSdk/interfaces/IView";

export interface IHumanResourcesSettingsManager extends ISettingsManager
{
    /** @returns resource id if found, -1 otherwise */
    getLoggedResource() : IHumanResource;
    getLoggedResourceId() : number;
    getHumanResources() : IHumanResource[];
    getHumanResourceById(id : number) : IHumanResource;
    createOrUpdate(resource : IHumanResource) : Promise<IHumanResource>;
    remove(id : number) : Promise<void>;
    getFullName(resource : IHumanResource) : string;
    getHumanResourceByUserId(userId : number) : IHumanResource;
}

export interface IInvalidHoursForResourceInfo {
    HoursId : number;
    WorkDate : Date;
    ResourceId : number;
    JobOrderId : number;
    JobOrderName : string;
    TaskId : number;
    taskTitle : string;
}

export interface IHumanResourceSettingsObserver
{
    onResourceChanged(resource : IHumanResource);
    onResourceAdded(resource : IHumanResource);
    onResourceDeleted(id : number);
}

export class HumanResourcesSettingsManager implements IHumanResourcesSettingsManager
{
    @LazyImport(nameof<IHumanResourcesService>())
    private humanResourcesService : IHumanResourcesService;
    @LazyImport(nameof<IModulesService>())
    private modulesService : IModulesService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService!: IUserInfo;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;

    private resources : IHumanResource[];
    private observers : IHumanResourceSettingsObserver[] = [];

    constructor()
    {
        this.settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Users.ResourcesRoles);
        this.resources = [];
    }

    load(): Promise<IHumanResource[]>
    {   
        return this.humanResourcesService.GetHumanResources(null, true, true)
            .then(r => this.resources = r);
    }

    getName(): string
    {
        return ProlifeSdk.HumanResources;
    }

    getLabel(): string
    {
        return ProlifeSdk.TextResources.Users.HourlyResources;
    }

    hasEditingUI(): boolean
    {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.JobOrderApplicationCode) &&
            !this.modulesService.IsModuleEnabled(ProlifeSdk.ResourcesManagerApplicationCode);
    }

    getEditingUI(): IView
    {
        return {
            templateName: 'HumanResources',
            templateUrl: 'Users/templates/settings',
            viewModel: new HumanResourcesEditingViewModel(this)
        };
    }

    public getHumanResources(showDisabled: boolean = false, showDeleted: boolean = false) : IHumanResource[]
    {
        return this.resources.filter(r => ((showDisabled || !r.Resource.Disabled) && (showDeleted || !r.Resource.Deleted)));
    }

    public getFullName(resource : IHumanResource) : string
    {
        return this.humanResourcesService.getFullName(resource);
    }

    public getHumanResourceById(id : number) : IHumanResource
    {
        const retValues : IHumanResource[] = this.resources.filter((resource) => resource.Resource.Id == id);
        if (retValues != null && retValues.length == 1)
            return retValues[0];
        return null;
    }

    public getLoggedResource(): IHumanResource {
        const userId = this.userInfoService.getIdUser();
        return this.getHumanResourceByUserId(userId);
    }

    public getLoggedResourceId(): number {
        const resource = this.getLoggedResource();
        return !resource ? -1 : resource.Resource.Id;
    }

    async createOrUpdate(resource : IHumanResource) : Promise<IHumanResource>
    {
        const newResource = await this.humanResourcesService.InsertOrUpdateHumanResource(resource);
        if(resource.Resource.Id > 0)
            this.onResourceChanged(newResource);
        else
            this.onResourceAdded(newResource);

        return newResource;
    }

    async remove(id : number) : Promise<void>
    {
        await this.humanResourcesService.DeleteHumanResource(id);
        this.onResourceDeleted(id);
    }

    private onResourceChanged(resource : IHumanResource)
    {
        const index : number = this.getIndexOfResource(resource.Resource.Id);
        this.resources[index] = resource;
        this.observers.forEach((obs) => obs.onResourceChanged(resource));
    }

    private onResourceAdded(resource : IHumanResource)
    {
        this.resources.push(resource);
        this.observers.forEach((obs) => obs.onResourceAdded(resource));
    }

    private onResourceDeleted(id : number)
    {
        const index : number = this.getIndexOfResource(id);
        this.resources.splice(index, 1);
        this.observers.forEach((obs) => obs.onResourceDeleted(id));
    }

    private getIndexOfResource(id : number) : number
    {
        const item = this.resources.filter((resource : IHumanResource) => resource.Resource.Id == id)[0];
        return this.resources.indexOf(item);
    }

    public getHumanResourceByUserId(userId : number) : IHumanResource
    {
        const matches = this.resources.filter((r : IHumanResource) => r.Resource.FkUser == userId);
        return matches.length < 1 ? null : matches[0];
    }
}
