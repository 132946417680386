import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 31/01/13
 * Time: 17.25
 * To change this template use File | Settings | File Templates.
 */

import { IDialogViewModel } from "../Desktop/interfaces/IDialogViewModel";

export class Dialog
{
    constructor()
    {
    }

    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): any
    {
        var options = allBindingsAccessor();
        var dialogTemplateName = options.dialogTemplateName || "dialog-container";
		var additionalClasses = options.dialogClasses || "";

        var div = null;
        var showHide = ko.computed(function() {
            var vm : IDialogViewModel = ko.utils.unwrapObservable(valueAccessor()),
                bc = bindingContext.createChildContext(vm);

            if(!vm) return;

            var show = vm.show();
            if(show) {
                div = document.createElement("div");
                ko.utils.toggleDomNodeCssClass(div, "bootstrap modal hide fade " + additionalClasses, true);
                document.body.appendChild(div);
                ko.renderTemplate(dialogTemplateName, bc, allBindingsAccessor(), div);
            } else if(!div) {
                return;
            }

            if(div) {
                if(show) {
                    (<any>$(div)).modal({ backdrop: 'static' });
                }
                (<any>$(div)).modal(show ? 'show' : 'hide');
            }

            if(show) {
                var divToRemove = div;
                $(div).on('escapeClose', function(e) {
                    e.preventDefault();
                    vm.close();
                });

                $(div).on('hidden', function(){
                    vm.show(false);
                    ko.removeNode(divToRemove);
                });
            } else {
                div = null;
            }
        });
        return {
            controlsDescendantBindings: true
        };
    }

    /*update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void{

    }*/
}

ko.bindingHandlers["openDialog"] = new Dialog();