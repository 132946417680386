import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { IDialog } from "../../Core/interfaces/IDialogsService";
import { IAjaxService } from "../../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { LazyImport } from "../../Core/DependencyInjection";

export class ChangePasswordDialog implements IDialog
{
    @LazyImport(nameof<IDesktopService>())
    private desktopService : IDesktopService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService : IAjaxService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    public templateName = "change-password-dialog";
    public templateUrl = "desktop/templates";
    title : string = ProlifeSdk.TextResources.Desktop.ChangePassword;
    modal: {
        close: (result?: any) => void;
    };

    private OldPassword : ko.Observable<string> = ko.observable("");
    private NewPassword : ko.Observable<string> = ko.observable("");
    private ConfirmNewPassword : ko.Observable<string> = ko.observable("");
    private ConfirmNewPasswordIcon : ko.Observable<string> = ko.observable("");
    private ConfirmNewPasswordIconColor : ko.Observable<string> = ko.observable("");
    private ConfirmNewPasswordIconTooltip : ko.Observable<string> = ko.observable("");

    constructor() {
        this.NewPassword.subscribe((newValue) => {
            if (!newValue && !this.ConfirmNewPassword()) {
                this.ConfirmNewPasswordIcon("");
                this.ConfirmNewPasswordIconColor("");
                this.ConfirmNewPasswordIconTooltip("");
                return;
            }

            if (newValue === this.ConfirmNewPassword()) {
                this.ConfirmNewPasswordIcon("fa a-check");
                this.ConfirmNewPasswordIconColor("green");
                this.ConfirmNewPasswordIconTooltip(ProlifeSdk.TextResources.Desktop.PasswordsMatch);
            } else {
                this.ConfirmNewPasswordIcon("fa fa-times");
                this.ConfirmNewPasswordIconColor("red");
                this.ConfirmNewPasswordIconTooltip(ProlifeSdk.TextResources.Desktop.PasswordsDoNotMatch);
            }
        });

        this.ConfirmNewPassword.subscribe((newValue) => {
            if (!newValue && !this.NewPassword()) {
                this.ConfirmNewPasswordIcon("");
                this.ConfirmNewPasswordIconColor("");
                this.ConfirmNewPasswordIconTooltip("");
                return;
            }

            if (newValue === this.NewPassword()) {
                this.ConfirmNewPasswordIcon("fa fa-check");
                this.ConfirmNewPasswordIconColor("green");
                this.ConfirmNewPasswordIconTooltip(ProlifeSdk.TextResources.Desktop.PasswordsMatch);
            } else {
                this.ConfirmNewPasswordIcon("fa fa-times");
                this.ConfirmNewPasswordIconColor("red");
                this.ConfirmNewPasswordIconTooltip(ProlifeSdk.TextResources.Desktop.PasswordsDoNotMatch);
            }
        });
    }

    public close(): void
    {
        this.modal.close();
    }

    public action(): void
    {
        this.doAction();
    }

    private async doAction(): Promise<void> {
        if (this.NewPassword() != this.ConfirmNewPassword())
        {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Desktop.PasswordsNotMatching);
            return;
        }

        this.desktopService.BlockPageUI(ProlifeSdk.TextResources.Desktop.UpdatingPassword);

        try {
            await this.ajaxService.Post("Login", "ChangePassword", {
                methodData: { oldPassword: this.OldPassword(), newPassword : this.NewPassword(), newPasswordCheck: this.ConfirmNewPassword() }
            });

            this.infoToastService.Success(ProlifeSdk.TextResources.Desktop.PasswordChanged);
            this.close();
        } catch (e) {
            console.log(e);
        } finally {
            this.desktopService.UnblockPageUI();
        }
    }
}