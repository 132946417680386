import * as ko from "knockout";
import { RowToArticleRefConverter } from "./RowToArticleRefConverter";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IAliasesService } from "../../../AliasesService";
import { IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider, IDocumentRowInlineReferenceProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { IEntityForRef } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IArticlesService, IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";

export class RowToArticleRefConverterProvider implements IDocumentRowInlineReferenceProviderFactory
{
    @LazyImport(nameof<IArticlesService>())
    private articleService : IArticlesService;

    @LazyImport(nameof<IAliasesService>())
    private aliasesService : IAliasesService;

    public ActivationCode : string = "@01";      //E' il provider di default e non ha un codice di attivazione associato
    public EntityType : string  = ProlifeSdk.WarehouseArticleEntityTypeCode;
    public Icon : string = "fa fa-barcode";
    public Description : string = ProlifeSdk.TextResources.Warehouse.WarehouseArticles;
    public DefaultActivation : boolean = true;
    public SupportedDocTypes : string[] = [
        ProlifeSdk.EstimateEntityTypeCode,
        ProlifeSdk.CustomerOrderEntityTypeCode,
        ProlifeSdk.DdtEntityTypeCode,
        ProlifeSdk.SalEntityTypeCode,
        ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
        ProlifeSdk.WarehouseLoadEntityTypeCode,
        ProlifeSdk.SupplierOrderEntityTypeCode,
        ProlifeSdk.RequestForQuotationEntityTypeCode,
        ProlifeSdk.PurchaseRequestEntityTypeCode,
    ];

    constructor()
    {
    }

    public async findEntities(query : string, skip : number, count : number, docInfo : IDocumentInfoForInlineRefProvider) : Promise<IEntityForRef[]>
    {
        let articles = await this.articleService.getArticles(query, null, skip, count)    
        let aliases = await this.aliasesService.ResolveArticleAliasesByIds(articles.map(a => a.ArticleId), docInfo.CustomerId());

        var entities : IEntityForRef[] = [];

        for(let article of articles) {
            let entity = this.createModelFor(article);

            let foundAlias = aliases.firstOrDefault(a => a.ArticleId == article.ArticleId);
            if(!foundAlias) {
                entities.push(entity);
                continue;
            }

            entities.push({
                Id: article.ArticleId,
                EntityType: ProlifeSdk.WarehouseArticleEntityTypeCode,
                Code: foundAlias.AliasedCode,
                Description: article.Description,
                SubTitle: article.Code,
                Icon: {
                    background: "white",
                    foreground: "black",
                    icon: this.Icon
                }
            });
        }

        return entities;
    }

    private createModelFor(a : IArticle) : IEntityForRef {
        return { 
            Id : a.ArticleId, 
            EntityType : ProlifeSdk.WarehouseArticleEntityTypeCode, 
            Description : a.Code + " " + a.Description,
            Icon: {
                background: "white",
                foreground: "black",
                icon: "fa fa-shopping-cart"
            }
        };
    }

    public create(document : IDocumentInfoForInlineRefProvider) : IDocumentRowInlineReferenceProvider
    {
        return new RowToArticleRefConverter(this.ActivationCode, document);
    }
}
