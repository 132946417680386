import * as ko from "knockout";
﻿import * as React from "@abstraqt-dev/jsxknockout";
import * as moment from "moment";
import 'moment-duration-format';
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param, ComponentParam } from "../Core/utils/ComponentUtils";
import { IDataSourceListener } from "../DataSources/IDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

let attributes = {
    Placeholder: "placeholder",
    ReadOnly: "readOnly",
    Label: "label",
    LabelIcon: "labelIcon",
    Value: "value",
    Listener: "listener",
    InjectTo: "injectTo",
    TabIndex: "tabindex",
    SubmitOnEnter: "submitOnEnter",
    HasFocus: "hasFocus",
    AllowNegative: "allowNegative",
    SignPositionLeft: "signPositionLeft",
    PositiveSignLabel: "positiveSignLabel",
    NegativeSignLabel: "negativeSignLabel"
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "duration" : {
                params?: {
                    Placeholder?: string;
                    ReadOnly?: string;
                    Label?: string;
                    LabelIcon?: string;
                    Value?: string;
                    Listener?: string;
                    InjectTo?: string;
                    TabIndex?: string;
                    SubmitOnEnter?: string;
                    HasFocus?: string;
                    AllowNegative?: string;
                    SignPositionLeft?: string;
                    PositiveSignLabel?: string;
                    NegativeSignLabel?:  string;
                } | string;

                placeholder?: string | (() => string);
                readOnly?: boolean | (() => string);
                label?: string | (() => string);
                labelIcon?: string | (() => string);
                value?: (() => string);
                listener?: (() => string);
                injectTo?: (() => string);
                tabindex?: number | (() => string);
                submitOnEnter?: boolean | (() => string);
                hasFocus?: boolean | (() => string);
                allowNegative?: boolean | (() => string);
                signPositionLeft?: boolean | (() => string);
                positiveSignLabel? : string | (() => string);
                negativeSignLabel? : string | (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

export interface IDurationComponent {

}

export interface IDurationComponentParameters {
    Placeholder?: Param<string>;
    ReadOnly? : Param<boolean>;

    Label: Param<string>;
    LabelIcon?: Param<string>;
    Value: Param<number>;
    TabIndex: Param<number>;
    SubmitOnEnter: Param<boolean>;
    HasFocus: Param<boolean>;

    Listener?: Param<IDataSourceListener>;
    InjectTo?: Param<IDurationComponent>;

    AllowNegative?: Param<boolean>;
    SignPositionLeft?: Param<boolean>;
    PositiveSignLabel?: Param<string>;
    NegativeSignLabel?: Param<string>;

}

export class DurationComponent implements IDurationComponent {
    Placeholder: ComponentParam<string>;
    ReadOnly: ComponentParam<boolean>;
    Label: ComponentParam<string>;
    LabelIcon: ComponentParam<string>;
    Value: ko.Observable<number>;
    TextValue: ComponentParam<string>;
    TabIndex: ComponentParam<number>;
    SubmitOnEnter: ComponentParam<boolean>;
    HasFocus: ComponentParam<boolean>;
    AllowNegative: ComponentParam<boolean>;
    SignPositionLeft: ComponentParam<boolean>;
    PositiveSignLabel: ComponentParam<string>;
    NegativeSignLabel: ComponentParam<string>;

    SignLabel: ko.Observable<string> = ko.observable(TextResources.DurationComponente.PositiveSignLabel);
    IsValuePositive: ko.Observable<boolean> = ko.observable(true);

    LastValueInvalid: ko.Observable<boolean> = ko.observable(false);

    private valueIsComputed = false;

    switchSign(){
        this.IsValuePositive(!this.IsValuePositive());
    }

    constructor(params : IDurationComponentParameters, private element : Element) {
        this.Placeholder = ComponentUtils.parseParameter(params.Placeholder, "");
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
        this.Label = ComponentUtils.parseParameter(params.Label, "");
        this.LabelIcon = ComponentUtils.parseParameter(params.LabelIcon, "");
        this.Value = ComponentUtils.parseParameter(params.Value, null) as ko.Observable;
        this.TabIndex = ComponentUtils.parseParameter(params.TabIndex, 0);
        this.SubmitOnEnter = ComponentUtils.parseParameter(params.SubmitOnEnter, false);
        this.HasFocus = ComponentUtils.parseParameter(params.HasFocus, false);
        this.AllowNegative = ComponentUtils.parseParameter(params.AllowNegative, false);
        this.SignPositionLeft = ComponentUtils.parseParameter(params.SignPositionLeft, false);
        this.PositiveSignLabel = ComponentUtils.parseParameter(params.PositiveSignLabel, "");
        this.NegativeSignLabel = ComponentUtils.parseParameter(params.NegativeSignLabel, "");

        
        this.IsValuePositive.subscribe(res => {
            this.SignLabel(res ? TextResources.DurationComponente.PositiveSignLabel : TextResources.DurationComponente.NegativeSignLabel);
            if ((this.Value() > 0 && !res) || (this.Value() < 0 && res)) {
                this.Value(this.Value() * -1)
            }
        })

        this.IsValuePositive(this.Value() >= 0);

        this.valueIsComputed = ko.isComputed(this.Value);

        if (ko.isComputed(this.Value)) {
            this.Value.extend({ notify: 'always' });
        }

        this.TextValue = ko.computed({
            read: () => {
                let date = moment.duration(this.Value() < 0 ? this.Value() * -1 : this.Value(), 'hours');
                if(date.seconds() == 0)
                    return date.format("hh:mm", { trim: false });
                return date.format("hh:mm:ss", { trim: false });
            },
            write: (value) => {
                if(!value) {
                    this.LastValueInvalid(true);
                    if (!this.valueIsComputed)
                        this.Value.valueHasMutated();
                    return;
                }

                let withColumns = value.replace(/\./g, ':').replace(/[^0-9:]/g, '');
                let components = withColumns.split(':');
                if(components.length == 0) {
                    this.LastValueInvalid(true);
                    if (!this.valueIsComputed)
                        this.Value.valueHasMutated();
                    return;
                }

                let hours = parseInt(components[0]);
                hours =  (hours < 0 && this.IsValuePositive()) ||( hours > 0 && !this.IsValuePositive())? hours * -1: hours; 
                if(isNaN(hours)) {
                    this.LastValueInvalid(true);
                    if (!this.valueIsComputed)
                        this.Value.valueHasMutated();
                    return;
                }

                let minutes = 0;
                minutes = (minutes < 0 && this.IsValuePositive()) ||( minutes > 0 && !this.IsValuePositive())? minutes * -1: minutes;
                if(components.length > 1) {
                    minutes = parseInt(components[1]);
                    if(isNaN(minutes))
                        minutes = 0;
                    if(minutes > 59) {
                        this.LastValueInvalid(true);
                        if (!this.valueIsComputed)
                            this.Value.valueHasMutated();
                        return;
                    }
                }

                let seconds = 0;
                seconds = (seconds < 0 && this.IsValuePositive()) ||( seconds > 0 && !this.IsValuePositive())? seconds * -1: seconds;
                if(components.length > 2) {
                    seconds = parseInt(components[2]);
                    if(isNaN(seconds))
                        seconds = 0;
                    if(seconds > 59) {
                        this.LastValueInvalid(true);
                        if (!this.valueIsComputed)
                            this.Value.valueHasMutated();
                        return;
                    }
                }
                
                let totalSeconds = seconds + (minutes * 60) + (hours * 3600);
                let duration = moment.duration(totalSeconds, 'seconds');

                if(!duration.isValid()) {
                    this.LastValueInvalid(true);
                    if (!this.valueIsComputed)
                        this.Value.valueHasMutated();
                    return;
                }

                this.LastValueInvalid(false);
                const newDuration = duration.asHours();
                if(newDuration == this.Value()) {
                    if (!this.valueIsComputed)
                        this.Value.valueHasMutated();
                }
                else
                    this.Value(newDuration);
            }
        }).extend({ notify: 'always' });
    }
}

ko.components.register('duration', {
    viewModel: {
        createViewModel: (params : IDurationComponentParameters, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            let vm = new DurationComponent(params, componentInfo.element as Element);

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="form-group" data-bind="css: { 'has-error': LastValueInvalid }">
                    <label class="control-label" data-bind="visible: Label || LabelIcon">
                        <ko-if data-bind="LabelIcon">
                            <i data-bind="css: LabelIcon" style="margin-right: 3px"></i>
                        </ko-if>
                        <ko-text data-bind="Label"></ko-text>
                    </label>
                    <ko-ifnot data-bind="AllowNegative">
                        <input class="form-control" type="text" data-bind={"value: TextValue, valueUpdate: 'focusout', selectOnFocus: { observeValue: HasFocus }, disable: ReadOnly, attr: { placeholder: Placeholder, tabindex: TabIndex }, hasFocus: HasFocus" + (vm.SubmitOnEnter() ? ", submitOnEnterOrTab: { ignoreTab: true, submitOnLostFocus: false }" : "")} />
                    </ko-ifnot>
                    <ko-if data-bind="AllowNegative">
                        <div class="input-group">
                            <ko-if data-bind="SignPositionLeft">
                            <span class="input-group-addon btn " data-bind="text: SignLabel, click: switchSign"></span>
                            </ko-if>
                                <input class="form-control" type="text" data-bind={"value: TextValue, valueUpdate: 'focusout', selectOnFocus: { observeValue: HasFocus }, disable: ReadOnly, attr: { placeholder: Placeholder, tabindex: TabIndex }, hasFocus: HasFocus" + (vm.SubmitOnEnter() ? ", submitOnEnterOrTab: { ignoreTab: true, submitOnLostFocus: false }" : "")} />
                            <ko-ifnot data-bind="SignPositionLeft">
                            <span class="input-group-addon btn" data-bind="text: SignLabel, click: switchSign"></span>
                            </ko-ifnot>
                        
                        </div>
                    </ko-if>
                </div>
            ])

            return vm;
        }
    },
    template: []
});