import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { Param, ComponentParam, ComponentUtils } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";

const attributes = {
    Checked: "checked",
    ReadOnly: "read-only"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "checkbox": {
               params?: {
                   Checked?: string,
                   ReadOnly?: string
               };

            "checked"?: boolean | (() => string);
            "read-only"?: boolean | (() => string);
           } & HTMLAttributes<HTMLElement>
       }
   }
}

export interface ICheckBoxParams {
    Checked: Param<boolean>;
    ReadOnly: Param<boolean>;
}

export class CheckBox {
    Checked: ComponentParam<boolean>;
    ReadOnly: ComponentParam<boolean>;

    constructor(params : ICheckBoxParams) {
        this.Checked = ComponentUtils.parseParameter(params.Checked, false);
        this.ReadOnly = ComponentUtils.parseParameter(params.ReadOnly, false);
    }
}

ko.components.register("checkbox", {
    viewModel: {
        createViewModel: (params: ICheckBoxParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            const vm = new CheckBox(params);

            const element = (componentInfo.element as HTMLElement);
            const classes = element.className || "";
            element.className = "";
            
            let templateNodes = [
                <label class={"control-label flex-container " + classes}>
                    <div class="checker" style="margin-top: 0px !important;" data-bind="css: { disabled: ReadOnly }">
                        <span data-bind="css : { checked : Checked }">
                            <input type="checkbox" data-bind="checked: Checked, disable: ReadOnly"></input>
                        </span>
                    </div>
                </label>
            ];

            if (componentInfo.templateNodes.length > 0) {
                templateNodes = [
                    <label class={"control-label flex-container " + classes}>
                        <div class="checker" style="margin-top: 0px !important;" data-bind="css: { disabled: ReadOnly }">
                            <span data-bind="css : { checked : Checked }">
                                <input type="checkbox" data-bind="checked: Checked, disable: ReadOnly"></input>
                            </span>
                        </div>
                        <span style="display: inline-block; width: 93%; text-align: left;" data-bind="template: { nodes: $componentTemplateNodes }"></span>
                    </label>
                ];  
            }

            ko.virtualElements.setDomNodeChildren(componentInfo.element, templateNodes);
            
            return vm;
        },
    },
    template: []
});