import * as ko from "knockout";
export namespace StringFormatters {

	export function formatTextIntoHtml(text : string): string
	{
		if (!text)
			return "";

		var testo = text.replace(/\r\n/g, '<br/>').replace(/[\r\n]/g, '<br/>');
		return testo.replace(/ /g, '&nbsp;').replace(/[\t\f\v]/g, '&nbsp;');
	}

	export function trimEx(text : string) : string
	{
		if (!text)
			return "";

		var testo = text.replace(/\r\n/g, '').replace(/[\r\n]/g, '');
		return testo.replace(/ /g, '').replace(/[\t\f\v]/g, '');
	}

}