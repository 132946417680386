import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { Param, ParamArray, ComponentUtils, ComponentParam, ComponentParamArray } from "../Core/utils/ComponentUtils";
import jss from "jss";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { TimelineOrientations } from "./TimelineOrientations";
import { IDataSource, IDataSourceListener, IDataSourceModel, IDataSourceView } from "../DataSources/IDataSource";

const { classes } = jss.createStyleSheet({
    prolifeHorizontalTimeline: {
        width: "100%"
    },

    timelineBox: {
        "--timeline-box-height": "150px",
        "--timeline-color": "#403d3d",
        "--timeline-node-color": "#FFC107",

        height: "var(--timeline-box-height)",
        width: "100%",

        "&.vertical": {
            width: "250px"
        },

        "& .timeline-title": {
            fontWeight: "bold",
            display: "inline-block",
            height: "30px",
            lineHeight: "30px"
        },
        
        "& .timeline-wrapper": {
            height: "var(--timeline-box-height)",
    
            "& .timeline": {
                minWidth: "100%",
                listStyle: "none",
                height: "20px",
                margin: "20px 0",
                padding: "0px 160px 0px 0px",
                backgroundColor: "var(--timeline-color)",
                position: "relative",
                
                "&.vertical": {
                    padding: "0px",
                    width: "20px",
                    "min-width": "20px",
                    "min-height": "100%",
                    margin: "0px 0px 0px 10px",

                    "& .empty-timeline": {
                        left: "15px",
                        width: "200px"
                    }
                },

                "&:before": {
                    content: "none"
                },
    
                "& .empty-timeline": {
                    padding: "0px 10px",
                    color: "var(--timeline-node-color)"
                },
    
                "& .timeline-node": {
                    display: "inline-block",
                    width: "30px",
                    height: "30px",
                    borderRadius: "15px !important",
                    backgroundColor: "var(--timeline-node-color)",
                    color: "var(--timeline-node-color)",
                    padding: "0px",
                    margin: "0px 80px",
                    position: "relative",
                    top: "-5px",
                    
                    "&.vertical": {
                        margin: "80px 0px",
                        top: "0px",
                        left: "-5px",

                        "& .node-label": {
                            top: "-16px",
                            width: "200px"
                        },

                        "& .timeline-points-list-wrapper": {
                            left: "27px",
                            top: "18px",
                            width: "200px"
                        }
                    },

                    "& .node-label": {
                        width: "125px",
                        display: "block",
                        position: "relative",
                        left: "35px",
                        lineHeight: "30px"
                    },
    
                    "& .timeline-points-list-wrapper": {
                        position: "absolute",
                        top: "30px",
                        left: "14px",
                        width: "140px",
                        height: "80px",
                        color: "black",
                        fontWeight: "600",
    
                        "& .timeline-points-list": {
                            listStyle: "none",
                            padding: "0",
    
                            "& .timeline-point": {
                                borderLeft: "3px solid var(--timeline-node-color)",
                                borderBottom: "1px solid lightgray",
                                marginBottom: "2px",
                                padding: "2px 5px",
        
                                "&:hover": {
                                    backgroundColor: "#eee",
                                    cursor: "pointer"
                                },
        
                                "&.selected": {
                                    backgroundColor: "#abd5f9"
                                }
                            }
                        }
                    }
                },
    
                "& .timeline-direction": {
                    display: "inline-block",
                    position: "absolute",
                    top: "2px",
                    right: "2px",
    
                    width: "0px",
                    height: "0px",

                    borderTop: "8px solid transparent",
                    borderBottom: "8px solid transparent",
                    borderLeft: "15px solid var(--timeline-node-color)",

                    "&.vertical": {
                        bottom: "-4px",
                        top: "initial",

                        borderLeft: "8px solid transparent",
                        borderRight: "8px solid transparent",
                        borderTop: "15px solid var(--timeline-node-color)"
                    }
                }
            }
        },

        "& .load-more-points": {
            
        },

        "& .load-more-nodes": {
            height: "20px",
            width: "20px",

            "& i": {
                color: "var(--timeline-node-color)"
            }
        }
    }
}).attach();

let attributes = {
    Title: "title",
    DataSource: "data-source",
    Listeners: "listeners",
    Orientation: "orientation",
    SearchFieldEnabled: "search-field-enabled"
};

export type TimelineOrientation = "horizontal" | "vertical";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "timeline": {
                params?: {
                    Title?: string,
                    DataSource?: IDataSource,
                    Listener?: IDataSourceListener | IDataSourceListener[],
                    Orientation?: TimelineOrientation,
                    SearchFieldEnabled?: boolean
                };

                title?: string | (() => string);
                "data-source"?: IDataSource | (() => string);
                listeners?: IDataSourceListener[] | (() => string);
                orientation?: TimelineOrientation | (() => string);
                "search-field-enabled"?: boolean | (() => string);
                
            } & HTMLAttributes<HTMLElement>
        }
    }
}

export interface ITimelineComponentParams {
    Title: Param<string>;
    DataSource: Param<IDataSource>;
    Listeners: ParamArray<IDataSourceListener>;
    Orientation: Param<TimelineOrientation>;
    SearchFieldEnabled: Param<boolean>;
}

export interface ITimelineComponent<T = any, K = any> {
    TimelineNodes: ko.ObservableArray<ITimelineComponentNode<T, K>>;
}

export interface ITimelineComponentNode<T = any, K = any> {
    Title: string;
    TimelinePoints: ko.ObservableArray<ITimelineComponentPoint<K>>;
    
    Model: T;
}

export interface ITimelineComponentPoint<T = any> {
    Title: string;
    Model: T;

    Selected: ko.Observable<boolean>;

    GetDataSourceModel(): IDataSourceModel<any, T>;
}

class TimelineComponentNode<T, K> implements ITimelineComponentNode<T, K> {
    public Title: string;
    public TimelinePoints: ko.ObservableArray<ITimelineComponentPoint<K>> = ko.observableArray([]);
    
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public AllDataLoaded: ko.Observable<boolean> = ko.observable(false);

    public Model: T;

    constructor(private dataSourceModel: IDataSourceModel<any, T>, private dataSource: IDataSource, private owner: TimelineComponent) {
        this.Title = dataSourceModel.title;
        this.Model = dataSourceModel.model;
    }

    public async LoadChildNodes(): Promise<void> {
        this.ShowLoadMore(false);

        let currentPoints = this.TimelinePoints();

        let skip: number = currentPoints.length;
        let points: IDataSourceModel<any, T>[] = await this.dataSource.getData(this.dataSourceModel, "", skip, 50);
        this.TimelinePoints(points.map(this.createPoint, this));

        this.AllDataLoaded(points.length === 0);
        this.ShowLoadMore(points.length > 0);
    }

    private createPoint(dataSourceModel: IDataSourceModel<any, T>): ITimelineComponentPoint {
        return new TimelineComponentPoint(dataSourceModel, this.owner);
    }
}

class TimelineComponentPoint<T> implements ITimelineComponentPoint<T> {
    public Title: string;
    public Model: T;

    public Selected: ko.Observable<boolean> = ko.observable(false);

    constructor(private dataSourceModel: IDataSourceModel<any, T>, private owner: TimelineComponent) {
        this.Title = dataSourceModel.title;
        this.Model = dataSourceModel.model;
    }
    
    public Select(): void {
        this.owner.select(this.GetDataSourceModel());
    }

    public GetDataSourceModel(): IDataSourceModel<any, T> {
        return this.dataSourceModel;
    }
}

export class TimelineComponent<T = any, K = any> implements ITimelineComponent<T, K>, IDataSourceView {
    public Title: ComponentParam<string>;
    public Orientation: ComponentParam<TimelineOrientation>;
    public SearchFieldEnabled: ComponentParam<boolean>;
    public DataSource: ComponentParam<IDataSource>;

    public TimelineNodes: ko.ObservableArray<ITimelineComponentNode<T, K>> = ko.observableArray([]);
    
    public TextFilter: ko.Observable<string> = ko.observable();
    public ShowLoadMore: ko.Observable<boolean> = ko.observable(true);
    public AllDataLoaded: ko.Observable<boolean> = ko.observable(false);

    public BoxHeight: ko.Computed<string>;
    public TimelineLenght: ko.Computed<string>;
    public TimelineWidth: ko.Computed<string>;
    public TimelineHeight: ko.Computed<string>;

    private listeners: ComponentParamArray<IDataSourceListener>;
    private selectedItems: IDataSourceModel<any, T>[] = [];

    constructor(params: ITimelineComponentParams) {
        this.Title = ComponentUtils.parseParameter(params.Title, "");
        this.Orientation = ComponentUtils.parseParameter(params.Orientation, TimelineOrientations.Horizontal);
        this.SearchFieldEnabled = ComponentUtils.parseParameter(params.SearchFieldEnabled, true);
        this.DataSource = ComponentUtils.parseParameter(params.DataSource, null);
        this.listeners = ComponentUtils.parseParameterArray(params.Listeners, []);

        this.BoxHeight = ko.computed(() => {
            return this.Orientation() == TimelineOrientations.Horizontal ? "auto" : "100%";
        });

        this.TimelineLenght = ko.computed(() => {
            let nodesNumber = this.TimelineNodes().length;
            return !nodesNumber ? "100%" : (nodesNumber * 190) + 160 + "px";
        });

        this.TimelineWidth = ko.computed(() => {
            return this.Orientation() === TimelineOrientations.Horizontal ? this.TimelineLenght() : "20px";
        });

        this.TimelineHeight = ko.computed(() => {
            return this.Orientation() === TimelineOrientations.Horizontal ? "20px" : this.TimelineLenght();
        });

        let lastTimeout: ReturnType<typeof setTimeout>;
        this.TextFilter.subscribe((filter: string) => {
            if (lastTimeout)
                clearTimeout(lastTimeout);

            lastTimeout = setTimeout(() => this.refresh(), 300);
        });

        if (this.DataSource())
            this.DataSource().setView(this);
    }
    
    public async LoadNextNodesPage(): Promise<void> {
        this.ShowLoadMore(false);

        let currentNodes = this.TimelineNodes();

        let skip: number = currentNodes.length;
        let nodes: IDataSourceModel<any, T>[] = await this.DataSource().getData(null, this.TextFilter(), skip, 200);
        this.TimelineNodes(currentNodes.concat(nodes.map(this.createNode, this)));

        this.AllDataLoaded(nodes.length === 0);
        this.ShowLoadMore(nodes.length > 0);
    }

    public refresh(keepSelection: boolean = true): void {
        this.TimelineNodes([]);
        this.AllDataLoaded(false);
        this.ShowLoadMore(true);

        if (keepSelection)
            this.select(...this.selectedItems.map((m => m)));
    }

    public async select(...models: IDataSourceModel<any, T>[]): Promise<void> { // Per il momento supporta solo selezione singola
        let permissionsRequests: Promise<boolean>[] = [];
        let listeners = this.listeners();

        for (let model of models) {
            listeners.forEach((l) => {
                if (l.canSelectItem)
                    permissionsRequests.push(l.canSelectItem(this.DataSource(), model));
            });
        }

        let results: boolean[] = await Promise.all(permissionsRequests);
        if (results.filter((v) => !v).length > 0)
            return;

        this.deselectPoints();
        
        for (let model of models) {
            let point: ITimelineComponentPoint = this.getPointFromModel(model);
            if (!point)
                continue;

            point.Selected(true);
            this.selectedItems.push(model);
            listeners.forEach((l) => l.onItemSelected(this.DataSource(), model));
        }
    }
    
    public getPointFromModel(model: IDataSourceModel<any, T>): ITimelineComponentPoint<any> {
        for (let node of this.TimelineNodes()) {
            for (let point of node.TimelinePoints()) {
                if (this.DataSource().areEqual(point.GetDataSourceModel(), model))
                    return point;
            }
        }

        return null;
    }
    
    public deselectPoints(): void {
        for (let node of this.TimelineNodes()) {
            for (let point of node.TimelinePoints()) {
                point.Selected(false);
            }
        }

        this.selectedItems = [];
    }
    
    public refreshImmediate(keepSelection: boolean = true): void {
        throw new Error("Method not implemented.");
    }
    
    public getPageSize(): number {
        throw new Error("Method not implemented.");
    }

    public setPageSize(size: number): void {
        throw new Error("Method not implemented.");
    }
    
    public pushState(): void {
        throw new Error("Method not implemented.");
    }
    
    public popState(): void {
        throw new Error("Method not implemented.");
    }
    
    public navigateTo(...history: IDataSourceModel<string | number, any, string | number, any>[]): void {
        throw new Error("Method not implemented.");
    }

    private createNode(dataSourceModel: IDataSourceModel<any, T>): ITimelineComponentNode {
        return new TimelineComponentNode(dataSourceModel, this.DataSource(), this);
    }
}

ko.components.register("timeline", {
    viewModel: {
        createViewModel: (params: ITimelineComponentParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new TimelineComponent(params);
            let htmlElement = componentInfo.element as HTMLElement;
            
            if (vm.Orientation() === TimelineOrientations.Horizontal)
                htmlElement.className += " " + classes.prolifeHorizontalTimeline;

            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class={classes.timelineBox + " flex-container flex-vertical"} data-bind="css: Orientation, style: { '--timeline-box-height': BoxHeight }">
                    <div class="flex-container" data-bind="css: { 'flex-vertical': Orientation() === 'vertical' }">
                        <label class="timeline-title" data-bind="text: Title, visible: Title"></label>
                        <div class="timeline-search flex-fill" data-bind="visible: SearchFieldEnabled">
                            <input type="text" class="form-control" placeholder={TextResources.ProlifeSdk.SearchPlaceholder} data-bind="value: TextFilter, valueUpdate: 'afterkeydown'" />
                        </div>
                    </div>
                    <div class="timeline-wrapper" data-bind="css: Orientation">
                        <div data-bind="slimScroll: (BoxHeight() == 'auto' ? '150px' : BoxHeight())">
                            <ul class="timeline" data-bind="style: { width: TimelineWidth, height: TimelineHeight }, css: Orientation">
                                <ko-if data-bind="TimelineNodes().length === 0">
                                    <li class="empty-timeline">{TextResources.Todolist.EmptyTimeline}</li>
                                </ko-if>
                                <ko-foreach data-bind="TimelineNodes">
                                    <li class="timeline-node" data-bind="css: $component.Orientation">
                                        <span class="node-label text-ellipsis" data-bind="text: Title, attr: { title: Title }"></span>
                                        <div class="timeline-points-list-wrapper">
                                            <div data-bind="slimScroll: '95px'">
                                                <ul class="timeline-points-list">
                                                    <ko-foreach data-bind="TimelinePoints">
                                                        <li class="timeline-point" data-bind="text: Title, click: Select, css: { selected: Selected }"></li>
                                                    </ko-foreach>
                                                    <ko-if data-bind="ShowLoadMore() && !AllDataLoaded()">
                                                        <li class="load-more-points text-center" data-bind="notifyWhenVisible: { rootSelector: '.timeline-points-list', callback: $data.LoadChildNodes.bind($data) }"></li>
                                                    </ko-if>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ko-foreach>
                                <ko-if data-bind="ShowLoadMore() && !AllDataLoaded()">
                                    <li class="load-more-nodes text-center" data-bind="notifyWhenVisible: { rootSelector: '.timeline-wrapper', callback: $data.LoadNextNodesPage.bind($data) }">
                                        <i class="fa fa-circle-o-notch fa-spin"></i>
                                    </li>
                                </ko-if>
                                <li class="timeline-direction" data-bind="css: Orientation"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});