import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../Core/utils/ComponentUtils";
import { If, IfNot } from "../../Components/IfIfNotWith";
import { FunctionPointComputationHome } from "./FunctionPointComputationHome";
import { INavBarActionManager } from "../../Desktop/interfaces/IApplication";
import { InfrastructureEditor } from "./InfrastructureEditor";

type FunctionPointComputationMainProps = {
    navBarActionManager: INavBarActionManager;
};

export function FunctionPointComputationMain(props: FunctionPointComputationMainProps) {
    const C = require("./FunctionPointComputationMain")
        ._FunctionPointComputationMain as typeof _FunctionPointComputationMain;
    return <C {...props} />;
}

export class _FunctionPointComputationMain {
    static defaultProps: Partial<FunctionPointComputationMainProps> = {};

    infrastructureId: ko.Observable<number> = ko.observable();

    constructor(private props: FunctionPointComputationMainProps) {}

    onNew() {
        this.infrastructureId(-1);
    }

    onEdit(infrastructureId: number) {
        this.infrastructureId(infrastructureId);
    }

    onDelete(iId: number): void {
        throw new Error("Method not implemented.");
    }

    render() {
        return (
            <>
                <If condition={() => this.infrastructureId() == null}>
                    {() => (
                        <FunctionPointComputationHome
                            onEdit={(iId) => this.onEdit(iId)}
                            onNew={() => this.onEdit(-1)}
                            onDelete={(iId) => this.onDelete(iId)}
                            navBarActionManager={this.props.navBarActionManager}
                        />
                    )}
                </If>
                <IfNot condition={() => this.infrastructureId() == null}>
                    {() => (
                        <InfrastructureEditor
                            infrastructureId={this.infrastructureId()}
                            onBack={() => this.infrastructureId(undefined)}
                        />
                    )}
                </IfNot>
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(FunctionPointComputationMain);
}
