import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 22/08/2017
 * Time: 09:07
 * To change this template use File | Settings | File Templates.
 */

import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { CampaignStateSettingManager } from "./Survey/ui/campaigns/settings/CampaignStateSettingManager";
import { CampaignTypeSettingManager } from "./Survey/ui/campaigns/settings/CampaignTypeSettingManager";
import { ITodoListResource } from "../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import { ICampaignService } from "../ProlifeSdk/interfaces/survey/ICampaignService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { ICampaignFilters, ICampaign, ICampaignType, ICampaignState } from "../ProlifeSdk/interfaces/survey/ICampaign";
import { IResourceForList } from "./interfaces/IResourceForList";

class CampaignService extends ProLifeService implements ICampaignService {
    private ajaxService: IAjaxService;

    constructor(private serviceLocator : IServiceLocator) {
        super(ProlifeSdk.AllocationsServiceCode);
        this.serviceLocator.registerServiceInstance(this);
    }

    InitializeService() {
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        new CampaignStateSettingManager(this.serviceLocator);
        new CampaignTypeSettingManager(this.serviceLocator);
    }

    getServiceType() : string
    {
        return ProlifeSdk.CampaignServiceType;
    }

    isOfType(serviceType: string) : boolean
    {
        return serviceType == this.getServiceType();
    }

    getCampaignsListForSplash(filters: ICampaignFilters, skip: number, count: number, background?: boolean): Promise<ICampaign[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignsListForSplash", {
            methodData: {
                Filters: filters,
                Skip: skip,
                Count: count
            }
        });
    }

    getCampaignsListForSplashCount(filters: any, background?: boolean): Promise<number> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignsListForSplashCount", {
            methodData: {
                Filters: filters
            }
        });
    }

    getCampaignTypes(): Promise<ICampaignType[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignTypes", {});
    }

    addCampaign(campaign: ICampaign, resources: ITodoListResource[]): Promise<ICampaign> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "AddCampaign", {
            methodData: {
                Campaign: campaign,
                Resources: resources
            }
        });
    }

    updateCampaign(campaign: ICampaign, resources: ITodoListResource[]): Promise<ICampaign> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "UpdateCampaign", {
            methodData: {
                Campaign: campaign,
                Resources: resources
            }
        });
    }

    getCampaignDetails(id: number): Promise<ICampaign> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignDetails", {
            methodData: {
                Id: id
            }
        });
    }

    deleteCampaign(id: number): Promise<void> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "DeleteCampaign", {
            methodData: {
                Id: id
            }
        });
    }

    getCampaignStates(): Promise<ICampaignState[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignStates", {});
    }

    getCampaignStateById(id: number): Promise<ICampaignState> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignStateById", {
            methodData: {
                Id: id
            }
        })
    }

    getCampaignLogicalState(campaignId: number): Promise<number> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignLogicalState", {
            methodData: {
                CampaignId: campaignId
            }
        });
    }

    getCampaignResources(campaignId: number): Promise<ITodoListResource[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignResources", {
            methodData: {
                CampaignId: campaignId
            }
        });
    }

    getCampaignResourcesForList(campaignId: number): Promise<IResourceForList[]> {
        return this.ajaxService.Post("Survey-api/CampaignApi", "GetCampaignResourcesForList", {
            methodData: {
                CampaignId: campaignId
            }
        });
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService
{
    return new CampaignService(serviceLocator);
}