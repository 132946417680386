import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { DetailedLayout } from "../ProlifeSdk/prolifesdk/documents/layouts/DetailedLayout";
import { CompactDetailedLayout } from "../ProlifeSdk/prolifesdk/documents/layouts/CompactDetailedLayout";
import { StandardLayout } from "../ProlifeSdk/prolifesdk/documents/layouts/StandardLayout";
import { ProtocolDefaultValuesProvider } from "./invoices/providers/ProtocolDefaultValuesProvider";
import { LazyImport } from "../Core/DependencyInjection";
import { DocumentCurrenciesFactory } from "../ProlifeSdk/prolifesdk/documents/DocumentCurrenciesFactory";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IOffsetsService } from "./OffsetsService";
import { ICurrenciesSettingsManager } from "./invoices/settings/CurrenciesSettingsManager";
import { IDocumentRowInlineReferenceProviderFactory } from "../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { DocumentDataWizardStep } from "./invoices/documents/wizard/DocumentDataWizardStep";
import { IDocumentsProvider } from "../ProlifeSdk/interfaces/invoice/IDocumentsProvider";
import { IEntityRefKey } from "../ProlifeSdk/interfaces/invoice/IEntityRefInfo";
import { IDocumentPaymentInfo } from "../ProlifeSdk/interfaces/invoice/IRegisterDocument";
import { IRowExpiryChangesStatus } from "../ProlifeSdk/interfaces/invoice/IBusinessDocument";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IReferenceForMap, INodeDetails } from "../ProlifeSdk/interfaces/invoice/IReferencesMap";
import { IAjaxService, IAjaxServiceNew } from "../Core/interfaces/IAjaxService";
import { IDialogsService } from "../Core/interfaces/IDialogsService";
import { IService } from "../Core/interfaces/IService";
import { ISettingsServiceObserver, ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IDocumentLayout } from "../ProlifeSdk/interfaces/invoice/IDocumentLayout";
import { IRelatedDocument } from "../ProlifeSdk/interfaces/invoice/IRelatedDocument";
import { ISecondaryRecipient } from "../ProlifeSdk/interfaces/invoice/ISecondaryRecipient";
import { IDocumentJournalExtendedRow } from "../ProlifeSdk/interfaces/prolife-sdk/IDocumentsJournalRow";
import {
    IProtocolDefaultValueForProtocol,
    IVatRegister,
} from "../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IPaymentModes } from "../ProlifeSdk/interfaces/invoice/settings/IPaymentModes";
import { Deferred } from "../Core/Deferred";
import { IIvaModes } from "../ProlifeSdk/interfaces/invoice/settings/IIvaModes";
import {
    IDocumentBuilderDocument,
    IDocumentCurrenciesFactory,
    IDocumentDataSource,
    IDocumentsServiceObserver,
    INodeKey,
    IRowKey,
    ILeafReferenceInfo,
    IDocumentRowForPricesControl,
    IPricesValidation,
    IEntityKey,
    IRowLeafReference,
    IGetDocumentsRequest,
    IDocumentCommonData,
    IGetNextAvailableVersionNumberForDocumentRequest,
    IGetNextAvailableRevisionNumberForDocumentVersionRequest,
    IGetDocumentVersionNumbersSuggestionsRequest,
    IDocumentVersionNumberSuggestion,
    IDocumentVersionNumberInfo,
    IVatNatureCode,
    IVatRegulationCode,
    IWithholdingTaxType,
    IPaymentTypeCode,
    ICustomersDataForMonthlyInvoicing,
    IJobOrdersDataForMonthlyInvoicing,
    IDocumentCurrenciesEditorParams,
    IProtocolDefaults,
    DocumentResourceAssignmentsDetails,
} from "../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { AllocatedResourcesReportDialog } from "./invoices/AllocatedResourcesReport";
import { ResponseData } from "../Core/response/ResponseBase";
import { ResourcesAssignmentsReport } from "./invoices/ResourcesAssignmentsReport";
import { SendMailAction } from "./invoices/documents/SendMailAction";

export interface IDocumentBuilderDocumentWithOptions extends IDocumentBuilderDocument {
    IgnoreReferenceNumberDuplicate: boolean;
    TrustAuthorized: boolean;
    TrustAuthorizationId: string;
    TrustAuthorizedById: number;
    TrustAuthorizedByName: string;
    RebuildSchedulesAction: number;
    OnWorkflowOutcomeChangeAction: number;
    DocumentJournalSettings: IDocumentsJournalSetting;
    IgnoreNegativeStockAmount: boolean;
    SendMailAction: SendMailAction;
}

export interface IDocumentsJournalSetting {
    BeforeId: string;
    MovedId: string;
    AfterId: string;
}

export interface IReferenceType {
    RefId?: number;
    DestinationId?: number;
    DestinationEntityType?: string;
    CatalogId?: number;
    EstimatedBudgetForTaskId?: number;
    TaskId?: number;
    Id?: number;
    FKDocument?: number;
    EntityType?: string;
    Description?: string;
    Amount?: number;
    FKUoM?: number;
    UoM?: string;
    FKCurrency: number;
    Currency?: string;
    UnitPrice?: number;
    Discounts?: string;
    NetUnitPrice?: number;
    TotalPrice?: number;
    UnitPriceInDocumentCurrency?: number;
    NetUnitPriceInDocumentCurrency?: number;
    TotalPriceInDocumentCurrency?: number;
    WarehouseId?: number;
}

export interface IDocumentInfoFromRef {
    NomeRegistroIVA?: string;
    Id: number;
    Number?: string;
    Date: Date;
}

export interface IDocumentForExportDocument {
    Id: number;
    Date: Date;
    Number?: string;
    FullVersionRevisionNumber?: string;
    CompanyBusinessName?: string;
    CompanyVATNumber?: string;
    CompanyTAXNumber?: string;
    CompanyAddress?: string;
    CompanyPostalCode?: string;
    CompanyCity?: string;
    CompanyProvince?: string;
    CompanyState?: string;
    CompanyMunicipality?: string;
    CompanyPhone?: string;
    CompanyFax?: string;
    CompanyEMail?: string;
    CompanyWebsite?: string;
    CompanyCommercialRegister?: string;
    CompanyREA?: string;
    CompanyRegisteredCapital?: number;
    FKCustomer?: number;
    CustomerBusinessName?: string;
    CustomerLegalPerson: boolean;
    CustomerName?: string;
    CustomerSurname?: string;
    CustomerVATNumber?: string;
    CustomerTAXNumber?: string;
    CustomerAddress?: string;
    CustomerPostalCode?: string;
    CustomerCity?: string;
    CustomerMunicipality?: string;
    CustomerProvince?: string;
    CustomerState?: string;
    FKRecipient?: number;
    RecipientBusinessName?: string;
    FKRecipientOrganizationalUnit?: number;
    RecipientLegalPerson: boolean;
    RecipientName?: string;
    RecipientSurname?: string;
    RecipientAddress?: string;
    RecipientPostalCode?: string;
    RecipientCity?: string;
    RecipientMunicipality?: string;
    RecipientProvince?: string;
    RecipientState?: string;
    ExternalReference?: string;
    ReferenceDate?: Date;
    ReferenceNumber?: string;
    CIG?: string;
    CUP?: string;
    PaymentType?: string;
    PaymentIBAN?: string;
    PaymentABI?: string;
    PaymentCAB?: string;
    ExpiryType?: string;
    Notes?: string;
    AdministrativeNotes?: string;
    TaxableTotal: number;
    Vat: number;
    Total: number;
    NotTaxableTotal: number;
    WithholdingTax?: string;
    WithholdingTaxTotal: number;
    FinalTotal: number;
    IRPEFTax: number;
    IRPEFTaxDescription?: string;
    IRPEFTaxTotal: number;
    NonIRPEFTax: number;
    NonIRPEFTaxDescription?: string;
    NonIRPEFTaxTotal: number;
    FiscalDocumentType: number;
    Packages?: number;
    Cause?: string;
    Aspect?: string;
    Weight?: number;
    Carriage?: string;
    TransportStart?: Date;
    Transport?: string;
    ValidityType?: string;
    Blocked: boolean;
    HasWithholdingTax: boolean;
    FKJobOrder?: number;
    JobOrderName?: string;
    EstimateStateName?: string;
    CommercialResponsibleName?: string;
    AdministrativeResponsibleName?: string;
}

export interface IDocumentForExportRows {
    Id: number;
    EntityType: string;
    FKDocument: number;
    Description?: string;
    Amount: number;
    AmountFormula?: string;
    FKUoM?: number;
    UoM?: string;
    UnitPrice: number;
    Discounts?: string;
    NetUnitPrice: number;
    TotalPrice: number;
    FKVatCode?: number;
    VatCode?: string;
    Index?: number;
    Order: number;
    ClosedAmount: number;
    ManuallyClosed: boolean;
    DueDate?: Date;
    FKCurrency: number;
    Currency?: string;
    FKLetterOfAttempt?: number;
    LetterOfAttemptDescription?: string;
    FKOffset?: number;
    OffsetCode?: string;
    ExtendedDescription?: string;
    Workflows?: string;
}

export interface IDocumentForExport {
    Document: IDocumentForExportDocument[];
    Rows: IDocumentForExportRows[];
}

export interface IDefaultMetadatas {
    MetadataId: number;
    MetadataValueType: string;
    ShowMarkerOnAllocationsGantt: boolean;
    Order: number;
}

export interface IRowRefMapping {
    DestEntityKey: number;
    DestEntityType?: string;
    SourceEntityKey: number;
    SourceEntityType?: string;
}

export interface IWarehouseEntityStockInfo {
    DestEntityKey: number;
    DestEntityType: string;
    ArticleId?: number;
    ArticleDescription?: string;
    AvailableAmount?: number;
    StockAmount: number;
    ReservedAmount: number;
    OrderedAmount: number;
    MinimumAmount: number;
}

export interface IResourceOnDocumentAllocation {
    ResourceId: number;
    Surname?: string;
    Name?: string;
    StartDate?: Date;
    EndDate?: Date;
    DocumentId: number;
    DocumentType: string;
    DocumentDate: Date;
    DocumentNumber?: string;
    DocumentClosingState: number;
    DocumentStateId?: number;
    ExternalReference?: string;
    ReferenceDate?: Date;
    ReferenceNumber?: string;
    DocumentCauseId?: number;
    DocumentCauseLogicType?: number;
    DocumentProtocolId: number;
    DocumentProtocol?: string;
    JobOrderId?: number;
    JobOrder?: string;
    HoursAmount: number;
    WorkingHoursAmount?: number;
    StandardWorkingHoursAmount?: number;
    JobOrderMetadataId?: number;
    JobOrderMetadataName?: string;
    JobOrderTypeId?: number;
    JobOrderType?: string;
    JobOrderTypeIcon?: string;
    JobOrderTypeIconBackground?: string;
    JobOrderTypeIconForeground?: string;
    JobOrderMetadatCode?: string;
}

export interface IGetDocumentsForListRequest {
    registerId?: number;
    documentTypeId?: number;
    textFilter?: string;
    includeWithoutJobOrder?: boolean;
    jobOrderId?: number;
    customerId?: number;
    startDate?: Date;
    endDate?: Date;
    searchOnDocumentsContent?: boolean;
    skip?: number;
    count?: number;
}

export interface IDocumentForList {
    Id: number;
    EntityType: string;
    FKRegister: number;
    Date: Date;
    Number?: string;
    TotalInDocumentCurrency: number;
    Currency: string;
    FKCurrency: number;
    DocumentCurrencyId: number;
    CurrencyCode: string;
    ExchangeValue: number;
    ExchangeValueForVat?: number;
    HasWithholdingTax: boolean;
    BillingLogicalStatus: number;
    FKEStimateState?: number;
    Blocked: boolean;
    SchedulesStatus: number;
    ClosureStatus: number;
    CreditNote?: boolean;
    DownPayment: boolean;
    ForPartialInvoicesRegister: boolean;
    IsApproved?: boolean;
    CustomerName?: string;
    JobOrderName?: string;
    NumberPrintChoice: number;
    FullVersionRevisionNumber?: string;
    CauseLogicType?: number;
}

export interface IGetDocumentsRowsRequest {
    DocumentId?: number;
    DocumentType?: string;
    TextFilter?: string;
    GetFullyAssigned?: boolean;
    Skip?: number;
    Count?: number;
}

export interface ICommonRowData {
    Id: number;
    EntityType: string;
    DocumentId: number;
    Description?: string;
    Amount: number;
    UnitPriceInDocumentCurrency: number;
    NetUnitPriceInDocumentCurrency: number;
    TotalPriceInDocumentCurrency: number;
    UnitPrice: number;
    NetUnitPrice: number;
    TotalPrice: number;
    Index?: number;
    Order: number;
    Uom?: string;
    FKUoM?: number;
    Discounts?: string;
    DueDate?: Date;
    ClosedAmount: number;
    ManuallyClosed: boolean;
    AssignedAmountToWorkflows?: number;
    FKVatCode?: number;
    VatCode?: string;
    FKLetterOfAttempt?: number;
    LetterOfAttemptDescription?: string;
    FKOffset?: number;
    OffsetCode?: string;
    FKCurrency: number;
    Currency?: string;
    AmountFormula?: string;
}

export interface ITaxRelief {
    Id: number;
    Label: string;
    Description: string;
    Discount: number;
    Deleted: boolean;
}

export type SentMail = {
    documentId: number;
    sentAt: Date;
    sentBy: string;
    sentById: number;
    status: SentMailStatus;
};

export enum SentMailStatus {
    MailSendSuccess = 5,
    MailSendPartial = 6,
    MailSendError = 7,
}

export interface IDocumentsService extends IService {
    DocumentCurrenciesFactory: IDocumentCurrenciesFactory;

    isPassiveCycleDocument(documentType: string): boolean;
    getEditRightForDocument(documentType: string): string;

    getLastMailSent(documentId: number): Promise<SentMail | null>;
    getMailSentLog(documentId: number): Promise<SentMail[]>;
    sendDocumentMail(documentId: number, documentType: string, action: SendMailAction): Promise<void>;

    getRegisteredDocumentProviders(): IDocumentsProvider[];
    registerDocumentsProvider(provider: IDocumentsProvider): void;
    unregisterDocumentsProvider(provider: IDocumentsProvider): void;

    registerDocumentDataSource(dataSource: IDocumentDataSource): void;
    registerDataWizardStep(step: DocumentDataWizardStep<any>, ...documentTypes: string[]): void;
    getDataWizardStepsForDocumentType(documentType: string): DocumentDataWizardStep[];

    unregisterDocumentDataSource(mapper: (dataSource: IDocumentDataSource) => boolean): void;

    registerDocumentDataSourceForMonthlyInvoicing(dataSource: IDocumentDataSource): void;
    unregisterDocumentDataSourceForMonthlyInvoicing(mapper: (dataSource: IDocumentDataSource) => boolean): void;

    getDataSourcesForDocumentType(destinationDocumentEntityTypeCode: string): IDocumentDataSource[];
    getDataSourcesForMonthlyInvoicing(destinationDocumentEntityTypeCode: string): IDocumentDataSource[];
    GetBusinessDocumentLayouts(): IDocumentLayout[];

    RegisterObserver(o: IDocumentsServiceObserver): void;
    GetReferenceMap(documentId: number, documentType: string): Promise<IReferenceForMap[]>;
    GetReferencesMapNodeDetails(nodeId: number, nodeType: string): Promise<INodeDetails>;
    GetReferencesMapNodesDetails(nodes: INodeKey[]): Promise<INodeDetails[]>;

    GetLeafsReferencesInfoForRow(rowKey: IRowKey): Promise<ILeafReferenceInfo[]>;

    registerRowReferencesGeneratorFactory(f: IDocumentRowInlineReferenceProviderFactory);
    getRowReferencesGeneratorFactories(docType: string): IDocumentRowInlineReferenceProviderFactory[];
    getRowReferencesGeneratorFactoryForEntity(entityType: string): IDocumentRowInlineReferenceProviderFactory;

    getPricesValidationInfo(
        docType: string,
        docId: number,
        accountHolderId: number
    ): Promise<IDocumentRowForPricesControl[]>;
    getCurrentPricesValidation(docType: string, docId: number): Promise<IPricesValidation>;
    validatePricesForDocument(docType: string, docId: number, htmlText: string): Promise<void>;

    GetPaymentInfoFromRefDocumentRows(entities: IEntityRefKey[]): Promise<IDocumentPaymentInfo[]>;

    loadRowsExpiryChangesStatus(rowsKeys: IEntityRefKey[]): Promise<IRowExpiryChangesStatus[]>;

    getRelatedDocumentsFromRefDocumentRows(
        destDocumentId: number,
        destDocumentType: string,
        refsIds: IEntityRefKey[]
    ): Promise<IRelatedDocument[]>;
    getRelatedDocuments(documentType: string, documentId: number): Promise<IRelatedDocument[]>;
    loadSecondaryRecipients(documentType: string, documentId: number): Promise<ISecondaryRecipient[]>;

    getDocumentsNumberForProtocol(protocolId: number): Promise<number>;

    loadDocumentsJournal(date?: Date): Promise<IDocumentJournalExtendedRow[]>;

    getFakeId(): number;
    GetRowsLeafsReferences(rows: IEntityKey[] | null): Promise<IRowLeafReference[]>;
    GetDocumentsRowsByIds(Ids: IEntityKey[] | null): Promise<ICommonRowData[]>;
    GetDocuments(request: IGetDocumentsRequest): Promise<IDocumentCommonData[]>;
    GetDocumentsByIds(ids: number[] | null, entityTypes: string[] | null): Promise<IDocumentCommonData[]>;
    GetNextAvailableVersionNumberForDocument(
        request: IGetNextAvailableVersionNumberForDocumentRequest
    ): Promise<number>;
    GetNextAvailableRevisionNumberForDocumentVersion(
        request: IGetNextAvailableRevisionNumberForDocumentVersionRequest
    ): Promise<number>;
    GetDocumentVersionNumbersSuggestions(
        request: IGetDocumentVersionNumbersSuggestionsRequest
    ): Promise<IDocumentVersionNumberSuggestion[]>;
    GetDocumentVersionNumbersSuggestionsByDocumentIds(
        documentType: string | null,
        ids: number[] | null
    ): Promise<IDocumentVersionNumberSuggestion[]>;
    GetDocumentVersionNumber(documentId: number | null, entityType: string | null): Promise<IDocumentVersionNumberInfo>;

    GetVatNatureCodes(textFilter: string | null, skip: number | null, count: number | null): Promise<IVatNatureCode[]>;
    GetVatNatureCodesByIds(ids: string[] | null): Promise<IVatNatureCode[]>;
    GetVatRegulations(
        natureCode: string | null,
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IVatRegulationCode[]>;
    GetVatRegulationsByIds(ids: string[] | null): Promise<IVatRegulationCode[]>;
    GetWithholdingTaxTypes(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IWithholdingTaxType[]>;
    GetWithholdingTaxTypesByIds(codes: string[] | null): Promise<IWithholdingTaxType[]>;
    GetPaymentTypeCodes(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IPaymentTypeCode[]>;
    GetPaymentTypeCodesByIds(ids: string[] | null): Promise<IPaymentTypeCode[]>;
    GetCustomersForMonthlyInvoicingWizard(customersIds: number[] | null): Promise<ICustomersDataForMonthlyInvoicing>;
    GetJobOrdersForMonthlyInvoicingWizard(jobOrderIds: number[] | null): Promise<IJobOrdersDataForMonthlyInvoicing>;

    ShowDocumentCurrenciesEditingDialog(
        editorParams: IDocumentCurrenciesEditorParams,
        abortable?: boolean
    ): Promise<boolean>;
    GetProtocolDefaultValues(
        protocolId: number,
        customerId: number | null,
        jobOrderId: number | null,
        documentType: string
    ): Promise<IProtocolDefaultValueForProtocol[]>;
    ComputeDefaultsForDocument(
        protocolId: number,
        customerId: number | null,
        jobOrderId: number | null,
        documentType: string
    ): Promise<IProtocolDefaults>;
    createOrUpdate(document: IDocumentBuilderDocumentWithOptions): Promise<IDocumentBuilderDocumentWithOptions>;
    DeleteDocument(documentId: number, documentType: string): Promise<void>;
    OpenDocument(documentType: string, documentId: number, newPage?: boolean): void;
    OpenDocumentOverlay(documentType: string, documentId: number): Promise<void>;
    CreateNewDocumentOverlay(documentType: string, registerId: number, jobOrderId?: number): Promise<void>;
    OpenDocumentOverlayById(documentId: number): Promise<void>;
    OpenDocumentById(documentId: number, newPage?: boolean): void;
    GetOpenDocumentURL(documentType: string, documentId: number): string;

    NewDocument(documentType: string, registerId: number, newPage?: boolean): void;
    GetVatRegistersGroups(): Promise<IVatRegisterGroup[]>;
    GetDocumentsInfoFromRefDocumentRows(refs: IReferenceType[] | null): Promise<IDocumentInfoFromRef[]>;
    SetDocumentsState(stateId: number | null, documents: number[] | null): Promise<void>;
    GetDocumentsForListByIds(documentsIds: number[] | null): Promise<IDocumentForList[]>;
    ComputeDefaultMetadatas(
        protocolId: number | null,
        customerId: number | null,
        jobOrderId: number | null
    ): Promise<IDefaultMetadatas[]>;
    ShowResourcesOnDocumentsAllocation(): Promise<void>;
    ShowResourcesAssignmentsReport(): Promise<void>;
    GetWarehouseEntitiesStocksInfoOnRows(
        entities: IRowRefMapping[] | null,
        warehouseId: number | null
    ): Promise<IWarehouseEntityStockInfo[]>;
    GetAllocatedResourcesOnDocuments(from: Date | null, to: Date | null): Promise<IResourceOnDocumentAllocation[]>;
    GetDocumentsForList(request: IGetDocumentsForListRequest): Promise<IDocumentForList[]>;
    GetDocumentsRows(request: IGetDocumentsRowsRequest): Promise<ICommonRowData[]>;
    GetTaxRelief(textFilter: string | null, skip: number | null, count: number | null): Promise<ITaxRelief[]>;
    GetTaxReliefByIds(ids: number[] | null): Promise<ITaxRelief[]>;
    GetDocument(documentId: number | null, documentType: string | null): Promise<IDocumentBuilderDocument>;

    GetResourceDocumentsAssignments(
        resourceIds: number[],
        fromDate: Date,
        toDate: Date
    ): Promise<ResponseData<DocumentResourceAssignmentsDetails[]>>;
}

export interface IVatRegisterGroup {
    GroupLabel: string;
    VatRegisters: IVatRegister[];
}

export class DocumentsService implements IDocumentsService, ISettingsServiceObserver {
    public get DocumentCurrenciesFactory(): IDocumentCurrenciesFactory {
        return this.m_documentCurrenciesFactory;
    }

    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<ISettingsService>())
    private settingsService: ISettingsService;
    private registeredDocumentProviders: IDocumentsProvider[] = [];
    private documentsDataSources: IDocumentDataSource[] = [];
    private documentsDataSourcesForMonthlyInvoicing: IDocumentDataSource[] = [];

    private m_documentCurrenciesFactory: IDocumentCurrenciesFactory;

    private DocumentLayouts: IDocumentLayout[] = [];
    private observers: IDocumentsServiceObserver[] = [];

    private rowReferencesGeneratorFactories: IDocumentRowInlineReferenceProviderFactory[] = [];
    private documentTypesEditRights: { [documentType: string]: string } = {};

    private nextFakeId = 0;

    constructor(private serviceLocator: IServiceLocator) {
        this.serviceLocator.registerServiceInstance(this);
        this.serviceLocator.registerServiceInstanceWithName(nameof<IDocumentsService>(), this);

        this.m_documentCurrenciesFactory = new DocumentCurrenciesFactory();
        this.DocumentLayouts = [new StandardLayout(), new DetailedLayout(), new CompactDetailedLayout()];
        this.documentTypesEditRights = {
            DOC: "Documents_Invoices",
            DOA: "Documents_Invoices",
            NDC: "Documents_Invoices",
            EST: "Documents_Estimates",
            DDT: "Documents_DDTs",
            SAL: "Documents_Invoices",
            SOR: "Warehouse_SupplierOrder",
            WHL: "Warehouse_Load",
            RFQ: "Provisioning_RequestForQuotation",
            RDA: "Provisioning_PurchaseRequest",
            PDO: "Documents_PassiveInvoices",
        };
    }

    public getEditRightForDocument(documentType: string): string {
        return this.documentTypesEditRights[documentType];
    }

    public isPassiveCycleDocument(documentType: string): boolean {
        return (
            [
                ProlifeSdk.SupplierOrderEntityTypeCode,
                ProlifeSdk.WarehouseLoadEntityTypeCode,
                ProlifeSdk.RequestForQuotationEntityTypeCode,
                ProlifeSdk.PurchaseRequestEntityTypeCode,
            ].indexOf(documentType) >= 0
        );
    }

    public InitializeService(): void {
        this.settingsService.addObserver(this);
    }

    onSettingsLoaded(): void {
        this.getRegisteredDocumentProviders().forEach((p: IDocumentsProvider) => {
            p.RegisterDefaultValuesProvider(new ProtocolDefaultValuesProvider());
        });
    }

    onSettingsUpdated(updateType: string): void {}

    OpenDocument(documentType: string, documentId: number, newPage?: boolean): void {
        const url = this.GetOpenDocumentURL(documentType, documentId);
        if (newPage) window.open(url, "_blank");
        else location.href = url;
    }

    OpenDocumentOverlay(documentType: string, documentId: number): Promise<void> {
        const def = new Deferred<void>();

        const modal = $(`<div class="modal"></div>`);
        modal.css({ padding: "10px" });
        modal.appendTo(document.body);
        modal.modal({ backdrop: "static" });

        const doc = $(
            `<document params="DocumentId: DocumentId, DocumentType: DocumentType, Viewer: $data, InjectTo: OnTopDocument().viewModel, Listener: $data, Shadow: true"></document>`
        );
        doc.appendTo(modal);

        const docVM = ko.observable();
        const docModel = ko.observable({ viewModel: docVM });
        const vm = {
            DocumentId: documentId,
            DocumentType: documentType,
            OnTopDocument: docModel,
            EditingDocument: ko.observable(),
            OnClose: () => {
                modal.modal("hide");
                modal.remove();
                def.resolve();
            },
            OnEdit: () => {},
            OnEndEdit: () => {},
        };
        ko.applyBindings(vm, doc[0]);

        return def.promise();
    }

    OpenDocumentOverlayById(documentId: number): Promise<void> {
        return this.OpenDocumentOverlay(null, documentId);
    }

    CreateNewDocumentOverlay(documentType: string, registerId: number, jobOrderId?: number): Promise<void> {
        const def = new Deferred<void>();

        const modal = $(`<div class="modal"></div>`);
        modal.css({ padding: "10px" });
        modal.appendTo(document.body);
        modal.modal({ backdrop: "static" });

        const doc = $(
            `<document params="DocumentId: DocumentId, FKRegister: FKRegister, DocumentType: DocumentType, FKJobOrder: FKJobOrder, Viewer: $data, InjectTo: OnTopDocument().viewModel, Listener: $data, Shadow: true"></document>`
        );
        doc.appendTo(modal);

        const docVM = ko.observable();
        const docModel = ko.observable({ viewModel: docVM });
        const vm = {
            DocumentId: -1,
            FKRegister: registerId,
            DocumentType: documentType,
            FKJobOrder: jobOrderId ?? -1,
            OnTopDocument: docModel,
            EditingDocument: ko.observable(),
            OnClose: () => {
                modal.modal("hide");
                modal.remove();
                def.resolve();
            },
            OnEdit: () => {},
            OnEndEdit: () => {},
        };
        ko.applyBindings(vm, doc[0]);

        return def.promise();
    }

    OpenDocumentById(documentId: number, newPage?: boolean): void {
        const url = this.GetOpenDocumentByIdURL(documentId);
        if (newPage) window.open(url, "_blank");
        else location.href = url;
    }

    GetOpenDocumentURL(documentType: string, documentId: number): string {
        return String.format("#/" + TextResources.Invoices.OpenDocumentsURLv2, documentType, documentId);
    }

    GetOpenDocumentByIdURL(documentId: number): string {
        return String.format("#/" + TextResources.Invoices.OpenDocumentsByIdURLv2, documentId);
    }

    NewDocument(documentType: string, registerId: number, newPage?: boolean): void {
        const url = this.GetNewDocumentURL(documentType, registerId);
        if (newPage) window.open(url, "_blank");
        else location.href = url;
    }

    GetNewDocumentURL(documentType: string, registerId: number): string {
        return String.format("#/" + TextResources.Invoices.NewDocumentURLv2, documentType, registerId);
    }

    async getLastMailSent(documentId: number): Promise<SentMail | null> {
        try {
            return await this.tryGetLastMailSent(documentId);
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    private async tryGetLastMailSent(documentId: number) {
        const response = await this.ajaxServiceNew.Get<ResponseData<SentMail>>("d/mailing", documentId + "/sent/last", {
            methodData: { documentId: documentId },
        });

        if (!response.succeeded) throw response;

        return response.data;
    }

    async getMailSentLog(documentId: number): Promise<SentMail[]> {
        try {
            return await this.tryGetMailSentLog(documentId);
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    private async tryGetMailSentLog(documentId: number) {
        const response = await this.ajaxServiceNew.Get<ResponseData<SentMail[]>>("d/mailing", documentId.toString(), {
            methodData: { documentId: documentId },
        });

        if (!response.succeeded) throw response;

        return response.data ?? [];
    }

    public async sendDocumentMail(documentId: number, documentType: string, action: SendMailAction): Promise<void> {
        return await this.ajaxService.Post<void>("Documents-api/Document", "SendMail", {
            methodData: {
                DocumentId: documentId,
                DocumentType: documentType,
                SendMailAction: action,
            },
        });
    }

    public registerRowReferencesGeneratorFactory(f: IDocumentRowInlineReferenceProviderFactory) {
        this.rowReferencesGeneratorFactories.push(f);
    }

    public getRowReferencesGeneratorFactories(docType: string): IDocumentRowInlineReferenceProviderFactory[] {
        return this.rowReferencesGeneratorFactories.filter((f: IDocumentRowInlineReferenceProviderFactory) => {
            return f.SupportedDocTypes.indexOf(docType) > -1;
        });
    }

    public getRowReferencesGeneratorFactoryForEntity(entityType: string): IDocumentRowInlineReferenceProviderFactory {
        const matches = this.rowReferencesGeneratorFactories.filter((f: IDocumentRowInlineReferenceProviderFactory) => {
            return f.EntityType == entityType;
        });
        return matches.length > 0 ? matches[0] : null;
    }

    RegisterObserver(o: IDocumentsServiceObserver): void {
        this.observers.push(o);
    }

    registerDocumentDataSource(dataSource: IDocumentDataSource): void {
        this.documentsDataSources.push(dataSource);
    }

    private dataWizardSteps: { [documentType: string]: DocumentDataWizardStep<any>[] } = {};

    registerDataWizardStep(step: DocumentDataWizardStep<any>, ...documentTypes: string[]) {
        for (const documentType of documentTypes) {
            if (!this.dataWizardSteps[documentType]) this.dataWizardSteps[documentType] = [];
            this.dataWizardSteps[documentType].push(step);
        }
    }

    getDataWizardStepsForDocumentType(documentType: string): DocumentDataWizardStep[] {
        if (!this.dataWizardSteps[documentType]) return [];

        return this.dataWizardSteps[documentType].slice();
    }

    unregisterDocumentDataSource(mapper: (dataSource: IDocumentDataSource) => boolean): void {
        this.documentsDataSources.filter(mapper).forEach((ds) => {
            const index = this.documentsDataSources.indexOf(ds);
            this.documentsDataSources.splice(index, 1);
        });
    }

    getDataSourcesForDocumentType(destinationDocumentEntityTypeCode: string): IDocumentDataSource[] {
        const dataSources = this.documentsDataSources.filter(
            (s: IDocumentDataSource) =>
                s.SupportedDestinationDocumentsTypesCodes.indexOf(destinationDocumentEntityTypeCode) > -1 &&
                s.HasAuthorization()
        );
        dataSources.sort((a: IDocumentDataSource, b: IDocumentDataSource) => {
            return (
                a.GetMapperFor(destinationDocumentEntityTypeCode).PositionIntoFlow -
                b.GetMapperFor(destinationDocumentEntityTypeCode).PositionIntoFlow
            );
        });
        return dataSources;
    }

    registerDocumentDataSourceForMonthlyInvoicing(dataSource: IDocumentDataSource): void {
        this.documentsDataSourcesForMonthlyInvoicing.push(dataSource);
    }

    unregisterDocumentDataSourceForMonthlyInvoicing(mapper: (dataSource: IDocumentDataSource) => boolean): void {
        this.documentsDataSourcesForMonthlyInvoicing.filter(mapper).forEach((ds) => {
            const index = this.documentsDataSourcesForMonthlyInvoicing.indexOf(ds);
            this.documentsDataSourcesForMonthlyInvoicing.splice(index, 1);
        });
    }

    getDataSourcesForMonthlyInvoicing(destinationDocumentEntityTypeCode: string): IDocumentDataSource[] {
        const dataSources = this.documentsDataSourcesForMonthlyInvoicing.filter(
            (s: IDocumentDataSource) =>
                s.SupportedDestinationDocumentsTypesCodes.indexOf(destinationDocumentEntityTypeCode) > -1 &&
                s.HasAuthorization()
        );
        return dataSources;
    }

    getServiceType(): string {
        return ProlifeSdk.DocumentsServiceType;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    getRegisteredDocumentProviders(): IDocumentsProvider[] {
        return this.registeredDocumentProviders;
    }

    registerDocumentsProvider(provider: IDocumentsProvider): void {
        this.registeredDocumentProviders.push(provider);
        this.observers.forEach((o: IDocumentsServiceObserver) => {
            o.OnDocumentsProviderAdded(provider);
        });
    }

    unregisterDocumentsProvider(provider: IDocumentsProvider): void {
        const index = this.registeredDocumentProviders.indexOf(provider);
        if (index < 0) return;
        this.registeredDocumentProviders.splice(index, 1);
    }

    GetBusinessDocumentLayouts(): IDocumentLayout[] {
        return this.DocumentLayouts;
    }

    GetReferenceMap(documentId: number, documentType: string): Promise<IReferenceForMap[]> {
        return this.ajaxService.Post("Documents-api/ReferencesMap", "GetReferenceMap", {
            methodData: {
                NodeId: documentId,
                NodeType: documentType,
            },
        });
    }

    getPricesValidationInfo(
        docType: string,
        docId: number,
        accountHolderId: number
    ): Promise<IDocumentRowForPricesControl[]> {
        return this.ajaxService.Post("Documents-api/PricesValidation", "GetPricesValidationInfo", {
            methodData: {
                DocType: docType,
                DocId: docId,
                AccountholderId: accountHolderId,
            },
        });
    }

    getCurrentPricesValidation(docType: string, docId: number): Promise<IPricesValidation> {
        return this.ajaxService.Post("Documents-api/PricesValidation", "GetCurrentPricesValidation", {
            methodData: {
                DocType: docType,
                DocId: docId,
            },
        });
    }

    validatePricesForDocument(docType: string, docId: number, htmlText: string): Promise<void> {
        return this.ajaxService.Post("Documents-api/PricesValidation", "ValidatePricesForDocument", {
            methodData: {
                DocType: docType,
                DocId: docId,
                HtmlText: htmlText,
            },
        });
    }

    GetReferencesMapNodeDetails(nodeId: number, nodeType: string): Promise<INodeDetails> {
        return this.ajaxService.Post("Documents-api/ReferencesMap", "GetReferencesMapNodeDetails", {
            methodData: {
                NodeId: nodeId,
                NodeType: nodeType,
            },
        });
    }

    GetLeafsReferencesInfoForRow(rowKey: IRowKey): Promise<ILeafReferenceInfo[]> {
        return this.ajaxService.Post("Documents-api/ReferencesInfo", "GetLeafsReferencesInfoForRow", {
            methodData: {
                EntityKeyType: rowKey.EntityType,
                EntityKeyId: rowKey.EntityKeyId,
                CatalogId: 0, //Non mi serve
            },
        });
    }

    GetReferencesMapNodesDetails(nodes: INodeKey[]): Promise<INodeDetails[]> {
        return this.ajaxService.Post("Documents-api/ReferencesMap", "GetReferencesMapNodesDetails", {
            methodData: nodes,
        });
    }

    GetPaymentInfoFromRefDocumentRows(entities: IEntityRefKey[]): Promise<IDocumentPaymentInfo[]> {
        return this.ajaxService.Post("Documents-api/Document", "GetPaymentInfoFromRefDocumentRows", {
            methodData: {
                Entities: entities,
            },
        });
    }

    loadRowsExpiryChangesStatus(rowsKeys: IEntityRefKey[]): Promise<IRowExpiryChangesStatus[]> {
        return this.ajaxService.Post("Documents-api/Document", "LoadRowsExpiryChangesStatus", {
            methodData: {
                RowsKeys: rowsKeys,
            },
        });
    }

    getRelatedDocumentsFromRefDocumentRows(
        destDocumentId: number,
        destDocumentType: string,
        refsIds: IEntityRefKey[]
    ): Promise<IRelatedDocument[]> {
        return this.ajaxService.Post("Documents-api/Document", "GetRelatedDocumentsFromRefDocumentsRows", {
            methodData: {
                RefDocumentRowsIds: refsIds,
            },
        });
    }

    getRelatedDocuments(documentType: string, documentId: number): Promise<IRelatedDocument[]> {
        return this.ajaxService.Post("Documents-api/Document", "GetRelatedDocuments", {
            methodData: {
                DocumentType: documentType,
                DocumentId: documentId,
            },
        });
    }

    loadSecondaryRecipients(documentType: string, documentId: number): Promise<ISecondaryRecipient[]> {
        return this.ajaxService.Post("Documents-api/Document", "LoadSecondaryRecipients", {
            methodData: {
                DocumentType: documentType,
                DocumentId: documentId,
            },
        });
    }

    getDocumentsNumberForProtocol(protocolId: number): Promise<number> {
        return this.ajaxService.Post("Documents-api/Document", "GetDocumentsNumberForProtocol", {
            methodData: {
                ProtocolId: protocolId,
            },
        });
    }

    loadDocumentsJournal(date: Date = null): Promise<IDocumentJournalExtendedRow[]> {
        return this.ajaxService.Post("Documents-api/Document", "LoadDocumentsJournal", {
            methodData: {
                Date: date,
            },
        });
    }

    getFakeId(): number {
        return this.nextFakeId--;
    }

    GetRowsLeafsReferences(rows: IEntityKey[] | null): Promise<IRowLeafReference[]> {
        return this.ajaxService.Post<IRowLeafReference[]>("Invoices-api/Document", "GetRowsLeafsReferences", {
            background: true,
            methodData: {
                rows: rows,
            },
        });
    }

    GetDocumentsRowsByIds(Ids: IEntityKey[] | null): Promise<ICommonRowData[]> {
        return this.ajaxService.Post<ICommonRowData[]>("Invoices-api/Document", "GetDocumentsRowsByIds", {
            background: true,
            methodData: {
                Ids: Ids,
            },
        });
    }

    GetDocuments(request: IGetDocumentsRequest): Promise<IDocumentCommonData[]> {
        return this.ajaxService.Post<IDocumentCommonData[]>("Invoices-api/Document", "GetDocuments", {
            background: true,
            methodData: request,
        });
    }

    GetDocumentsByIds(ids: number[] | null, entityTypes: string[] | null): Promise<IDocumentCommonData[]> {
        return this.ajaxService.Post<IDocumentCommonData[]>("Invoices-api/Document", "GetDocumentsByIds", {
            background: true,
            methodData: {
                ids: ids,
                entityTypes: entityTypes,
            },
        });
    }

    GetNextAvailableVersionNumberForDocument(
        request: IGetNextAvailableVersionNumberForDocumentRequest
    ): Promise<number> {
        return this.ajaxService.Post<number>("Documents-api/Document", "GetNextAvailableVersionNumberForDocument", {
            background: true,
            methodData: request,
        });
    }

    GetNextAvailableRevisionNumberForDocumentVersion(
        request: IGetNextAvailableRevisionNumberForDocumentVersionRequest
    ): Promise<number> {
        return this.ajaxService.Post<number>(
            "Documents-api/Document",
            "GetNextAvailableRevisionNumberForDocumentVersion",
            {
                background: true,
                methodData: request,
            }
        );
    }

    GetDocumentVersionNumbersSuggestions(
        request: IGetDocumentVersionNumbersSuggestionsRequest
    ): Promise<IDocumentVersionNumberSuggestion[]> {
        return this.ajaxService.Post<IDocumentVersionNumberSuggestion[]>(
            "Documents-api/Document",
            "GetDocumentVersionNumbersSuggestions",
            {
                background: true,
                methodData: request,
            }
        );
    }

    GetDocumentVersionNumbersSuggestionsByDocumentIds(
        documentType: string | null,
        ids: number[] | null
    ): Promise<IDocumentVersionNumberSuggestion[]> {
        return this.ajaxService.Post<IDocumentVersionNumberSuggestion[]>(
            "Documents-api/Document",
            "GetDocumentVersionNumbersSuggestionsByDocumentIds",
            {
                background: true,
                methodData: {
                    documentType: documentType,
                    ids: ids,
                },
            }
        );
    }

    GetDocumentVersionNumber(
        documentId: number | null,
        entityType: string | null
    ): Promise<IDocumentVersionNumberInfo> {
        return this.ajaxService.Post<IDocumentVersionNumberInfo>("Documents-api/Document", "GetDocumentVersionNumber", {
            background: true,
            methodData: {
                documentId: documentId,
                entityType: entityType,
            },
        });
    }

    ShowDocumentCurrenciesEditingDialog(
        editorParams: IDocumentCurrenciesEditorParams,
        abortable: boolean = false
    ): Promise<boolean> {
        return this.dialogsService.ShowModalComponent(
            {
                componentName: "document-currencies-manager",
                model: {
                    DocumentCurrencies: editorParams.DocumentCurrencies,
                    ReadOnly: editorParams.ReadOnly,
                    HeaderMessage: editorParams.HeaderMessage || null,
                    CurrenciesEditor: ko.observable(),

                    close: function () {
                        if (!abortable) this.action();
                        else this.modal.close(false);
                    },

                    action: function () {
                        const editor = this.CurrenciesEditor();

                        if (!editor) return;

                        if (editor.validate()) this.modal.close(true);
                    },
                },
                title: TextResources.Invoices.CurrenciesLabel,
                params: "DocumentCurrencies: DocumentCurrencies, ReadOnly: ReadOnly, HeaderMessage: HeaderMessage, InjectTo: CurrenciesEditor",
            },
            undefined,
            { saveText: TextResources.ProlifeSdk.Apply, noPrompt: !abortable }
        );
    }

    GetProtocolDefaultValues(
        protocolId: number,
        customerId: number | null,
        jobOrderId: number | null,
        documentType: string
    ): Promise<IProtocolDefaultValueForProtocol[]> {
        const result = this.ajaxService.Post<IProtocolDefaultValueForProtocol[]>(
            "Invoices-api/Document",
            "GetProtocolDefaultValues",
            {
                background: true,
                methodData: {
                    ProtocolId: protocolId,
                    CustomerId: customerId,
                    JobOrderId: jobOrderId,
                    DocumentType: documentType,
                },
            }
        );
        return result;
    }

    private protocolDefaultsHandlers: {
        [key: string]: (
            protocolDefaults: IProtocolDefaults,
            setting: IProtocolDefaultValueForProtocol,
            allSettings: IProtocolDefaultValueForProtocol[]
        ) => Promise<any>;
    } = {
        Appearance: this.applyAppearanceDefault.bind(this),
        PaymentMode: this.applyPaymentModeDefault.bind(this),
        IVA: this.applyIVADefault.bind(this),
        Cause: this.applyCauseDefault.bind(this),
        Transport: this.applyTransportDefault.bind(this),
        ExpireMode: this.applyExpireDefault.bind(this),
        DdtPorto: this.applyPortDefault.bind(this),
        Offset: this.applyOffsetDefault.bind(this),
        Outcome: this.applyOutcomeDefault.bind(this),
        Currency: this.applyCurrencyDefault.bind(this),
        DocumentNumberPrintChoice: this.applyDocumentNumberPrintChoiceDefault.bind(this),
        VersionNumberGenerationMode: this.applyVersionNumberGenerationModeDefault.bind(this),
        ShowExternalReferimentOnFinalDocument: this.applyShowExternalReferenceOnFinalDocumentDefault.bind(this),
        ShowCustomerOrderReferimentOnFinalDocument:
            this.applyShowCustomerOrderReferenceOnFinalDocumentDefault.bind(this),
        SendDocumentMail: this.applyAutomaticMailSendDefault.bind(this),
        IncludeDocumentAttachmentsInMail: this.applyIncludeDocumentAttachmentsInMailDefault.bind(this),
    };

    private async applyAppearanceDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKAspect = setting.IntValue;
    }

    private async applyPaymentModeDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKPaymentType = setting.IntValue;
        result.PaymentInfoSource = setting.Source;

        if (!result.FKPaymentType) return;

        const paymentModes = <IPaymentModes>this.settingsService.findSettingsManager(ProlifeSdk.PaymentMode);
        const paymentMode = paymentModes
            .getPaymentModes(true)
            .firstOrDefault((p) => p.IdTipoPagamento == result.FKPaymentType);

        if (!paymentMode || paymentMode.AssociaBanca == 0) return;

        if (paymentMode.AssociaBanca == 2) {
            const account = allSettings.firstOrDefault((p) => p.SettingKey == "PaymentAccount");
            result.PaymentIBAN = account?.StringValue;
        } else if (paymentMode.AssociaBanca == 1) {
            const abi = allSettings.firstOrDefault((p) => p.SettingKey == "PaymentABI");
            const cab = allSettings.firstOrDefault((p) => p.SettingKey == "PaymentCAB");

            result.PaymentABI = abi?.StringValue;
            result.PaymentCAB = cab?.StringValue;
        }
    }

    private async applyIVADefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKVatCode = setting.IntValue;

        const vatCodes = <IIvaModes>this.settingsService.findSettingsManager(ProlifeSdk.IvaModes);
        result.VatCode = vatCodes.getIvaModes().firstOrDefault((i) => i.IdTipoIVA === setting.IntValue)?.CodiceIVA;
    }

    private async applyCauseDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKCause = setting.IntValue;
    }

    private async applyTransportDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKTransport = setting.IntValue;
    }

    private async applyExpireDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKExpiryType = setting.IntValue;
        result.ExpireInfoSource = setting.Source;
    }

    private async applyPortDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKCarriage = setting.IntValue;
    }

    private async applyOffsetDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        const offsets = <IOffsetsService>this.serviceLocator.findService(nameof<IOffsetsService>());
        const offset = await offsets.GetOffsetsLeafByIds([setting.IntValue]);

        if (offset.length > 0) {
            result.FKOffset = offset[0].Id;
            result.OffsetCode = offset[0].Code;
        }
    }

    private async applyOutcomeDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.FKOutcome = setting.IntValue;
    }

    private async applyCurrencyDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        const currencies = <ICurrenciesSettingsManager>(
            this.settingsService.findSettingsManager(nameof<ICurrenciesSettingsManager>())
        );
        const defaultCurrency = currencies.getDefaultCurrency();
        const currencyId = setting.IntValue;

        result.FKCurrency = currencyId ?? defaultCurrency.Id;
        result.FKDefaultCurrency = defaultCurrency.Id;
    }

    private async applyDocumentNumberPrintChoiceDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.NumberPrintChoice = setting.IntValue;
    }

    private async applyVersionNumberGenerationModeDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.VersionNumberGenerationMode = setting.IntValue;
    }

    private async applyShowExternalReferenceOnFinalDocumentDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.ShowExternalReferenceOnFinalDocument = setting.IntValue !== 0;
    }

    private async applyShowCustomerOrderReferenceOnFinalDocumentDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.ShowCustomerOrderReferenceOnFinalDocument = setting.IntValue !== 0;
    }

    private async applyAutomaticMailSendDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.SendDocumentMail = setting.IntValue !== 0;
    }

    private async applyIncludeDocumentAttachmentsInMailDefault(
        result: IProtocolDefaults,
        setting: IProtocolDefaultValueForProtocol,
        allSettings: IProtocolDefaultValueForProtocol[]
    ) {
        result.IncludeDocumentAttachmentsInMail = setting.IntValue !== 0;
    }

    async ComputeDefaultsForDocument(
        protocolId: number,
        customerId: number | null,
        jobOrderId: number | null,
        documentType: string
    ): Promise<IProtocolDefaults> {
        const defaults = await this.GetProtocolDefaultValues(protocolId, customerId, jobOrderId, documentType);
        const result: IProtocolDefaults = <IProtocolDefaults>{};

        if (!defaults.any((d) => d.SettingKey == "Currency"))
            defaults.push({
                SettingKey: "Currency",
                ProtocolId: "",
                StringValue: null,
                IntValue: null,
                DecimalValue: null,
                DateValue: null,
                Source: "",
            });

        const promises: Promise<any>[] = [];

        for (const defaultValue of defaults) {
            const handler = this.protocolDefaultsHandlers[defaultValue.SettingKey];
            if (handler) promises.push(handler(result, defaultValue, defaults));
        }

        await Promise.all(promises);

        return result;
    }

    createOrUpdate(document: IDocumentBuilderDocumentWithOptions): Promise<IDocumentBuilderDocumentWithOptions> {
        const result = this.ajaxService.Post<IDocumentBuilderDocumentWithOptions>(
            "Invoices-api/Document",
            "CreateOrUpdate",
            {
                background: true,
                methodData: document,
            }
        );

        return result;
    }

    DeleteDocument(documentId: number, documentType: string): Promise<void> {
        const result = this.ajaxService.Post<void>("Invoices-api/Document", "DeleteDocument", {
            background: true,
            methodData: {
                documentId: documentId,
                documentType: documentType,
            },
        });

        return result;
    }

    GetVatNatureCodes(textFilter: string | null, skip: number | null, count: number | null): Promise<IVatNatureCode[]> {
        const result = this.ajaxService.Post<IVatNatureCode[]>("Invoices-api/VatNatureCode", "GetVatNatureCodes", {
            background: true,
            methodData: {
                textFilter: textFilter,
                skip: skip,
                count: count,
            },
        });

        return result;
    }

    GetVatNatureCodesByIds(ids: string[] | null): Promise<IVatNatureCode[]> {
        const result = this.ajaxService.Post<IVatNatureCode[]>("Invoices-api/VatNatureCode", "GetVatNatureCodesByIds", {
            background: true,
            methodData: {
                ids: ids,
            },
        });

        return result;
    }

    GetVatRegulations(
        natureCode: string | null,
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IVatRegulationCode[]> {
        const result = this.ajaxService.Post<IVatRegulationCode[]>("Invoices-api/VatRegulations", "GetVatRegulations", {
            background: true,
            methodData: {
                natureCode: natureCode,
                textFilter: textFilter,
                skip: skip,
                count: count,
            },
        });

        return result;
    }

    GetVatRegulationsByIds(ids: string[] | null): Promise<IVatRegulationCode[]> {
        const result = this.ajaxService.Post<IVatRegulationCode[]>(
            "Invoices-api/VatRegulations",
            "GetVatRegulationsByIds",
            {
                background: true,
                methodData: {
                    ids: ids,
                },
            }
        );

        return result;
    }

    GetWithholdingTaxTypes(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IWithholdingTaxType[]> {
        const result = this.ajaxService.Post<IWithholdingTaxType[]>(
            "Invoices-api/WithholdingTaxType",
            "GetWithholdingTaxTypes",
            {
                background: true,
                methodData: {
                    textFilter: textFilter,
                    skip: skip,
                    count: count,
                },
            }
        );

        return result;
    }

    GetWithholdingTaxTypesByIds(codes: string[] | null): Promise<IWithholdingTaxType[]> {
        const result = this.ajaxService.Post<IWithholdingTaxType[]>(
            "Invoices-api/WithholdingTaxType",
            "GetWithholdingTaxTypesByIds",
            {
                background: true,
                methodData: {
                    codes: codes,
                },
            }
        );

        return result;
    }

    GetPaymentTypeCodes(
        textFilter: string | null,
        skip: number | null,
        count: number | null
    ): Promise<IPaymentTypeCode[]> {
        const result = this.ajaxService.Post<IPaymentTypeCode[]>(
            "Invoices-api/PaymentTypeCode",
            "GetPaymentTypeCodes",
            {
                background: true,
                methodData: {
                    textFilter: textFilter,
                    skip: skip,
                    count: count,
                },
            }
        );

        return result;
    }

    GetPaymentTypeCodesByIds(ids: string[] | null): Promise<IPaymentTypeCode[]> {
        const result = this.ajaxService.Post<IPaymentTypeCode[]>(
            "Invoices-api/PaymentTypeCode",
            "GetPaymentTypeCodesByIds",
            {
                background: true,
                methodData: {
                    ids: ids,
                },
            }
        );

        return result;
    }

    GetCustomersForMonthlyInvoicingWizard(customersIds: number[] | null): Promise<ICustomersDataForMonthlyInvoicing> {
        const result = this.ajaxService.Post<ICustomersDataForMonthlyInvoicing>(
            "Invoices-api/Document",
            "GetCustomersForMonthlyInvoicingWizard",
            {
                background: true,
                methodData: {
                    customersIds: customersIds,
                },
            }
        );

        return result;
    }

    GetJobOrdersForMonthlyInvoicingWizard(jobOrderIds: number[] | null): Promise<IJobOrdersDataForMonthlyInvoicing> {
        const result = this.ajaxService.Post<IJobOrdersDataForMonthlyInvoicing>(
            "Invoices-api/Document",
            "GetJobOrdersForMonthlyInvoicingWizard",
            {
                background: true,
                methodData: {
                    jobOrderIds: jobOrderIds,
                },
            }
        );

        return result;
    }

    GetVatRegistersGroups(): Promise<IVatRegisterGroup[]> {
        return this.ajaxService.Post<IVatRegisterGroup[]>("Invoices-api/VatRegister", "GetVatRegistersGroups", {});
    }

    GetDocumentsInfoFromRefDocumentRows(refs: IReferenceType[] | null): Promise<IDocumentInfoFromRef[]> {
        const result = this.ajaxService.Post<IDocumentInfoFromRef[]>(
            "Invoices-api/Document",
            "GetDocumentsInfoFromRefDocumentRows",
            {
                background: true,
                methodData: {
                    refs: refs,
                },
            }
        );

        return result;
    }

    SetDocumentsState(stateId: number | null, documents: number[] | null): Promise<void> {
        const result = this.ajaxService.Post<void>("Documents-api/Document", "SetDocumentsState", {
            background: true,
            methodData: {
                stateId: stateId,
                documents: documents,
            },
        });

        return result;
    }

    GetDocumentsForListByIds(documentsIds: number[] | null): Promise<IDocumentForList[]> {
        const result = this.ajaxService.Post<IDocumentForList[]>("Invoices-api/Document", "GetDocumentsForListByIds", {
            background: true,
            methodData: {
                documentsIds: documentsIds,
            },
        });

        return result;
    }

    ComputeDefaultMetadatas(
        protocolId: number | null,
        customerId: number | null,
        jobOrderId: number | null
    ): Promise<IDefaultMetadatas[]> {
        const result = this.ajaxService.Post<IDefaultMetadatas[]>("Invoices-api/Document", "ComputeDefaultMetadatas", {
            background: true,
            methodData: {
                protocolId: protocolId,
                customerId: customerId,
                jobOrderId: jobOrderId,
            },
        });

        return result;
    }

    ShowResourcesOnDocumentsAllocation(): Promise<void> {
        const dialog = new AllocatedResourcesReportDialog();
        return dialog.show();
    }

    ShowResourcesAssignmentsReport(): Promise<void> {
        const dialog = new ResourcesAssignmentsReport();
        return dialog.show();
    }

    GetWarehouseEntitiesStocksInfoOnRows(
        entities: IRowRefMapping[] | null,
        warehouseId: number | null
    ): Promise<IWarehouseEntityStockInfo[]> {
        const result = this.ajaxService.Post<IWarehouseEntityStockInfo[]>(
            "Invoices-api/Document",
            "GetWarehouseEntitiesStocksInfoOnRows",
            {
                background: true,
                methodData: {
                    entities: entities,
                    warehouseId: warehouseId,
                },
            }
        );

        return result;
    }

    GetAllocatedResourcesOnDocuments(from: Date | null, to: Date | null): Promise<IResourceOnDocumentAllocation[]> {
        const result = this.ajaxService.Post<IResourceOnDocumentAllocation[]>(
            "Invoices-api/Document",
            "GetAllocatedResourcesOnDocuments",
            {
                background: true,
                methodData: {
                    from: from,
                    to: to,
                },
            }
        );

        return result;
    }

    GetDocumentsForList(request: IGetDocumentsForListRequest): Promise<IDocumentForList[]> {
        const result = this.ajaxService.Post<IDocumentForList[]>("Invoices-api/Document", "GetDocumentsForList", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetDocumentsRows(request: IGetDocumentsRowsRequest): Promise<ICommonRowData[]> {
        const result = this.ajaxService.Post<ICommonRowData[]>("Invoices-api/Document", "GetDocumentsRows", {
            background: true,
            methodData: request,
        });

        return result;
    }

    GetTaxRelief(textFilter: string | null, skip: number | null, count: number | null): Promise<ITaxRelief[]> {
        const result = this.ajaxService.Post<ITaxRelief[]>("Invoices-api/TaxRelief", "GetTaxRelief", {
            background: true,
            methodData: {
                textFilter: textFilter,
                skip: skip,
                count: count,
            },
        });

        return result;
    }

    GetTaxReliefByIds(ids: number[] | null): Promise<ITaxRelief[]> {
        const result = this.ajaxService.Post<ITaxRelief[]>("Invoices-api/TaxRelief", "GetTaxReliefByIds", {
            background: true,
            methodData: {
                ids: ids,
            },
        });

        return result;
    }

    GetDocument(documentId: number | null, documentType: string | null): Promise<IDocumentBuilderDocument> {
        const result = this.ajaxService.Post<IDocumentBuilderDocument>("Invoices-api/Document", "GetDocument", {
            background: true,
            methodData: {
                documentId: documentId,
                documentType: documentType,
            },
        });

        return result;
    }

    GetResourceDocumentsAssignments(
        resourceIds: number[],
        fromDate: Date,
        toDate: Date
    ): Promise<ResponseData<DocumentResourceAssignmentsDetails[]>> {
        return this.ajaxServiceNew.Post<ResponseData<DocumentResourceAssignmentsDetails[]>>(
            "d/documentsResources",
            "documentsAssignments",
            {
                methodData: {
                    resourceIds: resourceIds,
                    fromDate: fromDate,
                    toDate: toDate,
                },
            }
        );
    }
}

export default function Create(serviceLocator: IServiceLocator): IService {
    return new DocumentsService(serviceLocator);
}
