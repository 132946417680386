import * as ko from "knockout";
import "wcolpick";
import "wcolpick/wcolpick/wcolpick.css";
import jss from "jss";

jss.createStyleSheet({
    "@global": {
        ".prolife-color-picker-preview-bg": {
            backgroundColor: "white",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 2'%3E%3Cpath fill='rgb(204,204,204)' fill-rule='evenodd' d='M0 0h1v1H0V0zm1 1h1v1H1V1z'/%3E%3C/svg%3E")`,
            backgroundSize: "10px 10px",
            width: "1.429em",
            height: "1.429em",
            display: "inline-block",
            marginRight: "5px",
            fontSize: "14px",
        },

        "div.wcolpick": {
            "-webkit-border-radius": "7.5px !important",
            "-moz-border-radius": "7.5px !important",
            "border-radius": "7.5px !important",

            "& div.wcolpick_submit": {
                "-webkit-border-radius": "50% !important",
                "-moz-border-radius": "50% !important",
                "border-radius": "50% !important",

                "& div.wcolpick_tear": {
                    "-webkit-border-radius": "80% 0 55% 50% / 55% 0 80% 50% !important",
                    "-moz-border-radius": "80% 0 55% 50% / 55% 0 80% 50% !important",
                    "border-radius": "80% 0 55% 50% / 55% 0 80% 50% !important",
                },
            },

            "& div.wcolpick_field, & div.wcolpick_hex_field": {
                "-webkit-border-radius": "3px !important",
                "-moz-border-radius": "3px !important",
                "border-radius": "3px !important",
            },
        },
    },
}).attach();

export class ColorPicker {
    init(
        element: any,
        valueAccessor: () => any,
        allBindingsAccessor: ko.AllBindings,
        viewModel: any,
        bindingContext: ko.BindingContext
    ): void {
        const colorPickerAllowAlpha = ko.unwrap(
            (allBindingsAccessor.has("colorPickerAllowAlpha") && allBindingsAccessor.get("colorPickerAllowAlpha")) ??
                false
        );

        const disableBinding = allBindingsAccessor.has("disable") ? allBindingsAccessor.get("disable") : null;

        const defaultOptions = {
            alphaOutline: true,
            appendToBody: true,
            arrowsColor: "default",
            border: 1,
            checkersColor: "default",
            color: { h: 0, s: 0, b: 20, a: 1 },
            colorOutline: true,
            colorScheme: "light-full",
            colorSelOutline: true,
            compactLayout: false,
            enableAlpha: true,
            enableSubmit: true,
            fieldsBackground: "default",
            flat: false,
            hueOutline: true,
            layout: "full",
            livePreview: true,
            polyfill: false,
            position: "auto",
            readonlyFields: false,
            readonlyHexField: false,
            showEvent: "click",
            submitBackground: "default",
            submitColor: "default",
            variant: "standard",
        };

        const colorField: ko.Observable<string> = valueAccessor()["colorField"];
        let options: ko.Observable<string> = valueAccessor()["options"] || {};

        options = $.extend(false, defaultOptions, options);

        $(element).attr("type", "hidden");
        if (!colorField()) colorField("#000000");
        $(element).val(colorField());

        const markup = $(
            '<div class="input-group prolife-color-picker"><div class="input-group-btn"><button type="button" class="btn btn-default color-picker-button"><span class="prolife-color-picker-preview-bg"><span class="prolife-color-picker-preview"></span></span><span class="caret"></span></button></div></div>'
        );
        markup.insertAfter(element);

        const rgbaToString = ({ r, g, b, a }: { r: number; g: number; b: number; a: number }) => {
            return `rgba(${r},${g},${b},${a})`;
        };

        const events = {
            onLoaded: (ev) => {
                $(ev.el).find(".prolife-color-picker-preview").css("background-color", colorField());

                let color = colorField();
                if (color.startsWith("rgba")) {
                    color = (<any>$).wcolpick.decodeFromCSS(color);
                }

                (<any>$(ev.el)).setColor(color, false);
            },
            onChange: (ev) => {
                if (colorPickerAllowAlpha) {
                    $(ev.el).find(".prolife-color-picker-preview").css("background-color", rgbaToString(ev.rgba));
                } else {
                    $(ev.el)
                        .find(".prolife-color-picker-preview")
                        .css("background-color", "#" + ev.hex);
                }
                //colorField("#" + ev.hex);
            },
            onHide: (ev) => {
                if (colorPickerAllowAlpha) {
                    let rgba = (<any>$(ev.el)).getColor("rgba", false);
                    $(ev.el).find(".prolife-color-picker-preview").css("background-color", rgbaToString(rgba));
                    colorField(rgbaToString(rgba));
                    (<any>$(ev.el)).setColor(rgba, true);
                } else {
                    let hex = (<any>$(ev.el)).getColor("hex", false);
                    $(ev.el)
                        .find(".prolife-color-picker-preview")
                        .css("background-color", "#" + hex);
                    colorField("#" + hex);
                    (<any>$(ev.el)).setColor(hex, true);
                }
            },
            onSubmit: (ev) => {
                (<any>$(ev.colorDiv)).hide();
            },
        };

        options = $.extend(false, options, events);

        const picker = markup.find(".color-picker-button");
        (<any>picker).loads(options);

        if (ko.unwrap(disableBinding)) {
            picker.attr("disabled", "disabled");
        }

        const subscriptions = [];

        if (ko.isSubscribable(disableBinding)) {
            subscriptions.push(
                disableBinding.subscribe((value: boolean) => {
                    if (value) {
                        picker.attr("disabled", "disabled");
                    } else {
                        picker.removeAttr("disabled");
                    }
                })
            );
        }

        subscriptions.push(
            colorField.subscribe((value: string) => {
                let color = value;

                if ((value ?? "").startsWith("rgba")) {
                    color = (<any>$).wcolpick.decodeFromCSS(color);
                }

                (<any>$(picker)).setColor(color, false);
            })
        );

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscriptions.forEach((s) => s.dispose());
            (<any>picker).destroys();
        });
    }
}

ko.bindingHandlers["colorPicker"] = new ColorPicker();
