import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { SelectableMovementArticle } from "../../ui/Movements/SelectableMovementArticle";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import {
    IDocumentDataWizardRow,
    ProcessedRow,
} from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import {
    IDocumentBuilderDocumentOriginatingRows,
    IDocumentBuilderDocumentRows,
} from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { ITodoListService } from "../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IDiscountsManager, IDiscountsService } from "../../../../ProlifeSdk/interfaces/warehouse/IDiscountsService";
import {
    IStockService,
    IStockArticle,
    IStockArticleV2,
} from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { IWarehousesService } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import {
    DiscountCatalogRowMode,
    IDiscountsCatalogsService,
    IResolvedCustomerDiscountCatalogArticleWithCatalog,
} from "../../../DiscountsCatalogsService";
import { SegmentedInput } from "../../../../Components/SegmentedInput";
import { If } from "../../../../Components/IfIfNotWith";
import jss from "jss";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import moment = require("moment");

const { classes } = jss
    .createStyleSheet({
        "wizard-toolbar": {
            "& .form-group": {
                margin: 0,
            },

            "& .left-toolbar": {
                "& .ref-date": {
                    "& > .form-group": {
                        width: "140px",
                    },
                },
            },
        },
    })
    .attach();

type WarehouseEntity = "WAR" | "WPC";

export class WarehouseStockDataSource extends DocumentDataWizardStep<SelectableMovementArticle> {
    public StockArticles: ko.ObservableArray<SelectableMovementArticle> = ko.observableArray([]);
    public AllSelected: ko.Computed<boolean>;
    public SelectedArticles: ko.Computed<SelectableMovementArticle[]>;
    public IsDefaultWarehouse: ko.Observable<boolean> = ko.observable(false);
    public ReferenceDate: ko.Observable<Date> = ko.observable();
    public OnlyPositiveStock: ko.Observable<boolean> = ko.observable(true);
    public UnloadingMode: ko.Observable<"unloading" | "adjustment"> = ko.observable("unloading");
    public EntityType: ko.Observable<WarehouseEntity> = ko.observable(ProlifeSdk.WarehouseArticleEntityTypeCode);

    private discountsManagerForCustomer: IDiscountsManager = null;

    @LazyImport(nameof<IStockService>())
    private stockService: IStockService;
    @LazyImport(nameof<IWarehousesService>())
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    @LazyImport(nameof<IDiscountsService>())
    private discountsService: IDiscountsService;
    @LazyImport(nameof<IDiscountsCatalogsService>())
    private discountsCatalogsService: IDiscountsCatalogsService;
    @LazyImport(nameof<IWarehousesService>())
    private warehouseService: IWarehousesService;

    public WorkflowName: ko.Observable<string> = ko.observable();
    public WorkflowId: ko.Observable<number> = ko.observable();
    previousStepRows: IDocumentDataWizardRow[];

    constructor() {
        super();
        this.Title(TextResources.Warehouse.WarehouseInventory);

        this.SelectedArticles = ko.computed(() => {
            return this.StockArticles().filter((r: SelectableMovementArticle) => r.Selected());
        });

        this.AllSelected = ko.computed({
            read: function () {
                return this.SelectedArticles().length == this.StockArticles().length;
            },
            write: function (selected: boolean) {
                this.StockArticles().forEach((r: SelectableMovementArticle) => r.Selected(selected));
            },
            owner: this,
        });

        this.documentsService.registerDataWizardStep(
            this,
            ProlifeSdk.DdtEntityTypeCode,
            ProlifeSdk.WarehouseLoadEntityTypeCode
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    private applyDiscount(discount: IResolvedCustomerDiscountCatalogArticleWithCatalog) {
        const finalPrice = discount.UnitPrice + (discount.TotalDiscount / 100) * discount.UnitPrice;

        if (DiscountCatalogRowMode.getDiscountSign(discount.Mode) > 0) {
            //E' un ricarico quindi non mostro il ricarico nel documento
            return {
                unitPrice: discount.UnitPrice,
                discounts: undefined,
                netUnitPrice: finalPrice,
            };
        } else {
            const discountString = (
                (discount.Discount0 > 0 ? discount.Discount0 + "% " : "") +
                (discount.Discount1 > 0 ? discount.Discount1 + "% " : "") +
                (discount.Discount2 > 0 ? discount.Discount2 + "% " : "") +
                (discount.Discount3 > 0 ? discount.Discount3 + "% " : "") +
                (discount.Discount4 > 0 ? discount.Discount4 + "% " : "")
            ).trim();

            return {
                unitPrice: discount.UnitPrice,
                discounts: discountString,
                netUnitPrice: finalPrice,
            };
        }
    }

    async OnNext(): Promise<IDocumentDataWizardRow[]> {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();
        // const sign = this.mustUseCosts() && this.initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol ? -1 : 1; Rimosso il 04/08/2022, Task: 97553

        const articleIds = this.Rows()
            .map((r) => r.getArticleId())
            .distinct();

        const discounts = await this.discountsCatalogsService.ResolveCustomerDiscountCatalogsForArticles({
            articleIds,
            customerId: this.initializationInfo.CustomerId,
            isCustomer: this.initializationInfo.IsCustomer,
            overrideGroupId: this.initializationInfo.OverrideDiscountGroupId,
            referenceDate: this.initializationInfo.DocumentDate,
            warehouseId: this.initializationInfo.SourceWarehouseId,
        });

        const unloadingMode = this.UnloadingMode() === "unloading";

        const newRows = this.Rows().map((r) => {
            const discount: IResolvedCustomerDiscountCatalogArticleWithCatalog = discounts.firstOrDefault(
                (d) => d.ArticleId === r.getArticleId()
            ) ?? {
                Id: 0,
                Code: "",
                FkDiscountCatalog: null,
                ArticleId: r.ArticleId(),
                Discount0: 0,
                Discount1: 0,
                Discount2: 0,
                Discount3: 0,
                Discount4: 0,
                ExplicitPrice: null,
                Mode: 4, //Sconto su prezzo cliente
                TotalDiscount: 0,
                UnitPrice: r.UnitPrice() ?? 0,
            };

            const computedDiscount = this.applyDiscount(discount);
            const originalRow = r.getArticle() as IStockArticle;
            const originalAmount = originalRow.StockAmount;
            const newAmount = unloadingMode ? r.Amount() : r.Amount() - originalAmount;

            const newDocumentRow = {
                Id: 0,
                AmountFormula: null,
                // Amount: r.Amount() * sign,
                Amount: newAmount,
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                UnitPrice: computedDiscount.unitPrice,
                UnitPriceInDocumentCurrency: computedDiscount.unitPrice.ToCurrency(documentCurrency),
                NetUnitPrice: computedDiscount.netUnitPrice,
                NetUnitPriceInDocumentCurrency: computedDiscount.netUnitPrice.ToCurrency(documentCurrency),
                // TotalPrice: computedDiscount.netUnitPrice * r.Amount() * sign,
                TotalPrice: computedDiscount.netUnitPrice * newAmount,
                // TotalPriceInDocumentCurrency: (computedDiscount.netUnitPrice * r.Amount()).ToCurrency(documentCurrency) * sign,
                TotalPriceInDocumentCurrency: (computedDiscount.netUnitPrice * newAmount).ToCurrency(documentCurrency),
                Description: r.Description(),
                Discounts: computedDiscount.discounts,
                UoM: r.UOM(),
                Order: 0,
            };

            return {
                Row: new ProcessedRow(newDocumentRow, documentCurrency),
                IsSelected: ko.observable(),
                OriginatingRows: this.getReferences(r, newDocumentRow),
                RelatedWorkflows: [],
                SourceRows: [],
            };
        });

        return this.previousStepRows.concat(newRows.filter((r) => unloadingMode || r.Row.Amount() !== 0));
    }

    private getReferences(
        r: SelectableMovementArticle,
        newDocumentRow: IDocumentBuilderDocumentRows
    ): IDocumentBuilderDocumentOriginatingRows[] {
        const refs: IDocumentBuilderDocumentOriginatingRows[] = [];
        refs.push({
            RefId: 0,
            DocumentId: 0,
            SourceEntityType: r.EntityType,
            SourceEntityKeyId: r.getArticleId(),
            DestEntityType: this.initializationInfo.DocTypeCode,
            DestEntityKeyId: 0,
            CatalogId: r.CatalogId(),
            Amount: newDocumentRow.Amount,
            UnitPrice: newDocumentRow.UnitPrice,
            Discounts: newDocumentRow.Discounts,
            NetUnitPrice: newDocumentRow.NetUnitPrice,
            NetPrice: newDocumentRow.TotalPrice,
            WarehouseId: this.initializationInfo.SourceWarehouseId,
        });
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return (
            this.authorizationService.isAuthorized("Warehouse_Articles") &&
            (initializationInfo.DocTypeCode === ProlifeSdk.DdtEntityTypeCode ||
                (initializationInfo.DocTypeCode === ProlifeSdk.WarehouseLoadEntityTypeCode &&
                    initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol))
        );
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo) {
        await super.Initialize(initializationInfo);

        this.UnloadingMode(
            initializationInfo.DocTypeCode === ProlifeSdk.DdtEntityTypeCode ? "unloading" : "adjustment"
        );

        this.StockArticles([]);
        this.discountsManagerForCustomer = this.createDiscountManager(initializationInfo.CustomerId);
        this.ReferenceDate(initializationInfo.DocumentDate);

        const warehouseId = this.initializationInfo.SourceWarehouseNotSupported
            ? this.initializationInfo.DestinationWarehouseId
            : this.initializationInfo.SourceWarehouseId;

        try {
            const warehouse = await this.warehousesService.getWarehouseById(warehouseId);
            this.WorkflowId(warehouse.WorkflowId);

            const workflow = await this.todoListService.GetWorkflowForList(warehouse.WorkflowId);
            this.WorkflowName(workflow?.Title);

            const defaultWarehouse = await this.warehouseService.getDefaultWarehouse();
            this.IsDefaultWarehouse(warehouseId == defaultWarehouse.Id);

            if (!this.IsDefaultWarehouse()) {
                this.searchStock();
            }
        } catch {
            // eslint-disable-next-line no-empty
        }

        this.OnlyPositiveStock.subscribe(() => {
            if (!this.IsDefaultWarehouse()) {
                this.searchStock();
            }
        });

        this.EntityType.subscribe(() => {
            if (!this.IsDefaultWarehouse()) {
                this.searchStock();
            }
        });
    }

    public async EditWorkflow(): Promise<void> {
        if (!this.WorkflowId()) return;

        await this.todoListService.ShowEditWorkflowDialog(this.WorkflowId());
    }

    public createDiscountManager(customerId: number): IDiscountsManager {
        return customerId && customerId > 0 ? this.discountsService.getDiscountsManagerByCustomerId(customerId) : null;
    }

    private createViewModelFor(stockArticleV2: IStockArticleV2, entityType: string): SelectableMovementArticle {
        const stockArticle: IStockArticle = {
            Id: stockArticleV2.id,
            ManufacturerId: stockArticleV2.manufacturerId,
            CatalogId: stockArticleV2.catalogId,
            ArticleId: stockArticleV2.articleId,
            Code: stockArticleV2.code,
            EanCode: stockArticleV2.eanCode,
            ManufacturerCode: stockArticleV2.manufacturerCode,
            Description: stockArticleV2.description,
            ExtendedDescription: stockArticleV2.extendedDescription,
            BoxQuantity: stockArticleV2.boxQuantity,
            OrderQtStep: stockArticleV2.orderQtStep,
            MinOrderQt: stockArticleV2.minOrderQt,
            MaxOrderQt: stockArticleV2.maxOrderQt,
            LeadTime: stockArticleV2.leadTime,
            DealerPrice: stockArticleV2.dealerPrice,
            CustomerPrice: stockArticleV2.customerPrice,
            PriceMultiplier: stockArticleV2.priceMultiplier,
            Currency: stockArticleV2.currency,
            UOM: stockArticleV2.uom,
            Kit: stockArticleV2.kit,
            State: stockArticleV2.state,
            LastModification: stockArticleV2.lastModification,
            DiscountFamily: stockArticleV2.discountFamily,
            StatisticFamily: stockArticleV2.statisticFamily,
            MefCode: stockArticleV2.mefCode,
            Cost: stockArticleV2.cost,
            Hallway: stockArticleV2.hallway,
            Shelf: stockArticleV2.shelf,
            Ledge: stockArticleV2.ledge,
            WarehousePosition: stockArticleV2.warehousePosition,
            FKPurchaseType: stockArticleV2.fkPurchaseType,
            PurchaseType: stockArticleV2.purchaseType,
            StockAmount: stockArticleV2.stockAmount,
            TotalStockAmount: stockArticleV2.totalStockAmount,
            MinimumAmount: stockArticleV2.minimumAmount,
            Orders: stockArticleV2.orders,
            Reservations: stockArticleV2.reservations,
            TotalReservations: stockArticleV2.totalReservations,
        };

        const article = new SelectableMovementArticle(
            stockArticle,
            entityType,
            false,
            0,
            false,
            this.initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol
        );

        if (this.discountsManagerForCustomer != null)
            article.Discounts(this.discountsManagerForCustomer.getFormattedDiscountsForArticle(stockArticle));

        article.Amount(stockArticle.StockAmount);
        return article;
    }

    public async searchStock(): Promise<void> {
        const warehouseId = this.initializationInfo.SourceWarehouseNotSupported
            ? this.initializationInfo.DestinationWarehouseId
            : this.initializationInfo.SourceWarehouseId;
        const onlyPositiveStock = this.OnlyPositiveStock();

        try {
            const result = await this.stockService.getHistoricalStocksV2(
                this.ReferenceDate(),
                null,
                0,
                1000000,
                warehouseId,
                true,
                onlyPositiveStock,
                [this.EntityType()]
            );
            const viewModels = result.stockArticles.map((a) => this.createViewModelFor(a, this.EntityType()));

            this.StockArticles(viewModels);
        } catch (e) {
            console.log(e);
        }
    }

    public removeAll() {
        this.Rows().forEach((r: SelectableMovementArticle) => {
            this.StockArticles.push(r);
        });
        this.Rows([]);
    }

    public addSelectedRows() {
        const selectedRows = this.SelectedArticles();
        selectedRows.forEach((r: SelectableMovementArticle) => {
            this.Rows.push(r);
            this.StockArticles.remove(r);
        });
    }

    public removeSelectedRows() {
        const selectedRows = this.SelectedArticles();
        selectedRows.forEach(this.removeArticle.bind(this));
    }

    public removeArticle(article: SelectableMovementArticle) {
        this.Rows.remove(article);
        this.StockArticles.push(article);
    }

    private setCurrentDate() {
        this.ReferenceDate(moment(new Date()).endOf("day").toDate());
    }

    private setDocumentDate() {
        this.ReferenceDate(moment(this.initializationInfo.DocumentDate).endOf("day").toDate());
    }

    render() {
        let step: WarehouseStockDataSource;
        let row: SelectableMovementArticle;

        return ComponentUtils.bindTo(
            <div className="form-horizontal" style="padding: 20px;">
                <div className="row" style="margin-bottom: 20px" data-bind={{ visible: step.IsDefaultWarehouse }}>
                    <ko-bind data-bind={{ if: !!step.WorkflowId() }}>
                        <label className="col-md-1 control-label">
                            {TextResources.Invoices.DocumentWizardWarehouseWorkflow}
                        </label>
                        <div className="col-md-5">
                            <span className="form-control" data-bind={{ text: step.WorkflowName }}></span>
                        </div>
                        <div className="col-md-1">
                            <button
                                className="btn btn-primary"
                                data-bind={{ asyncClick: step.EditWorkflow }}
                                title={TextResources.Invoices.DocumentWizardEditWorkflow}
                            >
                                <i className="fa fa-pencil"></i>
                            </button>
                        </div>
                    </ko-bind>

                    <div className="col-md-6" data-bind={{ css: { "offset-md-6": !step.WorkflowId() } }}>
                        <div className="alert alert-danger pre" role="alert">
                            {TextResources.Invoices.DocumentWizardTooManyArticlesWarning}
                        </div>
                    </div>
                </div>

                <div className={"flex-container " + classes["wizard-toolbar"]} style="margin-bottom : 5px">
                    <div
                        className="flex-container flex-1 left-toolbar"
                        style={{ columnGap: "20px", alignItems: "flex-end" }}
                    >
                        <button type="button" className="btn btn-success" data-bind={{ click: step.addSelectedRows }}>
                            {TextResources.Invoices.DocumentWizardImportSelected}
                        </button>
                        <button type="button" className="btn btn-success" data-bind={{ click: step.searchStock }}>
                            {TextResources.Invoices.DocumentWizardReloadStock}
                        </button>

                        <div className="ref-date flex-container" style={{ alignItems: "flex-end" }}>
                            <DateTimeInput
                                value={this.ReferenceDate}
                                dateonly
                                label={TextResources.Invoices.DocumentWizardReferenceDate}
                                parent=".modal-body"
                                noIcon
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bind={{ click: step.setCurrentDate.bind(step) }}
                                title={TextResources.Invoices.DocumentWizardSetCurrentDate}
                            >
                                <i className="fa fa-calendar"></i>
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bind={{ click: step.setDocumentDate.bind(step) }}
                                title={TextResources.Invoices.DocumentWizardSetDocumentDate}
                            >
                                <i className="fa fa-file"></i>
                            </button>
                        </div>

                        <SegmentedInput
                            options={[
                                {
                                    value: ProlifeSdk.WarehouseArticleEntityTypeCode,
                                    text: TextResources.Invoices.WarehouseArticle,
                                },
                                {
                                    value: ProlifeSdk.WarehousePurchaseArticleEntityTypeCode,
                                    text: TextResources.Invoices.WarehousePurchase,
                                },
                            ]}
                            value={this.EntityType}
                            itemStyle={{ marginBottom: "5px" }}
                        />
                    </div>
                    <div
                        className="flex-container flex-1"
                        style={{ columnGap: "20px", alignItems: "flex-end", justifyContent: "end" }}
                    >
                        <SegmentedInput
                            options={[
                                {
                                    value: "unloading",
                                    text: TextResources.Invoices.DocumentWizardStockAmountUnloading,
                                },
                                {
                                    value: "adjustment",
                                    text: TextResources.Invoices.DocumentWizardStockAmountAdjustment,
                                },
                            ]}
                            value={this.UnloadingMode}
                            itemStyle={{ marginBottom: "5px" }}
                        />

                        <button type="button" className="btn btn-danger" data-bind={{ click: step.removeAll }}>
                            {TextResources.Invoices.DocumentWizardRemoveAll}
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <checkbox checked={() => "OnlyPositiveStock"}>
                            {TextResources.Invoices.DocumentWizardOnlyPositiveStock}
                        </checkbox>
                    </div>
                    <div className="col-md-6">
                        <div className="alert alert-danger pre" role="alert">
                            <If condition={() => this.UnloadingMode() === "unloading"}>
                                {() => <>{TextResources.Invoices.DocumentWizardStockAmountUnloadingHelp}</>}
                            </If>
                            <If condition={() => this.UnloadingMode() === "adjustment"}>
                                {() => <>{TextResources.Invoices.DocumentWizardStockAmountAdjustmentHelp}</>}
                            </If>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center" style="width: 120px">
                                        <div className="checker">
                                            <span data-bind={{ css: { checked: step.AllSelected } }}>
                                                <input type="checkbox" data-bind={{ checked: step.AllSelected }} />
                                            </span>
                                        </div>
                                    </th>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th className="text-center" style="width: 120px">
                                        {TextResources.Invoices.DocumentWizardAvailability}
                                    </th>
                                    <th className="text-center" style="width: 120px">
                                        {TextResources.Invoices.DocumentWizardUoM}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if: step.StockArticles().length == 0 }}>
                                    <tr>
                                        <td colSpan={4} className="text-center">
                                            {TextResources.Invoices.DocumentWizardNoArticle}
                                        </td>
                                    </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.StockArticles, as: "row" } }}>
                                    <tr className="text-center" data-bind={{ css: { active: row.Selected } }}>
                                        <td>
                                            <div className="checker">
                                                <span data-bind={{ css: { checked: row.Selected } }}>
                                                    <input type="checkbox" data-bind={{ checked: row.Selected }} />
                                                </span>
                                            </div>
                                        </td>
                                        <td className="text-left" data-bind={{ text: row.Description }}></td>
                                        <td className="text-right" data-bind={{ numberText: row.Amount }}></td>
                                        <td className="text-center" data-bind={{ text: row.UOM }}></td>
                                    </tr>
                                </ko-bind>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-md-6">
                        <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th className="text-left">{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th className="text-center" style="width : 120px">
                                        {TextResources.Invoices.DocumentWizardAmount}
                                    </th>
                                    <th className="text-center" style="width : 120px">
                                        {TextResources.Invoices.DocumentWizardUoM}
                                    </th>
                                    <th className="text-center" style="width : 120px">
                                        {TextResources.Invoices.DocumentWizardRemove}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ if: step.Rows().length == 0 }}>
                                    <tr>
                                        <td colSpan={4} style="text-align : center">
                                            {TextResources.Invoices.DocumentWizardNoRowAvailable}
                                        </td>
                                    </tr>
                                </ko-bind>
                                <ko-bind data-bind={{ foreach: { data: step.Rows, as: "row" } }}>
                                    <tr>
                                        <td className="text-left" data-bind={{ text: row.Description }}></td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control input-sm text-center"
                                                data-bind={{ numberValue: row.Amount, selectOnFocus: {} }}
                                            />
                                        </td>
                                        <td className="text-center" data-bind={{ text: row.UOM }}></td>
                                        <td className="text-center">
                                            <a
                                                href="#"
                                                className="btn btn-xs red"
                                                data-bind={{ click: step.removeArticle.bind(step, row) }}
                                            >
                                                <i className="fa fa-times"></i>
                                                {TextResources.Invoices.DocumentWizardRemove}
                                            </a>
                                        </td>
                                    </tr>
                                </ko-bind>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>,
            this,
            "step"
        );
    }
}
