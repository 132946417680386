import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

type DateTimeTextProps = {
    dateTime: Date|ko.Observable<Date>|ko.Computed<Date>;
    dateTextNoValue?: string;
    format?: string;
}

export function DateTimeText(props: DateTimeTextProps) {
    const C = require("./DateTimeText")._DateTimeText as typeof _DateTimeText;
    return <C {...props} />;
}

export class _DateTimeText {
    static defaultProps: Partial<DateTimeTextProps> = {
        dateTextNoValue: TextResources.ProlifeSdk.NotAvailable,
        format: "L LT"
    }

    constructor(private props : DateTimeTextProps) {}
    
    render() {
        const dtt = this;
        
        return ComponentUtils.bindTo((
            <span data-bind={{ dateTimeText: dtt.props.dateTime, dateTextNoValue: dtt.props.dateTextNoValue, customDateTimeFormat: dtt.props.format }}></span>
        ), this, "dtt");
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(DateTimeText);
}