import * as React from "@abstraqt-dev/jsxknockout";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { JobOrderTypesEditingViewModel } from "./JobOrderTypesEditingViewModel";

export class JobOrderTypesEditingDialog extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    constructor() {
        super({ noPrompt: true, className: "large" });

        this.title(TextResources.JobOrder.JobOrderTypesDialogTitle);
    }

    show(): Promise<void> {
        return this.dialogsService.ShowModal(this);
    }

    close(): void {
        this.modal.close();
    }

    action(): void {

    }

    renderBody() {
        return <JobOrderTypesEditingViewModel />;
    }
}