import * as ko from "knockout";
import { RoleRefConverter } from "./RoleRefConverter";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider, IDocumentRowInlineReferenceProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { IEntityForRef } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { ISettingsService } from "../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";

export class RoleRefConverterFactory implements IDocumentRowInlineReferenceProviderFactory
{
    private sdkService : IProLifeSdkService;
    private userCharactersSettingsManager : IUserCharactersSettingsManager;

    public EntityType : string  = ProlifeSdk.EstimatedWorkEntityTypeCode;
    public Icon : string = "fa fa-user";
    public ActivationCode : string = "@02";
    public Description : string = ProlifeSdk.TextResources.Users.ProfessionalFigures;
    public DefaultActivation : boolean = false;
    public SupportedDocTypes : string[] = [
        ProlifeSdk.EstimateEntityTypeCode,
        ProlifeSdk.CustomerOrderEntityTypeCode,
        ProlifeSdk.InvoiceEntityTypeCode,
        ProlifeSdk.AccompanyingInvoiceEntityTypeCode,
        ProlifeSdk.SalEntityTypeCode,
        ProlifeSdk.DdtEntityTypeCode,
        ProlifeSdk.WarehouseLoadEntityTypeCode
    ];

    constructor(private serviceLocator : IServiceLocator)
    {
        this.sdkService = <IProLifeSdkService>serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        var settingsService = <ISettingsService> serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.userCharactersSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);
    }

    public findEntities(query : string, skip : number, count : number, docInfo : IDocumentInfoForInlineRefProvider) : Promise<IEntityForRef[]>
    {
        var entities = this.userCharactersSettingsManager.getUserCharacters()
            .filter((a : IUserCharacter) => {
                var code : string = this.sdkService.Utilities.Numbers.ZeroPad(a.IdUserCharacter.toString(), 5);
                return (query || "").trim().length == 0 || code.toLowerCase().indexOf(query.toLowerCase()) > -1 || a.Description.toLowerCase().indexOf(query.toLowerCase()) > -1;
            })
            .map((a : IUserCharacter) => {
                var code : string = this.sdkService.Utilities.Numbers.ZeroPad(a.IdUserCharacter.toString(), 5);
                return { 
                    Id : a.IdUserCharacter, 
                    EntityType : ProlifeSdk.EstimatedWorkEntityTypeCode, 
                    Code: code,
                    Description : a.Description,
                    Icon: {
                        background: "white",
                        foreground: "black",
                        icon: this.Icon
                    }
                }
            });
        return Promise.resolve<IEntityForRef[]>(entities);
    }

    public create(document : IDocumentInfoForInlineRefProvider) : IDocumentRowInlineReferenceProvider
    {
        return new RoleRefConverter(this.serviceLocator, this.ActivationCode, document);
    }
}
