import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: g.biasci
 * Date: 11/07/13
 * Time: 9.05
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { CustomerOrderProtocolSettings } from "./CustomerOrderProtocolSettings";
import { IProtocolType, IVatRegisterEditingContainer } from "../../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { IVatRegister } from "../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Right } from "../../../../Core/Authorizations";

export class CustomerOrderProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.CustomerOrderTypeId;
    public DocumentTypeName: string = ProlifeSdk.TextResources.Warehouse.CustomerOrder;
    public DocumentTypeLabelForSettings: string = ProlifeSdk.TextResources.Warehouse.CustomerOrderProtocols;
    public DocumentTypeShortLabelForSettings: string =
        ProlifeSdk.TextResources.Warehouse.CustomerOrderProtocolsShortLabel;

    HasCustomTemplate = true;

    @Right("Warehouse_CustomerOrder")
    private canAddProtocolsRight: boolean;

    constructor() {}

    canAddProtocols(): boolean {
        return this.canAddProtocolsRight;
    }

    createSettingsViewModel(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister
    ): CustomerOrderProtocolSettings {
        return new CustomerOrderProtocolSettings(container, vatRegister);
    }
}
