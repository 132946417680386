import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Layout } from "../../../../Components/Layouts";
import { IRequestsForQuotationsDataSourceModel, RequestsForQuotationsDataSource } from "../../../../DataSources/RequestsForQuotationsDataSource";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { RouteParams } from "../../../../Components/Routing";
import { ITableItem, Table } from "../../../../Components/TableComponent/TableComponent";
import { Column } from "../../../../Components/TableComponent/CustomColumn";
import { IProvisioningService, IRequestForQuotationForList } from "../../../ProvisioningService";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { Portlet } from "../../../../Components/Portlet";
import { SupplierColumnFilter } from "../../../../Components/ColumnFilters/SupplierColumnFilter";
import { DateRangeColumnFilter } from "../../../../Components/ColumnFilters/DateRangeColumnFilter";
import { OptionsColumnFilter } from "../../../../Components/ColumnFilters/OptionsColumnFilter";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IApplicationNavBarAction, INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";
import { getStatesList, IRequestForQuotationStatus } from "./RequestForQuotationStatesList";

export type IRequestForQuotationsListProps = RouteParams<{
    navBarActionManager: INavBarActionManager;
}>;

export function RequestForQuotationsList(props: IRequestForQuotationsListProps) {
    const C = require("./RequestForQuotationsList")._RequestForQuotationsList as typeof _RequestForQuotationsList;
    return <C {...props} />;
}

export class _RequestForQuotationsList {
    public SupplierId: ko.Observable<number> = ko.observable();
    public DateFrom: ko.Observable<Date> = ko.observable();
    public DateTo: ko.Observable<Date> = ko.observable();
    public StateId: ko.Observable<number> = ko.observable();

    public StatesList: ko.ObservableArray<IRequestForQuotationStatus> = ko.observableArray(getStatesList());

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService : IProvisioningService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    
    private RequestsForQuotationsDataSource: RequestsForQuotationsDataSource = new RequestsForQuotationsDataSource();
    private addAction: IApplicationNavBarAction;

    constructor(private props: IRequestForQuotationsListProps) {
        this.SupplierId.subscribe((id: number) => {
            this.RequestsForQuotationsDataSource.setSupplierFilter(id);
            this.RequestsForQuotationsDataSource.refresh();
        });

        this.DateFrom.subscribe((date: Date) => {
            this.RequestsForQuotationsDataSource.setDateFromFilter(date);
            this.RequestsForQuotationsDataSource.refresh();
        });

        this.DateTo.subscribe((date: Date) => {
            this.RequestsForQuotationsDataSource.setDateToFilter(date);
            this.RequestsForQuotationsDataSource.refresh();
        });

        this.StateId.subscribe((id: number) => {
            this.RequestsForQuotationsDataSource.setStateIdFilter(id);
            this.RequestsForQuotationsDataSource.refresh();
        });

        // TODO registrarsi sul notificationsService per aggiornare in caso di creazione/modifica/cancellazione
    }

    componentDidMount() {
        this.addAction = this.props.navBarActionManager.addNavBarAction(
            TextResources.Provisioning.NewRequestForQuotation,
            "fa fa-plus",
            true,
            () => this.createRequestForQuotation()
        );
    }

    componentWillUnmount() {
        this.props.navBarActionManager.removeNavBarAction(this.addAction);
    }

    public async createRequestForQuotation(): Promise<void> {
        this.props.navigator.navigateTo("/Provisioning/RequestsForQuotations/New");
    }

    private editRequestForQuotation(rfqId: number) {
        this.props.navigator.navigateTo("/Provisioning/RequestsForQuotations/" + rfqId);
    }

    private async deleteRequestForQuotation(rfqId: number) {
        if(!await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.DeleteRequestForQuotationMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm
        )) return;

        await this.provisioningService.DeleteRequestForQuotation(rfqId);

        this.infoToastService.Success(TextResources.Provisioning.RequestForQuotationDeleted);
        this.RequestsForQuotationsDataSource.refresh();
    }

    render() {
        const _rfql = this;
        let rfq: IRequestsForQuotationsDataSourceModel;

        const { sortDate, sortNumber, sortString } = ComponentUtils.useSorter<IRequestForQuotationForList>();

        return  ComponentUtils.bindTo(
                <Layout.Grid columns={['1fr']} rows={["90px", "1fr"]}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <Portlet collapsible={false}>
                            <Portlet.Header>
                                <Portlet.Header.Default title="Richieste d'offerta" className="font-red-sunglo bold uppercase" />
                            </Portlet.Header>
                        </Portlet>
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={2}>
                        <Table dataSource={this.RequestsForQuotationsDataSource} rowAs="rfq" compact systemScrollable>
                            <Column style={{ width: '28px' }}>
                                <div className="flex-container flex-child-center" data-bind={{ click: rfq.toggleDescription }} style={{ cursor: 'pointer' }}>
                                    <span className="btn btn-xs btn-link" style={{ cursor: 'pointer' }}>
                                        <i className="fa" data-bind={{ css: { 'fa-angle-right': !rfq.DescriptionVisible(), 'fa-angle-down': rfq.DescriptionVisible } }} />
                                    </span>
                                </div>
                            </Column>
                            <Column title="Numero" sorter={sortNumber(r => parseInt(r.Number))}>
                                <div className="flex-container flex-child-center" data-bind={{ click: rfq.toggleDescription }} style={{ cursor: 'pointer' }}>
                                    <span data-bind={{ text: rfq.model.Number }} />
                                </div>
                            </Column>
                            <Column title="Creato da" sorter={sortString(r => r.CreationResourceName)}>
                                <div className="flex-container flex-child-center" data-bind={{ click: rfq.toggleDescription }} style={{ cursor: 'pointer' }}>
                                    <span data-bind={{ text: rfq.model.CreationResourceName }} />
                                </div>
                            </Column>
                            <Column title="Creato il" sorter={sortDate(r => r.CreationDate)} filterComponent={() => <DateRangeColumnFilter initialDateFrom={this.DateFrom()} initialDateTo={this.DateTo()} onDateRangeChanged={(s,e) => { this.DateFrom(s); this.DateTo(e); }} />}>
                                <span data-bind={{ dateText: rfq.model.CreationDate }} />
                            </Column>
                            <Column title="Fornitore" sorter={sortString(r => r.SupplierName)} filterComponent={() => <SupplierColumnFilter initialValue={this.SupplierId()} onSupplierSelected={this.SupplierId} />}>
                                <span data-bind={{ text: rfq.model.SupplierName }} />
                            </Column>
                            <Column title="Stato" sorter={sortNumber(r => r.StateId)} filterComponent={() => <OptionsColumnFilter initialValue={this.StateId()} options={this.StatesList()} optionsValue="Id" optionsText="Label" onChanged={this.StateId} placeholder="Tutti gli stati" />}>
                                <span data-bind={{ text: _rfql.StatesList().firstOrDefault(s => s.Id === rfq.model.StateId).Label }} />
                            </Column>
                            <Column title="Inviato da" sorter={sortString(r => r.SendingResourceName)}>
                                <span data-bind={{ text: rfq.model.SendingResourceName }} />
                            </Column>
                            <Column title="Inviato il" sorter={sortDate(r => r.DateOfSending)}>
                                <span data-bind={{ dateText: rfq.model.DateOfSending, dateTextNoValue: "Non inviato" }} />
                            </Column>
                            <Column style={{ width: 200, textAlign: 'right' }}>
                                <button className="btn btn-xs btn-circle btn-primary" data-bind={{ click: _rfql.editRequestForQuotation.bind(_rfql, rfq.id)}}>
                                    <i className="fa fa-pencil"/>&nbsp;Modifica
                                </button>
                                <button className="btn btn-xs btn-circle btn-danger" data-bind={{ asyncClick: _rfql.deleteRequestForQuotation.bind(_rfql, rfq.id)}}>
                                    <i className="fa fa-trash-o"/>&nbsp;Elimina
                                </button>
                            </Column>
                            <SecondaryRow if={() => "rfq.DescriptionVisible" }>
                                {(item : ITableItem<IRequestsForQuotationsDataSourceModel>) => (
                                    <td colSpan={item.VisibleColumnsCount()}>
                                        <div className="flex-container no-gutters">
                                            <div style={{ width: '19px', borderRight: '1px solid #ddd', alignSelf: 'stretch', backgroundColor: '#f5f5f5' }}></div>
                                            <div className="flex-1">
                                                <div style={{ padding: '5px', backgroundColor: '#f5f5f5' }} data-bind={{ html: rfq.model.Description || "Nessuna descrizione" }}></div>
                                            </div>
                                        </div>
                                        
                                    </td>
                                )}
                            </SecondaryRow>
                        </Table>
                    </Layout.Grid.Cell>
                </Layout.Grid>
        , this, "_rfql");
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(RequestForQuotationsList);
}