import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/01/2018
 * Time: 16:23
 * To change this template use File | Settings | File Templates.
 */

import * as moment from "moment";
import { Moment } from "moment";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ResourceViewModel, OperationalUnitForResourceViewModel, TeamForResourceViewModel, AllocationRangesForTeamViewModel } from "./TeamsManager";
import { PlannerGanttItem, PlannerGanttItemInterval } from "../../../ProlifeSdk/prolifesdk/controls/gantt/PlannerGanttItem";
import { IHumanResourceOrdersRoles, IHumanResourceOrders } from "../../../Users/HumanResourcesService";
import { IGanttWrapper } from "../../interfaces/IGanttWrapper";

export class ResourcesFirstRenderer {

    constructor(private Gantt : IGanttWrapper) {

    }

    public render(resource: ResourceViewModel): void {
        var resourceItem = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
        resourceItem.Id(resource.Id());
        resourceItem.Type("Resource"); // TODO refactoring
        resourceItem.Color("#FF8080");
        resourceItem.ProgressColor("#FF8080");
        resourceItem.Progress(0);
        resourceItem.Id(resource.Id());
        resourceItem.Tag(resource);
        resourceItem.Title(resource.Name());
        resourceItem.StartDate(resource.StartDate().toDate());
        resourceItem.EndDate(resource.EndDate().toDate());

        resourceItem.loadOpeningState();

        this.renderOperationalUnits(resource, resourceItem);
        this.renderResource(resourceItem);
        
        // resource.StartDate.subscribe((d) => resourceItem.StartDate(d.toDate()));
        // resource.EndDate.subscribe((d) => resourceItem.EndDate(d.toDate()));
        // resource.OperationalUnits.subscribe(this.renderOperationalUnits.bind(this, resource, resourceItem));
        
        this.Gantt.Gantt.Items.push(resourceItem);
        
        /* var resourceRenderInterceptor = ko.computed(() => {
            this.renderResource(resourceItem);
        }); */
    }

    private renderResource(resourceItem: PlannerGanttItem): void {
        var allIntervals = [];
        var intervals = [];

        resourceItem.Children().forEach((i: PlannerGanttItem) => {
            allIntervals = allIntervals.concat(i.Intervals());
        });

        allIntervals.sort((a : PlannerGanttItemInterval, b : PlannerGanttItemInterval) => moment(a.StartDate()).diff(moment(b.StartDate()), 'days'));

        var firstDate : PlannerGanttItemInterval = null;
        var sum: number = 0;

        allIntervals.forEach((i: PlannerGanttItemInterval) => {
            if (!firstDate || moment(firstDate.StartDate()).valueOf() != moment(i.StartDate()).valueOf()) {
                if (!!firstDate) {
                    var interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
                    var refValue: number = this.GetServiceOrdersTotalDailyHours(resourceItem.Tag().OperationalUnits(), moment(firstDate.StartDate()));
                    this.Gantt.SetIntervalStyle(interval, sum, refValue);
                    intervals.push(interval);
                }

                firstDate = i;
                sum = 0;
            }

            sum = sum + i.Value();
        });
        if (!!firstDate) {
            var interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
            var refValue: number = this.GetServiceOrdersTotalDailyHours(resourceItem.Tag().OperationalUnits(), moment(firstDate.StartDate()));
            this.Gantt.SetIntervalStyle(interval, sum, refValue);
            intervals.push(interval);
        }

        resourceItem.Intervals(intervals);
    }

    private renderOperationalUnits(resource: ResourceViewModel, resourceItem: PlannerGanttItem): void {
        var ous = [];

        resource.OperationalUnits().forEach((ou: OperationalUnitForResourceViewModel) => {
            var ouItem = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
            ouItem.Id(ou.Id);
            ouItem.Type("OperationalUnit"); // TODO refactoring
            ouItem.Title(ou.Name());
            ouItem.Color("#FF8080");
            ouItem.ProgressColor("rgba(0, 0, 0, 0.40)");
            ouItem.Progress(0);
            ouItem.MultipleIntervals(true);

            ouItem.loadOpeningState();

            this.renderTeams(ou, ouItem);

            this.renderOperationalUnitsIntervals(ou, ouItem);
            /* var operationalUnitsRenderInterceptor = ko.computed(() => {
                this.renderOperationalUnitsIntervals(ou, ouItem);
            }); */

            //ou.Teams.subscribe(this.renderTeams.bind(this, ou, ouItem));

            ous.push(ouItem);
        });

        resourceItem.Children(ous);
    }

    private renderOperationalUnitsIntervals(ou: OperationalUnitForResourceViewModel, ouItem: PlannerGanttItem): void {
        var intervals = [];
        var allIntervals = [];

        ouItem.Children().forEach((c : PlannerGanttItem) => {
            allIntervals = allIntervals.concat(c.Intervals());
        });

        allIntervals.sort((a : PlannerGanttItem, b : PlannerGanttItem) => moment(a.StartDate()).diff(moment(b.StartDate()), 'days'));

        var firstDate : PlannerGanttItemInterval = null;
        var sum: number = 0;

        allIntervals.forEach((i: PlannerGanttItemInterval) => {
            if (!firstDate || moment(firstDate.StartDate()).valueOf() != moment(i.StartDate()).valueOf()) {
                if (!!firstDate) {
                    var interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
                    var refValue: number = this.GetServiceOrdersTotalDailyHours([ou], moment(firstDate.StartDate()));
                    this.Gantt.SetIntervalStyle(interval, sum, refValue);
                    intervals.push(interval);
                }

                firstDate = i;
                sum = 0;
            }

            sum = sum + i.Value();
        });
        if (!!firstDate) {
            var interval = new PlannerGanttItemInterval(moment(firstDate.StartDate()).toDate(), moment(firstDate.EndDate()).toDate(), sum, this.Gantt.Gantt.StartDate);
            var refValue: number = this.GetServiceOrdersTotalDailyHours([ou], moment(firstDate.StartDate()));
            this.Gantt.SetIntervalStyle(interval, sum, refValue);
            intervals.push(interval);
        }

        ouItem.Intervals(intervals);
    }

    private renderTeams(ou: OperationalUnitForResourceViewModel, ouItem: PlannerGanttItem): void {
        var teams = [];

        ou.Teams().forEach((team: TeamForResourceViewModel) => {
            if (team.AllocationRanges().length > 0) {
                var teamItem = new PlannerGanttItem(this.Gantt.Gantt.StartDate, this.Gantt.Gantt);
                teamItem.Id(team.Id);
                teamItem.Type("Team"); // TODO refactoring
                teamItem.Title(team.Name());
                teamItem.StartDate(team.StartDate().toDate());
                teamItem.EndDate(team.EndDate().toDate());

                teamItem.loadOpeningState();

                /* team.Name.subscribe((n) => { teamItem.Title(n); });
                team.StartDate.subscribe((d) => { teamItem.StartDate(d.toDate()); });
                team.EndDate.subscribe((d) => { teamItem.EndDate(d.toDate()); }); */

                this.renderRanges(ou, team, teamItem);

                // team.AllocationRanges.subscribe(this.renderRanges.bind(this, ou, team, teamItem));

                teams.push(teamItem);
            }
        });

        ouItem.Children(teams);
    }

    private renderRanges(ou: OperationalUnitForResourceViewModel, team: TeamForResourceViewModel, teamItem: PlannerGanttItem): void {
        var ranges = [];

        team.AllocationRanges().forEach((range : AllocationRangesForTeamViewModel) => {
            var intervals = this.splitRangeToDays(ou, range);
            range.StartDate.subscribe(d => this.renderRanges(ou, team, teamItem));
            range.EndDate.subscribe(d => this.renderRanges(ou, team, teamItem));
            range.Amount.subscribe(a => this.renderRanges(ou, team, teamItem));
            ranges = ranges.concat(intervals);
        });

        teamItem.Intervals(ranges);
    }

    private splitRangeToDays(ou : OperationalUnitForResourceViewModel, range : AllocationRangesForTeamViewModel) : PlannerGanttItemInterval[] {
        var ranges = [];
        var serviceOrders = ou.ServiceOrders();
        var startDate = moment(range.StartDate());
        var endDate = moment(range.EndDate());
        var amount = (range.Amount() / 100);

        for(var d = startDate; d <= endDate; d = moment(d.add('days', 1))) {
            var serviceOrder = this.findServiceOrder(serviceOrders, d);
            var allocatedHours = range.AllocationType() == ProlifeSdk.PercentageEditigMode ?
                this.getWorkedHoursForDay(serviceOrder, d, range) * amount :
                this.getWorkedHoursForDay(serviceOrder, d, range);

            if(allocatedHours == 0)
                continue;

            var newRange = new PlannerGanttItemInterval(d.toDate(), d.toDate(), allocatedHours, this.Gantt.Gantt.StartDate);
            this.Gantt.SetIntervalStyle(newRange, allocatedHours, this.getServiceOrderHoursForDay(serviceOrder, d));

            ranges.push(newRange);
        }

        return ranges;
    }

    private findServiceOrder(serviceOrders : IHumanResourceOrders[], refDay : Moment) : IHumanResourceOrders {
        var foundServiceOrders = serviceOrders.filter(o => refDay >= moment(o.FromDate) && refDay < moment(o.ToDate || moment("2100-01-01")));
        if(foundServiceOrders.length > 0)
            return foundServiceOrders[0];
        return null;
    }

    private getWorkedHoursForDay(serviceOrder : IHumanResourceOrders, refDay : Moment, range: AllocationRangesForTeamViewModel) : number {
        var dayOfWeek = refDay.day();

        switch(dayOfWeek) {
            case 0:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSunday) : range.Sunday();
            case 1:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursMonday) : range.Monday();
            case 2:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursTuesday) : range.Tuesday();
            case 3:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursWednesday) : range.Wednesday();
            case 4:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursThursday) : range.Thursday();
            case 5:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursFriday) : range.Friday();
            case 6:
                return range.AllocationType() == ProlifeSdk.PercentageEditigMode ? (!serviceOrder ? 0 : serviceOrder.HoursSaturday) : range.Saturday();
            default:
                return 0;
        }
    }

    private getServiceOrderHoursForDay(serviceOrder : IHumanResourceOrders, refDay : Moment) : number {
        var dayOfWeek = refDay.day();

        switch(dayOfWeek) {
            case 0:
                return serviceOrder.HoursSunday;
            case 1:
                return serviceOrder.HoursMonday;
            case 2:
                return serviceOrder.HoursTuesday;
            case 3:
                return serviceOrder.HoursWednesday;
            case 4:
                return serviceOrder.HoursThursday;
            case 5:
                return serviceOrder.HoursFriday;
            case 6:
                return serviceOrder.HoursSaturday;
            default:
                return 0;
        }
    }

    private GetServiceOrdersTotalDailyHours(operationalUnits: OperationalUnitForResourceViewModel[], refDay: Moment): number {
        var sum: number = 0;
        operationalUnits.forEach((ou: OperationalUnitForResourceViewModel) => {
            var serviceOrders = ou.ServiceOrders();
            if (serviceOrders.length > 0) {
                var serviceOrder = this.findServiceOrder(serviceOrders, refDay);
                if (!!serviceOrder) {
                    sum = sum + this.getServiceOrderHoursForDay(serviceOrder, refDay);
                }
            }
        });
        return sum;
    }
}