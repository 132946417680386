import * as ko from "knockout";
import { IUoAddress } from "../../../ProlifeSdk/interfaces/customer/ICustomer";
import { IValidation, IValidator, IValidationService } from "../../../ProlifeSdk/ValidationService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { DetectClassChanges, DetectChanges } from "../../../Core/ChangeDetection";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";

@DetectClassChanges
export class UoAddresses
{
    @DetectChanges
    public AllAddresses : ko.ObservableArray<CustomerAddressViewModel> = ko.observableArray([]);
    public HasChanges : ko.Computed<boolean>;

    public isChanged : ko.Observable<number> = ko.observable(0);

    constructor() {
        this.HasChanges = ko.computed(() => {
            return !!this.AllAddresses().firstOrDefault(a => a.isChanged() !== 0)
                || this.isChanged() !== 0;
        });
    }

    public load(addresses : IUoAddress[])
    {
        this.AllAddresses([]);

        if(!addresses)
            return;

        this.AllAddresses(addresses.map(this.createViewModelFor.bind(this)));

        this.isChanged(0);
    }

    private createViewModelFor(a : IUoAddress) : CustomerAddressViewModel
    {
        return new CustomerAddressViewModel(a);
    }

    public getData() : IUoAddress[]
    {
        return this.AllAddresses().map((a) => a.getData());
    }

    public AddNew(contextViewModel: UoAddresses, event: Event): void
    {
        let address = new CustomerAddressViewModel(null);
        this.AllAddresses.push(address);
        address.HasFocus(true);

        let element = event.currentTarget as HTMLElement;
        scrollIntoViewIfNeeded(element, {
            scrollMode: 'if-needed',
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          });
    }

    public Delete(a : CustomerAddressViewModel)
    {
        this.AllAddresses.remove(a);
    }

    public GetValidation(): IValidation[] {
        let validation = [];

        let addresses = this.AllAddresses();

        for (let address of addresses) {
            validation = validation.concat(address.GetValidation());
        }
        
        return validation;
    }

    public dispose() {

    }
}

@DetectClassChanges
export class CustomerAddressViewModel
{
    @DetectChanges
    Address : ko.Observable<string> = ko.observable();
    @DetectChanges
    CAP : ko.Observable<string> = ko.observable();
    @DetectChanges
    Municipality : ko.Observable<string> = ko.observable();
    @DetectChanges
    Province : ko.Observable<string> = ko.observable();
    @DetectChanges
    City : ko.Observable<string> = ko.observable();
    @DetectChanges
    State : ko.Observable<string> = ko.observable();
    @DetectChanges
    Label : ko.Observable<string> = ko.observable();

    HasFocus : ko.Observable<boolean> = ko.observable(false);

    FormattedAddressInfo : ko.Computed<string>;
    isValid: ko.Computed<boolean>;

    isChanged: ko.Observable<number> = ko.observable(0);

    private validator: IValidator<CustomerAddressViewModel>;

    @LazyImport(nameof<IValidationService>())
    private validationService: IValidationService;

    constructor(private address : IUoAddress)
    {
        this.Address(address ? address.Address : "");
        this.Municipality(address ? address.Municipality : "");
        this.Province(address ? address.Province : "");
        this.City(address ? address.City : "");
        this.State(address ? address.State : "");
        this.CAP(address ? address.CAP : "");
        this.Label(address ? address.Label : "");

        this.FormattedAddressInfo = ko.computed(() => {
            var result = "";
            result += this.CAP() ? this.CAP() + " " : "";
            result += this.City() ? this.City() : "";
            result += this.City() && (this.Municipality() || this.State()) ? " - " : "";
            result += this.Municipality() ? this.Municipality() + " " : " ";
            result += this.Province() ? "(" + this.Province() + ") " : "";
            result += this.State() ? this.State() : "";
            return result;
        });

        this.isValid = ko.computed(() => {
            if(!this.Address() || this.Address().trim().length == 0)
                return false;

            return true;
        });

        this.validator = this.validationService.createValidator<CustomerAddressViewModel>()
            .isNotNullOrUndefinedOrWhiteSpace((a => a.Address()), TextResources.Customers.MissingAddress);

        this.isChanged(0);
    }

    public getData() : IUoAddress
    {
        var data : IUoAddress = this.address ? this.address : {
            Address: null,
            CAP: null,
            City: null,
            Province: null,
            State: null,
            Municipality: null,
            Label: null
        };

        data.Address = this.Address();
        data.CAP = this.CAP();
        data.City = this.City();
        data.Province = this.Province();
        data.State = this.State();
        data.Municipality = this.Municipality();
        data.Label = this.Label();

        return data;
    }

    public GetValidation(): IValidation[] {
        return this.validator.validate(this);
    }

    public dispose(): void {

    }
}
