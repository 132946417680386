import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import "./Document";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, Param } from "../../../Core/utils/ComponentUtils";
import { Document, IDocumentListener } from "./Document";
import { DocumentsMenuDataSource, IDocumentForMenuDataSourceModel } from "../../../DataSources/DocumentsMenuDataSource";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { ArrayDataSource } from "../../../DataSources/ArrayDataSource";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { IDocumentForList, IDocumentsService } from "../../DocumentsService";
import { DocumentNumberPrintChoices } from "../enums/DocumentNumberPrintChoices";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { INavigationMenuComponentTemplatesProvider, INavigationMenuComponent, INavigationMenuComponentActionsGroup, INavigationMenuComponentAction, INavigationMenuComponentModel } from "../../../Components/NavigationMenuComponent/INavigationMenuComponent";
import { IInvoicesDateFilter } from "../../../ProlifeSdk/interfaces/invoice/IInvoice";
import { IInvoicesService, IEntity } from "../../../ProlifeSdk/interfaces/invoice/IInvoicesService";
import { IAuthorizationService } from "../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { IOPAService } from "../../../Core/interfaces/IOPAService";
import { DocumentClosingStatusIndicatorUI } from "../../../Components/DocumentClosingStatusIndicator";
import { IVatRegisters } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";

// eslint-disable-next-line no-var
declare var Layout : any;

const attributes = {
    Documents: "documents",
    InjectTo: "injectTo"
};

declare global {
   namespace JSX {
       interface IntrinsicElements {
           "documents-viewer": {
               params?: {
                   Documents: string;
               },

               "documents": Document[] | (() => string);
               "injectTo": (() => string);            
           } & HTMLAttributes<HTMLElement>
       }
   }
}

const { classes } = jss.createStyleSheet({
    documentsMenuWrapper: {
        "& .documents-menu-icon": {
            minWidth: "30px !important", 
            minHeight: "30px !important", 
            textAlign: "center",
            lineHeight: "30px !important",
            padding: 0,
    
            "& i": {
                color: "white"
            }
        }
    }
}).attach();

export interface IDocumentForMenuDataSourceModelWithViewModel extends IDocumentForMenuDataSourceModel {
    viewModel: ko.Observable<Document>;
}

export interface IDocumentsViewerParams {
    InjectTo: Param<DocumentsViewer>;
}

export class DocumentsViewer implements IDataSourceListener, INavigationMenuComponentTemplatesProvider, IDocumentListener {
    Documents : ko.ObservableArray<IDocumentForMenuDataSourceModelWithViewModel> = ko.observableArray();
    EditingDocument : ko.Observable<Document> = ko.observable();
    OnTopDocument : ko.Computed<IDocumentForMenuDataSourceModelWithViewModel>;

    DocumentsMenu: ko.Observable<INavigationMenuComponent> = ko.observable();
    DocumentsMenuDataSource = new DocumentsMenuDataSource();
    OpenedDocumentsDataSource = new ArrayDataSource<IDocumentForList>();
    
    private selectingItem: boolean;
    private lastFilters: IInvoicesDateFilter;
    private showingMenuRoot: ko.Observable<boolean> = ko.observable(true);
    private editingDocumentsBlockedState: ko.Observable<boolean> = ko.observable(false);

    private selectedDocumentsList: IDocumentForMenuDataSourceModel[] = [];

    @LazyImport(nameof<IInvoicesService>())
    private invoicesService: IInvoicesService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationService: IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;
    @LazyImport(nameof<IOPAService>())
    private opaService : IOPAService;
    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegistersManager : IVatRegisters;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    private boundOnBeforeUnload : () => Promise<boolean>;

    constructor(params : IDocumentsViewerParams) {
        this.boundOnBeforeUnload = this.onBeforeUnload.bind(this);
        this.opaService.BeforeLeaveAsync(this.boundOnBeforeUnload);
        /*$(window).on('beforeunload.documentsViewer', this.onBeforeUnload.bind(this));

        this.opaService.BeforeLeave("#/" + TextResources.Invoices.DocumentsURL, () => {
            const editingDocument = this.EditingDocument();
            if(!editingDocument)
                return true;
            
            const message = this.onBeforeUnload();
            if(message) {
                return confirm(message + "\n\n" + TextResources.Invoices.ContinueMsg);
            }
            
            return true;
        });*/

        this.OnTopDocument = ko.computed(() => {
            return this.Documents().firstOrDefault();
        });

        this.editingDocumentsBlockedState.subscribe((value: boolean) => {
            if (!value)
                this.selectedDocumentsList = [];
        });

        this.OpenedDocumentsDataSource.setTitleGetter((currentModel) => TextResources.Invoices.OpenedDocumentsMenuTitle);

        this.DocumentsMenu.subscribe((menu: INavigationMenuComponent) => {
            const actionsGroup: INavigationMenuComponentActionsGroup = {
                isGroup: true,
                isSeparator: false,
                icon: "fa fa-angle-down",
                visible: () => !this.showingMenuRoot(),
                actions: [],
            }

            let action: INavigationMenuComponentAction = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-filter",
                text: TextResources.Invoices.AdvancedFilter,
                action: this.openAdvancedFilterDialog.bind(this)
            };

            actionsGroup.actions.push(action);
            
            if (!this.authorizationService.isAuthorized("Documents_LockUnlock")) {
                menu.registerAction(actionsGroup);
                return;
            }

            action = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-pencil",
                text: TextResources.Invoices.ModifyStatus,
                action: () => { 
                    this.editingDocumentsBlockedState(true);
                    menu.setMultipleSelection({ multipleSelection: true, selectLeafsOnly: true, keepSelection: false });
                    menu.setSelectionDisabled(false);
                },
                canExecute: () => !this.editingDocumentsBlockedState()
            };
            actionsGroup.actions.push(action);

            action = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-check",
                text: TextResources.Invoices.Done,
                action: () => { 
                    this.editingDocumentsBlockedState(false);
                    menu.setMultipleSelection({ multipleSelection: false, selectLeafsOnly: true, keepSelection: false });
                    menu.setSelectionDisabled(true);
                },
                canExecute: () => this.editingDocumentsBlockedState()
            };
            actionsGroup.actions.push(action);
            
            action = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-lock",
                text: TextResources.Invoices.Block,
                action: this.lockSelectedDocuments.bind(this),
                canExecute: () => this.editingDocumentsBlockedState()
            };
            actionsGroup.actions.push(action);
            
            action = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-unlock",
                text: TextResources.Invoices.Unblock,
                action: this.unlockSelectedDocuments.bind(this),
                canExecute: () => this.editingDocumentsBlockedState()
            };
            actionsGroup.actions.push(action);

            // N.B.: Prima di usare questa funzione introdurre un diritto
            /* action = {
                isSeparator: false,
                isGroup: false,
                icon: "fa fa-trash-o",
                text: TextResources.Invoices.DeleteDocuments,
                action: this.deleteDocuments.bind(this),
                canExecute: () => this.editingDocumentsBlockedState()
            };
            actionsGroup.actions.push(action); */

            menu.registerAction(actionsGroup);
        });

        const injectTo = ComponentUtils.parseParameter(params.InjectTo, null);
        injectTo(this);
    }

    async OnClose(document: Document): Promise<void> {
        const model = this.Documents().firstOrDefault(d => d.viewModel() === document);
        if(!model)
            return;
        
        this.closeDocument(model);
    }

    async OnCopyToEditingDocument(document: Document): Promise<void> {
        const editingDocument = this.EditingDocument();
        if(!editingDocument)
            return;

        await editingDocument.copyFrom(document);

        const model = this.Documents().firstOrDefault(d => d.viewModel() === editingDocument);
        this.bringToFront(model);
    }
    
    async OnEdit(document: Document): Promise<void> {
        this.EditingDocument(document);
    }

    async OnEndEdit(document: Document): Promise<void> {
        if(this.EditingDocument() === document)
            this.EditingDocument(undefined);
    }

    private async openAdvancedFilterDialog(): Promise<void> {
        const result : IInvoicesDateFilter = await this.invoicesService.ShowDateFilterDialog(this.lastFilters?.StartDate, this.lastFilters?.EndDate, this.lastFilters?.SearchOnDocumentsContent)
        
        if (!result)
            return;

        this.lastFilters = result;
        this.DocumentsMenuDataSource.setStartDate(result.StartDate);
        this.DocumentsMenuDataSource.setEndDate(result.EndDate);
        this.DocumentsMenuDataSource.setSearchOnDocumentsContent(result.SearchOnDocumentsContent);

        this.DocumentsMenuDataSource.refresh();
    }

    private setSelectedDocumentsBlockedState(blocked : boolean) {
        this.selectedDocumentsList.forEach(d => {
            d.blocked = blocked;

            const openedDocument = this.Documents().firstOrDefault(od => od.id == d.id);
            if(openedDocument) {
                openedDocument.blocked = blocked;
                const vm = openedDocument.viewModel();
                if(vm) {
                    vm.Blocked(blocked);
                }
            }
        });

        this.DocumentsMenuDataSource.refresh();
    }

    private async lockSelectedDocuments(): Promise<void> {
        const documentsIds: IEntity[] = this.selectedDocumentsList.map((d) => {
            return {
                Id: d.id,
                EntityType: d.entityType
            };
        });
        
        await this.invoicesService.setFreezeStatusByDocumentsIds(true, documentsIds);

        this.setSelectedDocumentsBlockedState(true);
    }

    private async unlockSelectedDocuments(): Promise<void> {
        const documentsIds: IEntity[] = this.selectedDocumentsList.map((d) => {
            return {
                Id: d.id,
                EntityType: d.entityType
            };
        });

        this.invoicesService.setFreezeStatusByDocumentsIds(false, documentsIds);

        this.setSelectedDocumentsBlockedState(false);
    }

    private async deleteDocuments() : Promise<void> {
        const documentsIds: IEntity[] = this.selectedDocumentsList.map((d) => {
            return {
                Id: d.id,
                EntityType: d.entityType
            };
        });

        for(const doc of documentsIds) {
            await this.documentsService.DeleteDocument(doc.Id, doc.EntityType);
        }
    }

    private refreshOpenedDocumentsMenu() {
        this.OpenedDocumentsDataSource.setData(...this.Documents());
        this.OpenedDocumentsDataSource.refresh();
    }

    dispose() {
        //$(window).off('beforeunload.documentsViewer');
        this.opaService.RemoveBeforeLeaveAsync(this.boundOnBeforeUnload);
    }

    onItemSelected(sender: IDataSource, model: IDocumentForMenuDataSourceModel | IDocumentForMenuDataSourceModelWithViewModel): void {
        if (!model)
            return;

        if(sender == this.DocumentsMenuDataSource) {
            if (!this.editingDocumentsBlockedState()) {
                this.selectingItem = true;
                this.openDocument(model);
                this.selectingItem = false;
            } else {
                this.selectedDocumentsList.push(model);
            }
        } else if(sender == this.OpenedDocumentsDataSource) {
            this.bringToFront(model as IDocumentForMenuDataSourceModelWithViewModel);
        }
    }

    onNavigate(sender: IDataSource, ...path: IDataSourceModel[]): void {
        if (sender === this.DocumentsMenuDataSource) {
            if (path.length === 0)
                this.showingMenuRoot(true);
            else
                this.showingMenuRoot(false);
        }
    }

    bringToFront(model: IDocumentForMenuDataSourceModelWithViewModel) {
        if(!model)
            return;

        const actualIndex = this.Documents.indexOf(model);
        if(actualIndex == 0)
            return;
        
        
        const internalDocuments = this.Documents.peek();
        this.Documents.valueWillMutate();
        internalDocuments.splice(actualIndex, 1);
        internalDocuments.unshift(model);
        this.Documents.valueHasMutated();

        this.refreshOpenedDocumentsMenu();
    }

    onItemDeselected(sender: IDataSource, model: IDocumentForMenuDataSourceModel): void {

    }

    openDocument(model: IDocumentForMenuDataSourceModel) {
        const existingDocument = this.Documents().firstOrDefault(d => d.id == model.id);
        if (existingDocument) {
            this.bringToFront(existingDocument);
            return;
        }

        const docWithViewModel : IDocumentForMenuDataSourceModelWithViewModel = Object.assign({ 
            viewModel: ko.observable()
        }, model);

        this.Documents.unshift(docWithViewModel);
        this.bringToFront(docWithViewModel);
        this.refreshOpenedDocumentsMenu();
    }

    openDocumentById(documentId : number, documentType : string) {
        const existingDocument = this.Documents().firstOrDefault(d => d.id == documentId);
        if (existingDocument) {
            this.bringToFront(existingDocument);
            return;
        }

        const docWithViewModel = this.createModelFromIdAndType(documentId, documentType);
        this.Documents.unshift(docWithViewModel);
        this.bringToFront(docWithViewModel);
        this.refreshOpenedDocumentsMenu();
    }

    closeDocument(model: IDocumentForMenuDataSourceModelWithViewModel) {
        this.OnEndEdit(model.viewModel());

        this.Documents.remove(model);
        this.bringToFront(this.Documents().firstOrDefault());
        this.refreshOpenedDocumentsMenu();
    }

    newDocument(registerId : number, documentType : string, jobOrderId: number | null) {
        if(this.EditingDocument()) {
            this.infoToastService.Warning(TextResources.Invoices.AlreadyEditingAnotherDocument);
            return;
        }

        const docWithViewModel = this.createModelFromIdAndType(-1, documentType, registerId);
        this.Documents.unshift(docWithViewModel);
        this.bringToFront(docWithViewModel);
        this.refreshOpenedDocumentsMenu();
    }

    private createModelFromIdAndType(documentId : number, documentType : string, registerId = -1) : IDocumentForMenuDataSourceModelWithViewModel {
        const register = this.vatRegistersManager.getVatRegisterById(registerId);

        return {
            id: documentId,
            title: "",
            isLeaf: true,
            isGroup: false,
            registerId: registerId,
            entityType: documentType,
            viewModel: ko.observable(),

            billingStateBadge: null,
            blocked: false,
            creditNote: false,
            currencySymbol: null,
            documentDate: null,
            downPayment: false,
            hasWithholdingTax: false,
            isApproved: false,
            jobOrderName: null,
            partialInvoice: false,
            schedulesState: 0,
            showBillingStateBadge: false,
            showStateIcon: false,
            stateIcon: null,
            totalInDocumentCurrency: 0,

            model: {
                Id: -1,
                BillingLogicalStatus: null,
                Blocked: false,
                ClosureStatus: 0,
                Currency: null,
                Date: null,
                DownPayment: false,
                FKRegister: registerId,
                HasWithholdingTax: false,
                SchedulesStatus: 0,
                TotalInDocumentCurrency: 0,
                EntityType: documentType,
                ForPartialInvoicesRegister: register?.ForPartialInvoices,
                NumberPrintChoice: DocumentNumberPrintChoices.OnlyProtocolNumber,
                FKCurrency: 0,
                CurrencyCode: null,
                DocumentCurrencyId: 0,
                ExchangeValue: 0,
                ExchangeValueForVat: 0,
            },

            closingStatusIndicator: new DocumentClosingStatusIndicatorUI({
                documentId: documentId,
                documentType: documentType,
                protocolId: registerId,
                documentState: null,
                documentClosingState: 0,
                documentCauseLogicType: null,

                className: "btn-lg documents-menu-icon",
                content: () => <i class="fa fa-file-text"></i>
            })
        };
    }

    hasTemplateFor(dataSource: IDataSource, model: INavigationMenuComponentModel): boolean {
        const documentModel = model as IDocumentForMenuDataSourceModel;
        if (!documentModel.model.EntityType && dataSource !== this.OpenedDocumentsDataSource)
            return false;

        return true;
    }
    
    templatesProvider(dataSource: IDataSource, model: INavigationMenuComponentModel): Node {
        const documentModel = model as IDocumentForMenuDataSourceModel;
        if (!documentModel.model.EntityType && dataSource !== this.OpenedDocumentsDataSource)
            return null;

        if (dataSource === this.DocumentsMenuDataSource)
            return this.getDocumentTemplateForMenu();

        return this.getOpenedDocumentTemplateForMenu();
    }
    
    private getDocumentTemplateForMenu(): Node {
        const $data: IDocumentForMenuDataSourceModel = null;

        return  <>
                    <div class="col1 dual-column">
                        <div class="cont">
                            <div class="cont-col1">
                                <ko-bind data-bind={{ tsxtemplate: $data.closingStatusIndicator }}></ko-bind>
                                <i class="fa fa-check-circle" style="color: lime; display: block; margin-left: auto; margin-right: auto;" data-bind="visible: isApproved"></i>
                            </div>
                            <div class="cont-col2">
                                <div class="desc">
                                    <div data-bind="text: title"></div>
                                    <div class="label label-sm" data-bind="text: subTitle"></div>
                                    <div class="label label-sm" data-bind="css : { 'with-holding-tax' : hasWithholdingTax, 'font-red' : creditNote }, moneyTextEx: totalInDocumentCurrency, currencySymbol: currencySymbol"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col2">
                        <div class="date" data-bind="dateText: documentDate"></div>
                        <ko-if data-bind="showBillingStateBadge">
                        <div class="badge pull-right" style="color: white; width: 30px; position: relative; right: 10px;" data-bind="css: billingStateBadge.cssClass, attr: { title: billingStateBadge.title }">
                            <strong data-bind="text: billingStateBadge.text"></strong>
                        </div>
                        </ko-if>
                        <ko-if data-bind="showStateIcon">
                        <span class="pull-right">
                            <i  data-bind="css: stateIcon.icon + ' ' + stateIcon.class"></i>
                        </span>
                        </ko-if>
                    </div>
                </>;
    }

    private getOpenedDocumentTemplateForMenu(): Node {
        return  <>
                    <div class="col1" data-bind="with: viewModel">
                        <div class="cont">
                            <div class="desc">
                                <div data-bind="text: Recipient.FormattedName"></div>
                                <div class="label label-sm">
                                    <span data-bind="text: DocumentTypeLabel"></span>
                                    <span data-bind="text: Number" style="margin-left: 10px"></span>
                                </div>
                                <div class="label label-sm" data-bind="moneyTextEx: FinalTotalInDocumentCurrency, currencySymbol: DocumentCurrencySymbol" style="display: block; text-align: left;"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col2" data-bind="with: viewModel">
                        <ko-if data-bind="ReadOnly">
                            <button class="btn btn-xs btn-default" data-bind="click: Close">
                                <i class="fa fa-times"></i> {TextResources.ProlifeSdk.Close}
                            </button>
                        </ko-if>
                    </div>
                </>;
    }

    private async onBeforeUnload() {
        const editingDocument = this.EditingDocument();
        if(!editingDocument)
            return true;

        return await this.dialogsService.ConfirmAsync(TextResources.Invoices.UnsavedChanges, "Annulla", "Perdi le modifiche") ;
    }
    
    //Chiamata da knockout quando il componente ha finito il rendering
    public koDescendantsComplete() {
        Layout.fixContentHeight();
    }
}

ko.components.register("documents-viewer", {
    viewModel: {
        createViewModel: (params: IDocumentsViewerParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);
            
            const vm = new DocumentsViewer(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <div class={"page-sidebar-wrapper " + classes.documentsMenuWrapper}>
                        <navigation-menu params="DataSource: DocumentsMenuDataSource, InjectTo: DocumentsMenu, Listeners: $data, TemplatesProvider: $data, DisableSelection: true" class="page-sidebar navbar-collapse collapse"></navigation-menu>
                    </div>
                    <div class="page-content-wrapper">
                        <div class="page-content">
                            <div class="documents-viewer with-tabs">
                                <div class="documents-container" data-bind="firstOnlyWithSlideInOut: Documents">
                                    <document 
                                        style="display: block"
                                        fkRegister={() => "registerId"}
                                        documentId={() => "id"}
                                        documentType={() => "entityType"}
                                        viewer={() => "$component"}
                                        injectTo={() => "viewModel"}
                                        listener={() => "$parent"}
                                        ></document>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="page-quick-sidebar-wrapper">
                        <div class="page-quick-sidebar">
                            <navigation-menu params="DataSource: OpenedDocumentsDataSource, Listeners: $data, TemplatesProvider: $data, DisableSearch: true, DisableSelection: true" class="page-sidebar navbar-collapse collapse"></navigation-menu>
                        </div>
                    </div>
                </>
            ]);

            ComponentUtils.OnDestroy(componentInfo.element, vm.dispose.bind(vm));
            
            return vm;
        },
    },
    template: []
});