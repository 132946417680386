import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ISettingsService } from "../ProlifeSdk/interfaces/settings/ISettingsService";
import { IUserCharactersSettingsManager } from "../ProlifeSdk/interfaces/users/IUserCharacter";

export class UserCharacterDescription {
	update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

		var interceptor = ko.computed(() => {
			var options = valueAccessor();

			var settingsService = <ISettingsService> Core.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
			var userCharactersSettingsManager = <IUserCharactersSettingsManager> settingsService.findSettingsManager(ProlifeSdk.UserCharactersServiceType);

			var elements = userCharactersSettingsManager.getUserCharacterById(ko.utils.unwrapObservable(options.userCharacter));

			if (elements)
				return elements.Description;
			else
				return ko.utils.unwrapObservable(options.defaultDescription) || userCharactersSettingsManager.getDefault().Description;
		});

		ko.applyBindingsToNode(element, { text: interceptor }, undefined);
	}
}

ko.bindingHandlers["UserCharacterDescription"] = new UserCharacterDescription();
ko.virtualElements.allowedBindings["UserCharacterDescription"] = true;

