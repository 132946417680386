import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 01/03/13
 * Time: 16.26
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { WarehouseSectionGroup } from "./WarehouseSectionGroup";
import { Right } from "../../../Core/Authorizations";

export class WarehouseMenu {
    public links : WarehouseSectionGroup[];

    @Right("Warehouse_WarehouseInspections")
    private canViewWarehouseInspections: boolean;

    constructor(selectedSectionId : number)
    {
        let firstGroup = new WarehouseSectionGroup(ProlifeSdk.TextResources.Warehouse.Warehouses, [
            { Icon : "fa-building", Name : ProlifeSdk.TextResources.Warehouse.Management, Url : ProlifeSdk.TextResources.Warehouse.WarehouseManagementURL, Id : 2 },
            { Icon : "fa-shopping-cart", Name : ProlifeSdk.TextResources.Warehouse.Inventory, Url : ProlifeSdk.TextResources.Warehouse.InventoryURL, Id : 1 },
            { Icon : "fa-history", Name : ProlifeSdk.TextResources.Warehouse.StocksHistory, Url : ProlifeSdk.TextResources.Warehouse.StocksHistoryURL, Id : 7 },
            { Icon : "fa-retweet", Name : ProlifeSdk.TextResources.Warehouse.Movements, Url : ProlifeSdk.TextResources.Warehouse.MovementsURL, Id : 8 },
        ], selectedSectionId);

        if (this.canViewWarehouseInspections)
            firstGroup.addLink({ Icon : "fa-check-square-o", Name : ProlifeSdk.TextResources.Warehouse.WarehouseInspections, Url : ProlifeSdk.TextResources.Warehouse.WarehouseInspectionsURL, Id : 12 }, selectedSectionId);

        let secondGroup = new WarehouseSectionGroup(ProlifeSdk.TextResources.Warehouse.Discounts, [
            { Icon : "fa-file-o", Name: ProlifeSdk.TextResources.Warehouse.DiscountsCatalogs, Url : ProlifeSdk.TextResources.Warehouse.DiscountsCatalogsURL, Id: 10 },
            { Icon : "fa-group", Name : ProlifeSdk.TextResources.Warehouse.Customers, Url : ProlifeSdk.TextResources.Warehouse.CustomersDiscountsURL, Id : 3 },
            { Icon : "fa-group", Name : ProlifeSdk.TextResources.Warehouse.Suppliers, Url : ProlifeSdk.TextResources.Warehouse.SuppliersDiscountsURL, Id : 4 },
            { Icon : "fa-star-o", Name : ProlifeSdk.TextResources.Warehouse.PreferredSuppliers, Url : ProlifeSdk.TextResources.Warehouse.PreferredSuppliersURL, Id : 11 },
        ], selectedSectionId);

        let thirdGroup = new WarehouseSectionGroup(ProlifeSdk.TextResources.Warehouse.Articles, [
            { Icon : "fa-file-o", Name : ProlifeSdk.TextResources.Warehouse.Catalogs, Url : ProlifeSdk.TextResources.Warehouse.CatalogsURL, Id : 5 },
            { Icon : "fa-file-text-o", Name : ProlifeSdk.TextResources.Warehouse.ArticlesList, Url : ProlifeSdk.TextResources.Warehouse.ArticlesListURL, Id : 6 },
            { Icon : "fa-share-alt", Name: ProlifeSdk.TextResources.Warehouse.Transforms, Url : ProlifeSdk.TextResources.Warehouse.TransformsURL, Id : 9 }
        ], selectedSectionId);

        this.links = [
            firstGroup,
            secondGroup,
            thirdGroup
        ];
    }

    dispose() {
        this.links = [];
    }
}