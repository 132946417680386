import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ITodoListService } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListWorkflow } from "../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";

export interface IWorkflowDisplayNameOptions {
    id: number;
    defaultDescription: string;
    serviceLocator: IServiceLocator;
}

export class WorkflowDisplayName
{
    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext) : void {

        var options = valueAccessor();
        var todoListService = <ITodoListService> Core.serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        var name : ko.Observable<string> = ko.observable(options.defaultDescription || "");
        todoListService.GetWorkflow(ko.utils.unwrapObservable(options.id)).then((g : ITodoListWorkflow) => {
            if(g)
                name(g.Title);
        });

        var interceptor = ko.computed(() => {
            return name();
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["workflowDisplayName"] = new WorkflowDisplayName();
ko.virtualElements.allowedBindings["workflowDisplayName"] = true;

