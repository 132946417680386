import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import moment = require("moment");
import { ComponentUtils, reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport, LazyImportSettingManager } from "../../../Core/DependencyInjection";
import { DocumentResource } from "./DocumentResources";
import { IDocumentsService } from "../../DocumentsService";
import {
    DocumentResourcesSettings,
    IDocumentResourcesSettingsManager,
} from "../../../ProlifeSdk/interfaces/invoice/settings/IDocumentResourcesSettingsManager";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { IHumanResourcesSettingsManager } from "../../../Users/Users/Settings/HumanResourcesSettingsManager";
import {
    DocumentResourceAssignmentsDetails,
    DocumentResourceAssignment,
} from "../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IInfoToastService } from "../../../Core/interfaces/IInfoToastService";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody } from "../../../Components/TableComponent/CustomColumn";
import { DocumentClosingStatusIndicator } from "../../../Components/DocumentClosingStatusIndicator";
import { NumericText } from "../../../Components/NumericText";
import { DateTimeText } from "../../../Components/DateTimeText";
import { ResponseError } from "../../../Core/response/ResponseBase";
import { If } from "../../../Components/IfIfNotWith";
import { NumberInput } from "../../../Components/NumberInput";
import { DataSourceType, DocumentFieldsReader } from "./DocumentFieldsReader";
import { ResourceAssignments } from "./ResourceAssignments";

const styleSheet = jss.createStyleSheet({
    "assignments-details": {
        minWidth: "900px",

        "& .resource-col": {
            width: "auto",
        },

        "& .document-col": {
            width: "70px",
        },

        "& .row-col": {
            width: "45px",
        },

        "& .from-col": {
            width: "100px",
        },

        "& .to-col": {
            width: "100px",
        },

        "& .total-col": {
            width: "70px",
        },

        "& .period-total-col": {
            width: "100px",
        },

        "& .month-col": {
            width: "60px",
        },

        "& .read-only-number": {
            padding: "0 11px",
        },
    },
});
const { classes } = styleSheet.attach();

type DocumentResourceAssignmentsDetailsProps = {
    resource: DocumentResource;
    alreadyAssignedAmount: number;
    documentFeldsReader: DocumentFieldsReader;
};

export class DocumentResourceAssignmentsDetailsViewer extends DialogComponentBase {
    static defaultProps: Partial<DocumentResourceAssignmentsDetailsProps> = {};

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    @LazyImportSettingManager(nameof<IDocumentResourcesSettingsManager>())
    private documentResourcesSettingsManager: IDocumentResourcesSettingsManager;
    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesSettingsManager: IHumanResourcesSettingsManager;

    private resourceAssignments = ko.observable<DocumentResourceAssignmentsDetails>();
    private resourceName: ko.Observable<string> = ko.observable("");
    private showWarning: ko.Observable<boolean> = ko.observable(false);

    private documentHoursAmount: ko.Observable<number> = ko.observable(0);
    private conversionFactor: ko.Observable<number> = ko.observable(0);
    private documentTotalHoursAmountWithConversionFactor: ko.Observable<number> = ko.observable(0);
    private documentResidualHoursAmountWithConversionFactor: ko.Observable<number> = ko.observable(0);

    private settings: DocumentResourcesSettings;
    private subscriptions: ko.Subscription[] = [];

    constructor(private props: DocumentResourceAssignmentsDetailsProps) {
        super({ popover: true });

        this.setResourceName();
    }

    componentDidMount() {
        const resource = this.props.resource;

        this.subscriptions = [
            resource.ResourceId.subscribe(() => {
                this.setResourceName();
                this.loadData();
            }),
            resource.StartDate.subscribe(() => this.loadData()),
            resource.EndDate.subscribe(() => this.loadData()),
            this.documentHoursAmount.subscribe(() => this.calculateDocumentHoursAmountWithConversionFactor()),
            this.conversionFactor.subscribe(() => this.calculateDocumentHoursAmountWithConversionFactor()),
        ];

        this.loadSettings();
        this.loadData();
    }

    close() {
        this.subscriptions.forEach((s) => s.dispose());
        super.close();
    }

    private calculateDocumentHoursAmountWithConversionFactor(): void {
        const hoursAmount = this.documentHoursAmount() ?? 0;
        const conversionFactor = this.conversionFactor() ?? 1;
        const totalHoursAmount = hoursAmount * conversionFactor;

        this.documentTotalHoursAmountWithConversionFactor(totalHoursAmount);
        this.documentResidualHoursAmountWithConversionFactor(totalHoursAmount - this.props.alreadyAssignedAmount);
    }

    private setResourceName(): void {
        const resourceId = this.props.resource.ResourceId();
        if (!resourceId) {
            this.resourceName(TextResources.ProlifeSdk.NotAvailable);
            this.title(
                String.format(
                    TextResources.Invoices.DocumentResourceAssignmentsDetailsTitle,
                    TextResources.ProlifeSdk.NotAvailable
                )
            );
        } else {
            const resource = this.humanResourcesSettingsManager.getHumanResourceById(resourceId);
            const resourceName = this.humanResourcesSettingsManager.getFullName(resource);

            this.resourceName(resourceName);
            this.title(String.format(TextResources.Invoices.DocumentResourceAssignmentsDetailsTitle, resourceName));
        }
    }

    private loadSettings(): void {
        this.settings = this.documentResourcesSettingsManager.getSettings();
        this.conversionFactor(this.settings.totalHoursConversionFactor);

        this.readHoursAmountFromDocument();
    }

    private readHoursAmountFromDocument() {
        if (this.settings?.totalHoursToAssignDataSource && this.settings?.totalHoursToAssignSourceField) {
            const hoursAmount = this.props.documentFeldsReader.getValue(
                this.settings.totalHoursToAssignDataSource as DataSourceType,
                this.settings.totalHoursToAssignSourceField
            );
            this.documentHoursAmount(hoursAmount);
        }
    }

    private async loadData(): Promise<void> {
        const resource = this.props.resource;
        const resourceId = resource.ResourceId();
        const fromDate = resource.StartDate();
        const toDate = resource.EndDate();

        if (!resourceId || !fromDate || !toDate) {
            this.showWarning(true);
            return;
        }

        this.showWarning(false);

        try {
            const resourceDetailedAssignemntsResponse = await this.documentsService.GetResourceDocumentsAssignments(
                [resourceId],
                fromDate,
                toDate
            );

            if (!resourceDetailedAssignemntsResponse.succeeded) {
                this.handleManagedErrors(resourceDetailedAssignemntsResponse.errors);
                return;
            }

            const resourceDetailedAssignemnts = resourceDetailedAssignemntsResponse.data.firstOrDefault();
            if (!resourceDetailedAssignemnts) {
                this.resourceAssignments(null);
            } else {
                this.resourceAssignments(resourceDetailedAssignemnts);
            }
        } catch (e) {
            console.error(e);

            if (Array.isArray(e) && e.length > 0 && e[0].code) {
                this.handleManagedErrors(e);
                return;
            }

            this.infoToastService.Error(TextResources.ProlifeSdk.GenericError);
        }
    }

    private handleManagedErrors(errors: ResponseError[]): void {
        const error = errors.map((e) => TextResources.Invoices[e.code]).join("<br />");
        this.infoToastService.Error(error);
    }

    async show(element: HTMLElement): Promise<void> {
        return this.dialogsService.ShowPopoverComponent(element, this, "bottom");
    }

    renderBody() {
        const drd = this;

        return ComponentUtils.bindTo(
            <div className={ComponentUtils.classNames("row", classes["assignments-details"])}>
                <div className="col-md-9">
                    <ResourceAssignments
                        resourceAssignments={this.resourceAssignments}
                        from={this.props.resource.StartDate}
                        to={this.props.resource.EndDate}
                    />
                </div>
                <div className="col-md-3">
                    <NumberInput
                        value={this.documentHoursAmount}
                        format="0,0[.][00]"
                        label={TextResources.Invoices.DocumentHoursToAssign}
                    />
                    <NumberInput
                        value={this.conversionFactor}
                        format="0,0[.][00]"
                        label={TextResources.Invoices.DocumentHoursToAssignConversionFactor}
                    />
                    <div className="form-group">
                        <label className="control-label">
                            {TextResources.Invoices.DocumentHoursAmountWithConversionFactor}
                        </label>
                        <div className="text-right read-only-number">
                            <NumericText
                                value={this.documentTotalHoursAmountWithConversionFactor}
                                format="0,0[.][00]"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label">{TextResources.Invoices.AlreadyAssignedAmount}</label>
                        <div className="text-right read-only-number">
                            <NumericText value={this.props.alreadyAssignedAmount} format="0,0[.][00]" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label">
                            {TextResources.Invoices.DocumentResidualHoursAmountWithConversionFactor}
                        </label>
                        <div className="text-right read-only-number">
                            <NumericText
                                value={this.documentResidualHoursAmountWithConversionFactor}
                                format="0,0[.][00]"
                            />
                        </div>
                    </div>
                </div>
            </div>,
            this,
            "drd"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(DocumentResourceAssignmentsDetailsViewer);
}
