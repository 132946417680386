import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentRowInlineReferenceProviderFactory, IDocumentInfoForInlineRefProvider, IDocumentRowInlineReferenceProvider } from "../../../../ProlifeSdk/prolifesdk/documents/converters/RefConverterBase";
import { IEntityForRef } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentRow";
import { RowToNewPurchaseArticleRefConverter } from "./RowToNewPurchaseArticleRefConverter";
import { IPurchaseTypeSettingManager } from "../../../../SettingManagers/PurchaseTypeSettingManager";

export class RowToNewPurchaseArticleRefConverterProvider implements IDocumentRowInlineReferenceProviderFactory
{
    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypes : IPurchaseTypeSettingManager;

    public ActivationCode = "@05";
    public EntityType = ProlifeSdk.WarehousePurchaseArticleEntityTypeCode;
    public Icon = "fa fa-barcode";
    public Description = ProlifeSdk.TextResources.Blog.NewPurchase;
    public DefaultActivation = true;
    public SupportedDocTypes : string[] = [
        ProlifeSdk.SupplierOrderEntityTypeCode,
        ProlifeSdk.WarehouseLoadEntityTypeCode
    ];

    public async findEntities(query : string, skip : number, count : number, docInfo : IDocumentInfoForInlineRefProvider) : Promise<IEntityForRef[]>
    {
        const allPurchaseTypes = [{
            Id: null,
            Default: false,
            Title: "Senza Tipologia"
         }].concat(this.purchaseTypes.getAll());
        return allPurchaseTypes.map(pt => ({
                Id : -1, 
                EntityType: ProlifeSdk.WarehousePurchaseArticleEntityTypeCode,
                Type: pt.Id,
                SubTitle: pt.Title,
                Description: query,
                Icon: {
                    background: "white",
                    foreground: "black",
                    icon: "fa fa-shopping-cart"
                }
            })
        )
    }

    public create(document : IDocumentInfoForInlineRefProvider) : IDocumentRowInlineReferenceProvider
    {
        return new RowToNewPurchaseArticleRefConverter(this.ActivationCode, document);
    }
}
