import * as ko from "knockout";

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { MilestoneInput } from "../EventsDetails/MilestoneInput";
import { IContextEventsObserver } from "../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../ProlifeSdk/interfaces/IView";
import { IEventBaseInput } from "../../../ProlifeSdk/interfaces/blog/IEventBaseInput";

export class MilestoneBlogEvent extends BlogEvent implements IView
{
    public viewModel : any;

    constructor (serviceLocator: IServiceLocator, contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator,contextEventsObserver);
        this.iconClass = "fa-flag";
        this.eventTypeName = ProlifeSdk.TextResources.Blog.MilestoneBlogTitle;
        this.templateName = "milestoneEvent";
        this.Clear();
    }

    getEditViewModel() : IEventBaseInput
    {
        return new MilestoneInput(this.serviceLocator, this.contextEventsObserver, this);
    }

    public LoadLocalTags()
    {
        super.loadTags();
    }
}