import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";

export abstract class WizardDialogStepBase<TInput = unknown, TResult = unknown> {
    IsSelected: ko.Observable<boolean> = ko.observable(false);
    IsVisible: ko.Observable<boolean> = ko.observable(true);

    Title: ko.Observable<string> = ko.observable();

    abstract OnShow(previousStepResult: TInput): void | Promise<void>;
    abstract OnNext(): TResult | Promise<TResult>;

    abstract render(): React.ReactNode;

    CanGoNext(): boolean {
        return true;
    }
    BeforeBack(): boolean {
        return true;
    }

    GetWarnings(): string[] {
        return [];
    }
}
