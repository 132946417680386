import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService, AjaxOptions } from "../Core/interfaces/IAjaxService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { IAuthenticationService } from "../Core/interfaces/IAuthenticationService";
import { IAjaxFilter, IXHR } from "../Core/interfaces/IAjaxFilterHostService";
import { IService } from "../Core/interfaces/IService";
import { IEntitiesLockService } from "../ProlifeSdk/interfaces/desktop/IEntitiesLockService";

export class EntitiesLockService extends ProLifeService implements IEntitiesLockService
{
    private ajaxService : IAjaxService;

	constructor(private serviceLocator : IServiceLocator)
    {
        super(ProlifeSdk.DesktopApplicationCode);
        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
        (this.ajaxService as any).addFilter(new LockExceptionHandler(serviceLocator));
		serviceLocator.registerServiceInstance(this);
		serviceLocator.registerServiceInstanceWithName(nameof<IEntitiesLockService>(), this);
	}

    public LockEntity(entityType : string, entityKeyId : number) : Promise<void>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "LockEntity", { methodData: {
            EntityType : entityType,
            EntityKeyId : entityKeyId
        }, background : true });
    }

    public UnlockEntity(entityType : string, entityKeyId : number) : Promise<boolean>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "UnlockEntity", { methodData: {
            EntityType : entityType,
            EntityKeyId : entityKeyId
        }, background : true });
    }

    public UnlockEntitiesLockedFromMe() : Promise<void>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "UnlockEntitiesLockedFromMe", { methodData: { },
            background : true });
    }

    public ForceUnlockForEntity(entityType : string, entityKeyId : number) : Promise<void>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "ForceUnlockForEntity", { methodData: {
            EntityType : entityType,
            EntityKeyId : entityKeyId
        }, background : true });
    }

    public IsEntityLockedByOthers(entityType : string, entityKeyId : number) : Promise<boolean>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "IsEntityLockedByOthers", { methodData: {
            EntityType : entityType,
            EntityKeyId : entityKeyId
        }, background : true });
    }

    public GetLockedEntitiesIds(entityType : string) : Promise<number[]>
    {
        return this.ajaxService.Post("Desktop-api/EntitiesLock", "GetLockedEntitiesIds", { methodData: {
            EntityType : entityType
        }, background : true });
    }

	getServiceType() : string
    {
		return ProlifeSdk.EntitiesLockServiceType;
	}

	isOfType(serviceType: string) : boolean
    {
		return serviceType == this.getServiceType();
	}

}

export class LockExceptionHandler implements IAjaxFilter
{
    private infoToastService : IInfoToastService;
    private authenticationService : IAuthenticationService;
    private toastService : IInfoToastService;

    constructor(private serviceLocator : IServiceLocator)
    {
        this.infoToastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
        this.authenticationService = <IAuthenticationService> serviceLocator.findService(ServiceTypes.Authentication);
        this.toastService = <IInfoToastService> serviceLocator.findService(ServiceTypes.InfoToast);
    }

    onStarting(parameters: JQueryAjaxSettings): Promise<any>
    {
        return null;
    }

    onComplete(options: AjaxOptions, jqXHR: JQueryXHR, textStatus: string): boolean
    {
        return false;
    }

    onSuccess(options: AjaxOptions, data: any, textStatus: string, jqXHR: JQueryXHR): boolean
    {
        return false;
    }

    onError(options: AjaxOptions, jqXHR: IXHR, textStatus: string, errorThrown: any): boolean
    {
        try
        {
            const deserializedError = JSON.parse(jqXHR.responseText || "null");

            if(deserializedError && deserializedError.ExceptionType.indexOf("ProLifeEntityLockException") > -1)
                this.infoToastService.Error(ProlifeSdk.TextResources.Desktop.ObjectAlreadyInUse);
        }
        catch(e)
        {
            console.log(e);
        }

        return false;
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
	return new EntitiesLockService(serviceLocator);
}
