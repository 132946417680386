import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { IPrintTemplateSettingsManager } from "../../Invoices/invoices/settings/PrintTemplateSettingsManager";
import { LazyImport, LazyImportSettingManager } from "../../Core/DependencyInjection";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { ReportPrintSelector } from "./ReportPrintSelector";
import { IVatRegisters } from "../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { IAjaxServiceNew } from "../../Core/interfaces/IAjaxService";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";

type ReportPrintButtonProps = {
    documentId: number;
    registerId: number;
};

export function ReportPrintButton(props: ReportPrintButtonProps) {
    const C = require("./ReportPrintButton")._ReportPrintButton as typeof _ReportPrintButton;
    return <C {...props} />;
}

export class _ReportPrintButton {
    static defaultProps: Partial<ReportPrintButtonProps> = {};

    @LazyImportSettingManager(nameof<IPrintTemplateSettingsManager>())
    private printTemplateSettingsManager: IPrintTemplateSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.VatRegisters)
    private vatRegisters: IVatRegisters;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    constructor(private props: ReportPrintButtonProps) {}

    async printOrOpenSelector(_1, event: Event) {
        const vatRegisterInfo = this.vatRegisters.getVatRegisterById(this.props.registerId);
        if (vatRegisterInfo.printTemplates.length === 0) return this.openSelector(_1, event);

        const printTemplates = this.printTemplateSettingsManager
            .getPrintTemplates()
            .filter((pt) => vatRegisterInfo.printTemplates.indexOf(pt.id) !== -1);
        if (vatRegisterInfo.preferredPrintTemplateId) {
            const preferredPrintTemplate = printTemplates.firstOrDefault(
                (pt) => pt.id === vatRegisterInfo.preferredPrintTemplateId
            );
            if (preferredPrintTemplate) {
                this.print(preferredPrintTemplate.id);
                return;
            }
        }

        return this.openSelector(_1, event);
    }

    async print(templateId: number) {
        this.dialogsService.LockUI(TextResources.Invoices.PrintingInProgress);
        let printResult: { blob: Blob; fileName: string };

        try {
            printResult = await this.ajaxServiceNew.DownloadOnly("q/api/ReportDesigner", "print", {
                background: false,
                methodData: {
                    templateId: templateId,
                    parameters: [
                        {
                            name: "EstimateId",
                            value: this.props.documentId,
                        },
                    ],
                },
            });
        } catch {
            //Info toast
            return;
        } finally {
            this.dialogsService.UnlockUI();
        }

        class SimpleDialog extends DialogComponentBase {
            dataUrl: string;

            constructor() {
                super({ className: "fullscreen", noPrompt: true });

                this.title("Stampa Documento");
                this.dataUrl = URL.createObjectURL(printResult.blob);
            }

            componentWillUnmount() {
                if (this.dataUrl) {
                    URL.revokeObjectURL(this.dataUrl);
                    this.dataUrl = null;
                }
            }

            renderFooter() {
                return (
                    <>
                        <a className="btn btn-primary" href={this.dataUrl} download={printResult.fileName}>
                            <i className="fa fa-download" />
                            &nbsp;{TextResources.Core.Download}
                        </a>
                        <a href="#" class="btn btn-default" data-bind="click: close">
                            {this.params.closeLabel ?? TextResources.ProlifeSdk.Close}
                        </a>
                    </>
                );
            }

            renderBody() {
                return (
                    <iframe
                        src={this.dataUrl}
                        style={{ position: "absolute", inset: 0, width: "100%", height: "100%", border: 0 }}
                    ></iframe>
                );
            }
        }

        await this.dialogsService.ShowModal(new SimpleDialog());
    }

    openSelector(_1, event: Event) {
        return this.dialogsService.ShowPopoverComponent(
            event.currentTarget as HTMLElement,
            {
                title: "Stampa Documento",
                content: () => <ReportPrintSelector {...this.props} onPrint={this.print.bind(this)} />,
            },
            "bottom"
        );
    }

    render() {
        const _rpb = this;

        return ComponentUtils.bindTo(
            <div className="btn-group btn-sm" style={{ padding: 0 }}>
                <div className="btn blue btn-sm" data-bind={{ click: _rpb.printOrOpenSelector.bind(_rpb) }}>
                    <i className="fa fa-print" />
                </div>
                <div className="btn blue btn-sm dropdown-toggle" data-bind={{ click: _rpb.openSelector.bind(_rpb) }}>
                    <i className="fa fa-angle-down" />
                </div>
            </div>,
            this,
            "_rpb"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(ReportPrintButton);
}
