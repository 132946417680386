/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 18/03/13
 * Time: 15.07
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";

export abstract class BasePagedList<T = any, K extends { dispose?: () => void } = any> {
    @LazyImport(nameof<IDialogsService>())
    public dialogService: IDialogsService;

    public pages: ko.ObservableArray<number> = ko.observableArray([0]);
    public selectedPage: ko.Observable<number> = ko.observable(0);
    public rows: ko.ObservableArray<K> = ko.observableArray([]);

    public isOnFirstPage: ko.Observable<boolean> = ko.observable(true);
    public isOnLastPage: ko.Observable<boolean> = ko.observable(true);

    public rowsPerPage = 25;
    private totalRows = 0;

    public Loading: ko.Observable<boolean> = ko.observable(false);
    public SomeFilterIsChanged: ko.Observable<boolean> = ko.observable(false);

    protected subscriptions: ko.Subscription[] = [];

    constructor(public searchFilter: ko.Observable<string> = ko.observable("")) {
        this.subscriptions.push(
            this.searchFilter.subscribe(() => {
                this.SomeFilterIsChanged(true);
            })
        );
    }

    public dispose() {
        this.subscriptions.forEach((s) => s.dispose());
        this.rows().forEach((row) => row.dispose && row.dispose());
    }

    public ExportExcel() {
        //Implementare nelle derivate
    }

    public Print() {
        //Implementare nelle derivate
    }

    public loadRows() {
        this.fetchRowsCount().then((rowsCount: number) => {
            this.setCount(rowsCount);
            this.loadPage();
        });
    }

    public abstract fetchRows(): Promise<T[]>;
    public abstract fetchRowsCount(): Promise<number>;
    public abstract createViewModelFor(row: T): K;

    public loadPage(): void {
        this.Loading(true);
        this.rows([]);
        this.SomeFilterIsChanged(false);

        this.fetchRows()
            .then(this.pushRows.bind(this))
            .finally(() => {
                this.Loading(false);
            });
    }

    protected pushRows(rows: T[]) {
        this.rows().forEach((row) => row.dispose && row.dispose());
        this.rows(rows.map(this.createViewModelFor.bind(this)));
    }

    protected setCount(rowsCount: number) {
        this.totalRows = rowsCount;
        this.selectedPage(0);
        this.recalculatePages();
    }

    public goToPage(pageIndex: number) {
        if (this.SomeFilterIsChanged()) return;

        this.selectedPage(pageIndex);
        this.recalculatePages();
        this.loadPage();
    }

    public goToPrevPage() {
        if (this.SomeFilterIsChanged()) return;

        if (this.isOnFirstPage()) return;
        this.goToPage(this.selectedPage() - 1);
    }

    public goToNextPage() {
        if (this.SomeFilterIsChanged()) return;

        if (this.isOnLastPage()) return;
        this.goToPage(this.selectedPage() + 1);
    }

    private recalculatePages() {
        const pages = [];
        const maxPages = Math.max(1, Math.ceil(this.totalRows / this.rowsPerPage));
        for (let i = Math.max(0, this.selectedPage() - 5); i < this.selectedPage(); i++) pages.push(i);
        pages.push(this.selectedPage());
        for (let i = this.selectedPage() + 1; i < Math.min(maxPages, this.selectedPage() + 5); i++) pages.push(i);

        this.pages(pages);
        this.isOnFirstPage(this.selectedPage() == 0);
        this.isOnLastPage(maxPages == this.selectedPage() + 1);
    }
}
