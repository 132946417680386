import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 28/08/2017
 * Time: 17:39
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { SurveyWizardPanelViewModel } from "./ui/SurveyWizardPanelViewModel";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IOPAService } from "../../Core/interfaces/IOPAService";
import { IApplicationsService } from "../../Desktop/interfaces/IApplicationsService";
import { IApplication } from "../../Desktop/interfaces/IApplication";
import { Deferred } from "../../Core/Deferred";

const csss = require('./ui/css/surveyWizard.scss');

export class SurveyWizardApplication implements IApplication {
    templateName: string = "surveywizard-panel";
    templateUrl: string = "surveywizard/templates/";
    templateData: any;
    public tileColor : string = 'tile-blue';

    public canShowSidebar: ko.Observable<boolean> = ko.observable(false);

    private mainPanel: SurveyWizardPanelViewModel;

    private applicationsService: IApplicationsService;

    constructor(private serviceLocator: IServiceLocator) {
        this.applicationsService = <IApplicationsService> serviceLocator.findService(ProlifeSdk.ApplicationsServiceType);
        this.applicationsService.registerApplication(this);
    }

    getName(): string {
        return ProlifeSdk.TextResources.SurveyWizard.SurveyWizardApplication;
    }

    getIcon(): string {
        return "fa fa-phone";
    }

    registerRoutes(): void {
        var opaService = <IOPAService> this.serviceLocator.findService(ServiceTypes.OPA);
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl, (context) => this.openSplashPage());
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/:id", (context) => this.openCampaign(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/:campaignId/" + ProlifeSdk.TextResources.SurveyWizard.WizardUrl + "/:queueId", (context) => this.openWizard(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "/:campaignId/" + ProlifeSdk.TextResources.SurveyWizard.WizardUrl + "/:queueId/:resumed", (context) => this.openWizard(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/Inbound/Wait", (context) => this.openWaitingForInbound(context));
        opaService.Get("#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "/Inbound/:callId/:phoneNumber/:calledPhoneNumber/:ivrCode/:questionnaireId", (context) => this.openInbound(context));

        var regExp: RegExp = new RegExp("#\/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl + "\/" + ProlifeSdk.TextResources.SurveyWizard.CampaignUrl + "\/([0-9]+)\/" + ProlifeSdk.TextResources.SurveyWizard.WizardUrl + "\/([0-9]+)(\/Resumed)?");
        opaService.BeforeLeave(<any>regExp, () => {
            if (!this.templateData.CurrentViewModel().ShowPrompt)
                return true;
            return confirm(ProlifeSdk.TextResources.SurveyWizard.WizardExit);
        });

    }

    getApplicationRoute(): string {
        return "#/" + ProlifeSdk.TextResources.SurveyWizard.SurveyWizardUrl;
    }

    getApplicationCode(): string {
        return "SurveyWizard";
    }

    onGoingDown(): void {
        if(this.mainPanel)
            this.mainPanel.dispose();

        this.mainPanel = null;
    }

    private initializePanel() : Promise<void>
    {
        var def = new Deferred<void>();

        if(this.mainPanel)
        {
            def.resolve();
            return def.promise();
        }

        this.mainPanel = new SurveyWizardPanelViewModel(this.serviceLocator);
        this.mainPanel.initialize().then(() => {
            this.templateData = this.mainPanel;

            this.applicationsService.startApplication(this);
            def.resolve();

        });

        return def.promise();
    }

    private openSplashPage() {
        this.initializePanel().then(() => {
            this.mainPanel.OpenSplashPage();
        });
    }

    private openCampaign(context: any): void {

        var campaignId = parseInt(context.params["id"]);

        this.initializePanel()
            .then(() => {
                if(campaignId > 0)
                    this.mainPanel.OpenCampaign(campaignId);
        });
    }

    private openWizard(context: any): void {

        var campaignId = parseInt(context.params["campaignId"]);
        var queueId = parseInt(context.params["queueId"]);
        var resumed = context.params["resumed"];

        this.initializePanel()
            .then(() => {
                if(campaignId > 0)
                    this.mainPanel.OpenWizard(campaignId, queueId, resumed);
            });
    }

    private openWaitingForInbound(context: any): void {

        this.initializePanel()
            .then(() => {
                this.mainPanel.OpenWaitingForInbound();
            });
    }

    private openInbound(context: any): void {

        var callId = context.params["callId"];
        var phoneNumber = context.params["phoneNumber"];
        var calledPhoneNumber = context.params["calledPhoneNumber"];
        var ivrCode = context.params["ivrCode"];
        var questionnaireId = context.params["questionnaireId"];

        this.initializePanel()
            .then(() => {
                this.mainPanel.OpenInbound(callId, phoneNumber, calledPhoneNumber, ivrCode, questionnaireId);
            });
    }
}