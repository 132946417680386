import * as ko from "knockout";
import { ITodoListTaskTag } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";

export class TodoListTaskTags
{
    public TagsValue : ko.Observable<string> = ko.observable("");
    private InitialTags : ko.ObservableArray<ITodoListTaskTag> = ko.observableArray([]);

    constructor(private typeToManage : string, private fieldToManage : string)
    {
    }

    public CancelChanges()
    {
        this.LoadFromModel(this.InitialTags());
    }

    public LoadFromModel(tags : ITodoListTaskTag[])
    {
        //Implementare nele derivate
        this.InitialTags(tags);
    }

    public SearchTags(query : any)
    {
        //Implementare nele derivate
    }

    public SetTagsOnCollection(list : ITodoListTaskTag[])
    {
        this.ExtractTagsByField(list)
            .forEach((t1 : ITodoListTaskTag) => { return list.splice(list.indexOf(t1), 1); });

        if(this.TagsValue().length == 0)
            return [];

        var tags : string[] = this.TagsValue().split("|");

        tags.map((serializedTag : string) => {
            return JSON.parse(serializedTag);
        }).forEach((t) => {
            list.push(t);
        });

        return [];
    }

    public GetStartTagsForListControl(element, callback)
    {
        var values = <string>$(element).val();
        if(values !== "")
        {
            var result : any[] = [];
            var tags : string[] = values.split("|");
            tags.forEach((serializedTag : string) =>
            {
                var object : ITodoListTaskTag = JSON.parse(serializedTag);
                result.push({id: serializedTag, text: object.Value});
            });
            callback(result);
        }
    }

    public ExtractTagsByField(tags : ITodoListTaskTag[])
    {
        return tags.filter((t : ITodoListTaskTag) => { return t.Field == this.fieldToManage; })
    }

    public ExtractTagsByType(tags : ITodoListTaskTag[])
    {
        return tags.filter((t : ITodoListTaskTag) => { return t.Type == this.typeToManage; })
    }

    public FormatAsTag(value : string)
    {
        return <ITodoListTaskTag>{
            Type : this.typeToManage,
            Field : this.fieldToManage,
            Value : value
        }
    }
}
