import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { BindTo } from "../../../../../Components/Bind";

export interface IRequestForQuotationResponseStateHelperProps {
    response: {
        ResponseDate? : ko.MaybeSubscribable<Date>;
        ResponseAmount? : ko.MaybeSubscribable<number>;
        ResponsePrice? : ko.MaybeSubscribable<number>;
        ResponseDeliveryDate? : ko.MaybeSubscribable<Date>;
    };
}

export class RequestForQuotationResponseStateHelper {
    public NoResponse: ko.Computed<boolean>;
    public IncompleteResponse: ko.Computed<boolean>;
    public CompleteResponse: ko.Computed<boolean>;
    public IncompleteResponseTitle: ko.Computed<string>;
    
    constructor(private props: IRequestForQuotationResponseStateHelperProps) {
        this.NoResponse = ko.computed(() => {
            return !this.props.response.ResponseDate;
        });

        this.IncompleteResponse = ko.computed(() => {
            return !!ko.unwrap(this.props.response.ResponseDate)
                && (!ko.unwrap(this.props.response.ResponseAmount)
                    || !ko.unwrap(this.props.response.ResponsePrice)
                    || !ko.unwrap(this.props.response.ResponseDeliveryDate)
                );
        });

        this.CompleteResponse = ko.computed(() => {
            return !!ko.unwrap(this.props.response.ResponseDate)
                && !!ko.unwrap(this.props.response.ResponseAmount)
                && !!ko.unwrap(this.props.response.ResponsePrice)
                && !!ko.unwrap(this.props.response.ResponseDeliveryDate);
        });

        this.IncompleteResponseTitle = ko.computed(() => {
            let response: string = "";

            if (!ko.unwrap(this.props.response.ResponseAmount))
                response += "<div>" + ProlifeSdk.TextResources.Provisioning.MissingRequestForQuotationResponseAmount + "</div>";

            if (!ko.unwrap(this.props.response.ResponsePrice))
                response += "<div>" + ProlifeSdk.TextResources.Provisioning.MissingRequestForQuotationResponsePrice + "</div>";

            if (!ko.unwrap(this.props.response.ResponseDeliveryDate))
                response += "<div>" + ProlifeSdk.TextResources.Provisioning.MissingRequestForQuotationResponseDeliveryDate + "</div>";

            return response;
        });
    }

    render() {
        let stateHelper: RequestForQuotationResponseStateHelper;
        
        return (
            <BindTo viewModel={this} as='stateHelper'> 
                <>
                    <span data-bind={{ visible: stateHelper.NoResponse}} title="Nessuna risposta">
                        <i class="fa fa-exclamation-circle" style={{ color: "red" }}></i>
                    </span>
                    <span data-bind={{ visible: stateHelper.IncompleteResponse, attr: { title: stateHelper.IncompleteResponseTitle } }}>
                        <i class="fa fa-exclamation-triangle" style={{ color: "orange" }}></i>
                    </span>
                    <span data-bind={{ visible: stateHelper.CompleteResponse }} title="Risposta completa">
                        <i class="fa fa-check" style={{ color: "green" }}></i>
                    </span>
                </>
            </BindTo>
        );
    }
}