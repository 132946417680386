import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 29/06/2018
 * Time: 15:30
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ImportMonthlyInvoicingDataWizardInvoicingBaseStep } from "./ImportMonthlyInvoicingDataWizardInvoicingBaseStep";
import { CurrencyUtils } from "../../../../../ProlifeSdk/prolifesdk/utils/CurrencyUtils";
import { ICurrenciesSettingsManager } from "../../../settings/CurrenciesSettingsManager";
import { LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IMonthlyInvoicingDataSource } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDataSourceForMonthlyInvoicing";
import { IWorkedHoursForMonthlyInvoicingWizard } from "../../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursForMonthlyInvoicingWizard";
import { IEntityToImportInfoForMonthlyInvoicing } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IEntityToImportInfoForMonthlyInvoicing";
import { IWizardInitializationInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IDocumentCurrencyViewModel } from "../../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWorkedHoursForDocumentForMonthlyInvoicing, IEntityForMonthlyInvoicingTree, IWorkedHoursEnvelopeForMontlyInvoicing, IPurchasesEnvelopeForMonthlyInvoicing } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDocumentForMonthlyInvoicingTree";
import { IDDTToImportForMonthlyInvoicingInfo } from "../../../../../ProlifeSdk/interfaces/invoice/wizard/IDDTToImportForMonthlyInvoicingInfo";
import { IPurchaseForMonthlyInvoicingWizard } from "../../../../../ProlifeSdk/interfaces/desktop/IPurchaseForMonthlyInvoicingWizard";
import { IInvoiceForMonthlyInvoicingWizard } from "../../../../interfaces/IInvoiceForMonthlyInvoicingWizard";
import { IPurchaseToImportForMonthlyInvoicing } from "../../../../../ProlifeSdk/interfaces/financial-data/IPurchasesForDocuments";
import { IExtendedWorkSheetRow } from "../../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IPurchaseForMonthlyInvoicing } from "../../../../../Blog/interfaces/IPurchasesService";

export interface IOriginatingRowsInfo {
    LeafsEntities?: DocumentForTree[];
    PartialInvoices?: InvoiceDocumentForTree[];
    WorkedHours?: IWorkedHoursForDocumentForMonthlyInvoicing;
    Purchases?: IPurchaseToImportForMonthlyInvoicing;
}

export interface IDocumentsForTreeFactory {
    [entityType: string]: (entity: IEntityToImportInfoForMonthlyInvoicing, originatingRowsInfo: IOriginatingRowsInfo) => DocumentForTree;
}

export class ImportMonthlyInvoicingDataWizardInvoicingSummaryStep extends ImportMonthlyInvoicingDataWizardInvoicingBaseStep {
    public FinalInvoices: ko.ObservableArray<DocumentForTree> = ko.observableArray([]);

    private documentsForTreeFactory: IDocumentsForTreeFactory;

    constructor(protected dataSource: IMonthlyInvoicingDataSource, initializationInfo: IWizardInitializationInfo) {
        super('import-monthly-invoicing-data-wizard-invoicing-summary-step', 'invoices/templates/wizard/steps', ProlifeSdk.TextResources.Invoices.MonthlyInvoicingWizardInvoicingSecondStepTitle, dataSource, initializationInfo);

        this.documentsForTreeFactory = {};

        this.documentsForTreeFactory[ProlifeSdk.DdtEntityTypeCode] = (e: IDDTToImportForMonthlyInvoicingInfo) => new DdtDocumentForTree(e);
        this.documentsForTreeFactory[ProlifeSdk.WorkedHoursEntityTypeCode] = (e: IWorkedHoursForMonthlyInvoicingWizard, originatingRowsInfo: IOriginatingRowsInfo) => new WorkedHoursDocumentForTree(e, originatingRowsInfo.WorkedHours);
        this.documentsForTreeFactory[ProlifeSdk.PurchasesEntityTypeCode] = (e: IPurchaseForMonthlyInvoicingWizard, originatingRowsInfo: IOriginatingRowsInfo) => new PurchasesDocumentForTree(e, originatingRowsInfo.Purchases);
        this.documentsForTreeFactory[ProlifeSdk.InvoiceEntityTypeCode] = (e: IInvoiceForMonthlyInvoicingWizard, originatingRowsInfo: IOriginatingRowsInfo) => originatingRowsInfo.PartialInvoices ? new InvoiceDocumentForTree(e, originatingRowsInfo.PartialInvoices) : new PartialInvoiceDocumentForTree(e, originatingRowsInfo.LeafsEntities);
    }

    public BeforeShow()
    {
        let entities: IEntityToImportInfoForMonthlyInvoicing[][] = this.dataSource.GetEntitiesToImport(this);
        let invoices: IInvoiceForMonthlyInvoicingWizard[] = <IInvoiceForMonthlyInvoicingWizard[]> entities[entities.length - 1];
        let documentsTree: DocumentForTree[] = [];

        invoices.sort((a: IInvoiceForMonthlyInvoicingWizard, b: IInvoiceForMonthlyInvoicingWizard) => a.LabelForInvoiceGrouping() < b.LabelForInvoiceGrouping() ? -1 : (a.LabelForInvoiceGrouping() == b.LabelForInvoiceGrouping() ? 0 : 1));

        for (let invoice of invoices) {
            let partialInvoicesForTree: DocumentForTree[] = [];
            let partialInvoices = invoice.entities as IInvoiceForMonthlyInvoicingWizard[];

            for (let partialInvoice of partialInvoices) {
                let leafEntities: DocumentForTree[] = [];
                let entities = partialInvoice.entities;

                for (let entity of entities) {
                    let originatingRowsInfo: IOriginatingRowsInfo = {
                        Purchases: entity.EntityType == ProlifeSdk.PurchasesEntityTypeCode ? (<IPurchaseForMonthlyInvoicingWizard> entity).purchase : null,
                        WorkedHours: entity.EntityType == ProlifeSdk.WorkedHoursEntityTypeCode ? (<IWorkedHoursForMonthlyInvoicingWizard> entity).WorkedHoursRow : null
                    };

                    leafEntities.push(this.documentsForTreeFactory[entity.EntityType](entity, originatingRowsInfo));
                }

                partialInvoicesForTree.push(this.documentsForTreeFactory[partialInvoice.EntityType](partialInvoice, { LeafsEntities: leafEntities }));
            }

            documentsTree.push(this.documentsForTreeFactory[invoice.EntityType](invoice, { PartialInvoices: partialInvoicesForTree }));
        }

        this.FinalInvoices(documentsTree);
    }

    public BeforeNext(): boolean
    {
        return true;
    }

    public IsValid(): boolean {
        return true;
    }

    public async getDocumentsToGenerate(): Promise<IEntityForMonthlyInvoicingTree[]> {
        return this.FinalInvoices().map((d: DocumentForTree) => d.getData());
    }
}

class DocumentForTree {
    public id: number;
    public type: string;
    public name: string;
    public vatRegisterId: number;
    public vatRegister: string;
    public total: number;
    public totalInDefaultCurrency: number;
    public customerId: number;
    public customer: string;
    public jobOrderId: number;
    public jobOrder: string;
    public expiryTypeId: number;
    public expiryType: string;
    public paymentTypeId: number;
    public paymentType: string;
    public paymentIBAN: string;
    public paymentABI: string;
    public paymentCAB: string;

    public documentCurrency: IDocumentCurrencyViewModel;
    public documentCurrencySymbol: string;
    public documentCurrencyCode: string;

    public isPartialInvoice: boolean;
    public showTotalInDefaultCurrency: boolean;

    public importedDocuments: ko.ObservableArray<DocumentForTree> = ko.observableArray([]);
    
    public BaseDocumentsNumber: ko.Computed<number>;

    private documentCurrencies: IDocumentCurrencyViewModel[] = [];
    
    @LazyImportSettingManager(nameof<ICurrenciesSettingsManager>())
    private currenciesSettingsManager: ICurrenciesSettingsManager;

    constructor(entity: IEntityToImportInfoForMonthlyInvoicing, importedDocuments: DocumentForTree[]) {
        this.id = entity.Id; 
        this.type = entity.EntityType;
        this.total = entity.Total();
        this.customerId = entity.CustomerId();
        this.customer = entity.CustomerName();
        this.jobOrderId = entity.JobOrderId();
        this.jobOrder = entity.JobOrderName();
        this.expiryTypeId = entity.ExpiryTypeId();
        this.expiryType = entity.ExpiryType();
        this.documentCurrencySymbol = entity.CurrencySymbol();
        this.documentCurrencyCode = entity.CurrencyCode();
        
        this.importedDocuments(importedDocuments || []);
        this.documentCurrencies = entity.DocumentCurrencies();

        this.documentCurrency = this.documentCurrencies.firstOrDefault((c) => c.IsDocumentCurrency());

        this.totalInDefaultCurrency = CurrencyUtils.applyCurrencyExchange(this.total, this.documentCurrency);

        let defaultCurrency = this.currenciesSettingsManager.getDefaultCurrency();

        this.showTotalInDefaultCurrency = defaultCurrency.CodeISO4217alpha3 !== this.documentCurrency.Currency().CodeISO4217alpha3;

        this.BaseDocumentsNumber = ko.computed(() => {
            let sum: number = 0;
            let importedDocuments = this.importedDocuments();
            let docsWithChilds: DocumentForTree[] = importedDocuments.filter((d: DocumentForTree) => d.importedDocuments().length > 0);

            if (docsWithChilds.length == 0)
                return importedDocuments.length;

            docsWithChilds.forEach((d: DocumentForTree) => {
                sum += d.BaseDocumentsNumber();
            });

            return sum;
        });
    }

    public getData(): IEntityForMonthlyInvoicingTree {
        return {
            EntityId: this.id,
            EntityType: this.type,
            DocumentLabel: this.name,
            VatRegisterId: this.vatRegisterId,
            CustomerId: this.customerId,
            CustomerName: this.customer,
            JobOrderId: this.jobOrderId,
            ExpiryTypeId: this.expiryTypeId,
            ExpiryType: this.expiryType,
            PaymentTypeId: this.paymentTypeId,
            PaymentType: this.paymentType,
            PaymentIBAN: this.paymentIBAN,
            PaymentABI: this.paymentABI,
            PaymentCAB: this.paymentCAB,
            DocumentCurrencies: this.documentCurrencies.map((c) => c.getData()),
            IsPartialInvoice: this.isPartialInvoice,
            ImportedDocuments: this.importedDocuments().map((d: DocumentForTree) => d.getData()),
            WorkedHoursRow: null,
            PurchasesRow: null
        };
    }
}

class DdtDocumentForTree extends DocumentForTree {

    constructor(ddt: IDDTToImportForMonthlyInvoicingInfo) {
        super(ddt, []);
        
        this.name = ddt.Name;
        this.isPartialInvoice = false;
        this.vatRegisterId = ddt.ProtocolId();
        this.vatRegister = ddt.ProtocolName()
        this.paymentTypeId = ddt.PaymentTypeId();
        this.paymentType = ddt.PaymentType();
    }
}

class WorkedHoursDocumentForTree extends DocumentForTree {
    public workedHoursRow: IWorkedHoursForDocumentForMonthlyInvoicing;
    
    constructor(workedHours: IWorkedHoursForMonthlyInvoicingWizard, originatingRow: IWorkedHoursForDocumentForMonthlyInvoicing) {
        super(workedHours, []);
        
        this.name = workedHours.Name;
        this.isPartialInvoice = false;
        this.paymentTypeId = workedHours.PaymentTypeId();
        this.paymentType = workedHours.PaymentType();
        this.workedHoursRow = originatingRow;
    }

    private createWorkedHoursForMonthlyInvoicing(): IWorkedHoursEnvelopeForMontlyInvoicing {
        var wh: IWorkedHoursEnvelopeForMontlyInvoicing = {
            HoursIds: this.workedHoursRow.WorkedHours.map((w: IExtendedWorkSheetRow) => w.HoursId),
            AggregationType: this.workedHoursRow.AggregationType,
            IsCallRightRow: !!this.workedHoursRow.Dependency,
            Dependency: !this.workedHoursRow.Dependency ? null : {
                HoursIds: this.workedHoursRow.Dependency.WorkedHours.map((w: IExtendedWorkSheetRow) => w.HoursId),
                AggregationType: this.workedHoursRow.Dependency.AggregationType,
                IsCallRightRow: false,
                Dependency: null
            }
        };

        return wh;
    }

    public getData(): IEntityForMonthlyInvoicingTree {
        let data = super.getData();
        data.WorkedHoursRow = !this.workedHoursRow ? null : this.createWorkedHoursForMonthlyInvoicing();
        return data;
    }
}

class PurchasesDocumentForTree extends DocumentForTree {
    public purchasesRow: IPurchaseToImportForMonthlyInvoicing;

    constructor(purchases: IPurchaseForMonthlyInvoicingWizard, originatingRow: IPurchaseToImportForMonthlyInvoicing) {
        super(purchases, []);

        this.name = purchases.Name;
        this.isPartialInvoice = false;
        this.paymentTypeId = purchases.PaymentTypeId();
        this.paymentType = purchases.PaymentType();
        this.purchasesRow = originatingRow;
    }

    private createPurchaseForMonthlyInvoicing(): IPurchasesEnvelopeForMonthlyInvoicing {
        return {
            PurchasesIds: this.purchasesRow.Purchases.map((p: IPurchaseForMonthlyInvoicing) => p.PurchaseId),
            AggregationType: this.purchasesRow.Purchases.length == 1 ? ProlifeSdk.DetailedPurchases : ProlifeSdk.AggregatedPurchases
        };
    }

    public getData(): IEntityForMonthlyInvoicingTree {
        let data = super.getData();
        data.PurchasesRow = !this.purchasesRow ? null : this.createPurchaseForMonthlyInvoicing();
        return data;
    }
}

class PartialInvoiceDocumentForTree extends DocumentForTree {
    
    constructor(invoice: IInvoiceForMonthlyInvoicingWizard, originatingRows: DocumentForTree[]) {
        super(invoice, originatingRows);

        this.name = invoice.Name;
        this.isPartialInvoice = true;
        this.vatRegisterId = invoice.VatRegisterForPartialInvoicing();
        this.vatRegister = invoice.VatRegisterNameForPartialInvoicing();
        this.paymentTypeId = invoice.PaymentTypeManagerUi.PaymentId();
        this.paymentType = invoice.PaymentTypeManagerUi.PaymentDescription();
        this.paymentIBAN = invoice.PaymentTypeManagerUi.PaymentIBAN();
        this.paymentABI = invoice.PaymentTypeManagerUi.PaymentABI();
        this.paymentCAB = invoice.PaymentTypeManagerUi.PaymentCAB();
    }
}

class InvoiceDocumentForTree extends DocumentForTree {
    
    constructor(invoice: IInvoiceForMonthlyInvoicingWizard, partialInvoices: InvoiceDocumentForTree[]) {
        super(invoice, partialInvoices);

        this.name = invoice.LabelForInvoiceGrouping();
        this.isPartialInvoice = false;
        this.vatRegisterId = invoice.VatRegisterForFinalInvoicing();
        this.vatRegister = invoice.VatRegisterNameForFinalInvoicing();
        this.jobOrderId = null;
        this.jobOrder = null;
        this.paymentTypeId = invoice.PaymentTypeManagerUi.PaymentId();
        this.paymentType = invoice.PaymentTypeManagerUi.PaymentDescription();
        this.paymentIBAN = invoice.PaymentTypeManagerUi.PaymentIBAN();
        this.paymentABI = invoice.PaymentTypeManagerUi.PaymentABI();
        this.paymentCAB = invoice.PaymentTypeManagerUi.PaymentCAB();
    }
}