import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 15/03/13
 * Time: 10.08
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as Core from "../Core/Core";
import { ICustomersService } from "../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../ProlifeSdk/interfaces/customer/ICustomer";

export class CustomerDisplayName {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any) : void
    {
        CustomerDisplayName.refreshContent(element, valueAccessor, allBindingsAccessor);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any): void
    {
        CustomerDisplayName.refreshContent(element, valueAccessor, allBindingsAccessor);
    }

    private static refreshContent(element : any, valueAccessor: () => any, allBindingsAccessor: () => any)
    {
        var options = valueAccessor();
        var customerService = <ICustomersService> Core.serviceLocator.findService(ProlifeSdk.CustomersServiceType);
        var customerId = ko.utils.unwrapObservable(options.CustomerId);
        var createLink = options.CreateLink || false;
        var defaultText = allBindingsAccessor().defaultCustomerText || "";

        if (customerId == "" || customerId == undefined || customerId == null || customerId == undefined || customerId <= 0)
        {
            $(element).text(defaultText);
            return;
        }

        customerService.getCustomer(<number>customerId)
            .then((customer : ICustomer) => {
                var risultato : string = defaultText;
                if (customer != null)
                    risultato = customer.FormattedContactName;

                if(createLink) {
                    $(element).empty();

                    $('<a href=""></a>')
                        .attr("href", customerService.GetCustomerUrl(customer.IdCliente))
                        .attr("target", "_blank")
                        .text(risultato)
                        .appendTo(element);
                }
                else
                    $(element).text(risultato);
            }).catch(() => {
                $(element).text(defaultText);
            });
    }
}

ko.bindingHandlers["CustomerDisplayName"] = new CustomerDisplayName();
ko.virtualElements.allowedBindings["CustomerDisplayName"] = true;