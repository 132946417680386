import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrderStatesControlsEntityProvider } from "./JobOrderStatesControlsEntityProvider";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IJobOrderStateSettingsManager } from "../../../ProlifeSdk/interfaces/job-order/settings/IJobOrderStateSettingsManager";
import { ISettingsService } from "../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IJobOrderState } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderState";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class JobOrderStatesEntityProvider implements IEntityProvider<number, IJobOrderState> {

    private statesManager : IJobOrderStateSettingsManager;

	constructor(private serviceLocator : IServiceLocator )
    {
        //ATTENZIONE: Non lo registro come entity provider sul servizio delle entità. Ha un uso sporadico quindi si istanzia manualmente quando serve.
        var settingsService : ISettingsService = <ISettingsService>serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        this.statesManager = <IJobOrderStateSettingsManager>settingsService.findSettingsManager(ProlifeSdk.JobOrderState);
	}

    getType() : string
    {
        return ProlifeSdk.JobOrderState;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.JobOrder.OrderStatus;
    }

    getPkValue(item : IJobOrderState) : number
    {
        return item.IdJobOrderStateId;
    }

    getDisplayName(item : IJobOrderState) : string
    {
        return item.Description;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        var state : IJobOrderState = this.statesManager.getJobOrderStateById(pkValue);
        def.resolve(state.Description);
        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IJobOrderState[]>
    {
        var def = new Deferred<IJobOrderState[]>();
        var states : IJobOrderState[] = this.statesManager.getJobOrderStates().filter((s : IJobOrderState) => {
            return s.Description.toUpperCase().indexOf(stringFilter.trim().toUpperCase()) > -1;
        });
        def.resolve(states);
        return def.promise();
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new JobOrderStatesControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : any) : Promise<IJobOrderState>
    {
        var def = new Deferred<IJobOrderState>();
        def.resolve(this.statesManager.getJobOrderStateById(pkValue));
        return def.promise();
    }
}