import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ResourceManagerArea } from "./ResourceManagerArea";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { WorkingDayEditor, WorkingHourEditor, classes } from "./WorkingDayEditor";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IOperationalUnitsSettingsManager, IOperationalUnit, IOperationalUnitCost } from "../../../../ProlifeSdk/interfaces/resourcesmanager/IOperationalUnitsSettingsManager";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IOperationalUnitWorkingHours } from "../../../../ProlifeSdk/interfaces/users/IHumanResource";
import { Deferred } from "../../../../Core/Deferred";

export class OperationalUnits extends ResourceManagerArea {

    public title : string;

    @LazyImportSettingManager(ProlifeSdk.OperationalUnitsSettingsServiceType)
    private ouSettingsService : IOperationalUnitsSettingsManager;

    private list : ko.ObservableArray<OperationalUnitForList> = ko.observableArray([]);
    public filteredList : ko.Computed<OperationalUnitForList[]>;
    public selected : ko.Observable<any> = ko.observable(null);
    public textFilter : ko.Observable<string> = ko.observable("");
    public showDeleted : ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ServiceTypes.Authorization)
    private authorizationsService: IAuthorizationService;

    public canShowDeleted: ko.Observable<boolean> = ko.observable(false);

    constructor(mainLayout : IResourcesManagerViewModel) {
        super(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsAreaTitle, "fa-sitemap", mainLayout,
            "ResourcesManager/Templates/Areas/OperationalUnits", "main-panel", ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_OperationalUnit);

        let showDeletedRight = this.authorizationsService.isAuthorized("ResourcesManager_CanViewShowDeletedButtonOnResourcesManager");
        this.canShowDeleted(showDeletedRight);

        this.filteredList = ko.computed(() => {
            return this.list()
                .filter((c : OperationalUnitForList) => { return c.model.Name.toUpperCase().indexOf(this.textFilter().toUpperCase()) > -1; })
                .filter((c : OperationalUnitForList) => { return !c.model.Deleted || this.showDeleted(); })
                .sort((a, b) =>{
                    if(a.model.Name < b.model.Name)
                        return -1;
                    if(a.model.Name > b.model.Name)
                        return 1;
                    return 0;
                });
        });

        this.createNew();
    }

    public updateElementInLists(model : IOperationalUnit) {
        this.removeElementFromLists(model.Id);
        this.list.push(new OperationalUnitForList(model, this));
    }

    public removeElementFromLists(id : number) {
        let updated = this.list().filter((c : OperationalUnitForList) => { return c.model.Id == id; });

        if(updated.length > 0)
            this.list.remove(updated[0]);
    }

    public edit(model : IOperationalUnit){
        this.selected(new OperationalUnitEditMask(model, this));
    }

    public createNew() {
        let emptyModel : IOperationalUnit = {
            Id: -1,
            Name: "",
            Description: "",
            StartDate: new Date(),
            EndDate: null,
            Deleted: false,
            Costs : []
        };
        this.edit(emptyModel);
    }

    public show() {
        super.show();

        this.ouSettingsService.load().then(() => {
            this.list(this.ouSettingsService.getAll(true).map((c : IOperationalUnit) => { return new OperationalUnitForList(c, this); }));
        });
    }
}

@DetectClassChanges
export class OperationalUnitEditMask implements IDetectChanges {
    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;

    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService : IProLifeSdkService;

    @LazyImportSettingManager(ProlifeSdk.OperationalUnitsSettingsServiceType)
    private ouSettingsService : IOperationalUnitsSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private toastService : IInfoToastService;

    public originalName : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public name : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public description : ko.Observable<string> = ko.observable("");
    @DetectChanges
    public startDate : ko.Observable<Date> = ko.observable(new Date());
    @DetectChanges
    public endDate : ko.Observable<Date> = ko.observable(new Date());

    public isNew : ko.Observable<boolean> = ko.observable(false);
    public deleted : ko.Observable<boolean> = ko.observable(false);

    public baseCssClass = classes.serviceOrderEditor;

    public costs : ko.ObservableArray<CostEditMask> = ko.observableArray([]);

    HoursPerDay: ko.ObservableArray<OperationalUnitWorkingDayEditor> = ko.observableArray();    

    public WorkingHoursNotDefined: ko.Computed<boolean>;
    private originalWorkingHours: IOperationalUnitWorkingHours[];

    isChanged: ko.Observable<number> = ko.observable(0);

    constructor(public model : IOperationalUnit, private parent : OperationalUnits){
        

        this.WorkingHoursNotDefined = ko.computed(() => {
            return !this.HoursPerDay().any(h => h.HasWorkingHours());
        });

        this.updateData(model);
    }
    
    dispose(): void {
        
    }

    private async loadStandardWorkingHours(unitId: number): Promise<void> {
        let workingHours = await this.ouSettingsService.getOperationalUnitStandardWorkingHours(unitId)
        this.originalWorkingHours = workingHours;
        this.reloadWorkingHours(workingHours);
    }

    private reloadWorkingHours(workingHours: IOperationalUnitWorkingHours[]) {
        this.HoursPerDay([]);

        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const day = new OperationalUnitWorkingDayEditor(dayOfWeek, this);
            this.HoursPerDay.push(day);
            for (let wh of workingHours.filter(wh => wh.DayOfWeek == dayOfWeek)) {
                day.WorkingHours.push(new OperationalUnitWorkingHourEditor(wh.Id, wh.DayOfWeek, moment(wh.Start, "HH:mm:ss").toDate(), wh.Duration, this));
            }
            day.isChanged(0);
        }
    }

    ApplyWorkingHoursToAllDays(sourceDay: OperationalUnitWorkingDayEditor) {
        for(let day of this.HoursPerDay()) {
            if(day !== sourceDay) {
                day.CopyFrom(sourceDay);
            }
        }
    }

    private async updateData(model : IOperationalUnit) {
        this.model = model;
        this.name(model.Name);
        this.description(model.Description);
        this.startDate(model.StartDate);
        this.endDate(model.EndDate);
        this.deleted(model.Deleted);
        this.isNew(model.Id <= 0);

        this.costs(model.Costs.map((c : IOperationalUnitCost) => { return new CostEditMask(c); }));
        await this.loadStandardWorkingHours(this.model.Id);

        this.originalName(this.isNew() ? ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsNewOperationalUnit : model.Name);

        this.isChanged(0);
    }

    public addCostPeriod(){
        this.costs.push(new CostEditMask({
            StartDate : null,
            EndDate : null,
            YearCost : 0
        }));
    }

    public removeCostPeriod(c : CostEditMask){
        this.costs.remove(c);
    }

    public hasChanges(): boolean {
        if(this.isChanged())
            return true;

        if(this.HoursPerDay().any(hpd => hpd.isChanged() > 0))
            return true;

        return false;
    }

    public async save(){
        if(this.name().length == 0)
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsInvalidName);
            return;
        }

        if(!moment(this.startDate()).isValid())
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsInvalidStartDate);
            return;
        }

        if(moment(this.startDate()).isValid() && (<any>moment(this.endDate())).isBefore(this.startDate()))
        {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsInvalidDatesRange);
            return;
        }

        let someCostAreNotValid : boolean = false;
        this.costs().forEach((c : CostEditMask) => { someCostAreNotValid = someCostAreNotValid || !c.isValid(); })

        if(someCostAreNotValid){
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsInvalidCosts);
            return;
        }

        let collisions : boolean = false;
        this.costs()
            .forEach((c : CostEditMask) => {
                this.costs()
                    .filter((c1 : CostEditMask) => { return c1 != c; })
                    .forEach((c1 : CostEditMask) => {
                        collisions = collisions || c.collide(c1);
                    });
            });

        if(collisions){
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsCostsCollisions);
            return;
        }

        this.model.Name = this.name();
        this.model.Description = this.description();
        this.model.StartDate = this.startDate();
        this.model.EndDate = this.endDate();

        this.model.Costs = this.costs().map((c : CostEditMask) => { return c.getData(); });

        try
        {
            let newOU = await this.ouSettingsService.createOrUpdate(this.model)
            await this.saveWorkingHours(newOU.Id);

            this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsUpdated);
            
            this.updateData(newOU);
            this.parent.updateElementInLists(newOU);
        }
        catch
        {
            this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsUpdateError);
        }
    }

    public async restoreItem() {
        this.model.Deleted = false;

        try
        {
            let newOU = await this.ouSettingsService.createOrUpdate(this.model)
            await this.saveWorkingHours(newOU.Id);

            this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsRestored);
            
            this.updateData(newOU);
            this.parent.updateElementInLists(newOU);
        }
        catch
        {
            this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsRestoredError);
        }
    }

    public async deleteItem(){

        let confirm = await this.dialogsService.ConfirmAsync(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsDeleteWarningConfirm);
        if(!confirm)
            return;

        try
        {
            let newOU = await this.ouSettingsService.delete(this.model)
            this.model.Deleted = true;

            this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsDeleted);
            
            this.updateData(newOU);
            this.parent.updateElementInLists(newOU);
        }
        catch
        {
            this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.OperationalUnitsDeleteError);
        }
    }

    private saveWorkingHours(unitId: number): Promise<IOperationalUnitWorkingHours[]> {
        let workingHours: IOperationalUnitWorkingHours[] = this.CreateWorkingHoursList();
        return this.ouSettingsService.saveOperationalUnitStandardWorkingHours(unitId, workingHours);
    }
    
    private CreateWorkingHoursList(): IOperationalUnitWorkingHours[] { 
        return this.HoursPerDay().selectMultiple(hpd => hpd.getData());
    }
}

@DetectClassChanges
class OperationalUnitWorkingDayEditor extends WorkingDayEditor<OperationalUnitWorkingHourEditor, IOperationalUnitWorkingHours> {

    constructor(dayOfWeek: number, private editor: OperationalUnitEditMask) {
        super(dayOfWeek);
    }

    createNewWorkingHour(start: Date): OperationalUnitWorkingHourEditor {
        return this.createWorkingHour(-1, start, 0);
    }

    createWorkingHour(id: number, start: Date, duration: number): OperationalUnitWorkingHourEditor {
        return new OperationalUnitWorkingHourEditor(id, this.dayOfWeek, start, duration, this.editor);
    }
}

@DetectClassChanges
class OperationalUnitWorkingHourEditor extends WorkingHourEditor<IOperationalUnitWorkingHours> {
    constructor(id: number, dayOfWeek: number, start: Date, duration: number, private editor: OperationalUnitEditMask) {
        super(id, dayOfWeek, start, duration);
    }

    getData(): IOperationalUnitWorkingHours {
        return {
            Id: this.Id(),
            DayOfWeek: this.DayOfWeek(),
            Start: moment(this.Start()).format("HH:mm:ss"),
            End: moment(this.Start()).add('hours', this.Duration()).format("HH:mm:ss"),
            Duration: this.Duration(),
            OperationalUnitId: this.editor.model.Id
        };
    }
}

export class CostEditMask {

    public startDate : ko.Observable<Date> = ko.observable();
    public endDate : ko.Observable<Date> = ko.observable();
    public yearCost : ko.Observable<number> = ko.observable();
    public periodCost : ko.Computed<number>;

    constructor(private cost : IOperationalUnitCost){

        //Costo riproporzionato al periodo
        this.periodCost = ko.computed(() => {
            let value : number = this.yearCost() * (moment(this.endDate()).diff(moment(this.startDate()), 'days') / 365);
            value = value > 0 ? value : 0;
            return value;
        });

        this.startDate(moment(cost.StartDate).toDate());
        this.endDate(moment(cost.EndDate).toDate());
        this.yearCost(cost.YearCost);
    }

    public collide(c : CostEditMask){
        return !((c.startDate() >= this.endDate()) || (this.startDate() >= c.endDate()));
    }

    public isValid(){
        // eslint-disable-next-line import/namespace
        return moment.isDate(this.startDate()) && moment.isDate(this.endDate()) &&
            moment(this.endDate()).diff(moment(this.startDate()), 'days') > 0 &&
            this.yearCost() > 0;
    }

    public getData(){
        this.cost.StartDate = this.startDate();
        this.cost.EndDate = this.endDate();
        this.cost.YearCost = this.yearCost();
        return this.cost;
    }
}

export class OperationalUnitForList {

    public isSelected : ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public model : IOperationalUnit, private parent : OperationalUnits){
        this.model.StartDate = moment(this.model.StartDate).toDate();

        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().model.Id == this.model.Id;
        });
    }

    public showDetails(){
        if (!this.parent.selected()) {
            this.parent.edit(this.model);
            return;
        }

        if (this.parent.selected().hasChanges()) {
            this.ShowConfirmOnLostChanges()
                .then((confirm: boolean) => {
                    if (confirm)
                        this.parent.edit(this.model);
                });
            return;
        }

        this.parent.edit(this.model);
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        let def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}
