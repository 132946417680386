import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel, IDataSource, IDataSourceView } from "./IDataSource";
import { IFamily, IFamiliesService } from "../ProlifeSdk/interfaces/warehouse/IFamiliesService";

export type IDiscountFamilyDataSourceModel = IDataSourceModel<number, IFamily>;

export class DiscountFamiliesDataSource implements IDataSource {
    @LazyImport(ProlifeSdk.FamiliesServiceType)
    private familiesService!: IFamiliesService;
    private manufacturerId: number;

    getTitle(currentModel: IDataSourceModel<string | number, any, string | number, any>): string {
        return ProlifeSdk.TextResources.Warehouse.DiscountFamilies;
    }

    isGroupedData(
        currentModel: IDataSourceModel<string | number, any, string | number, any>,
        textFilter: string
    ): boolean {
        return false;
    }

    areEqual(
        a: IDataSourceModel<string | number, any, string | number, any>,
        b: IDataSourceModel<string | number, any, string | number, any>
    ): boolean {
        return (!a && !b) || (!!a && !!b && a.id == b.id);
    }

    setManufacturerId(manufacturerId: number) {
        this.manufacturerId = manufacturerId;
    }

    async getData(
        currentModel: IDiscountFamilyDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDiscountFamilyDataSourceModel[]> {
        const families = await this.familiesService.getFamilies(textFilter, skip, count);
        return families
            .filter((f) => !this.manufacturerId || f.SupplierId == this.manufacturerId)
            .map(this.createModelFor, this);
    }

    private createModelFor(family: IFamily): IDiscountFamilyDataSourceModel {
        return {
            id: family.Id,
            title: family.Name,
            isLeaf: true,
            isGroup: false,
            subTitle: family.Description,
            model: family,
        };
    }

    getById(currentModel: IDiscountFamilyDataSourceModel, ids: number[]): Promise<IDiscountFamilyDataSourceModel[]> {
        const promises: Promise<IFamily>[] = [];
        for (const id of ids) {
            promises.push(this.familiesService.getFamilyById(id));
        }

        return Promise.all(promises).then((families: IFamily[]) => {
            return families.map(this.createModelFor, this);
        });
    }

    setView(view: IDataSourceView): void {}

    getSupportedDropMimeTypes(): string[] {
        return [];
    }
}
