import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk";
import { BlogEventBase } from "./BlogEventBase";
import { EventSignatureViewModel } from "./EventSignatureViewModel";
import { Tag } from "./Tag";
import { CommentBlogEvent } from "./CommentBlogEvent";
import { CommentInput } from "./CommentInput";
import { DetailsBlogDialog } from "./DetailsBlogDialog";
import { Attachment } from "./Attachment";
import { IContextEventsObserver } from "../../interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../interfaces/desktop/IDesktopService";
import { IView } from "../../interfaces/IView";
import { IBlogEventViewModel } from "../../interfaces/blog/IBlogEventViewModel";
import { IFileRepositoryService } from "../../interfaces/files/IFileRepositoryService";
import { IEventBaseInput } from "../../interfaces/blog/IEventBaseInput";
import { ILogEvent } from "../../interfaces/ILogEvent";
import { IFileOrFolder } from "../../interfaces/files/IFileOrFolder";
import { ITag } from "../../interfaces/ITag";
import { Deferred } from "../../../Core/Deferred";

export class BlogEvent extends BlogEventBase implements IView, IBlogEventViewModel {
    public IsInQuality: ko.Observable<boolean> = ko.observable();
    public IsCommercial: ko.Observable<boolean> = ko.observable();
    public IsTechnical: ko.Observable<boolean> = ko.observable();
    public IsAdministration: ko.Observable<boolean> = ko.observable();
    //public IsSigned : ko.Observable<boolean> = ko.observable();
    //public Signature : ko.Observable<string> = ko.observable();
    public Priority: ko.Observable<string> = ko.observable();
    public State: ko.Observable<string> = ko.observable();

    public Tag_State_Undefined = ProlifeSdk.Tag_State_Undefined;
    public Tag_State_ToDo = ProlifeSdk.Tag_State_ToDo;
    public Tag_State_InProgress = ProlifeSdk.Tag_State_InProgress;
    public Tag_State_Done = ProlifeSdk.Tag_State_Done;
    public isExtended: ko.Observable<boolean> = ko.observable(false);

    private fileRepositoryService: IFileRepositoryService;
    private desktopService: IDesktopService;
    public hasAttachments: ko.Observable<boolean> = ko.observable(false);

    //caricato solo quando viene espanso il dettaglio
    //private attachmentsDetails : KnockoutObservableArray = ko.observableArray([]);

    private newComment: ko.Observable<any> = ko.observable(null);

    constructor(serviceLocator: IServiceLocator, contextEventsObserver: IContextEventsObserver) {
        super(serviceLocator, contextEventsObserver);
        this.fileRepositoryService = <IFileRepositoryService>(
            this.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.desktopService = <IDesktopService>this.serviceLocator.findService(ProlifeSdk.DesktopServiceType);
        this.prepareNewComment();
    }

    public GetMailBody(): Promise<string> {
        const def = new Deferred<string>();

        super
            .GetMailBody()
            .then((b: string) => {
                const body =
                    b +
                    String.format(
                        ProlifeSdk.TextResources.ProlifeSdk.BlogMailBody,
                        this.getYesNo(this.IsInQuality()),
                        this.getYesNo(this.IsAdministration()),
                        this.getYesNo(this.IsCommercial()),
                        this.getYesNo(this.IsTechnical()),
                        this.Priority(),
                        this.State(),
                        this.getYesNo(this.IsSigned())
                    );
                def.resolve(body);
            })
            .catch(() => def.reject());

        return def.promise();
    }

    private getYesNo(value: boolean): string {
        return value ? ProlifeSdk.TextResources.ProlifeSdk.Yes : ProlifeSdk.TextResources.ProlifeSdk.No;
    }

    public SignEvent() {
        if (this.IsSigned()) {
            this.dialogService.Alert(
                ProlifeSdk.TextResources.ProlifeSdk.WarningEventAlreadySigned,
                ProlifeSdk.TextResources.ProlifeSdk.InvalidOperation,
                () => {}
            );
            return;
        }

        const viewModel = new EventSignatureViewModel(this.serviceLocator, this);

        this.dialogService
            .ShowModal<boolean>(
                viewModel,
                "signatureDialog fullscreen",
                {},
                "prolifesdk/templates",
                "event-signature-dialog-container"
            )
            .then((result) => {
                if (result) this.contextEventsObserver.RefreshEvents();
            });
    }

    public CreateMailBodyPartForList(list: Tag[], label: string): string {
        let body = label + ":%20";
        list.forEach((i: Tag) => {
            body += i.displayName() + ",%20";
        });
        body += "%0A";
        return body;
    }

    public Clear() {
        super.Clear();
        this.IsInQuality(false);
        this.IsAdministration(false);
        this.IsCommercial(false);
        this.IsTechnical(false);
        this.Priority(ProlifeSdk.Tag_Priority_Normal);
        this.State(ProlifeSdk.Tag_State_Undefined);
        this.hasAttachments(false);
    }

    prepareNewComment() {
        const event: CommentBlogEvent = new CommentBlogEvent(this.serviceLocator, this.contextEventsObserver);
        event.FkParentId(this.IdEvent());
        this.newComment(new CommentInput(this.serviceLocator, this.contextEventsObserver, event));
        this.newComment().SetParent(this);
    }

    public getEditViewModel(): IEventBaseInput {
        //Implementare nelle specializzazioni
        return null;
    }

    public addComment() {
        if (this.newComment().message() == null || this.newComment().message().length == 0) return;

        this.newComment()
            .save()
            .then((comment: ILogEvent) => {
                const commentEvent = new CommentBlogEvent(this.serviceLocator, this.contextEventsObserver);
                commentEvent.LoadItemFromServerObject(comment);
                commentEvent.setParent(this);
                this.ChildrenEvents.push(commentEvent);
            });

        this.prepareNewComment();
    }

    public switchSelectability() {
        this.isSelectable(!this.isSelectable());
        this.isExtended(this.isSelectable() ? false : this.isExtended());
    }

    public switchSelection() {
        this.isSelected(!this.isSelected());
    }

    public clickOnEvent() {
        if (!this.isExtended() && this.desktopService.isMobileBrowser()) this.openDetailBlogDialog();
        if (this.isSelectable()) this.switchSelection();
        else this.switchExtensionState();
    }

    openDetailBlogDialog() {
        const dialog: DetailsBlogDialog = new DetailsBlogDialog(
            this.serviceLocator,
            "details-blog-dialog",
            "blog/templates/dialog",
            "",
            this
        );
        dialog.ShowModal();
    }

    public LoadDetails() {
        super.LoadDetails();
    }

    public switchExtensionState(): Promise<void> {
        const def = new Deferred<void>();
        this.isExtended(!this.isExtended());

        if (this.isExtended()) {
            this.logService.get([this.IdEvent()]).then((events: ILogEvent[]) => {
                events[0].RelatedTasks = this.RelatedTasks().join("|");

                this.LoadItemFromServerObject(events[0]);
                this.LoadDetails();
                this.fileRepositoryService
                    .getFilesDetails(this.Attachments().map((a: Attachment) => a.id))
                    .then((files: IFileOrFolder[]) => {
                        this.Attachments().forEach((a: Attachment) => {
                            const matches: IFileOrFolder[] = files.filter(
                                (file: IFileOrFolder) => file != null && file.Id == a.id
                            );
                            if (matches.length > 0) a.loadFromFile(matches[0]);
                        });
                    })
                    .finally(() => {
                        def.resolve();
                    });
            });
        } else def.resolve();

        return def.promise();
    }

    public BaseLoadItem(item: ILogEvent) {
        super.BaseLoadItem(item);

        item.Tags.forEach((item: ITag) => {
            this.Priority(item.TagName == ProlifeSdk.Tag_Priority ? item.Value : this.Priority());
            this.State(item.TagName == ProlifeSdk.Tag_State ? item.Value : this.State());
            this.IsInQuality(
                item.TagName == ProlifeSdk.Tag_TracingQuality && item.Value.toString().toLowerCase() == "true"
                    ? item.Value
                    : this.IsInQuality()
            );
            this.IsAdministration(
                item.TagName == ProlifeSdk.Tag_IsAdministration && item.Value.toString().toLowerCase() == "true"
                    ? item.Value
                    : this.IsAdministration()
            );
            this.IsCommercial(
                item.TagName == ProlifeSdk.Tag_IsCommercial && item.Value.toString().toLowerCase() == "true"
                    ? item.Value
                    : this.IsCommercial()
            );
            this.IsTechnical(
                item.TagName == ProlifeSdk.Tag_IsTechnical && item.Value.toString().toLowerCase() == "true"
                    ? item.Value
                    : this.IsTechnical()
            );
            this.IsSigned(
                item.TagName == ProlifeSdk.Tag_IsSigned && item.Value.toString().toLowerCase() == "true"
                    ? item.Value
                    : this.IsSigned()
            );
            this.Signature(item.TagName == ProlifeSdk.Tag_Signature ? item.Value : this.Signature());
            this.Subject(item.TagName == ProlifeSdk.Tag_Subject ? item.Value : this.Subject());
            this.hasAttachments(item.TagName == ProlifeSdk.Tag_File || this.hasAttachments());
        });

        this.prepareNewComment();
    }

    public EditEvent() {
        this.contextEventsObserver.EditEvent(this);
    }

    public changeState(newValue: string) {
        if (newValue == this.State()) return;

        this.dialogService.Confirm(
            ProlifeSdk.TextResources.ProlifeSdk.ConfirmChangeEventState,
            ProlifeSdk.TextResources.ProlifeSdk.DoNotChangeState,
            ProlifeSdk.TextResources.ProlifeSdk.ChangeState,
            (result: boolean) => {
                if (result) {
                    this.State(newValue);
                    let found = false;
                    this.originalValue.Tags.forEach((tag: ITag) => {
                        if (tag.TagName == ProlifeSdk.Tag_State) {
                            tag.Value = newValue;
                            found = true;
                            return;
                        }
                    });
                    if (!found) {
                        this.originalValue.Tags.push(<ITag>{
                            TagName: ProlifeSdk.Tag_State,
                            Value: newValue,
                            TagTypeId: ProlifeSdk.TagType_String,
                        });
                    }

                    this.logService.updateEvent([this.originalValue]);
                }
            }
        );
    }
}
