import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { DocumentsUtilities } from "../../../ProlifeSdk/prolifesdk/DocumentsUtilities";
import { PurchaseTypesDataSource } from "../../../DataSources/PurchaseTypesDataSource";
import { LazyImportSettingManager, LazyImport } from "../../../Core/DependencyInjection";
import { IPurchaseTypeSettingManager } from "../../../SettingManagers/PurchaseTypeSettingManager";
import { PurchasesDataSource, IPurchasesDataSourceModel } from "../../../DataSources/PurchasesDataSource";
import { PurchaseRevenueRow } from "./PurchaseRevenueRow";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../DataSources/IDataSource";
import { IPurchaseRow } from "../../../ProlifeSdk/interfaces/financial-data/IPurchase";
import { IBlogTask } from "../../../ProlifeSdk/interfaces/blog/IBlogService";
import { ITodoListService } from "../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListTask } from "../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";

export class PurchaseCostRow implements IDataSourceListener {
    public AttachedTask : ko.Observable<any> = ko.observable();
    public PurchaseType : ko.Observable<number> = ko.observable(1);
    public PurchaseTypeName : ko.Computed<string>;
    public Description : ko.Observable<string> = ko.observable();
    public Amount : ko.Observable<number> = ko.observable(0);
    public UnitCost : ko.Observable<number> = ko.observable(0);
    public Discount : ko.Observable<string> = ko.observable();
    public NetCost : ko.Observable<number> = ko.observable(0);
    public TotalCost : ko.Observable<number> = ko.observable(0);

    private isCalculating : boolean = false;

    public PurchaseTypesDataSource : PurchaseTypesDataSource;
    public PurchasesDataSource: PurchasesDataSource;

    @LazyImportSettingManager(ProlifeSdk.PurchasesTypesSettingsManager)
    private purchaseTypeSettingsManager : IPurchaseTypeSettingManager;

    constructor(private serviceLocator : IServiceLocator, private originalRow? : IPurchaseRow, private selectedTasks? : ko.ObservableArray<IBlogTask>)
    {
        this.PurchaseTypesDataSource = new PurchaseTypesDataSource();
        this.PurchasesDataSource = new PurchasesDataSource();
        this.PurchasesDataSource.setFilter(true, false);

        if(this.originalRow) {
            this.PurchaseType(this.originalRow.PurchaseType);
            this.Description(this.originalRow.RowDescription);
            this.Amount(this.originalRow.Amount);
            this.UnitCost(this.originalRow.UnitMoney);
            this.Discount(this.originalRow.Discount);
            this.NetCost(this.originalRow.NetMoney);
            this.TotalCost(this.originalRow.TotalMoney);

            if(this.originalRow.TaskId) {
                if (this.selectedTasks && this.selectedTasks().length > 0) {
                    var task = this.selectedTasks().filter((t: any) => t.Id == this.originalRow.TaskId);
                    this.AttachedTask(task.length > 0 ? task[0] : undefined);
                } else {
                    var todoListService = <ITodoListService>this.serviceLocator.findService(ProlifeSdk.TodoListServiceType);
                    todoListService.GetTaskById(this.originalRow.TaskId)
                        .then((t : ITodoListTask) => {
                            this.AttachedTask({
                                Id: t.Id,
                                Description: t.Title
                            })
                        });
                }
            }
        }
        else
        {
            if(this.selectedTasks().length > 0) {
                this.AttachedTask(this.selectedTasks()[0]);
            }
        }

        this.PurchaseTypeName = ko.computed(() => {
            let type = this.PurchaseType();
            let pt = this.purchaseTypeSettingsManager.getById(type);
            if(pt)
                return pt.Title;
            return "";
        });

        this.Amount.subscribe(this.updateTotal.bind(this));
        this.UnitCost.subscribe(this.onUnitCostChanged.bind(this));
        this.Discount.subscribe(this.onDiscountsChanged.bind(this));
        this.NetCost.subscribe(this.onNetCostChanged.bind(this));

        if(this.selectedTasks)
            this.selectedTasks.subscribe(this.onSelectedTasksChanged.bind(this));

        this.updateTotal();
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if(sender === this.PurchasesDataSource) {
            let purchaseModel = model as IPurchasesDataSourceModel;
            this.PurchaseType(purchaseModel.id);
            this.UnitCost(purchaseModel.model.UnitPrice);
            this.Discount(purchaseModel.model.Discount);
            this.NetCost(purchaseModel.model.NetUnitPrice);
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        
    }

    async canSelectItem?(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return true;
    }

    private onSelectedTasksChanged() {
        if(this.AttachedTask()) {
            if(this.selectedTasks().filter((t : any) => t.Id == this.AttachedTask().Id).length == 0)
            {
                this.AttachedTask(undefined);
            }
        }
    }

    private onUnitCostChanged()
    {
        if(this.isCalculating)
            return;

        this.isCalculating = true;
        this.NetCost(this.UnitCost());
        this.Discount(undefined);
        this.updateTotal();
        this.isCalculating = false;
    }

    private onDiscountsChanged()
    {
        if(this.isCalculating)
            return;

        this.isCalculating = true;
        var discount = DocumentsUtilities.CalculateDiscount(this.Discount());
        this.NetCost(this.UnitCost() * discount);
        this.updateTotal();
        this.isCalculating = false;
    }

    private onNetCostChanged()
    {
        if(this.isCalculating)
            return;

        this.isCalculating = true;
        this.Discount(undefined);
        this.updateTotal();
        this.isCalculating = false;
    }

    public updateTotal()
    {
        if(!this.NetCost() || !this.Amount())
        {
            this.TotalCost(0);
            return;
        }

        var netUnitPrice = this.NetCost().Round(4);
        var amount = this.Amount().Round(2);
        this.TotalCost((amount * netUnitPrice).Round(2));
    }

    public setTask(task)
    {
        this.AttachedTask(task);
    }

    public toPurchaseRow(eventId : number, jobOrderId: number, eventDate : Date) : IPurchaseRow
    {
        var purchaseRow = {};

        if(this.originalRow) {
            purchaseRow = $.extend(purchaseRow, this.originalRow);
        }

        return <IPurchaseRow>$.extend(purchaseRow, {
            EventId: eventId,
            JobOrderId: jobOrderId,
            PurchaseDate: JSON.stringify(moment(eventDate)).replace(/"/g, ""),
            TaskId: this.AttachedTask() ? this.AttachedTask().Id : null,
            Description: this.Description(),
            RowDescription: this.Description(),
            Amount: this.Amount(),
            UnitMoney: this.UnitCost(),
            Discount: this.Discount(),
            NetMoney: this.NetCost(),
            TotalMoney: this.TotalCost(),
            IsCost: true,
            Billable: false,
            PurchaseType: this.PurchaseType()
        });
    }

    public cloneAsRevenew(unitPriceVariation : number) : PurchaseRevenueRow {
        var revenueRow = new PurchaseRevenueRow(this.serviceLocator, {
            EventId: 0,
            JobOrderId: 0,
            PurchaseDate: "",
            Description: this.Description(),
            RowDescription: this.Description(),
            Amount: this.Amount(),
            UnitMoney: this.UnitCost() + (this.UnitCost() * unitPriceVariation),
            Discount: this.Discount(),
            NetMoney: this.NetCost() + (this.NetCost() * unitPriceVariation),
            TotalMoney: this.TotalCost() + (this.TotalCost() * unitPriceVariation),
            IsCost: false,
            Billable: true,
            PurchaseType: this.PurchaseType(),
            TaskId: this.AttachedTask() ? this.AttachedTask().Id : 0
        }, this.selectedTasks);

        /*revenueRow.AttachedTask(this.AttachedTask());
        revenueRow.PurchaseType(this.PurchaseType());
        revenueRow.Description(this.Description());
        revenueRow.Amount(this.Amount());
        revenueRow.UnitPrice(this.UnitCost() + (this.UnitCost() * unitPriceVariation));
        revenueRow.Discount(this.Discount());
        revenueRow.NetPrice(this.NetCost() + (this.NetCost() * unitPriceVariation));
        revenueRow.TotalPrice(this.TotalCost() + (this.TotalCost() * unitPriceVariation));*/

        return revenueRow;
    }
}