import * as React from "@abstraqt-dev/jsxknockout";
import { VerticalLayout, LayoutColumn } from "../../../../Components/Layouts";
import { RequestForQuotationEditor } from "./RequestForQuotationEditor";
import { RequestForQuotationsList } from "./RequestForQuotationsList";
import { INavBarActionManager } from "../../../../Desktop/interfaces/IApplication";
import { Route, RouteParams, Routes } from "../../../../Components/Routing";

type RequestsForQuotationsPanelProps = {
    navBarActionManager: INavBarActionManager;
}
export class RequestsForQuotationsPanel {
    constructor(public props : RequestsForQuotationsPanelProps) {
    }

    render() {
        return  <VerticalLayout className={"flex-1"}>
                    <LayoutColumn size={12}>
                        <Routes>
                            <Route path="/Provisioning/RequestsForQuotations" exact element={(p) => <RequestForQuotationsList {...p} navBarActionManager={this.props.navBarActionManager} />} />
                            <Route path="/Provisioning/RequestsForQuotations/New" exact element={(p : RouteParams) => <RequestForQuotationEditor {...p} requestId={-1} />} />
                            <Route path="/Provisioning/RequestsForQuotations/:requestId" exact element={(p : RouteParams<{ requestId: string }>) => <RequestForQuotationEditor {...p} requestId={parseInt(p.requestId)} />} />
                        </Routes>
                    </LayoutColumn>
                </VerticalLayout>
    }
}