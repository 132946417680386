import * as ko from "knockout";
import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes"; 
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { FunctionPointRecordViewModel } from "../ViewModels/FunctionPointRecordViewModel";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IEditingDialog } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IEditingDialog";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { IFunctionPointRecordViewModel } from "../../../ProlifeSdk/interfaces/functionpointcomputation/IFunctionPointRecordViewModel";

export class FunctionPointRecordDialogManagerViewModel implements IEditingDialog {
    
    public templateName: string = "new-record-dialog";
    public templateUrl: string = "FunctionPointComputation/Templates/Dialogs";
    public title: string = ProlifeSdk.TextResources.FunctionPointComputation.RecordDialogTitle;
    
    public Record: IFunctionPointRecordViewModel;

    private dialogService: IDialogsService;
    
    modal?: { close: (result?: any) => void; };

    constructor(serviceLocator: IServiceLocator, record?: IFunctionPointRecordViewModel) {
        if (record)
            this.Record = record;
        else
            this.Record = new FunctionPointRecordViewModel(serviceLocator);

        this.dialogService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
    }

    public show(): void {
        this.dialogService.ShowModal(this, "large", null, this.templateUrl, this.templateName);
    }

    close(): void {
        this.modal.close();
    }    
    
    action(): void {
        this.Record.Save()
        .then(() => {
            this.modal.close();
        });
    }    
}