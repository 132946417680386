import * as ko from "knockout";
import * as Core from "../Core/Core";
import { ReferencesMapChartModel } from "../Invoices/bindings/DocumentReferencesMapChart/ReferencesMapChartModel";
import { ReferencesMapChartRenderer } from "../Invoices/bindings/DocumentReferencesMapChart/ReferencesMapChartRenderer";

declare var arbor: any;

export interface IReferenceForMap
{
    SourceDocId : number;
    DestDocId : number;
    SourceId : number;
    SourceEntityType : string;
    DestId : number;
    DestEntityType : string;
}

export class DocumentReferencesMapChart
{
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext)
    {
        let serviceLocator = Core.serviceLocator;
        let simpleEntitiesSourceClickHandler = valueAccessor()["simpleEntitiesSourceClickHandler"] || null;
        let sourceClickHandler = valueAccessor()["sourceClickHandler"] || null;
        let destinationClickHandler = valueAccessor()["destinationClickHandler"] || null;
        let colorsOptions = valueAccessor()["colors"] || {};
        let particleSystemOptions = valueAccessor()["particleSystemOptions"] || {};
        let drawer = arbor;

        let system = drawer.ParticleSystem(particleSystemOptions);
        system.renderer = new ReferencesMapChartRenderer(element, colorsOptions, serviceLocator);

        let flowChartModel = new ReferencesMapChartModel(serviceLocator, system, simpleEntitiesSourceClickHandler, sourceClickHandler, destinationClickHandler);
        $(element).data('__FlowChartModel__', flowChartModel);
    }

    update(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext)
    {
        var docId = ko.utils.unwrapObservable(valueAccessor()["documentId"]);
        var docType = ko.utils.unwrapObservable(valueAccessor()["documentType"]);
        var references = ko.utils.unwrapObservable(valueAccessor()["references"]);

        let flowChartModel = <ReferencesMapChartModel> $(element).data('__FlowChartModel__');
        flowChartModel.RefreshData(references, docId, docType);
    }

}

ko.bindingHandlers["documentReferencesMapChart"] = new DocumentReferencesMapChart();