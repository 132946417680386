import * as ko from "knockout";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { Login } from "./Login";
import { UserManager } from "../UserManager";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { LazyImport } from "../../Core/DependencyInjection";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IApplicationStarter } from "../interfaces/IApplicationsService";
import { CustomApplication } from "./CustomApplication";

export class ApplicationsViewModel {
    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    public installedApplications: ko.ObservableArray<IApplicationStarter> = ko.observableArray([]);
    public login: Login;
    public userManager: UserManager;

    public firstGroupApplications: ko.Computed<IApplicationStarter[]>;
    public secondGroupApplications: ko.Computed<IApplicationStarter[]>;
    public thirdGroupApplications: ko.Computed<IApplicationStarter[]>;
    public customApplications: ko.ObservableArray<IApplicationStarter> = ko.observableArray([]);

    public customApplicationsFirstGroup: ko.Computed<IApplicationStarter[]>;
    public customApplicationsSecondGroup: ko.Computed<IApplicationStarter[]>;

    public customApplicationsFirstGroupTitle: ko.Observable<string> = ko.observable("");
    public customApplicationsSecondGroupTitle: ko.Observable<string> = ko.observable("");
    public systemMessagesGroupTitle: ko.Observable<string> = ko.observable("");

    private firstGroupApplicationNames: ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.JobOrder.Orders,
        ProlifeSdk.TextResources.Blog.Blog,
        ProlifeSdk.TextResources.Contracts.Contracts,
        ProlifeSdk.ProjectsApplicationCode,
        ProlifeSdk.TextResources.Todolist.TaskBoard,
        ProlifeSdk.TextResources.Planning.ApplicationName,
        ProlifeSdk.TextResources.Allocations.Allocations,
    ]);

    private secondGroupApplicationNames: ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.Invoices.AdministrativeDocuments,
        ProlifeSdk.TextResources.Customers.AddressBook,
        ProlifeSdk.TextResources.Schedule.BillBook,
        ProlifeSdk.TextResources.FileRepository.FileRepository,
        ProlifeSdk.TextResources.Todolist.PersonalTodoList,
        ProlifeSdk.TextResources.Warehouse.Warehouse,
        ProlifeSdk.TextResources.Expenses.Expenses,
        ProlifeSdk.TextResources.WorkedHours.WorkReporting,
        ProlifeSdk.TextResources.Reminder.ApplicationName,
        ProlifeSdk.TextResources.SurveyWizard.SurveyWizardApplication,
        ProlifeSdk.TextResources.Agenda.AgendaApplicationName,
        ProlifeSdk.TextResources.Provisioning.ApplicationName,
        ProlifeSdk.TextResources.GDPR.ApplicationName,
        ProlifeSdk.TextResources.Quotation.ApplicationName,
        ProlifeSdk.TextResources.Tickets.ApplicationName,
    ]);

    private thirdGroupApplicationNames: ko.ObservableArray<string> = ko.observableArray([
        ProlifeSdk.TextResources.Settings.Settings,
        ProlifeSdk.TextResources.Todolist.TemplatesEditor,
        ProlifeSdk.TextResources.ResourcesManager.ApplicationName,
        ProlifeSdk.TextResources.Survey.SurveyApplication,
        ProlifeSdk.TextResources.Reports.Reports,
        ProlifeSdk.TextResources.Assets.Assets,
        ProlifeSdk.TextResources.Desktop.Records,
        ProlifeSdk.TextResources.FunctionPointComputation.FunctionPointComputation,
    ]);

    constructor(private serviceLocator: IServiceLocator) {
        //this.login = new Login(serviceLocator); //Fa partire la procedura di login
        this.userManager = new UserManager(serviceLocator);

        this.firstGroupApplications = ko.computed(() => {
            let applications: IApplicationStarter[] = [];
            this.firstGroupApplicationNames().forEach((appName: string) => {
                const foundApplications = this.installedApplications().filter(
                    (app: IApplicationStarter) => app.applicationName == appName
                );
                applications = applications.concat(foundApplications);
            });
            return applications;
        });

        this.secondGroupApplications = ko.computed(() => {
            let applications: IApplicationStarter[] = [];
            this.secondGroupApplicationNames().forEach((appName: string) => {
                const foundApplications = this.installedApplications().filter(
                    (app: IApplicationStarter) => app.applicationName == appName
                );
                applications = applications.concat(foundApplications);
            });
            return applications;
        });

        this.thirdGroupApplications = ko.computed(() => {
            let applications: IApplicationStarter[] = [];
            this.thirdGroupApplicationNames().forEach((appName: string) => {
                const foundApplications = this.installedApplications().filter(
                    (app: IApplicationStarter) => app.applicationName == appName
                );
                applications = applications.concat(foundApplications);
            });
            return applications;
        });

        this.customApplicationsFirstGroup = ko.computed(() => {
            return this.customApplications()
                .filter((app) => app.group === 1)
                .orderBy((app) => app.order);
        });

        this.customApplicationsSecondGroup = ko.computed(() => {
            return this.customApplications()
                .filter((app) => app.group === 2)
                .orderBy((app) => app.order);
        });
    }

    public async Initialize() {
        if (this.desktopService.isMobileBrowser()) {
            this.firstGroupApplicationNames([
                ProlifeSdk.TextResources.Todolist.TaskBoard,
                ProlifeSdk.TextResources.Blog.Blog,
            ]);
            this.secondGroupApplicationNames([ProlifeSdk.TextResources.WorkedHours.WorkReporting]);
            this.thirdGroupApplicationNames([]);
        }

        await this.loadCustomApplications();
        await this.loadSettings();
        await this.userManager.Initialize();
    }

    async refresh(): Promise<void> {
        await this.loadCustomApplications();
        await this.loadSettings();
    }

    private async loadCustomApplications() {
        const customTiles = await this.desktopService.getCustomTiles();
        const customApplications = customTiles.map((tile) => new CustomApplication(tile));
        this.customApplications(customApplications);
    }

    private async loadSettings() {
        const settings = await this.desktopService.getDesktopSettings();
        this.customApplicationsFirstGroupTitle(settings.customTilesFirstGroupTitle);
        this.customApplicationsSecondGroupTitle(settings.customTilesSecondGroupTitle);
        this.systemMessagesGroupTitle(settings.systemMessagesGroupTitle);
    }

    public VerifyMobileAppEnabled() {
        if (this.desktopService.isMobileBrowser()) {
            const applications = []
                .concat(this.firstGroupApplications())
                .concat(this.secondGroupApplications())
                .concat(this.thirdGroupApplications());
            if (applications.filter((a) => a.visible()).length === 0) {
                this.dialogsService.Alert(
                    TextResources.Desktop.UnauthorizedForMobileMessage,
                    TextResources.Desktop.UnauthorizedForMobileTitle,
                    () => {}
                );
            }
        }
    }

    dispose() {
        //this.login.dispose();
        this.userManager.dispose();
    }
}
