import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { ICustomerControlsEntityProvider } from "../../../ProlifeSdk/interfaces/customer/providers/ICustomerControlsEntityProvider";
import { ICustomersService } from "../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomer } from "../../../ProlifeSdk/interfaces/customer/ICustomer";

export class CustomerControlsEntityProvider implements ICustomerControlsEntityProvider
{
    private customersService : ICustomersService;
    private lastTimeout : ReturnType<typeof setTimeout>;

    public IsCustomer: boolean = null;
    public IsSupplier: boolean = null;

    constructor(private serviceLocator : IServiceLocator){
        this.customersService = <ICustomersService> serviceLocator.findService(ProlifeSdk.CustomersServiceType);
    }

    public findEntities(query : any) {
        if(this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        //Si usa il metodo getAllCustomersLimited che ritorna solo una parte dei risultati altrimenti le combo hanno problemi di performance!!!

        this.lastTimeout = setTimeout(() => {
            this.customersService.getAllCustomersEnvelope(query.term, this.IsCustomer, this.IsSupplier)
                .then((data) => {
                    query.callback({
                        results: data.map((customer : ICustomer) => {
                            return {
                                id: customer.IdCliente,
                                text: customer.FormattedContactName + (customer.PIVA && customer.PIVA.trim().length > 0 ? " (" + customer.PIVA + ")" : ""),
                                RagioneSociale: customer.FormattedContactName,
                                PIVA: customer.PIVA,
                                locked : (customer.BlockedBy || 0) > 0
                            };
                        })
                    });
                });
        }, 500);
    }

    public findEntity(element, callback) {
        var id = parseInt(<string>$(element).val());
        if(!isNaN(id) && id > 0) {
            this.customersService.getCustomerById(id)
                .then((customer : ICustomer) => callback({
                    id: customer.IdCliente,
                    text: customer.FormattedContactName + (customer.PIVA && customer.PIVA.trim().length > 0 ? " (" + customer.PIVA + ")" : ""),
                    RagioneSociale: customer.FormattedContactName,
                    PIVA: customer.PIVA,
                    locked : (customer.BlockedBy || 0) > 0
                }));
        }
    }
}
