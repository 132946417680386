import * as ko from "knockout";
/**
 * Created by d.bucchi on 15/06/2017.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { TodoListTask } from "../providers/TodoListTask";
import { TaskBoard } from "../../task-board/TaskBoard";
import { TaskBoardColumn } from "../../task-board/TaskBoardColumn";
import { PlanningTaskBoardColumn } from "../../task-board/PlanningTaskBoardColumn";
import { ITodoListService } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import { ITodoListElementsProvider } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListWorkflow } from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../../Core/interfaces/IDialogsService";

export class ChangeStateDialog implements IDialog {
    private dialogsService: IDialogsService;
    private todoListService: ITodoListService;

    templateName: string = "activity-change-state-dialog";
    templateUrl: string = "todolist/templates/activity";

    private someTasksSaved: boolean = false;
    observableTitle: ko.Observable<string> = ko.observable("");

    private activity: TodoListTask = null;

    private disposed: boolean = false;
    private selectedContainerId: number;

    TaskToChangeState: ko.Observable<any> = ko.observable();

    title: string;

    modal: {
        close: (result?: any) => void;
    };

    Items: ko.ObservableArray<TaskBoardColumn | PlanningTaskBoardColumn> = ko.observableArray();

    constructor(serviceLocator: IServiceLocator,private activitiesProvider: ITodoListElementsProvider, private taskBoard: any)
    {
        this.dialogsService = <IDialogsService>serviceLocator.findService(ServiceTypes.Dialogs);
        this.todoListService = <ITodoListService>serviceLocator.findService(ProlifeSdk.TodoListServiceType);

        if(taskBoard instanceof TaskBoard) {
            this.Items.push(new TaskBoardColumn(taskBoard, -1, ProlifeSdk.TextResources.Todolist.Backlog, 0, 0, 'fa fa-inbox label-default-mobile lbl', "#c6c6c6", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 0, ProlifeSdk.TextResources.Todolist.ToDo, 0, 0, 'fa fa-tachometer label-primary-mobile lbl', "#428bca", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 1, ProlifeSdk.TextResources.Todolist.InProgress, 0, 0, 'fa fa-bolt label-warning-mobile lbl', "#ecbc29", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 2, ProlifeSdk.TextResources.Todolist.Completed, 0, 0, 'fa fa-flag-checkered label-success-mobile lbl', "#45b6af", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 3, ProlifeSdk.TextResources.Todolist.Verified, 0, 0, 'fa fa-check label-success-mobile lbl', "#45b6af", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 4, ProlifeSdk.TextResources.Todolist.Suspended, 0, 0, 'fa fa-clock-o label-danger-mobile lbl', "#89c4f4", this));
            this.Items.push(new TaskBoardColumn(taskBoard, 5, ProlifeSdk.TextResources.Todolist.DeletedColumn, 0, 0, 'fa fa-trash-o label-danger-mobile lbl', "#f3565d", this));
        } else {
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, -1, ProlifeSdk.TextResources.Todolist.Backlog, 0, 0, 'fa fa-inbox label-default-mobile lbl', "#c6c6c6", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 0, ProlifeSdk.TextResources.Todolist.ToDo, 0, 0, 'fa fa-tachometer label-primary-mobile lbl', "#428bca", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 1, ProlifeSdk.TextResources.Todolist.InProgress, 0, 0, 'fa fa-bolt label-warning-mobile lbl', "#ecbc29", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 2, ProlifeSdk.TextResources.Todolist.Completed, 0, 0, 'fa fa-flag-checkered label-success-mobile lbl', "#45b6af", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 3, ProlifeSdk.TextResources.Todolist.Verified, 0, 0, 'fa fa-check label-success-mobile lbl', "#45b6af", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 4, ProlifeSdk.TextResources.Todolist.Suspended, 0, 0, 'fa fa-clock-o label-danger-mobile lbl', "#89c4f4", this));
            this.Items.push(new PlanningTaskBoardColumn(serviceLocator, taskBoard, 5, ProlifeSdk.TextResources.Todolist.DeletedColumn, 0, 0, 'fa fa-trash-o label-danger-mobile lbl', "#f3565d", this));
        }
    }

    public SetSelectedState(element)
    {
        this.Items().forEach((t: TaskBoardColumn) => {
            element.status == t.status ? t.SetSelectedState(element) : t.SetSelectedState(null);
        });
    }

    public ShowModal(): Promise<void>
    {
        return this.dialogsService.ShowModal<void>(this, "fullscreen", {}, this.templateUrl, this.templateName);
    }

    public SetActivity(activity: TodoListTask) {
        this.activity = activity;
        this.PrepareSelectedActivity(this.activity);
    }

    private PrepareSelectedActivity(activity: TodoListTask) {
        this.TaskToChangeState(activity);
        this.SetTitle();
    }

    private SetTitle()
    {
        var activityName = this.TaskToChangeState().Title();

        if (this.selectedContainerId) {
            this.todoListService.GetWorkflow(this.selectedContainerId)
                .then((w: ITodoListWorkflow) => {
                    this.observableTitle(w.Title + " - " + activityName);
                });

            this.observableTitle();
        } else {
            this.observableTitle(activityName);
        }
    }

    close()
    {
        this.modal.close();
    }

    action()
    {
        this.Items().forEach((t: TaskBoardColumn) => {
            if (this.TaskToChangeState()) {
                t.MoveToState(this.TaskToChangeState().Id, true);
            }
        });
        this.close();
    }
}
