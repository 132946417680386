import * as ko from "knockout";
declare var DocumentTouch;

export class Draggable
{
    static isTouchEnabled() : boolean {
        if(('ontouchstart' in window) || (<any>window).DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }
        return false;
    }

    init(element: any, valueAccessor: () => any): void
    {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var dragOptions = options["dragOptions"];
        var dragEnableSwitch = options["dragEnableSwitch"] as ko.Observable<boolean>;

        if(Draggable.isTouchEnabled()) {
            dragOptions.delay = 300;
        }

        $(element).draggable(dragOptions);

        if (!!dragEnableSwitch) {
            $(element).draggable("option", "disabled", !dragEnableSwitch());

            let subscribe = dragEnableSwitch.subscribe((value) => {
                $(element).draggable("option", "disabled", !value);
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                subscribe.dispose();
            });
        }
    }
}

ko.bindingHandlers['draggable'] = new Draggable();
