import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow, ComponentUtils } from "../../../Core/utils/ComponentUtils";
import jss from "jss";
import { ReportPage, ReportComponent } from "../Components";
import TsxForEach from "../../ForEach";
import { _ReportDesigner } from "../ReportDesigner";
import { RendererFor, ReportRendererProvider } from "./ReportRendererProvider";
import { ReportSelectionOverlay } from "../ReportSelectionOverlay";
import { ReportSection } from "../Components/ReportSection";

const styleSheet = jss.createStyleSheet({
    reportDesignerPage: {
        width: 'var(--w)',
        minHeight: 'var(--h)',
        border: '1px solid #444444',
        margin: '20px auto',
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 0
    }
});
const { classes } = styleSheet.attach();
export const ReportDesignerClass = classes;

type ReportPageRendererProps = {
    page: ReportPage;
    section: ReportSection;
    editingSection: ko.Observable<boolean>;
    reportDesigner: _ReportDesigner;
    onEdit: (component: ReportComponent) => void;
}

export function ReportPageRenderer(props: ReportPageRendererProps) {
    const C = require("./ReportPageRenderer")._ReportPageRenderer as typeof _ReportPageRenderer;
    return <C {...props} />;
}

@RendererFor(nameof<ReportPage>())
export class _ReportPageRenderer {
    static defaultProps: Partial<ReportPageRendererProps> = {
    }

    headerAndFooter : ko.ObservableArray<ReportComponent> = ko.observableArray();
    page: ko.ObservableArray<ReportComponent> = ko.observableArray();
    editing : ko.Observable<boolean> = ko.observable(false);

    constructor(private props : ReportPageRendererProps) {
        this.props.reportDesigner.on("edit", this.props.page, () => {
            this.editing(true);
            this.props.reportDesigner.selectedComponent(this.props.section.header);
        });
        this.props.reportDesigner.selectedComponent.subscribe((newComponent) => {
            if(newComponent && (newComponent === this.props.section.header || newComponent === this.props.section.footer || newComponent.isChildOf(this.props.section.header) || newComponent.isChildOf(this.props.section.footer)))
                return;

                this.editing(false);
        });

        this.headerAndFooter.push(this.props.section.header);
        this.headerAndFooter.push(this.props.section.footer);

        this.page.push(this.props.page);
    }
    
    render() {
        let p = this;

        return ComponentUtils.bindTo(
            <div className={classes.reportDesignerPage} data-bind={{ style: { '--w': p.props.page.width() + "cm", '--h': p.props.page.height() + "cm" }}}>
                <div style={{ position: 'absolute', inset: 0 }} data-bind={{ style: { backgroundColor: p.props.page.backgroundColor, backgroundImage: p.props.page.backgroundImageCSSUrl, backgroundSize: p.props.page.backgroundSize }}}></div>
                <div style={{ position: 'absolute', inset: 0, overflow: 'hidden' }} data-bind={{ style: { opacity: p.editing() ? 0.25 : 1 } }}>
                    <TsxForEach data={this.props.page.children} as="child">
                        {(child) => this.renderChild(child)}
                    </TsxForEach>
                </div>
                <ReportSelectionOverlay disabled={this.editing} reportDesigner={this.props.reportDesigner} components={this.page} onEdit={(c) => this.props.onEdit(c)} />

                <div style={{ position: 'absolute', inset: 0 }} data-bind={{ style: { opacity: p.editing() ? 1 : 0.25, pointerEvents: p.editing() ? 'auto' : 'none', zIndex: p.editing() ? 0 : -1 } }}>
                    {this.renderChild(this.props.section.header)}
                    {this.renderChild(this.props.section.footer)}
                    <ReportSelectionOverlay disabled={() => !this.editing()} reportDesigner={this.props.reportDesigner} components={this.headerAndFooter} onEdit={(c) => this.props.onEdit(c)} />
                </div>
            </div>
        , this, "p");
    }

    renderChild(child: ReportComponent) {
        const Renderer = ReportRendererProvider.createRenderer(child);
        return <Renderer component={child} parentPage={this.props.page} reportDesigner={this.props.reportDesigner} />
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ReportPageRenderer);
}