import * as ko from "knockout";
import moment = require("moment");
import jss from "jss";
import { DetectChanges } from "../../../../Core/ChangeDetection";
import { IDetectChanges } from "../../../../Core/interfaces/IDetectChanges";

export const { classes } = jss.createStyleSheet({
    serviceOrderEditor: {
        "& .working-hours-editor": {
            "& .working-hours-slot": {
                border: '1px solid #ddd',
                
                "& .working-hours-start, & .working-hours-duration": {
                    position: 'relative',

                    "& .working-hours-label": {
                        marginTop: '5px',
                        marginLeft: '5px',
                    },

                    "& input.form-control": {
                        height: '22px',
                        borderLeft: "0px",
                        borderRight: "0px",
                        textAlign: "right",

                        "&:last-child": {
                            borderBottom: '0px'
                        }
                            
                    },

                    "& .btn.working-hours-delete-slot": {
                        marginRight: '0',
                        position: 'absolute',
                        top: '-6px',
                        right: '0'
                    }
                },

                "& .working-hours-duration": {
                    marginTop: '5px'
                }
            },

            "& .working-hours-slot ~ .working-hours-slot": {
                marginTop: '5px',
            }
        }
    },

    costsSelector: {
        "& .default-column": {
            width: "70px"
        },

        "& .cost-column": {
            "&.text-center": {
                textAlign: "center"
            },
            width: "100px",
            textAlign: "right"
        },

        "& .annual-hours-column": {
            width: "150px"
        },

        "& .annual-hours-behaviours-column": {
            width: "150px",
            maxWidth: "150px !important"
        },

        "& .no-border": {
            border: "0px"
        },

        "& .no-border-top": {
            borderTop: "0px"
        },
        
        "& .no-border-bottom": {
            borderBottom: "0px"
        },
        
        "& .no-border-right": {
            borderRight: "0px"
        },
        
        "& .no-border-left": {
            borderLeft: "0px"
        },

        "& .annual-hours-label": {
            width: "40px !important",
            minWidth: "40px !important",
            paddingLeft: "5px"
        },

        "& .v-align-middle": {
            verticalAlign: "middle"
        },
        
        "& td.cost-column": {
            padding: "0px",
            border: "1px solid #ccc"
        },

        "& td.annual-hours-behaviours-column": {
            padding: "0px",
            border: "1px solid #ccc",
            borderRight: "0px"
        },

        "& td.annual-hours-column": {
            padding: "0px",
            border: "1px solid #ccc",

            "& .flex-container": {
                alignItems: "center"
            }
        }
    }
}).attach();

export abstract class WorkingHourEditor<T> implements IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable();

    @DetectChanges
    Id : ko.Observable<number> = ko.observable();
    @DetectChanges
    DayOfWeek : ko.Observable<number> = ko.observable();
    @DetectChanges
    Start: ko.Observable<Date> = ko.observable();
    @DetectChanges
    Duration: ko.Observable<number> = ko.observable();

    constructor(id: number, dayOfWeek: number, start: Date, duration: number) {
        this.Id(id);
        this.DayOfWeek(dayOfWeek);
        this.Start(start);
        this.Duration(duration);

        this.isChanged(0);
    }
    
    dispose(): void {}

    abstract getData(): T;
}

export abstract class WorkingDayEditor<T extends WorkingHourEditor<K>, K> implements IDetectChanges {
    isChanged: ko.Observable<number> = ko.observable();
    
    @DetectChanges
    WorkingHours: ko.ObservableArray<T> = ko.observableArray();
    @DetectChanges
    DayName : ko.Observable<string> = ko.observable();

    HasWorkingHours: ko.Computed<boolean>;
    TotalHours: ko.Computed<number>;

    constructor(public dayOfWeek: number) {
        this.DayName(moment.weekdaysShort(true, dayOfWeek - 1));

        this.HasWorkingHours = ko.computed(() => {
            return this.WorkingHours().length > 0;
        });

        this.TotalHours = ko.computed(() => {
            return this.WorkingHours().sum(h => h.Duration());
        });

        this.isChanged(0);
    }

    dispose(): void {
    }

    protected getDefaultStartingHour() {
        return moment("08:00:00", "HH:mm:ss").toDate();
    }

    abstract createNewWorkingHour(start: Date) : T;
    abstract createWorkingHour(id: number, start: Date, duration: number) : T;

    async AddWorkingHours() {
        let nextHourSlot: Date;
        if(this.WorkingHours().length > 0) {
            let lastHourSlot = this.WorkingHours()[this.WorkingHours().length - 1];
            nextHourSlot = moment(lastHourSlot.Start()).add('hours', lastHourSlot.Duration()).toDate();
        } else {
            nextHourSlot = this.getDefaultStartingHour();
        }

        this.WorkingHours.push(this.createNewWorkingHour(nextHourSlot));
    }

    RemoveWorkingHours(hour: T) {
        this.WorkingHours.remove(hour);
    }

    CopyFrom(sourceDay: WorkingDayEditor<T, K>) {
        this.WorkingHours(
            sourceDay.WorkingHours().map(wh => this.createWorkingHour(-1, moment(wh.Start()).toDate(), wh.Duration()))
        );
    }

    getData(): K[] {
        return this.WorkingHours().map(wh => wh.getData());
    }
}