import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { LazyImport } from "../Core/DependencyInjection";
import { ComponentUtils } from "../Core/utils/ComponentUtils";
import { IRelatedDocumentsPerTypeComponent, RelatedDocumentsPerTypeComponent } from "./WorkflowRelatedDocumentsComponent/RelatedDocumentsPerTypeEditorComponent";
import { IWorkflowEditor, IWorkflowEditorListener } from "../ProlifeSdk/interfaces/todolist/IWorkflowEditor";
import { IWorkflowRelatedDocument, ITodoListService, IWorkflowRelatedDocumentsSectionsDefaultSettings } from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { IInfoToastService } from "../Core/interfaces/IInfoToastService";
import { Layout } from "./Layouts";
import { CheckBox } from "./Checkbox";
import { IPortletVM } from "./Portlet";

export interface IWorkflowRelatedDocumentsEditorProps {
    workflowId: number;
    jobOrderId: ko.Observable<number>;
    forwardRef?: (editor: WorkflowRelatedDocumentsEditorComponent) => void;
    owner?: IWorkflowEditor;
}

export interface IWorkflowRelatedDocumentsEditorComponent {
    hasChanges(): boolean;
    isValid(): boolean;
    getData(): IWorkflowRelatedDocument[];
}

export class WorkflowRelatedDocumentsEditorComponent implements IWorkflowRelatedDocumentsEditorComponent, IWorkflowEditorListener {
    public GetAllJobOrders: ko.Observable<boolean> = ko.observable(false);
    public GetTotallyAssignedOrdersRows: ko.Observable<boolean> = ko.observable(false);
    
    public InvoicesTitle: ko.Observable<string> = ko.observable("Fatture");
    public DdtsTitle: ko.Observable<string> = ko.observable("DDT");
    public EstimatesTitle: ko.Observable<string> = ko.observable("Preventivi");
    public SalsTitle: ko.Observable<string> = ko.observable("Sal");
    public CustomerOrdersTitle: ko.Observable<string> = ko.observable("Ordini cliente");
    public SupplierOrdersTitle: ko.Observable<string> = ko.observable("Ordini fornitore");
    public WarehouseLoadsTitle: ko.Observable<string> = ko.observable("Carichi di magazzino");

    public InvoicesManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public DdtsManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public EstimatesManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public SalsManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public CustomerOrdersManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public SupplierOrdersManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();
    public WarehouseLoadsManager: ko.Observable<IRelatedDocumentsPerTypeComponent> = ko.observable();

    // Per pulsantiera
    public InvoicesScroller: ko.Observable<boolean> = ko.observable(false);
    public DdtsScroller: ko.Observable<boolean> = ko.observable(false);
    public EstimatesScroller: ko.Observable<boolean> = ko.observable(false);
    public SalsScroller: ko.Observable<boolean> = ko.observable(false);
    public CustomerOrdersScroller: ko.Observable<boolean> = ko.observable(false);
    public SupplierOrdersScroller: ko.Observable<boolean> = ko.observable(false);
    public WarehouseLoadsScroller: ko.Observable<boolean> = ko.observable(false);
    // Per pulsantiera

    private ComponentsAreInitialized: ko.Computed<boolean>;

    private WorkflowId: number;
    private JobOrderId: ko.Observable<number>;
    private Owner: IWorkflowEditor;

    private pendingDocumnetsLoad = false;
    private documentsToLoad: IWorkflowRelatedDocument[] = [];

    @LazyImport(nameof<ITodoListService>())
    private todoListService: ITodoListService;
    
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    constructor(private props: IWorkflowRelatedDocumentsEditorProps) {
        this.WorkflowId = this.props.workflowId;
        this.JobOrderId = this.props.jobOrderId;
        this.Owner = this.props.owner;

        if (this.Owner) {
            this.Owner.addListener(this);
        }

        this.GetAllJobOrders.subscribe(() => this.setGetAllJobOrdersOnManagers());
        this.GetTotallyAssignedOrdersRows.subscribe(() => this.setGetFullyAssignedRowsOnManagers());

        this.ComponentsAreInitialized = ko.computed(() => {
            return !!this.InvoicesManager()
                && !!this.DdtsManager()
                && !!this.EstimatesManager()
                && !!this.SalsManager()
                && !!this.CustomerOrdersManager()
                && !!this.SupplierOrdersManager()
                && !!this.WarehouseLoadsManager()
        });

        this.ComponentsAreInitialized.subscribe((value: boolean) => {
            if (!value)
                return;

            if (this.pendingDocumnetsLoad) {
                this.pendingDocumnetsLoad = false;
                this.loadDocumentsOnManagers(this.documentsToLoad);
                this.documentsToLoad = [];
            }

            this.setJobOrderIdOnManagers();
            this.setGetAllJobOrdersOnManagers();
            this.setGetFullyAssignedRowsOnManagers();

            this.loadSectionsSettings();
        });

        this.loadWorkflowRelatedDocuments();

        this.props.forwardRef && this.props.forwardRef(this);
    }
    
    public isValid(): boolean {
        return this.InvoicesManager().isValid()
            && this.DdtsManager().isValid()
            && this.EstimatesManager().isValid()
            && this.SalsManager().isValid()
            && this.CustomerOrdersManager().isValid()
            && this.SupplierOrdersManager().isValid()
            && this.WarehouseLoadsManager().isValid()
    }
    
    public getData(): IWorkflowRelatedDocument[] {
        let relatedDocuments: IWorkflowRelatedDocument[] = [];
        
        relatedDocuments = relatedDocuments.concat(this.InvoicesManager().getData());
        relatedDocuments = relatedDocuments.concat(this.DdtsManager().getData());
        relatedDocuments = relatedDocuments.concat(this.EstimatesManager().getData());
        relatedDocuments = relatedDocuments.concat(this.SalsManager().getData());
        relatedDocuments = relatedDocuments.concat(this.CustomerOrdersManager().getData());
        relatedDocuments = relatedDocuments.concat(this.SupplierOrdersManager().getData());
        relatedDocuments = relatedDocuments.concat(this.WarehouseLoadsManager().getData());

        return relatedDocuments;
    }
    
    public getWorkflowId(): number {
        return this.WorkflowId;
    }

    public async loadWorkflowRelatedDocuments(): Promise<void> {
        if (!this.WorkflowId)
            return;

        const relatedDocuments: IWorkflowRelatedDocument[] = await this.todoListService.GetWorkflowRelatedDocuments(this.WorkflowId);
        
        if (!this.ComponentsAreInitialized()) {
            this.pendingDocumnetsLoad = true;
            this.documentsToLoad = relatedDocuments;
            return;
        }

        this.loadDocumentsOnManagers(relatedDocuments);
    }

    public hasChanges(): boolean {
        return (this.InvoicesManager() && this.InvoicesManager().hasChanges())
            || (this.DdtsManager() && this.DdtsManager().hasChanges())
            || (this.EstimatesManager() && this.EstimatesManager().hasChanges())
            || (this.SalsManager() && this.SalsManager().hasChanges())
            || (this.CustomerOrdersManager() && this.CustomerOrdersManager().hasChanges())
            || (this.SupplierOrdersManager() && this.SupplierOrdersManager().hasChanges())
            || (this.WarehouseLoadsManager() && this.WarehouseLoadsManager().hasChanges());
    }
    
    public onJobOdrerChange(jobOrderId: number): void {
        this.JobOrderId(jobOrderId);
        
        if (!this.ComponentsAreInitialized())
            return;

        this.setJobOrderIdOnManagers();
    }

    public scrollToInvoices(): void {
        if (!this.InvoicesManager())
            return;

        this.InvoicesManager().expand();
        this.InvoicesManager().scrollTo();
    }

    public scrollToDdts(): void {
        if (!this.DdtsManager())
            return;

        this.DdtsManager().expand();
        this.DdtsManager().scrollTo();
    }
    
    public scrollToEstimates(): void {
        if (!this.EstimatesManager())
            return;

        this.EstimatesManager().expand();
        this.EstimatesManager().scrollTo();
    }
    
    public scrollToSals(): void {
        if (!this.SalsManager())
            return;

        this.SalsManager().expand();
        this.SalsManager().scrollTo();
    }

    public scrollToCustomerOrders(): void {
        if (!this.CustomerOrdersManager())
            return;

        this.CustomerOrdersManager().expand();
        this.CustomerOrdersManager().scrollTo();
    }

    public scrollToSupplierOrders(): void {
        if (!this.SupplierOrdersManager())
            return;

        this.SupplierOrdersManager().expand();
        this.SupplierOrdersManager().scrollTo();
    }

    public scrollToWarehouseLoads(): void {
        if (!this.WarehouseLoadsManager())
            return;

        this.WarehouseLoadsManager().expand();
        this.WarehouseLoadsManager().scrollTo();
    }

    public async saveSectionsConfig(): Promise<void> {
        const config: IWorkflowRelatedDocumentsSectionsDefaultSettings = {
            InvoicesOpened: !this.InvoicesManager()?.Collapsed(),
            DdtsOpened: !this.DdtsManager()?.Collapsed(),
            EstimatesOpened: !this.EstimatesManager()?.Collapsed(),
            SalsOpened: !this.SalsManager()?.Collapsed(),
            CustomerOrdersOpened: !this.CustomerOrdersManager()?.Collapsed(),
            SupplierOrdersOpened: !this.SupplierOrdersManager()?.Collapsed(),
            WarehouseLoadsOpened: !this.WarehouseLoadsManager()?.Collapsed(),
        };

        await this.todoListService.saveWorkflowRelatedDocumentsSectionsConfiguration(config);

        this.infoToastService.Success(ProlifeSdk.TextResources.Allocations.CartInfoConfigSaved);
    }

    public async loadSectionsSettings(): Promise<void> {
        const config: IWorkflowRelatedDocumentsSectionsDefaultSettings = await this.todoListService.getWorkflowRelatedDocumentsSectionsConfiguration();
        
        config.InvoicesOpened ? this.InvoicesManager().expand() : this.InvoicesManager().collapse();
        config.DdtsOpened ? this.DdtsManager().expand() : this.DdtsManager().collapse();
        config.EstimatesOpened ? this.EstimatesManager().expand() : this.EstimatesManager().collapse();
        config.SalsOpened ? this.SalsManager().expand() : this.SalsManager().collapse();
        config.CustomerOrdersOpened ? this.CustomerOrdersManager().expand() : this.CustomerOrdersManager().collapse();
        config.SupplierOrdersOpened ? this.SupplierOrdersManager().expand() : this.SupplierOrdersManager().collapse();
        config.WarehouseLoadsOpened ? this.WarehouseLoadsManager().expand() : this.WarehouseLoadsManager().collapse();
    }
    
    private setJobOrderIdOnManagers() {
        this.InvoicesManager().setJobOrderId(this.JobOrderId());
        this.DdtsManager().setJobOrderId(this.JobOrderId());
        this.EstimatesManager().setJobOrderId(this.JobOrderId());
        this.SalsManager().setJobOrderId(this.JobOrderId());
        this.CustomerOrdersManager().setJobOrderId(this.JobOrderId());
        this.SupplierOrdersManager().setJobOrderId(this.JobOrderId());
        this.WarehouseLoadsManager().setJobOrderId(this.JobOrderId());
    }

    private setGetAllJobOrdersOnManagers() {
        this.InvoicesManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.DdtsManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.EstimatesManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.SalsManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.CustomerOrdersManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.SupplierOrdersManager().setGetAllJobOrders(this.GetAllJobOrders());
        this.WarehouseLoadsManager().setGetAllJobOrders(this.GetAllJobOrders());
    }
    
    private setGetFullyAssignedRowsOnManagers() {
        this.InvoicesManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.DdtsManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.EstimatesManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.SalsManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.CustomerOrdersManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.SupplierOrdersManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
        this.WarehouseLoadsManager().setGetFullyAssignedRows(this.GetTotallyAssignedOrdersRows());
    }

    private loadDocumentsOnManagers(relatedDocuments: IWorkflowRelatedDocument[]) {
        this.InvoicesManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.InvoiceEntityTypeCode));
        this.DdtsManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.DdtEntityTypeCode));
        this.EstimatesManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.EstimateEntityTypeCode));
        this.SalsManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.SalEntityTypeCode));
        this.CustomerOrdersManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.CustomerOrderEntityTypeCode));
        this.SupplierOrdersManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.SupplierOrderEntityTypeCode));
        this.WarehouseLoadsManager().loadDocuments(relatedDocuments.filter(r => r.DocumentType === ProlifeSdk.WarehouseLoadEntityTypeCode));
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const relatedDocuments = this;
        const $data: IPortletVM = null;
        const Portlet: IPortletVM = null;
        
        return ComponentUtils.bindTo((
            <Layout.Grid rows={["1fr"]} columns={["1fr", "185px"]} noOverflow >
                <Layout.Grid.Cell row={1} column={1} className="flex-vertical" noOverflow>
                    <Layout.WithHeader className="related-documents">
                        <Layout.WithHeader.Header className="flex-vertical">
                            <div className="header flex-container">
                                <div className="flex-1">
                                    <CheckBox checked={this.GetAllJobOrders} label="Mostra anche documenti di altre commesse" />
                                </div>
                                <div className="flex-1">
                                    <CheckBox checked={this.GetTotallyAssignedOrdersRows} label="Mostra anche le righe completamente assegnate" />
                                </div>
                            </div>
                        </Layout.WithHeader.Header>
                        <Layout.WithHeader.Content className="no-gutters flex-vertical" noOverflow>
                            <Layout.ScrollContainer className="documents-groups" systemScrollable>
                                <RelatedDocumentsPerTypeComponent title={this.InvoicesTitle()} documentType='DOC' forwardRef={(component) => this.InvoicesManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.DdtsTitle()} documentType='DDT' forwardRef={(component) => this.DdtsManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.EstimatesTitle()} documentType='EST' forwardRef={(component) => this.EstimatesManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.SalsTitle()} documentType='SAL' forwardRef={(component) => this.SalsManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.CustomerOrdersTitle()} documentType='COR' forwardRef={(component) => this.CustomerOrdersManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.SupplierOrdersTitle()} documentType='SOR' forwardRef={(component) => this.SupplierOrdersManager(component)}></RelatedDocumentsPerTypeComponent>
                                <RelatedDocumentsPerTypeComponent title={this.WarehouseLoadsTitle()} documentType='WHL' forwardRef={(component) => this.WarehouseLoadsManager(component)}></RelatedDocumentsPerTypeComponent>
                            </Layout.ScrollContainer>
                        </Layout.WithHeader.Content>
                    </Layout.WithHeader>
                    <Layout.ScrollContainer systemScrollable></Layout.ScrollContainer>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell row={1} column={2}>
                    <div className="navbar pushbutton-panel">
                        <div className="collapse navbar-collapse">
                            <h3 className="sections-title">Sezioni</h3>
                            <ul className="nav navbar-nav">
                                <li className="" data-bind={{ with: relatedDocuments.InvoicesManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToInvoices.bind(relatedDocuments) }}>
                                        Fatture
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.DdtsManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToDdts.bind(relatedDocuments) }}>
                                        DDT
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.EstimatesManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToEstimates.bind(relatedDocuments) }}>
                                        Preventivi
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.SalsManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToSals.bind(relatedDocuments) }}>
                                        SAL
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.CustomerOrdersManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToCustomerOrders.bind(relatedDocuments) }}>
                                        Ordini cliente
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.SupplierOrdersManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToSupplierOrders.bind(relatedDocuments) }}>
                                        Ordini fornitore
                                    </button>
                                </li>
                                <li className="" data-bind={{ with: relatedDocuments.WarehouseLoadsManager }}>
                                    <ko-with data-bind={{ Portlet }}>
                                    <button className="btn btn-default btn-xs btn-status" data-bind={{ css: { 'btn-primary': !$data.Collapsed() }, toggle: $data.Collapsed, attr: { 'title': ($data.Collapsed() ? 'La sezione è aperta' : 'La sezione è chiusa') }, tooltip: { placement: 'left' } }}>
                                        <i className="fa" data-bind={{ css: { 'fa-folder-open-o': !$data.Collapsed(), 'fa-folder-o': $data.Collapsed } }}></i>
                                    </button>
                                    </ko-with>
                                    <button className="btn btn-default btn-scroller btn-task-state pull-right" data-bind={{ click: relatedDocuments.scrollToWarehouseLoads.bind(relatedDocuments) }}>
                                        Carichi magazz.
                                    </button>
                                </li>
                                <li className="actions-separator">
                                    &nbsp;
                                </li>
                                <li className="save-button text-right">
                                    <button className="btn" data-bind={{ click: relatedDocuments.saveSectionsConfig.bind(relatedDocuments) }}>
                                        Salva impostazioni
                                    </button>
                                </li>
                                <li className="defaults-button text-right">
                                    <button className="btn" data-bind={{ click: relatedDocuments.loadSectionsSettings.bind(relatedDocuments) }}>
                                        Carica impostazioni
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Layout.Grid.Cell>
            </Layout.Grid>
        ), this, "relatedDocuments");
    }
}

ko.components.register("workflow-related-documents-editor", {
    viewModel: {
        createViewModel: (params: IWorkflowRelatedDocumentsEditorProps, componentInfo: ko.components.ComponentInfo) => {
            const vm = new WorkflowRelatedDocumentsEditorComponent(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <>
                    <div className="flex-container flex-full-height flex-fill no-gutters flex-vertical related-documents-wrapper">
                        
                        <div className="documents-groups flex-fill" data-bind={{ slimScroll: '100%' }}>
                            
                        </div>
                    </div>
                    
                </>
            ]);
            
            return vm;
        },
    },
    template: []
});