import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { ComponentBase } from "../../../Components/ComponentBase";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../../Core/DependencyInjection";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Layout } from "../../../Components/Layouts";
import { Select } from "../../../Components/Select";
import { WarehousesDataSource } from "../../../DataSources/WarehousesDataSource";
import { CustomersDataSource } from "../../../DataSources/CustomersDataSource";
import { JobOrdersDataSource } from "../../../DataSources/JobOrdersDataSource";
import { DateTimeInput } from "../../../Components/DateTimeInput";
import { TextInput } from "../../../Components/TextInput";
import { ITableItem, Table } from "../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../DataSources/IDataSource";
import { PreOrderRowSourceDetails, IProvisioningService, ISavePreOrderRequestRow, ISourceToImport } from "../../ProvisioningService";
import { CheckBox } from "../../../Components/Checkbox";
import { EntityInfo } from "../ui/panels/ArticleInfo";
import { SecondaryRow } from "../../../Components/TableComponent/SecondaryRow";
import { RequirementSourceInfo } from "../ui/panels/RequirementSourceInfo";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type PreOrderImporterProps = {
    preOrderId: number;
    preOrderRows: ISavePreOrderRequestRow[];
}

export class PreOrderImporterDialog extends DialogComponentBase {
    static defaultProps: Partial<PreOrderImporterProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService : IDialogsService;
    
    private importer: _PreOrderImporter;

    constructor(private props : PreOrderImporterProps) {
        super({ className: "fullscreen" });
        this.title(TextResources.Provisioning.RequirementsImporterTitle);
    }

    showModal() : Promise<PreOrderRowSourceDetails[]> {
        return this.dialogsService.ShowModal(this);
    }
    
    action() {
        this.modal.close(this.importer.getSelectedRows());
    }
    
    renderBody() {
        return <PreOrderImporter {...this.props} forwardRef={i => this.importer = i} />;
    }
}

function PreOrderImporter(props: PreOrderImporterProps & { forwardRef: (importer: _PreOrderImporter) => void }) {
    const C = require("./PreOrderImporter")._PreOrderImporter as typeof _PreOrderImporter;
    return <C {...props} />;
}

type PreOrderRowSource = {
    Selected: ko.Computed<boolean>;
    SelectedAmount: ko.Computed<number>;

    Sources: ko.ObservableArray<PreOrderRowSourceRow>;

    DetailsShown: ko.Observable<boolean>;
    toggleDetails: () => void;
} & Omit<ISourceToImport, "sources">

type PreOrderRowSourceRow = {
    Selected: ko.Observable<boolean>;
} & Omit<PreOrderRowSourceDetails, "">

export class _PreOrderImporter extends ComponentBase {
    private WarehousesDataSource : WarehousesDataSource = new WarehousesDataSource();
    private CustomersDataSource : CustomersDataSource = new CustomersDataSource();
    private JobOrdersDataSource : JobOrdersDataSource = new JobOrdersDataSource();

    private WarehouseId: ko.Observable<number> = ko.observable();
    private CustomerId: ko.Observable<number> = ko.observable();
    private JobOrderId: ko.Observable<number> = ko.observable();
    private StartDate: ko.Observable<Date> = ko.observable();
    private EndDate: ko.Observable<Date> = ko.observable();
    private ProtocolSearch: ko.Observable<string> = ko.observable().extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' }});

    Rows : ko.ObservableArray<PreOrderRowSource> = ko.observableArray();

    private SelectAllRows: ko.Computed<boolean>;
    ArticlesAndPurchasesSearchFilter: ko.Observable<string> = ko.observable();
    ShowArticlesAndPurchases: ko.Observable<boolean> = ko.observable(true);
    ShowArticles: ko.Observable<boolean> = ko.observable(true);
    ShowPurchases: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService : IProvisioningService;

    constructor(private props : PreOrderImporterProps & { forwardRef: (importer: _PreOrderImporter) => void }) {
        super();

        this.CustomersDataSource.setReturnGroupedData(false);
        this.JobOrdersDataSource.setBypassCanViewAllForDocuments(true);
        this.JobOrdersDataSource.setViewFilters(true, true, true);

        this.SelectAllRows = ko.computed({
            read: () => {
                const selectedCount = this.Rows().filter(r => r.Selected()).length;
                if(selectedCount === 0) return false;
                if(selectedCount === this.Rows().length) return true;
                return undefined;
            },
            write: (value: boolean) => {
                this.Rows().forEach((r) => r.Selected(value));
            }
        });

        props.forwardRef(this);
    }

    async componentDidMount() {
        await this.load();

        this.disposeOnUnmount(this.WarehouseId.subscribe(() => this.load()));
        this.disposeOnUnmount(this.CustomerId.subscribe(() => this.load()));
        this.disposeOnUnmount(this.JobOrderId.subscribe(() => this.load()));
        this.disposeOnUnmount(this.StartDate.subscribe(() => this.load()));
        this.disposeOnUnmount(this.EndDate.subscribe(() => this.load()));
        this.disposeOnUnmount(this.ProtocolSearch.subscribe(() => this.load()));
    }

    getSelectedRows(): PreOrderRowSourceDetails[] {
        const selectedRows = this.Rows().selectMultiple(r => r.Sources().filter(s => s.Selected()));
        return selectedRows.map(s => ({
            CustomerName: s.CustomerName,
            CustomerOrderRowId: s.CustomerOrderRowId,
            CustomerOrderRowNumber: s.CustomerOrderRowNumber,
            Description: s.Description,
            DocumentDate: s.DocumentDate,
            DocumentId: s.DocumentId,
            DocumentName: s.DocumentName,
            DocumentRowId: s.DocumentRowId,
            EntityRefDescription: s.EntityRefDescription,
            EntityRefId: s.EntityRefId,
            EntityType: s.EntityType,
            Id: s.Id,
            JobOrderName: s.JobOrderName,
            NeededAmount: s.NeededAmount,
            Number: s.Number,
            OthersStockAmount: s.OthersStockAmount,
            RowAmount: s.RowAmount,
            SourceDiscounts: s.SourceDiscounts,
            SourceEntityCode: s.SourceEntityCode,
            SourceEntityId: s.SourceEntityId,
            SourceEntityType: s.SourceEntityType,
            SourceNetUnitPrice: s.SourceNetUnitPrice,
            SourceUnitPrice: s.SourceUnitPrice,
            SourceWarehouse: s.SourceWarehouse,
            SourceWarehouseId: s.SourceWarehouseId,
            UoM: s.UoM,
            WarehouseStock: s.WarehouseStock,
            CustomerOrderRowDeliveryDate: s.CustomerOrderRowDeliveryDate,
            CustomerOrderRowDiscounts: s.CustomerOrderRowDiscounts,
            CustomerOrderRowNetUnitPrice: s.CustomerOrderRowNetUnitPrice,
            CustomerOrderRowTotalPrice: s.CustomerOrderRowTotalPrice,
            CustomerOrderRowUnitPrice: s.CustomerOrderRowUnitPrice,
            EntityMeanCost: s.EntityMeanCost,
            Margin: s.Margin
        }));
    }

    private async load() {
        const rows = await this.provisioningService.SearchSourcesToImport(this.props.preOrderId, {
            warehouseId: this.WarehouseId(),
            customerId: this.CustomerId(),
            jobOrderId: this.JobOrderId(),
            dateFrom: this.StartDate(),
            dateTo: this.EndDate(),
            protocol: this.ProtocolSearch(),
            types: [].concat(this.ShowArticles() ? ["WAR"] : []).concat(this.ShowPurchases() ? ["WPC", "PRC"] : []),
            rows: []
        });
        this.Rows(rows.map(this.createRow, this).filter(r => r.neededAmount > 0));
    }

    private createRow(row : ISourceToImport) : PreOrderRowSource {
        const detailsShown = ko.observable(false);
        const sources = ko.observableArray(row.sources
            .map(s => {
                const sCopy = { ...s };
                const preOrderRowsForEntity = this.props.preOrderRows.filter(r => r.EntityId === row.entityId && r.EntityType === row.entityType);
                for(const por of preOrderRowsForEntity) {
                    const preOrderSource = por.Sources.firstOrDefault(es => es.EntityRefId === sCopy.EntityRefId);
                    if(!preOrderSource)
                        continue;
                    sCopy.NeededAmount -= preOrderSource.Amount;
                }
                return sCopy;
            })
            .filter(s => s.NeededAmount > 0)
            .map(s => ({
                ...s,
                Selected: ko.observable(false)
            })));
        const selected = ko.computed({
            read: () => {
                const selectedCount = sources().filter(s => s.Selected()).length;
                if(selectedCount === 0) return false;
                if(selectedCount === sources().length) return true;
                return undefined;
            },
            write: (value: boolean) => {
                sources().forEach(s => s.Selected(value));
            }
        });        
        const selectedAmount = ko.computed(() => {
            return sources().filter(s => s.Selected()).sum(s => s.NeededAmount);
        });

        return { 
            ...row,
            neededAmount: sources().sum(s => s.NeededAmount),
            Selected: selected,
            SelectedAmount: selectedAmount,
            Sources: sources,
            DetailsShown: detailsShown,
            toggleDetails: () => detailsShown(!detailsShown())
        };
    }

    private createRowModel(row: PreOrderRowSource) : IDataSourceModel<string, PreOrderRowSource> {
        return {
            id: row.entityType + "-" + row.entityId,
            title: row.entityDescription,
            isLeaf: true,
            isGroup: false,
            model: row
        }
    }

    render() {
        let req : IDataSourceModel<string, PreOrderRowSource>;
        const _pei = this;
        const dataSource = Table.defaultDataSource(this.Rows, this.createRowModel.bind(this));

        return <Layout.Grid columns={["1fr"]} rows={["auto", "1fr"]} style={{ position: 'absolute', inset: '15px' }}>
            <Layout.Grid.Cell column={1} row={1}>
                <Select.WithLabel label="Magazzino" allowClear dataSource={this.WarehousesDataSource} value={this.WarehouseId} placeholder="Filtra per magazzino..." />
                <Select.WithLabel label="Cliente" allowClear dataSource={this.CustomersDataSource} value={this.CustomerId} placeholder="Filtra per cliente..." />
                <Select.WithLabel label="Commessa" allowClear dataSource={this.JobOrdersDataSource} value={this.JobOrderId} placeholder="Filtra per commessa..." />
                <DateTimeInput label="Data documento" allowClear value={this.StartDate} placeholder="Data dal..." parent=".modal-body" />
                <DateTimeInput label="" allowClear value={this.EndDate} placeholder="Data al..." parent=".modal-body" />
                <TextInput label="Protocollo" value={this.ProtocolSearch} valueUpdate="afterkeydown" placeholder="Filtra per protocollo..." />
            </Layout.Grid.Cell>
            <Layout.Grid.Cell column={1} row={2}>
                <Table bindTo={{ _pei }} compact systemScrollable fixedLayout dataSource={dataSource} rowAs="req">
                    <Column style={{ width: '20px' }}>
                        <div class="with-identifier">
                            <span className="identifier" data-bind={{ style: { 'background-color': (req.model.entityType === 'WAR' ? '#43b545' : '#56b7f7') }}}></span>
                            <span class="btn btn-link btn-xs" title="Apri/Chiudi Dettagli" style={{ minWidth: '18px' }} data-bind={{ click: req.model.toggleDetails.bind(req.model), tooltip: { placement: 'left' } }}>
                                <i class="fa" data-bind={{ css: { 'fa-angle-right': !req.model.DetailsShown(), 'fa-angle-down': req.model.DetailsShown }}}></i>
                            </span>
                        </div>
                    </Column>
                    <Column style={{ width: '28px', textAlign: 'center' }}>
                        <ColumnHeader style={{ width: '28px', textAlign: 'center', padding: 0 }}>
                            <input type="checkbox" data-bind={{ checkbox: _pei.SelectAllRows }}></input>
                        </ColumnHeader>
                        <ColumnBody>
                            <input type="checkbox" data-bind={{ checkbox: req.model.Selected }}></input>
                        </ColumnBody>
                    </Column>
                    <Column title="Articolo/Acquisto" className="entity-description-column">
                        <ColumnHeader>
                            {() =>  <div className="btn-group btn-group-sm ignore-sort header-btn" style={{ marginRight: '5px' }}>
                                        <button className="btn btn-default dropdown" data-toggle="dropdown">
                                            Tipologie&nbsp;<i class="fa fa-angle-down"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-checkboxes">
                                            <TextInput simple placeholder="Cerca..." value={this.ArticlesAndPurchasesSearchFilter} valueUpdate="afterkeydown" />
                                            <CheckBox label="Tutte" checked={this.ShowArticlesAndPurchases} />
                                            <CheckBox label="Articoli" checked={this.ShowArticles} />
                                            <CheckBox label="Acquisti" checked={this.ShowPurchases} />
                                        </div>
                                    </div>
                            }
                        </ColumnHeader>
                        <ColumnBody>
                            {(item : ITableItem<PreOrderRowSource>) => (
                                <div data-bind={{ click: req.model.toggleDetails.bind(req.model) }} style={{ cursor: 'pointer' }}>
                                    <EntityInfo 
                                        code={item.Data.model.entityCode} 
                                        description={item.Data.model.entityDescription} 
                                        rowDescription=""
                                        type={item.Data.model.entityType} />
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title="Da ordinare" className="required-amount-column">
                        <span data-bind={{ numberText: req.model.neededAmount }}></span>
                    </Column>
                    <Column title="Selezionato">
                        <span data-bind={{ numberText: req.model.SelectedAmount }}></span>
                    </Column>
                    <SecondaryRow visible={() => "req.model.DetailsShown"}>
                        {(item : ITableItem<PreOrderRowSource>) => this.renderSourceRowsTable(item)}
                    </SecondaryRow>
                </Table>
            </Layout.Grid.Cell>
        </Layout.Grid>;
    }

    private renderSourceRowsTable(item : ITableItem<PreOrderRowSource>) {
        let req : IDataSourceModel<string, PreOrderRowSource>;
        let source : IDataSourceModel<number, PreOrderRowSourceRow>;

        const dataSource = Table.defaultDataSource(item.Data.model.Sources, s => ({ id: s.Id, title: s.Description }));

        return  <td colSpan={5}>
                    <div className="with-identifier">
                        <span class="identifier" data-bind={{ style: { 'background-color': (req.model.entityType === 'WAR' ? '#43b545' : '#56b7f7') }}}></span>
                        <div style={{ width: '19px', borderRight: '1px solid #ddd', alignSelf: 'stretch' }}></div>
                        <div class="flex-1 flex-container no-gutters">
                            <div className="flex-1">
                            <Table compact editable dataSource={dataSource} rowAs="source">
                                <Column style={{ width: '28px', textAlign: 'center' }}>
                                    <input type="checkbox" data-bind={{ checkbox: source.model.Selected }} />
                                </Column>
                                <Column title="Riga documento">
                                    <ColumnBody>
                                        {(item : ITableItem<PreOrderRowSourceRow>) => (
                                            <RequirementSourceInfo 
                                                docId={item.Data.model.DocumentId}
                                                docNumber={item.Data.model.Number} 
                                                docDate={item.Data.model.DocumentDate}
                                                rowNumber={item.Data.model.CustomerOrderRowNumber}
                                                protocolName={item.Data.model.DocumentName} />
                                        )}
                                    </ColumnBody>
                                </Column>
                                <Column title="Fabbisogno">
                                    <span data-bind={{ numberText: source.model.NeededAmount }}></span>
                                </Column>
                                <Column title="Q.tà riga">
                                    <span data-bind={{ numberText: source.model.RowAmount }}></span>
                                </Column>
                                <Column title="In stock">
                                    <span data-bind={{ numberText: source.model.WarehouseStock }}></span>
                                </Column>
                                <Column title="Stock altri magazz.">
                                    <span data-bind={{ numberText: source.model.OthersStockAmount }}></span>
                                </Column>
                                <Column title="Data cons.">
                                    <span data-bind={{ dateText: source.model.CustomerOrderRowDeliveryDate, dateTextNoValue: 'N/A' }}></span>
                                </Column>
                                <Column title="Commessa">
                                    <span data-bind={{ text: source.model.JobOrderName }}></span>
                                </Column>
                                <Column title="Magazzino">
                                    <span data-bind={{ text: source.model.SourceWarehouse }}></span>
                                </Column>
                                <Column title="Cliente">
                                    <span data-bind={{ text: source.model.CustomerName }}></span>
                                </Column>
                            </Table>
                            </div>
                        </div>
                    </div>
                </td>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PreOrderImporter);
}