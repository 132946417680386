import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { BaseDataSource } from "./BaseDataSource";
import { IDataSourceModel } from "./IDataSource";
import { IWarehouse, IWarehousesService } from "../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export type IWarehouseDataSourceModel = IDataSourceModel<number, IWarehouse>;

export class WarehousesDocumentDataSource extends BaseDataSource<IWarehouseDataSourceModel> {
    private jobOrderId = -1;
    private customerId: number = null;
    private getDeleted = false;
    private jobOrderWarehouses = [];
    private customerWarehouses = [];

    @LazyImport(nameof<IWarehousesService>())
    private warehousesService: IWarehousesService;

    public getTitle(currentModel: IDataSourceModel<number, IWarehouse>): string {
        return ProlifeSdk.TextResources.Warehouse.Warehouses;
    }

    public async selectDefaultWarehouse() {
        let defaultWarehouse = await this.warehousesService.getDefaultWarehouse();
        await this.selectByIds(defaultWarehouse.Id);
    }

    public setCustomerId(value: number): void {
        this.customerId = value;
    }

    public setJobOrderId(value: number): void {
        this.jobOrderId = value || -1;
    }

    //funzioni che varrnno chiamate dal select2 e restituiscono i dati

    //restituisce i dati da ricerca testuale (text filter)
    public async getData(
        currentModel: IWarehouseDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWarehouseDataSourceModel[]> {
        if (!currentModel) {
            let groups = [];
            this.jobOrderWarehouses = await this.warehousesService.getWarehouses(
                textFilter,
                skip,
                count,
                [this.customerId],
                false,
                this.getDeleted,
                false,
                [this.jobOrderId]
            );
            this.customerWarehouses = await this.warehousesService.getWarehouses(
                textFilter,
                skip,
                count,
                [this.customerId],
                true,
                this.getDeleted,
                true,
                [this.jobOrderId]
            );
            //se non è selezionata la commessa rimuovo il gruppo magazzini commessa
            if (this.jobOrderWarehouses.length > 0) {
                groups.push({
                    id: 0,
                    isGroup: true,
                    isLeaf: false,
                    title: "Magazzini interni",
                });
            }
            if (this.customerWarehouses.length > 0) {
                groups.push({
                    id: 1,
                    isGroup: true,
                    isLeaf: false,
                    title: "Magazzini commessa",
                });
            }
            return groups;
        }

        if (currentModel.id == 0) {
            return this.jobOrderWarehouses.map(this.createModelFor, this);
        }
        if (currentModel.id == 1) {
            return this.customerWarehouses.map(this.createModelFor, this);
        }

        return [];
    }

    public async getById(currentModel: IWarehouseDataSourceModel, ids: number[]): Promise<IWarehouseDataSourceModel[]> {
        let warehouse = await this.warehousesService.getWarehouseById(ids.firstOrDefault());
        return !warehouse ? [] : [this.createModelFor(warehouse)];
    }

    //devo sempre mostrare i magazzini interni quindi mi servono i gruppi!
    public isGroupedData(currentModel: IWarehouseDataSourceModel, textFilter: string): boolean {
        return !currentModel;
    }

    private createModelFor(warehouse: IWarehouse): IDataSourceModel<number, IWarehouse> {
        return {
            id: warehouse.Id,
            title: warehouse.Name,
            isGroup: false,
            isLeaf: true,
            model: warehouse,
        };
    }
}
