import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 14/03/13
 * Time: 14.40
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";

export class SiNoText {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                var value = ko.utils.unwrapObservable(underlyingObservable);
                if (value == null || value == undefined)
                    return "";
                var stringValue = ko.utils.unwrapObservable(underlyingObservable).toString();
                if (stringValue == "true" || stringValue == "True")
                    return ProlifeSdk.TextResources.Desktop.Yes;
                return ProlifeSdk.TextResources.Desktop.No;
            }

        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["SiNoText"] = new SiNoText();