import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSource, IDataSourceView, IDataSourceModel } from "./IDataSource";
import {
    IWarehousesService,
    IWarehouse,
    IWarehouseWithStockInfo,
} from "../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export type IWarehousesDataSourceModel = IDataSourceModel<number, IWarehouseWithStockInfo>;

export class WarehousesDataSource implements IDataSource {
    private showOnlyJobOrderWarehouses = false;
    private jobOrderIds: number[] = [];
    private customerIds: number[] = [];
    private getDeleted = false;
    private getAll = false;

    private view: IDataSourceView;

    @LazyImport(nameof<IWarehousesService>())
    private warehousesService: IWarehousesService;

    constructor() {}

    public getTitle(currentModel: IWarehousesDataSourceModel): string {
        return ProlifeSdk.TextResources.Warehouse.Warehouses;
    }

    public async getData(
        currentModel: IWarehousesDataSourceModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IWarehousesDataSourceModel[]> {
        const warehouses = await this.warehousesService.getWarehouses(
            textFilter,
            skip,
            count,
            this.customerIds,
            this.getAll,
            this.getDeleted,
            this.showOnlyJobOrderWarehouses,
            this.jobOrderIds
        );
        return warehouses.map(this.createModelFor, this);
    }

    public async getById(
        currentModel: IWarehousesDataSourceModel,
        ids: number[]
    ): Promise<IWarehousesDataSourceModel[]> {
        const warehouse = await this.warehousesService.getWarehouseById(ids.firstOrDefault());
        return !warehouse ? [] : [this.createModelFor(warehouse)];
    }

    public getSupportedDropMimeTypes(): string[] {
        return [];
    }

    public setView(view: IDataSourceView): void {
        this.view = view;
    }

    public setCustomerIds(...ids: number[]): void {
        this.customerIds = [...(ids ?? []).filter((id) => !!id)];
    }

    public setJobOrderIds(...ids: number[]): void {
        this.jobOrderIds = [...(ids ?? []).filter((id) => !!id)];
    }

    public setShowOnlyJobOrderWarehouses(value: boolean): void {
        this.showOnlyJobOrderWarehouses = value;
    }

    public setGetAllWarehouses(value: boolean): void {
        this.getAll = value;
    }

    public setGetDeleted(value: boolean): void {
        this.getDeleted = value;
    }

    public isGroupedData(currentModel: IWarehousesDataSourceModel, textFilter: string): boolean {
        return false;
    }

    public areEqual(a: IWarehousesDataSourceModel, b: IWarehousesDataSourceModel): boolean {
        return a === b || (!!a && !!b && a.id === b.id);
    }

    private createModelFor(warehouse: IWarehouse): IWarehousesDataSourceModel {
        return {
            id: warehouse.Id,
            title: warehouse.Name,
            isGroup: false,
            isLeaf: true,
            model: warehouse,
        };
    }
}
