import * as ko from "knockout";
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { BaseDataSource } from "./BaseDataSource";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { LazyImportSettingManager } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { IIvaMode, IIvaModes } from "../ProlifeSdk/interfaces/invoice/settings/IIvaModes";

export interface IVatTypesDataSourceModel extends IDataSourceModel<number, IIvaMode> {
    
}

export class VatTypesDataSource extends BaseDataSource<IVatTypesDataSourceModel> {
    @LazyImportSettingManager(ProlifeSdk.IvaModes)
    private ivaModes : IIvaModes;

    getTitle(currentModel: IVatTypesDataSourceModel): string {
        return TextResources.Invoices.ReasonsForPayment;
    }

    async getData(currentModel: IVatTypesDataSourceModel, textFilter: string, skip: number, count: number): Promise<IVatTypesDataSourceModel[]> {
        let splitFilters = (textFilter || "").toLowerCase().trim().split(' ').filter(w => w.length > 0);

        return this.ivaModes.getIvaModes()
            .filter(d => splitFilters.filter(w => d.CodiceIVA.toLowerCase().indexOf(w) != -1).length == splitFilters.length)
            .slice(skip, count)
            .map(this.createModel, this);
    }

    async getById(currentModel: IVatTypesDataSourceModel, ids: (string | number)[]): Promise<IVatTypesDataSourceModel[]> {
        return this.ivaModes.getIvaModes()
            .filter(d => ids.indexOf(d.IdTipoIVA) >= 0)
            .map(this.createModel, this);
    }

    private createModel(model : IIvaMode) : IVatTypesDataSourceModel {
        return {
            id: model.IdTipoIVA,
            title: model.CodiceIVA,
            isLeaf: true,
            isGroup: false,
            model: model
        }
    }
}