import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { MovementArticle } from "../../ui/Movements/MovementArticle";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { DocumentDataWizardStep } from "../../../../Invoices/invoices/documents/wizard/DocumentDataWizardStep";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IDocumentDataWizardRow, ProcessedRow } from "../../../../Invoices/invoices/documents/wizard/ImportDocumentDataWizard";
import { IDocumentBuilderDocumentOriginatingRows } from "../../../../ProlifeSdk/interfaces/invoice/IDocumentsService";
import { IWizardInitializationInfo } from "../../../../ProlifeSdk/interfaces/invoice/wizard/IWizardInitializationInfo";
import { IArticlesService, IArticle, IArticleStockAndCost } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";

export class WarehouseInventoryAdjustmentDataSource extends DocumentDataWizardStep<MovementArticle>
{
    NewRowId : ko.Observable<number> = ko.observable();
    public isResetting  = false;
    public editing : ko.Observable<boolean> = ko.observable(true);
    private currentWarehouseId : number;

    @LazyImport(nameof<IArticlesService>())
    private articlesService : IArticlesService;
    
    public ReferenceDate : ko.Observable<Date> = ko.observable();
    previousStepRows: IDocumentDataWizardRow[];

    constructor()
    {
        super();
        this.Title(TextResources.Warehouse.InventoryAdjustment);
        this.NewRowId.subscribe(this.createNewRow.bind(this));

        this.RegisterDataSource();
    }

    public RegisterDataSource()
    {
        this.documentsService.registerDataWizardStep(this,
            ProlifeSdk.WarehouseLoadEntityTypeCode
        );
    }

    OnShow(previousStepRows: IDocumentDataWizardRow[]): void {
        this.previousStepRows = previousStepRows;
    }

    OnNext(): IDocumentDataWizardRow[] {
        const documentCurrency = this.initializationInfo.DocumentCurrenciesInfo.DocumentCurrency();

        const newRows = this.Rows().map(r => ({
            Row: new ProcessedRow({
                Id: 0,
                AmountFormula: null,
                Amount: r.Amount(),
                ClosedAmount: 0,
                EntityType: this.initializationInfo.DocTypeCode,
                FKCurrency: documentCurrency.CurrencyId(),
                Currency: documentCurrency.Currency().Symbol,
                FKDocument: 0,
                ManuallyClosed: false,
                NetUnitPriceInDocumentCurrency: (r.NetUnitPrice() ?? 0).ToCurrency(documentCurrency),
                NetUnitPrice: r.NetUnitPrice(),
                Order: 0,
                TotalPriceInDocumentCurrency: (r.Price() ?? 0).ToCurrency(documentCurrency),
                TotalPrice: r.Price(),
                UnitPriceInDocumentCurrency: (r.UnitPrice() ?? 0).ToCurrency(documentCurrency),
                UnitPrice: r.UnitPrice(),
                Description: r.Description(),
                Discounts: r.Discounts(),
                UoM: r.UOM()
            }, documentCurrency),
            IsSelected: ko.observable(),
            OriginatingRows: this.getReferences(r),
            RelatedWorkflows: [],
            SourceRows: []
        }));

        return this.previousStepRows.concat(newRows);
    }

    private getReferences(r : MovementArticle) : IDocumentBuilderDocumentOriginatingRows[] {
        const refs : IDocumentBuilderDocumentOriginatingRows[] = [];
        refs.push({
            RefId: 0,
            DocumentId: 0,
            SourceEntityType : ProlifeSdk.WarehouseArticleEntityTypeCode,
            SourceEntityKeyId : r.getArticleId(),
            DestEntityType : this.initializationInfo.DocTypeCode,
            DestEntityKeyId : 0,
            CatalogId: r.CatalogId(),
            Amount: r.Amount(),
            UnitPrice : r.UnitPrice(),
            Discounts : r.Discounts(),
            NetUnitPrice: r.NetUnitPrice(),
            NetPrice : r.Price(),
            WarehouseId: this.initializationInfo.SourceWarehouseId
        });
        return refs;
    }

    CanShow(initializationInfo: IWizardInitializationInfo): boolean {
        return this.authorizationService.isAuthorized("Warehouse_Articles") && this.CheckInitializationInfoSupport(initializationInfo);
    }

    public async Initialize(initializationInfo: IWizardInitializationInfo)
    {
        await super.Initialize(initializationInfo);
        this.Rows([]);
        this.editing(true);
        this.isResetting = false;
        this.currentWarehouseId = initializationInfo.SourceWarehouseId;
        this.ReferenceDate(initializationInfo.DocumentDate);
    }

    public CheckInitializationInfoSupport(initializationInfo: IWizardInitializationInfo): boolean
    {
        return initializationInfo.DestinationDocumentProtocol.IsInventoryAdjustmentProtocol;
    }

    public removeArticle(article : MovementArticle)
    {
        if(this.isResetting)
            return;

        this.Rows.remove(article);
    }

    public createViewModelFor(article : IArticle) : MovementArticle
    {
        return new MovementArticle(article, false);
    }

    public createNewRow()
    {
        if(!this.NewRowId()) return;
        this.articlesService.getArticleByCatalogRowId(this.NewRowId())
            .then((article : IArticle) => {
                const movementArticle : MovementArticle = this.createViewModelFor(article);

                this.articlesService.getArticleStockAndCostAtDate(article.ArticleId, this.currentWarehouseId, this.initializationInfo.DocumentDate)
                    .then((businessInfo : IArticleStockAndCost) => {
                        if(businessInfo) {
                            movementArticle.UnitPrice(businessInfo.LoadPriceAvg || 0.0);
                            movementArticle.setAvailability(businessInfo.StockAmount || 0.0);
                        }
                    });

                this.Rows.push(movementArticle);
            });

        this.NewRowId(0);
    }

    render() {
        let step : WarehouseInventoryAdjustmentDataSource;
        let row: MovementArticle;

        return ComponentUtils.bindTo(
            <div class="form-horizontal" style="padding: 20px;">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="pull-right">
                            <small>{TextResources.Invoices.DocumentWizardReferenceDate}</small>
                            <ko-bind data-bind={{ dateText: step.ReferenceDate }}></ko-bind>
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table class="table table-striped table-bordered table-advance table-hover">
                            <thead>
                                <tr>
                                    <th>{TextResources.Invoices.DocumentWizardDescription}</th>
                                    <th style="text-align: center;width: 120px;">{TextResources.Invoices.DocumentWizardAmount}</th>
                                    <th style="text-align: center;width: 120px;">{TextResources.Invoices.DocumentWizardUoM}</th>
                                    <th style="text-align: right;width: 120px;">{TextResources.Invoices.DocumentWizardUnitPriceShort}</th>
                                    <th style="text-align: right;width: 120px;">{TextResources.Invoices.DocumentWizardAvailability}</th>
                                    <th style="text-align: center;width: 120px;">{TextResources.Invoices.DocumentWizardRemove}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ko-bind data-bind={{ foreach: { data: step.Rows, as: 'row' }}}>
                                <tr>
                                    <td data-bind={{articleText : row.ArticleId}} style="vertical-align: middle"></td>
                                    <td>
                                        <input class="form-control input-sm" type="text" data-bind={{numberValue: row.Amount, focusOnce: true, selectOnFocus: {}}} />
                                    </td>
                                    <td>
                                        <input class="form-control input-sm" type="text" data-bind={{value: row.UOM, selectOnFocus: {}}} />
                                    </td>
                                    <td>
                                        <input class="form-control input-sm text-right" type="text" data-bind={{moneyValueEx: row.UnitPrice, selectOnFocus: {}}} />
                                    </td>
                                    <td>
                                        <span class="form-control input-sm text-right" data-bind={{numberText: row.Availability}} />
                                    </td>
                                    <td class="text-center">
                                        <a href="#" class="btn btn-xs red" data-bind={{click: step.removeArticle.bind(step, row)}}>
                                            <i class="fa fa-trash-o"></i>
                                            {TextResources.Invoices.DocumentWizardRemove}
                                        </a>
                                    </td>
                                </tr>
                                </ko-bind>

                                <tr>
                                    <td>
                                        <input class="form-control input-sm" type="text" placeholder={TextResources.Invoices.DocumentWizardSearchArticlePlaceholder} data-bind={{articleValue: step.NewRowId}} />
                                    </td>
                                    <td colSpan={5}></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        , this, "step");
    }
}
