import * as ko from "knockout";
import * as moment from "moment";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { TodoListActivity, EstimateBudgetRow } from "../TodoListActivity";
import { TaskTransformsDialog } from "../dialogs/TaskTransformsDialog";
import { TodoListTaskHumanResourceTags } from "../tags/TodoListTaskHumanResourceTags";
import { TodoListTaskJobOrderTags } from "../tags/TodoListTaskJobOrderTags";
import { LazyImport, LazyImportSettingManager } from "../../../../../Core/DependencyInjection";
import { IHumanResourcesSettingsManager } from "../../../../../Users/Users/Settings/HumanResourcesSettingsManager";
import {
    TemplateTasksDataSource,
    ITemplateTasksDataSourceModel,
} from "../../../../../DataSources/TemplateTasksDataSource";
import { TemplatesDataSource } from "../../../../../DataSources/TemplatesDataSource";
import { Right } from "../../../../../Core/Authorizations";
import { IDataSourceListener, IDataSource, IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { IJobOrderService } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import {
    ITodoListTaskAssignedBudgets,
    ITodoListService,
    ITaskInsertOrUpdateResponse,
} from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListService";
import {
    ITodoListFolder,
    ITodoListTaskWorkHistory,
    ITodoListTask,
    ITodoListElementsProvider,
    IEstimateBudgetRow,
    ITodoListTaskLink,
    ITodoListTaskChangeHistory,
} from "../../../../../ProlifeSdk/interfaces/todolist/ITodoList";
import { ITodoListTemplateForList } from "../../../../../ProlifeSdk/interfaces/todolist/ITodoListTemplate";
import {
    ITodoListWorkflow,
    ITodoListWorkflowForList,
} from "../../../../../ProlifeSdk/interfaces/todolist/IWorkflowSelector";
import { IWorkedHoursService } from "../../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IBlogService } from "../../../../../ProlifeSdk/interfaces/blog/IBlogService";
import {
    IPlannedResource,
    IAllocationInfoForTask,
} from "../../../../../ProlifeSdk/interfaces/allocations/IAllocationsService";
import { IProLifeSdkService } from "../../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import {
    IArticlesService,
    IArticleTransformComponent,
    IArticle,
} from "../../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import {
    IBudgetRequestStatesSettingsManager,
    IBudgetRequestState,
} from "../../../../../ProlifeSdk/interfaces/todolist/IBudgetRequestStatesSettingsManager";
import { IJobOrderForList } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { Deferred } from "../../../../../Core/Deferred";
import { ResponseData } from "../../../../../Core/response/ResponseBase";

export class CombinedChangesHistory {
    Date: ko.Computed<Date>;
    ResourceName: ko.Computed<string>;
    StateChange: ko.Observable<TaskChangeHistory> = ko.observable();
    EstimateChange: ko.Observable<TaskChangeHistory> = ko.observable();

    constructor() {
        this.Date = ko.computed(() => {
            if (this.StateChange()) return this.StateChange().Date();

            if (this.EstimateChange()) return this.EstimateChange().Date();

            return null;
        });

        this.ResourceName = ko.computed(() => {
            if (this.StateChange()) return this.StateChange().ResourceName();

            if (this.EstimateChange()) return this.EstimateChange().ResourceName();

            return null;
        });
    }
}

export class TodoListTask extends TodoListActivity implements IDataSourceListener {
    @LazyImport(ProlifeSdk.WorkedHoursServiceType)
    private workedHoursService: IWorkedHoursService;

    @LazyImport(ProlifeSdk.BlogServiceType)
    private blogService: IBlogService;

    @LazyImport(ProlifeSdk.ProlifeSdkServiceType)
    protected prolifeSdkService: IProLifeSdkService;

    @LazyImport(ProlifeSdk.ArticlesServiceType)
    private articlesService: IArticlesService;

    @LazyImportSettingManager(ProlifeSdk.HumanResources)
    private humanResourcesManager: IHumanResourcesSettingsManager;

    @LazyImportSettingManager(ProlifeSdk.BudgetRequestCauses)
    private causesManager: IBudgetRequestStatesSettingsManager;

    //Property dell'oggetto
    public StartDate: ko.Observable<Date> = ko.observable();
    public DueDate: ko.Observable<Date> = ko.observable();

    public ProgressAvg: ko.Observable<number> = ko.observable(0);
    public OnTheFlyProgressAvg: ko.Observable<number> = ko.observable(0); //Serve per la modifica al volo dell'avanzamento
    public Status: ko.Observable<number> = ko.observable(0);

    public RelatedWorkFlow: ko.Observable<number> = ko.observable();
    public RelatedWorkFlowId: ko.Computed<number>;
    public StateFolder: ko.Observable<any> = ko.observable();

    public ReferencedTagsManager: TodoListTaskHumanResourceTags;
    public JobOrdersTagsManager: TodoListTaskJobOrderTags;

    public WorkedAmount: ko.Observable<number> | ko.Computed<number> = ko.observable();

    public BudgetRequestCause: ko.Observable<number> = ko.observable();
    public BudgetRequestCauseName: ko.Computed<string>;
    public EstimatedBudget: ko.Observable<number> = ko.observable();

    public SourceTemplateTaskId: ko.Observable<number> = ko.observable();
    public TemplateTasksDataSource: TemplateTasksDataSource = new TemplateTasksDataSource();

    public SourceTemplateRelatedWorkflowId: ko.Observable<number> = ko.observable();
    public TemplatesDataSource: TemplatesDataSource = new TemplatesDataSource();

    public Links: ko.ObservableArray<TaskLink> = ko.observableArray([]);
    public NewTaskLink: TaskLink;

    @Right("TaskBoard_CanEditSourceTemplate")
    public CanEditSourceTemplate: boolean;

    public TotalEstimatedArticlesAmount: ko.Computed<number>;
    public TotalEstimatedWorkAmount: ko.Computed<number>;
    public TotalEstimatedPurchasesPrice: ko.Computed<number>;

    public Folders: ITodoListFolder[];
    public BudgetRequestCauses: IBudgetRequestState[] = [];

    public Locked: ko.Observable<boolean> = ko.observable(false);

    //Tabs
    public DetailsShown: ko.Observable<boolean> = ko.observable(true);
    public PrerequisitesShown: ko.Observable<boolean> = ko.observable(false);
    public ChangeHistoryShown: ko.Observable<boolean> = ko.observable(false);
    public WorkHistoryShown: ko.Observable<boolean> = ko.observable(false);
    public AssignedBudgetsShown: ko.Observable<boolean> = ko.observable(false);
    public PlannedResourcesShown: ko.Observable<boolean> = ko.observable(false);
    public AllocationsInfoShown: ko.Observable<boolean> = ko.observable(false);

    //Computed
    public StatusDescription: ko.Computed<string>;
    public StateIcon: ko.Observable<string> = ko.observable();
    public StateColor: ko.Observable<string> = ko.observable();

    public ReferencedResourcesVisibility: ko.Observable<boolean> = ko.observable(false);
    public CanEditDuration: ko.Computed<boolean>;

    public ChangesHistory: ko.ObservableArray<TaskChangeHistory> = ko.observableArray();
    public CombinedChangesHistory: ko.Computed<CombinedChangesHistory[]>;

    public IsChangesHistoryLoaded: ko.Observable<boolean> = ko.observable();
    public WorkHistory: ko.ObservableArray<ITodoListTaskWorkHistory> = ko.observableArray();
    public IsWorkHistoryLoaded: ko.Observable<boolean> = ko.observable();
    public AssignedBudgets: ko.ObservableArray<ITodoListTaskAssignedBudgets> = ko.observableArray();
    public PlannedResources: ko.ObservableArray<IPlannedResource> = ko.observableArray();
    public AllocationInfo: ko.ObservableArray<any> = ko.observableArray([]);
    public AreAssignedBudgetsLoaded: ko.Observable<boolean> = ko.observable();
    public ArePlannedResourcesLoaded: ko.Observable<boolean> = ko.observable();
    public AreAllocationsInfoLoaded: ko.Observable<boolean> = ko.observable();
    public TotalWork: ko.Computed<number>;
    public TotalCost: ko.Computed<number>;
    public CanViewCosts: ko.Observable<boolean> = ko.observable();
    public CanViewWork: ko.Observable<boolean> = ko.observable();
    public CanViewBudgets: ko.Observable<boolean> = ko.observable();

    public ShowAllocationsInfo: ko.Computed<boolean>;

    public CreationDate: ko.Observable<Date> = ko.observable();
    public WorkDone: ko.Observable<number> = ko.observable();
    public IsReady: ko.Observable<boolean> = ko.observable();
    public HasWarehouse: ko.Observable<boolean> = ko.observable();
    public HasPurchases: ko.Observable<boolean> = ko.observable();
    public HasResources: ko.Observable<boolean> = ko.observable();
    public IsLateStart: ko.Observable<boolean> = ko.observable();
    public IsLateEnd: ko.Observable<boolean> = ko.observable();
    public HoursToBeBilled: ko.Observable<number> = ko.observable();
    public ShowClosedJobOrders: ko.Observable<boolean> = ko.observable(false);
    public CanDeallocateFromCart: ko.Observable<boolean> = ko.observable();

    public IsMilestone: ko.Observable<boolean> = ko.observable();

    public IsTask: ko.Observable<boolean> = ko.observable(true);

    private selectedResourceId: number;
    private selectedDate: Date;

    private calculatingEstimatedBudgetRows = false;
    private updatingDuration = false;

    private lastDuration = 0;

    private taskIcons = [
        "fa fa-inbox",
        "fa fa-tachometer",
        "fa fa-bolt",
        "fa fa-flag-checkered",
        "fa fa-check",
        "fa fa-clock-o",
        "fa fa-trash-o",
    ];

    private taskColors = [
        "label-default",
        "label-primary",
        "label-warning",
        "label-success",
        "label-success",
        "label-info",
        "label-danger",
    ];

    constructor(
        todolist: any,
        task: ITodoListTask,
        activityProvider: ITodoListElementsProvider,
        private numberOfRelatedBlogEvents: number = 0,
        private loadAllocationsInfo: boolean = true,
        deferLoad = false
    ) {
        super(todolist, task, activityProvider);

        this.BudgetRequestCauses = this.causesManager.get(false);

        this.collapsedTemplateName = "todo-list-task-collapsed";
        this.expandedTemplateName = "task-details";
        this.changeStatusTemplateName = "task-change-status";
        this.changePlanTemplateName = "task-change-plan";
        this.editTemplateName = "todo-list-task-details-edit";
        this.editTemplateInnerName = "todo-list-task-edit";
        this.templateForListInTaskEditor = "todo-list-task-collapsed";

        this.Folders = this.todoListService.GetTasksFolders().filter((f: ITodoListFolder) => {
            return !f.IsAggregateFolder;
        });

        this.ReferencedTagsManager = new TodoListTaskHumanResourceTags("REFERENCED");
        this.JobOrdersTagsManager = new TodoListTaskJobOrderTags(this);

        this.ShowClosedJobOrders.subscribe((value: boolean) => {
            this.JobOrdersTagsManager.ShowClosedJobOrders(value);
        });

        this.Duration.subscribe((duration: number) => {
            if (this.calculatingEstimatedBudgetRows) return;

            const estimatedWorkRows = this.EstimatedWorkRows() || [];
            if (estimatedWorkRows.length == 1) {
                const estimatedWorkRow = estimatedWorkRows[0];
                estimatedWorkRow.Amount(duration / this.Multiplier());
            }

            const actualProgress = this.ProgressAvg();
            const estimatedWorkedHours = (this.lastDuration * actualProgress) / 100;
            const newProgress = duration <= 0 ? 0 : (estimatedWorkedHours / duration) * 100;

            this.ProgressAvg(newProgress < 100 ? newProgress : 99);
            this.lastDuration = duration;
        });

        this.TotalEstimatedWorkAmount = ko.computed(() => {
            this.calculatingEstimatedBudgetRows = true;

            let total = 0;
            this.EstimatedWorkRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });

            this.Duration(total * this.Multiplier());

            this.calculatingEstimatedBudgetRows = false;
            return total;
        });

        this.TotalEstimatedArticlesAmount = ko.computed(() => {
            let total = 0;
            this.EstimatedArticlesRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });
            return total;
        });

        this.TotalEstimatedPurchasesPrice = ko.computed(() => {
            let total = 0;
            this.EstimatedPurchasesRows().forEach((r: EstimateBudgetRow) => {
                total = total + r.Amount();
            });
            return total;
        });

        this.ProgressAvg.subscribe((progress) => {
            this.Status(progress == 100 ? 1 : 0);
        });

        this.StatusDescription = ko.computed(() => {
            let description: string = String.format(
                ProlifeSdk.TextResources.Todolist.InProgressWithProgress,
                numeral(this.ProgressAvg()).format("0,0")
            );
            description = this.ProgressAvg() == 0 ? ProlifeSdk.TextResources.Todolist.ToDo : description;
            description = this.ProgressAvg() == 100 ? ProlifeSdk.TextResources.Todolist.CompletedSingular : description;
            return description;
        });

        this.RelatedWorkFlowId = ko.computed(() => {
            //return parseInt(this.RelatedWorkFlow());
            return this.RelatedWorkFlow();
        });

        this.ContainerIdField = this.RelatedWorkFlowId;

        this.BudgetRequestCauseName = ko.computed(() => {
            const matches: any[] = this.BudgetRequestCauses.filter((t) => {
                return t.Id == this.BudgetRequestCause();
            });
            return matches.length == 0 ? null : matches[0].Description;
        });

        this.CanViewCosts(this.authorizationsService.isAuthorized("JobOrders_ViewCostsDashboard"));
        this.CanViewWork(this.authorizationsService.isAuthorized("TaskBoard_CanViewWorkTabOnTask"));
        this.CanViewBudgets(this.authorizationsService.isAuthorized("JobOrders_BudgetRequest_View"));

        if (
            this.authorizationsService.isAuthorized("Allocations_Start") &&
            task &&
            task.Id > 0 &&
            this.loadAllocationsInfo
        ) {
            this.allocationsService.isTaskDirectlyAllocated(task.Id).then((result: boolean) => {
                this.CanDeallocateFromCart(result);
            });
        }

        this.CanEditDuration = ko.computed(() => {
            return (this.EstimatedWorkRows() || []).length <= 1 && this.Multiplier() == 1;
        });

        this.TotalWork = ko.computed(() => {
            let total = 0;
            this.WorkHistory().forEach((w: ITodoListTaskWorkHistory) => {
                total += w.Hours;
            });
            return total;
        });

        this.TotalCost = ko.computed(() => {
            let total = 0;
            this.WorkHistory().forEach((w: ITodoListTaskWorkHistory) => {
                total += w.Cost;
            });
            return total;
        });

        this.NewTaskLink = new TaskLink(this.task.Id, {
            Id: 0,
            TaskId: 0,
            Type: "",
            ReferenceId: 0,
        });

        this.Multiplier.subscribe((value: number) => {
            if (this.ActivitiesProgressAmountMode() == 0) return;

            if (!value) {
                this.ProgressAvg(this.WorkedAmount() ? 100 : 0);
                return;
            }

            this.ProgressAvg((this.WorkedAmount() / value) * 100);
        });

        this.ShowAllocationsInfo = ko.computed(() => {
            return (this.PlannedResources() && this.PlannedResources().length > 0) || this.AllocationInfo().length > 0;
        });

        this.CombinedChangesHistory = ko.computed(() => {
            if (!this.ChangesHistory()) return [];

            const history: TaskChangeHistory[] = this.ChangesHistory();
            const stateChanges: TaskChangeHistory[] = history.filter((h) => h.IsStateChange());
            const estimateChanges: TaskChangeHistory[] = history.filter((h) => !h.IsStateChange());

            let statesAsSource = false;
            let source: TaskChangeHistory[] = [];
            let target: TaskChangeHistory[] = [];

            if (stateChanges.length == estimateChanges.length || stateChanges.length > estimateChanges.length) {
                statesAsSource = true;
                source = stateChanges;
                target = estimateChanges;
            } else {
                statesAsSource = false;
                source = estimateChanges;
                target = stateChanges;
            }

            const result: CombinedChangesHistory[] = [];

            for (let i = 0; i < source.length; i++) {
                const combined = new CombinedChangesHistory();

                const sourceElem = source[i];
                const targetElem = target.firstOrDefault(
                    (t) =>
                        moment(t.Date()).isSame(moment(sourceElem.Date())) ||
                        (sourceElem.IsCreation() && t.IsCreation())
                );

                combined.StateChange(statesAsSource ? sourceElem : targetElem);
                combined.EstimateChange(statesAsSource ? targetElem : sourceElem);

                if (targetElem) {
                    const targetIndex = target.indexOf(targetElem);
                    if (targetIndex >= 0) target.splice(targetIndex, 1);
                }

                result.push(combined);
            }

            if (target.length > 0) {
                for (const elem of target) {
                    const combined = new CombinedChangesHistory();

                    combined.StateChange(statesAsSource ? null : elem);
                    combined.EstimateChange(statesAsSource ? elem : null);

                    result.push(combined);
                }
            }

            result.sort((l, r) => moment(r.Date()).valueOf() - moment(l.Date()).valueOf());

            return result;
        });

        if (!deferLoad) this.LoadFromModel(task, true);
    }

    onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.TemplatesDataSource) {
            const template = model?.model as ITodoListTemplateForList;
            const newTemplateId = !template ? [] : [template.Id];

            if (this.TemplateTasksDataSource.getTemplates().toString() !== newTemplateId.toString()) {
                this.TemplateTasksDataSource.setTemplates(newTemplateId);
                this.SourceTemplateTaskId(null);
            }
        } else if (sender === this.TemplateTasksDataSource) {
            const templateTask = (model as ITemplateTasksDataSourceModel)?.model;

            if (templateTask) {
                this.TemplateTasksDataSource.setTemplates([templateTask.TemplateId]);

                if (this.SourceTemplateRelatedWorkflowId() != templateTask.TemplateId)
                    this.TemplatesDataSource.selectByIds(templateTask.TemplateId);
            }
        }
    }

    onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {}

    async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        return true;
    }

    public InsertWorkedHoursOnTask(): void {
        const selectedDate = !this.selectedDate ? moment().toDate() : this.selectedDate;
        const jobOrderId = this.JobOrdersTagsManager.JobOrderId();
        let resourceId = this.selectedResourceId;

        if (!resourceId) {
            const userId = this.userInfoService.getIdUser();
            const resource = this.humanResourcesManager.getHumanResourceByUserId(userId);
            resourceId = !resource ? null : resource.Resource.Id;
        }

        this.workedHoursService.ShowWorkedHoursEditorDialog(
            resourceId,
            selectedDate,
            !jobOrderId ? null : parseInt(jobOrderId),
            this.Id,
            null,
            true
        );
    }

    public SetDataForWorkedHoursInsert(resourceId: number, date: Date): void {
        this.selectedResourceId = resourceId;
        this.selectedDate = date;
    }

    public SetShowClosedJobOrders(value: boolean): void {
        this.ShowClosedJobOrders(value);
    }

    public AddNewPurchase(): void {
        if (!this.NewPurchaseDescription()) return;

        const newRow: IEstimateBudgetRow = this.createEstimatedBudgetRowModel(
            ProlifeSdk.EstimatedPurchaseEntityTypeCode,
            this.Multiplier()
        );

        newRow.Description = this.NewPurchaseDescription();
        newRow.EntityKeyId = 1;

        this.EstimatedBudgetRows.push(this.createEstimatedBudgetRow(newRow));

        setTimeout(() => {
            this.NewPurchaseDescription(null);
        }, 100);
    }

    public AddNewLink() {
        if (!this.NewTaskLink.IsValid()) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Todolist.LinkIsNotValid,
                ProlifeSdk.TextResources.Todolist.LinkIsNotValidTitle,
                () => {}
            );
            return;
        }

        const newLink = this.NewTaskLink.Clone();
        this.NewTaskLink.Reset();
        this.Links.push(newLink);
    }

    public RemoveTaskLink(link: TaskLink) {
        this.Links.remove(link);
    }

    public OpenBlog() {
        const newTab = window.open("", "_blank");

        this.blogService.GetJobOrdersForEventsLinkedToTask(this.Id).then((jobOrdersIds: number[]) => {
            const url: string =
                jobOrdersIds.length > 0
                    ? this.blogService.GetUrlForTasksFilter(jobOrdersIds, [this.Id])
                    : this.blogService.GetBlogUrl();
            newTab.location.href = url;
        });
    }

    public SaveChanges(refreshAttachmentsDetailsData?: boolean): Promise<ResponseData<ITaskInsertOrUpdateResponse>> {
        if (
            !this.JobOrdersTagsManager.JobOrderId() ||
            isNaN(this.JobOrdersTagsManager.JobOrderId()) ||
            this.JobOrdersTagsManager.JobOrderId() < 0
        ) {
            this.toastService.Warning(ProlifeSdk.TextResources.Todolist.SelectJobOrder);
            return Promise.reject<ResponseData<ITaskInsertOrUpdateResponse>>(null);
        }

        //if(!this.RelatedWorkFlowId() || isNaN(this.RelatedWorkFlowId()))
        if (!this.RelatedWorkFlowId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.Todolist.SelectWorkflow);
            return Promise.reject<ResponseData<ITaskInsertOrUpdateResponse>>(null);
        }

        if (this.ReportingType() == null || this.ReportingType() == undefined || isNaN(this.ReportingType())) {
            this.toastService.Warning(ProlifeSdk.TextResources.Todolist.SelectReportingType);
            return Promise.reject<ResponseData<ITaskInsertOrUpdateResponse>>(null);
        }

        if (this.IsMilestone() && !this.DueDate()) {
            const def = new Deferred<ResponseData<ITaskInsertOrUpdateResponse>>();

            this.confirmSave().then((confirm: boolean) => {
                if (confirm) {
                    this.internalSaveChanges(refreshAttachmentsDetailsData)
                        .then(() => {
                            def.resolve();
                        })
                        .catch(() => {
                            def.reject();
                        });

                    return;
                }

                def.reject([]);
            });

            return def.promise();
        }

        return this.internalSaveChanges(refreshAttachmentsDetailsData);
    }

    private confirmSave(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Todolist.ConfirmMilestoneTaskWithoutEndDate,
            ProlifeSdk.TextResources.Todolist.ConfirmMilestoneTaskWithoutEndDateCancelBtn,
            ProlifeSdk.TextResources.Todolist.ConfirmMilestoneTaskWithoutEndDateConfirmBtn,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }

    private internalSaveChanges(
        refreshAttachmentsDetailsData?: boolean
    ): Promise<ResponseData<ITaskInsertOrUpdateResponse>> {
        const def = new Deferred<ResponseData<ITaskInsertOrUpdateResponse>>();

        if (this.task != null && this.task.TaskBoardStatus != this.StateFolder().Id) {
            if (this.StateFolder().Id > 3 && this.task.Id) {
                this.todoListService.HasBillableHours(null, this.task.Id, true).then((result: boolean) => {
                    if (!result) {
                        this.InnerSaveChanges(def, refreshAttachmentsDetailsData);
                        return;
                    }

                    this.dialogsService.Confirm(
                        ProlifeSdk.TextResources.Todolist.SureToChangeTaskStateWithBillableHours,
                        ProlifeSdk.TextResources.Todolist.DoNotDeleteTask,
                        ProlifeSdk.TextResources.Todolist.DeleteTask,
                        (confirm: boolean) => {
                            if (!confirm) {
                                def.reject();
                                return;
                            }

                            this.InnerSaveChanges(def, refreshAttachmentsDetailsData);
                        }
                    );
                });
            } else {
                this.InnerSaveChanges(def, refreshAttachmentsDetailsData);
            }
        } else {
            return super.SaveChanges(refreshAttachmentsDetailsData) as Promise<
                ResponseData<ITaskInsertOrUpdateResponse>
            >;
        }

        return def.promise();
    }

    private InnerSaveChanges(
        def: Deferred<ResponseData<ITaskInsertOrUpdateResponse>>,
        refreshAttachmentsDetailsData?: boolean
    ) {
        this.todoListService.CanChangeState(this.Id).then((result: boolean) => {
            if (!result) {
                this.dialogsService.Confirm(
                    ProlifeSdk.TextResources.Todolist.TaskIsNotReady,
                    ProlifeSdk.TextResources.Todolist.DoNotMove,
                    ProlifeSdk.TextResources.Todolist.ContinueMove,
                    (result: boolean) => {
                        if (!result) {
                            def.reject();
                            return;
                        }

                        super
                            .SaveChanges(refreshAttachmentsDetailsData)
                            .then((result) => {
                                def.resolve(result as ResponseData<ITaskInsertOrUpdateResponse>);
                            })
                            .catch(() => {
                                def.reject();
                            });
                    }
                );
            } else {
                super
                    .SaveChanges(refreshAttachmentsDetailsData)
                    .then((result) => {
                        def.resolve(result as ResponseData<ITaskInsertOrUpdateResponse>);
                    })
                    .catch(() => {
                        def.reject();
                    });
            }
        });
    }

    public SwitchReferencedVisibility() {
        this.ReferencedResourcesVisibility(!this.ReferencedResourcesVisibility());
    }

    public SetStateFolder(folder: ITodoListFolder) {
        this.StateFolder(folder);
    }

    public OnJobOrderSelectionChanged(jobOrderId: number) {
        this.WorkflowProvider.SetJobOrder(jobOrderId);
        this.WorkflowsDataSource.setJobOrders(jobOrderId);
        this.RelatedWorkFlow(null);
    }

    public SomeIsChanged(): boolean {
        let isChanged: boolean = super.SomeIsChanged();

        const matches = this.Folders.filter((f: ITodoListFolder) => {
            return f.Id == this.task.TaskBoardStatus;
        });

        isChanged = isChanged || (matches.length > 0 ? matches[0] : this.Folders[0]).Id != this.StateFolder().Id;
        //isChanged = isChanged || this.task.Status != this.Status(); -- N.B. Non serve controllare anche questo campo perché dipende dal ProgressAvg, che è già controllato più avanti
        isChanged = isChanged || this.task.StartDate != this.StartDate();
        isChanged = isChanged || this.task.DueDate != this.DueDate();
        isChanged = isChanged || this.task.ProgressAvg != this.ProgressAvg();
        //isChanged = isChanged || this.task.RelatedWorkFlow != (isNaN(parseInt(this.RelatedWorkFlow())) ? null : parseInt(this.RelatedWorkFlow()));
        isChanged = isChanged || this.task.RelatedWorkFlow != this.RelatedWorkFlow();

        const estimatedPurchasesRows = this.EstimatedPurchasesRows();
        const estimatedWorkRows = this.EstimatedWorkRows();
        const estimatedArticlesRows = this.EstimatedArticlesRows();

        const allRows: EstimateBudgetRow[] = estimatedPurchasesRows
            .concat(estimatedWorkRows)
            .concat(estimatedArticlesRows);

        const deletedRows = this.task.EstimatedBudgetRows.filter((r: IEstimateBudgetRow) => {
            allRows
                .map((r1) => {
                    return r1.row.Id;
                })
                .indexOf(r.Id) == -1;
        });
        isChanged = isChanged || deletedRows.length > 0;
        isChanged = isChanged || allRows.length != this.task.EstimatedBudgetRows.length;
        allRows.forEach((r: EstimateBudgetRow) => {
            isChanged = isChanged || r.SomeIsChanged();
        });

        return isChanged;
    }

    protected createEstimatedBudgetRow(row: IEstimateBudgetRow): EstimateBudgetRow {
        return new EstimateBudgetRow(row, this.JobOrdersTagsManager.JobOrderId, this);
    }

    public LoadFromModel(task: ITodoListTask, refreshAttachmentsDetailsData?: boolean) {
        super.LoadFromModel(task, refreshAttachmentsDetailsData);
        const matches = this.Folders.filter((f: ITodoListFolder) => {
            return f.Id == task.TaskBoardStatus;
        });
        this.StateFolder(matches.length > 0 ? matches[0] : this.Folders[0]);
        this.Status(task.Status);
        this.StartDate(task.StartDate);
        this.DueDate(task.DueDate);
        this.ProgressAvg(parseFloat(task.ProgressAvg.toFixed(2)));
        this.OnTheFlyProgressAvg(task.ProgressAvg);
        this.Locked(task.Locked);
        this.ReferencedTagsManager.LoadFromModel(task.Tags);
        this.JobOrdersTagsManager.LoadFromModel(task.Tags);

        this.StateColor(this.taskColors[task.TaskBoardStatus + 1]);
        this.StateIcon(this.taskIcons[task.TaskBoardStatus + 1]);

        this.BudgetRequestCause(task.BudgetRequestCause);
        //this.RelatedWorkFlow(task.RelatedWorkFlow ? task.RelatedWorkFlow.toString() : "");   //Impostare sempre il workflow dopo aver impostato la commmessa altrimenti lo schiaccia!!!
        this.RelatedWorkFlow(task.RelatedWorkFlow); //Impostare sempre il workflow dopo aver impostato la commmessa altrimenti lo schiaccia!!!

        this.WorkedAmount(
            this.prolifeSdkService.Utilities.Numbers.Round((this.ProgressAvg() / 100) * this.Multiplier(), 3)
        );

        const estimatedBudgetRows = this.task.EstimatedBudgetRows.map(this.createEstimatedBudgetRow.bind(this));
        estimatedBudgetRows.sort((a, b) => a.Order() - b.Order());
        this.EstimatedBudgetRows(estimatedBudgetRows);

        this.Links(this.task.Links.map((l: ITodoListTaskLink) => new TaskLink(this.task.Id, l)));

        const anyTask = this.task as any;
        if (anyTask.CreationDate) this.CreationDate(anyTask.CreationDate);
        if (anyTask.TotalWork) this.WorkDone(anyTask.TotalWork);
        if (anyTask.IsReady != undefined) this.IsReady(anyTask.IsReady);
        if (anyTask.HasWarehouse != undefined) this.HasWarehouse(anyTask.HasWarehouse);
        if (anyTask.HasPurchases != undefined) this.HasPurchases(anyTask.HasPurchases);
        if (anyTask.HasResources != undefined) this.HasResources(anyTask.HasResources);
        if (anyTask.IsLateStart != undefined) this.IsLateStart(anyTask.IsLateStart);
        if (anyTask.IsLateEnd != undefined) this.IsLateEnd(anyTask.IsLateEnd);
        if (anyTask.HoursToBeBilled != undefined) this.HoursToBeBilled(anyTask.HoursToBeBilled);
        if (anyTask.IsTask != undefined) this.IsTask(anyTask.IsTask);

        this.IsMilestone(task.IsMilestone);

        this.AttachmentsManager.setJobOrder(this.JobOrdersTagsManager.JobOrderId());

        this.Duration(task.Duration); // N.B. necessario reimpostare la duration qui perché le computed che osservano le EstimatedBudgetRows la azzerano e alla chiusura dell'editor viene chiesta la conferma di uscita perché il task risulta modificato

        this.IsAllocated(task.IsAllocated);
        this.IsEstimated(task.IsEstimated);

        this.SourceTemplateTaskId(task.SourceTemplateTaskId);

        if (task.SourceTemplateTaskId) {
            this.TemplateTasksDataSource.selectByIds(task.SourceTemplateTaskId);
        }

        this.LoadPlannedResources();
        this.LoadAllocationInfo();
    }

    public MoveElementBefore(beforeId, beforeIsTask, afterId, afterIsTask) {
        const afterOrBefore: boolean = beforeId ? false : true;
        const activityId: number = afterOrBefore ? afterId : beforeId;
        const activityIsTask: boolean = afterOrBefore ? afterIsTask : beforeIsTask;

        this.activityProvider.MoveActivity(this.Id, this.IsTask(), activityId, activityIsTask, afterOrBefore);
    }

    public CancelChanges() {
        super.CancelChanges();
        this.ReferencedTagsManager.CancelChanges();
        this.JobOrdersTagsManager.CancelChanges();
    }

    public GetData() {
        const model = super.GetData() as ITodoListTask;

        model.TaskBoardStatus = this.StateFolder().Id;
        model.Status = this.ProgressAvg() == 100 ? 1 : 0; //this.Status();
        model.StartDate = this.StartDate();
        model.DueDate = this.DueDate();
        model.ProgressAvg = this.ProgressAvg();
        model.BudgetRequestCause = this.BudgetRequestCause();
        model.RelatedWorkFlow = this.RelatedWorkFlow();
        model.Locked = this.Locked();

        this.ReferencedTagsManager.SetTagsOnCollection(model.Tags);
        this.JobOrdersTagsManager.SetTagsOnCollection(model.Tags);

        const estimatedWorkRows: IEstimateBudgetRow[] = this.EstimatedWorkRows().map((r: EstimateBudgetRow) => {
            return r.GetData(this.Multiplier());
        });
        const estimatedPurchasesRows: IEstimateBudgetRow[] = this.EstimatedPurchasesRows().map(
            (r: EstimateBudgetRow) => {
                return r.GetData(this.Multiplier());
            }
        );
        const estimatedArticlesRows: IEstimateBudgetRow[] = this.EstimatedArticlesRows().map((r: EstimateBudgetRow) => {
            return r.GetData(this.Multiplier());
        });

        model.EstimatedBudgetRows = estimatedPurchasesRows.concat(estimatedWorkRows).concat(estimatedArticlesRows);
        model.EstimatedBudget = 0;

        model.Links = this.Links().map((l: TaskLink) => l.getJson());

        model.IsMilestone = this.IsMilestone();
        model.SourceTemplateTaskId = this.SourceTemplateTaskId();

        model.WorkedHoursDefaultRoles = this.WorkedHoursDefaultRoles().map((r) => ({
            TaskId: this.Id,
            RoleId: r.RoleId(),
            Order: r.Order(),
        }));
        model.WorkedHoursDefaultWorkTimeCategories = this.WorkedHoursDefaultWorkTimeCategories().map((w) => ({
            TaskId: this.Id,
            WorkTimeCategoryId: w.WorkTimeCategoryId(),
            Order: w.Order(),
        }));

        return model;
    }

    public ApplyOnTheFlyProgress() {
        this.ProgressAvg(this.OnTheFlyProgressAvg());
        this.SaveChanges();
    }

    public CancelOnTheFlyProgress() {
        this.OnTheFlyProgressAvg(this.ProgressAvg());
    }

    public LoadChangesHistory() {
        if (this.task.Id <= 0) return;

        this.todoListService.GetTaskChangeHistory(this.task.Id).then((hs: ITodoListTaskChangeHistory[]) => {
            let lastProgress = 0;
            let lastEstimate = 0;
            let lastMultiplier = 0;
            let lastMultiplierUnitOfMeasure = "";
            let lastState = -1;

            let firstChange = true;
            hs.reverse().forEach((h) => {
                if (firstChange) {
                    lastProgress = h.NewProgressAvg || lastProgress;
                    lastEstimate = h.NewEstimatedWork || lastEstimate;
                    lastState = h.NewTaskBoardStatus || lastState;

                    if (!h.IsStateChange) h.NewTaskBoardStatus = -2;

                    firstChange = false;
                } else {
                    if (h.NewEstimatedWork == null) {
                        //Cambio di stima o progress
                        h.NewEstimatedWork = lastEstimate;
                        h.NewProgressAvg = lastProgress;
                        h.NewMultiplier = lastMultiplier;
                        h.NewMultiplierUnitOfMeasure = lastMultiplierUnitOfMeasure;
                        lastState = h.NewTaskBoardStatus;
                    } else {
                        //Cambio di stato
                        h.NewTaskBoardStatus = lastState;
                        lastProgress = h.NewProgressAvg;
                        lastEstimate = h.NewEstimatedWork;
                        lastMultiplier = h.NewMultiplier;
                        lastMultiplierUnitOfMeasure = h.NewMultiplierUnitOfMeasure;
                    }
                }
            });
            hs.reverse();

            let lastChange: TaskChangeHistory = null;
            const changes = hs.map((c: ITodoListTaskChangeHistory) => {
                const item = new TaskChangeHistory(c);
                item.Next = lastChange;
                if (lastChange) lastChange.Previous = item;

                lastChange = item;

                return item;
            });

            for (let i = 0; i < changes.length; i++) {
                if (!changes[i].IsStateChange()) continue;

                changes[i].Next = null;
                changes[i].Previous = null;

                for (let j = i - 1; j >= 0; j--) {
                    if (changes[j].IsStateChange()) {
                        changes[i].Next = changes[j];
                        break;
                    }
                }

                for (let j = i + 1; j < changes.length; j++) {
                    if (changes[j].IsStateChange()) {
                        changes[i].Previous = changes[j];
                        break;
                    }
                }
            }

            this.ChangesHistory(changes);
            this.IsChangesHistoryLoaded(true);
        });
    }

    private async SaveChangesHistory() {
        try {
            const response = await this.todoListService.SaveTaskChangeHistory(
                this.ChangesHistory().map((c) => c.toJson())
            );
            if (response.succeeded) this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.StateChangesSaved);
            else this.infoToastService.Error(ProlifeSdk.TextResources.ProlifeSdk.GenericError);
        } catch (e) {
            console.error(e);
        }
    }

    public LoadWorkHistory() {
        if (this.task.Id <= 0) return;

        this.todoListService.GetTaskWorkHistory(this.task.Id).then((ws: ITodoListTaskWorkHistory[]) => {
            this.WorkHistory(ws);
            this.IsWorkHistoryLoaded(true);
        });
    }

    public LoadAssignedBudgets() {
        if (this.task.Id <= 0) return;

        this.todoListService.GetAssignedBudgets(this.task.Id).then((ws: ITodoListTaskAssignedBudgets[]) => {
            this.AssignedBudgets(ws);
            this.AreAssignedBudgetsLoaded(true);
        });
    }

    public LoadPlannedResources() {
        if (this.task.Id <= 0 || !this.loadAllocationsInfo) return;

        this.allocationsService.getPlannedResourcesForTask(this.task.Id).then((resources: IPlannedResource[]) => {
            this.PlannedResources(resources);
            this.ArePlannedResourcesLoaded(true);
        });
    }

    public LoadAllocationInfo() {
        if (this.task.Id <= 0 || !this.loadAllocationsInfo) return;

        this.allocationsService
            .getAllocationInfoForTask(this.task.Id, this.task.RelatedWorkFlow)
            .then((allocationInfo: IAllocationInfoForTask[]) => {
                if (!allocationInfo) {
                    this.AllocationInfo.push(<IAllocationInfoForTask>{
                        TeamName: null,
                        CartName: null,
                        AllocationStartDate: null,
                        RealAllocationEndDate: null,
                        TheoreticalAllocationEndDate: null,
                    });
                    this.AreAllocationsInfoLoaded(true);
                    return;
                }

                this.AllocationInfo(allocationInfo);
                this.AreAllocationsInfoLoaded(true);
            });
    }

    public OpenTransformsDialog(row: EstimateBudgetRow) {
        new TaskTransformsDialog(row.GetData(1)).ShowModal().then((result: IArticleTransformComponent[]) => {
            const newRows = result;

            if (newRows.length == 0) return;

            this.RemoveEstimatedBudgetRow(row);

            newRows.forEach((r) => {
                this.articlesService.getArticleByArticleId(r.ComponentArticleId).then((article: IArticle) => {
                    const budgetRow: IEstimateBudgetRow = this.createEstimatedBudgetRowModel(
                        ProlifeSdk.WarehouseArticleEntityTypeCode,
                        this.Multiplier()
                    );
                    budgetRow.EntityKeyId = article.Id;
                    budgetRow.Description = r.ComponentCode + " " + r.ComponentDescription;
                    budgetRow.Amount = r.Amount;
                    this.EstimatedBudgetRows.push(this.createEstimatedBudgetRow(budgetRow));
                });
            });
        });
    }

    /*public DetailsShown : ko.Observable<boolean> = ko.observable(true);
     public PrerequisitesShown : ko.Observable<boolean> = ko.observable(false);
     public ChangeHistoryShown : ko.Observable<boolean> = ko.observable(false);
     public WorkHistoryShown : ko.Observable<boolean> = ko.observable(false);*/

    public ShowDetailsPane() {
        this.PrerequisitesShown(false);
        this.ChangeHistoryShown(false);
        this.WorkHistoryShown(false);
        this.AssignedBudgetsShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(false);
        this.DetailsShown(true);
    }

    public ShowPrerequisitesPane() {
        this.DetailsShown(false);
        this.ChangeHistoryShown(false);
        this.WorkHistoryShown(false);
        this.AssignedBudgetsShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(false);
        this.PrerequisitesShown(true);
    }

    public ShowChangeHistoryPane() {
        this.PrerequisitesShown(false);
        this.DetailsShown(false);
        this.WorkHistoryShown(false);
        this.AssignedBudgetsShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(false);
        this.ChangeHistoryShown(true);

        if (!this.IsChangesHistoryLoaded()) this.LoadChangesHistory();
    }

    public ShowWorkHistoryPane() {
        this.PrerequisitesShown(false);
        this.ChangeHistoryShown(false);
        this.DetailsShown(false);
        this.AssignedBudgetsShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(false);
        this.WorkHistoryShown(true);

        if (!this.IsWorkHistoryLoaded()) this.LoadWorkHistory();
    }

    public ShowAssignedBudgetPane() {
        this.PrerequisitesShown(false);
        this.ChangeHistoryShown(false);
        this.DetailsShown(false);
        this.WorkHistoryShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(false);
        this.AssignedBudgetsShown(true);

        if (!this.AreAssignedBudgetsLoaded()) this.LoadAssignedBudgets();
    }

    public ShowPlannedResourcesPane() {
        this.PrerequisitesShown(false);
        this.ChangeHistoryShown(false);
        this.DetailsShown(false);
        this.WorkHistoryShown(false);
        this.AssignedBudgetsShown(false);
        this.AllocationsInfoShown(false);
        this.PlannedResourcesShown(true);

        if (!this.ArePlannedResourcesLoaded()) this.LoadPlannedResources();
    }

    public ShowAllocationInfoPane() {
        this.PrerequisitesShown(false);
        this.ChangeHistoryShown(false);
        this.DetailsShown(false);
        this.WorkHistoryShown(false);
        this.AssignedBudgetsShown(false);
        this.PlannedResourcesShown(false);
        this.AllocationsInfoShown(true);

        if (!this.AreAllocationsInfoLoaded()) this.LoadAllocationInfo();
    }

    public GetBudgetRequestCause(assignedBudget: ITodoListTaskAssignedBudgets) {
        const matches: any[] = this.BudgetRequestCauses.filter((t) => {
            return t.Id == assignedBudget.BudgetRequestCause;
        });
        return matches.length == 0 ? null : matches[0].Description;
    }

    public DeallocateTaskFromCart() {
        if (!this.task || this.task.Id <= 0) return;

        this.allocationsService.deleteElement(this.task.Id, true).then(() => {
            this.infoToastService.Success(ProlifeSdk.TextResources.Todolist.TaskSuccessfullyDeallocated);
            this.CanDeallocateFromCart(false);
        });
    }
}

class TaskLink {
    JobOrderId: ko.Observable<number> = ko.observable();
    JobOrderName: ko.Observable<string> = ko.observable();

    WorkflowId: ko.Observable<number> = ko.observable();
    WorkflowName: ko.Observable<string> = ko.observable();

    TaskId: ko.Observable<number> = ko.observable();
    TaskName: ko.Observable<string> = ko.observable();

    private loading = false;

    @LazyImport(ProlifeSdk.JobOrderServiceType)
    private jobOrdersService: IJobOrderService;

    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService: ITodoListService;

    private jobOrderSearchTimeout: ReturnType<typeof setTimeout>;
    private workflowSearchTimeout: ReturnType<typeof setTimeout>;
    private taskSearchTimeout: ReturnType<typeof setTimeout>;

    constructor(private ownerId: number, private link: ITodoListTaskLink) {
        this.Load();
    }

    public Load() {
        if (this.link.Type == ProlifeSdk.JobOrderTaskEntityTypeCode) {
            this.TaskId(this.link.ReferenceId);

            this.todoListService.GetTaskById(this.TaskId()).then((t: ITodoListTask) => {
                this.TaskName(t.Title);

                this.WorkflowId(t.RelatedWorkFlow);

                this.todoListService.GetWorkflow(this.WorkflowId()).then((w: ITodoListWorkflow) => {
                    this.WorkflowName(w.Title);

                    this.JobOrderId(w.JobOrderId);

                    this.jobOrdersService.GetJobOrderForList(this.JobOrderId()).then((j: IJobOrderForList) => {
                        this.JobOrderName(j.Name);
                    });
                });
            });
        } else if (this.link.Type == ProlifeSdk.WorkflowEntityTypeCode) {
            this.WorkflowId(this.link.ReferenceId);

            this.todoListService.GetWorkflow(this.WorkflowId()).then((w: ITodoListWorkflow) => {
                this.WorkflowName(w.Title);

                this.JobOrderId(w.JobOrderId);

                this.jobOrdersService.GetJobOrderForList(this.JobOrderId()).then((j: IJobOrderForList) => {
                    this.JobOrderName(j.Name);
                });
            });
        }
    }

    public IsValid(): boolean {
        return this.JobOrderId() > 0 && this.WorkflowId() > 0;
    }

    public Clone(): TaskLink {
        return new TaskLink(this.ownerId, this.getJson(false));
    }

    public Reset() {
        this.TaskId(null);
        this.WorkflowId(null);
        this.JobOrderId(null);
    }

    public getJson(keepId = true): ITodoListTaskLink {
        if (this.link.Id) {
            return <ITodoListTaskLink>$.extend(this.link, {
                Type: this.TaskId() ? ProlifeSdk.JobOrderTaskEntityTypeCode : ProlifeSdk.WorkflowEntityTypeCode,
                ReferenceId: this.TaskId() ? this.TaskId() : this.WorkflowId(),
            });
        } else {
            return {
                Id: -1,
                TaskId: this.ownerId,
                Type: this.TaskId() ? ProlifeSdk.JobOrderTaskEntityTypeCode : ProlifeSdk.WorkflowEntityTypeCode,
                ReferenceId: this.TaskId() ? this.TaskId() : this.WorkflowId(),
            };
        }
    }

    public findJobOrders(query: any) {
        if (this.jobOrderSearchTimeout) clearTimeout(this.jobOrderSearchTimeout);

        this.jobOrderSearchTimeout = setTimeout(() => {
            this.jobOrdersService
                .GetJobOrdersList(-1, -1, -1, false, query.term, -1)
                .then((jobOrders: IJobOrderForList[]) => {
                    query.callback({
                        results: jobOrders.map((j: IJobOrderForList) => {
                            return {
                                id: j.JobOrderId,
                                text: j.Name,
                            };
                        }),
                    });
                });
        }, 500);
    }

    public findJobOrder(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id)) {
            this.jobOrdersService.GetJobOrderForList(<number>id).then((jobOrder: IJobOrderForList) =>
                callback({
                    id: jobOrder.JobOrderId,
                    text: jobOrder.Name,
                })
            );
        }
    }

    public findWorkflows(query: any) {
        if (this.workflowSearchTimeout) clearTimeout(this.workflowSearchTimeout);

        this.workflowSearchTimeout = setTimeout(() => {
            this.todoListService
                .GetWorkflowsForList(this.JobOrderId(), query.term)
                .then((workflows: ITodoListWorkflowForList[]) => {
                    query.callback({
                        results: workflows.map((w: ITodoListWorkflowForList) => {
                            return {
                                id: w.Id,
                                text: w.Title,
                            };
                        }),
                    });
                });
        }, 500);
    }

    public findWorkflow(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id)) {
            this.todoListService.GetWorkflowForList(<number>id).then((workflow: ITodoListWorkflowForList) =>
                callback({
                    id: workflow.Id,
                    text: workflow.Title,
                })
            );
        }
    }

    public findTasks(query: any) {
        if (this.taskSearchTimeout) clearTimeout(this.taskSearchTimeout);

        this.taskSearchTimeout = setTimeout(() => {
            const textFilters = [];

            if ((query.term || "").length > 0) textFilters.push(query.term);

            this.todoListService
                .GetTasks(textFilters, [], [parseInt(<any>this.WorkflowId())], null, -1, -1, -1)
                .then((tasks: ITodoListTask[]) => {
                    query.callback({
                        results: tasks.map((t: ITodoListTask) => {
                            return {
                                id: t.Id,
                                text: t.Title,
                            };
                        }),
                    });
                });
        }, 500);
    }

    public findTask(element, callback) {
        const id = parseInt(<string>$(element).val());
        if (!isNaN(id)) {
            this.todoListService.GetTaskById(<number>id).then((task: ITodoListTask) =>
                callback({
                    id: task.Id,
                    text: task.Title,
                })
            );
        }
    }
}

class TaskChangeHistory {
    Id: ko.Observable<number> = ko.observable();
    TaskId: ko.Observable<number> = ko.observable();
    Date: ko.Observable<Date> = ko.observable();
    ReferenceDate: ko.Observable<Date> = ko.observable();
    UserId: ko.Observable<number> = ko.observable();
    ResourceName: ko.Observable<string> = ko.observable();
    NewEstimatedWork: ko.Observable<number> = ko.observable();
    NewTaskBoardStatus: ko.Observable<number> = ko.observable();
    NewProgressAvg: ko.Observable<number> = ko.observable();
    NewMultiplier: ko.Observable<number> = ko.observable();
    NewMultiplierUnitOfMeasure: ko.Observable<string> = ko.observable();
    IsCreation: ko.Observable<boolean> = ko.observable();
    IsStateChange: ko.Observable<boolean> = ko.observable();

    WorkedAmount: ko.Computed<number>;

    Previous: TaskChangeHistory;
    Next: TaskChangeHistory;

    @LazyImport(ProlifeSdk.ProlifeSdkServiceType)
    private prolifeSdkService: IProLifeSdkService;

    constructor(private change: ITodoListTaskChangeHistory) {
        this.Id(change.Id);
        this.TaskId(change.TaskId);
        this.Date(change.Date);
        this.ReferenceDate(change.ReferenceDate);
        this.UserId(change.UserId);
        this.ResourceName(change.ResourceName);
        this.NewEstimatedWork(change.NewEstimatedWork);
        this.NewTaskBoardStatus(change.NewTaskBoardStatus);
        this.NewProgressAvg(change.NewProgressAvg);
        this.NewMultiplier(change.NewMultiplier);
        this.NewMultiplierUnitOfMeasure(change.NewMultiplierUnitOfMeasure);
        this.IsCreation(change.IsCreation);
        this.IsStateChange(change.IsStateChange);

        this.WorkedAmount = ko.computed(() => {
            return this.prolifeSdkService.Utilities.Numbers.Round(
                (this.NewProgressAvg() / 100) * this.NewMultiplier(),
                3
            );
        });

        this.ReferenceDate.subscribe(this.onReferenceDateChanged.bind(this));
    }

    private onReferenceDateChanged() {
        if (this.Next && moment(this.ReferenceDate()) > moment(this.Next.ReferenceDate()))
            this.ReferenceDate(moment(this.Next.ReferenceDate()).add("minute", -1).toDate());
        if (this.Previous && moment(this.ReferenceDate()) < moment(this.Previous.ReferenceDate()))
            this.ReferenceDate(moment(this.Previous.ReferenceDate()).add("minute", 1).toDate());
    }

    public toJson(): ITodoListTaskChangeHistory {
        const change: ITodoListTaskChangeHistory = <ITodoListTaskChangeHistory>$.extend({}, this.change);
        change.ReferenceDate = this.ReferenceDate();

        return change;
    }
}
