/**
 * Created with JetBrains WebStorm.
 * User: a.gemignani
 * Date: 26/02/13
 * Time: 15.29
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { JobOrdersControlsEntityProvider } from "./JobOrdersControlsEntityProvider";
import { IJobOrderService } from "../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IEntityProviderService } from "../../../ProlifeSdk/interfaces/IEntityProviderService";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IEntityProvider } from "../../../ProlifeSdk/interfaces/IEntityProvider";
import { IJobOrderForHint, IJobOrder } from "../../../ProlifeSdk/interfaces/job-order/IJobOrder";
import { IControlsEntityProvider } from "../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { Deferred } from "../../../Core/Deferred";

export class JobOrderEntityProvider implements IEntityProvider<number, IJobOrderForHint|IJobOrder> {

	private jobOrderService : IJobOrderService;

	constructor(private serviceLocator : IServiceLocator )
    {
		var entityProviderService : IEntityProviderService = <IEntityProviderService> serviceLocator.findService(ProlifeSdk.EntityProviderServiceType);
		entityProviderService.registerEntityProvider(this, this.getType());
		this.jobOrderService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
	}

    getType() : string
    {
        return ProlifeSdk.JobOrderEntityTypeCode;
    }

    getEntityTypeName() : string
    {
        return ProlifeSdk.TextResources.JobOrder.Order;
    }

    getPkValue(item : IJobOrderForHint) : number
    {
        return item.Id;
    }

    getDisplayName(item : IJobOrderForHint) : string
    {
        return item.Name;
    }

    getDisplayNameForKeyValue(pkValue : any) : Promise<string>
    {
        var def = new Deferred<string>();
        this.jobOrderService.get(pkValue).then(
                (jobOrder : IJobOrder) => {
                    def.resolve(jobOrder.Name);
                }
            )
            .catch(() => { def.reject(); });

        return def.promise();
    }

    hintSearch(stringFilter: string): Promise<IJobOrderForHint[]>
    {
        return this.jobOrderService.hintSearch(stringFilter);
    }

    getControlsProvider() : IControlsEntityProvider
    {
        return new JobOrdersControlsEntityProvider(this.serviceLocator);
    }

    getEntity(pkValue : number) : Promise<IJobOrder>
    {
        var def = new Deferred<IJobOrder>();
        this.jobOrderService.get(pkValue).then((result) => {
            def.resolve(result);
        });
        return def.promise();
    }
}