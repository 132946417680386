import * as React from "@abstraqt-dev/jsxknockout";
import * as numeral from "numeral";
import { ComponentUtils } from "../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../Core/utils/DialogComponentBase";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IDialogsService } from "../../../Core/interfaces/IDialogsService";
import { Layout } from "../../../Components/Layouts";
import { DateTimeText } from "../../../Components/DateTimeText";
import { SentMail } from "../../DocumentsService";
import { SendMailAction } from "./SendMailAction";
import { If, IfNot } from "../../../Components/IfIfNotWith";
import { AttachmentDescriptor } from "../../../Core/interfaces/IException";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";

export enum ActionSet {
    SaveDocumentOption = 0,
    NoSaveDocumentOption = 1,
}

type DocumentConfirmSendMailViewerProps = {
    LastMailSentStatus: SentMail;
    IncludeDocumentAttachments: boolean;
    TotalSizeExceeded: boolean;
    MetadataLogicalTypeWithNoData: string;
    MaxFileSize: number;
    FilesTotalMaxSize: number;
    NoValidMailFound: boolean;
    NoMailTemplateFound: boolean;
    AttachmentsTooLarge: AttachmentDescriptor[];
    ActionSet?: ActionSet;
};

export class DocumentConfirmSendMailViewerDialog extends DialogComponentBase {
    public NoValidMailFound = ko.observable(false);
    public NoMailTemplateFound = ko.observable(false);
    public IsFirstMailSent = ko.observable(false);

    public MailCannotBeSent: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(private props: DocumentConfirmSendMailViewerProps) {
        super({
            actionLabel: TextResources.ProlifeSdk.Confirm,
            closeLabel: TextResources.ProlifeSdk.Abort,
            className: "small",
        });

        if (!this.props.ActionSet) this.props.ActionSet = ActionSet.SaveDocumentOption;

        this.title(TextResources.Invoices.ConfirmDocumentSendMailTitle);

        this.NoValidMailFound(this.props.NoValidMailFound);
        this.NoMailTemplateFound(this.props.NoMailTemplateFound);
        this.IsFirstMailSent(!this.props.LastMailSentStatus);

        this.MailCannotBeSent = ko.computed(() => {
            return this.NoValidMailFound() || this.NoMailTemplateFound();
        });
    }

    abort() {
        this.modal.close(SendMailAction.Abort);
    }

    close() {
        this.modal.close(SendMailAction.Refuse);
    }

    action() {
        this.modal.close(SendMailAction.Send);
    }

    show(): Promise<SendMailAction> {
        return this.dialogsService.ShowModal(this);
    }

    renderFooter() {
        return <Footer {...this.props} modal={this} />;
    }

    renderBody() {
        return <DocumentConfirmSendMailViewer {...this.props} modal={this} />;
    }
}

class Footer {
    constructor(private props: DocumentConfirmSendMailViewerProps & { modal: DocumentConfirmSendMailViewerDialog }) {}

    render() {
        const footer = this;

        return ComponentUtils.bindTo(
            <>
                <If condition={this.props.modal.MailCannotBeSent}>
                    {() => (
                        <>
                            <AbortButton modal={this.props.modal} actionSet={this.props.ActionSet} />
                            <If condition={ko.computed(() => this.props.ActionSet === ActionSet.SaveDocumentOption)}>
                                {() => <RefuseButton modal={this.props.modal} actionSet={this.props.ActionSet} />}
                            </If>
                        </>
                    )}
                </If>

                <IfNot condition={this.props.modal.MailCannotBeSent}>
                    {() => (
                        <>
                            <AbortButton modal={this.props.modal} actionSet={this.props.ActionSet} />
                            <ActionButton modal={this.props.modal} actionSet={this.props.ActionSet} />
                            <If condition={ko.computed(() => this.props.ActionSet === ActionSet.SaveDocumentOption)}>
                                {() => <RefuseButton modal={this.props.modal} actionSet={this.props.ActionSet} />}
                            </If>
                        </>
                    )}
                </IfNot>
            </>,
            this,
            "footer"
        );
    }
}

class AbortButton {
    private label: string;

    constructor(private props: { modal: DocumentConfirmSendMailViewerDialog; actionSet: ActionSet }) {
        this.label = TextResources.ProlifeSdk.Abort;
    }

    render() {
        const abortButton = this;

        return ComponentUtils.bindTo(
            <a
                href="#"
                className="btn btn-default"
                data-bind={{ click: abortButton.props.modal.abort.bind(abortButton.props.modal) }}
            >
                {this.label}
            </a>,
            this,
            "abortButton"
        );
    }
}

class ActionButton {
    private label: string;

    constructor(private props: { modal: DocumentConfirmSendMailViewerDialog; actionSet: ActionSet }) {
        this.label =
            this.props.actionSet === ActionSet.SaveDocumentOption
                ? TextResources.Invoices.SaveAndSendMail
                : TextResources.Invoices.SendMail;
    }

    render() {
        const actionButton = this;

        return ComponentUtils.bindTo(
            <a
                className="btn btn-success"
                href="#"
                data-bind={{ click: actionButton.props.modal.action.bind(actionButton.props.modal) }}
            >
                {this.label}
            </a>,
            this,
            "actionButton"
        );
    }
}

class RefuseButton {
    private label: string;

    constructor(private props: { modal: DocumentConfirmSendMailViewerDialog; actionSet: ActionSet }) {
        this.label =
            this.props.actionSet === ActionSet.SaveDocumentOption
                ? TextResources.Invoices.SaveAndNotSendMail
                : TextResources.Invoices.NotSendMail;
    }

    render() {
        const refuseButton = this;

        return ComponentUtils.bindTo(
            <a
                className="btn btn-warning"
                href="#"
                data-bind={{ click: refuseButton.props.modal.close.bind(refuseButton.props.modal) }}
            >
                {this.label}
            </a>,
            this,
            "refuseButton"
        );
    }
}

export class DocumentConfirmSendMailViewer {
    static defaultProps: Partial<DocumentConfirmSendMailViewerProps> = {};
    private readonly fileSizeFormat = "0.00 b";

    constructor(private props: DocumentConfirmSendMailViewerProps & { modal: DocumentConfirmSendMailViewerDialog }) {}

    render() {
        return ComponentUtils.bindTo(
            <Layout.Grid rows={["min-content", "1fr"]} columns={["1fr"]}>
                <Layout.Grid.Cell column={1} row={1} className="flex-vertical">
                    <If condition={this.props.modal.MailCannotBeSent}>
                        {() => (
                            <>
                                <h4>{TextResources.Invoices.ConfirmDocumentSendMailNegativeMessage}</h4>
                                <ul>
                                    {this.props.NoMailTemplateFound && (
                                        <li>{TextResources.Invoices.NoMailTemplateFoundMessage}</li>
                                    )}
                                    {this.props.NoValidMailFound && (
                                        <li>{TextResources.Invoices.NoValidMailFoundMessage}</li>
                                    )}
                                </ul>
                            </>
                        )}
                    </If>

                    <IfNot condition={this.props.modal.MailCannotBeSent}>
                        {() => (
                            <>
                                <h4>{TextResources.Invoices.ConfirmDocumentSendMailPositiveMessage}</h4>

                                <span>
                                    {TextResources.Invoices.LastMailSentDateMessage}
                                    {this.props.LastMailSentStatus?.sentAt && (
                                        <DateTimeText dateTime={this.props.LastMailSentStatus?.sentAt}></DateTimeText>
                                    )}
                                    {!this.props.LastMailSentStatus?.sentAt && (
                                        <>{TextResources.ProlifeSdk.NotAvailable}</>
                                    )}
                                </span>

                                {this.props.MetadataLogicalTypeWithNoData?.length > 0 && (
                                    <div>
                                        <h4>{TextResources.Invoices.MetadataLogicalTypeWithNoDataMessage}:</h4>
                                        <span>{this.props.MetadataLogicalTypeWithNoData}</span>
                                    </div>
                                )}

                                {this.props.TotalSizeExceeded && (
                                    <h4>
                                        {String.format(
                                            TextResources.Invoices.DocumentAttachmentsTotalSizeLimitMessage,
                                            this.props.FilesTotalMaxSize
                                                ? numeral(this.props.FilesTotalMaxSize).format(this.fileSizeFormat)
                                                : ""
                                        )}
                                    </h4>
                                )}

                                {!this.props.TotalSizeExceeded && this.props.AttachmentsTooLarge.length > 0 && (
                                    <>
                                        <h4>
                                            {String.format(
                                                TextResources.Invoices.DocumentAttachmentSizeLimitMessage,
                                                this.props.MaxFileSize
                                                    ? numeral(this.props.MaxFileSize).format(this.fileSizeFormat)
                                                    : ""
                                            )}
                                        </h4>
                                        <ul>
                                            {this.props.AttachmentsTooLarge.map((attachment) => (
                                                <li key={attachment.Guid}>
                                                    {String.format(
                                                        TextResources.Invoices.DocumentAttachmentTooLargeRow,
                                                        attachment.Name,
                                                        numeral(attachment.Size).format(this.fileSizeFormat)
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </>
                        )}
                    </IfNot>
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "exceptionViewer"
        );
    }
}
