import * as React from "@abstraqt-dev/jsxknockout";
import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/03/13
 * Time: 10.03
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { BasePagedList } from "../Utilities/BasePagedList";
import { StockArticle } from "./StockArticle";
import { Article } from "../Articles/Article";
import { CreateNewArticleDialog } from "../Articles/CreateNewArticleDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IWarehousesService,
    IWarehouse,
    IWarehouseWithStockInfo,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import {
    IStockService,
    ISearchStockResult,
    IStockArticle,
} from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { Select } from "../../../../Components/Select";
import { CustomersDataSource } from "../../../../DataSources/CustomersDataSource";
import { WarehousesDataSource } from "../../../../DataSources/WarehousesDataSource";
import { DateTimeInput } from "../../../../Components/DateTimeInput";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { ICustomerForList } from "../../../../ProlifeSdk/interfaces/customer/ICustomersService";
import { ICustomerGroup } from "../../../../Customers/CustomersGroupsService";
import jss from "jss";
import { Layout } from "../../../../Components/Layouts";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";
import { ArticleDetailsDialog } from "../Articles/ArticleDetailsDialog";

const styleSheet = jss.createStyleSheet({
    historicalStockManagement: {
        "& table.table > thead": {
            "& > tr": {
                position: "sticky",
                top: 0,

                "& > th": {
                    fontSize: "0.9em",
                },
            },
        },
    },
});
const { classes } = styleSheet.attach();

type HistoricalStockManagementProps = {
    selectDefaultWarehouse?: boolean;
    warehouseId?: number;
};

export function HistoricalStockManagement(props: HistoricalStockManagementProps) {
    const C = require("./HistoricalStockManagement")._HistoricalStockManagement as typeof _HistoricalStockManagement;
    return <C {...props} />;
}

export class _HistoricalStockManagement extends BasePagedList {
    static defaultProps = {
        selectDefaultWarehouse: true,
    };

    public title: string = ProlifeSdk.TextResources.Warehouse.StocksHistory;
    public CustomerId: ko.Observable<number> = ko.observable();
    public WarehouseId: ko.Observable<number> = ko.observable();

    public ShowOnlyInStock: ko.Observable<boolean> = ko.observable(true);

    @LazyImport(ProlifeSdk.StockServiceType)
    private stockService: IStockService;
    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;

    public SectionId = 7;

    public HistoricalDate: ko.Observable<Date> = ko.observable();
    private oldHistoricalDate: Date;
    public ShowSelectFilterMessage: ko.Computed<boolean>;

    private CustomersDataSource: CustomersDataSource = new CustomersDataSource();
    private WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();

    constructor(public props: HistoricalStockManagementProps) {
        super();

        if (props.selectDefaultWarehouse)
            this.warehousesService.getDefaultWarehouse().then((warehouse: IWarehouse) => {
                if (warehouse) this.WarehouseId(warehouse.Id);
            });

        if (props.warehouseId) this.WarehouseId(props.warehouseId);

        this.WarehouseId.subscribe(() => this.loadRows());
        this.ShowOnlyInStock.subscribe(() => this.loadRows());

        this.HistoricalDate(moment(new Date()).startOf("day").subtract("days", 1).toDate());
        this.oldHistoricalDate = this.HistoricalDate();

        this.HistoricalDate.subscribe((v) => {
            if (!(v instanceof Date)) {
                this.HistoricalDate(this.oldHistoricalDate);
                return;
            }

            this.oldHistoricalDate = v;
            this.loadRows();
        });

        this.ShowSelectFilterMessage = ko.computed(() => {
            return (this.searchFilter() || "").trim().length == 0 && !this.ShowOnlyInStock();
        });
    }

    public ShowArticleInfo(article: Article) {
        const dialog = new CreateNewArticleDialog(true);
        dialog.SetWarehouse(this.WarehouseId());
        dialog.loadAndShow(article.getData());
    }

    public ExportExcel() {
        const url =
            "Warehouse/HistoricalStockReport/GenerateExcel?date=" +
            encodeURIComponent(moment(this.HistoricalDate()).format()) +
            "&warehouseId=" +
            this.WarehouseId() +
            "&showOnlyStocked=" +
            this.ShowOnlyInStock() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public Print() {
        const url =
            "Warehouse/HistoricalStockReport/GeneratePdf?date=" +
            encodeURIComponent(moment(this.HistoricalDate()).format()) +
            "&warehouseId=" +
            this.WarehouseId() +
            "&showOnlyStocked=" +
            this.ShowOnlyInStock() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    onWarehouseSelected(w: IDataSourceModel<number, IWarehouseWithStockInfo, string | number, any>): void {
        if (this.CustomerId() !== w?.model.CustomerId) this.CustomerId(w.model.CustomerId);
    }

    public onCustomerSelected(c: IDataSourceModel<number, ICustomerForList | ICustomerGroup, string | number, any>) {
        this.WarehousesDataSource.setCustomerIds(c?.id);
        this.WarehouseId(undefined);
    }

    public loadRows() {
        this.Loading(true);
        this.rows([]);
        this.SomeFilterIsChanged(false);

        if (!this.WarehouseId()) this.setCount(0);

        if (!this.WarehouseId() || (!this.ShowOnlyInStock() && (this.searchFilter() || "").trim().length == 0)) {
            this.Loading(false);
            return;
        }

        this.stockService
            .getHistoricalStocks(
                this.HistoricalDate(),
                this.searchFilter(),
                this.selectedPage() * this.rowsPerPage,
                this.rowsPerPage,
                this.WarehouseId(),
                this.ShowOnlyInStock()
            )
            .then((result: ISearchStockResult) => {
                this.setCount(result.Count);
                this.pushRows(result.StockArticles);
                this.Loading(false);
            })
            .catch(() => {
                this.Loading(false);
            });
    }

    public async fetchRows(): Promise<IStockArticle[]> {
        //Effettuo la ricerca solo se ho selezionato un magazzino e nel caso di ricerca su tutti gli articoli (non solo sui movimentati)
        //devo aver applicato un filtro di ricerca testuale
        if (!this.WarehouseId() || (!this.ShowOnlyInStock() && (this.searchFilter() || "").trim().length == 0))
            return [];

        const result = await this.stockService.getHistoricalStocks(
            this.HistoricalDate(),
            this.searchFilter(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage,
            this.WarehouseId(),
            this.ShowOnlyInStock()
        );
        return result.StockArticles;
    }

    public async fetchRowsCount(): Promise<number> {
        if (!this.WarehouseId()) return 0;

        return await this.stockService.getHistoricalStocksCount(
            this.HistoricalDate(),
            this.searchFilter(),
            this.WarehouseId(),
            this.ShowOnlyInStock()
        );
    }

    public createViewModelFor(stockArticle: IStockArticle): StockArticle {
        return new StockArticle(stockArticle);
    }

    public ShowArticleDetails(article: Article) {
        const dialog: ArticleDetailsDialog = new ArticleDetailsDialog(
            article.RealArticleId(),
            article.Code(),
            article.Description()
        );
        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    render() {
        const hsm = this;
        let page: number;
        let row: StockArticle;
        return ComponentUtils.bindTo(
            <>
                <h3 className="page-title">{TextResources.Warehouse.StocksHistory}</h3>

                <div className="page-bar" style={{ padding: "5px" }}>
                    <form
                        style="margin-right: 10px"
                        className="caption search input-icon pull-left"
                        data-bind={{ submit: hsm.loadRows }}
                    >
                        <i className="fa fa-search"></i>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Cerca articoli..."
                            data-bind={{ value: hsm.searchFilter, valueUpdate: "afterkeydown" }}
                        />
                    </form>

                    <button
                        className="btn pull-left"
                        data-bind={{
                            click: hsm.loadRows,
                            css: { "btn-warning": hsm.SomeFilterIsChanged, default: !hsm.SomeFilterIsChanged() },
                        }}
                        style={{ marginRight: "10px" }}
                    >
                        <i className="fa fa-search"></i>&nbsp;Cerca
                    </button>

                    <Select
                        style={{ width: "170px", marginRight: "10px" }}
                        className="pull-left"
                        dataSource={this.CustomersDataSource}
                        value={this.CustomerId}
                        allowClear
                        placeholder="Magazzini Interni"
                        onSelect={(c) => this.onCustomerSelected(c)}
                    />
                    <Select
                        style={{ width: "170px", marginRight: "10px" }}
                        className="pull-left"
                        dataSource={this.WarehousesDataSource}
                        value={this.WarehouseId}
                        allowClear
                        placeholder="Seleziona un Magazzino..."
                        onSelect={(w) => this.onWarehouseSelected(w)}
                    />
                    <DateTimeInput
                        value={this.HistoricalDate}
                        simple
                        dateonly
                        className="pull-left input-small"
                        noIcon
                    />

                    <div className="btn-group pull-right">
                        <button
                            type="button"
                            className="btn grey-salt dropdown-toggle btn-fit-height"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                            data-close-others="true"
                            data-delay="1000"
                        >
                            Azioni&nbsp;
                            <i className="fa fa-angle-down"></i>
                        </button>
                        <ul className="dropdown-menu pull-right" role="menu">
                            <ko-bind data-bind={{ if: hsm.WarehouseId() > 0 }}>
                                <li>
                                    <a href="#" data-bind={{ click: hsm.Print }}>
                                        Stampa
                                    </a>
                                </li>
                                <ko-bind data-bind={{ if: hsm.ShowOnlyInStock }}>
                                    <li>
                                        <a href="#" data-bind={{ click: hsm.ExportExcel }}>
                                            Esporta Excel
                                        </a>
                                    </li>
                                </ko-bind>
                            </ko-bind>
                        </ul>
                    </div>

                    <div className="pull-right" style="margin-right: 20px;margin-top: 7px;">
                        <div className="checker">
                            <span data-bind={{ css: { checked: hsm.ShowOnlyInStock } }}>
                                <input type="checkbox" data-bind={{ checked: hsm.ShowOnlyInStock }} />
                            </span>
                        </div>
                        Solo Movimentati
                    </div>
                </div>

                <Layout.ScrollContainer
                    systemScrollable
                    verticalOnly
                    className={classes.historicalStockManagement}
                    style={{
                        position: "absolute",
                        top: "120px",
                        bottom: "60px",
                        left: "20px",
                        right: "20px",
                        overflowY: "auto",
                    }}
                >
                    <table class="table table-advance">
                        <thead>
                            <tr>
                                <th>Codice</th>
                                <th>Descrizione</th>
                                <th>Tipologia</th>
                                <th class="text-right">Quantit&agrave;</th>
                                <th>UdM</th>
                                <th class="text-right">Min.Qt.</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ko-bind data-bind={{ if: hsm.ShowSelectFilterMessage }}>
                                <tr>
                                    <td colSpan={7} class="text-left">
                                        <h3>
                                            <i
                                                class="glyphicon glyphicon-arrow-up"
                                                style="font-size: 36px;margin-right: 25px;margin-left: 20px;"
                                            ></i>
                                            Per cercare gli articoli applicare un filtro...
                                        </h3>
                                    </td>
                                </tr>
                            </ko-bind>
                            <ko-bind data-bind={{ if: hsm.Loading }}>
                                <tr>
                                    <td colSpan={7} class="text-center">
                                        <h3>Caricamento articoli in corso...</h3>
                                    </td>
                                </tr>
                            </ko-bind>
                            <ko-bind data-bind={{ foreach: { data: hsm.rows, as: "row" } }}>
                                <tr>
                                    <td data-bind={{ text: row.Code }}></td>
                                    <td data-bind={{ text: row.Description }}></td>
                                    <td data-bind={{ text: row.PurchaseType }}></td>
                                    <td class="text-right" data-bind={{ numberText: row.StockAmount }}></td>
                                    <td data-bind={{ text: row.UOM }}></td>
                                    <td class="text-right" data-bind={{ numberText: row.MinimumAmount }}></td>
                                    <td style={{ width: "30px", textAlign: "right" }}>
                                        <a
                                            href="#"
                                            class="btn btn-xs default"
                                            data-bind={{ click: hsm.ShowArticleDetails.bind(hsm) }}
                                        >
                                            <i class="fa fa-eye"></i>&nbsp;Movimenti
                                        </a>
                                    </td>
                                </tr>
                            </ko-bind>
                        </tbody>
                    </table>
                </Layout.ScrollContainer>

                <div style={{ position: "absolute", bottom: 0, left: "20px" }}>
                    <ul className="pagination">
                        <li data-bind={{ css: { disabled: hsm.isOnFirstPage() || hsm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: hsm.goToPrevPage }}>
                                &larr; Prec
                            </a>
                        </li>

                        <ko-bind data-bind={{ foreach: { data: hsm.pages, as: "page" } }}>
                            <li
                                data-bind={{
                                    css: { disabled: hsm.SomeFilterIsChanged(), active: hsm.selectedPage() == page },
                                }}
                            >
                                <a href="#" data-bind={{ text: page + 1, click: hsm.goToPage.bind(hsm, page) }}></a>
                            </li>
                        </ko-bind>

                        <li data-bind={{ css: { disabled: hsm.isOnLastPage() || hsm.SomeFilterIsChanged() } }}>
                            <a href="#" data-bind={{ click: hsm.goToNextPage }}>
                                Succ &rarr;
                            </a>
                        </li>
                    </ul>
                </div>
            </>,
            this,
            "hsm"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(HistoricalStockManagement);
}
