import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { BlogEvent } from "../../../../ProlifeSdk/prolifesdk/blog/BlogEvent";
import { IContextEventsObserver } from "../../../../ProlifeSdk/interfaces/blog/IContextEventsObserver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { Deferred } from "../../../../Core/Deferred";

export class GitCommitEvent extends BlogEvent implements IView
{
    constructor (public serviceLocator: IServiceLocator, public contextEventsObserver : IContextEventsObserver)
    {
        super(serviceLocator, contextEventsObserver);
        this.Updatable(false);

        this.iconClass = "fa-upload";
        this.eventTypeName = ProlifeSdk.TextResources.JobOrder.GitCommit;
        this.templateName = "git-commit-event";
        this.templateUrl = "jobOrder/templates/events";
        this.isDeleteLocked(true);
    }

    public GetMailBody() : Promise<string>
    {
        var def = new Deferred<string>();
        super.GetMailBody()
            .then((b : string) => {
                var body = b;
                def.resolve(body);
            })
            .catch(() => def.reject());
        
            return def.promise();
    }

    public GetMailSubject() : string
    {
        return ProlifeSdk.TextResources.JobOrder.GitCommitMailSubject;
    }
}