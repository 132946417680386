import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../Core/DependencyInjection";
import { IDataSourceModel } from "./IDataSource";
import { ICartsService } from "../ProlifeSdk/interfaces/allocations/ICartsService";
import { ICartWithContent } from "../ProlifeSdk/interfaces/allocations/ICart";
import { BaseDataSource } from "./BaseDataSource";

export type ICartsDataSourceModel = IDataSourceModel<number, ICartWithContent>

export class CartsDataSource extends BaseDataSource<ICartsDataSourceModel> {
    private showClosedCarts = false;
    private showAllocatedCarts = false;

    private cartsToFilter: number[] = [];

    @LazyImport(nameof<ICartsService>())
    private cartsService!: ICartsService;

    constructor() {
        super();
    }
    
    public getTitle(currentModel: IDataSourceModel): string {
        return ProlifeSdk.TextResources.Allocations.CartsListTitle;
    }
    
    public async getData(currentModel: IDataSourceModel, textFilter: string, skip: number, count: number): Promise<ICartsDataSourceModel[]> {
        const carts: ICartWithContent[] = await this.cartsService.getCartsList(this.showClosedCarts, this.showAllocatedCarts, false, null, textFilter);
        return carts.filter(c => this.cartsToFilter.indexOf(c.Cart.Id) < 0).map((c) => this.createModel(c));
    }
    
    public async getById(currentModel: IDataSourceModel, ids: number[]): Promise<ICartsDataSourceModel[]> {
        const carts: ICartWithContent[] = await this.cartsService.getCartsList(this.showClosedCarts, this.showAllocatedCarts, false, null, "", ids);
        return carts.map((c) => this.createModel(c));
    }

    public setShowClosedCarts(value: boolean): void {
        this.showClosedCarts = value;
    }

    public setShowAllocatedCarts(value: boolean): void {
        this.showAllocatedCarts = value;
    }

    private createModel(cart: ICartWithContent): ICartsDataSourceModel {
        return {
            id: cart.Cart.Id,
            isGroup: false,
            isLeaf: true,
            title: cart.Cart.Title,
            icon: {
                icon: "square",
                background: cart.Cart.Color,
                foreground: "#000"
            },
            model: cart
        };
    }

    addCartToFilter(cartId: number) {
        if (this.cartsToFilter.indexOf(cartId) < 0)
            this.cartsToFilter.push(cartId);
    }

    removeCartToFilter(cartId: number) {
        if (this.cartsToFilter.indexOf(cartId) >= 0)
            this.cartsToFilter.remove(cartId);
    }
}