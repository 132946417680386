import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 12:34
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import { ProLifeService } from "../ProlifeSdk/prolifesdk/ProLifeService";
import { ServiceTypes } from "../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../Core/interfaces/IAjaxService";
import { IService } from "../Core/interfaces/IService";
import { IFestivitiesAgendasService } from "../ProlifeSdk/interfaces/agenda/IFestivitiesAgendasService";
import { IAgenda } from "../ProlifeSdk/interfaces/agenda/IAgendaService";

export class FestivitiesAgendasService extends ProLifeService  implements IFestivitiesAgendasService {
    private ajaxService: IAjaxService;

    constructor(private serviceLocator: IServiceLocator) {
        super(ProlifeSdk.FestivitiesAgendaServiceCode);
        this.serviceLocator.registerServiceInstance(this);

        this.ajaxService = <IAjaxService> this.serviceLocator.findService(ServiceTypes.Ajax);
    }

    getServiceType(): string {
        return ProlifeSdk.FestivitiesAgendaServiceCode;
    }

    isOfType(serviceType: string): boolean {
        return serviceType == this.getServiceType();
    }

    GetAgendas(): Promise<IAgenda[]> {
        return this.ajaxService.Get("Agenda-api", "FestivitiesAgendas", {});
    }
}

export default function Create(serviceLocator : IServiceLocator) : IService {
    return new FestivitiesAgendasService(serviceLocator);
}