import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { IFullMetadata } from "../../../MetadataService";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { IArrayDataSourceModel } from "../../../../DataSources/ArrayDataSource";
import { TextInput } from "../../../../Components/TextInput";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { DropdownList } from "../../../../Components/DropdownList";
import { LazyImportSettingManager, LazyImport } from "../../../../Core/DependencyInjection";
import { IMetadataSettingsManager, MetadataTypeDescriptor } from "../MetadataSettingsManager";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { MetadataType } from "../../enums/MetadataType";
import { CheckBox } from "../../../../Components/Checkbox";
import { If } from "../../../../Components/IfIfNotWith";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { MetadataListValuesEditor } from "./MetadataListValuesEditor";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { Delay } from "../../../../Decorators/Delay";
import { TextFiltersUtilities } from "../../../../Core/utils/TextFiltersUtilities";

const styleSheet = jss.createStyleSheet({
    metadatasEditor: {
        "& .metadatas-table": {
            "& td.metadata-table-column": {
                verticalAlign: "top",
            },
        },
    },
    listValuesEditor: {
        width: "450px",
        maxWidth: "450px",

        "& th": {
            "&.actions": {
                "& button": {
                    marginBottom: "3px",
                },
            },
        },

        "& .actions": {
            width: "65px",
        },

        "& table": {
            "& tbody": {
                "& td": {
                    "& input": {
                        "&.form-control": {
                            margin: "0px !important",
                            width: "98%",
                        },
                    },
                },
            },
        },
    },
});

const { classes } = styleSheet.attach();

type MetadataEditorProps = {
    metadata: IFullMetadata[];
};

type Metadata = {
    Id: number;
    Label: ko.Observable<string>;
    ValueType: ko.Observable<MetadataType>;
    ListValues: ko.ObservableArray<MetadataListValue>;
    ShowMarkerOnAllocationsGantt: ko.Observable<boolean>;

    ShowMarkerOnAllocationsGanttFlag: ko.Observable<boolean>;

    getData: () => IFullMetadata;
};

export type MetadataListValue = {
    Id: number;
    Label: ko.Observable<string>;
    FKMetadata: number;
    Order: ko.Observable<number>;
    Deleted: ko.Observable<boolean>;
};

@DetectClassChanges
export class MetadataEditor {
    static defaultProps: Partial<MetadataEditorProps> = {
        metadata: [],
    };

    isChanged: ko.Observable<number> = ko.observable();

    @DetectChanges
    private Metadatas: ko.ObservableArray<Metadata> = ko.observableArray([]);
    private FilteredMetadatas: ko.ObservableArray<Metadata> = ko.observableArray([]);
    private MetadataTypes: MetadataTypeDescriptor[] = [];

    private MetadatasFilter: ko.Observable<string> = ko.observable();
    private MetadataTypeFilter: MetadataType[] = [];

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImportSettingManager(nameof<IMetadataSettingsManager>())
    private metadataSettingsManager: IMetadataSettingsManager;

    private subscriptions: ko.Subscription[] = [];
    private nextFakeId = -1;

    @DetectChanges
    private deletedMetadatas: ko.ObservableArray<IFullMetadata> = ko.observableArray([]);

    constructor(private props: MetadataEditorProps) {
        this.populateTypesList();
        this.populateMetadatas(this.props.metadata);

        this.isChanged(0);
    }

    private populateMetadatas(metadatas: IFullMetadata[]) {
        this.Metadatas(metadatas.map((m) => this.createMetadata(m)));
        this.applyFilters();
    }

    public componentDidMount() {
        this.subscriptions.push(this.MetadatasFilter.subscribe(() => this.applyFilters()));
    }

    public componentWillUnmount() {
        for (const sub of this.subscriptions) sub.dispose();

        this.subscriptions = [];
        this.dispose();
    }

    @Delay(100)
    private applyFilters(): void {
        const metadata = this.Metadatas();
        const textFilter = this.MetadatasFilter();
        const selectedTypes = this.MetadataTypeFilter.slice();

        const filteredMetadata = metadata.filter((m) => {
            return TextFiltersUtilities.contains(m.Label(), textFilter) && selectedTypes.indexOf(m.ValueType()) >= 0;
        });

        this.FilteredMetadatas(filteredMetadata);
    }

    private onMetadataTypeFilterChanges(selectedTypes: MetadataType[]) {
        this.MetadataTypeFilter = selectedTypes;
        this.applyFilters();
    }

    public async createOrUpdateMetadatas(): Promise<void> {
        let metadatas = this.Metadatas().map((m) => m.getData());
        metadatas = metadatas.concat(this.deletedMetadatas());

        try {
            const updatedMetadatasList = await this.metadataSettingsManager.createOrUpdateMetadatas(metadatas);
            this.populateMetadatas(updatedMetadatasList);

            this.infoToastService.Success(TextResources.Invoices.SaveMetadatasSuccess);
            this.isChanged(0);
        } catch (e) {
            console.log(e);
        }
    }

    public addMetadata(): void {
        const newMetadata: IFullMetadata = this.createEmptyMetadata();
        const model = this.createMetadata(newMetadata);
        this.Metadatas.push(model);
    }

    public async deleteMetadata(metadata: IArrayDataSourceModel<Metadata>): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Invoices.ConfirmMetadataDelete,
            TextResources.ProlifeSdk.No,
            TextResources.ProlifeSdk.Yes
        );
        if (!confirm) return;

        this.Metadatas.remove(metadata.model);

        const deletedData = metadata.model.getData();
        deletedData.Deleted = true;

        this.deletedMetadatas.push(deletedData);
    }

    public dispose() {
        for (const sub of this.subscriptions) sub.dispose();
    }

    public render() {
        const metadatasEditor = this;
        let metadata: IDataSourceModel<number, Metadata>;

        const { sortString } = ComponentUtils.useSorter<Metadata>();
        const metadataTypeLabelGetter = (type: MetadataType) =>
            this.MetadataTypes.firstOrDefault((mt) => mt.ValueType === type)?.TypeLabel ?? "";

        return ComponentUtils.bindTo(
            <Layout.WithHeader className={classes.metadatasEditor}>
                <Layout.WithHeader.Header style={{ paddingTop: "30px" }}>
                    <h3 class="page-title">
                        <span>{TextResources.Invoices.MetadatasSettingsManagerName}</span>
                        <small
                            class="alert alert-danger"
                            style={{
                                position: "absolute",
                                left: "60%",
                                marginLeft: "-25%",
                                textAlign: "center",
                                top: "0px",
                                width: "500px",
                                color: "#a94442",
                            }}
                        >
                            {TextResources.ProlifeSdk.SettingManagerReloadAlert}
                        </small>
                    </h3>
                    <div className="flex-fill text-right" style={{ alignSelf: "flex-end", marginRight: "10px" }}>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bind={{ asyncClick: metadatasEditor.createOrUpdateMetadatas.bind(metadatasEditor) }}
                        >
                            <i className="fa fa-save"></i>&nbsp;{TextResources.Invoices.SaveMetadatas}
                        </button>
                    </div>
                </Layout.WithHeader.Header>
                <Layout.WithHeader.Content noOverflow={true}>
                    <Table
                        dataSource={{ array: this.FilteredMetadatas, factory: this.createModel.bind(this) }}
                        rowAs="metadata"
                        className="metadatas-table"
                        scrollable={true}
                    >
                        <Column
                            title={TextResources.Invoices.MetadataLabelColumn}
                            cssClasses="metadata-table-column"
                            sorter={sortString((m) => m.Label())}
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        {TextResources.Invoices.MetadataLabelColumn}
                                        <TableFilter customFilterStateHandler={() => !!this.MetadatasFilter()}>
                                            {() => (
                                                <TextInput
                                                    value={this.MetadatasFilter}
                                                    placeholder={TextResources.Invoices.MetadataPlaceholder}
                                                />
                                            )}
                                        </TableFilter>
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(row: ITableItem<Metadata>) => (
                                    <TextInput value={row.Data.model.Label} simple={true} />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Invoices.MetadataValueTypeColumn}
                            cssClasses="metadata-table-column"
                            style={{ width: "200px" }}
                            sorter={sortString((m) => metadataTypeLabelGetter(m.ValueType()))}
                        >
                            <ColumnHeader>
                                {() => (
                                    <>
                                        {TextResources.Invoices.MetadataValueTypeColumn}
                                        <TableFilter
                                            filterSource={this.Metadatas}
                                            itemLabelGetter={(m: Metadata) => metadataTypeLabelGetter(m.ValueType())}
                                            itemKeyGetter={(m: Metadata) => m.ValueType()}
                                            onSelectionChange={this.onMetadataTypeFilterChanges.bind(this)}
                                        />
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(row: ITableItem<Metadata>) => (
                                    <DropdownList
                                        simple={true}
                                        value={row.Data.model.ValueType}
                                        values={this.MetadataTypes}
                                        idGetter={(v) => v.ValueType}
                                        valueGetter={(v) => v.ValueType}
                                        textGetter={(v) => v.TypeLabel}
                                    />
                                )}
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Invoices.MetadataConfigurationColumn} style={{ width: "350px" }}>
                            <ColumnBody>
                                {(row: ITableItem<Metadata>) => (
                                    <>
                                        <If condition={row.Data.model.ShowMarkerOnAllocationsGanttFlag}>
                                            {() => (
                                                <div>
                                                    <CheckBox
                                                        label={TextResources.Invoices.ShowMarkerOnAllocationsGantt}
                                                        checked={row.Data.model.ShowMarkerOnAllocationsGantt}
                                                    />
                                                </div>
                                            )}
                                        </If>
                                        <If condition={() => row.Data.model.ValueType() === MetadataType.List}>
                                            {() => (
                                                <button
                                                    type="button"
                                                    className="btn btn-sm"
                                                    data-bind={{
                                                        asyncClick:
                                                            metadatasEditor.configureListValuesOnMetadata.bind(
                                                                metadatasEditor
                                                            ),
                                                        css: {
                                                            "btn-danger": metadata.model.ListValues().length === 0,
                                                            "btn-success": metadata.model.ListValues().length > 0,
                                                        },
                                                        attr: {
                                                            title:
                                                                metadata.model.ListValues().length === 0
                                                                    ? "Nessun valore configurato"
                                                                    : "Valori configurati correttamente",
                                                        },
                                                    }}
                                                >
                                                    <i className="fa fa-magic"></i>{" "}
                                                    {TextResources.Invoices.ConfigureMetadataListValues}
                                                </button>
                                            )}
                                        </If>
                                    </>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column style={{ width: "50px" }} cssClasses="metadata-table-column" className="text-right">
                            <ColumnHeader>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    data-bind={{ click: metadatasEditor.addMetadata.bind(metadatasEditor) }}
                                >
                                    <i className="fa fa-plus"></i>
                                </button>
                            </ColumnHeader>
                            <ColumnBody>
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    data-bind={{
                                        asyncClick: metadatasEditor.deleteMetadata.bind(metadatasEditor, metadata),
                                    }}
                                >
                                    <i className="fa fa-trash-o"></i>
                                </button>
                            </ColumnBody>
                        </Column>
                    </Table>
                </Layout.WithHeader.Content>
            </Layout.WithHeader>,
            this,
            "metadatasEditor"
        );
    }

    private async configureListValuesOnMetadata(target: IDataSourceModel<Metadata>, evt: Event): Promise<void> {
        const listValuesEditor = new MetadataListValuesEditor({
            listValues: target.model.ListValues,
            metadataId: target.model.Id,
        });
        return this.dialogsService.ShowPopoverComponent(
            evt.currentTarget as HTMLElement,
            listValuesEditor,
            "left",
            null,
            classes.listValuesEditor
        );
    }

    private populateTypesList() {
        const metadataTypes = this.metadataSettingsManager.getMetadataTypeDescriptors();
        this.MetadataTypes = metadataTypes;
    }

    private createEmptyMetadata(): IFullMetadata {
        return {
            Id: this.nextFakeId--,
            Label: TextResources.Invoices.NewMetadataDefaultLabel,
            ValueType: MetadataType.Text,
            Deleted: false,
            ShowMarkerOnAllocationsGantt: false,
            ListValues: [],
        };
    }

    private onMetadataChanges(metadata: Metadata) {
        this.isChanged(this.isChanged() + 1);
    }

    private createModel(metadata: Metadata) {
        this.subscriptions.push(metadata.Label.subscribe((l) => this.onMetadataChanges(metadata)));
        this.subscriptions.push(
            metadata.ValueType.subscribe((l) => {
                metadata.ShowMarkerOnAllocationsGanttFlag(metadata.ValueType() === MetadataType.DateTime);
                this.onMetadataChanges(metadata);
            })
        );
        this.subscriptions.push(
            metadata.ShowMarkerOnAllocationsGantt.subscribe((l) => this.onMetadataChanges(metadata))
        );

        return {
            id: metadata.Id,
            title: metadata.Label(),
            isGroup: false,
            isLeaf: true,
            model: metadata,
        };
    }

    private createMetadata(metadata: IFullMetadata) {
        return {
            Id: metadata.Id,
            Label: ko.observable(metadata.Label),
            ValueType: ko.observable(metadata.ValueType as MetadataType),
            ListValues: ko.observableArray(
                metadata.ListValues.map((lv) => ({
                    Id: lv.Id,
                    Label: ko.observable(lv.Label),
                    FKMetadata: lv.FKMetadata,
                    Order: ko.observable(lv.Order),
                    Deleted: ko.observable(lv.Deleted),
                }))
            ),
            ShowMarkerOnAllocationsGantt: ko.observable(metadata.ShowMarkerOnAllocationsGantt),
            ShowMarkerOnAllocationsGanttFlag: ko.observable(metadata.ValueType === MetadataType.DateTime),
            getData: function (this: Metadata) {
                return {
                    Id: this.Id,
                    Label: this.Label(),
                    ValueType: this.ValueType(),
                    Deleted: false,
                    ShowMarkerOnAllocationsGantt: this.ShowMarkerOnAllocationsGantt(),
                    ListValues: this.ListValues().map((lv) => ({
                        Id: lv.Id,
                        Label: lv.Label(),
                        FKMetadata: this.Id,
                        Order: lv.Order(),
                        Deleted: lv.Deleted(),
                    })),
                };
            },
        };
    }
}

export class MetadataEditorUI implements IView {
    templateName: string;
    templateUrl: string;
    viewModel: any;

    constructor(private props: MetadataEditorProps) {}

    render() {
        const Editor = require("./MetadataEditor").MetadataEditor as typeof MetadataEditor;
        return <Editor {...this.props} ref={(e) => (this.viewModel = e)} />;
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(MetadataEditor);
}
