import * as ko from "knockout";
import { NavigationMenu } from "../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { INavigationMenuProvider } from "../../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class ActivitiesNavigatorBase extends NavigationMenu
{
    constructor(serviceLocator : IServiceLocator, rootProvider: INavigationMenuProvider)
    {
        super(serviceLocator, rootProvider);
        this.templateUrl = "todolist/templates/navigation/workflows-navigator";
        this.templateName = "workflows-navigator";
        this.setMultipleSelectionStatus(true);
    }

    InitializeBase() : Promise<void>
    {
        this.clearSelection(false);
        this.SearchFilter("");
        this.MenuProvidersStack([this.RootMenuProvider]);
        return this.RootMenuProvider.refreshItems();
    }

    GetSelectedTasksIds() : number[]
    {
        return (<any>this.RootMenuProvider).GetSelectedTasksIds();
    }

    SetSelectedTasks(ids : number[]) : Promise<void>
    {
        this.clearSelection(false);
        return (<any>this.RootMenuProvider).SetSelectedTasks(ids);
    }

    public Dispose()
    {
        (<any>this.RootMenuProvider).Dispose();
    }
}