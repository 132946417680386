import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 14/09/2017
 * Time: 11:43
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { QuestionnaireStateEditingViewModel } from "./ui/QuestionnaireStateEditingViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../../../Core/interfaces/IAjaxService";
import { IModulesService } from "../../../../../ProlifeSdk/interfaces/desktop/IModulesService";
import { ISettingsService } from "../../../../../ProlifeSdk/interfaces/settings/ISettingsService";
import { IQuestionnaireStateSettingManager } from "../../../../interfaces/IQuestionnaireStateSettingManager";
import { IQuestionnaireState, IQuestionnaireLogicalState } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionnaire";
import { IView } from "../../../../../ProlifeSdk/interfaces/IView";

export class QuestionnaireStateSettingManager implements IQuestionnaireStateSettingManager {
    private ajaxService: IAjaxService;
    private modulesService : IModulesService;

    private questionnaireStates: IQuestionnaireState[] = [];
    private questionnaireLogicalStates: IQuestionnaireLogicalState[] = [];

    constructor(private serviceLocator: IServiceLocator) {
        var settingsService: ISettingsService = <ISettingsService> this.serviceLocator.findService(ProlifeSdk.SettingsServiceType);
        settingsService.registerSettingsManager(this, ProlifeSdk.TextResources.Survey.QuestionnairesEditor);
        this.modulesService = <IModulesService>serviceLocator.findService(ProlifeSdk.ModulesServiceType);
        this.ajaxService = <IAjaxService>this.serviceLocator.findService(ServiceTypes.Ajax);

        this.questionnaireLogicalStates.push(<IQuestionnaireLogicalState>{Id: 1, Description: ProlifeSdk.TextResources.Survey.DraftState, Locked: true});
        this.questionnaireLogicalStates.push(<IQuestionnaireLogicalState>{Id: 2, Description: ProlifeSdk.TextResources.Survey.EnableState, Locked: false});
        this.questionnaireLogicalStates.push(<IQuestionnaireLogicalState>{Id: 3, Description: ProlifeSdk.TextResources.Survey.DisabledState, Locked: true});
    }

    load(): Promise<IQuestionnaireState[]> {
        return this.ajaxService.Post<IQuestionnaireState[]>("Survey-api/QuestionnaireApi", "GetQuestionnaireStates", {})
            .then((states: IQuestionnaireState[]) => {
                this.questionnaireStates = states;
                return states;
            });
    }

    getName(): string {
        return ProlifeSdk.QuestionnaireState;
    }

    getLabel(): string {
        return ProlifeSdk.TextResources.Survey.QuestionnaireStatus;
    }

    hasEditingUI(): boolean {
        return this.modulesService.IsModuleEnabled(ProlifeSdk.SurveyApplicationCode) || false;
    }

    getEditingUI(): IView {
        return {
            templateName: "questionnaire-state",
            templateUrl: "survey/templates/questionnaire/settings",
            viewModel: new QuestionnaireStateEditingViewModel(this.serviceLocator, this)
        };
    }

    getQuestionnaireLogicalState(): IQuestionnaireLogicalState[] {
        return this.questionnaireLogicalStates;
    }

    getQuestionnaireStates(): IQuestionnaireState[] {
        return this.questionnaireStates;
    }

    getQuestionnaireStateById(id: number): IQuestionnaireState {
        var states: IQuestionnaireState[] = this.questionnaireStates.filter((s: IQuestionnaireState) => { return s.Id == id });
        return states[0];
    }

    addOrUpdate(state: IQuestionnaireState): Promise<IQuestionnaireState> {
        return this.ajaxService.Post<IQuestionnaireState>("Survey-api/QuestionnaireApi", "AddOrUpdateQuestionnaireState", {
            methodData: {
                State: state
            }
        }).then(this.updateState.bind(this));
    }

    remove(id: number): Promise<number> {
        return this.ajaxService.Post<number>("Survey-api/QuestionnaireApi", "RemoveQuestionnaireState", {
            methodData: {
                Id: id
            }
        }).then((i) => { this.removeState(id); return i; });
    }

    private updateState(state: IQuestionnaireState): void {
        var found: boolean = false;

        this.questionnaireStates.forEach((s: IQuestionnaireState) => {
            if (s.Id != state.Id)
                return;

            s.Id = state.Id;
            s.Description = state.Description;
            s.LogicalStateId = state.LogicalStateId;
            s.Locked = state.Locked;

            found = true;
        });

        if (!found)
            this.questionnaireStates.push(state);
    }

    private removeState(id: number): void {
        for (var i = 0; i <this.questionnaireStates.length; i++) {
            if (this.questionnaireStates[i].Id == id) {
                this.questionnaireStates.splice(i, 1);
                break;
            }
        }
    }
}