import * as ko from "knockout";
/**
 * Created by d.bucchi on 09/06/2017.
 */

import * as ProlifeSdk from "../ProlifeSdk";
import { IPageSwitcherObserver } from "../interfaces/prolife-sdk/IPageSwitcherObserver";

interface IPage {
    PageVisible : ko.Observable<boolean>;
    PageTitle : string;
    PageHeader: boolean;
    PageSwitchDirection: ko.Observable<string>;
    PageTopButton: boolean;
    DontRememberMe: boolean;
}

interface IPagesMap {
    [PageName: string]: IPage;
}

export class PageSwitcher
{
    private pages: IPagesMap = {};

    public actualPageName: ko.Observable<string> = ko.observable("");
    private pageHistory: ko.ObservableArray<string> = ko.observableArray();

    public actualPageTitle: ko.Observable<string> = ko.observable("");

    private observers: IPageSwitcherObserver[] = [];

    public canGoBack: ko.Computed<boolean>;
    public canLoadHeader: ko.Computed<boolean>;
    public actualPage : ko.Computed<IPage>;

    constructor(){
        this.canGoBack = ko.computed(() => {
            return this.pageHistory().length > 0;
        }, this);

        this.canLoadHeader = ko.computed(() => {
            if (this.actualPageName() != "") {
                return this.pages[this.actualPageName()].PageHeader;
            }
            return false;
        }, this);

        this.actualPage = ko.computed(() => {
            return this.pages[this.actualPageName()];
        });
    }

    public goToPage(pageName: string) {
        var oldPageName = this.actualPageName();

        if (oldPageName == pageName)
            return;

        if (this.actualPageName() != null) {
            this.pages[this.actualPageName()].PageSwitchDirection("next");
            this.pages[this.actualPageName()].PageVisible(false);
        }

        if (!this.pages[this.actualPageName()].DontRememberMe)
            this.pageHistory.push(this.actualPageName());
        
        this.pages[pageName].PageSwitchDirection("next");
        this.pages[pageName].PageVisible(true);
        this.actualPageName(pageName);
        this.actualPageTitle(this.pages[pageName].PageTitle);


        this.observers.forEach((o: IPageSwitcherObserver) => {
            o.onPageChanged(this.actualPageName(), oldPageName);
        });
    }

    goToCalendar() {
        var oldPageName = this.actualPageName();
        var pageName = ProlifeSdk.Page_Calendar;
        if (this.actualPageName() != null) {
            this.pages[this.actualPageName()].PageSwitchDirection("back");
            this.pages[this.actualPageName()].PageVisible(false);

        }

        this.actualPageName(pageName);
        this.pages[pageName].PageSwitchDirection("back");
        this.pages[pageName].PageVisible(true);
        this.actualPageTitle(this.pages[pageName].PageTitle);


        this.observers.forEach((o: IPageSwitcherObserver) => {
            o.onPageChanged(this.actualPageName(), oldPageName);
        });
        this.observers.forEach((o: IPageSwitcherObserver) => {
            o.onGoingBack(this.actualPageName());
        });
    }

    public goBack() {
        if (this.pageHistory().length == 0)
            return;
        var oldPageName = this.actualPageName();

        this.pages[this.actualPageName()].PageSwitchDirection("back");
        this.pages[this.actualPageName()].PageVisible(false);

        this.pages[this.pageHistory()[this.pageHistory().length - 1]].PageSwitchDirection("back");
        this.pages[this.pageHistory()[this.pageHistory().length - 1]].PageVisible(true);

        this.actualPageName(this.pageHistory()[this.pageHistory().length - 1]);
        this.actualPageTitle(this.pages[this.pageHistory()[this.pageHistory().length - 1]].PageTitle);
        this.pageHistory.splice(this.pageHistory().length - 1, 1);

        this.observers.forEach((o: IPageSwitcherObserver) => {
            o.onPageChanged(this.actualPageName(), oldPageName);
        });
        this.observers.forEach((o: IPageSwitcherObserver) => {
            o.onGoingBack(this.actualPageName());
        });
    }

    public newPageBack(): string {
        return this.pageHistory()[this.pageHistory().length - 1];
    }

    public isVisible(pageName: string): boolean {
        return this.pages[pageName].PageVisible();
    }

    public observeChanges(pageName: string, callback: (newValue: boolean) => void): void {
        this.pages[pageName].PageVisible.subscribe(callback);
    }

    public addPage(pageName: string, visible: boolean, pageTitle: string = "", hasHeader: boolean = true, direction: string = "next", enableRightTopButton: boolean = true, dontRememberMe: boolean = false, isRootPage: boolean = false) {
        this.pages[pageName] = {
            PageVisible : ko.observable(visible),
            PageTitle : pageTitle,
            PageHeader: hasHeader,
            PageSwitchDirection: ko.observable(direction),
            PageTopButton: enableRightTopButton,
            DontRememberMe: dontRememberMe
        };

        if (visible) {
            this.actualPageName(pageName);
            this.actualPageTitle(pageTitle);

            if (!dontRememberMe && !isRootPage)
                this.pageHistory.push(this.actualPageName());
        }
    }

    public setPageTitle(pageName: string, title: string) {
        this.pages[pageName].PageTitle = title;
    }

    public registerObserver(observer: IPageSwitcherObserver) {
        this.observers.push(observer);
    }

    public toNextPage(pageName: string): boolean {
        return this.pages[pageName].PageSwitchDirection() == "next";
    }

}
