import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/05/2017
 * Time: 17:24
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import { ITeamViewModel, ITeamsManagerProvider } from "./TeamsManager";

export class TeamSelector {
    CanGoBack : ko.Observable<boolean> = ko.observable(false);
    IsSearching : ko.Observable<boolean> = ko.observable(false);
    Title : ko.Observable<string> = ko.observable(ProlifeSdk.TextResources.Allocations.Teams);
    SearchFilter : ko.Observable<string> = ko.observable().extend({ rateLimit: 500 });

    ShowHiddenTeams: ko.Observable<boolean> = ko.observable(false);

    AllItems : ko.ObservableArray<ITeamViewModel> = ko.observableArray([]);
    Items : ko.Computed<ITeamViewModel[]>;

    constructor(private teamsManagerProvider : ITeamsManagerProvider) {
        this.Items = ko.computed(() => {
            let teams = this.ShowHiddenTeams() ? this.AllItems() : this.AllItems().filter(t => !t.Hide());
            return teams.sort((t1, t2) => t1.Position() - t2.Position())
        });

        teamsManagerProvider.OnTeamsLoaded.Add(this.Load.bind(this));

        var lastSelectedTeamIndex = -1;
        teamsManagerProvider.OnBeforeTeamsManagerChanged.Add(() => {
            if(!this.teamsManagerProvider.HasTeamsManager())
                return;

            lastSelectedTeamIndex = -1;
            var selectedTeams = teamsManagerProvider.GetTeams().filter((t : ITeamViewModel) => t.IsSelected());
            if(selectedTeams.length != 0)
                lastSelectedTeamIndex = teamsManagerProvider.GetTeams().indexOf(selectedTeams[0]);

        });
        teamsManagerProvider.OnAfterTeamsManagerChanged.Add(() => {
            if(!this.teamsManagerProvider.HasTeamsManager())
                return;

            this.Load();

            if(lastSelectedTeamIndex != -1)
                teamsManagerProvider.GetTeams()[lastSelectedTeamIndex].IsSelected(true);
        });

        teamsManagerProvider.OnTeamCreated.Add(this.Load.bind(this));
        teamsManagerProvider.OnTeamDeleted.Add(this.Load.bind(this));

        this.SearchFilter.subscribe(() => {
            this.Load();
        });
    }

    public GoBack() {

    }

    private Load() {
        this.AllItems(this.teamsManagerProvider.SearchTeams(this.SearchFilter()));
    }
}