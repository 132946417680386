import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 23/06/2016
 * Time: 10:12
 * To change this template use File | Settings | File Templates.
 */
import * as moment from "moment";
import { PlannerGantt } from "./PlannerGantt";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { UiUtilities, IRgba } from "../../../../Agenda/Agenda/ui/utils/UiUtilities";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialogsService, IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IPlannerGanttInterval, IPlannerGanttItem, IPlannerGanttLink, IPlannerGanttMarker, IPlannerGanttAllocation, IPlannerGanttItemMetadata } from "../../../interfaces/controls/gantt/PlannerGanttInterfaces";

export class PlannerGanttItem implements IPlannerGanttItem {
    public Id : ko.Observable<any> = ko.observable();
    public Type : ko.Observable<string> = ko.observable();
    public Tag : ko.Observable<any> = ko.observable();
    public Style : ko.Observable<string> = ko.observable("default");
    public DropScope: ko.Observable<string> = ko.observable("default");
    public Icon: ko.Observable<string> = ko.observable();

    public UniqueId : ko.Computed<string>;

    public Title : ko.Observable<string> = ko.observable();
    public Color : ko.Observable<string> = ko.observable();
    public DisabledColor : ko.Observable<string> = ko.observable();
    public HasEstimate : ko.Computed<boolean>;
    public ProgressColor : ko.Observable<string> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public Progress : ko.Observable<number> = ko.observable();
    public EstimatedWork : ko.Observable<number> = ko.observable();
    public WorkDone : ko.Observable<number> = ko.observable();
    public IsClosed : ko.Observable<boolean> = ko.observable();
    public MultipleIntervals : ko.Observable<boolean> = ko.observable();

    public FirstWorkedHour : ko.Observable<Date> = ko.observable();
    public LastWorkedHour : ko.Observable<Date> = ko.observable();
    public FirstWorkedHourDaysFromStart : ko.Computed<number>;
    public LastWorkedHourDaysFromStart : ko.Computed<number>;

    public IsHighlighted : ko.Observable<boolean> = ko.observable(false);

    public CanHaveChildren : ko.Observable<boolean> = ko.observable(true);
    public Children : ko.ObservableArray<IPlannerGanttItem> = ko.observableArray();
    public IsExpanded : ko.Observable<boolean> = ko.observable(false);
    public Visible : ko.Observable<boolean> = ko.observable(true);
    public Disabled: ko.Observable<boolean> = ko.observable(false);
    public Intervals : ko.ObservableArray<IPlannerGanttInterval> = ko.observableArray([]);

    public Links: ko.ObservableArray<IPlannerGanttLink> = ko.observableArray();

    public Markers : ko.ObservableArray<IPlannerGanttMarker> = ko.observableArray([]);
    public Selected : ko.Observable<boolean> = ko.observable(false);
    public DetailedAllocations : ko.ObservableArray<IPlannerGanttAllocation> = ko.observableArray();

    public Duration : ko.Computed<number>;
    public DaysFromStart : ko.Computed<number>;

    public get HasAction(): boolean {
        return !!this.m_action;
    }
    
    public get HasDialog(): boolean {
        return !!this.m_editingDialog;
    }

    public Metadata: IPlannerGanttItemMetadata;

    @LazyImport(nameof<IDialogsService>())
    protected m_dialogsService : IDialogsService;

    private m_editingDialog : () => IDialog | Promise<any>;
    private m_saveHandler : (moved : boolean) => void;
    private m_action : () => void;
    private m_loadChildrenHandler : (callback : (children : PlannerGanttItem[]) => void) => void;
    private m_childrenLoaded  = false;
    private m_loadingChildren  = false;
    private m_uniqueId: string;
    
    private m_dialogAdditionalClasses: string;
    private m_dialogTemplateName: string;
    private m_dialogTemplateUrl: string;

    constructor(private periodStart : ko.Observable<Date>, protected gantt: PlannerGantt) {
        this.Metadata = {};

        this.UniqueId = ko.computed(() => {
            this.m_uniqueId = (this.Type() || "") + (this.Id() || "");
            return this.m_uniqueId;
        });

        this.Duration = ko.computed(() => {
            if(!this.EndDate() || !this.StartDate())
                return 0;

            return Math.round(moment.duration(this.EndDate().valueOf() - this.StartDate().valueOf()).asDays() + 1);
        });

        this.DaysFromStart = ko.computed(() => {
            if(!this.StartDate())
                return 0;

            return moment.duration(this.StartDate().valueOf() - periodStart().valueOf()).asDays();
        });

        this.FirstWorkedHourDaysFromStart = ko.computed(() => {
            if(!this.FirstWorkedHour())
                return Number.MAX_VALUE;
            return moment.duration(this.FirstWorkedHour().valueOf() - periodStart().valueOf()).asDays();
        });

        this.LastWorkedHourDaysFromStart = ko.computed(() => {
            if(!this.LastWorkedHour())
                return Number.MIN_VALUE;
            return moment.duration(this.LastWorkedHour().valueOf() - periodStart().valueOf()).asDays();
        });

        this.HasEstimate = ko.computed(() => {
            return this.EstimatedWork() > 0;
        });

        const expandInterceptor = ko.computed(() => {
            const expanded = this.IsExpanded();

            if (this.m_uniqueId)
                this.gantt.saveGanttItemOpeningState(this.UniqueId(), expanded);

            if (this.m_childrenLoaded || this.m_loadingChildren || !this.m_loadChildrenHandler)
                return;

            if (expanded) {
                this.m_loadingChildren = true;
                this.m_loadChildrenHandler(this.OnChildrenLoaded.bind(this));
            }
        });
    }
    
    private OnChildrenLoaded(children : PlannerGanttItem[]) {
        this.m_childrenLoaded = true;
        this.m_loadingChildren = false;

        this.Children(children);
    }

    public endsOnDay(day : Date) : boolean {
        if(!this.EndDate() || !day)
            return false;

        return this.EndDate().valueOf() == day.valueOf();
    }

    public resizeStart(newDuration : number) {
        newDuration = Math.max(0, Math.ceil(newDuration) - 1);
        this.StartDate(moment(this.EndDate()).add('days', -newDuration).toDate());
        this.StartDate.notifySubscribers(this.StartDate());
        this.EndDate.notifySubscribers(this.EndDate());
        this.Duration.notifySubscribers(this.Duration());
        this.DaysFromStart.notifySubscribers(this.DaysFromStart());
        this.Save(false);
    }

    public resizeEnd(newDuration : number) {
        newDuration = Math.max(0, Math.ceil(newDuration) - 1);
        this.EndDate(moment(this.StartDate()).add('days', newDuration).toDate());
        this.StartDate.notifySubscribers(this.StartDate());
        this.EndDate.notifySubscribers(this.EndDate());
        this.Duration.notifySubscribers(this.Duration());
        this.DaysFromStart.notifySubscribers(this.DaysFromStart());
        this.Save(false);
    }

    public move(newStartFromOrigin : number) {
        const oldDuration = this.Duration();

        this.StartDate(moment(this.periodStart()).add('days', newStartFromOrigin).startOf('day').toDate());
        this.EndDate(moment(this.StartDate()).add('days', oldDuration - 1).startOf('day').toDate());
        this.StartDate.notifySubscribers(this.StartDate());
        this.EndDate.notifySubscribers(this.EndDate());
        this.Duration.notifySubscribers(this.Duration());
        this.DaysFromStart.notifySubscribers(this.DaysFromStart());
        this.Save(true);
    }

    public SetEditingDialog(dialog : () => IDialog | Promise<any>, additionalClasses?: string, templateUrl?: string, templateName?: string)
    {
        this.m_editingDialog = dialog;
        this.m_dialogAdditionalClasses = !additionalClasses ? null : additionalClasses;
        this.m_dialogTemplateUrl = !templateUrl ? null : templateUrl;
        this.m_dialogTemplateName = !templateName ? null : templateName;
    }

    public SetSaveHandler(saveHandler : (boolean) => void)
    {
        this.m_saveHandler = saveHandler;
    }

    public SetAction(action: () => void) {
        this.m_action = action;
    }

    public RunAction() {
        if (this.m_action) {
            this.m_action();
        }
    }

    public ShowEditingDialog()
    {
        if(this.m_editingDialog) {
            const dialogOrPromise = this.m_editingDialog();
            if(!(<Promise<any>>dialogOrPromise).then) {
                const dialog = <IDialog> dialogOrPromise;

                if (this.m_dialogTemplateUrl && this.m_dialogTemplateName) {
                    this.m_dialogsService.ShowModal<void>(dialog, this.m_dialogAdditionalClasses, null, this.m_dialogTemplateUrl, this.m_dialogTemplateName);
                    return;
                }
                if (this.m_dialogAdditionalClasses) {
                    this.m_dialogsService.ShowModal<void>(dialog, this.m_dialogAdditionalClasses);
                    return;
                }
                this.m_dialogsService.ShowModal<void>(dialog);
            }
        }
    }

    public Search(search : string, searchInChildren  = false) : boolean {
        if((search || "").trim().length == 0) {
            this.Children().forEach((c : PlannerGanttItem) => {
                c.Search(search, searchInChildren);
            });

            this.Visible(true);
            return true;
        }

        const searchSplits = search.toUpperCase().split(' ');
        let anyVisible = searchSplits.filter(s => (this.Title() || "").toUpperCase().indexOf(s) >= 0).length == searchSplits.length;

        this.Children().forEach((c : PlannerGanttItem) => {
            if(searchInChildren) {
                anyVisible = anyVisible || c.Search(search, searchInChildren);
            }
            c.Visible(anyVisible);

            if (anyVisible)
                this.SetItemAndChildrensVisibility(c, anyVisible);
        });

        this.Visible(anyVisible);

        return anyVisible;
    }

    public Save(moved : boolean)
    {
        if(!this.m_saveHandler) return;

        this.m_saveHandler(moved);
    }

    public OnChildrenNeeded(callback : (callback : (children : PlannerGanttItem[]) => void) => void) {
        this.m_loadChildrenHandler = callback;
    }

    public SetItemAndChildrensVisibility(item: PlannerGanttItem, visible: boolean): void {
        item.Visible(visible);
        item.Children().forEach((c: PlannerGanttItem) => { c.SetItemAndChildrensVisibility(c, visible); });
    }

    public getMarkersAtDate(date: Date): IPlannerGanttMarker[] {
        return this.Markers().filter((m) => moment(date).startOf("day").isSame(moment(m.Date()).startOf("day")));
    }

    public loadOpeningState(): void {
        const expandedState = this.gantt.getGanttItemOpeningState(this.UniqueId());
        this.IsExpanded(expandedState);
    }
}

export class PlannerGanttItemMarker implements IPlannerGanttMarker
{
    public Id : ko.Observable<any> = ko.observable();
    public Tag : ko.Observable<any> = ko.observable();
    public Title : ko.Observable<string> = ko.observable();
    public Date : ko.Observable<Date> = ko.observable();
    public Color : ko.Observable<string> = ko.observable();
    public Type : ko.Observable<string> = ko.observable("milestone");

    public Duration : ko.Computed<number>;
    public DaysFromStart : ko.Computed<number>;

    public OnClickCallback: () => void = () => {};

    constructor(date : Date, private periodStart : ko.Observable<Date>)
    {
        this.Date(moment(date).toDate());

        this.DaysFromStart = ko.computed(() => {
            if(!this.Date())
                return 0;

            return moment.duration(this.Date().valueOf() - periodStart().valueOf()).asDays();
        });

        this.Duration = ko.computed(() => {
            return 1;
        });
    }
}

export class PlannerGanttAllocation implements IPlannerGanttAllocation
{
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public Color : ko.Observable<string> = ko.observable();
    public AllocatedWork : ko.Observable<number> = ko.observable();
    public Type : ko.Observable<string> = ko.observable("milestone");

    public DaysFromStart : ko.Computed<number>;
    public Duration : ko.Computed<number>;

    constructor(startDate : Date, endDate : Date, allocatedWork : number, private periodStart : ko.Observable<Date>)
    {
        this.StartDate(moment(startDate).toDate());
        this.EndDate(moment(endDate).toDate());
        this.AllocatedWork(allocatedWork);

        this.DaysFromStart = ko.computed(() => {
            if(!this.StartDate())
                return 0;

            return moment.duration(this.StartDate().valueOf() - periodStart().valueOf()).asDays();
        });

        this.Duration = ko.computed(() => {
            if(!this.StartDate())
                return 0;

            return moment.duration(this.StartDate().valueOf() - this.EndDate().valueOf()).asDays() + 1;
        });
    }
}

export class PlannerGanttLink implements IPlannerGanttLink
{
    TargetId : ko.Observable<string> = ko.observable();
    SourceId : ko.Observable<string> = ko.observable();

    Target : ko.Computed<IPlannerGanttItem>;
    TargetPositionFromTop : ko.Computed<number>;
    TargetLeft : ko.Computed<number>;
    TargetRight: ko.Computed<number>;

    Source : ko.Computed<IPlannerGanttItem>;
    SourcePositionFromTop : ko.Computed<number>;
    SourceLeft : ko.Computed<number>;
    SourceRight: ko.Computed<number>;

    Left : ko.Computed<number>;
    Top : ko.Computed<number>;
    Width: ko.Computed<number>;
    Height: ko.Computed<number>;

    FromSourceStyle : ko.Computed<any>;
    FromSourceToMiddleStyle : ko.Computed<any>;
    ToTargetStyle : ko.Computed<any>;
    ArrowStyle : ko.Computed<any>;

    constructor(serviceLocator : IServiceLocator, gantt : PlannerGantt, targetId : string, sourceId : string)
    {
        this.TargetId(targetId);
        this.SourceId(sourceId);

        this.Target = ko.computed(() => {
            let target : IPlannerGanttItem = gantt.findItem(this.TargetId());
            if(!target)
                return target;

            while(gantt.getIndex(target) < 0) {
                target = gantt.getParent(target);
            }
            return target;
        });

        this.TargetPositionFromTop = ko.computed(() => {
            if(!this.Target()) return -1;

            return gantt.getIndex(this.Target()) * gantt.ItemHeight();
        });

        this.TargetLeft = ko.computed(() => {
            if(!this.Target()) return 0;
            return this.Target().DaysFromStart() * gantt.DaySizeInPixels();
        });

        this.TargetRight = ko.computed(() => {
            if(!this.Target()) return 0;
            return (this.Target().DaysFromStart() + this.Target().Duration()) * gantt.DaySizeInPixels();
        });

        this.Source = ko.computed(() => {
            let source : IPlannerGanttItem = gantt.findItem(this.SourceId());
            if(!source)
                return source;

            while(gantt.getIndex(source) < 0) {
                source = gantt.getParent(source);
            }
            return source;
        });

        this.SourcePositionFromTop = ko.computed(() => {
            if(!this.Source()) return -1;

            return gantt.getIndex(this.Source()) * gantt.ItemHeight();
        });

        this.SourceLeft = ko.computed(() => {
            if(!this.Source()) return 0;
            return this.Source().DaysFromStart() * gantt.DaySizeInPixels();
        });

        this.SourceRight = ko.computed(() => {
            if(!this.Source()) return 0;
            return (this.Source().DaysFromStart() + this.Source().Duration()) * gantt.DaySizeInPixels();
        });

        this.Top = ko.computed(() => {
            return Math.min(this.TargetPositionFromTop(), this.SourcePositionFromTop());
        });

        this.Left = ko.computed(() => {
            return Math.min(this.TargetLeft(), this.SourceLeft());
        });

        this.Width = ko.computed(() => {
            return Math.max(this.TargetRight(), this.SourceRight()) - this.Left();
        });

        this.Height = ko.computed(() => {
            return Math.abs(this.TargetPositionFromTop() - this.SourcePositionFromTop()) + gantt.ItemHeight();
        });

        this.FromSourceStyle = ko.computed(() => {
            const goingDown = this.TargetPositionFromTop() > this.SourcePositionFromTop();
            const goingRight = this.TargetLeft() > (this.SourceRight() + 10);

            if(this.TargetPositionFromTop() == this.SourcePositionFromTop()) {
                return <any> {
                    display: 'none'
                }
            }

            if(goingDown) {
                return <any> {
                    display: 'block',
                    position: 'absolute',
                    left: (this.SourceRight() - this.Left()) + 'px',
                    marginTop: '15px',
                    borderTop: '1px solid black',
                    borderRight: '1px solid black',
                    borderBottom: 'none',
                    width: goingRight ? (this.TargetLeft() - 10 - this.SourceRight()) + 'px' : '10px',
                    height: '15px'
                };
            } else {
                return <any> {
                    display: 'block',
                    position: 'absolute',
                    left: (this.SourceRight() - this.Left()) + 'px',
                    marginTop: (this.Height() - 30) + 'px',
                    borderTop: 'none',
                    borderBottom: '1px solid black',
                    borderRight: '1px solid black',
                    width: goingRight ? (this.TargetLeft() - 10 - this.SourceRight()) + 1 + 'px' : '10px',
                    height: '15px'
                };
            }
        });
        this.FromSourceToMiddleStyle = ko.computed(() => {
            const goingDown = this.TargetPositionFromTop() > this.SourcePositionFromTop();
            const goingRight = this.TargetLeft() > (this.SourceRight() + 10);

            if(this.TargetPositionFromTop() == this.SourcePositionFromTop()) {
                return <any> {
                    display: 'none'
                }
            }

            if(goingDown) {
                if(goingRight) {
                    return <any> {
                        display: 'block',
                        position: 'absolute',
                        marginLeft: (this.TargetLeft() - 10 - this.Left()) - 1 + 'px',
                        marginTop: '30px',
                        height: (this.TargetPositionFromTop() - this.Top()) - 30 + 'px',
                        width: '1px',
                        borderLeft: '1px solid black',
                        borderTop: 'none',
                        borderBottom: 'none'
                    };
                } else {
                    return <any> {
                        display: 'block',
                        position: 'absolute',
                        marginLeft: (this.TargetLeft() - 10 - this.Left()) - 1 + 'px',
                        marginTop: '30px',
                        height: (this.TargetPositionFromTop() - this.Top()) - 30 + 'px',
                        width: ((this.SourceRight() + 10) - (this.TargetLeft() - 10)) + 'px',
                        borderLeft: '1px solid black',
                        borderTop: '1px solid black',
                        borderBottom: 'none'
                    };
                }
            } else {
                if(goingRight) {
                    return <any> {
                        display: 'block',
                        position: 'absolute',
                        marginLeft: (this.TargetLeft() - 10 - this.Left()) + 'px',
                        marginTop: '30px',
                        height: (this.SourcePositionFromTop() - this.Top()) - 30 + 'px',
                        width: '1px',
                        borderLeft: '1px solid black',
                        borderTop: 'none',
                        borderBottom: 'none'
                    };
                } else {
                    return <any> {
                        display: 'block',
                        position: 'absolute',
                        marginLeft: (this.TargetLeft() - 10 - this.Left()) + 'px',
                        marginTop: '30px',
                        height: (this.SourcePositionFromTop() - this.Top()) - 30 + 'px',
                        width: ((this.SourceRight() + 10) - (this.TargetLeft() - 10)) + 'px',
                        borderLeft: '1px solid black',
                        borderTop: 'none',
                        borderBottom: '1px solid black'
                    };
                }
            }
        });

        this.ToTargetStyle = ko.computed(() => {
            const goingDown = this.TargetPositionFromTop() > this.SourcePositionFromTop();

            if(this.TargetPositionFromTop() == this.SourcePositionFromTop()) {
                return <any> {
                    display: 'none'
                }
            }

            if(goingDown) {
                return <any> {
                    display: 'block',
                    position: 'absolute',
                    left: (this.TargetLeft() - this.Left() - 10) - 1 + 'px',
                    marginTop: (this.Height() - 30) + 'px',
                    borderTop: 'none',
                    borderBottom: '1px solid black',
                    borderLeft: '1px solid black',
                    width: '10px',
                    height: '15px'
                };
            } else {
                return <any> {
                    display: 'block',
                    position: 'absolute',
                    left: (this.TargetLeft() - this.Left() - 10) + 'px',
                    marginTop: '15px',
                    borderTop: '1px solid black',
                    borderBottom: 'none',
                    borderLeft: '1px solid black',
                    width: '10px',
                    height: '15px'
                };
            }
        });

        this.ArrowStyle = ko.computed(() => {
            const goingDown = this.TargetPositionFromTop() > this.SourcePositionFromTop();
            if(goingDown) {
                return {
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    borderTop: '5px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderLeft: '5px solid black',
                    bottom: '-5px',
                    right: 0,
                    top: 'auto'
                };
            } else {
                return {
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    borderTop: '5px solid transparent',
                    borderBottom: '5px solid transparent',
                    borderLeft: '5px solid black',
                    top: '-5px',
                    right: 0,
                    bottom: 'auto'
                }
            }
        });
    }
}

export class PlannerGanttItemInterval implements IPlannerGanttInterval {
    public Id : ko.Observable<number> = ko.observable();
    public StartDate : ko.Observable<Date> = ko.observable();
    public EndDate : ko.Observable<Date> = ko.observable();
    public Value : ko.Observable<any> = ko.observable();
    public Color : ko.Observable<string> = ko.observable();
    public BorderColor : ko.Observable<string> = ko.observable();
    public FontColor : ko.Observable<string> = ko.observable();
    public Style : ko.Observable<string> = ko.observable("");
    public Tag : ko.Observable<any> = ko.observable();


    public ShowHistogram: ko.Observable<boolean> = ko.observable(false);
    public HistogramColor: ko.Observable<string> = ko.observable("rgb(0, 128, 0)");
    public HistogramHeight: ko.Observable<number> = ko.observable(0);


    public Duration : ko.Computed<number>;
    public DaysFromStart : ko.Computed<number>;

    public OnlyFullDays : ko.Observable<boolean> = ko.observable(true);

    private m_color: string;
    private m_borderColor: string;

    constructor(startDate : Date, endDate : Date, value : any, periodStart : ko.Observable<Date>) {
        this.StartDate(startDate);
        this.EndDate(endDate);
        this.Value(value);

        this.DaysFromStart = ko.computed(() => {
            if(!this.StartDate())
                return 0;
            
            const newStartDate = this.adjustDateOffset(this.StartDate(), periodStart());
            return moment.duration(newStartDate.valueOf() - periodStart().valueOf()).asDays();
        });

        this.Duration = ko.computed(() => {
            if(!this.EndDate() || !this.StartDate())
                return 0;

            const newEndDate = this.adjustDateOffset(this.EndDate(), this.StartDate());
            
            if (this.OnlyFullDays())
                return Math.round(moment.duration(newEndDate.valueOf() - this.StartDate().valueOf()).asDays() + 1);
                
            return moment.duration(newEndDate.valueOf() - this.StartDate().valueOf()).asDays();
        });
    }

    public makeTransparent(): void {
        this.m_color = this.Color();
        if (!this.m_color)
            return;

        let rgb: IRgba = UiUtilities.StringToRGB(this.m_color);
        if (!rgb)
            return;
        
        this.Color(UiUtilities.RgbToString(rgb, 0.1));

        this.m_borderColor = this.BorderColor();
        if (!this.m_borderColor)
            return;

        rgb = UiUtilities.StringToRGB(this.m_borderColor);
        if (!rgb)
            return;
        
        this.BorderColor(UiUtilities.RgbToString(rgb, 0.3));
    }
    
    public restoreColor(): void {
        if (this.m_color)
            this.Color(this.m_color);

        if (this.m_borderColor)
            this.BorderColor(this.m_borderColor);
    }

    // TODO trovare un altro modo per fare questa cosa (serve per gestire il cambio tra ora solare e ora legale)
    private adjustDateOffset(dateToAdjust: Date, referimentDate: Date): Date {
        const dateToAdjustOffset = moment(dateToAdjust).utcOffset();
        const referimentDateOffset = moment(referimentDate).utcOffset();

        const offsetsDifference = dateToAdjustOffset - referimentDateOffset;

        return moment(dateToAdjust).add(offsetsDifference, 'minutes').toDate();
    }
}