import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ExcelExporterButton } from "../../../../Components/ExcelExporterComponent/ExcelExporterComponent";
import {
    IJobOrderService,
    WorkflowAnalysis,
    WorkflowsAnalysisFilters,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { WorkflowsTable } from "./workflows-analysis-components/WorkflowsTable";
import { Filters } from "./workflows-analysis-components/Filters";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { JobOrderLogicalStates } from "../../../../DataSources/JobOrderStatesDataSource";
import { NumericText } from "../../../../Components/NumericText";
import { Button } from "../../../../Components/ButtonGroup";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import {
    IChangesNotificationsService,
    IObjectChangesInfo,
} from "../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";

const styleSheet = jss.createStyleSheet({
    "tempus-dominus-fix": {
        "& .tempus-dominus-widget": {
            zIndex: "10052",
        },
    },
    collapser: {
        borderBottom: "1px solid #e5e5e5",
        marginBottom: "10px",
        height: "20px",

        "& .collapse-button": {
            border: "0",
            position: "absolute",
            right: "15px",
            top: "8px",
            padding: "0px 10px",

            "&:focus": {
                outline: "none",
                backgroundColor: "white",
            },

            "&:hover": {
                outline: "none",
                backgroundColor: "white",
            },

            "&:active": {
                outline: "none",
                backgroundColor: "white",
            },
        },
    },
    header: {
        columnGap: "5px",
        marginBottom: "20px",

        "& .form-group": {
            margin: "0px",
        },

        "& .actions": {
            "& .flex-container": {
                "&.flex-vertical": {
                    margin: "0px !important",
                },

                "&.flex-end": {
                    justifyContent: "flex-end",
                    width: "100%",
                },
            },
        },
    },

    "results-info": {
        borderTop: "1px solid #e5e5e5",
        padding: "10px 0px",
    },
});
const { classes } = styleSheet.attach();

export class WorkflowsAnalysisModal extends DialogComponentBase {
    constructor() {
        super({ noPrompt: true, className: "fullscreen " + classes["tempus-dominus-fix"] });

        this.title(TextResources.JobOrder.WorkflowsAnalysisTitle);
    }

    action() {
        this.modal.close();
    }

    renderBody() {
        return <WorkflowsAnalysis />;
    }
}

type UpdatesEventHandler = { handle: number; eventName: string };

export function WorkflowsAnalysis() {
    const C = require("./WorkflowsAnalysis")._WorkflowsAnalysis as typeof _WorkflowsAnalysis;
    return <C />;
}

export class _WorkflowsAnalysis {
    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService: IJobOrderService;
    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService: IChangesNotificationsService;

    private WorkflowsAnalysis: ko.ObservableArray<WorkflowAnalysis> = ko.observableArray([]);
    private WorkflowsAnalysisCount: ko.Computed<number>;

    private CollapseFilters: ko.Observable<boolean> = ko.observable(false);

    private currentFilters: WorkflowsAnalysisFilters = {
        jobOrderTypes: [],
        jobOrderStates: [],
        jobOrderClassifications: [],
        customers: [],
        workflowCategories: [],
        workflowStates: [],
        workflowOutcomes: [],
        jobOrderLogicalState: JobOrderLogicalStates.Opened,
        viewAll: false,
    };

    private eventHandlers: UpdatesEventHandler[] = [];

    constructor() {
        this.WorkflowsAnalysisCount = ko.computed(() => this.WorkflowsAnalysis().length);
    }

    componentDidMount() {
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.WorkflowEntityTypeCode, this);
        this.changesNotificationsService.ObserveNotificationsFor(ProlifeSdk.JobOrderEntityTypeCode_ALT, this);
    }

    componentWillUnmount() {
        this.changesNotificationsService.RemoveObserver(this);

        for (const handler of this.eventHandlers)
            this.changesNotificationsService.UnregisterEventHandler(handler.eventName, handler.handle);
    }

    async OnEntityHasBeenChanged(changesInfo: IObjectChangesInfo, sentByMe: boolean) {
        if (this.needsToReloadWorkflows(changesInfo) || this.needsToReloadJobOrders(changesInfo)) {
            await this.load();
        }

        return false;
    }

    private needsToReloadWorkflows(changesInfo: IObjectChangesInfo) {
        const workflows = this.WorkflowsAnalysis();

        return (
            changesInfo.EntityCode == ProlifeSdk.WorkflowEntityTypeCode &&
            workflows.find(
                (w) =>
                    w.id == changesInfo.EntityKeyId ||
                    w.jobOrder.id === changesInfo.Changes.OldStatus?.JobOrderId ||
                    w.jobOrder.id === changesInfo.Changes.NewStatus?.JobOrderId
            )
        );
    }

    private needsToReloadJobOrders(changesInfo: IObjectChangesInfo) {
        const workflows = this.WorkflowsAnalysis();

        return (
            changesInfo.EntityCode == ProlifeSdk.JobOrderEntityTypeCode_ALT &&
            (changesInfo.Action === 0 ||
                changesInfo.Action === 2 ||
                (changesInfo.Action === 1 && workflows.find((w) => w.jobOrder.id == changesInfo.EntityKeyId)))
        );
    }

    private async load(): Promise<void> {
        const data = await this.jobOrderService.getWorkflowsAnalysis(this.currentFilters);
        this.WorkflowsAnalysis(data);
    }

    private handleFiltersChanged(filters: WorkflowsAnalysisFilters): void {
        this.currentFilters = filters;
    }

    private excelDataProvider() {
        return null;
    }

    render() {
        const workflowsAnalysis = this;

        return ComponentUtils.bindTo(
            <Layout.Grid
                columns={["1fr"]}
                rows={["min-content", "min-content", "min-content", "1fr"]}
                noOverflow
                style={{ position: "absolute", inset: "15px" }}
            >
                <Layout.Grid.Cell column={1} row={1} className={"flex-container " + classes.collapser}>
                    <Button
                        color="default"
                        className="collapse-button"
                        onClick={() => {
                            this.CollapseFilters(!this.CollapseFilters());
                        }}
                    >
                        <If condition={this.CollapseFilters}>{() => <i className="fa fa-chevron-down"></i>}</If>
                        <IfNot condition={this.CollapseFilters}>{() => <i className="fa fa-chevron-up"></i>}</IfNot>
                    </Button>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={2} className="flex-vertical">
                    <IfNot condition={this.CollapseFilters}>
                        {() => (
                            <Layout.Grid columns={["1fr", "auto"]} rows={["min-content"]} className={classes.header}>
                                <Layout.Grid.Cell row={1} column={1}>
                                    <Filters
                                        initialState={this.currentFilters}
                                        onFiltersChanged={this.handleFiltersChanged.bind(this)}
                                    />
                                </Layout.Grid.Cell>
                                <Layout.Grid.Cell row={1} column={2}>
                                    <Layout.Vertical className="flex-full-height actions">
                                        <Layout.Vertical.Row autoSize className="flex-fill flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-bind={{
                                                    asyncClick: workflowsAnalysis.load.bind(workflowsAnalysis),
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                <i className="fa fa-search"></i>&nbsp;{TextResources.ProlifeSdk.Search}
                                            </button>
                                        </Layout.Vertical.Row>
                                        <Layout.Vertical.Row autoSize className="flex-fill flex-end">
                                            <ExcelExporterButton
                                                exporterId=""
                                                exporterMethod="GenerateExcel"
                                                dataProvider={workflowsAnalysis.excelDataProvider.bind(
                                                    workflowsAnalysis
                                                )}
                                                position="left"
                                            >
                                                <i class="fa fa-download"></i>&nbsp;
                                                {TextResources.JobOrder.WorkflowsAnalysisExport}
                                            </ExcelExporterButton>
                                        </Layout.Vertical.Row>
                                    </Layout.Vertical>
                                </Layout.Grid.Cell>
                            </Layout.Grid>
                        )}
                    </IfNot>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={3} className={classes["results-info"]}>
                    <div className="flex-container">
                        {TextResources.JobOrder.WorkflowsAnalysisResultsCount}&nbsp;
                        <NumericText value={this.WorkflowsAnalysisCount} format="0,0" />
                    </div>
                </Layout.Grid.Cell>
                <Layout.Grid.Cell column={1} row={4}>
                    <WorkflowsTable workflows={this.WorkflowsAnalysis} />
                </Layout.Grid.Cell>
            </Layout.Grid>,
            this,
            "workflowsAnalysis"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(WorkflowsAnalysis);
}
