import * as ko from "knockout";
import * as numeral from "numeral";
import * as ProlifeSdk from "../../../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { ServiceTypes } from "../../../../../../Core/enumerations/ServiceTypes";
import { TreeGrid,TreeGridStringColumn, TreeGridColumn } from "../../../../../../ProlifeSdk/prolifesdk/controls/treegrid/TreeGrid";
import { TextResources } from "../../../../../../ProlifeSdk/ProlifeTextResources";
import { IJobOrderService, IJobOrderSchedule, IJobOrderEstimate } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IJobOrderEditor } from "../../../../../../ProlifeSdk/interfaces/job-order/IJobOrderEditor";
import { IServiceLocator } from "../../../../../../Core/interfaces/IServiceLocator";
import { IDialogsService } from "../../../../../../Core/interfaces/IDialogsService";
import { IAdministrativeReportPage } from "../../../../../interfaces/IAdministrativeReportPage";
import { IControlsEntityProvider } from "../../../../../../ProlifeSdk/interfaces/IControlsEntityProvider";
import { IFileRepositoryService } from "../../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { Deferred } from "../../../../../../Core/Deferred";

export class SchedulesPage implements IAdministrativeReportPage {
    Title : ko.Observable<string> = ko.observable(TextResources.JobOrder.PaymentsAdministrativeReportTitle);
    TemplateUrl:string = "joborder/templates/joborderdetail/administrative-report";
    TemplateName:string = "schedules-page";
    Table : TreeGrid = new TreeGrid();

    ExporterId : string = "JobOrder/JobOrderAdministativeReportSchedulesExport";

    SearchField : ko.Observable<string> = ko.observable();
    DateFrom : ko.Observable<Date> = ko.observable();
    DateTo : ko.Observable<Date> = ko.observable();
    AmountFrom : ko.Observable<number> = ko.observable();
    AmountTo : ko.Observable<number> = ko.observable();
    ValueFrom : ko.Observable<number> = ko.observable();
    ValueTo : ko.Observable<number> = ko.observable();
    OnlyClosed : ko.Observable<boolean> = ko.observable();
    OrderBy : ko.Observable<string> = ko.observable();
    OrderAscDesc : ko.Observable<string> = ko.observable();

    public humanResourcesSearchService : IControlsEntityProvider;

    private columnMapping : string[] = [
        'SourceProtocol',
        'ScheduleDate',
        'Description',
        //'Amount',
        'Value',
        'Closed'
    ];

    private expandCollapseCache : { [id: string] : boolean; } = {};

    private jobOrdersService : IJobOrderService;
    private fileRepositoryService : IFileRepositoryService;
    private dialogsService : IDialogsService;

    constructor(private serviceLocator : IServiceLocator, private editor : IJobOrderEditor) {
        this.jobOrdersService = <IJobOrderService> serviceLocator.findService(ProlifeSdk.JobOrderServiceType);
        this.dialogsService = <IDialogsService> serviceLocator.findService(ServiceTypes.Dialogs);
        this.fileRepositoryService = <IFileRepositoryService> serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType);

        let today = moment();
        this.DateFrom(moment(today).add(-1, "month").startOf("month").toDate());
        this.DateTo(today.endOf("day").toDate());

        this.Table.ShowActions(false);
        this.Table.AddColumn(new TreeGridStringColumn("Protocollo", value => value.SourceProtocol, 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Scadenza", value => value.ScheduleDate === undefined ? '' : !value.ScheduleDate ? ProlifeSdk.TextResources.ProlifeSdk.NotAvailable : moment(value.ScheduleDate).format("L"), 200).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Descrizione", value => value.Description, 200).Align('right'));
        //this.Table.AddColumn(new TreeGridStringColumn("Quantità", value => value.Amount, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00')).Align('right'));
        this.Table.AddColumn(new TreeGridStringColumn("Valore", value => value.Value, 100).Format(v => (v == undefined || v == null) ? "" : numeral(v).format('0,0.00 $')).Align('right'));
        this.Table.AddColumn(new TreeGridColumn("Chiuso", value => value.Closed === undefined ? '' : !value.Closed ? ProlifeSdk.TextResources.ProlifeSdk.No : ProlifeSdk.TextResources.ProlifeSdk.Yes, 150).Align('center'));

        this.Table.SortingModeChanged.Add((column : TreeGridColumn, sortMode : number) => {
            var index = this.Table.Columns().indexOf(column);
            if(index < 0 || sortMode == 0) {
                this.OrderBy(null);
                this.OrderAscDesc(null);
            } else {
                this.OrderBy(this.columnMapping[index]);
                this.OrderAscDesc(sortMode == 1 ? 'ASC' : 'DESC');
            }

            this.load();
        });

        ko.utils.observableBetween(this.AmountFrom, this.AmountTo);
        ko.utils.observableBetween(this.ValueFrom, this.ValueTo);
        ko.utils.observableBetween(this.DateFrom, this.DateTo);
    }

    dispose() {
    }

    public Search() {
        this.load();
    }

    public getExcelExportData() : any | null {
        return {
            JobOrderId: this.editor.JobOrderId,
            SearchField: this.SearchField(),
            DateFrom : this.DateFrom(),
            DateTo : this.DateTo(),
            AmountFrom : this.AmountFrom(),
            AmountTo : this.AmountTo(),
            ValueFrom : this.ValueFrom(),
            ValueTo : this.ValueTo(),
            OnlyClosed : this.OnlyClosed(),
            OrderBy: this.OrderBy(),
            OrderAscDesc: this.OrderAscDesc()
        };
    }

    /*private OpenColumnsInfo() {
        var report = new ReportColumnsInfoDialog("JobOrder/JobOrderAdministativeReportSchedulesExport", "AvailableColumns", "DefaultTemplate");
        this.dialogsService.ShowModal<void>(report, "large", { noPrompt: true });
    }

    public Export() {
        this.fileRepositoryService.openAsDialog("/" + ProlifeSdk.TextResources.JobOrder.ReportModelsURL)
            .then((selectedFiles : IFileOrFolder[]) => {
                if(selectedFiles.length == 0)
                    return;

                var template = selectedFiles[0];

                this.jobOrdersService.ExportSchedules(template.Id, this.editor.JobOrderId, this.SearchField(), this.DateFrom(), this.DateTo(), this.AmountFrom(), this.AmountTo(), this.ValueFrom(), this.ValueTo(), this.OnlyClosed(), this.OrderBy(), this.OrderAscDesc());
            });
    }*/

    load():Promise<boolean> {
        var def = new Deferred<boolean>();

        this.jobOrdersService.GetSchedules(this.editor.JobOrderId, this.SearchField(), this.DateFrom(), this.DateTo(), this.AmountFrom(), this.AmountTo(), this.ValueFrom(), this.ValueTo(), this.OnlyClosed(), this.OrderBy(), this.OrderAscDesc())
            .then((costs : IJobOrderSchedule[]) => {
                this.Table.ClearItems();

                /*var lastSourceType = "", lastSourceId = 0;
                var lastItem = null;
                var total = 0;
                var totalToImport = 0;

                costs.forEach(c => {
                    if(lastSourceType != c.SourceType || lastSourceId != c.SourceId) {
                        lastItem = this.Table.AddItem({}, this.getDescriptionString(c));
                        lastSourceType = c.SourceType;
                        lastSourceId = c.SourceId;

                        var existingValue = this.expandCollapseCache[c.SourceType + c.SourceId];
                        if(existingValue == undefined) {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = false;
                            existingValue = false;
                        }

                        lastItem.IsExpanded(existingValue);
                        lastItem.IsExpandedChanged.Add(isExpanded => {
                            this.expandCollapseCache[c.SourceType + c.SourceId] = isExpanded;
                        });
                    }

                    lastItem.AddChild(c, moment(c.ScheduleDate).format("L")).CanHaveChildren(false);

                    total += c.Value;
                    if(!c.Closed)
                        totalToImport += c.Value
                });*/

                var total = 0;
                var totalToImport = 0;

                costs.forEach(c => {
                    let item = this.Table.AddItem(c, this.getDescriptionString(c));
                    item.CanHaveChildren(false);

                    total += c.Value;
                    if(!c.Closed)
                        totalToImport += c.Value
                });

                this.Table.AddItem({ Value: total }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotal).CanHaveChildren(false);
                this.Table.AddItem({ Value: totalToImport }, ProlifeSdk.TextResources.JobOrder.WarehouseCostsTotalToImport).CanHaveChildren(false);

                def.resolve(true);
            });

        return def.promise();
    }

    private getDescriptionString(c : IJobOrderEstimate) : string {
        switch(c.SourceType) {
            case "DOC":
                return String.format(ProlifeSdk.TextResources.JobOrder.InvoicesCostsInvoice, c.SourceName, moment(c.SourceDate).format("L"));
        }

        return "";
    }

    isDefault():boolean {
        return false;
    }

    beforeChangePanel(): Promise<boolean> {
        return Promise.resolve<boolean>(true);
    }

    beforeShowPanel() {
    }
}