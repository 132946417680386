import * as ko from "knockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { ResourceManagerArea } from "./ResourceManagerArea";
import { OperationalUnitDropDownList } from "../dropdown/OperationalUnitDropDownList";
import { WorkTimeCategoriesDropDownList } from "../dropdown/WorkTimeCategoriesDropDownList";
import { CallRightTypesDropDownList } from "../dropdown/CallRightTypesDropDownList";
import { LazyImport, LazyImportSettingManager } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IProLifeSdkService } from "../../../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IResourcesManagerViewModel } from "../../../interfaces/IResourcesManagerViewModel";
import { IUserCharactersSettingsManager, IUserCharacter } from "../../../../ProlifeSdk/interfaces/users/IUserCharacter";
import { Deferred } from "../../../../Core/Deferred";

export class ResourcesRoles extends ResourceManagerArea {
    public humanSection: ResourcesRolesSection;
    public materialsSection: ResourcesRolesSection;

    constructor(mainLayout: IResourcesManagerViewModel) {
        super(
            ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesAreaTitle,
            "fa-graduation-cap",
            mainLayout,
            "ResourcesManager/Templates/Areas/ResourcesRoles",
            "main-panel",
            ProlifeSdk.TextResources.ResourcesManager.ResourcesManagerURL_Roles
        );

        this.humanSection = new ResourcesRolesSection(true);
        this.materialsSection = new ResourcesRolesSection(false);
    }

    public show() {
        super.show();

        this.humanSection.init();
        this.materialsSection.init();
    }
}

export class ResourcesRolesSection {
    public title: string;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesSettingsService: IUserCharactersSettingsManager;

    private list: ko.ObservableArray<RoleForList> = ko.observableArray([]);
    public filteredList: ko.Computed<RoleForList[]>;
    public selected: ko.Observable<RoleEditMask> = ko.observable(null);
    public textFilter: ko.Observable<string> = ko.observable("");
    public showDeleted: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(ServiceTypes.Authorization)
    private authorizationsService: IAuthorizationService;

    public canShowDeleted: ko.Observable<boolean> = ko.observable(false);

    constructor(private forHuman: boolean) {
        const showDeletedRight = this.authorizationsService.isAuthorized(
            "ResourcesManager_CanViewShowDeletedButtonOnResourcesManager"
        );
        this.canShowDeleted(showDeletedRight);

        this.filteredList = ko.computed(() => {
            return this.list()
                .filter((c: RoleForList) => {
                    return c.model.Description.toUpperCase().indexOf(this.textFilter().toUpperCase()) > -1;
                })
                .filter((c: RoleForList) => {
                    return !c.model.Eliminato || this.showDeleted();
                })
                .sort((a, b) => {
                    if (a.model.Description < b.model.Description) return -1;
                    if (a.model.Description > b.model.Description) return 1;
                    return 0;
                });
        });

        this.createNew();
    }

    public updateElementInLists(model: IUserCharacter) {
        this.removeElementFromLists(model.IdUserCharacter);
        this.list.push(new RoleForList(model, this));
    }

    public removeElementFromLists(id: number) {
        const updated = this.list().filter((c: RoleForList) => {
            return c.model.IdUserCharacter == id;
        });

        if (updated.length > 0) this.list.remove(updated[0]);
    }

    public edit(model: IUserCharacter) {
        this.selected(new RoleEditMask(model, this));
    }

    public createNew() {
        const emptyModel: IUserCharacter = {
            IdUserCharacter: -1,
            Description: "",
            Salary: 0,
            Cost: 0,
            UseCostInsteadOfMediumCost: false,
            IsDefault: 0,
            DefaultCallRightType: -1,
            DefaultWorkTimeCategory: -1,
            FkOperationalUnit: null,
            ResourceType: this.forHuman ? 0 : 1,
            Code: "",
        };
        this.edit(emptyModel);
    }

    public init() {
        this.rolesSettingsService.load().then(() => {
            this.list(
                this.rolesSettingsService
                    .getUserCharactersWithDeleted(this.forHuman ? 0 : 1)
                    .map((r: IUserCharacter) => {
                        return new RoleForList(r, this);
                    })
            );
        });
    }
}

export class RoleEditMask {
    @LazyImport(nameof<IProLifeSdkService>())
    private sdkService: IProLifeSdkService;

    @LazyImportSettingManager(ProlifeSdk.UserCharactersServiceType)
    private rolesSettingsService: IUserCharactersSettingsManager;

    @LazyImport(nameof<IInfoToastService>())
    private toastService: IInfoToastService;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IDocumentsService>())
    private docService: IDocumentsService;

    public originalName: ko.Observable<string> = ko.observable("");
    public description: ko.Observable<string> = ko.observable("");
    public price: ko.Observable<number> = ko.observable(0);
    public code: ko.Observable<string> = ko.observable("");
    public defaultCallRightType: CallRightTypesDropDownList;
    public defaultWorkTimeCategory: WorkTimeCategoriesDropDownList;
    public operationalUnit: OperationalUnitDropDownList;
    public isNew: ko.Observable<boolean> = ko.observable(false);
    public deleted: ko.Observable<boolean> = ko.observable(false);

    public cost: ko.Observable<number> = ko.observable(0);
    public useCostInsteadOfMediumCost: ko.Observable<boolean> = ko.observable(false);

    constructor(public model: IUserCharacter, private parent: ResourcesRolesSection) {
        this.defaultCallRightType = new CallRightTypesDropDownList(model.ResourceType);
        this.defaultWorkTimeCategory = new WorkTimeCategoriesDropDownList(model.ResourceType);
        this.operationalUnit = new OperationalUnitDropDownList();

        this.updateData(model);
    }

    private updateData(model: IUserCharacter) {
        this.model = model;
        this.description(model.Description);
        this.deleted(model.Eliminato);
        this.price(model.Salary);
        this.cost(model.Cost);
        this.useCostInsteadOfMediumCost(model.UseCostInsteadOfMediumCost);
        this.defaultCallRightType.selectedId(model.DefaultCallRightType);
        this.defaultWorkTimeCategory.selectedId(model.DefaultWorkTimeCategory);
        this.operationalUnit.selectedId(model.FkOperationalUnit);
        this.isNew(model.IdUserCharacter <= 0);

        this.originalName(
            this.isNew()
                ? ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesNewOperationalUnit
                : model.Description
        );

        const activationCode: string = this.docService.getRowReferencesGeneratorFactoryForEntity(
            ProlifeSdk.EstimatedWorkEntityTypeCode
        ).ActivationCode;
        const code: string =
            model.IdUserCharacter > 0
                ? activationCode + this.sdkService.Utilities.Numbers.ZeroPad(model.IdUserCharacter.toString(), 5)
                : "N.D.";
        this.code(code);
    }

    public save() {
        if (this.description().length == 0) {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesInvalidName);
            return;
        }

        if (!this.operationalUnit.selectedId()) {
            this.toastService.Warning(ProlifeSdk.TextResources.ResourcesManager.ResourcesOperationalUnitRequired);
            return;
        }

        this.model.Description = this.description();
        this.model.Salary = this.price();
        this.model.DefaultCallRightType = this.defaultCallRightType.selectedId();
        this.model.DefaultWorkTimeCategory = this.defaultWorkTimeCategory.selectedId();
        this.model.FkOperationalUnit = this.operationalUnit.selectedId();
        this.model.Cost = this.cost();
        this.model.UseCostInsteadOfMediumCost = this.useCostInsteadOfMediumCost();
        this.model.FkOperationalUnit = this.operationalUnit.selectedId();

        this.rolesSettingsService
            .createOrUpdate(this.model)
            .then((c: IUserCharacter) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesUpdated);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            })
            .catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesUpdateError);
            });
    }

    public hasChanges(): boolean {
        const newModel: IUserCharacter = <IUserCharacter>$.extend({}, this.model);

        newModel.Description = this.description();
        newModel.Salary = this.price();
        newModel.Cost = this.cost();
        newModel.UseCostInsteadOfMediumCost = this.useCostInsteadOfMediumCost();
        newModel.DefaultCallRightType = !this.defaultCallRightType.selectedId()
            ? -1
            : parseInt(this.defaultCallRightType.selectedId().toString());
        newModel.DefaultWorkTimeCategory = !this.defaultWorkTimeCategory.selectedId()
            ? -1
            : parseInt(this.defaultWorkTimeCategory.selectedId().toString());
        newModel.FkOperationalUnit = !this.operationalUnit.selectedId()
            ? null
            : parseInt(this.operationalUnit.selectedId().toString());

        return !Object.identical(newModel, this.model);
    }

    public restoreItem() {
        this.model.Eliminato = false;

        this.rolesSettingsService
            .createOrUpdate(this.model)
            .then((c: IUserCharacter) => {
                this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesRestored);
                this.updateData(c);
                this.parent.updateElementInLists(c);
            })
            .catch(() => {
                this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesRestoredError);
            });
    }

    public deleteItem() {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesDeleteWarning,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesDeleteWarningCancel,
            ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesDeleteWarningConfirm,
            (confirm: boolean) => {
                if (!confirm) return;

                this.rolesSettingsService
                    .remove(this.model.IdUserCharacter)
                    .then(() => {
                        this.toastService.Success(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesDeleted);
                        this.model.Eliminato = true;
                        this.parent.updateElementInLists(this.model);
                        this.parent.createNew();
                    })
                    .catch(() => {
                        this.toastService.Error(ProlifeSdk.TextResources.ResourcesManager.ResourcesRolesDeleteError);
                    });
            }
        );
    }
}

export class RoleForList {
    public isSelected: ko.Computed<boolean>;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(public model: IUserCharacter, private parent: ResourcesRolesSection) {
        this.isSelected = ko.computed(() => {
            return this.parent.selected() && this.parent.selected().model.IdUserCharacter == this.model.IdUserCharacter;
        });
    }

    public showDetails() {
        if (!this.parent.selected()) {
            this.parent.edit(this.model);
            return;
        }

        if (this.parent.selected().hasChanges()) {
            this.ShowConfirmOnLostChanges().then((confirm: boolean) => {
                if (confirm) this.parent.edit(this.model);
            });
            return;
        }

        this.parent.edit(this.model);
    }

    private ShowConfirmOnLostChanges(): Promise<boolean> {
        const def = new Deferred<boolean>();

        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEdit,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditCancel,
            ProlifeSdk.TextResources.ResourcesManager.ConfirmLostChangesOnResourceEditConfirm,
            (confirm: boolean) => {
                def.resolve(confirm);
            }
        );

        return def.promise();
    }
}
