import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IWorkedHoursEditorParameters } from "../../../interfaces/IWorkedHoursEditor";
import { IWorkedHoursForEditing } from "../../../../ProlifeSdk/interfaces/worked-hours/IWorkedHoursService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { DialogComponentBase } from "../../../../Core/utils/DialogComponentBase";
import { _WorkedHoursEditor } from "../editor/WorkedHoursEditor";

export class WorkedHoursEditorDialog extends DialogComponentBase {
    public templateName = "workedhours-editor-dialog";
    public templateUrl = "workedhours/templates/worksheet";
    
    public editorParams: IWorkedHoursEditorParameters;
    private hasChanges = false;

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(resourceId: number, workDate: Date, jobOrderId: number, taskId: number = null, hoursId: number = null, canEditDate = false) {
        super({ className: "fullscreen", noPrompt: true });

        this.title(ProlifeSdk.TextResources.WorkedHours.WorkedHoursEditorDialog);

        this.editorParams = {
            resourceId: resourceId,
            date: workDate,
            jobOrderId: jobOrderId,
            taskId: taskId,
            hoursId: hoursId,
            listeners: [],
            canEditDate: canEditDate,
            enableMultipleInsert: false,

            onEditorChanges: (hasChanges: boolean) => this.hasChanges = true,
            onWorkedHoursInserted: (workedHours: IWorkedHoursForEditing) => this.hasChanges = true,
            onWorkedHoursUpdated: (workedHours: IWorkedHoursForEditing) => this.hasChanges = true,
            onWorkedHoursDeleted: (hoursId: number) => this.hasChanges = true,
            onWorkedHoursApproval: () => this.hasChanges = true,
            onMassiveWorkedHoursApproval: () => this.hasChanges = true
        }
    }

    public show(): Promise<boolean> {
        return this.dialogsService.ShowModal(this);
    }

    public close(): void {
        this.modal.close(this.hasChanges);
    }
    
    public action(): void {
        throw new Error("Method not implemented.");
    }

    public renderBody() {
        return <_WorkedHoursEditor
                    resourceId={this.editorParams.resourceId}
                    date={this.editorParams.date}
                    listeners={[]}
                    jobOrderId={this.editorParams.jobOrderId}
                    taskId={this.editorParams.taskId}
                    hoursId={this.editorParams.hoursId}
                    canEditDate={this.editorParams.canEditDate}
                    enableMultipleInsert={this.editorParams.enableMultipleInsert}
                    canEditOtherUsersWorkedHours={this.editorParams.canEditOtherUsersWorkedHours}

                    onEditorChanges={this.editorParams.onEditorChanges.bind(this)}
                    onWorkedHoursInserted={this.editorParams.onWorkedHoursInserted.bind(this)}
                    onWorkedHoursUpdated={this.editorParams.onWorkedHoursUpdated.bind(this)}
                    onWorkedHoursDeleted={this.editorParams.onWorkedHoursDeleted.bind(this)}
                    onWorkedHoursApproval={this.editorParams.onWorkedHoursApproval.bind(this)}
                    onMassiveWorkedHoursApproval={this.editorParams.onMassiveWorkedHoursApproval.bind(this)}
                ></_WorkedHoursEditor>;
    }
}