import * as ko from "knockout";
/**
 * Created with JetBrains WebStorm.
 * User: d.collantoni
 * Date: 19/03/13
 * Time: 10.03
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { BasePagedList } from "../Utilities/BasePagedList";
import { WarehousesProvider } from "../Utilities/WarehousesProvider";
import { CustomersProvider, ICustomersProvider } from "../../../../Desktop/utilities/CustomersProvider";
import { CreateNewArticleDialog } from "../Articles/CreateNewArticleDialog";
import { ArticleDetailsDialog } from "../Articles/ArticleDetailsDialog";
import { LazyImport } from "../../../../Core/DependencyInjection";
import {
    IWarehousesProvider,
    IWarehousesService,
    IWarehouse,
} from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";
import {
    IStockService,
    IStockMovement,
    IArticleStockMovementsIntoPeriod,
} from "../../../../ProlifeSdk/interfaces/warehouse/IStockService";
import { IView } from "../../../../ProlifeSdk/interfaces/IView";
import { IAjaxService } from "../../../../Core/interfaces/IAjaxService";

export class MovementsHistoryManagement
    extends BasePagedList
    implements IView, IWarehousesProvider, ICustomersProvider
{
    templateName = "warehouse-stock-movements-history-management";
    templateUrl = "warehouse/templates";
    viewModel: any;

    public title: string = ProlifeSdk.TextResources.Warehouse.Movements;
    public CustomerId: ko.Observable<number> = ko.observable();
    public WarehouseId: ko.Observable<number> = ko.observable();

    @LazyImport(ProlifeSdk.StockServiceType)
    private stockService: IStockService;
    @LazyImport(nameof<IAjaxService>())
    private ajaxService: IAjaxService;
    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;

    private transactionActions: any = {};

    private IsStatusLegendVisible: ko.Observable<boolean> = ko.observable(false);
    private ArticleClickedOnStatusLegendShowing: ko.Observable<any> = ko.observable(null);
    public SectionId = 8;

    public ArticleDetailsDialog: CreateNewArticleDialog;

    private oldFrom: Date;
    private oldTo: Date;
    public From: ko.Observable<Date> = ko.observable();
    public To: ko.Observable<Date> = ko.observable();

    constructor(selectDefaultWarehouse = true) {
        super();
        this.viewModel = this;

        this.ArticleDetailsDialog = new CreateNewArticleDialog(true);

        new CustomersProvider(this);
        new WarehousesProvider(this);

        this.CustomerId.subscribe(() => this.WarehouseId(null));
        this.WarehouseId.subscribe(() => this.loadRows());

        if (selectDefaultWarehouse)
            this.warehousesService.getDefaultWarehouse().then((warehouse: IWarehouse) => {
                if (warehouse) this.WarehouseId(warehouse.Id);
            });

        this.From(moment(new Date()).startOf("month").subtract("month", 1).toDate());
        this.oldFrom = this.From();
        this.To(moment(new Date()).startOf("day").toDate());
        this.oldTo = this.To();

        this.From.subscribe((v) => {
            if (!(v instanceof Date)) {
                this.From(this.oldFrom);
                return;
            }

            this.oldFrom = v;
            this.loadRows();
        });

        this.To.subscribe((v) => {
            if (!(v instanceof Date)) {
                this.To(this.oldTo);
                return;
            }

            this.oldTo = v;
            this.loadRows();
        });
    }

    public ExportExcel() {
        const url =
            "Warehouse/StockMovementsIntoPeriodPrint/GenerateExcel?from=" +
            encodeURIComponent(JSON.stringify(this.From())) +
            "&to=" +
            encodeURIComponent(JSON.stringify(this.To())) +
            "&warehouseId=" +
            this.WarehouseId() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public Print() {
        const url =
            "Warehouse/StockMovementsIntoPeriodPrint/GeneratePdf?from=" +
            encodeURIComponent(JSON.stringify(this.From())) +
            "&to=" +
            encodeURIComponent(JSON.stringify(this.To())) +
            "&warehouseId=" +
            this.WarehouseId() +
            "&filter=" +
            encodeURIComponent(this.searchFilter());

        this.ajaxService.DownloadFileFromUrl(url, {});
    }

    public SelectWarehouse(warehouseId: number) {
        this.warehousesService.getWarehouseById(warehouseId).then((w: IWarehouse) => {
            if (!w) return;
            this.CustomerId(w.CustomerId);
            setTimeout(() => {
                this.WarehouseId(warehouseId);
            }, 500);
        });
    }

    public onCustomerSelected(e, newValue) {
        this.WarehouseId(undefined);
    }

    public fetchRows(): Promise<IStockMovement[]> {
        if (!this.WarehouseId()) {
            return Promise.resolve<IStockMovement[]>([]);
        }

        return this.stockService.getStockMovementsIntoPeriod(
            this.From(),
            this.To(),
            this.searchFilter(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage,
            this.WarehouseId()
        );
    }

    public fetchRowsCount(): Promise<number> {
        if (!this.WarehouseId()) {
            return Promise.resolve<number>(0);
        }

        return this.stockService.getStockMovementsIntoPeriodCount(
            this.From(),
            this.To(),
            this.searchFilter(),
            this.WarehouseId()
        );
    }

    public createViewModelFor(movements: IArticleStockMovementsIntoPeriod): StockArticleMovementsIntoPeriod {
        var article = new StockArticleMovementsIntoPeriod(movements);
        return article;
    }

    public showPurchaseDetails(movements: any) {
        const article = movements as StockArticleMovementsIntoPeriod;
        var dialog: ArticleDetailsDialog = new ArticleDetailsDialog(
            article.ArticleId,
            article.Code,
            article.Description,
            this.CustomerId(),
            this.WarehouseId()
        );
        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    findCustomers(query: any) {}
    findCustomer(element, callback) {}
    findWarehouses(query: any) {}
    findWarehouse(element, callback) {}
}

export class StockArticleMovementsIntoPeriod {
    public ArticleId: number;
    public Outputs: number;
    public Inputs: number;
    public Description: string;
    public Code: string;

    constructor(private movementsInfo: IArticleStockMovementsIntoPeriod) {
        this.ArticleId = movementsInfo.ArticleId;
        this.Description = movementsInfo.Description;
        this.Code = movementsInfo.Code;
        this.Outputs = movementsInfo.Outputs;
        this.Inputs = movementsInfo.Inputs;
    }
}
