import * as ko from "knockout";
var icons = {
    iconClass: '',
    iconClassFix: 'icon-',
    icons: [
        'action-redo',
        'action-undo',
        'anchor',
        'arrow-down',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'badge',
        'bag',
        'ban',
        'bar-chart',
        'basket',
        'basket-loaded',
        'bell',
        'book-open',
        'briefcase',
        'bubble',
        'bubbles',
        'bulb',
        'calculator',
        'calendar',
        'call-end',
        'call-in',
        'call-out',
        'camcorder',
        'camera',
        'check',
        'chemistry',
        'clock',
        'close',
        'cloud-download',
        'cloud-upload',
        'compass',
        'control-end',
        'control-forward',
        'control-pause',
        'control-play',
        'control-rewind',
        'control-start',
        'credit-card',
        'crop',
        'cup',
        'cursor',
        'cursor-move',
        'diamond',
        'direction',
        'directions',
        'disc',
        'dislike',
        'doc',
        'docs',
        'drawer',
        'drop',
        'earphones',
        'earphones-alt',
        'energy',
        'envelope',
        'envelope-letter',
        'envelope-open',
        'equalizer',
        'eye',
        'eyeglasses',
        'feed',
        'film',
        'fire',
        'flag',
        'folder',
        'folder-alt',
        'frame',
        'game-controller',
        'ghost',
        'globe',
        'globe-alt',
        'graduation',
        'graph',
        'grid',
        'handbag',
        'heart',
        'home',
        'hourglass',
        'info',
        'key',
        'layers',
        'like',
        'link',
        'list',
        'lock',
        'lock-open',
        'login',
        'logout',
        'loop',
        'magic-wand',
        'magnet',
        'magnifier',
        'magnifier-add',
        'magnifier-remove',
        'map',
        'microphone',
        'mouse',
        'moustache',
        'music-tone',
        'music-tone-alt',
        'note',
        'notebook',
        'paper-clip',
        'paper-plane',
        'pencil',
        'picture',
        'pie-chart',
        'pin',
        'plane',
        'playlist',
        'plus',
        'pointer',
        'power',
        'present',
        'printer',
        'puzzle',
        'question',
        'refresh',
        'reload',
        'rocket',
        'screen-desktop',
        'screen-smartphone',
        'screen-tablet',
        'settings',
        'share',
        'share-alt',
        'shield',
        'shuffle',
        'size-actual',
        'size-fullscreen',
        'social-dribbble',
        'social-dropbox',
        'social-facebook',
        'social-tumblr',
        'social-twitter',
        'social-youtube',
        'speech',
        'speedometer',
        'star',
        'support',
        'symbol-female',
        'symbol-male',
        'tag',
        'target',
        'trash',
        'trophy',
        'umbrella',
        'user',
        'user-female',
        'user-follow',
        'user-following',
        'user-unfollow',
        'users',
        'vector',
        'volume-1',
        'volume-2',
        'volume-off',
        'wallet',
        'wrench'
    ]
};

export class IconPicker
{
    init(element : any, valueAccessor : () => any, allBindingsAccessor: () => any, viewModel : any, bindingContext : ko.BindingContext) {
        var value = ko.utils.unwrapObservable(valueAccessor());

        (<any>$(element)).iconpicker({
            icon: value || 'icon-star',
            iconset: icons,
            unselectedClass: 'btn-default btn-lg',
            selectedClass: 'btn-warning btn-lg'
        }).on('change', function(e) {
            var observable = valueAccessor();
            if(ko.isWriteableObservable(observable))
                observable(e.icon);
        });
    }

    update(element : any, valueAccessor : () => any, allBindingsAccessor: () => any, viewModel : any, bindingContext : ko.BindingContext) {
        var value = ko.utils.unwrapObservable(valueAccessor());

        (<any>$(element)).iconpicker('setIcon', value);
    }
}

ko.bindingHandlers["iconPicker"] = new IconPicker();
