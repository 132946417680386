import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, classNames, reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { LazyImport } from "../../../Core/DependencyInjection";
import { IBlogService } from "../../../ProlifeSdk/interfaces/blog/IBlogService";

type JobOrderBlogButtonProps = {
    jobOrderId: number;
    extraSmall?: boolean;
};

export function JobOrderBlogButton(props: JobOrderBlogButtonProps) {
    const C = require("./JobOrderBlogButton")._JobOrderBlogButton as typeof _JobOrderBlogButton;
    return <C {...props} />;
}

export class _JobOrderBlogButton {
    static defaultProps: Partial<JobOrderBlogButtonProps> = {};

    @LazyImport(nameof<IBlogService>())
    private blogService: IBlogService;

    constructor(private props: JobOrderBlogButtonProps) {}

    private goToBlog(): void {
        if (!this.props.jobOrderId) return;
        this.blogService.openBlogInNewWindow(this.props.jobOrderId);
    }

    render() {
        const jbb = this;

        return ComponentUtils.bindTo(
            <button
                type="button"
                className={classNames("btn btn-primary", { "btn-xs": this.props.extraSmall })}
                title={TextResources.JobOrder.DetailedBlogButtonTooltip}
                data-bind={{
                    enable: !!jbb.props.jobOrderId,
                    click: jbb.goToBlog.bind(jbb),
                }}
            >
                <i className="fa fa-comments"></i>
            </button>,
            this,
            "jbb"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(JobOrderBlogButton);
}
