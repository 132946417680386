import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss";
import * as ko from "knockout";
import { DropdownList } from "../../../Components/DropdownList";
import { Layout } from "../../../Components/Layouts";
import { RichTextInput } from "../../../Components/RichTextInput";
import { TextInput } from "../../../Components/TextInput";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { Feature } from "./Feature";


const styleSheet = jss.createStyleSheet({
    featureCells: {       
        "& > div":{                        
            padding: "5px",
        }
    }
   
}).attach();

const {classes} = styleSheet;

type FeatureDataProps = {
    feature: ko.Observable<Feature>;
}

type FeatureType = {
    id: number,
    descriptionType: string;
}

export function FeatureData(props: FeatureDataProps) {
    const C = require("./FeatureData")._FeatureData as typeof _FeatureData;
    return <C {...props} />;
}

export class _FeatureData {
    static defaultProps: Partial<FeatureDataProps> = {
    }

    featureName: ko.Observable<string> = ko.observable();
    featureId: ko.Observable<string> = ko.observable();
    featureDescription: ko.Observable<string> = ko.observable();
    featureType: ko.Observable<string> = ko.observable();

    typeValues: FeatureType[] = [{ id: 1, descriptionType: "EI" },
    { id: 2, descriptionType: "EO" },
    { id: 3, descriptionType: "EQ" },
    { id: 4, descriptionType: "EIF" },
    { id: 5, descriptionType: "ILF" }];
    operation: ko.Observable<string> = ko.observable();
    operationTypes: FeatureType[] = [{ id: 1, descriptionType: "ADD" },
    { id: 2, descriptionType: "CFP" },
    { id: 3, descriptionType: "CHG" },
    { id: 4, descriptionType: "DEL" }];

    constructor(private props: FeatureDataProps) {

    }

    render() {
        return <Layout.Grid columns={["25%", "25%", "50%"]} rows={["auto", "auto", "auto", "auto", "auto", "auto"]} className={classes.featureCells}>
                    <Layout.Grid.Cell column={1} row={1}>
                        <TextInput label="Codice Requisito" value={this.featureId} className={"flex-1"} />
                    </Layout.Grid.Cell>             
                    <Layout.Grid.Cell column={2} row={1}>
                        <TextInput label="Codice" value={this.featureId} className={"flex-1"} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={"1/3"} row={2}>
                        <TextInput label="Nome" value={this.featureName} className={"flex-1"} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={"1/3"} row={3} >
                        <TextInput label="Tipologia MEV" value={this.featureId} className={"flex-1"} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={4} >
                        <DropdownList values={this.typeValues} value={this.featureType} valueGetter={r => String(r ?.id)} textGetter={r => r.descriptionType} label="BFC"
                            noSelectionPlaceholder="Seleziona il tipo..." className={"flex-1"} />
                    </Layout.Grid.Cell>                
                    <Layout.Grid.Cell column={2} row={4}>
                        <TextInput label="DET" value={this.featureId} className={"flex-1"} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={1} row={5}>
                        <TextInput label="RET/FTR" value={this.featureId} className={"flex-1"} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={2} row={5}>
                        <TextInput label="Complessità" value={this.featureId} className={"flex-1"} readonly />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={3} row={"1/4"} >
                        <RichTextInput height={"80px"} label="Note Varie Fornitore" value={this.featureId} resizable= {true} />
                    </Layout.Grid.Cell>
                    <Layout.Grid.Cell column={3} row={"4/7"} >
                        <RichTextInput height={"80px"} label="Descrizione MEV" value={this.featureDescription} resizable= {true}/>
                    </Layout.Grid.Cell>             
                </Layout.Grid>;
    }
}

if (module.hot) {
    module.hot.accept();
    reloadNow(FeatureData);
}

if(module.hot) {
    module.hot.accept();
    reloadNow(FeatureData);
}