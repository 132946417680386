import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import {
    IProtocolSettingsViewModel,
    IProtocolType,
    IVatRegisterEditingContainer,
} from "../../../ProlifeSdk/interfaces/invoice/IProtocolType";
import { TextResources } from "../../../ProlifeSdk/ProlifeTextResources";
import { Right } from "../../../Core/Authorizations";
import { IVatRegister } from "../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";

export class PassiveInvoiceProtocolType implements IProtocolType {
    public DcoumentTypeId: number = ProlifeSdk.PassiveInvoiceTypeId;
    public DocumentTypeName: string = TextResources.Invoices.PassiveInvoice;
    public DocumentTypeLabelForSettings: string = TextResources.Invoices.PassiveInvoiceProtocols;
    public DocumentTypeShortLabelForSettings: string = TextResources.Invoices.PassiveInvoiceProtocolsShortLabel;

    HasCustomTemplate = true;

    @Right("Documents_PassiveInvoices")
    private canAddProtocolsRight: boolean;

    canAddProtocols(): boolean {
        return this.canAddProtocolsRight;
    }

    createSettingsViewModel(
        container: IVatRegisterEditingContainer,
        vatRegister: IVatRegister
    ): PassiveInvoiceProtocolSettings {
        return new PassiveInvoiceProtocolSettings(container, vatRegister);
    }
}

export class PassiveInvoiceProtocolSettings implements IProtocolSettingsViewModel {
    public templateName = "passive-invoice-protocol-settings";
    public templateUrl = "invoices/templates/settings";

    public CFRequired: ko.Observable<boolean> = ko.observable();
    public PaymentAndExpireModeRequired: ko.Observable<boolean> = ko.observable();
    public DateSequenceRequired: ko.Observable<boolean> = ko.observable();

    constructor(private container: IVatRegisterEditingContainer, private vatRegister: IVatRegister) {
        this.CFRequired(this.vatRegister.CFRequired);
        this.PaymentAndExpireModeRequired(this.vatRegister.PaymentAndExpireModeRequired);
        this.DateSequenceRequired(this.vatRegister.DateSequenceRequired);
    }

    public applyChangesToRegister(vatRegister: IVatRegister) {
        vatRegister.CFRequired = this.CFRequired();
        vatRegister.PaymentAndExpireModeRequired = this.PaymentAndExpireModeRequired();
        vatRegister.DateSequenceRequired = this.DateSequenceRequired();
    }

    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    public async load() {}

    public isChanged(): boolean {
        return (
            this.vatRegister.CFRequired != this.CFRequired() ||
            this.vatRegister.PaymentAndExpireModeRequired != this.PaymentAndExpireModeRequired() ||
            this.vatRegister.DateSequenceRequired != this.DateSequenceRequired()
        );
    }
}
