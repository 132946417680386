import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { reloadNow } from "../../../Core/utils/ComponentUtils";
import { TextInput } from "../../TextInput";
import { ReportComponent } from "../Components";
import { EditorFor } from "./ReportEditorProvider";

type ReportComponentEditorProps = {
    component: ReportComponent;
};

export function ReportComponentEditor(props: ReportComponentEditorProps) {
    const C = require("./ReportComponentEditor")._ReportComponentEditor as typeof _ReportComponentEditor;
    return <C {...props} />;
}

@EditorFor("Component")
export class _ReportComponentEditor {
    static defaultProps: Partial<ReportComponentEditorProps> = {
    }

    constructor(private props : ReportComponentEditorProps) {
        
    }
    
    render() {
        const { component } = this.props;

        return  <div class="editor-group">
                    <span class="editor-group-title">Proprietà</span>
                    <div className="editor-group-body">
                        <TextInput label="Nome" value={component.name} />
                    </div>
                </div>;
    }
}

if(module.hot) {
    module.hot.accept();
    reloadNow(ReportComponentEditor);
}