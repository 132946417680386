import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 04/09/2017
 * Time: 10:25
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { ISurveyWizard, ISurveyWizardSection, ISurveyWizardQuestion } from "../../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { IQuestionListObject } from "../../../../../ProlifeSdk/interfaces/survey/IQuestionListObject";

export class DisableQuestionConfirmDialog implements IDialog {
    public templateName: string = "disable-question-confirm-dialog";
    public templateUrl: string = "surveywizard/templates/wizard/dialogs/";
    public title: string = "";
    public modal: { close: (result?: any) => void; };

    public PromptMessage: ko.Observable<string> = ko.observable();

    constructor(private wizard: ISurveyWizard, private data: IQuestionListObject) {
        if (this.data.IsSection) {
            this.title = ProlifeSdk.TextResources.SurveyWizard.DisableSectionConfirmDialogTitle;
            var message = ProlifeSdk.TextResources.SurveyWizard.DisableSectionPromptMessage;
            this.PromptMessage(message.replace("{section}", (<ISurveyWizardSection>data.Data).PublicLabel()));
        }

        if (!this.data.IsSection) {
            this.title = ProlifeSdk.TextResources.SurveyWizard.DisableQuestionConfirmDialogTitle;
            var message = ProlifeSdk.TextResources.SurveyWizard.DisableQuestionPromptMessage;
            this.PromptMessage(message.replace("{question}", (<ISurveyWizardQuestion>data.Data).Text()));
        }
    }

    close(): void {
        this.modal.close(false);
    }

    action(): void {
        if (this.data.IsSection)
            this.wizard.DoActionForAllQuestions(true);
        this.modal.close(true)
    }
}