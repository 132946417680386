import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 07/02/2018
 * Time: 17:25
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as moment from "moment";
import { GoogleCalendarSyncDialog } from "./GoogleCalendarSyncDialog";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IAgendaService, IAgenda, IDetailedAgenda, IAgendaTimetableViewModel } from "../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { IAgendaViewModel } from "../../../interfaces/IAgenda";
import { Deferred } from "../../../../Core/Deferred";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { AgendaEditingViewModel } from "../AgendaViewModel";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";

export class AgendaEditingDialog implements IDialog {
    public templateName: string = "agenda-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string;

    public AgendaForEditing: ko.Observable<AgendaEditingViewModel> = ko.observable();

    public ShowSaveButton: ko.Computed<boolean>;
    public ShowDeleteButton: ko.Computed<boolean>;
    public ShowRecoveryButton: ko.Computed<boolean>;
    public DisableSaveButton: ko.Computed<boolean>;
    public HasEvents: ko.Computed<boolean>;
    public HasFutureEvents: ko.Computed<boolean>;
    public HasEventsOnWaitingList: ko.Computed<boolean>;

    public CanSyncWithGoogleCalendar : ko.Observable<boolean> = ko.observable(true);

    modal: { close: (result?: any) => void; };

    @LazyImport(nameof<IAgendaService>())
    private agendaService: IAgendaService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;

    constructor(private serviceLocator: IServiceLocator, private agendaId: number, private folderId: number) {
        if (!this.agendaId) {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaEditingDialogTitleForInsert;
            this.AgendaForEditing(this.CreateEmptyAgendaViewModel());
        } else {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaEditingDialogTitleForEdit;
            this.agendaService.GetAgenda(agendaId)
                .then((agenda: IAgenda) => {
                    if (!agenda)
                        return;
                    this.AgendaForEditing(this.CreateAgendaViewModel(agenda));
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetAgendaError);
                });
        }

        this.ShowSaveButton = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;

            return this.AgendaForEditing().Deleted() == ProlifeSdk.NotDeletedState && !this.AgendaForEditing().IsReadOnly();
        });

        this.DisableSaveButton = ko.computed(() => {
            if (!this.AgendaForEditing())
                return true;

            return this.AgendaForEditing().HasTimetablesInEditing();
        });

        this.ShowRecoveryButton = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;

            return this.AgendaForEditing().Deleted() != ProlifeSdk.NotDeletedState && this.authorizationsService.isAuthorized("Agenda_RestoreDeletedAgendas") && !this.AgendaForEditing().IsReadOnly();
        });

        this.ShowDeleteButton = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;
            return !this.ShowRecoveryButton() && !this.AgendaForEditing().IsNew() && !this.AgendaForEditing().IsReadOnly() && this.authorizationsService.isAuthorized("Agenda_DeleteAgendas");
        });

        this.HasEvents = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;

            return this.AgendaForEditing().HasEvents();
        });

        this.HasFutureEvents = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;

            return this.AgendaForEditing().HasFutureEvents();
        });

        this.HasEventsOnWaitingList = ko.computed(() => {
            if (!this.AgendaForEditing())
                return false;

            return this.AgendaForEditing().HasEventsOnWaitingList();
        });
    }

    close(): void {
        const agenda = this.AgendaForEditing();

        if (agenda.HasTimetablesInEditing()) {
            this.dialogsService.Alert(
                ProlifeSdk.TextResources.Agenda.TimetablesInEditingAlert,
                ProlifeSdk.TextResources.Agenda.TimetablesInEditingAlertLabel,
                () => {}
            );

            return;
        }

        if (agenda.HasChanges()) {
            this.ConfirmClosing();
            return;
        }

        this.modal.close(null);
    }

    action(): void {
        this.DataAreValid()
            .then((result: boolean) => {
                if (!result)
                    return;

                this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading, true);

                this.Save()
                    .then((detailedAgenda: IDetailedAgenda) => {
                        if (this.AgendaForEditing().AgendaId() <= 0) {
                            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.InsertAgendaSuccess);
                            this.AgendaForEditing().AgendaId(detailedAgenda.Id);
                        }
                        else
                            this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.ModifyAgendSuccess);

                        this.modal.close(this.AgendaForEditing().GetDetailedData());
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.InsertFailed);
                    })
                    .finally(() => {
                        this.dialogsService.UnlockUI();
                    });
            });
    }

    deleteAgenda(): void {
        this.AgendaForEditing().Delete()
            .then((deleted: boolean) => {
                if (deleted) {
                    this.AgendaForEditing().Deleted(ProlifeSdk.DeletedState);
                    this.modal.close(this.AgendaForEditing().GetDetailedData());
                }
            });
    }

    recovery(): void {
        this.DataAreValid()
            .then((result: boolean) => {
                if (!result)
                    return;
                this.AgendaForEditing().Recovery(true);
                
                this.dialogsService.LockUI(TextResources.ProlifeSdk.Loading, true);

                this.Save()
                    .then((detailedAgenda: IDetailedAgenda) => {
                        this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.RecoverySuccess);
                        this.AgendaForEditing().Deleted(ProlifeSdk.NotDeletedState);
                        this.modal.close(detailedAgenda);
                    })
                    .catch(() => {
                        this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.RecoveryError);
                    }).finally(() => {
                        this.dialogsService.UnlockUI();
                    });
            });
    }

    private ConfirmClosing(): void {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.Agenda.LooseChangesMessage,
            ProlifeSdk.TextResources.Agenda.CancelButton,
            ProlifeSdk.TextResources.Agenda.ConfirmButton,
            (confirm: boolean) => {
                if (confirm)
                    this.modal.close(null);
            }
        );
    }

    private async DataAreValid(): Promise<boolean> {
        const agenda = this.AgendaForEditing();
        let validationResult = false;

        validationResult = agenda.IsValid();
        if (!agenda.TimeslotsModeEnabled())
            return validationResult;

        validationResult = await agenda.HasValidateTimetables();
        return validationResult;
    }

    private Save(): Promise<IDetailedAgenda> {
        var agenda: IDetailedAgenda = this.AgendaForEditing().GetDetailedData();
        agenda.Deleted = ProlifeSdk.NotDeletedState;
        return this.agendaService.CreateOrUpdate(agenda);
    }

    private CreateEmptyAgendaViewModel(): AgendaEditingViewModel {
        var today = moment().startOf("day").toDate();
        var agenda: IAgenda = {
            Id: -1,
            FolderId: this.folderId,
            Name: "",
            Icon: "icon-action-redo",
            Background: "#FFFFFF",
            Foreground: "#000000",
            CreatedBy: -1,
            CreationDate: today,
            ModifiedBy: -1,
            ModifyDate: today,
            TimeslotsModeEnabled: false,
            Deleted: ProlifeSdk.NotDeletedState,
            FestivitiesAgendaId: 1,
            ShowDialogOnDropOnWaitingList: false,
            DaysNumberForBadgeAlert: 0,
            DaysNumberForPlannedEventsAlert: 0,
            CustomersSelectionOnEventsRequired: false,
            CalendarSlotDuration: 15,
            HasSyncErrors: false
        };

        return this.CreateAgendaViewModel(agenda);
    }

    private CreateAgendaViewModel(agenda: IAgenda): AgendaEditingViewModel {
        var newAgenda = new AgendaEditingViewModel(this.serviceLocator, agenda);
        return newAgenda;
    }

    public openGoogleCalendarSync() {
        if(this.agendaId <= 0)
            return;

        var dialog = new GoogleCalendarSyncDialog(this.serviceLocator, this.agendaId);
        dialog.ShowModal();
    }
}