import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 12:27
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../../Core/enumerations/ServiceTypes";
import { NavigationProvider } from "../../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { FestivitiesAgendaViewModel } from "../../FestivitiesAgendaViewModel";
import { IServiceLocator } from "../../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IFestivitiesAgendasService } from "../../../../../ProlifeSdk/interfaces/agenda/IFestivitiesAgendasService";
import { IAgenda } from "../../../../../ProlifeSdk/interfaces/agenda/IAgendaService";
import { Deferred } from "../../../../../Core/Deferred";

export class FestivitiesRootNavigationProvider extends NavigationProvider {
    public SearchPlaceholder: string;
    public Title: ko.Observable<string> = ko.observable();
    public Droppable: ko.Observable<boolean> = ko.observable(false);

    private festivitiesAgendasService: IFestivitiesAgendasService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator) {
        super();

        this.festivitiesAgendasService = <IFestivitiesAgendasService> this.serviceLocator.findService(ProlifeSdk.FestivitiesAgendaServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.Name = ProlifeSdk.TextResources.Agenda.FestivitiesMenu;
        this.AllowMultiLevelSearch = false;
        this.SearchPlaceholder = ProlifeSdk.TextResources.Agenda.SearchPlaceHolder;
        this.Title(this.Name);
    }

    refreshItems(): Promise<IAgenda[]> {
        return this.festivitiesAgendasService.GetAgendas()
            .then((agendas: IAgenda[]) => {
                if (!agendas)
                    return agendas;
                this.Items(agendas.map((a: IAgenda) => { return this.CreateFestivitiesAgendaViewModel(a); }));
                return agendas;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.GetFestivitiesAgendasError);
                return [];
            });
    }

    search(textFilter : string) : Promise<void>
    {
        var deferred = new Deferred<void>();
        var childrenDeferreds : Promise<void> [] = [];

        this.Items().forEach(p =>
        {
            childrenDeferreds.push(p.search(textFilter));
        });

        Promise.all(childrenDeferreds)
            .then(() =>
            {
                var results = [];
                this.Items().forEach((p : FestivitiesAgendaViewModel) =>
                {
                    if (p.Title().indexOf(textFilter)  > -1)
                        results.push(p);
                });

                this.SearchedItems(results);
                deferred.resolve();
            })
            .catch(() => { this.SearchedItems([]); deferred.reject() });

        return deferred.promise();
    }

    private CreateFestivitiesAgendaViewModel(agenda: IAgenda): FestivitiesAgendaViewModel {
        var newAgenda = new FestivitiesAgendaViewModel(this.serviceLocator, agenda);
        newAgenda.setCurrentNavigator(this.navigator());
        newAgenda.refreshItems();
        return newAgenda;
    }
}