import * as ko from "knockout";
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../../Core/DependencyInjection";
import { IDialog } from "../../../../../Core/interfaces/IDialogsService";
import { IFileRepositoryService } from "../../../../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IGenericFileConfigurationsUI } from "../../../../../ProlifeSdk/interfaces/files/IGenericFileConfigurationsUI";
import { ITagHint } from "../../../../../ProlifeSdk/interfaces/files/ITagHint";
import { IVatRegister, IFileTagForProtocol } from "../../../../../ProlifeSdk/interfaces/invoice/settings/IVatRegisters";
import { Deferred } from "../../../../../Core/Deferred";

export class FileRepositorySettingsForProtocolDialog implements IDialog
{
    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService : IFileRepositoryService;

    modal: { close: (result?: any) => void; };
    public templateName : string = "protocol-configurations-popup";
    public templateUrl : string = "invoices/templates/settings";
    public title : string = ProlifeSdk.TextResources.Invoices.FileCreationSettings;
    private ui : IGenericFileConfigurationsUI = null;
    private lastAppliedConfiguration : ITagHint[] = [];
    private someIsChanged : boolean = false;

    constructor(private protocol : IVatRegister)
    {
        this.ui = this.fileRepositoryService.GetGenericFileConfigurationsUI();
        this.ResolveTagsLinks(protocol.DefaultFileTags || []).then((tags : ITagHint[]) => {
            this.lastAppliedConfiguration = tags;
            this.ui.Initialize(tags);
        });
    }

    public SomeIsChanged()
    {
        return this.someIsChanged;
    }

    public GetData() : IFileTagForProtocol[]
{
    var data : IFileTagForProtocol[] = this.lastAppliedConfiguration.map((t : ITagHint) => {
        return <IFileTagForProtocol>{
            LabelId: t.LabelId,
            ProtocolId: this.protocol.IdRegistroIVA
        }
    });
    return data;
}

    private ResolveTagsLinks(tagLinks : IFileTagForProtocol[]): Promise<ITagHint[]>
    {
        var def = new Deferred<ITagHint[]>();
        var defs : Promise<void>[] = [];
        var tags : ITagHint[] = [];

        tagLinks.forEach((l : IFileTagForProtocol) => {
            var tagDef = new Deferred<void>();
            defs.push(tagDef.promise());
            this.fileRepositoryService.GetTagLabelById(l.LabelId).then((t : ITagHint) => {
                tags.push(t);
                tagDef.resolve();
            });
        });

        Promise.all(defs)
            .then(() => {
                def.resolve(tags);
            })
            .catch(() => { def.reject([]); });

        return def.promise();
    }

    action(): void
    {
        this.someIsChanged = true;
        this.lastAppliedConfiguration = this.ui.GetAssignedTags();
        this.modal.close();
    }

    close(): void
    {
        this.ui.Initialize(this.lastAppliedConfiguration);
        this.modal.close();
    }
}