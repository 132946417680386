import * as ko from "knockout";
import { StringFormatters } from "../Desktop/utilities/StringFormatters";

export class TextAreaValue {
	init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
		var underlyingObservable = valueAccessor();
		var interceptor = ko.computed({
			read: function() {
				return StringFormatters.formatTextIntoHtml(underlyingObservable());
			},
			write: function(newValue) {
				underlyingObservable(newValue);
			}
		});

		ko.applyBindingsToNode(element, { html: interceptor }, undefined);
	}
}

ko.bindingHandlers["textAreaValue"] = new TextAreaValue();

