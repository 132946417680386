import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 19/02/2018
 * Time: 19:17
 * To change this template use File | Settings | File Templates.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { FestivityViewModel } from "../FestivityViewModel";
import { PlannedEventsAlertDialog } from "./PlannedEventsAlertDialog";
import * as moment from "moment";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IFestivityViewModel } from "../../../interfaces/IFestivityViewModel";
import { IFestivitiesService, IFestivity } from "../../../../ProlifeSdk/interfaces/agenda/IFestivitiesService";
import { IEventsService, IEventOnDatesIntervalDetails } from "../../../../ProlifeSdk/interfaces/agenda/IEventsService";
import { IFullCalendar } from "../../../../ProlifeSdk/interfaces/desktop/IFullCalendar";

export class FestivityEditingDialog implements IDialog {
    public templateName: string = "festivity-dialog";
    public templateUrl: string = "agenda/templates/dialogs";
    public title: string;

    public FestivityForEditing: ko.Observable<IFestivityViewModel> = ko.observable();

    public ShowDeleteButton: ko.Computed<boolean>;

    modal: { close: (result?: any) => void; };

    private festivitiesService: IFestivitiesService;
    private eventsService: IEventsService;
    private infoToastService: IInfoToastService;

    constructor(private serviceLocator: IServiceLocator, private festivityId: number, private agendaId: number, private calendar: IFullCalendar = null) {
        this.festivitiesService = <IFestivitiesService> this.serviceLocator.findService(ProlifeSdk.FestivitiesServiceCode);
        this.eventsService = <IEventsService> this.serviceLocator.findService(ProlifeSdk.EventsServiceCode);
        this.infoToastService = <IInfoToastService> this.serviceLocator.findService(ServiceTypes.InfoToast);

        if (!this.festivityId) {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaFestivityEditingDialogTitleForInsert;
            this.FestivityForEditing(this.CreateEmptyFestivityViewModel());
        } else {
            this.title = ProlifeSdk.TextResources.Agenda.AgendaFestivityEditingDialogTitleForEdit;
            this.festivitiesService.GetFestivity(this.festivityId)
                .then((festivity: IFestivity) => {
                    if (!festivity)
                        return;
                    this.FestivityForEditing(this.CreateFestivityViewModel(festivity));
                })
                .catch(() => {

                });
        }

        this.ShowDeleteButton = ko.computed(() => {
            if (!this.FestivityForEditing())
                return false;
            return !this.FestivityForEditing().IsNew();
        });
    }

    close(): void {
        this.modal.close(null);
    }

    action(): void {
        if (!this.DataAreValid())
            return;

        if (this.FestivityForEditing().CategoryId() == ProlifeSdk.ClosingDaysCategoryId) {
            this.eventsService.GetEventsOnDatesInterval(null, this.FestivityForEditing().StartDate(), this.FestivityForEditing().EndDate())
                .then((events: IEventOnDatesIntervalDetails[]) => {
                    if (events.length == 0) {
                        this.Save();
                        return;
                    }
                    let message = String.format(ProlifeSdk.TextResources.Agenda.PlannedEventsDialogText, 
                        moment(this.FestivityForEditing().StartDate()).format("DD/MM/YYYY HH:mm"),
                        !this.FestivityForEditing().EndDate() ? ProlifeSdk.TextResources.Agenda.PlannedEventsDialogNullEndDate : moment(this.FestivityForEditing().EndDate()).format("DD/MM/YYYY HH:mm"));
                    let plannedEventsDialog = new PlannedEventsAlertDialog(this.serviceLocator, events, message);
                    plannedEventsDialog.Show();
                })
                .catch(() => {
                    this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.InsertFailed);
                });
            return;
        }

        this.Save();
    }

    deleteFestivity(): void {
        this.FestivityForEditing().Delete()
            .then((deleted: boolean) => {
                if (deleted) {
                    this.FestivityForEditing().Deleted(ProlifeSdk.DeletedState);
                    this.modal.close(this.FestivityForEditing().GetData());
                }
            });
    }

    private DataAreValid(): boolean {
        if (!this.FestivityForEditing().Title()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventTitleRequired);
            return false;
        }

        if (!this.FestivityForEditing().CategoryId()) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.Agenda.EventCategoryRequired);
            return false;
        }

        return true;
    }

    private Save(): void {
        this.festivitiesService.CreateOrUpdate(this.FestivityForEditing().GetData())
            .then((festivityId: number) => {
                if (this.FestivityForEditing().Id() <= 0) {
                    this.FestivityForEditing().Id(festivityId);
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.FestivityInsertSuccess);
                } else {
                    this.infoToastService.Success(ProlifeSdk.TextResources.Agenda.FestivityModifySuccess);
                }
                this.modal.close(this.FestivityForEditing().GetData());
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Agenda.InsertFailed);
            });
    }

    private CreateEmptyFestivityViewModel(): IFestivityViewModel {
        var festivity: IFestivity = {
            id: -1,
            title: '',
            start: null,
            allDay: false,
            end: null,
            color: '#FFFFFF',
            AgendaId: this.agendaId,
            CategoryId: null,
            Note: '',
            Deleted: ProlifeSdk.NotDeletedState,
            CreatedBy: null,
            CreationDate: null,
            ModifiedBy: null,
            ModifyDate: null
        };

        return this.CreateFestivityViewModel(festivity);
    }

    private CreateFestivityViewModel(festivity: IFestivity): IFestivityViewModel {
        return new FestivityViewModel(this.serviceLocator, festivity, this.calendar);
    }

    public ShowDialog() : Promise<IFestivity> {
        var dialogsService = <IDialogsService> this.serviceLocator.findService(ServiceTypes.Dialogs);
        return dialogsService.ShowModal<IFestivity>(this, "large", null, "agenda/templates/dialogs", "festivity-dialog");
    }
}