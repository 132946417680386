import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, ComponentParam, Param, classNames } from "../Core/utils/ComponentUtils";
import { HTMLAttributes } from "@abstraqt-dev/jsxknockout";
import { IBaseInputParams, BaseInput } from "./BaseInputComponent";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import jss from "jss";

//Mapping tra property di Params e nome attributo su html
let attributes = {
    Value: "value",
    Format: "format",
    Label: "label",
    Placeholder: "placeholder",
    HelpText: "helpText",
    ReadOnly: "readOnly",
    MaxLength: "maxLength",
    UpperCase: "upperCase",
    AddonPosition: "addonPosition",
    ValueUpdate: "valueUpdate",
    ShowEditButton: "showEditButton"
};

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "percentage-input" : {
                params?: {
                    Value: string;
                    Format?: string;
                    Label?: string;
                    HelpText?: string;
                    Placeholder?: string;
                    ReadOnly?: string;
                    MaxLength?: string;
                    UpperCase?: string;
                    AddonPosition?: string;
                    ValueUpdate?: string;
                    ShowEditButton?: boolean;
                };

                value?: string | (() => string);
                format?: string | (() => string);
                /**
                 * Label del campo
                 */
                label?: string | (() => string);
                /**
                 * Testo visualizzato sotto l'input
                 */
                helpText?: string | (() => string);
                /**
                 * Placeholder text nell'input
                 */
                placeholder?: string | (() => string);

                readOnly?: boolean | (() => string);

                maxLength?: number | (() => string);

                upperCase?: boolean | (() => string);
                /**
                 * Posizione dell'elemento passato all'input
                 */
                addonPosition?: ("left" | "right") | (() => string);

                valueUpdate?: string | (() => string);
                
                showEditButton?: boolean | (() => string);
            } & HTMLAttributes<HTMLElement>
        }
    }
}

const { classes } = jss.createStyleSheet({
    PercentageInput: {
        "& .disabled-style": {
            cursor: "not-allowed",
            "background-color": "#eeeeee"
        }
    }
}).attach();

export interface IPercentageInputParams extends IBaseInputParams<number> {
    Format?: Param<string>;
}

export class PercentageInputVM extends BaseInput<number> {
    public Format: ko.Observable<string> = ko.observable();

    private defaultFormat: string = "0[.]0[0]%";

    constructor(params : IPercentageInputParams) {
        super(params);

        this.Format = ComponentUtils.parseParameter(params.Format, this.defaultFormat) as ko.Observable;

        this.initialize();
        this.ValueInterceptor.extend({ notify: 'always'});
    }

    protected readValue(): string {
        return this.Value()?.toString() ?? "";
        //return !this.Value() ? "" : numeral(this.Value()).format(this.Format());
    }

    protected writeValue(value : number) {
        this.Value(value);
    }
}

type PercentageInputProps = {
    simple?: boolean;
    className?: string;
    value: ko.Observable<number> | ko.Computed<number>;
    label?: string;
    selectOnFocus?: boolean;
    placeholder?: string;
    readonly?: ko.MaybeObservable<boolean> | ko.MaybeComputed<boolean>;

    onValueChange?: (value: number) => void;
}
export class PercentageInput {
    private vm : PercentageInputVM;

    private valueSubscription: ko.Subscription;

    constructor(private props : PercentageInputProps) {
        this.vm = new PercentageInputVM({
            Label: props.label,
            Placeholder: props.placeholder,
            ReadOnly: props.readonly,
            Value: props.value,
            AddonPosition: null,
            HasFocus: null,
            HelpText: null,
            MaxLength: null,
            SelectOnFocus: null,
            ShowEditButton: null,
            UpperCase: null,
            ValueUpdate: null,
        });
    }

    componentDidMount() {
        if (this.props.onValueChange)
            this.valueSubscription = this.props.value.subscribe((v) => this.props.onValueChange(v));
    }
    
    componentWillUnmount() {
        if (this.valueSubscription)
            this.valueSubscription.dispose();
        
        this.valueSubscription = undefined;
    }

    render() {
        let pi = this.vm;
        let bindings = "percentageValue: pi.ValueInterceptor, format: pi.Format, disable: pi.ReadOnly, valueUpdate: pi.ValueUpdate()";

        if(this.props.selectOnFocus)
            bindings += ", selectOnFocus: {}";

        if(this.props.simple)
            return ComponentUtils.bindTo(<input className="form-control text-right" placeholder={this.props.placeholder} type="text" data-bind={bindings}></input>, this.vm, 'pi');

        return ComponentUtils.bindTo(
                    <div className={classNames("form-group", this.props.className)}>
                        <label className="control-label">{this.props.label}</label>
                        <input className="form-control text-right" type="text" data-bind={bindings}></input>
                    </div>
        , this.vm, 'pi');
    }
}

ko.components.register("percentage-input", {
    viewModel: {
        createViewModel: (params: IPercentageInputParams, componentInfo: ko.components.ComponentInfo) => {
            ComponentUtils.handleAttributes(attributes, params, componentInfo.element);

            let vm = new PercentageInputVM(params);
            let templateFragment = [<div class="form-group">
                                        <ko-if data-bind="Label">
                                            <label class="control-label" data-bind="text: Label"></label>
                                        </ko-if>
                                        <input class="form-control text-right" type="text" data-bind="percentageValue: ValueInterceptor, format: Format, attr: { placeholder: Placeholder, maxlength: MaxLength }, disable: ReadOnly, valueUpdate: $data.ValueUpdate(), selectOnFocus: {}"/>
                                        <ko-if data-bind="HelpText">
                                            <span class="help-block" data-bind="text: HelpText">
                                            </span>
                                        </ko-if>
                                    </div>];

            if(componentInfo.templateNodes.length > 0) {
                templateFragment =  [<div class="form-group">
                                        <ko-if data-bind="Label">
                                            <label class="control-label" data-bind="text: Label"></label>
                                        </ko-if>
                                        <div class="input-group">
                                            <ko-if data-bind="AddonPosition() == 'left'">
                                                <ko-template data-bind="{ nodes: $componentTemplateNodes }"></ko-template>
                                            </ko-if>
                                            <input class="form-control text-right" type="text" data-bind="percentageValue: ValueInterceptor, format: Format, attr: { placeholder: Placeholder, maxlength: MaxLength }, disable: ReadOnly, valueUpdate: $data.ValueUpdate(), selectOnFocus: {}"/>
                                            <ko-if data-bind="AddonPosition() == 'right'">
                                                <ko-template data-bind="{ nodes: $componentTemplateNodes }"></ko-template>
                                            </ko-if>
                                        </div>
                                        <ko-if data-bind="HelpText">
                                            <span class="help-block" data-bind="text: HelpText">
                                            </span>
                                        </ko-if>
                                    </div>];
            }

            if (vm.ShowEditButton()) {
                templateFragment =  [
                    <div class={"form-group " + classes.PercentageInput}>
                        <ko-if data-bind="Label">
                            <label class="control-label" data-bind="text: Label"></label>
                        </ko-if>
                        <div class="input-group">
                            <ko-if data-bind="EditValue">
                                <input class="form-control text-right" type="text" data-bind="percentageValue: ValueInterceptor, format: Format, attr: { placeholder: Placeholder, maxlength: MaxLength }, disable: ReadOnly, valueUpdate: $data.ValueUpdate()"/>
                            </ko-if>
                            <ko-ifnot data-bind="EditValue">
                                <span class="form-control text-right" data-bind="percentageText: ValueInterceptor, format: Format, css: { 'disabled-style': ReadOnly }"/>
                            </ko-ifnot>
                            <span class="input-group-btn">
                                <button class="btn btn-primary active" type="button" data-bind="toggle: EditValue, disable: ReadOnly, visible: !EditValue()" title={TextResources.ProlifeSdk.EnableEditMode}>
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </span>
                        </div>
                        <ko-if data-bind="HelpText">
                            <span class="help-block" data-bind="text: HelpText">
                            </span>
                        </ko-if>
                    </div>
                ];
            }
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, templateFragment);
            
            return vm;
        },
    },
    template: []
});