import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase, IDialogComponentParams } from "../../Core/utils/DialogComponentBase";
import { IDialogsService } from "../../Core/interfaces/IDialogsService";
import { LazyImport } from "../../Core/DependencyInjection";
import { TextResources } from "../ProlifeTextResources";
import { WizardDialogStepBase } from "./WizardDialogStepBase";
import { BindTo } from "../../Components/Bind";

export abstract class WizardDialogBase<T = any> extends DialogComponentBase {
    @LazyImport(nameof<IDialogsService>())
    protected dialogsService: IDialogsService;

    public Steps: ko.ObservableArray<WizardDialogStepBase<T,T>> = ko.observableArray([]);
    public CurrentStep: ko.Observable<WizardDialogStepBase<T,T>> = ko.observable();
    public CanGoBack : ko.Computed<boolean>;

    public IsLastStep: ko.Computed<boolean>;

    protected sourcesSelectionStep: WizardDialogStepBase<T,T>;

    constructor(params: IDialogComponentParams) {
        super(Object.assign({ className: "fullscreen" }, params));

        this.IsLastStep = ko.computed(() => {
            let steps = this.Steps();
            let currentStep = this.CurrentStep();
            return currentStep == steps[steps.length - 1] && currentStep != this.sourcesSelectionStep;
        });

        this.CanGoBack = ko.computed(() => {
            var steps = this.Steps();
            return this.CurrentStep() != steps[0] && steps.length > 0;
        });
    }

    public close(): void {
        this.modal.close();
    }

    public async action(): Promise<void> {
        let currentStep = this.CurrentStep();
        let rows = await Promise.resolve(currentStep.OnNext());
        this.modal.close(rows);
    }

    public async back() : Promise<void>
    {
        if (!this.CanGoBack())
            return;

        let currentStep = this.CurrentStep();
        if (!currentStep.BeforeBack())
            return;

        var currentStepIndex = this.Steps.indexOf(this.CurrentStep());
        var step = this.Steps()[currentStepIndex - 1];
        this.CurrentStep(step);
    }

    public async next() : Promise<void>
    {
        let currentStep = this.CurrentStep();

        let warnings = currentStep.GetWarnings();
        if (warnings.length > 0) {
            let confirm = await this.dialogsService.ConfirmAsync(warnings.join("</br></br>"), TextResources.ProlifeSdk.Abort, TextResources.ProlifeSdk.Confirm);
            if (!confirm)
                return;
        }

        if (!currentStep.CanGoNext())
            return;

        this.dialogsService.LockUI();

        try
        {
            let rows = await Promise.resolve(currentStep.OnNext());

            let currentStepIndex = this.Steps.indexOf(currentStep);
            var nextStep = this.Steps()[currentStepIndex + 1];
            nextStep.OnShow(rows);

            this.CurrentStep(nextStep);
        }
        finally
        {
            this.dialogsService.UnlockUI();
        }
    }

    public async show(): Promise<T> {
        return this.dialogsService.ShowModal<T>(this);
    }

    public SetDataSources(selectedSteps: WizardDialogStepBase<T,T>[]) {
        this.Steps([this.sourcesSelectionStep]);
        this.Steps.push(...selectedSteps);
    }

    public renderBody() {
        let wizard: WizardDialogBase<T>;
        let step: WizardDialogStepBase;
        let $index: () => number;

        return (
            <BindTo viewModel={this} as="wizard">
                <div className="form-wizard" style={{ height : "100%" }}>
                    <div className="form-body" style={{ height : "100%", position : "relative" }}>
                        <ul className="nav nav-pills nav-justified steps" data-bind={{ foreach : { data: wizard.Steps, as: 'step' } }}>
                            <li data-bind={{ css : { active : wizard.CurrentStep() == step } }}>
                                <a href="javascript:void(0)" data-bind={{ click: function() {}, clickBubble: false }} data-toggle="tab" className="step">
                                    <span className="number" data-bind={{ text : $index() + 1 }} style={{ marginRight: "0px" }}>1</span>
                                    <div className="desc">
                                        <i className="fa fa-check"></i>
                                        <ko-text data-bind="step.Title"></ko-text>
                                    </div>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content" style={{ padding: "0px", position: "absolute", left: "0px", right: "0px", bottom: "0px", top: "90px", overflowY: "auto", overflowX : "hidden" }} data-bind={{ tsxtemplate: wizard.CurrentStep }}>
                        </div>

                    </div>
                </div>
            </BindTo>
        );
    }

    renderFooter() {
        let wizard: WizardDialogBase<T>;

        return (
            <BindTo viewModel={this} as="wizard">
                <>
                    <a href="#" className="btn btn-default" data-bind={{ click: wizard.close.bind(wizard) }}>Annulla</a>
                    <a href="#" className="btn default" data-bind={{ click: wizard.back, css: { 'disabled' : !wizard.CanGoBack() } }}>&larr; Indietro</a>
                    <a href="#" className="btn btn-primary" data-bind={{ asyncClick: wizard.next.bind(wizard), visible: !wizard.IsLastStep() }}>Avanti &rarr;</a>
                    <a href="#" className="btn btn-primary" data-bind={{ asyncClick: wizard.action.bind(wizard), visible: wizard.IsLastStep }}>Importa</a>
                </>
            </BindTo>
        );
    }
}