import * as ko from "knockout";
export function Delay(timeout : number = 500) {
    return function _Delay(target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) : void {
        let originalMethod : (...args : any[]) => void = descriptor.value;
        let timers = new WeakMap();

        descriptor.value = function(...args : any[]) {
            let self = this;
            
            if(timers.has(self))
                clearTimeout(timers.get(self));
            let timeoutId = setTimeout(function() {
                originalMethod.apply(self, args);
            }, timeout);

            timers.set(self, timeoutId);
        }
    }
}