import * as ko from "knockout";
import * as ProlifeSdk from "../../../ProlifeSdk/ProlifeSdk";
import moment = require("moment-timezone/moment-timezone");
import { IRequirement, IPreOrderRowWithDetails } from "../../ProvisioningService";

export class RequirementRowSourceBase {
    public get Id(): number {
        return this.requirement?.EntityId;
    }

    public get Type(): string {
        return this.requirement?.EntityType;
    }

    public Selected: ko.Observable<boolean> = ko.observable();

    public Description: ko.Observable<string> = ko.observable();
    public Amount: ko.Observable<number> = ko.observable();
    public UnitPrice: ko.Observable<number> = ko.observable();
    public NetUnitPrice: ko.Observable<number> = ko.observable();
    public NetScaledPrice: ko.Observable<number> = ko.observable();
    public CustomerOrderId: ko.Observable<number> = ko.observable();
    public CustomerOrderRowDeliveryDate: ko.Observable<Date> = ko.observable();
    public CustomerOrderRow: ko.Observable<number> = ko.observable();
    public CustomerOrderRowDescription: ko.Observable<string> = ko.observable();
    public CustomerOrderNumber: ko.Observable<string> = ko.observable();
    public CustomerOrderDate: ko.Observable<Date> = ko.observable();
    public Protocol: ko.Observable<string> = ko.observable();
    public JobOrderName: ko.Observable<string> = ko.observable();
    public SourceWarehouse: ko.Observable<string> = ko.observable();
    public CustomerName: ko.Observable<string> = ko.observable();
    public WarehouseStock: ko.Observable<number> = ko.observable();
    public OthersWarehousesStock: ko.Observable<number> = ko.observable();
    public Requirement: ko.Computed<number>;

    public get EntityCode() { return (this.requirement as IRequirement)?.EntityCode; }
    public get EntityDescription() { return (this.requirement as IRequirement)?.EntityDescription; }
    
    constructor(protected requirement: IRequirement | IPreOrderRowWithDetails) {
        this.Description(this.getDescription(this.requirement));
        this.Amount(this.requirement.Amount);
        this.UnitPrice(this.requirement.UnitPrice);
        this.NetUnitPrice(this.requirement.NetScaledPrice);
        this.NetScaledPrice(this.requirement.NetScaledPrice);
        this.CustomerOrderId(this.requirement.CustomerOrderId);
        this.CustomerOrderRowDeliveryDate(this.requirement.CustomerOrderRowDeliveryDate);
        this.CustomerOrderRow(this.requirement.CustomerOrderRowNumber);
        this.CustomerOrderRowDescription(this.requirement.CustomerOrderRowDescription);
        this.CustomerOrderNumber(this.requirement.CustomerOrderNumber);
        this.CustomerOrderDate(this.requirement.CustomerOrderDate);
        this.Protocol(this.requirement.Protocol);
        this.JobOrderName(this.requirement.JobOrderName);
        this.SourceWarehouse(this.requirement.SourceWarehouse);
        this.CustomerName(this.requirement.CustomerName);
        this.WarehouseStock(this.requirement.StockAmount);
        this.OthersWarehousesStock(this.requirement.OthersWarehousesStock);

        this.Requirement = ko.computed(() => {
            return this.Amount() - this.WarehouseStock();
        });
    }

    public getData(): IRequirement | IPreOrderRowWithDetails {
        return this.requirement;
    }

    protected getDescription(requirement: IRequirement | IPreOrderRowWithDetails): string {
        return String.format(ProlifeSdk.TextResources.Provisioning.RequirementSourceDescription, requirement.CustomerOrderRowNumber, requirement.CustomerOrderNumber, moment(requirement.CustomerOrderDate).format("L"), requirement.Protocol);
    }
}