import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { DialogComponentBase } from "../utils/DialogComponentBase";
import jss from "jss";
import { reloadNow } from "../utils/ComponentUtils";
import { If } from "../../Components/IfIfNotWith";
import { TextInput } from "../../Components/TextInput";
import { CheckBox } from "../../Components/Checkbox";
import { LazyImport } from "../DependencyInjection";
import { IAuthenticationService } from "../interfaces/IAuthenticationService";

const styleSheet = jss.createStyleSheet({
    "login-modal": {
        zIndex: 11001,

        "& > .modal-dialog > .modal-content": {
            width: "360px",
            margin: "0 auto",
            marginBottom: "0px",
            padding: "30px",
            paddingTop: "20px",
            paddingBottom: "15px",

            "& > .modal-header": {
                display: "none",
            },
            "& > .modal-footer": {
                display: "none",
            },
            "& > .modal-body": {
                padding: "0px",
                paddingTop: "25px",
                paddingBottom: "25px",
            },
        },
        "& + .modal-backdrop": {
            backdropFilter: "blur(10px)",
            backgroundColor: "#33333380 !important",
            opacity: 1,
            zIndex: 11000,
        },
    },
});
const { classes } = styleSheet.attach();

type RenewLoginDialogProps = {
    /* No Parameters */
};

export class RenewLoginDialog extends DialogComponentBase {
    private hasErrors = ko.observable(false);
    private hasChangedPassword = ko.observable(false);
    private rememberMe = ko.observable(false);
    private userName = ko.observable("");
    private password = ko.observable("");

    @LazyImport(nameof<IAuthenticationService>())
    private authenticationService: IAuthenticationService;

    static defaultProps: Partial<RenewLoginDialogProps> = {};

    constructor(private props: RenewLoginDialogProps) {
        super({ className: classes["login-modal"] });
    }

    action() {
        this.modal.close();
    }

    private handleLogin = (async () => {
        if (!(await this.authenticationService.refreshSession(this.userName(), this.password()))) {
            this.hasErrors(true);
            return;
        }

        this.hasErrors(false);
        this.hasChangedPassword(false);
        this.modal.close(true);
    }).bind(this);

    private handleEnterKeyDownForLogin = async () => {
        if (this.userName() && this.password()) await this.handleLogin();
    };

    renderHeader() {
        return <></>;
    }

    renderFooter() {
        return <></>;
    }

    renderBody() {
        return (
            <>
                <div className="content">
                    <div className="alert alert-info">
                        La tua sessione sta per scadere, inserisci le tue credenziali per continuare ad utilizzare il
                        sistema.
                    </div>
                    <h3 className="form-title">Accedi con il tuo account</h3>
                    <If condition={this.hasErrors}>
                        {() => (
                            <div className="alert alert-danger">
                                <span>Le credenziali inserite non sono corrette, riprova.</span>
                            </div>
                        )}
                    </If>
                    <If condition={this.hasChangedPassword}>
                        {() => (
                            <div className="alert alert-success">
                                <span>
                                    La password è stata aggiornata correttamente, è ora possibile accedere con la nuova
                                    password.
                                </span>
                            </div>
                        )}
                    </If>
                    <div className="form-group">
                        <div className="input-icon">
                            <i className="fa fa-user"></i>
                            <TextInput
                                id="Username"
                                name="Username"
                                placeholder="Nome utente"
                                simple
                                value={this.userName}
                                onEnterKeyDown={this.handleEnterKeyDownForLogin.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label visible-ie8 visible-ie9">Password</label>
                        <div className="input-icon">
                            <i className="fa fa-lock"></i>
                            <TextInput
                                id="Password"
                                name="Password"
                                placeholder="Password"
                                password
                                simple
                                value={this.password}
                                onEnterKeyDown={this.handleEnterKeyDownForLogin.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <CheckBox checked={this.rememberMe} label="Resta collegato" />
                        <button type="button" className="btn green pull-right" onClick={this.handleLogin.bind(this)}>
                            Accedi&nbsp;
                            <i className="m-icon-swapright m-icon-white"></i>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(RenewLoginDialog);
}
