import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import jss from "jss";
import { ComponentUtils, reloadNow } from "../../../../Core/utils/ComponentUtils";
import { BasePagedList } from "../Utilities/BasePagedList";
import {
    IArticle,
    IArticleWithPosition,
    IArticlesService,
    IArticlesServiceObserver,
} from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { Article } from "./Article";
import { Delay } from "../../../../Decorators/Delay";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { DeleteArticlesResults } from "../../../enums/DeleteArticleResults";
import { DocumentsReferencingArticleDialog } from "./DocumentsReferencingArticleDialog";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { CreateNewArticleDialog } from "./CreateNewArticleDialog";
import { Layout } from "../../../../Components/Layouts";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { ArticleDetailsDialog } from "./ArticleDetailsDialog";

const styleSheet = jss.createStyleSheet({
    "articles-management": {
        position: "absolute",
        top: "120px",
        bottom: "60px",
        left: "20px",
        right: "20px",
        overflowY: "auto",

        "& table.table > thead": {
            "& > tr": {
                position: "sticky",
                top: 0,

                "& > th": {
                    fontSize: "0.9em",
                },
            },
        },
    },

    pagination: {
        position: "absolute",
        bottom: 0,
        left: "20px",

        "& .pagination": {
            "& > li": {
                "& > button": {
                    position: "relative",
                    float: "left",
                    padding: "6px 12px",
                    marginLeft: "-1px",
                    lineHeight: "1.42857143",
                    color: "#428bca",
                    textDecoration: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                },
            },

            "& .active": {
                "& > button": {
                    background: "#eee",
                    borderColor: "#dddddd",
                    color: "#333",
                },
            },
        },
    },
});

const { classes } = styleSheet.attach();

type ArticlesManagementProps = {
    searchFilter: ko.Observable<string>;
    selectedCatalog: ko.Observable<number>;
    forwardRef?: (list: BasePagedList) => void;
};

export function ArticlesManagement(props: ArticlesManagementProps) {
    const C = require("./ArticlesManagement")._ArticlesManagement as typeof _ArticlesManagement;
    return <C {...props} />;
}

export class _ArticlesManagement
    extends BasePagedList<IArticleWithPosition, Article>
    implements IArticlesServiceObserver
{
    static defaultProps: Partial<ArticlesManagementProps> = {};

    @LazyImport(nameof<IArticlesService>())
    private articlesService: IArticlesService;
    @LazyImport(nameof<IAuthorizationService>())
    private authorizationsService: IAuthorizationService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;

    public CanDeleteArticles: ko.Observable<boolean> = ko.observable(false);

    constructor(private props: ArticlesManagementProps) {
        super(props.searchFilter);

        this.CanDeleteArticles(this.authorizationsService.isAuthorized("Warehouse_DeleteWarehouseArticle"));
    }

    componentDidMount() {
        if (this.props.forwardRef) this.props.forwardRef(this);
        this.articlesService.addObserver(this);
        this.subscriptions.push(this.props.selectedCatalog.subscribe(this.internalLoadRows.bind(this)));

        this.loadRows();
    }

    componentWillUnmount() {
        super.dispose();
        this.articlesService.removeObserver(this);
    }

    onArticleChanged(_: IArticle): void {
        this.loadRows();
    }

    onArticleAdded(_: IArticle): void {
        this.loadRows();
    }

    onArticleDeleted(_: number): void {
        this.loadRows();
    }

    @Delay(100)
    private internalLoadRows() {
        this.loadRows();
    }

    public fetchRows(): Promise<IArticleWithPosition[]> {
        return this.articlesService.getArticles(
            this.searchFilter(),
            this.props.selectedCatalog(),
            this.selectedPage() * this.rowsPerPage,
            this.rowsPerPage
        );
    }

    public fetchRowsCount(): Promise<number> {
        return this.articlesService.getArticlesCount(this.searchFilter(), this.props.selectedCatalog());
    }

    public createViewModelFor(article: IArticle): Article {
        return new Article(article);
    }

    public editArticle(article: Article) {
        const modal = new CreateNewArticleDialog();
        modal.loadAndShow(article.getData());
    }

    public async deleteArticle(article: Article): Promise<void> {
        const confirm = await this.dialogService.ConfirmAsync(
            ProlifeSdk.TextResources.Warehouse.DeleteArticleConfirmMessage,
            ProlifeSdk.TextResources.Warehouse.DeleteArticleCancel,
            ProlifeSdk.TextResources.Warehouse.DeleteArticleConfirm
        );

        if (!confirm) return;

        const deleteResult = await this.articlesService.deleteArticle(article.RealArticleId());

        if (deleteResult.Result === DeleteArticlesResults.ERROR_FOR_ARTICLE_USED_IN_DOCUMENTS) {
            const choice = await this.dialogService.ConfirmAsync(
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorMessage,
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorCancel,
                ProlifeSdk.TextResources.Warehouse.DeleteArticleErrorConfirm
            );

            if (!choice) {
                const documentsDialog = new DocumentsReferencingArticleDialog(article.RealArticleId());
                await documentsDialog.show();
            }
        } else {
            if (deleteResult.Result === DeleteArticlesResults.SUCCESS) {
                this.infoToastService.Success(ProlifeSdk.TextResources.Warehouse.DeleteArticleSuccess);
                this.loadRows();
            }
        }
    }

    public ShowArticleDetails(article: Article) {
        const dialog: ArticleDetailsDialog = new ArticleDetailsDialog(
            article.RealArticleId(),
            article.Code(),
            article.Description()
        );
        this.dialogService.ShowModal<void>(dialog, "fullscreen", null, dialog.templateUrl, dialog.templateName);
    }

    render() {
        const am = this;
        const row: Article = null;
        const page = 0;

        return ComponentUtils.bindTo(
            <>
                <Layout.ScrollContainer systemScrollable verticalOnly className={classes["articles-management"]}>
                    <table className="table table-advance">
                        <thead>
                            <tr>
                                <th>{TextResources.Warehouse.ArticleCode}</th>
                                <th>{TextResources.Warehouse.ArticleDescription}</th>
                                <th>{TextResources.Warehouse.ArticleType}</th>
                                <th>{TextResources.Warehouse.ArticleUoM}</th>
                                <th style={{ textAlign: "right" }} className="text-right">
                                    {TextResources.Warehouse.ArticleDealerPrice}
                                </th>
                                <th style={{ textAlign: "right" }} className="text-right">
                                    {TextResources.Warehouse.ArticleCustomerPrice}
                                </th>
                                <th>{TextResources.Warehouse.ArticlePosition}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody data-bind={{ foreach: { data: am.rows, as: "row" } }}>
                            <tr>
                                <td data-bind={{ text: row.Code }}></td>
                                <td data-bind={{ text: row.Description }}></td>
                                <td data-bind={{ text: row.PurchaseType }}></td>
                                <td data-bind={{ text: row.UOM }}></td>
                                <td
                                    style={{ textAlign: "right" }}
                                    data-bind={{ moneyText: row.DealerPrice, extendedMoneyFormat: true }}
                                ></td>
                                <td
                                    style={{ textAlign: "right" }}
                                    data-bind={{ moneyText: row.CustomerPrice, extendedMoneyFormat: true }}
                                ></td>
                                <td data-bind={{ text: row.WarehousePosition }}></td>
                                <td style={{ textAlign: "center" }}>
                                    <i
                                        className="fa"
                                        data-bind={{
                                            css: {
                                                "fa-flag": row.State() == 1,
                                                "fa-warning": row.State() == 3,
                                                "fa-ban": row.State() == 9,
                                            },
                                        }}
                                    ></i>
                                    &nbsp;
                                </td>
                                <td
                                    style={{ textAlign: "right", width: "220px" }}
                                    data-bind={{ style: { width: am.CanDeleteArticles() ? "320px" : "220px" } }}
                                >
                                    <button
                                        style={{ width: "85px", textAlign: "left" }}
                                        className="btn btn-xs default"
                                        data-bind={{ click: am.editArticle.bind(am, row) }}
                                    >
                                        <i className="fa fa-pencil"></i>&nbsp;{TextResources.Warehouse.EditArticle}
                                    </button>
                                    <button
                                        style={{ width: "95px", textAlign: "left" }}
                                        className="btn btn-xs default"
                                        data-bind={{ click: am.ShowArticleDetails.bind(am, row) }}
                                    >
                                        <i className="fa fa-eye"></i>&nbsp;{TextResources.Warehouse.ArticleMovements}
                                    </button>
                                    {this.CanDeleteArticles() && (
                                        <button
                                            style="width : 95px;text-align: left"
                                            className="btn btn-xs btn-danger"
                                            data-bind={{ click: am.deleteArticle.bind(am, row) }}
                                        >
                                            <i className="fa fa-trash-o"></i>&nbsp;
                                            {TextResources.Warehouse.DeleteArticle}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Layout.ScrollContainer>

                <div className={classes.pagination}>
                    <ul className="pagination">
                        <li data-bind={{ css: { disabled: am.isOnFirstPage() || am.SomeFilterIsChanged() } }}>
                            <button data-bind={{ click: am.goToPrevPage }}>
                                &larr; {TextResources.Warehouse.ArticlesPaginationPrev}
                            </button>
                        </li>

                        <ko-bind data-bind={{ foreach: { data: am.pages, as: "page" } }}>
                            <li
                                data-bind={{
                                    css: { disabled: am.SomeFilterIsChanged(), active: am.selectedPage() == page },
                                }}
                            >
                                <button data-bind={{ text: page + 1, click: am.goToPage.bind(am, page) }}></button>
                            </li>
                        </ko-bind>

                        <li data-bind={{ css: { disabled: am.isOnLastPage() || am.SomeFilterIsChanged() } }}>
                            <button data-bind={{ click: am.goToNextPage }}>
                                {TextResources.Warehouse.ArticlesPaginationNext} &rarr;
                            </button>
                        </li>
                    </ul>
                </div>
            </>,
            this,
            "am"
        );
    }
}

if (module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(ArticlesManagement);
}
