import * as ko from "knockout";
import { BaseDataSource } from "./BaseDataSource";
import { LazyImport } from "../Core/DependencyInjection";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";
import { IDocumentsService } from "../Invoices/DocumentsService";
import { IDataSourceModel } from "./IDataSource";
import { IVatRegulationCode } from "../ProlifeSdk/interfaces/invoice/IDocumentsService";

export class VatRegulationsCodeDataSource extends BaseDataSource<IDataSourceModel<string, IVatRegulationCode>> {
    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    
    private natureCode: string = null;

    public getTitle(currentModel: IDataSourceModel<string, IVatRegulationCode>): string {
        return "";
    }
    
    public async getData(currentModel: IDataSourceModel<string, IVatRegulationCode>, textFilter: string, skip: number, count: number): Promise<IDataSourceModel<string, IVatRegulationCode>[]> {
        let codes = await this.documentsService.GetVatRegulations(this.natureCode, textFilter, skip, count);
        return codes.map(this.createModel, this);
    }
    
    public async getById(currentModel: IDataSourceModel<string, IVatRegulationCode>, ids: string[]): Promise<IDataSourceModel<string, IVatRegulationCode>[]> {
        let codes = await this.documentsService.GetVatRegulationsByIds(ids);
        return codes.map(this.createModel, this);
    }

    public setNatureCode(code: string): void {
        this.natureCode = code;
    }

    private createModel(code: IVatRegulationCode): IDataSourceModel<string, IVatRegulationCode> {
        return {
            id: code.Code,
            title: String.format(TextResources.Invoices.VatNatureCodeLabel, code.Code, code.Description),
            isGroup: false,
            isLeaf: true,
            model: code
        };
    }
}