import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import { ComponentUtils, reloadNow } from "../../Core/utils/ComponentUtils";
import { DialogComponentBase } from "../../Core/utils/DialogComponentBase";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type OptionsColumnFilterProps = {
    initialValue: number;
    options: unknown[];
    optionsValue: string;
    optionsText: string;
    placeholder: string;
    onChanged: (value: number) => void;
}

export function OptionsColumnFilter(props: OptionsColumnFilterProps) {
    const C = require("./OptionsColumnFilter")._OptionsColumnFilter as typeof _OptionsColumnFilter;
    return <C {...props} />;
}

export class _OptionsColumnFilter extends DialogComponentBase {
    static defaultProps: Partial<OptionsColumnFilterProps> = {
    }

    Value : ko.Observable<number> = ko.observable();
    valueSubscription: ko.Subscription;

    constructor(private props : OptionsColumnFilterProps) {
        super({ popover: true });
        this.Value(props.initialValue);

        this.valueSubscription = this.Value.subscribe((v) => {
            this.props.onChanged(v);
        });
    }

    componentWillUnmount() {
        this.valueSubscription.dispose();
    }
    
    action() {
        this.modal.close();
    }
    
    renderBody() {
        const _ocf = this;

        return ComponentUtils.bindTo(
            <select class="form-control" data-bind={{ options: _ocf.props.options, value: _ocf.Value, optionsValue: _ocf.props.optionsValue, optionsText: _ocf.props.optionsText, optionsCaption: _ocf.props.placeholder }}></select>
        , this, "_ocf");
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(OptionsColumnFilter);
}