import * as ko from "knockout";
/**
 * Created by m.buonaguidi on 31/05/2017.
 */

import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IDialog } from "../../../../Core/interfaces/IDialogsService";
import { IWarehousesService, IWarehousesWhereArticleIsStocked } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";

export class ArticleWarehousesStockedInDialog implements IDialog {
    title: string = ProlifeSdk.TextResources.Warehouse.WarehousesWhereArticleIsStockedDialogTitle;
    templateName: string = "article-warehouses-stocked-dialog";
    templateUrl: string = "warehouse/templates";
    modal: { close : (result? : any) => void; };

    @LazyImport(ProlifeSdk.WarehousesServiceType)
    private warehousesService: IWarehousesService;
    @LazyImport(nameof<IInfoToastService>())
    private inforToastService: IInfoToastService;

    public warehousesList : ko.ObservableArray<IWarehousesWhereArticleIsStocked> = ko.observableArray([]);
    public emptyResultset: ko.Observable<boolean> = ko.observable(false);

    constructor(articleId : number) {
        this.warehousesService.GetWarehousesWhereArticleIsStocked(articleId)
            .then((warehousesList : IWarehousesWhereArticleIsStocked[]) => {
                if (!warehousesList) {
                    this.emptyResultset(true);
                    return;
                }
                if (warehousesList.length == 0) {
                    this.emptyResultset(true);
                    return;
                }
                this.warehousesList(warehousesList);
                this.emptyResultset(false);
            })
            .catch(() => {
                this.inforToastService.Error(ProlifeSdk.TextResources.Warehouse.ErrorWhileRetrievingWarehousesData);
            });
    }

    action() : void {

    }

    close() : void {
        this.modal.close([]);
    }
}
