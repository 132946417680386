import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import { Param, ComponentParam, ComponentUtils } from "../../Core/utils/ComponentUtils";
import { LazyImport } from "../../Core/DependencyInjection";
import { TextResources } from "../../ProlifeSdk/ProlifeTextResources";
import { IPlannedResource, IWorkflowAllocationInfo, IAllocationsService } from "../../ProlifeSdk/interfaces/allocations/IAllocationsService";


export interface IWorkflowAllocationTeamsParams {
    JobOrderId : Param<number>;
    WorkflowId : Param<number>;
}

export class WorkflowAllocationTeams {
    JobOrderId : ComponentParam<number>;
    WorkflowId : ComponentParam<number>;

    Allocations : ko.ObservableArray<IPlannedResource> = ko.observableArray();
    AllocationInfo: ko.ObservableArray<IWorkflowAllocationInfo> = ko.observableArray();
    Loading: ko.Observable<boolean> = ko.observable();

    @LazyImport(nameof<IAllocationsService>())
    private allocationsService : IAllocationsService;
    
    constructor(params : IWorkflowAllocationTeamsParams) {
        this.JobOrderId = ComponentUtils.parseParameter(params.JobOrderId, -1);
        this.WorkflowId = ComponentUtils.parseParameter(params.WorkflowId, -1);

        this.load();
    }

    private async load() : Promise<void> {
        this.Loading(true);

        let allocations = await this.allocationsService.getPlannedResourcesForWorkflow(this.WorkflowId());
        this.Allocations(allocations);

        let allocationsInfo = await this.allocationsService.getWorkflowAllocationInfo(this.WorkflowId());
        this.AllocationInfo(allocationsInfo);

        this.Loading(false);
    }
}

ko.components.register("workflow-allocation-teams", {
    viewModel: {
        createViewModel: (params: IWorkflowAllocationTeamsParams, componentInfo: ko.components.ComponentInfo) => {
            params = ComponentUtils.parseParameters(params, componentInfo.element);

            let vm = new WorkflowAllocationTeams(params);
            
            ko.virtualElements.setDomNodeChildren(componentInfo.element, [
                <div class="row">
                    <div class="col-md-12">
                        <div data-bind="slimScroll: '257px'" style="width: 900px;">
                            <table class="table table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 20%">{TextResources.Allocations.Team}</th>
                                        <th style="width: 20%">{TextResources.Allocations.Cart}</th>
                                        <th style="width: 20%">{TextResources.Allocations.AllocationStartDate}</th>
                                        <th style="width: 20%">{TextResources.Allocations.RealAllocationEndDate}</th>
                                        <th style="width: 20%">{TextResources.Allocations.TheoreticalAllocationEndDate}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ko-if data-bind="!Loading() && AllocationInfo().length == 0">
                                        <tr>
                                            <td colSpan={5}>
                                                <h5 class="text-center">{TextResources.Allocations.EmptyWorkflowTeamsList}</h5>
                                            </td>
                                        </tr>
                                    </ko-if>
                                    <ko-if data-bind="Loading">
                                        <tr>
                                            <td colSpan={5}>
                                                <h5 class="text-center">
                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                </h5>
                                            </td>
                                        </tr>
                                    </ko-if>
                                    <ko-foreach data-bind="AllocationInfo">
                                        <tr>
                                            <td data-bind="text: TeamName ? TeamName : 'N/A'"></td>
                                            <td data-bind="text: CartName ? CartName : 'N/A'"></td>
                                            <td data-bind="dateText: AllocationStartDate, dateTextNoValue: 'N/A'"></td>
                                            <td data-bind="dateText: RealAllocationEndDate, dateTextNoValue: 'N/A'"></td>
                                            <td data-bind="dateText: TheoreticalAllocationEndDate, dateTextNoValue: 'N/A'"></td>
                                        </tr>
                                    </ko-foreach>
                                </tbody>
                            </table>
                            <label class="control-label">{TextResources.Todolist.PlannedResources}</label>
                            <div class="task-planned-resources" style="width: 50%">
                                <ko-if data-bind="Allocations().length === 0">
                                    <span>{TextResources.Allocations.EmptyPlannedResourcesList}</span>
                                </ko-if>
                                <ko-foreach data-bind="Allocations"> 
                                    <span class="label label-primary" style="display: block; margin-bottom: 3px" data-bind="text: ResourceName"></span>
                                </ko-foreach>
                            </div>
                        </div>
                    </div>
                </div>
            ]);
            
            return vm;
        },
    },
    template: []
});