import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: m.buonaguidi
 * Date: 03/07/2017
 * Time: 20:11
 * To change this template use File | Settings | File Templates.
 */

import { Section } from "./Section";
import { QuestionForWizard } from "./QuestionForWizard";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import { DescriptionPopOver, PopOverData } from "../popover/DescriptionPopOver";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import {
    ISurveyWizardSection,
    ISurveyWizardQuestion,
    ISurveyWizard,
} from "../../../../ProlifeSdk/interfaces/survey-wizard/ISurveyWizardService";
import { ISurveyService } from "../../../../ProlifeSdk/interfaces/survey/ISurveyService";
import { ISection } from "../../../../ProlifeSdk/interfaces/survey/ISection";
import { IQuestion } from "../../../../ProlifeSdk/interfaces/survey/IQuestion";

export class SectionForWizard extends Section implements ISurveyWizardSection {
    public templateName = "section-list-element-for-wizard";
    public templateUrl = "surveywizard/templates/wizard";

    public Questions: ko.ObservableArray<ISurveyWizardQuestion> = ko.observableArray([]);
    public Selected: ko.Observable<boolean> = ko.observable(false);
    private surveyService: ISurveyService;
    private infoToastService: IInfoToastService;

    public PopOverData: PopOverData;
    public PopOverViewModel: DescriptionPopOver;

    public Scroll: ko.Computed<boolean>;
    public DoScroll: ko.Observable<boolean> = ko.observable(false);

    constructor(private serviceLocator: IServiceLocator, section: ISection, private wizard: ISurveyWizard) {
        super(section);
        this.surveyService = <ISurveyService>this.serviceLocator.findService(ProlifeSdk.SurveyServiceType);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);

        this.PopOverData = new PopOverData(this.Description);
        this.PopOverViewModel = new DescriptionPopOver(this.PopOverData);

        this.Scroll = ko.computed(() => {
            return this.DoScroll();
        });
    }

    public LoadQuestions(sectionId: number): Promise<IQuestion[]> {
        return this.surveyService
            .getSectionQuestions(sectionId)
            .then((questions: IQuestion[]) => {
                if (!questions || questions.length == 0) {
                    return [];
                }
                this.Questions(
                    questions.map((question: IQuestion) => {
                        const newQuestion = new QuestionForWizard(this.serviceLocator, question, this.wizard);
                        newQuestion.RegisterSelectionObserver(this.wizard);
                        return newQuestion;
                    })
                );
                return questions;
            })
            .catch(() => {
                this.infoToastService.Error(ProlifeSdk.TextResources.Survey.DownloadQuestionsError);
                return [];
            });
    }
}
