import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";
import moment = require("moment");
import { LazyImport } from "../Core/DependencyInjection";
import {
    DateDataSource,
    IMonthNavigationMenuComponentModel,
    IYearNavigationMenuComponentModel,
} from "../WorkedHours/workedhours/ui2019/navigation/DateDataSource";
import { IDataSourceModel, IDataSourceView } from "./IDataSource";
import { INavigationMenuComponentModel } from "../Components/NavigationMenuComponent/INavigationMenuComponent";
import {
    ITaskBoardPlanningDayPlan,
    ITaskBoardPlanningCartInDayPlan,
    ITodoListService,
} from "../ProlifeSdk/interfaces/todolist/ITodoListService";
import { TextResources } from "../ProlifeSdk/ProlifeTextResources";

export interface ICartPerDayDataSourceModel extends IDataSourceModel<number, ITaskBoardPlanningDayPlan> {
    isDay: true;
    resourceId: number;
    year: number;
    month: number;
    day: number;
    date: Date;
    dayNumber: string;
    isToday: boolean;
    isHoliday: boolean;
}

export interface ICartInDayPlanDataSourceModel
    extends INavigationMenuComponentModel<string, ITaskBoardPlanningCartInDayPlan> {
    isCart: true;
    effectiveDate: Date;
    firstAllocatedDate: Date;
    lastAllocatedDate: Date;
    isPriority: boolean;
    datesDifferenceInDays: number;
    showDatesDifferenceInDays: boolean;
    selectedDate: Date;
    isOnGoing: boolean;
}

export class CartsPerDayDataSource extends DateDataSource {
    @LazyImport(ProlifeSdk.TodoListServiceType)
    private todoListService!: ITodoListService;
    private navigateToMonthRequested: Date = null;
    private standardModeEnabled = true;

    getTitle(currentModel: IDataSourceModel | null): string {
        if (this.standardModeEnabled) {
            const dayModel = currentModel as ICartPerDayDataSourceModel;
            if (dayModel && dayModel.isDay) {
                return moment(dayModel.date).format("ddd LL");
            }

            return super.getTitle(currentModel);
        }

        return moment().format("ddd LL");
    }

    async getData(
        currentModel: INavigationMenuComponentModel,
        textFilter: string,
        skip: number,
        count: number
    ): Promise<IDataSourceModel[]> {
        if (skip > 0) return new Promise<INavigationMenuComponentModel[]>((resolve) => resolve([]));

        const splittedText = (textFilter || "")
            .toLowerCase()
            .split(" ")
            .filter((s) => s != "");

        if (!this.standardModeEnabled)
            return (await this.getCartsData(null)).filter(
                (c) => splittedText.filter((t) => c.title.toLowerCase().indexOf(t) != -1).length == splittedText.length
            );

        const monthModel = currentModel as IMonthNavigationMenuComponentModel;
        if (monthModel && monthModel.isMonth) {
            return await this.getDaysData(monthModel.year, monthModel.month);
        }

        const dayModel = currentModel as ICartPerDayDataSourceModel;
        if (dayModel && dayModel.isDay) {
            return (await this.getCartsData(dayModel.date)).filter(
                (c) => splittedText.filter((t) => c.title.toLowerCase().indexOf(t) != -1).length == splittedText.length
            );
        }

        return await super.getData(currentModel, textFilter, skip, count);
    }

    async getCartsData(date: Date): Promise<IDataSourceModel[]> {
        const carts: ITaskBoardPlanningCartInDayPlan[] = await this.todoListService.GetDayDetailsForResource(
            this.resourceId,
            date
        );
        return carts.map(this.createPlanningCart.bind(this, date));
    }

    createPlanningCart(selectedDate: Date, cart: ITaskBoardPlanningCartInDayPlan): ICartInDayPlanDataSourceModel {
        const model: ICartInDayPlanDataSourceModel = {
            id: cart.CartId + "_" + cart.IsManual,
            title: cart.CartName,
            subTitle: cart.TeamName,
            isLeaf: true,
            isGroup: false,
            icon: {
                icon: "fa " + (cart.IsManual ? "fa-calendar" : "fa-shopping-cart"),
                background: cart.Color,
                foreground: "white",
            },
            model: cart,
            isCart: true,
            effectiveDate: cart.EffectiveDate,
            firstAllocatedDate: cart.FirstAllocatedDate,
            lastAllocatedDate: cart.LastAllocatedDate,
            isPriority: cart.IsPriority,
            datesDifferenceInDays: !cart.WorkEndDate
                ? 0
                : moment(cart.LastAllocatedDate).diff(moment(cart.WorkEndDate), "days"),
            showDatesDifferenceInDays: !!cart.WorkEndDate,
            selectedDate: selectedDate ?? moment().toDate(),
            isOnGoing: moment(cart.EffectiveDate).isSame(moment().startOf("day")),
        };

        if (cart.IsShared) {
            model.secondaryAction = {
                icon: {
                    icon: "fa fa-users",
                    background: cart.Color,
                    foreground: "white",
                },
                action: () => {
                    console.log("Method not implemented.");
                },
                title: TextResources.Allocations.SharedCart,
            };
        }

        if (cart.IsPriority) {
            model.badge = [
                {
                    title: TextResources.Allocations.CartWithPriority,
                    text: "",
                    icon: "fa-arrow-up",
                    cssClass: "badge-danger",
                },
            ];
        }

        return model;
    }

    async getById(currentModel: IDataSourceModel, ids: number[]): Promise<IDataSourceModel[]> {
        const monthModel = currentModel as IMonthNavigationMenuComponentModel;
        if (monthModel && monthModel.isMonth) {
            const monthPlan: ITaskBoardPlanningDayPlan[] = await this.todoListService.GetMonthPlanForResource(
                this.resourceId,
                monthModel.year,
                monthModel.month + 1
            );
            return monthPlan
                .filter((m) => ids.indexOf(m.Id) != -1)
                .map((m) => this.createPlanningDay(m.Id, monthModel.month, monthModel.year, m));
        }

        return await super.getById(currentModel, ids);
    }

    async getDaysData(year: number, month: number): Promise<IDataSourceModel[]> {
        const monthPlan: ITaskBoardPlanningDayPlan[] = await this.todoListService.GetMonthPlanForResource(
            this.resourceId,
            year,
            month + 1
        );

        const daysModels: ICartPerDayDataSourceModel[] = [];
        monthPlan.forEach((p: ITaskBoardPlanningDayPlan) => {
            daysModels.push(this.createPlanningDay(p.Id, month, year, p));
        });

        return daysModels;
    }

    standardMode(value: boolean) {
        this.standardModeEnabled = value;
    }

    protected createPlanningDay(
        day: number,
        month: number,
        year: number,
        dailyPlan: ITaskBoardPlanningDayPlan = null
    ): ICartPerDayDataSourceModel {
        const calendarDate = moment(new Date()).year(year).month(month).date(day).toDate();
        const today = moment();

        const model: ICartPerDayDataSourceModel = {
            id: day,
            resourceId: this.resourceId,
            isDay: true,
            isGroup: false,
            isLeaf: false,
            title: moment().year(year).month(month).date(day).format("dddd"),

            year: year,
            month: month,
            day: day,

            date: calendarDate,
            dayNumber: moment(calendarDate).format("DD"),
            isToday: today.year() == year && today.month() == month && today.date() == day,
            isHoliday: moment(calendarDate).isoWeekday() == 6 || moment(calendarDate).isoWeekday() == 7,

            model: dailyPlan,
        };

        return model;
    }

    public areEqual(a: INavigationMenuComponentModel, b: INavigationMenuComponentModel): boolean {
        if (a === b) return true;
        if (!a || !b) return false;

        const aAsMonth = a as IMonthNavigationMenuComponentModel;
        const bAsMonth = b as IMonthNavigationMenuComponentModel;

        if (aAsMonth.isMonth && bAsMonth.isMonth) {
            return (
                aAsMonth.month == bAsMonth.month &&
                aAsMonth.year == bAsMonth.year &&
                aAsMonth.resourceId == bAsMonth.resourceId
            );
        }

        const aAsYear = a as IYearNavigationMenuComponentModel;
        const bAsYear = b as IYearNavigationMenuComponentModel;

        if (aAsYear.isYear && bAsYear.isYear) {
            return aAsYear.year == bAsYear.year && aAsYear.resourceId == bAsYear.resourceId;
        }

        const aAsDay = a as ICartPerDayDataSourceModel;
        const bAsDay = b as ICartPerDayDataSourceModel;

        if (aAsDay.isDay && bAsDay.isDay) {
            return (
                aAsDay.day == bAsDay.day &&
                aAsDay.month == bAsDay.month &&
                aAsDay.year == bAsDay.year &&
                aAsDay.resourceId == bAsDay.resourceId
            );
        }

        if (a.isLeaf && b.isLeaf) {
            return a.id === b.id;
        }

        return false;
    }

    setView(view: IDataSourceView): void {
        super.setView(view);

        if (this.navigateToMonthRequested) {
            this.navigateToMonth(this.navigateToMonthRequested);
        }
    }

    public async navigateToMonth(startDate: Date): Promise<void> {
        if (!this.view) {
            this.navigateToMonthRequested = startDate;
            return;
        }

        const year = startDate.getFullYear();
        const month = startDate.getMonth();

        const yearModel = this.createYear(year);
        const monthModel = this.createMonth(year, month);

        this.view.navigateTo(yearModel, monthModel);

        this.navigateToMonthRequested = null;
    }
}
