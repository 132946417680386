import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 30/05/2019
 * Time: 16:33
 * To change this template use File | Settings | File Templates.
 */

import { ServiceTypes } from "../../../Core/enumerations/ServiceTypes";
import { IServiceLocator } from "../../../Core/interfaces/IServiceLocator";
import { IAjaxService } from "../../../Core/interfaces/IAjaxService";
import { IEntityCodeGeneratorSettingsManager, IEntityCodeGeneratorConfig } from "../../interfaces/job-order/IEntityCodeGeneratorSettingsManager";
import { IView } from "../../interfaces/IView";

export class EntityCodeGeneratorSettingsManager implements IEntityCodeGeneratorSettingsManager {

    protected ajaxService : IAjaxService;

    constructor(protected serviceLocator : IServiceLocator, protected entityType : string) {
        this.ajaxService = <IAjaxService> serviceLocator.findService(ServiceTypes.Ajax);
    }

    load(): Promise<void> {
        return Promise.resolve<void>(undefined);
    }

    getName():string {
        return undefined;
    }

    getLabel():string {
        return undefined;
    }

    hasEditingUI(): boolean {
        return false;
    }

    getEditingUI():IView {
        return undefined;
    }

    getConfiguration(): Promise<IEntityCodeGeneratorConfig> {
        return this.ajaxService.Post("Core-api/EntityCodeGenerator", "GetConfiguration", {
            methodData: {
                EntityType: this.entityType
            }
        });
    }

    getSequenceNames() : Promise<string[]> {
        return this.ajaxService.Post("Core-api/EntityCodeGenerator", "GetSequenceNames", {
            methodData: {
                EntityType: this.entityType
            },
            background: true
        });
    }

    getSequenceLastValue(sequenceName : string) : Promise<number> {
        return this.ajaxService.Post("Core-api/EntityCodeGenerator", "GetSequenceLastValue", {
            methodData: {
                EntityType: this.entityType,
                SequenceName: sequenceName
            },
            background: true
        });
    }

    saveConfiguration(config : IEntityCodeGeneratorConfig) : Promise<void> {
        return this.ajaxService.Post("Core-api/EntityCodeGenerator", "SaveConfiguration", {
            methodData: {
                EntityType: this.entityType,
                Config: config
            }
        });
    }
}