import * as ko from "knockout";
/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 22/03/2016
 * Time: 14:29
 * To change this template use File | Settings | File Templates.
 */
import * as ProlifeSdk from "../ProlifeSdk/ProlifeSdk";

export class WorkflowStateBinding {
    init(element: any, valueAccessor: () => any, allBindingsAccessor: () => any, viewModel: any, bindingContext: ko.BindingContext): void {
        var underlyingObservable = valueAccessor();

        var interceptor = ko.computed(() => {
            var taskStatus = ko.utils.unwrapObservable(underlyingObservable);
            switch(taskStatus) {
                case 0:
                    return ProlifeSdk.TextResources.Todolist.WorkflowStateNotStarted;
                case 1:
                    return ProlifeSdk.TextResources.Todolist.WorkflowStateInProgress;
                case 2:
                    return ProlifeSdk.TextResources.Todolist.WorkflowStateCompleted;
                case 3:
                    return ProlifeSdk.TextResources.Todolist.WorkflowStateVerified;
                default:
                    return "";
            }
        });

        ko.applyBindingsToNode(element, { text: interceptor }, undefined);
    }
}

ko.bindingHandlers["workflowStateText"] = new WorkflowStateBinding()
ko.virtualElements.allowedBindings["workflowStateText"] = true;