import * as ko from "knockout";
export class MouseInteractions {
    init(element: any, valueAccessor: () => any): void
    {
        var mouseOverHandler = valueAccessor()["mouseOverHandler"];
        var mouseOutHandler = valueAccessor()["mouseOutHandler"];

        if(mouseOverHandler)
            $(element).on("mouseover", mouseOverHandler);

        if(mouseOutHandler)
            $(element).on("mouseout", mouseOutHandler);
    }
}

ko.bindingHandlers['mouseInteraction'] = new MouseInteractions();