import * as ko from "knockout";
import * as React from "@abstraqt-dev/jsxknockout";
import jss from "jss"
import * as ProlifeSdk from "../../../../../ProlifeSdk/ProlifeSdk";
import { ComponentUtils, reloadNow } from "../../../../../Core/utils/ComponentUtils";
import { RouteParams } from "../../../../../Components/Routing";
import { RequirementsImporter } from "../../../dialogs/RequirementsImporter";
import { IPreOrderDetails, IPreOrderRowDetails, PreOrderRowSourceDetails, IPreOrderRowSupplierDetails, IProvisioningService, ISavePreOrderRequestRow } from "../../../../ProvisioningService";
import { LazyImport } from "../../../../../Core/DependencyInjection";
//import { PreOrderRow } from "../../../entities/PreOrder/PreOrderRow";
import { IInfoToastService } from "../../../../../Core/interfaces/IInfoToastService";
import { IDialogsService } from "../../../../../Core/interfaces/IDialogsService";
import { TextResources } from "../../../../../ProlifeSdk/ProlifeTextResources";
import { ITableItem, Table } from "../../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../../Components/TableComponent/CustomColumn";
import { SecondaryRow } from "../../../../../Components/TableComponent/SecondaryRow";
import { IDataSourceModel } from "../../../../../DataSources/IDataSource";
import { CustomerOrdersRowsTableEditor } from "../../../entities/PreOrder/CustomerOrdersRowsTableEditor";
import { PreOrderRowSuppliersEditor, _PreOrderRowSuppliersEditor } from "./PreOrderRowSuppliersEditor";
import { DateTimeInput } from "../../../../../Components/DateTimeInput";
import { AccordionSecondaryRow } from "../AccordionSecondaryRow";
import { ComponentBase } from "../../../../../Components/ComponentBase";
import { IChangesNotificationsService } from "../../../../../ProlifeSdk/interfaces/desktop/IChangesNotificationsService";
import { PreOrderImporterDialog } from "../../../dialogs/PreOrderImporter";
import { IJobOrderService } from "../../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IDocumentsService } from "../../../../../Invoices/DocumentsService";
import moment = require("moment");
import { EntityInfo } from "../ArticleInfo";

const styleSheet = jss.createStyleSheet({
});
const { classes } = styleSheet.attach();

type PreOrderRowsEditorProps = RouteParams<{
    preOrderId: number;
    hasChanges: ko.Observable<boolean>;
    margin: ko.Observable<number>;
    forwardRef?: (e: _PreOrderRowsEditor) => void;
}>;

export function PreOrderRowsEditor(props: PreOrderRowsEditorProps) {
    const C = require("./PreOrderRowsEditor")._PreOrderRowsEditor as typeof _PreOrderRowsEditor;
    return <C {...props} />;
}

export type PreOrderRow = {
    RowId: number;
    PreOrderId: number;
    EntityId: number;
    Type: string;
    Description: string;
    Amount: ko.Observable<number>;
    UoM: string;
    NeededAmount: ko.Computed<number>;
    UnitCost: number;
    Discounts: string;
    TotalCost: number;
    DeliveryDate: ko.Observable<Date>;
    EntityCode: string;

    SuppliersNumber: ko.Observable<number>;
    SuppliersWithAnswerCount: ko.Observable<number>;
    SuppliersList: ko.Observable<string>;
    SuppliersStateColor : ko.Computed<string>;

    Selected: ko.Observable<boolean>;
    DetailsShown: ko.Observable<boolean>;

    Suppliers: IPreOrderRowSupplierDetails[];
    Sources: ko.ObservableArray<PreOrderRowSource>;

    suppliersEditor: _PreOrderRowSuppliersEditor;

    toggleDetails(): void;
}

export type PreOrderRowSource = {
    Selected: ko.Observable<boolean>;
    CustomerOrderRowDeliveryDate: ko.Observable<Date>;
    Margin: ko.Observable<number>;
    DocumentInfo: string;

    MarginWarning: ko.Computed<string>;

    editDeliveryDate() : Promise<unknown>;
    goToDocument() : void;
} & Omit<PreOrderRowSourceDetails, "CustomerOrderRowDeliveryDate" | "Margin">;

export class _PreOrderRowsEditor extends ComponentBase {
    static defaultProps: Partial<PreOrderRowsEditorProps> = {
    }

    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    @LazyImport(nameof<IProvisioningService>())
    private provisioningService: IProvisioningService;

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService : IInfoToastService;

    @LazyImport(nameof<IChangesNotificationsService>())
    private changesNotificationsService : IChangesNotificationsService;

    @LazyImport(nameof<IJobOrderService>())
    private jobOrderService : IJobOrderService;

    @LazyImport(nameof<IDocumentsService>())
    private documentsService : IDocumentsService;

    public Rows: ko.ObservableArray<PreOrderRow> = ko.observableArray([]);
    public SelectAllRows: ko.Computed<boolean>;
    private onPreOrderChangedHandler: number;

    constructor(private props : PreOrderRowsEditorProps) {
        super();
        this.SelectAllRows = ko.computed({
            read: () => {
                return this.Rows().filter(r => r.Selected()).length === this.Rows().length;
            },
            write: (value: boolean) => {
                this.Rows().forEach((r) => r.Selected(value));
            }
        });

        if(this.props.forwardRef)
            this.props.forwardRef(this);
    }

    async componentDidMount() {
        await this.load();

        this.onPreOrderChangedHandler = this.changesNotificationsService.RegisterEventHandler("OnPreOrderChanged", (data: { preOrderId: number }) => {
            if(this.props.preOrderId !== data.preOrderId) return;
            this.load();
        });
    }

    componentWillUnmount() {
        this.changesNotificationsService.UnregisterEventHandler("OnPreOrderChanged", this.onPreOrderChangedHandler);
    }

    getRows() : ISavePreOrderRequestRow[] {
        return this.Rows().map(r => ({
            Id: r.RowId,
            Amount: r.Amount(),
            DeliveryDate: r.DeliveryDate(),
            EntityId: r.EntityId,
            EntityType: r.Type,
            Sources: r.Sources().map(s => ({
                Id: s.Id,
                DocumentType: s.EntityType,
                DocumentId: s.DocumentId,
                DocumentRowId: s.CustomerOrderRowId,
                DocumentRowNumber: s.CustomerOrderRowNumber,
                EntityRefId: s.EntityRefId,
                Amount: s.RowAmount,
                UoM: s.UoM,
                DocumentRowDescription: s.Description,
                EntityRefDescription: s.EntityRefDescription,
                WarehouseId: s.SourceWarehouseId,
                Margin: s.Margin()
            })),
            Suppliers: r.suppliersEditor ? r.suppliersEditor.getSuppliers() : r.Suppliers.map(s => ({
                Id: s.RequestForQuotationRowId,
                SupplierId: s.SupplierId,
                Shipments: s.Shipments.map(s => ({
                    Id: s.Id,
                    IsDefault: s.IsDefault,
                    ShipmentDate: s.ShipmentDate,
                    Amount: s.Amount,
                    WarehouseId: s.Fkwarehouse,
                    Address: s.Address,
                    PostalCode: s.PostalCode,
                    City: s.City,
                    Province: s.Province,
                    State: s.State
                }))
            }))
        }));
    }

    private async load() {
        if (!this.props.preOrderId || this.props.preOrderId <= 0)
            return;
            
        const preOrder = await this.provisioningService.GetCompletePreOrder(this.props.preOrderId);
        this.Rows(this.createRows(preOrder));
    }

    public async oldImportRequirements() {
        const dialog = new RequirementsImporter();
        dialog.show();
    }

    public async importRequirements(): Promise<void> {
        const dialog = new PreOrderImporterDialog({ preOrderId: this.props.preOrderId, preOrderRows: this.getRows() });
        const selectedRows = await dialog.showModal();

        if (!selectedRows || selectedRows.length === 0)
            return;

        for(const row of selectedRows) {
            row.Margin = this.props.margin();
            let rowForEntity = this.Rows().filter(r => r.EntityId === row.SourceEntityId && r.Type === row.SourceEntityType).firstOrDefault();
            if(!rowForEntity) {
                rowForEntity = this.createRow({
                    Amount: row.RowAmount,
                    Discounts: row.SourceDiscounts,
                    EntityDescription: row.EntityRefDescription,
                    EntityId: row.SourceEntityId,
                    EntityType: row.SourceEntityType,
                    EntityCode: row.SourceEntityCode,
                    Id: -1,
                    NeededAmount: row.NeededAmount,
                    PreOrderId: this.props.preOrderId,
                    Sources: [row],
                    StateId: 1,
                    Suppliers: [],
                    SuppliersCount: 0,
                    SuppliersList: "",
                    SuppliersWithAnswerCount: 0,
                    TotalCost: row.NeededAmount * row.SourceNetUnitPrice,
                    UnitCost: row.SourceUnitPrice,
                    UoM: row.UoM,
                    DeliveryDate: row.CustomerOrderRowDeliveryDate
                });
                this.Rows.push(rowForEntity);
            } else {
                rowForEntity.Sources.push(this.createSource(row));
            }
        }
    }

    private createSource(source: PreOrderRowSourceDetails) : PreOrderRowSource {
        const deliveryDate = ko.observable(source.CustomerOrderRowDeliveryDate);
        const margin = ko.observable(source.Margin);

        return {
            ...source,
            Selected: ko.observable(false),
            CustomerOrderRowDeliveryDate: deliveryDate,
            Margin: margin,
            DocumentInfo: `${source.DocumentName} n° ${source.Number} del ${moment(source.DocumentDate).format("L")}`,

            MarginWarning: ko.computed(() => {
                const targetPrice = source.EntityMeanCost * (1 + (margin() / 100));
                if(source.SourceNetUnitPrice < targetPrice)
                    return "danger";
                return "ok";
            }),

            editDeliveryDate: async () => await this.jobOrderService.ShowEditExpireDialog(source.CustomerOrderRowId, ProlifeSdk.CustomerOrderEntityTypeCode, true),
            goToDocument: () => this.documentsService.OpenDocumentOverlayById(source.DocumentId),
        }
    }

    private createRow(row: IPreOrderRowDetails) : PreOrderRow {
        const amount = ko.observable(row.Amount);
        const deliveryDate = ko.observable(row.DeliveryDate);
        const detailsShown = ko.observable(false);
        const sources = ko.observableArray(row.Sources.map(this.createSource, this));
        const neededAmount = ko.computed(() => {
            let totalAmount = 0;
            const groupedByWarehouse = sources().groupBy(s => s.SourceWarehouseId);
            for(const warehouseId in groupedByWarehouse) {
                let stock = Math.max(0, groupedByWarehouse[warehouseId][0].WarehouseStock);
                for(const source of groupedByWarehouse[warehouseId]) {
                    const needed = Math.max(0, source.NeededAmount - stock);
                    stock -= source.NeededAmount - needed;
                    totalAmount += needed;
                }
            }
            return totalAmount;
        });

        this.disposeOnUnmount(amount.subscribe(() => this.props.hasChanges(true)));
        this.disposeOnUnmount(deliveryDate.subscribe(() => this.props.hasChanges(true)));

        return {
            Amount: amount,
            DeliveryDate: deliveryDate,
            Description: row.EntityDescription,
            DetailsShown: detailsShown,
            Discounts: row.Discounts,
            EntityId: row.EntityId,
            EntityCode: row.EntityCode,
            NeededAmount: neededAmount,
            PreOrderId: row.PreOrderId,
            RowId: row.Id,
            Selected: ko.observable(false),
            SuppliersList: ko.observable(row.SuppliersList),
            SuppliersNumber: ko.observable(row.SuppliersCount),
            SuppliersStateColor: ko.computed(() => {
                if(row.SuppliersCount === 0)
                    return "default";
                if(row.SuppliersWithAnswerCount === 0)
                    return "danger";
                if(row.SuppliersWithAnswerCount < row.SuppliersCount)
                    return "warning";
                return "success";
            }),
            SuppliersWithAnswerCount: ko.observable(row.SuppliersWithAnswerCount),
            TotalCost: row.TotalCost,
            UnitCost: row.UnitCost,
            Type: row.EntityType,
            UoM: row.UoM,

            Suppliers: row.Suppliers,
            Sources: sources,

            suppliersEditor: null,

            toggleDetails: () => {
                detailsShown(!detailsShown());
            }
        }
    }

    public createRows(preOrder: IPreOrderDetails): PreOrderRow[] {
        return preOrder.Rows.map(this.createRow, this);
    }

    public async deleteSelectedRows() : Promise<void> {
        const rowsToDelete = this.Rows().filter(r => r.Selected());
        if(rowsToDelete.length == 0)
        {
            this.infoToastService.Error(TextResources.Provisioning.NoPreOrderRowsSelected);
            return;
        }

        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.RemoveSourceRowsFromPreOrderRowMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm);

        if (!confirm)
            return;

        //await this.provisioningService.RemoveRowsFromPreOrder(rowIds);

        for (const row of rowsToDelete)
            this.Rows.remove(row);
    }

    public async removeRow(row: PreOrderRow): Promise<void> {
        const confirm = await this.dialogsService.ConfirmAsync(
            TextResources.Provisioning.RemoveSourceRowFromPreOrderRowMessage,
            TextResources.Provisioning.Abort,
            TextResources.Provisioning.Confirm);

        if (!confirm)
            return;

        //await this.provisioningService.RemoveRowFromPreOrder(row.RowId);
        this.Rows.remove(row);
    }
    
    public createRowModel(row: PreOrderRow): IDataSourceModel<number, PreOrderRow> {
        return {
            id: row.RowId,
            title: "",
            isGroup: false,
            isLeaf: true,
            model: row
        };
    }

    private renderDetails(preOrderRow: IDataSourceModel<string | number, PreOrderRow>) {
        return (
            <td colSpan={8}>
                <div className="with-identifier">
                    <span class="identifier" data-bind={{ style: { 'background-color': (preOrderRow.model.Type === 'WAR' ? '#43b545' : '#56b7f7') }}}></span>
                    <div style={{ width: '19px', borderRight: '1px solid #ddd', alignSelf: 'stretch' }}></div>
                    <div class="flex-1 flex-container no-gutters">
                        <div className="flex-1">
                            <AccordionSecondaryRow>
                                <AccordionSecondaryRow.Section 
                                    label="Fabbisogni" 
                                    title="Apri/Chiudi Fabbisogni" 
                                    element={() => <CustomerOrdersRowsTableEditor preOrderRowId={preOrderRow.model.RowId} sources={preOrderRow.model.Sources} />} />
                                <AccordionSecondaryRow.Section 
                                    label="Fornitori" 
                                    title="Apri/Chiudi Fornitori" 
                                    element={() => <PreOrderRowSuppliersEditor navigator={this.props.navigator} preOrderRowId={preOrderRow.model.RowId} suppliers={preOrderRow.model.Suppliers} hasChanges={this.props.hasChanges} forwardRef={e => preOrderRow.model.suppliersEditor = e} />} />
                            </AccordionSecondaryRow>
                        </div>
                    </div>
                </div>
            </td>
        );
    }

    render() {
        const _re = this;
        const preOrderRow: IDataSourceModel<number, PreOrderRow> = null;
        
        const renderHeaderActions = () => (<>
            <button type="button" class="btn btn-danger btn-sm btn-circle" data-bind={{ asyncClick: _re.deleteSelectedRows.bind(_re) }} title="Elimina righe selezionate" >
                <i class="fa fa-trash-o" />&nbsp;
                Elimina Selezionati
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-circle" style={{ marginLeft: 'auto'}} data-bind={{ asyncClick: _re.importRequirements.bind(_re) }}>
                <i className="fa fa-magic" />&nbsp;
                Importa
            </button>
            {/* <button type="button" class="btn btn-primary btn-sm btn-circle" style={{ marginLeft: 'auto'}} data-bind={{ asyncClick: _re.oldImportRequirements.bind(_re) }}>
                <i className="fa fa-magic" />&nbsp;
                Importa (OLD)
            </button> */}
        </>);

        const { sortString, sortNumber, sortDate } = ComponentUtils.useSorter<PreOrderRow>();
        const dataSource = Table.defaultDataSource(this.Rows, (row) => this.createRowModel(row));

        return  <Table bindTo={{ _re: this }} compact editable striped systemScrollable dataSource={dataSource} rowAs="preOrderRow" headerActions={renderHeaderActions} fixedLayout>
                    <Column style={{ width: '20px' }}>
                        <div class="with-identifier">
                            <span className="identifier" data-bind={{ style: { 'background-color': (preOrderRow.model.Type === 'WAR' ? '#43b545' : '#56b7f7') }}}></span>
                            <span class="btn btn-link btn-xs" title="Apri/Chiudi Dettagli" style={{ minWidth: '18px' }} data-bind={{ click: preOrderRow.model.toggleDetails.bind(preOrderRow.model), tooltip: { placement: 'left' } }}>
                                <i class="fa" data-bind={{ css: { 'fa-angle-right': !preOrderRow.model.DetailsShown(), 'fa-angle-down': preOrderRow.model.DetailsShown }}}></i>
                            </span>
                        </div>
                    </Column>
                    <Column style={{ width: '20px', textAlign: 'center' }}>
                        <ColumnHeader style={{ width: '20px', textAlign: 'right' }}>
                            <input type="checkbox" data-bind={{ checkbox: _re.SelectAllRows }}></input>
                        </ColumnHeader>
                        <ColumnBody>
                            <input type="checkbox" data-bind={{ checkbox: preOrderRow.model.Selected }}></input>
                        </ColumnBody>
                    </Column>
                    <Column title="Articolo/Acquisto" sorter={sortString(r => r.Description)} style={{ width: '300px' }}>
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRow>) => (
                                <div className="flex-container flex-child-center" data-bind={{ click: preOrderRow.model.toggleDetails.bind(preOrderRow.model)}} style={{ cursor: 'pointer' }}>
                                    {/* <div className="text-ellipsis flex-1" data-bind={{ text: preOrderRow.model.Description }}></div> */}
                                    <EntityInfo 
                                        code={item.Data.model.EntityCode} 
                                        description={item.Data.model.Description} 
                                        rowDescription={item.Data.model.Description}
                                        type={item.Data.model.Type} />
                                </div>
                            )}
                        </ColumnBody>
                    </Column>
                    <Column title="Da ordinare" sorter={sortNumber(r => r.Amount())} className="text-right" style={{ width: '100px' }}>
                        <input type="text" class="form-control input-sm text-right" placeholder="Q.tà da ordinare" data-bind={{ numberValue: preOrderRow.model.Amount, selectOnFocus: {} }} />
                    </Column>
                    <Column title="Q.tà richiesta" sorter={sortNumber(r => r.NeededAmount())} className="text-right" style={{ width: '100px' }}>
                        <span data-bind={{ numberText: preOrderRow.model.NeededAmount }}></span>
                    </Column>
                    {/* <Column title="Costo unitario" sorter={sortNumber(r => r.UnitCost)} className="text-right" style={{ width: '100px' }}>
                        <span data-bind={{ moneyText: preOrderRow.model.UnitCost }}></span>
                    </Column>
                    <Column title="Sconto" sorter={sortString(r => r.Discounts)} className="text-right" style={{ width: '150px' }}>
                        <span className="number amount large" data-bind={{ discountText: preOrderRow.model.Discounts }}></span>
                    </Column>
                    <Column title="Costo totale" sorter={sortNumber(r => r.TotalCost)} className="text-right" style={{ width: '100px' }}>
                        <span className="number amount large" data-bind={{ moneyText: preOrderRow.model.TotalCost }}></span>
                    </Column> */}
                    <Column title="Data cons." sorter={sortDate(r => r.DeliveryDate())} className="text-right" style={{ width: '120px' }}>
                        <ColumnBody>
                            {(item: ITableItem<PreOrderRow>) => <DateTimeInput value={item.Data.model.DeliveryDate} placeholder="Consegna il..." simple dateonly allowClear />}
                        </ColumnBody>
                        <input type="text" className="form-control input-sm value" placeholder="Data cons." data-bind={{ nullableDatePicker: preOrderRow.model.DeliveryDate }}></input>
                    </Column>
                    <Column title="Fornitori">
                        <div className="flex-container flex-child-center">
                            <span className="badge badge-sm" title="Fornitori con risposta / Totale fornitori" data-bind={{ css: 'badge-' + preOrderRow.model.SuppliersStateColor() }}>
                                <span data-bind={{ numberText: preOrderRow.model.SuppliersWithAnswerCount, format: '0' }} />/
                                <span data-bind={{ numberText: preOrderRow.model.SuppliersNumber, format: '0' }} />
                            </span>
                            <span className="text-ellipsis" style={{ flex: 1, padding: '0 5px' }} data-bind={{ text: (preOrderRow.model.SuppliersList() ? preOrderRow.model.SuppliersList() : 'Nessun fornitore') }}></span>
                        </div>
                    </Column>
                    <Column style={{ width: '100px', textAlign: 'right' }}>
                        <button type="button" className="btn btn-danger btn-xs btn-circle" title="Rimuovi dal preordine" data-bind={{ click: _re.removeRow.bind(_re, preOrderRow.model), tooltip: { placement: 'left' } }}>
                            <i className="fa fa-trash-o"></i>&nbsp;Elimina
                        </button>
                    </Column>
                    <SecondaryRow visible={() => "preOrderRow.model.DetailsShown"}>
                        {(item: ITableItem<PreOrderRow>) => this.renderDetails(item.Data)}
                    </SecondaryRow>
                </Table>
    }
}

if(module.hot) {
    module.hot.accept();
    module.hot.dispose(() => styleSheet.detach());
    reloadNow(PreOrderRowsEditor);
}