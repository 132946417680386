/**
 * Created with WebStorm.
 * User: d.collantoni
 * Date: 13/09/2016
 * Time: 10:20
 * To change this template use File | Settings | File Templates.
 */

import * as ko from "knockout";
import { ServiceTypes } from "../../../../Core/enumerations/ServiceTypes";
import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import { NavigationMenu } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationMenu";
import { NavigationProvider } from "../../../../ProlifeSdk/prolifesdk/controls/navigation/NavigationProvider";
import { TypesWithCounterNavigationProvider } from "./providers/splash-page-menu-providers/TypesWithCounterNavigationProvider";
import { IJobOrderService } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderService";
import { IServiceLocator } from "../../../../Core/interfaces/IServiceLocator";
import { IAuthorizationService } from "../../../../Core/interfaces/IAuthorizationService";
import { IAuthenticationService } from "../../../../Core/interfaces/IAuthenticationService";
import { IJobOrderTypeWithCounter } from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderType";
import {
    IStateAndTypeMenuObserver,
    INavigationFilters,
    INavigationMultipleFilters,
} from "../../../../ProlifeSdk/interfaces/job-order/IJobOrderNavigationMenu";
import { INavigationMenuProvider } from "../../../../ProlifeSdk/interfaces/navigation-menu/INavigationMenuProvider";

export class RootNavigationProvider extends NavigationProvider {
    constructor(serviceLocator: IServiceLocator) {
        super();
        this.Name = ProlifeSdk.TextResources.JobOrder.Types;
        this.AllowMultiLevelSearch = false;

        const authorizationsService: IAuthorizationService = <IAuthorizationService>(
            serviceLocator.findService(ServiceTypes.Authorization)
        );
        const authenticationService: IAuthenticationService = <IAuthenticationService>(
            serviceLocator.findService(ServiceTypes.Authentication)
        );
        const jobOrdersService: IJobOrderService = <IJobOrderService>(
            serviceLocator.findService(ProlifeSdk.JobOrderServiceType)
        );

        const userId = (<any>authenticationService.getUser()).IdUser;
        const canViewAllJobOrders = authorizationsService.isAuthorized("JobOrders_ViewAllJobOrders");

        jobOrdersService
            .getJobOrderTypesWithCounters(userId, canViewAllJobOrders)
            .then((types: IJobOrderTypeWithCounter[]) => {
                types.forEach((t: IJobOrderTypeWithCounter) => {
                    const navigationProvider = new TypesWithCounterNavigationProvider(serviceLocator, -1, t);
                    navigationProvider.IsLeaf = true;
                    navigationProvider.setCurrentNavigator(this.navigator());

                    this.Items.push(navigationProvider);
                });
            });
    }
}

export class TypeWithCounterSelectionMenu extends NavigationMenu {
    public StopOnTypeLevel: boolean = true;
    public SelectedType: ko.Observable<number> = ko.observable(-1);

    private typeObservers: IStateAndTypeMenuObserver[] = [];

    constructor(serviceLocator: IServiceLocator) {
        super(serviceLocator, new RootNavigationProvider(serviceLocator));
        this.templateUrl = "jobOrder/Templates/navigation";
        this.templateName = "state-and-type-navigator";
        this.setDefaultMenuLocation();
    }

    private setDefaultMenuLocation() {
        this.MenuProvidersStack([this.RootMenuProvider]);
    }

    navigateTo(provider: INavigationMenuProvider) {
        super.navigateTo(provider);
        this.NotifyChangesOnFilters();
    }

    private NotifyChangesOnFilters() {
        this.typeObservers.forEach((o: IStateAndTypeMenuObserver) => {
            o.OnNavigationFilterIsChanged(this.GetFiltersFromActualPosition());
        });
    }

    public GetFiltersFromActualPosition(): INavigationFilters {
        const filters: INavigationFilters = {
            LogicalState: -1,
            Type: -1,
            State: -1,
        };

        this.MenuProvidersStack().forEach((p) => {
            if (p.SetFilterInfoOnModel) p.SetFilterInfoOnModel(filters);
        });

        this.selectedProviders.forEach((p) => {
            if (p.SetFilterInfoOnModel) p.SetFilterInfoOnModel(filters);
        });

        return filters;
    }

    public GetMultipleFiltersFromActualPosition(): INavigationMultipleFilters {
        const filters: INavigationMultipleFilters = {
            LogicalState: -1,
            Types: [],
            States: [],
        };

        this.MenuProvidersStack().forEach((p) => {
            if (p.SetMultipleFilterInfoOnModel) p.SetMultipleFilterInfoOnModel(filters);
        });

        this.selectedProviders.forEach((p) => {
            if (p.SetMultipleFilterInfoOnModel) p.SetMultipleFilterInfoOnModel(filters);
        });

        return filters;
    }

    GoBack() {
        super.GoBack();
        this.clearSelection(false);
        this.NotifyChangesOnFilters();
    }

    selectProvider(provider: INavigationMenuProvider, notifyToObservers: boolean = true) {
        super.selectProvider(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    removeProviderFromSelection(provider: INavigationMenuProvider, notifyToObservers: boolean = true) {
        super.removeProviderFromSelection(provider, notifyToObservers);
        this.NotifyChangesOnFilters();
    }

    RegisterStateAndTypeObserver(o: IStateAndTypeMenuObserver) {
        this.typeObservers.push(o);
    }
}
