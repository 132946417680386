import * as ko from "knockout";
import * as numeral from "numeral";
import * as moment from "moment";
import * as ProlifeSdk from "../../ProlifeSdk/ProlifeSdk";
import { ServiceTypes } from "../../Core/enumerations/ServiceTypes";
import { TagsContextMenu } from "./TagsContextMenu";
import { TagsExplorer } from "./TagsExplorer";
import { ResizeImageDialog } from "./ResizeImageDialog";
import { ImageEditorDialog } from "./ImageEditorDialog";
import { ContextMenuForFileRespositoryDialog } from "./dialog/ContextMenuForFileRespositoryDialog";
import { LazyImport } from "../../Core/DependencyInjection";
import { IException } from "../../Core/interfaces/IException";
import { IServiceLocator } from "../../Core/interfaces/IServiceLocator";
import { IDialog, IDialogsService } from "../../Core/interfaces/IDialogsService";
import { IInfoToastService } from "../../Core/interfaces/IInfoToastService";
import { IProLifeSdkService } from "../../ProlifeSdk/interfaces/prolife-sdk/IProlifeSdkService";
import { IDesktopService } from "../../ProlifeSdk/interfaces/desktop/IDesktopService";
import { IFileRepositoryNavigator } from "../interfaces/IFileRepositoryNavigator";
import { IFileRepositorySearchCriteria } from "../interfaces/IFileRepositorySearchCriteria";
import { ITagHint } from "../../ProlifeSdk/interfaces/files/ITagHint";
import {
    IFileRepositoryPathStep,
    IFileHistory,
    IFileRepositoryService,
} from "../../ProlifeSdk/interfaces/files/IFileRepositoryService";
import { IPermaLink } from "../../ProlifeSdk/interfaces/files/IPermaLink";
import { IFileOrFolder } from "../../ProlifeSdk/interfaces/files/IFileOrFolder";
import { ITagHintEx } from "../interfaces/ITagHintEx";
import { Deferred } from "../../Core/Deferred";
import { IAjaxServiceNew } from "../../Core/interfaces/IAjaxService";

export class FileRepositoryMainViewModel implements IFileRepositoryNavigator, IDialog {
    templateName = "file-repository";
    templateUrl = "fileRepository/templates";
    title: string = ProlifeSdk.TextResources.FileRepository.FileRepository;
    modal: {
        close: (result?: unknown) => void;
    };

    Loading: ko.Observable<boolean> = ko.observable(false);

    Favourites: ko.ObservableArray<FileOrFolder> = ko.observableArray();
    Clipboard: ko.ObservableArray<FileOrFolder> = ko.observableArray();
    SearchCriteria: ko.ObservableArray<IFileRepositorySearchCriteria> = ko.observableArray();
    MostUsedTags: ko.ObservableArray<ITagHint> = ko.observableArray();

    AssignedTags: ko.Computed<ITagHint[]>;

    CurrentPathSteps: ko.ObservableArray<IFileRepositoryPathStep> = ko.observableArray();
    FullPathToCurrentDirectory: ko.Observable<string> = ko.observable("Home");

    FilesOrFolders: ko.Computed<FileOrFolder[]>;
    CurrentDirectoryContents: ko.ObservableArray<FileOrFolder> = ko.observableArray();
    PendingUploads: ko.ObservableArray<UploadingFileOrFolder> = ko.observableArray();
    ViewDetails: ko.Observable<boolean> = ko.observable(false);
    ViewIcons: ko.Observable<boolean> = ko.observable(true);
    ViewTiles: ko.Observable<boolean> = ko.observable(false);
    ClipboardDetails: ko.Observable<boolean> = ko.observable(false);
    ClipboardBig: ko.Observable<boolean> = ko.observable(false);
    SelectMode: ko.Observable<boolean> = ko.observable(false);

    SelectedFiles: ko.Computed<FileOrFolder[]>;
    SelectedFilesSize: ko.Computed<string>;
    ClipboardSelectedFiled: ko.Computed<FileOrFolder[]>;
    HasSelectedFiles: ko.Computed<boolean>;
    HasClipboardSelectedFiles: ko.Computed<boolean>;
    CanDelete: ko.Computed<boolean>;
    CanRename: ko.Computed<boolean>;

    IsHistoryLoading: ko.Observable<boolean> = ko.observable(false);

    SelectedFileShares: ko.ObservableArray<IPermaLink> = ko.observableArray();
    SelectedFileHistory: ko.ObservableArray<
        IFileHistory & { MostRecentPublished: boolean; download: () => Promise<void> }
    > = ko.observableArray();

    TotalSpace: ko.Observable<number> = ko.observable(0);
    UsedSpace: ko.Observable<number> = ko.observable(0);

    private currentPath: string;
    private fromDate: Date;
    private toDate: Date;
    private name: string;
    private extension: string;
    private modifiedBy: number;
    private contentType: string;
    private contents: string;
    private tags: number[] = [];
    private shared = false;

    private fileRepository: IFileRepositoryService;
    private dialogsService: IDialogsService;
    private infoToastService: IInfoToastService;
    private sdkService: IProLifeSdkService;

    @LazyImport(nameof<IDesktopService>())
    private desktopService: IDesktopService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    private TagsContextMenu: TagsContextMenu;
    private TagsExplorer: TagsExplorer;

    constructor(private serviceLocator: IServiceLocator, public isDialog: boolean = false) {
        this.TagsExplorer = new TagsExplorer(serviceLocator, this);
        this.fileRepository = <IFileRepositoryService>(
            this.serviceLocator.findService(ProlifeSdk.FileRepositoryServiceType)
        );
        this.sdkService = <IProLifeSdkService>this.serviceLocator.findService(ProlifeSdk.ProlifeSdkServiceType);
        this.dialogsService = <IDialogsService>this.serviceLocator.findService(ServiceTypes.Dialogs);
        this.infoToastService = <IInfoToastService>this.serviceLocator.findService(ServiceTypes.InfoToast);
        this.currentPath = "/";
        this.CurrentPathSteps([{ Name: "Home", Path: "/" }]);
        this.SelectMode(isDialog);

        if (localStorage.getItem("ProLife_FileRepository_ViewDetails")) {
            this.ViewDetails(true);
            this.ViewIcons(false);
            this.ViewTiles(false);
        }
        if (localStorage.getItem("ProLife_FileRepository_ViewIcons")) {
            this.ViewIcons(true);
            this.ViewDetails(false);
            this.ViewTiles(false);
        }
        if (localStorage.getItem("ProLife_FileRepository_ViewTiles")) {
            this.ViewTiles(true);
            this.ViewIcons(false);
            this.ViewDetails(false);
        }

        this.ViewDetails.subscribe(() => {
            if (this.ViewDetails()) {
                localStorage.setItem("ProLife_FileRepository_ViewDetails", "1");
                this.ViewIcons(false);
                this.ViewTiles(false);
            } else localStorage.removeItem("ProLife_FileRepository_ViewDetails");
        });
        this.ViewIcons.subscribe(() => {
            if (this.ViewIcons()) {
                localStorage.setItem("ProLife_FileRepository_ViewIcons", "1");
                this.ViewDetails(false);
                this.ViewTiles(false);
            } else localStorage.removeItem("ProLife_FileRepository_ViewIcons");
        });
        this.ViewTiles.subscribe(() => {
            if (this.ViewTiles()) {
                localStorage.setItem("ProLife_FileRepository_ViewTiles", "1");
                this.ViewDetails(false);
                this.ViewIcons(false);
            } else localStorage.removeItem("ProLife_FileRepository_ViewTiles");
        });

        this.FilesOrFolders = ko.computed(() => {
            return this.CurrentDirectoryContents().concat(
                this.PendingUploads().filter((u: UploadingFileOrFolder) => u.Path() == this.currentPath)
            );
        });

        this.SelectMode.subscribe((selectMode) => {
            if (this.isDialog) {
                this.SelectMode(true);
                return;
            }

            if (!selectMode) {
                this.deselectAll();
            }
        });

        this.SelectedFiles = ko.computed(() => {
            return this.CurrentDirectoryContents().filter((f: FileOrFolder) => f.Selected());
        });

        ko.computed(() => {
            this.SelectedFileShares([]);
            this.SelectedFileHistory([]);
            if (this.SelectedFiles().length == 1) {
                const file = this.SelectedFiles()[0];

                if (!file.IsFolder()) {
                    this.IsHistoryLoading(true);

                    this.fileRepository
                        .GetFileHistory(file.Id)
                        .then((history: IFileHistory[]) => {
                            let firstPublishedFound = false;

                            this.SelectedFileHistory(
                                history.map((h) => {
                                    const newHistory = {
                                        ...h,
                                        MostRecentPublished: h.Published && !firstPublishedFound,
                                        download: () =>
                                            this.ajaxServiceNew.DownloadFileFromUrl(
                                                this.fileRepository.GetVersionDownloadUrl(h.FileId),
                                                { overrideMethod: "GET" }
                                            ),
                                    };

                                    if (newHistory.MostRecentPublished) firstPublishedFound = true;

                                    return newHistory;
                                })
                            );
                        })
                        .finally(() => {
                            this.IsHistoryLoading(false);
                        });
                }

                if (!file.HasPermalinks()) return;

                this.fileRepository.getPermalinksForFile(file.Id).then((shares) => this.SelectedFileShares(shares));
            }
        });

        ko.computed(() => {
            if (this.Loading()) this.desktopService.BlockPageUI(ProlifeSdk.TextResources.FileRepository.Loading);
            else this.desktopService.UnblockPageUI();
        });

        this.SelectedFilesSize = ko.computed(() => {
            return numeral(this.SelectedFiles().reduce((total, file) => total + file.NumericSize(), 0)).format(
                "0,0[.]0 b"
            );
        });

        this.ClipboardSelectedFiled = ko.computed(() => {
            return this.Clipboard().filter((f: FileOrFolder) => f.Selected());
        });

        this.HasSelectedFiles = ko.computed(() => {
            return this.SelectedFiles().length > 0;
        });

        this.HasClipboardSelectedFiles = ko.computed(() => {
            return this.ClipboardSelectedFiled().length > 0;
        });

        this.CanDelete = ko.computed(() => {
            return (
                this.HasSelectedFiles() && this.SelectedFiles().filter((f: FileOrFolder) => f.IsReserved()).length == 0
            );
        });

        this.CanRename = ko.computed(() => {
            return this.SelectedFiles().length == 1 && !this.SelectedFiles()[0].IsReserved();
        });

        this.AssignedTags = ko.computed(() => {
            const selectedFiles = this.SelectedFiles().filter((f: FileOrFolder) => !f.IsFolder());
            if (selectedFiles.length == 0) return [];

            if (selectedFiles.length == 1) return selectedFiles[0].Tags() || [];

            const selectedFilesTags = selectedFiles.map((f: FileOrFolder) => f.Tags() || []);
            let allTags = selectedFilesTags[0];
            for (let i = 1; i < selectedFilesTags.length; i++) {
                const fileTags = selectedFilesTags[i];
                allTags = fileTags.filter((t) => allTags.filter((at) => at.LabelId == t.LabelId).length > 0);
            }

            return allTags;
        });

        this.loadFavourites();
        this.TagsContextMenu = new TagsContextMenu(serviceLocator, this.doReload.bind(this), this.doReload.bind(this));
    }

    private loadFavourites() {
        this.fileRepository
            .getFavouriteFolders()
            .then((favourites: IFileOrFolder[]) =>
                this.Favourites(favourites.map(this.createViewModelFor.bind(this, [])))
            );
    }

    public dispose() {
        //Ok così
    }

    public assignTag(tag: ITagHintEx) {
        this.Loading(true);
        if (tag.IsCreateNew) {
            this.fileRepository
                .createNewTag(tag.Value, 0)
                .then((newTag: ITagHint) => this.assignTagToSelectedFiles(newTag.LabelId));
        } else {
            this.assignTagToSelectedFiles(tag.LabelId);
        }
    }

    private assignTagToSelectedFiles(tagId: number) {
        const selectedFileIds: string[] = <string[]>this.SelectedFiles()
            .filter((f: FileOrFolder) => !f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);

        this.fileRepository.assignTagToFiles(tagId, selectedFileIds).then(() => this.doReload());
    }

    public removeTag(tag: ITagHintEx) {
        const selectedFileIds: string[] = <string[]>this.SelectedFiles()
            .filter((f: FileOrFolder) => !f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);

        this.fileRepository.removeTagFromFiles(tag.LabelId, selectedFileIds).then(() => this.doReload());
    }

    public appendSearchCriteria(newSearchCriteria: IFileRepositorySearchCriteria) {
        switch (newSearchCriteria.Type) {
            case "name":
                this.name = newSearchCriteria.Value;
                break;
            case "contents":
                this.contents = newSearchCriteria.Value;
                break;
            case "contentType":
                this.contentType = newSearchCriteria.Value;
                break;
            case "extension":
                this.extension = newSearchCriteria.Value;
                break;
            case "fromDate":
                this.fromDate = moment(newSearchCriteria.Value, "DD/MM/YYYY").startOf("day").toDate();
                break;
            case "toDate":
                this.toDate = moment(newSearchCriteria.Value, "DD/MM/YYYY").endOf("day").toDate();
                break;
            case "tag":
                this.tags.push(newSearchCriteria.TagId);
                break;
            case "shared":
                this.shared = true;
                break;
        }

        this.UpdateSearchCriteria();
        this.reload();
    }

    public removeSearchCriteria(searchCriteria: IFileRepositorySearchCriteria) {
        switch (searchCriteria.Type) {
            case "name":
                this.name = undefined;
                break;
            case "contents":
                this.contents = undefined;
                break;
            case "contentType":
                this.contentType = undefined;
                break;
            case "extension":
                this.extension = undefined;
                break;
            case "fromDate":
                this.fromDate = undefined;
                break;
            case "toDate":
                this.toDate = undefined;
                break;
            case "tag":
                {
                    const index = this.tags.indexOf(searchCriteria.TagId);
                    this.tags.splice(index, 1);
                }
                break;
            case "shared":
                this.shared = false;
                break;
        }

        this.UpdateSearchCriteria();
        this.reload();
    }

    public clearSearchCriteria(autoReload = true) {
        this.name = undefined;
        this.contents = undefined;
        this.contentType = undefined;
        this.fromDate = undefined;
        this.toDate = undefined;
        this.tags = [];
        this.shared = false;

        if (autoReload) this.reload();
    }

    private UpdateSearchCriteria() {
        const criteria = [];

        this.name &&
            criteria.push({
                Type: "name",
                Label: ProlifeSdk.TextResources.FileRepository.Name,
                Value: this.name,
                Display: ProlifeSdk.TextResources.FileRepository.Name + ": " + this.name,
            });
        this.contents &&
            criteria.push({
                Type: "contents",
                Label: ProlifeSdk.TextResources.FileRepository.Contains,
                Value: this.contents,
                Display: ProlifeSdk.TextResources.FileRepository.Contains + ": " + this.contents,
            });
        this.contentType &&
            criteria.push({
                Type: "contentType",
                Label: ProlifeSdk.TextResources.FileRepository.Type,
                Value: this.contentType,
                Display: ProlifeSdk.TextResources.FileRepository.Type + ": " + this.contentType,
            });
        this.fromDate &&
            criteria.push({
                Type: "fromDate",
                Label: ProlifeSdk.TextResources.FileRepository.ModifiedFrom,
                Value: moment(this.fromDate).format("L"),
                Display:
                    ProlifeSdk.TextResources.FileRepository.ModifiedFrom + ": " + moment(this.fromDate).format("L"),
            });
        this.toDate &&
            criteria.push({
                Type: "toDate",
                Label: ProlifeSdk.TextResources.FileRepository.ModifiedTo,
                Value: moment(this.toDate).format("L"),
                Display: ProlifeSdk.TextResources.FileRepository.ModifiedTo + ": " + moment(this.toDate).format("L"),
            });
        this.extension &&
            criteria.push({
                Type: "extension",
                Label: ProlifeSdk.TextResources.FileRepository.Extension,
                Value: this.extension,
                Display: ProlifeSdk.TextResources.FileRepository.Extension + ": " + this.extension,
            });
        this.shared &&
            criteria.push({
                Type: "shared",
                Label: ProlifeSdk.TextResources.FileRepository.Shared,
                Value: ProlifeSdk.TextResources.FileRepository.SharedYes,
                Display: ProlifeSdk.TextResources.FileRepository.SharedYesDisplay,
            });

        if (this.tags.length > 0) {
            this.fileRepository.resolveTagNames(this.tags).then((tags: ITagHint[]) => {
                criteria.push(
                    ...tags.map((t: ITagHint) => ({
                        Type: "tag",
                        Label: ProlifeSdk.TextResources.FileRepository.Tag,
                        Value: t.Name,
                        Display: ProlifeSdk.TextResources.FileRepository.Tag + ": " + t.Name,
                    }))
                );
                this.SearchCriteria(criteria);
            });
            return;
        }

        this.SearchCriteria(criteria);
    }

    public onSearchInputFocus(focused: boolean) {
        const $search = $(".search");
        //let $searchInput = $('.search li input[type="search"]');
        //let $queries = $('.queries');

        if (focused) {
            $search.addClass("active");
            //$queries.scrollLeft($queries[0].scrollWidth);
            //$searchInput.width($queries.width());
        } else {
            $search.removeClass("active");
            //$queries.scrollLeft($queries[0].scrollWidth);
            //$searchInput.width(150);
            //$searchInput.val('');
        }
    }

    public goHome() {
        if (this.isDialog) return;
        location.href = "#/";
    }

    public goBack() {
        if (this.isDialog) return;
        history.back();
    }

    public goForward() {
        if (this.isDialog) return;
        history.forward();
    }

    public navigateTo(
        path: string,
        fromDate?: Date,
        toDate?: Date,
        name?: string,
        extension?: string,
        modifiedBy?: number,
        contentType?: string,
        contents?: string,
        tags?: number[],
        shared?: boolean
    ) {
        this.TagsExplorer.Hide();
        this.currentPath = path || "/";
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.name = name;
        this.extension = extension;
        this.modifiedBy = modifiedBy;
        this.contentType = contentType;
        this.contents = contents;
        this.tags = tags || [];
        this.shared = shared;

        const allPaths = [{ Name: "Home", Path: "/" }];
        const folders = path.split("/").filter((s) => s);
        folders.forEach((f: string, i: number) => {
            allPaths.push({
                Name: f,
                Path: "/" + folders.slice(0, i + 1).join("/") + "/",
            });
        });

        this.CurrentPathSteps(allPaths);
        this.FullPathToCurrentDirectory("Home" + path);

        this.UpdateSearchCriteria();
        this.doReload();
    }

    private reload() {
        if (this.isDialog) {
            this.navigateTo(
                this.currentPath,
                this.fromDate,
                this.toDate,
                this.name,
                this.extension,
                this.modifiedBy,
                this.contentType,
                this.contents,
                this.tags,
                this.shared
            );
        } else {
            const filters = [];

            this.fromDate && filters.push("fromDate=" + moment(this.fromDate).toJSON());
            this.toDate && filters.push("toDate=" + moment(this.toDate).toJSON());
            this.name && filters.push("name=" + encodeURI(this.name));
            this.extension && filters.push("extension=" + encodeURI(this.extension));
            this.modifiedBy && filters.push("modifiedBy=" + this.modifiedBy);
            this.contentType && filters.push("contentType=" + encodeURI(this.contentType));
            this.contents && filters.push("contents=" + encodeURI(this.contents));
            this.tags.length > 0 && filters.push("tags=[" + this.tags.join(",") + "]");
            this.shared && filters.push("shared=true");

            let queryString = "";
            if (filters.length > 0) {
                queryString = "?" + filters.join("&");
            }

            location.href = "#/FileRepository/" + this.currentPath + queryString;
        }
    }

    public breadCrumbClick(newPath: string) {
        if (this.isDialog) {
            this.navigateTo(newPath);
        } else {
            location.href = "#/FileRepository" + newPath;
        }
    }

    public navigateHome() {
        if (this.isDialog) {
            this.navigateTo("/");
        } else {
            location.href = "#/FileRepository";
        }
    }

    public getCurrentPath(): string {
        return this.currentPath;
    }

    public uploadCompleted(uploadedFile: UploadingFileOrFolder) {
        this.PendingUploads.remove(uploadedFile);
        this.infoToastService.Success(
            this.sdkService.Utilities.String.Format(ProlifeSdk.TextResources.FileRepository.UploadSuccess, [
                uploadedFile.Name(),
            ])
        );

        if (uploadedFile.Path() == this.currentPath) this.doReload();
    }

    public uploadFailed(uploadedFile: UploadingFileOrFolder) {
        this.PendingUploads.remove(uploadedFile);
        this.infoToastService.Warning(
            this.sdkService.Utilities.String.Format(ProlifeSdk.TextResources.FileRepository.ErrorDuringUpload, [
                uploadedFile.Name(),
            ])
        );

        if (uploadedFile.Path() == this.currentPath) {
            this.doReload();
        }
    }

    public isInSelectMode(): boolean {
        return this.SelectMode();
    }

    public deselectAll() {
        this.CurrentDirectoryContents().forEach((f: FileOrFolder) => f.Selected(false));
    }

    public deselectAllClipboard() {
        this.Clipboard().forEach((f: FileOrFolder) => f.Selected(false));
    }

    public copyInClipboard() {
        if (!this.HasSelectedFiles()) return;

        const filesToAppend = this.SelectedFiles()
            .filter(
                (f: FileOrFolder) =>
                    this.Clipboard().filter((ff: FileOrFolder) => ff.getFileOrFolder().Id == f.getFileOrFolder().Id)
                        .length == 0
            )
            .map((f: FileOrFolder) => this.createViewModelFor([], f.getFileOrFolder()));

        this.Clipboard.push(...filesToAppend);
    }

    public copyAllFromClipboardHere() {
        const selectedFiles = !this.HasClipboardSelectedFiles() ? this.Clipboard() : this.ClipboardSelectedFiled();
        const folderIds = selectedFiles
            .filter((f: FileOrFolder) => f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);
        const fileIds = selectedFiles
            .filter((f: FileOrFolder) => !f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);

        this.fileRepository
            .canCopy(folderIds, fileIds)
            .then(() => this.doCopy(folderIds, fileIds))
            .catch(() => {
                this.infoToastService.Warning(
                    ProlifeSdk.TextResources.FileRepository.FileNotCopiedForInsufficientSpace
                );
            });
    }

    private doCopy(folderIds, fileIds) {
        Promise.all([
            this.fileRepository.copyFolders(folderIds, this.currentPath),
            this.fileRepository.copyFiles(fileIds, this.currentPath),
        ])
            .catch((exception: IException) => {
                this.infoToastService.Warning(
                    this.sdkService.Utilities.String.Format(
                        ProlifeSdk.TextResources.FileRepository.ErrorDuringFileCopy,
                        [exception.ExceptionMessage]
                    )
                );
            })
            .then(() => {
                this.infoToastService.Success(ProlifeSdk.TextResources.FileRepository.CopySuccess);
            })
            .finally(() => this.doReload());
    }

    public moveAllFromClipboardHere() {
        const selectedFiles = !this.HasClipboardSelectedFiles() ? this.Clipboard() : this.ClipboardSelectedFiled();
        const folderIds = selectedFiles
            .filter((f: FileOrFolder) => f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);
        const fileIds = selectedFiles
            .filter((f: FileOrFolder) => !f.IsFolder())
            .map((f: FileOrFolder) => f.getFileOrFolder().Id);

        Promise.all([
            this.fileRepository.moveFolders(folderIds, this.currentPath),
            this.fileRepository.moveFiles(fileIds, this.currentPath),
        ])
            .then(this.removeMovedFilesOrFolders.bind(this, selectedFiles.slice()))
            .catch((exception: IException) => {
                this.infoToastService.Warning(
                    this.sdkService.Utilities.String.Format(
                        ProlifeSdk.TextResources.FileRepository.ErrorDuringFileMoving,
                        [exception.ExceptionMessage]
                    )
                );
            })
            .finally(() => this.doReload());
    }

    private removeMovedFilesOrFolders(selectedFiles: FileOrFolder[]) {
        selectedFiles.forEach((f: FileOrFolder) => this.Clipboard.remove(f));
    }

    public clearClipboard() {
        this.Clipboard([]);
    }

    public doReload() {
        const selectedIds = this.SelectedFiles().map((f: FileOrFolder) => f.getFileOrFolder().Id);

        this.TagsExplorer.Refresh();

        this.CurrentDirectoryContents([]);
        this.Loading(true);

        if (
            this.fromDate ||
            this.toDate ||
            this.name ||
            this.extension ||
            this.modifiedBy ||
            this.contentType ||
            this.contents ||
            this.tags.length > 0 ||
            this.shared
        ) {
            this.fileRepository
                .search(
                    this.currentPath,
                    this.fromDate,
                    this.toDate,
                    this.name,
                    this.extension,
                    this.modifiedBy,
                    this.contentType,
                    this.contents,
                    this.tags,
                    this.shared
                )
                .then(this.onBrowseCompleted.bind(this, selectedIds))
                .finally(() => this.Loading(false));
        } else {
            this.fileRepository
                .browse(this.currentPath)
                .then(this.onBrowseCompleted.bind(this, selectedIds))
                .finally(() => this.Loading(false));
        }

        this.fileRepository.getTagHints("").then((tags) => this.MostUsedTags(tags));

        Promise.all([
            this.fileRepository.getAvailableSpace(),
            this.fileRepository.getTotalSpace(),
            this.fileRepository.getUsedSpace(),
        ]).then(([, totalSpace, usedSpace]) => {
            this.TotalSpace(totalSpace);
            this.UsedSpace(usedSpace);
        });
    }

    private onBrowseCompleted(previouslySelectedIds: string[], filesOrFolders: IFileOrFolder[]) {
        this.CurrentDirectoryContents(filesOrFolders.map(this.createViewModelFor.bind(this, previouslySelectedIds)));
    }

    private createViewModelFor(previouslySelectedIds: string[], fileOrFolder: IFileOrFolder): FileOrFolder {
        const vm = new FileOrFolder(this.serviceLocator, this, fileOrFolder);
        if ((previouslySelectedIds || []).indexOf(fileOrFolder.Id) != -1) vm.Selected(true);
        return vm;
    }

    public uploadFileWithResize(file: File) {
        this.dialogsService
            .ShowModal<{ [fileName: string]: Blob }>(new ResizeImageDialog(this.serviceLocator, file))
            .then((result) => {
                if (!result) return;

                for (const fileName in result) {
                    const blob = result[fileName];
                    this.doUploadFile(blob, fileName);
                }
            });
    }

    public uploadFile(file: File) {
        if (this.FilesOrFolders().filter((f: FileOrFolder) => f.Name() == file.name).length > 0) {
            this.dialogsService.Confirm(
                ProlifeSdk.TextResources.FileRepository.FileAlreadyExistsAlert,
                ProlifeSdk.TextResources.FileRepository.CancelUpload,
                ProlifeSdk.TextResources.FileRepository.UploadAsNewVersion,
                (result: boolean) => {
                    if (result) this.doUploadFile(file, file.name);
                }
            );
        } else this.doUploadFile(file, file.name);
    }

    private doUploadFile(file: Blob, fileName: string) {
        this.fileRepository.getAvailableSpace().then((availableSpace: number) => {
            const newAvailableSpace =
                availableSpace - this.PendingUploads().reduce((total, upload) => total + upload.NumericSize(), 0);
            if (file.size > newAvailableSpace) {
                this.infoToastService.Warning(
                    this.sdkService.Utilities.String.Format(
                        ProlifeSdk.TextResources.FileRepository.FileNotUploadForInsufficientSpace,
                        [fileName]
                    )
                );
                return;
            }

            this.PendingUploads.push(
                new UploadingFileOrFolder(this.serviceLocator, this, this.fileRepository, file, fileName)
            );
        });
    }

    public removeSelectedFiles() {
        if (!this.CanDelete()) return;

        const files = this.SelectedFiles().filter((f: FileOrFolder) => !f.IsFolder());
        const folders = this.SelectedFiles().filter((f: FileOrFolder) => f.IsFolder());

        let message = ProlifeSdk.TextResources.FileRepository.SureDelete;
        if (files.length > 0) {
            if (files.length == 1)
                message += this.sdkService.Utilities.String.Format(
                    ProlifeSdk.TextResources.FileRepository.SureDeleteFile,
                    [files[0].Name()]
                );
            else
                message += this.sdkService.Utilities.String.Format(
                    ProlifeSdk.TextResources.FileRepository.SureDeleteFiles,
                    [files.length.toString()]
                );
        }

        if (folders.length > 0) {
            if (files.length > 0) message += ProlifeSdk.TextResources.FileRepository.SureDeleteAnd;

            if (folders.length == 1)
                message += this.sdkService.Utilities.String.Format(
                    ProlifeSdk.TextResources.FileRepository.SureDeleteFolder,
                    [folders[0].Name()]
                );
            else
                message += this.sdkService.Utilities.String.Format(
                    ProlifeSdk.TextResources.FileRepository.SureDeleteFolders,
                    [folders.length.toString()]
                );
        }

        this.dialogsService.Confirm(
            message + "?",
            ProlifeSdk.TextResources.FileRepository.Cancel,
            ProlifeSdk.TextResources.FileRepository.Delete,
            this.onRemoveSelectedFilesAndFolders.bind(this, files, folders)
        );
    }

    public createNewFolder() {
        this.dialogsService.Prompt(
            ProlifeSdk.TextResources.FileRepository.InsertFolderName,
            ProlifeSdk.TextResources.FileRepository.Cancel,
            ProlifeSdk.TextResources.FileRepository.CreateFolder,
            this.onCreateNewFolder.bind(this),
            ProlifeSdk.TextResources.FileRepository.NewFolder
        );
    }

    public renameSelected() {
        const selectedFileOrFolder = this.SelectedFiles()[0];
        if (selectedFileOrFolder.IsFolder())
            this.dialogsService.Prompt(
                ProlifeSdk.TextResources.FileRepository.InsertFolderToRenameName,
                ProlifeSdk.TextResources.FileRepository.Cancel,
                ProlifeSdk.TextResources.FileRepository.RenameFolder,
                this.onRenameFolder.bind(this),
                this.SelectedFiles()[0].Name()
            );
        else
            this.dialogsService.Prompt(
                ProlifeSdk.TextResources.FileRepository.InsertFileToRenameName,
                ProlifeSdk.TextResources.FileRepository.Cancel,
                ProlifeSdk.TextResources.FileRepository.RenameFile,
                this.onRenameFile.bind(this),
                this.SelectedFiles()[0].Name()
            );
    }

    public launchSelectedFile() {
        if (!this.HasSelectedFiles()) return;
        this.SelectedFiles()[0].Launch();
    }

    public sendByMail() {
        const selection: FileOrFolder[] = this.SelectedFiles().filter((f: FileOrFolder) => !f.IsFolder());
        const filesIds: string[] = selection.map((f: FileOrFolder) => f.Id);

        let body = "";
        const subject =
            filesIds.length == 1
                ? ProlifeSdk.TextResources.FileRepository.ProLifeDocument
                : ProlifeSdk.TextResources.FileRepository.ProLifeDocuments;

        const deferreds = filesIds.map((fileId: string) => {
            const deferred = new Deferred();
            this.fileRepository
                .createPermalink(fileId)
                .then((permalink: IPermaLink) => {
                    body +=
                        selection.filter((f: FileOrFolder) => f.Id.toUpperCase() == fileId.toUpperCase())[0].Name() +
                        " -> " +
                        permalink.ReferenceUrl +
                        "%0A";
                    deferred.resolve([]);
                })
                .catch(() => deferred.reject([]));
            return deferred.promise();
        });
        Promise.all(deferreds).then(() => {
            const mailReference = "mailto:?subject=" + subject + "&body=" + body;
            location.href = mailReference;
            this.doReload();
        });
    }

    public onRenameFolder(newFolderName: string) {
        const selectedFolder = this.SelectedFiles()[0];

        if (!newFolderName || selectedFolder.Name() == newFolderName) return;

        if (newFolderName.indexOf("/") >= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.FolderNameCannotContainsChars);
            return;
        }

        this.fileRepository.renameFolder(selectedFolder.FullPath(), newFolderName).finally(() => this.doReload());
    }

    public onRenameFile(newFileName: string) {
        const selectedFile = this.SelectedFiles()[0];

        if (!newFileName || selectedFile.Name() == newFileName) return;

        if (newFileName.indexOf("/") >= 0) {
            this.infoToastService.Warning(ProlifeSdk.TextResources.FileRepository.FileNameCannotContainsChars);
            return;
        }

        this.fileRepository
            .renameFile(selectedFile.FullPath(), newFileName)
            .finally(() => this.doReload())
            .catch((exception: IException) => {
                this.infoToastService.Warning(
                    this.sdkService.Utilities.String.Format(
                        ProlifeSdk.TextResources.FileRepository.ErrorDuringFileRename,
                        [exception.ExceptionMessage]
                    )
                );
            });
    }

    private onCreateNewFolder(newFolderName: string) {
        if (!newFolderName) return;

        this.fileRepository.createFolder(this.currentPath, newFolderName).finally(() => this.doReload());
    }

    private onRemoveSelectedFilesAndFolders(files: FileOrFolder[], folders: FileOrFolder[], result: boolean) {
        if (!result) return;

        const filesDeferred = files.map(this.removeFile.bind(this));
        const foldersDeferred = folders.map(this.removeFolder.bind(this));
        const allDeferred = filesDeferred.concat(foldersDeferred);

        Promise.all(allDeferred).finally(() => this.doReload());
    }

    private removeFile(f: FileOrFolder): Promise<void> {
        return this.fileRepository.deleteFile(f.FullPath());
    }

    private removeFolder(f: FileOrFolder): Promise<void> {
        return this.fileRepository.deleteFolder(f.FullPath());
    }

    close(): void {
        this.modal.close();
    }

    action(): void {
        this.modal.close(this.SelectedFiles().map((f: FileOrFolder) => f.getFileOrFolder()));
    }

    searchToday() {
        this.clearSearchCriteria(false);
        this.fromDate = moment().startOf("day").toDate();
        this.toDate = moment().endOf("day").toDate();
        this.reload();
    }

    searchYesterday() {
        this.clearSearchCriteria(false);
        this.fromDate = moment().subtract("day", 1).startOf("day").toDate();
        this.toDate = moment().subtract("day", 1).endOf("day").toDate();
        this.reload();
    }

    searchLastWeek() {
        this.clearSearchCriteria(false);
        this.fromDate = moment().subtract("week", 1).startOf("day").toDate();
        this.toDate = moment().endOf("day").toDate();
        this.reload();
    }

    searchAllImages() {
        this.clearSearchCriteria(false);
        this.contentType = "image";
        this.reload();
    }

    searchAllDocuments() {
        this.clearSearchCriteria(false);
        this.contentType = "application";
        this.reload();
    }

    searchAllShared() {
        this.clearSearchCriteria(false);
        this.shared = true;
        this.reload();
    }

    searchForTag(tag: ITagHint) {
        this.clearSearchCriteria(false);
        this.tags.push(tag.LabelId);
        this.reload();
    }

    revokeShare(share: IPermaLink) {
        this.dialogsService.Confirm(
            ProlifeSdk.TextResources.FileRepository.SureCancelSharing,
            ProlifeSdk.TextResources.FileRepository.Cancel,
            ProlifeSdk.TextResources.FileRepository.SureCancelSharingConfirm,
            this.onRevokeShare.bind(this, share)
        );
    }

    private onRevokeShare(share: IPermaLink, result: boolean) {
        if (!result) return;

        this.fileRepository.deletePermalink(share.PermalinkId).then(() => this.doReload());
    }

    editImage() {
        const file = this.SelectedFiles()[0];
        this.dialogsService
            .ShowModal<{ [fileName: string]: Blob }>(
                new ImageEditorDialog(this.serviceLocator, file.getFileOrFolder()),
                "fullscreen",
                { fade: false }
            )
            .then((results) => {
                if (!results) return;
                const blob = results[file.Name()];
                this.doUploadFile(blob, file.Name());
            });
    }

    Publish(fileId: string) {
        this.fileRepository.Publish(fileId).then(() => this.doReload());
    }

    Unpublish(fileId: string) {
        this.fileRepository.Unpublish(fileId).then(() => this.doReload());
    }

    OpenDialogForContextMenu() {
        this.dialogsService.ShowModal<void>(
            new ContextMenuForFileRespositoryDialog(this.serviceLocator, this),
            "fullscreen",
            { fade: false }
        );
    }
}

class FileOrFolder {
    Selected: ko.Observable<boolean> = ko.observable(false);

    IsUpload: ko.Observable<boolean> = ko.observable(false);
    Name: ko.Observable<string> = ko.observable();
    Path: ko.Observable<string> = ko.observable();
    Extension: ko.Observable<string> = ko.observable();
    IsFolder: ko.Observable<boolean> = ko.observable();
    HasFolders: ko.Observable<boolean> = ko.observable();
    HasFiles: ko.Observable<boolean> = ko.observable();
    HasPreview: ko.Observable<boolean> = ko.observable();
    IsReserved: ko.Observable<boolean> = ko.observable(false);
    CreationDate: ko.Observable<Date> = ko.observable();
    ModifiedDate: ko.Observable<Date> = ko.observable();
    CreatedBy: ko.Observable<number> = ko.observable();
    ModifiedBy: ko.Observable<number> = ko.observable();
    Size: ko.Observable<string> = ko.observable();
    NumericSize: ko.Observable<number> = ko.observable();
    HasPermalinks: ko.Observable<boolean> = ko.observable(false);
    Version: ko.Observable<number> = ko.observable();

    PreviewStyle: ko.Observable<string> = ko.observable();
    FullPath: ko.Observable<string> = ko.observable();
    StyleClasses: ko.Observable<string> = ko.observable();
    public Id: string;

    Tags: ko.ObservableArray<ITagHint> = ko.observableArray();

    @LazyImport(ProlifeSdk.DesktopServiceType)
    private desktopService: IDesktopService;

    @LazyImport(nameof<IAjaxServiceNew>())
    private ajaxServiceNew: IAjaxServiceNew;

    @LazyImport(nameof<IFileRepositoryService>())
    private fileRepositoryService: IFileRepositoryService;

    constructor(
        protected serviceLocator: IServiceLocator,
        protected navigator: IFileRepositoryNavigator,
        private fileOrFolder: IFileOrFolder
    ) {
        this.update(fileOrFolder);
    }

    public update(fileOrFolder: IFileOrFolder) {
        this.fileOrFolder = fileOrFolder;
        this.Name(fileOrFolder.Name);
        this.Path(fileOrFolder.Path);
        this.Extension(fileOrFolder.IsFolder ? "" : this.getFileExtension(fileOrFolder.Name));
        this.FullPath(
            fileOrFolder.IsFolder
                ? fileOrFolder.Path.replace(/\\/g, "/")
                : fileOrFolder.Path.replace(/\\/g, "/") + fileOrFolder.Name
        );
        this.IsFolder(fileOrFolder.IsFolder);
        this.HasFolders(fileOrFolder.HasFolders);
        this.HasFiles(fileOrFolder.HasFiles);
        this.HasPreview(fileOrFolder.HasPreview);
        this.CreationDate(moment(fileOrFolder.CreationDate).toDate());
        this.ModifiedDate(moment(fileOrFolder.ModifiedDate).toDate());
        this.CreatedBy(fileOrFolder.CreatedBy);
        this.ModifiedBy(fileOrFolder.ModifiedBy);
        this.IsReserved(fileOrFolder.Reserved);
        this.NumericSize(fileOrFolder.Size);
        this.HasPermalinks(fileOrFolder.HasPermalinks);
        this.Tags(fileOrFolder.Tags || []);
        this.Id = fileOrFolder.Id;
        this.Version(fileOrFolder.Version);

        this.StyleClasses(this.fileRepositoryService.getPreviewStyle(fileOrFolder));

        if (!fileOrFolder.IsFolder) {
            this.Size(numeral(fileOrFolder.Size).format("0,0[.]0 b"));
        }

        if (fileOrFolder.HasPreview) {
            const previewUrl = this.fileRepositoryService.getPreviewUrl(fileOrFolder);
            this.PreviewStyle("background-image: url('" + previewUrl + "')");
        }
    }

    private getFileExtension(fname: string) {
        return fname.substr((~-fname.lastIndexOf(".") >>> 0) + 2);
    }

    public Select() {
        if (!this.navigator.isInSelectMode()) this.navigator.deselectAll();

        this.Selected(!this.Selected());
    }

    public Launch() {
        if (this.fileOrFolder.IsFolder) {
            if (this.navigator.isDialog) this.navigator.navigateTo(this.fileOrFolder.Path.replace(/\\/g, "/"));
            else {
                const encodedUri = this.fileOrFolder.Path.replace(/\\/g, "/")
                    .split("/")
                    .map((c) => encodeURIComponent(c))
                    .join("/");
                location.href = "#/FileRepository" + encodedUri;
                return;
            }
        }

        if (this.navigator.isInSelectMode()) {
            this.Selected(!this.Selected());
            return;
        }

        const url = this.fileRepositoryService.getDownloadUrl(this.fileOrFolder);
        location.href = url;
    }

    public getFileOrFolder(): IFileOrFolder {
        return this.fileOrFolder;
    }
}

class UploadingFileOrFolder extends FileOrFolder {
    Progress: ko.Observable<string> = ko.observable();

    constructor(
        serviceLocator: IServiceLocator,
        navigator: IFileRepositoryNavigator,
        private fileRepository: IFileRepositoryService,
        private file: Blob,
        private fileName: string
    ) {
        super(serviceLocator, navigator, {
            Name: fileName,
            Path: navigator.getCurrentPath(),
            IsFolder: false,
            HasFolders: false,
            HasFiles: false,
            HasPreview: false,
            CreationDate: new Date(),
            ModifiedDate: new Date(),
            CreatedBy: 0,
            ModifiedBy: 0,
            Size: file.size,
            HasPermalinks: false,
            Reserved: false,
            Version: 1,
        });

        this.IsUpload(true);
        this.Progress("0%");

        const promise = this.fileRepository.upload(this.navigator.getCurrentPath(), file, fileName);
        promise.then(() => this.navigator.uploadCompleted(this));
        promise.progress((progressEvent) => {
            this.Progress(Math.round(progressEvent.percentComplete * 100) + "%");
        });
        promise.catch(() => this.navigator.uploadFailed(this));
    }

    public Launch() {
        //Niente da fare
    }

    public Select() {
        //Niente da fare
    }
}
