import * as React from "@abstraqt-dev/jsxknockout";
import { ComponentUtils, reloadNow } from "../Core/utils/ComponentUtils";
import { Select, SelectProps } from "./Select";
import { ResourcesAndGroupsDataSource } from "../DataSources/ResourcesAndGroupsDataSource";
import { IResourcesGroup } from "../ProlifeSdk/interfaces/users/IResourcesGroupsSettingsManager";
import { IHumanResource } from "../Users/HumanResourcesService";
import { SelectMultiple, SelectMultipleProps } from "./SelectMultiple";
import { IDataSourceModel } from "../DataSources/IDataSource";

type ResourcesSelectorPropsBase = {
    includeDeleted?: boolean;
    includeDisabled?: boolean;
    includeMaterialResources?: boolean;
    includeGroups?: boolean;

    label?: string;
};

class ResourcesSelectorBase<T extends ResourcesSelectorPropsBase = ResourcesSelectorPropsBase> {
    static defaultProps: Partial<ResourcesSelectorProps> = {
        includeDeleted: false,
        includeDisabled: false,
        includeMaterialResources: false,
        includeGroups: false
    }

    protected dataSource: ResourcesAndGroupsDataSource;
    protected selectProps: Omit<T, "includeDeleted"|"includeDisabled"|"includeMaterialResources"|"includeGroups">;
    
    constructor(protected props : T) {
        const { includeDeleted, includeDisabled, includeMaterialResources, includeGroups, ...rest } = this.props;

        this.selectProps = rest;

        this.dataSource = new ResourcesAndGroupsDataSource();
        this.dataSource.setIncludeDeletedResources(includeDeleted);
        this.dataSource.setIncludeDisabledResources(includeDisabled);
        this.dataSource.setIncludeMaterialResources(includeMaterialResources);
        this.dataSource.setIncludeGroups(includeGroups);
    }
}

type ResourcesSelectorProps = ResourcesSelectorPropsBase & Omit<SelectProps<string, string|IHumanResource|IResourcesGroup>, "dataSource">;

export function ResourcesSelector(props: ResourcesSelectorProps) {
    const C = require("./ResourcesSelector")._ResourcesSelector as typeof _ResourcesSelector;
    return <C {...props} />;
}

export class _ResourcesSelector extends ResourcesSelectorBase<ResourcesSelectorProps> {
    constructor(props : ResourcesSelectorProps) {
        super(props);
    }

    render() {
        if (this.props.label) {
            return  <Select.WithLabel 
                        label={this.props.label}
                        dataSource={this.dataSource}
                        {...this.selectProps}

                        renderSelectedItem={selectedItemRender}
                    />
        }

        return  <Select
                    dataSource={this.dataSource}
                    {...this.selectProps}

                    renderSelectedItem={selectedItemRender}
                />;
    }
}

type ResourcesMultipleSelectorProps = ResourcesSelectorPropsBase & Omit<SelectMultipleProps<string, string|IHumanResource|IResourcesGroup>, "dataSource">;

export function ResourcesMultipleSelector(props: ResourcesMultipleSelectorProps) {
    const C = require("./ResourcesSelector")._ResourcesMultipleSelector as typeof _ResourcesMultipleSelector;
    return <C {...props} />;
}

export class _ResourcesMultipleSelector extends ResourcesSelectorBase<ResourcesMultipleSelectorProps> {
    constructor(props : ResourcesMultipleSelectorProps) {
        super(props);
    }

    render() {
        if (this.props.label) {
            return  <SelectMultiple.WithLabel 
                        label={this.props.label}
                        dataSource={this.dataSource}
                        {...this.selectProps}

                        renderSelectedItem={selectedItemRender}
                    />
        }

        return  <SelectMultiple
                    dataSource={this.dataSource}
                    {...this.selectProps}

                    renderSelectedItem={selectedItemRender}
                />;
    }
}

function selectedItemRender(item: IDataSourceModel<string, string|IHumanResource|IResourcesGroup>) {
    if (typeof item === "string")
        return <></>;

    const resource = item as IDataSourceModel<string, IHumanResource>;
    if (resource && resource.model.Resource) {
        return selectedResourceRender(resource);
    }

    const group = item as IDataSourceModel<string, IResourcesGroup>;
    return <span className={ComponentUtils.classNames({ "unavailable-resource": group.model.Deleted})}>{item.title}</span>;
}

function selectedResourceRender(item: IDataSourceModel<string, IHumanResource>) {
    return <span className={ComponentUtils.classNames({ "unavailable-resource": item.model.Resource.Deleted || item.model.Resource.Disabled })}>{item.title}</span>;
}

if (module.hot) {
    module.hot.accept();
    reloadNow(ResourcesSelector);
    reloadNow(ResourcesMultipleSelector);
}