export { default as AgendaService } from "./Agenda/AgendaService";
export { default as EventsService } from "./Agenda/EventsService";
export { default as FestivitiesAgendasService } from "./Agenda/FestivitiesAgendasService";
export { default as FestivitiesService } from "./Agenda/FestivitiesService";
export { default as FolderService } from "./Agenda/FolderService";
export { default as GoogleCalendarService } from "./Agenda/GoogleCalendarService";
export { default as AllocationsService } from "./Allocations/AllocationsService";
export { default as CartsService } from "./Allocations/CartsService";
export { default as AssetsService } from "./Assets/AssetsService";
export { default as BlogService } from "./Blog/BlogService";
export { default as MailsService } from "./Blog/MailsService";
export { default as PurchasesService } from "./Blog/PurchasesService";
export { default as ContractsService } from "./Contracts/ContractsService";
export { default as CustomersGroupsService } from "./Customers/CustomersGroupsService";
export { default as CustomersService } from "./Customers/CustomersService";
export { default as TrustMovementsService } from "./Customers/TrustMovementsService";
export { default as TrustsService } from "./Customers/TrustsService";
export { default as ChangesNotificationsService } from "./Desktop/ChangesNotificationsService";
export { default as DataSourcesService } from "./Desktop/DataSourcesService";
export { default as ApplicationsService } from "./Desktop/desktop/ApplicationsService";
export { default as UserInfoService } from "./Desktop/desktop/UserInfoService";
export { default as DesktopService } from "./Desktop/DesktopService";
export { default as EntitiesLockService } from "./Desktop/EntitiesLockService";
export { default as EntityProviderService } from "./Desktop/EntityProviderService";
export { default as FinancialDataService } from "./Desktop/FinancialDataService";
export { default as LogService } from "./Desktop/LogService";
export { default as PaymentPortalService } from "./Desktop/PaymentPortalService";
export { default as SmtpConfigurationService } from "./Desktop/SmtpConfigurationService";
export { default as UserSessionService } from "./Desktop/UserSessionService";
export { default as ExpenseService } from "./Expense/ExpenseService";
export { default as FileRepositoryService } from "./FileRepository/FileRepositoryService";
export { default as FunctionPointComputationService } from "./FunctionPointComputation/FunctionPointComputationService";
export { default as GDPRService } from "./GDPR/GDPRService";
export { default as CurrenciesService } from "./Invoices/CurrenciesService";
export { default as DocumentsService } from "./Invoices/DocumentsService";
export { default as EstimatesService } from "./Invoices/EstimatesService";
export { default as InvoicesService } from "./Invoices/InvoicesService";
export { default as MetadataService } from "./Invoices/MetadataService";
export { default as OffsetsService } from "./Invoices/OffsetsService";
export { default as TrustAuthorizationProcessService } from "./Invoices/TrustAuthorizationProcessService";
export { default as VatRegistersService } from "./Invoices/VatRegistersService";
export { default as JobOrderService } from "./JobOrder/JobOrderService";
export { default as SalService } from "./JobOrder/SalService";
export { default as MsdnService } from "./Msdn/MsdnService";
export { default as PlanningService } from "./Planning/PlanningService";
export { default as ProjectsService } from "./Projects/ProjectsService";
export { default as ExcelExportService } from "./ProlifeSdk/ExcelExportService";
export { default as IdGeneratorService } from "./ProlifeSdk/IdGeneratorService";
export { default as ApplicationsConfigurationsService } from "./ProlifeSdk/prolifesdk/ApplicationsConfigurationsService";
export { default as ProlifeSdkService } from "./ProlifeSdk/ProlifeSdkService";
export { default as ValidationService } from "./ProlifeSdk/ValidationService";
export { default as ProtocolsService } from "./Protocols/ProtocolsService";
export { default as ProvisioningService } from "./Provisioning/ProvisioningService";
export { default as QuotationService } from "./Quotation/QuotationService";
export { default as ReminderService } from "./Reminder/ReminderService";
export { default as ReportsService } from "./Reports/ReportsService";
export { default as ResourcesManagerService } from "./ResourcesManager/ResourcesManagerService";
export { default as ScheduleService } from "./Schedule/ScheduleService";
export { default as SettingsService } from "./Settings/SettingsService";
export { default as CampaignService } from "./Survey/CampaignService";
export { default as PeopleService } from "./Survey/PeopleService";
export { default as SampleService } from "./Survey/SampleService";
export { default as SurveyService } from "./Survey/SurveyService";
export { default as SurveyWizardService } from "./SurveyWizard/SurveyWizardService";
export { default as TicketsService } from "./Tickets/TicketsService";
export { default as EstimatedBudgetForTaskStatesService } from "./Todolist/EstimatedBudgetForTaskStatesService";
export { default as TodoListService } from "./Todolist/TodoListService";
export { default as WorkflowOutcomesService } from "./Todolist/WorkflowOutcomesService";
export { default as HumanResourcesService } from "./Users/HumanResourcesService";
export { default as UsersService } from "./Users/UsersService";
export { default as AliasesService } from "./Warehouse/AliasesService";
export { default as ArticlesService } from "./Warehouse/ArticlesService";
export { default as CatalogsService } from "./Warehouse/CatalogsService";
export { default as DiscountsCatalogsService } from "./Warehouse/DiscountsCatalogsService";
export { default as DiscountsService } from "./Warehouse/DiscountsService";
export { default as FamiliesService } from "./Warehouse/FamiliesService";
export { default as ManufacturersService } from "./Warehouse/ManufacturersService";
export { default as MovementService } from "./Warehouse/MovementService";
export { default as OrdersService } from "./Warehouse/OrdersService";
export { default as PreferredSuppliersService } from "./Warehouse/PreferredSuppliersService";
export { default as StockService } from "./Warehouse/StockService";
export { default as WarehouseInspectionsService } from "./Warehouse/WarehouseInspectionsService";
export { default as WarehousesService } from "./Warehouse/WarehousesService";
export { default as BarCodeScannerService } from "./WorkedHours/BarCodeScannerService";
export { default as WorkedHoursService } from "./WorkedHours/WorkedHoursService";
